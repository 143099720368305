import React, { Component } from 'react';
import { Card, Elevation, Button } from "@blueprintjs/core"
import { Table, Column, Cell } from "@blueprintjs/table"
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select";
import moment from "moment";

export default class Fichajes extends Component {
    
    state ={
        hora: new Date(),
        trabajadores:[
            {value: 1, label: "Rafael"},
            {value: 2, label: "Gabriel"},
            {value: 3, label: "Miguel"},
            {value: 4, label: "Angel"},
        ],
        trabajadorSeleccionado: null,
        fichajesRealizados: [
            { nombre: "Rafael García León", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 17:30", normales: 8, extras: 3},
            { nombre: "Gabriel Fernandez Lopez", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 15:30", normales: 8, extras: 0},
            { nombre: "Miguel Rodriguez Perez", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 17:30", normales: 8, extras: 2},
            { nombre: "Angel Sanchez Escobar", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 16:30", normales: 8, extras: 1},
            { nombre: "Gabriel Diaz Perez", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 15:30", normales: 8, extras: 0},
        ],
        fechaMock: null,
    }

    render() {

        const seleccionTrabajador  = (value) => { 
            this.setState({trabajadorSeleccionado: value})
        }

        const cellRendererNombre = (rowIndex) => {
            return <Cell >{this.state.fichajesRealizados[rowIndex].nombre}</Cell>
        }

        const cellRendererEntrada = (rowIndex) => {
            return <Cell >{this.state.fichajesRealizados[rowIndex].fechaEntrada}</Cell>
        }

        const cellRendererSalida = (rowIndex) => {
            return <Cell >{this.state.fichajesRealizados[rowIndex].fechaSalida}</Cell>
        }

        const cellRendererNormales = (rowIndex) => {
            return <Cell >{this.state.fichajesRealizados[rowIndex].normales}</Cell>
        }

        const cellRendererExtras = (rowIndex) => {
            return <Cell >{this.state.fichajesRealizados[rowIndex].extras}</Cell>
        }

        const cellRendererTotales = (rowIndex) => {
            return <Cell >{this.state.fichajesRealizados[rowIndex].normales + this.state.fichajesRealizados[rowIndex].extras}</Cell>
        }

        const añadirFichaje = () => {
            let estadoOriginal = this.state.fichajesRealizados
            estadoOriginal.push({nombre: this.state.trabajadorSeleccionado.label, fechaEntrada: this.state.hora.toString(), fechaSalida: this.state.hora.toString(), normales: 8, extras: 0})
            this.setState({fichajesRealizados: estadoOriginal})
        }
        const generarInforme = () => {
            alert("Aqui vamos a la pantalla de informes")
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFechaFin = (nuevaFecha2) => {
            this.setState({fechaHasta: nuevaFecha2, filtros:true })
        }


        document.title ="LexControl | Fichajes"

        return (
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO} className="tablaSesenta">
                    <Card interactive={true} elevation={Elevation.TWO}><Reloj /></Card>                               
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <DateInput 
                            minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter} 
                            onChange={escogeFechaFin}
                            value={this.state.fechaHasta}
                        />
                    </Card>
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Table  numRows={this.state.fichajesRealizados.length} columnWidths={[180,120,120,85,85,85]} >
                            <Column name="Nombre"  cellRenderer={cellRendererNombre}/>
                            <Column name="Entrada"  cellRenderer={cellRendererEntrada}/>
                            <Column name="Salida"  cellRenderer={cellRendererSalida}/>
                            <Column name="Normales"  cellRenderer={cellRendererNormales}/>
                            <Column name="Extra"  cellRenderer={cellRendererExtras}/>
                            <Column name="Totales"  cellRenderer={cellRendererTotales}/>
                        </Table>
                    </Card>
                    </Card>
                    <Card interactive={true} elevation={Elevation.TWO} className="tablaCuarenta">
                        <Select
                            options={this.state.trabajadores}
                            className="pixelAlrededor"
                            onChange={seleccionTrabajador}
                            value={this.state.trabajadorSeleccionado}
                            isClearable
                        />
                        {
                            this.state.trabajadorSeleccionado != null?
                            <>
                                <h4>VA A INTRODUCIR EL FICHAJE PARA EL TRABAJADOR {this.state.trabajadorSeleccionado != null ?this.state.trabajadorSeleccionado.label:"null"}</h4>
                                <Button text="FICHAR?" className="pixelAlrededor" onClick={añadirFichaje} />
                            </>
                            :
                            <>
                                <h1>SELECCIONE UN TRABAJADOR PARA FICHAR</h1>
                                <Button text="GENERAR INFORME" className="pixelAlrededor" onClick={generarInforme} />
                            </>
                        }
                    </Card>
                </div>
            </>
        )
    }
}

class Reloj extends Component {
    state = {
      time: new Date()
    };
  
    componentDidMount() {
      this.timerID = setInterval(() => this.tick(), 1000);
    }
  
    componentWillUnmount() {
      clearInterval(this.timerID);
    }
  
    tick() {
      this.setState({
        time: new Date()
      });
    }
  
    render() {
      return (
        <div>
          <h2>Son las {this.state.time.toLocaleTimeString()}</h2>
        </div>
      );
    }
  }