import React from "react"
import { FormGroup, Card, Alignment, TextArea,Checkbox,Button,Classes,Tooltip,Dialog,InputGroup,Intent,AnchorButton} from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import const_table from "../BootstrapTable";
import moment from 'moment';
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

class DatosDenuncia extends React.Component {

    state={
        fechaDenuncia: new Date(),
        fechaHechos: new Date(),
        fechaInformacion: null,
        medioRecepcionSeleccionado:null,
        medioRecepcion:[
        ],
        medioInformacionSeleccionado:null,
        medioInformacion:[
        ],
        hechosDenunciados:null,
        bolFechaInformacion:false,
        nuevoMedio:null,
        modalMedioAbierto:false
    }

    reiniciarEstado() {
        this.setState({

            fechaDenuncia: new Date(),
            fechaHechos: new Date(),
            fechaInformacion: null,
            medioRecepcionSeleccionado:null,
            medioInformacionSeleccionado:null,
            hechosDenunciados:null,
            bolFechaInformacion:false,
            nuevoMedio:null,
            modalMedioAbierto:false

        });
    }


    cargarMedios=async ()=>{

        
        //Medios recepcion
        let mediosRecepcion= await client.query({
            query:gql`{mediosRecepcionDenuncias{ID_MEDIO_RECEPCION_DENUNCIA,DESCRIPCION}}            `
        }).then(async(result)=>{
            //console.log(result)
            return result.data.mediosRecepcionDenuncias
        })
        
        let mediosContacto= await client.query({
            query:gql`{tiposMediosContacto{ID_TIPO_MEDIO_CONTACTO,DESCRIPCION}}            `
        }).then(async(result)=>{
            //console.log(result)
            return result.data.tiposMediosContacto
        })    
            let medios=[]
            mediosRecepcion.forEach(async(medio)=>{
             
                medios.push({"value":medio.ID_MEDIO_RECEPCION_DENUNCIA,"label":medio.DESCRIPCION}) 

            })
            this.setState({medioRecepcion:medios})

        //Medios informacion
     
            let mediosCont=[]
            mediosContacto.forEach(async(medio)=>{
            
                mediosCont.push({"value":medio.ID_TIPO_MEDIO_CONTACTO,"label":medio.DESCRIPCION}) 

            })
            this.setState({medioInformacion:mediosCont})
    
        if(this.props.datosDenuncia){
        
            this.setState({fechaDenuncia:new Date(this.props.datosDenuncia.fechaDenuncia)})
            this.setState({fechaHechos:new Date(this.props.datosDenuncia.fechaHechos)})
            this.setState({fechaInformacion:this.props.datosDenuncia.fechaInformado?new Date(this.props.datosDenuncia.fechaInformado):null})
            this.setState({bolFechaInformacion:this.props.datosDenuncia.fechaInformado?true:false})
            this.setState({hechosDenunciados:this.props.datosDenuncia.hechosDenunciados})
            //buscamos la relacion
            let recepSel=this.state.medioRecepcion.find(recepcion=>{
                return recepcion.value===this.props.datosDenuncia.medioRecepcion
            })
            this.setState({medioRecepcionSeleccionado:recepSel})

            let informacionSel=this.state.medioInformacion.find(informacion=>{
                return informacion.value===this.props.datosDenuncia.medioInformacion
            })
            this.setState({medioInformacionSeleccionado:informacionSel})
        
        }

    }

    componentDidMount=async()=>{

     await this.cargarMedios() 
    }

    

    render(){

        console.log(this.state)

        const handleSeleccionarMedioRecepcion=(value)=>{
            this.setState({medioRecepcionSeleccionado:value})
        }

        const handleSeleccionarMedioInformacion=(value)=>{
            this.setState({medioInformacionSeleccionado:value})
        }

        const handleCambiarHechosDenunciados=(ev)=>{
            this.setState({hechosDenunciados:ev.currentTarget.value})
        }

        const handleEnabledChangeBolFechaInformacion=()=>{
            let valor=!this.state.bolFechaInformacion
            this.setState({bolFechaInformacion:valor})
            if(valor){
                this.setState({fechaInformacion:new Date()})
            }else{
                this.setState({fechaInformacion:null})
            }
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFechaDenuncia = (nuevaFecha) => {
            this.setState({fechaDenuncia: nuevaFecha})
        }

        const escogeFechaHechos = (nuevaFecha) => {
            this.setState({fechaHechos: nuevaFecha})
        }

        const escogeFechaInformacion = (nuevaFecha) => {
            this.setState({fechaInformacion: nuevaFecha})
        }

        const abrirModalMedio=()=>{
            this.setState({
                nuevoMedio:null,
                modalMedioAbierto:true
            })
        }

        const handlenuevoMedio=(ev)=>{
            this.setState({nuevoMedio:ev.currentTarget.value})
        }

        const cerrarModal=()=>{
            this.setState({modalMedioAbierto:false})
        }

        const anadirMedio=(ev)=>{

            if(!this.state.nuevoMedio)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.medioRecepcion.find(medio=>{
                return medio.label===this.state.nuevoMedio
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos la medio empresa.
            client.mutate({
                mutation: gql`
                  mutation{crearMedioRecepcionDenuncia(VALORES:{DESCRIPCION:"${this.state.nuevoMedio}"}){ID_MEDIO_RECEPCION_DENUNCIA}}
                `
                }).then(async result=>{
                
                    await this.cargarMedios()
                    
                    let medioCreado=result.data.crearMedioRecepcionDenuncia
                    let medioSel=this.state.medioRecepcion.find(medio=>{
                        return medio.value===medioCreado.ID_MEDIO_RECEPCION_DENUNCIA
                    })

                    this.setState({medioRecepcionSeleccionado:medioSel})
                    cerrarModal()

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

        }


        return (
            <>

                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("denuncias.mediorecepcion",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}nuevoMedio
                    isOpen={this.state.modalMedioAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("denuncias.entermediorecepcion",{ns:"page"})+":"}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoMedio?"":i18next.t("denuncias.emptymediorecepcion",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-comunicacion" onChange={handlenuevoMedio}  intent={this.state.nuevoMedio?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirMedio}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <>
                    <div className="pixelAlrededor">
                        <Card>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.fechadenuncia",{ns:"page"}).toLowerCase())}
                                labelFor="fecha-denuncia"
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaDenuncia}
                                    value={this.state.fechaDenuncia?this.state.fechaDenuncia:new Date()}
                                    align={Alignment.RIGHT}
                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.fechahechos",{ns:"page"}).toLowerCase())}
                                labelFor="fecha-hecho"
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaHechos}
                                    value={new Date(this.state.fechaHechos)}
                                    align={Alignment.RIGHT}
                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.mediorecepcion",{ns:"page"}).toLowerCase())}
                                labelFor="medio-recepcion"
                                intent="danger"
                                helperText={this.state.medioRecepcionSeleccionado?"":i18next.t("denuncias.noselectmedio",{ns:"page"})}
                            >
                                <div style={this.state.medioRecepcionSeleccionado?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.medioRecepcion}
                                        className="pixelAlrededor"
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        onChange={handleSeleccionarMedioRecepcion}
                                        value={this.state.medioRecepcion.filter(medio => medio.value === (this.state.medioRecepcionSeleccionado?this.state.medioRecepcionSeleccionado.value:null))}
                                    />
                                </div>
                            </FormGroup>
                            <Button 
                                alignText="right"
                                icon="add"
                                className="iconoIzquierda"
                                onClick={abrirModalMedio}
                            />
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.fechadenunciado",{ns:"page"}).toLowerCase())}
                                labelFor="fecha-informacion"
                            >
                                <Checkbox defaultChecked={this.state.fechaInformacion} onChange={handleEnabledChangeBolFechaInformacion} />
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaInformacion}
                                    value={this.state.fechaInformacion}
                                    align={Alignment.RIGHT}
                                    disabled={!this.state.bolFechaInformacion}
                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.medioinformacion",{ns:"page"}).toLowerCase())}
                                labelFor="medio-informacion"
                            >
                                <Select
                                    options={this.state.medioInformacion}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={handleSeleccionarMedioInformacion}
                                    value={this.state.medioInformacion.filter(medio => medio.value === (this.state.medioInformacionSeleccionado?this.state.medioInformacionSeleccionado.value:null))}
                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.hechosdenunciado",{ns:"page"}).toLowerCase())}
                                labelFor="hechos-denunciados"
                                intent="danger"
                                helperText={this.state.hechosDenunciados?"":i18next.t("denuncias.emptyhechosdenunciado",{ns:"page"})}
                            >
                                <TextArea fill={true} value={this.state.hechosDenunciados} intent={this.state.hechosDenunciados?"primary":"danger"} onChange={handleCambiarHechosDenunciados}/>
                            </FormGroup>
                        </Card>
                    </div>
                </>
            </>
        )
    }
}

export default withTranslation()(DatosDenuncia);