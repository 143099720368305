import React, { Component } from 'react';
import { Elevation, Callout, Card, FormGroup, Navbar, ButtonGroup, Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, TextArea, RadioGroup, Radio, Spinner } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';

class Apoderados extends Component {

    state={

        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        apoderados: [
        ],
        personas:[],
        abierto: false,
        value: 1,
        contenido:null,
        limites:null,
        personaSeleccionada:null,
        apoderadoSeleccionado:null,
        enabled: true,
        cargando:false,
        cargandoForm:false,
        columnWidths: [100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirCD:false,
        modalImprimirCD: false,
        cargandoImprimirCDG:false,
        modalImprimirCDG: false,
        logotipo: null,
        reloadTable: false

    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    obtenerNombreApoderado=async(apoderado)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${apoderado.ID_APODERADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            apoderado.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        })
    }

    cargarApoderados=async ()=>{

        this.setState({cargando:true})

        let apoderados=await this.props.client.query({
            query:gql`{apoderados{ID_APODERADO,ID_MODALIDAD_DELEGACION,LIMITES,CONTENIDO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return JSON.parse(JSON.stringify(result.data.apoderados))
        })

        if(!apoderados || apoderados.length===0){
            this.setState({
                apoderados:[],
                cargando:false
            })
            return
        }

        let index=0
        for(let persona of apoderados){

            //await this.obtenerNombreApoderado(persona)
            let nombre='';
            // eslint-disable-next-line array-callback-return
            this.state.personas.find((pers)=>{
                if (pers.value===persona.ID_APODERADO) {
                    nombre=pers.label;
                }
            })
            apoderados[index].nombre=nombre
            apoderados[index].id=persona.ID_APODERADO
            apoderados[index].limites=persona.LIMITES
            apoderados[index].contenido=persona.CONTENIDO
            apoderados[index].idModalidad=persona.ID_MODALIDAD_DELEGACION

            index++
        }

        this.setState({
            apoderados:apoderados,
            cargando:false
        })

    }

    cargarPersonas=async()=>{

        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            let personas=[]
            result.data.personasFisicas.forEach(async(persona)=>{
             
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 

            })
            this.setState({personas:personas})
        })

    }
    
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.cargarPersonas()
            await this.cargarApoderados()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoForm: false})
        });
    }
    generarImprimirA= async() => {
        this.setState({
            cargandoImprimirA:true,
            modalImprimirA: true,
            reloadTable: true
        }, async () => {
             this.setState({cargandoImprimirA:false}, () => {
                 ReactDOM.render(this.ImprimirA(), document.getElementById('generarA-pdf'))
             })
        })
    }

    generarImprimirAG= async() => {
        this.setState({
            cargandoImprimirAG:true,
            modalImprimirAG:true
        }, async () => {
             this.setState({cargandoImprimirAG:false}, () => {
                 ReactDOM.render(this.ImprimirAG(), document.getElementById('generarAG-pdf'))
             })
        })
    }
    
    ImprimirA=()=>{
        let apoderadoSel=this.state.personas.find(persona=>{return persona.value===this.state.apoderadoSeleccionado.id})
                        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.legalRepresentatives", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.legalRepresentative", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.apoderadoSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("name", { ns: "global" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{apoderadoSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.content", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.apoderadoSeleccionado.contenido}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.bounds", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.apoderadoSeleccionado.limites}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.delegationForm", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.apoderadoSeleccionado.idModalidad===1?'Permanente':'Contrato entre consejero y sociedad'}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirAG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.legalRepresentatives", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.legalRepList", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.apoderados?this.state.apoderados.map((apoderado, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("name", { ns: "global" }).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{apoderado.nombre?apoderado.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("partesInteresadas.legalRepresentatives", { ns: "page2" })}`

            const abrirModalNuevo = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso", { ns: "global" }))
                    return
                }

                this.setState({
                    modalAbierto: true,
                    nuevoActivado:true,
                    editarActivado:false,
                    borrarActivado:false,
                    value:1,
                    limites:null,
                    contenido:null,
                    personaSeleccionada:null,
                    apoderadoSeleccionado:null
                })
            }

            const abrirModalEditar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso", { ns: "global" }))
                    return
                }

                if(!this.state.apoderadoSeleccionado){
                    alert(i18next.t("partesInteresadas.selectLegalRep", { ns: "page2" }))
                    return
                }

                
                let apoderadoSel=this.state.personas.find(persona=>{return persona.value===this.state.apoderadoSeleccionado.id})
                this.setState({
                    personaSeleccionada:apoderadoSel,
                    nuevoActivado:false,
                    editarActivado:true,
                    borrarActivado:false,
                    value:this.state.apoderadoSeleccionado.idModalidad,
                    limites:this.state.apoderadoSeleccionado.limites,
                    contenido:this.state.apoderadoSeleccionado.contenido,
                    modalAbierto: true,
                    reloadTable: true


                })
            }

            const abrirModalBorrar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso", { ns: "global" }))
                    return
                }

                if(!this.state.apoderadoSeleccionado){
                    alert(i18next.t("partesInteresadas.selectLegalRep", { ns: "page2" }))
                    return
                }
                this.setState({modalBorrarAbierto: true})
            }

            const seleccionPersona  = (value) => { 
                this.setState({personaSeleccionada: value})
            }

            const handleRadioChange = (event) => {
                this.setState({value: parseInt(event.currentTarget.value)})
            }
        
            const cerrarModal = () => {
                this.setState({
                    modalAbierto: false,
                    modalBorrarAbierto: false,
                    reloadTable: false

                })
            }
            
            const añadirNuevo = (value) => {
                
                if(!this.state.personaSeleccionada){
                    return
                }

                //comporbacion de que existe en la tabla.
                let existe=this.state.apoderados.find(apoderados=>{return apoderados.id===this.state.personaSeleccionada.value})
                if(existe){
                    alert(i18next.t("partesInteresadas.existingLegalRep", { ns: "page2" }))
                    return
                }

                this.setState({pendienteGuardar:true})
                this.setState({controlesDesactivados:true})

                this.state.apoderados.push({
                    "id":0,
                    "nombre": this.state.personaSeleccionada.label
                })

                
                cerrarModal()
            }

            const actualizarApoderado = () => {

                if(!this.state.personaSeleccionada && !this.state.apoderadoSeleccionado){
                    return
                }
    
    
                let apoderados=this.state.apoderados
                this.state.apoderados.find((apoderado,index) =>{
                    if(apoderado.id===this.state.apoderadoSeleccionado.id){
                        apoderados[index]["nombre"]=this.state.personaSeleccionada?this.state.personaSeleccionada.label:this.state.apoderadoSeleccionado.nombre
                    }
                    return apoderado.id===this.state.apoderadoSeleccionado.id
                })
                this.setState({pendienteActualizar:true})
                this.setState({controlesDesactivados:true})
                this.setState({apoderados:apoderados})

        
                cerrarModal()
            }    

            const eliminarApoderado = () => {
                let apoderados=this.state.apoderados.filter(apoderado => apoderado.id !== this.state.apoderadoSeleccionado.id)
                this.setState({apoderados:apoderados})
                this.setState({pendienteBorrar:true})     
                this.setState({controlesDesactivados:true})
                cerrarModal()
            };

            const guardarCambios=()=>{

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso", { ns: "global" }))
                    return
                }

                if(this.state.pendienteGuardar){
                
                    this.props.client.mutate({
                        mutation:gql`mutation{crearApoderado(VALORES:{ID_APODERADO:${this.state.personaSeleccionada.value},ID_MODALIDAD_DELEGACION:${this.state.value},CONTENIDO:${this.state.contenido?'"""'+this.state.contenido+'"""':null},LIMITES:${this.state.limites?'"""'+this.state.limites+'"""':null}}){ID_APODERADO}}`
                    }).then(async result=>{
                     
                        alert(i18next.t("insertcorrecto", { ns: "global" }))
                        await this.cargarApoderados()

                        this.setState({
                            pendienteGuardar:false,
                            controlesDesactivados: false,
                            apoderadoSeleccionado:false,
                            reloadTable: false

                        })
                    
                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })

                }else if(this.state.pendienteActualizar){
    
                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarApoderado(ID_APODERADO:${this.state.apoderadoSeleccionado.id},VALORES:{CONTENIDO:${this.state.contenido?'"""'+this.state.contenido+'"""':null},LIMITES:${this.state.limites?'"""'+this.state.limites+'"""':null},ID_MODALIDAD_DELEGACION:${this.state.value}}){ID_APODERADO}}`
                    }).then(async result=>{

                        alert(i18next.t("updatecorrecto", { ns: "global" }))

                        await this.cargarApoderados()
                        

                        this.setState({
                            controlesDesactivados:false,
                            pendienteActualizar:false,
                            apoderadoSeleccionado:false,
                            reloadTable: false

                        })
                        
                    
                        
                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })
    
    
                }else if(this.state.pendienteBorrar){
    
                    this.props.client.mutate({
                        mutation:gql`mutation{eliminarApoderado(ID_APODERADO:${this.state.apoderadoSeleccionado.id}){ID_APODERADO}}`
                    }).then(result=>{
                    
                        alert(i18next.t("deletecorrecto", { ns: "global" }))
                        this.cargarApoderados()
                        this.setState({
                            pendienteBorrar:false,
                            controlesDesactivados:false,
                            apoderadoSeleccionado:false,
                            reloadTable: false

                        })

                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })
                }
                 
            }

            const cancelarCambios=()=>{

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso", { ns: "global" }))
                    return
                }

                this.setState({
                    personaSeleccionada:null,
                    apoderadoSeleccionado:null,
                    pendienteGuardar:false,
                    pendienteActualizar:false,
                    pendienteBorrar:false,
                    controlesDesactivados: false,
                    reloadTable: false
                })
                this.cargarApoderados()
            }

            const cambiarlimites = (ev) => { 
                this.setState({limites: ev.currentTarget.value})
            }

            const cambiarcontenido = (ev) => { 
                this.setState({contenido: ev.currentTarget.value})
            }

            const abrirModalNuevaPersona=()=>{
    
                //abrir modal de persona física
                this.setState({
                    modalPersonaAbierto:true,
                    popoverAbierto:false,
                    nuevaPresonaActivado:true,
                    editarPersonaActivado:false,
                    personaSeleccionada:null
                })
    
            }
    
            const cerrarModal2 = () => {
                this.setState({
                    modalPersonaAbierto: false,
                })
            }

            
            const abrirModalEditarPersona=()=>{

                if(!this.state.personaSeleccionada){
                    alert(i18next.t("partesInteresadas.selectPerson", { ns: "page2" }))
                    return
                }

                this.setState({nuevaPresonaActivado:false})
                this.setState({editarPersonaActivado:true})
                //si es personas física
                this.setState({modalPersonaAbierto:true})
            }

            const crearPersona=async()=>{

                await this.datosPersona.crearPersona()
                if(this.datosPersona.state.idPersonaCreada){
                    await this.cargarPersonas()
                    //seleccionar empleado creado

                    let personaSel=await this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})

                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }
    
            const actualizarPersona=async ()=>{
    
                await this.datosPersona.actualizarPersona()
                if(this.datosPersona.state.idPersonaActualizada){
                    await this.cargarPersonas()
                    await this.cargarApoderados()

                    //seleccionar empleado creado
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }

            const cerrarModalImprimir= () => {
                this.setState({
                    modalImprimirA:false,
                    modalImprimirAG: false,
                    reloadTable: false

                })
        }
        
        const columns = [
            {
                dataField: 'nombre',
                text: i18next.t("name", { ns: "global" }),
            }
        ];

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                this.setState({
                    apoderadoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

    
            return (
                <>
                {this.state.cargandoForm===true?
                    <Cargando/>
                :
                <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.legalRepresentatives", { ns: "page2" })}`}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirAG}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirAG?
                                <div style={{ width: '100%', height: '500px'}} id='generarAG-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.legalRepresentative", { ns: "page2" })}`}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirA}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirA?
                                <div style={{ width: '100%', height: '500px'}} id='generarA-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("partesInteresadas.newLegalRep", { ns: "page2" }):i18next.t("partesInteresadas.updateLegalRep", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div className="pixelAlrededor bp3-card separarBordes">
                            <FormGroup
                                label={`${i18next.t("person", { ns: "global" })}:`}
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?i18next.t("partesInteresadas.selectLegalRep", { ns: "page2" }):""}
                            >
                                <div style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                        isDisabled={this.state.editarActivado}
                                        options={this.state.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersona}
                                        value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevaPresonaActivado} onClick={abrirModalNuevaPersona} />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                            </FormGroup>
                            <FormGroup
                            label={`${i18next.t("partesInteresadas.content", { ns: "page2" })}:`}
                            labelFor="contenido"
                        >
                            <TextArea maxlength='250' id="contenido" value={this.state.contenido} onChange={cambiarcontenido} fill={true} />
                        </FormGroup>
                        <FormGroup
                            label={`${i18next.t("partesInteresadas.bounds", { ns: "page2" })}`}
                            labelFor="limites"
                        >
                            <TextArea maxlength='250' id="limites" value={this.state.limites} onChange={cambiarlimites}  fill={true} />
                        </FormGroup>
                        <RadioGroup
                            label={i18next.t("partesInteresadas.delegationForm", { ns: "page2" })}
                            name="group"
                            onChange={handleRadioChange}
                            selectedValue={this.state.value}
                        >
                            <Radio label={i18next.t("partesInteresadas.permanent", { ns: "page2" })} value={1} />
                            <Radio label={i18next.t("partesInteresadas.agreement", { ns: "page2" })} value={2} />
                        </RadioGroup>
                
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?i18next.t("partesInteresadas.addLegalRep", { ns: "page2" }):i18next.t("partesInteresadas.editLegalRep", { ns: "page2" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:actualizarApoderado}
                                >
                                {this.state.nuevoActivado?i18next.t("partesInteresadas.addLegalRep", { ns: "page2" }):i18next.t("partesInteresadas.editLegalRep", { ns: "page2" })}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("partesInteresadas.deleteAcc", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("partesInteresadas.confirmDeleteLegalRep", { ns: "page2" })}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("partesInteresadas.deleteCredit", { ns: "page2" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarApoderado}
                                >
                                    {i18next.t("yes", { ns: "global" })}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("partesInteresadas.personProperties", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas i18next={i18next} datosPersona={this.state.personaSeleccionada?this.state.personaSeleccionada.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPresonaActivado?i18next.t("addPerson", { ns: "global" }):i18next.t("editPerson", { ns: "global" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                            >
                            {this.state.nuevaPresonaActivado?i18next.t("addPerson", { ns: "global" }):i18next.t("editPerson", { ns: "global" })} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                    <div className="pixelAlrededor tablaSesenta">
                    <Card>
                        <h4>{i18next.t("partesInteresadas.legalRepresentatives", { ns: "page2" })}</h4>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    {this.state.controlesDesactivados?[
                                        <React.Fragment>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} disabled />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} disabled/>
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} active={this.state.borrarActivado} disabled/>
                                            <NavbarDivider />
                                            <Button className="bp3-minimal" icon="print"  text={i18next.t("print", { ns: "global" })} disabled/>
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral", { ns: "global" })} disabled/>
                                        </React.Fragment>
                                    ]:[
                                        <React.Fragment>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                            <NavbarDivider />
                                            <Tooltip 
                                                content={i18next.t("partesInteresadas.printLegalRep", { ns: "page2" })} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    disabled={this.state.apoderadoSeleccionado?false:true}  
                                                    text={i18next.t("print", { ns: "global" })} onClick={()=>this.generarImprimirA()}
                                                />
                                            </Tooltip>  
                                            <Tooltip 
                                                content={i18next.t("partesInteresadas.printAllLegalRep", { ns: "page2" })} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    text={i18next.t("printgral", { ns: "global" })} 
                                                    onClick={()=>this.generarImprimirAG()}
                                                />
                                            </Tooltip>
                                            
                                        </React.Fragment>
                                    ]}
         
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        {this.state.reloadTable?
                            <Cargando/>
                            :
                            <BootstrapTable
                                keyField="id"
                                data={this.state.apoderados}
                                columns={columns}
                                selectRow={selectRow}
                                pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        }
                        <br />
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text={i18next.t("guardar", { ns: "global" })}
                            intent="danger"
                            disabled={!this.state.controlesDesactivados}
                            onClick={guardarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text={i18next.t("cancelar", { ns: "global" })}
                            disabled={!this.state.controlesDesactivados}
                            onClick={cancelarCambios}
                            className="iconoDerech bp3-minimal"
                        />
                        <br />
                        <br />
                    </Card>
                    </div>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("partesInteresadas.articleText", { ns: "page2" })}</Callout>
                            <br />
                            <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/rd/1885/08/22/(1)", "_blank")}>{i18next.t("partesInteresadas.articleButton", { ns: "page2" })}</AnchorButton>
                            <br />
                        </Card>
                    </div>
                </>
                }
                </>
            )   
    }
}
export default withTranslation()(withApollo(Apoderados))