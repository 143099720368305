import { StyleSheet, Font } from '@react-pdf/renderer';

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

export default StyleSheet.create({
    dialog:{
        width: '65%'    
    },
    dialogL:{
        width: '80%'    
    },
    tableColHead:{
      textAlign:'center',
      flexDirection: 'column', 
      borderRightWidth: 1, 
      fontSize:8, 
      fontFamily: 'Open Sans Bold', 
      borderRightStyle: 'solid', 
      borderRightColor: '#000000'
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  imageHeadL: {
    maxWidth: 80,
    maxHeight: 80,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 10,
    marginTop:-1
  },
  labelRegularFullWidth: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans',
    width: '100%'
  },
  labelBoldFullWidth: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    width: '100%'
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    width: '30%'
  },
  textData: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '60%'
  },
  labelDataST: {
    flexDirection: 'row',
    marginRight: 10,
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    width: '50%',
    textDecoration:'underline'
  },
  labelDataXL: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    width: '40%',
  },
  labelDataXXL: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    width: '50%',
  },
  rowWhiteSpacing:{
    width: '100%',
    marginBottom: 15,
  },
  rowSpace:{
    flexDirection: 'row',
    height:20
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  rowFullWidthMarginBtm: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
  textDataAreas: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: 250
  },
  textDataDepto: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    marginLeft:80,
    flexWrap: 'wrap',
    width: 250
  },
  textDataDeptoO: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '95%'
  },
  textDataEmp: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '95%'
  },
  rowTitleCompliance: {
    flexDirection:'row',
     width:'100%',
    marginBottom:10,
    marginTop: 20
  },
  labelTitleCompliance: {
     fontSize: 9,
     fontFamily: 'Open Sans Bold',
     textAlign:'left'
  },
  textCompliance: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    textAlign:'left'
  },
  rowCompliance: {
    flexDirection:'row',
     width:'100%',
    marginBottom:10,
  },
  rowFooterCompliance:{
    flexDirection:'row',
     width:'100%',
    marginTop:30,
  },
  textFooterCompliance: {
    fontSize: 8,
    fontFamily: 'Open Sans Bold',
    textAlign:'right'
  },
  textContrato:{
    fontSize: 8,
    fontFamily: 'Open Sans Bold',
    textAlign:'left'
  },
  rowTitleComplianceCC:{
    flexDirection:'row',
     width:'100%',
    marginTop: 10
  },
  rowContrato:{ 
    flexDirection:'row',
    width:'100%',
   marginTop: 5
  },
  labelFirmaLeft: {
    flexDirection: 'column',
    width: '50%',
    fontSize:8,
    textAlign:'left',
    fontFamily: 'Open Sans Bold'
  },
  labelFirmaRight: {
    flexDirection: 'column',
    width: '50%',
    fontSize:8,
    textAlign:'right',
    fontFamily: 'Open Sans Bold'
  },
  labelDataA: {
    flexDirection: 'column',
    width: '20%',
    fontSize:8,
    fontFamily: 'Open Sans Bold'
  },
  valueDataA: {
    flexDirection: 'column',
    width: '80%',
    fontSize:8,
    fontFamily: 'Open Sans'
  },
  labelDataD: {
    flexDirection: 'column',
    width: '35%',
    fontSize:8,
    fontFamily: 'Open Sans Bold'
  },
  valueDataD: {
    flexDirection: 'column',
    width: '65%',
    fontSize:8,
    fontFamily: 'Open Sans'
  },
  labelA: {
    flexDirection: 'row',
    width: '40%',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  labelD: {
    flexDirection: 'row',
    width: '60%'
  },
  rowHeadGral: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:5
  },
  rowDown:{
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
  },
  labelDataT:{
    flexDirection: 'column',
    width: '80%',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    textAlign:'right',
    borderRight: 1,
    borderColor: '#0000',
    boderStyle: 'solid',
    paddingRight: '2px'
  },
  valueDataT:{
    flexDirection: 'column',
    width: '20%',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  viewArea: {
    flexDirection: 'row',
    width: '100%'
    
  },
  viewDpto: {
    flexDirection: 'row',
    marginLeft:80,
    width: '100%'
    
  },
  viewEmp: {
    flexDirection: 'row',
    width: '100%',
    marginLeft:120,
  },
  iconoArea: {
    flexDirection: 'column',
    width: 10,
    height:10,
    marginTop:2,
    marginRight:5
  },
  iconoDpto: {
    flexDirection: 'column',
    width: 10,
    height:10,
    marginTop:2,
    marginRight:5
  },
  iconoEmpl: {
    flexDirection: 'column',
    width: 10,
    height:10,
    marginTop:2,
    marginRight:5
  },
  textDataDeptoB: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    paddingLeft:20,
    flexWrap: 'wrap',
    width: '100%'
  },
  textDataDepto2: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    paddingLeft:40,
    flexWrap: 'wrap',
    width: '100%'
  },
  textDataDepto2B: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    paddingLeft:40,
    flexWrap: 'wrap',
    width: '100%'
  },
  textDataDepto3: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    paddingLeft:60,
    flexWrap: 'wrap',
    width: '100%'
  },
  textDataDepto3B: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    paddingLeft:60,
    flexWrap: 'wrap',
    width: '100%'
  },
  rowHeadNoneBorder: {
    flexDirection: 'row',
    width: '100%',
    marginTop:2
  },
  labelHeadNoneBorder: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    marginRight:1
  },
  rowTitleNoneBorder75: {
    width: '75%',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  rowTitleNoneBorder25: {
    flexDirection: 'column',
    width: '25%' 
  },
  labelTitleCenter: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center',
    alignContent: 'center'
  },
  rowTitleNoneBorder60: {
    flexDirection: 'row',
    width: '60%'
  },
  rowTitleNoneBorder40: {
    flexDirection: 'column',
    width: '40%' 
  },
  rowLineSpacing:{
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    borderTopWidth: 1, 
    borderTopStyle: 'solid', 
    borderTopColor: '#000000'
  },
  rowTitleNoneBorder50: {
    flexDirection: 'column',
    width: '50%' 
  },
  textWrap: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans',
    flexWrap: 'wrap',
    width: '90%',
    backgroundColor:'white',
    color:'black'
  },
  rowTitleNoneBorder100: {
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  rowTitleNoneBorderLeft100: {
    width: '100%',
    textAlign: 'start',
    flexWrap: 'wrap'
  },
  labelTitleLeft: {
    flexDirection: 'column',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    textAlign: 'left'
  },
  rowTitleNoneBorder100Left: {
    width: '100%',
    justifyContent: 'left',
    alignContent: 'left',
    textAlign: 'left',
    flexWrap: 'wrap'
  },
  rowTitleNoneBorder75Callout: {
    width: '75%',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    backgroundColor: '#ffd2d2',
    margin:'2px'
  },
  rowTitleNoneBorder100Callout: {
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    backgroundColor: '#ffd2d2',
    marginTop:'2px',
    padding:'5px'
  },
  rowHeadNoneBorderCallout: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#ffd2d2',
    padding:'5px'
  },
  textWrapCallout: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans Bold',
    flexWrap: 'wrap',
    width: '90%'
  },
  textWrapGray: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans',
    flexWrap: 'wrap',
    width: '90%',
    backgroundColor: '#bfccd6',
    color:'black',
    paddingLeft:'5px',
    paddingRight:'5px'
  },
  textWrapGreen: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans',
    flexWrap: 'wrap',
    backgroundColor: 'forestgreen',
    color:'white',
    paddingLeft:'5px',
    paddingRight:'5px'
  },
  textWrapYellow: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans',
    flexWrap: 'wrap',
    backgroundColor: 'yellow',
    color:'black',
    paddingLeft:'5px',
    paddingRight:'5px'
  },
  textWrapOrange: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans',
    flexWrap: 'wrap',
    backgroundColor: 'orangered',
    color:'white',
    paddingLeft:'5px',
    paddingRight:'5px'
  },
  textWrapRed: {
    flexDirection: 'row',
    fontSize:8,
    fontFamily: 'Open Sans',
    flexWrap: 'wrap',
    backgroundColor: '#db3737',
    color:'white',
    paddingLeft:'5px',
    paddingRight:'5px'
  },
  rowTitleNoneBorder75CalloutGreen: {
    width: '75%',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'lightgreen',
    margin:'2px'
  }
  
 
});