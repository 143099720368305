import React from "react";
import { Card, Elevation, Callout, Navbar, ButtonGroup, Button, Alignment, Dialog, FormGroup, FileInput, Classes, Tooltip, TextArea } from "@blueprintjs/core"
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import { Cargando } from "./Cargando";
import * as XLSX from 'xlsx';
import moment from 'moment';
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
let client = new ApolloClient({
  uri: process.env.REACT_APP_DOMINIO+"/api",
  credentials: 'include',
});

class ImportarTrabajadores extends React.Component
{
    state = {
        modalAbierto: false,
        cargando: false,
        registros:[],
        importado:false,
        respuesta:''
    }

    render()
    {
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("importartrabajadores.title",{ns:"page"})

        const cerrarModal=()=>{
            this.setState({
                modalAbierto:false,
                cargando:false
            })
        }

        const abrirModalNuevo = () => {
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            this.setState({
                modalAbierto: true,
                registros:[],
                cargando:true,
                importado:false,
                respuesta:''
            })

        }

        const validarFormato =async (data) => {
            if (data.length > 0) {
                let hasnif=false;
                let hasidtipocontrato=false;

                for (let key in data[0]) {
                    if (key === 'NIF') {
                        hasnif=true;
                    }
                    if (key === 'ID_TIPO_CONTRATO') {
                        hasidtipocontrato=true;
                    }
                }
                if (!hasnif) {
                    alert(i18next.t("importartrabajadores.formatoincorrecto",{ns:"page"}));
                    return false;
                }
                if (!hasidtipocontrato) {
                    alert(i18next.t("importartrabajadores.tipocontratoempty",{ns:"page"}));
                    return false;
                }
                this.setState({
                    registros: data,
                    importado:true
                },()=>{
                    cerrarModal();
                    return true;
                });
            } else {
                alert(i18next.t("importartrabajadores.noprocesado",{ns:"page"}));
                return false;
            }
        }

        const archivoSubido=(ev)=>{           

            const file = ev.target.files[0];
            const reader = new FileReader();
            reader.onload =async (ev) => {
                const binaryStr = ev.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                
                // Suponiendo que el archivo solo tiene una hoja
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
          
                // Convierte la hoja de Excel a un arreglo de objetos
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                    raw: false, // Si lo pones en `false`, xlsx intentará detectar fechas automáticamente
                });

                await validarFormato(jsonData);
                
            };

            reader.readAsBinaryString(file);
          
        }

        const crearEmpleado=async (id_persona,persona)=> {
            return await client.mutate({
                mutation:gql`mutation{crearEmpleado(VALORES:{
                    ID_EMPLEADO:${id_persona},
                    CODIGO_EMPLEADO:"${id_persona}",
                    FECHA_ALTA:"${moment(persona.FECHA_ALTA).format('YYYY-MM-DD')}",
                    FECHA_BAJA:${persona.FECHA_BAJA?'"'+moment(persona.FECHA_BAJA).format('YYYY-MM-DD')+'"':null},
                    FECHA_INGRESO_INICIAL:${persona.FECHA_INGRESO_INICIAL?'"'+moment(persona.FECHA_INGRESO_INICIAL).format('YYYY-MM-DD')+'"':null},
                    FECHA_ANTIGUEDAD:${persona.FECHA_ANTIGUEDAD?'"'+moment(persona.FECHA_ANTIGUEDAD).format('YYYY-MM-DD')+'"':null},
                    ID_TIPO_CONTRATO:${persona.ID_TIPO_CONTRATO?persona.ID_TIPO_CONTRATO:null},
                    PARCIALIDAD:${persona.PARCIALIDAD?'"'+persona.PARCIALIDAD+'"':null},
                    CATEGORIA:${persona.CATEGORIA?'"'+persona.CATEGORIA+'"':null},
                    RETRIBUCION:${persona.RETRIBUCION?'"'+persona.RETRIBUCION+'"':null},
                    RETRIBUCION_VOLUNTARIA:${persona.RETRIBUCION_VOLUNTARIA?'"'+persona.RETRIBUCION_VOLUNTARIA+'"':null},
                    PORCENTAJE_VOLUNTARIA:${persona.PORCENTAJE_VOLUNTARIA?'"'+persona.PORCENTAJE_VOLUNTARIA+'"':null},
                    RETRIBUCION_VARIABLES:${persona.RETRIBUCION_VARIABLES?'"'+persona.RETRIBUCION_VARIABLES+'"':null},
                    PORCENTAJE_VARIABLES:${persona.PORCENTAJE_VARIABLES?'"'+persona.PORCENTAJE_VARIABLES+'"':null},
                    UBICACION:${persona.UBICACION?'"'+persona.UBICACION+'"':null},
                    CLAVE_CONTRATO:${persona.CLAVE_CONTRATO?'"'+persona.CLAVE_CONTRATO+'"':null},
                    GRUPO_COTIZACION:${persona.GRUPO_COTIZACION?'"'+persona.GRUPO_COTIZACION+'"':null},
                    TEMPORALIDAD:${persona.TEMPORALIDAD?'"'+persona.TEMPORALIDAD+'"':null},
                    FORMACION_ACADEMICA:${persona.FORMACION_ACADEMICA?'"'+persona.FORMACION_ACADEMICA+'"':null},
                    OTRAS_TITULACIONES:${persona.OTRAS_TITULACIONES?'"'+persona.OTRAS_TITULACIONES+'"':null},
                    AREA_CONOCIMIENTOS:${persona.AREA_CONOCIMIENTOS?'"'+persona.AREA_CONOCIMIENTOS+'"':null},
                    CONOCIMIENTOS_ESPECIFICOS:${persona.CONOCIMIENTOS_ESPECIFICOS?'"'+persona.CONOCIMIENTOS_ESPECIFICOS+'"':null},
                    HABILIDADES:${persona.HABILIDADES?'"'+persona.HABILIDADES+'"':null},
                    APTITUDES:${persona.APTITUDES?'"'+persona.APTITUDES+'"':null},
                    ACTITUDES:${persona.ACTITUDES?'"'+persona.ACTITUDES+'"':null},
                    ESPECIALIDADES:${persona.ESPECIALIDADES?'"""'+persona.ESPECIALIDADES+'"""':null},
                    EXPERIENCIA:${persona.EXPERIENCIA?'"""'+persona.EXPERIENCIA+'"""':null},
                    EXPERIENCIA_COINCIDENTE:${persona.EXPERIENCIA_COINCIDENTE?'"""'+persona.EXPERIENCIA_COINCIDENTE+'"""':null},
                    SITUACION_FAMILIAR_IRPF:${persona.SITUACION_FAMILIAR_IRPF?'"'+persona.SITUACION_FAMILIAR_IRPF+'"':null},
                    NIF_CONYUGE:${persona.NIF_CONYUGE?'"'+persona.NIF_CONYUGE+'"':null},
                    GRADO_MINUSVALIA:${persona.GRADO_MINUSVALIA?'"'+persona.GRADO_MINUSVALIA+'"':null},
                    MOVILIDAD_REDUCIDA:${persona.MOVILIDAD_REDUCIDA?'"'+persona.MOVILIDAD_REDUCIDA+'"':null},
                    OBSERVACIONES_RECONOCIMIENTO:${persona.OBSERVACIONES_RECONOCIMIENTO?'"""'+persona.OBSERVACIONES_RECONOCIMIENTO+'"""':null},
                    OTROS_DATOS_MEDICOS:${persona.OTROS_DATOS_MEDICOS?'"""'+persona.OTROS_DATOS_MEDICOS+'"""':null},
                    IDIOMAS:${persona.IDIOMAS?'"'+persona.IDIOMAS+'"':null},
                    CURSOS:${persona.CURSOS?'"'+persona.CURSOS+'"':null}

                }){ID_EMPLEADO}}`
            }).then(async result=>{
                return "INSERT PERSON NIF:"+persona.NIF+" - RESULT:SUCCESS\n";
            }).catch(err=>{
                console.log(err);
                return "INSERT PERSON NIF:"+persona.NIF+" - RESULT:FAILED\n";
            })
        }

        const crearDocumentoIdentificativo=async (id_persona,persona) => {
            return await client.mutate({
                mutation: gql`
                    mutation{crearDocumentoIdentificativo(VALORES:{
                        ID_PERSONA:${id_persona},
                        ID_TIPO_DOCUMENTO:${1},
                        VALOR:"${persona.NIF}",
                        FECHA_EMISION:${null},
                        FECHA_CADUCIDAD:${null}
                    }){ID_DOCUMENTO_IDENTIFICATIVO}}`
            }).then(async ()=>{
                return crearEmpleado(id_persona,persona);
            }).catch(err=>{
                console.log(err);
                return "INSERT PERSON NIF:"+persona.NIF+" - RESULT:FAILED\n";
            });
        }

        const crearNuevaPersonaFisica= async (id_persona,persona) => {
            const fullname=`${persona.APELLIDO1}${persona.APELLIDO2?' '+persona.APELLIDO2+'':''}, ${persona.NOMBRE}`;
            return await client.mutate({
                mutation:gql`mutation{crearPersonaFisica(VALORES:{
                    ID_PERSONA:${id_persona},
                    NOMBRE:"${persona.NOMBRE}",
                    APELLIDO1:"${persona.APELLIDO1}",
                    APELLIDO2:${persona.APELLIDO2?'"'+persona.APELLIDO2+'"':null},
                    FECHA_NACIMIENTO:${persona.FECHA_NACIMIENTO?'"'+moment(persona.FECHA_NACIMIENTO).format('YYYY-MM-DD')+'"':null},
                    ID_LENGUA_MATERNA:${persona.ID_LENGUA_MATERNA?persona.ID_LENGUA_MATERNA:null},
                    EDAD:${persona.EDAD?'"'+persona.EDAD+'"':null},
                    GRAVAMENES:${persona.GRAVAMENES?'"'+persona.GRAVAMENES+'"':null},
                    DOMICILIO:${persona.DOMICILIO?'"'+persona.DOMICILIO+'"':null},
                    LOCALIDAD:${persona.LOCALIDAD?'"'+persona.LOCALIDAD+'"':null},
                    FECHA_ALTA:${persona.FECHA_ALTA?'"'+moment(persona.FECHA_ALTA).format("YYYY-MM-DD")+'"':null},
                    FECHA_BAJA:${persona.FECHA_BAJA?'"'+moment(persona.FECHA_BAJA).format("YYYY-MM-DD")+'"':null},
                    GENERO:${persona.GENERO?'"'+persona.GENERO+'"':null},
                    ID_NACIONALIDAD:${persona.ID_NACIONALIDAD?persona.ID_NACIONALIDAD:null},
                    OBSERVACIONES:${persona.OBSERVACIONES?'"""'+persona.OBSERVACIONES+'"""':null},
                    FULLNAME:"${fullname}"
                }){ID_PERSONA}}`
            }).then(async result=>{
                return crearDocumentoIdentificativo(result.data.crearPersonaFisica.ID_PERSONA,persona);
            }).catch(err=>{
                console.log(err);
                return "INSERT PERSON NIF:"+persona.NIF+" - RESULT:FAILED\n";
            })
        }

        const crearNuevaPersona=async (persona) => {
            return await client.mutate({
                mutation:gql`mutation{crearPersona(VALORES:{ID_TIPO_PERSONA:1}){ID_PERSONA}}`
            }).then(result=>{
                return crearNuevaPersonaFisica(result.data.crearPersona.ID_PERSONA,persona);
                
            }).catch(err=>{
                console.log(err);
                return "INSERT PERSON NIF:"+persona.NIF+" - RESULT:FAILED\n";
            })
        }

        const actualizarEmpleado=async (id_persona,persona)=>{
            return await client.mutate({
                mutation:gql`mutation{actualizarEmpleado(ID_EMPLEADO:${id_persona},VALORES:{
                    CODIGO_EMPLEADO:"${id_persona}",
                    FECHA_ALTA:"${moment(persona.FECHA_ALTA).format('YYYY-MM-DD')}",
                    FECHA_BAJA:${persona.FECHA_BAJA?'"'+moment(persona.FECHA_BAJA).format('YYYY-MM-DD')+'"':null},
                    FECHA_INGRESO_INICIAL:${persona.FECHA_INGRESO_INICIAL?'"'+moment(persona.FECHA_INGRESO_INICIAL).format('YYYY-MM-DD')+'"':null},
                    FECHA_ANTIGUEDAD:${persona.FECHA_ANTIGUEDAD?'"'+moment(persona.FECHA_ANTIGUEDAD).format('YYYY-MM-DD')+'"':null},
                    ID_TIPO_CONTRATO:${persona.ID_TIPO_CONTRATO?persona.ID_TIPO_CONTRATO:null},
                    PARCIALIDAD:${persona.PARCIALIDAD?'"'+persona.PARCIALIDAD+'"':null},
                    CATEGORIA:${persona.CATEGORIA?'"'+persona.CATEGORIA+'"':null},
                    RETRIBUCION:${persona.RETRIBUCION?'"'+persona.RETRIBUCION+'"':null},
                    RETRIBUCION_VOLUNTARIA:${persona.RETRIBUCION_VOLUNTARIA?'"'+persona.RETRIBUCION_VOLUNTARIA+'"':null},
                    PORCENTAJE_VOLUNTARIA:${persona.PORCENTAJE_VOLUNTARIA?'"'+persona.PORCENTAJE_VOLUNTARIA+'"':null},
                    RETRIBUCION_VARIABLES:${persona.RETRIBUCION_VARIABLES?'"'+persona.RETRIBUCION_VARIABLES+'"':null},
                    PORCENTAJE_VARIABLES:${persona.PORCENTAJE_VARIABLES?'"'+persona.PORCENTAJE_VARIABLES+'"':null},
                    UBICACION:${persona.UBICACION?'"'+persona.UBICACION+'"':null},
                    CLAVE_CONTRATO:${persona.CLAVE_CONTRATO?'"'+persona.CLAVE_CONTRATO+'"':null},
                    GRUPO_COTIZACION:${persona.GRUPO_COTIZACION?'"'+persona.GRUPO_COTIZACION+'"':null},
                    TEMPORALIDAD:${persona.TEMPORALIDAD?'"'+persona.TEMPORALIDAD+'"':null},
                    FORMACION_ACADEMICA:${persona.FORMACION_ACADEMICA?'"'+persona.FORMACION_ACADEMICA+'"':null},
                    OTRAS_TITULACIONES:${persona.OTRAS_TITULACIONES?'"'+persona.OTRAS_TITULACIONES+'"':null},
                    AREA_CONOCIMIENTOS:${persona.AREA_CONOCIMIENTOS?'"'+persona.AREA_CONOCIMIENTOS+'"':null},
                    CONOCIMIENTOS_ESPECIFICOS:${persona.CONOCIMIENTOS_ESPECIFICOS?'"'+persona.CONOCIMIENTOS_ESPECIFICOS+'"':null},
                    HABILIDADES:${persona.HABILIDADES?'"'+persona.HABILIDADES+'"':null},
                    APTITUDES:${persona.APTITUDES?'"'+persona.APTITUDES+'"':null},
                    ACTITUDES:${persona.ACTITUDES?'"'+persona.ACTITUDES+'"':null},
                    ESPECIALIDADES:${persona.ESPECIALIDADES?'"""'+persona.ESPECIALIDADES+'"""':null},
                    EXPERIENCIA:${persona.EXPERIENCIA?'"""'+persona.EXPERIENCIA+'"""':null},
                    EXPERIENCIA_COINCIDENTE:${persona.EXPERIENCIA_COINCIDENTE?'"""'+persona.EXPERIENCIA_COINCIDENTE+'"""':null},
                    SITUACION_FAMILIAR_IRPF:${persona.SITUACION_FAMILIAR_IRPF?'"'+persona.SITUACION_FAMILIAR_IRPF+'"':null},
                    NIF_CONYUGE:${persona.NIF_CONYUGE?'"'+persona.NIF_CONYUGE+'"':null},
                    GRADO_MINUSVALIA:${persona.GRADO_MINUSVALIA?'"'+persona.GRADO_MINUSVALIA+'"':null},
                    MOVILIDAD_REDUCIDA:${persona.MOVILIDAD_REDUCIDA?'"'+persona.MOVILIDAD_REDUCIDA+'"':null},
                    OBSERVACIONES_RECONOCIMIENTO:${persona.OBSERVACIONES_RECONOCIMIENTO?'"""'+persona.OBSERVACIONES_RECONOCIMIENTO+'"""':null},
                    OTROS_DATOS_MEDICOS:${persona.OTROS_DATOS_MEDICOS?'"""'+persona.OTROS_DATOS_MEDICOS+'"""':null},
                    IDIOMAS:${persona.IDIOMAS?'"'+persona.IDIOMAS+'"':null},
                    CURSOS:${persona.CURSOS?'"'+persona.CURSOS+'"':null}

                }){ID_EMPLEADO}}`
            }).then(async result=>{
                return "UPDATE PERSON NIF:"+persona.NIF+" - RESULT:SUCCESS\n";
            }).catch(err=>{
                console.log(err);
                return "UPDATE PERSON NIF:"+persona.NIF+" - RESULT:FAILED\n";
            })
            
        }

        const actualizarPersonaFisica=async (id_persona,persona) => {
            const fullname=`${persona.APELLIDO1}${persona.APELLIDO2?' '+persona.APELLIDO2+'':''}, ${persona.NOMBRE}`;
            return await client.mutate({
                mutation:gql`mutation{actualizarPersonasFisicas(ID_PERSONA:${id_persona},VALORES:{
                    NOMBRE:"${persona.NOMBRE}",
                    APELLIDO1:"${persona.APELLIDO1}",
                    APELLIDO2:${persona.APELLIDO2?'"'+persona.APELLIDO2+'"':null},
                    FECHA_NACIMIENTO:${persona.FECHA_NACIMIENTO?'"'+moment(persona.FECHA_NACIMIENTO).format('YYYY-MM-DD')+'"':null},
                    ID_LENGUA_MATERNA:${persona.ID_LENGUA_MATERNA?persona.ID_LENGUA_MATERNA:null},
                    EDAD:${persona.EDAD?'"'+persona.EDAD+'"':null},
                    GRAVAMENES:${persona.GRAVAMENES?'"'+persona.GRAVAMENES+'"':null},
                    DOMICILIO:${persona.DOMICILIO?'"'+persona.DOMICILIO+'"':null},
                    LOCALIDAD:${persona.LOCALIDAD?'"'+persona.LOCALIDAD+'"':null},
                    FECHA_ALTA:${persona.FECHA_ALTA?'"'+moment(persona.FECHA_ALTA).format("YYYY-MM-DD")+'"':null},
                    FECHA_BAJA:${persona.FECHA_BAJA?'"'+moment(persona.FECHA_BAJA).format("YYYY-MM-DD")+'"':null},
                    GENERO:${persona.GENERO?'"'+persona.GENERO+'"':null},
                    ID_NACIONALIDAD:${persona.ID_NACIONALIDAD?persona.ID_NACIONALIDAD:null},
                    OBSERVACIONES:${persona.OBSERVACIONES?'"""'+persona.OBSERVACIONES+'"""':null},
                    FULLNAME:"${fullname}"                
                }){ID_PERSONA}}`
            }).then(async ()=>{
                return actualizarEmpleado(id_persona,persona);
            }).catch(err=>{
                console.log(err);
                return "UPDATE PERSON NIF:"+persona.NIF+" - RESULT:FAILED\n";
            })
        }

        const procesarPersona=async (persona)=>{
            return await client.query({
                query:gql`{documentosIdentificativos(FILTROS:{VALOR:"${persona.NIF}"}){ID_PERSONA}}`,
                fetchPolicy: 'network-only'

            }).then(async result=>{

                if(result.data.documentosIdentificativos.length>0) {
                    const id_persona=result.data.documentosIdentificativos[0].ID_PERSONA;
                    return await actualizarPersonaFisica(id_persona,persona);
                    
                } else {
                    return await crearNuevaPersona(persona);
                }
            }).catch(err=>{
                console.log(err);
                return "ERROR FIND NIF:"+persona.NIF+"\n";
            });
            
        }
        const isEmpty=(str)=> {
            return (!str || str.length === 0 || str.trim() === "");
        }

        const guardarCambios=async ()=>{
            let result='';
            this.setState({cargando:true,respuesta:''});
            if (this.state.registros.length>0) {
                for (const persona of this.state.registros) {
                    result='';
                    if (isEmpty(persona.NIF) || isEmpty(persona.ID_TIPO_CONTRATO)) {
                        if (isEmpty(persona.NIF)) {
                            result="PERSON NIF EMPTY - RESULT:FAILED\n";
                            this.setState({respuesta:this.state.respuesta+result});
                        }
                        if (isEmpty(persona.ID_TIPO_CONTRATO)) {
                            result="PERSON NIF:"+persona.NIF+" ID TIPO CONTRATO EMPTY - RESULT:FAILED\n";
                            this.setState({respuesta:this.state.respuesta+result});
                        }
                    } else {
                        result= await procesarPersona(persona);
                        this.setState({respuesta:this.state.respuesta+result});
                    }
                }
                this.setState({cargando:false});
            }
            
        }

        const columns = [
        {
            dataField: 'NIF',
            text: i18next.t("importartrabajadores.NIF",{ns:"page"})
        },
        {
            dataField: 'NOMBRE',
            text: i18next.t("importartrabajadores.NOMBRE",{ns:"page"})
        },
        {
            dataField: 'APELLIDO1',
            text: i18next.t("importartrabajadores.APELLIDO1",{ns:"page"})
        },
        {
            dataField: 'APELLIDO2',
            text: i18next.t("importartrabajadores.APELLIDO2",{ns:"page"})
        },
        {
            dataField: 'FECHA_NACIMIENTO',
            text: i18next.t("importartrabajadores.FECHA_NACIMIENTO",{ns:"page"})
        },
        {
            dataField: 'ID_LENGUA_MATERNA',
            text: i18next.t("importartrabajadores.ID_LENGUA_MATERNA",{ns:"page"})
        },
        {
            dataField: 'GRAVAMENES',
            text: i18next.t("importartrabajadores.GRAVAMENES",{ns:"page"})
        },
        {
            dataField: 'DOMICILIO',
            text: i18next.t("importartrabajadores.DOMICILIO",{ns:"page"})
        },
        {
            dataField: 'LOCALIDAD',
            text: i18next.t("importartrabajadores.LOCALIDAD",{ns:"page"})
        },
        {
            dataField: 'FECHA_ALTA',
            text: i18next.t("importartrabajadores.FECHA_ALTA",{ns:"page"})
        },
        {
            dataField: 'FECHA_BAJA',
            text: i18next.t("importartrabajadores.FECHA_BAJA",{ns:"page"})
        },
        {
            dataField: 'ID_NACIONALIDAD',
            text: i18next.t("importartrabajadores.ID_NACIONALIDAD",{ns:"page"})
        },
        {
            dataField: 'OBSERVACIONES',
            text: i18next.t("importartrabajadores.OBSERVACIONES",{ns:"page"})
        },
        {
            dataField: 'EDAD',
            text: i18next.t("importartrabajadores.EDAD",{ns:"page"})
        },
        {
            dataField: 'GENERO',
            text: i18next.t("importartrabajadores.GENERO",{ns:"page"})
        },
        {
            dataField: 'FECHA_INGRESO_INICIAL',
            text: i18next.t("importartrabajadores.FECHA_INGRESO_INICIAL",{ns:"page"})
        },
        {
            dataField: 'FECHA_ANTIGUEDAD',
            text: i18next.t("importartrabajadores.FECHA_ANTIGUEDAD",{ns:"page"})
        },
        {
            dataField: 'ID_TIPO_CONTRATO',
            text: i18next.t("importartrabajadores.ID_TIPO_CONTRATO",{ns:"page"})
        },
        {
            dataField: 'PARCIALIDAD',
            text: i18next.t("importartrabajadores.PARCIALIDAD",{ns:"page"})
        },
        {
            dataField: 'CATEGORIA',
            text: i18next.t("importartrabajadores.CATEGORIA",{ns:"page"})
        },
        {
            dataField: 'RETRIBUCION',
            text: i18next.t("importartrabajadores.RETRIBUCION",{ns:"page"})
        },
        {
            dataField: 'RETRIBUCION_VOLUNTARIA',
            text: i18next.t("importartrabajadores.RETRIBUCION_VOLUNTARIA",{ns:"page"})
        },
        {
            dataField: 'PORCENTAJE_VOLUNTARIA',
            text: i18next.t("importartrabajadores.PORCENTAJE_VOLUNTARIA",{ns:"page"})
        },
        {
            dataField: 'RETRIBUCION_VARIABLES',
            text: i18next.t("importartrabajadores.RETRIBUCION_VARIABLES",{ns:"page"})
        },
        {
            dataField: 'PORCENTAJE_VARIABLES',
            text: i18next.t("importartrabajadores.PORCENTAJE_VARIABLES",{ns:"page"})
        },
        {
            dataField: 'UBICACION',
            text: i18next.t("importartrabajadores.UBICACION",{ns:"page"})
        },
        {
            dataField: 'TEMPORALIDAD',
            text: i18next.t("importartrabajadores.TEMPORALIDAD",{ns:"page"})
        },
        {
            dataField: 'FORMACION_ACADEMICA',
            text: i18next.t("importartrabajadores.FORMACION_ACADEMICA",{ns:"page"})
        },
        {
            dataField: 'OTRAS_TITULACIONES',
            text: i18next.t("importartrabajadores.OTRAS_TITULACIONES",{ns:"page"})
        },
        {
            dataField: 'ESPECIALIDADES',
            text: i18next.t("importartrabajadores.ESPECIALIDADES",{ns:"page"})
        },
        {
            dataField: 'AREA_CONOCIMIENTOS',
            text: i18next.t("importartrabajadores.AREA_CONOCIMIENTOS",{ns:"page"})
        },
        {
            dataField: 'CONOCIMIENTOS_ESPECIFICOS',
            text: i18next.t("importartrabajadores.CONOCIMIENTOS_ESPECIFICOS",{ns:"page"})
        },
        {
            dataField: 'HABILIDADES',
            text: i18next.t("importartrabajadores.HABILIDADES",{ns:"page"})
        },
        {
            dataField: 'APTITUDES',
            text: i18next.t("importartrabajadores.APTITUDES",{ns:"page"})
        },
        {
            dataField: 'ACTITUDES',
            text: i18next.t("importartrabajadores.ACTITUDES",{ns:"page"})
        },
        {
            dataField: 'EXPERIENCIA',
            text: i18next.t("importartrabajadores.EXPERIENCIA",{ns:"page"})
        },
        {
            dataField: 'EXPERIENCIA_COINCIDENTE',
            text: i18next.t("importartrabajadores.EXPERIENCIA_COINCIDENTE",{ns:"page"})
        },
        {
            dataField: 'IDIOMAS',
            text: i18next.t("importartrabajadores.IDIOMAS",{ns:"page"})
        },
        {
            dataField: 'CURSOS',
            text: i18next.t("importartrabajadores.CURSOS",{ns:"page"})
        },
        {
            dataField: 'SITUACION_FAMILIAR_IRPF',
            text: i18next.t("importartrabajadores.SITUACION_FAMILIAR_IRPF",{ns:"page"})
        },
        {
            dataField: 'NIF_CONYUGE',
            text: i18next.t("importartrabajadores.NIF_CONYUGE",{ns:"page"})
        },
        {
            dataField: 'GRADO_MINUSVALIA',
            text: i18next.t("importartrabajadores.GRADO_MINUSVALIA",{ns:"page"})
        },
        {
            dataField: 'MOVILIDAD_REDUCIDA',
            text: i18next.t("importartrabajadores.MOVILIDAD_REDUCIDA",{ns:"page"})
        },
{
            dataField: 'OBSERVACIONES_RECONOCIMIENTO',
            text: i18next.t("importartrabajadores.OBSERVACIONES_RECONOCIMIENTO",{ns:"page"})
        },
        {
            dataField: 'OTROS_DATOS_MEDICOS',
            text: i18next.t("importartrabajadores.OTROS_DATOS_MEDICOS",{ns:"page"})
        },
        {
            dataField: 'CLAVE_CONTRATO',
            text: i18next.t("importartrabajadores.CLAVE_CONTRATO",{ns:"page"})
        },
        {
            dataField: 'GRUPO_COTIZACION',
            text: i18next.t("importartrabajadores.GRUPO_COTIZACION",{ns:"page"})
        },

        ];

        const dataWithKeys = this.state.registros.map((row,i) => ({
            ...row,
            id: i
        }));

        return (
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("uploadfile",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("importartrabajadores.file",{ns:"page"})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.archivo?"":i18next.t("selectfield",{ns:"global"})+i18next.t("importartrabajadores.file",{ns:"page"})}
                            >
                                <FileInput inputProps={{ accept: '.xlsx' }}  onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                            </FormGroup>
                        </div>
                        
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                    </Card>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("importartrabajadores.title",{ns:"page"})}</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="cloud-upload" text={i18next.t("uploadfile",{ns:"global"})} onClick={abrirModalNuevo}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        {this.state.cargando === true?
                            <Cargando />
                            :
                            <div style={{overflowX:'scroll'}}>
                            <BootstrapTable
                            keyField='id'
                            data={ dataWithKeys }
                            columns={ columns }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                            </div>
                        }
                        {this.state.respuesta.length>0?
                        <FormGroup
                            label={i18next.t("importartrabajadores.logs",{ns:"page"})}
                            labelFor="logs"
                        >
                            <TextArea style={{backgroundColor:"black",color:"white",minHeight:250}} readOnly id="logs" fill={true}  value={this.state.respuesta} />
                        </FormGroup>
                        :<></>}
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text="Guardar"
                            intent="danger"
                            disabled={!this.state.importado}
                            onClick={guardarCambios}
                            className="bp3-intent-primary iconoDerecha bp3-minimal"
                        />
                        <br></br>
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(ImportarTrabajadores);