import React from "react"
import { Card, Elevation } from "@blueprintjs/core"
import Compliance1 from "../../Assets/Compliance-1.png"
import Compliance2 from "../../Assets/Compliance-2.png"
import Compliance3 from "../../Assets/Compliance-3.png"
import Compliance4 from "../../Assets/Compliance-4.png"

export default class GobiernoCorporativo extends React.Component {
    render(){

        document.title ="LexControl | Compliance"

        return (
            <>
                <Card interactive={true} elevation={Elevation.TWO} className="bp3-running-text">
                    <img src={Compliance1} className="imagenCentrada"  alt="Compliance"/>
                    <img src={Compliance2} className="imagenCentrada"  alt="Compliance"/>
                    <img src={Compliance3} className="imagenCentrada"  alt="Compliance"/>
                    <img src={Compliance4} className="imagenCentrada"  alt="Compliance"/>
                </Card>
            </>
        )
    }
}