import React, { Component } from 'react';
import { Card, Elevation, FormGroup, Button, Callout } from "@blueprintjs/core"

import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from 'moment';
import ReactToPrint from "react-to-print";

class ExportarDatos extends Component {
    
    state={
        abierto1: false,
        abierto2: false,
        logotipo:null,
        cargando:true,
        cif:null
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarCIF=async ()=>{

        //OBTENCION DE LA PERSONA JURIDICA Y EL CIF DE LA EMPRESA.
        let cif=await this.props.client
        .query({
            query: gql`
            {
                documentosIdentificativos(FILTROS:{ID_PERSONA:1,ID_TIPO_DOCUMENTO:4}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR,FECHA_EMISION,FECHA_CADUCIDAD}
            }
            `,
            fetchPolicy: 'network-only'
        }).then(async result=>{ 
            if(result.data.documentosIdentificativos.length>0){
                return result.data.documentosIdentificativos[0].VALOR
            }
        })

        return cif
    }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,

                }

            }
            
        })

        //MEDIO DE CONTACTO
        await this.props.client
        .query({
          query: gql`
            {
              personasMediosContacto(LIMIT: 2, FILTROS: { ID_PERSONA: 1, PREFERIDO:true}) {
                MEDIO_CONTACTO{VALOR}
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
            if(result.data.personasMediosContacto.length>0){
                datosEmpresa["medio"]=result.data.personasMediosContacto[0].MEDIO_CONTACTO.VALOR
            }
        });

        //DOMICILIO
        await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:1,DOMICILIO_SOCIAL:true}){ID_CENTRO_TRABAJO,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            result.data.centrosTrabajos.forEach((centro)=>{
                console.log(centro)
                datosEmpresa["domicilio"]=`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM} ${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""}(${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})`
            })
        })

        return datosEmpresa
    }

    cargarCentros=async()=>{

        let centros=await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:1}){ID_CENTRO_TRABAJO,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let copyCentros=[]
            result.data.centrosTrabajos.forEach((centro,index)=>{
                copyCentros.push({"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM} ${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""}(${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
            })

            return copyCentros
        })

        return centros
    }

    obtenerCapital=async()=>{

        let capital=await this.props.client
        .query({
            query: gql`
            {
                capSocialActual{Valor,numTitulos,importeTotal}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.capSocialActual
        })

        return capital

    }

    obtenerVariaciones=async()=>{

        let variaciones=await this.props.client.query({
            query:gql`{capSocialVariaciones(FILTROS:{FECHA:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,
            fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.capSocialVariaciones
        })

        return variaciones
    }

    obtenerNombreMiembrosTransmisiones=async(persona)=>{
        
        if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            await this.props.client
             .query({
                 query: gql`
                     {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${persona.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}},
                 `,
                 fetchPolicy:'network-only'

             }).then(result=>{ 
     
                persona.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`

             })
 
         //Si es persona juridica
         }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             await this.props.client
             .query({
                 query: gql`
                     {personasJuridicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                 `,
                 fetchPolicy:'network-only'

             }).then(result=>{ 
 
                persona.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

             })
 
         }
    }

    obtenerTransmisiones=async()=>{

        //OBTENCION DE LAS TRANSMISIONES.
        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones(FILTROS:{FECHA:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){
                ID_TRANSMISION,
                FECHA,
                ORIGEN,
                FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                TIPO_ACCION,
                NUMERO_TITULOS,
                NUMERACION_TITULOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })

        for(let transmision of transmisiones){
            await this.obtenerNombreMiembrosTransmisiones(transmision.FK_ORIGEN)
            await this.obtenerNombreMiembrosTransmisiones(transmision.FK_DESTINO)
        }

        return transmisiones
    }

    obtenerNombreMiembros=async(persona)=>{
        
        if(persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            await this.props.client
             .query({
                 query: gql`
                     {personasFisicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}},
                 `,
                 fetchPolicy:'network-only'

             }).then(result=>{ 
     
                persona.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`

             })
 
         //Si es persona juridica
         }else if(persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             await this.props.client
             .query({
                 query: gql`
                     {personasJuridicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                 `,
                 fetchPolicy:'network-only'

             }).then(result=>{ 
 
                persona.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

             })
 
         }
    }

    cargarGarantias=async()=>{

        let garantias=await this.props.client.query({
            query:gql`{garantias{ID_GARANTIA,ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA,CANTIDAD,CONDICIONES,TIPO_GARANTIA{ID_TIPO_GARANTIA,DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.garantias
        })

        for(let garantia of garantias){
            await this.obtenerNombreMiembros(garantia)
        }
        return garantias
    }

    cargarPolizas=async ()=>{

        //carga de las polizas 
        let polizas=await this.props.client
        .query({
            query: gql`
            {polizasSeguro(CAMPOS:"CAPITAL_ASEGURADO"){
                TIPO_SEGURO{DESCRIPCION},
                CAPITAL_ASEGURADO,
                ASEGURADORA{DESCRIPCION},
                }}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.polizasSeguro
        })

        return polizas
    }

    cargarModificaciones=async ()=>{

        let numMod=this.props.client.query({
            query:gql`{modificacionesEstatutos(FILTROS:{FECHA_EFECTO:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){ID_MODIFICACION_ESTATUTOS,ARTICULO,REDACCION_ANTERIOR,REDACCION_POSTERIOR,FECHA_EFECTO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.modificacionesEstatutos.length
        })

        return numMod

    }

    
    formatearPeriodicidad=(periodicidad)=>{


        if(typeof periodicidad==="string")periodicidad=JSON.parse(periodicidad)

        let format=""
        if(periodicidad.dia && periodicidad.dia>0){
            format+="El día "+periodicidad.dia+" de"
        }else if(periodicidad.diaSemana){
            format+="El "+periodicidad.ordinal+" "+periodicidad.diaSemana+" de"
        }

        if(periodicidad.numeroMes && periodicidad.numeroMes>0){
            format+=" cada "+periodicidad.numeroMes+" meses"
        }else if(periodicidad.meseseSeleccionados.length>0){
            let meses=periodicidad.meseseSeleccionados.map(function(elem){
                return elem.label;
            }).join(",");
            format+=" los meses "+meses
        }
        return format
    }

    cargarOrgano=async()=>{

        let organo=await this.props.client
        .query({
          query: gql`
            {organoAdministracion{ID_FORMA_ADMINISTRACION,FRECUENCIA_REUNIONES}}
          `,fetchPolicy:'network-only'
        })
        .then(async result =>{
            if(result.data.organoAdministracion.length>0){

                let desc=await this.props.client
                .query({
                  query: gql`
                    {formasAdministracion(FILTROS:{ID_FORMA_ADMINISTRACION:${result.data.organoAdministracion[0].ID_FORMA_ADMINISTRACION}}){ID_FORMA_ADMINISTRACION,DESCRIPCION}}
                  `,fetchPolicy:'network-only'
                })
                .then(result =>{
                  return result.data.formasAdministracion[0].DESCRIPCION
                });
                result.data.organoAdministracion[0].desc=desc
            }
            return result.data.organoAdministracion.length>0?result.data.organoAdministracion[0]:null
        });
        return organo
    }

    cargarMiembros=async ()=>{
        
        //buscamos a los miembros del organo
        let miembros=await this.props.client.query({
            query:gql`{organosAdministracionMiembros(CAMPOS:"ID_PERSONA,SUPLENTE"){
                ID_PERSONA,
                SUPLENTE,
                PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`, fetchPolicy:'network-only'
        }).then(async(result)=>{

            let miembros=result.data.organosAdministracionMiembros
            for(let miembro of miembros){
                await this.obtenerNombreMiembros(miembro)
            }
            let suplentes=miembros.filter(miembro=>{return miembro.SUPLENTE===true})
            let noSuplentes=miembros.filter(miembro=>{return !miembro.SUPLENTE})
            return {suplentes:suplentes,nosuplentes:noSuplentes}
        })
        return miembros
    }

    cargarComites=async ()=>{

        let comites=await this.props.client.query({
            query:gql`{agrupaciones{ID_AGRUPACION,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.agrupaciones
        })
        return comites
    }

    cargarOrganosCompliance=async()=>{

        let organo=[]
        //comprobar si existe el organo compliance
        await this.props.client
        .query({
        query: gql`
                 {organosCompliance{ASUMIDO}}

        `,fetchPolicy:'network-only'
        })
        .then(result =>{
            if(result.data.organosCompliance.length>0){
                organo.asumido=result.data.organosCompliance[0].ASUMIDO
            }
        });

        //obtencion de los miembros
        let miembros=await this.props.client
        .query({
          query: gql`
          {miembrosOrganizacion{ID_PERSONA}}`,
          fetchPolicy:'network-only'
        })
        .then(async result =>{
            result.data.miembrosOrganizacion.forEach(async miembro=>{
                
                //Obtencion del nombre del miembro
                await this.props.client.query({
                    query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${miembro.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
                    fetchPolicy:'network-only'
                }).then((result)=>{

                    let persona=result.data.personasFisicas[0]
                    miembro.nombre=`${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.APELLIDO1}, ${persona.NOMBRE}`
                })

             })
             return result.data.miembrosOrganizacion
        });

        //obtencion de los delegados
        let delegados=await this.props.client
        .query({
        query: gql`
        {DelegadosAutorizados{ID_PERSONA}}`,
        fetchPolicy:'network-only'
        })
        .then(async result =>{
            result.data.DelegadosAutorizados.forEach(async delegado=>{
                
                //Obtencion del nombre del miembro
                await this.props.client.query({
                    query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${delegado.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
                    fetchPolicy:'network-only'
                }).then((result)=>{

                    let persona=result.data.personasFisicas[0]
                    delegado.nombre=`${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.APELLIDO1}, ${persona.NOMBRE}`
                })

            })
            return result.data.DelegadosAutorizados
        });

        //obtencion de los profesionales externos
        let profesionales=await this.props.client
        .query({
        query: gql`
        {ProfesionalesExternos{ID_PERSONA}}`,
        fetchPolicy:'network-only'
        })
        .then(async result =>{
            result.data.ProfesionalesExternos.forEach(async profesional=>{
                
                //Obtencion del nombre del miembro
                await this.props.client.query({
                    query:gql`{personasJuridicas(FILTROS:{ID_PERSONA:${profesional.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}`,
                    fetchPolicy:'network-only'
                }).then((result)=>{

                    let persona=result.data.personasJuridicas[0]
                    profesional.nombre=persona.RAZON_SOCIAL
                })

            })
            return result.data.ProfesionalesExternos
        });
        organo.miembros=miembros
        organo.delegados=delegados
        organo.profesionales=profesionales

        return organo
    }  

    cargarActividades=async()=>{

        return await this.props.client
        .query({
          query: gql`
            {actividades{
             OBJETO_SOCIAL,
             PRODUCTOS,
             SERVICIOS,
             CANALES,
             ZONAS,
             RIESGO_INTRINSECO,
             RIESGO_ENTORNO,
             IMPACTO_MEDIOAMBIENTAL,
             RIESGO_MEDIOAMBIENTAL}}
          `
        })
        .then(result =>{
            return result.data.actividades.length>0?result.data.actividades[0]:null
        });
        
    }

    cargarInstalaciones=async()=>{

        return await this.props.client
        .query({
          query: gql`
            {instalaciones{
             FIJAS,
             MOVILES,
             ESPECIALES,
             TRANSPORTE,
             MAQUINARIA,
             INFORMATICOS,
             SOFTWARE}}
          `
        })
        .then(result =>{
            return result.data.instalaciones.length>0?result.data.instalaciones[0]:null
        });

    }

    cargarMemorias=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:7}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarMemorias=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:7}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarInformes=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:8}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarInformes=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:8}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarPlanesIgualdad=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:9}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarPlanesMovilidad=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:10}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    obtenerNombresPersonal=async(persona)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONAL_ALTA_DIRECCION}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            persona.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`

        })
    }
    cargarPersonal=async ()=>{

        let personal=await this.props.client.query({
            query:gql`{personalAltaDireccion{ID_PERSONAL_ALTA_DIRECCION,ATRIBUCIONES,RETRIBUCION,INDEMNIZACIONES}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return result.data.personalAltaDireccion
        })

        //console.log(result)
        for(let persona of personal){
            await this.obtenerNombresPersonal(persona)
        }

        return personal
    }

    obtenerNombreApoderado=async(apoderado)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${apoderado.ID_APODERADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            apoderado.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        })
    }

    cargarApoderados=async ()=>{

        let apoderados=await this.props.client.query({
            query:gql`{apoderados{ID_APODERADO,ID_MODALIDAD_DELEGACION,LIMITES,CONTENIDO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return result.data.apoderados
        })

        for(let persona of apoderados){
            await this.obtenerNombreApoderado(persona)
        }

        return apoderados

    }

    cargarAcreedores=async()=>{

        return await this.props.client.query({
            query:gql`{acreedores{ID_ACREEDOR,ACREEDOR{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}},ID_SECTOR_ACTIVIDAD,SECTOR_ACTIVIDAD{DESCRIPCION},ID_TIPO_ACREEDOR_SECTOR,TIPO_ACREEDOR_SECTOR{DESCRIPCION},ID_TIPO_ACREEDOR_VOLUMEN,TIPO_ACREEDOR_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            let acreedores=[]
            for(let acreedor of result.data.acreedores){

                let nombreacreedor=""
                //Si es persona fisica...
                if(acreedor.ACREEDOR.TIPO_PERSONA.ID_TIPO_PERSONA===1){

                   nombreacreedor=await this.props.client
                    .query({
                        query: gql` 
                            {personasFisicas(FILTROS:{ID_PERSONA:${acreedor.ID_ACREEDOR}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                        `,fetchPolicy:'network-only'

                    }).then(result=>{ 
                       return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                    })

                //Si es persona juridica
                }else if(acreedor.ACREEDOR.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    nombreacreedor=await this.props.client
                    .query({
                        query: gql`
                            {personasJuridicas(FILTROS:{ID_PERSONA:${acreedor.ID_ACREEDOR}}){ID_PERSONA,RAZON_SOCIAL}}
                        `,fetchPolicy:'network-only'
                    }).then(result=>{ 
                        if(result.data.personasJuridicas.length>0){
                            return result.data.personasJuridicas[0].RAZON_SOCIAL
                        }
                    })

                }
                acreedores.push({"nombre":nombreacreedor}) 
            }

            return acreedores
        })

    }

    cargarservicios=async()=>{

        return await this.props.client.query({
            query:gql`{serviciosIndependientes(ORDEN:"ID_SERVICIO_INDEPENDIENTE,ASC"){ID_SERVICIO_INDEPENDIENTE,TIPO,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}},ID_SECTOR_ACTIVIDAD,SECTOR_ACTIVIDAD{DESCRIPCION},ID_CLASIFICACION_VOLUMEN,CLASIFICACION_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            //console.log(result)
            let servicios=[]
            for(let servicio of result.data.serviciosIndependientes){

                let nombreservicio=""
                //Si es persona fisica...
                if(servicio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

                   nombreservicio=await this.props.client
                    .query({
                        query: gql` 
                            {personasFisicas(FILTROS:{ID_PERSONA:${servicio.ID_SERVICIO_INDEPENDIENTE}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                        `,fetchPolicy:'network-only'
                    }).then(result=>{ 
                       return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                    })

                //Si es persona juridica
                }else if(servicio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    nombreservicio=await this.props.client
                    .query({
                        query: gql`
                            {personasJuridicas(FILTROS:{ID_PERSONA:${servicio.ID_SERVICIO_INDEPENDIENTE}}){ID_PERSONA,RAZON_SOCIAL}}
                        `,fetchPolicy:'network-only'
                    }).then(result=>{ 
                        return result.data.personasJuridicas[0].RAZON_SOCIAL
                    })

                }
                servicios.push({"nombre":nombreservicio}) 

            }
    
            return servicios
        })

    }

    cargarasesorias=async()=>{

        return await this.props.client.query({
            query:gql`{asesoriasExternas{ID_ASESORIA_EXTERNA,ASESORIA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA_NOMBRAMIENTO,ID_CLASIFICACION_VOLUMEN,CLASIFICACION_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            let asesorias=[]
            for(let asesoria of result.data.asesoriasExternas){

                let nombreasesoria=""
                //Si es persona fisica...
                if(asesoria.ASESORIA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

                   nombreasesoria=await this.props.client
                    .query({
                        query: gql` 
                            {personasFisicas(FILTROS:{ID_PERSONA:${asesoria.ID_ASESORIA_EXTERNA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                        `,
                        fetchPolicy:'network-only'
                    }).then(result=>{ 
                        if(result.data.personasFisicas.length>0){
                            return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                        }
                    })

                //Si es persona juridica
                }else if(asesoria.ASESORIA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    nombreasesoria=await this.props.client
                    .query({
                        query: gql`
                            {personasJuridicas(FILTROS:{ID_PERSONA:${asesoria.ID_ASESORIA_EXTERNA}}){ID_PERSONA,RAZON_SOCIAL}}
                        `,
                        fetchPolicy:'network-only'
                    }).then(result=>{ 
                        if(result.data.personasJuridicas.length>0){
                            return result.data.personasJuridicas[0].RAZON_SOCIAL
                        }
                    })

                }
                asesorias.push({"nombre":nombreasesoria})           
            }
            return asesorias
        })

    }

    cargarauditores=async()=>{
    
        return await this.props.client.query({
            query:gql`{auditores{ID_AUDITOR,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA_NOMBRAMIENTO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            let auditores=[]
            for(let auditor of result.data.auditores){

                let nombreauditor=""
                //Si es persona fisica...
                if(auditor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

                   nombreauditor=await this.props.client
                    .query({
                        query: gql` 
                            {personasFisicas(FILTROS:{ID_PERSONA:${auditor.ID_AUDITOR}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                        `,
                        fetchPolicy:'network-only'
                    }).then(result=>{ 
                        if(result.data.personasFisicas.length>0){
                            return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                        }
                    })

                //Si es persona juridica
                }else if(auditor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    nombreauditor=await this.props.client
                    .query({
                        query: gql`
                            {personasJuridicas(FILTROS:{ID_PERSONA:${auditor.ID_AUDITOR}}){ID_PERSONA,RAZON_SOCIAL}}
                        `,
                        fetchPolicy:'network-only'
                    }).then(result=>{ 
                        if(result.data.personasJuridicas.length>0){
                            return result.data.personasJuridicas[0].RAZON_SOCIAL
                        }
                    })

                }
                auditores.push({"nombre":nombreauditor}) 
            }
            return auditores
        })

    }

    obtenerNombreFondos=async(fondo)=>{
        
        await this.props.client
        .query({
            query: gql`
                {personasJuridicas(FILTROS:{ID_PERSONA:${fondo.ID_FONDO_INVERSION}}){ID_PERSONA,RAZON_SOCIAL}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 

            fondo.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

        })
    }
    cargarFondos=async()=>{


        let fondos=await this.props.client
        .query({
            query: gql`
                {fondosInversiones{ID_FONDO_INVERSION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.fondosInversiones
        })

        for(let fondo of fondos){
            await this.obtenerNombreFondos(fondo)
        }   

        return fondos
       
    }

    obtenerNombreGestores=async(gestor)=>{
        
        if(gestor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            await this.props.client
             .query({
                 query: gql`
                     {personasFisicas(FILTROS:{ID_PERSONA:${gestor.ID_SOCIO_GESTOR}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}},
                 `,
                 fetchPolicy:'network-only'

             }).then(result=>{ 
     
                if(result.data.personasFisicas.length>0)gestor.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`

             })
 
         //Si es persona juridica
         }else if(gestor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             await this.props.client
             .query({
                 query: gql`
                     {personasJuridicas(FILTROS:{ID_PERSONA:${gestor.ID_SOCIO_GESTOR}}){ID_PERSONA,RAZON_SOCIAL}}
                 `,
                 fetchPolicy:'network-only'

             }).then(result=>{ 
 
                if(result.data.personasJuridicas.length>0)gestor.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

             })
 
         }
    }


    cargarSociosGestores=async()=>{

        let socios=await this.props.client.query({
            query:gql`{sociosGestores{ID_SOCIO_GESTOR,PERSONA{ID_PERSONA,ID_TIPO_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.sociosGestores
        })

        for(let socio of socios){
            await this.obtenerNombreGestores(socio)            
        }
        return socios
    }

    cargarPactos=async()=>{

        let acuerdos=await this.props.client
        .query({
            query: gql` 
                {acuerdosParasociales{ID_ACUERDO,ID_TIPO_ACUERDO,FECHA_VIGENCIA,FECHA_VENCIMIENTO,DESCRIPCION,RESULTADO_VOTACION}}
            `,
            fetchPolicy: 'network-only'
        }).then(result=>{ 
            return result.data.acuerdosParasociales
        })

        let pactosBloqueo=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===1})
        let pactosDefensa=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===2})
        let pactosNoAgresion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===3})
        let acuerdosConversion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===4})
        let pactosAtribucion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===5})
        let pactosOrganizacion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===6})
        let otrosPactos=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===7})

        return {
            "pactosBloqueo":pactosBloqueo,
            "pactosDefensa":pactosDefensa,
            "pactosNoAgresion":pactosNoAgresion,
            "acuerdosConversion":acuerdosConversion,
            "pactosAtribucion":pactosAtribucion,
            "pactosOrganizacion":pactosOrganizacion,
            "otrosPactos":otrosPactos
        }
    }

    cargarJoints=async()=>{

        //obtencion de los proyectos
        return await this.props.client
        .query({
            query: gql`
                {jointVentures{ID_JOINT_VENTURE,NOMBRE_PROYECTO,VIGENCIA_INICIO,VIGENCIA_FIN}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.jointVentures
        })

    }

    cargarPrivates=async()=>{

        //obtencion de los privates
        return await this.props.client
        .query({
            query: gql`
                {privatesEquity{ID_PRIVATE_EQUITY,NOMBRE_PROYECTO,VIGENCIA_INICIO,VIGENCIA_FIN}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.privatesEquity
        })

    }

    obtenerMiembrosComisiones=async(miembro,nombres)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${miembro.ID_MIEMBRO}}){
                ID_PERSONA,
                NOMBRE,
                APELLIDO1,
                APELLIDO2
            }}`
        }).then(async(result)=>{

            let persona=result.data.personasFisicas[0]
            nombres.push(`-  ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`)
            
        })
    }

    cargarComisiones=async ()=>{

        //buscamos las comisiones
          //cargar la tabla de comisiones.
          let comisiones=await this.props.client.query({
            query:gql`{comisiones{
                ID_COMISION,
                NOMBRE,
                ID_TIPO_COMISION,
                TIPO_COMISION{ID_TIPO_COMISION,DESCRIPCION}
            }}`, 
        }).then(async(result)=>{
            return result.data.comisiones
        })

        //buscamos los miembros de cada comision.
        for(let comision of comisiones){
            await this.props.client.query({
                query:gql`{comisionesMiembros(FILTROS:{ID_COMISION:${comision.ID_COMISION}}){
                    ID_MIEMBRO
                }}`
            }).then(async(result)=>{
    
                let miembros=result.data.comisionesMiembros
                let nombres=[]
                for(let miembro of miembros){
                    await this.obtenerMiembrosComisiones(miembro,nombres)
                }      
                console.log(nombres)  
                let listaNombre=""
                nombres.forEach(nombre=>{
                    listaNombre+=`
                        ${nombre}
                    `
                })    
                listaNombre = listaNombre.substring(0, listaNombre.length - 1)       
  
                comision.miembros=listaNombre
               
            })
        }
        return comisiones
    }

    componentWillMount=async()=>{

        this.obtenerLogoEmpresa()
        let cif=await this.cargarCIF()
        let datosEmpresa=await this.cargarDatosEmpresa()
        let centros=await this.cargarCentros()
        let capital=await this.obtenerCapital()
        let variaciones=await this.obtenerVariaciones()
        let transmisiones=await this.obtenerTransmisiones()
        let garantias=await this.cargarGarantias()
        let polizas=await this.cargarPolizas()
        let numMod=await this.cargarModificaciones()
        let organo=await this.cargarOrgano()
        let miembrosOrgano=await this.cargarMiembros()
        let comites=await this.cargarComites()
        let organoCompliance=await this.cargarOrganosCompliance()
        let actividades=await this.cargarActividades()
        let instalaciones=await this.cargarInstalaciones()
        let memorias=await this.cargarMemorias()
        let informes=await this.cargarInformes()
        let planesIgualdad=await this.cargarPlanesIgualdad()
        let planesMovilidad=await this.cargarPlanesMovilidad()
        let personalAltaDireccion=await this.cargarPersonal()
        let apoderados=await this.cargarApoderados()
        let acreedores=await this.cargarAcreedores()
        let servicios= await this.cargarservicios()
        let asesorias=await this.cargarasesorias()
        let auditores= await this.cargarauditores()
        let fondos=await this.cargarFondos()
        let socios=await this.cargarSociosGestores()
        let pactos=await this.cargarPactos()
        let joints=await this.cargarJoints()
        let privates=await this.cargarPrivates()
        let comisiones=await this.cargarComisiones()

        console.log(transmisiones)

        this.setState({
            cif:cif,
            cargando:false,
            datosEmpresa:datosEmpresa,
            centros:centros,
            capital:capital,
            transmisiones:transmisiones,
            variaciones:variaciones,
            garantias:garantias,
            polizas:polizas,
            numMod:numMod,
            organo:organo,
            miembrosOrgano:miembrosOrgano,
            comites:comites,
            organoCompliance:organoCompliance,
            actividades:actividades,
            instalaciones:instalaciones,
            memorias:memorias,
            informes:informes,
            planesIgualdad:planesIgualdad,
            planesMovilidad:planesMovilidad,
            personalAltaDireccion:personalAltaDireccion,
            apoderados:apoderados,
            acreedores:acreedores,
            servicios:servicios,
            asesorias:asesorias,
            auditores:auditores,
            fondos:fondos,
            socios:socios,
            pactos:pactos,
            jointVentures:joints,
            privates:privates,
            comisiones:comisiones
        })
    }

    datos =()=>(
        <div className="html-pdf" ref={(input) => { this.pdfDatos = input; }} >
            <div className="body-pdf">
                <br />
                <img
                    className="App-logo-pdf"
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    alt="logotipo"
                />
                <br />
                <p>
                    <b>DATOS DE LA EMPRESA</b>
                </p>
             
                <p>
                    <b>DATOS INDENTIFICATIVOS DE LA EMPRESA</b>
                </p>

                <p>
                    <b>CIF: </b>{this.state.cif}
                </p>
                <p>
                    <b>RAZÓN SOCIAL: </b>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}
                </p>
                <p>
                    <b>NOMBRE COMERCIAL: </b>{this.state.datosEmpresa?this.state.datosEmpresa.nombreComercial:""}
                </p>
                <p>
                    <b>SITIO WEB CORPORATIVO: </b>{this.state.datosEmpresa?this.state.datosEmpresa.sitioWeb:""}
                </p>
                <p>
                    <b>DOMICILIO: </b>{this.state.datosEmpresa && this.state.datosEmpresa.domicilio?this.state.datosEmpresa.domicilio:""}
                </p>
                <p>
                    <b>MEDIO DE CONTACTO: </b>{this.state.datosEmpresa && this.state.datosEmpresa.medio?this.state.datosEmpresa.medio:""}
                </p>
                <p>
                    <b>RELACIÓN DE CENTROS DE TRABAJO:</b>
                </p>
                {this.state.centros?this.state.centros.map(centro=>{
                    return (<p>{centro.direccion}</p>)
                }):<></>}
                <p><b>
                    ESTRUCTURA ACCIONARIAL
                </b></p>

                <p>
                    <b>Tipo de sociedad: </b>{this.state.datosEmpresa?this.state.datosEmpresa.tipoSociedad:""}
                </p>
                <p>
                    <b>Tipo de títulos: </b>{this.state.datosEmpresa?this.state.datosEmpresa.tipoTitulos:""}
                </p>
                <p>
                    <b>Estado del capital social actual: </b>{`Número de títulos: ${this.state.capital?(this.state.capital.numTitulos?this.state.capital.numTitulos:0):0}; Valor del título: ${this.state.capital?(this.state.capital.Valor?this.state.capital.Valor:0.00):0.00} €; Importe total:${this.state.capital?(this.state.capital.importeTotal?this.state.capital.importeTotal:0.00):0.00} €`}
                </p>
                <p><b>Relación de variaciones del último ejercicio:</b></p>
                    {this.state.variaciones?this.state.variaciones.map(variacion=>{
                        if(variacion.ID_TIPO_VARIACION===1){
                            return (<p>{`- En fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${variacion.CAMBIO_NUMERO_TITULOS>0?"aumentó":"decrementó"} el número de títulos en ${Math.abs(variacion.CAMBIO_NUMERO_TITULOS)} `}</p>)
                        }else{
                            return (<p>{`- En fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} ${variacion.CAMBIO_VALOR_TITULO>0?"aumentó":"decrementó"} el valor del título en ${Math.abs(variacion.CAMBIO_VALOR_TITULO)} `}</p>)
                        }
                    }):<></>}
              
                <p>
                    <b>Transmisión de títulos en el último ejercicio:</b>
                </p>
                {this.state.transmisiones?this.state.transmisiones.map(transmision=>{
                    return (<p>{`- Con fecha ${moment(transmision.FECHA).format("DD-MM-YYYY")} ${transmision.FK_ORIGEN.nombre}
                    trasmitió ${transmision.NUMERO_TITULOS} titulos con numeración ${transmision.NUMERACION_TITULOS} a ${transmision.FK_DESTINO.nombre}
                    `}</p>)
                }):<></>} 
                <p><b>Cuentas anuales:</b></p>
                    <p><b>"Total partidas activo: </b>" {(this.state.datosEmpresa?(this.state.datosEmpresa.totalPartidas?this.state.datosEmpresa.totalPartidas:0.00):0.00)+ " €"} </p>
                    <p><b>Importe neto anual de la cifra denegocio: </b> {(this.state.datosEmpresa?(this.state.datosEmpresa.cifraAnual?this.state.datosEmpresa.cifraAnual:0.00):0.00)+ " €"} </p>
                    <p><b>Número medio de trabajadores: </b> {(this.state.datosEmpresa?(this.state.datosEmpresa.numTrabajadores?this.state.datosEmpresa.numTrabajadores:0.00):0.00)+ " €"} </p>
                <p><b>Créditos y garantías a socios y administradores en el último ejercicio:</b></p>
                {this.state.garantias?this.state.garantias.map(garantia=>{
                    return (<p>{`- Crédito concedido a ${garantia.nombre} el ${moment(garantia.fecha).format("DD-MM-YYYY")} por importe de ${garantia.CANTIDAD} €`}</p>)
                }):<></>}
                
                <p>
                    <b>Pólizas de seguro:</b>
                </p>
                {this.state.polizas?this.state.polizas.map(poliza=>{
                    return (
                        <>
                            <p><b>Compañía aseguradora: </b>{`${poliza.ASEGURADORA.DESCRIPCION}`} € </p>
                            <p><b>capital asegurado: </b>{`${poliza.CAPITAL_ASEGURADO}`} € </p>
                            <p><b>tipo deseguro: </b>${poliza.TIPO_SEGURO.DESCRIPCION}`} € </p>
                        </>
                        )
                }):<></>}

                <p>
                    <b>Modificaciones estatutarias: </b>{`${this.state.numMod?this.state.numMod:0} modificaciones en el ejercicio`}
                </p>

                <p>
                    <b>ÓRGANO DE ADMINISTRACIÓN</b>
                </p>

                <p>
                    <b>Forma de administración: </b>{this.state.organo?this.state.organo.desc:""}
                </p>
                <p><b>Frecuencia de reuniones: </b>{this.state.organo && this.state.organo.FRECUENCIA_REUNIONES?this.formatearPeriodicidad(this.state.organo.FRECUENCIA_REUNIONES):""}</p>
                <p>
                    <b>Relación de miembros: </b>
                </p>
                {this.state.miembrosOrgano?this.state.miembrosOrgano.nosuplentes.map(miembro=>{
                    return (<p>- {miembro.nombre}</p>)
                }):<></>}
                <p>
                    <b>Relación de suplentes: </b>
                </p>
                {this.state.miembrosOrgano?this.state.miembrosOrgano.suplentes.map(miembro=>{
                    return (<p>- {miembro.nombre}</p>)
                }):<></>}
                <p>
                    <b>Relación de comités consultivos: </b>
                </p>
                {this.state.comites?this.state.comites.map(comite=>{
                    return (<p>- {comite.DESCRIPCION}</p>)
                }):<></>}

                <p>
                    <b>ÓRGANO DE COMPLIANCE</b>
                </p>

                <p>
                    <b>Asumido por el órgano de administración: </b>{this.state.organoCompliance?(this.state.organoCompliance.asumido===true?"Si":"No"):""}
                </p>
                <p>
                    <b>Relación de miembros: </b>
                </p>
                {this.state.organoCompliance?this.state.organoCompliance.miembros.map(organo=>{
                    return (<p>- {organo.nombre}</p>)
                }):<></>}
                <p>
                    <b>Profesionales externos:</b>
                </p>
                {this.state.organoCompliance?this.state.organoCompliance.profesionales.map(organo=>{
                    return (<p>- {organo.nombre}</p>)
                }):<></>}
                <p>
                    <b>Delegados autorizados: </b>
                </p>
                {this.state.organoCompliance?this.state.organoCompliance.delegados.map(organo=>{
                    return (<p>- {organo.nombre}</p>)
                }):<></>}

                <p>
                    <b>ACTIVIDADES E INSTALACIONES</b>
                </p>
                <p>
                    <b>Datos relativos a las actividades</b>
                </p>

                <p>
                    <b>Objeto social ración: </b>{this.state.actividades?this.state.actividades.OBJETO_SOCIAL:""}
                </p>
                <p>
                    <b>Productos que se ofrecen: </b>{this.state.actividades?this.state.actividades.PRODUCTOS:""}
                </p>
                <p>
                    <b>Servicios que se prestan: </b>{this.state.actividades?this.state.actividades.SERVICIOS:""}
                </p>
                <p>
                    <b>Canales de distribución: </b>{this.state.actividades?this.state.actividades.CANALES:""}
                </p>
                <p>
                    <b>Zonas geográficas: </b>{this.state.actividades?this.state.actividades.ZONAS:""}
                </p>
                <p>
                    <b>Actividades de riesgo intrínseco: </b>{this.state.actividades?this.state.actividades.RIESGO_INTRINSECO:""}
                </p>
                <p>
                    <b>Actividades con riesgo para el entorno: </b>{this.state.actividades?this.state.actividades.RIESGO_ENTORNO:""}
                </p>
                <p>
                    <b>Actividades con impacto medioambiental: </b>{this.state.actividades?this.state.actividades.RIESGO_MEDIOAMBIENTAL:""}
                </p>

                <p>
                    <b>Datos relativos a las instalaciones</b>
                </p>
                <p>
                    <b>Instalaciones fijas: </b>{this.state.instalaciones?this.state.instalaciones.FIJAS:""}
                </p>
                <p>
                    <b>Instalaciones móviles: </b>{this.state.instalaciones?this.state.instalaciones.MOVILES:""}
                </p>
                <p>
                    <b>Instalaciones especiales: </b>{this.state.instalaciones?this.state.instalaciones.ESPECIALES:""}
                </p>
                <p>
                    <b>Elementos de transporte: </b>{this.state.instalaciones?this.state.instalaciones.TRANSPORTE:""}
                </p>
                <p>
                    <b>Maquinaria: </b>{this.state.instalaciones?this.state.instalaciones.MAQUINARIA:""}
                </p>
                <p>
                    <b>Equipos informáticos: </b>{this.state.instalaciones?this.state.instalaciones.INFORMATICOS:""}
                </p>
                <p>
                    <b>Software: </b>{this.state.instalaciones?this.state.instalaciones.SOFTWARE:""}
                </p>

                <p>
                    <b>RESPONSABILIDAD SOCIAL CORPORATIVA</b>
                </p>

                <p>
                    <b>Relación de memorias:</b>
                </p>
                {this.state.memorias?this.state.memorias.map(memoria=>{
                    return (<p>- {memoria.descripcion}</p>)
                }):<></>}
                <p>
                    <b>Relación de informes: </b>
                </p>
                {this.state.informes?this.state.informes.map(informe=>{
                    return (<p>- {informe.descripcion}</p>)
                }):<></>}
                <p>
                    <b>Relación de planes de igualdad: </b>
                </p>
                {this.state.planesIgualdad?this.state.planesIgualdad.map(plan=>{
                    return (<p>- {plan.descripcion}</p>)
                }):<></>}
                <p>
                    <b>Relación de planes de movilidad: </b>
                </p>
                {this.state.planesMovilidad?this.state.planesMovilidad.map(plan=>{
                    return (<p>- {plan.descripcion}</p>)
                }):<></>}

                <p>
                    <b>PARTES INTERESADAS</b>
                </p>

                <p>
                    <b>Personal de alta dirección:</b>
                </p>
                {this.state.personalAltaDireccion?this.state.personalAltaDireccion.map(personal=>{
                    return (<p>- {personal.nombre}</p>)
                }):<></>}
                <p>
                    <b>Apoderados:</b>
                </p>
                {this.state.apoderados?this.state.apoderados.map(apoderado=>{
                    return (<p>- {apoderado.nombre}</p>)
                }):<></>}
                <p>
                    <b>Acreedores diversos:</b>
                </p>
                {this.state.acreedores?this.state.acreedores.map(acreedor=>{
                    return (<p>- {acreedor.nombre}</p>)
                }):<></>}
                <p>
                    <b>Servicios independientes empresariales:</b>
                </p>
                {this.state.servicios?this.state.servicios.map(servicio=>{
                    return (<p>- {servicio.nombre}</p>)
                }):<></>}
                <p>
                    <b>Asesorías externa: </b>
                </p>
                {this.state.asesorias?this.state.asesorias.map(asesoria=>{
                    return (<p>- {asesoria.nombre}</p>)
                }):<></>}
                <p>
                    <b>Auditores: </b>
                </p>
                {this.state.auditores?this.state.auditores.map(auditor=>{
                    return (<p>- {auditor.nombre}</p>)
                }):<></>}
                <p>
                    <b>Fondos de inversión (capital riesgo): </b>
                </p>
                {this.state.fondos?this.state.fondos.map(fondo=>{
                    return (<p>- {fondo.nombre}</p>)
                }):<></>}
                <p>
                    <b>Socios gestores (capital riesgo): </b>
                </p>
                {this.state.socios?this.state.socios.map(socio=>{
                    return (<p>- {socio.nombre}</p>)
                }):<></>}

                <p>
                    <b>ACUERDOS PARASOCIALES VIGENTES</b>
                </p>

                <p>
                    <b>Pactos de bloqueo:</b>
                </p>
                {this.state.pactos?this.state.pactos.pactosBloqueo.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Pactos de defensa o control:</b>
                </p>
                {this.state.pactos?this.state.pactos.pactosDefensa.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Pactos de no agresión:</b>
                </p>
                {this.state.pactos?this.state.pactos.pactosNoAgresion.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Acuerdos de conversión y de salida:</b>
                </p>
                {this.state.pactos?this.state.pactos.acuerdosConversion.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Pactos de atribución:</b>
                </p>
                {this.state.pactos?this.state.pactos.pactosAtribucion.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Pactos de organización:</b>
                </p>
                {this.state.pactos?this.state.pactos.pactosOrganizacion.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Otros pactos:</b>
                </p>
                {this.state.pactos?this.state.pactos.otrosPactos.map(pacto=>{
                    return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
                }):<></>}
                <p>
                    <b>Joint Ventures:</b>
                </p>
                {this.state.jointVentures?this.state.jointVentures.map(joint=>{
                    return (<p>-Fecha Vigencia:{moment(joint.VIGENCIA_INICIO).format("DD-MM-YYYY")},Fecha Vencimiento:{joint.VIGENCIA_FIN?moment(joint.VIGENCIA_FIN).format("DD-MM-YYYY"):"No definida"},Resultado votación:{joint.NOMBRE_PROYECTO}</p>)
                }):<></>}
                <p>
                    <b>Inversión de capital riesgo:</b>
                </p>
                {this.state.privates?this.state.privates.map(equity=>{
                    return (<p>-Fecha Vigencia:{moment(equity.VIGENCIA_INICIO).format("DD-MM-YYYY")},Fecha Vencimiento:{equity.VIGENCIA_FIN?moment(equity.VIGENCIA_FIN).format("DD-MM-YYYY"):"No definida"},Resultado votación:{equity.NOMBRE_PROYECTO}</p>)
                }):<></>}

                <p>
                    <b>COMISIONES</b>
                </p>
                {this.state.privates?this.state.comisiones.map(comision=>{
                    return (<p>{`- ${comision.NOMBRE}
                    Tipo:${comision.TIPO_COMISION.DESCRIPCION}
                    Miembros:${comision.miembros}`}
                    
                    </p>)
                }):<></>}
                <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p><p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
            </div>
        </div>
    );

    render() {

        document.title ="LexControl | Exportación de datos"

        return (
            <>
            <div>
                <Card interactive={true} elevation={Elevation.TWO} id="informe-anual">
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Exportación de datos</Callout>
                    <FormGroup
                        label="Para generar el documento, pulse aqui: "
                        labelFor="informacion"
                        inline={true}
                    >
                        {!this.state.cargando
                        ?
                            <ReactToPrint
                                trigger={() => <Button className="bp3-minimal" icon="print">Imprimir datos empresa</Button>}
                                content={() => this.pdfDatos}
                            />
                            :
                            <></>
                        }

                    </FormGroup>
                </Card>
            </div>
            </>
        )
    }
}

export default withApollo(ExportarDatos)