import gql from "graphql-tag";
import ApolloClient from "apollo-boost";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: "include"
});

async function obtenerRegistros() {
    try {
        const result=await client
        .query({
            query:gql`{registros{ID_REGISTRO,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        });
        return result.data.registros;
    } catch (error) {
        return [];
    }
	
}

async function obtenerCalificacionesAcuerdos() {
    try {
        const result=await client.query({
            query:gql`{calificacionesAcuerdo{value:ID_CALIFICACION_ACUERDO,label:DESCRIPCION}}`,
            fetchPolicy:'network-only'
        });
        return result.data.calificacionesAcuerdo;
    } catch (error) {
        return [];
    }
}

async function obtenerConvocatoria() {
    try {
        const result=await client.query({
            query:gql`{tipoConvocatorias{value:ID_TIPO_CONVOCATORIA,label:DESCRIPCION}}`,
            fetchPolicy:'cache-first'
        });
        return result.data.tipoConvocatorias;
    } catch (error) {
        return [];
    }
}

async function obtenerPersonasFisicas() {
    try {
        const result=await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){value:ID_PERSONA,tipo:PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},label:FULLNAME}}`,
            fetchPolicy:'network-only'
        });
        return result.data.personasFisicas;
    } catch (error) {
        return [];
    }
}

async function obtenerPersonasJuridicas() {
    try {
        const result=await client.query({
            query:gql`{personasJuridicas{value:ID_PERSONA,tipo:PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},label:RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        });
        return result.data.personasJuridicas;
    } catch (error) {
        return [];
    }
}

async function obtenerEmpleados(personas) {
    try {
        const result=await client.query({
            query:gql`{empleados{ID_EMPLEADO}}`,
            fetchPolicy:'network-only'
        });
        let empleados=[];
        for(let empleado of result.data.empleados){
            const personaSel=personas.find(persona=>{return (persona.value===empleado.ID_EMPLEADO && persona.tipo.TIPO_PERSONA.ID_TIPO_PERSONA===1)});
            empleados.push({
                value:empleado.ID_EMPLEADO,
                label:`${personaSel.label}`
            })
        }
        return empleados;
    } catch (error) {
        return [];
    }
}

export default {obtenerRegistros,obtenerCalificacionesAcuerdos,obtenerConvocatoria,obtenerPersonasFisicas,obtenerPersonasJuridicas,obtenerEmpleados}