import React from "react"

import { Card, Elevation } from "@blueprintjs/core"

import CuadroMandoDocumentacionImagen from "../../Assets/CUADROS DE MANDO-1.png"

export default class CuadroMandoDocumentacion extends React.Component {

    render(){

        document.title ="LexControl | Cuadro de mando"

        return (
            <Card interactive={true} elevation={Elevation.TWO} className="bp3-running-text">
                <img src={CuadroMandoDocumentacionImagen} className="imagenCentrada"  alt="Cuadro de mando, explicación"/>
            </Card>
        )
    }
}