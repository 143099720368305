/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Spinner, Position, Tab, Card, FormGroup, Elevation, Navbar,Popover ,ButtonGroup, Alignment, Button, Dialog, Classes, Tooltip, AnchorButton, Intent, Checkbox, TextArea, Tabs, InputGroup } from "@blueprintjs/core"
import { Table,RegionCardinality, Column, Cell, Regions } from "@blueprintjs/table"
import Select from "react-select"
import VistaCalendarioReuniones from "./VistaCalendarioReuniones"
import InformacionReuniones from "./InformacionReuniones"
import { DateInput, TimePicker } from "@blueprintjs/datetime";
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from 'react-apollo';
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "./Cargando";
import ReactDOM from 'react-dom';
import ObtenerRecursos from './Elementos/ObtenerRecursosNecesarios';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';


class CalendarioReuniones extends Component {

    state={
        modalAbierto: false,
        modalEditarActivado: false,
        modalBorrarActivado: false,
        bolFechaDesde:false,
        fechaDesde: new Date(),
        fechaHasta: new Date(),
        bolFechaHasta:false,
        horaPrimera: new Date(),
        fechaReunion:new Date(),
        horaSegunda:null,
        segundaHoraDesactivada: true,
        lugar:null,
        nombreEmpresa:null,
        nuevoModo:null,
        tiposReuniones: [
        ],
        reuniones: [
        ],
        filtros:false,
        convocantes: [
        ],
        convocados: [
        ],
        modosConvocatoria: [
        ],
        textoConvocatoria:null,
        ordenDia: [
        ],
        tipoConvFiltro:null,
        tipoConvocatoriaSeleccionada:null,
        modalModoAbierto:false,
        modalPuntosAbierto:false,
        modalConvocados:null,
        nuevoPunto:null,
        puntoSeleccionado:null,
        editandoPuesto:false,
        diasConvocatorias:null,
        tiposReunionesFiltros:[],
        modalComisiones:null,
        comisiones:[],
        comisionSeleccionada:null,
        convocanteSeleccionado:null,
        modoSeleccionado:null,
        convocatoriaSeleccionada:null,
        modalBorrarAbierto:null,
        cargandoConvocatorias:false,
        cargandoConvocados:false,
        cargandoComisiones:false,
        regionSeleccionadaConvocatoria:null,
        regionSeleccionadaPuesto:null,
        convocadoSeleccionado:null,
        convocadoSeleccionadoTabla:null,
        nuevoConvocadoActivado:false,
        eliminarConvocadoActivado:false,
        nuevaPersonaActivado:null,
        editarPersonaActivado:null,
        pasoGuia: 0,
        steps: [
            {
              target: '#paso-uno',
              content: 'En esta pantalla, creamos la convocatoria de reuniones.',
              selector: '#paso-uno',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 0,
            },
            {
              target: '#paso-dos',
              content: 'Seleccionamos el tipo de reunión.',
              selector: '#paso-dos',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 1,
            },
            {
              target: '#paso-tres',
              content: 'Seleccionamos quien convoca la reunión.',
              selector: '#paso-tres',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 2,
            },
            {
              target: '#paso-cuatro',
              content: 'Su fecha y hora.',
              selector: '#paso-cuatro',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 3,
            },
            {
              target: '#paso-cinco',
              content: 'Dónde se va a celebrar.',
              selector: '#paso-cinco',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 4,
            },
            {
                target: '#paso-seis',
                content: 'Aqui generamos el téxto de la convocatoria automáticamente.',
                selector: '#paso-seis',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 5,
            },
            {
                target: '#paso-siete',
                content: 'Cómo vamos a informar a los interesados de la reunión.',
                selector: '#paso-siete',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 6,
            },
            {
                target: '#paso-ocho',
                content: 'Los puntos del orden del día.',
                selector: '#paso-ocho',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 7,
            },
            {
                target: '#paso-nueve',
                content: 'Y las personas (Físicas o juridicas) convocadas.',
                selector: '#paso-nueve',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 8,
            },
            {
                target: '#paso-diez',
                content: 'Aqui podemos usar filtros para buscar las convocatorias creadas',
                selector: '#paso-nueve',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 9,
            },
        ],
        cargandoCR:false,
        datosEmpresa:[],
        personasFisicas:null,
        personasJuridicas:null,
        logotipo:null,
        columnWidths: [100,100,100],
        resized: false,
        modalImprimirR:false,
        modalImprimirRG:false,
        cargadoImprimirR:false,
        cargandoImprimirRG:false

    }
    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaConvocatorias.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaConvocatorias.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaConvocatorias.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaConvocatorias.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

    cargarPersonasFisicas= async()=>{
        let personasFisicas=await this.props.client
        .query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},NOMBRE,APELLIDO1,APELLIDO2}}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
          return  result.data.personasFisicas
        })
        return personasFisicas  
    }
    cargarPersonasJuridicas= async() =>{
        let personasJuridicas= await this.props.client.query({
            query:gql`{personasJuridicas{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},RAZON_SOCIAL}}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
         return  result.data.personasJuridicas
        })
        
            return personasJuridicas
    }
    cargarDatosEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   }

   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarDatosEmpresa() 
    let personasFisicas= await this.cargarPersonasFisicas()
   let personasJuridicas= await this.cargarPersonasJuridicas()
    this.setState({
        datosEmpresa:datosEmpresa,
        personasFisicas:personasFisicas,
        personasJuridicas:personasJuridicas
    })

}

    cargarReuniones=async()=>{

        this.setState({cargandoConvocatorias:true})

        let query=gql`{convocatorias{
            ID_CONVOCATORIA,
            COMISION{TIPO_COMISION{ID_TIPO_COMISION,DESCRIPCION}},
            TIPO_CONVOCATORIA{ID_TIPO_CONVOCATORIA,DESCRIPCION},
            MODO_CONVOCATORIA{ID_MODO_CONVOCATORIA,DESCRIPCION},
            FECHA,
            ID_CONVOCANTE,
            HORA_1,
            HORA_2,
            ID_COMISION,
            TITULO,
            LUGAR,
            TEXTO,
            ORDEN_DEL_DIA}}`

        //si han accionado algun filtro.
        if(this.state.filtros){
            query=gql`{convocatorias(FILTROS:{${(this.state.bolFechaDesde || this.state.fechaHasta)?'FECHA:{DESDE:"'+(this.state.bolFechaDesde?moment(this.state.fechaDesde).format("YYYY-MM-DD"):"1970-01-01")+'",HASTA:"'+(this.state.bolFechaHasta?moment(this.state.fechaHasta).format("YYYY-MM-DD"):"2050-01-01")+'"}':""} ${this.state.tipoConvFiltro && this.state.tipoConvFiltro.value>0?",ID_TIPO_CONVOCATORIA:"+this.state.tipoConvFiltro.value:""}}){ID_CONVOCATORIA,COMISION{TIPO_COMISION{ID_TIPO_COMISION,DESCRIPCION}},TIPO_CONVOCATORIA{ID_TIPO_CONVOCATORIA,DESCRIPCION},MODO_CONVOCATORIA{ID_MODO_CONVOCATORIA,DESCRIPCION},FECHA,ID_CONVOCANTE,HORA_1,HORA_2,ID_COMISION,TITULO,LUGAR,TEXTO,ORDEN_DEL_DIA}}`            
        }

        //carga de convocatorias y señalarlas en el calendario
        await this.props.client.query({
            query:query,
            fetchPolicy:'network-only'

        }).then(async(result)=>{

            let convocatorias=[]
            let diasConvocatorias=[]
            for(let convocatoria of result.data.convocatorias){
                convocatorias.push({
                    id:convocatoria.ID_CONVOCATORIA,
                    fecha: convocatoria.FECHA,
                    hora: convocatoria.HORA_1,
                    hora2: convocatoria.HORA_2,  
                    modo:convocatoria.MODO_CONVOCATORIA.ID_MODO_CONVOCATORIA,
                    tipo: convocatoria.TIPO_CONVOCATORIA.DESCRIPCION,
                    idTipo: convocatoria.TIPO_CONVOCATORIA.ID_TIPO_CONVOCATORIA,
                    convocante:convocatoria.ID_CONVOCANTE,
                    comision:convocatoria.ID_COMISION,
                    lugar:convocatoria.LUGAR,
                    texto:convocatoria.TEXTO,
                    orden:convocatoria.ORDEN_DEL_DIA,
                })
                //marcamos la fecha en el calendario.
                diasConvocatorias.push(new Date(convocatoria.FECHA))
            }
            this.setState({
                reuniones:convocatorias,
                diasConvocatorias:diasConvocatorias,
                cargandoConvocatorias:false
            })

        })
      
    }

    obtenerNombreMiembros=async(miembro)=>{
        if(miembro.PERSONA){

            if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                //obtenemos el nombre
               this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.value=miembro.ID_PERSONA
                    miembro.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                    miembro.tipo=1
                    }  

                })
    
            }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_PERSONA){
                        miembro.value=miembro.ID_PERSONA
                        miembro.label=persona.RAZON_SOCIAL
                        miembro.tipo=2
                    }
                })
            }    
            

            }else{

            if(miembro.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.DESTINO){
                        miembro.value=miembro.DESTINO
                        miembro.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`    
                    }
                })
               
            }else if(miembro.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.DESTINO){
                        miembro.value=miembro.DESTINO
                        miembro.label=persona.RAZON_SOCIAL
                    }
                })
    
            }
        }
        
        
    }

       

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    obtenerMiembrosComision=async(comision)=>{

        let miembros= await this.props.client.query({
            query:gql`{comisionesMiembros(FILTROS:{ID_COMISION:${comision.ID_COMISION}}){
                ID_MIEMBRO
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

         return result.data.comisionesMiembros
        }) 
            miembros.forEach((miembro,index)=>{
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_MIEMBRO){
                        miembros.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE} `
                    }
                })
              
        
            comision.miembros=miembros
        })
    
    }

  

    cargarConvocantes=async()=>{

        let empleados=await this.props.client
        .query({
            query: gql`
            {empleados{ID_EMPLEADO,EMPLEADO{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.empleados
        })

        //buscamos el nombre del empleado
        for(let empleado of empleados){
            this.state.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===empleado.ID_EMPLEADO){
                    empleado.value=empleado.ID_EMPLEADO
                    empleado.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                }
            })
        }

        this.setState({convocantes:empleados}) 

    }

    cargarModos=async()=>{

        
        //cargar select modos convocatoria
        await this.props.client.query({
            query:gql`{modosConvocatoria{ID_MODO_CONVOCATORIA,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            let modosConvocatoria=[]
            for(let modo of result.data.modosConvocatoria){
                modosConvocatoria.push({
                    label: modo.DESCRIPCION,
                    value: modo.ID_MODO_CONVOCATORIA,
                })
            }
            this.setState({modosConvocatoria:modosConvocatoria})   
        })

    }

   
    cargarMiembrosCapital=async()=>{

        this.setState({cargandoConvocados:true})

        //carga de filtro tipo convocatoria
        let miembros=await this.props.client.query({
            query:gql`{capSocialInicialDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.capSocialInicialDistribucion
        })
        let miembrosActuales=await this.props.client.query({
            query:gql`{capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.capSocialActualDistribucion
        })
        let miembrosTransmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        for(let miembro of miembrosTransmisiones){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=miembros.find(m=>{return m.ID_PERSONA===miembro.DESTINO})
            if(!existe)miembros.push(miembro)
        }
        for(let miembro of miembrosActuales){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=miembros.find(m=>{return m.ID_PERSONA===miembro.ID_PERSONA})
            if(!existe)miembros.push(miembro)
        }
        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }

        this.setState({
            convocados:miembros,
            cargandoConvocados:false
        })

    }

    cargarMiembrosOrganoAdmon=async()=>{

        this.setState({cargandoConvocados:true})

        //carga de filtro tipo convocatoria
        let miembros=await this.props.client.query({
            query:gql`{organosAdministracionMiembros{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.organosAdministracionMiembros
        })

        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }
        this.setState({
            convocados:miembros,
            cargandoConvocados:false
        })

    }

    
    cargarMiembrosCompliance=async()=>{

        this.setState({cargandoConvocados:true})

        //carga de filtro tipo convocatoria
        let miembros=await this.props.client.query({
            query:gql`{miembrosOrganizacion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.miembrosOrganizacion
        })

        for(let miembro of miembros){
            //obtenemos el nombre
            await this.obtenerNombreMiembros(miembro)
        }

        this.setState({
            convocados:miembros,
            cargandoConvocados:false
        })

    }

    cargarMiembrosOrganoInstruccion=async()=>{

        this.setState({cargandoConvocados:true})

        //carga de filtro tipo convocatoria
        let miembros=await this.props.client.query({
            query:gql`{complianceOrganosInstruccion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceOrganosInstruccion
        })


        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }
        this.setState({
            convocados:miembros,
            cargandoConvocados:false
        })

    }

    cargarMiembrosOrganoResolucion=async()=>{

        this.setState({cargandoConvocados:true})

        //carga de filtro tipo convocatoria
        let miembros=await this.props.client.query({
            query:gql`{complianceOrganosResolucion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceOrganosResolucion
        })

        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }
        this.setState({
            convocados:miembros,
            cargandoConvocados:false
        })

    }

    cargarComisiones=async()=>{

        this.setState({cargandoComisiones:true})

        //cargar la tabla de comisiones.
        let comisiones=await this.props.client.query({
            query:gql`{comisiones{
                ID_COMISION,
                NOMBRE,
                ID_TIPO_COMISION,
                TIPO_COMISION{DESCRIPCION}
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.comisiones
        })    

        //buscamos los miembros de cada comision
       for(let comision of comisiones){
      
            await this.obtenerMiembrosComision(comision)   
        }    
        this.setState({
            comisiones:comisiones,
            cargandoComisiones:false
        })
    }    

    cargarPersonas=async ()=>{

        let personas=[]
       this.state.personasFisicas.forEach(persona =>{            
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        })
      this.state.personasJuridicas.forEach(persona=>{            
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

     })
            this.setState({personas:personas})

    }

    componentDidMount(){
        this.setState({cargandoCR:true}, async()=>{
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarReuniones()
            await this.obtenerLogoEmpresa()
            await this.cargarConvocatoria()
            await this.cargarModos()
            await this.cargarEmpresa()
            await this.cargarConvocantes()
            await this.cargarComisiones()
            this.setState({cargandoCR:false})

        })
    }    

        
       cargarConvocatoria=async()=>{ 
        const tiposReunionesFiltros=await ObtenerRecursos.obtenerConvocatoria();
        this.setState({tiposReunionesFiltros:tiposReunionesFiltros},
        async()=>{
            //eliminamos el primer elemento
            let tipoSinFiltros=tiposReunionesFiltros.filter(tipo=>{
                return tipo.value!==0
            })
        
            this.setState({tiposReuniones:tipoSinFiltros})
        });
    }    

   
    cargarEmpresa=async()=>{
        //obtener el nombre de la empresa
        this.props.client.query({
            query:gql`{personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            this.setState({nombreEmpresa:result.data.personasJuridicas[0].RAZON_SOCIAL})            
        })

        //obtener el id del usuario actual logueado en la aplicacion.
        this.props.client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            this.setState({idUsuario:result.data.obtenerUsuarioActual}) 
        })
    }    

    componentDidUpdate(){
            if(this.state.filtros){
                this.cargarReuniones()
            
            this.setState({filtros:false})
        }

    }
    generarImprimirR=async()=>{
        this.setState({
            cargandoImprimirR:true,
            modalImprimirR:true}, async () =>{
                await this.cargarConvocados();
                this.setState({cargandoImprimirR:false}, ()=>{
                    ReactDOM.render(this.ImprimirR(), document.getElementById('generarR-pdf'))
                })
            })
    }

    generarImprimirRG=async() =>{
        this.setState({
            cargandoImprimirRG:true,
            modalImprimirRG:true
        }, async()=>{
            this.setState({cargandoImprimirRG:false}, () =>{
                ReactDOM.render(this.ImprimirRG(), document.getElementById('generarRG-pdf'))
            })
        })
    }
    ImprimirR=()=>{
        let convocanteSel= this.state.personas.find(persona=>{return persona.value===this.state.convocatoriaSeleccionada.convocante})
        let modoSel=this.state.modosConvocatoria.find(modo=>{return modo.value===this.state.convocatoriaSeleccionada.modo})
    return(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>CUADRO DE MANDO</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>INFORME DE CONVOCATORIAS</Text>
                </View>
                    {this.state.convocatoriaSeleccionada?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>TIPO DE CONVOCATORIA:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.tipo}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>CONVOCANTE:</Text>
                                    <Text style={styles.textData}>{convocanteSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA CONVOCATORIA:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.fecha?moment(this.state.convocatoriaSeleccionada.fecha).format('DD-MM-YYYY'):''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>HORA DE LA PRIMERA CONVOCATORIA:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.hora?this.state.convocatoriaSeleccionada.hora:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>HORA DE LA SEGUNDA CONVOCATORIA:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.hora2?this.state.convocatoriaSeleccionada.hora2:''}</Text>
                                </View>
                                 <View style={styles.row}>
                                    <Text style={styles.labelData}>LUGAR DE CELEBRACIÓN:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.lugar?this.state.convocatoriaSeleccionada.lugar:''}</Text>
                                </View>
                                 <View style={styles.row}>
                                    <Text style={styles.labelData}>TEXTO DE LA CONVOCATORIA:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.texto?this.state.convocatoriaSeleccionada.texto:''}</Text>
                                </View>
                                 <View style={styles.row}>
                                    <Text style={styles.labelData}>MODO DE CONVOCATORIA:</Text>
                                    <Text style={styles.textData}>{modoSel.label}</Text>
                                </View>
                                 <View style={styles.row}>
                                    <Text style={styles.labelData}>PUNTOS A SEGUIR:</Text>
                                    <Text style={styles.textData}>{this.state.convocatoriaSeleccionada.orden?this.state.convocatoriaSeleccionada.orden:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>CONVOCADOS:</Text>
                                    <Text style={styles.textData}></Text>
                                </View>
                                {this.state.convocados?this.state.convocados.map((convocado, index)=>{
                                return(
                                    <View  key={index} style={styles.row}>
                                        <Text style={styles.labelData}></Text>
                                        <Text style={styles.textData}>{convocado.label}</Text>
                                    </View>
                                    )
                                 }):<></>}
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
}  
    ImprimirRG=() =>(
    <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation='landscape' style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>CUADRO DE MANDO </Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>CALENDARIO DE REUNIONES</Text>
                </View>
                    {this.state.reuniones?this.state.reuniones.map((reunion, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>FECHA</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>HORA 1º CONVOCATORIA </Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'40%'}}>TIPO DE CONVOCATORIA</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{reunion.fecha?moment(reunion.fecha).format('DD-MM-YYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{reunion.hora?reunion.hora:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8, fontFamily: 'Open Sans',width:'40%'}}>{reunion.tipo?reunion.tipo:''}</Text>
                                </View>         
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
    reunionesInvisibles =()=> (
        <div className="outer">
            <div className="inner">
                <div className="html-pdf" ref={(input) => { this.tablaConvocatoriasAImprimir = input; }} >
                    <div className="body-pdf">
                        <br />
                        <img
                            className="App-logo-pdf"
                            src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                            alt="logotipo"
                        />
                        <br />
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Table defaultColumnWidth={190} selectionModes="NONE" ref={input => {this.tablaConvocatorias = input;}} className="tableAImprimir-pdf" numRows={this.state.reuniones.length}>
                                <Column name="Fecha"  cellRenderer={this.cellRendererFecha}/>   
                                <Column name="Hora 1ª C." cellRenderer={this.cellRendererHora}/>   
                                <Column name="Tipo de convocatoria" cellRenderer={this.cellRendererTipo}/>
                            </Table>
                        </Card>
                    </div>
                    <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
                </div>
            </div>
        </div>
    )

    cellRendererFecha = (rowIndex) => {
        return <Cell>{moment(this.state.reuniones[rowIndex].fecha).format("DD-MM-YYYY")}</Cell>
    }
    
    cellRendererHora = (rowIndex) => {
        return <Cell>{this.state.reuniones[rowIndex].hora}</Cell>
    }

    cellRendererTipo = (rowIndex) => {
        return <Cell>{this.state.reuniones[rowIndex].tipo}</Cell>
    }

    cargarConvocados= async () => {
        //CONVOCADOS
        let convocados=await this.props.client
        .query({
            query: gql`
            {convocatoriasConvocados(FILTROS:{ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id}}){ID_CONVOCADO,CONVOCADO{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.convocatoriasConvocados
        })

        convocados.forEach(async (convocado,index)=>{
            
            if(convocado.CONVOCADO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===convocado.ID_CONVOCADO){
                        convocados[index].value=persona.ID_PERSONA
                        convocados[index].label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                    }  

                })
                
            }else if(convocado.CONVOCADO.TIPO_PERSONA.ID_TIPO_PERSONA===2){

                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===convocado.ID_CONVOCADO){
                        convocados[index].value=persona.ID_PERSONA
                        convocados[index].label=persona.RAZON_SOCIAL
                    }  

                })
            
            }
        
        })
        this.setState({convocados:convocados})
    }

    render() {

        document.title ="LexControl | Reuniones"

        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirR:false,
                modalImprimirRG:false
            })
        }

         const abrirModalNuevo = () => {
  
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({

                modoSeleccionado:null,
                convocanteSeleccionado:null,
                bolFechaDesde:false,
                fechaDesde: new Date(),
                fechaHasta: new Date(),
                bolFechaHasta:false,
                horaPrimera: new Date(),
                fechaReunion:new Date(),
                horaSegunda:null,
                segundaHoraDesactivada: true,
                lugar:null,        
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                tipoConvocatoriaSeleccionada:null,
                puntoSeleccionado:null,
                editandoPuesto:false,
                comisionSeleccionada:null,
                textoConvocatoria:null,
                convocados:[],
                ordenDia:[],
                convocatoriaSeleccionada:null

            })
        }


        const abrirModalEditar = async () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.convocatoriaSeleccionada){
                alert("Debe seleccionar una convocatoria")
                return
            }

            //TIPO SELECCIONADO
            let tipoSel=this.state.tiposReuniones.find(reunion=>{
                return reunion.value===this.state.convocatoriaSeleccionada.idTipo
            })

            //CONVOCANTE SELECCIONADO
            let convocanteSel=this.state.convocantes.find(con=>{
                return con.value===this.state.convocatoriaSeleccionada.convocante
            })

            //MODO SELECCIONADO
            let modoSel=this.state.modosConvocatoria.find(mod=>{
                return mod.value===this.state.convocatoriaSeleccionada.modo
            })

            //COMISION SELECCIONADA
            let comisionSel=this.state.comisiones.find(comision=>{
                return comision.ID_COMISION ===this.state.convocatoriaSeleccionada.comision
            })

            
            //PUNTOS
            let puntos=this.state.convocatoriaSeleccionada.orden.split("|")
            puntos.forEach((punto,index)=>{
                puntos[index]={id:index,label:punto}
            })

            await this.cargarConvocados();
            
            //HORAS
            let hora=new Date(this.state.convocatoriaSeleccionada.fecha)
            hora.setHours(this.state.convocatoriaSeleccionada.hora.split(":")[0],this.state.convocatoriaSeleccionada.hora.split(":")[1],0,0)
        
            let hora2=new Date(this.state.convocatoriaSeleccionada.fecha)
            if(this.state.convocatoriaSeleccionada.hora2){
                hora2.setHours(this.state.convocatoriaSeleccionada.hora2.split(":")[0],this.state.convocatoriaSeleccionada.hora2.split(":")[1],0,0)
            }

            this.setState({

                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,

                modoSeleccionado:modoSel,
                convocanteSeleccionado:convocanteSel,
                horaPrimera: new Date(hora),
                fechaReunion:new Date(this.state.convocatoriaSeleccionada.fecha),
                horaSegunda:this.state.convocatoriaSeleccionada.hora2?hora2:null,
                segundaHoraDesactivada:this.state.convocatoriaSeleccionada.hora2?false:true,
                lugar:this.state.convocatoriaSeleccionada.lugar,        
                tipoConvocatoriaSeleccionada:tipoSel,
                comisionSeleccionada:(comisionSel?comisionSel:null),
                textoConvocatoria:this.state.convocatoriaSeleccionada.texto,
                ordenDia:puntos,

            })

        }

        const abrirModalEliminar=()=>{

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.convocatoriaSeleccionada){
                alert("Debes seleccionar una convocatoria a eliminar")
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto:true
            })
        }

        const eliminarConvocatoria=async()=>{

            if(!this.state.convocatoriaSeleccionada){
                alert("Debes seleccionar una convocatoria")
                return
            }

            let convocatorias=this.state.reuniones.filter(reunion=>{
                return reunion.id!==this.state.convocatoriaSeleccionada.id
            })

            let error=false

            //eliminamos los viejos convocados
            await this.props.client.mutate({
                mutation: gql`
                mutation{eliminarConvocatoriasConvocados(ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id}){ID_CONVOCATORIA,ID_CONVOCADO}}
                `
            }).catch(err=>{
                error=true
                alert("Ha ocurrido un error al eliminar la convocatoria.Pongase en conctacto con el soporte técnico")
            })

            if(error)return

            await this.props.client.mutate({
                mutation: gql`
                mutation{eliminarConvocatoria(ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id}){ID_CONVOCATORIA}}
                `
            }).catch(err=>{
                error=true
                alert("Ha ocurrido un error al eliminar la convocatoria.Pongase en conctacto con el soporte técnico")
            })

            if(error)return
            
            alert("La convocatoria se ha eliminado correctamente")

            this.setState({
                reuniones:convocatorias,
                convocatoriaSeleccionada:null,
                regionSeleccionadaConvocatoria:null
            },()=>{this.tablaConvocatorias.clearSelection()})

            cerrarModal()
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFechaInicio = (nuevaFecha1) => {
            this.setState({fechaDesde: nuevaFecha1})
            this.setState({filtros:true})
        }

        const escogeHora = (hora) => {
            this.setState({horaPrimera: hora})
        }

        const escogeSegundaHora = (hora) => {
            this.setState({horaSegunda: hora})
        }

        const escogeFechaFin = (nuevaFecha2) => {
            this.setState({fechaHasta: nuevaFecha2})
            this.setState({filtros:true})
        }

        const cellRendererFecha = (rowIndex) => {
            return <Cell>{moment(this.state.reuniones[rowIndex].fecha).format("DD-MM-YYYY")}</Cell>
        }
        
        const cellRendererHora = (rowIndex) => {
            return <Cell>{this.state.reuniones[rowIndex].hora}</Cell>
        }

        const cellRendererTipo = (rowIndex) => {
            return <Cell>{this.state.reuniones[rowIndex].tipo}</Cell>
        }

        const cellRendererOrden = (rowIndex) => {
            return <Cell>{this.state.ordenDia[rowIndex].label}</Cell>
        }

        const cellRendererConvocado = (rowIndex) => {
            return <Cell>{this.state.convocados[rowIndex].label}</Cell>
        }

        const cellRendererComisionNombre = (rowIndex) => {
            return <Cell>{this.state.comisiones[rowIndex].NOMBRE}</Cell>
        }

        const cellRendererComisionTipo = (rowIndex) => {
            return <Cell>{this.state.comisiones[rowIndex].TIPO_COMISION.DESCRIPCION}</Cell>
        }

        const cellRendererComisionNMiembros = (rowIndex) => {
            return <Cell>{this.state.comisiones[rowIndex].miembros.length}</Cell>
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const cerrarModa2 = () => {
            this.setState({
                modalModoAbierto: false,
                modalPuntosAbierto:false,
                modalComisiones:false,
                modalConvocados:false
            })
        }

        const aceptarComision=()=>{

            let convocados=[]
            this.state.comisionSeleccionada.miembros.forEach(miembro=>{
                convocados.push({
                    label:miembro.nombre,
                    value:miembro.ID_MIEMBRO
                })
            })
            this.setState({convocados:convocados})
            cerrarModa2()

        }

        const cancelarComision = () => {
            alert("Debe seleccionar una comision")
            this.setState({
                tipoConvocatoriaSeleccionada: null,
            })
            cerrarModa2()
        }

        const generarTexto = () => {

            if(!this.state.tipoConvocatoriaSeleccionada){
                alert("Debe seleccionar un tipo de convocatoria")
                return
            }

            if(!this.state.lugar){
                alert("Debe rellenar el lugar de la reunión")
                return
            }

            let tipoReunion=""
            switch(this.state.tipoConvocatoriaSeleccionada.value){
                case 1:
                case 2:
                case 4:
                case 5:
                    tipoReunion="de la "+this.state.tipoConvocatoriaSeleccionada.label
                break
                case 3:
                case 6:
                case 7:
                case 8:
                    tipoReunion="del "+this.state.tipoConvocatoriaSeleccionada.label
                break;

                default:
                break;
            }

            this.setState({textoConvocatoria:`Por la presente se le convoca a la reunión ${tipoReunion}  de la empresa ${this.state.nombreEmpresa} que tendrá lugar el próximo día ${moment(this.state.fechaReunion).format("DD/MM/YYYY")} a las ${moment(this.state.horaPrimera).format("HH:mm")} en primera convocatoria ${!this.state.segundaHoraDesactivada?`y a las ${moment(this.state.horaSegunda).format("HH:mm")} en segunda ,`:""} para tratar el siguiente:`})
        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({fechaReunion: nuevaFecha})
        }

        const handleEnabledChange = () => {
            let valor=!this.state.segundaHoraDesactivada
            this.setState({segundaHoraDesactivada:valor })
            if(!valor){
                this.setState({horaSegunda:new Date()})
            }else{
                let hora=new Date()
                hora.setHours(0,0,0,0)
                this.setState({horaSegunda:hora})
            }
        }

        const cambiarBolFechaDesde = () => {
            let nuevoValor=!this.state.bolFechaDesde 
            this.setState({ bolFechaDesde: nuevoValor})
            this.setState({filtros:true})
        }

        const cambiarBolFechaHasta = () => {
            let nuevoValor=!this.state.bolFechaHasta 
            this.setState({ bolFechaHasta: nuevoValor})
            this.setState({filtros:true})

        }

        const cambiarTipoConvFiltro=(value)=>{
            this.setState({tipoConvFiltro:value})
            this.setState({filtros:true})
        }

        const cambiarConvocante=(value)=>{
            this.setState({convocanteSeleccionado:value})
        }

        const cambiarLugar=(ev)=>{
            this.setState({lugar:ev.currentTarget.value})
        }

        const cambiarTextoConvocatoria=(ev)=>{
            this.setState({textoConvocatoria:ev.currentTarget.value})
        }

        const abrirModalComisiones=()=>{
            this.setState({modalComisiones:true})
        }

        const cambiarTipo=(value)=>{

            this.setState({tipoConvocatoriaSeleccionada:value})

            switch(value.value){
                case 1:
                case 2:
                    this.cargarMiembrosCapital() 
                break;
                case 3:
                    this.cargarMiembrosOrganoAdmon() 
                break;
                case 5:
                    abrirModalComisiones() 
                break;
                case 6:
                    this.cargarMiembrosCompliance()
                break;
                case 7:
                    this.cargarMiembrosOrganoInstruccion()
                break;
                case 8:
                    this.cargarMiembrosOrganoResolucion()
                break;
                default:
                break;
            }
            if(value.value!==5)this.setState({comisionSeleccionada:null})

        }

        
        const cambiarModo=(value)=>{
            this.setState({modoSeleccionado:value})
        }

        const cambiarNuevoModo=(ev)=>{
            this.setState({nuevoModo:ev.currentTarget.value})
        }

        const cambiarNuevoPunto=(ev)=>{
            this.setState({nuevoPunto:ev.currentTarget.value})
        }

        const abrirModalModo=()=>{
            this.setState({modalModoAbierto:true})
        }

        const abrirModalPuntos=()=>{
            this.setState({editandoPuesto:false})
            this.setState({puntoSeleccionado:null})
            this.setState({nuevoPunto:null})
            this.setState({modalPuntosAbierto:true})
        }

        const abrirModalAgregarConvocados=()=>{
            this.setState({
                modalConvocados:true,
                nuevoConvocadoActivado:true,
                eliminarConvocadoActivado:false,
                nuevaPersonaActivado:false,
                editarPersonaActivado:false,
                convocadoSeleccionado:false
            })
        }


        const abrirModalPuntosEditar=()=>{
            
            if(!this.state.puntoSeleccionado){
                alert("Debes seleccionar un puesto.")
                return
            }

            this.setState({modalPuntosAbierto:true})
            this.setState({editandoPuesto:true})
            this.setState({nuevoPunto:this.state.puntoSeleccionado.label})
        }

        
        const anadirModo=(ev)=>{


            if(!this.state.nuevoModo)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.modosConvocatoria.find(modo=>{
                return modo.label===this.state.nuevoModo
            })

            if(existe){
                alert("Ya existe un modo con esta descripción")
                return
            }

            //creamos el modo convocatoria.
            this.props.client.mutate({
                mutation: gql`
                  mutation{crearModoConvocatoria(VALORES:{DESCRIPCION:"${this.state.nuevoModo}"}){ID_MODO_CONVOCATORIA}}
                `
                }).then(async result=>{
                   
                    await this.cargarModos()

                    let modoSel=this.state.modosConvocatoria.find(modo=>{
                        return modo.value===result.data.crearModoConvocatoria.ID_MODO_CONVOCATORIA
                    })
                    this.setState({modoSeleccionado:modoSel})
                    cerrarModa2()
                    

                }).catch(err=>{
                    alert('Ha ocurrido un error al insertar el modo.Pongase en contacto con el soporte')
                })

        }

        const anadirPunto=(ev)=>{


            if(!this.state.nuevoPunto)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.ordenDia.find(orden=>{
                return orden.label===this.state.nuevoPunto
            })

            if(existe){
                alert("Ya existe un punto con esta descripción")
                return
            }

            let puntos=this.state.ordenDia
            puntos.push({value:this.state.ordenDia.length,label:this.state.nuevoPunto})
            this.setState({ordenDia:puntos})

            cerrarModa2()
           
        }

        const editarPunto=()=>{

            if(!this.state.puntoSeleccionado){
                alert("Debe seleccionar un punto")
                return
            }
            if(!this.state.nuevoPunto)return

            let ordenDia=this.state.ordenDia
            ordenDia.find((orden,index)=>{
                if(orden.value===this.state.puntoSeleccionado.value){
                    ordenDia[index].label=this.state.nuevoPunto
                }
                return orden.value===this.state.puntoSeleccionado.value
            })
            this.setState({ordenDia:ordenDia})
            cerrarModa2()
        }

        const seleccionarPuesto=(value)=>{
            if(value.length===0)return
            let seleccionada=this.state.ordenDia[value[0].rows[0]]                
            this.setState({
                puntoSeleccionado:seleccionada,
                regionSeleccionadaPuesto:value
            })
        }

        const seleccionarComision=(value)=>{
            if(value.length===0)return
            let seleccionada=this.state.comisiones[value[0].rows[0]]                
            this.setState({comisionSeleccionada:seleccionada})
        }

        const seleccionarConvocatoria=(value)=>{
            if(value.length===0)return
            let seleccionada=this.state.reuniones[value[0].rows[0]]                
            this.setState({
                convocatoriaSeleccionada:seleccionada,
                regionSeleccionadaConvocatoria:value
            })
        }


        const subirPuesto=()=>{
           
            if(!this.state.puntoSeleccionado){
                alert("Debes seleccionar un puesto.")
                return
            }

            let ordenDia=this.state.ordenDia
            //buscamos su posicion 
            ordenDia.find((orden,index)=>{
                if(orden.value===this.state.puntoSeleccionado.value){
                    //si no esta en la primera posicion
                    if(index>0){
                        let antiguosDatos=ordenDia[index-1]
                        ordenDia[index-1]=ordenDia[index]
                        ordenDia[index]=antiguosDatos
                        this.setState({regionSeleccionadaPuesto:[Regions.row(index-1)]})
                    }
                }
                return orden.value===this.state.puntoSeleccionado.value
            })

            this.setState({ordenDia:ordenDia})
           
        }


        const bajarPuesto=()=>{
           
            if(!this.state.puntoSeleccionado){
                alert("Debes seleccionar un puesto.")
                return
            }

            let ordenDia=this.state.ordenDia
            //buscamos su posicion 
            ordenDia.find((orden,index)=>{
                if(orden.value===this.state.puntoSeleccionado.value){
                    //si no esta en la primera posicion
                    if(index!==(ordenDia.length-1)){
                        let antiguosDatos=ordenDia[index+1]
                        ordenDia[index+1]=ordenDia[index]
                        ordenDia[index]=antiguosDatos
                        this.setState({regionSeleccionadaPuesto:[Regions.row(index+1)]})
                    }
                }
                return orden.value===this.state.puntoSeleccionado.value
            })

            this.setState({ordenDia:ordenDia})
           
        }

        const eliminarPuesto=()=>{

            if(!this.state.puntoSeleccionado){
                alert("Debes seleccionar un puesto.")
                return
            }
            let ordenDia=this.state.ordenDia.filter(dia=>{
                return dia.value!==this.state.puntoSeleccionado.value
            })
            this.setState({
                ordenDia:ordenDia,
                puntoSeleccionado:null
            })

        }

        const anadirConvocatoria=()=>{

            if(!this.state.tipoConvocatoriaSeleccionada){
                return
            }

            if(!this.state.convocanteSeleccionado){
                return
            }

            if(!this.state.lugar){
                return
            }

            if(!this.state.textoConvocatoria){
                return
            }

            if(!this.state.modoSeleccionado){
                return
            }

            if(this.state.ordenDia.length===0){
                return
            }

            if(this.state.convocados.length===0){
                return
            }

            let tipoReunion=""
            switch(this.state.tipoConvocatoriaSeleccionada.value){
                case 1:
                case 2:
                case 4:
                case 5:
                    tipoReunion="DE LA "+this.state.tipoConvocatoriaSeleccionada.label.toUpperCase()
                break
                case 3:
                case 6:
                case 7:
                case 8:
                    tipoReunion="DEL "+this.state.tipoConvocatoriaSeleccionada.label.toUpperCase()
                break;
                default:
                break;
            }

            let puntos=this.state.ordenDia.map(function(elem){
                return elem.label;
            }).join("|");

            //mutation parar crear la reunion
            this.props.client.mutate({
                mutation: gql`
                  mutation{crearConvocatoria(VALORES:{
                        ID_CONVOCANTE:${this.state.convocanteSeleccionado.value},
                        ID_TIPO_CONVOCATORIA:${this.state.tipoConvocatoriaSeleccionada.value},
                        FECHA:"${moment(this.state.fechaReunion).format("YYYY-MM-DD")}",
                        HORA_1:"${moment(this.state.horaPrimera).format("HH:mm")}",
                        HORA_2:${!this.state.segundaHoraDesactivada?'"'+moment(this.state.horaSegunda).format("HH:mm")+'"':null},
                        TITULO:"${'CONVOCATORIA DE REUNIÓN' +tipoReunion}",
                        LUGAR:"${this.state.lugar}",
                        TEXTO:"""${this.state.textoConvocatoria}""",
                        ORDEN_DEL_DIA:"${puntos}",
                        ID_MODO_CONVOCATORIA:${this.state.modoSeleccionado.value},
                        ID_COMISION:${this.state.comisionSeleccionada?this.state.comisionSeleccionada.ID_COMISION:null},
                    }){ID_CONVOCATORIA}}
                `
            }).then(async result=>{
            
                alert("Reunion insertada correctamente")
                let datosConvocatoria=result.data.crearConvocatoria

                //insercion del historial
                this.props.client.mutate({
                    mutation: gql`
                        mutation{crearConvocatoriaH(VALORES:{
                            ID_CONVOCATORIA:${datosConvocatoria.ID_CONVOCATORIA},
                            ID_CONVOCANTE:${this.state.convocanteSeleccionado.value},
                            ID_TIPO_CONVOCATORIA:${this.state.tipoConvocatoriaSeleccionada.value},
                            FECHA:"${moment(this.state.fechaReunion).format("YYYY-MM-DD")}",
                            HORA_1:"${moment(this.state.horaPrimera).format("HH:mm")}",
                            HORA_2:${!this.state.segundaHoraDesactivada?'"'+moment(this.state.horaSegunda).format("HH:mm")+'"':null},
                            TITULO:"${'CONVOCATORIA DE REUNIÓN' +tipoReunion}",
                            LUGAR:"${this.state.lugar}",
                            TEXTO:"""${this.state.textoConvocatoria}""",
                            ORDEN_DEL_DIA:"${puntos}",
                            ID_MODO_CONVOCATORIA:${this.state.modoSeleccionado.value},
                            ID_COMISION:${this.state.comisionSeleccionada?this.state.comisionSeleccionada.ID_COMISION:null},
                            FECHA_REGISTRO:"${moment(new Date()).format("YYYY-MM-DD HH:mm")}",
                            ID_USUARIO:${this.state.idUsuario}

                        }){ID_CONVOCATORIA}}
                    `
                })

                //damos de alta los convocados
                this.state.convocados.forEach(convocado=>{

                    this.props.client.mutate({
                        mutation: gql`
                        mutation{crearConvocatoriasConvocados(VALORES:{ID_CONVOCATORIA:${datosConvocatoria.ID_CONVOCATORIA},ID_CONVOCADO:${convocado.value}}){ID_CONVOCATORIA,ID_CONVOCADO}}
                        `
                    })

                })

                await this.cargarReuniones()

                let filaCreada=this.state.reuniones.find((reunion,index)=>{
                    if(reunion.id===datosConvocatoria.ID_CONVOCATORIA){
                        this.setState({regionSeleccionadaConvocatoria:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                    }
                    return reunion.id===datosConvocatoria.ID_CONVOCATORIA

                })

                this.setState({
                    convocatoriaSeleccionada:filaCreada
                })

                cerrarModal()
                
            }).catch(err=>{
                alert('Ha ocurrido un error al insertar la convocatoria.Pongase en contacto con el soporte')
            })
 
        }

        const editarConvocatoria=async ()=>{

         
            if(!this.state.tipoConvocatoriaSeleccionada){
                return
            }

            if(!this.state.convocanteSeleccionado){
                return
            }

            if(!this.state.lugar){
                return
            }

            if(!this.state.textoConvocatoria){
                return
            }

            if(!this.state.modoSeleccionado){
                return
            }

            if(this.state.ordenDia.length===0){
                return
            }

            if(this.state.convocados.length===0){
                return
            }

            let tipoReunion=""
            switch(this.state.tipoConvocatoriaSeleccionada.value){
                case 1:
                case 2:
                case 4:
                case 5:
                    tipoReunion="DE LA "+this.state.tipoConvocatoriaSeleccionada.label.toUpperCase()
                break
                case 3:
                case 6:
                case 7:
                case 8:
                    tipoReunion="DEL "+this.state.tipoConvocatoriaSeleccionada.label.toUpperCase()
                break;

                default:
                break;
            }

            let puntos=this.state.ordenDia.map(function(elem){
                return elem.label;
            }).join("|");

            //mutation parar crear la reunion
            await this.props.client.mutate({
                mutation: gql`
                  mutation{actualizarConvocatoria(ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id},VALORES:{
                        
                        ID_CONVOCANTE:${this.state.convocanteSeleccionado.value},
                        ID_TIPO_CONVOCATORIA:${this.state.tipoConvocatoriaSeleccionada.value},
                        FECHA:"${moment(this.state.fechaReunion).format("YYYY-MM-DD")}",
                        HORA_1:"${moment(this.state.horaPrimera).format("HH:mm")}",
                        HORA_2:${!this.state.segundaHoraDesactivada?'"'+moment(this.state.horaSegunda).format("HH:mm")+'"':null},
                        TITULO:"${'CONVOCATORIA DE REUNIÓN' +tipoReunion}",
                        LUGAR:"${this.state.lugar}",
                        TEXTO:"""${this.state.textoConvocatoria}""",
                        ORDEN_DEL_DIA:"${puntos}",
                        ID_MODO_CONVOCATORIA:${this.state.modoSeleccionado.value},
                        ID_COMISION:${this.state.comisionSeleccionada?this.state.comisionSeleccionada.ID_COMISION:null},
                    }){ID_CONVOCATORIA}}
                `
            }).then(async result=>{
               
                alert("Reunion actualizada correctamente")
                //insercion del historial
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarConvocatoriaH(ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id},VALORES:{
                            ID_CONVOCANTE:${this.state.convocanteSeleccionado.value},
                            ID_TIPO_CONVOCATORIA:${this.state.tipoConvocatoriaSeleccionada.value},
                            FECHA:"${moment(this.state.fechaReunion).format("YYYY-MM-DD")}",
                            HORA_1:"${moment(this.state.horaPrimera).format("HH:mm")}",
                            HORA_2:${!this.state.segundaHoraDesactivada?'"'+moment(this.state.horaSegunda).format("HH:mm")+'"':null},
                            TITULO:"${'CONVOCATORIA DE REUNIÓN' +tipoReunion}",
                            LUGAR:"${this.state.lugar}",
                            TEXTO:"""${this.state.textoConvocatoria}""",
                            ORDEN_DEL_DIA:"${puntos}",
                            ID_MODO_CONVOCATORIA:${this.state.modoSeleccionado.value},
                            ID_COMISION:${this.state.comisionSeleccionada?this.state.comisionSeleccionada.ID_COMISION:null},
                            FECHA_REGISTRO:"${moment(new Date()).format("YYYY-MM-DD HH:mm")}",
                            ID_USUARIO:${this.state.idUsuario}
                        }){ID_CONVOCATORIA}}
                    `
                }).catch(err=>{
                    console.log(err)
                })

                //eliminamos los vijos convocados
                await this.props.client.mutate({
                    mutation: gql`
                    mutation{eliminarConvocatoriasConvocados(ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id}){ID_CONVOCATORIA}}
                    `
                })

                //damos de alta los nuevos convocados
                this.state.convocados.forEach(async convocado=>{

                    await this.props.client.mutate({
                        mutation: gql`
                        mutation{crearConvocatoriasConvocados(VALORES:{ID_CONVOCATORIA:${this.state.convocatoriaSeleccionada.id},ID_CONVOCADO:${convocado.value}}){ID_CONVOCATORIA}}
                        `
                    })

                })
                
                await this.cargarReuniones()

                let filaCreada=this.state.reuniones.find((reunion,index)=>{
                    if(reunion.id===this.state.convocatoriaSeleccionada.id){
                        this.setState({regionSeleccionadaConvocatoria:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                    }
                    return reunion.id===this.state.convocatoriaSeleccionada.id

                    
                })

                this.setState({
                    convocatoriaSeleccionada:filaCreada
                })

                cerrarModal()
                
            }).catch(err=>{
                alert('Ha ocurrido un error al actualizar la convocatoria.Pongase en contacto con el soporte')
            })
 
        }

        const seleccionPersona  = (value) => { 
            this.setState({convocadoSeleccionado: value})
        }

        const agregarConvocado=()=>{

            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.convocadoSeleccionado)return
            //comprobar que no existe ya en la lista
            let existeConvocado=this.state.convocados.find(convocado=>{return convocado.value===this.state.convocadoSeleccionado.value})

            if(existeConvocado){
                alert("El convocado ya esta en la lista.")
                return
            }

            let convocados=this.state.convocados
            convocados.push({
                value:this.state.convocadoSeleccionado.value,
                label:this.state.convocadoSeleccionado.label
            })
            
            this.setState({convocados:convocados,modalConvocados:false})


        }

        const eliminarConvocado=()=>{

            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.convocadoSeleccionado){
                alert("Debe seleccionar un convocado.")
                return
            }
            let convocados=this.state.convocados.filter(convocado=>{return convocado.value!==this.state.convocadoSeleccionado.value})
            
            this.setState({convocados:convocados,nuevoConvocadoActivado:false,eliminarConvocadoActivado:true})
        }

        const seleccionarConvocadoTabla=(value)=>{
            if(value.length===0)return
            let seleccionada=this.state.convocados[value[0].rows[0]]                
            this.setState({convocadoSeleccionado:seleccionada})
        }

        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text="Nueva Persona Física" fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text="Nueva Persona Jurídica" onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        
        const guardarPersonaJuridica=async ()=>{
            
            let result=await this.informacion.handleGuardarDatos() 
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({convocadoSeleccionado:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})
          
        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPersonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }

        const abrirModalNuevaPersona=()=>{
    
            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                popoverAbierto:false,
                nuevaPersonaActivado:true,
                editarPersonaActivado:false,
                convocadoSeleccionado:null,
            })

        }

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.convocadoSeleccionado){
                alert("Debe seleccionar una persona.")
                return
            }

            this.setState({
                editarPersonaActivado:true,
                nuevaPersonaActivado:false
            })
            
            //si es personas física
            if(this.state.convocadoSeleccionado.tipo===1){
                this.setState({modalPersonaAbierto:true})

            //si es persona jurídica
            }else if(this.state.convocadoSeleccionado.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }

        }

        const cerrarModal2 = async () => {

            await this.cargarPersonas()
            if(this.state.convocadoSeleccionado && this.state.convocadoSeleccionado.tipo===2){
                let personaSel=await this.state.personas.find(persona=>{return persona.value===this.state.convocadoSeleccionado.value})
                await this.setState({convocadoSeleccionado:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                let convocados=this.state.convocados
                convocados.find(con=>{
                    if(con.value===this.state.convocadoSeleccionado.value)con.label=personaSel.label
                    return con.value===this.state.convocadoSeleccionado.value
                })
                this.setState({convocados:convocados})
            }
            await this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false
            })
        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                await this.cargarPersonas();
                let personaSel=await this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                await this.setState({convocadoSeleccionado:personaSel})
                cerrarModal2();
            }
        };

        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                await this.cargarConvocantes()
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.convocadoSeleccionado.value})
                this.setState({convocadoSeleccionado:personaSel})
                let convocados=this.state.convocados
                convocados.find(con=>{
                    if(con.value===this.state.convocadoSeleccionado.value)con.label=personaSel.label
                    return con.value===this.state.convocadoSeleccionado.value
                })
                this.setState({convocados:convocados})
                await this.setState({modalNuevaJuridicaAbierto:false})
           
                //cerrar el modal
                cerrarModal2();
            }
        };

        const handleJoyrideCallback=async(data)=>{
            
            const { action, index, status, type } = data;

            if(data.action === "close"){
                localStorage.setItem("ayuda", false)
                this.setState({pasoGuia: 0, modalAbierto:false})
            } else {
            
                if ([EVENTS.STEP_AFTER].includes(type)) {
                    
                    let paso=index+ (action === ACTIONS.PREV ? -1 : 1)

                    //paso en el que nos encontremos.
                    switch(paso){
                        case 0:
                            await this.setState({modalAbierto:false})
                        break;
                        case 1:
                            await this.setState({nuevoActivado:true, modalAbierto:true})
                        break;
                        case 4:
                            document.querySelector(".bp3-overlay-scroll-container").scrollTo(0,0)
                        break;
                        case 6:
                            document.querySelector(".bp3-overlay-scroll-container").scrollTo(0,document.querySelector(".bp3-overlay-scroll-container").scrollHeight)
                        break;
                        case 8:
                            await this.setState({modalAbierto:true})
                            document.querySelector(".bp3-overlay-scroll-container").scrollTo(0,document.querySelector(".bp3-overlay-scroll-container").scrollHeight)
                        break;
                        case 9:
                            await this.setState({modalAbierto:false})
                        break;
                        default:
                    }
            
                    await this.setState({ pasoGuia: paso });   


                }

                //si termina la guia.
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                    localStorage.setItem("ayuda",false)
                }

            }

        }
        
        return (
            <>
            {this.state.cargandoCR===true?
                <Cargando/>
            :
            <>    
            {localStorage.getItem("ayuda")==="true"?
                <>
                    <Joyride
                        run={localStorage.getItem("ayuda")==="true"?true:false}
                        callback={handleJoyrideCallback}
                        showProgress={true}
                        stepIndex={this.state.pasoGuia}
                        steps={this.state.steps}
                        continuous={true}
                        debug={true}
                        disableScrollParentFix={true}
                        locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
                        styles={{
                            options: {
                                arrowColor: '#e3ffeb',
                                backgroundColor: '#e3ffeb',
                                primaryColor: '#E31E24',
                                }
                        }}
                    />
                </>
            :null}
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="print"
                onClose={cerrarModalImprimir}
                title="Imprimir calendario de reuniones"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalImprimirRG}
                style={styles.dialogL}
            >
                {!this.state.cargandoImprimirRG?
                        <div style={{ width: '100%', height: '500px'}} id='generarRG-pdf'>
                        </div>
                        :
                            <Spinner />
                    }

            </Dialog>
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="print"
                onClose={cerrarModalImprimir}
                title="Imprimir detalle de reunión seleccionada"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalImprimirR}
                style={styles.dialogL}
            >
                {!this.state.cargandoImprimirR?
                        <div style={{ width: '100%', height: '500px'}} id='generarR-pdf'>
                        </div>
                        :
                            <Spinner />
                    }

            </Dialog>
            <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={i18next}/>  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Cancelar." position="top">          
                    <Button
                        alignText="left"
                        icon="cross"
                        text="Cancelar"
                        intent="danger"
                        onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                        className="iconoIzquierda bp3-minimal"
                    />
                </Tooltip>
                <Tooltip content="Guardar." position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text="Guardar"
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>
        </Dialog>

            <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title="Propiedades de la empresa"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.convocadoSeleccionado?this.state.convocadoSeleccionado:null}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text="salir"
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar convocatoria"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar la convocatoria?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar convocatoria." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarConvocatoria}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModa2}
                    title="Crear modo convocatoria"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalModoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Introduzca el nombre del modo de convocatoria:"
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoModo?"":"Debe insertar el nombre del modo de convocatoria."}
                        >
                            <InputGroup id="texto-modo-convocatoria" onChange={cambiarNuevoModo}  placeholder="Escriba aquí el nombre del modo" intent={this.state.nuevoModo?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Agregar modo." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirModo}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModa2}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModa2}
                    title={this.state.editandoPuesto?"Editar punto del día":"Crear punto del día"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalPuntosAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Introduzca el punto del día:"
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoPunto?"":"Debe escribir el punto del dia."}
                        >
                            <InputGroup id="texto-punto-dia" onChange={cambiarNuevoPunto} defaultValue={this.state.puntoSeleccionado?this.state.puntoSeleccionado.label:""} placeholder="Escriba aquí el nombre del punto" intent={this.state.nuevoPunto?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.state.editandoPuesto?"Editar punto.":"Agregar punto."} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.editandoPuesto?editarPunto:anadirPunto}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModa2}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModa2}
                    title="Comisiones"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalComisiones}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Seleccione la comision"
                        labelFor="informacion"
                        >
                            <Table defaultColumnWidth={140} onSelection={seleccionarComision} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.comisiones.length}>
                                <Column loading={this.state.cargandoComisiones} name="Nombre" cellRenderer={cellRendererComisionNombre}/>   
                                <Column loading={this.state.cargandoComisiones} name="Tipo" cellRenderer={cellRendererComisionTipo}/>   
                                <Column loading={this.state.cargandoComisiones} name="Numero Miembros" cellRenderer={cellRendererComisionNMiembros}/>  
                            </Table>                       
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.state.editandoPuesto?"Editar punto.":"Agregar punto."} position="top">
                            <AnchorButton
                                disabled={this.state.comisionSeleccionada?false:true}
                                intent={Intent.PRIMARY}
                                onClick={aceptarComision}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cancelarComision}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog 
                transitionDuration={400}
                className="calendario-wrapper pixelAlrededorGrande dialog-ancho"
                autoFocus={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.state.editarActivado?"Editar Reunión":"Crear Reunión"}
                canEscapeKeyClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                isOpen={this.state.modalAbierto}
            >
            <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
            <div className="calendario-wrapper-tabla-izquierda">
                <div>
                    <FormGroup
                        label="Tipo de convocatoria:"
                        labelFor="tipo-convocatoria"
                        intent="danger"
                        helperText={this.state.tipoConvocatoriaSeleccionada?"":"Debe seleccionar un tipo de convocatoria"}
                    >
                        <div style={this.state.tipoConvocatoriaSeleccionada?{}:{border:"1px solid red"}} id="paso-dos">
                            <Select
                                options={this.state.tiposReuniones}
                                className="pixelAlrededor"
                                onChange={cambiarTipo}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                value={this.state.tiposReuniones.filter(tipo=>tipo.value===(this.state.tipoConvocatoriaSeleccionada?this.state.tipoConvocatoriaSeleccionada.value:0))}
                            />
                        </div>
                    </FormGroup>
                </div>
                <div ></div>
                    <FormGroup
                            label="Convocante:"
                            labelFor="convocante"
                            intent="danger"
                            helperText={this.state.convocanteSeleccionado?"":"Debe seleccionar el convocante"}
                        >
                        <div style={this.state.convocanteSeleccionado?{}:{border:"1px solid red"}} id="paso-tres">
                            <Select
                                options={this.state.convocantes}
                                className="pixelAlrededor"
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={cambiarConvocante}
                                value={this.state.convocantes.filter(convocante=>convocante.value===(this.state.convocanteSeleccionado?this.state.convocanteSeleccionado.value:0))}
                            />
                        </div>
                    </FormGroup>
                </div>

                <div id="paso-cuatro">
                    <FormGroup
                        label="Fecha de la reunión:"
                        labelFor="fecha-reunion"
                        inline={true}
                        className="pixelAlrededor" 
                    >
                        <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter} 
                            onChange={escogeFecha}
                            value={this.state.fechaReunion}
                            align={Alignment.RIGHT}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Hora de la primera convocatoria:"
                        labelFor="hora-primera"
                        inline={true}
                        className="pixelAlrededor" 
                        >
                        <TimePicker
                            {...jsDateFormatter} 
                            onChange={escogeHora}
                            value={this.state.horaPrimera}
                            align={Alignment.RIGHT}
                            />
                    </FormGroup>
                    <FormGroup
                        label="Hora de la segunda convocatoria:"
                        labelFor="hora-primera"
                        inline={true}
                        className="pixelAlrededor" 
                        >
                        <TimePicker
                            disabled={this.state.segundaHoraDesactivada}
                            {...jsDateFormatter} 
                            onChange={escogeSegundaHora}
                            value={this.state.horaSegunda}
                            align={Alignment.RIGHT}
                            />
                        <Checkbox checked={!this.state.segundaHoraDesactivada} onChange={handleEnabledChange} label="¿Incluir hora de 2ª convocatoria?" />
                    </FormGroup>
                </div>

                <div id="paso-cinco">
                    <FormGroup
                        label="Lugar de celebración:"
                        labelFor="lugar"
                        intent="danger"
                        helperText={this.state.lugar?"":"Debe rellenar el lugar de celebración"}
                    >
                        <InputGroup value={this.state.lugar} intent={this.state.lugar?"primary":"danger"} onChange={cambiarLugar} id="lugar" />
                    </FormGroup>
                </div>
                <div id="paso-seis">

                    <FormGroup>
                    <AnchorButton
                        onClick={generarTexto}
                    >
                        Generar Texto
                    </AnchorButton>
                    </FormGroup>
                    <FormGroup
                        label="Texto de la convocatoria:"
                        labelFor="lugar"
                        intent="danger"
                        helperText={this.state.textoConvocatoria?"":"Debe rellenar el texto de la convocatoria"}
                    >
                        <TextArea 
                            fill={true}
                            onChange={cambiarTextoConvocatoria}
                            value={this.state.textoConvocatoria}
                            intent={this.state.textoConvocatoria?"primary":"danger"}
                            className="textareaAlta"
                        />
                    </FormGroup>
                </div>

                <div id="paso-siete">
                    <FormGroup
                        label="Modo de convocatoria:"
                        labelFor="modo-convocatoria"
                        intent="danger"
                        helperText={this.state.modoSeleccionado?"":"Debe rellenar el texto de la convocatoria"}
                    >
                        <div style={this.state.modoSeleccionado?{}:{border:"1px solid red"}}>
                            <Select
                                options={this.state.modosConvocatoria}
                                className="pixelAlrededor"
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={cambiarModo}
                                value={this.state.modosConvocatoria.filter(modo=>modo.value===(this.state.modoSeleccionado?this.state.modoSeleccionado.value:0))}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Button 
                            alignText="right"
                            icon="add"
                            className="iconoIzquierda"
                            onClick={abrirModalModo}
                        />
                    </FormGroup>
                </div>
                    <div id="paso-ocho">
                        <ButtonGroup 
                            minimal={true}
                            label="Modo de convocatoria:"
                        >
                            <Tooltip content="Crear punto del órden del día" position={Position.TOP}>
                                <Button icon="new-text-box" onClick={abrirModalPuntos} />
                            </Tooltip>
                            <Tooltip content="Editar punto del órden del día" position={Position.TOP}>
                                <Button icon="edit" onClick={abrirModalPuntosEditar} />
                            </Tooltip>
                            <Tooltip content="Borrar punto del órden del día" position={Position.TOP}>
                                <Button icon="delete" onClick={eliminarPuesto} />
                            </Tooltip>
                            <Tooltip content="Subir punto del órden del día" position={Position.TOP}>
                                <Button icon="upload" onClick={subirPuesto} />
                            </Tooltip>
                            <Tooltip content="Bajar punto del órden del día" position={Position.TOP}>
                                <Button icon="download" onClick={bajarPuesto} />
                            </Tooltip>
                        </ButtonGroup>
                        <br />
                        <div style={this.state.ordenDia.length>0?{}:{border:"1px solid red"}}> 
                            <Table defaultColumnWidth={600} selectedRegions={this.state.regionSeleccionadaPuesto} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}  onSelection={seleccionarPuesto} numRows={this.state.ordenDia.length}>
                                <Column name="Puntos a seguir" cellRenderer={cellRendererOrden}/>   
                            </Table>
                        </div>
                    </div>
                    <div id="paso-nueve">
          
                        <FormGroup
                            label="Convocados:"
                            labelFor="convocados"
                        >
                        <ButtonGroup 
                            minimal={true}
                            label="Modo de convocatoria:"
                        >
                            <Tooltip content="Crear convocado" position={Position.TOP}>
                                <Button icon="add" onClick={abrirModalAgregarConvocados} active={this.state.nuevoConvocadoActivado} />
                            </Tooltip>
                            <Tooltip content="Borrar convocado" position={Position.TOP}>
                                <Button icon="delete" active={this.state.eliminarConvocadoActivado} onClick={eliminarConvocado} />
                            </Tooltip>
                        </ButtonGroup>
                        <div style={this.state.convocados.length>0?{}:{border:"1px solid red"}}> 
                            <Table defaultColumnWidth={600} onSelection={seleccionarConvocadoTabla} enableMultipleSelection={false} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.convocados.length}>
                                <Column name="Convocados" cellRenderer={cellRendererConvocado}/>   
                            </Table>
                        </div>
                        </FormGroup>
                    </div>
            </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content="Propiedades de la convocatoria." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.editarActivado?editarConvocatoria:anadirConvocatoria}
                            >
                                {this.state.editarActivado?"Editar Convocatoria":"Añadir Convocatoria"}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModa2}
                    title={"Agregar convocado"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalConvocados}
                >
                    <div className={Classes.DIALOG_BODY + "pixelAlrededor"}>
                        <FormGroup
                            label="Convocado:"
                            labelFor="persona"
                            intent="danger"
                            helperText={!this.state.convocadoSeleccionado?"Debe seleccionar el convocado.":""}
                        >
                            <div style={this.state.convocadoSeleccionado?{}:{border:"1px solid red"}}>
                                <Select
                                    options={this.state.personas}
                                    className="pixelAlrededor"
                                    onChange={seleccionPersona}
                                    value={this.state.convocadoSeleccionado}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Popover content={<Botonera />} transitionDuration={400} target={<Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevaPersonaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={"Agregar convocado."} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={agregarConvocado}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModa2}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                    >
                    <DatosPersonasFisicas
                        datosPersona={
                        this.state.convocadoSeleccionado
                            ? this.state.convocadoSeleccionado.value
                            : null
                        }
                        ref={input => {
                        this.datosPersona = input;
                        }}
                        i18next={i18next}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal2}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip
                            content={
                            this.state.nuevaPersonaActivado
                                ? "Añadir persona."
                                : "Actualizar persona."
                            }
                            position="top"
                        >
                            <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={
                                this.state.nuevaPersonaActivado
                                ? crearPersona
                                : actualizarPersona
                            }
                            >
                            {this.state.nuevaPersonaActivado
                                ? "Añadir persona"
                                : "Actualizar Persona"}
                            </AnchorButton>
                        </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" id="paso-uno" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar} />
                                    <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalEliminar} />
                                    <Tooltip content="Desea imprimir todas las reuniones" position="right">
                                        <Button  className="bp3-minimal" icon="print" text="Imprimir" onClick={()=>this.generarImprimirRG()} />
                                    </Tooltip>
                                    <Tooltip content="Desea imprimir la reunion seleccionada " position="right">
                                        <Button  className="bp3-minimal" icon="print" text="Imprimir Seleccionado" onClick={()=>this.generarImprimirR()} disabled={this.state.convocatoriaSeleccionada?false:true} />
                                    </Tooltip>
                             </ButtonGroup>  
                        </Navbar.Group>
                    </Navbar>
                    <div className="tablaTreinta">
                        <Tabs id="vista-calendario"
                            animate={this.animacion} 
                            onChange={this.cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                        >
                            <Tab id="vista-calendario" title="Vista de calendario" panel={<VistaCalendarioReuniones diasConvocatorias={this.state.diasConvocatorias} />} />
                            <Tab id="informacion" title="Información" panel={<InformacionReuniones />} />
                        </Tabs>
                    </div>
                    <div className="tablaSetenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <div id="paso-diez">
                            <FormGroup
                                label="Fecha desde:"
                                labelFor="fecha-desde"
                                inline={true}
                                className="pixelAlrededor" 
                            >
                                <Checkbox checked={this.state.bolFechaDesde} onChange={cambiarBolFechaDesde} />
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaInicio}
                                    value={this.state.fechaDesde}
                                    align={Alignment.RIGHT}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Fecha hasta:"
                                labelFor="fecha-hasta"
                                inline={true}
                                className="pixelAlrededor" 
                            >
                                <Checkbox checked={this.state.bolFechaHasta} onChange={cambiarBolFechaHasta} />
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaFin}
                                    value={this.state.fechaHasta}
                                    align={Alignment.RIGHT}
                                />
                            </FormGroup>


                                <FormGroup
                                    label="Tipo de convocatoria:"
                                    labelFor="tipo-convocatoria"
                                >
                                <Select
                                    options={this.state.tiposReunionesFiltros}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={cambiarTipoConvFiltro}
                                />
                                </FormGroup>
                                <Table columnWidths={this.state.columnWidths} selectedRegions={this.state.regionSeleccionadaConvocatoria} enableMultipleSelection={false} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} ref={input => {this.tablaConvocatorias = input;}} onSelection={seleccionarConvocatoria} numRows={this.state.reuniones.length} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                                    <Column loading={this.state.cargandoConvocatorias} name="Fecha"  cellRenderer={cellRendererFecha}/>   
                                    <Column loading={this.state.cargandoConvocatorias} name="Hora 1ª C." cellRenderer={cellRendererHora}/>   
                                    <Column loading={this.state.cargandoConvocatorias} name="Tipo de convocatoria" cellRenderer={cellRendererTipo}/>
                                </Table>

                            </div>
                        </Card>
                    </div>
                </div>
            </>
            }
          </>  
        )
    }
}

export default withApollo(CalendarioReuniones)