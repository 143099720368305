import React from "react"
import { FormGroup, Card, Alignment, TextArea, Switch, Classes,AnchorButton,Tooltip,Dialog, InputGroup, Intent, Button, Spinner} from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import i18next from 'i18next';


let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


export default class InstruccionSienietro extends React.Component {

    state={
        comenzada: false,
        estadoInstruccion: [
        ],
        fechaInstruccion:new Date(),
        estadoInstruccionSeleccionado:null,
        conclusiones:null,
        nuevoEstado:null,
        modalEstadoAbierto:false,
        cargandoI:false
    }

    cargarEstadosInstruccion=async ()=>{


        //Estados instruccion
        let instruccion= await client.query({
            query:gql`{estadosInstruccionSiniestros{ID_ESTADO_INSTRUCCION_SINIESTRO,DESCRIPCION}}            `
        }).then(async(result)=>{
            return result.data.estadosInstruccionSiniestros
        })    
            //console.log(result)
            let estados=[]
            instruccion.forEach(async(estado)=>{
             
                estados.push({"value":estado.ID_ESTADO_INSTRUCCION_SINIESTRO,"label":estado.DESCRIPCION}) 

            })
            this.setState({estadoInstruccion:estados})
    

        
        if(this.props.datosInstruccion && Object.keys(this.props.datosInstruccion).length >0){
            this.setState({comenzada:this.props.datosInstruccion})
            this.setState({fechaInstruccion:this.props.datosInstruccion?new Date(this.props.datosInstruccion.FECHA_ALTA):new Date()})
            this.setState({conclusiones:this.props.datosInstruccion?this.props.datosInstruccion.CONCLUSIONES:""})

            //buscamos el estado instruccion.
            let estadoSel=this.state.estadoInstruccion.find(estado=>{
                return estado.value===this.props.datosInstruccion.ID_ESTADO_INSTRUCCION
            })

            this.setState({estadoInstruccionSeleccionado:estadoSel})    
        }else{

            this.setState({noexiste:true})
        }

    }

    componentDidMount=async()=>{
        this.setState({cargandoI:true}, async () =>{
            await this.cargarEstadosInstruccion()  
            this.setState({cargandoI:false})      
        })
    }

    render(){


        const handleCambiarComienzaInstruccion=(ev)=>{
            this.setState({comenzada:!this.state.comenzada})
        }

        const handleCambiarFechaInstruccion=(value)=>{
            this.setState({fechaInstruccion:value})
        }

        const handleCambiarEstadoInstruccion=(value)=>{
            this.setState({estadoInstruccionSeleccionado:value})
        }

        const handleCambiarConclusiones=(ev)=>{
            this.setState({conclusiones:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const abrirModalNuevoEstado=()=>{
            this.setState({modalEstadoAbierto:true})
        }

        const handlenuevoEstado=(ev)=>{
            this.setState({nuevoEstado:ev.currentTarget.value})
        }

        const cerrarModal=()=>{
            this.setState({modalEstadoAbierto:false})
        }

        const anadirEstado=(ev)=>{

            if(!this.state.nuevoEstado)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.estadoInstruccion.find(estado=>{
                return estado.label===this.state.nuevoEstado
            })

            if(existe){
                alert(i18next.t("siniestros.stateExists", { ns: "page2" }))
                return
            }

            //creamos la estado instruccion.
            client.mutate({
                mutation: gql`
                  mutation{crearEstadoInstruccionSiniestro(VALORES:{DESCRIPCION:"${this.state.nuevoEstado}"}){ID_ESTADO_INSTRUCCION_SINIESTRO}}
                `
            }).then(async result=>{

                await this.cargarEstadosInstruccion()

                let estadoCreado=result.data.crearEstadoInstruccionSiniestro
                let estadoSel=this.state.estadoInstruccion.find(estado=>{
                    return estado.value===estadoCreado.ID_ESTADO_INSTRUCCION_SINIESTRO
                })

                this.setState({estadoInstruccionSeleccionado:estadoSel})
                cerrarModal()

            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })

        }


        return (
            <>
                {this.state.cargandoI===true?
                    <Spinner/>
                :
                <>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("siniestros.createCollectiveAgreement", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}nuevoEstado
                    isOpen={this.state.modalEstadoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={`${i18next.t("siniestros.enterReceptionStateName", { ns: "page2" })}:`}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoEstado?"":i18next.t("siniestros.insertInstructionStateName", { ns: "page2" })}
                        >
                            <InputGroup id="texto-nombre-estado" onChange={handlenuevoEstado}  placeholder={i18next.t("siniestros.enterInstructionStateNameHere", { ns: "page2" })} intent={this.state.nuevoEstado?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("siniestros.addState", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirEstado}
                            >
                                {i18next.t("aceptar", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar", { ns: "global" })}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card>
                        {(this.state.comenzada && (this.props.datosInstruccion && Object.keys(this.props.datosInstruccion).length >0 ))?
                            <Switch checked={true} disabled label={i18next.t("siniestros.instructionStarted", { ns: "page2" })} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaInstruccion} />
                        :
                            <Switch id="boleano-comenzar-instruccion-siniestro" checked={this.state.comenzada} label={i18next.t("siniestros.instructionStarted", { ns: "page2" })} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaInstruccion} />
                        }                       
                        <FormGroup
                            label={i18next.t("siniestros.instructionStartDate", { ns: "page2" })}
                            labelFor="fecha-alta-instruccion"
                            disabled={!this.state.comenzada}
                            inline={true}
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={handleCambiarFechaInstruccion}
                                value={this.state.fechaInstruccion}
                                align={Alignment.RIGHT}
                                disabled={!this.state.comenzada}
                            />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("siniestros.instructionState", { ns: "page2" })}
                            labelFor="estado-instruccion"
                            disabled={!this.state.comenzada}
                            intent="danger"
                            helperText={this.state.estadoInstruccionSeleccionado || !this.state.comenzada?"":i18next.t("siniestros.selectInstructionState", { ns: "page2" })}
                        >
                                <div style={this.state.estadoInstruccionSeleccionado || !this.state.comenzada?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.estadoInstruccion}
                                        className="pixelAlrededor"
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        isDisabled={!this.state.comenzada}
                                        onChange={handleCambiarEstadoInstruccion}
                                        value={this.state.estadoInstruccion.filter(estado => estado.value === (this.state.estadoInstruccionSeleccionado?this.state.estadoInstruccionSeleccionado.value:0))}
                                    />
                                </div>
                        </FormGroup>
                        <FormGroup>
                            <Button 
                                alignText="right"
                                id="btn-crear-estado-instruccion-siniestro"
                                icon="add"
                                className="iconoIzquierda"
                                disabled={!this.state.comenzada}
                                onClick={abrirModalNuevoEstado}
                            />
                        </FormGroup>
                            <FormGroup
                                label={i18next.t("siniestros.conclusions", { ns: "page2" })}
                                labelFor="conclusiones"
                                disabled={!this.state.comenzada}
                            >
                                <TextArea onChange={handleCambiarConclusiones} value={this.state.conclusiones} fill={true} disabled={!this.state.comenzada} />
                            </FormGroup>
                        
                    </Card>
                </div>
            </>
            }
          </>   
        )
    }
}