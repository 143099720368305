import React from "react"
import { Spinner, Card, FormGroup, TextArea, Elevation, Button, Dialog, Classes, AnchorButton, Intent, Tree } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import Denuncias from "./Denuncias.js"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import i18next from 'i18next';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';


let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class DatosSiniestro extends React.Component {

    state={
        fechaSiniestro: new Date(),
        denuncias: [
        ],
        denunciaSeleccionada:null,
        riesgos:null,
        descripcion:null,
        abrirDenuncias:false,
        arbolRiesgos: [
        
        ],
        riesgosSeleccionados: [],
        modalRiesgos: false,
        riesgosSel:null,
        riesgoSeleccionadoTabla:null,
        cargandoDS:false
    }
    

    cerrarModalDenuncias=()=>{
        this.setState({abrirDenuncias:false})
    }

    buscarRiesgo=async(arbol,idRiesgo)=>{
         
        for(let hijo of arbol){
            if(parseInt(hijo.id.split("_").pop())===idRiesgo && hijo.id.includes("riesgo_")){
                let riesgos=this.state.riesgosSeleccionados
                riesgos.push(hijo)
                this.setState({riesgosSeleccionados:riesgos})
                return 
            }else{
                if(hijo.childNodes)this.buscarRiesgo(hijo.childNodes,idRiesgo)
            }
        }

    }

    cargarDenuncias=async()=>{

        this.setState({abrirDenuncias:false})

        //cargamos en el select las denuncias
        let denuncias= await client
        .query({
            query: gql`
            {denuncias(ORDEN:"ID_DENUNCIA,DESC"){
                ID_DENUNCIA,
                DENUNCIANTE_NOMBRE,
            }}
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.denuncias
        })    
    
            let denunciasVal=[]
    
            denuncias.forEach(async denuncia=>{
                denunciasVal.push({"value":denuncia.ID_DENUNCIA,"label":denuncia.DENUNCIANTE_NOMBRE})
            })
            this.setState({denuncias:denunciasVal})
    
            if(this.props.datosSiniestro && Object.keys(this.props.datosSiniestro).length>0){

                //buscamos en el arbol de los riesgos los riesgos seleccionados.
                let riesgos=this.props.datosSiniestro.riesgos?this.props.datosSiniestro.riesgos.split(","):""
                for(let r of riesgos){
                    this.buscarRiesgo(this.state.arbolRiesgos,parseInt(r))
                }

                this.setState({
                    descripcion:this.props.datosSiniestro.descripcion,
                    fechaSiniestro:new Date(this.props.datosSiniestro.fechaSiniestro)
                })

                if(!this.state.denunciaSeleccionada){
                    //buscamos la denuncia seleccionado.
                    let denunciaSel=this.state.denuncias.find(denuncia=>{
                        return denuncia.value===this.props.datosSiniestro.idDenuncia
                    })
                    this.setState({denunciaSeleccionada:denunciaSel})   
                }
               
    
            }
    
    }

    
    tieneHijos=(riesgo,arrayRiesgos)=>{
        var arrayHijos=[];
        let codigo=riesgo.label.split("-")[0].trim()
        let index=1
        var pad = "00"

        // eslint-disable-next-line array-callback-return
        arrayRiesgos.find(element=>{
            if(element.ID_RIESGO_PADRE===parseInt(riesgo.id.split("_").pop())){
                arrayHijos.push({"depth":(element.childNodes?element.childNodes.length:0),id:"riesgo_"+element.ID_RIESGO,"label":codigo+pad.substring(0, pad.length - String(index).length) + index+" - "+element.TITULO,childNodes:(element.childNodes?element.childNodes:null),"descripcion":element.DESCRIPCION,"icon":(element.childNodes?"folder-close":"share")})
                index++
            }
        });
        if(arrayHijos.length>0){
            riesgo.icon="folder-close"
            riesgo.childNodes=arrayHijos;
        }
        return riesgo;
    }

    cargarBloques=async ()=>{
        
        //obtencion de los bloques de riesgo
        var pad = "00"

        //obtencion de los bloques de riesgo
        let bloquesRiesgo=await client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.bloquesRiesgo;
        })

        let complianceRiesgos=await client.query({
            query:gql`{complianceRiesgos{ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE,ID_BLOQUE}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceRiesgos
        })             
            let bloques=[]
            for(let bloque of bloquesRiesgo){        
                let index=1
                //obtencion de los riesgos de cada bloque.
                let hijos=[]
                // eslint-disable-next-line array-callback-return
                complianceRiesgos.find((hijo)=>{
                    if (hijo.ID_BLOQUE===bloque.ID_BLOQUE) {
                        if(hijo.ID_RIESGO_PADRE===null){
                            let nuevoHijo={"depth":(hijo.childNodes?hijo.childNodes.length:0),"id":"riesgo_"+hijo.ID_RIESGO,"label":bloque.LETRA+pad.substring(0, pad.length - String(index).length) + index+" - "+hijo.TITULO,"descripcion":hijo.DESCRIPCION,"icon":"share","childNodes":(hijo.childNodes?hijo.childNodes:null)}
                            hijos.push(nuevoHijo)
                            this.tieneHijos(nuevoHijo,complianceRiesgos)
                            index++
                        }
                    
                    }
                })
                bloques.push({"depth":hijos.length,"id":"bloque_"+bloque.ID_BLOQUE,"label":"Bloque "+bloque.LETRA+" - "+bloque.TITULO,"icon":"folder-close",childNodes:hijos}) 
         
            }
            this.setState({arbolRiesgos:bloques})


    }
    

    componentDidMount=(props)=>{
        this.setState({cargandoDS:true}, async () =>{
            await this.cargarBloques()
            await this.cargarDenuncias()
            this.setState({cargandoDS:false})
        })

       
    }

    

    render(){

        const abrirModalDenuncias=()=>{
            if(!this.state.denunciaSeleccionada){
                alert(`${i18next.t("selectempty", { ns: "global" })} ${i18next.t("siniestros.complaint", { ns: "page2" })} `)
                return
            }
            this.setState({abrirDenuncias:true})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFechaDesde = (nuevaFecha) => {
            this.setState({ fechaSiniestro: nuevaFecha })
        }

        const cambiarDenuncia = (value) => {
            this.setState({denunciaSeleccionada:value})
        }

        const cambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        }

        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }

    

        const abrirModalRiesgos = () => {

            //cerramos el arbol
            replegarArbol(this.state.arbolRiesgos)

            if(this.state.riesgosSel){
                let riesgoSel=this.state.riesgosSel
                riesgoSel.isSelected=false
            }
            this.setState({modalRiesgos: true})
        }

        const cerrarModal = () => {
            this.setState({modalRiesgos: false})
        }

        const seleccionarRiesgo = () => {
         
            if(!this.state.riesgosSel){
                alert(`${i18next.t("selectempty", { ns: "global" })} ${i18next.t("siniestros.risk", { ns: "page2" })}`)
                return
            }

            //agregamos el riesgo a la tabla.
            let riesgos=this.state.riesgosSeleccionados
            //comprobamos que no este ya seleccionado.
            let existe=this.state.riesgosSeleccionados.find(ries=>{return ries.id.split("_").pop()===this.state.riesgosSel.id.split("_").pop()})
            if(existe){
                alert(i18next.t("siniestros.selectedRiskExists", { ns: "page2" }))
                return
            }
            riesgos.push(this.state.riesgosSel)
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:this.state.riesgosSel
            })

            cerrarModal()
      
        }

        const eliminarRiesgoTabla = () => {
         
            if(!this.state.riesgoSeleccionadoTabla){
                alert(`${i18next.t("selectempty", { ns: "global" })} ${i18next.t("siniestros.risk", { ns: "page2" })}`)
                return
            }

            let riesgos=this.state.riesgosSeleccionados
            riesgos=riesgos.filter(riesgo=>{return riesgo.id!==this.state.riesgoSeleccionadoTabla.id})
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:null
            },()=>{if(this.state.riesgosSeleccionados.length>0)this.tablaRiegosRelacionados.clearSelection()})
        }

        const handleOnSelect =(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    riesgoSeleccionadoTabla: row
                },async()=>{
                })
                
            }
            return true; 
        }

        const columns = [
            {
                dataField: 'label',
                text: i18next.t("name", { ns: "global" }),

            }
        ];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };
        

        return (
            <>
            {this.state.cargandoDS===true?
                <Spinner/>
            :
            <>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("lexcontrol", { ns: "global" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalRiesgos}
                >
                <div className={Classes.DIALOG_BODY}>
                    <Tree
                        contents={this.state.arbolRiesgos}
                        onNodeDoubleClick={this.handleNodeClick}
                        onNodeCollapse={this.handleNodeCollapse}
                        onNodeExpand={this.handleNodeExpand}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={seleccionarRiesgo}>
                        {i18next.t("siniestros.chooseRisk", { ns: "page2" })}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        {i18next.t("cancelar", { ns: "global" })}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>

                <Card interactive={true} elevation={Elevation.TWO}>
                <Denuncias externo={true} datosDenunciaAbierto={this.state.abrirDenuncias} cargarDenuncias={() => this.cargarDenuncias()} cerrarModalDenuncias={() => this.cerrarModalDenuncias()} idDenuncia={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.value:null}></Denuncias>

                        <FormGroup
                            label={`${i18next.t("siniestros.claimDate", { ns: "page2" })}:`}
                            labelFor="fecha-siniestro"
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaDesde}
                                value={this.state.fechaSiniestro}
                            />
                        </FormGroup>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label={`${i18next.t("siniestros.associatedComplaint", { ns: "page2" })}:`}
                        labelFor="denuncia-asociada"
                        intent="danger"
                        helperText={this.state.denunciaSeleccionada?"":`${i18next.t("selectempty", { ns: "global" })} ${i18next.t("siniestros.complaint", { ns: "page2" })}`}
                    >
                    <div style={this.state.denunciaSeleccionada?{}:{border:"1px solid red"}}>
                            <Select
                                options={this.state.denuncias}
                                className="pixelAlrededor"
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={cambiarDenuncia}
                                value={this.state.denuncias.filter(denuncia => denuncia.value === (this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.value:null))}
                            />
                    </div>
                    </FormGroup>
                    <FormGroup>
                    <Button 
                        alignText="right"
                        icon="edit"
                        className="iconoIzquierda"
                        onClick={abrirModalDenuncias}
                        />
                    </FormGroup>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label={`${i18next.t("siniestros.associatedRisks", { ns: "page2" })}:`}
                        labelFor="riesgos-asociados"
                    >
                        {this.state.riesgosSeleccionados.length > 0 ?
                            <BootstrapTable
                               keyField="id"
                               data={this.state.riesgosSeleccionados}
                               columns={columns}
                               selectRow={selectRow}
                               pagination={ paginationFactory(const_table.generateOptions(6,0,5)) }
                               headerClasses="header-class"
                               rowClasses="row-class"
                               hover
                               condensed
                               noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                           />      
                        :
                            <p>{i18next.t("siniestros.addRiskMessage", { ns: "page2" })}</p>
                        }
                        <Button label={i18next.t("siniestros.addRisk", { ns: "page2" })} icon="add" onClick={abrirModalRiesgos} />
                        <Button label={i18next.t("siniestros.removeRisk", { ns: "page2" })} icon="delete" onClick={eliminarRiesgoTabla} />
                      
                    </FormGroup>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label={`${i18next.t("siniestros.claimDescription", { ns: "page2" })}:`}
                        labelFor="descripcion-siniestro"
                        intent="danger"
                        helperText={this.state.descripcion?"":i18next.t("siniestros.fillClaimDescription", { ns: "page2" })}
                    >
                        <TextArea onChange={cambiarDescripcion} intent={this.state.descripcion?"primary":"danger"} value={this.state.descripcion} fill={true} />
                    </FormGroup>
                </Card>
            </>
        }
        </>
        )
    }

    
    handleNodeCollapse = (nodeData) => {
    nodeData.isExpanded = false;
    this.setState(this.state);
    };
    
    handleNodeExpand = (nodeData) => {
        nodeData.isExpanded = true;
        this.setState(this.state);
    };
    
    forEachNode(nodes, callback: (node: ITreeNode) => void) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }

    handleNodeClick = (nodeData, _nodePath, e) => {

      
        if(this.state.riesgosSel){
            let riesgoSel=this.state.riesgosSel
            riesgoSel.isSelected=false
        }


        //si es un riesgo...
        if(nodeData.id.includes("riesgo_")){    
            this.setState({riesgosSel:nodeData})
            nodeData.isSelected=true
        } else {
            this.setState({riesgosSel:null})
        }
    }
}
