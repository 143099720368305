import React from "react"
import { 
  Card,
  FormGroup, 
  InputGroup, 
  Elevation, 
  TextArea 
} from "@blueprintjs/core"
import Select from "react-select";
import moment from 'moment';
import { DateInput } from "@blueprintjs/datetime";

export default class ControlAcuerdo extends React.Component {
  state={
    observaciones:'',
    plazo:'',
    responsable:'',
    cumplimiento:'',
    personaSeleccionada:null,
    fechaSolicitud: new Date()
  }

  componentDidMount=()=>{
    if(this.props.acuerdo && Object.keys(this.props.acuerdo).length>0){

        let persona=this.props.empleados.find(persona=>{return persona.value===this.props.acuerdo.ID_SOLICITANTE})
		
        this.setState({
            observaciones:this.props.acuerdo.OBSERVACIONES?this.props.acuerdo.OBSERVACIONES:'',
            plazo:this.props.acuerdo.PLAZO?this.props.acuerdo.PLAZO:'',
            responsable:this.props.acuerdo.RESPONSABLE?this.props.acuerdo.RESPONSABLE:'',
            cumplimiento:this.props.acuerdo.CUMPLIMIENTO?this.props.acuerdo.CUMPLIMIENTO:'',
            personaSeleccionada:persona,
            fechaSolicitud:this.props.acuerdo.FECHA_SOLICITUD?new Date(this.props.acuerdo.FECHA_SOLICITUD + ' 00:00:00'):null
        });

    }
  }

  render(){

    const jsDateFormatter = {
      // note that the native implementation of Date functions differs between browsers
      formatDate: date => moment(date).format("DD/MM/YYYY"),
      parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
      placeholder: "DD/MM/YYYY"
    };

    const handlePlazo=(ev)=>{
      this.setState({plazo:ev.currentTarget.value})
    }

    const handleCumplimiento=(ev)=>{
      this.setState({cumplimiento:ev.currentTarget.value})
    }

    const handleResponsable=(ev)=>{
      this.setState({responsable:ev.currentTarget.value})
    }

    const cambiarTexto=(ev)=>{
        this.setState({observaciones:ev.currentTarget.value})
    }

    const seleccionarPersona = value => {
      this.setState({ personaSeleccionada: value });
    };

    const escogeFechaSolicitud = nuevaFecha => {
      this.setState({ fechaSolicitud: nuevaFecha });
    };

    return(
        <>
            <Card interactive={true} elevation={Elevation.TWO}>
              <FormGroup
                  label={this.props.i18next.t("actas.plazo",{ns:"page"})+":"}
                  labelFor="control-plazo"
              >
                  <InputGroup id="control-plazo" value={this.state.plazo} onChange={handlePlazo}  />   
              </FormGroup>
              <FormGroup
                  label={this.props.i18next.t("actas.grado",{ns:"page"})+":"}
                  labelFor="control-cumplimiento"
              >
                  <InputGroup id="control-cumplimiento" value={this.state.cumplimiento} onChange={handleCumplimiento}  />   
              </FormGroup>
              <FormGroup
                  label={this.props.i18next.t("actas.responsable",{ns:"page"})+":"}
                  labelFor="control-responsable"
              >
                  <InputGroup id="control-responsable" value={this.state.responsable} onChange={handleResponsable}  />   
              </FormGroup>
              <FormGroup
                label={this.props.i18next.t("actas.observaciones",{ns:"page"})+":"}
                labelFor="control-observaciones"
              >
                <TextArea id="control-observaciones" fill={true} onChange={cambiarTexto} value={this.state.observaciones} />
              </FormGroup>
              <FormGroup label={this.props.i18next.t("actas.solicitante",{ns:"page"})+":"}>
                  <Select
                  options={this.props.empleados}
                  className="pixelAlrededor"
                  backspaceRemovesValue={true}
                  onChange={seleccionarPersona}
                  isClearable={true}
                  value={this.state.personaSeleccionada}
                  />
              </FormGroup>
              <FormGroup label={this.props.i18next.t("actas.fechasolicitud",{ns:"page"})+":"} inline={true}>
                <DateInput minDate={new Date(1900, 1 ,1)}
                  {...jsDateFormatter}
                  onChange={escogeFechaSolicitud}
                  value={this.state.fechaSolicitud}
                />
              </FormGroup>
            </Card>
        </>
    )
  }
}