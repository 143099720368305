import React from "react"
import { Card, Elevation, FormGroup,Button, InputGroup } from "@blueprintjs/core";
import Select from "react-select"
import { Table, Column, Cell, RegionCardinality} from "@blueprintjs/table"
import moment from 'moment';
import ObtenerRecursos from './Elementos/ObtenerRecursosNecesarios';


export default class VistaListadoActas extends React.Component {

    state={
        tiposReuniones: [

        ],
        actas: [
      
        ],
        copyActas:[],
        numeroActa:1,
        organoSeleccionado:null,
        columnWidths: [100,100,100,100],
        resized: false,
        regionSeleccionada:null
    }

    resizeColumns() {
        
      let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
      let totalMinSizeReq = 0;
      let cumulativeColumnWidths= [];
      for (let index = 0; index < this.tablaActas.locator.grid.numCols; index++) {
          totalMinSizeReq = totalMinSizeReq + this.tablaActas.locator.getWidestVisibleCellInColumn(index);
          cumulativeColumnWidths.push(this.tablaActas.locator.getWidestVisibleCellInColumn(index));
      }
      if (totalMinSizeReq > containerSize) {
          this.setState({
              columnWidths: cumulativeColumnWidths,
              resized: true
          });
      } else {
          let diff = ((containerSize - totalMinSizeReq) / this.tablaActas.locator.grid.numCols);
          this.setState({
              columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
              resized: true
          });
      }
      
    }    

    /*componentDidUpdate=async(props)=>{
        if(props!==this.props){
            await this.setState({actas:this.props.actas})
            await this.setState({copyActas:this.props.actas})
            if(this.props.acta){
                await this.setState({
                    actas:this.state.copyActas
                })
                await this.state.actas.find((ac,index)=>{
                    if(parseInt(ac.ID_ACTA)===parseInt(this.props.acta.ID_ACTA)){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return parseInt(ac.ID_ACTA)===parseInt(this.props.acta.ID_ACTA)
                })
            }
        }
    }*/

    filtrar=async()=>{  
      this.setState({actas:this.state.copyActas})
    }

    componentDidMount=async()=>{
        const tiposReunionesFiltros=await ObtenerRecursos.obtenerConvocatoria();
        this.setState({tiposReunionesFiltros:tiposReunionesFiltros},
            async ()=>{
                //eliminamos el primer elemento
                let tipoSinFiltros=tiposReunionesFiltros.filter(tipo=>{
                    return tipo.value!==0
                })
                this.setState({tiposReuniones:tipoSinFiltros},async()=>{
                    //marcamos el primer organo por defecto
                    let organoDefecto=tipoSinFiltros.find(tipo=>{return tipo.value===1})
                    this.setState({organoSeleccionado:organoDefecto})
                    this.setState({copyActas:this.props.actas})
                
                    await this.filtrar();
                })
            });

    }

    render(){

        const cellRendererNumero = (rowIndex) => {
            return <Cell>{this.state.actas[rowIndex].NUMERO}</Cell>
        }

        const cellRendererFecha = (rowIndex) => {
          return <Cell>{moment(this.state.actas[rowIndex].FECHA_REUNION).format("DD-MM-YYYY")}</Cell>
        }

        const cellRendererOrgano = (rowIndex) => {
            return <Cell>{this.state.actas[rowIndex].TIPO_CONVOCATORIA.DESCRIPCION}</Cell>
        }

        const cellRendererDescripcion = (rowIndex) => {

            
            let tipoReunion=""
            switch(this.state.actas[rowIndex].ID_TIPO_CONVOCATORIA){
                case 1:
                case 2:
                case 4:
                case 5:
                    tipoReunion="Acta de reunión de la "+this.state.actas[rowIndex].TIPO_CONVOCATORIA.DESCRIPCION
                break
                case 3:
                case 6:
                case 7:
                case 8:
                    tipoReunion="Acta de reunión del "+this.state.actas[rowIndex].TIPO_CONVOCATORIA.DESCRIPCION
                break;

                default:
                break;
            }

            return <Cell>{tipoReunion}</Cell>
        }

        const cambiarNumeroActa=(ev)=>{
            this.setState({numeroActa:ev.currentTarget.value})
        }

        const cambiarTipoReunion=(value)=>{
            this.setState({organoSeleccionado:value})
        }

        const filtrar=async()=>{  
            this.setState({actas:this.state.copyActas.filter(acta=>{return parseInt(acta.ID_TIPO_CONVOCATORIA)===parseInt(this.state.organoSeleccionado.value) && parseInt(acta.NUMERO)===parseInt(this.state.numeroActa)})})
        }

        const limpiarFiltros=()=>{
            this.setState({
                actas:this.state.copyActas
            })
        }

        const seleccionarActa = async (value) => {

            if(value.length===0)return
            let seleccionada=this.state.actas[value[0].rows[0]]            
            await this.props.setActaSeleccionada(seleccionada)
            this.setState({regionSeleccionada:value})
        }

        return(
            <>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label="Número de acta:"
                        labelFor="numero-acta"
                    >
                        <InputGroup type="number" min={1} step="1" value={this.state.numeroActa} onChange={cambiarNumeroActa} id="numero-acta" />
                    </FormGroup>
                    <FormGroup
                        label="Órgano del acta:"
                        labelFor="organo-acta"
                    >
                            <Select
                                options={this.state.tiposReuniones}
                                className="pixelAlrededor"
                                value={this.state.organoSeleccionado}
                                onChange={cambiarTipoReunion}
                                backspaceRemovesValue={true}
                                isClearable={true}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Button intent={"danger"} className="iconoDerecha bp3-minimal" onClick={limpiarFiltros} icon="filter-remove">Eliminar filtros</Button>
                        <Button intent={"danger"} className="iconoDerecha bp3-minimal" onClick={filtrar} icon="filter">Filtrar</Button>
                    </FormGroup>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                        <Table enableRowHeader={false} selectedRegions={this.state.regionSeleccionada} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarActa} numRows={this.state.actas.length} ref={input => {this.tablaActas = input;}} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                            if (!this.state.resized) {
                                                this.resizeColumns();
                                            }
                                        }}>
                            <Column name="Nº Acta" cellRenderer={cellRendererNumero}/>   
                            <Column name="Fecha Reunión" cellRenderer={cellRendererFecha}/>   
                            <Column name="Órgano" cellRenderer={cellRendererOrgano}/>   
                            <Column name="Descripción" cellRenderer={cellRendererDescripcion}/>   
                        </Table>
                </Card>
            </>
        )
    }
}