import React from 'react';
import { FormGroup, InputGroup, Button, Dialog, Classes, Tooltip, AnchorButton, Checkbox, Intent, Card, Elevation, Navbar, NavbarHeading, Alignment, ButtonGroup } from "@blueprintjs/core"
import { DateRangePicker, DateInput } from "@blueprintjs/datetime"
import "moment/locale/es"
import MomentLocaleUtils from 'react-day-picker/moment';
import Select from "react-select"
import gql from "graphql-tag";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withApollo } from 'react-apollo';
import { Cargando } from "../Cargando";
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const estadoInicial = {
    cif: null,
    cifMod:null,
    CCC: [],
    convenios: [],
    convenioColectivo:null,
    grupos: [],
    grupoElegido: null,
    grupoEmpresarial:null,
    sitioWeb:null,
    sitioWebCanalDenuncias:null,
    fechaInicio: null,
    fechaFin: null,
    razonSocial:null,
    nombreComercial:null,
    modalIsOpen: false,
    modalConvenioAbierto:false,
    modalGrupoEmpresarial:false,
    idEmpresa:null,
    conveniosSeleccionado:null,
    urlValida:true,
    urlValidaCanalDenuncias:true,
    modalNuevoCifAbierto: false,
    modalCuentaAbierto: false,
    tipoDocumento:4,
    cargado:false,
    cargandoConvenio:false,
    cargandoCuenta:false        
};

class InformacionGeneral extends React.Component {
    
    state=estadoInicial

    cargarGrupos=async()=>{

        this.props.client
        .query({
            query: gql`
            {
                gruposEmpresariales{ID_GRUPO_EMPRESARIAL,DESCRIPCION}
            }
            `, 
            fetchPolicy: 'network-only'
              
        }).then(result=>{
            let index=0
            for(let grupo of result.data.gruposEmpresariales){
                result.data.gruposEmpresariales[index]={"label":grupo.DESCRIPCION,"value":grupo.ID_GRUPO_EMPRESARIAL}
                index++
            }
            this.setState({grupos:result.data.gruposEmpresariales})
        })

    }

    cargarDatosEmpresa=()=>{
        //DATOS JURIDICOS
        this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,WEB_CORPORATIVA,WEB_CANAL_DENUNCIAS,CONVENIOS_COLECTIVOS}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{

            this.setState({idEmpresa:result.data.personasJuridicas[0].ID_PERSONA})
            this.setState({razonSocial:result.data.personasJuridicas[0].RAZON_SOCIAL})
            this.setState({nombreComercial:result.data.personasJuridicas[0].NOMBRE_COMERCIAL})
            if(result.data.personasJuridicas[0].WEB_CORPORATIVA!==null){
                this.setState({sitioWeb:result.data.personasJuridicas[0].WEB_CORPORATIVA})
            }
            if(result.data.personasJuridicas[0].WEB_CANAL_DENUNCIAS!==null){
              this.setState({sitioWebCanalDenuncias:result.data.personasJuridicas[0].WEB_CANAL_DENUNCIAS})
            }
            this.setState({grupoElegido:result.data.personasJuridicas[0].ID_GRUPO_EMPRESARIAL})
            if(result.data.personasJuridicas[0].INICIO_EJERCICIO!==null && result.data.personasJuridicas[0].FIN_EJERCICIO!==null){
                let finicio=new Date(result.data.personasJuridicas[0].INICIO_EJERCICIO);
                finicio.setHours(0,0,0,0);
                let ffin=new Date(result.data.personasJuridicas[0].FIN_EJERCICIO);
                ffin.setHours(0,0,0,0);
                this.setState({fechaInicio:finicio,fechaFin:ffin})
            }
            //convenios colectivos.
            if(result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS){
                let conveniosColectivos=result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS.split("|");
                conveniosColectivos.forEach((convenio,index)=>{
                    conveniosColectivos[index]={"id":index,"nombre":convenio}
                })
                this.setState({convenios:conveniosColectivos})
            }
            
            this.setState({cargado:true})            
            
        })
    }

    cargarCuentas=async()=>{

        //OBTENCION DE LAS CCC DE LA EMPRESA.
        await this.props.client
        .query({
            query: gql`
            {
                personasJuridicasCcc(ORDEN:"ID_CCC,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_CCC,CCC,PRINCIPAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
        
            let cuentas=[]
            for(let ccc of result.data.personasJuridicasCcc){
                if(ccc.PRINCIPAL===false){
                    cuentas.push({"id":ccc.ID_CCC,"numero":ccc.CCC,"principal":ccc.PRINCIPAL})
                }else{
                    //ponemos la principal en primer lugar y la marcamos como seleccionada en la tabla.
                    cuentas.splice(0,0,{"id":ccc.ID_CCC,"numero":ccc.CCC,"principal":ccc.PRINCIPAL})
                }
            }

            this.setState({CCC:cuentas})
        
        })
        
    }

    cargarCIF=async ()=>{

         //OBTENCION DE LA PERSONA JURIDICA Y EL CIF DE LA EMPRESA.
         await this.props.client
         .query({
         query: gql`
         {
             documentosIdentificativos(FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada},ID_TIPO_DOCUMENTO:4}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR,FECHA_EMISION,FECHA_CADUCIDAD}
         }
         `,
         fetchPolicy: 'network-only'

         }).then(result=>{ 

             if(result.data.documentosIdentificativos.length>0){
                 this.setState({
                    cif:result.data.documentosIdentificativos[0].VALOR,
                    cifMod:result.data.documentosIdentificativos[0].VALOR,
                    existeDocumento:true,
                    bolFechaEmision:result.data.documentosIdentificativos[0].FECHA_EMISION,
                    bolFechaCaducidad:result.data.documentosIdentificativos[0].FECHA_CADUCIDAD,
                    idDocumento:result.data.documentosIdentificativos[0].ID_DOCUMENTO_IDENTIFICATIVO,
                })
        
                 if(result.data.documentosIdentificativos[0].FECHA_CADUCIDAD!==null)this.setState({fechaCaducidad:new Date(result.data.documentosIdentificativos[0].FECHA_CADUCIDAD)})
                 if(result.data.documentosIdentificativos[0].FECHA_EMISION!==null)this.setState({fechaEmision:new Date(result.data.documentosIdentificativos[0].FECHA_EMISION)})
             }
         })
    }

    //cuando se haya montado el componente lo rellenamos con sus datos.
    componentDidMount=async()=>{

        //DATOS" DE LA EMPRESA
        await this.cargarDatosEmpresa()
        //DATOS GRUPOS EMPRESARIALES
        await this.cargarGrupos()
        //CARGA DE CUENTAS
        await this.cargarCuentas()
        //CARGAR CIF
        await this.cargarCIF()
    }

    handleGuardarDatos =async () => {

        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:'global'}))
            return
        }

        const validate=(url) =>{
            var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
            if (pattern.test(url)) {
                return true;
            } 
            return false;
        }

        //si el usuario no tiene permisos para editar, no hacemos nada.
        if(this.props.editable===false)return

        //si nos escriben un sitio web comprobar que este es válido.
        if(this.state.sitioWeb){
            //comprobar si el sitio web es valido.
            if(validate(this.state.sitioWeb)===false){

                this.setState({urlValida:false})
                return
            }else{

                this.setState({urlValida:true})
            }

        }

        //si nos escriben un sitio web canal de denuncias comprobar que este es válido.
        if(this.state.sitioWebCanalDenuncias){
          //comprobar si el sitio web es valido.
          if(validate(this.state.sitioWebCanalDenuncias)===false){

              this.setState({urlValidaCanalDenuncias:false})
              return
          }else{

              this.setState({urlValidaCanalDenuncias:true})
          }

        }
      
   
        //comprobacion razon social
        if(!this.state.razonSocial){
            return;
        }

        //comporbacion de las fechas.
        if(this.state.fechaInicio===null || this.state.fechaFin===null){
            return;
        }

        //cogemos los convenios insertados
        var convenios=""
        this.state.convenios.forEach(convenio=>{
            convenios+=convenio.nombre+"|"
        })
        convenios=convenios.substring(0,convenios.length-1)

        //Ejecutamos la query para actualizar los convenios.
        this.props.client.mutate({
        mutation: gql`
            mutation{actualizarPersonaJuridica(ID_PERSONA:${this.props.empresaSeleccionada},VALORES:{RAZON_SOCIAL:"${this.state.razonSocial}",NOMBRE_COMERCIAL:${this.state.nombreComercial?'"'+this.state.nombreComercial+'"':null},ID_GRUPO_EMPRESARIAL:${this.state.grupoElegido},INICIO_EJERCICIO:"${moment(this.state.fechaInicio).format('YYYY-MM-DD')}",FIN_EJERCICIO:"${moment(this.state.fechaFin).format('YYYY-MM-DD')}",WEB_CORPORATIVA:${this.state.sitioWeb?'"'+this.state.sitioWeb+'"':null},WEB_CANAL_DENUNCIAS:${this.state.sitioWebCanalDenuncias?'"'+this.state.sitioWebCanalDenuncias+'"':null},CONVENIOS_COLECTIVOS:${convenios?'"'+convenios+'"':null}}){ID_PERSONA}}
        `
        }).then(result=>{
            alert(i18next.t("updatecorrecto",{ns:'global'}))            

        }).catch(err=>{
            alert(i18next.t("error",{ns:'global'}))
        })

    }

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("empresa.informacion",{ns:"company"});
  
        //si no se han cargado los datos...
        if (!this.state.cargado) {
             return <Cargando />
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY",
        }
        
        const seleccionGrupo = (value) => {
            this.setState({grupoElegido: value.value})
        }

        const cambiarWeb = (ev) => {
            this.setState({sitioWeb: ev.currentTarget.value})
        }

        const cambiarWebCanalDenuncias = (ev) => {
          this.setState({sitioWebCanalDenuncias: ev.currentTarget.value})
        }

        const abrirWebPestaña = () => {
            let win = window.open (this.state.sitioWeb, '_blank')
            win.focus()
        }

        const abrirWebPestañaCanalDenuncias = () => {
          let win = window.open (this.state.sitioWebCanalDenuncias, '_blank')
          win.focus()
        }
        
        const cambioFecha = (value) => {
            this.setState({fechaInicio:value[0]})
            this.setState({fechaFin:value[1]})
        }

        const handleCambarRazonSocial = (e) => {
            this.setState({razonSocial:e.currentTarget.value})
        }

        const handleConvenioColectivo = (e) => {
            this.setState({convenioColectivo:e.currentTarget.value})
        }

        const handleGrupoEmpresarial = (e) => {
            this.setState({grupoEmpresarial:e.currentTarget.value})
        }

        const handleNombreComercial = (e) => {
            this.setState({nombreComercial:e.currentTarget.value})
        }

        const abrirModalCifNuevo =async() => {

            //si no puede editar esta pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            //limpiamos antiguos valores
            this.setState({
                cifMod:null,
                bolFechaEmision:false,
                bolFechaCaducidad:false,
                fechaEmision:null,
                fechaCaducidad:null
            })

            if(this.state.existeDocumento){
                await this.cargarCIF()
            }else{
                this.setState({cif:null})
            }
            this.setState({modalNuevoCifAbierto: true})
        }
        const abrirModalCuentaNuevo = () => {

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }
       
            this.setState({modalCuentaAbierto: true,
            cargandoCuenta:true})
            this.setState({nuevaCuentaActivo: true})
            this.setState({editarCuenta: false})
            this.setState({eliminarCuenta: false})

            this.setState({ccc:null})
            this.setState({cuentaSeleccionada:null})
            if(this.state.CCC.length===0){
                this.setState({esPrincipal:true})
            }else{
                this.setState({esPrincipal:false})
            }

        }

        const abrirmodalCuentaAbierto = () => {

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return false;
            }
         
            if(!this.state.cuentaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return false;
            }

            this.setState({nuevaCuentaActivo: false})
            this.setState({editarCuenta: true})
            this.setState({modalCuentaAbierto: true,
            cargandoCuenta:true})
            this.setState({eliminarCuenta: false})

            this.setState({ccc:this.state.cuentaSeleccionada.numero})
            this.setState({esPrincipal:this.state.cuentaSeleccionada.principal})

        }

        const abrirModalCuentaEliminar = () =>{

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return false;
            }

            if(!this.state.cuentaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return false;
            }
            this.setState({nuevaCuentaActivo: false})
            this.setState({editarCuenta: false})
            this.setState({eliminarCuenta: true})
            this.setState({modalCuentaBorrar: true})

        }

        
   /* const validaCif = (cadena) =>
        { 
            if (cadena==="")
            {
                return false;
            }
            var texto=cadena;
            var pares = 0; 
            var impares = 0; 
            var suma; 
            var ultima; 
            var unumero; 
            var uletra = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"]; 
            var xxx; 
            
            texto = texto.toUpperCase(); 
            
            var regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g); 
            if (!regular.exec(texto)) 
            {
                return false
            }
               
            ultima = texto.substr(8,1); 
    
            for (var cont = 1 ; cont < 7 ; cont ++){ 
                xxx = (2 * parseInt(texto.substr(cont++,1))).toString() + "0"; 
                impares += parseInt(xxx.substr(0,1)) + parseInt(xxx.substr(1,1)); 
                pares += parseInt(texto.substr(cont,1)); 
            } 
            xxx = (2 * parseInt(texto.substr(cont,1))).toString() + "0"; 
            impares += parseInt(xxx.substr(0,1)) + parseInt(xxx.substr(1,1)); 
            
            suma = (pares + impares).toString(); 
            unumero = parseInt(suma.substr(suma.length - 1, 1)); 
            unumero = (10 - unumero).toString(); 
            if(unumero === 10) unumero = 0; 
            
            if ((ultima === unumero) || (ultima === uletra[unumero])) 
                return true; 
            else {
                return false
            }
 
        }    */

        
        const anadirCIF = async() => {

            if(!this.state.cifMod)return
            //if(!validaCif(this.state.cifMod)){
             //   this.setState({cifInvalido:true})
            //    return
            //}else{
                this.setState({cifInvalido:false})
            //}
            this.setState({cif:this.state.cifMod})

            //insertamos el cif en la bdd
            if(!this.state.existeDocumento){
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{crearDocumentoIdentificativo(VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},ID_TIPO_DOCUMENTO:${this.state.tipoDocumento},VALOR:"${this.state.cifMod}",FECHA_EMISION:${this.state.fechaEmision?'"'+moment(this.state.fechaEmision).format('YYYY-MM-DD')+'"':null},FECHA_CADUCIDAD:${this.state.fechaCaducidad?'"'+moment(this.state.fechaCaducidad).format('YYYY-MM-DD')+'"':null}}){ID_DOCUMENTO_IDENTIFICATIVO}}
                    `
                }).then(result=>{
                    alert(i18next.t("insertcorrecto",{ns:'global'}))
                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })

            }else{

                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarDocumentoIdentificativo(ID_DOCUMENTO_IDENTIFICATIVO:${this.state.idDocumento},VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},ID_TIPO_DOCUMENTO:${this.state.tipoDocumento},VALOR:"${this.state.cifMod}",FECHA_EMISION:${this.state.fechaEmision?'"'+moment(this.state.fechaEmision).format('YYYY-MM-DD')+'"':null},FECHA_CADUCIDAD:${this.state.fechaCaducidad?'"'+moment(this.state.fechaCaducidad).format('YYYY-MM-DD')+'"':null}}){ID_DOCUMENTO_IDENTIFICATIVO}}
                    `
                }).then(result=>{
                    alert(i18next.t("updatecorrecto",{ns:'global'}))
                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })

            }

            cerrarModal()
        }

        const anadirCuenta = async() => {

            if(!this.state.ccc)return

           
            let existe=this.state.CCC.find(cuenta=>{return cuenta.numero===this.state.ccc})
            if(existe){
                alert(i18next.t("yaexiste",{ns:'global'}))
                return
            }

            let cuentas=this.state.CCC
            if(this.state.esPrincipal){
                cuentas.forEach((cuenta,index)=>{
                    cuentas[index].principal=false
                })
            }

            //si nos marcan es principal, desmarcamos la antigua.
            if(this.state.esPrincipal){

                await this.props.client
                .query({
                    query: gql`
                    {
                        personasJuridicasCcc(FILTROS:{PRINCIPAL:true}){ID_CCC,CCC,PRINCIPAL}
                    }
                    `,
                    fetchPolicy: 'network-only'

                }).then(async result=>{ 

                    if(result.data.personasJuridicasCcc.length>0){

                        await this.props.client.mutate({
                            mutation: gql`
                                mutation{actualizarPersonaJuridicaCcc(ID_CCC:${result.data.personasJuridicasCcc[0].ID_CCC},VALORES:{PRINCIPAL:false}){ID_CCC}}
                            `
                        })
                        
                    }

                })

            }

            //añadimos la cuenta a la bdd
            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearPersonaJuridicaCcc(VALORES:{CCC:"${this.state.ccc}",ID_PERSONA:${this.props.empresaSeleccionada},PRINCIPAL:${this.state.esPrincipal}}){ID_CCC}}
                `
            }).then(async result=>{
               
                //si todo ha ido ok.
                alert(i18next.t("insertcorrecto",{ns:'global'}))
                await this.cargarCuentas()

                
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

            cerrarModal()
        }
        const actualizarCuenta = async() => {

            if(!this.state.ccc)return

            let existe=this.state.CCC.find(cuenta=>{
                return cuenta.numero===this.state.ccc && cuenta.id!==this.state.cuentaSeleccionada.id
            })
            if(existe){
                alert(i18next.t("yaexiste",{ns:'global'}))
                return
            }

             //si nos marcan es principal, desmarcamos la antigua.
             if(this.state.esPrincipal){
                await this.props.client
                .query({
                    query: gql`
                    {
                        personasJuridicasCcc(FILTROS:{PRINCIPAL:true}){ID_CCC,CCC,PRINCIPAL}
                    }
                    `,
                    fetchPolicy: 'network-only' 

                }).then(async result=>{ 

                    if(result.data.personasJuridicasCcc.length>0){

                        await this.props.client.mutate({
                                mutation: gql`
                                    mutation{actualizarPersonaJuridicaCcc(ID_CCC:${result.data.personasJuridicasCcc[0].ID_CCC},VALORES:{PRINCIPAL:false}){ID_CCC}}
                                `
                        }).catch(err=>{
                            alert(i18next.t("error",{ns:'global'}))
                        })
                        
                    }

                })

            }


            //aCtualizamos la cuenta
            await this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarPersonaJuridicaCcc(ID_CCC:${this.state.cuentaSeleccionada.id},VALORES:{CCC:"${this.state.ccc}",ID_PERSONA:${this.props.empresaSeleccionada},PRINCIPAL:${this.state.esPrincipal}}){ID_CCC}}
                `
            }).then(async result=>{

                alert(i18next.t("updatecorrecto",{ns:'global'}))
                await this.cargarCuentas()
                //reseleccionamos la fila con los nuevos datos.
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })
            
            cerrarModal()

        }

        const eliminarCuenta=async()=>{

            await this.props.client.mutate({
                mutation: gql`
                    mutation{eliminarPersonaJuridicaCcc(ID_CCC:${this.state.cuentaSeleccionada.id}){ID_CCC}}
                `
            }).then(result=>{
            
                alert(i18next.t("deletecorrecto",{ns:'global'}))
                this.setState({
                    cuentaSeleccionada:null,
                })
                this.cargarCuentas()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

            cerrarModal()

        }


        const marcarPrincipal=async ()=>{

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return false;
            }

            let hayError=false

            if(!this.state.cuentaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return false;
            }
            this.setState({cargandoCuenta:true});
            //quitamos la principal anterior.
            /*
            let cuentas=this.state.CCC
            
            cuentas.forEach((cuenta,index)=>{
                if(cuenta.id===this.state.cuentaSeleccionada.id){
                    cuentas[index]["principal"]=true
                }else{
                    cuentas[index]["principal"]=false
                }
            })
            */

            await  this.props.client
            .query({
                query: gql`
                {
                    personasJuridicasCcc(FILTROS:{PRINCIPAL:true}){ID_CCC,CCC,PRINCIPAL}
                }
                `,
                fetchPolicy: 'network-only'

            }).then(async result=>{ 

                if(result.data.personasJuridicasCcc.length>0){

                    await this.props.client.mutate({
                        mutation: gql`
                            mutation{actualizarPersonaJuridicaCcc(ID_CCC:${result.data.personasJuridicasCcc[0].ID_CCC},VALORES:{PRINCIPAL:false}){ID_CCC}}
                        `
                    }).catch(err=>{
                        hayError=true
                        alert(i18next.t("error",{ns:'global'}))
                    })
                }

                if(hayError)return

                //actualizamos la nueva
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarPersonaJuridicaCcc(ID_CCC:${this.state.cuentaSeleccionada.id},VALORES:{PRINCIPAL:true}){ID_CCC}}
                    `
                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })

            })

            await this.cargarCuentas()

            this.setState({cargandoCuenta:false},()=>{
                alert(i18next.t("updatecorrecto",{ns:'global'}))
            })

        }
        
        const handleCancelar=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            //si el usuario no tiene permisos para editar, no hacemos nada.
            if(this.props.editable===false)return

            this.setState(estadoInicial)
            this.cargarDatosEmpresa()
            this.cargarGrupos()
            //recarga del cif
             
            if(this.state.existeDocumento){
                this.cargarCIF()
            }
            this.cargarCuentas()
            this.setState({ccc:null})
            this.setState({cuentaSeleccionada:null})

        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    cuentaSeleccionada: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectConvenio=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    conveniosSeleccionado: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleEliminarConvenios = () => {

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return false;
            }

            if(!this.state.conveniosSeleccionado){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return false;
            }
            
            let convenios=this.state.convenios.filter(convenio => convenio.id !== this.state.conveniosSeleccionado.id)
            this.setState({convenios:convenios,conveniosSeleccionado:null})
        }

        const abrirModalConvenio=()=>{

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return false;
            }

            this.setState({
                modalConvenioAbierto:true,
                cargandoConvenio:true
            })
        }

        const abrirModalGrupo=()=>{

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            this.setState({modalGrupoEmpresarial:true})
        }

        const cerrarModal=()=>{
            this.setState({
                modalConvenioAbierto:false,
                modalGrupoEmpresarial:false,
                modalNuevoCifAbierto:false,
                modalCuentaAbierto:false,
                modalCuentaBorrar:false,
                cargandoConvenio:false,
                cargandoCuenta:false
            })
            
        }

        const anadirConvenio=()=>{
            if(!this.state.convenioColectivo){
                return
            }
            
            let convenios=this.state.convenios
            convenios.push({"id":convenios.length,"nombre":this.state.convenioColectivo})
            this.setState({convenios:convenios})
            cerrarModal()
        }

        const anadirGrupo=()=>{

            //si no se escribe nada.
            if(!this.state.grupoEmpresarial)return

            //insertamos el grupo empresarial.
            this.props.client.mutate({
            mutation: gql`
                mutation{crearGrupoEmpresarial(VALORES:{DESCRIPCION:"${this.state.grupoEmpresarial}"}){ID_GRUPO_EMPRESARIAL}}
            `
            }).then(async result=>{
                alert(i18next.t("insertcorrecto",{ns:'global'}))
                await this.cargarGrupos()
                this.setState({grupoElegido:result.data.crearGrupoEmpresarial.ID_GRUPO_EMPRESARIAL})
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

            cerrarModal()
        }

        const handleCambiarFechaCaducidad = (value) => {
            this.setState({fechaCaducidad:value})
        };

        const handleCambiarFechaEmision = (value) => {
            this.setState({fechaEmision:value})
        };

        const handleCambiarCif=(e)=>{
            this.setState({cifMod:e.currentTarget.value})  
        }

        const handleCambiarCCC=(e)=>{
            this.setState({ccc:e.currentTarget.value})  
        }

        const activarFechaEmision=()=>{

            let value=!this.state.bolFechaEmision

            this.setState({bolFechaEmision:value})
            if(value){
                this.setState({fechaEmision:new Date()})
            }else{
                this.setState({fechaEmision:null})
            }
        }

        const activarFechaCaducidad=()=>{

            let value=!this.state.bolFechaCaducidad

            this.setState({bolFechaCaducidad:value})

            if(value){
                this.setState({fechaCaducidad:new Date()})
            }else{
                this.setState({fechaCaducidad:null})
            }
        }

        const activarEsPrincipal=()=>{
            this.setState({esPrincipal:!this.state.esPrincipal})
        }

        const columnsConvenios = [
            {
                dataField: 'nombre',
                text: i18next.t("empresa.tabinformacion.conveniosaplicables",{ns:'company'}),
                sort: true,
            }
        ];

        const nameCode=(cell,row)=>{
            let name='';
            if (row.principal) {
                name="Principal:";
            }
            name+=cell;
            return (
                name
            );
        }

        const columns = [
            {
                dataField: 'numero',
                text: i18next.t("empresa.tabinformacion.codigoscuentacotizacion",{ns:'company'}),
                sort: true,
                formatter: nameCode
            }
        ];

        return (
            <>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.existeDocumento?i18next.t("empresa.tabinformacion.formCIF.titleedit",{ns:'company'}):i18next.t("empresa.tabinformacion.formCIF.titleadd",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoCifAbierto}
                >
                    <div>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("empresa.tabinformacion.CIF",{ns:'company'})}
                                labelFor="cif"
                                intent="danger"
                                helperText={this.state.cifMod?(this.state.cifInvalido?i18next.t("empresa.tabinformacion.formCIF.validateCIF",{ns:'company'}):""):i18next.t("empresa.tabinformacion.formCIF.emptyCIF",{ns:'company'})}
                            >
                                <InputGroup
                                    name="Valor"
                                    placeholder={i18next.t("empresa.tabinformacion.formCIF.placeholderCIF",{ns:'company'})}
                                    onChange={handleCambiarCif}
                                    defaultValue={this.state.cif}
                                    intent={this.state.cifMod?(this.state.cifInvalido?"danger":"primary"):"danger"}
                                />
                            </FormGroup>
                        </div>
                        <div className="piexlAlrededor">
                            <FormGroup
                                labelFor="fecha emision"
                                intent="danger"
                            >
                                <Checkbox checked={this.state.bolFechaEmision} onChange={activarFechaEmision} />
                                <label>{i18next.t("empresa.tabinformacion.formCIF.fechaemision",{ns:'company'})}: </label>
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={handleCambiarFechaEmision}
                                    value={this.state.fechaEmision}
                                    placeholder="DD-MM-YYYY"
                                    disabled={!this.state.bolFechaEmision}
                                    defaultValue={this.state.bolFechaEmision?new Date():null}
                                />
                            </FormGroup>
                        </div>
                        <div>
                            <Checkbox checked={this.state.bolFechaCaducidad} onChange={activarFechaCaducidad} />
                            <label>Fecha de caducidad: </label>
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                defaultValue={this.state.bolFechaCaducidad?new Date():null}
                                onChange={handleCambiarFechaCaducidad}
                                value={this.state.fechaCaducidad}
                                placeholder="DD-MM-YYYY"
                                disabled={!this.state.bolFechaCaducidad}
                            />
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:'global'})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar", {ns: 'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirCIF}
                            >
                                {i18next.t("agregar", {ns: 'global'})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevaCuentaActivo?i18next.t("empresa.tabinformacion.formCuenta.titleadd",{ns:'company'}):i18next.t("empresa.tabinformacion.formCuenta.titleedit",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalCuentaAbierto}
                >
                    <div className="pixelAlrededor">
                        <FormGroup
                            label={i18next.t("empresa.tabinformacion.formCuenta.numberaccount",{ns:'company'})}
                            labelFor="informacion"
                            intent="danger"
                            helperText={this.state.ccc?"":i18next.t("empresa.tabinformacion.formCuenta.validationnumberaccount",{ns:'company'})}
                        >
                            <InputGroup
                                name="Valor"
                                placeholder={i18next.t("empresa.tabinformacion.formCuenta.placeholdernumberaccount",{ns:'company'})}
                                onChange={handleCambiarCCC}
                                intent={this.state.ccc?"primary":"danger"}
                                maxLength={20}
                                defaultValue={this.state.cuentaSeleccionada?this.state.cuentaSeleccionada.numero:""}
                            />
                        </FormGroup>
                        <Checkbox disabled={this.state.CCC.length===0 || (this.state.cuentaSeleccionada?this.state.cuentaSeleccionada.principal:false)?true:false}  checked={this.state.esPrincipal} onChange={activarEsPrincipal} label={i18next.t("empresa.tabinformacion.formCuenta.accountdefault",{ns:'company'})} />

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:'global'})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaCuentaActivo?i18next.t("empresa.tabinformacion.formCuenta.titleadd",{ns:'company'}):i18next.t("empresa.tabinformacion.formCuenta.titleedit",{ns:'company'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaCuentaActivo?anadirCuenta:actualizarCuenta}
                            >
                            {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabinformacion.formCuenta.titledel",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalCuentaBorrar}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("empresa.tabinformacion.formCuenta.questiondel",{ns:'company'})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarCuenta}
                            >
                                {i18next.t("yes",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabinformacion.formconveniocolectivo.title",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalConvenioAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("empresa.tabinformacion.formconveniocolectivo.name",{ns:'company'})}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.convenioColectivo?"":i18next.t("empresa.tabinformacion.formconveniocolectivo.validationname",{ns:'company'})}
                        >
                            <InputGroup id="texto-nombre-convenio" onChange={handleConvenioColectivo}  placeholder={i18next.t("empresa.tabinformacion.formconveniocolectivo.placeholdername",{ns:'company'})} intent={this.state.convenioColectivo?"primary":"danger"} />
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("agregar", {ns: 'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirConvenio}
                            >
                                {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={i18next.t("empresa.tabinformacion.formgrupoempresarial.title",{ns:'company'})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalGrupoEmpresarial}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={i18next.t("empresa.tabinformacion.formgrupoempresarial.name",{ns:'company'})}
                    labelFor="informacion"
                    intent="danger"
                    helperText={this.state.grupoEmpresarial?"":i18next.t("empresa.tabinformacion.formgrupoempresarial.validationname",{ns:'company'})}
                    >
                        <InputGroup id="texto-nombre-grupo" onChange={handleGrupoEmpresarial} placeholder={i18next.t("empresa.tabinformacion.formgrupoempresarial.placeholdername",{ns:'company'})} intent={this.state.grupoEmpresarial?"primary":"danger"} />
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("agregar", {ns: 'global'})} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={anadirGrupo}
                        >
                            {i18next.t("aceptar",{ns:'global'})}
                        </AnchorButton>
                    </Tooltip>
                    <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                        <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:'global'})}</Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
        
            <div className="tablaMitadIzquierda separacionCentralTarjetas">
            { /* razón social y convenio colectivo */  }
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande celdasInfoGeneral">
            <FormGroup
                label={i18next.t("empresa.tabinformacion.razonsocial",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={(this.state.razonSocial && this.state.razonSocial!=="")?"":i18next.t("empresa.tabinformacion.validacionrazonsocial",{ns:'company'})}
                className=".primer-paso"
            >
                <InputGroup id="texto-razon-social" value={this.state.razonSocial?this.state.razonSocial:""} onChange={handleCambarRazonSocial} placeholder={i18next.t("placeholder",{ns:'global'})} intent={(this.state.razonSocial)?"primary":"danger"}/>
            </FormGroup>

            <FormGroup
            label={i18next.t("empresa.tabinformacion.nombrecomercial",{ns:'company'})}
            labelFor="informacion"
            >
                <InputGroup id="texto-nombre-comercial" value={this.state.nombreComercial?this.state.nombreComercial:""} onChange={handleNombreComercial} placeholder={i18next.t("placeholder",{ns:'global'})} intent="primary"/>
            </FormGroup>
            <FormGroup
            label={i18next.t("empresa.tabinformacion.CIF",{ns:'company'})}
            labelFor="informacion"
            >
                    <InputGroup
                        name="CIF"
                        placeholder={i18next.t("empresa.tabinformacion.placeholderCIF",{ns:'company'})}
                        value={this.state.cif?this.state.cif:""}
                        disabled
                        className="tablaOchenta"
                    />
                    <Button 
                        alignText="right"
                        icon="edit"
                        disabled
                        className="iconoIzquierda"
                        onClick={abrirModalCifNuevo}
                    />
            </FormGroup>
            

            <Navbar>
                <Navbar.Group>
                    <NavbarHeading>{i18next.t("empresa.tabinformacion.convenioscolectivos",{ns:'company'})}</NavbarHeading>
                    <ButtonGroup align={Alignment.LEFT}>
                        <React.Fragment key="fragment">
                            <Button 
                                alignText="right"
                                icon="add"
                                className="iconoIzquierda"
                                onClick={abrirModalConvenio}
                            />
                            <Button 
                                alignText="right"
                                icon="delete"
                                onClick={handleEliminarConvenios}
                                className="iconoIzquierda"
                            /> 
                        </React.Fragment>
                    </ButtonGroup>
                </Navbar.Group>
            </Navbar>
            {this.state.cargandoConvenio?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.convenios }
                columns={ columnsConvenios }
                selectRow={ const_table.selectRow(handleOnSelectConvenio) }
                pagination={ paginationFactory(const_table.options) }
                defaultSorted={ const_table.defaultSorted('nombre','asc') }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={i18next.t("datavacio",{ns:'global'})}
            />
            }
            <Navbar>
                <Navbar.Group>
                    <NavbarHeading>{i18next.t("empresa.tabinformacion.codigoscuentacotizacion",{ns:'company'})}</NavbarHeading>
                    <ButtonGroup align={Alignment.LEFT}>
                        <React.Fragment key="fragment">
                            <Button className="bp3-minimal" icon="add"  active={this.state.nuevaCuentaActivo} onClick={abrirModalCuentaNuevo} />
                            <Button className="bp3-minimal" icon="edit" active={this.state.editarCuenta} onClick={abrirmodalCuentaAbierto}  />
                            <Button className="bp3-minimal" icon="delete" active={this.state.eliminarCuenta} onClick={abrirModalCuentaEliminar}  disabled={this.state.cuentaSeleccionada?this.state.cuentaSeleccionada.principal:false} />
                            <Button className="bp3-minimal" icon="star" active={this.state.principalActivado} onClick={marcarPrincipal} disabled={this.state.cuentaSeleccionada?this.state.cuentaSeleccionada.principal:false}  />
                        </React.Fragment>
                    </ButtonGroup>
                </Navbar.Group>
            </Navbar>
            {this.state.cargandoCuenta?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.CCC }
                columns={ columns }
                selectRow={ const_table.selectRow(handleOnSelect) }
                pagination={ paginationFactory(const_table.options) }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={i18next.t("datavacio",{ns:'global'})}
            />
            }
            <Button 
                alignText="left"
                icon="cross"
                text={i18next.t("cancelar",{ns:'global'})}
                intent="danger"
                onClick={handleCancelar}
                className="iconoDerecha bp3-minimal"
            />
            <Button
                alignText="right"
                icon="floppy-disk"
                text={i18next.t("guardar",{ns:'global'})}
                intent="danger"
                onClick={this.handleGuardarDatos}
                className="iconoDerecha bp3-minimal"
            />
            <br />
            <br />
                </Card>
            </div>

            <div className="tablaMitadDerecha">
            { /* Grpo empresarial */ }
            <div>
            <Card interactive={true} elevation={Elevation.TWO} className="celdasInfoGeneral">
            <FormGroup
            label={i18next.t("empresa.tabinformacion.grupoempresarial",{ns:'company'})}
            labelFor="informacion"
            >
            <div>
                <Select 
                    value={this.state.grupos.filter(grupo => grupo.value === this.state.grupoElegido?this.state.grupoElegido:0)}
                    onChange={seleccionGrupo}
                    options={this.state.grupos}
                    placeholder={i18next.t("empresa.tabinformacion.placeholdergrupoempresarial",{ns:'company'})}
                    className="grupoEmpresarial"
                />
            </div>
            <div>
                <Button 
                    alignText="right"
                    icon="add"
                    className="iconoIzquierda"
                    onClick={abrirModalGrupo}
                    
                />           
                </div>
            </FormGroup>

            <FormGroup
            label={i18next.t("empresa.tabinformacion.sitiowebcorporativo",{ns:'company'})}
            labelFor="informacion"
            helperText={this.state.urlValida?"":i18next.t("empresa.tabinformacion.validacionsitiowebcorporativo",{ns:'company'})}
            intent={this.state.urlValida?"":"danger"}
            >   
                <div>
                    <InputGroup 
                        id="sitio-web-corporativo" 
                        placeholder={i18next.t("empresa.tabinformacion.placeholdersitiowebcorporativo",{ns:'company'})} 
                        intent={this.state.urlValida?"primary":"danger"}

                        value={this.state.sitioWeb?this.state.sitioWeb:""}
                        onChange={cambiarWeb}
                    />
                </div>
                <div>
                    <Button 
                        alignText="right"
                        icon="export"
                        className="iconoIzquierda"
                        onClick={abrirWebPestaña}
                    />
                </div> 
            </FormGroup>
            
            <FormGroup
            label={i18next.t("empresa.tabinformacion.sitiowebcanaldenuncias",{ns:'company'})}
            labelFor="informacion"
            helperText={this.state.urlValidaCanalDenuncias?"":i18next.t("empresa.tabinformacion.validacionsitiowebcanaldenuncias",{ns:'company'})}
            intent={this.state.urlValidaCanalDenuncias?"":"danger"}
            >   
                <div>
                    <InputGroup 
                        id="sitio-web-canal-denuncias" 
                        placeholder={i18next.t("empresa.tabinformacion.placeholdersitiowebcanaldenuncias",{ns:'company'})} 
                        intent={this.state.urlValidaCanalDenuncias?"primary":"danger"}

                        value={this.state.sitioWebCanalDenuncias?this.state.sitioWebCanalDenuncias:""}
                        onChange={cambiarWebCanalDenuncias}
                    />
                </div>
                <div>
                    <Button 
                        alignText="right"
                        icon="export"
                        className="iconoIzquierda"
                        onClick={abrirWebPestañaCanalDenuncias}
                    />
                </div> 
            </FormGroup>
            
            <FormGroup
            label={i18next.t("empresa.tabinformacion.ejercicioempresaentrefechas",{ns:'company'})}
            labelFor="informacion"
            helperText={(this.state.fechaInicio===null || this.state.fechaFin===null)?i18next.t("empresa.tabinformacion.validacionfechasejercicio",{ns:'company'}):""}
            intent="danger"
            >
                <div style={(!this.state.fechaFin && !this.state.fechaFin)?{border:"1px solid red"}:{}}>
                    <DateRangePicker minDate={new Date(1900, 1 ,1)}
                        shortcuts={false}
                        contiguousCalendarMonths={false}
                        onChange={cambioFecha}
                        localeUtils={MomentLocaleUtils}
                        value={[this.state.fechaInicio, this.state.fechaFin]}
                        locale={i18next.language}
                        intent="danger"
                    />
                </div>
            </FormGroup>
            
            </Card>
            


            </div>
            </div>
            { /* Botones */ }


            </>
        )
    }
}

export default withTranslation()(withApollo(InformacionGeneral))