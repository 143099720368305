import React from 'react';
import { Navbar,FormGroup,Checkbox, Alignment, Button, ButtonGroup, Dialog, Classes, Tooltip, AnchorButton, Intent, InputGroup, Card, Elevation } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from "../Cargando"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class MediosContacto extends React.Component {
    state = {

        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        preferidoActivado: false,
        tipoMedios: [],
        empresaSeleccionada:null,
        valorMedio: "",
        Medios: [],
        modalAbierto: false,
        modalBorrarAbierto: false,
        tipoSeleccionado: "",
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        cargando: true,
        medioSeleccionado:null,
        valorMedioIncorrecto:false,
        valorMedioIncorrectoEditar:false,
        controlesDesactivados:false,
        esPreferido:false,
        cargandoMedios:false,
        modalLeyes:false
    }
    
    cargarMediosContacto=async(empresa)=>{

        //DATOS MEDIOS CONTACTO DE LA EMPRESA SELECCIONADA
        let personasMedios=
        await this.props.client
        .query({
            query: gql`
            {
            personasMediosContacto(FILTROS:{ID_PERSONA:${empresa}},ORDEN:"PREFERIDO,DESC"){ID_PERSONA,ID_MEDIO_CONTACTO,PREFERIDO}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.personasMediosContacto
        })
        let copyContacto=[]
        for(let pm of personasMedios){

            //Mostramos los conctactos de la empresa pasada props   
            await this.props.client
            .query({
                query: gql`
                {
                    mediosContacto(FILTROS:{ID_MEDIO_CONTACTO:${pm.ID_MEDIO_CONTACTO}}){ID_MEDIO_CONTACTO,ID_TIPO_MEDIO_CONTACTO,TIPO_CONTACTO{DESCRIPCION},VALOR}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{
                let contactos=result.data.mediosContacto;
                contactos.forEach((contacto,index)=>{
                    if(pm.PREFERIDO===false){
                        copyContacto.push({"id":contacto.ID_MEDIO_CONTACTO,"idTipo":contacto.ID_TIPO_MEDIO_CONTACTO,"tipo":contacto.TIPO_CONTACTO.DESCRIPCION,"valor":contacto.VALOR,"preferido":pm.PREFERIDO})
                    }else{
                        copyContacto.splice(0,0,{"id":contacto.ID_MEDIO_CONTACTO,"idTipo":contacto.ID_TIPO_MEDIO_CONTACTO,"tipo":contacto.TIPO_CONTACTO.DESCRIPCION,"valor":contacto.VALOR,"preferido":pm.PREFERIDO})
                    }
                })
            })   
        }

        this.setState({Medios:copyContacto,
        cargandoMedios:false});

    }

    //cuando se haya montado el componente lo rellenamos con sus datos.
    componentDidMount(props){

        this.setState({cargando: true})

        //sobreescrimimos el alert para que no se muestren
        this.cargarMediosContacto(this.props.empresaSeleccionada)
        //DATOS TIPO MEDIO CONTACTO
        this.props.client
        .query({
            query: gql`
            {
                tiposMediosContacto{ID_TIPO_MEDIO_CONTACTO,DESCRIPCION}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let copyMedios=[]
            result.data.tiposMediosContacto.forEach((contacto,index)=>{
                copyMedios.push({"id":contacto.ID_TIPO_MEDIO_CONTACTO,"label":contacto.DESCRIPCION,"value":contacto.ID_TIPO_MEDIO_CONTACTO})
            })   
            this.setState({tipoMedios:copyMedios})
        })

        this.setState({cargando: false})

    }

    handleGuardarCambios=async()=>{

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:'global'}))
            return
        }

        if(this.state.esPreferido){

            let medioPreferido=await this.props.client
            .query({
                query: gql`
                {   
                    personasMediosContacto(FILTROS:{PREFERIDO:true}){ID_PERSONA,ID_MEDIO_CONTACTO,PREFERIDO}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{
                return result.data.personasMediosContacto
            })

            if(medioPreferido.length>0){
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarPersonaMedioContacto(ID_PERSONA:${this.props.empresaSeleccionada},ID_MEDIO_CONTACTO:${medioPreferido[0].ID_MEDIO_CONTACTO},VALORES:{PREFERIDO:false}){ID_PERSONA,ID_MEDIO_CONTACTO}}
                    `
                })
            }

        }
      
       
        //si hemos seleccionado la opcion de nuevo.
        if(this.state.pendienteGuardar){
            
            //Ejecutamos la query para crear el medio.
            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearMedioContacto(VALORES:{ID_TIPO_MEDIO_CONTACTO:${this.state.tipoSeleccionado.value},VALOR:"${this.state.valorMedio}"}){ID_MEDIO_CONTACTO}}
                `
            }).then(result=>{
                //insertamos en personas-medios-contacto
                this.props.client.mutate({
                    mutation: gql`
                        mutation{crearPersonaMedioContacto(VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},ID_MEDIO_CONTACTO:${result.data.crearMedioContacto.ID_MEDIO_CONTACTO},PREFERIDO:${this.state.esPreferido}}){ID_MEDIO_CONTACTO,ID_PERSONA}}
                    `
                }).then(async result=>{
                  
                    alert(i18next.t("insertcorrecto",{ns:'global'}))
                    await this.cargarMediosContacto(this.props.empresaSeleccionada)
                    
                    this.setState({
                        pendienteGuardar:false,
                        controlesDesactivados:false,
                    })

                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })
            
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })
                
                
        }
        else if(this.state.pendienteActualizar){

          //Ejecutamos la query para actualizar el medio.
            await this.props.client.mutate({
            mutation: gql`
                mutation{actualizarMedioContacto(ID_MEDIO_CONTACTO:${this.state.medioSeleccionado.id},VALORES:{ID_TIPO_MEDIO_CONTACTO:${this.state.tipoSeleccionado.id},VALOR:"${this.state.valorMedio}"}){ID_MEDIO_CONTACTO}}
            `
            })

            await this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarPersonaMedioContacto(ID_PERSONA:${this.props.empresaSeleccionada},ID_MEDIO_CONTACTO:${this.state.medioSeleccionado.id},VALORES:{PREFERIDO:${this.state.esPreferido}}){ID_MEDIO_CONTACTO,ID_PERSONA}}
                `
            }).then(result=>{
    
                alert(i18next.t("updatecorrecto",{ns:'global'}))
                this.cargarMediosContacto(this.props.empresaSeleccionada)
           
                //reseleccionamos el contacto en la tabla
                this.setState({
                    pendienteActualizar:false,
                    controlesDesactivados:false,
                    cargando:false
                })    

                    

            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

        }
        else if(this.state.pendienteBorrar){

            //eliminamos primero de la tabla personas_medio
            await this.props.client.mutate({
            mutation: gql`
                mutation{eliminarPersonaMedioContacto(ID_PERSONA:${this.props.empresaSeleccionada},ID_MEDIO_CONTACTO:${this.state.medioSeleccionado.id}){ID_MEDIO_CONTACTO}}
            `
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

            //Ejecutamos la query para actualizar el medio.
            await this.props.client.mutate({
              mutation: gql`
                  mutation{eliminarMedioContacto(ID_MEDIO_CONTACTO:${this.state.medioSeleccionado.id}){ID_MEDIO_CONTACTO}}
              `
            }).then(result=>{

                alert(i18next.t("deletecorrecto",{ns:'global'}))
                this.setState({
                    pendienteBorrar:false,
                    medioSeleccionado:null,
                    controlesDesactivados:false
                })
                //this.cargarMediosContacto(this.props.empresaSeleccionada)
                this.setState({controlesDesactivados:false})
                  
            }).catch(err=>{
            alert(i18next.t("error",{ns:'global'}))
            })
        }

        this.setState({tipoSeleccionado:null})
        this.setState({valorMedio:null})
        this.setState({esPreferido:false})

    }

    render() {
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("empresa.medioscontacto",{ns:"company"});
        
        const abrirModalNuevo = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(this.state.Medios.length===0){
                this.setState({esPreferido:true})
            }else{
                this.setState({esPreferido:false})
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                valorMedio:null,
                tipoSeleccionado:null,
                medioSeleccionado:null,
                cargandoMedios:true
            })

        }
        const abrirModalEditar = () => {

             //si no tiene permisos para editar la pantalla
             if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(this.state.medioSeleccionado===null){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }
            //pasamos los valores del medio contacto seleccionado al state,
            //para no realizar tantas comporbaciones a la hora de hacer inserts y actualozaciones
            let tipoSel=this.state.tipoMedios.find(medio =>{
               return medio.value===this.state.medioSeleccionado.idTipo
            })
            this.setState({tipoSeleccionado:tipoSel})
        
            //cambiar el icono activado.
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:true})
            this.setState({borrarActivado:false})
            this.setState({modalAbierto:true,
                cargandoMedios:true})
            this.setState({valorMedio:this.state.medioSeleccionado.valor})
            this.setState({esPreferido:this.state.medioSeleccionado.preferido})

        }

        const validarMedio=()=>{
            //comprobacion de los datos insertados corresponden con el patron adecuado.
            console.log(this.state.tipoSeleccionado.value)
            let patron=""
            switch(this.state.tipoSeleccionado.value){
                case 1:
                case 5:
                    //patron para verificar correo.
                    patron=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                break;  

                case 2:
                case 3:
                case 4:
                    //verificacion telefono y fax
                    patron=/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/
                break;
                
                default:
                break;
            }
            
            //comprobacion de que el medio de contacto es válido.
            if(patron.test(this.state.valorMedio)===false){
                this.setState({
                    valorMedioIncorrecto:true,
                    valorMedioIncorrectoEditar:true
                })
                return false
            }else{
                this.setState({
                    valorMedioIncorrecto:false,
                    valorMedioIncorrectoEditar:false

                })
                return true
            }

        }

        const anadirNuevo = () => {


            if(this.state.valorMedio===null || this.state.valorMedio===""){
                return
            }
            if(this.state.tipoSeleccionado===null || this.state.tipoSeleccionado===""){
                return
            }
            if(!validarMedio())return
            
            //si hacemos al nuevo valor preferido, eliminamos al anterior.
            if(this.state.esPreferido){
                let medios=this.state.Medios
                medios.forEach((medio,index)=>{
                    medios[index]["preferido"]=false
                })
            }

            //si hemos rellenado los dos campos, agregamos los valores al listado.
            this.state.Medios.push({
                "tipo":  this.state.tipoSeleccionado.label,
                "valor": this.state.valorMedio,
                "preferido":this.state.esPreferido
            })

            ////console.log(this.state.Medios)
            this.setState(this.state)
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        }

        const abrirModalEliminar=()=>{

           
             //si no tiene permisos para editar la pantalla
             if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(this.state.medioSeleccionado===null){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }

            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
            this.setState({modalBorrarAbierto:true})

        }

        const actualizarMedio=()=>{

            if(this.state.valorMedio===null || this.state.valorMedio===""){
                return
            }
            if(this.state.tipoSeleccionado===null || this.state.tipoSeleccionado===""){
                return
            }

            if(!validarMedio())return

            //si hemos rellenado los dos campos, agregamos los valores al listado.
            let medios=this.state.Medios
            medios.find((medio,index) =>{
                if(medio.id===this.state.medioSeleccionado.id){
                    medios[index]["tipo"]=this.state.tipoSeleccionado.label
                    medios[index]["valor"]=this.state.valorMedio
                    medios[index]["preferido"]=this.state.esPreferido
                }
                return medio.id===this.state.medioSeleccionado.id
            })

            //ponemos los demas a false
            medios.forEach((medio,index)=>{
                if(medio.id!==this.state.medioSeleccionado.id){
                    medios[index]["preferido"]=false
                }
            })

            this.setState({Medios:medios})
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        }

        const eliminarMedio=()=>{

            var medios=this.state.Medios.filter(medio => medio.id !== this.state.medioSeleccionado.id)
            this.setState({Medios:medios})
            this.setState({pendienteBorrar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        }

        const handleValorChange=(e)=>{
            this.setState({valorMedio:e.currentTarget.value})         
        }

        const handleChangepreferido=()=>{
            this.setState({esPreferido:!this.state.esPreferido})
        }

        const handleCancelar=async()=>{

             //si no tiene permisos para editar la pantalla
             if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            this.setState({
                valorMedio:null,
                tipoSeleccionado:null,
                medioSeleccionado:null,
                pendienteActualizar:false,
                pendienteBorrar:false,
                pendienteGuardar:false,
                controlesDesactivados:false,
            })
  
            await this.cargarMediosContacto(this.props.empresaSeleccionada)

        }

        const handleMarcarPreferido=async ()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(!this.state.medioSeleccionado){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }
            this.setState({cargandoMedios:true});
            //quitamos el anterior preferido
            let medioPreferido=await this.props.client
            .query({
                query: gql`
                {
                    personasMediosContacto(FILTROS:{PREFERIDO:true}){ID_PERSONA,ID_MEDIO_CONTACTO,PREFERIDO}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{
                return result.data.personasMediosContacto
            })

            if(medioPreferido.length>0){
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarPersonaMedioContacto(ID_PERSONA:${this.props.empresaSeleccionada},ID_MEDIO_CONTACTO:${medioPreferido[0].ID_MEDIO_CONTACTO},VALORES:{PREFERIDO:false}){ID_MEDIO_CONTACTO}}
                    `
                })
            }

            /*
            let medios=this.state.Medios
            medios.find((medio,index) =>{
                if(medio.id===this.state.medioSeleccionado.id){
                    medios[index]["preferido"]=true
                }else{
                    if(this.state.esPreferido){
                        medios[index]["preferido"]=false
                    }
                }
            })*/

           await this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarPersonaMedioContacto(ID_PERSONA:${this.props.empresaSeleccionada},ID_MEDIO_CONTACTO:${this.state.medioSeleccionado.id},VALORES:{PREFERIDO:true}){ID_MEDIO_CONTACTO}}
                `
            }).then(async result=>{
                alert(i18next.t("updatecorrecto",{ns:'global'}));
                await this.cargarMediosContacto(this.props.empresaSeleccionada)
            
                
            });

        }

        const abrirModalLeyes = async() => {
            this.setState({
                modalLeyes:true
            });
        }

        const cerrarModalLeyes = () => {
            this.setState({
                modalLeyes: false
            })
        }

        const seleccionTipo  = (value) => { 
            this.setState({tipoSeleccionado: value})
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                valorMedioIncorrecto:false,
                valorMedioIncorrectoEditar:false,
                cargandoMedios:false
            })
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                let tipoSeleccionado=this.state.tipoMedios.find((tipo,index)=>{
                    return tipo.id===row.idTipo
                })
                this.setState({
                    tipoSeleccionado:tipoSeleccionado,
                    medioSeleccionado: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const nameTipo=(cell,row)=>{
            let tipo='';
            if (row.preferido) {
                tipo="Preferido: ";
            }
            return (
                <>
                    <strong>{tipo}</strong><span>{cell}</span>
                </>
            );
        }

        const columns = [
            {
                dataField: 'tipo',
                text: i18next.t("empresa.tabmedioscontacto.type",{ns:'company'}),
                sort: true,
                formatter: nameTipo
            },
            {
                dataField: 'valor',
                text: i18next.t("empresa.tabmedioscontacto.value",{ns:'company'}),
                sort: true,
            }
        ];

        return (
            <>
            {this.state.cargando === true ?
                <Cargando />
                :
                <>
                <Dialog
                    ransitionDuration={400}
                    className="dialog-ancho dialog-scroll"
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModalLeyes}
                    title={i18next.t("empresa.tabmedioscontacto.titleley",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalLeyes}
                >
                    <div id="dialogo-actas">
                        <Card interactive={true} elevation={Elevation.TWO} >
                            
                            <p><AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a9", "_blank")}>{i18next.t("empresa.tabmedioscontacto.articulo",{ns:'company'})}</AnchorButton></p>
                        </Card>
                    </div>
                    <br></br>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                                <Button onClick={cerrarModalLeyes}>{i18next.t("close",{ns:'global'})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("empresa.tabmedioscontacto.titleadd",{ns:'company'}):i18next.t("empresa.tabmedioscontacto.titleedit",{ns:'company'})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                    <div className="pixelAlrededor">
                        <FormGroup
                            label={i18next.t("empresa.tabmedioscontacto.type",{ns:'company'})}
                            labelFor="informacion"
                            className="pixelAlrededorGrande"
                            intent="danger"
                            helperText={!this.state.tipoSeleccionado?i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabmedioscontacto.type",{ns:'company'}):""}
                        >
                            <div style={this.state.tipoSeleccionado?{}:{border:"1px solid red"}}>
                                <Select 
                                    defaultValue={this.state.tipoMedios.filter(tipo => tipo.value === (this.state.medioSeleccionado?this.state.medioSeleccionado.idTipo:0) )}
                                    placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabmedioscontacto.type",{ns:'company'})}
                                    options={this.state.tipoMedios}
                                    onChange={seleccionTipo}
                                />
                            </div>
                        </FormGroup>
                    </div>
    
                    <div className="pixelAlrededor">
                        <FormGroup
                            label={i18next.t("empresa.tabmedioscontacto.value",{ns:'company'})}
                            labelFor="informacion"
                            className="pixelAlrededorGrande"
                            intent="danger"
                            helperText={!this.state.valorMedio?i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmedioscontacto.value",{ns:'company'}):(this.state.valorMedioIncorrecto)?i18next.t("empresa.tabmedioscontacto.validatevalue",{ns:'company'})+` ${(this.state.tipoSeleccionado.value===1 || this.state.tipoSeleccionado.value===5)?i18next.t("empresa.tabmedioscontacto.email",{ns:'company'}):i18next.t("empresa.tabmedioscontacto.phone",{ns:'company'})} `+i18next.t("valid",{ns:'global'}):""}
                        >
                            <InputGroup id="valor-medio" defaultValue={this.state.medioSeleccionado?this.state.medioSeleccionado.valor:''} onChange={handleValorChange} placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmedioscontacto.value",{ns:'company'})} intent={this.state.valorMedioIncorrecto || !this.state.valorMedio?"danger":"primary"} />
                        </FormGroup>
                    </div>
                    <FormGroup
                        labelFor="informacion"
                        className="pixelAlrededorGrande"
                    >
                        {this.state.Medios.length===0?
                            <Checkbox checked={true} disabled label={i18next.t("empresa.tabmedioscontacto.mediofavorito",{ns:'company'})} />
                        :
                            <Checkbox checked={this.state.esPreferido} disabled={this.state.medioSeleccionado && this.state.medioSeleccionado.preferido?true:false} onChange={handleChangepreferido} label={i18next.t("empresa.tabmedioscontacto.mediofavorito",{ns:'company'})}/>
                        }
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:'global'})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirNuevo:actualizarMedio}
                                >
                                   {i18next.t("aceptar",{ns:'global'})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Card>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("empresa.tabmedioscontacto.titledel",{ns:'company'})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("empresa.tabmedioscontacto.questiondel",{ns:'company'})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarMedio}
                                >
                                    {i18next.t("yes",{ns:'global'})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:'global'})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                    <div>
                    <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor tablaCien">
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                {this.state.controlesDesactivados?[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:'global'})} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:'global'})} active={this.state.editarActivado} disabled/>
                                        <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:'global'})} active={this.state.borrarActivado} disabled />
                                        <Button className="bp3-minimal" icon="star" text={i18next.t("favorite",{ns:'global'})} disabled/>
                                        <Button className="bp3-minimal" icon="eye-open" text={i18next.t("seelaws",{ns:'global'})}  onClick={abrirModalLeyes}/>
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:'global'})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:'global'})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:'global'})} active={this.state.borrarActivado} onClick={abrirModalEliminar} disabled={this.state.medioSeleccionado?this.state.medioSeleccionado.preferido:false}/>
                                        <Button className="bp3-minimal" icon="star" text={i18next.t("favorite",{ns:'global'})} active={this.state.preferidoActivado} onClick={handleMarcarPreferido} disabled={this.state.medioSeleccionado?this.state.medioSeleccionado.preferido:false}/>
                                        <Button className="bp3-minimal" icon="eye-open" text={i18next.t("seelaws",{ns:'global'})}  onClick={abrirModalLeyes}/>
                                    </React.Fragment>
                                ]}
                            </ButtonGroup>
                        </Navbar.Group>
    
                        <Navbar.Group align={Alignment.RIGHT}>                    
                            <ButtonGroup>
                                <Button
                                    alignText="right"
                                    icon="floppy-disk"
                                    text={i18next.t("guardar",{ns:'global'})}
                                    disabled={!this.state.controlesDesactivados}
                                    onClick={this.handleGuardarCambios}
                                    className="bp3-intent-danger iconoDerecha bp3-minimal"
                                />
                                <Button 
                                    alignText="left"
                                    icon="cross"
                                    text={i18next.t("cancelar",{ns:'global'})}
                                    disabled={!this.state.controlesDesactivados}
                                    onClick={handleCancelar}
                                    className="bp3-intent-danger iconoIzquierda bp3-minimal"
                                />
                            </ButtonGroup> 
                        </Navbar.Group>
                    </Navbar>
                    {this.state.cargandoMedios?
                        <Cargando />
                    :
                    <BootstrapTable
                        keyField='id'
                        data={ this.state.Medios }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
                    />
                    }
                    </Card>
                    </div>
                </>
            }
        </>
        )
    }
}


export default withTranslation()(withApollo(MediosContacto))