/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Spinner, Callout, Card, Elevation, FormGroup, InputGroup, Navbar, ButtonGroup, Alignment, Button, Dialog, Classes, Tooltip, AnchorButton, Intent } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'
    },
    page:{
        paddingBottom:95,
        paddingTop:30,
        paddingLeft: 25,
        paddingRight: 20
    },
    body:{
        flex: 1,
        backgroundColor: '#FFFFFF',
        marginLeft:20,
    },
    row: {
        flexDirection: 'row',
        width: '100%'
      },
    imageHead: {
        width: '20%',
        maxHeight: 60,
        marginBottom: 20
    },
    rowHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
    },
    rowHeadTwo: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginBottom: 20,
        marginTop:-1
      },
      labelHead: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      colHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      rowTitleOne: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000'
      },
      labelTitle: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        textAlign: 'center'
      },
      text: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
      },
      footerPage: {
        position: 'absolute',
        fontSize: 8,
        bottom: 60,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'black',
        fontFamily: 'Open Sans Bold',
      },
      icono: {
        position: 'absolute',
        float: 'right',
        width: 40,
        bottom: 10,
        right: 20,
      },
      footer: {
        position: 'absolute',
        fontSize: 8,
        bottom: 10,
        left: 20,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        fontFamily: 'Open Sans',
      }
   
})


class Comisiones extends Component {

    state={
        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        pendienteBorrar:false,
        pendienteActualizar:false,
        pendienteGuardar:false,
        comisiones: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        tipoComision: [
        ],
        miembros:[],
        miembrosSeleccionados:[],
        nombre:null,
        tipoSeleccionado:null,
        comisionSeleccionada:null,
        controlesDesactivados:false,
        modalBorrarAbierto:false,
        personasFisicas:null, 
        cargando:false,
        datosEmpresa:null,
        logotipo:null,
        imprimirActivado:false,
        modalImprimir:false,
        cargandoImprimir:false,
        cargandoGrid:false
    }

    cargarEmpresa= async() => {
        
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

       return datosEmpresa
   
   }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
                {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })

       return personasFisicas

       
    }
    cargarDatos=async() => {
       let personasFisicas= await this.cargarPersonasFisicas()
       let datosEmpresa= await this.cargarEmpresa()
       this.setState({
           personasFisicas:personasFisicas,
           datosEmpresa: datosEmpresa
           
       })
    }
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    obtenerMiembrosComisiones=async(comision)=>{

        let miembros= await this.props.client.query({
            query:gql`{comisionesMiembros(FILTROS:{ID_COMISION:${comision.ID_COMISION}}){
                ID_MIEMBRO
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.comisionesMiembros
        })   
            miembros.forEach(async(miembro,index)=>{
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_MIEMBRO){
                        miembros[index].label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                        miembros[index].value=persona.ID_PERSONA
                    }
                })

                // await this.props.client.query({
                //     query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${miembro.ID_MIEMBRO}}){
                //         ID_PERSONA,
                //         NOMBRE,
                //         APELLIDO1,
                //         APELLIDO2
                //     }}`,fetchPolicy:'network-only'
                // }).then(async(result)=>{

                //     let persona=result.data.personasFisicas[0]
                //     miembros[index].label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE} `
                //     miembros[index].value=persona.ID_PERSONA

                // })

            })
                 return miembros

    
        } 
        

    cargarComisiones=async ()=>{

        //buscamos las comisiones
        //cargar la tabla de comisiones.
        let comisiones=await this.props.client.query({
            query:gql`{comisiones{
                ID_COMISION,
                NOMBRE,
                ID_TIPO_COMISION,
                TIPO_COMISION{ID_TIPO_COMISION,DESCRIPCION}
            }}`,fetchPolicy:'network-only'

        }).then(async(result)=>{            
            return JSON.parse(JSON.stringify(result.data.comisiones))
        })

        let miembros= await this.props.client.query({
            query:gql`{comisionesMiembros{
                ID_COMISION,ID_MIEMBRO
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.comisionesMiembros
        })

        

        //buscamos los miembros de cada comision.

        for(let comision of comisiones){
               
            let miembrosVal=[];
            miembros.forEach(async(miembro)=>{
                if (miembro.ID_COMISION===comision.ID_COMISION) {
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===miembro.ID_MIEMBRO){
                            miembro.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                            miembro.value=persona.ID_PERSONA
                            miembrosVal.push(miembro)
                        }
                    })
                }
            })
            
            comision.id=comision.ID_COMISION
            comision.miembros=miembrosVal
            comision.nombre=comision.NOMBRE
            comision.tipo=comision.TIPO_COMISION.DESCRIPCION
            comision.idTipo=comision.TIPO_COMISION.ID_TIPO_COMISION

        }
        this.setState({comisiones:comisiones})

    }
    cargarMiembros=async ()=> {
        let miembros=[]
       await this.state.personasFisicas.forEach(persona=>{
            
            miembros.push({
                value:persona.ID_PERSONA,
                label:` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
            })
        })
       this.setState({
           miembros:miembros
       })

        
    }
    cargarTipoComision= async () => {
         //cargar tipo de comisiones.
         await this.props.client
         .query({
             query: gql` 
             {tiposComision{ID_TIPO_COMISION,DESCRIPCION}}
             `
         }).then(result=>{ 
 
            let tipoComisiones=[]
             result.data.tiposComision.forEach(tipo=>{
                 tipoComisiones.push({
                     value:tipo.ID_TIPO_COMISION,
                     label:tipo.DESCRIPCION
                 })
             })
             this.setState({tipoComision:tipoComisiones})
             console.log(this.state.tipoComision)
         })
    }
    

    componentDidMount(props){
        this.setState({cargando: true}, async () => {
            await this.cargarDatos()
            await this.obtenerLogoEmpresa()       
            await this.cargarComisiones()
            this.setState({
                cargando:false
            })
        })
        // //cargar select de miembros.
        // this.props.client
        // .query({
        //     query: gql` 
        //         {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
        //     `,fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     let miembros=[]
        //     result.data.personasFisicas.forEach(persona=>{
        //         miembros.push({
        //             value:persona.ID_PERSONA,
        //             label:` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
        //         })
        //     })
        //     this.setState({miembros:miembros})
        //     console.log(this.state.miembros)

        // })
    }

    generarPDF= async () =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true,
            cargandoGrid:true,
            comisionSeleccionada:null
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.ImprimirComisiones(), document.getElementById("generarInforme-pdf"))
            })
        })
    }

    ImprimirComisiones=() => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.body}>
                     <View fixed style={styles.row}>
                         <Image style={styles.imageHead} src={this.state.logotipo?this.state.logotipo:pantallaInicio} />
                     </View>
                <View fixed style={styles.rowHead}>
                  <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                  <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
              </View>
              <View fixed style={styles.rowHeadTwo}>
                  <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                  <Text style={styles.text}>{i18next.t("comisiones.title",{ns:"page"}).toUpperCase()}</Text>
              </View>
              <View style={styles.rowTitleOne}>
                  <Text style={styles.labelTitle}>{i18next.t("comisiones.titleprint",{ns:"page"}).toUpperCase()}</Text>
              </View>
              {this.state.comisiones?this.state.comisiones.map((comision, index) => {
                  return(
                    <View key={index}>   
                         {index===0 && (                   
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("comisiones.name",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'45%'}}>{i18next.t("comisiones.type",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{i18next.t("comisiones.nromiembro",{ns:"page"}).toUpperCase()}</Text>
                            </View>
                         )}                   
                        <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{comision.nombre?comision.nombre:''}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'45%'}}>{comision.tipo?comision.tipo:''}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{comision.miembros?comision.miembros.length:0}</Text>
                        </View>
                    </View>
                  )
              }):<></>}
                           
             </View> 
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                        fixed
                      />
                    <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                    />
                  <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
    </PDFViewer>

    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+' | '+i18next.t("comisiones.title",{ns:"page"})

        function memberFormatter(cell, row) {
            let members=(cell)?cell.length:0  
            return (
                members
            );
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({
                cargandoNuevo:true,
                cargandoGrid:true,
                modalAbierto: true,
            },async() =>{
                if (this.state.tipoComision.length===0) {
                    await  this.cargarTipoComision()
                }
                if (this.state.miembros.length===0) {
                    await  this.cargarMiembros()
                }
                this.setState({
                    cargandoNuevo:false,
                    nuevoActivado:true,
                    editarActivado:false,
                    borrarActivado:false,
                    comisionSeleccionada:null,

                    miembrosSeleccionados:[],
                    nombre:null,
                    tipoSeleccionado:null,
                })
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.comisionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            
            this.setState({
                cargandoNuevo:true,
                cargandoGrid:true,
                modalAbierto: true,
            },async() =>{
                if (this.state.tipoComision.length===0) {
                    await  this.cargarTipoComision()
                }
                if (this.state.miembros.length===0) {
                    await  this.cargarMiembros()
                }

                let tipoSel=this.state.tipoComision.find(tipo=>{return tipo.value===this.state.comisionSeleccionada.idTipo})

            
                this.setState({
                    cargandoNuevo:false,
                    nuevoActivado:false,
                    editarActivado:true,
                    borrarActivado:false,

                    miembrosSeleccionados:this.state.comisionSeleccionada.miembros,
                    nombre:this.state.comisionSeleccionada.nombre,
                    tipoSeleccionado:tipoSel
                })
            })

        }

        const abrirModalEliminar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }


            if(!this.state.comisionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
            })

        }

        const columns = [
            {
                dataField: 'nombre',
                text: i18next.t("comisiones.name",{ns:"page"})
            },
            {
                dataField: 'tipo',
                text: i18next.t("comisiones.type",{ns:"page"})
            },
            {
                dataField: 'miembros',
                text: i18next.t("comisiones.nromiembro",{ns:"page"}),
                formatter: memberFormatter
            }
        ];

        const anadirNuevo = (value) => {
            
            if(!this.state.nombre)return

            if(!this.state.tipoSeleccionado)return

            if(this.state.miembrosSeleccionados.length===0)return

            if(this.state.comisiones.find(comision=>{return comision.nombre===this.state.nombre})){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            let comisiones=this.state.comisiones
            comisiones.push({
                nombre:this.state.nombre,
                tipo:this.state.tipoSeleccionado.label,
                miembros:this.state.miembrosSeleccionados
            })

            this.setState({
                comisiones:comisiones,
                pendienteGuardar:true,
                controlesDesactivados:true
            })

            cerrarModal()

        }

        const editarComision = (value) => {

            if(!this.state.nombre)return

            if(!this.state.tipoSeleccionado)return

            if(this.state.miembrosSeleccionados.length===0)return

            if(this.state.comisiones.find(comision=>{return (comision.id!==this.state.comisionSeleccionada.id  &&  comision.nombre===this.state.nombre)})){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            let coms=this.state.comisiones
            //actualizamos la tabla
            this.state.comisiones.find((comision,index)=>{

                if(comision.id===this.state.comisionSeleccionada.id){
                    comision.nombre=this.state.nombre
                    comision.tipo=this.state.tipoSeleccionado.label
                    comision.miembros=this.state.miembrosSeleccionados
                }
                return comision.id===this.state.comisionSeleccionada.id
            })
            this.setState({comisiones:coms})
            cerrarModal()
            this.setState({
                pendienteActualizar:true,
                controlesDesactivados:true
            })   
            
        }

        const eliminarComision=()=>{

            let comisiones=this.state.comisiones.filter(comision => comision.id !== this.state.comisionSeleccionada.id)
            this.setState({
                comisiones:comisiones,
                pendienteBorrar:true,
                controlesDesactivados:true,
                comisionSeleccionada:null
            })
         
            cerrarModal()

        }


        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false,
                comisionSeleccionada:null
            })
        }

        const handleSeleccionarmiembro=(value)=>{
            this.setState({miembrosSeleccionados:value})
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    comisionSeleccionada: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const seleccionarTipo = (value) => {
            this.setState({tipoSeleccionado:value})
        }

        const cambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        }

        const guardarCambios=async()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.pendienteGuardar){

                //insertamos la comision en la bdd.
                await this.props.client
                .mutate({
                mutation: gql`
                    mutation{crearComision(VALORES:{NOMBRE:"${this.state.nombre}",ID_TIPO_COMISION:${this.state.tipoSeleccionado.value}}){ID_COMISION}}
                `
                })
                .then(async result => {

                    //insertamos los miembros de la comision.
                    this.state.miembrosSeleccionados.forEach(async miembro=>{
                        await this.props.client
                        .mutate({
                        mutation: gql`
                            mutation{crearComisionMiembro(VALORES:{ID_COMISION:${result.data.crearComision.ID_COMISION},ID_MIEMBRO:${miembro.value}}){ID_COMISION,ID_MIEMBRO}}
                        `
                        })
                    })
                    //recargamos las comisiones
                    await this.cargarComisiones()
    
                    this.setState({
                        pendienteGuardar:false,
                        controlesDesactivados:false,
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteActualizar){


                
                //insertamos la comision en la bdd.
                await this.props.client
                .mutate({
                mutation: gql`
                    mutation{actualizarComision(ID_COMISION:${this.state.comisionSeleccionada.id},VALORES:{NOMBRE:"${this.state.nombre}",ID_TIPO_COMISION:${this.state.tipoSeleccionado.value}}){ID_COMISION}}
                `
                })
                .then(async result => {
                   
                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    //elimianmos los miembros anteriores.
                    await this.props.client
                    .mutate({
                    mutation: gql`
                        mutation{eliminarComisionMiembro(ID_COMISION:${this.state.comisionSeleccionada.id}){ID_COMISION}}
                    `
                    })

                    //insertamos los miembros de la comision.
                    this.state.miembrosSeleccionados.forEach(async miembro=>{
                        await this.props.client
                        .mutate({
                        mutation: gql`
                            mutation{crearComisionMiembro(VALORES:{ID_COMISION:${this.state.comisionSeleccionada.id},ID_MIEMBRO:${miembro.value}}){ID_COMISION,ID_MIEMBRO}}
                        `
                        })
                    })
                    //recargamos las comisiones
                    await this.cargarComisiones()
                    this.setState({pendienteActualizar:false})
                    this.setState({controlesDesactivados:false})
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })


            }else if(this.state.pendienteBorrar){


                //elimianmos los miembros anteriores.
                await this.props.client
                .mutate({
                mutation: gql`
                    mutation{eliminarComisionMiembro(ID_COMISION:${this.state.comisionSeleccionada.id}){ID_COMISION,ID_MIEMBRO}}
                `
                })

                //eliminamos la comision en la bdd.
                await this.props.client
                .mutate({
                mutation: gql`
                    mutation{eliminarComision(ID_COMISION:${this.state.comisionSeleccionada.id}){ID_COMISION}}
                `
                })
                .then(async result => {
        
                        alert(i18next.t("deletecorrecto",{ns:"global"}))
                      
                        //recargamos las comisiones
                        this.setState({pendienteBorrar:false})
                        this.setState({controlesDesactivados:false})
                        this.setState({comisionSeleccionada:null})

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
                
            }

        }


        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                comisionSeleccionada:null,
                miembrosSeleccionados:[],
                nombre:null,
                tipoSeleccionado:null,
                pendienteGuardar:false,
                pendienteActualizar:false,
                pendienteBorrar:false,
                controlesDesactivados:false,
                comisiones:[]
            },async ()=>{
                await this.cargarComisiones()
            })

            
        }

        return (
            <>
            {this.state.cargando?
                <Cargando />
                :
                <>
                <Dialog
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("comisiones.titleprint",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialog}
                    >
                         {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarInforme-pdf">
                            </div>
                            :
                                <Spinner />
                        }
                        
                    </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?i18next.t("comisiones.addcomision",{ns:"page"}):i18next.t("comisiones.editcomision",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    {!this.state.cargandoNuevo?
                    <>
                    
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <FormGroup
                            label={i18next.t("comisiones.name",{ns:"page"})+':'}
                            labelFor="nombre"
                            intent="danger"
                            helperText={!this.state.nombre?i18next.t("emptyfield",{ns:"global"})+' '+i18next.t("comisiones.name",{ns:"page"}):""}
                        >
                            <InputGroup id="nombre" value={this.state.nombre} onChange={cambiarNombre} intent={!this.state.nombre?"danger":"primary"} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("comisiones.type",{ns:"page"})+':'}
                            labelFor="tipo"
                            intent="danger"
                            helperText={!this.state.tipoSeleccionado?i18next.t("selectempty",{ns:"global"})+' '+i18next.t("comisiones.type",{ns:"page"}):""}
                        >
                            <div style={!this.state.tipoSeleccionado?{border:"1px solid red"}:{}}> 
                                <Select
                                    options={this.state.tipoComision}
                                    className="pixelAlrededor"
                                    onChange={seleccionarTipo}
                                    defaultValue={this.state.tipoComision.filter(tipo => tipo.value === (this.state.tipoSeleccionado?this.state.tipoSeleccionado.value:0))}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("comisiones.miembros",{ns:"page"})}
                            labelFor="miembros"
                            intent="danger"
                            helperText={this.state.miembrosSeleccionados.length===0?i18next.t("selectempty",{ns:"global"})+' '+i18next.t("comisiones.miembros",{ns:"page"}):""}
                        >
                        <div style={this.state.miembrosSeleccionados.length===0?{border:"1px solid red"}:{}}>
                            <Select
                                isMulti
                                isClearable
                                isSearchable
                                name="miembros"
                                onChange={handleSeleccionarmiembro}
                                options={this.state.miembros}
                                value={this.state.miembrosSeleccionados}
                            />
                        </div>
                        </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("comisiones.addcomision",{ns:"page"}):i18next.t("comisiones.editcomision",{ns:"page"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?anadirNuevo:editarComision}
                            >
                               {this.state.nuevoActivado?i18next.t("comisiones.addcomision",{ns:"page"}):i18next.t("comisiones.editcomision",{ns:"page"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </>
                        :
                        <Spinner />
                        }
                        
                </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("comisiones.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarComision}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <div className="pixelAlrededorGrande">
                    <Card interactive={true} elevation={Elevation.TWO} className="tablaSesenta">
                    {this.state.cargandoGrid?
                        <Cargando />
                        :
                        <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalEliminar} />
                                    <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={()=>{this.generarPDF()}}/>
                                    
                                    </ButtonGroup>
                            </Navbar.Group>
                            <Navbar.Group align={Alignment.RIGHT}>
                                    <ButtonGroup>
                                        <Button 
                                            alignText="right"
                                            icon="cross"
                                            text={i18next.t("cancelar",{ns:"global"})}
                                            disabled={!this.state.controlesDesactivados}
                                            onClick={cancelarCambios}
                                            className="bp3-intent-danger iconoDerecha bp3-minimal"
                                        />
                                        <Button 
                                            alignText="right"
                                            icon="floppy-disk"
                                            text={i18next.t("guardar",{ns:"global"})}
                                            disabled={!this.state.controlesDesactivados}
                                            onClick={guardarCambios}
                                            className="bp3-intent-danger iconoDerecha bp3-minimal"
                                        />
                                    </ButtonGroup>
                                </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='id'
                            data={ this.state.comisiones }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        
                        <br />
                        </>
                    }   
                    </Card>
                    <div className="tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("comisiones.articulo",{ns:"page"})}</Callout>
                            <br />
                            <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a249", "_blank")}>{i18next.t("comisiones.articulo",{ns:"page"})}</AnchorButton>
                            <br />
                        </Card>
                    </div>
                </div>
            </>
        }
        </>
        )
    }
}

export default withTranslation()(withApollo(Comisiones))