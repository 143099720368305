import React,{Component} from 'react';
import {
    AnchorButton
} from "@blueprintjs/core";
import { withApollo } from 'react-apollo';
import i18next from 'i18next';


const OPT_CONTEXTO = 1;
const OPT_OBLIGACIONES = 2;
const OPT_CONTRATOS = 3;
const OPT_RIESGOS_PENALES = 4;
const OPT_DIGITAL = 5;
const OPT_BLANQUEOS = 6;
const OPT_SERVICIOS = 7;

class LegislacionProcedimientos extends Component {
    render() {
        const getContextos=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&p=20210428&tn=1#a226", "_blank")}>{i18next.t("procedimientosCompliance.articleCompaniesAct", { ns: "page2" }).toUpperCase()}</AnchorButton>
                <br />
                </>
            )
        }   

        const getObligaciones=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={() => window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&p=20210428&tn=1#a226", "_blank")}>{ i18next.t("procedimientosCompliance.articleCompaniesAct", { ns: "page2" }).toUpperCase()}</AnchorButton>
                <br />
                </>
            )
        }

        const getContratos=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={() => window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763#tii-3", "_blank")}>{i18next.t("procedimientosCompliance.civilCode", { ns: "page2" }).toUpperCase() }</AnchorButton>
                <br />
                </>
            )
        }
        
        const getRiesgosPenales=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={() => window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1995-25444#a31", "_blank")}>{ i18next.t("procedimientosCompliance.criminalLiabilitiesInBusiness", { ns: "page2" }).toUpperCase()}</AnchorButton>
                <br />
                </>
            )
        }

        const getDigital=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/biblioteca_juridica/codigos/codigo.php?id=55&modo=2&nota=0&tab=2", "_blank")}>{i18next.t("procedimientosCompliance.dataProtection", { ns: "page2" }).toUpperCase()}</AnchorButton>
                <br />
                </>
            )
        }

        const getBlanqueos=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-6737", "_blank")}>{i18next.t("procedimientosCompliance.consolidatedLegislation", { ns: "page2" }).toUpperCase()}</AnchorButton>
                <br />
                </>
            )
        }

        const getServicios=()=>{
            return (
                <>
                <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2015-11430#s2-3", "_blank")}>{i18next.t("procedimientosCompliance.subcontractingAssignmentAndBusinessSuccession", { ns: "page2" }).toUpperCase()}</AnchorButton>
                <br />
                </>
            )
        }

        switch(this.props.bloqueSeleccionado){
            case OPT_CONTEXTO:
                return getContextos();
            case OPT_OBLIGACIONES:
                return getObligaciones();
            case OPT_CONTRATOS:
                return getContratos();
            case OPT_RIESGOS_PENALES:
                return getRiesgosPenales();
            case OPT_DIGITAL:
                return getDigital();
            case OPT_BLANQUEOS:
                return getBlanqueos();
            case OPT_SERVICIOS:
                return getServicios();
            default:
                return null;
        }
    }
}

export default withApollo(LegislacionProcedimientos);