/* eslint-disable array-callback-return */
import React from "react"
import { Card, Elevation,Checkbox,Navbar, Button, Alignment, Position,ButtonGroup, Dialog, Classes, Tooltip, AnchorButton, Intent, FormGroup, Tree, InputGroup, Callout, Popover, Spinner } from "@blueprintjs/core"
import gql from "graphql-tag";
import Select from "react-select"
import confirmacion from './Confirmacion'
import moment from 'moment';
import { withApollo } from "react-apollo";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image
} from '@react-pdf/renderer';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import styles from './stylesPrint';

export interface ITreeExampleState {
    areas: ITreeNode[];
}

 class EstructuraOrganizativa extends React.Component {

    state={
        cargandoEmpleado:false,
        modalImprimirAD:false,
        modalImprimirE: false,
        modalImprimirADE: false,
        nuevoActivado: true,
        modalDepAbierto:false,
        imprimirActivado:false,
        imprimirEmpActivado:false,
        imprimirAreaEmpActivado:false,
        editarActivado: false,
        borrarActivado: false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        areas: [],
        areasDialog: [],
        empleadosTabla: [
        
        ],
        empleadosTablaGral: [
        
        ],
        nombreArea:null,
        nombreDepartamento:null,
        popoverAbierto: false,
        areaSeleccionada: null,
        pathSeleccionado:null,
        pathSeleccionadoDialog:null,
        areaSeleccionadaSelect:null,
        departamentoSeleccionado:null,
        nombreAreaInsta:null,
        empleadoSeleccionadosSelect:[],
        borrarEmpActivado:false,
        nuevoEmpActivado:true,
        modalCambio:null,
        empeladoExistente:null,
        empeladoExistenteId:null,
        departamentoExistente:null,
        departamentoExistenteId:null,
        empleadoCambiarId:null,
        depDialog:[],
        pasoGuia:0,
        agregarContrato:false,
        hayContrato:false,
        idContrato:null,
        logotipo:null,
        datosEmpresa:null,
        steps: [
            {
              target: '#arbol-area',
              content: i18next.t("organizationalstructure.stepuno",{ns:"page"}),
              selector: '#arbol-area',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 1,
            },
            {
                target: '#boton-nuevo',
                content: i18next.t("organizationalstructure.stepdos",{ns:"page"}),
                selector: '#boton-nuevo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 2,
            },
            {
                target: '#boton-nueva-area',
                content: i18next.t("organizationalstructure.steptres",{ns:"page"}),
                selector: '#boton-nueva-area',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 3,
            },
            {
                target: '#crear-area',
                content: i18next.t("organizationalstructure.stepcuatro",{ns:"page"}),
                placement :'left',
                position: 'center',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 4,
            },
            {
                target: '#arbol-area',
                content: i18next.t("organizationalstructure.stepcinco",{ns:"page"}),
                selector: '#arbol-area',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 5,
            },
            {
                target: '#boton-nuevo',
                content: i18next.t("organizationalstructure.stepseis",{ns:"page"}),
                selector: '#boton-nuevo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 6,
            },
            {
                target: '#boton-nuevo-departamento',
                content: i18next.t("organizationalstructure.stepsiete",{ns:"page"}),
                selector: '#boton-nuevo-departamento',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 7,
            },
            {
                target: '#select-departamento',
                content: i18next.t("organizationalstructure.stepocho",{ns:"page"}),
                selector: '#select-departamento',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 8,
            },
            {
                target: '#boton-nuevo-empleado',
                content: i18next.t("organizationalstructure.stepnueve",{ns:"page"}),
                selector: '#boton-nuevo-empleado',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 9,
            },
            {
                target: '#select-empleados',
                content: i18next.t("organizationalstructure.stepdiez",{ns:"page"}),
                selector: '#select-empleados',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 10,
            },
            {
                target: '#boton-crear-empleado',
                content: i18next.t("organizationalstructure.steponce",{ns:"page"}),
                selector: '#boton-crear-empleado',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 11,
            }
        ],
        modalContratoAbierto:false
    }

    
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
        
        return datosEmpresa
    }

    existeContrato=async()=>{

        this.props.client
        .query({
            query: gql`
            {complianceContratos{ID_CONTRATO}}
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            if(result.data.complianceContratos && result.data.complianceContratos.length>0){
                await this.setState({hayContrato:true,idContrato:result.data.complianceContratos[0].ID_CONTRATO})
            }else{
                await this.setState({hayContrato:false})
            }
        })
    }

    cerrarArbol=()=>{
        let areasdialog=this.state.areas
        areasdialog.forEach(area=>{
            area.isExpanded=false
            area.isSelected=false
            area.childNodes.forEach(dep=>{
                dep.isSelected=false
            })
        })
        this.setState({areas:areasdialog})
    }

    cerrarArbolDialog=()=>{
        let areasdialog=this.state.areasDialog
        areasdialog.forEach(area=>{
            area.isExpanded=false
            area.isSelected=false
            area.childNodes.forEach(dep=>{
                dep.isSelected=false
            })
        })
        this.setState({areasDialog:areasdialog})
    }


    cargarAreas=async()=>{

        let areas=[]
        let areasPredef=[]

        // obtencion de las areas
        await this.props.client.query({
            query:gql`{departamentosAreas{ID_AREA,DESCRIPCION,PREDEFINIDA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

           
            result.data.departamentosAreas.forEach(async(area)=>{
                if(!area.PREDEFINIDA){
                    areas.push({id:area.ID_AREA,value:area.ID_AREA,label:"A"+area.ID_AREA+" - "+area.DESCRIPCION,tipo:"area",descripcion:area.DESCRIPCION})
                }else{
                    areasPredef.push({id:area.ID_AREA,value:area.ID_AREA,label:"A"+area.ID_AREA+" - "+area.DESCRIPCION,tipo:"area",descripcion:area.DESCRIPCION})
                }
            })  
            this.setState({
                areas:areas,
                areasDialog: areasPredef
            })
  
        })
     
        //obtencion de los departamentos
        let depPredef=[]
        areasPredef.forEach(async (area,index)=>{

            await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,fetchPolicy:'network-only'
            }).then(async(result)=>{
    
                let deps=[]
                result.data.departamentos.forEach(async(dep)=>{
                    depPredef.push({id:dep.ID_DEPARTAMENTO,label:"D"+dep.ID_DEPARTAMENTO+" - "+dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                    deps.push({id:dep.ID_DEPARTAMENTO,label:"D"+dep.ID_DEPARTAMENTO+" - "+dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                })  
                areasPredef[index].childNodes=deps
               
            })
            this.setState({
                depDialog: depPredef
            })
        })

        areas.forEach(async (area,index)=>{

            await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,fetchPolicy:'network-only'
            }).then(async(result)=>{
    
                let deps=[]
                result.data.departamentos.forEach(async(dep)=>{
                    deps.push({id:dep.ID_DEPARTAMENTO,label:"D"+dep.ID_DEPARTAMENTO+" - "+dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                })  
                areas[index].childNodes=deps
               
            })

        })

    }

    cargarEmpleados=async ()=>{

        let empleados=await this.props.client
        .query({
            query: gql`
            {empleados{ID_EMPLEADO}}
            `
            ,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.empleados
        })

        //buscamos el nombre del empleado
        empleados.forEach(async(empleado,index)=>{
           await this.props.client
            .query({
                query: gql`
                {personasFisicas(FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}
                `
                ,fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.personasFisicas.length>0){
                    let empleado=result.data.personasFisicas[0]
                    empleados[index].value=empleados[index].ID_EMPLEADO
                    empleados[index].label=`${empleado.APELLIDO1} ${empleado.APELLIDO2?empleado.APELLIDO2:""} ,${empleado.NOMBRE}`
                } 
            })
        })
        /* console.log(empleados) */
        this.setState({empleados:empleados}) 

    }

    obtenerDatosEmpleado=async(empleado)=>{
        /*await this.props.client.query({
            query:gql`{empleados(JERARQUIA:false,CAMPOS:"FECHA_ALTA,FECHA_BAJA",FILTROS:{ID_EMPLEADO:${empleado.ID_EMPLEADO}}){FECHA_ALTA,FECHA_BAJA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            if(result.data.empleados.length>0){
                empleado.fechaAlta=result.data.empleados[0].FECHA_ALTA
                empleado.fechaBaja=result.data.empleados[0].FECHA_BAJA?result.data.empleados[0].FECHA_BAJA:""
            }

        })*/

        await this.props.client
        .query({
             query: gql`
             {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}
             `
             ,fetchPolicy:'network-only'
        }).then(result=>{ 
                let persona=result.data.personasFisicas[0]
                empleado.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
        })


        }

    cargarDepEmp=async(idDep)=>{

        let empleadosDep=await this.props.client
        .query({
            query: gql`
            {departamentosEmpleados(FILTROS:{ID_DEPARTAMENTO:${idDep}}){ID_EMPLEADO,ID_DEPARTAMENTO,DEPARTAMENTO{DESCRIPCION},DEPARTAMENTO{AREA{DESCRIPCION}},EMP{FECHA_ALTA},EMP{FECHA_BAJA},EMP{CODIGO_EMPLEADO},PERS_FISICA{APELLIDO1},PERS_FISICA{APELLIDO2},PERS_FISICA{NOMBRE}}}
            `
            ,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.departamentosEmpleados
        })
        if(empleadosDep.length===0)this.setState({empleadosTabla:[]})

        //buscamos el nombre del empleado
        /*for(let empleado of empleadosDep){
            await this.obtenerDatosEmpleado(empleado)
        }*/

        
        this.setState({empleadosTabla:empleadosDep}) 


    }

    componentDidMount=()=>{

        this.setState({cargando: true})

        this.cargarAreas()
        this.cargarEmpleados()
        this.existeContrato()
        this.obtenerLogoEmpresa();
        
        this.setState({cargando: false})
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }

    cargarDepEmpGral=async(idDep)=>{

        let empleadosDep=await this.props.client
        .query({
            query: gql`
            {departamentosEmpleados(FILTROS:{ID_DEPARTAMENTO:${idDep}}){ID_EMPLEADO,ID_DEPARTAMENTO,DEPARTAMENTO{DESCRIPCION},DEPARTAMENTO{AREA{DESCRIPCION}},EMP{FECHA_ALTA},EMP{FECHA_BAJA},EMP{CODIGO_EMPLEADO},PERS_FISICA{APELLIDO1},PERS_FISICA{APELLIDO2},PERS_FISICA{NOMBRE}}}
            `
            ,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.departamentosEmpleados
        })
        let empleadosGral = this.state.empleadosTablaGral
        empleadosGral.push(empleadosDep);
        //console.log(empleadosGral);
        this.setState({empleadosTablaGral:empleadosGral}) 


    }

    cargarDatosEmp = async () => {
        if (this.state.areas!==null) {
            this.state.areas.map((area, index)=>{
                this.state.areas[index].childNodes.map(async (child, indexC)=>{
                    await this.cargarDepEmpGral(child.id)
                })
            })
        }
    }

    InformeAD = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organizationalstructure.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("organizationalstructure.titleareas",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.areas?this.state.areas.map((area, index)=>{
                        return (
                            <View style={styles.viewArea}>
                                <Text key={index} style={styles.textDataAreas}>{area.label}</Text>
                                {this.state.areas[index].childNodes.map((child, indexC)=>{
                                    return (
                                        <Text key={indexC} style={styles.textDataDepto}>{child.label}</Text>
                                    )
                                })}
                            </View>
                        )
                    }):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    InformeE = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organizationalstructure.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("organizationalstructure.titleempleados",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.empleadosTabla?this.state.empleadosTabla.map((empleado, index)=>{
                    return (
                            <View>
                                {index===0 && (
                                <View style={styles.rowHead}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={styles.valueDataA}>{empleado.DEPARTAMENTO.AREA.DESCRIPCION}</Text>
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>{i18next.t("organizationalstructure.departamento",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={styles.valueDataD}>{empleado.DEPARTAMENTO.DESCRIPCION}</Text>
                                    </View>
                                </View>
                                )}
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{i18next.t("organizationalstructure.code",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{i18next.t("organizationalstructure.employees",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{i18next.t("organizationalstructure.createdat",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans Bold',width:'20%'}}>{i18next.t("organizationalstructure.deletedat",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{empleado.EMP.CODIGO_EMPLEADO}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{empleado.PERS_FISICA.APELLIDO1} {empleado.PERS_FISICA.APELLIDO2?empleado.PERS_FISICA.APELLIDO2:''}, {empleado.PERS_FISICA.NOMBRE}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{moment(empleado.EMP.FECHA_ALTA).format("DD-MM-YYYY    ")}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'20%'}}>{empleado.EMP.FECHA_BAJA!==null?moment(empleado.EMP.FECHA_BAJA).format("DD-MM-YYYY    "):''}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    InformeADE = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organizationalstructure.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("organizationalstructure.employees",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.state.empleadosTablaGral?this.state.empleadosTablaGral.map((empleados, indexG)=>{
                    return (
                        <View key={indexG}>
                    {empleados.map((empleado, index)=>{
                        return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.rowHeadGral}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={styles.valueDataA}>{empleado.DEPARTAMENTO.AREA.DESCRIPCION}</Text>
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>{i18next.t("organizationalstructure.departamento",{ns:"page"}).toUpperCase()}:</Text>
                                        <Text style={styles.valueDataD}>{empleado.DEPARTAMENTO.DESCRIPCION}</Text>
                                    </View>
                                </View>
                                )}
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{i18next.t("organizationalstructure.code",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{i18next.t("organizationalstructure.employees",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{i18next.t("organizationalstructure.createdat",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans Bold',width:'20%'}}>{i18next.t("organizationalstructure.deletedat",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{empleado.EMP.CODIGO_EMPLEADO}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{empleado.PERS_FISICA.APELLIDO1} {empleado.PERS_FISICA.APELLIDO2?empleado.PERS_FISICA.APELLIDO2:''}, {empleado.PERS_FISICA.NOMBRE}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{moment(empleado.EMP.FECHA_ALTA).format("DD-MM-YYYY    ")}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'20%'}}>{empleado.EMP.FECHA_BAJA!==null?moment(empleado.EMP.FECHA_BAJA).format("DD-MM-YYYY    "):''}</Text>
                                </View>
                                
                            </View>
                        )
                    })}

                    </View>
                    )
                }):<></>}

            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    
    generarInformeAD =async () => {
        this.setState({
            modalImprimirAD: true,
            cargandoImprimir:true
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeAD(), document.getElementById('informe-pdf-ad'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeAD(), document.getElementById('informe-pdf-ad'))
                })
            }
        })
        
    }

    generarInformeE =async () => {
        this.setState({
            modalImprimirE:true,
            cargandoImprimir:true
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeE(), document.getElementById('informe-pdf-e'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeE(), document.getElementById('informe-pdf-e'))
                })
            }
        })
        
    }

    generarInformeADE =async () => {
        this.setState({
            modalImprimirADE:true,
            cargandoImprimir:true
        }, async () => {
            await this.cargarDatosEmp();
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeADE(), document.getElementById('informe-pdf-ade'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeADE(), document.getElementById('informe-pdf-ade'))
                })
            }
        })
        
    }

    obtenerCodigo=async(indiceEmpleado,idDepto)=>{
        let codigo_empleado=""
        let indiceArea=""
        let indiceDpto=""
        let indicePuesto=""
        if (idDepto) {
        await this.props.client
            .query({
                query: gql` 
                    {departamentos(CAMPOS:"ID_AREA",FILTROS:{PREDEFINIDO:false,ID_DEPARTAMENTO:${idDepto}}){ID_AREA}}
                `,
                fetchPolicy:'network-only'
            }).then(async result=>{ 
                indiceArea=result.data.departamentos[0].ID_AREA
            })
            indiceDpto=idDepto;
        }
        await this.props.client
        .query({
            query: gql` 
                {empleadosPuestos(FILTROS:{ID_EMPLEADO:${indiceEmpleado},FECHA_BAJA:null},LIMIT:1,ORDEN:"ID_PUESTO,DESC"){PUESTO_TRABAJO{ID_PUESTO}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            if (result.data.empleadosPuestos.length>0) {
                for(let puesto of result.data.empleadosPuestos){
                    indicePuesto=puesto.PUESTO_TRABAJO.ID_PUESTO
                }
            }
        })
        codigo_empleado="A"+indiceArea+"|D"+indiceDpto+"|P"+indicePuesto+"|E"+indiceEmpleado;
        return codigo_empleado
        
    }

    render(){

        const { steps } = this.state;
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("organizationalstructure.title",{ns:"page"});
        
        const abrirModalNuevaArea = () => {

            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({nombreArea:null})
            this.setState({modalAbierto:true})

            this.setState({nuevoActivado:true})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:false})
            this.setState({imprimirActivado:false})
            this.setState({areaSeleccionada:null})
            this.setState({popoverAbierto: false})
        }

        const abrirModalAreaInstanea = () => {

            this.setState({nombreAreaInsta:null})
            this.setState({modalAreaInstantanea:true})

        }

        const abrirModalNuevoDepartamento= () => {
            
            this.cerrarArbolDialog()

            if (this.state.areaSeleccionada){
                this.setState({areaSeleccionadaSelect:this.state.areaSeleccionada}) 
            }else if(this.state.departamentoSeleccionado){
                this.state.areas.find(area=>{
                    if(area.value===this.state.departamentoSeleccionado.padre)this.setState({areaSeleccionadaSelect:area})
                    return area.value===this.state.departamentoSeleccionado.padre
                })
            }else{
                this.setState({areaSeleccionadaSelect:null}) 
            }

         

            this.setState({
                nombreDepartamento:null,
                modalDepAbierto:true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                imprimirActivado:false,
                popoverAbierto: false,
                agregarContrato:false
            })
        }

        const abrirModalEditar = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.areaSeleccionada && !this.state.departamentoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.cerrarArbolDialog()

            //si editamos un area
            if(this.state.areaSeleccionada){
                this.setState({nombreArea:this.state.areaSeleccionada.descripcion})
                this.setState({modalAbierto:true})
            }

            //si editamos un departamento
            if(this.state.departamentoSeleccionado){
                this.setState({nombreDepartamento:this.state.departamentoSeleccionado.label})
                //buscamos su area seleccionado
                let areaSel=this.state.areas.find(area=>{return area.id===this.state.departamentoSeleccionado.padre})
                this.setState({areaSeleccionadaSelect:areaSel})
                this.setState({modalDepAbierto:true})
            }

            this.setState({nuevoActivado:false})
            this.setState({editarActivado:true})
            this.setState({borrarActivado:false})
            this.setState({imprimirActivado:false})

        }

        const abrirModalEmpleados=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.departamentoSeleccionado){

                alert(i18next.t("noseleccionado",{ns:"global"}))
                return

            }

            
            if(this.state.empleados.length===0){
                alert(i18next.t("organizationalstructure.validaasociar",{ns:"page"}))
                return
            }

            this.setState({
                cargandoEmpleado:true,
                empleadoSeleccionado:null,
                empleadoSeleccionadosSelect:[],
                modalEmpleados:true,
                borrarEmpActivado:false,
                nuevoEmpActivado:true,
                empeladoExistente:null,
                empeladoExistenteId:null,
                departamentoExistente:null,
                departamentoExistenteId:null,
                empleadoCambiarId:null,
                imprimirEmpActivado:false,
                imprimirAreaEmpActivado:false
            })
        }

        const abrirModalBorrarEmpDep=()=>{

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarEmpDepAbierto:true,
                borrarEmpActivado:true,
                nuevoEmpActivado:false,
                imprimirEmpActivado:false,
                imprimirAreaEmpActivado:false
            })
        }

        
        const abrirPopover = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }else{
                this.setState({popoverAbierto: true})
            }

        }

        
        const abrirModalBorrar = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.areaSeleccionada && !this.state.departamentoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            if(this.state.areaSeleccionada){
                this.setState({modalBorrarAbierto:true})
            }else{
                this.setState({modalBorrarDepAbierto:true})
            }

            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})

        }

        const cerrarModal=()=>{
            this.setState({modalAbierto: false,
                cargando:false,
                cargandoEmpleado:false,
                modalBorrarAbierto: false,
                modalDepAbierto: false,
                modalBorrarDepAbierto: false,
                modalEmpleados: false,
                modalBorrarEmpDepAbierto: false,
                modalCambio: false
            })
        }

        const cerrarModalImprimirAD=()=>{
            this.setState({modalImprimirAD: false})
        }

        const cerrarModalImprimirE=()=>{
            this.setState({modalImprimirE: false})
        }

        const cerrarModalImprimirADE=()=>{
            this.setState({modalImprimirADE: false})
        }

        const cerrarModal2=()=>{
            this.setState({modalAreaInstantanea: false})
        }

        const handleNodeClick = (nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {

            if(this.state.controlesDesactivados)return
            //limpiamos el path anterior
            if(this.state.pathSeleccionado){

                let areas=this.state.areas
                if(this.state.pathSeleccionado.length===1){
                    areas[this.state.pathSeleccionado].isSelected=false
                }else if(this.state.pathSeleccionado.length===2){
                    areas[this.state.pathSeleccionado[0]].childNodes[this.state.pathSeleccionado[1]].isSelected=false
                }                    
                
                
            }
            this.setState({pathSeleccionado:_nodePath})
            nodeData.isSelected? nodeData.isSelected = false : nodeData.isSelected = true
            if(nodeData.tipo==="area"){
                this.setState({areaSeleccionada:nodeData})
                this.setState({departamentoSeleccionado:null})
            }else{
                this.setState({departamentoSeleccionado:nodeData})
                this.setState({areaSeleccionada:null})
                this.cargarDepEmp(nodeData.id)
            }
            
         
        }

      

        const handleNodeCollapse = (nodeData: ITreeNode) => {
            if(this.state.controlesDesactivados)return

            nodeData.isExpanded = false;
            this.setState(this.state);
        };
    
        const handleNodeExpand = (nodeData: ITreeNode) => {
            if(this.state.controlesDesactivados)return

            nodeData.isExpanded = true;
            this.setState(this.state);
        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }
        function dateFormatterBaja(cell, row) {
            let baja='';
            if (cell) {
                baja=moment(cell).format("DD/MM/YYYY");
            }
            return (
                <>
                    <span>{baja}</span>
                </>
            );
        }

        const nameFormato=(cell,row)=>{
            let name=cell.APELLIDO1;
            if (cell.APELLIDO2) {
                name=name+" "+cell.APELLIDO2;
            }
            name=name+", "+cell.NOMBRE;
            return (
                <>
                    <span>{name}</span>
                </>
            );
        }

        const columnsSug = [{
            dataField: 'descripcion',
            text: i18next.t("name",{ns:"global"})
        }];

        const columns = [{
            dataField: 'DEPARTAMENTO.AREA.DESCRIPCION',
            text: i18next.t("organizationalstructure.area",{ns:"page"})
            }, {
            dataField: 'DEPARTAMENTO.DESCRIPCION',
            text: i18next.t("organizationalstructure.departamento",{ns:"page"})
            }, {
            dataField: 'PERS_FISICA',
            text: i18next.t("name",{ns:"global"}),
            formatter: nameFormato,
            sort: true
            }, {
            dataField: 'EMP.CODIGO_EMPLEADO',
            text: i18next.t("organizationalstructure.code",{ns:"page"}),
            sort: true
            }, {
            dataField: 'EMP.FECHA_ALTA',
            text: i18next.t("organizationalstructure.createdat",{ns:"page"}),
            formatter: dateFormatter
            }, {
            dataField: 'EMP.FECHA_BAJA',
            text: i18next.t("organizationalstructure.deletedat",{ns:"page"}),
            formatter: dateFormatterBaja
        }];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    empleadoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectSug=(row, isSelect) => {
            console.log(row);
            if (isSelect) {
                this.setState({
                    nombreArea: row.descripcion
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowSug = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectSug,
            style: { backgroundColor: '#c8e6c9' }
        };

        const handleCambiarNombreArea=(ev)=>{
            this.setState({nombreArea:ev.currentTarget.value})
        }

        const handleCambiarNombreAreaInsta=(ev)=>{
            this.setState({nombreAreaInsta:ev.currentTarget.value})
        }

        const handleCambiarNombreDepartamento=(ev)=>{
            this.setState({nombreDepartamento:ev.currentTarget.value})
        }

        const handleSeleccionarEmpleadosSelect=(value)=>{
            this.setState({empleadoSeleccionadosSelect:value})
        }

        const anadirArea=()=>{

            if(!this.state.nombreArea){
                return
            }

            //comprobar que no existe un area con este nombre.
            let existe=this.state.areas.find(area=>{return area.label===this.state.nombreArea})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //si tenemos el nombre del area, lo agregamos al arbol.
            let areas=this.state.areas
            areas.push({"label":this.state.nombreArea})
            //actualizamos las areas
            this.setState({areas:areas})
            this.setState({controlesDesactivados:true})
            this.setState({pendienteGuardar:true})
            cerrarModal()

        }

        const anadirAreaInsta=async()=>{

            if(!this.state.nombreAreaInsta){
                return
            }
            
            //comprobar que no existe un area con este nombre.
            let existe=this.state.areas.find(area=>{return area.label===this.state.nombreAreaInsta})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }
            
            //si tenemos el nombre del area, lo agregamos al arbol.
            let areas=this.state.areas
            areas.push({"label":this.state.nombreArea})
            //actualizamos las areas
            this.setState({areas:areas})
            //ejecutamos la mutacion para generara el area
            await this.props.client.mutate({
                mutation:gql`mutation{crearDepartamentoArea(VALORES:{DESCRIPCION:"${this.state.nombreAreaInsta}",PREDEFINIDA:false}){ID_AREA}}`
            }).then(async result=>{

                this.setState({pendienteGuardar:false})
                this.setState({controlesDesactivados:false})
                await this.cargarAreas()

                //seleccionamos el area creada en el select de areas.
                let areaSel=this.state.areas.find(area=>{return area.id===result.data.crearDepartamentoArea.ID_AREA})
                this.setState({areaSeleccionadaSelect:areaSel})
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            
            cerrarModal2()

        }

        const anadirDepartamento=()=>{

            if(!this.state.nombreDepartamento){
                return
            }

            if(!this.state.areaSeleccionadaSelect){
                return
            }

        
            
            //si tenemos el nombre del area, lo agregamos al arbol.
            let areas=this.state.areas
            let existe=false
            areas.forEach(area=>{

                if(area.childNodes){
                    area.childNodes.forEach(elem=>{
                        if(elem.label===this.state.nombreDepartamento){
                            existe=true
                        }
                    })                   
                }
              
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            this.cerrarArbol()

            //buscamos el area seleccionada en el select y agregamos el nuevo departamento.
            areas.find((area,index)=>{
                if(area.id===this.state.areaSeleccionadaSelect.value){
                    areas[index].isExpanded=true
                    if(areas[index].childNodes){
                        areas[index].childNodes.push({
                            label:this.state.nombreDepartamento
                        })
                    }else{
                        areas[index].childNodes=[]
                        areas[index].childNodes.push({
                            label:this.state.nombreDepartamento
                        })
                    }
                    areas[index].childNodes[areas[index].childNodes.length-1].isSelected=true
                }
                return area.id===this.state.areaSeleccionadaSelect.value
            })

            this.setState({areas:areas})

            this.setState({controlesDesactivados:true})
            this.setState({pendienteGuardarDepartamento:true})

            cerrarModal()

        }

        const actualizarDepartamento=()=>{


            if(!this.state.nombreDepartamento){
                return
            }

            if(!this.state.areaSeleccionadaSelect){
                return
            }

            //actualizacion de su nombre

            
            //si tenemos el nombre del area, lo agregamos al arbol.
            let areas=this.state.areas
            let existe=false
            areas.forEach(area=>{
                if(area.childNodes){
                    area.childNodes.forEach(elem=>{
                        if(elem.label===this.state.nombreDepartamento && elem.id!==this.state.departamentoSeleccionado.id){
                            existe=true
                        }
                    })                   
                }
              
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //SI ACTUALIZAMOS EL DEPARTAMENTO CON UN AREA DISTINTA.
            //buscamos el area seleccionada en el select y agregamos el nuevo departamento.
            if(this.state.areaSeleccionadaSelect.value!==this.state.departamentoSeleccionado.padre){

                //lo eliminamos del area actual.
                areas.find((area,index)=>{
                    if(area.id===this.state.departamentoSeleccionado.padre){
                        if(area.childNodes){
                            let departamentEliminar=""
                            area.childNodes.find((departamento,indDep)=>{
                                if(departamento.id===this.state.departamentoSeleccionado.id){
                                    departamentEliminar=indDep
                                }
                                return departamento.id===this.state.departamentoSeleccionado.id
                            })
                            
                            area.childNodes.splice(departamentEliminar,1)
                            if(area.childNodes.length===0)area.childNodes=[]
                            area.isExpanded=false
                        }
                    }
                    return area.id===this.state.departamentoSeleccionado.padre
                })

                //la agregamos al nuevo area
                areas.find((area,index)=>{
                    if(area.id===this.state.areaSeleccionadaSelect.value){
                        area.isExpanded=true
                        if(area.childNodes){
                            area.childNodes.push({
                                label:this.state.nombreDepartamento
                            })
                        }else{
                            area.childNodes=[]
                            area.childNodes.push({
                                label:this.state.nombreDepartamento
                            })
                        }
                        area.childNodes[area.childNodes.length-1].isSelected=true
                    }
                    return area.id===this.state.areaSeleccionadaSelect.value
                })

            }
            
            //Actualziacion de su nombre.
            areas.find((area,index)=>{
                if(area.id===this.state.departamentoSeleccionado.padre){

                    areas[index].childNodes.find((departamento,indDep)=>{
                        if(departamento.id===this.state.departamentoSeleccionado.id){
                            areas[index].childNodes[indDep].label=this.state.nombreDepartamento
                        }
                        return departamento.id===this.state.departamentoSeleccionado.id
                    })
                    areas[index].isExpanded=false
                }
                return area.id===this.state.departamentoSeleccionado.padre
            })

            this.setState({areas:areas})

            this.setState({controlesDesactivados:true})
            this.setState({pendienteActualizarDepartamento:true})
            this.setState({pathSeleccionado:null})
            this.setState({empleadosTabla:[]})

            cerrarModal()

        }


        
        const actualizarArea=()=>{

            if(!this.state.nombreArea){
                return
            }

            //comprobar que no existe un area con este nombre.
            let existe=this.state.areas.find(area=>{return area.descripcion===this.state.nombreArea && this.state.areaSeleccionada.value!==area.value})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //si tenemos el nombre del area, lo agregamos al arbol.
            let areas=this.state.areas
            areas.find((area,index)=>{
                if(area.id===this.state.areaSeleccionada.id){
                    areas[index].label="A"+area.id+" - "+this.state.nombreArea
                }
                return area.id===this.state.areaSeleccionada.id
            })

            this.setState({controlesDesactivados:true})
            this.setState({pendienteActualizar:true})
            cerrarModal()

        }

        const eliminarArea=()=>{

            if(this.state.areaSeleccionada.childNodes && this.state.areaSeleccionada.childNodes.length>0){
                alert(i18next.t("organizationalstructure.validarborrararea",{ns:"page"}))
                cerrarModal()
                return
            }
            
            let areas= this.state.areas.filter(area=>{
                return area.id!==this.state.areaSeleccionada.id
            })

            this.setState({areas:areas,
                controlesDesactivados:true,
                pendienteBorrar:true,
                pathSeleccionado:null
            },()=>{

                cerrarModal()
            })
        }

        const eliminarDepartamento=async()=>{
            
            let areas= this.state.areas

            //Observamos si el departamento pertenece al contrato de compliance
            await this.props.client
            .query({
                query: gql`
                {complianceContratosDepartamentos(FILTROS:{ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id}}){ID_CONTRATO_DEPARTAMENTO}}
                `
                ,fetchPolicy:'network-only'
            }).then(result=>{ 
                if (result.data.complianceContratosDepartamentos.length>0) {
                    result.data.complianceContratosDepartamentos.map((compliance)=>{
                        this.props.client.mutate({
                            mutation:gql`mutation{eliminarComplianceContratoDepartamento(ID_CONTRATO_DEPARTAMENTO:${compliance.ID_CONTRATO_DEPARTAMENTO}){ID_CONTRATO_DEPARTAMENTO}}`
                        }).catch(err=>{
                            console.log(err)
                            alert(i18next.t("error",{ns:"global"}))
                            cerrarModal()
                            return true
                        })
                    })
                    
                }
            })
            
           

            if(this.state.empleadosTabla.length>0){
                alert(i18next.t("organizationalstructure.validarborrarempleado",{ns:"page"}))
                cerrarModal()
                return
            }

            //Observamos si tenemos algun puesto de trabajo en el departamento.
            let puestos=await this.props.client
            .query({
                query: gql`
                {puestosTrabajo(FILTROS:{ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id}}){ID_PUESTO}}
                `
                ,fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.puestosTrabajo.length
            })
            
            if(puestos>0){
                alert(i18next.t("organizationalstructure.validarborrarpuesto",{ns:"page"}))
                cerrarModal()
                return
            }

            //lo eliminamos del area actual.
            areas.find((area,index)=>{
                if(area.id===this.state.departamentoSeleccionado.padre){
                    if(area.childNodes){
                        let departamentEliminar=""
                        area.childNodes.find((departamento,indDep)=>{
                            if(departamento.id===this.state.departamentoSeleccionado.id){
                                departamentEliminar=indDep
                            }
                            return departamento.id===this.state.departamentoSeleccionado.id
                        })
                        
                        area.childNodes.splice(departamentEliminar,1)
                        if(area.childNodes.length===0)area.childNodes=[]
                    }
                }
                return area.id===this.state.departamentoSeleccionado.padre
            })

            this.setState({areas:areas})
            this.setState({controlesDesactivados:true})
            this.setState({pendienteBorrarDepartamento:true})
            this.setState({pathSeleccionado:null})
            cerrarModal()
        }

        const anadirEmpleados=async()=>{

            if(this.state.empleadoSeleccionadosSelect.length===0)return
            
            //damos de alta los empleados en el departamento seleccionado.
            this.state.empleadoSeleccionadosSelect.forEach(async empleado=>{

                //si ya existe en el departamento seleccionado.
                let existe=this.state.empleadosTabla.find(emp=>{
                    return emp.ID_EMPLEADO===empleado.ID_EMPLEADO
                })
                if(existe){
                    alert(i18next.t("yaexiste",{ns:"global"}))
                    return
                }

                //si existe en otro departamento.
                let empleadosDep=await this.props.client
                .query({
                    query: gql`
                    {departamentosEmpleados(FILTROS:{ID_EMPLEADO:${empleado.value}}){ID_EMPLEADO,ID_DEPARTAMENTO,DEPARTAMENTO{DESCRIPCION}}}
                    `
                    ,fetchPolicy:'network-only'
                }).then(result=>{ 
                    return result.data.departamentosEmpleados
                })
                
                if(empleadosDep.length>0){
                    
                    this.setState({departamentoExistente:empleadosDep[0].DEPARTAMENTO.DESCRIPCION})  
                    this.setState({departamentoExistenteId:empleadosDep[0].ID_DEPARTAMENTO})  
                    //buscamos el nombre del empleado.
                    let empeladoExiste=this.state.empleados.find(empleado=>{
                        return empleado.value===empleadosDep[0].ID_EMPLEADO 
                    })
                    this.setState({empeladoExistente:empeladoExiste.label})
                    this.setState({empeladoExistenteId:empeladoExiste.value})

                    await confirmacion(`El empleado ${this.state.empeladoExistente} está actualmente en el departamento ${this.state.departamentoExistente}.
                        ¿Desea cambiarlo de departamento? Tenga en cuenta que el empleado sera dado de baja del puesto ejercido en el anterior departamento`).then(
                        async(result) => {
                        //hacemos clic en si
                            await cambiarEmpleado(empleado.value)
                        },
                        (result) => {
                            //hacemos clic en cancelar
                        }
                    )
                    
                    return
                    
                }
                //mutation para asociar los empleados a los departamentos.
                await  this.props.client.mutate({
                    mutation:gql`mutation{crearDepartamentoEmpleado(VALORES:{ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id},ID_EMPLEADO:${empleado.value}}){ID_DEPARTAMENTO,ID_EMPLEADO}}`
                }).then(async result=>{
                 
                    await this.cargarDepEmp(this.state.departamentoSeleccionado.id)
                    
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            })
            cerrarModal()

        }
        
        const eliminarEmpleadoDepartamento=async ()=>{
            
            let empleados= this.state.empleadosTabla.filter(empleado=>{
                return empleado.ID_EMPLEADO!==this.state.empleadoSeleccionado.ID_EMPLEADO
            })

            this.setState({empleadosTabla:empleados,
                cargarEmpleados:true})

            await this.props.client.mutate({
                mutation:gql`mutation{eliminarDepartamentoEmpleado(ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id}){ID_EMPLEADO,ID_DEPARTAMENTO}}`
            }).then(result=>{

                this.setState({
                    empleadoSeleccionado:null,
                    cargandoEmpleado:false
                },()=>{
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                })
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

              

            cerrarModal()
        }

        const cambiarEmpleado=async(idEmpleado)=>{

            //eliminamos su antiguo departamento.
            await this.props.client.mutate({
                mutation:gql`mutation{eliminarDepartamentoEmpleado(ID_EMPLEADO:${idEmpleado},ID_DEPARTAMENTO:${this.state.departamentoExistenteId}){ID_DEPARTAMENTO,ID_EMPLEADO}}`
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

            //lo damos de baja de su último puesto de trabajo ejercido en el anterior departamento
            await this.props.client
            .query({
                query: gql` 
                    {empleadosPuestos(CAMPOS:"ID_EMPLEO",FILTROS:{ID_EMPLEADO:${idEmpleado},FECHA_BAJA:null}){ID_EMPLEO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 

                if(result.data.empleadosPuestos.length>0){
                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarEmpleadoPuesto(ID_EMPLEO:${result.data.empleadosPuestos[0].ID_EMPLEO},VALORES:{FECHA_BAJA:${'"'+moment().format('YYYY-MM-DD')+'"'}}){ID_EMPLEADO,ID_PUESTO}}`
                    })
                }
              

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

            //dejamos en blanco su codigo de empleado
            /*
            await  this.props.client.mutate({
                mutation:gql`mutation{actualizarEmpleado(
                ID_EMPLEADO:${idEmpleado},
                VALORES:{
                    CODIGO_EMPLEADO:"",
                }){ID_EMPLEADO}}`
           })   
           */
        
            //lo cambiamos a su nuevo departamento.
            await this.props.client.mutate({
                mutation:gql`mutation{crearDepartamentoEmpleado(VALORES:{ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id},ID_EMPLEADO:${idEmpleado}}){ID_EMPLEADO,ID_DEPARTAMENTO}}`
            }).then(async result=>{

                await this.cargarDepEmp(this.state.departamentoSeleccionado.id)
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

            this.cargarDepEmp(this.state.departamentoSeleccionado.id)
            cerrarModal()
        }

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //APARTADO AREAS

            if(this.state.pendienteGuardar){

                this.props.client.mutate({
                    mutation:gql`mutation{crearDepartamentoArea(VALORES:{DESCRIPCION:"${this.state.nombreArea}",PREDEFINIDA:false}){ID_AREA}}`
                }).then(result=>{

                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    this.setState({pendienteGuardar:false})
                    this.setState({controlesDesactivados:false})
                    this.cargarAreas()
                    
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteActualizar){
                    

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarDepartamentoArea(ID_AREA:${this.state.areaSeleccionada.id},VALORES:{DESCRIPCION:"${this.state.nombreArea}"}){ID_AREA}}`
                }).then(result=>{

                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    this.setState({pendienteActualizar:false})
                    this.setState({controlesDesactivados:false})
                    this.cargarAreas()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarDepartamentoArea(ID_AREA:${this.state.areaSeleccionada.id}){ID_AREA}}`
                }).then(result=>{
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({pendienteBorrar:false})
                    this.setState({controlesDesactivados:false})
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }

            //APARTADO DEPARTAMENTOS
            if(this.state.pendienteGuardarDepartamento){

                this.props.client.mutate({
                    mutation:gql`mutation{crearDepartamento(VALORES:{DESCRIPCION:"${this.state.nombreDepartamento}",ID_AREA:${this.state.areaSeleccionadaSelect.value},PREDEFINIDO:false}){ID_DEPARTAMENTO}}`
                }).then(result=>{
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    this.setState({pendienteGuardarDepartamento:false})
                    this.setState({controlesDesactivados:false})
                    this.cargarAreas()

                    if(this.state.agregarContrato){

                        this.props.client.mutate({
                            mutation:gql`mutation{crearComplianceContratoDepartamento(VALORES:{ID_CONTRATO:${this.state.idContrato},ID_DEPARTAMENTO:${result.data.crearDepartamento.ID_DEPARTAMENTO}}){ID_CONTRATO,ID_DEPARTAMENTO}}`
                        })
        
                    }

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }

            if(this.state.pendienteActualizarDepartamento){

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarDepartamento(ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id},VALORES:{DESCRIPCION:"${this.state.nombreDepartamento}",ID_AREA:${this.state.areaSeleccionadaSelect.value}}){ID_DEPARTAMENTO}}`
                }).then(result=>{

                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    this.setState({pendienteActualizarDepartamento:false})
                    this.setState({controlesDesactivados:false})
                    this.cargarAreas()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })


            }

            if(this.state.pendienteBorrarDepartamento){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarDepartamento(ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id}){ID_DEPARTAMENTO}}`
                }).then(result=>{

                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({pendienteBorrarDepartamento:false})
                    this.setState({controlesDesactivados:false})
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }



        }

        const cancelarCambios=()=>{

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({nombreArea:null})
            this.setState({areaSeleccionada:null})
            this.setState({controlesDesactivados:false})

            this.setState({pendienteGuardar:false})
            this.setState({pendienteActualizar:false})
            this.setState({pendienteBorrar:false})

            this.cargarAreas()

        }

        const Botonera = () => {

            if(this.props.editable){
                return(
                    <>
                        <Button className="bp3-minimal" icon="new-link" id="boton-nueva-area" text={i18next.t("organizationalstructure.newarea",{ns:"page"})} fill={true} onClick={abrirModalNuevaArea} />
                        <br />
                        <Button className="bp3-minimal" icon="git-new-branch" id="boton-nuevo-departamento" text={i18next.t("organizationalstructure.newdepartamento",{ns:"page"})} onClick={abrirModalNuevoDepartamento} />
                    </>
                )
            }else{
                return(<></>)
            }
          
        }

        const seleccionarArea=(value)=>{
            this.setState({areaSeleccionadaSelect:value})
        }


        const desplegarArbol=(hijos)=>{
            
            hijos.forEach(hijo=>{
                  hijo.isExpanded=true
                  if(hijo.childNodes)desplegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
        
        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }

        const handleNodeCollapseDialog = (nodeData: ITreeNode) => {
            if(this.state.editarActivado)return
            
            nodeData.isExpanded = false;
            this.setState(this.state);
        };

        const handleNodeClickDialog = (nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
            //limpiamos el path anterior

            if(this.state.editarActivado)return

            if(this.state.pathSeleccionadoDialog){
      
               let areas=this.state.areasDialog
               if(this.state.pathSeleccionadoDialog.length===1){
                   areas[this.state.pathSeleccionadoDialog].isSelected=false
               }else if(this.state.pathSeleccionadoDialog.length===2){
                   areas[this.state.pathSeleccionadoDialog[0]].childNodes[this.state.pathSeleccionadoDialog[1]].isSelected=false
               }                    
               
           }
           this.setState({pathSeleccionadoDialog:_nodePath})
           if(nodeData.tipo==="departamento"){
               nodeData.isSelected? nodeData.isSelected = false : nodeData.isSelected = true
               this.setState({areaSeleccionadaDialog:nodeData,nombreDepartamento:nodeData.label})
           }
       
        }

        const handleNodeExpandDialog = (nodeData: ITreeNode) => {
            if(this.state.editarActivado)return

            nodeData.isExpanded = true;
            this.setState(this.state);
        }

        const handleJoyrideCallback=async(data)=>{
            
            const { action, index, status, type } = data;

            if(data.action === "close"){
                localStorage.setItem("ayuda", false)
                this.setState({pasoGuia: 0, modalAbierto:false,modalDepAbierto:false,modalEmpleados:false})
            }else{ 

                if ([EVENTS.STEP_AFTER].includes(type)) {

                    let paso=index+ (action === ACTIONS.PREV ? -1 : 1)

                    //paso en el que nos encontremos.
                    // eslint-disable-next-line default-case
                    switch(paso){
                
                        case 1:
                            await this.setState({modalAbierto:false,popoverAbierto:false})
                        break;
                        case 2:
                            await this.setState({popoverAbierto:true,modalAbierto:false})
                        break;
                        case 3:
                            await this.setState({modalAbierto:true,popoverAbierto:false})
                        break;
                        case 4:
                            await this.setState({modalAbierto:false,controlesDesactivados:false})
                        break;
                        case 5:
                            await this.setState({controlesDesactivados:false,popoverAbierto:false})
                        break;
                        case 6:
                            await this.setState({popoverAbierto:true,modalDepAbierto:false})
                        break;
                        case 7:
                            await this.setState({popoverAbierto:false,modalDepAbierto:true})
                        break;
                        case 8:
                            await this.setState({modalDepAbierto:false,modalEmpleados:false})
                        break;
                        case 9:
                        case 10:
                            await this.setState({modalEmpleados:true})
                        break;
                        case 11:
                            await this.setState({modalEmpleados:false})
                        break;
                    }
            
                    await this.setState({ pasoGuia: paso });   


                }

                //si termina la guia.
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                    localStorage.setItem("ayuda",false)
                }
            }

        }

        const handleEnabledAgregarContrato = () => {
            this.setState({modalContratoAbierto:true})
        }

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("organizationalstructure.titleadddepartamento",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalContratoAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h3>{i18next.t("organizationalstructure.questionadddepartamento",{ns:"page"})}</h3>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={()=>{
                                        this.setState({ agregarContrato: !this.state.agregarContrato,modalContratoAbierto:false })
                                    }}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("no",{ns:"global"})} position="top">
                                <Button onClick={()=>{
                                    this.setState({modalContratoAbierto:false})
                                }}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                    <>
                        <Joyride
                            run={localStorage.getItem("ayuda")==="true"?true:false}
                            callback={handleJoyrideCallback}
                            showProgress={true}
                            stepIndex={this.state.pasoGuia}
                            steps={steps}
                            continuous ={true}
                            locale={{ back: i18next.t("return",{ns:"global"}), close: i18next.t("close",{ns:"global"}), last: i18next.t("last",{ns:"global"}), next: i18next.t("next",{ns:"global"}), skip: i18next.t("jump",{ns:"global"}) }}
                            styles={{
                                options: {
                                    arrowColor: '#e3ffeb',
                                    backgroundColor: '#e3ffeb',
                                    primaryColor: '#E31E24',
                                  }
                            }}
                        />
                    </>
                
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"})+" "+i18next.t("organizationalstructure.area",{ns:"page"}):i18next.t("editar",{ns:"global"})+" "+i18next.t("organizationalstructure.area",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    isOpen={this.state.modalAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("nameof",{ns:"global"})+i18next.t("organizationalstructure.area",{ns:"page"})+":"}
                                labelFor="nombre-area"
                                intent="danger"
                                helperText={this.state.nombreArea?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("nameof",{ns:"global"})+i18next.t("organizationalstructure.area",{ns:"page"})} 

                            >
                                <InputGroup id="nombre-area" onChange={handleCambiarNombreArea} intent={this.state.nombreArea?"primary":"danger"} value={this.state.nombreArea?this.state.nombreArea:""} className="pixelAlrededor" fill={true} />
                            </FormGroup>
                            <FormGroup
                                label={!this.state.editarActivado?i18next.t("organizationalstructure.somesuggestions",{ns:"page"})+":":""}
                                labelFor="sugerencias"
                            >
                            {!this.state.editarActivado
                            
                            ?
                                <BootstrapTable
                                    keyField='id'
                                    data={ this.state.areasDialog }
                                    columns={ columnsSug }
                                    selectRow={ selectRowSug }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            :
                                <></>
                            }
                                
                            </FormGroup>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <AnchorButton
                                    onClick={cerrarModal}
                                >
                                    {i18next.t("close",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                                <Button
                                    id="crear-area"
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirArea:actualizarArea}
                                >
                                   {i18next.t("aceptar",{ns:"global"})}
                                </Button>
                        </div>
                    </div>
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("agregar",{ns:"global"})+ " " +i18next.t("organizationalstructure.area",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAreaInstantanea}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("nameof",{ns:"global"})+i18next.t("organizationalstructure.area",{ns:"page"})+":"}
                                labelFor="nombre-area"
                                intent="danger"
                                helperText={this.state.nombreAreaInsta?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("nameof",{ns:"global"})+i18next.t("organizationalstructure.area",{ns:"page"})} 

                            >
                                <InputGroup id="nombre-area" onChange={handleCambiarNombreAreaInsta} intent={this.state.nombreArea?"primary":"danger"} value={this.state.nombreAreaInsta} className="pixelAlrededor" fill={true} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <AnchorButton
                                    onClick={cerrarModal2}
                                >
                                    {i18next.t("close",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={anadirAreaInsta}
                                >
                                   {i18next.t("aceptar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
             
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})+ " "+ i18next.t("organizationalstructure.area",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("organizationalstructure.questiondelarea",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarArea}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>

            {/*<Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Cambiar empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalCambio}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>El empleado {this.state.empeladoExistente} está actualmente en el departamento {this.state.departamentoExistente}.
                            <br/>¿Desea cambiarlo de departamento? 
                        </p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cambiar empleado." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={cambiarEmpleado}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>*/}

            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})+" "+i18next.t("organizationalstructure.departamento",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarDepAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("organizationalstructure.questiondeldepartamento",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarDepartamento}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organizationalstructure.titledelempleado",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarEmpDepAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("organizationalstructure.questiondelempleado",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarEmpleadoDepartamento}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"})+" "+i18next.t("organizationalstructure.departamento",{ns:"page"}):i18next.t("editar",{ns:"global"})+" "+i18next.t("organizationalstructure.departamento",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalDepAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("organizationalstructure.namedepartamento",{ns:"page"})+":"}
                                labelFor="nombre-area"
                                intent="danger"
                                helperText={this.state.nombreDepartamento?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("organizationalstructure.namedepartamento",{ns:"page"})} 

                            >
                                <InputGroup id="nombre-area" onChange={handleCambiarNombreDepartamento} intent={this.state.nombreDepartamento?"primary":"danger"} value={this.state.nombreDepartamento} className="pixelAlrededor" fill={true} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("organizationalstructure.area",{ns:"page"})+":"}
                                labelFor="area-pertenece"
                                intent="danger"
                            >
                                <div id="select-departamento" style={!this.state.areaSeleccionadaSelect?{border:"1px solid red"}:{}}>
                                    <Select
                                        options={this.state.areas}
                                        className="pixelAlrededor"
                                        isClearable={true}
                                        onChange={seleccionarArea}
                                        isDisabled={this.state.editarActivado}
                                        value={this.state.areaSeleccionadaSelect}
                                    />
                                </div>
                            </FormGroup>
                            {this.state.hayContrato && this.state.nuevoActivado?
                                <Checkbox checked={this.state.agregarContrato} onChange={handleEnabledAgregarContrato} label={i18next.t("organizationalstructure.incluirdpto",{ns:"page"})} />
                            :null}
                            {!this.state.editarActivado?
                                <FormGroup>
                                    <Tooltip content={i18next.t("agregar",{ns:"global"})}>
                                        <Button icon="add" onClick={abrirModalAreaInstanea} />
                                    </Tooltip>
                                </FormGroup>
                            :<></>}
                    
                           
                                {!this.state.editarActivado
                                
                                ?
                                <Tree 
                                    ref={(input) => { this.arbolDialogo= input; }} 
                                    contents={this.state.areasDialog}
                                    onNodeClick={handleNodeClickDialog}
                                    onNodeCollapse={handleNodeCollapseDialog}
                                    onNodeExpand={handleNodeExpandDialog}
                                />

                                :
                                    <></>
                                }
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <AnchorButton
                                    onClick={cerrarModal}
                                >
                                    {i18next.t("close",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    id="boton-crear-departamento"
                                    onClick={this.state.nuevoActivado?anadirDepartamento:actualizarDepartamento}
                                >
                                {i18next.t("aceptar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("agregar",{ns:"global"})+" "+i18next.t("organizationalstructure.employees",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    isOpen={this.state.modalEmpleados}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("organizationalstructure.employees",{ns:"page"})+":"}
                                labelFor="empleados"
                                intent="danger"
                                helperText={!this.state.empleadoSeleccionadosSelect.length>0?i18next.t("emptyfield",{ns:"global"})+i18next.t("organizationalstructure.employees",{ns:"page"}):""} 

                            >
                            <div id="select-empleados" style={!this.state.empleadoSeleccionadosSelect.length>0?{border:"1px solid red"}:{}}>
                                <Select
                                    isMulti
                                    isClearable
                                    isSearchable
                                    name="departamentos"
                                    onChange={handleSeleccionarEmpleadosSelect}
                                    options={this.state.empleados}
                                    value={this.state.empleadoSeleccionadosSelect}
                                />
                            </div>                            
                            </FormGroup>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <AnchorButton
                                    onClick={cerrarModal}
                                >
                                    {i18next.t("close",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    id="boton-crear-empleado"
                                    intent={Intent.PRIMARY}
                                    onClick={anadirEmpleados}
                                >
                                   {i18next.t("aceptar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirAD}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title={i18next.t("print",{ns:"global"})+" "+i18next.t("organizationalstructure.titleareas",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirAD}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-ad">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirE}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title={i18next.t("print",{ns:"global"})+" "+i18next.t("organizationalstructure.titleempleados",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirE}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-e">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirADE}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title={i18next.t("print",{ns:"global"})+ " "+i18next.t("organizationalstructure.employees",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirADE}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-ade">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
              
                <div className="pixelAlrededor tablaMitadIzquierda">
                    <Card  interactive={true} elevation={Elevation.TWO} >
                    <Callout  className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("organizationalstructure.titleareas",{ns:"page"})}</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                {this.state.controlesDesactivados?
                                    <>
                                        <Button key="botonNuevo" className="bp3-minimal" icon="new-object" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                        <Button key="botonEditar" className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled/>
                                        <Button key="botonBorrar" className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled />
                                        <Button key="botonImprimir" className="bp3-minimal"  icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} disabled />
                                    </>
                                :
                                    <>
                                        <Popover isOpen={this.state.popoverAbierto} key="botonNuevo"   content={<Botonera />} transitionDuration={400} target={<Button className="bp3-minimal"  id="boton-nuevo"  icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} key="botonera_popover" onClick={abrirPopover} />} />
                                        <Button key="botonEditar"   className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} id="boton-editar" active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button key="botonBorrar" className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} id="boton-eliminar" active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                        <Button key="botonImprimir" className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} id="boton-imprimir" active={this.state.imprimirActivado} onClick={() => this.generarInformeAD()} />
                                        
                                    </>
                                }
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <Card interactive={true} elevation={Elevation.TWO} id="arbol-area">
                        <div style={this.state.controlesDesactivados?{backgroundColor:"#bfccd6"}:{}}>
                            <Tooltip content={i18next.t("tree.replegar",{ns:"global"})} position={Position.TOP} ><Button icon="exclude-row" onClick={()=>replegarArbol(this.state.areas)}/></Tooltip>
                            <Tooltip content={i18next.t("tree.desplegar",{ns:"global"})} position={Position.TOP} ><Button icon="add-column-right" onClick={()=>desplegarArbol(this.state.areas)}/></Tooltip>
                            <Tree 
                                contents={this.state.areas}
                                onNodeClick={handleNodeClick}
                                onNodeCollapse={handleNodeCollapse}
                                onNodeExpand={handleNodeExpand}
                            />
                        </div>
                        </Card>
                        
                        <Button
                            id="boton-guardar-cambios" 
                            alignText="right"
                            icon="floppy-disk"
                            text={i18next.t("guardar",{ns:"global"})}
                            onClick={guardarCambios}
                            disabled={!this.state.controlesDesactivados}
                            intent="danger"
                            className="bp3-intent-primary iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            text={i18next.t("cancelar",{ns:"global"})}
                            onClick={cancelarCambios}
                            disabled={!this.state.controlesDesactivados}
                            intent="danger"
                            className="bp3-intent-primary iconoIzquierda bp3-minimal"
                        />

                    </Card>
                </div>
                <div className="pixelAlrededor tablaMitadDerecha">
                    <Card interactive={true} elevation={Elevation.TWO} >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("organizationalstructure.titleempleados",{ns:"page"})}</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                {this.state.controlesDesactivados?
                                    <>
                                        <Button className="bp3-minimal"  icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoEmpActivado} disabled />
                                        <Button className="bp3-minimal"  icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarEmpActivado} disabled />
                                        <Button className="bp3-minimal"  icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirEmpActivado} disabled />
                                        <Button className="bp3-minimal"  icon="print" text={i18next.t("printgral",{ns:"global"})} active={this.state.imprimirAreaEmpActivado} disabled />
                                    </>
                                :
                                    <>
                                        <Button className="bp3-minimal" id="boton-nuevo-empleado" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoEmpActivado} onClick={abrirModalEmpleados} />
                                        <Button className="bp3-minimal" id="boton-eliminar-empleado" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarEmpActivado} onClick={abrirModalBorrarEmpDep} />
                                        <Button className="bp3-minimal" id="boton-imprimir-empleado" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirEmpActivado} onClick={() => this.generarInformeE()} />
                                        <Button className="bp3-minimal" id="boton-imprimir-empleado" icon="print" text={i18next.t("printgral",{ns:"global"})} active={this.state.imprimirAreaEmpActivado} onClick={() => this.generarInformeADE()} />
                                    </>
                                }
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        {this.state.cargandoEmpleado?
                            <Cargando />
                        :
                        <BootstrapTable
                            keyField='ID_EMPLEADO'
                            data={ this.state.empleadosTabla }
                            columns={ columns }
                            selectRow={ selectRow }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        }
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}

export default withTranslation()(withApollo(EstructuraOrganizativa))