/* eslint-disable array-callback-return */
import React from "react"
import { Spinner, AnchorButton,Checkbox, Navbar, Card, Alignment, ButtonGroup, Button, NavbarDivider, Tooltip, Classes, Intent, Dialog, FormGroup, TextArea, InputGroup } from "@blueprintjs/core"
import { DateInput} from "@blueprintjs/datetime"
import Select from "react-select"
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from "react-apollo";
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../../Pantallas/Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'
    },
    page:{
        paddingBottom:95,
        paddingTop:30,
        paddingLeft: 25,
        paddingRight: 20
    },
    body:{
        flex: 1,
        backgroundColor: '#FFFFFF',
        marginLeft:20,
    },
    row: {
        flexDirection: 'row',
        width: '100%'
      },
    imageHead: {
        width: '20%',
        maxHeight: 60,
        marginBottom: 20
    },
    rowHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
    },
    rowHeadTwo: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginBottom: 20,
        marginTop:-1
      },
      labelHead: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      colHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      rowTitleOne: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000'
      },
      labelTitle: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        textAlign: 'center'
      },
      text: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
      },
      footerPage: {
        position: 'absolute',
        fontSize: 8,
        bottom: 60,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'black',
        fontFamily: 'Open Sans Bold',
      },
      icono: {
        position: 'absolute',
        float: 'right',
        width: 40,
        bottom: 10,
        right: 20,
      },
      footer: {
        position: 'absolute',
        fontSize: 8,
        bottom: 10,
        left: 20,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        fontFamily: 'Open Sans',
      }
   
})

class PactosParasociales extends React.Component {
    state={
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        pactos: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        socios:[],
        sociosSeleccionados:[],
        fechaSeleccionadaInicio: new Date(),
        bolFechaSeleccionadaFin:null,
        fechaSeleccionadaFin:null,
        controlesDesactivados:false,
        descripcion:null,
        resultadoVotacion:null,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        pactoSeleccionado:null,
        modalImprimir:false,
        imprimirActivado:false,
        cargandoImprimir:false,
        cargandoPP: false,
        cargandoGrid:false
    }
  
    cargarPactos=async ()=>{
        
        let acuerdos=await this.props.client
        .query({
            query: gql` 
                {acuerdosParasociales(FILTROS:{ID_TIPO_ACUERDO:7}){ID_ACUERDO,FECHA_VIGENCIA,FECHA_VENCIMIENTO,DESCRIPCION,RESULTADO_VOTACION}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
            
            return result.data.acuerdosParasociales
            
        })


        //mostramos los datos en la tabla
        let datosPactos=[]
        await acuerdos.forEach(acuerdo=>{

            datosPactos.push({
                "idAcuerdo":acuerdo.ID_ACUERDO,
                "fechaInicio":moment(acuerdo.FECHA_VIGENCIA).format("DD-MM-YYYY"),
                "fechaVencimiento":acuerdo.FECHA_VENCIMIENTO?moment(acuerdo.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):null,
                "resultado":acuerdo.RESULTADO_VOTACION,
                "descripcion":acuerdo.DESCRIPCION
            })


        })


    
        datosPactos.forEach(async (dato, index) => {

            let socios = await this.props.client
                .query({
                    query: gql` 
                    {acuerdosParasocialesSocios(FILTROS:{ID_ACUERDO:${dato.idAcuerdo}}){ID_SOCIO,SOCIO{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
                `,
                    fetchPolicy: 'network-only'
                }).then(result => {
                    return result.data.acuerdosParasocialesSocios
                })

            socios.forEach(async (socio) => {
                datosPactos[index].datosSocios = []

                if (socio.SOCIO.TIPO_PERSONA.ID_TIPO_PERSONA === 1) {
                    this.props.personasFisicas.find(persona => {
                        if (persona.ID_PERSONA === socio.ID_SOCIO) {
                            datosPactos[index].datosSocios.push(persona)
                        }
                    })
                    //Si es persona juridica
                } else if (socio.SOCIO.TIPO_PERSONA.ID_TIPO_PERSONA === 2) {
                    this.props.personasJuridicas.find(persona => {
                        if (persona.ID_PERSONA === socio.ID_SOCIO) {
                            datosPactos[index].datosSocios.push(persona)
                        }
                    })

                }
            })

        })
     
        this.setState({pactos:datosPactos})

    }

    

    componentDidMount(props){
        
        this.setState({cargandoPP:true}, async() => {
            await this.cargarPactos()
            this.setState({cargandoPP:false,socios:this.props.socios})      
        })
    
    }

    imprimirPactosPS= async()=>{
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true,
            pactoSeleccionado:null,
            cargandoGrid:true
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.ImprimirPactosPS(), document.getElementById('imprimirPactosPS-pdf') )
            })
        })
    }

    ImprimirPactosPS = () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.body}>
                     <View fixed style={styles.row}>
                         <Image style={styles.imageHead} src={this.props.logotipo?this.props.logotipo:pantallaInicio} />
                     </View>
                <View fixed style={styles.rowHead}>
                  <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                  <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
              </View>
              <View fixed style={styles.rowHeadTwo}>
                  <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                  <Text style={styles.text}>{this.props.i18next.t("acuerdosparasociales.title",{ns:"page"}).toUpperCase()}</Text>
              </View>
              <View style={styles.rowTitleOne}>
                  <Text style={styles.labelTitle}>{this.props.i18next.t("acuerdosparasociales.otros",{ns:"page"})}</Text>
              </View>
              {this.state.pactos?this.state.pactos.map((pacto, index) => {
                  return(
                    <View key={index}>   
                         {index===0 && (                   
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{this.props.i18next.t("acuerdosparasociales.inicio",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{this.props.i18next.t("acuerdosparasociales.vencimiento",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{this.props.i18next.t("acuerdosparasociales.resultado",{ns:"page"}).toUpperCase()}</Text>
                            </View>
                         )}                   
                        <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{pacto.fechaInicio?pacto.fechaInicio:''}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{pacto.fechaVencimiento?pacto.fechaVencimiento:''}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{pacto.resultado?pacto.resultado:''}</Text>
                        </View>
                    </View>
                  )
              }):<></>}
                           
             </View> 
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                        fixed
                      />
                    <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                    />
                <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                </Page>
        </Document>
    </PDFViewer>
    )

    

    render(){

        const cerrarModalImprimir = () => {
            this.setState({modalImprimir:false,cargandoGrid:false})
        }


        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
    
            this.setState({
                imprimirActivado:false,
                modalImprimir:false,
                cargandoImprimir:false,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                fechaSeleccionadaInicio: new Date(),
                bolFechaSeleccionadaFin:null,
                fechaSeleccionadaFin:null,
                controlesDesactivados:false,
                descripcion:null,
                resultadoVotacion:null,
                modalAbierto: true,
                sociosSeleccionados:[],
                pactoSeleccionado:null,
                cargandoGrid:true
            })

        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
    
            if(!this.state.pactoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                fechaSeleccionadaInicio:new Date(moment(this.state.pactoSeleccionado.fechaInicio,"DD-MM-YYYY").format("YYYY-MM-DD")),
                bolFechaSeleccionadaFin:this.state.pactoSeleccionado.fechaVencimiento?true:false,
                fechaSeleccionadaFin:this.state.pactoSeleccionado.fechaVencimiento?new Date(moment(this.state.pactoSeleccionado.fechaVencimiento,"DD-MM-YYYY").format("YYYY-MM-DD")):null,
                resultadoVotacion:this.state.pactoSeleccionado.resultado,
                descripcion:this.state.pactoSeleccionado.descripcion,
                cargandoGrid:true
            })

            //si tenemos socios
            let socios=[]
            if(this.state.pactoSeleccionado["datosSocios"]){
                this.state.pactoSeleccionado["datosSocios"].forEach(socio=>{
                    let sl=this.state.socios.find(s=>{
                        return s.value===socio.ID_PERSONA
                    })
                    socios.push(sl)
                })
            }
            this.setState({sociosSeleccionados:socios})


        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
    

            if(!this.state.pactoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({modalBorrarAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        function dataFormatter(cell, row) {
            return (
                cell? cell : ""
            );
        }

        const columns = [
        {
        dataField: 'fechaInicio',
        text: this.props.i18next.t("acuerdosparasociales.inicio",{ns:"page"}),
        formatter: dataFormatter
        },
        {
            dataField: 'fechaVencimiento',
            text: this.props.i18next.t("acuerdosparasociales.vencimiento",{ns:"page"}),
            formatter: dataFormatter
            },
            {
                dataField: 'resultado',
                text: this.props.i18next.t("acuerdosparasociales.resultado",{ns:"page"}),
                formatter: dataFormatter
                }
        ];

        
        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        pactoSeleccionado: row
        });
        }
        return true;
        }

        const handleSeleccionarSocio = (value) => {
            this.setState({sociosSeleccionados:value})
        }

        const guardarCambios = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.pendienteGuardar){
                
                let idAcuerdo=0
                //mutacion para crear el pacto
                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{crearAcuerdoParasocial(VALORES:{ID_TIPO_ACUERDO:7,DESCRIPCION:"""${this.state.descripcion}""",FECHA_VIGENCIA:"${moment(this.state.fechaSeleccionadaInicio).format('YYYY-MM-DD')}",FECHA_VENCIMIENTO:${this.state.bolFechaSeleccionadaFin?'"'+moment(this.state.fechaSeleccionadaFin).format('YYYY-MM-DD')+'"':null},RESULTADO_VOTACION:${this.state.resultadoVotacion?'"'+this.state.resultadoVotacion+'"':null}}){ID_ACUERDO}}
                `
                })
                .then(result => {
        
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                    idAcuerdo=result.data.crearAcuerdoParasocial.ID_ACUERDO
                   
                
                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

                //insercion de los socios del pacto.
                for(let socio of this.state.sociosSeleccionados){

                    //insertamos cada uno de los socios que hemos seleccionado.
                    //mutacion para crear el pacto
                    await this.props.client
                    .mutate({
                    mutation: gql`
                        mutation{crearAcuerdoParasocialSocio(VALORES:{ID_ACUERDO:${idAcuerdo},ID_SOCIO:${socio.value}}){ID_ACUERDO,ID_SOCIO}}
                    `
                    })
                 
                }

                await this.cargarPactos()

                this.setState({
                    controlesDesactivados:false,
                    pendienteGuardar:false
                })

            }else if(this.state.pendienteActualizar){

                let error=false

                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{actualizarAcuerdoParasocial(ID_ACUERDO:${this.state.pactoSeleccionado.idAcuerdo},VALORES:{DESCRIPCION:"""${this.state.descripcion}""",FECHA_VIGENCIA:"${moment(this.state.fechaSeleccionadaInicio).format('YYYY-MM-DD')}",FECHA_VENCIMIENTO:${this.state.bolFechaSeleccionadaFin?'"'+moment(this.state.fechaSeleccionadaFin).format('YYYY-MM-DD')+'"':null},RESULTADO_VOTACION:${this.state.resultadoVotacion?'"'+this.state.resultadoVotacion+'"':null}}){ID_ACUERDO}}
                `
                })
                .then(result => {
                    
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                    
                }).catch(err=>{
                    error=true
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

                if(error)return
                //actualizamos los socios
             
                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{eliminarAcuerdoParasocialSocio(ID_ACUERDO:${this.state.pactoSeleccionado.idAcuerdo}){ID_SOCIO,ID_ACUERDO}}
                `
                })
        
                //actualizamos a los nuevos
                this.state.sociosSeleccionados.forEach(async socio=>{
                    
                    await this.props.client
                    .mutate({
                    mutation: gql`
                        mutation{crearAcuerdoParasocialSocio(VALORES:{ID_ACUERDO:${this.state.pactoSeleccionado.idAcuerdo},ID_SOCIO:${socio.value}}){ID_SOCIO,ID_ACUERDO}}
                    `
                    })

                })

                await this.cargarPactos()

                this.setState({
                    controlesDesactivados:false,
                    pendienteActualizar:false
                })

            }else if(this.state.pendienteBorrar){

                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{eliminarAcuerdoParasocialSocio(ID_ACUERDO:${this.state.pactoSeleccionado.idAcuerdo}){ID_SOCIO,ID_ACUERDO}}
                `
                })


                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{eliminarAcuerdoParasocial(ID_ACUERDO:${this.state.pactoSeleccionado.idAcuerdo}){ID_ACUERDO}}
                `
                }).then(result => {
                    this.setState({
                        pactoSeleccionado:null,
                    })

                    alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))
                    
                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })
                
                this.setState({
                    controlesDesactivados:false,
                    pendienteBorrar:false,
                    pactoSeleccionado:null
                })
                
            }


        }

        const cancelarCambios = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.cargarPactos()
            this.setState({
                controlesDesactivados:false,
                pendienteGuardar:false,
                pendienteActualizar:false,
                pendienteBorrar:false,
                pactoSeleccionado:null
            })
            
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false
            })
        }

        const escogeFechaInicio = (nuevaFecha) => {
            this.setState({fechaSeleccionadaInicio: nuevaFecha})
        }

        const escogeFechaFin = (nuevaFecha) => {
            this.setState({fechaSeleccionadaFin: nuevaFecha})
        }

        const anadirNuevo = () => {
            if(this.state.sociosSeleccionados.length===0)return
            if(!this.state.descripcion)return
            let pactos=this.state.pactos
            pactos.push({"fechaInicio":moment(this.state.fechaSeleccionadaInicio).format('DD-MM-YYYY'),"fechaVencimiento":this.state.bolFechaSeleccionadaFin?moment(this.state.fechaSeleccionadaFin).format('DD-MM-YYYY'):"","resultado":this.state.resultadoVotacion?this.state.resultadoVotacion:""})

            this.setState({
                pactos:pactos,
                pendienteGuardar:true,
                controlesDesactivados:true,
            })

            cerrarModal()
        }
        
        const actualizarPacto=()=>{

            if(this.state.sociosSeleccionados.length===0)return
            if(!this.state.descripcion)return
            let pactos=this.state.pactos
            pactos.find((pacto,index)=>{
                if(pacto.idAcuerdo===this.state.pactoSeleccionado.idAcuerdo){
                    pactos[index].fechaInicio=moment(this.state.fechaSeleccionadaInicio).format("DD-MM-YYYY")
                    pactos[index].fechaVencimiento=this.state.fechaSeleccionadaFin?moment(this.state.fechaSeleccionadaFin).format("DD-MM-YYYY"):""
                    pactos[index].resultado=this.state.resultadoVotacion
                }
                return pacto.idAcuerdo===this.state.pactoSeleccionado.idAcuerdo
            })
            
            cerrarModal()

            this.setState({
                pendienteActualizar:true,
                controlesDesactivados:true,
            })

        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        }

        const handleCambiarResultado = (ev) => {
            this.setState({resultadoVotacion:ev.currentTarget.value})
        }

        const handleCabmiarFechaFin = (ev) => {

            let valor=!this.state.bolFechaSeleccionadaFin
            this.setState({bolFechaSeleccionadaFin:valor})
            if(valor){
                this.setState({fechaSeleccionadaFin:new Date()})
            }else{
                this.setState({fechaSeleccionadaFin:null})
            }
        }

        const eliminarAcuerdo = () => {
            let pactos=this.state.pactos.filter(pacto => pacto.idAcuerdo !== this.state.pactoSeleccionado.idAcuerdo)
            this.setState({
                pactos:pactos,
                pendienteBorrar:true,
                controlesDesactivados:true
            })
            cerrarModal()
        };

        

        return (
            <>
               {this.state.cargandoPP?
               <Cargando />
               :
               <>
               <Dialog
                       transitionDuration={400}
                       autoFocus={true}
                       enforceFocus={true}
                       icon="print"
                       onClose={cerrarModalImprimir}
                       title={this.props.i18next.t("print",{ns:"global"})}
                       canEscapeKeyClose={true}
                       canOutsideClickClose={true}
                       isCloseButtonShown={true}
                       usePortal={true}
                       isOpen={this.state.modalImprimir}
                       style={styles.dialog}
                   >
                       {!this.state.cargandoImprimir?
                        <div style={{ width: '100%', height: '600px'}} id="imprimirPactosPS-pdf">
                        </div>
                        :
                        <Spinner />

                       }
                   </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className="pixelAlrededor">
                        <FormGroup
                            label={this.props.i18next.t("acuerdosparasociales.socios",{ns:"page"})+":"}
                            labelFor="socios"
                            fill={true}
                            intent="danger"
                            helperText={this.state.sociosSeleccionados.length===0?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("acuerdosparasociales.socios",{ns:"page"}):""}
                        >
                        <div style={!this.state.sociosSeleccionados.length>0?{border:"1px solid red"}:{}}>
                        <Select
                                isMulti
                                isClearable
                                isSearchable
                                name="departamentos"
                                onChange={handleSeleccionarSocio}
                                options={this.state.socios}
                                value={this.state.sociosSeleccionados}
                            />
                            </div>
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("acuerdosparasociales.inicio",{ns:"page"})+":"}
                            labelFor="fecha-inicio"
                            inline={true}
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaInicio}
                                value={this.state.fechaSeleccionadaInicio}
                            />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("acuerdosparasociales.vencimiento",{ns:"page"})+":"}
                            labelFor="fecha-vencimiento"
                            inline={true}
                        >
                            <Checkbox checked={this.state.bolFechaSeleccionadaFin} onChange={handleCabmiarFechaFin}></Checkbox>
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaFin}
                                value={this.state.fechaSeleccionadaFin}
                                disabled={!this.state.bolFechaSeleccionadaFin}
                            />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("description",{ns:"global"})+":"}
                            labelFor="descripcion"
                            intent="danger"
                            helperText={!this.state.descripcion?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("description",{ns:"global"}):""}
                        >
                            <TextArea
                                fill={true}
                                onChange={handleCambiarDescripcion}
                                intent={!this.state.descripcion?"danger":"primary"}
                                value={this.state.descripcion}
                            />
                        </FormGroup>
                        <FormGroup
                            label={this.props.i18next.t("acuerdosparasociales.resultado",{ns:"page"})+":"}
                            labelFor="resultado"
                        >
                            <InputGroup value={this.state.resultadoVotacion} onChange={handleCambiarResultado} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?anadirNuevo:actualizarPacto}
                            >
                              {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{this.props.i18next.t("acuerdosparasociales.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAcuerdo}
                            >
                                {this.props.i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <div>
                    <Card>
                    {this.state.cargandoGrid?
                            <Cargando/>
                        :
                        <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <NavbarDivider />
                                        <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} disabled={this.state.controlesDesactivados} />
                                        <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar} disabled={this.state.controlesDesactivados}/>
                                        <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar} disabled={this.state.controlesDesactivados}/>
                                        <Button className="iconoDerecha bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={() => this.imprimirPactosPS()}>{this.props.i18next.t("print",{ns:"global"})}</Button>

                                </ButtonGroup>
                            </Navbar.Group>
                            <Navbar.Group align={Alignment.RIGHT}>
                                <ButtonGroup>
                                    <Button 
                                        alignText="right"
                                        icon="cross"
                                        text={this.props.i18next.t("cancelar",{ns:"global"})}
                                        onClick={cancelarCambios}
                                        disabled={!this.state.controlesDesactivados}
                                        className="bp3-intent-danger iconoDerecha bp3-minimal"
                                    />
                                    <Button 
                                        alignText="right"
                                        icon="floppy-disk"
                                        text={this.props.i18next.t("guardar",{ns:"global"})}
                                        disabled={!this.state.controlesDesactivados}
                                        onClick={guardarCambios}
                                        className="bp3-intent-danger iconoDerecha bp3-minimal"
                                    />
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                        keyField='idAcuerdo'
                        data={ this.state.pactos }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                        />
                        <br />
                        </>
                        }
                    </Card>
                </div>
            </>
            } 
        </>
        )
    }
}
export default withApollo(PactosParasociales)