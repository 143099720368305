import React, {Component} from 'react';
import {
    Button,
    Card,
    Callout,
    Elevation,
    Intent,
    Navbar,
    ButtonGroup,
    Alignment,
    Dialog,
    Spinner
} from "@blueprintjs/core";
import {withApollo} from 'react-apollo';
import { Cargando } from "../Pantallas/Cargando";
import gql from "graphql-tag";
import ReactDOM from 'react-dom';
import LegislacionProcedimientos from "../Pantallas/Elementos/LegislacionProcedimientos";
import DatosProcedimientos from "../Pantallas/Elementos/DatosProcedimientos";
import SituacionActual from "../Pantallas/Elementos/SituacionActual";
import ProcedimientosGral from "../Pantallas/Elementos/ProcedimientosGral";
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import iconoFooter from '../../Assets/lc.png';
import moment from 'moment';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import styles from './stylesPrint';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';


const OPT_CONTEXTO = 1;
const OPT_OBLIGACIONES = 2;
const OPT_CONTRATOS = 3;
const OPT_RIESGOS_PENALES = 4;
const OPT_DIGITAL = 5;
const OPT_BLANQUEOS = 6;
const OPT_SERVICIOS = 7;

class Procedimientos extends Component {
    state = {
        cargando: true,    
        bloques: [],
        bloqueSeleccionado: null,
        cargandoImprimir:false,
        modalImprimir:false,
        datosEmpresa:null,
        logotipo:null,
        datosProcedimientos:[],
        situacionActual:[],
        procedimientosGral:[],
        permitir:false
    }

    componentDidMount=async()=>{
        await this.obtenerLogoEmpresa();
        await this.cargarDatos();
        await this.cargarBloques();

        this.setState({
            cargando: false
        })
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    cargarBloques=async()=>{
        await this.props.client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            this.setState({bloques:result.data.bloquesRiesgo});
        });
        
    }

    generarImprimir= async() => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            this.setState({
                cargandoImprimir:false
            }, () => {
                ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
            });
           
            
        })
    }

    async cargarDatosSituacionActual() {
        this.props.client
       .query({
         query: gql`
           {bloquesDatosSituacionActual(FILTROS:{ID_BLOQUE:${this.state.bloqueSeleccionado.ID_BLOQUE}}){
            ID,
            ID_BLOQUE,
            DESCRIPCION,
            ID_ADJUNTO,
            ADJUNTO{DATOS,NOMBRE},
            ID_ADJUNTO_2,
            ADJUNTO_2{DATOS,NOMBRE},
            ID_ADJUNTO_3,
            ADJUNTO_3{DATOS,NOMBRE}}}
         `,
         fetchPolicy:'network-only'
       }).then(async(result)=>{
            this.setState({situacionActual:result.data.bloquesDatosSituacionActual});
       })
    }

    cargarDatosProcedimientosGral() {
        this.props.client
       .query({
         query: gql`
           {bloquesDatosProcedimientos(FILTROS:{ID_BLOQUE:${this.state.bloqueSeleccionado.ID_BLOQUE}}){
            ID,
            ID_BLOQUE,
            PROCEDIMIENTO,
            ID_ADJUNTO,
            ADJUNTO{DATOS,NOMBRE},
            ID_ADJUNTO_2,
            ADJUNTO_2{DATOS,NOMBRE},
            ID_ADJUNTO_3,
            ADJUNTO_3{DATOS,NOMBRE}}}
         `,
         fetchPolicy:'network-only'
       }).then(async(result)=>{
            this.setState({procedimientosGral:result.data.bloquesDatosProcedimientos});
       })
    }

    async cargarDatosProcedimientos(tipo) {
       return await this.props.client
       .query({
         query: gql`
           {complianceRiesgos(FILTROS:{ID_BLOQUE:${this.state.bloqueSeleccionado.ID_BLOQUE},TIPO:"${tipo}"}){
            ID_RIESGO,
            ID_BLOQUE,
            TIPO,
            DESCRIPCION,
            CODIGO_RIESGO,
            TITULO}}
         `,
         fetchPolicy:'network-only'
       }).then((result)=>{
            let data=this.state.datosProcedimientos;
            data[tipo]=result.data.complianceRiesgos;
        this.setState({datosProcedimientos:data});
       })
    }

    Imprimir=()=>{
        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.state.bloqueSeleccionado.TITULO}</Text>
                </View>
                {this.state.bloqueSeleccionado.ID_BLOQUE===OPT_CONTEXTO?
                    (
                    <>
                    <View style={styles.colHead}>
                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.context", { ns: "page2" }).toUpperCase()}</Text>                                                                
                    </View>
                    {this.state.datosProcedimientos['contexto'].length>0?
                        this.state.datosProcedimientos['contexto'].map((dato, index)=>{
                            return (
                                    
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>
                                
                            )
                        }):<></>}
                    <View style={{
                    marginTop:10
                    }}></View>
                    <View style={styles.colHead}>
                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.licenses", { ns: "page2" }).toUpperCase()}</Text>                                                                
                    </View>
                    {this.state.datosProcedimientos['licencias'].length>0?
                        this.state.datosProcedimientos['licencias'].map((dato, index)=>{
                            return (
                                    
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>
                                
                            )
                        }):<></>}
                    
                    <View style={{
                    marginTop:10
                    }}></View>
                    <View style={styles.colHead}>
                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.competitiveness", { ns: "page2" }).toUpperCase()}</Text>                                                                
                    </View>
                    {this.state.datosProcedimientos['competividad'].length>0?
                        this.state.datosProcedimientos['competividad'].map((dato, index)=>{
                            return (
                                    
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>
                                
                            )
                        }):<></>}

                    <View style={{
                    marginTop:10
                    }}></View>
                    <View style={styles.colHead}>
                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.operationalProcedures", { ns: "page2" }).toUpperCase()}</Text>                                                                
                    </View>
                    {this.state.datosProcedimientos['procedimientos operativos'].length>0?
                        this.state.datosProcedimientos['procedimientos operativos'].map((dato, index)=>{
                            return (
                                    
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>
                                
                            )
                        }):<></>}  

                    <View style={{
                    marginTop:10
                    }}></View>
                    <View style={styles.colHead}>
                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                    </View>
                    {this.state.datosProcedimientos['otros contextos'].length>0?
                        this.state.datosProcedimientos['otros contextos'].map((dato, index)=>{
                            return (
                                    
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>
                                
                            )
                        }):<></>}


                        </>)
                    :this.state.bloqueSeleccionado.ID_BLOQUE===OPT_OBLIGACIONES?
                        (
                        <>
                        <View style={styles.colHead}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.municipal", { ns: "page2" }).toUpperCase()}</Text>                                                                
                        </View>
                        {this.state.datosProcedimientos['municipales'].length>0?
                            this.state.datosProcedimientos['municipales'].map((dato, index)=>{
                                return (
                                        
                                    <View style={styles.colHead} key={index}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                    </View>
                                    
                                )
                            }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.provincial", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['provinciales'].length>0?
                                this.state.datosProcedimientos['provinciales'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.autonomous", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['autonomicas'].length>0?
                                this.state.datosProcedimientos['autonomicas'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.national", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['nacionales'].length>0?
                                this.state.datosProcedimientos['nacionales'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}

<View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.community", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['comunitarias'].length>0?
                                this.state.datosProcedimientos['comunitarias'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.sectoral", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['sectoriales'].length>0?
                                this.state.datosProcedimientos['sectoriales'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['otras obligaciones'].length>0?
                                this.state.datosProcedimientos['otras obligaciones'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                        </>)
                    :this.state.bloqueSeleccionado.ID_BLOQUE===OPT_CONTRATOS?
                        (
                        <>
                        <View style={styles.colHead}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.private", { ns: "page2" }).toUpperCase()}</Text>                                                                
                        </View>
                        {this.state.datosProcedimientos['privados'].length>0?
                            this.state.datosProcedimientos['privados'].map((dato, index)=>{
                                return (
                                        
                                    <View style={styles.colHead} key={index}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                    </View>
                                    
                                )
                            }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.public", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['publicos'].length>0?
                                this.state.datosProcedimientos['publicos'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.withPartners", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['con socios'].length>0?
                                this.state.datosProcedimientos['con socios'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                                    <Text style={{ textAlign: 'center', flexDirection: 'column', fontSize: 8, fontFamily: 'Open Sans Bold', width: '100%' }}>{ i18next.t("procedimientosCompliance.withAdministrators", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['con administradores'].length>0?
                                this.state.datosProcedimientos['con administradores'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}

<View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.seniorManagement", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['de alta direccion'].length>0?
                                this.state.datosProcedimientos['de alta direccion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.international", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['internacionales'].length>0?
                                this.state.datosProcedimientos['internacionales'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['otros contratos'].length>0?
                                this.state.datosProcedimientos['otros contratos'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                        </>)
                    :this.state.bloqueSeleccionado.ID_BLOQUE===OPT_RIESGOS_PENALES?
                        (
                        <>
                        <View style={styles.colHead}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.economic", { ns: "page2" }).toUpperCase()}</Text>                                                                
                        </View>
                        {this.state.datosProcedimientos['economicos'].length>0?
                            this.state.datosProcedimientos['economicos'].map((dato, index)=>{
                                return (
                                        
                                    <View style={styles.colHead} key={index}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                    </View>
                                    
                                )
                            }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.security", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['seguridad'].length>0?
                                this.state.datosProcedimientos['seguridad'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.urbanPlanning", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['urbanismo'].length>0?
                                this.state.datosProcedimientos['urbanismo'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                 <Text style={{ textAlign: 'center', flexDirection: 'column', fontSize: 8, fontFamily: 'Open Sans Bold', width: '100%' }}>{i18next.t("procedimientosCompliance.environment", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['medio ambiente'].length>0?
                                this.state.datosProcedimientos['medio ambiente'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}

<View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.publicHealth", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['salud publica'].length>0?
                                this.state.datosProcedimientos['salud publica'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.administration", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['administracion'].length>0?
                                this.state.datosProcedimientos['administracion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['otros riesgos penales'].length>0?
                                this.state.datosProcedimientos['otros riesgos penales'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                        </>)
                    :this.state.bloqueSeleccionado.ID_BLOQUE===OPT_DIGITAL?
                        (
                        <>
                        <View style={styles.colHead}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.cybersecurity", { ns: "page2" }).toUpperCase()}</Text>                                                                
                        </View>
                        {this.state.datosProcedimientos['ciberseguridad'].length>0?
                            this.state.datosProcedimientos['ciberseguridad'].map((dato, index)=>{
                                return (
                                        
                                    <View style={styles.colHead} key={index}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                    </View>
                                    
                                )
                            }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.lopd", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['lopd'].length>0?
                                this.state.datosProcedimientos['lopd'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.electronicCommerce", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['lssi y ce'].length>0?
                                this.state.datosProcedimientos['lssi y ce'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.trademarksAndDomains", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['marcas y dominios'].length>0?
                                this.state.datosProcedimientos['marcas y dominios'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}

<View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['otros digital'].length>0?
                                this.state.datosProcedimientos['otros digital'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            
                        </>)
                    :this.state.bloqueSeleccionado.ID_BLOQUE===OPT_BLANQUEOS?
                        (
                        <>
                        <View style={styles.colHead}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.concealment", { ns: "page2" }).toUpperCase()}</Text>                                                                
                        </View>
                        {this.state.datosProcedimientos['ocultacion'].length>0?
                            this.state.datosProcedimientos['ocultacion'].map((dato, index)=>{
                                return (
                                        
                                    <View style={styles.colHead} key={index}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                    </View>
                                    
                                )
                            }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.acquisition", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['adquisicion'].length>0?
                                this.state.datosProcedimientos['adquisicion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.conversion", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['conversion'].length>0?
                                this.state.datosProcedimientos['conversion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.investment", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['inversion'].length>0?
                                this.state.datosProcedimientos['inversion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}

<View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.supply", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['suministro'].length>0?
                                this.state.datosProcedimientos['suministro'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.distribution", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['distribucion'].length>0?
                                this.state.datosProcedimientos['distribucion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            
                            
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['otros blanqueos'].length>0?
                                this.state.datosProcedimientos['otros blanqueos'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                        </>)
                    :this.state.bloqueSeleccionado.ID_BLOQUE===OPT_SERVICIOS?
                        (
                        <>
                        <View style={styles.colHead}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.subcontracting", { ns: "page2" }).toUpperCase()}</Text>                                                                
                        </View>
                        {this.state.datosProcedimientos['subcontratacion'].length>0?
                            this.state.datosProcedimientos['subcontratacion'].map((dato, index)=>{
                                return (
                                        
                                    <View style={styles.colHead} key={index}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                    </View>
                                    
                                )
                            }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.tempAgencies", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['etts'].length>0?
                                this.state.datosProcedimientos['etts'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.assignment", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['cesion'].length>0?
                                this.state.datosProcedimientos['cesion'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.subrogations", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['subrogaciones'].length>0?
                                this.state.datosProcedimientos['subrogaciones'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}

                            <View style={{
                            marginTop:10
                            }}></View>
                            <View style={styles.colHead}>
                                <Text style={{ textAlign: 'center', flexDirection: 'column', fontSize: 8, fontFamily: 'Open Sans Bold', width: '100%' }}>{i18next.t("procedimientosCompliance.others", { ns: "page2" }).toUpperCase()}</Text>                                                                
                            </View>
                            {this.state.datosProcedimientos['otros servicios'].length>0?
                                this.state.datosProcedimientos['otros servicios'].map((dato, index)=>{
                                    return (
                                            
                                        <View style={styles.colHead} key={index}>
                                            <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                        </View>
                                        
                                    )
                                }):<></>}
                        </>)
                    :<></>
                }
                <View style={{
                    flexDirection: 'row',
                    width: '100%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#000000',
                    marginTop:10
                }}>
                    <Text style={{ textAlign: 'center', flexDirection: 'column', fontSize: 8, fontFamily: 'Open Sans Bold', width: '100%' }}>{ i18next.t("procedimientosCompliance.currentSituationDescription", { ns: "page2" }).toUpperCase()}</Text>                                                                
                </View>
                    {this.state.situacionActual.length>0?
                        this.state.situacionActual.map((dato,index)=>{
                            return (
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>
                            )
                    }):<></>}
                <View style={{
                    flexDirection: 'row',
                    width: '100%',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#000000',
                    marginTop:10
                }}>
                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("procedimientosCompliance.generalProcedures", { ns: "page2" }).toUpperCase()}</Text>                                                                
                </View>
                    {this.state.procedimientosGral.length>0?
                        this.state.procedimientosGral.map((dato,index)=>{
                            return (
                                <View style={styles.colHead} key={index}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{dato.PROCEDIMIENTO?dato.PROCEDIMIENTO:''}</Text>
                                </View>
                            )
                    }):<></>}
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page", { ns: "global" })} ${pageNumber} ${i18next.t("de", { ns: "global" })} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    seleccionarBloque(bloque) {
        this.setState({
            bloqueSeleccionado:bloque,
            permitir:false
        },async ()=>{
            switch(bloque.ID_BLOQUE){
                case OPT_CONTEXTO:
                    //COTEXTO
                    await this.cargarDatosProcedimientos('contexto');
                    await this.cargarDatosProcedimientos('licencias');
                    await this.cargarDatosProcedimientos('competividad');
                    await this.cargarDatosProcedimientos('procedimientos operativos');
                    await this.cargarDatosProcedimientos('otros contextos');
                    this.setState({permitir:true});
                break;
                case OPT_OBLIGACIONES:
                    //OBLIGACIONES
                    await this.cargarDatosProcedimientos('municipales');
                    await this.cargarDatosProcedimientos('provinciales');
                    await this.cargarDatosProcedimientos('autonomicas');
                    await this.cargarDatosProcedimientos('nacionales');
                    await this.cargarDatosProcedimientos('comunitarias');
                    await this.cargarDatosProcedimientos('sectoriales');
                    await this.cargarDatosProcedimientos('otras obligaciones');
                    this.setState({permitir:true});
                break;
                case OPT_CONTRATOS:
                    //CONTRATOS
                    await this.cargarDatosProcedimientos('privados');
                    await this.cargarDatosProcedimientos('publicos');
                    await this.cargarDatosProcedimientos('con socios');
                    await this.cargarDatosProcedimientos('con administradores');
                    await this.cargarDatosProcedimientos('de alta direccion');
                    await this.cargarDatosProcedimientos('internacionales');
                    await this.cargarDatosProcedimientos('otros contratos');
                    this.setState({permitir:true});
                break;
                case OPT_RIESGOS_PENALES:
                    //RIESGOS PENALES
                    await this.cargarDatosProcedimientos('economicos');
                    await this.cargarDatosProcedimientos('seguridad');
                    await this.cargarDatosProcedimientos('urbanismo');
                    await this.cargarDatosProcedimientos('medio ambiente');
                    await this.cargarDatosProcedimientos('salud publica');
                    await this.cargarDatosProcedimientos('administracion');
                    await this.cargarDatosProcedimientos('otros riesgos penales');    
                    this.setState({permitir:true});
                break;
                case OPT_DIGITAL:
                    //DIGITAL
                    await this.cargarDatosProcedimientos('ciberseguridad');
                    await this.cargarDatosProcedimientos('lopd');
                    await this.cargarDatosProcedimientos('lssi y ce');
                    await this.cargarDatosProcedimientos('marcas y dominios');
                    await this.cargarDatosProcedimientos('otros digital');
                    this.setState({permitir:true});
                break;
                case OPT_BLANQUEOS:
                    //BLANQUEOS
                    await this.cargarDatosProcedimientos('ocultacion');
                    await this.cargarDatosProcedimientos('adquisicion');
                    await this.cargarDatosProcedimientos('conversion');
                    await this.cargarDatosProcedimientos('inversion');
                    await this.cargarDatosProcedimientos('suministro');
                    await this.cargarDatosProcedimientos('distribucion');
                    await this.cargarDatosProcedimientos('otros blanqueos');
                    this.setState({permitir:true});
                break;
                case OPT_SERVICIOS:
                    //SERVICIOS
                    await this.cargarDatosProcedimientos('subcontratacion');
                    await this.cargarDatosProcedimientos('etts');
                    await this.cargarDatosProcedimientos('cesion');
                    await this.cargarDatosProcedimientos('subrogaciones');
                    await this.cargarDatosProcedimientos('otros servicios');
                    
                    this.setState({permitir:true});
                break;
                default:
                    break;
            }
            
            await this.cargarDatosSituacionActual();
            await this.cargarDatosProcedimientosGral();
                    
        });
    }

    render() {

        const cerrarModalImprimir = () => {
            this.setState({
                modalImprimir:false
            });
        }

        return (
            <>
                {this.state.cargando?
                    <Cargando/>
                :
                <>
                    <div className="pixelAlrededor">
                        <div className="tablaSesenta">
                            <Card interactive={true} id="card-bloques" elevation={Elevation.TWO}>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("procedimientosCompliance.blocks", { ns: "page2" })}</Callout>
                                <Navbar>
                                    <Navbar.Group>
                                        <ButtonGroup align={Alignment.LEFT}>
                                            <Button key="botonImprimir" className="bp3-minimal" icon="print" disabled={this.state.bloqueSeleccionado && this.state.permitir?false:true} text={i18next.t("print", { ns: "global" })}  onClick={()=>this.generarImprimir()}/>
                                        </ButtonGroup>
                                    </Navbar.Group>
                                </Navbar>
                                {this.state.bloques?this.state.bloques.map((bloque,index)=>{
                                    return (
                                        <Button 
                                            key={index} 
                                            style={{
                                                display: 'initial',
                                                width:'100%',
                                                marginTop:2,
                                                marginBottom:2
                                            }} 
                                            intent={this.state.bloqueSeleccionado?this.state.bloqueSeleccionado.ID_BLOQUE===bloque.ID_BLOQUE?Intent.PRIMARY:'':''} 
                                            className="bp3-button" 
                                            type="button"
                                            onClick={()=>this.seleccionarBloque(bloque)}>
                                            {bloque.TITULO}
                                        </Button>
                                    )
                                }):<></>}
                            </Card>    
                        </div>
                        <div className="tablaCuarenta">
                            <Card interactive={true} id="card-bloques" elevation={Elevation.TWO}>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("procedimientosCompliance.applicableLegislation", { ns: "page2" })}</Callout>
                                <LegislacionProcedimientos editable={this.props.editable} bloqueSeleccionado={this.state.bloqueSeleccionado?this.state.bloqueSeleccionado.ID_BLOQUE:null} />                                
                            </Card>
                        </div>
                    </div>
                    <div id="contenedor-recursos-humanos">
                
                    <div className="pixelAlrededor">
                        <Card interactive={true} id="card-bloques" elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.bloqueSeleccionado?this.state.bloqueSeleccionado.TITULO:''}</Callout>
                            <DatosProcedimientos editable={this.props.editable} bloqueSeleccionado={this.state.bloqueSeleccionado?this.state.bloqueSeleccionado:null} />
                        </Card>
                        <br></br>
                        <Card interactive={true} id="card-bloques" elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("procedimientosCompliance.currentSituationDescription", { ns: "page2" }).toUpperCase()}</Callout>
                            <SituacionActual editable={this.props.editable} bloqueSeleccionado={this.state.bloqueSeleccionado?this.state.bloqueSeleccionado.ID_BLOQUE:null} />
                        </Card>
                        <br></br>
                        <Card interactive={true} id="card-bloques" elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("procedimientosCompliance.generalProcedures", { ns: "page2" }).toUpperCase()}</Callout>
                            <ProcedimientosGral editable={this.props.editable} bloqueSeleccionado={this.state.bloqueSeleccionado?this.state.bloqueSeleccionado.ID_BLOQUE:null} />
                        </Card>
                    </div>
                    </div>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print", { ns: "global" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                </>
                }
            </>
        )
    }
}

export default withTranslation()(withApollo(Procedimientos));