import React, { Component } from 'react';
import { FormGroup, Button, Card, Elevation, Dialog, FileInput, Callout, Classes, Tooltip, AnchorButton, Intent, InputGroup } from "@blueprintjs/core"
import { Editor } from 'react-draft-wysiwyg';
import axios from 'axios';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from "../Pantallas/Cargando";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class CodigoEtico extends Component {
    state={
        subida1Activada: false,
        subida2Activada: false,
        edicion1Activada: false,
        edicion2Activada: false,
        nombreManual:null,
        archivoManual:null,
        contenidoManual:null,
        archivoReglamento:null,
        tipoArchivoReglamento:null,
        nombreReglamento:null,
        subidaEtico: false,
        archivoEtico:null,
        nombreEtico:null,
        descripcionEtico:null,
        contenidoEtico:null,
        cargando: false,
        editorState: 
            {
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign','link', 'embedded', 'image', 'remove', 'history']
            },
            toolbarState: 
            {
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'history'],
                inline: {
                    options: ['bold', 'italic', 'underline'],
                },
            },
            contentState: "",
            
    }


    recargarManual=async()=>{
        await this.props.client
        .query({
            query: gql`
            {adjuntos(FILTROS:{ID_TIPO_ADJUNTO:20}){ID_ADJUNTO,DATOS}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){
                this.setState({contenidoManual:result.data.adjuntos[0]}) 
            }
        })

    }

    descargarManual = () => {
        let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.contenidoManual.DATOS.replace(".",""), '_blank')
        win.focus()
    }

    descargarCodigoEtico= () => {
        let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.contenidoEtico.DATOS.replace(".",""), '_blank')
        win.focus()
    }

    recargarReglamento=async()=>{
        await this.props.client
        .query({
            query: gql`
            {adjuntos(FILTROS:{ID_TIPO_ADJUNTO:21}){ID_ADJUNTO,DATOS}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){
                this.setState({contenidoReglamento:result.data.adjuntos[0]}) 
            }
        })

    }

    recargarEtico=async()=>{
        await this.props.client
        .query({
            query: gql`
            {adjuntos(FILTROS:{ID_TIPO_ADJUNTO:22}){ID_ADJUNTO,DATOS}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){
                this.setState({contenidoEtico:result.data.adjuntos[0]}) 
            }
        })

    }

    descargarReglamento = () => {
        let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.contenidoReglamento.DATOS.replace(".",""), '_blank')
        win.focus()
    }

    componentDidMount=()=>{

        this.setState({cargando: true})
        
        this.recargarManual()
        this.recargarReglamento()
        this.recargarEtico()

        this.setState({cargando: false})
    }
    

    render() {

        const abrirModalSubida = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({
                subida2Activada: true,
                archivoManual:null,
                nombreManual:null,
            })
        }

        const abrirModalSubidaReglamento = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                subida1Activada: true,
                archivoReglamento:null,
                nombreReglamento:null,
                descargarReglamento:null
            })
        }

        const abrirModalSubidaEtico = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                subidaEtico: true,
                archivoEtico:null,
                nombreEtico:null,
                descripcionEtico:null
            })
        }

        const cerrarModal = () => {
            this.setState({
                subida1Activada: false,
                subida2Activada: false,
                subidaEtico:false,
                edicion1Activada: false,
                edicion2Activada: false,
            })
        }

        const añadirNuevo = (value) => {
            //console.log(value);
        }

        const subirManual=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            if(extension!=="doc" && extension!=="docx"){
                alert(i18next.t("empresa.tabdocumentos.invaliddoc",{ns:"company"}))
                return
            }
            this.setState({archivoManual:ev.target.files[0]})
            this.setState({tipoArchivoManual:ev.target.files[0].type})
            this.setState({nombreManual:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const subirReglamento=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            if(extension!=="doc" && extension!=="docx"){
                alert(i18next.t("empresa.tabdocumentos.invaliddoc",{ns:"company"}))
                return
            }
            this.setState({archivoReglamento:ev.target.files[0]})
            this.setState({tipoArchivoReglamento:ev.target.files[0].type})
            this.setState({nombreReglamento:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const subirEtico=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            if(extension!=="pdf"){
                alert(i18next.t("empresa.tabdocumentos.invalidpdf",{ns:"company"}))
                return
            }
            this.setState({archivoEtico:ev.target.files[0]})
            this.setState({tipoArchivoEtico:ev.target.files[0].type})
            this.setState({nombreEtico:ev.target.files[0].name.replace("."+extension,"")})
          
        }
        

        const guardarManual=async ()=>{

            if(!this.state.archivoManual)return
            if(!this.state.nombreManual)return

            const api = axios.create({
                withCredentials: true
            });
            var formData = new FormData();
            formData.append("files",this.state.archivoManual);

            //eliminamos el anterior manual.
            if(this.state.contenidoManual){
                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.contenidoManual.ID_ADJUNTO}){ID_ADJUNTO}}`)
            }
            

            await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:20,NOMBRE:"${this.state.nombreManual}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"CÓDIGO ÉTICO"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.recargarManual()
                cerrarModal()
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })


        }

        const guardarReglamento=async ()=>{

            if(!this.state.archivoReglamento)return
            if(!this.state.nombreReglamento)return

            const api = axios.create({
                withCredentials: true
            });
            var formData = new FormData();
            formData.append("files",this.state.archivoReglamento);

            //eliminamos el anterior manual.
            if(this.state.contenidoReglamento){
                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.contenidoReglamento.ID_ADJUNTO}){ID_ADJUNTO}}`)
            }
            

            await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:21,NOMBRE:"${this.state.nombreReglamento}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"REGLAMENTO DE CONDUCTAS"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.recargarReglamento()
                cerrarModal()
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })


        }


        const guardarEtico=async ()=>{

            if(!this.state.archivoEtico)return
            if(!this.state.nombreEtico)return

            const api = axios.create({
                withCredentials: true
            });
            var formData = new FormData();
            formData.append("files",this.state.archivoEtico);

            //eliminamos el anterior manual.
            if(this.state.contenidoEtico){
                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.contenidoEtico.ID_ADJUNTO}){ID_ADJUNTO}}`)
            }
            
            await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:22,NOMBRE:"${this.state.nombreEtico}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"REGLAMENTO DE CONDUCTAS"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.recargarEtico()
                cerrarModal()
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })


        }



        const cambiarNombreManual=(ev)=>{
            this.setState({nombreManual:ev.currentTarget.value})            
        }

        const cambiarNombreReglamento=(ev)=>{
            this.setState({nombreReglamento:ev.currentTarget.value})            
        }

        const cambiarNombreEtico=(ev)=>{
            this.setState({nombreEtico:ev.currentTarget.value})            
        }

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabdocumentos.titleaddreglamento",{ns:"company"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.subida1Activada}
                    className="dialog-ancho"
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label={i18next.t("empresa.tabdocumentos.fileadd",{ns:"company"})}
                        labelFor="file-input"
                        labelInfo="(required)"
                    >
                        <FileInput className="pixelAlrededor" text={this.state.archivoReglamento?this.state.archivoReglamento.name:""} style={this.state.archivoReglamento?{}:{border:"1px solid red"}} onInputChange={subirReglamento} fill={true} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("empresa.tabdocumentos.namefileadd",{ns:"company"})}
                        labelFor="text-input"
                        intent="danger"
                        helperText={!this.state.nombreReglamento?i18next.t("empresa.tabdocumentos.validfileadd",{ns:"company"}):""}
                    >
                        <InputGroup id="text-input" value={this.state.nombreReglamento} intent={!this.state.nombreReglamento?"danger":"primary"} onChange={cambiarNombreReglamento} placeholder={i18next.t("empresa.tabdocumentos.namefileadd",{ns:"company"})} />
                    </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={guardarReglamento}
                            >
                                {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabdocumentos.titleaddprevencion",{ns:"company"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.subida2Activada}
                    className="dialog-ancho"
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label={i18next.t("empresa.tabdocumentos.fileadd",{ns:"company"})}
                        labelFor="file-input"
                        labelInfo="(required)"
                    >
                        <FileInput className="pixelAlrededor" text={this.state.archivoManual?this.state.archivoManual.name:""} style={this.state.archivoManual?{}:{border:"1px solid red"}} onInputChange={subirManual} fill={true} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("empresa.tabdocumentos.namefileadd",{ns:"company"})}
                        labelFor="text-input"
                        intent="danger"
                        helperText={!this.state.nombreManual?i18next.t("empresa.tabdocumentos.validfileadd",{ns:"company"}):""}
                    >
                        <InputGroup id="text-input" value={this.state.nombreManual} intent={!this.state.nombreManual?"danger":"primary"} onChange={cambiarNombreManual} placeholder={i18next.t("empresa.tabdocumentos.namefileadd",{ns:"company"})} />
                    </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content="Añadir nuevo Manual de prevención de riesgos penales." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={guardarManual}
                            >
                                {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabdocumentos.titleaddetico",{ns:"company"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.subidaEtico}
                    className="dialog-ancho"
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label={i18next.t("empresa.tabdocumentos.fileadd",{ns:"company"})}
                        labelFor="file-input"
                        labelInfo="(required)"
                    >
                        <FileInput className="pixelAlrededor" text={this.state.archivoEtico?this.state.archivoEtico.name:""} style={this.state.archivoEtico?{}:{border:"1px solid red"}} onInputChange={subirEtico} fill={true} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("empresa.tabdocumentos.namefileadd",{ns:"company"})}
                        labelFor="text-input"
                        intent="danger"
                        helperText={!this.state.nombreEtico?i18next.t("empresa.tabdocumentos.validfileadd",{ns:"company"}):""}
                    >
                        <InputGroup id="text-input" value={this.state.nombreEtico} intent={!this.state.nombreEtico?"danger":"primary"} onChange={cambiarNombreEtico} placeholder={i18next.t("empresa.tabdocumentos.namefileadd",{ns:"company"})} />
                    </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={guardarEtico}
                            >
                                {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabdocumentos.titleaddreglamento",{ns:"company"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.edicion1Activada}
                >
                    <Card>
                    <Editor 
                        initialEditorState={this.state.editorState}
                        wrapperClassName="editor-menu-modificado bp3-card bp3-elevation-2"
                        editorClassName="editor-texto-modificado bp3-card bp3-elevation-2"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={this.state.toolbarState}
                        onContentStateChange={this.onContentStateChange}
                    />
                    </Card>

                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={añadirNuevo}
                            >
                                {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabdocumentos.titleaddprevencion",{ns:"company"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.edicion2Activada}
                >
                    <Card>
                    <Editor 
                        initialEditorState={this.state.editorState}
                        wrapperClassName="editor-menu-modificado bp3-card bp3-elevation-2"
                        editorClassName="editor-texto-modificado bp3-card bp3-elevation-2"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={this.state.toolbarState}
                        onContentStateChange={this.onContentStateChange}
                    />
                    </Card>

                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={añadirNuevo}
                            >
                                {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <div>
                <div className="tablaTercio">
                    <Card interactive={true} elevation={Elevation.TWO} id="codigo-etico" className="cardDocumentos">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.titleetico",{ns:"company"})}</Callout>
                                <FormGroup
                                    label={i18next.t("empresa.tabdocumentos.adjuntar",{ns:"company"})+": "}
                                    labelFor="informacion"
                                    inline={true}
                                >
                                    <Button icon="upload" onClick={abrirModalSubidaEtico} />

                                </FormGroup>
                                {this.state.contenidoEtico?
                                <FormGroup
                                label={i18next.t("empresa.tabdocumentos.consultar",{ns:"company"})+": "}
                                labelFor="código"
                                inline={true}
                                >
                                    <Button icon="download" onClick={this.descargarCodigoEtico} />

                                </FormGroup>
                            :<></>}
                        
                    </Card>
                </div>
                <div className="tablaTercio">
                    <Card interactive={true} elevation={Elevation.TWO} id="reglamento-conducta-laboral" className="cardDocumentos">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.titlereglamento",{ns:"company"})}</Callout>
                        <FormGroup
                            label={i18next.t("empresa.tabdocumentos.adjuntar",{ns:"company"})+": "}
                            labelFor="informacion"
                            inline={true}
                        >
                            <Button icon="upload" onClick={abrirModalSubidaReglamento} />

                        </FormGroup>
                        {this.state.contenidoReglamento?
                        <FormGroup
                        label={i18next.t("empresa.tabdocumentos.consultar",{ns:"company"})+": "}
                        labelFor="manual"
                        inline={true}
                        >
                            <Button icon="download" onClick={this.descargarReglamento} />

                        </FormGroup>
                        :<></>}
                    </Card>
                </div>
                <div className="tablaTercio">
                    <Card interactive={true} elevation={Elevation.TWO} id="manual-prevencion-riesgos-penales" className="cardDocumentos">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.titleprevencion",{ns:"company"})}</Callout>
                        <FormGroup
                            label={i18next.t("empresa.tabdocumentos.adjuntar",{ns:"company"})+": "}
                            labelFor="informacion"
                            inline={true}
                        >
                            <Button icon="upload" onClick={abrirModalSubida} />

                        </FormGroup>
                        {this.state.contenidoManual?
                        <FormGroup
                        label={i18next.t("empresa.tabdocumentos.consultar",{ns:"company"})+": "}
                        labelFor="manual"
                        inline={true}
                        >
                            <Button icon="download" onClick={this.descargarManual} />

                        </FormGroup>
                        :<></>}
                    </Card>
                </div>
                </div>
            </>
            }
        </>
        )
    }
}export default withTranslation()(withApollo(CodigoEtico));