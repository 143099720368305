import React from "react";
import { Tabs, Tab } from "@blueprintjs/core";
import InformacionGeneral from "../Pantallas/Elementos/InformacionGeneralNuevo";
import MediosContacto from "../Pantallas/Elementos/MediosContacto";
import CentrosTrabajo from "../Pantallas/Elementos/CentrosTrabajo";
import Documentos from "../Pantallas/Elementos/Documentos";
import Vinculos from "../Pantallas/Elementos/Vinculos";
import ArchivosAdjuntos from "../Pantallas/Elementos/ArchivosAdjuntos";
export class DatosPersonasJuridicas extends React.Component {
  
    cambiarPestaña = navbarTabId => this.setState({ pestaña: navbarTabId });

    guardarCambios=()=>{
        this.informacion.handleGuardarDatos()
    }

    render() {

    return (
      <div>
        <Tabs
          id="datos-empresa"
          animate={this.animacion}
          onChange={this.cambiarPestaña}
          selectedTabId={this.pestaña}
          className="separarBordes"
        >
          <Tab
            id="informacion-general"
            title={this.props.i18next.t("juridicas.general",{ns:"page"})}
            panel={
              <InformacionGeneral
                i18next={this.props.i18next}
                empresaSeleccionada={
                  this.props.empresaSeleccionada
                    ? this.props.empresaSeleccionada.ID_PERSONA
                    : null
                }
                ref={input => {
                  this.informacion = input;
                }}
              />
            }
          />
          <Tab
            id="medios de contacto"
            title={this.props.i18next.t("juridicas.medios",{ns:"page"})}
            panel={
              <MediosContacto
                i18next={this.props.i18next}
                 ref={(input) => { this.medios= input; }}
                empresaSeleccionada={
                  this.props.empresaSeleccionada
                    ? this.props.empresaSeleccionada.ID_PERSONA
                    : null
                }
              />
            }
          />
          <Tab
            id="centros de trabajo"
            title={this.props.i18next.t("juridicas.centros",{ns:"page"})}
            panel={
              <CentrosTrabajo
                i18next={this.props.i18next}
                ref={(input) => { this.centros= input; }}
                empresaSeleccionada={
                  this.props.empresaSeleccionada
                    ? this.props.empresaSeleccionada.ID_PERSONA
                    : null
                }
              />
            }
          />
          {this.props.empresaSeleccionada &&
          this.props.empresaSeleccionada.EMPRESA_PRINCIPAL ? (
            <Tab
              id="vinculos"
              title={this.props.i18next.t("juridicas.vinculos",{ns:"page"})}
              panel={
                <Vinculos
                  i18next={this.props.i18next}
                  ref={(input) => { this.vinculos= input; }}
                  empresaSeleccionada={
                    this.props.empresaSeleccionada
                      ? this.props.empresaSeleccionada.ID_PERSONA
                      : null
                  }
                />
              }
            />
          ) : (
            <></>
          )}
            {this.props.empresaSeleccionada &&
          this.props.empresaSeleccionada.EMPRESA_PRINCIPAL ? (
            <Tab
              id="documentos"
              title={this.props.i18next.t("juridicas.documentos",{ns:"page"})}
              panel={
                <Documentos
                  i18next={this.props.i18next}
                  ref={(input) => { this.vinculos= input; }}
                  empresaSeleccionada={
                    this.props.empresaSeleccionada
                      ? this.props.empresaSeleccionada.ID_PERSONA
                      : null
                  }
                />
              }
            />
          ) : (
            <></>
          )}
          <Tab
            id="archivos-adjuntos"
            title={this.props.i18next.t("juridicas.adjuntos",{ns:"page"})}
            panel={
              <ArchivosAdjuntos
                i18next={this.props.i18next}
                ref={(input) => { this.adjuntos= input; }}
                empresaSeleccionada={
                  this.props.empresaSeleccionada
                    ? this.props.empresaSeleccionada.ID_PERSONA
                    : null
                }
              />
            }
          />
        </Tabs>
      </div>
    );
  }
}
