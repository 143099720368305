/* eslint-disable array-callback-return */
import React from "react"
import {Spinner, FormGroup, Card, Alignment, Button,TextArea, Switch ,Tree,AnchorButton,Intent,Dialog,Classes} from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


class Resolucion extends React.Component {

    state={
        resuelta: false,
        personaResponsable: [
        ],
        personaResponsableSeleccionada:null,
        fechaResolucion:new Date(),
        riesgosAfectados:null,
        decisionesTomadas:null,
        modalRiesgos: false,
        arbolRiesgos: [
        
        ],
        riesgosSeleccionados: [],
        riesgosSel:null,
        riesgoSeleccionadoTabla:null,
        cargandoR:false
    }


    reiniciarEstado() {
        this.setState({
            resuelta: false,
            personaResponsableSeleccionada:null,
            fechaResolucion:new Date(),
            riesgosAfectados:null,
            decisionesTomadas:null
        });
    }

    cargarPersonas=async()=>{
        let personasFisicas= await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`
        }).then(async(result)=>{
            //console.log(result)
           return result.data.personasFisicas
        })   
        let personasJuridicas=  await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}`
        }).then(async(result)=>{
            return result.data.personasJuridicas
        })     
            let personas=[]
            personasFisicas.forEach(async(persona)=>{
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 
            })
            personasJuridicas.forEach(async(persona)=>{
                personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL}) 
             })

        this.setState({personaResponsable:personas})

        if(this.props.datosResolucion && Object.keys(this.props.datosResolucion).length>0){
            
            let riesgos=this.props.datosResolucion.RIESGOS_AFECTADOS?this.props.datosResolucion.RIESGOS_AFECTADOS.split(","):""
            for(let r of riesgos){
                this.buscarRiesgo(this.state.arbolRiesgos,parseInt(r))
            }

            this.setState({resuelta:this.props.datosResolucion})
            this.setState({decisionesTomadas:this.props.datosResolucion?this.props.datosResolucion.DECISIONES_TOMADAS:null})
            this.setState({fechaResolucion:this.props.datosResolucion?new Date(this.props.datosResolucion.FECHA_RESOLUCION):new Date()})

            //buscamos el medio .
            let perosonaSel=personas.find(persona=>{
                return persona.value===this.props.datosResolucion.ID_RESPONSABLE
            })

            this.setState({personaResponsableSeleccionada:perosonaSel})

        }else{
            this.setState({noexiste:true})
        }

    }

    buscarRiesgo=async(arbol,idRiesgo)=>{
         
        for(let hijo of arbol){
            if(parseInt(hijo.id.split("_").pop())===idRiesgo && hijo.id.includes("riesgo_")){
                let riesgos=this.state.riesgosSeleccionados
                riesgos.push(hijo)
                this.setState({riesgosSeleccionados:riesgos})
                return 
            }else{
                if(hijo.childNodes)this.buscarRiesgo(hijo.childNodes,idRiesgo)
            }
        }

    }

    tieneHijos=(riesgo,arrayRiesgos)=>{
        var arrayHijos=[];
        let codigo=riesgo.label.split("-")[0].trim()
        let index=1
        var pad = "00"

        arrayRiesgos.find(element=>{
            if(element.ID_RIESGO_PADRE===parseInt(riesgo.id.split("_").pop())){
                arrayHijos.push({"depth":(element.childNodes?element.childNodes.length:0),id:"riesgo_"+element.ID_RIESGO,"label":codigo+pad.substring(0, pad.length - String(index).length) + index+" - "+element.TITULO,childNodes:(element.childNodes?element.childNodes:null),"descripcion":element.DESCRIPCION,"icon":(element.childNodes?"folder-close":"share")})
                index++
            }
            return
        
        });
        if(arrayHijos.length>0){
            riesgo.icon="folder-close"
            riesgo.childNodes=arrayHijos;
        }
        return riesgo;
    }
    cargarBloques=async ()=>{
        var pad = "00"

        //obtencion de los bloques de riesgo
        let bloquesRiesgo=await client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.bloquesRiesgo;
        })

        let complianceRiesgos=await client.query({
            query:gql`{complianceRiesgos{ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE,ID_BLOQUE}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceRiesgos
        })
        
       let bloques=[]
            for(let bloque of bloquesRiesgo){        
                let index=1
                //obtencion de los riesgos de cada bloque.
                let hijos=[]
                // eslint-disable-next-line array-callback-return
                complianceRiesgos.find((hijo)=>{
                    if (hijo.ID_BLOQUE===bloque.ID_BLOQUE) {
                        if(hijo.ID_RIESGO_PADRE===null){
                            let nuevoHijo={"depth":(hijo.childNodes?hijo.childNodes.length:0),"id":"riesgo_"+hijo.ID_RIESGO,"label":bloque.LETRA+pad.substring(0, pad.length - String(index).length) + index+" - "+hijo.TITULO,"descripcion":hijo.DESCRIPCION,"icon":"share","childNodes":(hijo.childNodes?hijo.childNodes:null)}
                            hijos.push(nuevoHijo)
                            this.tieneHijos(nuevoHijo,complianceRiesgos)
                            index++
                        }
                    
                    }
                })
                bloques.push({"depth":hijos.length,"id":"bloque_"+bloque.ID_BLOQUE,"label":"Bloque "+bloque.LETRA+" - "+bloque.TITULO,"icon":"folder-close",childNodes:hijos}) 
         
            }
            this.setState({arbolRiesgos:bloques})


    }

    componentDidMount=async()=>{
        this.setState({cargandoR:true}, async() =>{
            await this.cargarBloques()
            await this.cargarPersonas()  
            this.setState({cargandoR:false})
        })
    }

    handleNodeCollapse = (nodeData) => {
        nodeData.isExpanded = false;
        this.setState(this.state);
        };
        
        handleNodeExpand = (nodeData) => {
            nodeData.isExpanded = true;
            this.setState(this.state);
        };
        
        forEachNode(nodes, callback) {
            if (nodes == null) {
                return;
            }
    
            for (const node of nodes) {
                callback(node);
                this.forEachNode(node.childNodes, callback);
            }
    }
    
    handleNodeClick = (nodeData, _nodePath, e) => {

        
        if(this.state.riesgosSel){
            let riesgoSel=this.state.riesgosSel
            riesgoSel.isSelected=false
        }


        //si es un riesgo...
        if(nodeData.id.includes("riesgo_")){    
            this.setState({riesgosSel:nodeData})
            nodeData.isSelected=true
        } else {
            this.setState({riesgosSel:null})
        }
    }


    render(){

   

        const handleCambiarComienzaResolucion=(value)=>{
            this.setState({resuelta:!this.state.resuelta})
        }

        const handleCambiarFechaResolucion=(value)=>{
            this.setState({fechaResolucion:value})
        }

        const handleCambiarPersonaResponsable=(value)=>{
            this.setState({personaResponsableSeleccionada:value})
        }

        const handleDecisionesTomadas=(ev)=>{
            this.setState({decisionesTomadas:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
    

        const abrirModalRiesgos = () => {

            //cerramos el arbol
            replegarArbol(this.state.arbolRiesgos)

            if(this.state.riesgosSel){
                let riesgoSel=this.state.riesgosSel
                riesgoSel.isSelected=false
            }
            this.setState({modalRiesgos: true})
        }

        const cerrarModal = () => {
            this.setState({modalRiesgos: false})
        }

        const seleccionarRiesgo = () => {
         
            if(!this.state.riesgosSel){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //agregamos el riesgo a la tabla.
            let riesgos=this.state.riesgosSeleccionados
            //comprobamos que no este ya seleccionado.
            let existe=this.state.riesgosSeleccionados.find(ries=>{return ries.id.split("_").pop()===this.state.riesgosSel.id.split("_").pop()})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }
            riesgos.push(this.state.riesgosSel)
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:this.state.riesgosSel
            })

            cerrarModal()
      
        }

        const eliminarRiesgoTabla = () => {
         
            if(!this.state.riesgoSeleccionadoTabla){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let riesgos=this.state.riesgosSeleccionados
            riesgos=riesgos.filter(riesgo=>{return riesgo.id!==this.state.riesgoSeleccionadoTabla.id})
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:null
            })
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    riesgoSeleccionadoTabla: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const columns = [{
            dataField: 'label',
            text: i18next.t("identificacion.name",{ns:"page"})
            }
        ];

        return (
            <>
            {this.state.cargandoR===true?
                <Spinner/>
            :
            <>
            
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("edicionriesgo.riesgos",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalRiesgos}
                >
                <div className={Classes.DIALOG_BODY}>
                    <Tree
                        contents={this.state.arbolRiesgos}
                        onNodeDoubleClick={this.handleNodeClick}
                        onNodeCollapse={this.handleNodeCollapse}
                        onNodeExpand={this.handleNodeExpand}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={seleccionarRiesgo}>
                        {i18next.t("identificacion.escogerriesgo",{ns:"page"})}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        {i18next.t("close",{ns:"global"})}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card>
                            {(this.state.resuelta && (this.props.datosResolucion && Object.keys(this.props.datosResolucion).length >0 ))?
                                <Switch checked={true} disabled label={i18next.t("denuncias.dardenunciaresuelta",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaResolucion} />
                            :
                                <Switch id="boleano-resolucion" checked={this.state.resuelta} label={i18next.t("denuncias.dardenunciaresuelta",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaResolucion} />
                            }      
                            <div id="datos-resolucion">                
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.fecharesolucion",{ns:"page"}).toLowerCase())}
                                labelFor="fecha-resolucion"
                                disabled={!this.state.resuelta}
                                inline={true}
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={handleCambiarFechaResolucion}
                                    value={this.state.fechaResolucion}
                                    align={Alignment.RIGHT}
                                    disabled={!this.state.resuelta}
                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.personaresponsable",{ns:"page"}).toLowerCase())}
                                labelFor="persona-responsable"
                                disabled={!this.state.resuelta}
                                intent="danger"
                                helperText={this.state.personaResponsableSeleccionada || !this.state.resuelta?"":i18next.t("denuncias.noselectpersonaresponsable",{ns:"page"})}
                            >
                                <div style={this.state.personaResponsableSeleccionada || !this.state.resuelta?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.personaResponsable}
                                        className="pixelAlrededor"
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        isDisabled={!this.state.resuelta}
                                        onChange={handleCambiarPersonaResponsable}
                                        value={this.state.personaResponsableSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <div className="tablaTreinta">
                                <FormGroup
                                    label={const_table.capitalize(i18next.t("denuncias.riesgosafectados",{ns:"page"}).toLowerCase())}
                                    labelFor="riesgos-relacionados"
                                    disabled={!this.state.resuelta}
                                >
                                {this.state.riesgosSeleccionados.length>0?
                                        <BootstrapTable
                                            keyField='id'
                                            data={ this.state.riesgosSeleccionados }
                                            columns={ columns }
                                            selectRow={ const_table.selectRow(handleOnSelect) }
                                            pagination={ paginationFactory(const_table.options) }
                                            headerClasses="header-class"
                                            rowClasses="row-class"
                                            hover
                                            condensed
                                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                        />
                                    :
                                        <p>{i18next.t("identificacion.debeaddriesgo",{ns:"page"})}</p>
                                }                          
                                <Button label={i18next.t("denuncias.addriesgo",{ns:"page"})} disabled={!this.state.resuelta} icon="add" onClick={abrirModalRiesgos} />
                                <Button label={i18next.t("denuncias.delriesgo",{ns:"page"})} disabled={!this.state.resuelta} icon="delete" onClick={eliminarRiesgoTabla} />
                            </FormGroup>
                            </div>
                            <div className="tablaSetenta">
                                <FormGroup
                                    label={const_table.capitalize(i18next.t("denuncias.decisionestomadas",{ns:"page"}).toLowerCase())}
                                    labelFor="motivo-denuncia"
                                    disabled={!this.state.resuelta}
                                    intent="danger"
                                    helperText={this.state.decisionesTomadas || !this.state.resuelta?"":i18next.t("denuncias.emptydecisionestomadas",{ns:"page"})}
                                >
                                    <TextArea fill={true} intent={this.state.decisionesTomadas || !this.state.resuelta?"primary":"danger"} value={this.state.decisionesTomadas} onChange={handleDecisionesTomadas} disabled={!this.state.resuelta} />
                                </FormGroup>
                            </div>
                        </div>
                    </Card>
                </div>
            </>
            }
          </> 

        )
    }
}

export default withTranslation()(Resolucion);