import React, { Component } from 'react';
import logo from '../Assets/lc.png';
import { Icon, Intent, Tooltip, Position } from "@blueprintjs/core";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { withRouter} from "react-router-dom";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { ES, GB } from 'country-flag-icons/react/3x2'

class Cabecera extends Component {
  state = {
    username:null,
    
  }

  componentDidMount=async()=>{
    //obtenemos los datos del usuario logueado en la aplicacion
    let cliente = new ApolloClient({
        uri: process.env.REACT_APP_DOMINIO+"/bdds",
        credentials: 'include',
    });


      await cliente
      .query({
          query: gql`
          {obtenerUsuariosLoginActual{ID_USUARIO,USUARIO,NOMBRE,APELLIDOS,ID_PERFIL}}
          `,
          fetchPolicy:'network-only'
      }).then(async result1=>{    
          this.setState({
              username:result1.data.obtenerUsuariosLoginActual.USUARIO,
        })
        
      });
  }
  
  render() {
    
    
    return (
        <div className="Cabecera bp3-running-text">
            <img src={logo} className="App-logo-cabecera" alt="logo" />
            <h5 className="texto-blanco">{i18next.t(this.props.menu,{ns:"menu"})}</h5>
            <h5 className="texto-blanco">{this.props.bdd}</h5>
            <div>
            <Tooltip content={i18next.t("header.cerrarsesion",{ns:'global'})} position={Position.RIGHT}>
            <Icon icon="log-out" iconSize={24} onClick={this.cerrarSesion} className="iconoAyuda" intent={Intent.PRIMARY} />
            </Tooltip>
            {this.state.username!=="usuarioweb@example.com" && (
              <Tooltip content={i18next.t("header.perfilusuario",{ns:'global'})} position={Position.RIGHT}>
              <Icon icon="user" iconSize={24} onClick={this.irAperfil} className="iconoAyuda" intent={Intent.PRIMARY} />
              </Tooltip>
            )}
            {this.state.username==="soporte@lexcontrol.es" && (
              <Tooltip content={i18next.t("header.cambiarempresa",{ns:'global'})} position={Position.RIGHT}>
              <Icon icon="refresh" iconSize={24} onClick={this.cambiarBdd} className="iconoAyuda" intent={Intent.PRIMARY} />
              </Tooltip>
            )}
            <Tooltip content={i18next.t("header.ayuda",{ns:'global'})} position={Position.RIGHT}>
              <Icon icon="help" iconSize={24} onClick={this.activarAyuda} className="iconoAyuda" intent={Intent.PRIMARY} />
            </Tooltip>
            <Tooltip content={i18next.t("header.traducirES",{ns:'global'})} position={Position.RIGHT}>
              <Icon icon={<ES width={40} className="iconoAyudaSvg" onClick={this.props.changeLngES}/>} iconSize={24}  className="iconoAyuda" intent={Intent.PRIMARY} />
            </Tooltip>
            <Tooltip content={i18next.t("header.traducirEN",{ns:'global'})} position={Position.RIGHT}>
              <Icon icon={<GB width={40} className="iconoAyudaSvg" onClick={this.props.changeLngEN}/>} iconSize={24}  className="iconoAyuda" intent={Intent.PRIMARY} />
            </Tooltip>
            
            </div>
        </div> 
    );
  }

  activarAyuda=()=>{
    this.props.setAyuda()
    this.setState(this.state)
  }

  cerrarSesion=async()=>{

    let client = new ApolloClient({
      uri: process.env.REACT_APP_DOMINIO+"/bdds",
      credentials: 'include',
    });
    await client
      .query({
        query: gql`
          {
            cerrarSesionUsuariosLoginActual
          }
        `
    }).then(()=>{
      localStorage.clear();
      this.props.setLogueado(false);
      this.props.limpiarBddSeleccionada()
      this.props.history.push("/")
    })

  }

  cambiarBdd=()=>{

      let client = new ApolloClient({
        uri: process.env.REACT_APP_DOMINIO+"/bdds",
        credentials: 'include',
      });
      client
      .query({
        query: gql`
          {
            limpiarBddSeleccionada
          }
        `
    }).then(()=>{
      this.setState({usuario:null})
      this.setState({menu:null})
      this.setState({bdd:null})
      this.props.limpiarBddSeleccionada()
      this.props.setPerfil(false)

      this.props.history.push("/")
    })

  }

  irAperfil = async() => {
    await this.props.setPerfil(true)
    await this.props.history.push("/perfil")
  }
}

export default withTranslation()(withRouter(Cabecera));
