import React, { Component } from 'react';
import './App.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css'
import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import ContenedorPrincipal from './Contenedores/ContenedorPrincipal'
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {

  render() {
    return (
      <div className="App">
          <ContenedorPrincipal />
      </div>
    );
  }
}

export default App;

