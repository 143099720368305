import React from "react"

import CodigoEtico from "../CodigoEtico"
import RSC from "../RSC"
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class Documentos extends React.Component {
    render(){

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("empresa.tabdocumentos.title",{ns:"company"});

        return (
            <>  
                <div className="pixelAlrededor">
                    <CodigoEtico editable={this.props.editable}/>
                </div>
                <div className="pixelAlrededor">
                    <RSC editable={this.props.editable}/>
                </div>
            </>
        )
    }
}

export default withTranslation()(Documentos)