/* eslint-disable no-useless-escape */
import React from "react"
import { FormGroup, InputGroup, TextArea } from "@blueprintjs/core"

export default class Retribucion extends React.Component {

    state={
        asignacion:0,
        dietas: null,
        beneficios: null,
        retribucion: null,
        acciones: null,
        indemnizacion: null,
        ahorro: null,
        asignacionValida:true
    }

    componentDidMount(props){

        if(this.props.datos && Object.keys(this.props.datos).length >0){

            this.setState({
                asignacion:Intl.NumberFormat('es-ES').format(this.props.datos.ASIGNACION_FIJA),
                dietas: this.props.datos.DIETAS_ASISTENCIA,
                beneficios: this.props.datos.PARTICIPACION_BENEFICIOS,
                retribucion: this.props.datos.RETRIBUCION_VARIABLE,
                acciones: this.props.datos.REMUNERACION_ACCIONES,
                indemnizacion: this.props.datos.INDEMNIZACIONES_CESE,
                ahorro: this.props.datos.SISTEMAS_AHORRO,

            })

        }
    }

    render(){

        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }
    
        const cambiarAsignacion=(ev)=>{

              //solo permitir en el input numeros,comas y puntos.
              let pattern=/^[0-9,.]*$/
              if(pattern.test(ev.currentTarget.value)===false)return
  
              //comprobacion que sea un número válido
              let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
              if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                  this.setState({asignacionValida:true})
              }else{
                  this.setState({asignacionValida:false})
              }
  
              this.setState({asignacion:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
          
        }

        const cambiarDietas=(ev)=>{
            this.setState({dietas:ev.currentTarget.value})
        }

        const cambiarRetribucion=(ev)=>{
            this.setState({retribucion:ev.currentTarget.value})
        }

        const cambiarBeneficios=(ev)=>{
            this.setState({beneficios:ev.currentTarget.value})
        }

        const cambiarAcciones=(ev)=>{
            this.setState({acciones:ev.currentTarget.value})
        }

        const cambiarIndemnizacion=(ev)=>{
            this.setState({indemnizacion:ev.currentTarget.value})
        }

        const cambiarAhorro=(ev)=>{
            this.setState({ahorro:ev.currentTarget.value})
        }

        return (
            <>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.asignacion",{ns:"page"})+":"}
                    labelFor="asignacion"
                    fill={true}
                    intent="danger"
                    helperText={!this.state.asignacionValida?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("organoadministracion.asignacion",{ns:"page"}):""}
                >
                    <InputGroup id="asignacion" type="text" ref={(input) => { this.inputAsignacion= input; }} intent={!this.state.asignacionValida?"danger":"primary"}  onChange={cambiarAsignacion}  value={this.state.asignacion} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.dietas",{ns:"page"})+":"}
                    labelFor="dietas"
                >
                    <TextArea id="dietas" fill={true} onChange={cambiarDietas} value={this.state.dietas} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.participacion",{ns:"page"})+":"}
                    labelFor="beneficios"
                >
                    <TextArea id="beneficios" fill={true} onChange={cambiarBeneficios} value={this.state.beneficios} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.retvariable",{ns:"page"})+":"}
                    labelFor="retribucion"
                >
                    <TextArea id="retribucion" fill={true} onChange={cambiarRetribucion} value={this.state.retribucion} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.remacciones",{ns:"page"})+":"}
                    labelFor="acciones"
                >
                    <TextArea id="acciones" fill={true} onChange={cambiarAcciones} value={this.state.acciones} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.indemnizacion",{ns:"page"})+":"}
                    labelFor="indemnizacion"
                >
                    <TextArea id="indemnizacion" fill={true} onChange={cambiarIndemnizacion} value={this.state.indemnizacion} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.prevision",{ns:"page"})+":"}
                    labelFor="ahorro"
                >
                    <TextArea id="ahorro" fill={true}  onChange={cambiarAhorro} value={this.state.ahorro} />
                </FormGroup>
            </>
        )
    }
}