/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Spinner, Card, Elevation, Navbar, NavbarHeading, ButtonGroup, Alignment, Button, Dialog, FormGroup, Classes, Tooltip, AnchorButton, Intent } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "./Cargando";
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import const_table from './BootstrapTable';
import paginationFactory from 'react-bootstrap-table2-paginator';



import styles from './stylesPrint';

class OrganosResolucion extends Component {
    state={
        asesorSeleccionado:null,
        modalAsesoresBuscarAbierto: false,

        pendienteGuardarEmpleados:null,
        modalAsesoresAbierto: false,
        empleadoSelectSeleccionado:null,
        personaAsesorSeleccionada:null,
        empleadoSeleccionado:null,
        pendienteBorrarEmpleado:null,
        modalEmpleadosAbierto:null,
        controlesDesactivados:false,

        controlesEmpleadosDesactivados:false,
        buscarMiembroActivado:true,
        eliminarMiembroActivado:false,
        nuevoAsesorActivado:true,
        buscarAsesorActivado:false,
        eliminarAsesorActivado:false,

        empleadosSelect:[
        ],
        empleados:[
        ],
        asesoresExternos:[        
        ],
        clientesSelect: [
        ],
       
        modalBorrarEmpleadoAbierto:false,
        modalBorrarAsesorAbierto:false,
        modalPersonaAbierto:false,
        modalImprimirOA:false,
        cargandoImprimirOA:false,
        modalImprimirOM:false,
        cargandoImprimirOM:false,
        datosEmpresa:[],
        logotipo:null,
        cargando:false,
        personasFisicas:null,
        esEmpleado:null,
        columnWidths:[100],
        resized: false,
    }
   
    
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
    }
   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarEmpresa() 
    let personasFisicas= await this.cargarPersonasFisicas()
    let empleado=await this.obtenerEmpleados()
    this.setState({
        datosEmpresa:datosEmpresa,
        personasFisicas:personasFisicas,
        esEmpleado:empleado
    })

    }

    cargarPersonasFisicas=async()=>{
       let personasFisicas= await this.props.client
            .query({
                query: gql`
                {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{
                return result.data.personasFisicas 
            })
             return personasFisicas    
    }
    obtenerEmpleados=async()=>{
       let empleado=await this.props.client
            .query({
                query: gql`
                {empleados{ID_EMPLEADO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
               return result.data.empleados
            })
            return empleado
    
    }

    esEmpleado=async(idPersona)=>{

        let empleadosVal=[]
        this.state.esEmpleado.find(empleado=>{
            if(empleado.ID_EMPLEADO===idPersona){
                empleadosVal.push(empleado)
            }
        })
        if(empleadosVal.length>0){
            return true
        }else{
            return false
        }

    }

    cargarAsesores=async ()=>{
        
        let complianceOrganos=await this.props.client
        .query({
            query: gql`
            {complianceOrganosResolucion{ID_PERSONA}}
            `,
            fetchPolicy:'network-only'
        }).then(async result => { 
            let compliances=[]
            for(let compliance of result.data.complianceOrganosResolucion){
                if(await this.esEmpleado(compliance.ID_PERSONA)===false){
                
                    compliances.push(compliance)
                }
            }   
            return compliances
        
        })
        //buscamos el nombre
        for(let compliance of complianceOrganos){
          //obtencion del nombre de la persona fisica.
                //RELLENO LA TABLA DE PERSONAS EXTERNAS
                let nombre=''

            this.state.personasFisicas.find(persona => {
                
                if (persona.ID_PERSONA === compliance.ID_PERSONA) {
                    nombre = `${persona.APELLIDO1} ${persona.APELLIDO2 ? persona.APELLIDO2 : ''} ${persona.NOMBRE}`
                

                    compliance.id = persona.ID_PERSONA
                    compliance.nombre = nombre
                }

            })
        }
        this.setState({asesoresExternos:complianceOrganos})
    }

    
    cargarEmpleados=async ()=>{

        let complianceOrganos=await this.props.client
        .query({
            query: gql`
            {complianceOrganosResolucion{ID_PERSONA}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            let compliances=[]
            for(let compliance of result.data.complianceOrganosResolucion){
                if(await this.esEmpleado(compliance.ID_PERSONA)===true){
                
                    compliances.push(compliance)
                }
            }   

            return compliances
        
        })
        
        //buscamos el nombre
        for(let compliance of complianceOrganos){
            //obtencion del nombre de la persona fisica.
            //RELLENO LA TABLA DE PERSONAS EXTERNAS
            let nombre=''

            this.state.personasFisicas.find(persona=>{
                if (persona.ID_PERSONA === compliance.ID_PERSONA) {
                    nombre = `${persona.APELLIDO1} ${persona.APELLIDO2 ? persona.APELLIDO2 : ''} ${persona.NOMBRE}`
                
                    compliance.id = persona.ID_PERSONA
                    compliance.nombre = nombre
                }
            })
        }
        this.setState({empleados:complianceOrganos})

    }
    cargarPersonas=async()=>{
        let personas=[];
        this.state.personasFisicas.forEach(async persona=>{
            if(!await this.esEmpleado(persona.ID_PERSONA)){
              personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`})
            }
        })
        this.setState({clientesSelect:personas})
    }   
    cargarTablaEmpleados=async()=>{
        //carga desplegables empleados
        let empleados=[];
        this.state.personasFisicas.forEach(persona=>{
            this.state.esEmpleado.find(empleado=>{
                if(empleado.ID_EMPLEADO===persona.ID_PERSONA){
                    empleados.push({"value":empleado.ID_EMPLEADO,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`})
                }
            })
            this.setState({empleadosSelect:empleados})

        })
    }  
    generarImprimirM=async()=>{
        this.setState({
            cargandoImprimirOM:true,
            modalImprimirOM:true
        },async () => {           
            this.setState({cargandoImprimirOM:false}, ()=>{
                ReactDOM.render(this.ImprimirMiembros(), document.getElementById('generarImprimirM-pdf'))
            })
        })
    }  
    generarImprimirA=async()=>{
        this.setState({
            cargandoImprimirOA:true,
            modalImprimirOA:true
        },async () => {
           this.setState({cargandoImprimirOA:false},()=>{
                ReactDOM.render(this.ImprimirAsesores(), document.getElementById('generarImprimirA-pdf'))
            })
        })
}


    componentDidMount(){ 
        this.setState({
            cargando:true
        }, async()=>{
            await this.cargarDatos()
            await this.cargarAsesores()
            await this.cargarTablaEmpleados()
            await this.cargarEmpleados()
            await this.cargarPersonas()
            await this.obtenerLogoEmpresa()
            this.setState({cargando:false})
        })
    }

    ImprimirMiembros=() =>(
    
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4"  style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organoResolucion.resolutionOrgans", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{i18next.t("organoResolucion.organizationMembersTitle", { ns: "page2" }).toUpperCase}:</Text>
                </View>
                    {this.state.empleados?this.state.empleados.map((empleado, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column',  fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("organoResolucion.members", { ns: "page2" }).toUpperCase()}</Text>
                                 </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{empleado.nombre?empleado.nombre:''}</Text>
                                </View> 
                            
                        </View>
                        )
                    }):<></>}
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    ImprimirAsesores=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4"  style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organoResolucion.resolutionOrgans", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{i18next.t("organoResolucion.externalAdvisors", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.asesoresExternos?this.state.asesoresExternos.map((asesor, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                        <Text style={{ textAlign: 'center', flexDirection: 'column', borderRightWidth: 1, fontSize: 8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width: '100%' }}>{i18next.t("organoResolucion.advisors", { ns: "page2" }).toUpperCase()}</Text>
                                 </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'100%'}}>{asesor.nombre?asesor.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
)

    render() {

        document.title = `${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("organoResolucion.resolutionOrgans", {ns:"page2"})}`
        
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirOA:false,
                modalImprimirOM:false
            })
        }
        const abrirModalNuevo1 = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({
                modalAsesoresBuscarAbierto: true,
                nuevoAsesorActivado:false,
                buscarAsesorActivado:true,
                eliminarAsesorActivado:false,
                personaAsesorSeleccionada:null
            })
        }

        const abrirModalEmpleados = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({
                modalEmpleadosAbierto:true,
                buscarMiembroActivado:true,
                eliminarMiembroActivado:false,
                empleadoSelectSeleccionado:null
            })
        }

        const abrirModalBorrarEmpleados = () => {


            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert(i18next.t("organoResolucion.selectEmployeeMessage", { ns: "page2" }))
                return
            }
            this.setState({buscarMiembroActivado:false})
            this.setState({eliminarMiembroActivado:true})
            this.setState({modalBorrarEmpleadoAbierto:true})
        }

        const abrirModalBorrarAsesor = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.asesorSeleccionado){
                alert(i18next.t("organoResolucion.selectAdvisorMessage", { ns: "page2" }))
                return
            }
            this.setState({nuevoAsesorActivado:false})
            this.setState({buscarAsesorActivado:false})
            this.setState({eliminarAsesorActivado:true})
            this.setState({modalBorrarAsesorAbierto:true})
        }

        const handleOnSelectMiembro =(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    empleadoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; 
        }

        const columnsMiembro = [
            {
                dataField: 'nombre',
                text: i18next.t('organoResolucion.member', { ns: "page2" })
            }
        ];

        const selectRowMiembro = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectMiembro,
            style: { backgroundColor: '#c8e6c9' }
        };

        const handleOnSelectAsesor =(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    asesorSeleccionado: row
                },async()=>{
                })
                
            }
            return true; 
        }

        const columnsAsesores = [
            {
                dataField: 'nombre',
                text: i18next.t('organoResolucion.advisor', { ns: "page2" })
            }
        ];

        const selectRowAsesores = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectAsesor,
            style: { backgroundColor: '#c8e6c9' }
        };
       

        const handleSeleccionarPersona=(value)=>{
            this.setState({personaAsesorSeleccionada:value})
        }

        const handleSeleccionarEmpleadoSelect=(value)=>{
            this.setState({empleadoSelectSeleccionado:value})
        }

        const cerrarModal = () => {
            this.setState({
                modalAsesoresBuscarAbierto:false,
                modalAsesoresAbierto: false,
                modalEmpleadosAbierto:false,
                modalBorrarEmpleadoAbierto:false,
                modalBorrarAsesorAbierto:false,
                modalPersonaAbierto:false
            })
        }

        const eliminarAsesor = () => {
            let asesoresExternos=this.state.asesoresExternos.filter(asesor => asesor.id !== this.state.asesorSeleccionado.id)
            this.setState({asesoresExternos:asesoresExternos})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
            
            cerrarModal()
        };

        const eliminarEmpleado = () => {
            let empleados=this.state.empleados.filter(empleado => empleado.id !== this.state.empleadoSeleccionado.id)
            this.setState({empleados:empleados})
            this.setState({pendienteBorrarEmpleado:true})     
            this.setState({controlesEmpleadosDesactivados:true})
            
            cerrarModal()
        };

        const añadirAsesorSeleccionado=()=>{

            if(!this.state.personaAsesorSeleccionada)return
            let asesores=this.state.asesoresExternos

            //comporbar que la persona seleccionada no este ya en la lista.
            let existe=asesores.find(asesor=>{return asesor.id===this.state.personaAsesorSeleccionada.value})
            if(existe){
                alert(i18next.t("organoResolucion.personAlreadyInAdvisorList", { ns: "page2" }))
                return
            }

            asesores.push({
                "nombre": this.state.personaAsesorSeleccionada.label,
                "id": this.state.personaAsesorSeleccionada.value
            })
            this.setState({asesores:asesores})
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        }

        const añadirEmpleado=()=>{
            if(!this.state.empleadoSelectSeleccionado){
                return
            }
            let empleados=this.state.empleados

            let existe=empleados.find(empleado=>{return empleado.id===this.state.empleadoSelectSeleccionado.value})
            if(existe){
                alert(i18next.t("organoResolucion.personAlreadyInMemberList", { ns: "page2" }))
                return
            }

            empleados.push({
                    "nombre": this.state.empleadoSelectSeleccionado.label,
                    "id": this.state.empleadoSelectSeleccionado.value
                })
            this.setState({empleados:empleados})
            this.setState({pendienteGuardarEmpleados:true})
            this.setState({controlesEmpleadosDesactivados:true})
            cerrarModal()

        }

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(this.state.pendienteGuardar){

                this.props.client.mutate({
                    mutation:gql `mutation{crearOrganoResolucion(VALORES:{ID_PERSONA:${this.state.personaAsesorSeleccionada.value}}){ID_PERSONA}}`
                }).then(result=>{
                    alert(i18next.t("insertcorrecto", { ns: "global" }))
                    this.cargarAsesores()
                    this.setState({pendienteGuardar:false})
                    this.setState({controlesDesactivados:false})
                }).catch(err=>{
                    alert(i18next.t("error", { ns: "global" }))
                })

            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarOrganoResolucion(ID_PERSONA:${this.state.asesorSeleccionado.id}){ID_PERSONA}}`
                }).then(result=>{
                    
                    alert(i18next.t("deletecorrecto", { ns: "global" }))
                    this.setState({controlesDesactivados:false})
                    this.setState({pendienteBorrar:false})
                    this.cargarAsesores()
                    
                }).catch(err=>{
                    alert(i18next.t("error", { ns: "global" }))
                })
            }
        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.cargarAsesores()

            this.setState({
                pendienteGuardar:false,
                pendienteBorrar:false,
                controlesDesactivados:false,
                personaAsesorSeleccionada:null
            })

        }


        const guardarCambiosEmpleados=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(this.state.pendienteGuardarEmpleados){

                this.props.client.mutate({
                    mutation:gql`mutation{crearOrganoResolucion(VALORES:{ID_PERSONA:${this.state.empleadoSelectSeleccionado.value}}){ID_PERSONA}}`
                }).then(result=>{
                    alert(i18next.t("insertcorrecto", { ns: "global" }))
                    this.setState({pendienteGuardarEmpleados:false})
                    this.setState({controlesEmpleadosDesactivados:false})
                    this.cargarEmpleados()
                }).catch(err=>{
                    alert(i18next.t("error", { ns: "global" }))
                })

            }else if(this.state.pendienteBorrarEmpleado){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarOrganoResolucion(ID_PERSONA:${this.state.empleadoSeleccionado.id}){ID_PERSONA}}`
                }).then(result=>{
                    alert(i18next.t("deletecorrecto", { ns: "global" }))
                    this.setState({controlesEmpleadosDesactivados:false})
                    this.setState({controlesEmpleadosDesactivados:false})
                    this.cargarEmpleados()
                }).catch(err=>{
                    alert(i18next.t("error", { ns: "global" }))
                })
            }

        }

        const cancelarCambiosEmpleados=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.cargarEmpleados()

            this.setState({
                pendienteGuardarEmpleados:false,
                pendienteBorrarEmpleado:false,
                controlesEmpleadosDesactivados:false,
                empleadoSelectSeleccionado:null
            })

        }

        const crearPersona=async()=>{
            await this.datosPersona.crearPersona()
            if(this.datosPersona.state.idPersonaCreada){
                this.props.client.mutate({
                    mutation:gql `mutation{crearOrganoResolucion(VALORES:{ID_PERSONA:${this.datosPersona.state.idPersonaCreada}}){ID_PERSONA}}`
                }).then(result=>{
                    alert(i18next.t("insertcorrecto", { ns: "global" }))
                    this.cargarAsesores()
                }).catch(err=>{
                    alert(i18next.t("error", { ns: "global" }))
                })            
                //cerrar el modal
                cerrarModal()
            }

        }

        
        const abrirModalNuevaPersona=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                nuevoAsesorActivado:true,
                buscarAsesorActivado:false,
                eliminarAsesorActivado:false
            })
        }

        return (
            <>
               
               {this.state.cargando===true?
                     <Cargando/>
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={ i18next.t("organoResolucion.printOrganizationMembers", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirOM}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirOM?
                            <div style={{ width: '100%', height: '500px'}} id='generarImprimirM-pdf'>
                            </div>
                            :
                                <Spinner />
                    }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("organoResolucion.printExternalAdvisors", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirOA}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirOA?
                            <div style={{ width: '100%', height: '500px'}} id="generarImprimirA-pdf">
                            </div>
                            :
                                <Spinner />
                    }

                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organoResolucion.selectAdvisorPrompt", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAsesoresBuscarAbierto}
                >
                   <FormGroup
                        label={i18next.t("organoResolucion.employee", { ns: "page2" })}
                        labelFor="empleado"
                        intent="danger"
                        helperText={!this.state.personaAsesorSeleccionada?i18next.t("organoResolucion.selectPerson", { ns: "page2" }):""}
                    >
                        <div className="pixelAlrededor" style={!this.state.personaAsesorSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.clientesSelect}
                                className="pixelAlrededor"
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={handleSeleccionarPersona}
                            />
                        </div>
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("organoResolucion.addAdvisor", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.DANGER}
                                onClick={añadirAsesorSeleccionado}
                            >
                               {i18next.t("organoResolucion.addAdvisor", { ns: "page2" })}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organoResolucion.personProperties", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas i18next={i18next} datosPersona={this.state.adminstradorSeleccionado?this.state.adminstradorSeleccionado.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("organoResolucion.addPerson", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={crearPersona} 
                            >
                            {i18next.t("organoResolucion.addPerson", { ns: "page2" })}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
 
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organoResolucion.selectEmployeePrompt", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEmpleadosAbierto}
                >
                     <FormGroup
                            label={i18next.t("organoResolucion.employee", { ns: "page2" })}
                            labelFor="empleado"
                            intent="danger"
                            helperText={!this.state.empleadoSelectSeleccionado?i18next.t("organoResolucion.selectEmployeeMessage", { ns: "page2" }):""}
                        >
                            <div className="pixelAlrededor" style={!this.state.empleadoSelectSeleccionado?{border:"1px solid red"}:{}}>
                                <Select
                                    options={this.state.empleadosSelect}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={handleSeleccionarEmpleadoSelect}
                                />
                             </div>
                        </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("organoResolucion.addEmployee", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.DANGER}
                                onClick={añadirEmpleado}
                            >
                               {i18next.t("organoResolucion.addEmployee", { ns: "page2" })}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organoResolucion.deleteMember", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarEmpleadoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("organoResolucion.prompConfirmDeleteMember", { ns: "page2" })}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("organoResolucion.confirmDeleteMember", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarEmpleado}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organoResolucion.deleteMember", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarEmpleadoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("organoResolucion.prompConfirmDeleteMember", { ns: "page2" })}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("organoResolucion.confirmDeleteMember", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarEmpleado}
                            >
                                {i18next.t("yes", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organoResolucion.deleteAdvisor", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAsesorAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("organoResolucion.confirmDeleteAdvisor", { ns: "page2" })}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("organoResolucion.deleteAdvisor", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAsesor}
                            >
                                {i18next.t("yes", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                
                <div className="tablaMitad pixelAlrededor">
                <Card interactive={true} elevation={Elevation.TWO}>
                        <Navbar>
                            <Navbar.Group>
                                <NavbarHeading>{i18next.t("organoResolucion.organizationMembersTitle", { ns: "page2" })}</NavbarHeading>
                            </Navbar.Group>
                        </Navbar>
                        <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                            {this.state.controlesEmpleadosDesactivados?[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar", { ns: "global" })} active={this.state.buscarMiembroActivado} disabled/>
                                        <Button className="bp3-minimal" key="btnBorra" icon="delete" text={i18next.t("delete", { ns: "global" })}   active={this.state.eliminarMiembroActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" })} active={this.state.imprimirActivado} disabled/>
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar", { ns: "global" })} active={this.state.buscarMiembroActivado} onClick={abrirModalEmpleados} />
                                        <Button className="bp3-minimal" key="btnBorra" icon="delete" text={i18next.t("delete", { ns: "global" })} onClick={abrirModalBorrarEmpleados} active={this.state.eliminarMiembroActivado}/>
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" })} onClick={()=>this.generarImprimirM()}/> 

                                    </React.Fragment>
                            ]}
                            </ButtonGroup>
                        </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField="id"
                            data={this.state.empleados}
                            columns={columnsMiembro}
                            selectRow={selectRowMiembro}
                            pagination={ paginationFactory(const_table.options)}
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                        />
                    <div>
                        <br/>
                            <Button 
                                alignText="right"
                                icon="floppy-disk"
                                text={i18next.t("guardar", { ns: "global" })}
                                intent="danger"
                                disabled={!this.state.controlesEmpleadosDesactivados}
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                                onClick={guardarCambiosEmpleados}
                            />
                             <Button 
                                alignText="right"
                                icon="cross"
                                disabled={!this.state.controlesEmpleadosDesactivados}
                                text={i18next.t("cancelar", { ns: "global" })}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                                onClick={cancelarCambiosEmpleados}
                            />
                        <br />
                        <br />
                    </div>
                </Card>
                </div>
                <div className="tablaMitad pixelAlrededor">
                <Card interactive={true} elevation={Elevation.TWO}>
                        <Navbar>
                            <Navbar.Group>
                                <NavbarHeading>{i18next.t("organoResolucion.externalAdvisorsTitle", { ns: "page2" })}</NavbarHeading>
                            </Navbar.Group>
                        </Navbar>
                        <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                               
                                {this.state.controlesDesactivados?[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnNuevo" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar", { ns: "global" })} active={this.state.nuevoActivado}  disabled/>
                                        <Button className="bp3-minimal" key="btnBorrar" icon="delete" text={i18next.t("delete", { ns: "global" })}   active={this.state.borrarActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" })} disabled />
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnNuevo" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoAsesorActivado} onClick={abrirModalNuevaPersona} />
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar", { ns: "global" })} active={this.state.buscarAsesorActivado} onClick={abrirModalNuevo1} />
                                        <Button className="bp3-minimal" key="btnBorrar" icon="delete" text={i18next.t("delete", { ns: "global" })}  onClick={abrirModalBorrarAsesor} active={this.state.eliminarAsesorActivado}/>
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" })} onClick={()=>this.generarImprimirA()}/> 

                                    </React.Fragment>
                            ]}
                            </ButtonGroup>
                        </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField="id"
                            data={this.state.asesoresExternos}
                            columns={columnsAsesores}
                            selectRow={selectRowAsesores}
                            pagination={ paginationFactory(const_table.options)}
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                        />
                    <div>
                        <br/>
                            <Button 
                                alignText="right"
                                icon="floppy-disk"
                                onClick={guardarCambios}
                                disabled={!this.state.controlesDesactivados}
                                text={i18next.t("guardar", { ns: "global" })}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="cross"
                                onClick={cancelarCambios}
                                disabled={!this.state.controlesDesactivados}
                                text={i18next.t("cancelar", { ns: "global" })}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                            />
                        <br />
                        <br />
                    </div>
                    </Card>
                </div>
            </>
            }
           </> 
        )
    }
}

export default withTranslation()(withApollo(OrganosResolucion))