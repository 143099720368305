import React from "react"
import { FormGroup, Card, Alignment, TextArea, Switch, Button, Classes, AnchorButton, Intent, Dialog, Tree } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import i18next from 'i18next';
import BootstrapTable from "react-bootstrap-table-next";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


export default class Resolucion extends React.Component {

    state={
        resuelta: false,
        personaResponsable: [
        ],
        personaResponsableSeleccionada:null,
        fechaResolucion:new Date(),
        riesgosAfectados:null,
        decisionesTomadas:null,
        modalRiesgos: false,
        arbolRiesgos: [
        
        ],
        riesgosSeleccionados: [],
        riesgosSel:null,
        riesgoSeleccionadoTabla:null
    }

    cargarPersonas=async()=>{

        let personas=[]

        await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`
        }).then(async(result)=>{
            //console.log(result)
            result.data.personasFisicas.forEach(async(persona)=>{
             
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 

            })
            
        })

        await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}`
        }).then(async(result)=>{
            //console.log(result)
            result.data.personasJuridicas.forEach(async(persona)=>{
             
                personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL}) 

            })
        })

        this.setState({personaResponsable:personas})

        if(this.props.datosResolucion && Object.keys(this.props.datosResolucion).length>0){

            //buscamos en el arbol de los riesgos los riesgos seleccionados.
            let riesgos=this.props.datosResolucion.RIESGOS_AFECTADOS?this.props.datosResolucion.RIESGOS_AFECTADOS.split(","):""
            for(let r of riesgos){
                this.buscarRiesgo(this.state.arbolRiesgos,parseInt(r))
            }

            this.setState({resuelta:this.props.datosResolucion})
            this.setState({decisionesTomadas:this.props.datosResolucion?this.props.datosResolucion.DECISIONES_TOMADAS:null})
            this.setState({fechaResolucion:this.props.datosResolucion?new Date(this.props.datosResolucion.FECHA_RESOLUCION):new Date()})

            //buscamos el medio .
            let perosonaSel=personas.find(persona=>{
                return persona.value===this.props.datosResolucion.ID_RESPONSABLE
            })

            this.setState({personaResponsableSeleccionada:perosonaSel})

        }else{
            this.setState({noexiste:true})
        }

    }

    buscarRiesgo=async(arbol,idRiesgo)=>{
         
        for(let hijo of arbol){
            if(parseInt(hijo.id.split("_").pop())===idRiesgo && hijo.id.includes("riesgo_")){
                let riesgos=this.state.riesgosSeleccionados
                riesgos.push(hijo)
                this.setState({riesgosSeleccionados:riesgos})
                return 
            }else{
                if(hijo.childNodes)this.buscarRiesgo(hijo.childNodes,idRiesgo)
            }
        }

    }

    tieneHijos=(riesgo,arrayRiesgos)=>{
        var arrayHijos=[];
        arrayRiesgos.find((element) =>{
            if(element.ID_RIESGO_PADRE===riesgo.ID_RIESGO){
                this.tieneHijos(element,arrayRiesgos)
                arrayHijos.push({"depth":(element.childNodes?element.childNodes.length:0),id:"riesgo_"+element.ID_RIESGO,"label":element.CODIGO_RIESGO+" - "+element.TITULO,childNodes:(element.childNodes?element.childNodes:null),"descripcion":element.DESCRIPCION,"icon":(element.childNodes?"folder-close":"share")})
            }
            // eslint-disable-next-line array-callback-return
            return 
        });
        if(arrayHijos.length>0)riesgo.childNodes=arrayHijos;
        return riesgo;
    }

    cargarBloques=async ()=>{
        
        //obtencion de los bloques de riesgo
        await client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let bloques=[]
            for(let bloque of result.data.bloquesRiesgo){        
                
                //obtencion de los riesgos de cada bloque.
                let hijos=[]
                await client.query({
                    query:gql`{complianceRiesgos(FILTROS:{ID_BLOQUE:${bloque.ID_BLOQUE}}){ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE}}`
                    ,fetchPolicy:'network-only'
                }).then((result)=>{
                    let results=JSON.parse(JSON.stringify(result.data.complianceRiesgos))
                    results.forEach((hijo)=>{
                        this.tieneHijos(hijo,JSON.parse(JSON.stringify(result.data.complianceRiesgos)))
                        if(hijo.ID_RIESGO_PADRE===null){
                            hijos.push({"depth":(hijo.childNodes?hijo.childNodes.length:0),"id":"riesgo_"+hijo.ID_RIESGO,"label":hijo.CODIGO_RIESGO+" - "+hijo.TITULO,"descripcion":hijo.DESCRIPCION,"icon":(hijo.childNodes?"folder-close":"share"),"childNodes":(hijo.childNodes?hijo.childNodes:null)})
                        }
                    })
                    bloques.push({"depth":hijos.length,"id":"bloque_"+bloque.ID_BLOQUE,"label":"Bloque "+bloque.LETRA+" - "+bloque.TITULO,"icon":"folder-close",childNodes:hijos}) 
                })

            }
            this.setState({arbolRiesgos:bloques})

        })
    }
    

    componentDidMount=async(props)=>{

        await this.cargarBloques()
        await this.cargarPersonas()        

    }


    
    handleNodeCollapse = (nodeData) => {
        nodeData.isExpanded = false;
        this.setState(this.state);
        };
        
        handleNodeExpand = (nodeData) => {
            nodeData.isExpanded = true;
            this.setState(this.state);
        };
        
        forEachNode(nodes, callback: (node: ITreeNode) => void) {
            if (nodes == null) {
                return;
            }
    
            for (const node of nodes) {
                callback(node);
                this.forEachNode(node.childNodes, callback);
            }
    }
    
    handleNodeClick = (nodeData, _nodePath, e) => {

        
        if(this.state.riesgosSel){
            let riesgoSel=this.state.riesgosSel
            riesgoSel.isSelected=false
        }


        //si es un riesgo...
        if(nodeData.id.includes("riesgo_")){    
            this.setState({riesgosSel:nodeData})
            nodeData.isSelected=true
        } else {
            this.setState({riesgosSel:null})
        }
    }

    render(){

        const handleCambiarComienzaResolucion=(value)=>{
            this.setState({resuelta:!this.state.resuelta})
        }

        const handleCambiarFechaResolucion=(value)=>{
            this.setState({fechaResolucion:value})
        }

        const handleCambiarPersonaResponsable=(value)=>{
            this.setState({personaResponsableSeleccionada:value})
        }

        const handleDecisionesTomadas=(ev)=>{
            this.setState({decisionesTomadas:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };


        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
    

        const abrirModalRiesgos = () => {

            //cerramos el arbol
            replegarArbol(this.state.arbolRiesgos)

            if(this.state.riesgosSel){
                let riesgoSel=this.state.riesgosSel
                riesgoSel.isSelected=false
            }
            this.setState({modalRiesgos: true})
        }

        const cerrarModal = () => {
            this.setState({modalRiesgos: false})
        }

        const seleccionarRiesgo = () => {
         
            if (!this.state.riesgosSel) {
                alert(`${i18next.t("global.selectempty", { ns: "page" })} ${i18next.t("siniestros.risk", { ns: "page2" })}`)
                return
            }

            //agregamos el riesgo a la tabla.
            let riesgos=this.state.riesgosSeleccionados
            //comprobamos que no este ya seleccionado.
            let existe=this.state.riesgosSeleccionados.find(ries=>{return ries.id.split("_").pop()===this.state.riesgosSel.id.split("_").pop()})
            if(existe){
                alert(i18next.t("siniestros.riskAlreadySelected", { ns: "page2" }))
                return
            }
            riesgos.push(this.state.riesgosSel)
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla: this.state.riesgosSel
            })

            cerrarModal()
      
        }

        const eliminarRiesgoTabla = () => {
         
            if(!this.state.riesgoSeleccionadoTabla){
                alert(`${i18next.t("global.selectempty", { ns: "page" })} ${i18next.t("siniestros.risk", { ns: "page2" })}`)
                return
            }

            let riesgos=this.state.riesgosSeleccionados
            riesgos=riesgos.filter(riesgo=>{return riesgo.id!==this.state.riesgoSeleccionadoTabla.id})
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:null
            },()=>{if(this.state.riesgosSeleccionados.length>0)this.tablaRiegosRelacionados.clearSelection()})
        }
        
        const handleOnSelect =(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    riesgoSeleccionadoTabla: row
                },async()=>{
                })
                
            }
            return true; 
        }

        const columns = [
            {
                dataField: 'label',
                text: i18next.t("name", { ns: "global" })
            }
        ];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        
    
        return (
            <>
                 <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="LexControl"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalRiesgos}
                >
                <div className={Classes.DIALOG_BODY}>
                    <Tree
                        contents={this.state.arbolRiesgos}
                        onNodeDoubleClick={this.handleNodeClick}
                        onNodeCollapse={this.handleNodeCollapse}
                        onNodeExpand={this.handleNodeExpand}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={seleccionarRiesgo}>
                        {i18next.t("siniestros.chooseRisk", { ns: "page2" })}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        {i18next.t("close", { ns: "global" })}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>

                <div className="pixelAlrededor">
                    <Card>
                            {(this.state.resuelta && (this.props.datosResolucion && Object.keys(this.props.datosResolucion).length >0 ))?
                                <Switch checked={true} disabled label={i18next.t("siniestros.markClaimAsResolved", { ns: "page2" })} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaResolucion} />
                            :
                                <Switch id="boleano-resolucion-siniestro" checked={this.state.resuelta} label={i18next.t("siniestros.markClaimAsResolved", { ns: "page2" })} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaResolucion} />
                            }     
                            <div id="datos-resolucion-siniestro">                  
                                <FormGroup
                                    label={i18next.t("siniestros.resolutionDate", { ns: "page2" })}
                                    labelFor="fecha-resolucion"
                                    disabled={!this.state.resuelta}
                                    inline={true}
                                >
                                    <DateInput minDate={new Date(1900, 1 ,1)}
                                        {...jsDateFormatter} 
                                        onChange={handleCambiarFechaResolucion}
                                        value={this.state.fechaResolucion}
                                        align={Alignment.RIGHT}
                                        disabled={!this.state.resuelta}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("siniestros.responsiblePerson", { ns: "page2" })}
                                    labelFor="persona-responsable"
                                    disabled={!this.state.resuelta}
                                    intent="danger"
                                    helperText={this.state.personaResponsableSeleccionada || !this.state.resuelta?"":i18next.t("siniestros.selectResponsiblePerson", { ns: "page2" })}
                                >
                                <div style={this.state.personaResponsableSeleccionada || !this.state.resuelta?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.personaResponsable}
                                        className="pixelAlrededor"
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        isDisabled={!this.state.resuelta}
                                        onChange={handleCambiarPersonaResponsable}
                                        value={this.state.personaResponsableSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <div className="tablaTreinta">
                                <FormGroup
                                    label={`${i18next.t("siniestros.associatedRisks", { ns: "page2" })}:`}
                                    labelFor="riesgos-asociados"
                                    disabled={!this.state.resuelta}
                                >
                                    {this.state.riesgosSeleccionados.length > 0 ?
                                         <BootstrapTable
                                            keyField="id"
                                            data={this.state.riesgosSeleccionados}
                                            columns={columns}
                                            selectRow={selectRow}
                                            headerClasses="header-class"
                                            rowClasses="row-class"
                                            hover
                                            condensed
             
                                        />
                                    :
                                        <p>{i18next.t("siniestros.requireRisk", { ns: "page2" })}</p>
                                    }
                                    <Button label={i18next.t("siniestros.addRisk", { ns: "page2" })} disabled={!this.state.resuelta} icon="add" onClick={abrirModalRiesgos} />
                                    <Button label={i18next.t("siniestros.removeRisk", { ns: "page2" })} disabled={!this.state.resuelta} icon="delete" onClick={eliminarRiesgoTabla} />
                                </FormGroup>
                            </div>
                            <div className="tablaSetenta">
                                <FormGroup
                                    label={i18next.t("siniestros.decisionsMade", { ns: "page2" })}
                                    labelFor="motivo-denuncia"
                                    disabled={!this.state.resuelta}
                                    intent="danger"
                                    helperText={this.state.decisionesTomadas || !this.state.resuelta?"":i18next.t("siniestros.fillDecisionsMade", { ns: "page2" })}
                                >
                                    <TextArea fill={true} intent={this.state.decisionesTomadas || !this.state.resuelta?"primary":"danger"} value={this.state.decisionesTomadas} onChange={handleDecisionesTomadas} disabled={!this.state.resuelta} />
                                </FormGroup>
                            </div>
                        </div>  
                    </Card>
                </div>
            </>
        )
    }
}