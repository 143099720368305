import React,{ Component } from 'react';
import {
    Card,
    Elevation,
    Navbar,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    FormGroup,
    FileInput,
    InputGroup,
    TextArea,
    Intent,
    Tooltip,
    Classes,
    AnchorButton,
    NavbarDivider
} from '@blueprintjs/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from "../BootstrapTable";
import Select from "react-select";
import axios from 'axios';
import { Cargando } from '../Cargando';

class Adjuntos extends Component {
    state={
        adjuntoSeleccionado:null,
        nombre:'',
        archivo:null,
        tipoSeleccionado:null,
        descripcion:'',
        modalAbierto:false,
        nuevoActivado:true,
        cargando:false
    }

    render() {

        const cerrarModal=()=>{
            this.setState({
                modalAbierto:false,
                nuevoActivado:true,
                cargando:false
            });
        }

        const abrirModalNuevo=()=>{
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:'',
                archivo:null,
                tipoSeleccionado:null,
                descripcion:'',
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                cargando:true
            })
        }

        const abrirModalEditar=()=>{
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let tipoSel=this.props.tipoadjuntos.find(tipo=>{
               return tipo.value===this.state.adjuntoSeleccionado.idTipo
            })
            
            this.setState({
                nombre:this.state.adjuntoSeleccionado.nombre,
                archivo:null,
                tipoSeleccionado:tipoSel,
                descripcion:this.state.adjuntoSeleccionado.descripcion,
                modalAbierto: true,
                nuevoActivado:false,
                cargando:true
            })
        }

        const guardarCambios=()=>{
            const api = axios.create({
                withCredentials: true
            });

            let formData = new FormData();
            formData.append("files",this.state.archivo);
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:${this.state.tipoSeleccionado.value},NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:${this.props.entidad},ID_ASOCIADO:${this.props.id_asociado},DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                await this.props.cargarAdjuntos();
                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}));
                cerrarModal();
                return true;
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
                cerrarModal();
                return false;
            })
        }

        const actualizarCambios=()=>{
            const api = axios.create({
                withCredentials: true
            });

            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre!==''?this.state.nombre:this.state.adjuntoSeleccionado.nombre}",ID_TIPO_ADJUNTO:${this.state.tipoSeleccionado?this.state.tipoSeleccionado.value:this.state.adjuntoSeleccionado.idTipo},DESCRIPCION:"${this.state.descripcion!==''?this.state.descripcion:this.state.adjuntoSeleccionado.descripcion}"}){ID_ADJUNTO}}`).then(async (response)=> {
                // handle success
                await this.props.cargarAdjuntos();
                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}));
                cerrarModal();
                return true;
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
                return false;
            })
        }

        const borrarCambios=()=>{
            const api = axios.create({
                withCredentials: true
            });
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then(async (response)=>{
                // handle success  
                await this.props.cargarAdjuntos();
                alert(this.props.i18next.t("deletecorrecto",{ns:"global"}));
                cerrarModal();
                return true;
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
                return false;
            })
        }
        
        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    adjuntoSeleccionado: row
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const columns = [{
            dataField: 'nombre',
            text: this.props.i18next.t("name",{ns:"global"})
            }, {
            dataField: 'tipo',
            text: this.props.i18next.t("type",{ns:"global"})
            }, {
            dataField: 'mime',
            text: this.props.i18next.t("typefile",{ns:"global"})
        }];

        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({
                archivo:ev.target.files[0],
                tipoArchivo:ev.target.files[0].type,
                nombre:ev.target.files[0].name.replace("."+extension,"")
            })
          
        }

        const seleccionTipo  = (value) => { 
            this.setState({tipoSeleccionado: value})
        }

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };
        
        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const agregarAdjunto =async () => {

            if (!this.state.archivo) {
                alert(this.props.i18next.t("validfyle1",{ns:"global"}));
                return
            }
            if (!this.state.tipoSeleccionado) {
                alert(this.props.i18next.t("validfyle2",{ns:"global"}));
                return
            }
            if (!this.state.nombre) {
                alert(this.props.i18next.t("validfyle3",{ns:"global"}));
                return
            }
            if (!this.state.descripcion) {
                alert(this.props.i18next.t("validfyle4",{ns:"global"}));
                return
            }

            this.setState({cargando:true},async ()=>{
                await guardarCambios();
            })
            
            
        }

        const ActualizarAdjunto=async () => {
            if (!this.state.tipoSeleccionado) {
                alert(this.props.i18next.t("validfyle2",{ns:"global"}));
                return
            }
            if (!this.state.nombre) {
                alert(this.props.i18next.t("validfyle3",{ns:"global"}));
                return
            }
            if (!this.state.descripcion) {
                alert(this.props.i18next.t("validfyle4",{ns:"global"}));
                return
            }

            this.setState({cargando:true},async ()=>{
                await actualizarCambios();
            })
        }

        const eliminarAdjunto =async () => {
            if(!this.state.adjuntoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            if (!window.confirm(this.props.i18next.t("contratos.questiondel",{ns:"page"}))) {
                return
            }

            this.setState({cargando:true},async ()=>{
                await borrarCambios();
            })
            
            
        };

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        return (
            <>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    {this.state.cargando?
                        <Cargando/>
                    :
                    <div id="dialogo-actas">
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            {this.state.nuevoActivado?
                                <FormGroup
                                    label={this.props.i18next.t("adjunto",{ns:"global"})+":"}
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.archivo?"":this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("adjunto",{ns:"global"})}
                                >
                                    <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                                </FormGroup>
                                :<></>
                            }
                            <FormGroup
                                label={this.props.i18next.t("typefile",{ns:"global"})+":"}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.tipoSeleccionado?"":this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("typefile",{ns:"global"})}
                            >
                                <div style={this.state.tipoSeleccionado?{}:{border:"1px solid red"}}>
                                    <Select 
                                        options={this.props.tipoadjuntos}
                                        onChange={seleccionTipo}
                                        placeholder="Seleccion el tipo de archivo"
                                        value={this.state.tipoSeleccionado}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                    label={this.props.i18next.t("name",{ns:"global"})+":"}
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.nombre!==''?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("name",{ns:"global"})}
                            >
                                <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  value={this.state.nombre} placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent={this.state.nombre!==''?"primary":"danger"} />
                            </FormGroup>
                            <FormGroup
                                    label={this.props.i18next.t("description",{ns:"global"})+":"}
                                    labelFor="descripcion"
                                    intent="danger"
                                    helperText={this.state.descripcion?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("description",{ns:"global"})}
                            >
                                <TextArea
                                    large={true}
                                    fill={true}
                                    intent={this.state.descripcion!==''?Intent.PRIMARY:Intent.DANGER}
                                    onChange={handleCambiarDescripcion}
                                    value={this.state.descripcion}
                                />                    
                            </FormGroup>
                        </Card>
                    </div>
                    }
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?agregarAdjunto:ActualizarAdjunto}
                                >
                                    {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    
                </Dialog>
                <Card interactive={true} elevation={Elevation.TWO} >
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})}   onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})}  onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})}  onClick={eliminarAdjunto}/>
                                <NavbarDivider />
                                <Button className="bp3-minimal" icon="document-open" text={this.props.i18next.t("openfile",{ns:"global"})} disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    {this.state.cargando?
                        <Cargando/>
                    :
                        <BootstrapTable
                            keyField='id'
                            data={ this.props.adjuntos }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                        />
                    }
                </Card>
            </>
        );
    }
}

export default Adjuntos;