import {Spinner, Dialog, Alignment, Button, ButtonGroup, Callout, Card, Classes, Elevation, Navbar, NavbarGroup, Position, Tooltip } from '@blueprintjs/core'
import { Cell, Column, RegionCardinality, Table } from '@blueprintjs/table'
import React, { Component } from 'react'
import { CSVLink } from 'react-csv';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from './Cargando';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image
} from '@react-pdf/renderer';
import moment from 'moment';
import iconoFooter from '../../Assets/lc.png';
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import ReactDOM from 'react-dom'



import styles from './stylesPrint';

 class ValoracionPuestos extends Component {
    state={
        datosCsv:[],
        resized:false,
        regionSeleccionada:null,
        columnWidths:[100,100,80,50,30,50,100,100,100],
        totalRegistros:0,
        indiceRegistros:0,
        cargando:false,
        puestos:[],
        puestosGral:[],
        tareas:[],
        columnWidths2:[100,100,100,100,100],
        columnWidths3:[100,100],
        resized2:false,
        resized3:false,
        puestoSeleccionado:null,
        cargandoPuest:true,
        cargandoCsv:false,
        modalImprimir:false,
        cargandoImprimir:false,
        logotipo:null,
        datosEmpresa:[],
        registros:[],
        modalImprimirFact:false,
        modalImprimirProp:false,
        cargandoImprimirFact:false,
        cargandoImprimirProp:false
      
    }
    registrosMostrar=10

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("valoracion-puestos")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaValoracion.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaValoracion.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaValoracion.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaValoracion.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
    }

    resizeColumnsFactores() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaFactores.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaFactores.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaFactores.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths2: cumulativeColumnWidths,
                resized2: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaFactores.locator.grid.numCols);
            this.setState({
                columnWidths2: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized2: true
            });
        }
        
    }

    resizeColumnsTareas() {
        
      let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
      let totalMinSizeReq = 0;
      let cumulativeColumnWidths= [];
      for (let index = 0; index < this.tablaTareas.locator.grid.numCols; index++) {
          totalMinSizeReq = totalMinSizeReq + this.tablaTareas.locator.getWidestVisibleCellInColumn(index);
          cumulativeColumnWidths.push(this.tablaTareas.locator.getWidestVisibleCellInColumn(index));
      }
      if (totalMinSizeReq > containerSize) {
          this.setState({
              columnWidths3: cumulativeColumnWidths,
              resized3: true
          });
      } else {
          let diff = ((containerSize - totalMinSizeReq) / this.tablaTareas.locator.grid.numCols);
          this.setState({
              columnWidths3: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
              resized3: true
          });
      }
      
  }
    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    
    cargarPuestos= async () =>{
        await this.props.client
        .query({
            query: gql` 
                    {puestosPaginacion(INDICE_MOSTRAR:${this.state.indiceRegistros},NUMERO_MOSTRAR:${this.registrosMostrar}){total,
                      PUESTOS_TRABAJO{AREA,DEPARTAMENTO,DESCRIPCION,ID_RIESGO_OBJETIVO,RIESGO_OBJETIVO,
                        GRADO_RESPONSABILIDAD,CODIGO_PUESTO,DESC_CORTA,DESC_LARGA,CENTRO_TRABAJO,
                      TAREAS,CONOCIMIENTOS_APTITUDES,RESPONSABILIDAD,ESFUERZO,CONDICIONES_TRABAJO,
                      DESTREZA_EQUIPOS,CONOCIMIENTOS_INFORMATICOS,POLIVALENCIA,EXPERIENCIA,POSTURAS_FORZADAS,
                      MOVIMIENTOS_REPETITIVOS,CONCENTRACION,DIFICULTAD_TECNICA,ORGANIZACION_TRABAJO,
                      RESPONSABILIDAD_MAQUINARIA,JEFATURA,INICIATIVA_POLIVALENCIA,CONTACTO_CLIENTES,TRABAJO_DISTANCIA,
                      POLIVALENCIA_EXIGIDA,RIESGO_PSICOSOCIAL,GENERO_CONOCIMIENTO,GENERO_ESFUERZO,GENERO_RESPONSABILIDAD,
                      GENERO_CONDICIONES}}}
                        `,
                        fetchPolicy:'network-only'
            }).then(async result=>{
                console.log(result);
                this.setState({
                  puestos:result.data.puestosPaginacion.PUESTOS_TRABAJO,
                  totalRegistros:result.data.puestosPaginacion.total
                })
            })
    }

    cargarPuestosGral=async()=>{
        
        let puestos=await this.props.client.query({
            query:gql`{puestosTrabajo{
                ID_PUESTO,
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION},
                DEPARTAMENTO{AREA{DESCRIPCION}}
                DEPARTAMENTO{ID_AREA}DESCRIPCION,
                GRADO_RESPONSABILIDAD,
                ID_RIESGO_OBJETIVO,
                RIESGO_OBJETIVO{DESCRIPCION},
                CODIGO_PUESTO,
                DESC_CORTA,
                DESC_LARGA,
                NUMERO_TRABAJADORES,
                TAREAS,
                CONOCIMIENTOS_APTITUDES,
                RESPONSABILIDAD,
                ESFUERZO,
                CONDICIONES_TRABAJO,
                DESTREZA_EQUIPOS,
                CONOCIMIENTOS_INFORMATICOS,
                POLIVALENCIA,
                EXPERIENCIA,
                POSTURAS_FORZADAS,
                MOVIMIENTOS_REPETITIVOS,
                CONCENTRACION,
                DIFICULTAD_TECNICA,
                ORGANIZACION_TRABAJO,
                RESPONSABILIDAD_MAQUINARIA,
                JEFATURA,
                INICIATIVA_POLIVALENCIA,
                CONTACTO_CLIENTES,
                TRABAJO_DISTANCIA,
                POLIVALENCIA_EXIGIDA,
                RIESGO_PSICOSOCIAL,
                GENERO_CONOCIMIENTO,
                GENERO_ESFUERZO,
                GENERO_RESPONSABILIDAD,
                GENERO_CONDICIONES,
                CENTRO_TRABAJO
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.puestosTrabajo
        })
        this.setState({puestosGral:puestos})
    }

    cargarDatosEmpresa=async()=>{         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })
    

       return datosEmpresa

}
cargarDatos= async () => {
    let datosEmpresa= await this.cargarDatosEmpresa()
    this.setState({datosEmpresa:datosEmpresa})
}
    
    componentDidMount(){
        this.setState({cargando:true}, async () =>{
            await this.cargarPuestos();
            await this.obtenerLogoEmpresa();
            await this.cargarDatos()
            this.setState({
                cargando:false
            })
        })
    }
    cargarRiesgoColor = (data) => {
      
        let color="";
        switch(data){
            case 1:
                color="green"
            break;
            case 2:
                color="yellow"
            break;
            case 3:
                color="orange"
            break;
            case 4:
                color="red"
            break;
            
            default:
            break;
        }
        return color;
      }

    generarImprimirProp= async()=> {
        this.setState({
            cargandoImprimirProp: true,
            modalImprimirProp: true
        }, async( )=>{
            await this.cargarPuestosGral()
            this.setState({
                cargandoImprimirProp: false
            }, () =>{
                ReactDOM.render(this.ImprimirPropiedades(), document.getElementById('generarProp-pdf'))
            })
        })
    }
    ImprimirPropiedades= () =>{
        let color=''
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PUESTOS DE TRABAJO</Text>
                </View>
                {this.state.puestosGral?this.state.puestosGral.map((puesto, index) => {
                    color= this.cargarRiesgoColor(puesto.ID_RIESGO_OBJETIVO)
                    return(                        
                        <View key={index}>                                  
                            {index===0 && (
                               <View style={styles.colHead}>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>ÁREA</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>DPTO</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'13%'}}>PTO TRAB</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>CÓD PTO</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>RESP</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>RIESGO OBJ</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>DESC. BREVE</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'19%'}}>DESC. DETALLADA</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>CTRO TRAB</Text>
                           </View>                           
                            )}
                           
                           <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'13%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.CODIGO_PUESTO?puesto.CODIGO_PUESTO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.GRADO_RESPONSABILIDAD?puesto.GRADO_RESPONSABILIDAD:''}</Text>
                                    <Text style={{backgroundColor:`${color}`,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{puesto.RIESGO_OBJETIVO?puesto.RIESGO_OBJETIVO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.DESC_CORTA?puesto.DESC_CORTA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'19%'}}>{puesto.DESC_LARGA?puesto.DESC_LARGA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.CENTRO_TRABAJO?puesto.CENTRO_TRABAJO:''}</Text>
                            </View>
                                           
                            
                        </View>
                        
                    )
                }):<></>}       
            </View>
            <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
            fixed
            />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
}  
    
    

    generarImprimir= async()=>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async()=>{
            await this.cargarPuestosGral()
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.InformeValoracionPuestos(), document.getElementById('generar-pdf'))
            })

        })
    }

    InformeValoracionPuestos= () => {
        let color=''
        return(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>VALORACIÓN PUESTOS DE TRABAJO</Text>
                </View>
                {this.state.puestosGral?this.state.puestosGral.map((puesto, index)=>{
                    color= this.cargarRiesgoColor(puesto.ID_RIESGO_OBJETIVO)
                    return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>DPTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'13%'}}>PTO TRAB</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>CÓD PTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>RESP</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>RIESGO OBJ</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>DESC. BREVE</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'19%'}}>DESC. DETALLADA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>CTRO TRAB</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'13%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.CODIGO_PUESTO?puesto.CODIGO_PUESTO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.GRADO_RESPONSABILIDAD?puesto.GRADO_RESPONSABILIDAD:''}</Text>
                                    <Text style={{backgroundColor:`${color}`,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{puesto.RIESGO_OBJETIVO?puesto.RIESGO_OBJETIVO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.DESC_CORTA?puesto.DESC_CORTA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'19%'}}>{puesto.DESC_LARGA?puesto.DESC_LARGA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.CENTRO_TRABAJO?puesto.CENTRO_TRABAJO:''}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
              <View style={{margin:10}}>
              <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>FACTORES Y TAREAS DE LOS PUESTOS DE TRABAJO</Text>
                </View>

                <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE CONOCIMIENTOS Y APTITUDES</Text>
                </View>
              {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONOCIMIENTOS Y APTITUDES</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>DESTREZA Y MANEJO DE EQUIPO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONOCIMIENTOS INFORMÁTICOS</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>POLIVALENCIA Y CREATIVIDAD</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>EXPERIENCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_APTITUDES?puesto.CONOCIMIENTOS_APTITUDES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DESTREZA_EQUIPOS?puesto.DESTREZA_EQUIPOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_INFORMATICOS?puesto.CONOCIMIENTOS_INFORMATICOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA?puesto.POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.EXPERIENCIA?puesto.EXPERIENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_CONOCIMIENTO?puesto.GENERO_CONOCIMIENTO:''}</Text></View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE ESFUERZO</Text>
                </View>
               {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>ESFUERZO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>POSTURAS FORZADAS</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>MOVIMIENTOS REPETITIVOS</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONCENTRACIÓN Y ESFUERZO EMOCIONAL</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>DIFICULTAD TÉCNICA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                   
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.ESFUERZO?puesto.ESFUERZO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POSTURAS_FORZADAS?puesto.POSTURAS_FORZADAS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.MOVIMIENTOS_REPETITIVOS?puesto.MOVIMIENTOS_REPETITIVOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONCENTRACION?puesto.CONCENTRACION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DIFICULTAD_TECNICA?puesto.DIFICULTAD_TECNICA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.GENERO_ESFUERZO?puesto.GENERO_ESFUERZO:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE RESPONSABILIDAD</Text>
                </View>
                    {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>RESPONSABILIDAD</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>ORGANIZACIÓN DEL TRABAJO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>RESPONSABILIDAD MAQUINARIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>JEFATURA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>INICIATIVA Y POLIVALENCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'9%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.RESPONSABILIDAD?puesto.RESPONSABILIDAD:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.ORGANIZACION_TRABAJO?puesto.ORGANIZACION_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RESPONSABILIDAD_MAQUINARIA?puesto.RESPONSABILIDAD_MAQUINARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.JEFATURA?puesto.JEFATURA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.INICIATIVA_POLIVALENCIA?puesto.INICIATIVA_POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_RESPONSABILIDAD?puesto.GENERO_RESPONSABILIDAD:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE CONDICIONES DE TRABAJO</Text>
                </View>

                {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONDICIONES DE TRABAJO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONTACTO CON CLIENTE</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>TRABAJO A DISTANCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>POLIVALENCIA EXIGIDA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>RIESGO PSICOSOCIAL</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONDICIONES_TRABAJO?puesto.CONDICIONES_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONTACTO_CLIENTES?puesto.CONTACTO_CLIENTES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.TRABAJO_DISTANCIA?puesto.TRABAJO_DISTANCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA_EXIGIDA?puesto.POLIVALENCIA_EXIGIDA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RIESGO_PSICOSOCIAL?puesto.RIESGO_PSICOSOCIAL:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'8%'}}>{puesto.GENERO_CONDICIONES?puesto.GENERO_CONDICIONES:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>TAREAS</Text>
                </View>
                {// eslint-disable-next-line
                this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  let tareas=puesto.TAREAS?JSON.parse(puesto.TAREAS):[];
                  for(let tarea of tareas){
                  return (
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>DESCRIPCION</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>VALOR</Text>
                                </View>
                            )}
                              <View style={styles.colHead}>                                
                                      
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{tarea.descripcion}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{tarea.valor}</Text>
                              </View>
                        </View>
                  )
              }
            }):<></>}
              </View>
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
 }
 generarImprimirFactores= async () =>{
     this.setState({
         cargandoImprimirFact:true,
         modalImprimirFact: true
        }, async () => {
            await this.cargarPuestosGral()
            this.setState({
                cargandoImprimirFact: false
            }, () => {
                ReactDOM.render(this.ImprimirFactores(), document.getElementById('generarFact-pdf'))
            })
        })
 }

 ImprimirFactores= () => (
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>EMPRESA:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>INFORME:</Text>
                <Text style={styles.text}>RECURSOS HUMANOS</Text>
            </View>
            <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>FACTORES Y TAREAS DE LOS PUESTOS DE TRABAJO</Text>
            </View>

                <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE CONOCIMIENTOS Y APTITUDES</Text>
                </View>
              {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONOCIMIENTOS Y APTITUDES</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>DESTREZA Y MANEJO DE EQUIPO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONOCIMIENTOS INFORMÁTICOS</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>POLIVALENCIA Y CREATIVIDAD</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>EXPERIENCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_APTITUDES?puesto.CONOCIMIENTOS_APTITUDES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DESTREZA_EQUIPOS?puesto.DESTREZA_EQUIPOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONOCIMIENTOS_INFORMATICOS?puesto.CONOCIMIENTOS_INFORMATICOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA?puesto.POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.EXPERIENCIA?puesto.EXPERIENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_CONOCIMIENTO?puesto.GENERO_CONOCIMIENTO:''}</Text></View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE ESFUERZO</Text>
                </View>
               {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'17%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>ESFUERZO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>POSTURAS FORZADAS</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>MOVIMIENTOS REPETITIVOS</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONCENTRACIÓN Y ESFUERZO EMOCIONAL</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>DIFICULTAD TÉCNICA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'7%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                   
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.ESFUERZO?puesto.ESFUERZO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POSTURAS_FORZADAS?puesto.POSTURAS_FORZADAS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.MOVIMIENTOS_REPETITIVOS?puesto.MOVIMIENTOS_REPETITIVOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONCENTRACION?puesto.CONCENTRACION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.DIFICULTAD_TECNICA?puesto.DIFICULTAD_TECNICA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'7%'}}>{puesto.GENERO_ESFUERZO?puesto.GENERO_ESFUERZO:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE RESPONSABILIDAD</Text>
                </View>
                    {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>RESPONSABILIDAD</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>ORGANIZACIÓN DEL TRABAJO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>RESPONSABILIDAD MAQUINARIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>JEFATURA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>INICIATIVA Y POLIVALENCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'9%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.RESPONSABILIDAD?puesto.RESPONSABILIDAD:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.ORGANIZACION_TRABAJO?puesto.ORGANIZACION_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RESPONSABILIDAD_MAQUINARIA?puesto.RESPONSABILIDAD_MAQUINARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{puesto.JEFATURA?puesto.JEFATURA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.INICIATIVA_POLIVALENCIA?puesto.INICIATIVA_POLIVALENCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'9%'}}>{puesto.GENERO_RESPONSABILIDAD?puesto.GENERO_RESPONSABILIDAD:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>FACTORES DE CONDICIONES DE TRABAJO</Text>
                </View>

                {this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  return(
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONDICIONES DE TRABAJO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CONTACTO CON CLIENTE</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'7%'}}>TRABAJO A DISTANCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>POLIVALENCIA EXIGIDA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>RIESGO PSICOSOCIAL</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'8%'}}>GÉNERO</Text>
                                </View>
                            )}
                            <View style={styles.colHead}>                                
                                    
                                   <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONDICIONES_TRABAJO?puesto.CONDICIONES_TRABAJO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.CONTACTO_CLIENTES?puesto.CONTACTO_CLIENTES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'7%'}}>{puesto.TRABAJO_DISTANCIA?puesto.TRABAJO_DISTANCIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.POLIVALENCIA_EXIGIDA?puesto.POLIVALENCIA_EXIGIDA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{puesto.RIESGO_PSICOSOCIAL?puesto.RIESGO_PSICOSOCIAL:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'8%'}}>{puesto.GENERO_CONDICIONES?puesto.GENERO_CONDICIONES:''}</Text>
                            </View>
                        </View>
                  )
              }):<></>}
              <View style={{margin:6}}>
                    <Text style={styles.labelTitle}>TAREAS</Text>
                </View>
                {
                    // eslint-disable-next-line
                this.state.puestosGral? this.state.puestosGral.map((puesto, index) => {
                  let tareas=puesto.TAREAS?JSON.parse(puesto.TAREAS):[];
                  for(let tarea of tareas){
                  return (
                        <View key={index}>
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>DEPARTAMENTO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NOMBRE PUESTO</Text>                                    
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>DESCRIPCION</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>VALOR</Text>
                                </View>
                            )}
                              <View style={styles.colHead}>                                
                                      
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO.AREA?puesto.DEPARTAMENTO.AREA.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DEPARTAMENTO?puesto.DEPARTAMENTO.DESCRIPCION:''}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{puesto.DESCRIPCION?puesto.DESCRIPCION:""}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{tarea.descripcion}</Text>
                                      <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{tarea.valor}</Text>
                              </View>
                        </View>
                  )
              }
            }):<></>}
        </View>
        <Text
        style={styles.footerPage}
        render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
        fixed
        />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
        <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
      </Page>
    </Document>
  </PDFViewer>
 )
    generarCsv=async() =>{
        await this.cargarDatosCsv();
        this.Csv.link.click();
    }

    cargarDatosCsv= async()=>{
        this.setState({cargandoCsv:true})
        await this.cargarPuestosGral()
        let registrosCsv=[]

        for(let registro of this.state.puestosGral){

            registrosCsv.push({"Área":registro.DEPARTAMENTO.AREA.DESCRIPCION,
                                "Departamento": registro.DEPARTAMENTO.DESCRIPCION,
                                "Nombre Puesto": registro.DESCRIPCION,
                                "Código Puesto":registro.CODIGO_PUESTO,
                                "Responsabilidad":registro.GRADO_RESPONSABILIDAD, 
                                "Riesgo Objetivo":registro.RIESGO_OBJETIVO.DESCRIPCION,
                                "Descripción Corta": registro.DESC_CORTA,
                                "Descripción Larga": registro.DESC_LARGA,
                                "Centro de Trabajo": registro.CENTRO_TRABAJO,
                                "Conocimientos y Aptitudes": registro.CONOCIMIENTOS_APTITUDES,
                                "Destreza y manejo de equipos": registro.DESTREZA_EQUIPOS,
                                "Conocimientos Informáticos": registro.CONOCIMIENTOS_INFORMATICOS,
                                "Polivalencia y Creatividad":registro.POLIVALENCIA,
                                "Experiencia": registro.EXPERIENCIA,
                                "Género Conocimiento": registro.GENERO_CONOCIMIENTO,
                                "Esfuerzo":registro.ESFUERZO,
                                "Posturas Forzadas": registro.POSTURAS_FORZADAS,
                                "Movimientos Repetivos": registro.MOVIMIENTOS_REPETITIVOS,
                                "Concentración y esfuerzo emocional": registro.CONCENTRACION,
                                "Dificultad Técnica": registro.DIFICULTAD_TECNICA,
                                "Género Esfuerzo": registro.GENERO_ESFUERZO,
                                "Responsabilidad2": registro.RESPONSABILIDAD,
                                "Organización del trabajo": registro.ORGANIZACION_TRABAJO,
                                "Responsabilidad Maquinaria": registro.RESPONSABILIDAD_MAQUINARIA,
                                "Jefatura": registro.JEFATURA,
                                "Iniciativa y Polivalencia": registro.INICIATIVA_POLIVALENCIA,
                                "Género Responsabilidad": registro.GENERO_RESPONSABILIDAD,
                                "Condiciones de Trabajo": registro.CONDICIONES_TRABAJO,
                                "Contacto con Cliente": registro.CONTACTO_CLIENTES,
                                "Trabajo a distancia": registro.TRABAJO_DISTANCIA,
                                "Polivalencia exigida": registro.POLIVALENCIA_EXIGIDA,
                                "Riesgo psicosocial": registro.RIESGO_PSICOSOCIAL,
                                "Género Condiciones": registro.GENERO_CONDICIONES,
                                "Tareas":registro.TAREAS
                            })
        }

        registrosCsv.sort(function(a,b){
            return('' + a.Nombre).localeCompare(b.Nombre)
        })

        this.setState({datosCsv: registrosCsv})

    this.setState({cargandoCsv:false})
    }

    botonesPaginacion=()=>{
        let botones=[]
        let totalPaginas= Math.ceil(this.state.totalRegistros/ this.registrosMostrar)
        let restante= this.state.indiceRegistros+(totalPaginas- this.state.indiceRegistros)

        if(this.state.indiceRegistros>=3){
            botones.push(
                <Button>...</Button>
            )
        }
        for(let i=this.state.inidceRegistros>2?this.state.indiceRegistros-2:0;i<(this.state.indiceRegistros<2?(restante>=4?4:restante):(this.state.indiceRegistros+3)<totalPaginas?this.state.indiceRegistros+3:restante); i++){
            botones.push(
                <Button key={i} className={this.state.indiceRegistros===i?Classes.INTENT_PRIMARY:''} onClick={()=>{this.cambiarIndiceMostrar(i)}}>{i+1}</Button>
            )
        }

        if(this.state.indiceRegistros<10 && (this.state.indiceRegistros+3)<totalPaginas){
			botones.push(
				<Button>...</Button>
			)

		}
		return botones
    }

    cambiarIndiceMostrar=async(indice)=>{
      this.setState({ 
          indiceRegistros: indice,
          cargando: true 
      }, async () => {
          await this.cargarPuestos()
          this.setState({cargando: false})
      })
        
    }

    irPrimerPagina= async()=>{
        this.setState({
            indiceRegistros:0,
            cargando:true
        }, async () => {
            await this.cargarPuestos()
            this.setState({cargando:false})
        })
    }
    irUltimaPagina= async()=>{
        this.setState({
            indiceRegistros: Math.ceil(this.state.totalRegistros / this.registrosMostrar) - 1,
            cargando:true
        }, async ()=>{
            await this.cargarPuestos()
            this.setState({cargando:false})
        })
    }

    render() {

        document.title ="LexControl | Valoración de Puestos de Trabajo"
        const handleSeleccionarPuesto = (value) => {
            
            if(value.length===0)return 

            let seleccionada=this.state.puestos[value[0].rows[0]] 
            this.setState({
                puestoSeleccionado:seleccionada,
                tareas:seleccionada.TAREAS?JSON.parse(seleccionada.TAREAS):[],
                regionSeleccionada:value,
                cargandoPuest:false
            })
        }
        const cellRenderArea= (rowIndex)=> {
            return (<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].AREA}`}</Cell>)
        }
        const cellRenderDpto=(rowIndex)=>{
            return(<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].DEPARTAMENTO}`}</Cell>)
        }
        const cellRenderPuesto=(rowIndex)=>{
            return(<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].DESCRIPCION}`}</Cell>)
        }

        const cellRenderCodigoPuesto=(rowIndex)=>{
            return(<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].CODIGO_PUESTO}`}</Cell>)
        }
        const cellRenderResponsabilidad= (rowIndex)=>{
            return(<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].GRADO_RESPONSABILIDAD}`}</Cell>)
        }
        const cellRenderRiesgoObj= (rowIndex)=>{
            let color=''
            switch (this.state.puestos[rowIndex].ID_RIESGO_OBJETIVO) {
                case 1:
                    color='green'                    
                    break;
                case 2:
                    color='yellow'
                    break;
                case 3: 
                    color='orange'
                    break;
                case 4:
                    color='red'
                    break;
                default:
                    break;
            }
            return(<Cell wrapText="false" truncated="false" style={{backgroundColor:color}}>{`${this.state.puestos[rowIndex].RIESGO_OBJETIVO}`}</Cell>)
        }
        const cellRenderDescripcion=(rowIndex)=>{
            return(<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].DESC_CORTA?this.state.puestos[rowIndex].DESC_CORTA:''}`}</Cell>)
        }
        const cellRenderDescripcionLarga=(rowIndex)=>{
            return(<Cell wrapText="false" truncated="false">{`${this.state.puestos[rowIndex].DESC_LARGA?this.state.puestos[rowIndex].DESC_LARGA:''}`}</Cell>)
        }
        const cellRenderCentroTrabajo=(rowIndex)=>{
            return(<Cell>{`${this.state.puestos[rowIndex].CENTRO_TRABAJO?this.state.puestos[rowIndex].CENTRO_TRABAJO:''}`}</Cell>)
        }
        const cellRendererDestreza=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.DESTREZA_EQUIPOS?this.state.puestoSeleccionado.DESTREZA_EQUIPOS:'0'}`:'0'}</Cell>)            
        }
        const cellRendererConoc=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.CONOCIMIENTOS_INFORMATICOS?this.state.puestoSeleccionado.CONOCIMIENTOS_INFORMATICOS:'0'}`:'0'}</Cell>)            
        }
        const cellRendererPoliyCreat=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.POLIVALENCIA?this.state.puestoSeleccionado.POLIVALENCIA:'0'}`:'0'}</Cell>)
        }
        const cellRendererExperiencia=() =>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.EXPERIENCIA?this.state.puestoSeleccionado.EXPERIENCIA:'0'}`:'0'}</Cell>)
        }
        const cellRendererGeneConoc = ()=> {
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.GENERO_CONOCIMIENTO?this.state.puestoSeleccionado.GENERO_CONOCIMIENTO:''}`:''}</Cell>)
        }
        const cellRendererPosturas=() =>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.POSTURAS_FORZADAS?this.state.puestoSeleccionado.POSTURAS_FORZADAS:'0'}`:'0'}</Cell>)
        }
        const cellRendererMovimientos=() =>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.MOVIMIENTOS_REPETITIVOS?this.state.puestoSeleccionado.MOVIMIENTOS_REPETITIVOS:'0'}`:'0'}</Cell>)
        }
        const cellRendererConcetracion= () => {
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.CONCENTRACION?this.state.puestoSeleccionado.CONCENTRACION:'0'}`:'0'}</Cell>)
        }
        const cellRendererDificultad=() => {
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.DIFICULTAD_TECNICA?this.state.puestoSeleccionado.DIFICULTAD_TECNICA:'0'}`:'0'}</Cell>)
        }
        const cellRendererGeneEsf=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.GENERO_ESFUERZO?this.state.puestoSeleccionado.GENERO_ESFUERZO:''}`:''}</Cell>)
        }
        const cellRendererOrganizacion=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.ORGANIZACION_TRABAJO?this.state.puestoSeleccionado.ORGANIZACION_TRABAJO:'0'}`:'0'}</Cell>)
        }
        const cellRendererRespons=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.RESPONSABILIDAD_MAQUINARIA?this.state.puestoSeleccionado.RESPONSABILIDAD_MAQUINARIA:'0'}`:'0'}</Cell>)
        }
        const cellRendererJefatura=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.JEFATURA?this.state.puestoSeleccionado.JEFATURA:'0'}`:'0'}</Cell>)
        }
        const cellRendererInicyPoliv=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.INICIATIVA_POLIVALENCIA?this.state.puestoSeleccionado.INICIATIVA_POLIVALENCIA:'0'}`:'0'}</Cell>)
        }
        const cellRendererGeneRespons=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.GENERO_RESPONSABILIDAD?this.state.puestoSeleccionado.GENERO_RESPONSABILIDAD:''}`:''}</Cell>)
        }
        const cellRendererContacto=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.CONTACTO_CLIENTES?this.state.puestoSeleccionado.CONTACTO_CLIENTES:'0'}`:'0'}</Cell>)
        }
        const cellRendererTrabDistancia=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.TRABAJO_DISTANCIA?this.state.puestoSeleccionado.TRABAJO_DISTANCIA:'0'}`:'0'}</Cell>)
        }
        const cellRendererPoliExigida=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.POLIVALENCIA_EXIGIDA?this.state.puestoSeleccionado.POLIVALENCIA_EXIGIDA:'0'}`:'0'}</Cell>)
        }
        const cellRendererRiesgo=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.RIESGO_PSICOSOCIAL?this.state.puestoSeleccionado.RIESGO_PSICOSOCIAL:'0'}`:'0'}</Cell>)
        }
        const cellRendererGeneCondiciones=()=>{
            return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.GENERO_CONDICIONES?this.state.puestoSeleccionado.GENERO_CONDICIONES:''}`:''}</Cell>)
        }
        const cellRendererDescTarea=(rowIndex)=>{
          return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.tareas[rowIndex].descripcion}`:''}</Cell>)
        }
        const cellRendererValorTarea=(rowIndex)=>{
          return(<Cell loading={this.state.cargandoPuest}>{this.state.puestoSeleccionado?`${this.state.tareas[rowIndex].valor}`:''}</Cell>)
        } 
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false,
                modalImprimirProp:false,
                modalImprimirFact: false
            })
        }
        

        

       
        return (
            <>
            {this.state.cargando?
                <Cargando/>
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Valoración de Puestos de Trabajo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Puestos de Trabajo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirProp}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirProp?
                            <div style={{ width: '100%', height: '600px'}} id="generarProp-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Factores y Tareas de Puestos de Trabajo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirFact}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirFact?
                            <div style={{ width: '100%', height: '600px'}} id="generarFact-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <div id="contenedor-valoracion-puestos">
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Valoración Puestos de Trabajo</Callout>
                        <Navbar>
                            <NavbarGroup align={Alignment.LEFT}>
                               <ButtonGroup align={Alignment.LEFT}>
                                    <Tooltip content="Imprimir General" position={Position.RIGHT}>
                                        <Button key='botonImprimir' className="bp3-minimal" icon='print' text='Imprimir General' id='boton-imprimir' onClick={() => this.generarImprimir()}/>
                                    </Tooltip>
                                    <Tooltip content="Imprimir Propiedades" position={Position.RIGHT}>
                                        <Button key='botonImprimir' className="bp3-minimal" icon='print' text='Imprimir Puestos' id='boton-imprimir' onClick={() => this.generarImprimirProp()}/>
                                    </Tooltip>
                                    <Tooltip content="Imprimir Factores y Tareas" position={Position.RIGHT}>
                                        <Button key='botonImprimir' className="bp3-minimal" icon='print' text='Imprimir Factores y Tareas' id='boton-imprimir' onClick={() => this.generarImprimirFactores()}/>
                                    </Tooltip>
                                    
                                    <CSVLink ref={(r) => {this.Csv = r;}} data={this.state.datosCsv} separator={';'} enclosingCharacter={``} filename={"ValoracionPuestos.csv"}/>
                                    {!this.state.cargandoCsv?
                                    <Tooltip content="Exportar el listado de valoraciones de puestos de trabajo" position={Position.RIGHT}>
                                        <Button className='bp3-minimal' icon='document' text='Exportar' onClick={()=> this.generarCsv()}/>
                                    </Tooltip>
                                    :
                                    <Button className="bp3-minimal" icon="document" text="Cargando..."/>
                                    }
                               </ButtonGroup>
                            </NavbarGroup>
                        </Navbar>
                        <>
                        <Table className="valoracion-puestos" onSelection={handleSeleccionarPuesto} enableColumnResizing={false} columnWidths={this.state.columnWidths} enableRowHeader={false} ref={(input) => {this.tablaValoracion = input;}} enableMultipleSelection={false} selectedRegions={this.state.regionSeleccionada} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.puestos.length} onCompleteRender={() => {
                                    if (!this.state.resized) {
                                        this.resizeColumns();
                                    }
                                }}>
                            <Column name='Área' cellRenderer={cellRenderArea}/>
                            <Column name='Dpto' cellRenderer={cellRenderDpto}/>
                            <Column name='Pto. Trab.' wrapText="false" cellRenderer={cellRenderPuesto}/>
                            <Column name='Cód. Pto.' cellRenderer={cellRenderCodigoPuesto}/>
                            <Column name='Resp.' cellRenderer={cellRenderResponsabilidad}/>
                            <Column name='Riesgo Obj.' cellRenderer={cellRenderRiesgoObj}/>
                            <Column name='Desc. Breve' cellRenderer={cellRenderDescripcion} />
                            <Column name='Desc. Detallada' cellRenderer={cellRenderDescripcionLarga} />
                            <Column name='Ctro. Trab.' cellRenderer={cellRenderCentroTrabajo}  />
                        </Table>
                        <div className="centrar2">
                            {this.state.totalRegistros>10?
                                <ButtonGroup className="pixelAlrededorGrande">
                                <Button icon="chevron-left" onClick={this.irPrimerPagina} disabled={this.state.indiceRegistros===0?true:false}></Button>
                                    {this.botonesPaginacion()}
                                <Button icon="chevron-right" onClick={this.irUltimaPagina} disabled={this.state.indiceRegistros===Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1?true:false}></Button>
                            </ButtonGroup> 
                            :<></>}
                        </div>
                        </>
                    </Card>
                </div>
                <div className="contenedor_factoresyTareas">
                    <Card elevation={Elevation.TWO} interactive={true}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                          Factores y Tareas {this.state.puestoSeleccionado?`${this.state.puestoSeleccionado.DESCRIPCION?'de '+this.state.puestoSeleccionado.DESCRIPCION:''}`: ""}
                        </Callout>
                        <br></br>
                        <Callout className="bp3-intent-danger">
                            {this.state.puestoSeleccionado?`Conocimientos y aptitudes : ${this.state.puestoSeleccionado.CONOCIMIENTOS_APTITUDES?this.state.puestoSeleccionado.CONOCIMIENTOS_APTITUDES:''}%`: "Seleccione un puesto para ver factores de conocimientos y aptitudes"}
                        </Callout>
                                <Table columnWidths={this.state.columnWidths2} ref={(input)=>{this.tablaFactores= input;}} selectedRegions={this.state.regionSeleccionada}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.puestoSeleccionado?1:0} onCompleteRender={() => {
                                                if (!this.state.resized2) {
                                                    this.resizeColumnsFactores();
                                                }
                                            }}>
                                    <Column name="Destreza y manejo de equipo" cellRenderer={cellRendererDestreza}/>
                                    <Column name="Conocimientos Informáticos" cellRenderer={cellRendererConoc}/>
                                    <Column name="Polivalencia y creatividad" cellRenderer={cellRendererPoliyCreat}/>
                                    <Column name="Experiencia" cellRenderer={cellRendererExperiencia}/>
                                    <Column name="Género" cellRenderer={cellRendererGeneConoc}/>
                                </Table>
                        <br></br>
                        <Callout className="bp3-intent-danger">
                        {this.state.puestoSeleccionado?`Esfuerzo : ${this.state.puestoSeleccionado.ESFUERZO?this.state.puestoSeleccionado.ESFUERZO:''}%`: "Seleccione un puesto para ver factores de esfuerzo"}
                        </Callout>
                              <Table columnWidths={this.state.columnWidths2} ref={(input)=>{this.tablaFactores= input;}} enableMultipleSelection={false}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.puestoSeleccionado?1:0} onCompleteRender={() => {
                                                if (!this.state.resized2) {
                                                    this.resizeColumnsFactores();
                                                }
                                            }}>
                                    <Column name="Posturas Forzadas" cellRenderer={cellRendererPosturas}/>
                                    <Column name="Movimientos repetitivos" cellRenderer={cellRendererMovimientos}/>
                                    <Column name="Concentración y esfuerzo emocional" cellRenderer={cellRendererConcetracion}/>
                                    <Column name="Dificultad Técnica" cellRenderer={cellRendererDificultad}/>
                                    <Column name="Género" cellRenderer={cellRendererGeneEsf}/>
                                </Table>
                        <br></br>
                        <Callout className="bp3-intent-danger">
                        {this.state.puestoSeleccionado?`Responsabilidad : ${this.state.puestoSeleccionado.RESPONSABILIDAD?this.state.puestoSeleccionado.RESPONSABILIDAD:''}%`: "Seleccione un puesto para ver factores de responsabilidad"}
                        </Callout>
                                <Table columnWidths={this.state.columnWidths2} ref={(input)=>{this.tablaFactores= input;}} enableMultipleSelection={false}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.puestoSeleccionado?1:0} onCompleteRender={() => {
                                                if (!this.state.resized2) {
                                                    this.resizeColumnsFactores();
                                                }
                                            }}>
                                    <Column name="Organización del trabajo" cellRenderer={cellRendererOrganizacion}/>
                                    <Column name="Responsabilidad Maquinaria" cellRenderer={cellRendererRespons}/>
                                    <Column name="Jefatura" cellRenderer={cellRendererJefatura}/>
                                    <Column name="Iniciativa y Polivalencia" cellRenderer={cellRendererInicyPoliv}/>
                                    <Column name="Género" cellRenderer={cellRendererGeneRespons}/>
                                </Table>
                        <br></br>
                        <Callout className="bp3-intent-danger">
                            {this.state.puestoSeleccionado?`Condiciones de Trabajo : ${this.state.puestoSeleccionado.CONDICIONES_TRABAJO?this.state.puestoSeleccionado.CONDICIONES_TRABAJO:''}%`: "Seleccione un puesto para ver factores de condiciones de trabajo"}
                        </Callout>
                        <Table columnWidths={this.state.columnWidths2} ref={(input)=>{this.tablaFactores= input;}} enableMultipleSelection={false}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.puestoSeleccionado?1:0} onCompleteRender={() => {
                                                if (!this.state.resized2) {
                                                    this.resizeColumnsFactores();
                                                }
                                            }}>
                                    <Column name="Contacto con el Cliente" cellRenderer={cellRendererContacto}/>
                                    <Column name="Trabajo a Distancia" cellRenderer={cellRendererTrabDistancia}/>
                                    <Column name="Polivalencia Exigida" cellRenderer={cellRendererPoliExigida}/>
                                    <Column name="Riesgo Psicosocial" cellRenderer={cellRendererRiesgo}/>
                                    <Column name="Género" cellRenderer={cellRendererGeneCondiciones}/>
                                </Table>
                        <br></br>
                        <Callout className="bp3-intent-danger">
                            {this.state.puestoSeleccionado?`Tareas`: "Seleccione un puesto para ver tareas"}
                        </Callout>
                        <Table columnWidths={this.state.columnWidths3} ref={(input)=>{this.tablaTareas= input;}} enableMultipleSelection={false}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.tareas.length} onCompleteRender={() => {
                                                if (!this.state.resized3) {
                                                    this.resizeColumnsTareas();
                                                }
                                            }}>
                                    <Column name="Descripción" cellRenderer={cellRendererDescTarea}/>
                                    <Column name="Valor" cellRenderer={cellRendererValorTarea}/>
                                  </Table>
                    </Card>
                                            
                                                          
                   
                </div>
            </div>
         </> 
         
         }  
     </>
        )
      
    }
}

export default withApollo(ValoracionPuestos)