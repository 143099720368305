import React, {Component} from 'react';
import { withApollo } from 'react-apollo';
import { Cargando } from './Cargando';
import { Card, 
    Elevation, 
    Callout,
    Navbar,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    Tabs,
    Tab,
    Tooltip,
    Classes,
    Intent,
    AnchorButton } from "@blueprintjs/core"
import InformacionActas from "./InformacionActas"
import Informacion from "./Elementos/Informacion"
import AdjuntosActa from "./Elementos/AdjuntosActa"
import Resumen from "./Elementos/Resumen"
import Asistentes from "./Elementos/AsistentesActa"
import DatosActa from "./Elementos/Acta"
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from "./BootstrapTable";
import filterFactory, { selectFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';
import gql from "graphql-tag";
import AcuerdosActas from "./AcuerdosActas"
import axios from 'axios';
import DatosEmpresa from './Elementos/ObtenerDatosEmpresa';
import ObtenerRecursos from './Elementos/ObtenerRecursosNecesarios';
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class Actas extends Component {
    state={
        cargando: false,
        nuevaActa:true,
        pestañaDialog: "datos-acta",
        modalNuevoAbierto: false,
        actaSeleccionada:null,
        actas:[],
        tiposReuniones:[],
        cargandoAcuerdo:false,
        acuerdos:[],
        asistentes:[],
        asistentes_vota:0,
        representantes_vota:0,
        personas:[],
        actaAdjuntos:[],
        inscripcionOptions: [],
        registroOptions: [],
        empleados:[],
        nombreEmpresa:'',
        logotipo:null,
        modalLeyes:false,
        guardando:false
    }

    componentDidMount=async ()=>{
        this.setState({cargando:true,cargandoAcuerdo:true});
        
        await this.cargarPersonas();
    }

    obtenerActas=async () => {
        return await this.props.client.query({
            query:gql`{actas(ORDEN:"FECHA_REUNION,ASC"){
                ID_ACTA,
                NUMERO,
                FECHA_REUNION,
                LUGAR,
                RESUMEN,
                APROBADA,
                FECHA_APROBACION,
                SISTEMA_APROBACION,
                FIRMADA,
                ID_TIPO_CONVOCATORIA,
                TIPO_CONVOCATORIA{DESCRIPCION},
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.actas))
        })
    }

    actualizarNumeracion=async ()=>{
        const actas=await this.obtenerActas();
        let numero={};
        for(const acta of actas) {
            if (numero[acta.ID_TIPO_CONVOCATORIA]) {
                numero[acta.ID_TIPO_CONVOCATORIA]+=1;
            } else {
                numero[acta.ID_TIPO_CONVOCATORIA]=1;
            }
            await this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarNumeracion(
                        ID_ACTA:${acta.ID_ACTA},
                        VALORES:{
                            NUMERO:${numero[acta.ID_TIPO_CONVOCATORIA]}
                    }){ID_ACTA}}                `
            });

        }
    }

    recargarAcuerdos=()=>{
        this.setState({
            cargandoAcuerdo:true
        },async ()=>{
            await this.cargarAcuerdos(this.state.actaSeleccionada.ID_ACTA);
            this.setState({cargandoAcuerdo:false});
        })
    }

    cargarPersonas=async ()=>{
        const personasFisicas=await ObtenerRecursos.obtenerPersonasFisicas();
        
        const personasJuridicas=await ObtenerRecursos.obtenerPersonasJuridicas();
        
        const personas = [...personasFisicas, ...personasJuridicas];

        const empleados=await ObtenerRecursos.obtenerEmpleados(personas);
        
        this.setState({
            personas:personas,
            empleados:empleados
        }, async()=>{
            await this.cargarDatos();
        });

    }

    calcularAsistentesVota=async (asistentes)=>{
        let asistentes_vota=0;
        for(let persona of asistentes){
            if (persona.vota && persona.representa === null) {
                asistentes_vota+=1;
            } 
        }
        return asistentes_vota;
    }

    calcularRepresentantesVota=async (asistentes)=>{
        let representantes_vota=0
        for(let persona of asistentes){
            if (persona.vota && persona.representa) {
                representantes_vota+=1;
            } 
        }
        return representantes_vota;
    }

    setAdjuntos=(adjuntos)=>{
        this.setState({
            actaAdjuntos:adjuntos
        });
    }

    setAsistentes=async (asistentes)=>{
        const representantes_vota=await this.calcularRepresentantesVota(asistentes);
        const asistentes_vota=await this.calcularAsistentesVota(asistentes);
        this.setState({
            asistentes:asistentes,
            representantes_vota:representantes_vota,
            asistentes_vota:asistentes_vota
        });
    }

    cargarDatos=async()=>{
        this.setState({
            registroOptions:await ObtenerRecursos.obtenerRegistros(),
            inscripcionOptions: await ObtenerRecursos.obtenerCalificacionesAcuerdos(),
            tiposReuniones: await ObtenerRecursos.obtenerConvocatoria(),
            nombreEmpresa:await DatosEmpresa.obtenerNombreEmpresa(),
            logotipo:await DatosEmpresa.obtenerLogoEmpresa()
        },async()=>{
            await this.cargarActas();
        });
        
    }

    cargarActas=async()=>{
        this.setState({
            actas:await this.obtenerActas(),
            cargando: false,
            cargandoAcuerdo:false
        });
        
    }

    obtenerNombreMiembros=async(miembro)=>{
        let personaSel=this.state.personas.find(persona=>{return (persona.value===miembro.ID_PERSONA && persona.tipo.TIPO_PERSONA.ID_TIPO_PERSONA===miembro.TIPO_PERSONA.ID_TIPO_PERSONA)});
        miembro.label=personaSel.label;        
        
    }

    cargarAdjuntos=async () => {
            if (this.state.actaSeleccionada) {
                this.props.client.query({
                    query:gql`{actasPdf(FILTROS:{ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}}){
                        ID_ACTA,
                        RUTA_ARCHIVO
                    }}`,
                    fetchPolicy:'network-only'
                }).then(async(result)=>{
                    this.setState({actaAdjuntos:result.data.actasPdf});
                });

                
            }

            this.setState({modalNuevoAbierto:true});
    }

    cargarAsistentes=async()=>{
        if (this.state.actaSeleccionada) {
            this.props.client.query({
                query:gql`{actasAsistentes(FILTROS:{ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}}){
                    ID_ACTA,
                    ID_ASISTENTE,
                    ASISTENTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                    DERECHO_VOTO,
                    REPRESENTA_A
                    REPRESENTANTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                }}`,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
                let asistentes=[]
                for(let asistente of JSON.parse(JSON.stringify(result.data.actasAsistentes))){
                    await this.obtenerNombreMiembros(asistente.ASISTENTE)
                    if(asistente.REPRESENTANTE)await this.obtenerNombreMiembros(asistente.REPRESENTANTE)
                    asistentes.push({
                        value:asistente.ID_ASISTENTE,
                        label:asistente.ASISTENTE.label,
                        vota:asistente.DERECHO_VOTO,
                        idRepresenta:asistente.REPRESENTA_A,
                        representa:asistente.REPRESENTANTE?asistente.REPRESENTANTE.label:null
                    })
                }
                this.setAsistentes(asistentes);
            })
        }
    }

    cargarAcuerdos=async(idActa)=>{
        let acuerdos=await this.props.client.query({
            query:gql`
            {
            actasAcuerdos(FILTROS:{ID_ACTA:${idActa}}){
              ID_ACUERDO
              ID_ACTA,
              NUMERO,
              TEXTO,
              PLAZO,
              CUMPLIMIENTO,
              RESPONSABLE,
              OBSERVACIONES,
              INSCRIBIBLE_RM,
              ID_SOLICITANTE,
              FECHA_SOLICITUD
            }
            }
          `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.actasAcuerdos))
        })

        this.setState({acuerdos:acuerdos}) 

    }

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("actas.title",{ns:"page"});

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }

        const cambiarPestaña = (navbarTabId) => this.setState({ pestañaDialog: navbarTabId });

        const eliminarActa = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.actaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}));
                return
            }

            if (!window.confirm(i18next.t("actas.questiondelacta",{ns:"page"}))) {
                return
            }
            this.setState({guardando:true});
            this.props.client.mutate({
                mutation:gql`mutation{eliminarActa(ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}){ID_ACTA}}`
            }).then(async result=>{
               
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({
                    actaSeleccionada:null,
                    cargando: true,
                    cargandoAcuerdo:true
                },async ()=>{
                    await this.actualizarNumeracion();
                    await this.cargarActas();
                    this.setState({guardando:false});
                    cerrarModal();
                })
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
        }

        const abrirModalLeyes = async() => {
            this.setState({
                modalLeyes:true
            });
        }

        const abrirModalNuevo = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({
                nuevaActa:true,
                pestañaDialog:"datos-acta",
                modalNuevoAbierto: true,
                actaSeleccionada:null,
                cargando:true,
                cargandoAcuerdo:true,
                acuerdos:[]
            },()=>{
                this.setAsistentes([]);
            })

        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.actaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevaActa:false,
                pestañaDialog:"datos-acta",
                actaAdjuntos:[]
            },async () =>{
                await this.cargarAdjuntos();
                    
            })

        }

        const anadirActa = async() => {
            this.setState({pestañaDialog:"datos-acta",guardando:true});
            if(!this.DatosActa.state.tipoReunion){
                this.setState({pestañaDialog:"datos-acta"});
                alert(i18next.t("actas.valid_1",{ns:"page"}));
                return
            }

            if(this.DatosActa.state.lugarReunion===''){
                this.setState({pestañaDialog:"datos-acta"});
                alert(i18next.t("actas.valid_3",{ns:"page"}));
                return
            }

            if(this.state.asistentes.length===0){
                alert(i18next.t("actas.valid_4",{ns:"page"}))
                return
            }
            
            this.DatosActa.state.cargando=true;
            //creamos el acta.
            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearActa(VALORES:{
                        NUMERO:${0}
                        FECHA_REUNION:"${moment(this.DatosActa.state.fechaReunion).format("YYYY-MM-DD")}"
                        LUGAR:"${this.DatosActa.state.lugarReunion}"
                        ID_TIPO_CONVOCATORIA:${this.DatosActa.state.tipoReunion.value}
                        RESUMEN:${this.DatosResumen.state.resumen?'"""'+this.DatosResumen.state.resumen+'"""':null}
                        APROBADA:${this.DatosActa.state.actaAprobada}
                        FECHA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+moment(this.DatosActa.state.fechaAprobacion).format("YYYY-MM-DD")+'"':null}
                        SISTEMA_APROBACION:${this.DatosActa.state.actaAprobada && this.DatosActa.state.sistemaAprobacion?'"'+this.DatosActa.state.sistemaAprobacion+'"':null}
                        FIRMADA:${this.DatosActa.state.actaFirmada}
                        ID_COMISION:null
                    }){ID_ACTA}}
                `
            }).then(async result=>{
                await this.actualizarNumeracion();

                if (this.DatosAdjuntosActa.state.fileAdjunto) {
                    let archivo=this.DatosAdjuntosActa.state.fileAdjunto
                    //insertamos el archivo
  
                    const api = axios.create({
                        withCredentials: true
                    });
  
                    var formData = new FormData();
                    formData.append("files",archivo);
  
                    //si no tenemos adjunto
                    
                    await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearActaPdf(VALORES:{ID_ACTA:${result.data.crearActa.ID_ACTA}}){ID_ACTA,RUTA_ARCHIVO}}`, formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }).catch(err=>{
                        alert(i18next.t("error",{ns:"global"}))
                        this.DatosActa.state.cargando=false;
                        this.setState({ 
                            cargando: false,
                            cargandoAcuerdo:false
                        },async ()=>{
                            await this.cargarActas();
                            cerrarModal()
                        });
                        console.log(err)
                    })
                }

                if(this.state.asistentes.length>0){
                    for(let asistente of this.state.asistentes){
                        //creamos el asistente
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearActaAsistente(VALORES:{
                                    ID_ACTA:${result.data.crearActa.ID_ACTA}
                                    ID_ASISTENTE:${asistente.value}
                                    DERECHO_VOTO:${asistente.vota}
                                    REPRESENTA_A:${asistente.idRepresenta?asistente.idRepresenta:null}
                                }){ID_ACTA,ID_ASISTENTE,DERECHO_VOTO,REPRESENTA_A}}
                            `
                        })

                    }
                }

                alert(i18next.t("insertcorrecto",{ns:"global"}));
                this.setState({ 
                    cargando: true,
                    cargandoAcuerdo:true,
                    guardando:false
                },async ()=>{
                    await this.cargarActas();
                    cerrarModal()
                });
                
                
            }).catch(err=>{
                console.log(err);
                this.DatosActa.state.cargando=false;
                this.setState({ 
                    cargando: false,
                    cargandoAcuerdo:false,
                    guardando:false
                },async ()=>{
                    await this.cargarActas();
                    cerrarModal()
                });
                alert(i18next.t("error",{ns:"global"}))
            })
        }

        
        const actualizarActa = async() => {
            this.setState({pestañaDialog:"datos-acta",guardando:true});
            if(!this.DatosActa.state.tipoReunion){
                this.setState({pestañaDialog:"datos-acta"});
                alert(i18next.t("actas.valid_1",{ns:"page"}));
                return
            }

            if(this.DatosActa.state.lugarReunion===''){
                this.setState({pestañaDialog:"datos-acta"});
                alert(i18next.t("actas.valid_3",{ns:"page"}));
                return
            }

            if(this.state.asistentes.length===0){
                alert(i18next.t("actas.valid_4",{ns:"page"}))
                return
            }
            
            this.DatosActa.state.cargando=true;
            
            //creamos el acta.
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarActa(
                        ID_ACTA:${this.state.actaSeleccionada.ID_ACTA},
                        VALORES:{
                            NUMERO:${0}
                            FECHA_REUNION:"${moment(this.DatosActa.state.fechaReunion).format("YYYY-MM-DD")}"
                            LUGAR:"${this.DatosActa.state.lugarReunion}"
                            ID_TIPO_CONVOCATORIA:${this.DatosActa.state.tipoReunion.value}
                            RESUMEN:${this.DatosResumen.state.resumen?'"""'+this.DatosResumen.state.resumen+'"""':null}
                            APROBADA:${this.DatosActa.state.actaAprobada}
                            FECHA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+moment(this.DatosActa.state.fechaAprobacion).format("YYYY-MM-DD")+'"':null}
                            SISTEMA_APROBACION:${this.DatosActa.state.actaAprobada && this.DatosActa.state.sistemaAprobacion?'"'+this.DatosActa.state.sistemaAprobacion+'"':null}
                            FIRMADA:${this.DatosActa.state.actaFirmada}
                            ID_COMISION:null
                    }){ID_ACTA}}
                `
            }).then(async result=>{
                await this.actualizarNumeracion();
                //eliminamos los antiguos asistentes.
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{eliminarActaAsistente(ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}){ID_ACTA}}
                    `
                })

                if(this.state.asistentes.length>0){
                    for(let asistente of this.state.asistentes){
                        //creamos el asistente
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearActaAsistente(VALORES:{
                                    ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}
                                    ID_ASISTENTE:${asistente.value}
                                    DERECHO_VOTO:${asistente.vota}
                                    REPRESENTA_A:${asistente.idRepresenta?asistente.idRepresenta:null}
                                }){ID_ACTA,ID_ASISTENTE,DERECHO_VOTO,REPRESENTA_A}}
                            `
                        }).catch(err=>{
                            alert(i18next.t("error",{ns:"global"}))
                            this.DatosActa.state.cargando=false;
                            this.setState({ 
                                cargando: false,
                                cargandoAcuerdo:false
                            },async ()=>{
                                await this.cargarActas();
                                cerrarModal()
                            });
                            console.log(err)
                        })

                    }
                }

                alert(i18next.t("updatecorrecto",{ns:"global"}));
                this.setState({ 
                    cargando: true,
                    cargandoAcuerdo:true,
                    guardando:false
                },async ()=>{
                    await this.cargarActas();
                    cerrarModal()
                });


            }).catch(err=>{
                console.log(err)
                this.DatosActa.state.cargando=false;
                this.setState({ 
                    cargando: false,
                    cargandoAcuerdo:false,
                    guardando:false
                },async ()=>{
                    await this.cargarActas();
                    cerrarModal()
                });
                alert(i18next.t("error",{ns:"global"}))
            })
        }

        const defaultSorted = [{
            dataField: 'NUMERO',
            order: 'asc'
        }];

        const filterByDate = (filterVal, data) => {
            if (filterVal) {
              return data.filter(acta => moment(acta.FECHA_REUNION).format("DD/MM/YYYY").startsWith(filterVal));
            }
            return data;
        }

        const columns = [{
            dataField: 'NUMERO',
            text: i18next.t("actas.nroacta",{ns:"page"}),
            sort: true,
            filter: textFilter({ 
                placeholder: i18next.t("actas.nroacta",{ns:"page"}),
                comparator: Comparator.EQ 
            })
            }, {
            dataField: 'FECHA_REUNION',
            text: i18next.t("actas.fecha",{ns:"page"}),
            formatter: dateFormatter,
            filter: textFilter({ 
                placeholder: i18next.t("actas.fecha",{ns:"page"}),
                onFilter: filterByDate
            })
            }, {
            dataField: 'ID_TIPO_CONVOCATORIA',
            text: i18next.t("actas.organo",{ns:"page"}),
            formatter: cell => this.state.tiposReuniones.filter(opt => opt.value === cell)[0].label || '',
            filter: selectFilter({
                options: this.state.tiposReuniones,
                placeholder: i18next.t("actas.selectorgano",{ns:"page"})
            })
        }];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    actaSeleccionada: row,
                    cargandoAcuerdo:true
                },async()=>{
                    await this.cargarAcuerdos(this.state.actaSeleccionada.ID_ACTA);
                    await this.cargarAsistentes();
                    this.setState({cargandoAcuerdo:false});
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                nuevaActa:true,
                pestañaDialog:"datos-acta",
                cargando:false,
                cargandoAcuerdo:false
            })
        }

        const cerrarModalLeyes = () => {
            this.setState({
                modalLeyes: false
            })
        }

        return (
            <>
            <Dialog
                ransitionDuration={400}
                className="dialog-ancho dialog-scroll"
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModalLeyes}
                title={i18next.t("actas.titleley",{ns:"page"})}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                isCloseButtonShown={false}
                usePortal={true}
                isOpen={this.state.modalLeyes}
            >
                <div id="dialogo-actas">
                    <InformacionActas i18next={i18next} />
                </div>
                <br></br>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModalLeyes}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog 
                transitionDuration={400}
                className="dialog-scroll"
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                title=""
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                isCloseButtonShown={false}
                usePortal={true}
                isOpen={this.state.guardando}
                onClose={cerrarModal}
            >
                <Cargando/>
            </Dialog>
            <Dialog 
                transitionDuration={400}
                className="dialog-ancho dialog-scroll"
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.state.nuevaActa?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                isCloseButtonShown={false}
                usePortal={true}
                isOpen={this.state.modalNuevoAbierto}
            >
                <div id="dialogo-actas">
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <Tabs 
                            id="propiedades-acta"
                            animate={this.animacion} 
                            onChange={cambiarPestaña} 
                            selectedTabId={this.state.pestañaDialog}
                            renderActiveTabPanelOnly={false}
                        >
                            <Tab id="datos-acta" title={i18next.t("actas.datos",{ns:"page"})} panel={<DatosActa editable={this.props.editable} ref={(input) => { this.DatosActa= input; }}  acta={this.state.actaSeleccionada} asistentes={this.state.asistentes} asistentes_vota={this.state.asistentes_vota} representantes_vota={this.state.representantes_vota} tiposReuniones={this.state.tiposReuniones} i18next={i18next}/>} />
                            <Tab id="asistentes" title={i18next.t("actas.asistentes",{ns:"page"})} panel={<Asistentes editable={this.props.editable} ref={(input) => { this.DatosAsistentes= input; }} acta={this.state.actaSeleccionada} asistentes={this.state.asistentes} setAsistentes={this.setAsistentes} personas={this.state.personas} cargarPersonas={this.cargarPersonas}   i18next={i18next}/>} />
                            <Tab id="resumen" title={i18next.t("actas.resumen",{ns:"page"})} panel={<Resumen editable={this.props.editable} ref={(input) => { this.DatosResumen= input; }} acta={this.state.actaSeleccionada} i18next={i18next}/>} />
                            <Tab id="adjuntos" title={i18next.t("actas.adjuntos",{ns:"page"})} panel={<AdjuntosActa editable={this.props.editable} ref={(input) => { this.DatosAdjuntosActa= input; }}  acta={this.state.actaSeleccionada} actaAdjuntos={this.state.actaAdjuntos} setAdjuntos={this.setAdjuntos} isNew={this.state.nuevaActa?true:false} i18next={i18next}/>} />
                            <Tab id="informacion" title={i18next.t("actas.informacion",{ns:"page"})} panel={<Informacion editable={this.props.editable}  i18next={i18next} />} />
                            
                        </Tabs>
                    </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaActa?anadirActa:actualizarActa}
                            >
                                {this.state.nuevaActa?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>  
            </Dialog>
            <br></br>
                <div className="tablaCien">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.cargando===true?
                                <Cargando/>
                        :
                        <>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("actas.title",{ns:"page"})}</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})}  onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})}  onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} onClick={eliminarActa} />
                                    <Button className="bp3-minimal" icon="eye-open" text={i18next.t("seelaws",{ns:"global"})}  onClick={abrirModalLeyes}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='ID_ACTA'
                            data={ this.state.actas }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            filter={ filterFactory() }
                            defaultSorted={ defaultSorted }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }
                    </Card>
                </div>
                <div className="tablaCien">
                    <AcuerdosActas 
                        editable={this.props.editable} 
                        cargandoAcuerdo={this.state.cargandoAcuerdo} 
                        acuerdos={this.state.acuerdos} 
                        recargarAcuerdos={this.recargarAcuerdos} 
                        acta={this.state.actaSeleccionada} 
                        asistentes={this.state.asistentes} 
                        obtenerNombreMiembros={this.obtenerNombreMiembros}
                        inscripcionOptions={this.state.inscripcionOptions}
                        registroOptions={this.state.registroOptions}
                        cargarRegistros={this.cargarRegistros}
                        empleados={this.state.empleados}
                        nombreEmpresa={this.state.nombreEmpresa}
                        logotipo={this.state.logotipo}
                        i18next={i18next}
                    />
                </div>
            </>
            
        )
    }
}

export default withTranslation()(withApollo(Actas));