/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from "react";
import {
  Spinner,
  Card,
  Elevation,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  Dialog,
  FormGroup,
  Navbar,
  NavbarHeading,
  ButtonGroup,
  Button,
  InputGroup,
  Alignment,
  Callout
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import gql from "graphql-tag";
import { NuevoTituloSocios } from "./NuevoTituloSocios";
import { withApollo } from "react-apollo";
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { CSVLink } from "react-csv";
import { Cargando } from "../Cargando";
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
import styles from '../stylesPrint';



class CapitalSocialInicial extends React.Component {
  _isMounted = false;

  state = {
	fecha: "",
	fechaSeleccionada: new Date(),
	valorTitulo: 0,
	numTitulos: 0,
	capitalSocialInicial: "0.00",
	existeCapitaSocial: false,
	abrirModalGuardarDatos: false,
	abrirModalDistribucion: false,
	capitalSocial: [
	],
	distribucionSeleccionada:null,
	nuevoActivado:true,
	editarActivado:false,
	borrarActivado:false,
	tiposParticipacion:null,
	tipoCapital:null,
	cargandoCsv:true,
	datosCsv:[],
	datosImpresion:[],
	valorTituloValido:true,
	cargando: false,
	imprimirActivado:false,
	modalImprimir:false,
	cargandoImprimir:false,
	personasFisicas:null,
	personasJuridicas: null,
	loading : false,
	cargandoGrid:false
  };

  obtenerPotencias=(n)=>{

	let array = [];
	let binaryRepresentation = parseInt(n, 10).toString(2);
	binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
	for(let i = binaryRepresentation.length - 1; i >= 0; i--){
		if(parseInt(binaryRepresentation[i]) === 1){
			array[Math.pow(2, i)]=true
		}

	}

	return array
  }

 
//   cargarCapitalInicial=async()=>{
// 	let capital=
// 	await this.props.client.query({
// 		query:gql`{capSocialInicialDistribucion{
// 		  ID_DISTRIBUCION,
// 		  ID_PERSONA,
// 		  PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
// 		  TIPO_TITULO,
// 		  NUMERO_TITULOS,
// 		  NUMERACION_TITULOS
// 		}}`,fetchPolicy:'network-only'
// 	  }).then(async(result)=>{
// 		  return result.data.capSocialInicialDistribucion
		  
// 	  })
// 	  return capital
//   }
cargarPersonasFisicas= async() =>{
	let personasFisicas=await this.props.client
	  .query({
		  query: gql` 
			  {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		  `,fetchPolicy:'network-only'
	  }).then(result=>{ 
		  return result.data.personasFisicas
	  })
	  return personasFisicas	  
}
cargarPersonasJuridicas= async() =>{
	let personasJuridicas=await this.props.client
	  .query({
		  query: gql`
			  {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		  `,fetchPolicy:'network-only'
	  }).then(result=>{ 
		  return result.data.personasJuridicas
	  })
	  return personasJuridicas	  
}
  cargarDatos=async()=>{
	  let personasFisicas= await this.cargarPersonasFisicas()
	  let personasJuridicas= await this.cargarPersonasJuridicas()
	//   let capitalInicial= await this.cargarCapitalInicial()
	  this.setState({
		  personasFisicas:personasFisicas,
		  personasJuridicas:personasJuridicas,
		//   capitalSocInicial: capitalInicial
	  })
  }
  cargarDatosCsv=async()=>{

	this.setState({cargandoCsv:true})
	let datos=[]
	
	let capital=this.props.capSocialInicialDistribucion
	let index=1
	for(let cap of capital){
	  //buscamos el nombre de la persona
	  let nombre=""
	  if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
		this.state.personasFisicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){
			  cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
			  cap.GRAVAMENES=persona.GRAVAMENES
			  nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
  
			}
		})

		

	  //Si es persona juridica
	  }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
		this.state.personasJuridicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){
				cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
				cap.GRAVAMENES=persona.GRAVAMENES
				cap.nombre= persona.RAZON_SOCIAL
			}
		})

		

		}
		cap.nombre=nombre
		let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
		let tipos=""

		tiposKeys.forEach(t=>{

			if(this.state.tiposParticipacion){

				let tipoSel=null
				if(this.state.tipoCapital===1){
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
				}else{
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
				}
				if(tipos===""){
				tipos+=tipoSel.DESCRIPCION
				}else{

				tipos+=","+tipoSel.DESCRIPCION

				}

			}

		})
		cap.tipo=tipos

		datos.push({
		  "Nº":index,
		  "Socio":nombre,
		  "Tipo de titulo":tipos,
		  "Número de titulos":cap.NUMERO_TITULOS,
		  "%":parseFloat((cap.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2),
		  "Numeración":cap.NUMERACION_TITULOS.replace("-"," a "),
		  "Gravamenes":cap.GRAVAMENES
		})


		index++;
	}
	this.setState({datosCsv:datos,datosImpresion:capital,cargandoCsv:false})

  }
  cargarCapital=async()=>{

	let capital=await this.props.client
	.query({
	  query:gql`
	  	{capSocialInicialDistribucion{
		ID_DISTRIBUCION,
		ID_PERSONA,
		PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		TIPO_TITULO,
		NUMERO_TITULOS,
		NUMERACION_TITULOS}}
	  `,
	  fetchPolicy:'network-only'
	}).then(async(result)=>{
		//console.log(result.data.capSocialInicialDistribucion)
		return result.data.capSocialInicialDistribucion
	})

	for(let cap of capital){
	  //buscamos el nombre de la persona
	
	  if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
		this.state.personasFisicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){				
				cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:''
				cap.GRAVAMENES=persona.GRAVAMENES
				cap.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
			}
		})

	

	  //Si es persona juridica
	  }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
		this.state.personasJuridicas.find(persona=>{
			if(persona.ID_PERSONA===cap.ID_PERSONA){
				cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
				cap.GRAVAMENES=persona.GRAVAMENES
				cap.nombre= persona.RAZON_SOCIAL
			}
		})


		

		}
	
		let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
		let tipos=""

		
		tiposKeys.forEach(t=>{
				
			 let tipoSel=null
			if(this.state.tipoCapital===1){
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
			}else{
				tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
			}
			if(tipos===""){
				tipos+=tipoSel.DESCRIPCION
			}else{

				tipos+=","+tipoSel.DESCRIPCION

			}			

		})
		cap.tipo=tipos


	}

	this.setState({capitalSocial:capital})
	this.cargarDatosCsv()

  }

  cargarNumTitulos=async()=>{

	//obtencion de los titulos del capital social.
	let numTitulos = 0;
	  let capitalInicial=this.props.capSocialInicialDistribucion
	  capitalInicial.forEach(socio => {
		numTitulos += socio.NUMERO_TITULOS;
	  });
	  this.setState({ numTitulos: numTitulos });


  }
  tipoCapital= async()=>{
	let personas= this.props.empresaPrincipal
	// await this.props.client
	//   .query({
	// 	  query: gql`
	// 	  {
	// 		  personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
	// 	  }
	// 	  `,
	// 	  fetchPolicy:'network-only'
	//   }).then(result=>{ 
	// 		return result.data.personasJuridicas  
	//   })
		//   let tiposAccion= await this.props.client
		//   .query({
		// 	query:gql`{tiposAccion{
		// 		ID_TIPO_ACCION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`
		//   }).then(async(result)=>{
		// 	  return result.data.tiposAccion
		//   })
		//  let tiposParticipacion= await this.props.client
		//   .query({
		// 	query:gql`{tiposParticipacion{
		// 		ID_TIPO_PARTICIPACION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`,fetchPolicy:'network-only'
		//   }).then(async(result)=>{
		// 	  return result.data.tiposParticipacion
		//   })
		
		 for(let persona of personas){
			if(persona.ID_TIPO_CAPITAL_SOCIAL===1){
				this.setState({
					tiposParticipacion:this.props.tiposAccion
				})
			}else{
				this.setState({
					tiposParticipacion:this.props.tiposParticipacion
				})
			}
			this.setState({tipoCapital: persona.ID_TIPO_CAPITAL_SOCIAL})
		}
  }
   cargarcapIncial= async ()=>{
	    //CAP SOCIAL INICIAL
	//   await this.props.client
	//   .query({
	// 	query: gql`
	// 	  {
	// 		capSocialInicial {
	// 		  FECHA_VALIDEZ
	// 		  VALOR_TITULO
	// 		}
	// 	  }
	// 	`,fetchPolicy:'network-only'
	//   })
	//   .then(result => {
		let capitalInicial= this.props.capSocialInicial
		//si tenemos capita social inicial.
		if (capitalInicial.length > 0) {
			capitalInicial.forEach(capital=>{
				this.setState({
					existeCapitaSocial: true,
					valorTitulo:Intl.NumberFormat('es-ES').format(capital.VALOR_TITULO),
					fechaSeleccionada: capital.FECHA_VALIDEZ
				  });
			})
		 
		}
   }
   generarImprimir=async()=>{
	this.setState({
		cargandoImprimir:true,
		modalImprimir:true,
		distribucionSeleccionada:null,
	cargandoGrid:true}, async ()=>{
			this.setState({cargandoImprimir:false}, () =>{
				ReactDOM.render(this.ImprimirCapInit(), document.getElementById('generarImprimirCI-pdf'))
			})
		})
	}
	ImprimirCapInit=()=>(
		<PDFViewer style={{width: '100%', height: '100%'}}>
			<Document>
			<Page size="A4" orientation="landscape" style={styles.page}>
				<View style={styles.body}>
					<View fixed style={styles.row}>
					<Image
						style={styles.imageHeadL}
						src={this.props.logotipo?this.props.logotipo:pantallaInicio}
						/>
					</View>
					<View fixed style={styles.rowHead}>
						<Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
						<Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
					</View>
					<View fixed style={styles.rowHeadTwo}>
						<Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
						<Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
					</View>
					<View style={styles.rowTitleOne}>
						<Text style={styles.labelTitle}>{this.props.i18next.t("estructurasocietaria.capital",{ns:"page"}).toUpperCase()}</Text>
					</View>
						{this.state.datosImpresion?this.state.datosImpresion.map((dato, index)=>{ 
							
							return(
								<View key={index}>
								{index ===0 && (
								<View style={styles.colHead}>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("estructurasocietaria.socio",{ns:"page"}).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("estructurasocietaria.nacionalidad",{ns:"page"}).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{this.props.i18next.t("estructurasocietaria.tipo",{ns:"page"}).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("estructurasocietaria.nrotitulos",{ns:"page"}).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("estructurasocietaria.porcentaje",{ns:"page"}).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("estructurasocietaria.numeracion",{ns:"page"}).toUpperCase()}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'10%'}}>{this.props.i18next.t("estructurasocietaria.gravamenes",{ns:"page"}).toUpperCase()}</Text>
																   
								</View>
								)}
									<View style={styles.colHead}>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.nombre?dato.nombre:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NACIONALIDAD?dato.NACIONALIDAD:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{dato.tipo?dato.tipo:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.NUMERO_TITULOS?dato.NUMERO_TITULOS:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{parseFloat((dato.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2)}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NUMERACION_TITULOS?dato.NUMERACION_TITULOS:''}</Text>
										<Text style={{textAlign:'center',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'10%'}}>{dato.GRAVAMENES?dato.GRAVAMENES:''}</Text>
									</View>                               
								
							</View>
							)
						}):<></>}      
				</View>
	
				<Text
					style={styles.footerPage}
					render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
					fixed
				/>
				<Image
						style={styles.icono}
						src={iconoFooter}
						fixed
						/>
				<Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
			</Page>
			</Document>
		 </PDFViewer>
		)

  componentDidMount=async()=>{
	 this.setState({cargando: true}, async()=>{
		this._isMounted = true;
		 await this.tipoCapital()
		 await this.cargarDatos()		 
		 await this.cargarNumTitulos()
		 await this.cargarCapital()
		
		 //si el componente ha terminado de montarse.
	if (this._isMounted) {
		await this.cargarcapIncial()
		
	}
	this.setState({cargando:false})	 
  })
}




   render() {
	const capitalInicial=this.props.capSocialInicialDistribucion
		  
	function porcFormatter(cell, row) {
		let numTitulos=0;
		capitalInicial.forEach(socio => {
			numTitulos += socio.NUMERO_TITULOS;
		  });
		let porc = parseFloat((row.NUMERO_TITULOS/numTitulos)*100).toFixed(2)
		return (
			porc
		);
	}

	function dataFormatter(cell, row) {
		return (
			cell? cell : ""
		);
	}
		const columns = [
		{
		dataField: 'nombre',
		text: this.props.i18next.t("estructurasocietaria.socio",{ns:"page"})
		},{
			dataField: 'NACIONALIDAD',
			text: this.props.i18next.t("estructurasocietaria.nacionalidad",{ns:"page"})
			},{
				dataField: 'tipo',
				text: this.props.i18next.t("estructurasocietaria.tipo",{ns:"page"})
				},{
					dataField: 'NUMERO_TITULOS',
					text: this.props.i18next.t("estructurasocietaria.nrotitulos",{ns:"page"})
					},{
						dataField: '',
						text: this.props.i18next.t("estructurasocietaria.porcentaje",{ns:"page"}),
						formatter: porcFormatter
						},{
							dataField: 'NUMERACION_TITULOS',
							text: this.props.i18next.t("estructurasocietaria.numeracion",{ns:"page"})
							},{
								dataField: 'GRAVAMENES',
								text: this.props.i18next.t("estructurasocietaria.gravamenes",{ns:"page"}),
								formatter: dataFormatter
								}
		];
		  

	document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("estructurasocietaria.capital",{ns:"page"})

	const cerrarModalImprimir=()=>{
		this.setState({
			modalImprimir:false,
			cargandoGrid:false
		})
	}
	const formatearMiles=(numero)=>{
		return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
	}
	
	const cambiarValorTitulo = ev => {

		  //solo permitir en el input numeros,comas y puntos.
		  let pattern=/^[0-9,.]*$/
		  if(pattern.test(ev.currentTarget.value)===false)return

		  //comprobacion que sea un número válido
		  let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
		  if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
			  this.setState({valorTituloValido:true})
		  }else{
			  this.setState({valorTituloValido:false})
		  }

		  this.setState({valorTitulo:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
	};

	const cerrarModal = () => {
	  this.setState({
		abrirModalGuardarDatos: false,
		abrirModalDistribucion: false,
		modalBorrarAbierto:false,
		cargandoGrid:false
	  });
	};

	const escogeFecha = nuevaFecha => {
	  this.setState({
		fechaSeleccionada: moment(nuevaFecha).format("YYYY-MM-DD")
	  });
	};
	const abrirModalGuardar = rowIndex => {

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
			return
		}

		if(this.state.valorTitulo==="0"){
			alert(this.props.i18next.t("estructurasocietaria.valid1",{ns:"page"}))
			return
		}

		this.setState({ abrirModalGuardarDatos: true });
	};

	const abrirModalDistribucion =() => {
		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
			return
		}

	  if(!this.state.existeCapitaSocial){
		alert(this.props.i18next.t("estructurasocietaria.valid2",{ns:"page"}))
		return
	  }

	  this.setState({ 
		distribucionSeleccionada:null,
		nuevoActivado:true,
		editarActivado:false,
		borrarActivado:false,
		abrirModalDistribucion: true,
		cargandoGrid:true
	  });
	};

	const abrirModalDistribucionEditar=()=>{

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
			return
		}

	  if(!this.state.distribucionSeleccionada){
		alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
		return
	  }

	  this.setState({ 
		abrirModalDistribucion: true,
		nuevoActivado:false,
		editarActivado:true,
		borrarActivado:false,
		cargandoGrid:true
	  });

	}

	const getMomentFormatter = (format) => {
	  // note that locale argument comes from locale prop and may be undefined
	  return {
		formatDate: (date, locale) =>
		  moment(date)
			.locale(locale)
			.format(format),
		parseDate: (str, locale) =>
		  moment(str, format)
			.locale(locale)
			.toDate(),
		placeholder: format
	  };
	};

	const guardarCambiosCapitalSocial = () => {


	  let query = "";
	  if(!this.state.valorTituloValido)return

	  //si no existe el capital social lo creamos
	  if (!this.state.existeCapitaSocial) {
		query = gql`mutation{crearCapSocialInicial(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  } else {
		// de lo contrario, lo actualizariamos
		query = gql`mutation{actualizarCapSocialInicial(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  }

	  //guardar el capital social.
	  this.props.client.mutate({
		mutation: query
	  });

	  alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
	  
	  this.setState({existeCapitaSocial:true})

	  cerrarModal();
	};

	const aceptar = async () => {
		this.setState({ loading : true },async () => {
      if(!this.nuevoTitulo.state.socioSeleccionado) {
        alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
			  this.setState({loading : false})
        return
      } 

      let tipoTitulo=0
      for (let key in this.nuevoTitulo.state.activadoCheckbox) {
        //damos de alta los tipos de participaciones
        tipoTitulo+=parseInt(key.replace("\"",""))
      }

      let existe=false;
	  if(this.state.nuevoActivado){

		//comprobar que no exista un socio con ese tipo de titulo.
		// let existe=await this.props.client
		// .query({
		//   query: gql`
		// 	{
		// 	  capSocialInicialDistribucion (CAMPOS:"ID_PERSONA,NUMERO_TITULOS",FILTROS:{ID_PERSONA:${this.nuevoTitulo.state.socioSeleccionado.value},TIPO_TITULO:${tipoTitulo}}){
		// 		ID_PERSONA
		// 		NUMERO_TITULOS
		// 		PERSONA {
		// 		  TIPO_PERSONA {
		// 			ID_TIPO_PERSONA
		// 		  }
		// 		}
		// 	  }
		// 	}
		//   `,fetchPolicy:'network-only'
		// })
		// .then(result => {
		//   if(result.data.capSocialInicialDistribucion.length>0)return true;
		//   return false;
		// });
		this.props.capSocialInicialDistribucion.find(capital=>{
			if(capital.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && capital.TIPO_TITULO===tipoTitulo){
				existe=true
			}
		})
		// if(this.state.capitalSocInicial.length>0){
		// 	existe=true
		// }
		if(existe===true){
			alert(this.props.i18next.t("yaexiste",{ns:"global"}))
			this.setState({loading : false})
		  return
		}

		await this.nuevoTitulo.aceptarDistribución()
		//si se ha creado el titulo
		if(this.nuevoTitulo.state.tituloCreado){
		  await this.cargarCapital()
		  await this.cargarNumTitulos()

		  await this.setState({
			  loading : false

		  })

		  cerrarModal()

		} else {
      this.setState({loading : false})
    } 
	  }else{

		//comprobar que no exista un socio con ese tipo de titulo.
		let existe=this.state.capitalSocial.find(cap=>{ return cap.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && cap.TIPO_TITULO===tipoTitulo && cap.ID_DISTRIBUCION!==this.state.distribucionSeleccionada.ID_DISTRIBUCION})
		if(existe){
			alert(this.props.i18next.t("yaexiste",{ns:"global"}))
			this.setState({loading : false})
		  return
		}

		await this.nuevoTitulo.aceptarDistribución()
		//si se ha creado el titulo
		if(this.nuevoTitulo.state.tituloActualizado){
		  await this.cargarCapital()
		  await this.cargarNumTitulos()

		  this.setState({
			  loading: false
		  })

		  cerrarModal()
    } else {
      this.setState({loading : false})
    }
		  
		}

    })

	};

	const handleOnSelect=(row, isSelect) => {
		if (isSelect) {
			this.setState({
				distribucionSeleccionada: row
			});
		}
		return true;
	}
	
	const eliminarDistribucion=async()=>{
	
	  //obtener variaciones distribucion
	  let vardis= await this.props.client.query({
		query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}}){ID_VARIACION}}`,fetchPolicy:'network-only'
	  }).then(result=>{
		  ////console.log(result)
		  return result.data.capSocialVariacionesDistribucion
	  })

	  vardis.forEach(async dis=>{
		//eliminar las variaciones distribucion
		await this.props.client.mutate({
		  mutation:gql`mutation{eliminarCapSocialVariacionesDistribucion(ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}){ID_VARIACION}}`
		})
		
		//si la variacion contiene 0 variaciones distribucion, tambien la eliminamos
		await this.props.client.query({
		  query:gql`{capSocialVariacionesDistribucion(CAMPOS:"ID_VARIACION",FILTROS:{ID_VARIACION:${dis.ID_VARIACION}}){ID_VARIACION}}`
		  ,fetchPolicy:'network-only'
		}).then(async result=>{

		  if(result.data.capSocialVariacionesDistribucion.length===0){
			await this.props.client.mutate({
				mutation:gql`mutation{eliminarCapSocialVariacion(ID_VARIACION:${dis.ID_VARIACION}){ID_VARIACION}}`
			}).catch(err=>{
				alert(this.props.i18next.t("error",{ns:"global"}))
				return
			})
		  }
		})
	  })
	  
	  //eliminamos la distribucion
	  await this.props.client.mutate({
		mutation:gql`mutation{eliminarCapSocialInicialDistribucion(ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}){ID_DISTRIBUCION}}`
	  })
	  .then(async result=>{

		await this.setState({
		  distribucionSeleccionada:null,
		})
		await this.cargarCapital()
		await this.cargarNumTitulos()
		
		alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))

		cerrarModal()
		
	  }).catch(err=>{
		alert(this.props.i18next.t("error",{ns:"global"}))
	  })

	  
	}

	return (
	  <>
		  	{this.state.cargando === true?
		  		<Cargando />
				  :
				  <>
					<Dialog 
						transitionDuration={400}
						autoFocus={true}
						enforceFocus={true}
						icon="print"
						onClose={cerrarModalImprimir}
						title={this.props.i18next.t("print",{ns:"global"})}
						canEscapeKeyClose={true}
						canOutsideClickClose={true}
						isCloseButtonShown={true}
						usePortal={true}
						isOpen={this.state.modalImprimir}
						style={styles.dialog}
					>
						{!this.state.cargandoImprimir?
								<div style={{ width: '100%', height: '600px'}} id="generarImprimirCI-pdf"></div>
								:
									<Spinner />
							}
					</Dialog>	
					<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title={this.props.i18next.t("estructurasocietaria.capital",{ns:"page"})}
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.abrirModalGuardarDatos}
					className="pixelAlrededor dialog-medio-ancho"

					>
					<div className={Classes.DIALOG_BODY}>
						<p>Datos del capital social inicial:</p>
						<br />
						<p>
						Fecha de validez{" "}
						{moment(this.state.fechaSeleccionada).format("DD-MM-YYYY")}
						</p>
						<p>Valor individual del titulo: {this.state.valorTitulo}</p>
						<br />
						<p>¿Guardar estos datos?</p>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Tooltip content="Guardar cambios." position="top">
							<AnchorButton
							intent={Intent.PRIMARY}
							onClick={guardarCambiosCapitalSocial}
							>
							Si
							</AnchorButton>
						</Tooltip>
						<Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
							<Button onClick={cerrarModal}>No</Button>
						</Tooltip>
						</div>
					</div>
					</Dialog>
					<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title={this.props.i18next.t("estructurasocietaria.titledistribucion",{ns:"page"})}
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
					isOpen={this.state.abrirModalDistribucion}
					ref={(input) => { this.dialogDistribucion = input; }}
					>
				        
						<div id="modal-nuevo-titulo" className={Classes.DIALOG_BODY}>
							<NuevoTituloSocios datosDistribucion={this.state.distribucionSeleccionada} ref={(input) => { this.nuevoTitulo = input; }} loading={this.state.loading} i18next={this.props.i18next}/>
						</div>

						<div className={Classes.DIALOG_FOOTER}>
							<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Tooltip
								content={this.props.i18next.t("aceptar",{ns:"global"})}
								position="top"
							>
								<AnchorButton
									id="guardar-titulos"
									intent={Intent.PRIMARY}
									onClick={aceptar}
								>
								{this.props.i18next.t("aceptar",{ns:"global"})}
								</AnchorButton>
							</Tooltip>
							<Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
								<Button onClick={cerrarModal}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
							</Tooltip>
							</div>
						</div>
						
					</Dialog>
					<Dialog transitionDuration={400}
						autoFocus={true}
						enforceFocus={true}
						icon="warning-sign"
						onClose={cerrarModal}
						title={this.props.i18next.t("delete",{ns:"global"})}
						canEscapeKeyClose={true}
						canOutsideClickClose={true}
						isCloseButtonShown={true}
						usePortal={true}
						isOpen={this.state.modalBorrarAbierto}
					>
							<div className={Classes.DIALOG_BODY}>
								<h2>{this.props.i18next.t("estructurasocietaria.questiondeldistribucion",{ns:"page"})}</h2>
							</div>
							<div className={Classes.DIALOG_FOOTER}>
							<div className={Classes.DIALOG_FOOTER_ACTIONS}>
								<Tooltip content={this.props.i18next.t("eliminar",{ns:"global"})} position="top">
									<AnchorButton
										intent={Intent.PRIMARY}
										onClick={eliminarDistribucion}
									>
										{this.props.i18next.t("yes",{ns:"global"})}
									</AnchorButton>
								</Tooltip>
								<Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
									<Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
								</Tooltip>
							</div>
						</div>
					</Dialog>
					<div className="tablaTreinta">
						<Card interactive={true} id="datos-capital-social-inicial" elevation={Elevation.TWO}>
							<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("estructurasocietaria.infocapital",{ns:"page"})}</Callout>
						<br />
						<FormGroup label={this.props.i18next.t("estructurasocietaria.fechavalidez",{ns:"page"})+":"} labelFor="informacion">
							<div id="input-fecha-validez">	
								<DateInput minDate={new Date(1900, 1 ,1)}
									{...getMomentFormatter("DD-MM-YYYY")}
									locale="es"
									onChange={escogeFecha}
									value={new Date(this.state.fechaSeleccionada)}
								/>
							</div>
						</FormGroup>
						<br />
						<FormGroup 
							label={this.props.i18next.t("estructurasocietaria.valortitulo",{ns:"page"})+":"} 
							labelFor="informacion"
							intent="danger"
							helperText={!this.state.valorTituloValido?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("estructurasocietaria.valortitulo",{ns:"page"}):""}
						>
						<InputGroup
							id="input-valor-titulo"
							name="Valor del titulo"
							value={this.state.valorTitulo}
							onChange={cambiarValorTitulo}
							type="text"
							intent={!this.state.valorTituloValido?"danger":"primary"}
						/>
						</FormGroup>
						<br />
						<FormGroup label={this.props.i18next.t("estructurasocietaria.numerotitulos",{ns:"page"})+":"} labelFor="informacion">
						<InputGroup
							id="input-total-titulo"
							name="Número de títulos"
							value={this.state.numTitulos}
							disabled
						/>
						</FormGroup>
						<br />
						<FormGroup
						label={this.props.i18next.t("estructurasocietaria.capitalsocialinicial",{ns:"page"})+":"}
						labelFor="informacion"
						>
						<InputGroup
							name="Capital social inicial (total)"
							id="input-total-cantidad"
							value={
								Intl.NumberFormat('es-ES').format(parseFloat(this.state.valorTitulo?this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.'):0).toFixed(2) * this.state.numTitulos) + " €"
							}
							disabled
						/>
						</FormGroup>
						<Button
						alignText="right"
						icon="floppy-disk"
						text={this.props.i18next.t("guardar",{ns:"global"})}
						onClick={abrirModalGuardar}
						className="bp3-intent-danger iconoDerecha bp3-minimal"
						/>
						<br />
						<br />
					</Card>
					</div>
					<div className="tablaSetenta">
					<Card interactive={true} elevation={Elevation.TWO}>
					<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("estructurasocietaria.titlecapital",{ns:"page"})}</Callout>
					{this.state.cargandoGrid?
                            <Cargando/>
                        :
                        <>
						<Navbar>
						<Navbar.Group>
							<NavbarHeading>
							{this.props.i18next.t("estructurasocietaria.detcapital",{ns:"page"})}
							</NavbarHeading>
						</Navbar.Group>
						</Navbar>
						<Navbar>
						<Navbar.Group>
							<ButtonGroup align={Alignment.LEFT}>
							<Button
								className="bp3-minimal"
								id="nuevo-capital-social"
								icon="add"
								text={this.props.i18next.t("new",{ns:"global"})}
								active={this.state.nuevoActivado}
								onClick={abrirModalDistribucion}
							/>
							<Button
								className="bp3-minimal"
								id="editar-capital-social"
								icon="edit"
								text={this.props.i18next.t("edit",{ns:"global"})}
								onClick={abrirModalDistribucionEditar}
								active={this.state.editarActivado}
							/>
							<Button id="boton-imprimir" className="iconoDerecha bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={()=>this.generarImprimir()}>{this.props.i18next.t("print",{ns:"global"})}</Button>
						
							{this.state.cargandoCsv===false?
								<Button
								className="bp3-minimal"
								icon="document"
								id="boton-exportar"
								>
								<CSVLink data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"Capital.csv"}>{this.props.i18next.t("exportar",{ns:"global"})}</CSVLink>
								</Button>
							:
								<Button
								className="bp3-minimal"
								icon="document"
								text={this.props.i18next.t("cargando",{ns:"global"})}
								/>
							}
							</ButtonGroup>
						</Navbar.Group>
						</Navbar>
						<div id="tabla-distribucion">
							<BootstrapTable
							keyField='ID_DISTRIBUCION'
							data={ this.state.capitalSocial }
							columns={ columns }
							selectRow={ const_table.selectRow(handleOnSelect) }
							pagination={ paginationFactory(const_table.options) }
							headerClasses="header-class"
							rowClasses="row-class"
							hover
							condensed
							noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
							/>
						</div>
						</>
   						}
					</Card>
					</div>
				</>
			}
	  </>
	);
  }
  componentWillUnmount(props) {
	this._isMounted = false;
  }
}

export default withApollo(CapitalSocialInicial)