const obtenerTipo=(nombreExtension)=>{

    let extension=""
    switch(nombreExtension){
        case ".pdf":
            extension="Documento PDF"
        break;
        case ".doc":
        case ".docx":
            extension="Documento de Microsoft Word"
        break;
        case ".xls":
        case ".xlsx":
            extension="Documento de Microsoft Excel"
        break;
        case ".txt":
            extension="Documento de Texto"
        break;
        case ".png":
        case ".jpg":
        case ".jpeg":
        case ".svg":
            extension="Imagen"
        break;
        default:
            extension="Otros"
        break;
    }
    return extension

}

export default {obtenerTipo};