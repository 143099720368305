import React, { Component } from "react";
import { Card, FormGroup, TextArea, Elevation, Button,  Dialog, Spinner } from '@blueprintjs/core';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import { Cargando } from "../Pantallas/Cargando"

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
      dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:135,
    paddingTop:30,
    paddingLeft: 25,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#000000',
    padding:2,
    
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '90%'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize: 11,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  labelTitleOne  : {
    flexDirection: 'column',
    fontSize:13,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
  
})
class SobreSociedad extends Component {

    state = {
        idFinanciera:null,
        existeFinanciera:false,
        modalImprir:false,
        imprimirActivado:false,
        cargandoImprimir:false,
        cargandoSS:false,
        desarrolloSostenible: null,
        subcontratacion: null,
        proveedores:null,
        consumidores: null,
        informacionFiscal: null,
    }

    componentDidMount=(props)=>{
        //si nos llegan datos...
        this.setState({
            cargandoSS: true,
        }, async ()=>{
            if(this.props.datos!==null){                        
                  await this.setState({
                    cargandoSS:false,
                    idFinanciera:this.props.datos.ID_FINANCIERA,
                    desarrolloSostenible: this.props.datos.DESARROLLO,
                    subcontratacion: this.props.datos.SUBCONTRATACION,
                    proveedores:this.props.datos.PROVEEDORES,
                    consumidores: this.props.datos.CONSUMIDORES,
                    informacionFiscal: this.props.datos.INFORMACION_FISCAL,
                    existeFinanciera:true
                })
    
            }

        })
       
    }
    generarInformeSS= async () =>{
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true
        }, async () =>{
            this.setState({cargandoImprimir:false},() => {
                ReactDOM.render(this.ImprimirSS(), document.getElementById('informeSS-pdf'))
            })
        })
        
    }
    ImprimirSS= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("informacionnofinanciera.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View fixed style={styles.rowTitleOne}>
                    <Text style={styles.labelTitleOne}>{this.props.i18next.t("informacionnofinanciera.sobresociedad",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.desarrollosostenible",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.desarrolloSostenible}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.subcontratacion",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.subcontratacion}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.proveedores",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.proveedores}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.consumidores",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.consumidores}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.informacionfisica",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.informacionFiscal}</Text>
                </View>
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )


    render() {
        const cerrarModalImprimir = () => {
            this.setState({modalImprimir:false})
        }

        const guardarCambios= async ()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            
            //si hay datos
            if(this.state.existeFinanciera){
                //actualizamos los datos
              await this.props.client
              .mutate({
                    mutation:gql`mutation{actualizarInformacionNoFinanciera(
                        ID_FINANCIERA:${this.state.idFinanciera},
                        VALORES:{
                            DESARROLLO:${this.state.desarrolloSostenible?'"'+this.state.desarrolloSostenible+'"':null},
                            SUBCONTRATACION:${this.state.subcontratacion?'"'+this.state.subcontratacion+'"':null},
                            PROVEEDORES:${this.state.proveedores?'"'+this.state.proveedores+'"':null},
                            CONSUMIDORES:${this.state.consumidores?'"'+this.state.consumidores+'"':null},
                            INFORMACION_FISCAL:${this.state.informacionFiscal?'"'+this.sociedad.state.informacionFiscal+'"':null}})
                            }){ID_FINANCIERA}}`
                        }).then(async result=>{
                  
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

            }else{
                //creamos un nuevo registro
                this.props.client.mutate({
                    mutation:gql`mutation{crearInformacionNoFinanciera(
                        VALORES:{
                            ID_FINANCIERA:${1}
                            DESARROLLO:${this.state.desarrolloSostenible?'"'+this.state.desarrolloSostenible+'"':null},
                            SUBCONTRATACION:${this.state.subcontratacion?'"'+this.state.subcontratacion+'"':null},
                            PROVEEDORES:${this.state.proveedores?'"'+this.state.proveedores+'"':null},
                            CONSUMIDORES:${this.state.consumidores?'"'+this.state.consumidores+'"':null},
                            INFORMACION_FISCAL:${this.state.informacionFiscal?'"'+this.sociedad.state.informacionFiscal+'"':null}})
                            }){ID_FINANCIERA}}`
                }).then(async result=>{
                  
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                    await this.setState({
                        existeFinanciera:true
                    })


                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })


            }

        }


        const handleCambiarDesarrolloSostenible = (event) => {
            let desarrolloSostenible = event.target.value
             desarrolloSostenible= desarrolloSostenible.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({desarrolloSostenible: desarrolloSostenible})
        }

        const handleCambiarSubcontratacion = (event) => {
            let subcontratacion = event.target.value
             subcontratacion= subcontratacion.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({subcontratacion: subcontratacion})
        }

        const handleCambiarProveedores = (event) => {
            let proveedores = event.target.value
             proveedores= proveedores.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({proveedores: proveedores})
        }

        const handlecambiarConsumidores = (event) => {
            let consumidores = event.target.value
             consumidores= consumidores.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({consumidores: consumidores})
        }

        const handleCambiarInformacionFiscal = (event) => {
            let informacionFiscal = event.target.value
             informacionFiscal= informacionFiscal.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({informacionFiscal: informacionFiscal})
        }

        return (
            <>
            {this.state.cargandoSS === true?
               <Cargando />
               :
               <>

               <Dialog 
                   transitionDuration={400}
                   autoFocus={true}
                   enforceFocus={true}
                   icon="print"
                   onClose={cerrarModalImprimir}
                   title={this.props.i18next.t("print",{ns:"global"})}
                   canEscapeKeyClose={true}
                   canOutsideClickClose={true}
                   isCloseButtonShown={true}
                   usePortal={true}
                   isOpen={this.state.modalImprimir}
                   style={styles.dialog}
               >
                   {!this.state.cargandoImprimir?
                           <div style={{ width: '100%', height: '600px'}} id="informeSS-pdf">
                           </div>
                           :
                               <Spinner />
                       }

               </Dialog>
            <div className={ "pixelAlrededor tablaSesenta" }>
                <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.actividades= input; }} >
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.desarrollosostenible",{ns:"page"})+":"}
                        labelFor="desarrollo-sostenible"
                    >
                        <TextArea id="desarrollo-sostenible" fill={true} onChange={handleCambiarDesarrolloSostenible} value={this.state.desarrolloSostenible?this.state.desarrolloSostenible:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.subcontratacion",{ns:"page"})+":"}
                        labelFor="subcontratacion"
                    >
                        <TextArea id="subcontratacion" fill={true} onChange={handleCambiarSubcontratacion} value={this.state.subcontratacion?this.state.subcontratacion:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.proveedores",{ns:"page"})+":"}
                        labelFor="proveedores"
                    >
                        <TextArea id="proveedores" fill={true} onChange={handleCambiarProveedores} value={this.state.proveedores?this.state.proveedores:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.consumidores",{ns:"page"})+":"}
                        labelFor="consumidores"
                    >
                        <TextArea id="consumidores" fill={true} onChange={handlecambiarConsumidores} value={this.state.consumidores?this.state.consumidores:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.informacionfisica",{ns:"page"})+":"}
                        labelFor="informacion-fiscal"
                    >
                        <TextArea id="informacion-fiscal" fill={true} onChange={handleCambiarInformacionFiscal} value={this.state.informacionFiscal?this.state.informacionFiscal:''} />
                    </FormGroup>
                    <Button className="iconoDerecha bp3-intent-danger" icon="print" onClick={()=>this.generarInformeSS()}>{this.props.i18next.t("print",{ns:"global"})}</Button>
                    <Button className="iconoDerecha bp3-intent-danger" icon="floppy-disk" onClick={guardarCambios}>{this.props.i18next.t("guardar",{ns:"global"})}</Button>
                    
                    <br/>
                    <br/>
                </Card>
            </div>
        </>
    }
    </>
        )
    }
}

export default withApollo(SobreSociedad)