import React, {Component} from 'react';
import {
    Navbar,
    ButtonGroup,
    Button,
    Alignment,
    Dialog,
    Spinner,
    Card,
    Elevation,
    FormGroup,
    TextArea,
    Classes,
    AnchorButton,
    NavbarDivider,
    Intent,
    Tooltip,
    FileInput,
    InputGroup
} from "@blueprintjs/core";
import axios from 'axios';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import {withApollo} from 'react-apollo';
import { Cargando } from "../Cargando";
import gql from "graphql-tag";
import iconoFooter from '../../../Assets/lc.png';
import moment from 'moment';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import styles from '../stylesPrint';
import i18next from 'i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';




class ContenidoProcedimientos extends Component {
    state = {
        cargando: true,
        cargandoImprimir:false,
        columnWidths:[100],
        resized:false,
        columnWidthsAdjuntos:[400,400],
        modal:false,
        modalRiesgoAbierto:false,
        modalAdjuntosAbierto:false,
        modalImprimir: false,
        modalBorrarAbierto: false,
        nombre:'',
        archivo:null,
        nuevoActivado:false,
        datoSeleccionado:null,
        adjuntoSeleccionado:null,    
        datosProcedimientos:[],
        bloque:'',
        titulo:'',
        descripcionRiesgo:'',
        archivosAdjuntos:[],
        textoVerAdjuntos: i18next.t("procedimientosCompliance.viewAttachments", { ns: "page2" })
    }

    async componentDidMount() {
        await this.cargarDatosProcedimientos();
        this.setState({
            cargando:false
        })
    }

    resizeColumns() {
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        if (this.tabla) {
            for (let index = 0; index < this.tabla.locator.grid.numCols; index++) {
                totalMinSizeReq = totalMinSizeReq + this.tabla.locator.getWidestVisibleCellInColumn(index);
                cumulativeColumnWidths.push(this.tabla.locator.getWidestVisibleCellInColumn(index));
            }
            if (totalMinSizeReq > containerSize) {
                this.setState({
                    columnWidths: cumulativeColumnWidths,
                    resized: true
                });
            } else {
                let diff = ((containerSize - totalMinSizeReq) / this.tabla.locator.grid.numCols);
                this.setState({
                    columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                    resized: true
                });
            }
        }
        
    }

    generarImprimir= async() => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
            })
        })
    }

    Imprimir=()=>{
        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.tipoProcedimiento.toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.tipoProcedimiento.toUpperCase()}</Text>
                </View>
                {this.state.datosProcedimientos?this.state.datosProcedimientos.map((dato, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("risk", { ns: "global" })}</Text>                                                                
                                    </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.LETRA:''}{dato.ID_RIESGO?dato.ID_RIESGO:''} - {dato.TITULO?dato.TITULO:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page", { ns: "global" })} ${pageNumber} ${i18next.t("de", { ns: "global" })} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    cargarAdjuntos() {
        this.props.client
       .query({
         query: gql`
           {bloquesDatos(FILTROS:{ID_BLOQUE:${this.props.bloqueSeleccionado.ID_BLOQUE},ID_RIESGO:${this.state.datoSeleccionado.ID_RIESGO}}){
            ID,
            ID_RIESGO,
            ID_BLOQUE,
            ID_ADJUNTO,
            ADJUNTO{DATOS,NOMBRE}}}
         `,
         fetchPolicy:'network-only'
       }).then(async (result) => {
           this.setState({archivosAdjuntos:result.data.bloquesDatos,
               textoVerAdjuntos:`${i18next.t("procedimientosCompliance.view", { ns: "page2" })} ${(result.data.bloquesDatos.length > 0 ? result.data.bloquesDatos.length : '')} ${i18next.t("procedimientosCompliance.attachments", { ns: "page2" })}`
           });
       })
    }

    cargarDatosProcedimientos() {
        this.props.client
       .query({
         query: gql`
           {complianceRiesgos(FILTROS:{ID_BLOQUE:${this.props.bloqueSeleccionado.ID_BLOQUE},TIPO:"${this.props.tipoProcedimiento}"}){
            ID_RIESGO,
            ID_BLOQUE,
            TIPO,
            DESCRIPCION,
            CODIGO_RIESGO,
            TITULO}}
         `,
         fetchPolicy:'network-only'
       }).then(async (result) => {
           this.setState({datosProcedimientos:result.data.complianceRiesgos});
       })
    }

    render() {
        const guardar = () => {
            if(!this.props.bloqueSeleccionado)return;

            if (this.state.archivo) {
            const api = axios.create({
                withCredentials: true
            });
            var formData = new FormData();
            formData.append("files",this.state.archivo);
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:29,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque datos"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
            
                //damos de alta el contexto
                this.props.client.mutate({
                    mutation:gql`mutation{crearBloquesDatos(
                    VALORES:{
                            ID_RIESGO:${this.state.datoSeleccionado.ID_RIESGO}
                            ID_BLOQUE:${this.props.bloqueSeleccionado.ID_BLOQUE}
                            ID_ADJUNTO:${response.data.data.crearAdjunto.ID_ADJUNTO}
                    } 
                    ){ID}}`
                }).then(async result=>{
                
                    alert(i18next.t("procedimientosCompliance.fileCreated", { ns: "page2" }));
                    cerrarModalAdjuntos();
                    await this.cargarAdjuntos();

                    this.setState({
                        adjuntoSeleccionado:null
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error", { ns: "global" }))
                })
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error", { ns: "global" }))
            })
            } else {
                alert(i18next.t("procedimientosCompliance.uploadFile", { ns: "page2" }));
                return
            }

        }

        const actualizar=()=>{

            if(!this.props.bloqueSeleccionado)return
            
            if (this.state.archivo) {
                const api = axios.create({
                    withCredentials: true
                });
                var formData = new FormData();
                formData.append("files",this.state.archivo);
                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:29,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque datos"}){ID_ADJUNTO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then(async(response)=> {
                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarBloquesDatos(
                            ID:${this.state.adjuntoSeleccionado.ID},
                            VALORES:{
                                ID_ADJUNTO:${response.data.data.crearAdjunto.ID_ADJUNTO}
                        } 
                        ){ID}}`
                    }).then(async result=>{
                    
                        alert(i18next.t("procedimientosCompliance.fileUpdated", { ns: "page2" }))
                        cerrarModalAdjuntos()
                        await this.cargarAdjuntos()

                        
                        this.setState({
                            adjuntoSeleccionado:null
                        })
                        
                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            } else {
                alert(i18next.t("procedimientosCompliance.uploadFile", { ns: "page2" }));
                return
            }


        }

        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({
                archivo:ev.target.files[0],
                tipoArchivo:ev.target.files[0].type,
                nombre:ev.target.files[0].name.replace("."+extension,"")
            })
          
        }

        const cerrarModal = () => {
            this.setState({
                modalRiesgoAbierto:false,
                modalAdjuntosAbierto:false
            });
        }

        const cerrarModalAdjuntos= () => {
            this.setState({
                modal:false,
            });
        }

        const cerrarModalBorrar= () => {
            this.setState({
                modalBorrarAbierto: false,
            });
        }

        const cerrarModalImprimir = () => {
            this.setState({
                modalImprimir:false
            });
        }

        const abrirModalAdjuntos = () => {

            this.setState({
               modalAdjuntosAbierto:true,
            });
        }

        const abrirModal = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({
                archivo: null,
                nombre: '',
                modal:true,
                nuevoActivado:true                
            });
        }

        const descargarArchivo = () => {
            if (this.state.adjuntoSeleccionado.ADJUNTO) {
                let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ADJUNTO.DATOS.replace(".",""), '_blank')
                win.focus()
            } else {
                alert(i18next.t("procedimientosCompliance.noAttachment", { ns: "page2" }));
            }
        }

        const abrirModalBorrar=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("procedimientosCompliance.selectFile", { ns: "page2" }));
                return;
            }
            
            this.setState({
                modalBorrarAbierto:true
            })
        }

        const borrar = () => {
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("procedimientosCompliance.selectFile", { ns: "page2" }))
                return;
            }

            this.props.client.mutate({
                mutation:gql`mutation{eliminarBloquesDatos(
                    ID:${this.state.adjuntoSeleccionado.ID},
                ){ID}}`
            }).then(async result=>{
            
                alert(i18next.t("deletecorrecto", { ns: "global" }))
                cerrarModalBorrar()
                await this.cargarAdjuntos()

                this.setState({
                    adjuntoSeleccionado:null
                })
                
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })
            
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return;
            }

            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("procedimientosCompliance.selectFile", { ns: "page2" }));
                return;
            }

            this.setState({
                nuevoActivado:false,
                modal: true,
                archivo: null,
                nombre: '',
            })

        }

        const abrirModalVer = () => {

            
            if(!this.state.datoSeleccionado){
                alert(i18next.t("procedimientosCompliance.selectData", { ns: "page2" }))
            }

            this.setState({
                modalRiesgoAbierto: true,
                bloque:"BLOQUE "+this.props.bloqueSeleccionado.LETRA+" - "+this.props.bloqueSeleccionado.TITULO,
                titulo: this.state.datoSeleccionado.TITULO,
                descripcionRiesgo: this.state.datoSeleccionado.DESCRIPCION
            })

        }
        
        const riesgoFormato = (cell, row) => {
          let riesgo =`${this.props.bloqueSeleccionado.LETRA}${cell} - ${row.TITULO}`
            return (
                <>
                    <span>{riesgo}</span>
                </>
            );
        }

        const nombreFormato = (cell, row) => {
            return (
                <>
                    <span>{cell}</span>
                </>
            );
        }

        const archivoFormato = (cell, row) => {
            let file = cell.replace(".","")
              return (
                  <>
                      <span>{file}</span>
                  </>
              );
          }
        const columns = [
            {
                dataField: 'ID_RIESGO',
                text: i18next.t("risk", { ns: "global" }),
                formatter: riesgoFormato

            }
        ];

        const columnsAttachmentFileTable = [
            {
                dataField: 'ADJUNTO.NOMBRE',
                text: i18next.t("name", { ns: "global" }),
                formatter : nombreFormato
            },
            {
                dataField: 'ADJUNTO.DATOS',
                text: i18next.t("procedimientosCompliance.file", { ns: "page2" }),
                formatter : archivoFormato
            }
        ];


        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    datoSeleccionado: row,
                    textoVerAdjuntos: 'Ver adjuntos'
                }, async () => {
                    await this.cargarAdjuntos();
                })
                
            }
            return true; // return true or dont return to approve current select action
        }
        const handleOnSelectAttachmentFile=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    adjuntoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowAttachmentFileTable = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectAttachmentFile,
            style: { backgroundColor: '#c8e6c9' }
        };

        return (
            <>
                {this.state.cargando?
                    <Cargando/>
                :
                    <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="eye-open" text={i18next.t("procedimientosCompliance.view", { ns: "page2" })} disabled={this.state.datoSeleccionado?false:true} onClick={abrirModalVer}/>
                                    <NavbarDivider />
                                    <Button key="botonImprimir" className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" })}  onClick={()=>this.generarImprimir()}/>
                                    <NavbarDivider />
                                    {this.state.archivosAdjuntos.length>0?
                                        <Button className="bp3-minimal" style={{fontWeight:'bold'}} text={this.state.textoVerAdjuntos} icon="document-open"  disabled={this.state.datoSeleccionado?false:true} onClick={abrirModalAdjuntos}/>
                                    :
                                        <Button className="bp3-minimal" icon="document-open" text={`${i18next.t("procedimientosCompliance.view", { ns: "page2" })} 0 ${i18next.t("procedimientosCompliance.attachments", { ns: "page2" })}`} disabled={this.state.datoSeleccionado?false:true} onClick={abrirModalAdjuntos}/>
                                    }
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <div style={{ width: '100%', minHeight: '100px', maxHeight: '500px', overflowY: 'scroll', padding: '10px 10px 10px 10px',backgroundColor:'lightgray'}} >
                        <div style={{ width: '100%', minHeight: '100px', maxHeight: '500px', overflowY: 'scroll', padding: '10px 10px 10px 10px',backgroundColor:'white'}} >
                        <BootstrapTable
                            keyField="ID_RIESGO"
                            data={this.state.datosProcedimientos}
                            columns={columns}
                            selectRow={selectRow}
                            pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </div>
                        </div>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title={i18next.t("procedimientosCompliance.riskProperties", { ns: "page2" })}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            isOpen={this.state.modalRiesgoAbierto}
                            className="dialog-ancho dialog-scroll "
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <div id="dialogo-riesgos" className="bp3-card separarBordes">
                                    <FormGroup
                                        label={`${i18next.t("procedimientosCompliance.block", { ns: "page2" })}:`}
                                        labelFor="bloque"
                                    >
                                        <InputGroup fill={true} defaultValue={this.state.bloque?this.state.bloque:""} readOnly={true}/>
                                    </FormGroup>
                                    <FormGroup
                                        label={`${i18next.t("procedimientosCompliance.title", { ns: "page2" })}:`}
                                        labelFor="titulo"
                                    >
                                        <InputGroup fill={true} defaultValue={this.state.titulo?this.state.titulo:""} readOnly={true}/>
                                    </FormGroup>
                                    <FormGroup
                                        label={`${i18next.t("description", { ns: "global" })}:`}
                                        labelFor="descripcion"
                                    >
                                        <TextArea fill={true} readOnly={true} className="textareaEdicion" defaultValue={this.state.descripcionRiesgo?this.state.descripcionRiesgo:""} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title={i18next.t("procedimientosCompliance.riskAttachments", { ns: "page2" })}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            isOpen={this.state.modalAdjuntosAbierto}
                            className="dialog-ancho dialog-scroll "
                        >
                            <div className={Classes.DIALOG_BODY}>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })}  onClick={abrirModal} />
                                        <Button className="bp3-minimal" icon="edit" disabled={this.state.adjuntoSeleccionado?false:true} text={i18next.t("edit", { ns: "global" })} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="delete" disabled={this.state.adjuntoSeleccionado?false:true} text={i18next.t("delete", { ns: "global" })} onClick={abrirModalBorrar} />
                                        <NavbarDivider />
                                        <Button className="bp3-minimal" icon="document-open" text={i18next.t("procedimientosCompliance.viewDocument", { ns: "page2" })} disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}/>
                                    
                                    </ButtonGroup>
                                </Navbar.Group>
                                </Navbar>
                                <BootstrapTable
                                    keyField="ID"
                                    data={this.state.archivosAdjuntos}
                                    columns={columnsAttachmentFileTable}
                                    selectRow={selectRowAttachmentFileTable}
                                    pagination={ paginationFactory(const_table.generateOptions(4,0,10)) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            	/>
                            <br />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("cerrar", { ns: "global" })}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModalAdjuntos}
                            title={`${i18next.t("create", { ns: "global" })} / ${i18next.t("edit", { ns: "global" })}`}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modal}
                            className="dialog-ancho"
                        >
                        <div>
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <FormGroup
                                label={i18next.t("procedimientosCompliance.attachedFile", { ns: "page2" })}
                                labelFor="files"
                                intent="danger"
                                helperText={!this.state.nuevoActivado?"":`${i18next.t("procedimientosCompliance.select", { ns: "page2" })} ${i18next.t("procedimientosCompliance.attachedFile", { ns: "page2" }).toLocaleLowerCase()}`}
                            >
                                <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                            </FormGroup>
                        </Card>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <AnchorButton intent={Intent.PRIMARY} onClick={this.state.nuevoActivado?guardar:actualizar}>
                                {i18next.t("guardar", { ns: "global" })}
                            </AnchorButton>
                            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModalAdjuntos}>
                                {i18next.t("close", { ns: "global" })}
                            </AnchorButton>
                            </div>
                        </div>
                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModalImprimir}
                            title={i18next.t("print", { ns: "global" })}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimir}
                            style={styles.dialogL}
                        >
                            {!this.state.cargandoImprimir?
                                    <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        <Dialog transitionDuration={400}
                                autoFocus={true}
                                enforceFocus={true}
                                icon="warning-sign"
                                onClose={cerrarModalBorrar}
                                title={i18next.t("delete", { ns: "global" })}
                                canEscapeKeyClose={true}
                                canOutsideClickClose={true}
                                isCloseButtonShown={true}
                                usePortal={true}
                                isOpen={this.state.modalBorrarAbierto}
                            >
                                <div className={Classes.DIALOG_BODY}>
                                    <h2>{i18next.t("procedimientosCompliance.confirmDeleteFile", { ns: "page2" })}</h2>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("eliminar", { ns: "global" })} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={borrar}
                                        >
                                           {i18next.t("yes", { ns: "global" })}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                        <Button onClick={cerrarModalBorrar}>{i18next.t("no", { ns: "global" })}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                    </>
                }
            </>
        )
    }
}

export default withApollo(ContenidoProcedimientos);