/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Spinner,Dialog, Card, Elevation, FormGroup, Button, Callout } from "@blueprintjs/core"

import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from 'moment';
import Documento1 from "../../Assets/ORDEN ECC 461_2013 CONTENIDO Y ESTRUCTURA DEL INFORME.pdf"
import Documento2 from "../../Assets/CIRCULAR 5_2013 MODELOS DE INFORME.pdf"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';

import styles from './stylesPrint';

class InformeAnual extends Component {
    
    state={
        abierto1: false,
        abierto2: false,
        logotipo:null,
        cargando:true,
        cif:null,
        datosEmpresa:[],
        centros:[],
        capital:null,
        variaciones:[],
        personasFisicas:null,
        personasJuridicas:null,
        garantias:[],
        polizas:[],
        miembrosOrgano:null,
        organos:[],
        transmisiones:[],
        comites:null,
        organoCompliance:null,
        actividades:[],
        instalaciones:[],
        memorias:null,
        personalAltaDireccion:null,
        planesMovilidad:[],
        planesIgualdad:null,
        informes:[],        
        apoderados:null,
        acreedores:null,
        servicios:null,
        asesorias:null,
        auditores:null,
        fondos:null,
        socios:null,
        pactos:null,
        jointVentures:null,
        privates:null,
        comisiones:null


    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarCIF=async ()=>{

        //OBTENCION DE LA PERSONA JURIDICA Y EL CIF DE LA EMPRESA.
        let cif=await this.props.client
        .query({
            query: gql`
            {
                documentosIdentificativos(FILTROS:{ID_PERSONA:1,ID_TIPO_DOCUMENTO:4}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR,FECHA_EMISION,FECHA_CADUCIDAD}
            }
            `,
            fetchPolicy: 'network-only'
        }).then(async result=>{ 
            return result.data.documentosIdentificativos
        })    
            let cifVal=''
            for (let docIde of cif){
               cifVal= docIde.VALOR
            }
            this.setState({cif:cifVal})
        }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,

                }

            }
            
        })

        //MEDIO DE CONTACTO
        await this.props.client
        .query({
          query: gql`
            {
              personasMediosContacto(LIMIT: 2, FILTROS: { ID_PERSONA: 1, PREFERIDO:true}) {
                MEDIO_CONTACTO{VALOR}
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
            if(result.data.personasMediosContacto.length>0){
                datosEmpresa["medio"]=result.data.personasMediosContacto[0].MEDIO_CONTACTO.VALOR
            }
        });

        //DOMICILIO
        await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:1,DOMICILIO_SOCIAL:true}){ID_CENTRO_TRABAJO,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            result.data.centrosTrabajos.forEach((centro)=>{
                datosEmpresa["domicilio"]=`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM} ${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""}(${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})`
            })
        })

        return datosEmpresa
    }

    cargarCentros=async()=>{

        let centros=await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:1}){ID_CENTRO_TRABAJO,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.centrosTrabajos
        })    
            let copyCentros=[]
            centros.forEach(centro=>{
                copyCentros.push({"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM} ${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""}(${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
            })

        this.setState({centros:copyCentros})
    }

    obtenerCapital=async()=>{

        let capital=await this.props.client
        .query({
            query: gql`
            {
                capSocialActual{Valor,numTitulos,importeTotal}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.capSocialActual
        })

        this.setState({capital:capital})

    }

    obtenerVariaciones=async()=>{

        let variaciones=await this.props.client.query({
            query:gql`{capSocialVariaciones(FILTROS:{FECHA:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,
            fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.capSocialVariaciones
        })
        this.setState({
            variaciones:variaciones
        })        
    }

    obtenerNombreMiembrosTransmisiones=async(miembro)=>{
        let nombre=''
        if(miembro.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.state.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                }
            }) 
         //Si es persona juridica
         }else if(miembro.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.state.personasJuridicas.find(persona=>{
                 if(persona.ID_PERSONA===miembro.ID_PERSONA){
                     nombre= persona.RAZON_SOCIAL
                 }
             }) 
         }
         miembro.nombre=nombre
    }

    obtenerTransmisiones=async()=>{

        //OBTENCION DE LAS TRANSMISIONES.
        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones(FILTROS:{FECHA:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){
                ID_TRANSMISION,
                FECHA,
                ORIGEN,
                FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                TIPO_ACCION,
                NUMERO_TITULOS,
                NUMERACION_TITULOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })

        for(let transmision of transmisiones){
            await this.obtenerNombreMiembrosTransmisiones(transmision.FK_ORIGEN)
            await this.obtenerNombreMiembrosTransmisiones(transmision.FK_DESTINO)
        }

        this.setState({
            transmisiones:transmisiones
        })
    }

    obtenerNombreMiembros=async(miembro)=>{
        
        if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.state.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                }
            }) 
         //Si es persona juridica
         }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.state.personasJuridicas.find(persona=>{
                 if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.nombre=persona.RAZON_SOCIAL

                 }
             }) 
         }
    }

    cargarGarantias=async()=>{

        let garantias=await this.props.client.query({
            query:gql`{garantias{ID_GARANTIA,ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA,CANTIDAD,CONDICIONES,TIPO_GARANTIA{ID_TIPO_GARANTIA,DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.garantias
        })

        for(let garantia of garantias){
            await this.obtenerNombreMiembros(garantia)
        }
        this.setState({
            garantias:garantias
        })
    }

    cargarPolizas=async ()=>{

        //carga de las polizas 
        let polizas=await this.props.client
        .query({
            query: gql`
            {polizasSeguro(CAMPOS:"CAPITAL_ASEGURADO"){
                TIPO_SEGURO{DESCRIPCION},
                CAPITAL_ASEGURADO,
                ASEGURADORA{DESCRIPCION},
                }}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.polizasSeguro
        })

       this.setState({
           polizas:polizas
       })
    }

    cargarModificaciones=async ()=>{

        let numMod=await this.props.client.query({
            query:gql`{modificacionesEstatutos(FILTROS:{FECHA_EFECTO:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){ID_MODIFICACION_ESTATUTOS,ARTICULO,REDACCION_ANTERIOR,REDACCION_POSTERIOR,FECHA_EFECTO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            console.log(result)
            return result.data.modificacionesEstatutos.length
        })

        this.setState({
            numMod:numMod
        })

    }

    
    formatearPeriodicidad=(periodicidad)=>{


        if(typeof periodicidad==="string")periodicidad=JSON.parse(periodicidad)

        let format=""
        if(periodicidad.dia && periodicidad.dia>0){
            format+="El día "+periodicidad.dia+" de"
        }else if(periodicidad.diaSemana){
            format+="El "+periodicidad.ordinal+" "+periodicidad.diaSemana+" de"
        }

        if(periodicidad.numeroMes && periodicidad.numeroMes>0){
            format+=" cada "+periodicidad.numeroMes+" meses"
        }else if(periodicidad.meseseSeleccionados.length>0){
            let meses=periodicidad.meseseSeleccionados.map(function(elem){
                return elem.label;
            }).join(",");
            format+=" los meses "+meses
        }
        return format
    }
    cargarOrgano=async()=>{
        let opciones=[]
        let formas= await this.props.client
        .query({
          query: gql`
            {formasAdministracion{ID_FORMA_ADMINISTRACION,DESCRIPCION}}
          `
        })
        .then(result =>{
            return result.data.formasAdministracion
        })  
        let organos= await this.props.client
        .query({
          query: gql`
            {organoAdministracion{ID_FORMA_ADMINISTRACION,FRECUENCIA_REUNIONES}}
          `,fetchPolicy:'network-only'
        })
        .then(result =>{
            return result.data.organoAdministracion
        })
        for(let organo of organos){
            formas.forEach(forma=>{
                opciones.push({
                    "value":forma.ID_FORMA_ADMINISTRACION,
                    "label": forma.DESCRIPCION
                })
            })
            let formaSel= opciones.find(forma=>{
                    return forma.value===organo.ID_FORMA_ADMINISTRACION
                })
                organo.desc=formaSel.label   
        }
       this.setState({
        organos:organos
        })
}  


    // cargarOrgano=async()=>{

    //     let organo=await this.props.client
    //     .query({
    //       query: gql`
    //         {organoAdministracion{ID_FORMA_ADMINISTRACION,FRECUENCIA_REUNIONES}}
    //       `,fetchPolicy:'network-only'
    //     })
    //     .then(async result =>{
    //         console.log(result)

    //         if(result.data.organoAdministracion.length>0){

    //             let desc=await this.props.client
    //             .query({
    //               query: gql`
    //                 {formasAdministracion(FILTROS:{ID_FORMA_ADMINISTRACION:${result.data.organoAdministracion[0].ID_FORMA_ADMINISTRACION}}){ID_FORMA_ADMINISTRACION,DESCRIPCION}}
    //               `,fetchPolicy:'network-only'
    //             })
    //             .then(result =>{
    //               return result.data.formasAdministracion[0].DESCRIPCION
    //             });
    //             result.data.organoAdministracion[0].desc=desc
    //         }
    //         return result.data.organoAdministracion.length>0?result.data.organoAdministracion[0]:null
    //     });
    //     return organo
    // }
    cargarMiembros=async ()=>{
        
        //buscamos a los miembros del organo
        let miembros=await this.props.client.query({
            query:gql`{organosAdministracionMiembros(CAMPOS:"ID_PERSONA,SUPLENTE"){
                ID_PERSONA,
                SUPLENTE,
                PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`, fetchPolicy:'network-only'
        }).then(async(result)=>{

            let miembros=result.data.organosAdministracionMiembros
            for(let miembro of miembros){
                if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===miembro.ID_PERSONA){
                            miembro.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    }) 
                 //Si es persona juridica
                 }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                     this.state.personasJuridicas.find(persona=>{
                         if(persona.ID_PERSONA===miembro.ID_PERSONA){
                            miembro.nombre=persona.RAZON_SOCIAL
        
                         }
                     }) 
                 }
            }
            let suplentes=miembros.filter(miembro=>{return miembro.SUPLENTE===true})
            let noSuplentes=miembros.filter(miembro=>{return !miembro.SUPLENTE})
            return {suplentes:suplentes,nosuplentes:noSuplentes}
        })
        this.setState({miembrosOrgano:miembros})
    }
 
   

    cargarComites=async ()=>{

        let comites=await this.props.client.query({
            query:gql`{agrupaciones{ID_AGRUPACION,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.agrupaciones
        })
        this.setState({comites:comites})
    }

    cargarOrganosCompliance=async()=>{

        let organo=[]
        //comprobar si existe el organo compliance
        await this.props.client
        .query({
        query: gql`
                 {organosCompliance{ASUMIDO}}

        `,fetchPolicy:'network-only'
        })
        .then(result =>{
            if(result.data.organosCompliance.length>0){
                organo.asumido=result.data.organosCompliance[0].ASUMIDO
            }
        });

        //obtencion de los miembros
        let miembros=await this.props.client
        .query({
          query: gql`
          {miembrosOrganizacion{ID_PERSONA}}`,
          fetchPolicy:'network-only'
        })
        .then(async result =>{
            return result.data.miembrosOrganizacion
        })
        let delegados=await this.props.client
        .query({
        query: gql`
        {DelegadosAutorizados{ID_PERSONA}}`,
        fetchPolicy:'network-only'
        })
        .then(async result =>{
            return result.data.DelegadosAutorizados
        })
        let profesionales=await this.props.client
        .query({
        query: gql`
        {ProfesionalesExternos{ID_PERSONA}}`,
        fetchPolicy:'network-only'
        })
        .then(async result =>{
          return result.data.ProfesionalesExternos
        })       
        
        miembros.forEach(miembro=>{
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_PERSONA){
                        miembro.nombre=`${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.APELLIDO1}, ${persona.NOMBRE}`
                    }
                })
             })
             organo.miembros=miembros

        //obtencion de los delegados
        delegados.forEach(delegado=>{
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===delegado.ID_PERSONA){
                        delegado.nombre=`${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.APELLIDO1}, ${persona.NOMBRE}`
                    }
                })
        })
            organo.delegados=delegados

        //obtencion de los profesionales externos
        profesionales.forEach(profesional=>{
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===profesional.ID_PERSONA){
                        profesional.nombre=persona.RAZON_SOCIAL
                    }
                })
               
            })        
        organo.profesionales=profesionales

        this.setState({organoCompliance:organo})
    }  

    cargarActividades=async()=>{

        let actividades= await this.props.client
        .query({
          query: gql`
            {actividades{
             OBJETO_SOCIAL,
             PRODUCTOS,
             SERVICIOS,
             CANALES,
             ZONAS,
             RIESGO_INTRINSECO,
             RIESGO_ENTORNO,
             IMPACTO_MEDIOAMBIENTAL,
             RIESGO_MEDIOAMBIENTAL}}
          `
        })
        .then(result =>{
            return result.data.actividades
        });
        this.setState({actividades:actividades})
    }

    cargarInstalaciones=async()=>{

        let instalaciones= await this.props.client
        .query({
          query: gql`
            {instalaciones{
             FIJAS,
             MOVILES,
             ESPECIALES,
             TRANSPORTE,
             MAQUINARIA,
             INFORMATICOS,
             SOFTWARE}}
          `
        })
        .then(result =>{
            return result.data.instalaciones
        });
            let instalacionVal=''
        if(instalaciones.length>0){
            instalaciones.forEach(instalacion=>{
                instalacionVal= instalacion
            })
        }
            this.setState({instalaciones: instalacionVal})
    }

    cargarMemorias=async()=>{

        let adjuntos=await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:7}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{
            return result.data.adjuntos;
        })    
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            this.setState({memorias:copyAdjuntos})  

    }

   

    // cargarInformes=async()=>{

    //     return await this.props.client
    //     .query({
    //         query: gql`
    //         {
    //             adjuntos(FILTROS:{ID_TIPO_ADJUNTO:8}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
    //         }
    //         `,fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let adjuntos=result.data.adjuntos;
    //         let copyAdjuntos=[]
    //         adjuntos.forEach((adjunto,index)=>{
    //             copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
    //         })
    //         return copyAdjuntos
    //     })

    // }

    cargarInformes=async()=>{

        let informes= await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:8}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.adjuntos
        })    
            let copyAdjuntos=[]
            informes.forEach((adjunto)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            this.setState({informes:copyAdjuntos})

    }

    cargarPlanesIgualdad=async()=>{
        let planes= await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:9}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.adjuntos;
        })    
            let copyAdjuntos=[]
            planes.forEach((adjunto)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            this.setState({planesIgualdad:copyAdjuntos})
    

    }

    cargarPlanesMovilidad=async()=>{

        let adjuntos= await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:10}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.adjuntos;
        })    
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            this.setState({planesMovilidad:copyAdjuntos}) 
    

    }

    // obtenerNombresPersonal=async(persona)=>{
    //     await this.props.client.query({
    //         query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONAL_ALTA_DIRECCION}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
    //         fetchPolicy:'network-only'
    //     }).then(async(result)=>{
    //         persona.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`

    //     })
    // }
    cargarPersonal=async ()=>{

        let personal=await this.props.client.query({
            query:gql`{personalAltaDireccion{ID_PERSONAL_ALTA_DIRECCION,ATRIBUCIONES,RETRIBUCION,INDEMNIZACIONES}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return result.data.personalAltaDireccion
        })

        for(let persona of personal){
            this.state.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_PERSONAL_ALTA_DIRECCION){
                    persona.nombre=`${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:""} ${miembro.NOMBRE}`

                }
            })
        }

        this.setState({personalAltaDireccion:personal})
    }

    // obtenerNombreApoderado=async(apoderado)=>{
    //     await this.props.client.query({
    //         query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${apoderado.ID_APODERADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
    //         fetchPolicy:'network-only'
    //     }).then(async(result)=>{
    //         apoderado.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //     })
    // }

    cargarApoderados=async ()=>{

        let apoderados=await this.props.client.query({
            query:gql`{apoderados{ID_APODERADO,ID_MODALIDAD_DELEGACION,LIMITES,CONTENIDO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return result.data.apoderados
        })

        for(let persona of apoderados){
            this.state.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_APODERADO){
                    persona.nombre=`${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:""} ${miembro.NOMBRE}`
                }
            })
        }

            this.setState({apoderados:apoderados})
    }

    cargarAcreedores=async()=>{

       let acreedores= await this.props.client.query({
            query:gql`{acreedores{ID_ACREEDOR,ACREEDOR{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}},ID_SECTOR_ACTIVIDAD,SECTOR_ACTIVIDAD{DESCRIPCION},ID_TIPO_ACREEDOR_SECTOR,TIPO_ACREEDOR_SECTOR{DESCRIPCION},ID_TIPO_ACREEDOR_VOLUMEN,TIPO_ACREEDOR_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.acreedores
        })    

            let acreedoresVal=[]
            for(let acreedor of acreedores){

                let nombreacreedor=""
                //Si es persona fisica...
                if(acreedor.ACREEDOR.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                        this.state.personasFisicas.find(persona=>{
                            if(persona.ID_PERSONA===acreedor.ID_PERSONA){
                                nombreacreedor=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                            }
                        })
                //    nombreacreedor=await this.props.client
                //     .query({
                //         query: gql` 
                //             {personasFisicas(FILTROS:{ID_PERSONA:${acreedor.ID_ACREEDOR}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                //         `,fetchPolicy:'network-only'

                //     }).then(result=>{ 
                //        return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                //     })

                //Si es persona juridica
                }else if(acreedor.ACREEDOR.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===acreedor.ID_PERSONA){
                            nombreacreedor=persona.RAZON_SOCIAL
                        }
                    })
                    // nombreacreedor=await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${acreedor.ID_ACREEDOR}}){ID_PERSONA,RAZON_SOCIAL}}
                    //     `,fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     if(result.data.personasJuridicas.length>0){
                    //         return result.data.personasJuridicas[0].RAZON_SOCIAL
                    //     }
                    // })

                }
                acreedoresVal.push({"nombre":nombreacreedor}) 
            }

            this.setState({acreedores:acreedoresVal})
        

    }

    cargarservicios=async()=>{

        let servicios= await this.props.client.query({
            query:gql`{serviciosIndependientes(ORDEN:"ID_SERVICIO_INDEPENDIENTE,ASC"){ID_SERVICIO_INDEPENDIENTE,TIPO,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}},ID_SECTOR_ACTIVIDAD,SECTOR_ACTIVIDAD{DESCRIPCION},ID_CLASIFICACION_VOLUMEN,CLASIFICACION_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.serviciosIndependientes
        })    
           
            // let servicios=[]
            for(let servicio of servicios){

                let nombreservicio=""
                //Si es persona fisica...
                if(servicio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===servicio.ID_SERVICIO_INDEPENDIENTE){
                            nombreservicio= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    })

                //    nombreservicio=await this.props.client
                //     .query({
                //         query: gql` 
                //             {personasFisicas(FILTROS:{ID_PERSONA:${servicio.ID_SERVICIO_INDEPENDIENTE}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                //         `,fetchPolicy:'network-only'
                //     }).then(result=>{ 
                //        return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                //     })

                //Si es persona juridica
                }else if(servicio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===servicio.ID_SERVICIO_INDEPENDIENTE){
                            nombreservicio= persona.RAZON_SOCIAL
                        }
                    })
                    // nombreservicio=await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${servicio.ID_SERVICIO_INDEPENDIENTE}}){ID_PERSONA,RAZON_SOCIAL}}
                    //     `,fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     return result.data.personasJuridicas[0].RAZON_SOCIAL
                    // })

                }
                servicio.nombre=nombreservicio

            }
    
            this.setState({servicios:servicios})
    

    }

    cargarasesorias=async()=>{

        let asesorias=await this.props.client.query({
            query:gql`{asesoriasExternas{ID_ASESORIA_EXTERNA,ASESORIA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA_NOMBRAMIENTO,ID_CLASIFICACION_VOLUMEN,CLASIFICACION_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.asesoriasExternas
        })   

            let asesoriasVal=[]
            for(let asesoria of asesorias){

                let nombreasesoria=""
                //Si es persona fisica...
                if(asesoria.ASESORIA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===asesoria.ID_ASESORIA_EXTERNA){
                            nombreasesoria=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    })

                //    nombreasesoria=await this.props.client
                //     .query({
                //         query: gql` 
                //             {personasFisicas(FILTROS:{ID_PERSONA:${asesoria.ID_ASESORIA_EXTERNA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                //         `,
                //         fetchPolicy:'network-only'
                //     }).then(result=>{ 
                //         if(result.data.personasFisicas.length>0){
                //             return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                //         }
                //     })

                //Si es persona juridica
                }else if(asesoria.ASESORIA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===asesoria.ID_ASESORIA_EXTERNA){
                            nombreasesoria= persona.RAZON_SOCIAL
                        }
                    })
                    // nombreasesoria=await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${asesoria.ID_ASESORIA_EXTERNA}}){ID_PERSONA,RAZON_SOCIAL}}
                    //     `,
                    //     fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     if(result.data.personasJuridicas.length>0){
                    //         return result.data.personasJuridicas[0].RAZON_SOCIAL
                    //     }
                    // })

                }
                asesoriasVal.push({"nombre":nombreasesoria})           
            }
            this.setState({asesorias:asesoriasVal})
    

    }

    cargarauditores=async()=>{
    
        let auditores= await this.props.client.query({
            query:gql`{auditores{ID_AUDITOR,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA_NOMBRAMIENTO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.auditores
        })    

            let auditoresVal=[]
            for(let auditor of auditores){

                let nombreauditor=""
                //Si es persona fisica...
                if(auditor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===auditor.ID_AUDITOR){
                            nombreauditor=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    })

                //    nombreauditor=await this.props.client
                //     .query({
                //         query: gql` 
                //             {personasFisicas(FILTROS:{ID_PERSONA:${auditor.ID_AUDITOR}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                //         `,
                //         fetchPolicy:'network-only'
                //     }).then(result=>{ 
                //         if(result.data.personasFisicas.length>0){
                //             return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                //         }
                //     })

                //Si es persona juridica
                }else if(auditor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===auditor.ID_AUDITOR){
                            nombreauditor=persona.RAZON_SOCIAL
                        }                        
                    })
                    // nombreauditor=await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${auditor.ID_AUDITOR}}){ID_PERSONA,RAZON_SOCIAL}}
                    //     `,
                    //     fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     if(result.data.personasJuridicas.length>0){
                    //         return result.data.personasJuridicas[0].RAZON_SOCIAL
                    //     }
                    // })

                }
                auditoresVal.push({"nombre":nombreauditor}) 
            }
            this.setState({auditores:auditoresVal})
    }

    // obtenerNombreFondos=async(fondo)=>{
        
    //     await this.props.client
    //     .query({
    //         query: gql`
    //             {personasJuridicas(FILTROS:{ID_PERSONA:${fondo.ID_FONDO_INVERSION}}){ID_PERSONA,RAZON_SOCIAL}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(result=>{ 

    //         fondo.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

    //     })
    // }
    cargarFondos=async()=>{


        let fondos=await this.props.client
        .query({
            query: gql`
                {fondosInversiones{ID_FONDO_INVERSION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.fondosInversiones
        })
        for(let fondo of fondos){
            let nombre=''
            this.state.personasJuridicas.find(persona=>{
                if(persona.ID_PERSONA===fondo.ID_FONDO_INVERSION){
                    nombre=persona.RAZON_SOCIAL
                }
            })
            fondo.nombre=nombre
        }   

        this.setState({fondos:fondos})
       
    }

    // obtenerNombreGestores=async(gestor)=>{
        
    //     if(gestor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
    //         await this.props.client
    //          .query({
    //              query: gql`
    //                  {personasFisicas(FILTROS:{ID_PERSONA:${gestor.ID_SOCIO_GESTOR}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}},
    //              `,
    //              fetchPolicy:'network-only'

    //          }).then(result=>{ 
     
    //             if(result.data.personasFisicas.length>0)gestor.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`

    //          })
 
    //      //Si es persona juridica
    //      }else if(gestor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
    //          await this.props.client
    //          .query({
    //              query: gql`
    //                  {personasJuridicas(FILTROS:{ID_PERSONA:${gestor.ID_SOCIO_GESTOR}}){ID_PERSONA,RAZON_SOCIAL}}
    //              `,
    //              fetchPolicy:'network-only'

    //          }).then(result=>{ 
 
    //             if(result.data.personasJuridicas.length>0)gestor.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

    //          })
 
    //      }
    // }


    cargarSociosGestores=async()=>{

        let socios=await this.props.client.query({
            query:gql`{sociosGestores{ID_SOCIO_GESTOR,PERSONA{ID_PERSONA,ID_TIPO_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.sociosGestores
        })
        for(let socio of socios){
            let nombre='';
            if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===socio.ID_SOCIO_GESTOR){
                        nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                    }
                })
            }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===socio.ID_SOCIO_GESTOR){
                        nombre=persona.RAZON_SOCIAL
                    }
                })
            }

          socio.nombre=nombre
            // await this.obtenerNombreGestores(socio)            
        }
            this.setState({socios: socios})
    }

    cargarPactos=async()=>{

        let acuerdos=await this.props.client
        .query({
            query: gql` 
                {acuerdosParasociales{ID_ACUERDO,ID_TIPO_ACUERDO,FECHA_VIGENCIA,FECHA_VENCIMIENTO,DESCRIPCION,RESULTADO_VOTACION}}
            `,
            fetchPolicy: 'network-only'
        }).then(result=>{ 
            return result.data.acuerdosParasociales
        })

        let pactosBloqueo=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===1})
        let pactosDefensa=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===2})
        let pactosNoAgresion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===3})
        let acuerdosConversion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===4})
        let pactosAtribucion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===5})
        let pactosOrganizacion=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===6})
        let otrosPactos=acuerdos.filter(acuerdo=>{return acuerdo.ID_TIPO_ACUERDO===7})

        return {
            "pactosBloqueo":pactosBloqueo,
            "pactosDefensa":pactosDefensa,
            "pactosNoAgresion":pactosNoAgresion,
            "acuerdosConversion":acuerdosConversion,
            "pactosAtribucion":pactosAtribucion,
            "pactosOrganizacion":pactosOrganizacion,
            "otrosPactos":otrosPactos
        }
    }

    cargarJoints=async()=>{

        //obtencion de los proyectos
        let joints= await this.props.client
        .query({
            query: gql`
                {jointVentures{ID_JOINT_VENTURE,NOMBRE_PROYECTO,VIGENCIA_INICIO,VIGENCIA_FIN}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.jointVentures
        })
            this.setState({jointVentures:joints})
    }

    cargarPrivates=async()=>{

        //obtencion de los privates
        let privates= await this.props.client
        .query({
            query: gql`
                {privatesEquity{ID_PRIVATE_EQUITY,NOMBRE_PROYECTO,VIGENCIA_INICIO,VIGENCIA_FIN}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.privatesEquity
        })
        this.setState({privates:privates})

    }

    obtenerMiembrosComisiones=async(miembro,nombres)=>{
        // this.state.personasFisicas.find(persona=>{
        //     if(persona.ID_PERSONA===miembro.ID_PERSONA){
        //         nombres.push(`-  ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`)
        //     }
        // })
        await this.props.client.query({
            query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${miembro.ID_MIEMBRO}}){
                ID_PERSONA,
                NOMBRE,
                APELLIDO1,
                APELLIDO2
            }}`
        }).then(async(result)=>{

            let persona=result.data.personasFisicas[0]
            nombres.push(`-  ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`)
            
        })
    }

    cargarComisiones=async ()=>{

        //buscamos las comisiones
          //cargar la tabla de comisiones.
          let comisiones=await this.props.client.query({
            query:gql`{comisiones{
                ID_COMISION,
                NOMBRE,
                ID_TIPO_COMISION,
                TIPO_COMISION{ID_TIPO_COMISION,DESCRIPCION}
            }}`, 
        }).then(async(result)=>{
            return result.data.comisiones
        })

        //buscamos los miembros de cada comision.
        for(let comision of comisiones){
            await this.props.client.query({
                query:gql`{comisionesMiembros(FILTROS:{ID_COMISION:${comision.ID_COMISION}}){
                    ID_MIEMBRO
                }}`
            }).then(async(result)=>{
    
                let miembros=result.data.comisionesMiembros
                let nombres=[]
                for(let miembro of miembros){
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===miembro.ID_MIEMBRO){
                            nombres.push(`-  ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`)
                        }
                    })
            
                    // await this.obtenerMiembrosComisiones(miembro,nombres)
                }      
                let listaNombre=""
                nombres.forEach(nombre=>{
                    listaNombre+=`
                        ${nombre}
                    `
                })    
                listaNombre = listaNombre.substring(0, listaNombre.length - 1)       
                comision.miembros=listaNombre
               
            })
        }
       this.setState({comisiones:comisiones})
    }

    cargarPersonasFisicas=async()=>{
        let personasFisicas= await this.props.client
        .query({
            query: gql`
                {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}},
            `,
            fetchPolicy:'network-only'

        }).then(result=>{ 
            return result.data.personasFisicas
        })
        return personasFisicas    
    }
    cargarPersonasJuridicas=async()=>{
        let personasJuridicas=await this.props.client
        .query({
            query: gql`
                {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
            `,
            fetchPolicy:'network-only'

        }).then(result=>{ 
            return result.data.personasJuridicas
        })
        return personasJuridicas    
    }
    cargarDatos=async()=>{
        let personasFisicas= await this.cargarPersonasFisicas()
        let personasJuridicas= await this.cargarPersonasJuridicas()
        let datosEmpresa= await this.cargarDatosEmpresa()
        let pactos=await this.cargarPactos()


        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas:personasFisicas,
            personasJuridicas:personasJuridicas,
            pactos:pactos,


        })
    }

    componentDidMount(){
        this.setState({cargandoIA:true}, async() =>{
        await this.cargarDatos()   
        await this.obtenerLogoEmpresa()
        await this.cargarCIF()
        await this.cargarCentros()
        await this.obtenerCapital()
        await this.obtenerVariaciones()
        await this.obtenerTransmisiones()
        await this.cargarGarantias()
        await this.cargarPolizas()
        await this.cargarModificaciones()
        await this.cargarMiembros()
        await this.cargarOrgano()
        await this.cargarComites()
        await this.cargarOrganosCompliance()
        console.log(this.state.organoCompliance)
        await this.cargarActividades()
        await this.cargarInstalaciones()
        await this.cargarMemorias()
        await this.cargarInformes()
        console.log(this.state.memorias)
        await this.cargarPlanesIgualdad()
        await this.cargarPlanesMovilidad()
        await this.cargarPersonal()
        await this.cargarApoderados()
        await this.cargarAcreedores()
        await this.cargarservicios()
        await this.cargarasesorias()
        await this.cargarauditores()
        await this.cargarFondos()
        await this.cargarSociosGestores()
        await this.cargarJoints()
        await this.cargarPrivates()
        await this.cargarComisiones()
        console.log(this.state.comisiones)

        this.setState({cargandoIA:false})
    })
}

    generarImprimirIA=async()=>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async()=>{
            this.setState({cargandoImprimir:false},()=>{
                ReactDOM.render(this.ImprimirIA(), document.getElementById('generarIA-pdf'))
            })
        })
    }
    cargarVar=()=>{
        let vari=[]
        this.state.variaciones.forEach(variacion=>{
            if(variacion.ID_TIPO_VARIACION===1){
                vari.push(`- En fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${variacion.CAMBIO_NUMERO_TITULOS>0?"aumentó":"decrementó"} el número de títulos en ${Math.abs(variacion.CAMBIO_NUMERO_TITULOS)}`)
            }else{
                vari.push(`- En fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} ${variacion.CAMBIO_VALOR_TITULO>0?"aumentó":"decrementó"} el valor del título en ${Math.abs(variacion.CAMBIO_VALOR_TITULO)} `)
            }
        })
        return vari
    }

    ImprimirIA=()=>{
        let variacion= this.cargarVar()
    return(
    <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>INFORME ANUAL</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>INFORME ANUAL DE GOBIERNO CORPORATIVO</Text>
                </View>
                    <View >
                        <View style={styles.row}>
                            <Text style={styles.labelData}>EJERCICIO DE REFERENCIA:</Text>
                            <Text style={styles.textData}>{moment().format("YYYY")}</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>DATOS INDENTIFICATIVOS DE LA EMPRESA</Text>
                        </View>
                       
                        <View style={styles.row}>
                            <Text style={styles.labelData}>C.I.F:</Text>
                            <Text style={styles.textData}>{this.state.cif}</Text>
                        </View>                           
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RAZÓN SOCIAL:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa.razonSocial?this.state.datosEmpresa.razonSocial:''}</Text>
                         </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>NOMBRE COMERCIAL:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.nombreComercial:''}</Text>
                        </View>
                         <View style={styles.row}>
                            <Text style={styles.labelData}>SITIO WEB CORPORATIVO:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.sitioWeb:''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>DOMICILIO:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.domicilio:''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>MEDIO DE CONTACTO:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.medio:''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE CENTROS DE TRABAJO:</Text>
                            <Text style={styles.textData}></Text>
                        </View>
                        {this.state.centros.map((centro, index)=>{
                            return(
                                    <Text  key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{centro.direccion}</Text>
                                )})}
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}> ESTRUCTURA ACCIONARIAL</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>TIPO DE SOCIEDAD:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.tipoSociedad:''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>TIPO DE TÍTULOS:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.tipoTitulos:''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelDataST}>ESTADO DEL CAPITAL SOCIAL ACTUAL:</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>NÚMERO DE TÍTULOS:</Text>
                            <Text style={styles.textData}>{this.state.capital?this.state.capital.numTitulos:0}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>VALOR DEL TÍTULOS:</Text>
                            <Text style={styles.textData}>{this.state.capital?this.state.capital.Valor:0.00} €</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>IMPORTE TOTAL:</Text>
                            <Text style={styles.textData}>{this.state.capital?this.state.capital.importeTotal:0.00} €</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelDataXL}>RELACIÓN DE VARIACIONES DEL ÚLTIMO EJERCICIO:</Text>
                        </View>
                        {variacion?variacion.map((vari, index)=>{
                            return(
                                <View key={index} style={styles.row}>
                                    <Text style={{flexDirection:'row', fontSize: 8, fontFamily:'Open Sans', width:'100%'}}>{vari}</Text>
                               </View>
                            )
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelDataXL}>TRANSMISIÓN DE TÍTULOS EN EL ÚLTIMO EJERCICIO:</Text>
                        </View>
                        {this.state.transmisiones?this.state.transmisiones.map((transmision, index)=>{
                            return(
                                <Text key={index} style={{flexDirection:'row', fontSize: 8, fontFamily:'Open Sans', width:'100%'}}>{`- Con fecha ${moment(transmision.FECHA).format("DD-MM-YYYY")} ${transmision.FK_ORIGEN.nombre}
                                trasmitió ${transmision.NUMERO_TITULOS} titulos con numeración ${transmision.NUMERACION_TITULOS} a ${transmision.FK_DESTINO.nombre}
                                `}</Text>
                            )
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelDataST}>CUENTAS ANUALES:</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>TOTAL PARTIDAS ACTIVO:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa.totalPartidas?this.state.datosEmpresa.totalPartidas:0.00} €</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>IMPORTE NETO ANUAL DE LA CIFRA DE NEGOCIO:</Text>
                            <Text style={styles.textData}>{this.state.datosEmpresa.cifraAnual?this.state.datosEmpresa.cifraAnual:0.00} €</Text>
                        </View>
                         <View style={styles.row}>
                            <Text style={styles.labelData}>NÚMERO MEDIO DE TRABAJADORES:</Text>
                           <Text style={styles.textData}>{this.state.datosEmpresa.numTrabajadores?this.state.datosEmpresa.numTrabajadores:0}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelDataXL}>CRÉDITOS Y GARANTÍAS A SOCIOS Y ADMINISTRADORES EN EL ÚLTIMO EJERCICIO:</Text>
                        </View>
                        {this.state.garantias?this.state.garantias.map((garantia, index)=>{
                            return( <Text key={index} style={{flexDirection:'row', fontSize: 8, fontFamily:'Open Sans', width:'100%'}}>
                                {`- Crédito concedido a ${garantia.nombre} el ${moment(garantia.FECHA).format("DD-MM-YYYY")} por importe de ${garantia.CANTIDAD} €`}
                            </Text>)
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelDataST}>PÓLIZAS DE SEGURO:</Text>
                        </View>
                        {this.state.polizas?this.state.polizas.map((poliza, index)=>{
                            return(
                                <View key={index}>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>COMPAÑIA ASEGURADORA:</Text>
                                        <Text style={styles.textData}>{poliza.ASEGURADORA?poliza.ASEGURADORA.DESCRIPCION:''}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>CAPITAL ASEGURADO:</Text>
                                        <Text style={styles.textData}>{poliza.CAPITAL_ASEGURADO?poliza.CAPITAL_ASEGURADO:''}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>TIPO DESEGURO:</Text>
                                        <Text style={styles.textData}>{poliza.TIPO_SEGURO?poliza.TIPO_SEGURO.DESCRIPCION:''}</Text>
                                    </View>
                                </View>
                            )
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelData}>MODIFICACIONES ESTATUTARIAS:</Text>
                            <Text style={styles.textData}>{`${this.state.numMod?this.state.numMod:0} modificaciones en el ejercicio`}</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}> ÓRGANO DE ADMINISTRACIÓN</Text>
                        </View>
                        {this.state.organos?this.state.organos.map((organo,index)=>{
                           return(
                            <View key={index}>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FORMA DE ADMINISTRACIÓN:</Text>
                                    <Text style={styles.textData}>{organo.desc?organo.desc:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FRECUENCIA DE REUNIONES:</Text>
                                    <Text style={styles.textData}>{organo && organo.FRECUENCIA_REUNIONES?this.formatearPeriodicidad(organo.FRECUENCIA_REUNIONES):''}</Text>
                                </View>
                            </View>
                           )
                        }):<></>}
                      
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE MIEMBROS:</Text>
                        </View>
                        {this.state.miembrosOrgano?this.state.miembrosOrgano.nosuplentes.map((miembro, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{miembro.nombre}</Text>)
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE SUPLENTES:</Text>
                        </View>
                        {this.state.miembrosOrgano?this.state.miembrosOrgano.suplentes.map((miembro, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{miembro.nombre}</Text>)
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE COMITÉS CONSULTIVOS:</Text>
                        </View>
                        {this.state.comites?this.state.comites.map((comite, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{comite.DESCRIPCION}</Text>)
                        }):<></>}
                         <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>ÓRGANO DE COMPLIANCE</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>ASUMIDO POR EL ÓRGANO DE ADMINISTRACIÓN:</Text>
                            <Text style={styles.textData}>{this.state.organoCompliance.asumido===true?'SI':'NO'}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE MIEMBROS:</Text>
                        </View>
                        {this.state.organoCompliance?this.state.organoCompliance.miembros.map((miembro, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{miembro.nombre}</Text>)
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelData}>PROFESIONALES EXTERNOS:</Text>
                        </View>
                        {this.state.organoCompliance?this.state.organoCompliance.profesionales.map((profesional, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{profesional.nombre}</Text>)
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelData}>DELEGADOS AUTORIZADOS:</Text>
                        </View>
                        {this.state.organoCompliance?this.state.organoCompliance.delegados.map((delgado, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{delgado.nombre}</Text>)
                        }):<></>}
                          <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>DATOS RELATIVOS A LAS ACTIVIDADES</Text>
                        </View>
                        {this.state.actividad?this.state.actividad.map((actividad, index)=>{
                         return(
                            <View key={index}>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>OBJETO SOCIAL RACIÓN:</Text>
                                <Text style={styles.textData}>{actividad.OBJETO_SOCIAL?actividad.OBJETO_SOCIAL:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>PRODUCTOS QUE SE OFRECEN:</Text>
                                <Text style={styles.textData}>{actividad.PRODUCTOS?actividad.PRODUCTOS:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>SERVICIOS QUE SE PRESTAN:</Text>
                                <Text style={styles.textData}>{actividad.SERVICIOS?actividad.SERVICIOS:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>CANALES DE DISTRIBUCIÓN:</Text>
                                <Text style={styles.textData}>{actividad.CANALES?actividad.CANALES:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ZONAS GEOGRÁFICAS:</Text>
                                <Text style={styles.textData}>{actividad.ZONAS?actividad.ZONAS:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ACTIVIDADES DE RIESGO INTRÍNSECO:</Text>
                                <Text style={styles.textData}>{actividad.RIESGO_INTRINSECO?actividad.RIESGO_INTRINSECO:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ACTIVIDADES CON RIESGO PARA EL ENTORNO:</Text>
                                <Text style={styles.textData}>{actividad.RIESGO_ENTORNO?actividad.RIESGO_ENTORNO:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ACTIVIDADES CON IMPACTO MEDIOAMBIENTAL:</Text>
                                <Text style={styles.textData}>{actividad.RIESGO_MEDIOAMBIENTAL?actividad.RIESGO_MEDIOAMBIENTAL:''}</Text>
                            </View>
                        </View>
                        )}):<></>}
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelData}>DATOS RELATIVOS A LAS INSTALACIONES</Text>
                        </View>
                        {this.state.instalaciones?this.state.instalaciones.map((instalacion, index)=>{
                         return(
                            <View key={index}>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>INSTALACIONES FIJAS:</Text>
                                <Text style={styles.textData}>{instalacion.FIJAS?instalacion.FIJAS:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>INSTALACIONES MÓVILES:</Text>
                                <Text style={styles.textData}>{instalacion.MOVILES?instalacion.MOVILES:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>INSTALACIONES ESPECIALES:</Text>
                                <Text style={styles.textData}>{instalacion.ESPECIALES?instalacion.ESPECIALES:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ELEMENTOS DE TRANSPORTE:</Text>
                                <Text style={styles.textData}>{instalacion.TRANSPORTE?instalacion.TRANSPORTE:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>MAQUINARIA:</Text>
                                <Text style={styles.textData}>{instalacion.MAQUINARIA?instalacion.MAQUINARIA:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>EQUIPOS INFORMÁTICOS:</Text>
                                <Text style={styles.textData}>{instalacion.INFORMATICOS?instalacion.INFORMATICOS:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>SOFTWARE:</Text>
                                <Text style={styles.textData}>{instalacion.SOFTWARE?instalacion.SOFTWARE:''}</Text>
                            </View>
                        </View>
                        )}):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE MEMORIAS:</Text>
                        </View>
                        {this.state.memorias?this.state.memorias.map((memoria, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>- {memoria.descripcion}</Text>)
                        }):<></>}
                          <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE INFORMES:</Text>
                        </View>
                        {this.state.informes?this.state.informes.map((informe, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>- {informe.descripcion}</Text>)
                        }):<></>}
                          <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE PLANES DE IGUALDAD:</Text>
                        </View>
                        {this.state.planesIgualdad?this.state.planesIgualdad.map((plan, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>- {plan.descripcion}</Text>)
                        }):<></>}
                           <View style={styles.row}>
                            <Text style={styles.labelData}>RELACIÓN DE PLANES DE MOVILIDAD:</Text>
                        </View>
                        {this.state.planesMovilidad?this.state.planesMovilidad.map((plan, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>- {plan.descripcion}</Text>)
                        }):<></>}
                         <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>PARTES INTERESADAS</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelData}>PERSONAL DE ALTA DIRECCIÓN:</Text>
                        </View>
                        {this.state.personalAltaDireccion?this.state.personalAltaDireccion.map((personal, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{personal.nombre}</Text>)
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelData}>APODERADOS:</Text>
                        </View>
                        {this.state.apoderados?this.state.apoderados.map((apoderado, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{apoderado.nombre}</Text>)
                        }):<></>}
                        {this.state.personalAltaDireccion?this.state.personalAltaDireccion.map((personal, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{personal.nombre}</Text>)
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelDataXL}>SERVICIOS INDEPENDIENTES EMPRESARIALES:</Text>
                        </View>
                        {this.state.servicios?this.state.servicios.map((servicio, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{servicio.nombre}</Text>)
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelData}>AUDITORES:</Text>
                        </View>
                        {this.state.auditores?this.state.auditores.map((auditor, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{auditor.nombre}</Text>)
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelDataXL}>FONDOS DE INVERSIÓN (CAPITAL RIESGO):</Text>
                        </View>
                        {this.state.fondos?this.state.fondos.map((fondo, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{fondo.nombre}</Text>)
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelData}>SOCIOS GESTORES (CAPITAL RIESGO):</Text>
                        </View>
                        {this.state.socios?this.state.socios.map((socio, index)=>{
                            return(<Text key={index} style={{flexDirection:'column', fontSize: 8, fontFamily:'Open Sans', width:'100%', alignItems: 'flex-start', justifySelf: 'flex-start', marginLeft: 160 }}>{socio.nombre}</Text>)
                        }):<></>}
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>ACUERDOS PARASOCIALES VIGENTES</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.labelDataST}>PACTOS DE BLOQUEO:</Text>
                        </View>
                        {this.state.pactos?this.state.pactos.pactosBloqueo.map((pacto, index)=>{
                            return(
                                <View key={index}>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                        <Text style={styles.textData}>{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                        <Text style={styles.textData}>{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                        <Text style={styles.textData}>{pacto.RESULTADO_VOTACION}</Text>
                                    </View>
                                </View>
                            )
                        }):<></>}
                          <View style={styles.row}>
                            <Text style={styles.labelDataST}>PACTOS DE DEFENSA O CONTROL:</Text>
                        </View>
                        {this.state.pactos?this.state.pactos.pactosDefensa.map((pacto, index)=>{
                            return(
                                <View key={index}>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                        <Text style={styles.textData}>{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                        <Text style={styles.textData}>{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                        <Text style={styles.textData}>{pacto.RESULTADO_VOTACION}</Text>
                                    </View>
                                </View>
                            )    
                        }):<></>}
                          <View style={styles.row}>
                            <Text style={styles.labelDataST}>PACTOS DE NO AGRESIÓN:</Text>
                        </View>
                        {this.state.pactos?this.state.pactos.pactosNoAgresion.map((pacto, index)=>{
                            return(
                                <View key={index}>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                        <Text style={styles.textData}>{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                        <Text style={styles.textData}>{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                        <Text style={styles.textData}>{pacto.RESULTADO_VOTACION}</Text>
                                    </View>
                                </View>
                            )
                        }):<></>}
                         <View style={styles.row}>
                            <Text style={styles.labelDataST}>ACUERDOS DE CONVERSIÓN Y DE SALIDA:</Text>
                        </View>
                        {this.state.pactos?this.state.pactos.acuerdosConversion.map((pacto, index)=>{
                            return(
                                <View key={index}>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                        <Text style={styles.textData}>{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                        <Text style={styles.textData}>{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                        <Text style={styles.textData}>{pacto.RESULTADO_VOTACION}</Text>
                                    </View>
                                </View>
                            )
                        }):<></>} 
                          <View style={styles.row}>
                            <Text style={styles.labelDataST}>PACTOS DE ATRIBUCIÓN:</Text>
                        </View>
                        {this.state.pactos?this.state.pactos.pactosAtribucion.map((pacto, index)=>{
                         return(
                            <View key={index}>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                    <Text style={styles.textData}>{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                    <Text style={styles.textData}>{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                    <Text style={styles.textData}>{pacto.RESULTADO_VOTACION}</Text>
                                </View>
                            </View>
                        )   
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelDataST}>OTROS PACTOS:</Text>
                        </View>
                        {this.state.pactos?this.state.pactos.otrosPactos.map((pacto, index)=>{
                         return(
                            <View key={index}>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                    <Text style={styles.textData}>{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                    <Text style={styles.textData}>{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                    <Text style={styles.textData}>{pacto.RESULTADO_VOTACION}</Text>
                                </View>
                            </View>
                        )   
                        }):<></>}
                       <View style={styles.row}>
                            <Text style={styles.labelDataST}>JOINT VENTURES:</Text>
                        </View>
                        {this.state.jointVentures?this.state.jointVentures.map((joint, index)=>{
                         return(
                            <View key={index}>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                    <Text style={styles.textData}>{moment(joint.VIGENCIA_INICIO).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                    <Text style={styles.textData}>{joint.VIGENCIA_FIN?moment(joint.VIGENCIA_FIN).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                    <Text style={styles.textData}>{joint.NOMBRE_PROYECTO}</Text>
                                </View>
                            </View>
                        )   
                        }):<></>}
                        <View style={styles.row}>
                            <Text style={styles.labelDataST}>INVERSIÓN DE CAPITAL RIESGO:</Text>
                        </View>
                        {this.state.privates?this.state.privates.map((equity, index)=>{
                         return(
                            <View key={index}>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VIGENCIA:</Text>
                                    <Text style={styles.textData}>{moment(equity.VIGENCIA_INICIO).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA VENCIMIENTO:</Text>
                                    <Text style={styles.textData}>{equity.VIGENCIA_FIN?moment(equity.VIGENCIA_FIN).format("DD-MM-YYYY"):'NO DEFINIDA'}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>RESULTADO VOTACIÓN:</Text>
                                    <Text style={styles.textData}>{equity.NOMBRE_PROYECTO}</Text>
                                </View>
                            </View>
                        )   
                        }):<></>}
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>COMISIONES</Text>
                        </View>
                        {this.state.comisiones?this.state.comisiones.map((comision, index)=>{
                            return(
                                <View key={index}>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>NOMBRE:</Text>
                                    <Text style={styles.textData}>{comision.NOMBRE}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>TIPO:</Text>
                                        <Text style={styles.textData}>{comision.TIPO_COMISION.DESCRIPCION}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.labelData}>MIEMBROS:</Text>
                                        <Text style={styles.textData}>{comision.miembros}</Text>
                                    </View>
                                </View>
                            )
                        }):<></>} 
                     
                    </View>
                       
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
}   

    // informeAnual =()=>(
    //     <div className="html-pdf" ref={(input) => { this.pdfInformeAnual = input; }} >
    //         <div className="body-pdf">
    //             <br />
    //             <img
    //                 className="App-logo-pdf"
    //                 src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                 alt="logotipo"
    //             />
    //             <br />
    //             <p>
    //                 <b>INFORME ANUAL DE GOBIERNO CORPORATIVO</b>
    //             </p>
    //             <p >
    //                 <b>Ejercicio de referencia:{moment().format("YYYY")}</b>
    //             </p>
    //             <p>
    //                 <b>DATOS INDENTIFICATIVOS DE LA EMPRESA</b>
    //             </p>

    //             <p>
    //                 <b>CIF: </b>{this.state.cif}
    //             </p>
    //             <p>
    //                 <b>RAZÓN SOCIAL: </b>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}
    //             </p>
    //             <p>
    //                 <b>NOMBRE COMERCIAL: </b>{this.state.datosEmpresa?this.state.datosEmpresa.nombreComercial:""}
    //             </p>
    //             <p>
    //                 <b>SITIO WEB CORPORATIVO: </b>{this.state.datosEmpresa?this.state.datosEmpresa.sitioWeb:""}
    //             </p>
    //             <p>
    //                 <b>DOMICILIO: </b>{this.state.datosEmpresa && this.state.datosEmpresa.domicilio?this.state.datosEmpresa.domicilio:""}
    //             </p>
    //             <p>
    //                 <b>MEDIO DE CONTACTO: </b>{this.state.datosEmpresa && this.state.datosEmpresa.medio?this.state.datosEmpresa.medio:""}
    //             </p>
    //             <p>
    //                 <b>RELACIÓN DE CENTROS DE TRABAJO:</b>
    //             </p>
    //             {this.state.centros?this.state.centros.map(centro=>{
    //                 return (<p>{centro.direccion}</p>)
    //             }):<></>}
    //             <p><b>
    //                 ESTRUCTURA ACCIONARIAL
    //             </b></p>

    //             <p>
    //                 <b>Tipo de sociedad: </b>{this.state.datosEmpresa?this.state.datosEmpresa.tipoSociedad:""}
    //             </p>
    //             <p>
    //                 <b>Tipo de títulos: </b>{this.state.datosEmpresa?this.state.datosEmpresa.tipoTitulos:""}
    //             </p>
    //             <p>
    //                 <b>Estado del capital social actual: </b>{`Número de títulos: ${this.state.capital?(this.state.capital.numTitulos?this.state.capital.numTitulos:0):0}; Valor del título: ${this.state.capital?(this.state.capital.Valor?this.state.capital.Valor:0.00):0.00} €; Importe total:${this.state.capital?(this.state.capital.importeTotal?this.state.capital.importeTotal:0.00):0.00} €`}
    //             </p>
    //             <p><b>Relación de variaciones del último ejercicio:</b></p>
    //                 {this.state.variaciones?this.state.variaciones.map(variacion=>{
    //                     if(variacion.ID_TIPO_VARIACION===1){
    //                         return (<p>{`- En fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${variacion.CAMBIO_NUMERO_TITULOS>0?"aumentó":"decrementó"} el número de títulos en ${Math.abs(variacion.CAMBIO_NUMERO_TITULOS)} `}</p>)
    //                     }else{
    //                         return (<p>{`- En fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} ${variacion.CAMBIO_VALOR_TITULO>0?"aumentó":"decrementó"} el valor del título en ${Math.abs(variacion.CAMBIO_VALOR_TITULO)} `}</p>)
    //                     }
    //                 }):<></>}
              
    //             <p>
    //                 <b>Transmisión de títulos en el último ejercicio:</b>
    //             </p>
    //             {this.state.transmisiones?this.state.transmisiones.map(transmision=>{
    //                 return (<p>{`- Con fecha ${moment(transmision.FECHA).format("DD-MM-YYYY")} ${transmision.FK_ORIGEN.nombre}
    //                 trasmitió ${transmision.NUMERO_TITULOS} titulos con numeración ${transmision.NUMERACION_TITULOS} a ${transmision.FK_DESTINO.nombre}
    //                 `}</p>)
    //             }):<></>} 
    //             <p><b>Cuentas anuales:</b></p>
    //                 <p><b>"Total partidas activo: </b>" {(this.state.datosEmpresa?(this.state.datosEmpresa.totalPartidas?this.state.datosEmpresa.totalPartidas:0.00):0.00)+ " €"} </p>
    //                 <p><b>Importe neto anual de la cifra denegocio: </b> {(this.state.datosEmpresa?(this.state.datosEmpresa.cifraAnual?this.state.datosEmpresa.cifraAnual:0.00):0.00)+ " €"} </p>
    //                 <p><b>Número medio de trabajadores: </b> {(this.state.datosEmpresa?(this.state.datosEmpresa.numTrabajadores?this.state.datosEmpresa.numTrabajadores:0.00):0.00)+ " €"} </p>
    //             <p><b>Créditos y garantías a socios y administradores en el último ejercicio:</b></p>
    //             {this.state.garantias?this.state.garantias.map(garantia=>{
    //                 return (<p>{`- Crédito concedido a ${garantia.nombre} el ${moment(garantia.fecha).format("DD-MM-YYYY")} por importe de ${garantia.CANTIDAD} €`}</p>)
    //             }):<></>}
                
    //             <p>
    //                 <b>Pólizas de seguro:</b>
    //             </p>
    //             {this.state.polizas?this.state.polizas.map(poliza=>{
    //                 return (
    //                     <>
    //                         <p><b>Compañía aseguradora: </b>{`${poliza.ASEGURADORA.DESCRIPCION}`} € </p>
    //                         <p><b>capital asegurado: </b>{`${poliza.CAPITAL_ASEGURADO}`} € </p>
    //                         <p><b>tipo deseguro: </b>{`${poliza.TIPO_SEGURO.DESCRIPCION}`} € </p>
    //                     </>
    //                     )
    //             }):<></>}

    //             <p>
    //                 <b>Modificaciones estatutarias: </b>{`${this.state.numMod?this.state.numMod:0} modificaciones en el ejercicio`}
    //             </p>

    //             <br />
    //             <br />
    //             <br />
    //             <br />

    //             <p>
    //                 <b>ÓRGANO DE ADMINISTRACIÓN</b>
    //             </p>

    //             <p>
    //                 <b>FORMA DE ADMINISTRACIÓN: </b>{this.state.organo?this.state.organo.desc:""}
    //             </p>
    //             <p><b>FRECUENCIA DE REUNIONES: </b>{this.state.organo && this.state.organo.FRECUENCIA_REUNIONES?this.formatearPeriodicidad(this.state.organo.FRECUENCIA_REUNIONES):""}</p>
    //             <p>
    //                 <b>RELACIÓN DE MIEMBROS: </b>
    //             </p>
    //             {this.state.miembrosOrgano?this.state.miembrosOrgano.nosuplentes.map(miembro=>{
    //                 return (<p>- {miembro.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Relación de suplentes: </b>
    //             </p>
    //             {this.state.miembrosOrgano?this.state.miembrosOrgano.suplentes.map(miembro=>{
    //                 return (<p>- {miembro.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Relación de comités consultivos: </b>
    //             </p>
    //             {this.state.comites?this.state.comites.map(comite=>{
    //                 return (<p>- {comite.DESCRIPCION}</p>)
    //             }):<></>}

    //             <p>
    //                 <b>ÓRGANO DE COMPLIANCE</b>
    //             </p>

    //             <p>
    //                 <b>ASUMIDO POR EL ÓRGANO DE ADMINISTRACIÓN: </b>{this.state.organoCompliance?(this.state.organoCompliance.asumido===true?"Si":"No"):""}
    //             </p>
    //             <p>
    //                 <b>Relación de miembros: </b>
    //             </p>
    //             {this.state.organoCompliance?this.state.organoCompliance.miembros.map(organo=>{
    //                 return (<p>- {organo.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>PROFESIONALES EXTERNOS:</b>
    //             </p>
    //             {this.state.organoCompliance?this.state.organoCompliance.profesionales.map(organo=>{
    //                 return (<p>- {organo.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>DELEGADOS AUTORIZADOS: </b>
    //             </p>
    //             {this.state.organoCompliance?this.state.organoCompliance.delegados.map(organo=>{
    //                 return (<p>- {organo.nombre}</p>)
    //             }):<></>}

    //             <p>
    //                 <b>ACTIVIDADES E INSTALACIONES</b>
    //             </p>
    //             <p>
    //                 <b>DATOS RELATIVOS A LAS ACTIVIDADES</b>
    //             </p>

    //             <p>
    //                 <b>OBJETO SOCIAL RACIÓN: </b>{this.state.actividades?this.state.actividades.OBJETO_SOCIAL:""}
    //             </p>
    //             <p>
    //                 <b>PRODUCTOS QUE SE OFRECEN: </b>{this.state.actividades?this.state.actividades.PRODUCTOS:""}
    //             </p>
    //             <p>
    //                 <b>SERVICIOS QUE SE PRESTAN: </b>{this.state.actividades?this.state.actividades.SERVICIOS:""}
    //             </p>
    //             <p>
    //                 <b>CANALES DE DISTRIBUCIÓN: </b>{this.state.actividades?this.state.actividades.CANALES:""}
    //             </p>
    //             <p>
    //                 <b>ZONAS GEOGRÁFICAS: </b>{this.state.actividades?this.state.actividades.ZONAS:""}
    //             </p>
    //             <p>
    //                 <b>ACTIVIDADES DE RIESGO INTRÍNSECO: </b>{this.state.actividades?this.state.actividades.RIESGO_INTRINSECO:""}
    //             </p>
    //             <p>
    //                 <b>ACTIVIDADES CON RIESGO PARA EL ENTORNO: </b>{this.state.actividades?this.state.actividades.RIESGO_ENTORNO:""}
    //             </p>
    //             <p>
    //                 <b>ACTIVIDADES CON IMPACTO MEDIOAMBIENTAL: </b>{this.state.actividades?this.state.actividades.RIESGO_MEDIOAMBIENTAL:""}
    //             </p>

    //             <p>
    //                 <b>Datos relativos a las instalaciones</b>
    //             </p>
    //             <p>
    //                 <b>INSTALACIONES FIJAS: </b>{this.state.instalaciones?this.state.instalaciones.FIJAS:""}
    //             </p>
    //             <p>
    //                 <b>INSTALACIONES MÓVILES: </b>{this.state.instalaciones?this.state.instalaciones.MOVILES:""}
    //             </p>
    //             <p>
    //                 <b>INSTALACIONES ESPECIALES: </b>{this.state.instalaciones?this.state.instalaciones.ESPECIALES:""}
    //             </p>
    //             <p>
    //                 <b>ELEMENTOS DE TRANSPORTE: </b>{this.state.instalaciones?this.state.instalaciones.TRANSPORTE:""}
    //             </p>
    //             <p>
    //                 <b>MAQUINARIA: </b>{this.state.instalaciones?this.state.instalaciones.MAQUINARIA:""}
    //             </p>
    //             <p>
    //                 <b>EQUIPOS INFORMÁTICOS: </b>{this.state.instalaciones?this.state.instalaciones.INFORMATICOS:""}
    //             </p>
    //             <p>
    //                 <b>SOFTWARE: </b>{this.state.instalaciones?this.state.instalaciones.SOFTWARE:""}
    //             </p>

    //             <p>
    //                 <b>RESPONSABILIDAD SOCIAL CORPORATIVA</b>
    //             </p>

    //             <p>
    //                 <b>RELACIÓN DE MEMORIAS:</b>
    //             </p>
    //             {this.state.memorias?this.state.memorias.map(memoria=>{
    //                 return (<p>- {memoria.descripcion}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Relación de informes: </b>
    //             </p>
    //             {this.state.informes?this.state.informes.map(informe=>{
    //                 return (<p>- {informe.descripcion}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>RELACIÓN DE PLANES DE IGUALDAD: </b>
    //             </p>
    //             {this.state.planesIgualdad?this.state.planesIgualdad.map(plan=>{
    //                 return (<p>- {plan.descripcion}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Relación de planes de movilidad: </b>
    //             </p>
    //             {this.state.planesMovilidad?this.state.planesMovilidad.map(plan=>{
    //                 return (<p>- {plan.descripcion}</p>)
    //             }):<></>}

    //             <p>
    //                 <b>PARTES INTERESADAS</b>
    //             </p>

    //             <p>
    //                 <b>PERSONAL DE ALTA DIRECCIÓN:</b>
    //             </p>
    //             {this.state.personalAltaDireccion?this.state.personalAltaDireccion.map(personal=>{
    //                 return (<p>- {personal.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>APODERADOS:</b>
    //             </p>
    //             {this.state.apoderados?this.state.apoderados.map(apoderado=>{
    //                 return (<p>- {apoderado.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Acreedores diversos:</b>
    //             </p>
    //             {this.state.acreedores?this.state.acreedores.map(acreedor=>{
    //                 return (<p>- {acreedor.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>SERVICIOS INDEPENDIENTES EMPRESARIALES:</b>
    //             </p>
    //             {this.state.servicios?this.state.servicios.map(servicio=>{
    //                 return (<p>- {servicio.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Asesorías externa: </b>
    //             </p>
    //             {this.state.asesorias?this.state.asesorias.map(asesoria=>{
    //                 return (<p>- {asesoria.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>AUDITORES: </b>
    //             </p>
    //             {this.state.auditores?this.state.auditores.map(auditor=>{
    //                 return (<p>- {auditor.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>FONDOS DE INVERSIÓN (CAPITAL RIESGO): </b>
    //             </p>
    //             {this.state.fondos?this.state.fondos.map(fondo=>{
    //                 return (<p>- {fondo.nombre}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>SOCIOS GESTORES (CAPITAL RIESGO): </b>
    //             </p>
    //             {this.state.socios?this.state.socios.map(socio=>{
    //                 return (<p>- {socio.nombre}</p>)
    //             }):<></>}

    //             <p>
    //                 <b>ACUERDOS PARASOCIALES VIGENTES</b>
    //             </p>

    //             <p>
    //                 <b>PACTOS DE BLOQUEO:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.pactosBloqueo.map(pacto=>{
    //                 return (<p>-FECHA VIGENCIA:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},RESULTADO VOTACIÓN:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>PACTOS DE DEFENSA O CONTROL:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.pactosDefensa.map(pacto=>{
    //                 return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Pactos de no agresión:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.pactosNoAgresion.map(pacto=>{
    //                 return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Acuerdos de conversión y de salida:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.acuerdosConversion.map(pacto=>{
    //                 return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Pactos de atribución:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.pactosAtribucion.map(pacto=>{
    //                 return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Pactos de organización:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.pactosOrganizacion.map(pacto=>{
    //                 return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>Otros pactos:</b>
    //             </p>
    //             {this.state.pactos?this.state.pactos.otrosPactos.map(pacto=>{
    //                 return (<p>-Fecha Vigencia:{moment(pacto.FECHA_VIGENCIA).format("DD-MM-YYYY")},Fecha Vencimiento:{pacto.FECHA_VENCIMIENTO?moment(pacto.FECHA_VENCIMIENTO).format("DD-MM-YYYY"):"No definida"},Resultado votación:{pacto.RESULTADO_VOTACION}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>JOINT VENTURES:</b>
    //             </p>
    //             {this.state.jointVentures?this.state.jointVentures.map(joint=>{
    //                 return (<p>-Fecha Vigencia:{moment(joint.VIGENCIA_INICIO).format("DD-MM-YYYY")},Fecha Vencimiento:{joint.VIGENCIA_FIN?moment(joint.VIGENCIA_FIN).format("DD-MM-YYYY"):"No definida"},Resultado votación:{joint.NOMBRE_PROYECTO}</p>)
    //             }):<></>}
    //             <p>
    //                 <b>INVERSIÓN DE CAPITAL RIESGO:</b>
    //             </p>
    //             {this.state.privates?this.state.privates.map(equity=>{
    //                 return (<p>-Fecha Vigencia:{moment(equity.VIGENCIA_INICIO).format("DD-MM-YYYY")},Fecha Vencimiento:{equity.VIGENCIA_FIN?moment(equity.VIGENCIA_FIN).format("DD-MM-YYYY"):"No definida"},Resultado votación:{equity.NOMBRE_PROYECTO}</p>)
    //             }):<></>}

    //             <p>
    //                 <b>COMISIONES</b>
    //             </p>
    //             {this.state.privates?this.state.comisiones.map(comision=>{
    //                 return (<p>{`- ${comision.NOMBRE}
    //                 Tipo:${comision.TIPO_COMISION.DESCRIPCION}
    //                 Miembros:${comision.miembros}`}
                    
    //                 </p>)
    //             }):<></>}
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p><p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </div>
    // );

    render() {

        document.title ="LexControl | Informe Anual del gobierno corporativo"

        const cerrarModalImprimir=()=>{
            this.setState({modalImprimir:false})
        }

        return (
            <>
            {this.state.cargandoIA===true?
                <Cargando/>
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe anual"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '500px'}} id='generarIA-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <div className="tablaSesenta">
                <Card interactive={true} elevation={Elevation.TWO} id="informe-anual">
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Informe anual del gobierno corporativo</Callout>
                    <FormGroup
                        label="Para generar el documento, pulse aqui: "
                        labelFor="informacion"
                        inline={true}
                    >

                        <Button className="bp3-minimal" icon="print" onClick={()=>this.generarImprimirIA()}>Imprimir libro socios</Button>
                             {/* <ReactToPrint
                                trigger={() => <Button className="bp3-minimal" icon="print">Imprimir libro socios</Button>}
                            content={() => this.pdfInformeAnual}
                             /> */}


                    </FormGroup>
                </Card>
            </div>
            <div className="tablaCuarenta">
            <Card interactive={true} elevation={Elevation.TWO}>
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">BOE-A-2013-6805</Callout>
                    <a role="button" target="_blank"  rel="noopener noreferrer" href={Documento1} class="bp3-button bp3-icon-git-repo">Orden ECC/461/2013, de 20 de marzo</a>
                    <a role="button" target="_blank"  rel="noopener noreferrer" href={Documento2} class="bp3-button bp3-icon-git-repo">Circular 5/2013, de 12 de junio CNMV</a>
                </Card>
            </div>
            </>
            }
          </>  
        )
    }
}

export default withApollo(InformeAnual)