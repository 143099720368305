import React from "react"
import { Card, Elevation, FormGroup, InputGroup,Button,Tooltip,AnchorButton,Dialog,Classes,Intent} from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class EmpleadosInformacionContractual extends React.Component {

    state={
        tipoContrato: [
        ],
        modalContratoAbierto:false,
        contrato:null,
        contratoSeleccionado:null,
        parcialidad:null,
        categoria:null,
        ubicacion:null,
        clave_contrato:null,
        grupo_cotizacion:null,
        temporalidad:null,
        codigoEmpleado:null
    }

    cargarContratos=async()=>{

        client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });

        //cargar de los tipos de contrato
        await client
        .query({
            query: gql` 
                {tiposContrato{ID_TIPO_CONTRATO,DESCRIPCION}}
            `
        }).then(result=>{ 

            let contratos=[]
            result.data.tiposContrato.forEach(contrato=>{
                contratos.push({"value":contrato.ID_TIPO_CONTRATO,"label":contrato.DESCRIPCION })
            })
            this.setState({tipoContrato:contratos})

        })

    }

    componentDidMount(props){
        this.cargarContratos()
    }
    render(){

        const seleccionContrato  = (value) => { 
            this.setState({contratoSeleccionado: value})
        }
        
        const abrirModalContrato=()=>{
            this.setState({modalContratoAbierto:true})
        }

        const cerrarModal=()=>{
            this.setState({modalContratoAbierto:false})   
        }

        const handlecontrato = (e) => {
            this.setState({contrato:e.currentTarget.value})
        }

        const handleCambiarParcialidad = (e) => {
            this.setState({parcialidad:e.currentTarget.value})
        }

        const handleCambiarCategoria = (e) => {
            this.setState({categoria:e.currentTarget.value})
        }

        
        const handleCambiarUbicacion= (e) => {
            this.setState({ubicacion:e.currentTarget.value})
        }

        const handleCambiarClaveContrato= (e) => {
            this.setState({clave_contrato:e.currentTarget.value})
        }

        const handleCambiarGrupoCotizacion= (e) => {
            this.setState({grupo_cotizacion:e.currentTarget.value})
        }

        const handleCambiarTemporalidad = (e) => {
            this.setState({temporalidad:e.currentTarget.value})
        }
        
        const anadirContrato=async()=>{

            if(!this.state.contrato){
                return
            }
            
        
           //mutation apra crear la persona fisica.
            await client.mutate({
                mutation:gql`mutation{crearTipoContrato(VALORES:{DESCRIPCION:"${this.state.contrato}"}){ID_TIPO_CONTRATO,DESCRIPCION}}`
            }).then(async result=>{
             
                await this.cargarContratos()
                let con=await this.state.tipoContrato.find(c=>{
                    return c.value===result.data.crearTipoContrato.ID_TIPO_CONTRATO
                })
                this.setState({contratoSeleccionado:con})
                cerrarModal()
                
            }).catch(err=>{
                alert("Ha ocurrido un error al crear el tipo de contrato.Pongase en contacto con el soporte técnico.")
            })

        }

        return (
            <>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Crear tipo de contrato"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalContratoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Introduzca el nombre del contrato  y pulse aceptar"
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.contrato?"":"Debe insertar el contrato"}
                        >
                            <InputGroup id="texto-nombre-contrato" onChange={handlecontrato}  placeholder="Escriba aqui el nombre del contrato" intent={this.state.contrato?"primary":"danger"} />
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Agregar contrato." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirContrato}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label="Tipo de contrato:"
                            labelFor="tipo-contrato"
                        >
                            <Select
                                options={this.state.tipoContrato}
                                className="pixelAlrededor"
                                onChange={seleccionContrato}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                value={this.state.tipoContrato.filter(contrato => contrato.value === (this.state.contratoSeleccionado?this.state.contratoSeleccionado.value?this.state.contratoSeleccionado.value:this.state.contratoSeleccionado:0))}
                                />
                            <Button 
                                alignText="right"
                                icon="add"
                                className="iconoIzquierda"
                                onClick={abrirModalContrato}
                            />    
                        </FormGroup>
                        <FormGroup
                            label="Clave de Contrato:"
                            labelFor="clave_contrato"
                        >
                            <InputGroup id="clave_contrato" value={this.state.clave_contrato?this.state.clave_contrato:''} onChange={handleCambiarClaveContrato} />
                        </FormGroup>
                        <FormGroup
                            label="Grupo de cotización:"
                            labelFor="grupo_cotizacion"
                        >
                            <InputGroup id="grupo_cotizacion" value={this.state.grupo_cotizacion?this.state.grupo_cotizacion:''} onChange={handleCambiarGrupoCotizacion} />
                        </FormGroup>
                        <FormGroup
                            label="Parcialidad:"
                            labelFor="parcialidad"
                        >
                            <InputGroup id="parcialidad" value={this.state.parcialidad?this.state.parcialidad:''} onChange={handleCambiarParcialidad} />
                        </FormGroup>
                        <FormGroup
                            label="Categoría:"
                            labelFor="categoria"
                        >
                            <InputGroup id="categoria" value={this.state.categoria?this.state.categoria:''} onChange={handleCambiarCategoria}/>
                        </FormGroup>
                        <FormGroup
                            label="Ubicación:"
                            labelFor="ubicacion"
                        >
                            <InputGroup id="ubicacion" value={this.state.ubicacion?this.state.ubicacion:''} onChange={handleCambiarUbicacion} />
                        </FormGroup>
                        <FormGroup
                            label="Temporalidad:"
                            labelFor="temporalidad"
                        >
                            <InputGroup id="temporalidad" value={this.state.temporalidad?this.state.temporalidad:''} onChange={handleCambiarTemporalidad} />
                        </FormGroup>
                        {/*<FormGroup
                            label="Código de empleado:"
                            labelFor="codigo-empleado"
                        >
                            <InputGroup id="codigo-empleado" value={this.state.codigoEmpleado} onChange={handleCambiarCodigoEmplreado} />
                        </FormGroup>*/}
                    </Card>
                </div>
            </>
        )
    }
}