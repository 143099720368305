/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, Elevation, Navbar,Spinner, ButtonGroup, Alignment,Position,Button, Tree, Dialog, Classes, AnchorButton, Tooltip, Intent, Callout, Tabs, Tab } from "@blueprintjs/core"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import moment from "moment"
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import PuestosPropiedades from "./Elementos/PuestosPropiedades"
import PuestosFactores from "./Elementos/PuestosFactores";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';



import styles from './stylesPrint';
import { isEmpty } from 'lodash';
  

export interface ITreeExampleState {
    areas: ITreeNode[];
}
class CatalogoPuestos extends Component {


    state={
        modalImprimir: false,
        modalImprimirAD: false,
        modalImprimirPG: false,

        cargandoImprimir:false,

        imprimirPActivado:false,
        imprimirGralActivado:false,


        datosEmpresa:null,
        logotipo : null,

        imprimirActivado: false,
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        puestosTablaGral: [],

        puestos: [],
        valorSlider: 1,
        gradoResponsabilidad: 1,
        areas: [],
        areasDialog: [],
        riesgoSeleccionado: null,
        areaSeleccionada:null,
        departamentoSeleccionado:null,
        nombrePuesto:null,
        codigoPuesto:null,
        descripcionBreve:null,
        descripcionDetallada:null,
        riesgoOptions:[],
        puestoSeleccionado:null,
        tareas:[],
        conocimientosAptitudes:null,
        conocimientosInformaticos:null,
        valorDestreza:null,
        polivalenciaCreatividad:null,
        valorExperiencia:null,
        posturasForzadas:null,
        movimientosRepetitivos:null,
        valorConcentracion:null,
        dificultadTecnica:null,
        organizacionTrabajo:null,
        responsabilidadMaquinaria:null,
        valorJefatura:null,
        iniciativaPolivalencia:null,
        valorContactoClientes:null,
        trabajoDistancia:null,
        polivalenciaExigida:null,
        riesgoPsicosocial:null,
        valorEsfuerzo:null,
        valorResponsabilidad:null,
        valorCondiciones:null,
        centroTrabajo:null,
        generoSeleccionadoConocimientosAptitudes:null,
        generoSeleccionadoEsfuerzo:null,
        generoSeleccionadoResponsabilidad:null,
        generoSeleccionadoCondiciones:null,
        generos:[],
        modalPuestoDetalles:false,
        pasoGuia:0,
        columnWidths: [100,100,100,100,100],
        resized: false,
        steps: [
            {
              target: '#descripcion-arbol',
              content: 'En este arbol seleccionamos el departamento al que queramos añadirle un departamento.',
              selector: '#descripcion-arbol',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 1,
            },
            {
              target: '#descripcion-puestos',
              content: 'En este cuadro creamos los puestos relacionados con el departamento que seleccionamos antes.',
              selector: '#descripcion-puestos',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 2,
            },
            {
              target: '#nombre-puesto',
              content: 'Creamos su nombre.',
              selector: '#nombre-puesto',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 3,
            },
            {
              target: '#grado-responsabilidad',
              content: 'Asignamos su grado de responsabilidad.',
              selector: '#grado-responsabilidad',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 4,
            },
            {
              target: '#riesgo-objetivo',
              content: 'El riesgo que puede tener para la empresa este puesto.',
              selector: '#riesgo-objetivo',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 5,
            },
            {
              target: '#descripcion',
              content: 'Así como una descripción del mismo.',
              selector: '#descripcion',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 6,
            },
          ],
          cargando: false,
    }

    // se Obtiene logo empresa (state.logotipo()
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{
                    NOMBRE:"LOGOTIPO_EMPRESA",
                    ID_ENTIDAD_ASOCIADA:12,
                    ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    // se cargan los datos de la empresa
    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
       
        return datosEmpresa
    }
    // se cargan los puestos de trabajo (state. puestos)
    cargarPuestosDep=async(id)=>{
        
        let puestos=await this.props.client.query({
            query:gql`{puestosTrabajo(FILTROS:{ID_DEPARTAMENTO:${id}}){
                ID_PUESTO,
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION},
                DEPARTAMENTO{AREA{DESCRIPCION}}
                DEPARTAMENTO{ID_AREA}DESCRIPCION,
                GRADO_RESPONSABILIDAD,
                ID_RIESGO_OBJETIVO,
                RIESGO_OBJETIVO{DESCRIPCION},
                CODIGO_PUESTO,
                DESC_CORTA,
                DESC_LARGA,
                NUMERO_TRABAJADORES,
                TAREAS,
                CONOCIMIENTOS_APTITUDES,
                RESPONSABILIDAD,
                ESFUERZO,
                CONDICIONES_TRABAJO,
                DESTREZA_EQUIPOS,
                CONOCIMIENTOS_INFORMATICOS,
                POLIVALENCIA,
                EXPERIENCIA,
                POSTURAS_FORZADAS,
                MOVIMIENTOS_REPETITIVOS,
                CONCENTRACION,
                DIFICULTAD_TECNICA,
                ORGANIZACION_TRABAJO,
                RESPONSABILIDAD_MAQUINARIA,
                JEFATURA,
                INICIATIVA_POLIVALENCIA,
                CONTACTO_CLIENTES,
                TRABAJO_DISTANCIA,
                POLIVALENCIA_EXIGIDA,
                RIESGO_PSICOSOCIAL,
                GENERO_CONOCIMIENTO,
                GENERO_ESFUERZO,
                GENERO_RESPONSABILIDAD,
                GENERO_CONDICIONES,
                CENTRO_TRABAJO
            }}`,
            fetchPolicy:'network-only'
        }).then(async (result) => {
            return result.data.puestosTrabajo
        })

        this.setState({puestos:puestos})
        
    }
    // se obtineen las areas(state.areas state.areasdialog)
    cargarAreas=async()=>{

        let areas=[]
        let areasPredef=[]

        // obtencion de las areas
        await this.props.client.query({
            query:gql`{departamentosAreas{ID_AREA,DESCRIPCION,PREDEFINIDA}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

           
            result.data.departamentosAreas.forEach(async(area)=>{
                if(!area.PREDEFINIDA){
                    areas.push({id:area.ID_AREA,value:area.ID_AREA,label:"A"+area.ID_AREA+" - "+area.DESCRIPCION,tipo:"area"})
                }else{
                    areasPredef.push({id:area.ID_AREA,value:area.ID_AREA,label:"A"+area.ID_AREA+" - "+area.DESCRIPCION,tipo:"area"})
                }
            })  
            this.setState({
                areas:areas,
                areasDialog: areasPredef
            })
             
        })
     
        //obtencion de los departamentos
        areasPredef.forEach(async (area,index)=>{

            await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
    
                let deps=[]
                result.data.departamentos.forEach(async(dep)=>{
                    deps.push({id:dep.ID_DEPARTAMENTO,label:"D"+dep.ID_DEPARTAMENTO+" - "+dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                })  
                areasPredef[index].childNodes=deps
               
            })

        })

        areas.forEach(async (area,index)=>{

            await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
    
                let deps=[]
                result.data.departamentos.forEach(async(dep)=>{
                    deps.push({id:dep.ID_DEPARTAMENTO,label:"D"+dep.ID_DEPARTAMENTO+" - "+dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                })  
                areas[index].childNodes=deps
               
            })

        })
      
    }
    // se cargan los riesgos
    cargarRiesgos= async() => {
         //carga riesgos (select colores)
         this.props.client
         .query({
             query: gql` 
                 {perfilesRiesgo{ID_PERFIL_RIESGO,DESCRIPCION}}
             `
         }).then(result=>{ 
 
             let riesgos=[]
             
             result.data.perfilesRiesgo.forEach(riesgo=>{
                 let color=""
                 switch(riesgo.ID_PERFIL_RIESGO){
                     case 1:
                         color="green"
                     break;
                     case 2:
                         color="yellow"
                     break;
                     case 3:
                         color="orange"
                     break;
                     case 4:
                         color="red"
                     break;
 
                     default:
                     break;
                 }
                 riesgos.push({"value":riesgo.ID_PERFIL_RIESGO,"label":riesgo.DESCRIPCION,color})
             })
             this.setState({riesgoOptions:riesgos})
                   
 
         })
    }
    // se cargan los datos de la empres (state.datosEmpres)
    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }
    //se craga el color segun el riego (state.colorRiego)
    cargarRiesgoColor = (data) => {
      
        let color="";
        switch(data){
            case 1:
                color="green"
            break;
            case 2:
                color="yellow"
            break;
            case 3:
                color="orange"
            break;
            case 4:
                color="red"
            break;
            
            default:
            break;
        }
        return color;
      }
       //   carga los puestos por Areas
       cargarPuestos = async () => {
        if (this.state.areas !== null && this.state.areas.length > 0) {  
            await Promise.all(this.state.areas.map(async (area) => {
                if (area.childNodes.length > 0) {
                    await Promise.all(area.childNodes.map(async (child) => {
                        await this.cargarPuestoGral(child.id);
                    }));
                }
            }));  
        }
    }
    
    // carga los puestos de los dep (state.puestosTablaGral)
    cargarPuestoGral= async(idDep) =>{
        const result = await this.props.client.query({
            query: gql`
            {puestosTrabajo(FILTROS:{ID_DEPARTAMENTO:${idDep}}){
                ID_PUESTO,
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION},
                DEPARTAMENTO{AREA{DESCRIPCION}}
                DEPARTAMENTO{ID_AREA}DESCRIPCION,
                GRADO_RESPONSABILIDAD,
                ID_RIESGO_OBJETIVO,
                RIESGO_OBJETIVO{DESCRIPCION},
                CODIGO_PUESTO,
                DESC_CORTA,
                DESC_LARGA,
                NUMERO_TRABAJADORES
            }}
            `, fetchPolicy:'network-only'
        });

        const puestosDep = result.data.puestosTrabajo;
        console.log(puestosDep)
        if (!isEmpty(puestosDep)) {
            this.setState(prevState => ({
                puestosTablaGral: [...prevState.puestosTablaGral, puestosDep]
            }));
        }
        
    }
    


    cargarGeneros=async()=>{
        let generos=[]
        generos.push({"label":i18next.t("catalogopuestos.female",{ns:"page2"}),"value":"Femenino"})
        generos.push({"label":i18next.t("catalogopuestos.male",{ns:"page2"}),"value":"Masculino"})
        generos.push({"label":i18next.t("catalogopuestos.neutral",{ns:"page2"}),"value":"Neutro"})

        this.setState({generos:generos})

    }

   
    componentDidMount(){

        this.setState({ cargando: true })

        this.cargarRiesgos()
        this.cargarAreas()
        this.obtenerLogoEmpresa()
        this.cargarGeneros()

        this.setState({cargando: false})
    }  
   
    // documentos a imprmir
    // imprime puestos por depto
    Puestos = () => {
      let total=0;
      let color='';
      return (
        
        <PDFViewer style={{width: '100%', height: '100%'}}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.body}>
                  <View fixed style={styles.row}>
                    <Image
                      style={styles.imageHead}
                      src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                      />
                  </View>
                  <View fixed style={styles.rowHead}>
                      <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                  </View>
                  <View fixed style={styles.rowHeadTwo}>
                      <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{i18next.t("catalogopuestos.title",{ns:"page2"}).toUpperCase()}</Text>
                  </View>
                  <View style={styles.rowTitleOne}>
                      <Text style={styles.labelTitle}>{i18next.t("catalogopuestos.titleOne",{ns:"page2"}).toUpperCase()}</Text>
                  </View>
                  {this.state.puestos?this.state.puestos.map((puesto, index) => {
                      total = total + puesto.NUMERO_TRABAJADORES;
                      color=this.cargarRiesgoColor(puesto.ID_RIESGO_OBJETIVO)
                      return(
                          
                          <View key={index}>
                              {index===0 && (
                                  <View style={styles.rowHeadGral}>
                                      <View style={styles.labelA}>
                                          <Text style={styles.labelDataA}>{i18next.t("catalogopuestos.area",{ns:"page2"}).toUpperCase()}:</Text>
                                          <Text style={styles.valueDataA}>{puesto.DEPARTAMENTO.AREA.DESCRIPCION}</Text> 
                                      </View>
                                      <View style={styles.labelD}>
                                          <Text style={styles.labelDataD}>{i18next.t("catalogopuestos.department",{ns:"page2"}).toUpperCase()}:</Text>
                                          <Text style={styles.valueDataD}>{puesto.DEPARTAMENTO.DESCRIPCION}</Text> 
                                      </View>
                                  </View>
                              )}
                                     
                              {index===0 && (
                                  <View style={styles.colHead}>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("catalogopuestos.number",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("catalogopuestos.designation",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'14%'}}>{i18next.t("catalogopuestos.code",{ns:"page2"}).toUpperCase()}</Text>
                                     
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("catalogopuestos.risk",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'18%'}}>{i18next.t("catalogopuestos.responsibilityLavel",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'18%'}}>{i18next.t("catalogopuestos.numberOfEmployee",{ns:"page2"}).toUpperCase()}</Text>
                                  </View>                               
                              )}
                             
                              <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{index+1}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{puesto.DESCRIPCION}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'14%'}}>{puesto.CODIGO_PUESTO}</Text>
                                    <Text style={{backgroundColor:`${color}`, textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{puesto.RIESGO_OBJETIVO.DESCRIPCION}</Text>
                                 
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'18%'}}>{puesto.GRADO_RESPONSABILIDAD}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'18%'}}>{puesto.NUMERO_TRABAJADORES}</Text>
                              </View> 
                                             
                              
                          </View>
                          
                      )
                  }):<></>}
                  
                  <View style={styles.rowDown}>
                        <Text style={styles.labelDataT}>{i18next.t("catalogopuestos.totalEmployee",{ns:"page2"}).toUpperCase()}</Text>
                        <Text style={styles.valueDataT}>{total}</Text> 
                                      
                </View>                 
              </View>
              <Text
              style={styles.footerPage}
              render={({ pageNumber, totalPages }) => `${i18next.t("page", { ns: "global" })} ${pageNumber} ${i18next.t("de", { ns: "global" })} ${totalPages}`}
              fixed
              />
              <Image
                      style={styles.icono}
                      src={iconoFooter}
                      fixed
                      />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
          </Document>
        </PDFViewer>
      )
    }
    //imprime areas y dptos   
    ImprimirAD=  () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("catalogopuestos.titleOne",{ns:"page2"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("catalogopuestos.areasAndDepartments",{ns:"page2"}).toUpperCase()}</Text>
                </View>
                {this.state.areas?this.state.areas.map((area, index)=>{
                        return (
                            <View style={styles.viewArea}>
                                <Text key={index} style={styles.textDataAreas}>{area.label}</Text>
                                {this.state.areas[index].childNodes.map((child, indexC)=>{
                                    return (
                                        <Text key={indexC} style={styles.textDataDepto}>{child.label}</Text>
                                    )
                                })}
                            </View>
                        )
                    }):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    // imprime todas los puestos de todas las areas
    ImprimirGral = () => {
        console.log(this.state.puestosTablaGral)
      let total = 0;
      let color="";
      return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("catalogopuestos.title",{ns:"page2"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("catalogopuestos.titleOne",{ns:"page2"}).toUpperCase()}</Text>
                </View>
                {this.state.puestosTablaGral?this.state.puestosTablaGral.map((puestos, indexG)=>{
                    total=0;
                    return (
                        <View key={indexG}>
                    {puestos.map((puesto, index)=>{
                        total = total + puesto.NUMERO_TRABAJADORES;
                        color=this.cargarRiesgoColor(puesto.ID_RIESGO_OBJETIVO)
                        return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.rowHeadGral}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>{i18next.t("catalogopuestos.area",{ns:"page2"}).toUpperCase()}</Text>
                                        <Text style={styles.valueDataA}>{puesto.DEPARTAMENTO.AREA.DESCRIPCION}</Text>
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>{i18next.t("catalogopuestos.department",{ns:"page2"}).toUpperCase()}</Text>
                                        <Text style={styles.valueDataD}>{puesto.DEPARTAMENTO.DESCRIPCION}</Text>
                                    </View>
                                </View>
                                )}
                                {index===0 && (
                                <View style={styles.colHead}>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("catalogopuestos.number",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("catalogopuestos.designation",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'14%'}}>{i18next.t("catalogopuestos.code",{ns:"page2"}).toUpperCase()}</Text>                                     
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("catalogopuestos.risk",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'18%'}}>{i18next.t("catalogopuestos.responsibilityLavel",{ns:"page2"}).toUpperCase()}</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'18%'}}>{i18next.t("catalogopuestos.numberOfEmployee",{ns:"page2"}).toUpperCase()}</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{index+1}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{puesto.DESCRIPCION}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'14%'}}>{puesto.CODIGO_PUESTO}</Text>
                                    <Text style={{backgroundColor:`${color}`, textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{puesto.RIESGO_OBJETIVO.DESCRIPCION}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'18%'}}>{puesto.GRADO_RESPONSABILIDAD}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'18%'}}>{puesto.NUMERO_TRABAJADORES}</Text>
                                </View>
                                
                            </View>
                        )
                    })}
                      {puestos.length>0 && (
                      <View style={styles.rowDown}>
                        <Text style={styles.labelDataT}>{i18next.t("catalogopuestos.totalEmployee",{ns:"page2"}).toUpperCase()}</Text>
                        <Text style={styles.valueDataT}>{total}</Text> 
                                      
                      </View>
                      )}
                    </View>
                    )
                }):<></>}
                
            </View>

            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
      )
    }
    // genera Puestos
    generarInformePuestos= async () => {
        if(!this.state.departamentoSeleccionado){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true
        }, async () => {
            if(this.state.datosEmpresa==null){
                await this.cargarDatos();
                ReactDOM.render(this.Puestos(), document.getElementById('generar-pdf'))
            }else{
                this.setState({
                    cargandoImprimir:false,
                }, () =>{
                    ReactDOM.render(this.Puestos(),  document.getElementById('generar-pdf'))
                })
            }
        })
    }
    // genera ImprimirAD
    generarInformeAD= async () => {
        this.setState({
            modalImprimirAD:true,
            cargandoImprimir:true
        }, async () => {
            if(this.state.datosEmpresa==null){
                await this.cargarDatos();
                ReactDOM.render(this.ImprimirAD(), document.getElementById('generar-pdf-ad'))
            }else{
                this.setState({
                    cargandoImprimir:false
                },() => {
                    ReactDOM.render(this.ImprimirAD(), document.getElementById('generar-pdf-ad'))
                })
            }    
        })
    }
    // genra ImprimirGral
    generarInformeGral= async () => {
        this.setState({
            modalImprimirPG: true,
            cargandoImprimir: true,
            puestosTablaGral:[]
        }, async () => {
            await this.cargarPuestos();
            if(this.state.datosEmpresa===null){
                await this.cargarDatos();
            }
            this.setState({
                cargandoImprimir: false
            }, () => {
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generar-pdf-gral'))
            })
        })
    }

    obtenerCodigo=async(indicePuesto)=>{
        let codigo_puesto="";
        let indiceArea=await this.props.client
        .query({
            query: gql` 
                {departamentos(CAMPOS:"ID_AREA",FILTROS:{PREDEFINIDO:false,ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id}}){ID_AREA}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.departamentos[0].ID_AREA
        })
        codigo_puesto="A"+indiceArea+"D"+this.state.departamentoSeleccionado.id+"P"+indicePuesto;
        return codigo_puesto

    }

    render() {
        const {steps} = this.state;

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("catalogopuestos.title",{ns:"page2"});
       
        
        const cerrarModal=()=>{
            this.setState({
                modalPuestoDetalles: false,
                modalBorrarAbierto:false
            })
        }
        const cerrarModalImprimir=()=>{
            this.setState({modalImprimir: false})
            
        }
        const cerrarModalImprimirAD=() =>{
            this.setState({modalImprimirAD: false})
        }
        const cerrarModalImprimirPG=() =>{
            this.setState({modalImprimirPG: false})
        }

        const handleNodeClick = (nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {

            if(this.state.controlesDesactivados)return
            //limpiamos el path anterior
            if(this.state.pathSeleccionado){

                let areas=this.state.areas
                if(this.state.pathSeleccionado.length===1){
                    areas[this.state.pathSeleccionado].isSelected=false
                }else if(this.state.pathSeleccionado.length===2){
                    areas[this.state.pathSeleccionado[0]].childNodes[this.state.pathSeleccionado[1]].isSelected=false
                }                    
                
                
            }
            this.setState({pathSeleccionado:_nodePath})
            this.setState({areaSeleccionada:this.state.areas[_nodePath[0]]})
            nodeData.isSelected? nodeData.isSelected = false : nodeData.isSelected = true
            if(nodeData.tipo==="departamento"){
                this.setState({departamentoSeleccionado:nodeData})
                this.cargarPuestosDep(nodeData.id)
            }else{
                this.setState({departamentoSeleccionado:null})
                this.cargarPuestosDep(0)
            }
            
            
        }
        
        const handleNodeExpand = (nodeData: ITreeNode) => {
            if(this.state.controlesDesactivados)return

            nodeData.isExpanded = true;
            this.setState(this.state);
        }

        const handleNodeCollapse = (nodeData: ITreeNode) => {
            if(this.state.controlesDesactivados)return

            nodeData.isExpanded = false;
            this.setState(this.state);
        };
    
        
        /*
        const handleCambiarCodigoPuesto = (ev) => {
            this.setState({codigoPuesto: ev.currentTarget.value})
        }*/

        const guardarPuesto = async() => {

            if(!this.state.nombrePuesto)return
            
            //if(!this.state.codigoPuesto)return

            //comprobar que no exista un puesto con ese codigo
            /*
            let existe= await this.props.client
            .query({
                query: gql` 
                    {puestosTrabajo(FILTROS:{CODIGO_PUESTO:"${this.state.codigoPuesto}"}){CODIGO_PUESTO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.puestosTrabajo.length>0)return true
                return false
            })

            if(existe){
                alert("Ya existe un puesto con ese codigo.")
                return
            }*/

            //comprobar que no exista un puesto con ese nombre
            let existe2= await this.props.client
            .query({
                query: gql` 
                    {puestosTrabajo(FILTROS:{DESCRIPCION:"${this.state.nombrePuesto}"}){DESCRIPCION}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.puestosTrabajo.length>0)return true
                return false
            })

            if(existe2){
                alert(i18next.t("catalogopuestos.jobAlreadyExists",{ns:"page2"}))
                return
            }

            let data_aptitudes=this.state.conocimientosAptitudes?this.state.conocimientosAptitudes:0
            let data_responsabilidad=this.state.valorResponsabilidad?this.state.valorResponsabilidad:0
            let data_esfuerzo=this.state.valorEsfuerzo?this.state.valorEsfuerzo:0
            let data_condiciones=this.state.valorCondiciones?this.state.valorCondiciones:0
            
            let suma=parseFloat(data_aptitudes)+parseFloat(data_responsabilidad)+parseFloat(data_esfuerzo)+parseFloat(data_condiciones)

            if (suma!==100) {
                alert(i18next.t("catalogopuestos.addAlert",{ns:"page2"}))
                return
            }

            let tareasJson="["
            this.state.tareas.forEach((tarea,index)=>{
                if(index===0){
                    tareasJson+=`{"id":${tarea.id},"descripcion":"${tarea.descripcion}","valor":"${tarea.valor}"}`
                }else{
                    tareasJson+=`,{"id":${tarea.id},"descripcion":"${tarea.descripcion}","valor":"${tarea.valor}"}`
                }
            })
            tareasJson+="]"
            //creamos el puesto de trabajo.
            await this.props.client.mutate({
                mutation:gql`mutation{crearPuestoTrabajo(VALORES:{
                    ID_DEPARTAMENTO:${this.state.departamentoSeleccionado.id},
                    DESCRIPCION:"${this.state.nombrePuesto}",
                    GRADO_RESPONSABILIDAD:${this.state.gradoResponsabilidad},
                    ID_RIESGO_OBJETIVO:${this.state.riesgoSeleccionado.value},
                    DESC_CORTA:${this.state.descripcionBreve?'"'+this.state.descripcionBreve+'"':null},
                    TAREAS:${this.state.tareas.length>0?JSON.stringify(tareasJson):null},
                    CONOCIMIENTOS_APTITUDES:${this.state.conocimientosAptitudes?'"'+this.state.conocimientosAptitudes+'"':null},
                    RESPONSABILIDAD:${this.state.valorResponsabilidad?'"'+this.state.valorResponsabilidad+'"':null},
                    ESFUERZO:${this.state.valorEsfuerzo?'"'+this.state.valorEsfuerzo+'"':null},
                    CONDICIONES_TRABAJO:${this.state.valorCondiciones?'"'+this.state.valorCondiciones+'"':null},
                    DESTREZA_EQUIPOS:${this.state.valorDestreza?'"'+this.state.valorDestreza+'"':null},
                    CONOCIMIENTOS_INFORMATICOS:${this.state.conocimientosInformaticos?'"'+this.state.conocimientosInformaticos+'"':null},
                    POLIVALENCIA:${this.state.polivalenciaCreatividad?'"'+this.state.polivalenciaCreatividad+'"':null},
                    EXPERIENCIA:${this.state.valorExperiencia?'"'+this.state.valorExperiencia+'"':null},
                    POSTURAS_FORZADAS:${this.state.posturasForzadas?'"'+this.state.posturasForzadas+'"':null},
                    MOVIMIENTOS_REPETITIVOS:${this.state.movimientosRepetitivos?'"'+this.state.movimientosRepetitivos+'"':null},
                    CONCENTRACION:${this.state.valorConcentracion?'"'+this.state.valorConcentracion+'"':null},
                    DIFICULTAD_TECNICA:${this.state.dificultadTecnica?'"'+this.state.dificultadTecnica+'"':null},
                    ORGANIZACION_TRABAJO:${this.state.organizacionTrabajo?'"'+this.state.organizacionTrabajo+'"':null},
                    RESPONSABILIDAD_MAQUINARIA:${this.state.responsabilidadMaquinaria?'"'+this.state.responsabilidadMaquinaria+'"':null},
                    JEFATURA:${this.state.valorJefatura?'"'+this.state.valorJefatura+'"':null},
                    INICIATIVA_POLIVALENCIA:${this.state.iniciativaPolivalencia?'"'+this.state.iniciativaPolivalencia+'"':null},
                    CONTACTO_CLIENTES:${this.state.valorContactoClientes?'"'+this.state.valorContactoClientes+'"':null},
                    TRABAJO_DISTANCIA:${this.state.trabajoDistancia?'"'+this.state.trabajoDistancia+'"':null},
                    POLIVALENCIA_EXIGIDA:${this.state.polivalenciaExigida?'"'+this.state.polivalenciaExigida+'"':null},
                    RIESGO_PSICOSOCIAL:${this.state.riesgoPsicosocial?'"'+this.state.riesgoPsicosocial+'"':null},
                    GENERO_CONOCIMIENTO:${this.state.generoSeleccionadoConocimientosAptitudes?'"'+this.state.generoSeleccionadoConocimientosAptitudes.value+'"':null},
                    GENERO_ESFUERZO:${this.state.generoSeleccionadoEsfuerzo?'"'+this.state.generoSeleccionadoEsfuerzo.value+'"':null},
                    GENERO_RESPONSABILIDAD:${this.state.generoSeleccionadoResponsabilidad?'"'+this.state.generoSeleccionadoResponsabilidad.value+'"':null},
                    GENERO_CONDICIONES:${this.state.generoSeleccionadoCondiciones?'"'+this.state.generoSeleccionadoCondiciones.value+'"':null},
                    CENTRO_TRABAJO:${this.state.centroTrabajo?'"'+this.state.centroTrabajo+'"':null},
                    DESC_LARGA:${this.state.descripcionDetallada?'"""'+this.state.descripcionDetallada+'"""':null}}){ID_PUESTO}}`
            }).then(async result=>{
        
                //generamos el codigo del puesto
                //obtencion del ultimo puesto de ese area y de partamento
        
                /*let mayor=await Math.max.apply(Math,puestos.map(puesto=>{ return parseInt(puesto.CODIGO_PUESTO.split("|")[2])}))
    
                let codDep=this.state.pathSeleccionado[1]+1
                let CodaArea=this.state.pathSeleccionado[0]+1
                let codigoPuesto=`${CodaArea}|${codDep}|${this.state.puestos.length>0?this.state.puestos.length+1:1}`

                await  this.props.client.mutate({
                    mutation:gql`mutation{actualizarPuestoTrabajo(
                        ID_PUESTO:${result.data.crearPuestoTrabajo.ID_PUESTO},
                        VALORES:{
                        CODIGO_PUESTO:"${codigoPuesto}",
                    }){ID_PUESTO}}`

                })*/

                let codigo_puesto=await this.obtenerCodigo(result.data.crearPuestoTrabajo.ID_PUESTO)

                await this.props.client.mutate({
                    mutation:gql`mutation{actualizarPuestoTrabajo(
                        ID_PUESTO:${result.data.crearPuestoTrabajo.ID_PUESTO},
                        VALORES:{
                        CODIGO_PUESTO:"${codigo_puesto}"})
                    {ID_PUESTO}}`
                }).then(async result=>{
                    console.log(i18next.t("catalogopuestos.successUpdateCode",{ns:"page2"}));
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                })

                await this.cargarPuestosDep(this.state.departamentoSeleccionado.id)


                cerrarModal()
                
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })


        }

        const actualizarPuesto = async() => {

            if(!this.state.nombrePuesto)return
            //if(!this.state.codigoPuesto)return

            //comprobar que no exista un puesto con ese codigo
            /*
            let existe= await this.props.client
            .query({
                query: gql` 
                    {puestosTrabajo(FILTROS:{CODIGO_PUESTO:"${this.state.codigoPuesto}"}){ID_PUESTO,CODIGO_PUESTO}{ID_PUESTO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                let ex=false
                if(result.data.puestosTrabajo.length>0){
                    if(result.data.puestosTrabajo[0].ID_PUESTO!==this.state.puestoSeleccionado.ID_PUESTO){
                        ex= true
                    }
                }
                return ex
            })

            if(existe){
                alert("Ya existe un puesto con ese codigo.")
                return
            }*/

             //comprobar que no exista un puesto con ese nombre
             let existe2= await this.props.client
             .query({
                 query: gql` 
                     {puestosTrabajo(FILTROS:{DESCRIPCION:"${this.state.nombrePuesto}"}){ID_PUESTO,DESCRIPCION}}
                 `,
                 fetchPolicy:'network-only'
             }).then(result=>{ 
                 let ex=false
                 if(result.data.puestosTrabajo.length>0){
                     if(result.data.puestosTrabajo[0].ID_PUESTO!==this.state.puestoSeleccionado.ID_PUESTO){
                         ex= true
                     }
                 }
                 return ex
             })
 
             if(existe2){
                alert(i18next.t("catalogopuestos.jobAlreadyExists",{ns:"page2"}))
                 return
             }

            let data_aptitudes=this.state.conocimientosAptitudes?this.state.conocimientosAptitudes:0
            let data_responsabilidad=this.state.valorResponsabilidad?this.state.valorResponsabilidad:0
            let data_esfuerzo=this.state.valorEsfuerzo?this.state.valorEsfuerzo:0
            let data_condiciones=this.state.valorCondiciones?this.state.valorCondiciones:0
            
            let suma=parseFloat(data_aptitudes)+parseFloat(data_responsabilidad)+parseFloat(data_esfuerzo)+parseFloat(data_condiciones)
            if (suma!==100) {
                alert(i18next.t("catalogopuestos.addAlert",{ns:"page2"}))
                return
            }

             let tareasJson="["
             this.state.tareas.forEach((tarea,index)=>{
                 if(index===0){
                    tareasJson+=`{"id":${tarea.id},"descripcion":"${tarea.descripcion}","valor":"${tarea.valor}"}`
                 }else{
                    tareasJson+=`,{"id":${tarea.id},"descripcion":"${tarea.descripcion}","valor":"${tarea.valor}"}`
                 }
             })
             tareasJson+="]"
             //creamos el puesto de trabajo.
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarPuestoTrabajo(
                    ID_PUESTO:${this.state.puestoSeleccionado.ID_PUESTO},
                    VALORES:{
                    DESCRIPCION:"${this.state.nombrePuesto}",
                    GRADO_RESPONSABILIDAD:${this.state.gradoResponsabilidad},
                    ID_RIESGO_OBJETIVO:${this.state.riesgoSeleccionado.value},
                    DESC_CORTA:${this.state.descripcionBreve?'"'+this.state.descripcionBreve+'"':null},
                    TAREAS:${this.state.tareas.length>0?JSON.stringify(tareasJson):null},
                    CONOCIMIENTOS_APTITUDES:${this.state.conocimientosAptitudes?'"'+this.state.conocimientosAptitudes+'"':null},
                    RESPONSABILIDAD:${this.state.valorResponsabilidad?'"'+this.state.valorResponsabilidad+'"':null},
                    ESFUERZO:${this.state.valorEsfuerzo?'"'+this.state.valorEsfuerzo+'"':null},
                    CONDICIONES_TRABAJO:${this.state.valorCondiciones?'"'+this.state.valorCondiciones+'"':null},
                    DESTREZA_EQUIPOS:${this.state.valorDestreza?'"'+this.state.valorDestreza+'"':null},
                    CONOCIMIENTOS_INFORMATICOS:${this.state.conocimientosInformaticos?'"'+this.state.conocimientosInformaticos+'"':null},
                    POLIVALENCIA:${this.state.polivalenciaCreatividad?'"'+this.state.polivalenciaCreatividad+'"':null},
                    EXPERIENCIA:${this.state.valorExperiencia?'"'+this.state.valorExperiencia+'"':null},
                    POSTURAS_FORZADAS:${this.state.posturasForzadas?'"'+this.state.posturasForzadas+'"':null},
                    MOVIMIENTOS_REPETITIVOS:${this.state.movimientosRepetitivos?'"'+this.state.movimientosRepetitivos+'"':null},
                    CONCENTRACION:${this.state.valorConcentracion?'"'+this.state.valorConcentracion+'"':null},
                    DIFICULTAD_TECNICA:${this.state.dificultadTecnica?'"'+this.state.dificultadTecnica+'"':null},
                    ORGANIZACION_TRABAJO:${this.state.organizacionTrabajo?'"'+this.state.organizacionTrabajo+'"':null},
                    RESPONSABILIDAD_MAQUINARIA:${this.state.responsabilidadMaquinaria?'"'+this.state.responsabilidadMaquinaria+'"':null},
                    JEFATURA:${this.state.valorJefatura?'"'+this.state.valorJefatura+'"':null},
                    INICIATIVA_POLIVALENCIA:${this.state.iniciativaPolivalencia?'"'+this.state.iniciativaPolivalencia+'"':null},
                    CONTACTO_CLIENTES:${this.state.valorContactoClientes?'"'+this.state.valorContactoClientes+'"':null},
                    TRABAJO_DISTANCIA:${this.state.trabajoDistancia?'"'+this.state.trabajoDistancia+'"':null},
                    POLIVALENCIA_EXIGIDA:${this.state.polivalenciaExigida?'"'+this.state.polivalenciaExigida+'"':null},
                    RIESGO_PSICOSOCIAL:${this.state.riesgoPsicosocial?'"'+this.state.riesgoPsicosocial+'"':null},
                    GENERO_CONOCIMIENTO:${this.state.generoSeleccionadoConocimientosAptitudes?'"'+this.state.generoSeleccionadoConocimientosAptitudes.value+'"':null},
                    GENERO_ESFUERZO:${this.state.generoSeleccionadoEsfuerzo?'"'+this.state.generoSeleccionadoEsfuerzo.value+'"':null},
                    GENERO_RESPONSABILIDAD:${this.state.generoSeleccionadoResponsabilidad?'"'+this.state.generoSeleccionadoResponsabilidad.value+'"':null},
                    GENERO_CONDICIONES:${this.state.generoSeleccionadoCondiciones?'"'+this.state.generoSeleccionadoCondiciones.value+'"':null},
                    CENTRO_TRABAJO:${this.state.centroTrabajo?'"'+this.state.centroTrabajo+'"':null},
                    DESC_LARGA:${this.state.descripcionDetallada?'"""'+this.state.descripcionDetallada+'"""':null}})
                {ID_PUESTO}}`
            }).then(async result=>{
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                await this.cargarPuestosDep(this.state.departamentoSeleccionado.id)
                
                cerrarModal()
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
        }

        const riesgoFormato = (cell, row) => {
          let color = "";
          switch (row.ID_RIESGO_OBJETIVO) {
            case 1:
              color = "green";
              break;
            case 2:
              color = "yellow";
              break;
            case 3:
              color = "orange";
              break;
            case 4:
              color = "red";
              break;
            default:
              break;
          }
          return (
            <div style={{ backgroundColor: color }}>
              {row.RIESGO_OBJETIVO.DESCRIPCION}
            </div>
          );
        };

        const columns = [
            {
                dataField: 'DESCRIPCION',
                text: i18next.t('catalogopuestos.name', { ns: "page2" })
            },
            {
                dataField: 'GRADO_RESPONSABILIDAD',
                text: i18next.t('catalogopuestos.responsibility', { ns: "page2" })
            },
            {
                dataField: 'ID_RIESGO_OBJETIVO',
                text: i18next.t('catalogopuestos.risk', { ns: "page2" }),
                formatter: riesgoFormato
            },
            {
                dataField: 'CODIGO_PUESTO',
                text: i18next.t('catalogopuestos.code', { ns: "page2" })
            },
            {
                dataField: 'NUMERO_TRABAJADORES',
                text: i18next.t('catalogopuestos.numberOfEmployee', { ns: "page2" })
            }
        ];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    puestoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const abrirModalNuevoPuestoDetalles = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.departamentoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let riesgoSel=this.state.riesgoOptions.find(riesgo=>{
                return riesgo.value===1
            })
            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalPuestoDetalles: true,
                imprimirActivado:false,
                imprimirPActivado:false,
                imprimirGralActivado: false,
                nombrePuesto:null,
                gradoResponsabilidad:1,
                riesgoSeleccionado:riesgoSel,
                codigoPuesto:null,
                descripcionBreve:null,
                descripcionDetallada:null,
                conocimientosAptitudes:null,
                conocimientosInformaticos:null,
                valorDestreza:null,
                polivalenciaCreatividad:null,
                valorExperiencia:null,
                posturasForzadas:null,
                movimientosRepetitivos:null,
                valorConcentracion:null,
                dificultadTecnica:null,
                organizacionTrabajo:null,
                responsabilidadMaquinaria:null,
                valorJefatura:null,
                iniciativaPolivalencia:null,
                valorContactoClientes:null,
                trabajoDistancia:null,
                polivalenciaExigida:null,
                riesgoPsicosocial:null,
                valorEsfuerzo:null,
                valorResponsabilidad:null,
                valorCondiciones:null,
                generoSeleccionadoConocimientosAptitudes:null,
                generoSeleccionadoEsfuerzo:null,
                generoSeleccionadoResponsabilidad:null,
                generoSeleccionadoCondiciones:null,
                centroTrabajo:null,
                tareas:[]
            })
        }

        const abrirModalEditarPuestoDetalles = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.puestoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let riesgoSel=this.state.riesgoOptions.find(riesgo=>{
                return riesgo.value===this.state.puestoSeleccionado.ID_RIESGO_OBJETIVO
            })

            
            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                imprimirPActivado:false,
                imprimirGralActivado: false,
                imprimirActivado:false,
                nombrePuesto:this.state.puestoSeleccionado.DESCRIPCION,
                gradoResponsabilidad:this.state.puestoSeleccionado.GRADO_RESPONSABILIDAD,
                riesgoSeleccionado:riesgoSel,
                codigoPuesto:this.state.puestoSeleccionado.CODIGO_PUESTO,
                descripcionBreve:this.state.puestoSeleccionado.DESC_CORTA,
                descripcionDetallada:this.state.puestoSeleccionado.DESC_LARGA,
                modalPuestoDetalles: true,
                conocimientosAptitudes:this.state.puestoSeleccionado.CONOCIMIENTOS_APTITUDES,
                conocimientosInformaticos:this.state.puestoSeleccionado.CONOCIMIENTOS_INFORMATICOS,
                valorDestreza:this.state.puestoSeleccionado.DESTREZA_EQUIPOS,
                polivalenciaCreatividad:this.state.puestoSeleccionado.POLIVALENCIA,
                valorExperiencia:this.state.puestoSeleccionado.EXPERIENCIA,
                posturasForzadas:this.state.puestoSeleccionado.POSTURAS_FORZADAS,
                movimientosRepetitivos:this.state.puestoSeleccionado.MOVIMIENTOS_REPETITIVOS,
                valorConcentracion:this.state.puestoSeleccionado.CONCENTRACION,
                dificultadTecnica:this.state.puestoSeleccionado.DIFICULTAD_TECNICA,
                organizacionTrabajo:this.state.puestoSeleccionado.ORGANIZACION_TRABAJO,
                responsabilidadMaquinaria:this.state.puestoSeleccionado.RESPONSABILIDAD_MAQUINARIA,
                valorJefatura:this.state.puestoSeleccionado.JEFATURA,
                iniciativaPolivalencia:this.state.puestoSeleccionado.INICIATIVA_POLIVALENCIA,
                valorContactoClientes:this.state.puestoSeleccionado.CONTACTO_CLIENTES,
                trabajoDistancia:this.state.puestoSeleccionado.TRABAJO_DISTANCIA,
                polivalenciaExigida:this.state.puestoSeleccionado.POLIVALENCIA_EXIGIDA,
                riesgoPsicosocial:this.state.puestoSeleccionado.RIESGO_PSICOSOCIAL,
                valorEsfuerzo:this.state.puestoSeleccionado.ESFUERZO,
                valorResponsabilidad:this.state.puestoSeleccionado.RESPONSABILIDAD,
                valorCondiciones:this.state.puestoSeleccionado.CONDICIONES_TRABAJO,
                centroTrabajo:this.state.puestoSeleccionado.CENTRO_TRABAJO,
                tareas:this.state.puestoSeleccionado.TAREAS?JSON.parse(this.state.puestoSeleccionado.TAREAS):[]
            },async ()=>{
                if(this.state.puestoSeleccionado.GENERO_CONOCIMIENTO){
                    let generoSeleccionado=this.state.generos.find(genero=>{return genero.value===this.state.puestoSeleccionado.GENERO_CONOCIMIENTO})
                    this.setState({generoSeleccionadoConocimientosAptitudes:generoSeleccionado})
                }
                if(this.state.puestoSeleccionado.GENERO_ESFUERZO){
                    let generoSeleccionado=this.state.generos.find(genero=>{return genero.value===this.state.puestoSeleccionado.GENERO_ESFUERZO})
                    this.setState({generoSeleccionadoEsfuerzo:generoSeleccionado})
                }
                if(this.state.puestoSeleccionado.GENERO_RESPONSABILIDAD){
                    let generoSeleccionado=this.state.generos.find(genero=>{return genero.value===this.state.puestoSeleccionado.GENERO_RESPONSABILIDAD})
                    this.setState({generoSeleccionadoResponsabilidad:generoSeleccionado})
                }
                if(this.state.puestoSeleccionado.GENERO_CONDICIONES){
                    let generoSeleccionado=this.state.generos.find(genero=>{return genero.value===this.state.puestoSeleccionado.GENERO_CONDICIONES})
                    this.setState({generoSeleccionadoCondiciones:generoSeleccionado})
                }
            })
        }


        const eliminarPuesto = () => {
           
            //eliminamos el puesto de trabajo.
            this.props.client.mutate({
                mutation:gql`mutation{eliminarPuestoTrabajo(ID_PUESTO:${this.state.puestoSeleccionado.ID_PUESTO}){ID_PUESTO}}`
            }).then(async result=>{
                alert(i18next.t("deletecorrecto", { ns: "global" }))
                await this.cargarPuestosDep(this.state.departamentoSeleccionado.id)
                this.setState({
                    puestoSeleccionado:null,
                },()=>{this.tablaPuestos.clearSelection()})
                cerrarModal() 
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("catalogopuestos.errDeleteJobPosition",{ns:"page2"}))            })
        };

        const abrirModalBorrar = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.puestoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                imprimirGralActivado: false,
                imprimirPActivado: false,
                imprimirActivado:false
            })
        }

        const desplegarArbol=(hijos)=>{
            
            hijos.forEach(hijo=>{
                  hijo.isExpanded=true
                  if(hijo.childNodes)desplegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
        
        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }

        const handleJoyrideCallback=async(data)=>{
            
            const { action, index, status, type } = data;


            if(data.action==="close"){
                localStorage.setItem("ayuda", false)
                this.setState ({
                    modalPuestoDetalles:false, 
                    pasoGuia: 0
                })
            }else{

                if ([EVENTS.STEP_AFTER].includes(type)) {
                
                    let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
                    
                    //paso en el que nos encontremos.
                    // eslint-disable-next-line default-case
                    switch(paso){
                        case 1:
                            await this.setState({modalPuestoDetalles:false})
                        break;
                        case 2:
                            await this.setState({modalPuestoDetalles:true})
                        break;
                        case 6:
                            await this.setState({modalPuestoDetalles:false})
                        break;
                    }
              
                    await this.setState({ pasoGuia: paso });   
    
    
                }
    
                //si termina la guia.
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                    localStorage.setItem("ayuda", false)
                }
                
            }
        

        }

        const handleCambiarNombrePuesto = (ev) => {
            this.setState({nombrePuesto: ev.currentTarget.value})
        }

        const handleCambiarDescripcionBreve = (ev) => {
            this.setState({descripcionBreve: ev.currentTarget.value})
        }

        const handleCambiarDescripcionDetallada = (ev) => {
            this.setState({descripcionDetallada: ev.currentTarget.value})
        }

        const handleSliderResponsabilidad = (value) => {
            this.setState({ gradoResponsabilidad: value })
        }

        const seleccionRiesgos  = (value) => { 
            this.setState({riesgoSeleccionado: value})
        }


        const handleTareas = (value) => {
            if (this.state.puestoSeleccionado) {
                let puesto=this.state.puestoSeleccionado;
                puesto.TAREAS = value;
                
                this.setState({puestoSeleccionado:puesto})
            }
            this.setState({ tareas: value })
        }

        const handleCambiarConocimientosAptitudes = (e) => {
            this.setState({conocimientosAptitudes: e})
        }

        const handleCambiarConocimientosInformaticos = (e) => {
            this.setState({conocimientosInformaticos: e})
        }

        const handleCambiarValorDestreza = (ev) => {
            this.setState({valorDestreza: ev})
        }

        const handleCambiarPolivalenciaCreatividad = (ev) => {
            this.setState({polivalenciaCreatividad: ev})
        }
        const handleCambiarValorExperiencia = (ev) => {
            this.setState({valorExperiencia: ev})
        }
        const handleCambiarPosturasForzadas = (ev) => {
            this.setState({posturasForzadas: ev})
        }
        const handleCambiarMovimientosRepetitivos = (ev) => {
            this.setState({movimientosRepetitivos: ev})
        }
        const handleCambiarValorConcentracion = (ev) => {
            this.setState({valorConcentracion: ev})
        }
        const handleCambiarDificultadTecnica = (ev) => {
            this.setState({dificultadTecnica: ev})
        }

        const handleCambiarOrganizacionTrabajo = (ev) => {
            this.setState({organizacionTrabajo: ev})
        }
        const handleCambiarResponsabilidadMaquinaria = (ev) => {
            this.setState({responsabilidadMaquinaria: ev})
        }
        const handleCambiarValorJefatura = (ev) => {
            this.setState({valorJefatura: ev})
        }
        const handleCambiarIniciativaPolivalencia = (ev) => {
            this.setState({iniciativaPolivalencia: ev})
        }
        const handleCambiarCentroTrabajo = (ev) => {
            this.setState({centroTrabajo: ev.currentTarget.value})
        }
        const handleCambiarValorContactoClientes = (ev) => {
            this.setState({valorContactoClientes: ev})
        }
        const handleCambiarTrabajoDistancia = (ev) => {
            this.setState({trabajoDistancia: ev})
        }
        const handleCambiarPolivalenciaExigida = (ev) => {
            this.setState({polivalenciaExigida: ev})
        }
        const handleCambiarRiesgoPsicosocial = (ev) => {
            this.setState({riesgoPsicosocial: ev})
        }

        const handleCambiarValorEsfuerzo = (ev) => {
            this.setState({valorEsfuerzo: ev})
        }
        
        const handleCambiarValorResponsabilidad = (ev) => {
            this.setState({valorResponsabilidad: ev})
        }
        
        const handleCambiarValorCondiciones = (ev) => {
            this.setState({valorCondiciones: ev})
        }

        const seleccionarGeneroConocimientosAptitudes = genero => {
        this.setState({ generoSeleccionadoConocimientosAptitudes: genero });
        };

        const seleccionarGeneroEsfuerzo = genero => {
        this.setState({ generoSeleccionadoEsfuerzo: genero });
        };

        const seleccionarGeneroResponsabilidad = genero => {
        this.setState({ generoSeleccionadoResponsabilidad: genero });
        };

        const seleccionarGeneroCondiciones = genero => {
        this.setState({ generoSeleccionadoCondiciones: genero });
        };
        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                    {localStorage.getItem("ayuda")==="true"?
                        <>
                            <Joyride
                                run= {localStorage.getItem("ayuda")==="true"?true:false}
                                callback={handleJoyrideCallback}
                                showProgress={true}
                                stepIndex={this.state.pasoGuia}
                                steps={steps}
                                continuous ={true}
                                locale={{ back: i18next.t("return",{ns:"global"}), close: i18next.t("close",{ns:"global"}), last: i18next.t("last",{ns:"global"}), next: i18next.t("next",{ns:"global"}), skip: i18next.t("jump",{ns:"global"}) }}
                                styles={{
                                    options: {
                                        arrowColor: '#e3ffeb',
                                        backgroundColor: '#e3ffeb',
                                        primaryColor: '#E31E24',
                                    }
                                }}
                            />
                        </>
                    :null}
            {/*Abre modal imprimir Areas y Departamentos  */}
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirAD}
                    title={i18next.t("catalogopuestos.printAreasAndDepartments", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirAD}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf-ad">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                {/* abre Modal Imprimir Gral */}
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirPG}                    
                    title={i18next.t("catalogopuestos.generalPrint", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPG}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf-gral">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
             {/* abre modal para cargar puestos */}
                <Dialog
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("catalogopuestos.jobModalTitle", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        isOpen={this.state.modalPuestoDetalles}
                        style={{width:'90%'}}
                    >
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Tabs
                                id="pestañas"
                                animate={this.animacion} 
                                onChange={this.cambiarPestaña} 
                                selectedTabId={this.state.pestaña}
                                renderActiveTabPanelOnly={false}
                                className="pixelAlrededor"
                            >
                                <Tab id="propiedades" title={i18next.t("catalogopuestos.tabJobProperties", { ns: "page2" })} panel={<PuestosPropiedades 
                                ref={(input) => { this.propiedades= input; }} 
                                riesgoOptions={this.state.riesgoOptions} 
                                riesgoSeleccionado={this.state.riesgoSeleccionado} 
                                handleCambiarNombrePuesto={handleCambiarNombrePuesto}
                                handleSliderResponsabilidad={handleSliderResponsabilidad}
                                seleccionRiesgos={seleccionRiesgos}
                                handleCambiarDescripcionBreve={handleCambiarDescripcionBreve}
                                handleCambiarDescripcionDetallada={handleCambiarDescripcionDetallada}
                                nombrePuesto={this.state.nombrePuesto}
                                gradoResponsabilidad={this.state.gradoResponsabilidad}
                                descripcionBreve={this.state.descripcionBreve}
                                descripcionDetallada={this.state.descripcionDetallada}
                                handleCambiarCentroTrabajo={handleCambiarCentroTrabajo}
                                centroTrabajo={this.state.centroTrabajo}
                                />} />
                                <Tab id="factores" title={i18next.t("catalogopuestos.tabFactorsAndTasks", { ns: 'page2' })} panel={<PuestosFactores 
                                ref={(input) => { this.factores= input; }} 
                                handleCambiarConocimientosAptitudes={handleCambiarConocimientosAptitudes}
                                conocimientosAptitudes={this.state.conocimientosAptitudes}
                                handleCambiarConocimientosInformaticos={handleCambiarConocimientosInformaticos}
                                conocimientosInformaticos={this.state.conocimientosInformaticos}
                                handleCambiarValorDestreza={handleCambiarValorDestreza}
                                valorDestreza={this.state.valorDestreza}
                                handleCambiarPolivalenciaCreatividad={handleCambiarPolivalenciaCreatividad}
                                polivalenciaCreatividad={this.state.polivalenciaCreatividad}
                                handleCambiarValorExperiencia={handleCambiarValorExperiencia}
                                valorExperiencia={this.state.valorExperiencia}
                                handleCambiarPosturasForzadas={handleCambiarPosturasForzadas}
                                posturasForzadas={this.state.posturasForzadas}
                                handleCambiarMovimientosRepetitivos={handleCambiarMovimientosRepetitivos}
                                movimientosRepetitivos={this.state.movimientosRepetitivos}
                                handleCambiarValorConcentracion={handleCambiarValorConcentracion}
                                valorConcentracion={this.state.valorConcentracion}
                                handleCambiarDificultadTecnica={handleCambiarDificultadTecnica}
                                dificultadTecnica={this.state.dificultadTecnica}
                                handleCambiarOrganizacionTrabajo={handleCambiarOrganizacionTrabajo}
                                organizacionTrabajo={this.state.organizacionTrabajo}
                                handleCambiarResponsabilidadMaquinaria={handleCambiarResponsabilidadMaquinaria}
                                responsabilidadMaquinaria={this.state.responsabilidadMaquinaria}
                                handleCambiarValorJefatura={handleCambiarValorJefatura}
                                valorJefatura={this.state.valorJefatura}
                                handleCambiarIniciativaPolivalencia={handleCambiarIniciativaPolivalencia}
                                iniciativaPolivalencia={this.state.iniciativaPolivalencia}
                                handleCambiarValorContactoClientes={handleCambiarValorContactoClientes}
                                valorContactoClientes={this.state.valorContactoClientes}
                                handleCambiarTrabajoDistancia={handleCambiarTrabajoDistancia}
                                trabajoDistancia={this.state.trabajoDistancia}
                                handleCambiarPolivalenciaExigida={handleCambiarPolivalenciaExigida}
                                polivalenciaExigida={this.state.polivalenciaExigida}
                                handleCambiarRiesgoPsicosocial={handleCambiarRiesgoPsicosocial}
                                riesgoPsicosocial={this.state.riesgoPsicosocial}
                                handleCambiarValorEsfuerzo={handleCambiarValorEsfuerzo}
                                valorEsfuerzo={this.state.valorEsfuerzo}
                                handleCambiarValorResponsabilidad={handleCambiarValorResponsabilidad}
                                valorResponsabilidad={this.state.valorResponsabilidad}
                                handleCambiarValorCondiciones={handleCambiarValorCondiciones}
                                valorCondiciones={this.state.valorCondiciones}
                                seleccionarGeneroConocimientosAptitudes={seleccionarGeneroConocimientosAptitudes}
                                generoSeleccionadoConocimientosAptitudes={this.state.generoSeleccionadoConocimientosAptitudes}
                                seleccionarGeneroEsfuerzo={seleccionarGeneroEsfuerzo}
                                generoSeleccionadoEsfuerzo={this.state.generoSeleccionadoEsfuerzo}
                                seleccionarGeneroResponsabilidad={seleccionarGeneroResponsabilidad}
                                generoSeleccionadoResponsabilidad={this.state.generoSeleccionadoResponsabilidad}
                                seleccionarGeneroCondiciones={seleccionarGeneroCondiciones}
                                generoSeleccionadoCondiciones={this.state.generoSeleccionadoCondiciones}
                                generos={this.state.generos}
                                tareas={this.state.tareas}
                                handleTareas={handleTareas}
                                />} />
                                
                                
                            </Tabs>
                        </Card>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("close", { ns: "global" })} position="top">
                                    <AnchorButton
                                        onClick={cerrarModal}
                                    >
                                        {i18next.t("close", { ns: "global" })}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={this.state.nuevoActivado?i18next.t("catalogopuestos.createJobPosition", { ns: "page2" }):i18next.t("catalogopuestos.updateJobPosition", { ns: "page2" })} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={this.state.nuevoActivado?guardarPuesto:actualizarPuesto}
                                    >
                                        {this.state.nuevoActivado?i18next.t("catalogopuestos.createJobPosition", { ns: "page2" }):i18next.t("catalogopuestos.updateJobPosition", { ns: "page2" })}
                                    </AnchorButton>
                                </Tooltip>
                            </div>
                        </div>
                </Dialog>
              {/* ABRE MODAL BORRAR PUESTOS */}
                <Dialog 
                    transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("catalogopuestos.deleteCreditor", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                                <h2>{i18next.t("catalogopuestos.confirmDeleteJobPosition", { ns: "page2" })}</h2>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("catalogopuestos.deleteCredit", { ns: "page2" })} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarPuesto}
                                    >
                                        {i18next.t("yes", { ns: "global" })}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                                </Tooltip>
                            </div>
                        </div>
                </Dialog>
                {/* ABRE MODAL IMPRIMIR */}
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}                   
                    title={i18next.t("catalogopuestos.printJobPositionByDepartment", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                    <div className="pixelAlrededor tablaMitadIzquierda">
                        <Card interactive={true} elevation={Elevation.TWO} id="descripcion-arbol">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("catalogopuestos.areasAndDepartments", { ns: "page2" })}</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.RIGHT}>
                                {this.state.controlesDesactivados?
                                    <>
                                                    <Button key="botonImprimir" className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" }) } active={this.state.imprimirActivado} disabled />
                                    </>
                                :
                                    <>
                                        
                                        <Button key="botonImprimir" className="bp3-minimal" icon="print" text={i18next.t("print", { ns: "global" })} id="boton-imprimir" active={this.state.imprimirActivado} onClick={() => this.generarInformeAD()} />
                                        
                                    </>
                                }
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                            <Card interactive={true} elevation={Elevation.TWO} >
                                <Tooltip content={i18next.t("tree.replegar", { ns: "global" })} position={Position.TOP} ><Button icon="exclude-row" onClick={()=>replegarArbol(this.state.areas)}/></Tooltip>
                                <Tooltip content={i18next.t("tree.desplegar", { ns: "global" })} position={Position.TOP} ><Button icon="add-column-right" onClick={()=>desplegarArbol(this.state.areas)}/></Tooltip>
                                <Tree
                                    contents={this.state.areas}
                                    onNodeClick={handleNodeClick}
                                    onNodeCollapse={handleNodeCollapse}
                                    onNodeExpand={handleNodeExpand}
                                />
                            </Card>
                        </Card>
                    </div>
                    <div className="pixelAlrededor tablaMitadDerecha">
                        <Card interactive={true} elevation={Elevation.TWO} >
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("catalogopuestos.titleTable", { ns: "page2" })}</Callout>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        {this.state.controlesDesactivados?
                                        <>
                                        <Button className="bp3-minimal"  icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal"  icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled />
                                        <Button className="bp3-minimal"  icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirPActivado} disabled />
                                        <Button className="bp3-minimal"  icon="print" text={i18next.t("printgral",{ns:"global"})} active={this.state.imprimirGralEmpActivado} disabled />
                                        </>
                                        :
                                        <>
                                        <Button className="bp3-minimal" id="descripcion-puestos" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevoPuestoDetalles} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditarPuestoDetalles} />
                                        <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                        <Button className="bp3-minimal" id="imprimir" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirPActivado} onClick={() => this.generarInformePuestos()} />
                                        <Button className="bp3-minimal" id="imprimir" icon="print" text={i18next.t("printgral",{ns:"global"})} active={this.state.imprimirGralActivado} onClick={() => this.generarInformeGral()} />
                                       </>

                                       }     
                                    </ButtonGroup>
                                </Navbar.Group>
                                </Navbar>
                                <BootstrapTable
                                    keyField="ID_PUESTO"
                                    data={this.state.puestos}
                                    columns={columns}
                                    selectRow={selectRow}
                                    pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                        </Card>
                    </div>

                </>

            }
            </>
        )
    }
}
export default withTranslation()(withApollo(CatalogoPuestos))