import React from "react"
import { Card, Elevation } from "@blueprintjs/core"

export default class InformacionActas extends React.Component {

    state={
        abierto1: false,
        abierto2: false,
        abierto3: false,
    }

    render(){
        //<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">LEY DE SOCIEDADES DE CAPITAL <br /> REGLAMENTO DEL REGISTRO MERCANTIL </Callout>
        //            <br></br>
        return(
            <> 
                <Card interactive={true} elevation={Elevation.TWO} >
                    
                   <p><a role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a202" className="bp3-button bp3-icon-git-repo">{this.props.i18next.t("actas.articulo_1",{ns:"page"})}</a></p>
                   <p><a role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a250" className="bp3-button bp3-icon-git-repo">{this.props.i18next.t("actas.articulo_2",{ns:"page"})}</a></p>
                   <p><a role="button" target="_blank"  rel="noopener noreferrer" href="https://boe.es/buscar/act.php?id=BOE-A-1996-17533&tn=1&p=20170304#s2-3" className="bp3-button bp3-icon-git-repo">{this.props.i18next.t("actas.articulo_3",{ns:"page"})}</a></p>
                </Card>
            </>
        )
    }
}