import React from "react";
import { Card, Spinner } from "@blueprintjs/core";
export class Cargando extends React.Component {
  render() {
    return (
      <Card className="cardLoading pixelAlrededorGrande">
        <Spinner />
      </Card>
    );
  }
}
