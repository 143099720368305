import React from "react"
import { Classes, FormGroup, InputGroup, Slider, TextArea } from "@blueprintjs/core"
import Select from "react-select"
import chroma from "chroma-js"
import i18next from 'i18next';


export default class PuestosPropiedades extends React.Component {
    render(){
        

        const estilosColorSelect = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = chroma(data.color);
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? '#ccc'
                  : isSelected
                    ? chroma.contrast(color, 'white') > 2 ? 'gray' : 'gray'
                    : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
              };
            },
            input: styles => ({ ...styles, ...dot() }),
            placeholder: styles => ({ ...styles, ...dot() }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
        }

        const dot = (color = '#ccc') => ({
            alignItems: 'center',
            display: 'flex',
          
            ':before': {
              backgroundColor: color,
              borderRadius: 10,
              content: '" "',
              display: 'block',
              marginRight: 8,
              height: 10,
              width: 10,
            },
          });
        return (
            <div className={Classes.DIALOG_BODY}>
                <div className="pixelAlrededor">
                    <FormGroup
                        label={i18next.t("catalogopuestos.jobTitle", { ns: "page2" })}
                        labelFor="nombre-puesto"
                        intent="danger"
                        helperText={this.props.nombrePuesto?"":i18next.t("catalogopuestos.fillInJobTitle", { ns: "page2" })}
                    >
                        <div id="nombre-puesto">
                            <InputGroup value={this.props.nombrePuesto?this.props.nombrePuesto:''}  intent={this.props.nombrePuesto?"primary":"danger"} onChange={this.props.handleCambiarNombrePuesto} className="pixelAlrededor" fill={true} />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("catalogopuestos.responsibilityGrade", { ns: "page2" })}
                    >
                        <div id="grado-responsabilidad">
                            <Slider
                                initialValue={this.props.riesgoSeleccionado}
                                min={1}
                                max={8}
                                onChange={this.props.handleSliderResponsabilidad}
                                onRelease={this.props.handleSliderResponsabilidad}
                                value={this.props.gradoResponsabilidad}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("catalogopuestos.risk", { ns: "page2" })+":"}
                    >
                        <Select
                            id="riesgo-objetivo"
                            options={this.props.riesgoOptions}
                            className="pixelAlrededor"
                            onChange={this.props.seleccionRiesgos}
                            backspaceRemovesValue={true}
                            isClearable={true}
                            styles={estilosColorSelect}
                            value={this.props.riesgoOptions.filter(riesgo => riesgo.value === (this.props.riesgoSeleccionado?this.props.riesgoSeleccionado.value:1))}
                        />
                    </FormGroup>
                    <FormGroup
                        label={ i18next.t("catalogopuestos.briefDescription", { ns: "page2" })}
                        labelFor="descripcion-breve"

                    >
                        <InputGroup id="descripcion" onChange={this.props.handleCambiarDescripcionBreve} value={this.props.descripcionBreve?this.props.descripcionBreve:''}  fill={true} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("catalogopuestos.detailedDescription", { ns: "page2" })}
                        labelFor="descripcion-detallada"

                    >
                        <TextArea onChange={this.props.handleCambiarDescripcionDetallada} value={this.props.descripcionDetallada?this.props.descripcionDetallada:''}  fill={true} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("catalogopuestos.workCenter", { ns: "page2" })}
                        labelFor="cnetro-trabajo"

                    >
                        <InputGroup id="centro_trabajo" onChange={this.props.handleCambiarCentroTrabajo} value={this.props.centroTrabajo?this.props.centroTrabajo:''}  fill={true} />
                    </FormGroup>
                </div>
            </div>
        )
    }
}