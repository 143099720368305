import React, { Component } from "react";
import { Card, FormGroup, TextArea, Elevation, Button,  Dialog, Spinner } from '@blueprintjs/core';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
      dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:135,
    paddingTop:30,
    paddingLeft: 25,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#000000',
    padding:2,
    
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '90%'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize: 11,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  labelTitleOne  : {
    flexDirection: 'column',
    fontSize:13,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
  
})

 class MedioAmbiente extends Component {

    state = {
        modalImprimir:false,
        imprimirActivado:false,
        cargandoImprimir:false,
        cargandoMA:false,
        contaminacion:null,
        economiaCircular:null,
        usoSostenible:null,
        cambioClimatico: null,
        bioDiversidad: null,
        idFinanciera:null,
        existeFinanciera:false,
      
        
    }

    

    

    componentDidMount=(props)=>{
            console.log(this.props.datos)
            this.setState({
                cargandoMA: true,
            }, async ()=>{
                if(this.props.datos!==null){                        
                      await this.setState({
                            cargandoMA:false,
                            idFinanciera:this.props.datos.ID_FINANCIERA, 
                            contaminacion:this.props.datos.CONTAMINACION,
                            economiaCircular: this.props.datos.ECONOMIA,
                            usoSostenible:this.props.datos.USO,
                            cambioClimatico: this.props.datos.CAMBIO_CLIMATICO,
                            bioDiversidad: this.props.datos.BIODIVERSIDAD,
                            existeFinanciera:true,
                        })
                        console.log(this.state.datosFinanciera)
                    }                 
            })
                
       
    }
    generarInformeMA= async () =>{
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true
        }, async () =>{
            this.setState({cargandoImprimir:false},() => {
                ReactDOM.render(this.ImprimirMA(), document.getElementById('informeMA-pdf'))
            })
        })
        
    }

    ImprimirMA= () => (
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.props.i18next.t("informacionnofinanciera.title",{ns:"page"}).toUpperCase()}</Text>
            </View>
            <View fixed style={styles.rowTitleOne}>
                <Text style={styles.labelTitleOne}>{this.props.i18next.t("informacionnofinanciera.medioambiente",{ns:"page"}).toUpperCase()}</Text>
            </View>
            <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.contaminacion",{ns:"page"}).toUpperCase()}</Text>
                </View>
            <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.contaminacion}</Text>
            </View>
            <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.economiacircular",{ns:"page"}).toUpperCase()}</Text>
                </View>
            <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.economiaCircular}</Text>
            </View>
            <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.usosostenible",{ns:"page"}).toUpperCase()}</Text>
                </View>
            <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.usoSostenible}</Text>
            </View>
            <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.cambioclimatico",{ns:"page"}).toUpperCase()}</Text>
                </View>
            <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.cambioClimatico}</Text>
            </View>
            <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.biodiversidad",{ns:"page"}).toUpperCase()}</Text>
                </View>
            <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.bioDiversidad}</Text>
            </View>
           
        </View>
        <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
            fixed
          />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
        <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
      </Page>
    </Document>
  </PDFViewer>
)

    

    render() {

        const cerrarModalImprimir = () => {
            this.setState({modalImprimir:false})
        }

        const guardarCambios= async ()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            
            //si hay datos
            if(this.state.existeFinanciera){
                console.log(this.state.existeFinanciera)
                console.log(this.props.client)
                console.log(this.state.contaminacion)
                //actualizamos los datos
              await this.props.client
              .mutate({
                    mutation:gql`mutation{actualizarInformacionNoFinanciera(
                        ID_FINANCIERA:${this.state.idFinanciera},
                        VALORES:{
                                CONTAMINACION:${this.state.contaminacion?'"'+this.state.contaminacion+'"':null},
                                ECONOMIA:${this.state.economiaCircular?'"'+this.state.economiaCircular+'"':null},
                                USO:${this.state.usoSostenible?'"'+this.state.usoSostenible+'"':null},
                                CAMBIO_CLIMATICO:${this.state.cambioClimatico?'"'+this.state.cambioClimatico+'"':null},
                                BIODIVERSIDAD:${this.state.bioDiversidad?'"'+this.state.bioDiversidad+'"':null},
                               })
                        {ID_FINANCIERA}}`
                }).then(async result=>{
                  
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

            }else{

                //creamos un nuevo registro
                this.props.client.mutate({
                    mutation:gql`mutation{crearInformacionNoFinanciera(
                        VALORES:{
                            ID_FINANCIERA:${1}
                            CONTAMINACION:${this.state.contaminacion?'"'+this.state.contaminacion+'"':null},
                            ECONOMIA:${this.state.economiaCircular?'"'+this.state.economiaCircular+'"':null},
                            USO:${this.state.usoSostenible?'"'+this.state.usoSostenible+'"':null},
                            CAMBIO_CLIMATICO:${this.state.cambioClimatico?'"'+this.state.cambioClimatico+'"':null},
                            BIODIVERSIDAD:${this.state.bioDiversidad?'"'+this.state.bioDiversidad+'"':null},
                           }){ID_FINANCIERA}`
                }).then(async result=>{
                  
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                    await this.setState({
                        existeFinanciera:true
                    })


                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })


            }

        }


        const handleCambiarContaminacion = (event) => {
            let contaminacion = event.target.value
                contaminacion= contaminacion.replace(/(\r\n|\n|\r)/gm, "")

            console.log(contaminacion)
            this.setState({contaminacion: contaminacion})
        }

        const handleCambiarEconomia = (event) => {
            let economiaCircular = event.target.value
                economiaCircular= economiaCircular.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({economiaCircular: economiaCircular})
        }

        const handleCambiarUso = (event) => {
            let usoSostenible = event.target.value
             usoSostenible= usoSostenible.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({usoSostenible: usoSostenible})
        }

        const handleCambioClimatico = (event) => {
            let cambioClimatico = event.target.value
            cambioClimatico= cambioClimatico.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({cambioClimatico: cambioClimatico})
        }

        const handleBiodiversidad = (event) => {
            let bioDiversidad = event.target.value
            bioDiversidad= bioDiversidad.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({bioDiversidad: bioDiversidad})
        }

        return (
            <>
            {this.state.cargandoMA === true?
                <Spinner />
                :
            <>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informeMA-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

            <div  className={"pixelAlrededor tablaSesenta" } >            
                <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.actividades= input; }} >
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.contaminacion",{ns:"page"})+":"}
                        labelFor="contaminacion"
                    >
                        <TextArea id="contaminacion" fill={true} onChange={handleCambiarContaminacion} value={this.state.contaminacion?this.state.contaminacion:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.economiacircular",{ns:"page"})+":"}
                        labelFor="economia-circular"
                    >
                        <TextArea id="economia-circular" fill={true} onChange={handleCambiarEconomia} value={this.state.economiaCircular?this.state.economiaCircular:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.usosostenible",{ns:"page"})+":"}
                        labelFor="uso-sostenible"
                    >
                        <TextArea id="uso-sostenible" fill={true} onChange={handleCambiarUso} value={this.state.usoSostenible?this.state.usoSostenible:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.cambioclimatico",{ns:"page"})+":"}
                        labelFor="cambio-climatico"
                    >
                        <TextArea id="cambio-climatico" fill={true}  onChange={handleCambioClimatico} value={this.state.cambioClimatico?this.state.cambioClimatico:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.biodiversidad",{ns:"page"})+":"}
                        labelFor="Biodiversidad"
                    >
                        <TextArea id="Biodiversidad" fill={true} onChange={handleBiodiversidad} value={this.state.bioDiversidad?this.state.bioDiversidad:''} />
                    </FormGroup>
                    <Button className="iconoDerecha bp3-intent-danger" icon="print" onClick={()=>this.generarInformeMA()}>{this.props.i18next.t("print",{ns:"global"})}</Button>
                    <Button className="iconoDerecha bp3-intent-danger" icon="floppy-disk" onClick={guardarCambios}>{this.props.i18next.t("guardar",{ns:"global"})}</Button>
                    
                    <br/>
                    <br/>
                </Card>
            </div>
        </>
        }
        </>
        )
    }
}
export default withApollo(MedioAmbiente)