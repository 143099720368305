import React from 'react'
import { FormGroup, InputGroup, Button, Card, Elevation, Callout , Spinner, Dialog} from "@blueprintjs/core"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from "moment"
import { Cargando } from "../Cargando"
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import ReactDOM from 'react-dom';
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';


import styles from '../stylesPrint';

class Documentos extends React.Component {

    state = {
        cif: null,
        cifMod:null,
        CCC: [],
        modalNuevoCifAbierto: false,
        existeDocumento:false,
        modalCuentaAbierto: false,
        modalCuentaBorrar:false,
        nuevaCuentaActivo:true,
        editarCuenta:false, 
        eliminarCuenta:false,
        idPersona:null,
        tipoDocumento:4,
        fechaCaducidad:null,
        fechaEmision:null,
        idDocumento:null,
        pendienteGuardarCuenta:false,
        pendienteActualizarCuenta:false,
        pendienteGuardarCif:false,
        cuentaSeleccionada:null,
        pendienteEliminarCuenta:null,
        controlesDesactivados:false,
        bolFechaEmision:false,
        bolFechaCaducidad:false,
        cifInvalido:false,
        esPrincipal:false,
        documentoHistorico:[],
        downloadUrl:null,
        cargando:true,
        logotipo:null,
        hayContrato:null,
        cargandoForm:false,
        datosEmpresa:null,
        cargandoImprimirG:false,
        modalImprimirG: false,
     
    }
    
    cargarPreguntasDocumento=async()=>{

        //OBTENCION DE LA PERSONA JURIDICA Y EL CIF DE LA EMPRESA.
        await this.props.client
        .query({
        query: gql`
        {
            documentosHistoricos(ORDEN:"ID_ITEM,ASC"){ID_ITEM,TEXTO}
        }
        `
        }).then(result=>{ 
            let respuestas=[]
            result.data.documentosHistoricos.forEach(respuesta=>{
                respuestas[respuesta.ID_ITEM-1]=respuesta.TEXTO
            })
            this.setState({
                documentoHistorico:respuestas,
                cargando:false
            })
        })

    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

     cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        
        this.setState({
            datosEmpresa:datosEmpresa,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

   generarImprimirG= async() => {
        this.setState({
            cargandoImprimirG:true,
            modalImprimirG:true
        }, async () => {
            this.setState({cargandoImprimirG:false}, () => {
                ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
            })
        })
    }

    //cuando se haya montado el componente lo rellenamos con sus datos.
    async componentWillMount(props){
        //this.cargarCIF()
        //this.cargarCuentas()
        //comprobar si existe el contrato complience
        
        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            
            //comprobamos si hay documento compliance
            this.props.client
            .query({
                query: gql`
                {complianceContratos{ID_CONTRATO}}
                `
            }).then(result=>{ 
                if(result.data.complianceContratos && result.data.complianceContratos.length>0){
                    this.setState({hayContrato:true})
                }else{
                    this.setState({hayContrato:false})
                }
            })

            await this.cargarPreguntasDocumento()
            this.setState({cargandoForm: false})
        }); 

    }

    ImprimirG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("documentosCompliance.previousStatements", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowWhiteSpacing}>
                </View> 
                <View style={styles.rowFullWidthMarginBtm}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.incidentHistory", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowFullWidthMarginBtm}>
                            <Text style={styles.labelRegularFullWidth}>{ i18next.t("documentosCompliance.statementExplanation", { ns: "page2" })}:</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{ i18next.t("documentosCompliance.shareholdingVariation", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[0]?this.state.documentoHistorico[0]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("siniestros.activityParalyzed", { ns: "page2" }) }</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[1]?this.state.documentoHistorico[1]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.assetsSoldLost", { ns: "page2" }) }</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[2]?this.state.documentoHistorico[2]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{ i18next.t("documentosCompliance.currentLiabilitiesExceeded", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[3]?this.state.documentoHistorico[3]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.negativeNetWorth", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[4]?this.state.documentoHistorico[4]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.insolvencySituation", { ns: "page2" }) }</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[5]?this.state.documentoHistorico[5]:""}</Text>
                </View>

                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.bankruptcyDeclared", { ns: "page2" }) }</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[6]?this.state.documentoHistorico[6]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{ i18next.t("documentosCompliance.prebankruptcyRequested", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[7]?this.state.documentoHistorico[7]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.debtRenegotiationNeeded", { ns: "page2" }) }</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[8]?this.state.documentoHistorico[8]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.occupationalRiskPrevention", { ns: "page2" }) }</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[9]?this.state.documentoHistorico[9]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{ i18next.t("documentosCompliance.psychosocialRisks", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[10]?this.state.documentoHistorico[10]:""}</Text>
                </View>
                
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{ i18next.t("documentosCompliance.taxEvasion", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[11]?this.state.documentoHistorico[11]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.socialSecurity", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[12]?this.state.documentoHistorico[12]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.employmentPractices", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[13]?this.state.documentoHistorico[13]:""}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelBoldFullWidth}>{ i18next.t("documentosCompliance.environmentalViolations", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[14]?this.state.documentoHistorico[14]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.personalDataProtectionLaws", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[15]?this.state.documentoHistorico[15]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.civilClaimsAgainstAdministrators", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[16]?this.state.documentoHistorico[16]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.administratorsDisqualification", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[17]?this.state.documentoHistorico[17]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.reputationalDamage", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[18]?this.state.documentoHistorico[18]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.ongoingJudicialProcedure", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[19]?this.state.documentoHistorico[19]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.ongoingAdministrativeSanctionProcedure", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[20]?this.state.documentoHistorico[20]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.companyCivilLiabilityInsurance", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[21]?this.state.documentoHistorico[21]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.managementCivilLiabilityInsurance", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[22]?this.state.documentoHistorico[22]:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelBoldFullWidth}>{i18next.t("documentosCompliance.unforeseenContingencies", { ns: "page2" })}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelRegularFullWidth}>{this.state.documentoHistorico[23]?this.state.documentoHistorico[23]:""}</Text>
                </View>
                
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    historcioIncidencias =()=> (
        <div className="html-pdf" ref={(input) => { this.pdfHistorico = input; }} >
        <div className="body-pdf">
            <br />
            <img
                className="App-logo-pdf"
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                alt="logotipo"
            />
            <br />
            <div>
                <h4>HISTÓRICO DE INCIDENCIAS Y VARIACIONES EN LA COMPOSICIÓN DEL
                ACCIONARIADO, EN LA SITUACIÓN JURÍDICA, CONTABLE O PATRIMONIAL DE LA
                SOCIEDAD, ASÍ COMO RELACIÓN DE SANCIONES ADMINISTRATIVAS POR
                FALTAS GRAVES, CONDENAS JUDICIALES O CONTINGENCIAS DE RIESGO
                PROBABLE DE ATENCIÓN PRIORITARIA
                </h4>
            </div>
            <br />
            <br />
            <div>
                <p>Esta declaración se formula como expresión de los antecedentes de la entidad y tiene por
                    objeto analizar las circunstancias históricas, y las que concurrieron al producirse cualquier clase
                    de suceso de trascendencia en la vida de la sociedad, así como para conocer las contingencias
                    que actualmente le afectan. Alguna vez en la vida de la sociedad:
                </p>
            </div>
            <br />
            <br />
            <div >
                    <b><p>{ i18next.t("documentosCompliance.shareholdingVariation", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[0]?this.state.documentoHistorico[0]:""}</p>
                <b><p>{i18next.t("documentosCompliance.activityParalyzed", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[1]?this.state.documentoHistorico[1]:""}</p>
                <b><p>{i18next.t("documentosCompliance.assetsSoldLost", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[2]?this.state.documentoHistorico[2]:""}</p>
                <b><p>{i18next.t("documentosCompliance.currentLiabilitiesExceeded", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[3]?this.state.documentoHistorico[3]:""}</p>
                    <b><p>{ i18next.t("documentosCompliance.negativeNetWorth", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[4]?this.state.documentoHistorico[4]:""}</p>
                    <b><p>{i18next.t("documentosCompliance.insolvencySituation", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[5]?this.state.documentoHistorico[5]:""}</p>
                    <b><p>{i18next.t("documentosCompliance.bankruptcyDeclared", { ns: "page2" }) }</p></b>
                <p>{this.state.documentoHistorico[6]?this.state.documentoHistorico[6]:""}</p>
                    <b><p>{ i18next.t("documentosCompliance.prebankruptcyRequested", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[7]?this.state.documentoHistorico[7]:""}</p>
                    <b><p>{ i18next.t("documentosCompliance.debtRenegotiationNeeded", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[8]?this.state.documentoHistorico[8]:""}</p>
                    <b><p>{ i18next.t("documentosCompliance.occupationalRiskPrevention", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[9]?this.state.documentoHistorico[9]:""}</p>
                <b><p>{i18next.t("documentosCompliance.psychosocialRisks", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[10]?this.state.documentoHistorico[10]:""}</p>
                <br />
                <br />
                <br />
                <br />
                <br />
                    <b><p>{ i18next.t("documentosCompliance.taxEvasion", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[11]?this.state.documentoHistorico[11]:""}</p>
                    <b><p>{ i18next.t("documentosCompliance.socialSecurity", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[12]?this.state.documentoHistorico[12]:""}</p>
                <b><p>{i18next.t("documentosCompliance.employmentPractices", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[13]?this.state.documentoHistorico[13]:""}</p>
                <b><p>{i18next.t("documentosCompliance.environmentalViolations", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[14]?this.state.documentoHistorico[14]:""}</p>
                <b><p>{i18next.t("documentosCompliance.personalDataProtectionLaws", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[15]?this.state.documentoHistorico[15]:""}</p>
                <b><p>{i18next.t("documentosCompliance.civilClaimsAgainstAdministrators", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[16]?this.state.documentoHistorico[16]:""}</p>
                <b><p>{i18next.t("documentosCompliance.administratorsDisqualification", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[17]?this.state.documentoHistorico[17]:""}</p>
                <b><p>{i18next.t("documentosCompliance.reputationalDamage", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[18]?this.state.documentoHistorico[18]:""}</p>
                <b><p>{i18next.t("documentosCompliance.ongoingJudicialProcedure", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[19]?this.state.documentoHistorico[19]:""}</p>
                <b><p>{i18next.t("documentosCompliance.ongoingAdministrativeSanctionProcedure", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[20]?this.state.documentoHistorico[20]:""}</p>
                <b><p>{i18next.t("documentosCompliance.companyCivilLiabilityInsurance", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[21]?this.state.documentoHistorico[21]:""}</p>
                <b><p>{i18next.t("documentosCompliance.managementCivilLiabilityInsurance", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[22]?this.state.documentoHistorico[22]:""}</p>
                <b><p>{i18next.t("documentosCompliance.unforeseenContingencies", { ns: "page2" })}</p></b>
                <p>{this.state.documentoHistorico[23]?this.state.documentoHistorico[23]:""}</p>
            </div>
        </div>
        <p className="footer-pdf">{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</p>
    </div>
    );
    

    render() {

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("documentosCompliance.previousStatements", { ns: "page2" })}`

        const cambioRespuesta= (ev,index) => {
            let valores=this.state.documentoHistorico
            if(ev.currentTarget.value!=="" && ev.currentTarget.value!==null){
                valores[index]=ev.currentTarget.value
            }else{
                valores.splice(index,1)
            }
            this.setState({documentoHistorico: valores})
        }

        
        const guardarDocumento=async ()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            //si existe el contrato compliance no hacemos nada.
            if(this.state.hayContrato)return

             if(this.state.documentoHistorico.length===0){
                 alert(i18next.t("documentosCompliance.fillAnyDocumentField", { ns: "page2" }))
                 return
             }

            this.state.documentoHistorico.forEach(async (respuesta,index)=>{

                if(respuesta){
                    //comprobacion de que existe el item
                    let existe=await this.props.client
                    .query({
                        query: gql`
                        {
                            documentosHistoricos(FILTROS:{ID_ITEM:${(index+1)}}){ID_ITEM}
                        }
                        `,fetchPolicy:'network-only'
                    }).then(result=>{ 
                        if(result.data.documentosHistoricos.length>0)return true
                        return false                    
                    })

                    //si existe actualizamos
                    if(existe){

                        await this.props.client
                        .mutate({
                            mutation: gql`
                            mutation{
                                actualizarDocumentoHistorico(ID_ITEM:${(index+1)},VALORES:{TEXTO:${respuesta?'"'+respuesta+'"':null}}){ID_ITEM}
                            }
                            `
                        }).catch(err=>{
                            alert(i18next.t("error", { ns: "global" }))
                        })

                        //de lo contrario creamos el item.
                    }else{

                        await this.props.client
                        .mutate({
                            mutation: gql`
                            mutation{
                               crearDocumentoHistorico(VALORES:{ID_ITEM:${(index+1)},TEXTO:${respuesta?'"'+respuesta+'"':null}}){ID_ITEM}
                            }
                            `
                        }).catch(err=>{
                            alert(i18next.t("error", { ns: "global" }))
                        })

                    }

                }

            })
           
            alert(i18next.t("updatecorrecto", { ns: "global" }))

        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirG:false
            })
        }
        //Fin eventos
        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("documentosCompliance.printPreviousStatements", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande">
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{ i18next.t("documentosCompliance.shareholdingHistory", { ns: "page2" })}</Callout>
                <br />
                <FormGroup className="pixelAlrededorGrande" 
                    label={i18next.t("documentosCompliance.shareholdingVariation", { ns: "page2" })}
                    labelFor="informacion"
                >
                    <InputGroup  id="variacion-50-accionariado" defaultValue={this.state.documentoHistorico[0]?this.state.documentoHistorico[0]:""}  onChange={(e) => cambioRespuesta(e,0)} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.activityParalyzed", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,1)} id="paralizacion-actividad" defaultValue={this.state.documentoHistorico[1]?this.state.documentoHistorico[1]:""}  placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.assetsSoldLost", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,2)} id="vendido-perdido-activos" defaultValue={this.state.documentoHistorico[2]?this.state.documentoHistorico[2]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.currentLiabilitiesExceeded", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,3)} id="variacion-50-accionariado" defaultValue={this.state.documentoHistorico[3]?this.state.documentoHistorico[3]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.negativeNetWorth", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,4)} id="patrimonio-neto-negativo" defaultValue={this.state.documentoHistorico[4]?this.state.documentoHistorico[4]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.insolvencySituation", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup id="insolvencia" onChange={(e) => cambioRespuesta(e,5)} defaultValue={this.state.documentoHistorico[5]?this.state.documentoHistorico[5]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.bankruptcyDeclared", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,6)} id="concurso-acreedores" defaultValue={this.state.documentoHistorico[6]?this.state.documentoHistorico[6]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.prebankruptcyRequested", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,7)} id="preconcurso-acreedores" defaultValue={this.state.documentoHistorico[7]?this.state.documentoHistorico[7]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.debtRenegotiationNeeded", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,8)} id="renegociar-deuda" defaultValue={this.state.documentoHistorico[8]?this.state.documentoHistorico[8]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{ i18next.t("documentosCompliance.shareholdingHistory", { ns: "page2" })}</Callout>
                <FormGroup className="pixelAlrededorGrande"
                label={ i18next.t("documentosCompliance.occupationalRiskPrevention", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,9)} id="prevencion" defaultValue={this.state.documentoHistorico[9]?this.state.documentoHistorico[9]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.psychosocialRisks", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,10)} id="psicosocial" defaultValue={this.state.documentoHistorico[10]?this.state.documentoHistorico[10]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.taxEvasion", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,11)} id="defraudacion-hacienda" defaultValue={this.state.documentoHistorico[11]?this.state.documentoHistorico[11]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.socialSecurity", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,12)} id="seguridad-social" defaultValue={this.state.documentoHistorico[12]?this.state.documentoHistorico[12]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.employmentPractices", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,13)} id="practicas-empleo" defaultValue={this.state.documentoHistorico[13]?this.state.documentoHistorico[13]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.environmentalViolations", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,14)} defaultValue={this.state.documentoHistorico[14]?this.state.documentoHistorico[14]:""} id="infraccion-normas-medioambientales" placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.personalDataProtectionLaws", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,15)} defaultValue={this.state.documentoHistorico[15]?this.state.documentoHistorico[15]:""} id="infraccion-lopd" placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.civilClaimsAgainstAdministrators", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,16)} defaultValue={this.state.documentoHistorico[16]?this.state.documentoHistorico[16]:""} id="reclamaciones-civiles" placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.administratorsDisqualification", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,17)} id="administradores-inhabilitados" defaultValue={this.state.documentoHistorico[17]?this.state.documentoHistorico[17]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.reputationalDamage", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,18)} id="danos-reputacionales" defaultValue={this.state.documentoHistorico[18]?this.state.documentoHistorico[18]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.ongoingJudicialProcedure", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,19)} id="procedimiento-judicial" defaultValue={this.state.documentoHistorico[19]?this.state.documentoHistorico[19]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.ongoingAdministrativeSanctionProcedure", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,20)} id="procedimiento-administrativo" defaultValue={this.state.documentoHistorico[20]?this.state.documentoHistorico[20]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.companyCivilLiabilityInsurance", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,21)} id="rcg-compania" defaultValue={this.state.documentoHistorico[21]?this.state.documentoHistorico[21]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.managementCivilLiabilityInsurance", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,22)} id="rcg-administradores" defaultValue={this.state.documentoHistorico[22]?this.state.documentoHistorico[22]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                <FormGroup className="pixelAlrededorGrande"
                label={i18next.t("documentosCompliance.unforeseenContingencies", { ns: "page2" })}
                labelFor="informacion"
                >
                    <InputGroup onChange={(e) => cambioRespuesta(e,23)} id="contingencia-extraordinaria" defaultValue={this.state.documentoHistorico[23]?this.state.documentoHistorico[23]:""} placeholder="Escriba aqui..." intent="primary" />
                </FormGroup>
                {this.state.cargando?
                        <></>
                    :
                    <Button 
                        className="bp3-minimal bp3-intent-danger" 
                        icon="print" 
                        text={i18next.t("print", { ns: "global" })} 
                        onClick={()=>this.generarImprimirG()}
                    />
                    
                    }
                <Button 
                    alignText="left"
                    icon="floppy-disk"
                    text={i18next.t("guardar", { ns: "global" })}
                    intent="danger"
                    onClick={guardarDocumento}
                    className="iconoIzquierda bp3-minimal"
                    disabled={this.state.hayContrato}
                />
                </Card> 
            </>
        }
        </>
        )
    }
}
export default withTranslation()(withApollo(Documentos))