import React, { Component } from "react";
import { Card, FormGroup, TextArea, Elevation, Button,  Dialog, Spinner } from '@blueprintjs/core';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import { Cargando } from "../Pantallas/Cargando"

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
      dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:135,
    paddingTop:30,
    paddingLeft: 25,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#000000',
    padding:2,
    
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '90%'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize: 11,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  labelTitleOne  : {
    flexDirection: 'column',
    fontSize:13,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
  
})

 class CuestionesSociales extends Component {

    state = {
        idFinanciera:null,
        existeFinanciera:false,
        modalImprir:false,
        imprimirActivado:false,
        cargandoImprimir:false,
        cargandoCS:false,
        empleo: null,
        tiempo: null,
        salud:null,
        relaciones: null,
        formacion: null,
        accesibilidad: null,
        igualdad: null,
    }

    componentDidMount=(props)=>{
        this.setState({
            cargandoCS: true,
        }, async ()=>{
            if(this.props.datos!==null){                        
                  await this.setState({
                        cargandoCS:false,
                        idFinanciera:this.props.datos.ID_FINANCIERA,
                        empleo:this.props.datos.EMPLEO,
                        tiempo:this.props.datos.TIEMPO_TRABAJO,
                        salud:this.props.datos.SALUD,
                        relaciones: this.props.datos.RELACIONES,
                        formacion: this.props.datos.FORMACION,
                        accesibilidad: this.props.datos.ACCESIBILIDAD,
                        igualdad: this.props.datos.IGUALDAD,
                        existeFinanciera:true
                })
    
            }

        })
       
    }

    generarInformeCS= async () =>{
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true
        }, async () =>{
            this.setState({cargandoImprimir:false},() => {
                ReactDOM.render(this.ImprimirCS(), document.getElementById('informeCS-pdf'))
            })
        })
        
    }

    ImprimirCS= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("informacionnofinanciera.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View fixed style={styles.rowTitleOne}>
                    <Text style={styles.labelTitleOne}>{this.props.i18next.t("informacionnofinanciera.cuestionessociales",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.empleo",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.empleo}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.tiempo",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.tiempo}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.saludseguridad",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.salud}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.relacionessociales",{ns:"page"})}</Text>
                    </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.relaciones}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.formacion",{ns:"page"})}</Text>
                </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.formacion}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.accesibilidad",{ns:"page"})}</Text>
                </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.accesibilidad}</Text>
                </View>
                <View style={styles.rowTitle}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("informacionnofinanciera.igualdad",{ns:"page"})}</Text>
                </View>
                <View style={styles.row}>
                        <Text style={styles.textData}>{this.state.igualdad}</Text>
                </View>
               
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        const cerrarModalImprimir = () => {
            this.setState({modalImprimir:false})
        }

        const handleCambiarEmpleo = (event) => {
            let empleo = event.target.value
             empleo= empleo.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({empleo: empleo})
        }

        const handleCambiarTiempo = (event) => {
            let tiempo = event.target.value
             tiempo= tiempo.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({tiempo: tiempo})
        }

        const handleCambiarSalud = (event) => {
            let salud = event.target.value
             salud= salud.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({salud: salud})
        }

        const handleCambiarRelaciones = (event) => {
            let relaciones = event.target.value
             relaciones= relaciones.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({relaciones: relaciones})
        }

        const handleFormacion = (event) => {
            let formacion = event.target.value
             formacion= formacion.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({formacion: formacion})
        }

        const handleAccesibilidad = (event) => {
            let accesibilidad = event.target.value
             accesibilidad= accesibilidad.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({accesibilidad: accesibilidad})
        }

        const handleIgualdad = (event) => {
            let igualdad = event.target.value
             igualdad= igualdad.replace(/(\r\n|\n|\r)/gm, "")
            this.setState({igualdad:igualdad})
        }
        const guardarCambios= async ()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            
            //si hay datos
            if(this.state.existeFinanciera){
                console.log(this.state.existeFinanciera)
                console.log(this.props.client)
                //actualizamos los datos
              await this.props.client
              .mutate({
                    mutation:gql`mutation{actualizarInformacionNoFinanciera(
                        ID_FINANCIERA:${this.state.idFinanciera},
                        VALORES:{
                            EMPLEO:${this.state.empleo?'"'+this.state.empleo+'"':null},
                            TIEMPO_TRABAJO:${this.state.tiempo?'"'+this.state.tiempo+'"':null},
                            SALUD:${this.state.salud?'"'+this.state.salud+'"':null},
                            RELACIONES:${this.state.relaciones?'"'+this.state.relaciones+'"':null},
                            FORMACION:${this.state.formacion?'"'+this.state.formacion+'"':null},
                            ACCESIBILIDAD:${this.state.accesibilidad?'"'+this.state.accesibilidad+'"':null},
                            IGUALDAD:${this.state.igualdad?'"'+this.state.igualdad+'"':null},
                            }){ID_FINANCIERA}}`
                        }).then(async result=>{
                  
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

            }else{

                //creamos un nuevo registro
                this.props.client.mutate({
                    mutation:gql`mutation{crearInformacionNoFinanciera(
                        VALORES:{
                            ID_FINANCIERA:${1}
                            EMPLEO:${this.state.empleo?'"'+this.state.empleo+'"':null},
                            TIEMPO_TRABAJO:${this.state.tiempo?'"'+this.state.tiempo+'"':null},
                            SALUD:${this.state.salud?'"'+this.state.salud+'"':null},
                            RELACIONES:${this.state.relaciones?'"'+this.state.relaciones+'"':null},
                            FORMACION:${this.state.formacion?'"'+this.state.formacion+'"':null},
                            ACCESIBILIDAD:${this.state.accesibilidad?'"'+this.state.accesibilidad+'"':null},
                            IGUALDAD:${this.state.igualdad?'"'+this.state.igualdad+'"':null},
                            }){ID_FINANCIERA}}`
                }).then(async result=>{
                  
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                    await this.setState({
                        existeFinanciera:true
                    })


                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })


            }

        }

        return (
            <>
             {this.state.cargandoCS === true?
                <Cargando />
                :
                <>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informeCS-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

            <div className={"pixelAlrededor tablaSesenta" } >
                <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.actividades= input; }} >
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.empleo",{ns:"page"})+":"}
                        labelFor="empleo"
                    >
                        <TextArea id="empleo" fill={true} onChange={handleCambiarEmpleo} value={this.state.empleo?this.state.empleo:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.tiempo",{ns:"page"})+":"}
                        labelFor="tiempo-trabajo"
                    >
                        <TextArea id="tiempo-trabajo" fill={true} onChange={handleCambiarTiempo} value={this.state.tiempo?this.state.tiempo:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.saludseguridad",{ns:"page"})+":"}
                        labelFor="salud-seguridad"
                    >
                        <TextArea id="salud-seguridad" fill={true} onChange={handleCambiarSalud} value={this.state.salud?this.state.tiempo:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.relacionessociales",{ns:"page"})+":"}
                        labelFor="relaciones-sociales"
                    >
                        <TextArea id="relaciones-sociales" fill={true} onChange={handleCambiarRelaciones} value={this.state.relaciones?this.state.relaciones:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.formacion",{ns:"page"})+":"}
                        labelFor="formacion"
                    >
                        <TextArea id="formacion" fill={true} onChange={handleFormacion} value={this.state.formacion?this.state.formacion:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.accesibilidad",{ns:"page"})+":"}
                        labelFor="accesibilidad"
                    >
                        <TextArea id="accesibilidad" fill={true} onChange={handleAccesibilidad} value={this.state.accesibilidad?this.state.accesibilidad:''} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("informacionnofinanciera.igualdad",{ns:"page"})+":"}
                        labelFor="igualdad"
                    >
                        <TextArea id="igualdad" fill={true} onChange={handleIgualdad} value={this.state.igualdad?this.state.igualdad:''} />
                    </FormGroup>
                    <Button className="iconoDerecha bp3-intent-danger" icon="print" onClick={()=>this.generarInformeCS()}>{this.props.i18next.t("print",{ns:"global"})}</Button>
                    <Button className="iconoDerecha bp3-intent-danger" icon="floppy-disk" onClick={guardarCambios}>{this.props.i18next.t("guardar",{ns:"global"})}</Button>
                    
                    <br/>
                    <br/>
                </Card>
            </div>
        </>
        }
       </> 
        )
    }
}

export default withApollo(CuestionesSociales)