/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import {Spinner,Card, Elevation, Navbar, NavbarHeading, ButtonGroup, Alignment, Button, Dialog, FormGroup, Classes, Tooltip, AnchorButton, Intent } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "./Cargando";
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import styles from './stylesPrint';

class OrganosInstruccion extends Component {
    state={
        asesorSeleccionado:null,
        modalAsesoresBuscarAbierto: false,

        pendienteGuardarEmpleados:null,
        modalAsesoresAbierto: false,
        empleadoSelectSeleccionado:null,
        personaAsesorSeleccionada:null,
        empleadoSeleccionado:null,
        pendienteBorrarEmpleado:null,
        modalEmpleadosAbierto:null,

        buscarMiembroActivado:true,
        eliminarMiembroActivado:false,
        nuevoAsesorActivado:true,
        buscarAsesorActivado:false,
        eliminarAsesorActivado:false,

        empleadosSelect:[
        ],
        empleados:[
        ],
        asesoresExternos:[        
        ],
        clientesSelect: [
        ],
       
        modalBorrarEmpleadoAbierto:false,
        modalBorrarAsesorAbierto:false,
        modalPersonaAbierto:false,
        modalImprimirOA:false,
        cargandoImprimirOA:false,
        modalImprimirOM:false,
        cargandoImprimirOM:false,
        datosEmpresa:[],
        logotipo:null,
        cargando:false,
        personasFisicas:null,
        esEmpleado:null
        
    }

    
    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }
   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarEmpresa() 
    let personasFisicas= await this.cargarPersonasFisicas()
    let empleado=await this.obtenerEmpleados()
    console.log(empleado)   
    this.setState({
        datosEmpresa:datosEmpresa,
        personasFisicas:personasFisicas,
        esEmpleado:empleado
    })

}

    cargarPersonasFisicas=async()=>{
       let personasFisicas= await this.props.client
            .query({
                query: gql`
                {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{
                return result.data.personasFisicas 
            })
             return personasFisicas    
    }
    obtenerEmpleados=async()=>{
       let empleado=await this.props.client
            .query({
                query: gql`
                {empleados{ID_EMPLEADO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
               return result.data.empleados
            })
            return empleado
    
        }
    

    esEmpleado=async(idPersona)=>{
        let empleadosVal=[]
        this.state.esEmpleado.find(empleado=>{
            if(empleado.ID_EMPLEADO===idPersona){
                empleadosVal.push(empleado)
            }
        })
        if(empleadosVal.length>0){
            return true
        }else{
            return false
        }
        // return await this.props.client
        // .query({
        //     query: gql`
        //     {empleados(FILTROS:{ID_EMPLEADO:${idPersona}}){ID_EMPLEADO}}
        //     `,
        //     fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     return (result.data.empleados.length>0?true:false)
        // })

    }

    cargarAsesores=async()=>{
        let complianceOrganos=await this.props.client
        .query({
            query: gql`
            {complianceOrganosInstruccion{ID_PERSONA}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            let compliances=[]
            for(let compliance of result.data.complianceOrganosInstruccion){
                if(await this.esEmpleado(compliance.ID_PERSONA)===false){
                    compliances.push(compliance)
                }
            }              
              return compliances
        })        
                
            for(let compliance of complianceOrganos){
                 //obtencion del nombre de la persona fisica.
                //RELLENO LA TABLA DE PERSONAS EXTERNAS
                let nombre='';
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===compliance.ID_PERSONA){
                        nombre= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                    }
                    compliance.id= persona.ID_PERSONA
                    compliance.nombre=nombre
                })
            }
            console.log(complianceOrganos)
            this.setState({asesoresExternos:complianceOrganos})

    }

   
    
    cargarEmpleados=async ()=>{

        let complianceOrganos=await this.props.client
        .query({
            query: gql`
            {complianceOrganosInstruccion{ID_PERSONA}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            let compliances=[]
            for(let compliance of result.data.complianceOrganosInstruccion){
                if(await this.esEmpleado(compliance.ID_PERSONA)===true){
                
                    compliances.push(compliance)
                }
            }   

            return compliances
        
        })
        
        //buscamos el nombre
        for(let compliance of complianceOrganos){
             //obtencion del nombre de la persona fisica.
            //RELLENO LA TABLA DE PERSONAS EXTERNAS
            let nombre='';

            this.state.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===compliance.ID_PERSONA){
                    nombre= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                }
                compliance.id= persona.ID_PERSONA
                compliance.nombre=nombre
            })
        }

        this.setState({empleados:complianceOrganos})

    }
    cargarPersonas=async()=>{
        let personas=[];
        this.state.personasFisicas.forEach(async persona=>{
            if(!await this.esEmpleado(persona.ID_PERSONA)){
              personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`})
            }
        })
        this.setState({clientesSelect:personas})
        // this.props.client
        // .query({
        //     query: gql`
        //     {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
        //     `,
        //     fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     let personas=[]
        //     result.data.personasFisicas.forEach(async persona=>{
        //         if(!await this.esEmpleado(persona.ID_PERSONA)){
        //             personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`})
        //         }
        //     })
        //     this.setState({clientesSelect:personas})
        // })
    }

    cargarTablaEmpleados=async()=>{
        //carga desplegables empleados
        let empleados=[];
        this.state.personasFisicas.forEach(persona=>{
            this.state.esEmpleado.find(empleado=>{
                if(empleado.ID_EMPLEADO===persona.ID_PERSONA){
                    empleados.push({"value":empleado.ID_EMPLEADO,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`})
                }
            })
            this.setState({empleadosSelect:empleados})

        })

        // this.props.client
        // .query({
        //     query: gql`
        //     {personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
        //     `,
        //     fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     let empleados=[]
        //     result.data.personasFisicas.forEach(persona=>{
        //         //obtencion del nombre de la persona fisica.
        //         //RELLENO LA TABLA DE PERSONAS EXTERNAS
        //         this.props.client
        //         .query({
        //             query: gql`
        //             {empleados(FILTROS:{ID_EMPLEADO:${persona.ID_PERSONA}}){ID_EMPLEADO}}
        //             `,
        //             fetchPolicy:'network-only'
        //         }).then(result=>{ 
        //             result.data.empleados.forEach(empleado=>{
        //                 empleados.push({"value":empleado.ID_EMPLEADO,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`})
        //             })
        //             this.setState({empleadosSelect:empleados})
        //         })

        //     })
        // })

    }
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }


    componentDidMount(){
        this.setState({
            cargando:true
        }, async()=>{
            await this.cargarDatos()
            await this.cargarAsesores()
            await this.cargarTablaEmpleados()
            await this.cargarEmpleados()
            await this.cargarPersonas()
            await this.obtenerLogoEmpresa()
            this.setState({cargando:false})
        })

    }
    
    generarImprimirM=async()=>{
        this.setState({
            cargandoImprimirOM:true,
            modalImprimirOM:true
        },async () => {           
            this.setState({cargandoImprimirOM:false}, ()=>{
                ReactDOM.render(this.ImprimirMiembros(), document.getElementById('generarImprimirM-pdf'))
            })
        })
    }


     generarImprimirA=async()=>{
            this.setState({
                cargandoImprimirOA:true,
                modalImprimirOA:true
            },async () => {
               this.setState({cargandoImprimirOA:false},()=>{
                    ReactDOM.render(this.ImprimirAsesores(), document.getElementById('generarImprimirA-pdf'))
                })
            })
    }

    ImprimirMiembros=() =>(
    
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4"  style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organosinstruccion.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("organosinstruccion.listmiembro",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.empleados?this.state.empleados.map((empleado, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column',  fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("organosinstruccion.miembro",{ns:"page"}).toUpperCase()}</Text>
                                 </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{empleado.nombre?empleado.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
      )
    
ImprimirAsesores=()=>(
            <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
              <Page size="A4"  style={styles.page}>
                <View style={styles.body}>
                    <View fixed style={styles.row}>
                      <Image
                        style={styles.imageHeadL}
                        src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                        />
                    </View>
                    <View fixed style={styles.rowHead}>
                        <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                        <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                    </View>
                    <View fixed style={styles.rowHeadTwo}>
                        <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                        <Text style={styles.text}>{i18next.t("organosinstruccion.title",{ns:"page"}).toUpperCase()}</Text>
                    </View>
                    <View style={styles.rowTitleOne}>
                        <Text style={styles.labelTitle}>{i18next.t("organosinstruccion.listasesor",{ns:"page"}).toUpperCase()}</Text>
                    </View>
                        {this.state.asesoresExternos?this.state.asesoresExternos.map((asesor, index)=>{
                            console.log(asesor)
                            return(
                                <View key={index}>
                                   {index ===0 && (
                                    <View style={styles.colHead}>
                                       <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'100%'}}>{i18next.t("organosinstruccion.asesor",{ns:"page"}).toUpperCase()}</Text>
                                     </View>
                                   )}
                                    <View style={styles.colHead}>
                                        <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'100%'}}>{asesor.nombre?asesor.nombre:''}</Text>
                                    </View>                               
                                
                            </View>
                            )
                        }):<></>}      
                </View>
        
                <Text
                    style={styles.footerPage}
                    render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                    fixed
                  />
                <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                        />
                <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
            </Document>
          </PDFViewer>
    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("organosinstruccion.title",{ns:"page"});

        const columns=[{
            dataField: 'nombre',
            text: const_table.capitalize(i18next.t("organosinstruccion.miembro",{ns:"page"}))
        }];

        const columnsAsesor=[{
            dataField: 'nombre',
            text: const_table.capitalize(i18next.t("organosinstruccion.asesor",{ns:"page"}))
        }];

        const handleOnSelect=(row, isSelect)=> {
            if (isSelect) {
                this.setState({
                    empleadoSeleccionado: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        } 
        const handleOnSelectAsesor=(row, isSelect)=> {
            if (isSelect) {
                this.setState({
                    asesorSeleccionado: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }    

        const abrirModalNuevo1 = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            this.setState({
                modalAsesoresBuscarAbierto: true,
                nuevoAsesorActivado:false,
                buscarAsesorActivado:true,
                eliminarAsesorActivado:false,
                personaAsesorSeleccionada:null
            })
        }

        const abrirModalEmpleados = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalEmpleadosAbierto:true,
                buscarMiembroActivado:true,
                eliminarMiembroActivado:false,
                empleadoSelectSeleccionado:null
            })
        }

        const abrirModalBorrarEmpleados = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({buscarMiembroActivado:false})
            this.setState({eliminarMiembroActivado:true})
            this.setState({modalBorrarEmpleadoAbierto:true})
        }

        const abrirModalBorrarAsesor = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.asesorSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({nuevoAsesorActivado:false})
            this.setState({buscarAsesorActivado:false})
            this.setState({eliminarAsesorActivado:true})
            this.setState({modalBorrarAsesorAbierto:true})
        }

        const handleSeleccionarPersona=(value)=>{
            this.setState({personaAsesorSeleccionada:value})
        }

        const handleSeleccionarEmpleadoSelect=(value)=>{
            this.setState({empleadoSelectSeleccionado:value})
        }

        const cerrarModal = () => {
            this.setState({
                modalAsesoresBuscarAbierto:false,
                modalAsesoresAbierto: false,
                modalEmpleadosAbierto:false,
                modalBorrarEmpleadoAbierto:false,
                modalBorrarAsesorAbierto:false,
                modalPersonaAbierto:false
            })
        }

        const eliminarAsesor = () => {
            let asesoresExternos=this.state.asesoresExternos.filter(asesor => asesor.id !== this.state.asesorSeleccionado.id)
            this.setState({asesoresExternos:asesoresExternos})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
            
            cerrarModal()
        };

        const eliminarEmpleado = () => {
            let empleados=this.state.empleados.filter(empleado => empleado.id !== this.state.empleadoSeleccionado.id)
            this.setState({empleados:empleados})
            this.setState({pendienteBorrarEmpleado:true})     
            this.setState({controlesEmpleadosDesactivados:true})
            
            cerrarModal()
        };

        const añadirAsesorSeleccionado=()=>{

            if(!this.state.personaAsesorSeleccionada)return
            let asesores=this.state.asesoresExternos

            //comporbar que la persona seleccionada no este ya en la lista.
            let existe=asesores.find(asesor=>{return asesor.id===this.state.personaAsesorSeleccionada.value})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            asesores.push({"nombre":this.state.personaAsesorSeleccionada.label})
            this.setState({asesores:asesores})
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        }

        const añadirEmpleado=()=>{

            if(!this.state.empleadoSelectSeleccionado){
                return
            }
            let empleados=this.state.empleados

            let existe=empleados.find(empleado=>{return empleado.id===this.state.empleadoSelectSeleccionado.value})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            empleados.push({"nombre":this.state.empleadoSelectSeleccionado.label})
            this.setState({empleados:empleados})
            this.setState({pendienteGuardarEmpleados:true})
            this.setState({controlesEmpleadosDesactivados:true})
            cerrarModal()

        }

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.pendienteGuardar){                               

                this.props.client.mutate({
                    mutation:gql `mutation{crearOrganoInstruccion(VALORES:{ID_PERSONA:${this.state.personaAsesorSeleccionada.value}}){ID_PERSONA}}`
                }).then(result=>{
                
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    this.cargarAsesores()
                    this.setState({pendienteGuardar:false})
                    this.setState({controlesDesactivados:false})
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarOrganoInstruccion(ID_PERSONA:${this.state.asesorSeleccionado.id}){ID_PERSONA}}`
                }).then(result=>{
             
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({controlesDesactivados:false})
                    this.setState({pendienteBorrar:false})
                    this.cargarAsesores()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
            }
        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.cargarAsesores()

            this.setState({
                pendienteGuardar:false,
                pendienteBorrar:false,
                controlesDesactivados:false,
                personaAsesorSeleccionada:null
            })

        }


        const guardarCambiosEmpleados=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.pendienteGuardarEmpleados){

                this.props.client.mutate({
                    mutation:gql`mutation{crearOrganoInstruccion(VALORES:{ID_PERSONA:${this.state.empleadoSelectSeleccionado.value}}){ID_PERSONA}}`
                }).then(result=>{
                
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    this.setState({pendienteGuardarEmpleados:false})
                    this.setState({controlesEmpleadosDesactivados:false})
                    this.cargarEmpleados()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrarEmpleado){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarOrganoInstruccion(ID_PERSONA:${this.state.empleadoSeleccionado.id}){ID_PERSONA}}`
                }).then(result=>{

                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({controlesEmpleadosDesactivados:false})
                    this.setState({controlesEmpleadosDesactivados:false})
                    this.cargarEmpleados()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
            }

        }

        const cancelarCambiosEmpleados=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.cargarEmpleados()

            this.setState({
                pendienteGuardarEmpleados:false,
                pendienteBorrarEmpleado:false,
                controlesEmpleadosDesactivados:false,
                empleadoSelectSeleccionado:null
            })

        }

        const crearPersona=async()=>{

            await this.datosPersona.crearPersona()
            if(this.datosPersona.state.idPersonaCreada){
                this.props.client.mutate({
                    mutation:gql `mutation{crearOrganoInstruccion(VALORES:{ID_PERSONA:${this.datosPersona.state.idPersonaCreada}}){ID_PERSONA}}`
                }).then(result=>{
                 
                        alert(i18next.t("insertcorrecto",{ns:"global"}))
                        this.cargarAsesores()
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })            
                //cerrar el modal
                cerrarModal()
            }

        }

        
        const abrirModalNuevaPersona=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                nuevoAsesorActivado:true,
                buscarAsesorActivado:false,
                eliminarAsesorActivado:false
            })
        }
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirOA:false,
                modalImprimirOM:false
            })
        }
        return (
            <>
                {this.state.cargando===true?
                     <Cargando/>
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("organosinstruccion.imprimirmiembros",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirOM}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirOM?
                            <div style={{ width: '100%', height: '500px'}} id='generarImprimirM-pdf'>
                            </div>
                            :
                                <Spinner />
                    }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("organosinstruccion.imprimirasesores",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirOA}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirOA?
                            <div style={{ width: '100%', height: '500px'}} id="generarImprimirA-pdf">
                            </div>
                            :
                                <Spinner />
                    }

                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organosinstruccion.selectasesor",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAsesoresBuscarAbierto}
                >
                   <FormGroup
                        label={i18next.t("identificacion.empleado",{ns:"page"})}
                        labelFor="empleado"
                        intent="danger"
                        helperText={!this.state.personaAsesorSeleccionada?i18next.t("organosinstruccion.emptyempleado",{ns:"page"}):""}
                    >
                        <div className="pixelAlrededor" style={!this.state.personaAsesorSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.clientesSelect}
                                className="pixelAlrededor"
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={handleSeleccionarPersona}
                            />
                        </div>
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.DANGER}
                                onClick={añadirAsesorSeleccionado}
                            >
                               {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas 
                        datosPersona={this.state.adminstradorSeleccionado?this.state.adminstradorSeleccionado.value:null} 
                        ref={(input) => { this.datosPersona= input; }}
                        i18next={i18next} 
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={crearPersona} 
                            >
                            {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
 
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("organosinstruccion.selectempleado",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEmpleadosAbierto}
                >
                     <FormGroup
                            label={i18next.t("identificacion.empleado",{ns:"page"})}
                            labelFor="empleado"
                            intent="danger"
                            helperText={!this.state.empleadoSelectSeleccionado?i18next.t("organosinstruccion.emptyempleado",{ns:"page"}):""}
                        >
                            <div className="pixelAlrededor" style={!this.state.empleadoSelectSeleccionado?{border:"1px solid red"}:{}}>
                                <Select
                                    options={this.state.empleadosSelect}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={handleSeleccionarEmpleadoSelect}
                                />
                             </div>
                        </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.DANGER}
                                onClick={añadirEmpleado}
                            >
                               {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar miembro"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarEmpleadoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el mimbro?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarEmpleado}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar miembro"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarEmpleadoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el mimbro?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarEmpleado}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar miembro"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAsesorAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el asesor?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAsesor}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                
                <div className="tablaMitad pixelAlrededor">
                <Card interactive={true} elevation={Elevation.TWO}>
                        <Navbar>
                            <Navbar.Group>
                                <NavbarHeading>{i18next.t("organosinstruccion.miembrosorganizacion",{ns:"page"})}</NavbarHeading>
                            </Navbar.Group>
                        </Navbar>
                        <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                            {this.state.controlesEmpleadosDesactivados?[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar",{ns:"global"})} active={this.state.buscarMiembroActivado} disabled/>
                                        <Button className="bp3-minimal" key="btnBorra" icon="delete" text={i18next.t("delete",{ns:"global"})}   active={this.state.eliminarMiembroActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} disabled/>
                                     </React.Fragment>
                                ]:[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar",{ns:"global"})} active={this.state.buscarMiembroActivado} onClick={abrirModalEmpleados} />
                                        <Button className="bp3-minimal" key="btnBorra" icon="delete" text={i18next.t("delete",{ns:"global"})} onClick={abrirModalBorrarEmpleados} active={this.state.eliminarMiembroActivado}/>
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirM()}/> 

                                    </React.Fragment>
                            ]}
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                        keyField='ID_PERSONA'
                        data={ this.state.empleados }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    <div>
                        <br/>
                            <Button 
                                alignText="right"
                                icon="floppy-disk"
                                text={i18next.t("guardar",{ns:"global"})}
                                disabled={!this.state.controlesEmpleadosDesactivados}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                                onClick={guardarCambiosEmpleados}
                            />
                             <Button 
                                alignText="right"
                                icon="cross"
                                text={i18next.t("cancelar",{ns:"global"})}
                                disabled={!this.state.controlesEmpleadosDesactivados}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                                onClick={cancelarCambiosEmpleados}
                            />
                        <br />
                        <br />
                    </div>
                </Card>
                </div>
                <div className="tablaMitad pixelAlrededor">
                <Card interactive={true} elevation={Elevation.TWO}>
                        <Navbar>
                            <Navbar.Group>
                                <NavbarHeading>{i18next.t("organosinstruccion.asesoresexternos",{ns:"page"})}</NavbarHeading>
                            </Navbar.Group>
                        </Navbar>
                        <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                               
                                {this.state.controlesDesactivados?[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnNuevo" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar",{ns:"global"})} active={this.state.nuevoActivado}  disabled/>
                                        <Button className="bp3-minimal" key="btnBorrar" icon="delete" text={i18next.t("delete",{ns:"global"})}   active={this.state.borrarActivado} disabled/>
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} disabled/> 
                                    </React.Fragment>
                                ]:[
                                    <React.Fragment key="fragment">
                                        <Button className="bp3-minimal" key="btnNuevo" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoAsesorActivado} onClick={abrirModalNuevaPersona} />
                                        <Button className="bp3-minimal" key="btnBuscar" icon="geosearch" text={i18next.t("buscar",{ns:"global"})} active={this.state.buscarAsesorActivado} onClick={abrirModalNuevo1} />
                                        <Button className="bp3-minimal" key="btnBorrar" icon="delete" text={i18next.t("delete",{ns:"global"})}  onClick={abrirModalBorrarAsesor} active={this.state.eliminarAsesorActivado}/>
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirA()}/> 
                                       
                                    </React.Fragment>
                            ]}
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                        keyField='ID_PERSONA'
                        data={ this.state.asesoresExternos }
                        columns={ columnsAsesor }
                        selectRow={ const_table.selectRow(handleOnSelectAsesor) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    <div>
                        <br/>
                            <Button 
                                alignText="right"
                                icon="floppy-disk"
                                onClick={guardarCambios}
                                disabled={!this.state.controlesDesactivados}
                                text={i18next.t("guardar",{ns:"global"})}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="cross"
                                onClick={cancelarCambios}
                                disabled={!this.state.controlesDesactivados}
                                text={i18next.t("cancelar",{ns:"global"})}
                                intent="danger"
                                className="bp3-intent-primary iconoDerecha bp3-minimal"
                            />
                        <br />
                        <br />
                    </div>
                    </Card>
                </div>
            </>
        }
        </>
        )
    }
}

export default withTranslation()(withApollo(OrganosInstruccion))