import React, {Component} from "react";
import { withApollo } from "react-apollo";
import {Card,Elevation,Navbar,Tooltip,ButtonGroup,Alignment,Button} from "@blueprintjs/core";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Cargando } from "./Cargando";
import ReactToPrint from "react-to-print";
import styles from "./stylesPrint";
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png';
import gql from "graphql-tag";
import moment from 'moment';
import global from './Elementos/Global';

class InformeEmpresa extends Component {
    state={
        cargando:true,
        logotipo: null,
        personasJuridicas:null,
        cif:null,
        grupo: null,
        fechaInicio:null,
        fechaFin:null,
        convenios: [],
        ccc:[],
        marcas: [],
        nombresCom: [],
        patentes: [],
        propiedades: [],
        dominios: [],
        medios: [],
        centros: [],
        sucursales: [],
        memorias: [],
        planesMovilidad: [],
        informes: [],
        planesIgualdad: [],
        sociedades: [],
        fusiones: "",
        escisiones: "",
        filiales: "",
        adjuntos: [],
        objeto:null,
        prodcutos:null,
        servicios:null,
        distribucion:null,
        zonas:null,
        actividades:null,
        activiadesEntorno:null,
        actividadesMedio:null,
        actividadesMedioRiesgo:null,
        fijas:null,
        moviles:null,
        especiales:null,
        transporte:null,
        maquinaria:null,
        equipos:null,
        software:null,
        existeInstalaciones:null,
        seguros:[]
    }

    componentDidMount(){

        this.setState({
            cargando: true
        },async()=>{
            await this.obtenerLogoEmpresa();
            await this.cargarDatosEmpresa();
        })

    }

    cargarGrupos=async()=>{

        this.props.client
        .query({
            query: gql`
            {
                gruposEmpresariales(FILTROS:{ID_GRUPO_EMPRESARIAL:${this.state.personasJuridicas.ID_GRUPO_EMPRESARIAL}}){ID_GRUPO_EMPRESARIAL,DESCRIPCION}
            }
            `, 
            fetchPolicy: 'network-only'
              
        }).then(result=>{
            if(result.data.gruposEmpresariales.length>0){
                this.setState({grupo:result.data.gruposEmpresariales[0].DESCRIPCION});
            }
            this.setState({cargando:false});
        })

    }

    cargarInstalaciones=async()=>{
        this.props.client
       .query({
         query: gql`
           {instalaciones{
            FIJAS,
            MOVILES,
            ESPECIALES,
            TRANSPORTE,
            MAQUINARIA,
            INFORMATICOS,
            SOFTWARE}}
         `
       })
       .then(result =>{
           if(result.data.instalaciones.length>0){

                this.setState({fijas:result.data.instalaciones[0].FIJAS,
                    moviles:result.data.instalaciones[0].MOVILES,
                    especiales:result.data.instalaciones[0].ESPECIALES,
                    transporte:result.data.instalaciones[0].TRANSPORTE,
                    maquinaria:result.data.instalaciones[0].MAQUINARIA,
                    equipos:result.data.instalaciones[0].INFORMATICOS,
                    software:result.data.instalaciones[0].SOFTWARE
                })

           }
       });
    }

    cargarActividades=async()=>{
        this.props.client
       .query({
         query: gql`
           {actividades{
            OBJETO_SOCIAL,
            PRODUCTOS,
            SERVICIOS,
            CANALES,
            ZONAS,
            RIESGO_INTRINSECO,
            RIESGO_ENTORNO,
            IMPACTO_MEDIOAMBIENTAL,
            RIESGO_MEDIOAMBIENTAL}}
         `,
         fetchPolicy:'network-only'
       })
       .then(result =>{
           if(result.data.actividades.length>0){

                this.setState({
                    objeto:result.data.actividades[0].OBJETO_SOCIAL,
                    prodcutos:result.data.actividades[0].PRODUCTOS,
                    servicios:result.data.actividades[0].SERVICIOS,
                    distribucion:result.data.actividades[0].CANALES,
                    zonas:result.data.actividades[0].ZONAS,
                    actividades:result.data.actividades[0].RIESGO_INTRINSECO,
                    activiadesEntorno:result.data.actividades[0].RIESGO_ENTORNO,
                    actividadesMedio:result.data.actividades[0].IMPACTO_MEDIOAMBIENTAL,
                    actividadesMedioRiesgo:result.data.actividades[0].RIESGO_MEDIOAMBIENTAL
                })

           }
       });
    }

    cargarCIF=async ()=>{

        await this.props.client
        .query({
        query: gql`
        {
            documentosIdentificativos(FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA},ID_TIPO_DOCUMENTO:4}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR,FECHA_EMISION,FECHA_CADUCIDAD}
        }
        `,
        fetchPolicy: 'network-only'

        }).then(result=>{ 

            if(result.data.documentosIdentificativos.length>0){
                this.setState({cif:result.data.documentosIdentificativos[0].VALOR});
            }
        })
    }

    cargarMarcas=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
              personasJuridicasMarcas(ORDEN:"ID_MARCA,ASC",FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_MARCA,NOMBRE,NRO_REGISTRO,NACIONAL_INTERNACIONAL,CLASIFICACION_NIZA,DESCRIPCION_CLASE,VALIDEZ}
            }
            `,
            fetchPolicy: 'network-only'
    
        }).then(result=>{ 
        
            let marcas=[]
            for(let marca of result.data.personasJuridicasMarcas){
              marcas.push({"id":marca.ID_MARCA,"nombre":marca.NOMBRE,"nro_registro":marca.NRO_REGISTRO,"nacional_internacional":marca.NACIONAL_INTERNACIONAL,"clasificacion_niza":marca.CLASIFICACION_NIZA,"descripcion_clase":marca.DESCRIPCION_CLASE,"validez":marca.VALIDEZ})
            }
    
            this.setState({
              marcas:marcas,
            })
        
        })
        
    }

    cargarAdjuntos=async()=>{

        let copyAdjuntos=[]

        //obtener los tipos de documento 'otros'
        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_ASOCIADO:${this.state.personasJuridicas.ID_PERSONA},ID_ENTIDAD_ASOCIADA:1,ID_TIPO_ADJUNTO:3}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            }
        })

        //obtener los tipos de documento 'estatutos de la empresa'
        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_ASOCIADO:${this.state.personasJuridicas.ID_PERSONA},ID_ENTIDAD_ASOCIADA:1,ID_TIPO_ADJUNTO:4}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            }
        })

        copyAdjuntos.sort=async (a,b)=>{
            return a.ID_ADJUNTO-b.ID_ADJUNTO
        }

        await this.setState({adjuntos:copyAdjuntos})

    }

    cargarSociedades=async ()=>{

        await this.props.client
        .query({
            query: gql`
            {
                sociedadesVinculadas{ID_SOCIEDAD_VINCULADA,NOMBRE,PORCENTAJE_PARTICIPACION,COMENTARIOS}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 

            let sociedades=result.data.sociedadesVinculadas;
            let copySociedades=[]
            sociedades.forEach((sociedad,index)=>{

                copySociedades.push({
                    "id":sociedad.ID_SOCIEDAD_VINCULADA,
                    "nombre":sociedad.NOMBRE,
                    "Participacion": sociedad.PORCENTAJE_PARTICIPACION,
                    "Comentarios": sociedad.COMENTARIOS
                })
            })
            this.setState({sociedades:copySociedades})
        })
    }

    cargarSeguros=async ()=>{

        //carga de las polizas 
        await this.props.client
        .query({
            query: gql`
            {polizasSeguro{
                ID_POLIZA,
                ID_TIPO_SEGURO,
                TIPO_SEGURO{DESCRIPCION},
                CAPITAL_ASEGURADO,
                GARANTIAS_ASEGURADAS,
                FECHA_DESDE,
                FECHA_HASTA,
                NOTAS,
                ID_ASEGURADORA,
                ASEGURADORA{DESCRIPCION},
                NUMERO_POLIZA,
                ID_ADJUNTO,
                DOC_ADJUNTO{DATOS}
                }}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            let polizas=[]
            result.data.polizasSeguro.forEach(poliza=>{

                polizas.push({
                    "id":poliza.ID_POLIZA,
                    "idTipoSeguro":poliza.ID_TIPO_SEGURO,
                    "tipoSeguro":poliza.TIPO_SEGURO.DESCRIPCION,
                    "capital":poliza.CAPITAL_ASEGURADO,
                    "garantias":poliza.GARANTIAS_ASEGURADAS,
                    "fechaDesde":poliza.FECHA_DESDE,
                    "fechaHasta":poliza.FECHA_HASTA,
                    "notas":poliza.NOTAS,
                    "idAseguradora":poliza.ID_ASEGURADORA,
                    "aseguradora":poliza.ASEGURADORA.DESCRIPCION,
                    "numeroPoliza":poliza.NUMERO_POLIZA,
                    "idDocumento":poliza.ID_ADJUNTO,
                    "rutaDocumento":poliza.DOC_ADJUNTO.DATOS
                })
            })
            this.setState({seguros:polizas})
        })
    }

    cargarMemorias=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:7}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({memorias:copyAdjuntos})

        })
    }

    cargarPlanesMovilidad=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:10}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({planesMovilidad:copyAdjuntos})

        })
    }

    cargarCentros=async ()=>{

        await this.props.client
         .query({
             query: gql`
             {
                 centrosTrabajos(FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_CENTRO_TRABAJO,NOMBRE,DOMICILIO_SOCIAL,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
             }
             `,fetchPolicy:'network-only'
         }).then(result=>{ 
             let copyCentros=[]
             for(let centro of result.data.centrosTrabajos){
                 if(centro.DOMICILIO_SOCIAL===false){
                     copyCentros.push({"nombre":centro.NOMBRE,"domicilioSocial":centro.DOMICILIO_SOCIAL,"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM}${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""} ( ${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
                 }else{
                     copyCentros.splice(0,0,{"nombre":centro.NOMBRE,"domicilioSocial":centro.DOMICILIO_SOCIAL,"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM}${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""} ( ${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
                 }
             }   
          
             this.setState({centros:copyCentros});
         })
 
    }

    cargarSucursales=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                sucursales(FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_SUCURSAL,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let copySucursales=[]
            result.data.sucursales.forEach((sucursal,index)=>{
                copySucursales.push({"nombre":sucursal.NOMBRE,"direccion":`${sucursal.DIRECCION.TIPO_VIA.DESCRIPCION} ${sucursal.DIRECCION.NOMBRE_VIA},${sucursal.DIRECCION.NUMERO_KM} ${sucursal.DIRECCION.RESTO_DIRECCION?`,${sucursal.DIRECCION.RESTO_DIRECCION}`:""}(${sucursal.DIRECCION.CP?sucursal.DIRECCION.CP:""} ${sucursal.DIRECCION.LOCALIDAD.DESCRIPCION} - ${sucursal.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
            })

            this.setState({sucursales:copySucursales})
        })

    }


    cargarMediosContacto=async(empresa)=>{

        let personasMedios=
        await this.props.client
        .query({
            query: gql`
            {
            personasMediosContacto(FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}},ORDEN:"PREFERIDO,DESC"){ID_PERSONA,ID_MEDIO_CONTACTO,PREFERIDO}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.personasMediosContacto
        })
        let copyContacto=[]
        for(let pm of personasMedios){

            //Mostramos los conctactos de la empresa pasada props   
            await this.props.client
            .query({
                query: gql`
                {
                    mediosContacto(FILTROS:{ID_MEDIO_CONTACTO:${pm.ID_MEDIO_CONTACTO}}){ID_MEDIO_CONTACTO,ID_TIPO_MEDIO_CONTACTO,TIPO_CONTACTO{DESCRIPCION},VALOR}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{
                let contactos=result.data.mediosContacto;
                contactos.forEach((contacto,index)=>{
                    if(pm.PREFERIDO===false){
                        copyContacto.push({"tipo":contacto.TIPO_CONTACTO.DESCRIPCION,"valor":contacto.VALOR,"preferido":pm.PREFERIDO})
                    }else{
                        copyContacto.splice(0,0,{"tipo":contacto.TIPO_CONTACTO.DESCRIPCION,"valor":contacto.VALOR,"preferido":pm.PREFERIDO})
                    }
                })
            })   
        }

        this.setState({medios:copyContacto});

    }

    cargarPatentes=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
              personasJuridicasPatentes(ORDEN:"ID_PATENTE,ASC",FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_PATENTE,DESCRIPCION,VALIDEZ}
            }
            `,
            fetchPolicy: 'network-only'
    
        }).then(result=>{ 
        
            let patentes=[]
            for(let patente of result.data.personasJuridicasPatentes){
              patentes.push({"id":patente.ID_PATENTE,"descripcion":patente.DESCRIPCION,"validez":patente.VALIDEZ})
            }
    
            this.setState({
              patentes:patentes,
            })
        
        })
        
    }

    cargarInformes=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:8}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({informes:copyAdjuntos})

        })
    }

    cargarPlanesIgualdad=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:9}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({planesIgualdad:copyAdjuntos})

        })
    }

    cargarDominios=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
              personasJuridicasDominios(ORDEN:"ID_DOMINIO,ASC",FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_DOMINIO,NOMBRE,HOSTING,VALIDEZ}
            }
            `,
            fetchPolicy: 'network-only'
    
        }).then(result=>{ 
        
            let dominios=[]
            for(let dominio of result.data.personasJuridicasDominios){
              dominios.push({"id":dominio.ID_DOMINIO,"nombre":dominio.NOMBRE,"hosting":dominio.HOSTING,"validez":dominio.VALIDEZ})
            }
    
            this.setState({
              dominios:dominios,
            })
        
        })
        
    }

    cargarPropiedades=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
              personasJuridicasPropiedades(ORDEN:"ID_PROPIEDAD,ASC",FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_PROPIEDAD,SOFTWARE,OTRO,VALIDEZ}
            }
            `,
            fetchPolicy: 'network-only'
    
        }).then(result=>{ 
        
            let propiedades=[]
            for(let propiedad of result.data.personasJuridicasPropiedades){
              propiedades.push({"id":propiedad.ID_PROPIEDAD,"software":propiedad.SOFTWARE,"otro":propiedad.OTRO,"validez":propiedad.VALIDEZ})
            }
    
            this.setState({
              propiedades:propiedades,
            })
        
        })
        
    }

    cargarNombresComerciales=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
              personasJuridicasComerciales(ORDEN:"ID_COMERCIAL,ASC",FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_COMERCIAL,NOMBRE,NRO_REGISTRO,NACIONAL_INTERNACIONAL,CLASIFICACION_NIZA,DESCRIPCION_CLASE,VALIDEZ}
            }
            `,
            fetchPolicy: 'network-only'
    
        }).then(result=>{ 
        
            let nombres=[]
            for(let nombre of result.data.personasJuridicasComerciales){
              nombres.push({"id":nombre.ID_COMERCIAL,"nombre":nombre.NOMBRE,"nro_registro":nombre.NRO_REGISTRO,"nacional_internacional":nombre.NACIONAL_INTERNACIONAL,"clasificacion_niza":nombre.CLASIFICACION_NIZA,"descripcion_clase":nombre.DESCRIPCION_CLASE,"validez":nombre.VALIDEZ})
            }
    
            this.setState({
              nombres:nombres,
            })
        
        })
        
    }

    cargarCuentas=async()=>{

        //OBTENCION DE LAS CCC DE LA EMPRESA.
        await this.props.client
        .query({
            query: gql`
            {
                personasJuridicasCcc(ORDEN:"ID_CCC,ASC",FILTROS:{ID_PERSONA:${this.state.personasJuridicas.ID_PERSONA}}){ID_CCC,CCC,PRINCIPAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
        
            let cuentas=[]
            for(let ccc of result.data.personasJuridicasCcc){
                if(ccc.PRINCIPAL===false){
                    cuentas.push({"nombre":ccc.CCC})
                }else{
                    cuentas.splice(0,0,{"nombre":'Principal:'+ccc.CCC})
                }
            }

            this.setState({ccc:cuentas})
        
        })
        
    }

    cargarDatosEmpresa=()=>{
        this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,FILIALES,FUSIONES,ESCISIONES,WEB_CORPORATIVA,WEB_CANAL_DENUNCIAS,CONVENIOS_COLECTIVOS}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            let finicio=null;
            let ffin=null;
            if(result.data.personasJuridicas[0].INICIO_EJERCICIO!==null && result.data.personasJuridicas[0].FIN_EJERCICIO!==null){
                finicio=new Date(result.data.personasJuridicas[0].INICIO_EJERCICIO);
                finicio.setHours(0,0,0,0);
                ffin=new Date(result.data.personasJuridicas[0].FIN_EJERCICIO);
                ffin.setHours(0,0,0,0);
            }
            let conveniosColectivos=null;
            if(result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS){
                conveniosColectivos=result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS.split("|");
                conveniosColectivos.forEach((convenio,index)=>{
                    conveniosColectivos[index]={"id":index,"nombre":convenio}
                })
            }
            this.setState({
                fechaInicio:finicio,
                fechaFin:ffin,
                personasJuridicas:result.data.personasJuridicas[0],
                convenios:conveniosColectivos,
                fusiones:result.data.personasJuridicas[0].FUSIONES,
                escisiones:result.data.personasJuridicas[0].ESCISIONES,
                filiales:result.data.personasJuridicas[0].FILIALES
            },async()=>{
                await this.cargarCIF();
                await this.cargarCuentas();
                await this.cargarMarcas();
                await this.cargarNombresComerciales();
                await this.cargarPatentes();
                await this.cargarPropiedades();
                await this.cargarDominios();
                await this.cargarMediosContacto();
                await this.cargarCentros();
                await this.cargarSucursales();
                await this.cargarMemorias();
                await this.cargarPlanesMovilidad();
                await this.cargarInformes();
                await this.cargarPlanesIgualdad();
                await this.cargarSociedades();
                await this.cargarAdjuntos();
                await this.cargarActividades();
                await this.cargarInstalaciones();
                await this.cargarSeguros();
                await this.cargarGrupos();
            })
        })
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(async (result)=>{ 
            if(result.data.adjuntos.length>0){
                
                this.setState({
                    logotipo:process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".","")
                });
            
            }
        })
    
    }

    render() {
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("informeempresa.title",{ns:"page"});
        
        return (
            <>
            {this.state.cargando?
                <Cargando />
                :
                <> 
                    <div className="tablaCien">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Tooltip content={i18next.t("print",{ns:"global"})} position="top">
                                            <ReactToPrint
                                                trigger={() => <Button className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})}/>}
                                                content={() => this.report}
                                            />
                                        
                                        </Tooltip>
                                    
                                    </ButtonGroup>
                                </Navbar.Group>

                            </Navbar>
                            <Card style={{marginTop:'5px'}}>
                            <div ref={(input) => { this.report= input; }} style={{marginTop:'10px',marginRight:'10px'}}>
                                <div style={styles.body}>
                                    <div>
                                        <img src={this.state.logotipo?this.state.logotipo:pantallaInicio} style={styles.imageHead} alt="logo" />
                                    </div>
                                    <br></br>
                                    <div style={{textAlign:'center'}}>
                                        <span style={{fontSize:'12px'}}><b>{i18next.t("informeempresa.title",{ns:"page"}).toUpperCase()}</b></span>
                                    </div>
                                    <br></br>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.razonsocial",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.personasJuridicas?this.state.personasJuridicas.RAZON_SOCIAL:''}</span>
                                                </td>
                                                <td>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.grupoempresarial",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.grupo?this.state.grupo:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.nombrecomercial",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.personasJuridicas?this.state.personasJuridicas.NOMBRE_COMERCIAL:''}</span>
                                                </td>
                                                <td>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.sitiowebcorporativo",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.personasJuridicas.WEB_CORPORATIVA?this.state.personasJuridicas.WEB_CORPORATIVA:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.CIF",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.cif?this.state.cif:''}</span>
                                                </td>
                                                <td>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.sitiowebcanaldenuncias",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.personasJuridicas.WEB_CANAL_DENUNCIAS?this.state.personasJuridicas.WEB_CANAL_DENUNCIAS:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" style={{width: '100%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.ejercicioempresaentrefechas",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.fechaInicio?moment(this.state.fechaInicio).format('DD/MM/YYYY'):''} - {this.state.fechaFin?moment(this.state.fechaFin).format('DD/MM/YYYY'):''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.convenioscolectivos",{ns:'company'}).toUpperCase()}:</b></span>
                                                    <table style={{width: '70%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabinformacion.conveniosaplicables",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                            </tr>
                                                            {this.state.convenios.map(( val, index ) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabinformacion.codigoscuentacotizacion",{ns:'company'}).toUpperCase()}:</b></span>
                                                    <table style={{width: '70%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabinformacion.codigoscuentacotizacion",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                            </tr>
                                                            {this.state.ccc.map(( val, index ) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabmarcasdominios.marcas",{ns:'company'}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.brand",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.registrationno",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.marcas.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nro_registro}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nacional_internacional}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.clasificacion_niza}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.descripcion_clase}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.validez}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabmarcasdominios.nombrescomerciales",{ns:'company'}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.tradename",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.registrationno",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.nombresCom.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nro_registro}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nacional_internacional}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.clasificacion_niza}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.descripcion_clase}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.validez}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabmarcasdominios.patentesinvenciones",{ns:'company'}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.description",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.patentes.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.descripcion}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.validez}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabmarcasdominios.propiedadintelectual",{ns:'company'}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.software",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.other",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.propiedades.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.software}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.otro}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.validez}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabmarcasdominios.dominiosinternet",{ns:'company'}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.domain",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.hosting",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.dominios.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.hosting}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.validez}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.medioscontacto",{ns:"company"}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmedioscontacto.type",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabmedioscontacto.value",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.medios.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.preferido?<strong>Preferido: </strong>:''}{val.tipo}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.valor}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabcentrostrabajo.centrostrabajo",{ns:"company"}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabcentrostrabajo.name",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabcentrostrabajo.address",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.centros.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.domicilioSocial?<strong>Principal: </strong>:''}{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.direccion}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabcentrostrabajo.sucursales",{ns:"company"}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabcentrostrabajo.name",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabcentrostrabajo.address",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.sucursales.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.direccion}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: '50%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.title",{ns:"company"}).toUpperCase()}:</b></span>
                                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.name",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.type",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.mime",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                            </tr>
                                                            {this.state.memorias.map(( val, index ) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.tipo}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.mime}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{width: '50%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabdocumentos.informes.title",{ns:"company"}).toUpperCase()}:</b></span>
                                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.name",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.type",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.mime",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                            </tr>
                                                            {this.state.informes.map(( val, index ) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.tipo}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.mime}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%',verticalAlign:'sub'}}>
                                                <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabdocumentos.planesmovilidad.title",{ns:"company"}).toUpperCase()}:</b></span>
                                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.name",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.type",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.mime",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                            </tr>
                                                            {this.state.planesMovilidad.map(( val, index ) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.tipo}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.mime}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td style={{width: '50%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabdocumentos.planesigualdad.title",{ns:"company"}).toUpperCase()}:</b></span>
                                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.name",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.type",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.mime",{ns:'company'}).toUpperCase()}</b></span>
                                                                </td>
                                                            </tr>
                                                            {this.state.planesIgualdad.map(( val, index ) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.tipo}</span>
                                                                            </td>
                                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                                <span style={{fontSize:'9px'}}>{val.mime}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabvinculos.title",{ns:"company"}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabvinculos.colname",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabvinculos.colparticipacion",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.sociedades.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.Participacion}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: '33%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabvinculos.fusiones",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.fusiones?this.state.fusiones:''}</span>
                                                </td>
                                                <td style={{width: '33%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabvinculos.escisiones",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.escisiones?this.state.escisiones:''}</span>
                                                </td>
                                                <td style={{width: '33%',verticalAlign:'sub'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.tabvinculos.filiales",{ns:'company'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.filiales?this.state.filiales:''}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("empresa.adjuntos",{ns:"company"}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.name",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.type",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("empresa.tabdocumentos.memorias.mime",{ns:'company'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.adjuntos.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.nombre}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.tipo}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.mime}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <div style={{textAlign:'center'}}>
                                        <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.title",{ns:"page"}).toUpperCase()}</b></span>
                                    </div>
                                    <br></br>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.objetosocial",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.objeto?this.state.objeto:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.productos",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.prodcutos?this.state.prodcutos:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.servicios",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.servicios?this.state.servicios:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.canales",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.distribucion?this.state.distribucion:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.zonas",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.zonas?this.state.zonas:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.actividadesintrínseco",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.actividades?this.state.actividades:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.actividadesentorno",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.activiadesEntorno?this.state.activiadesEntorno:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.actividadesimpactomedioambiental",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.actividadesMedio?this.state.actividadesMedio:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td  style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("actividades.actividadesriesgomedioambiental",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.actividadesMedioRiesgo?this.state.actividadesMedioRiesgo:''}</span>
                                                </td>
                                                <td  style={{width: '50%'}}>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <div style={{textAlign:'center'}}>
                                        <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.title",{ns:"page"}).toUpperCase()}</b></span>
                                    </div>
                                    <br></br>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.instalacionesfijas",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.fijas?this.state.fijas:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.instalacionesmoviles",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.moviles?this.state.moviles:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.instalacionesespeciales",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.especiales?this.state.especiales:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.elementostransporte",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.transporte?this.state.transporte:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.maquinaria",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.maquinaria?this.state.maquinaria:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.equiposinformaticos",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.equipos?this.state.equipos:''}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>
                                                    <span style={{fontSize:'10px'}}><b>{i18next.t("instalaciones.software",{ns:'page'}).toUpperCase()}: </b></span>
                                                    <span style={{fontSize:'10px'}}>{this.state.software?this.state.software:''}</span>
                                                </td>
                                                <td style={{width: '50%'}}>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <span style={{fontSize:'10px'}}><b>{i18next.t("programaseguros.title",{ns:"page"}).toUpperCase()}:</b></span>
                                    <table style={{width: '100%'}} className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                                        <tbody>
                                            <tr>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("programaseguros.tiposeguro",{ns:'page'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("programaseguros.aseguradora",{ns:'page'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("programaseguros.numeropoliza",{ns:'page'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("programaseguros.fechadesde",{ns:'page'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("programaseguros.fechahasta",{ns:'page'}).toUpperCase()}</b></span>
                                                </td>
                                                <td style={{padding: '0 0 0 0',textAlign: 'center'}}>
                                                    <span style={{fontSize:'9px'}}><b>{i18next.t("programaseguros.notas",{ns:'page'}).toUpperCase()}</b></span>
                                                </td>
                                            </tr>
                                            {this.state.seguros.map(( val, index ) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.tipoSeguro}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.aseguradora}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{val.numeroPoliza}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{ moment(val.fechaDesde).format("DD/MM/YYYY")}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}>{moment(val.fechaHasta).format("DD/MM/YYYY")}</span>
                                                            </td>
                                                            <td style={{padding: '0px 0px 0px 5px'}}>
                                                                <span style={{fontSize:'9px'}}><div dangerouslySetInnerHTML={{__html: val.notas}}/></span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </Card>
                        </Card>
                    </div>
                </>
            }
            </>
        )
    }
}

export default withTranslation()(withApollo(InformeEmpresa));