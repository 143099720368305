import React from "react"
import { Card, Elevation } from "@blueprintjs/core"
import GobiernoCImagen from "../../Assets/Gobierno Corporativo-1.png"

export default class GobiernoCorporativo extends React.Component {
    render(){

        document.title ="LexControl | Gobierno corporativo"

        return (
            <>
                <Card interactive={true} elevation={Elevation.TWO} className="bp3-running-text">
                    <img src={GobiernoCImagen} className="imagenCentrada" alt="Compliance"/>
                </Card>
            </>
        )
    }
}