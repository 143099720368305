import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import {initReactI18next} from 'react-i18next';
import i18next from 'i18next';
import global_es from './traslations/es/global.json';
import global_en from './traslations/en/global.json';
import company_es from './traslations/es/company.json';
import company_en from './traslations/en/company.json';
import menu_es from './traslations/es/menu.json';
import menu_en from './traslations/en/menu.json';
import main_es from './traslations/es/main.json';
import main_en from './traslations/en/main.json';
import page_es from './traslations/es/page.json';
import page_en from './traslations/en/page.json';
import page2_en from './traslations/en/page2.json';
import page2_es from './traslations/es/page2.json';

i18next.use(initReactI18next).init({
        fallbackLng: 'es',
        lng: "es",
        resources: {
                es: {
                        global: global_es,
                        company: company_es,
                        menu:   menu_es,
                        main: main_es,
                        page: page_es,
                        page2 : page2_es
                },
                en: {
                        global:  global_en,
                        company: company_en,
                        menu: menu_en,
                        main: main_en,
                        page: page_en,
                        page2 : page2_en
                }
        },
        ns: ['global','company','menu','main','page', 'page2'],
        defaultNS: 'global'
});
i18next.languages = ['es','en'];
const ApolloApp = AppComponent => (
        <BrowserRouter>
                <App />
        </BrowserRouter>
);

render(ApolloApp(App), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
