import React, { Component } from "react";
import {
  Card,
  Navbar,
  ButtonGroup,
  Alignment,
  Popover,
  Button,
  NavbarDivider,
  Dialog,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  Elevation,
  Spinner
} from "@blueprintjs/core";
import { Table, Column, Cell,RegionCardinality,Regions } from "@blueprintjs/table";
import InformacionGeneralNuevo from "../Pantallas/Elementos/InformacionGeneralNuevo";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';

class OtrasPartesInteresadas extends Component {
  state = {
	modalNuevoAbierto: false,
	editarActivado: false,
	borrarActivado: false,
	otrasPersonas: [],
	personaSeleccionada:null,
	cargando:false,
	regionSeleccionada:null,
	cargandoForm:false,
	columnWidths: [100],
	resized: false,
	datosEmpresa:null,
	cargandoImprimirG:false,
	modalImprimirG: false,
	logotipo:null,
	personasFisicas:null,
	personasJuridicas:null,
  };


  cargarPersonas = async () => {
	let personas = [];
	this.setState({cargando:true})
	//console.log(this.state.personasFisicas);
	//console.log(this.state.personasJuridicas);
	for(let persona of this.state.personasFisicas){
		personas.push({"value":persona.ID_PERSONA,"nombre":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

	}

	for(let persona of this.state.personasJuridicas){
		personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"nombre":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
	}

	this.setState({
	  otrasPersonas: personas,
	  cargando:false
	});
  };

  obtenerLogoEmpresa=async()=>{
	//obtencion logo de la empresa
	 await this.props.client
	 .query({
		 query: gql`
			 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
		 `,fetchPolicy:'network-only'             
	 }).then(result=>{ 
		 if(result.data.adjuntos.length>0){

			 var request = require('request').defaults({ encoding: null,withCredentials:true });

			 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
				 if (!error && response.statusCode === 200) {
				 let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
				 this.setState({logotipo:data})
				 }
			 });

		 }
	 })
 
 }

 resizeColumns() {
	
	let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
	let totalMinSizeReq = 0;
	let cumulativeColumnWidths= [];
	for (let index = 0; index < this.tablaPersonas.locator.grid.numCols; index++) {
		totalMinSizeReq = totalMinSizeReq + this.tablaPersonas.locator.getWidestVisibleCellInColumn(index);
		cumulativeColumnWidths.push(this.tablaPersonas.locator.getWidestVisibleCellInColumn(index));
	}
	if (totalMinSizeReq > containerSize) {
		this.setState({
			columnWidths: cumulativeColumnWidths,
			resized: true
		});
	} else {
		let diff = ((containerSize - totalMinSizeReq) / this.tablaPersonas.locator.grid.numCols);
		this.setState({
			columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
			resized: true
		});
	}
	
}

generarImprimirG= async() => {
	this.setState({
		cargandoImprimirG:true,
		modalImprimirG:true
	}, async () => {
		 this.setState({cargandoImprimirG:false}, () => {
			 ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
		 })
	})
}


ImprimirG=()=>(
	<PDFViewer style={{width: '100%', height: '100%'}}>
	<Document>
	  <Page size="A4" style={styles.page}>
		<View style={styles.body}>
			<View fixed style={styles.row}>
			  <Image
				style={styles.imageHeadL}
				src={this.state.logotipo?this.state.logotipo:pantallaInicio}
				/>
			</View>
			<View fixed style={styles.rowHead}>
				<Text style={styles.labelHead}>EMPRESA:</Text>
				<Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
			</View>
			<View fixed style={styles.rowHeadTwo}>
				<Text style={styles.labelHead}>INFORME:</Text>
				<Text style={styles.text}>OTRAS PARTES INTERESADAS</Text>
			</View>
			<View style={styles.rowTitleOne}>
				<Text style={styles.labelTitle}>LISTA DE OTRAS PARTES INTERESADAS</Text>
			</View>
				{this.state.otrasPersonas?this.state.otrasPersonas.map((cliente, index)=>{
					return(
						<View key={index}>
						   {index ===0 && (
							<View style={styles.colHead}>
							   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>NOMBRE</Text>
							</View>
						   )}
							<View style={styles.colHead}>
								<Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{cliente.nombre?cliente.nombre:''}</Text>
							</View>                               
						
					</View>
					)
				}):<></>}      
		</View>

		<Text
			style={styles.footerPage}
			render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
			fixed
		  />
		<Image
				style={styles.icono}
				src={iconoFooter}
				fixed
				/>
		<Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
	  </Page>
	</Document>
  </PDFViewer>
)

cargarDatos=async()=> {
	let datosEmpresa= await this.cargarEmpresa()
	let personasFisicas=await this.cargarPersonasFisicas()
	let personasJuridicas=await this.cargarPersonasJuridicas()
	
	this.setState({
		datosEmpresa:datosEmpresa,
		personasFisicas: personasFisicas,
		personasJuridicas: personasJuridicas,
	})

}

cargarEmpresa= async() => {
         
	//DATOS JURIDICOS
	let datosEmpresa= await this.props.client
	.query({
		query: gql`
		{
			personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
		}
		`,
		fetchPolicy: 'network-only'

	}).then(result=>{
		//si tenemos razon social.
		if(result.data.personasJuridicas.length>0){
			return {
				"razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
			}

		}
		
	})

   return datosEmpresa

}

cargarPersonasFisicas= async () => {
	let personasFisicas=this.props.client
	.query({
		query: gql` 
		{
			personasFisicasOtras {
			  ID_PERSONA
			  NOMBRE
			  APELLIDO1
			  APELLIDO2
			  PERSONA {
				TIPO_PERSONA{ID_TIPO_PERSONA}
			  }
			}
		  }
		`,
		fetchPolicy: 'network-only'

	}).then(result=>{ 
	 return result.data.personasFisicasOtras;
	})
	
	return personasFisicas;
   
}

cargarPersonasJuridicas= async () => {
	let personasJuridicas=this.props.client
	.query({
		query: gql`
		{
			personasJuridicasOtras {
			  ID_PERSONA
			  RAZON_SOCIAL
			  EMPRESA_PRINCIPAL
			  PERSONA {
				TIPO_PERSONA{ID_TIPO_PERSONA}
			  }
			}
		  }
		`,
		fetchPolicy: 'network-only'

   }).then(result=>{
	 return result.data.personasJuridicasOtras;
	})
	
	return personasJuridicas;
	
}

  componentDidMount = props => {
	this.setState({
		cargandoForm: true
	},async () => {
		await this.obtenerLogoEmpresa()
		await this.cargarDatos()
		await this.cargarPersonas();
		this.setState({cargandoForm: false})
	}); 
  };

	cellRendererNombre = rowIndex => {
		return (
		  <Cell wrapText="false" loading={this.state.cargando} truncated="false">{`${
			this.state.otrasPersonas[rowIndex].nombre
		  }`}</Cell>
		);
	};

	

  render() {

	document.title ="LexControl | Otras partes interesadas"

	const cellRendererNombre = rowIndex => {
	  return (
		<Cell wrapText="false" loading={this.state.cargando} truncated="false">{`${
		  this.state.otrasPersonas[rowIndex].nombre
		}`}</Cell>
	  );
	};

	const Botonera = () => {
	  return (
		<>
		  <Button
			className="bp3-minimal"
			icon="new-link"
			text="Nueva Persona Física"
			fill={true}
			onClick={abrirModalNuevaPersona}
		  />
		  <br />
		  <Button
			className="bp3-minimal"
			icon="git-new-branch"
			text="Nueva Persona Jurídica"
			onClick={abrirModalNuevaPersonaJurídica}
		  />
		</>
	  );
	};

	const abrirModalNuevaPersona = () => {

	  //abrir modal de persona física
	  this.setState({
		  personaSeleccionada:null,
		  modalPersonaAbierto: true,
		  popoverAbierto: false,
		  nuevaPresonaActivado: true,
		  editarPersonaActivado: false
	  });
	};

	const abrirModalNuevaPersonaJurídica = () => {
	  //abrir modal de persona jurídica
	  this.setState({
		modalNuevaJuridicaAbierto: true,
		popoverAbierto: false,
		nuevaPresonaActivado: true,
		editarPersonaActivado: false
	  });

	};

	const crearPersona = async () => {
	  await this.datosPersona.crearPersona();
	  if (this.datosPersona.state.idPersonaCreada) {
		//cerrar el modal
		await this.cargarPersonas();

		//reseleccionamos la que teniamos seleccionada
		let sel=this.state.otrasPersonas.find((persona,index)=>{
			if(persona.ID_PERSONA===this.datosPersona.state.idPersonaCreada){
				this.setState({regionSeleccionada:[Regions.row(index)]})
			}
			return persona.ID_PERSONA===this.datosPersona.state.idPersonaCreada

		})
		this.setState({
			personaSeleccionada:sel
		})
		cerrarModal();

	  }
	};

	const actualizarPersona = async () => {
	  await this.datosPersona.actualizarPersona();
	  if (this.datosPersona.state.idPersonaActualizada) {
		//cerrar el modal

		await this.cargarPersonas();

		//reseleccionamos la que teniamos seleccionada
		let sel=this.state.otrasPersonas.find((persona,index)=>{
			if(persona.ID_PERSONA===this.datosPersona.state.idPersonaActualizada){
				this.setState({regionSeleccionada:[Regions.row(index)]})
			}
			return persona.ID_PERSONA===this.datosPersona.state.idPersonaActualizada

		})
		this.setState({
			personaSeleccionada:sel
		})

		cerrarModal();
	  }
	};

	const abrirModalEditarPersona = () => {

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert("No tienes permisos para realizar esta acción")
		return
	  }

	 if (!this.state.personaSeleccionada) {
		alert("Debe seleccionar una persona.");
		return;
	  }

	  this.setState({ nuevaPresonaActivado: false });
	  this.setState({ editarPersonaActivado: true });


	  //si es personas física
	  if (this.state.personaSeleccionada.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA === 1) {
		this.setState({ modalPersonaAbierto: true });
		//si es persona jurídica
	  } else if (this.state.personaSeleccionada.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA === 2) {
		this.setState({ modalEditarPersonaJuridica: true });
	  }
	};

	const cerrarModal = () => {
	  this.setState({
		modalEditarPersonaJuridica: false,
		modalNuevaJuridicaAbierto: false,
		modalPersonaAbierto: false
	   });
	};

	const guardarPersonaJuridica = async () => {

	   
 
	  

	  let result=await this.informacion.handleGuardarDatos() 
	  if(!result)return;
	  await this.cargarPersonas();

	  //reseleccionamos la que teniamos seleccionada
	  let sel=this.state.otrasPersonas.find((persona,index)=>{
		if(persona.ID_PERSONA===this.informacion.state.personaCreada){
			this.setState({regionSeleccionada:[Regions.row(index)]})
		}
		return persona.ID_PERSONA===this.informacion.state.personaCreada

	  })
	  this.setState({
		  personaSeleccionada:sel
	  })

	  await this.setState({ modalNuevaJuridicaAbierto: false });

	};

	const abrirPopover = () => {
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert("No tienes permisos para realizar esta acción")
		return
	  }else{
		this.setState({ popoverAbierto: true });
	  }
	};

	const seleccionarPersona=(value)=>{
		if(value.length===0)return
		let seleccionada=this.state.otrasPersonas[value[0].rows[0]]
		this.setState({personaSeleccionada:seleccionada})
	}


  const cerrarModal2 = async () => {
	this.setState({
		modalEditarPersonaJuridica:false,
	},()=>{
	   this.cargarPersonas()
	})
  }

  const cerrarModalImprimir= () => {
	this.setState({
		modalImprimirG:false
	})
}
	return (
	  <>
		  {
			  this.state.cargandoForm === true?
			  <Cargando />
			  :
			  <>
			  	<Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Otras partes interesadas"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal}
				title="Propiedades de la empresa"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.modalNuevaJuridicaAbierto}
				className="datos-empresa-dialog"
				>
				<InformacionGeneralNuevo
					ref={input => {
					this.informacion = input;
					}}
				/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content="Salir." position="top">
						<Button
						alignText="left"
						icon="log-out"
						text="Salir"
						intent="danger"
						onClick={cerrarModal}
						className="iconoIzquierda bp3-minimal"
						/>
					</Tooltip>
					<Tooltip content="Guardar." position="top">
						<Button
						alignText="left"
						icon="floppy-disk"
						text="Guardar"
						intent="danger"
						onClick={guardarPersonaJuridica}
						className="iconoIzquierda bp3-minimal"
						/>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal}
				title="Propiedades de la persona"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				className="dialog-ancho"
				isOpen={this.state.modalPersonaAbierto}
				>
				<DatosPersonasFisicas
					datosPersona={
					this.state.personaSeleccionada
						? this.state.personaSeleccionada.ID_PERSONA
						: null
					}
					ref={input => {
					this.datosPersona = input;
					}}
					i18next={i18next}
				/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content="Cerrar y volver." position="top">
						<Button onClick={cerrarModal}>Cerrar</Button>
					</Tooltip>
					<Tooltip
						content={
						this.state.nuevaPresonaActivado
							? "Añadir persona."
							: "Actualizar persona."
						}
						position="top"
					>
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={
							this.state.nuevaPresonaActivado
							? crearPersona
							: actualizarPersona
						}
						>
						{this.state.nuevaPresonaActivado
							? "Añadir persona"
							: "Actualizar Persona"}
						</AnchorButton>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal2}
				title="Propiedades de la empresa"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.modalEditarPersonaJuridica}
				className="datos-empresa-dialog"
				>
				<DatosPersonasJuridicas
					animacion={this.animacion}
					cambiarPestaña={this.cambiarPestaña}
					pestaña={this.state.pestaña}
					empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
				/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS} />
					<ButtonGroup>
						<Button
							alignText="right"
							icon="log-out"
							intent="danger"
							text="salir"
							onClick={cerrarModal2}
						/>
					</ButtonGroup>
				</div>
				</Dialog>
				<div className="tablaSesenta pixelAlrededorGrande">
				<Card>
				<h4>Otras partes interesadas</h4>
				<Navbar>
					<Navbar.Group>
					<ButtonGroup align={Alignment.LEFT}>
						{!this.props.editable?

							<Button
							disabled={this.state.editando}
							className="bp3-minimal"
							icon="add"
							text="Nuevo"
							active={this.state.nuevaPresonaActivado}
							onClick={abrirPopover}
							/>
											:
							<Popover
							disabled={this.state.editando}
							content={<Botonera />}
							transitionDuration={400}
							target={
							<Button
								disabled={this.state.editando}
								className="bp3-minimal"
								icon="add"
								text="Nuevo"
								active={this.state.nuevaPresonaActivado}
								onClick={abrirPopover}
							/>
							}
						/>
						}

						<Button
						className="bp3-minimal"
						icon="edit"
						text="Editar"
						onClick={abrirModalEditarPersona}
						active={this.state.editarActivado}
						/>
						<NavbarDivider />
						<Tooltip 
							content="Desea imprimir todas las otras partes interesadas" 
							position="right" 
						>
							<Button 
								className="bp3-minimal" 
								icon="print" 
								text="Imprimir" 
								onClick={()=>this.generarImprimirG()}
							/>
						</Tooltip>
						
					</ButtonGroup>
					</Navbar.Group>
				</Navbar>
				<Table
					ref={(input) => { this.tablaPersonas= input; }}
					numRows={this.state.otrasPersonas.length}
					onSelection={seleccionarPersona}
					selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}
					selectedRegions={this.state.regionSeleccionada}
					enableMultipleSelection={false}
					enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
						if (!this.state.resized) {
							this.resizeColumns();
						}
					}}>
					<Column name="Nombre" cellRenderer={cellRendererNombre} />
				</Table>
				<br />
				</Card>
				</div>
				<div className="pixelAlrededorGrande tablaCuarenta">
					<Card interactive={true} elevation={Elevation.TWO}>
					<h4>EL RIESGO REPUTACIONAL</h4>
					<p>La empresa debe estar en condiciones de poder acreditar conocimiento de la identidad de cualquier persona física o jurídica con la que interactúe, sea cual fuere el vínculo que ha generado el contacto. Un ordenado registro permitirá identificar riesgos que en principio pueden no afectarnos, pero a veces la simple asociación involuntaria a determinada persona o entidad, puede acarrear vínculos que, aunque más aparentes que reales, pudieran ocasionar daños reputacionales.</p>
					</Card>
				</div>
			</>
		  }
	  </>
	);
  }
}

export default withApollo(OtrasPartesInteresadas);
