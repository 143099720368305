/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Spinner,Navbar, FormGroup, Button, Callout, Alignment, ButtonGroup, Dialog, InputGroup, Classes, Tooltip, AnchorButton, Intent, Card, Elevation, Switch } from "@blueprintjs/core"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import moment from "moment"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

import styles from './stylesPrint';


class ObligacionesEmitidas extends Component {

    state = {
        obligaciones:[
        ],
        series:null,
        numOblig:1,
        importeNom:0.00,
        importeNomValido:false,
        convertibles:false,
        nuevoActivado:false,
        editarActivado:false,
        borrarActivado:false,
        obligacionSeleccionada:null,
        importeTotalObligaciones:0,
        cargando: false,
        cargandoImprimir:false,
        modalImprimir:false,
        imprimirActivado:false,
        cargandoGrid:false
    }
    
    cargarObligaciones=async()=>{

        //reiniciamos el valor de las obliaciones
        this.setState({importeTotalObligaciones:0})

        let obligaciones=await this.props.client
        .query({
            query: gql`
                {obligacionesEmitidas{
                    ID_OBLIGACION,
                    NUMERO,
                    SERIES,
                    IMPORTE_NOMINAL,
                    CONVERTIBLES
                }}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obligacionesEmitidas
        })
        let totalObligaciones=0
        let i=0
        //calculamos el importe total de las obligaciones.
        obligaciones.forEach(obligacion=>{
            totalObligaciones+=(obligacion.NUMERO*obligacion.IMPORTE_NOMINAL)
            i++
            if(i===obligaciones.length){
                this.setState({importeTotalObligaciones:parseFloat(totalObligaciones).toFixed(2)})
            }
        })

        this.setState({obligaciones:obligaciones})

    }

   

    componentDidMount(props){
        this.setState({ cargando: true }, async() =>{
            await this.cargarObligaciones()
            this.setState({cargando:false})
        })
    }
    generarImprimirOE=async()=>{
        this.setState({
            cargandoImprimir:true,
            obligacionSeleccionada:null,
            cargandoGrid:true,
        modalImprimir:true}, async () =>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirOE(), document.getElementById('generarImprimir-pdf'))
            })
        })
    }
    ImprimirOE=() =>(
    <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("obligacionesemitidas.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.obligaciones?this.state.obligaciones.map((obligacion, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{this.props.i18next.t("obligacionesemitidas.series",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{this.props.i18next.t("obligacionesemitidas.nroobligaciones",{ns:"page"}).toUpperCase()}</Text>                                                                
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{this.props.i18next.t("obligacionesemitidas.importenominal",{ns:"page"}).toUpperCase()}</Text>                                                                
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{this.props.i18next.t("obligacionesemitidas.importetotal",{ns:"page"}).toUpperCase()}</Text>                                                                
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{this.props.i18next.t("obligacionesemitidas.convertibles",{ns:"page"}).toUpperCase()}</Text>                                                             
                                    </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.SERIES?obligacion.SERIES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.NUMERO?obligacion.NUMERO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.IMPORTE_NOMINAL?parseFloat(obligacion.IMPORTE_NOMINAL).toFixed(2):''}€</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.IMPORTE_NOMINAL?parseFloat(obligacion.IMPORTE_NOMINAL * obligacion.NUMERO).toFixed(2):''}€</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.CONVERTIBLES?"SI":"NO"}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}
                    <View style={styles.rowTitleOne}>
                    	<Text style={styles.labelTitle}>{this.props.i18next.t("obligacionesemitidas.titleimporte",{ns:"page"}).toUpperCase()}:</Text>
            		</View>
                    <View style={styles.rowTitleOne}>
                        <Text style={styles.labelData}>{this.state.importeTotalObligaciones}€</Text>
                    </View>    
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    

    render() {

        document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("obligacionesemitidas.title",{ns:"page"})

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalAbierto: true,
                series:null,
                numOblig:1,
                importeNom:0.00,
                convertibles:false,
                obligacionSeleccionada: null,
                cargandoGrid:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.obligacionSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalAbierto: true,
                series:this.state.obligacionSeleccionada.SERIES,
                numOblig:this.state.obligacionSeleccionada.NUMERO,
                importeNom:Intl.NumberFormat('es-ES').format(this.state.obligacionSeleccionada.IMPORTE_NOMINAL),
                convertibles:this.state.obligacionSeleccionada.CONVERTIBLES,
                importeNomValido:true,
                cargandoGrid:true
            })

        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.obligacionSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
                cargandoGrid:true
            })

        }

        const añadirNuevo = (value) => {

            if(!this.state.series)return
            if(!this.state.importeNom || !this.state.importeNomValido)return
            if(this.state.numOblig<1)return

            //comprobacion de que no exista una obligacion con el nombre dado.
            let existe=this.state.obligaciones.find(obligacion=>{return obligacion.SERIES===this.state.series})
            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }
            //mutation para añadir la obligacion emitida.
            this.props.client.mutate({
                mutation:gql`mutation{crearObligacionEmitida(
                    VALORES:{
                        NUMERO:${this.state.numOblig},
                        SERIES:"${this.state.series}",
                        IMPORTE_NOMINAL:${this.state.importeNom.replace(/\./g, '').replace(/\,/g, '.')},
                        CONVERTIBLES:${this.state.convertibles},
                    }){ID_OBLIGACION}}`

            }).then(async result=>{

                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                await this.cargarObligaciones()

                cerrarModal()

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })


        }

        const actualizarObligacion=()=>{

            if(!this.state.series)return
            if(!this.state.importeNom || !this.state.importeNomValido)return
            if(this.state.numOblig<1)return

            let existe=this.state.obligaciones.find(obligacion=>{return obligacion.SERIES===this.state.series})
            if(existe && this.state.obligacionSeleccionada.ID_OBLIGACION!==existe.ID_OBLIGACION){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //mutation para actualizar la obligacion emitida.
            this.props.client.mutate({
                mutation:gql`mutation{actualizarObligacionEmitida(
                    ID_OBLIGACION:${this.state.obligacionSeleccionada.ID_OBLIGACION},
                    VALORES:{
                        NUMERO:${this.state.numOblig},
                        SERIES:"${this.state.series}",
                        IMPORTE_NOMINAL:${this.state.importeNom.replace(/\./g, '').replace(/\,/g, '.')},
                        CONVERTIBLES:${this.state.convertibles},
                    }){ID_OBLIGACION}}`

            }).then(async result=>{

                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                await this.cargarObligaciones()

                cerrarModal()
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })


        }

        const eliminarObligacion=()=>{

            //mutation para eliminar la obligacion emitida.
            this.props.client.mutate({
                mutation:gql`mutation{eliminarObligacionEmitida(
                    ID_OBLIGACION:${this.state.obligacionSeleccionada.ID_OBLIGACION},
                ){ID_OBLIGACION}}`
            }).then(async result=>{

                alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))
                await this.cargarObligaciones()

                this.setState({
                    obligacionSeleccionada:null,
                })

                cerrarModal()
            
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false
            })
        }
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        const cambiarSeries = (ev) => {
            this.setState({series: ev.currentTarget.value})
        }

        const cambiarNumeroObligaciones = (ev) => {
            this.setState({numOblig: ev.currentTarget.value})
        }

        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }

        const cambiarImporteNom = (ev) => {
            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({importeNomValido:true})
            }else{
                this.setState({importeNomValido:false})
            }

            this.setState({importeNom:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
        }

        const handleConvertibles = () => {
            this.setState({
                convertibles: !this.state.convertibles
            })
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        obligacionSeleccionada: row
        });
        }
        return true;
        }

        function nominalFormatter(cell, row) {
            let nominal = `${Intl.NumberFormat('es-ES').format(parseFloat(cell).toFixed(2))} €`
            return (
                nominal
            );
        }

        function totalFormatter(cell, row) {
            let total = `${Intl.NumberFormat('es-ES').format(parseFloat(cell*row.NUMERO).toFixed(2))} €`
            return (
                total
            );
        }
        const yes=this.props.i18next.t("yes",{ns:"global"});
        const no=this.props.i18next.t("no",{ns:"global"});
        function convertiblesFormatter(cell, row) {
            return (
                cell?yes:no
            );
        }


        const columns = [
        {
        dataField: 'SERIES',
        text: this.props.i18next.t("obligacionesemitidas.series",{ns:"page"})
        },
        {
            dataField: 'NUMERO',
            text: this.props.i18next.t("obligacionesemitidas.nroobligaciones",{ns:"page"})
            },
            {
                dataField: 'IMPORTE_NOMINAL',
                text: this.props.i18next.t("obligacionesemitidas.importenominal",{ns:"page"}),
                formatter: nominalFormatter
                },
                {
                    dataField: 'IMPORTE_NOMINAL',
                    text: this.props.i18next.t("obligacionesemitidas.importenominal",{ns:"page"}),
                    formatter: totalFormatter
                    },
                    {
                        dataField: 'CONVERTIBLES',
                        text: this.props.i18next.t("obligacionesemitidas.importenominal",{ns:"page"}),
                        formatter: convertiblesFormatter
                        }
        ];

        return (
            <>
            {
                this.state.cargando === true?
                <Cargando />
                :
                <>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModalImprimir}
                            title={this.props.i18next.t("print",{ns:"global"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimir}
                            style={styles.dialog}
                        >
                            {!this.state.cargandoImprimir?
                                    <div style={{ width: '100%', height: '600px'}} id='generarImprimir-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        <Dialog transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title={this.props.i18next.t("obligacionesemitidas.title",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            className="pixelAlrededorGrande"
                            isOpen={this.state.modalAbierto}
                        >
                            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande">
                                <FormGroup
                                    label={this.props.i18next.t("obligacionesemitidas.series",{ns:"page"})+":"}
                                    labelFor="series"
                                    helperText={!this.state.series?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("obligacionesemitidas.series",{ns:"page"}):""}
                                    intent="danger"
                                >
                                    <InputGroup onChange={cambiarSeries} intent={!this.state.series?"danger":"primary"} value={this.state.series} className="pixelAlrededor" fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={this.props.i18next.t("obligacionesemitidas.nroobligaciones",{ns:"page"})+":"}
                                    labelFor="numro-obligaciones"
                                    intent="danger"
                                    helperText={this.state.numOblig<1?this.props.i18next.t("obligacionesemitidas.valid",{ns:"page"}):""}
                                >
                                    <InputGroup type="number"  step="1" min={1} value={this.state.numOblig} intent={this.state.numOblig<1?"danger":"primary"} onChange={cambiarNumeroObligaciones} className="pixelAlrededor" fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label={this.props.i18next.t("obligacionesemitidas.importenominal",{ns:"page"})+":"}
                                    labelFor="importe-nominal"
                                    helperText={!this.state.importeNom || !this.state.importeNomValido?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("obligacionesemitidas.importenominal",{ns:"page"}):""}
                                    intent="danger"
                                >
                                    <InputGroup onChange={cambiarImporteNom} type="text" value={this.state.importeNom} intent={!this.state.importeNom || !this.state.importeNomValido?"danger":"primary"} className="pixelAlrededor" min={0} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    labelFor="convertibles"
                                >
                                    <Switch checked={this.state.convertibles} innerLabel={this.props.i18next.t("no",{ns:"global"})} innerLabelChecked={this.props.i18next.t("yes",{ns:"global"})} value={this.state.convertibles} label={this.props.i18next.t("obligacionesemitidas.convertibles",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleConvertibles} />
                                </FormGroup>
                            </Card>
                            <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                                </Tooltip>
                                <Tooltip content={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={this.state.nuevoActivado?añadirNuevo:actualizarObligacion}
                                    >
                                        {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                            </div>
                        </div>
                        </Dialog>
                        <Dialog transitionDuration={400}
                                autoFocus={true}
                                enforceFocus={true}
                                icon="warning-sign"
                                onClose={cerrarModal}
                                title={this.props.i18next.t("delete",{ns:"global"})}
                                canEscapeKeyClose={true}
                                canOutsideClickClose={true}
                                isCloseButtonShown={true}
                                usePortal={true}
                                isOpen={this.state.modalBorrarAbierto}
                            >
                                <div className={Classes.DIALOG_BODY}>
                                    <h2>{this.props.i18next.t("obligacionesemitidas.questiondel",{ns:"page"})}</h2>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={this.props.i18next.t("eliminar",{ns:"global"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={eliminarObligacion}
                                        >
                                            {this.props.i18next.t("yes",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.cargandoGrid?
                            <Cargando/>
                        :
                        <FormGroup>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar} />
                                        <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Button  className="bp3-minimal"  icon="print" text={this.props.i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={()=>this.generarImprimirOE()} />

                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <BootstrapTable
                            keyField='ID_OBLIGACION'
                            data={ this.state.obligaciones }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                            />
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger callout-rebelde">
                                {this.props.i18next.t("obligacionesemitidas.titleimporte",{ns:"page"})}:                         
                                <InputGroup value={Intl.NumberFormat('es-ES').format(this.state.importeTotalObligaciones)} className="pixelAlrededor" readOnly={true} fill={false} />
                            </Callout>
                        </FormGroup>
                        }
                    </Card>
                </>
            }
            </>
        )
    }
}
export default withApollo(ObligacionesEmitidas)