import React from "react"
import { Card, Elevation,Button,Navbar,Classes,AnchorButton,Intent,Tooltip,Dialog,Alignment, FormGroup, InputGroup, TextArea, NumericInput, ButtonGroup } from "@blueprintjs/core"
import Select from "react-select"
import { DateInput } from "@blueprintjs/datetime"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import moment from "moment"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import { Cargando } from "../Cargando"
import i18next from "i18next";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class Epis extends React.Component {

    state={

        epis:[],
        episSelect:[],
        epiSeleccionada:null,
        epiSeleccionadaTabla:null,
        nuevoTipoEpi:null,
        cantidad:1,
        fechaEntrega:new Date(),
        eliminarEpiActivado:false,
        editarEpiActivado:false,
        modalEpi:false,
        modalBorrarAbierto:false,
        nuevoTipoEpiActivado:false,
        editarTipoEpiActivado:false,
        observaciones:null,
        cargandoEpis:false
    }

    cargarEpis=async()=>{

        //EPIS del select
        let epis=await client
        .query({
            query: gql`
            {epis{ID_EPI,DESCRIPCION}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return JSON.parse(JSON.stringify(result.data.epis))
        })

        epis.forEach(epi=>{
            epi.label=epi.DESCRIPCION
            epi.value=epi.ID_EPI
        })

        this.setState({episSelect:epis,cargandoEpis:false}); 


    }
    

    componentDidMount=()=>{
        this.setState({
            cargandoEpis:true
        },async ()=>{
            await this.cargarEpis()
        })
    }

    render(){


        const handleOnSelect = (row,isSelect) => {
            if (isSelect) {
                this.setState({
                    epiSeleccionadaTabla: row
                });
            }
        }
        
        const abrirModalNuevoEpi=()=>{

            this.setState({
                nuevoEpiActivado:true,
                editarEpiActivado:false,
                eliminarEpiActivado:false,
                modalEpi:true,
                epiSeleccionadaTabla:null,
                epiSeleccionada:null,
                fechaEntrega:new Date(),
                cantidad:1,
                observaciones:null,
                cargandoEpis:true
            })

        }

        const abrirModalNuevoTipoEpi=()=>{

            this.setState({
                nuevoTipoEpi:null,
                nuevoTipoEpiActivado:true,
                editarTipoEpiActivado:false,
                modalTipoEpi:true,
            })

        }

        const abrirModalActualizarEpi=()=>{


            if(!this.state.epiSeleccionadaTabla){
                alert("Debe seleccionar una epi");
                return
            }

            let epiSel=this.state.episSelect.find(epi=>{return epi.ID_EPI===this.state.epiSeleccionadaTabla.ID_EPI})

            this.setState({
                nuevoEpiActivado:false,
                editarEpiActivado:true,
                eliminarEpiActivado:false,
                modalEpi:true,
                epiSeleccionada:epiSel,
                fechaEntrega:new Date(this.state.epiSeleccionadaTabla.FECHA_ENTREGA),
                cantidad:this.state.epiSeleccionadaTabla.CANTIDAD,
                observaciones:this.state.epiSeleccionadaTabla.OBSERVACIONES,
                cargandoEpis:true
            })

        }

        const abrirModalActualizarTipoEpi=()=>{

            if(!this.state.epiSeleccionada){
                alert("Debes seleccionar una epi")
                return
            }

            this.setState({
                nuevoTipoEpi:this.state.epiSeleccionada.label,
                nuevoTipoEpiActivado:false,
                editarTipoEpiActivado:true,
                modalTipoEpi:true,
            })

        }

        const anadirEpi = async() => {

            if(!this.state.epiSeleccionada)return
            let existe=this.state.epis.find(epi=>{return epi.ID_EPI===this.state.epiSeleccionada.ID_EPI})
            if(existe){
                alert("El trabajador ya tiene este tipo de epi.")
                return
            }

            let epis=this.state.epis
            // eslint-disable-next-line no-unused-vars
            let id=this.state.epis.length>0?this.state.epis[this.state.epis.length-1].id+1:1
            epis.push({
                ID_EPI:this.state.epiSeleccionada.ID_EPI,
                DESCRIPCION:this.state.epiSeleccionada.label,
                FECHA_ENTREGA:this.state.fechaEntrega,
                CANTIDAD:this.state.cantidad,
                OBSERVACIONES:this.state.observaciones,
            })
            
            this.setState({epis:epis,
                modalEpi:false,
                cargandoEpis:false    
            })
            
        }

        const actualizarEpi=()=>{
            
         

            let epis=this.state.epis
            
            this.setState({epis:epis,
                modalEpi:false,
            cargandoEpis:false})
        }

        const eliminarEpi=()=>{

            if(!this.state.epiSeleccionadaTabla){
                alert("Debe seleccionar una epi")
                return
            }

            let epis=this.state.epis
            epis=this.state.epis.filter(epi => epi.ID_EPI !== this.state.epiSeleccionadaTabla.ID_EPI)
            this.setState({
                epis:epis,
                epiSeleccionadaTabla:null,
                nuevoEpiActivado:false,
                editarEpiActivado:false,
                eliminarEpiActivado:true,
                modalBorrarAbierto:false
            })


        }

        const seleccionarEpi  = (value) => { 
            this.setState({epiSeleccionada: value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        function dateFormatter(cell) {
            let fecha=null;
            if (cell) {
                fecha=moment(cell).format("DD/MM/YYYY");
            }
            return (
                fecha
            );
        }

        const epiFormatter=(cell,row)=>{
            let epi=null;
            if (cell) {
                epi=cell.EPI.DESCRIPCION;            
            } else {
                epi=row.DESCRIPCION;
            }
            return (
                epi
            );
        }

        const columns = [
            {
                dataField: 'EPI',
                text: 'Epi',
                formatter: epiFormatter
            },
            {
                dataField: 'FECHA_ENTREGA',
                text: 'Fecha entrega',
                formatter: dateFormatter
            },
            {
                dataField: 'CANTIDAD',
                text: 'Cantidad'
            },
            {
                dataField: 'OBSERVACIONES',
                text: 'Observaciones'
            }
        ]  

        const handleCambiarFechaEntrega = (value) =>{
            this.setState({fechaEntrega: value})
        }

        const handleCambiarCantidad = (value) =>{
            this.setState({cantidad: value})
        }

        const anadirTipoEpi=()=>{
            
            if(!this.state.nuevoTipoEpi)return

            client.mutate({
                mutation:gql`mutation{crearEpi(VALORES:{DESCRIPCION:"${this.state.nuevoTipoEpi}"}){ID_EPI}}`

            }).then(async result=>{
           
                await this.cargarEpis()
                //reseleccionamos la que teniamos seleccionada
                let sel=await this.state.episSelect.find(epi=>{
                    return epi.ID_EPI===result.data.crearEpi.ID_EPI
                })


                await this.setState({
                    epiSeleccionada:sel,
                    modalTipoEpi:false
                })

                alert("El registro se ha insertado correctamente.")

                
            }).catch(err=>{
                alert("Ha ocurrido un error al crear el el tipo de epi.Pongase en contacto con el soporte técnico.")
            })



        }

        const actualizarTipoEpi=()=>{

            if(!this.state.nuevoTipoEpi)return

            client.mutate({
                mutation:gql`mutation{actualizarEpi(ID_EPI:${this.state.epiSeleccionada.ID_EPI},VALORES:{DESCRIPCION:"${this.state.nuevoTipoEpi}"}){ID_EPI}}`

            }).then(async result=>{
           
                await this.cargarEpis()
                //reseleccionamos la que teniamos seleccionada
                let sel=await this.state.episSelect.find(epi=>{
                    return epi.ID_EPI===result.data.actualizarEpi.ID_EPI
                })


                await this.setState({
                    epiSeleccionada:sel,
                    modalTipoEpi:false
                })

                alert("El registro se ha actualizado correctamente.")

                
            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar el tipo de epi.Pongase en contacto con el soporte técnico.")
            })
        }

        const handleCambiarTipoEpi=(ev)=>{
            this.setState({nuevoTipoEpi:ev.currentTarget.value}) 
        }

        const abrirModalBorrarEpi=()=>{

            if(!this.state.epiSeleccionadaTabla){
                alert("Debe seleccionar una epi");
                return
            }

            this.setState({
                modalBorrarAbierto:true,
                nuevoEpiActivado:false,
                editarEpiActivado:false,
                eliminarEpiActivado:true
            })

        }

        const handleCambiarObservaciones=(ev)=>{
            this.setState({observaciones:ev.currentTarget.value})
        }


        return (
            <>

                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalEpi:false,cargandoEpis:false})}}
                    title={this.state.nuevoEpi?"Crear epi":"Actualizar epi"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEpi}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Epi:"
                        labelFor="epi"
                        intent="danger"
                        helperText={this.state.epiSeleccionada?"":"Debe seleccionar una epi"}
                        >
                            <div style={!this.state.epiSeleccionada?{border:"1px solid red"}:{}}>
                                <Select 
                                    options={this.state.episSelect}
                                    className="pixelAlrededor"
                                    onChange={seleccionarEpi}
                                    isDisabled={this.state.editarEpiActivado}
                                    value={this.state.epiSeleccionada}

                                />
                            </div>
                            {!this.state.editarEpiActivado?
                                <ButtonGroup>
                                    <Button 
                                        icon="add"
                                        className="bp3-minimal"
                                        onClick={abrirModalNuevoTipoEpi}
                                        active={this.state.nuevoTipoEpiActivado}
                                    />
                                    <Button 
                                        icon="edit"
                                        className="bp3-minimal"
                                        onClick={abrirModalActualizarTipoEpi}
                                        active={this.state.editarTipoEpiActivado}
                                    />
                                </ButtonGroup>
                            :<></>}
                            
                        </FormGroup>
                        <FormGroup
                            label="Fecha de entrega:"
                            labelFor="fecha-entrega"
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={handleCambiarFechaEntrega}
                                value={this.state.fechaEntrega}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Cantidad"
                            labelFor="cantidad"
                        >
                            <NumericInput
                                min={1}
                                value={this.state.cantidad}
                                onValueChange={handleCambiarCantidad}
                            />
                        </FormGroup>

                        <FormGroup
                            label="Observaciones"
                            labelFor="Observaciones"
                        >
                            <TextArea fill={true} value={this.state.observaciones} onChange={handleCambiarObservaciones} />
                        </FormGroup>


                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.state.nuevoEpiActivado?"Agregar epi.":"Actualizar epi."} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoEpiActivado?anadirEpi:actualizarEpi}
                                >
                                    Aceptar
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={()=>{this.setState({modalEpi:false,cargandoEpis:false})}}>Cancelar</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalTipoEpi:false})}}
                    title={this.state.nuevoEpi?"Crear tipo de epi":"Actualizar tipo de epi"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalTipoEpi}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Tipo Epi:"
                        labelFor="epi"
                        intent="danger"
                        helperText={this.state.nuevoTipoEpi?"":"Debe escribir el tipo de epi"}
                        >
                            <InputGroup id="texto-tipo-epi" onChange={handleCambiarTipoEpi} value={this.state.nuevoTipoEpi}  placeholder="Escriba el nuevo tipo de epi" intent={this.state.nuevoTipoEpi?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.state.nuevoEpiActivado?"Agregar tipo epi.":"Actualizar tipo epi."} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoTipoEpiActivado?anadirTipoEpi:actualizarTipoEpi}
                                >
                                    Aceptar
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={()=>{this.setState({modalTipoEpi:false})}}>Cancelar</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={()=>{this.setState({modalBorrarAbierto:false})}}
                        title="Borrar una epi"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea eliminar la epi seleccionada?</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar epi." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarEpi}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={()=>{this.setState({modalBorrarAbierto:false})}}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label="Epis:"
                            labelFor="epis"
                        >
                            <Navbar>
                                <Navbar.Group align={Alignment.LEFT}>
                                    <Button 
                                        icon="add"
                                        className="bp3-minimal"
                                        onClick={abrirModalNuevoEpi}
                                        active={this.state.nuevoEpiActivado}
                                    />
                                    <Button 
                                        icon="edit"
                                        className="bp3-minimal"
                                        onClick={abrirModalActualizarEpi}
                                        active={this.state.editarEpiActivado}
                                    />
                                    <Button 
                                        icon="cross"
                                        className="bp3-minimal"
                                        onClick={abrirModalBorrarEpi}
                                        active={this.state.eliminarEpiActivado}
                                    />
                                </Navbar.Group>
                            </Navbar>
                            {this.state.cargandoEpis?
                                <Cargando />
                            :
                                <BootstrapTable
                                    keyField='id'
                                    data={ this.state.epis }
                                    columns={ columns }
                                    selectRow={ const_table.selectRow(handleOnSelect) }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            }
                        </FormGroup>
                    </Card>
                </div>
            </>
        )
    }
}