import React from "react"
import { Callout, FormGroup, InputGroup, Navbar, Button, NumericInput, Alignment, Dialog, Intent, Classes, AnchorButton, Tooltip  } from "@blueprintjs/core"
import Select from "react-select"
import i18next from 'i18next';
import BootstrapTable from 'react-bootstrap-table-next';


export default class PuestosFactores extends React.Component {

    state={

        nuevaTareaActivado:true,
        editarTareaActivado:false,
        eliminarTareaActivado:false,
        descripcion:null,
        valor:null,
        modalTarea:false,
        tareaSeleccionada:null,
        modalBorrarAbierto: false
    }
    
    render(){
         const columns = [
            {
                dataField: 'descripcion',
                text: i18next.t('catalogopuestos.description', { ns: "page2" })
            },
            {
                dataField: 'valor',
                text: i18next.t('catalogopuestos.value', { ns: "page2" })
            }
        ];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    tareaSeleccionada: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const abrirModalNuevaTarea=()=>{

            console.log(this.state.descripcion)
            this.setState({
                descripcion:null,
                valor:null,
                nuevaTareaActivado:true,
                editarTareaActivado:false,
                eliminarTareaActivado:false,
                modalTarea:true,
                tareaSeleccionada:null
            })

        }

        const abrirModalActualizarTarea=()=>{


            if(!this.state.tareaSeleccionada){
                alert(i18next.t("selectempty", { ns: "global" })+i18next.t("catalogopuestos.task", { ns: "page2" }));
                return
            }

            this.setState({
                descripcion:this.state.tareaSeleccionada.descripcion,
                valor:this.state.tareaSeleccionada.valor,
                nuevaTareaActivado:false,
                editarTareaActivado:true,
                eliminarTareaActivado:false,
                modalTarea:true,
            })

        }

        const abrirModalBorrarTarea=()=>{
            if(!this.state.tareaSeleccionada){
                alert(i18next.t("selectempty", { ns: "global" })+i18next.t("catalogopuestos.task", { ns: "page2" }));
                return
            }
                this.setState({
                    nuevaTareaActivado:false,
                    editarTareaActivado:false,
                    eliminarTareaActivado:true,
                    modalBorrarAbierto:true
                })
            
        }
        const handleDescripcion = (e) => {
            this.setState({descripcion:e.currentTarget.value})
        }

        const handleValor = (e) => {
            this.setState({valor:e.currentTarget.value})
        }

        const anadirTarea = async() => {

            if(!this.state.descripcion || !this.state.valor)return

            let tareas=this.props.tareas
            let id=this.props.tareas.length>0?this.props.tareas[this.props.tareas.length-1].id+1:1
            tareas.push({
                id:id,
                descripcion:this.state.descripcion,
                valor:this.state.valor
            })
            let tareaCreada=await tareas.find((tarea,index)=>{
                return tarea.id===id
            })
            this.setState({tareaSeleccionada:tareaCreada,modalTarea:false},async ()=>{
                await this.props.handleTareas(tareas)
            })
            
        }

        const actualizarTarea=()=>{
            
            if(!this.state.tareaSeleccionada){
                alert(i18next.t("selectempty", { ns: "global" })+i18next.t("catalogopuestos.task", { ns: "page2" }));
                return
            }

            let tareas=this.props.tareas
            let tareaSel=tareas.find((tarea,index)=>{
                return tarea.id===this.state.tareaSeleccionada.id
            })
            
            tareaSel.descripcion=this.state.descripcion
            tareaSel.valor=this.state.valor  

            this.setState({modalTarea:false},async ()=>{
                await this.props.handleTareas(tareas)
            })
        }

        const eliminarTarea=()=>{

            if(!this.state.tareaSeleccionada){
                alert(i18next.t("selectempty", { ns: "global" })+i18next.t("catalogopuestos.task", { ns: "page2" }));
                return
            }

            let tareas=this.props.tareas
            tareas=this.props.tareas.filter(tarea => tarea.id !== this.state.tareaSeleccionada.id)
            this.setState({
                tareaSeleccionada:null,
                nuevaTareaActivado:false,
                editarTareaActivado:false,
                eliminarTareaActivado:true,
                modalBorrarAbierto:false
            },async ()=>{
                await this.props.handleTareas(tareas)
            })

        }
        return (
            <>
            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={()=>{this.setState({modalTarea:false})}}
                title={!this.state.tareaSeleccionada?i18next.t("catalogopuestos.createTask", { ns: "page2" }):i18next.t("catalogopuestos.updateTask", { ns: "page2" })}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalTarea}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={i18next.t("catalogopuestos.description", { ns: "page2" })}
                    labelFor="descripcion"
                    intent="danger"
                    helperText={this.state.descripcion?"":i18next.t("catalogopuestos.fromDescriptionError", { ns: "page2" })}
                    >
                        <InputGroup id="texto-nombre-descripcion" onChange={handleDescripcion} value={this.state.descripcion?this.state.descripcion:''}  placeholder={i18next.t("catalogopuestos.formPlaceholderDescription", { ns: "page2" })} intent={this.state.descripcion?"primary":"danger"} />
                    </FormGroup>
                    <FormGroup
                    label={i18next.t("catalogopuestos.value", { ns: "page2" })}
                    labelFor="valor"
                    intent="danger"
                    helperText={this.state.valor?"":i18next.t("catalogopuestos.formValueError", { ns: "page2" })}
                    >
                        <InputGroup id="texto-nombre-valor" onChange={handleValor} value={this.state.valor?this.state.valor:''}  placeholder={i18next.t("catalogopuestos.formPlaceholderValue", { ns: "page2" })} intent={this.state.valor?"primary":"danger"} />
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.state.nuevaTareaActivado?i18next.t("catalogopuestos.addTask", { ns: "page2" }):i18next.t("catalogopuestos.updateTask", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaTareaActivado?anadirTarea:actualizarTarea}
                            >
                                {i18next.t("aceptar", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={()=>{this.setState({modalTarea:false})}}>{i18next.t("cancelar", { ns: "global" })}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={()=>{this.setState({modalBorrarAbierto:false})}}
                title={i18next.t("catalogopuestos.deleteTask", { ns: "page2" })}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalBorrarAbierto}
            >
                <div className={Classes.DIALOG_BODY}>
                    <h2>{i18next.t("catalogopuestos.confirmDeleteTask", { ns: "page2" })}</h2>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("catalogopuestos.deleteTask", { ns: "page2" })} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={eliminarTarea}
                        >
                            {i18next.t("yes", { ns: "global" })}
                        </AnchorButton>
                    </Tooltip>
                    <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                        <Button onClick={()=>{this.setState({modalBorrarAbierto:false})}}>No</Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
            <div className="pixelAlrededor tablaMitadIzquierda">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger" style={{ paddingBottom: 0 }}>{ i18next.t("catalogopuestos.titleFactors", { ns: "page2" }).toUpperCase()}</Callout>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger" style={{paddingTop:0}}>{i18next.t("catalogopuestos.subTitleFactors", { ns: "page2" })}</Callout>
                        <div className="pixelAlrededor tablaMitadIzquierda">
                        <FormGroup
                            label={i18next.t("catalogopuestos.knowledgeAndSkills", { ns: "page2" }).toUpperCase() + " (20%-35%):"}
                                labelFor="conocimientos-aptitudes"
                                style={{fontWeight: "bold"}}
                            >
                                <NumericInput min={0} max={100} clampValueOnBlur={true} value={this.props.conocimientosAptitudes?this.props.conocimientosAptitudes:0} onValueChange={this.props.handleCambiarConocimientosAptitudes} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.leadership", { ns: "page2" })}
                                labelFor="destreza"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.valorDestreza?this.props.valorDestreza:0} onValueChange={this.props.handleCambiarValorDestreza} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.computerSkills", { ns: "page2" })}
                                labelFor="conocimientos-informatico"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.conocimientosInformaticos?this.props.conocimientosInformaticos:0} onValueChange={this.props.handleCambiarConocimientosInformaticos} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.creativity", { ns: "page2" })}
                                labelFor="polivalencia-creatividad"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.polivalenciaCreatividad?this.props.polivalenciaCreatividad:0} onValueChange={this.props.handleCambiarPolivalenciaCreatividad} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.experience", { ns: "page2" })}
                                labelFor="experiencia"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.valorExperiencia?this.props.valorExperiencia:0} onValueChange={this.props.handleCambiarValorExperiencia} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.currentGender", { ns: "page2" }).toUpperCase() + " (M-F-N):"}
                                labelFor="genero-conocimientos"
                            >
                                <Select
                                    options={this.props.generos}
                                    className="tablaMitadIzquierda"
                                    onChange={this.props.seleccionarGeneroConocimientosAptitudes}
                                    value={this.props.generoSeleccionadoConocimientosAptitudes}
                                />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.responsibility", { ns: "page2" }).toUpperCase() +" (25%-40%):"}
                                labelFor="responsabilidad"
                                style={{fontWeight: "bold"}}
                            >
                                <NumericInput min={0} max={100} clampValueOnBlur={true} value={this.props.valorResponsabilidad?this.props.valorResponsabilidad:0} onValueChange={this.props.handleCambiarValorResponsabilidad} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.workOrganization", { ns: "page2" })}
                                labelFor="organizacion-trabajo"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.organizacionTrabajo?this.props.organizacionTrabajo:0} onValueChange={this.props.handleCambiarOrganizacionTrabajo} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.machineryResponsibility", { ns: "page2" })}
                                labelFor="responsabilidad-maquinaria"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.responsabilidadMaquinaria?this.props.responsabilidadMaquinaria:0} onValueChange={this.props.handleCambiarResponsabilidadMaquinaria} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.management", { ns: "page2" })}
                                labelFor="jefatura"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.valorJefatura?this.props.valorJefatura:0} onValueChange={this.props.handleCambiarValorJefatura} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.initiativeVersatility", { ns: "page2" })}
                                labelFor="iniciativa-polivalencia"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.iniciativaPolivalencia?this.props.iniciativaPolivalencia:0} onValueChange={this.props.handleCambiarIniciativaPolivalencia} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.currentGender", { ns: "page2" }).toUpperCase() +" (M-F-N):"}
                                labelFor="genero-responsabilidad"
                            >
                                <Select
                                    options={this.props.generos}
                                    className="tablaMitadIzquierda"
                                    onChange={this.props.seleccionarGeneroResponsabilidad}
                                    value={this.props.generoSeleccionadoResponsabilidad}
                                />
                            </FormGroup>
                        </div>
                        <div className="pixelAlrededor tablaMitadDerecha">
                            <FormGroup
                                label={i18next.t("catalogopuestos.effort", { ns: "page2" }).toUpperCase()+" (15%-25%):"}
                                labelFor="esfuerzo"
                                style={{fontWeight: "bold"}}
                            >
                                <NumericInput min={0} max={100} clampValueOnBlur={true} value={this.props.valorEsfuerzo?this.props.valorEsfuerzo:0} onValueChange={this.props.handleCambiarValorEsfuerzo} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.forcedPostures", { ns: "page2" })}
                                labelFor="posturas-forzadas"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.posturasForzadas?this.props.posturasForzadas:0} onValueChange={this.props.handleCambiarPosturasForzadas} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.repetitiveMovements", { ns: "page2" })}
                                labelFor="movimientos-repetitivos"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.movimientosRepetitivos?this.props.movimientosRepetitivos:0} onValueChange={this.props.handleCambiarMovimientosRepetitivos} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.concentrationEmotionalEffort", { ns: "page2" })}
                                labelFor="concentracion"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.valorConcentracion?this.props.valorConcentracion:0} onValueChange={this.props.handleCambiarValorConcentracion} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.technicalDifficulty", { ns: "page2" })}
                                labelFor="dificultad-tecnica"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.dificultadTecnica?this.props.dificultadTecnica:0} onValueChange={this.props.handleCambiarDificultadTecnica} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.currentGender", { ns: "page2" }).toUpperCase() +" (M-F-N):"}
                                labelFor="genero-esfuerzo"
                            >
                                <Select
                                    options={this.props.generos}
                                    className="tablaMitadIzquierda"
                                    onChange={this.props.seleccionarGeneroEsfuerzo}
                                    value={this.props.generoSeleccionadoEsfuerzo}
                                />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.workingConditions", { ns: "page2" }).toUpperCase()+" (5%-15%):"}
                                labelFor="condiciones"
                                style={{fontWeight: "bold"}}
                            >
                                <NumericInput min={0} max={100} clampValueOnBlur={true} value={this.props.valorCondiciones?this.props.valorCondiciones:0} onValueChange={this.props.handleCambiarValorCondiciones} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.contactWithClients", { ns: "page2" })}
                                labelFor="contacto-clientes"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.valorContactoClientes?this.props.valorContactoClientes:0} onValueChange={this.props.handleCambiarValorContactoClientes} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.remoteWork", { ns: "page2" })}
                                labelFor="trabajo_distancia"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.trabajoDistancia?this.props.trabajoDistancia:0} onValueChange={this.props.handleCambiarTrabajoDistancia} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.requiredVersatility", { ns: "page2" })}
                                labelFor="polivalencia-exigida"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.polivalenciaExigida?this.props.polivalenciaExigida:0} onValueChange={this.props.handleCambiarPolivalenciaExigida} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.psychosocialRisk", { ns: "page2" })}
                                labelFor="riesgo-psicosocial"
                                
                            >
                                <NumericInput min={0} max={5} clampValueOnBlur={true} value={this.props.riesgoPsicosocial?this.props.riesgoPsicosocial:0} onValueChange={this.props.handleCambiarRiesgoPsicosocial} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("catalogopuestos.currentGender", { ns: "page2" }).toUpperCase() +" (M-F-N):"}
                                labelFor="genero-condiciones"
                            >
                                <Select
                                    options={this.props.generos}
                                    className="tablaMitadIzquierda"
                                    onChange={this.props.seleccionarGeneroCondiciones}
                                    value={this.props.generoSeleccionadoCondiciones}
                                />
                            </FormGroup>
                        </div>
                    
                
            </div>
            <div className="pixelAlrededor tablaMitadDerecha">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger" style={{ paddingBottom: 0 }}>{ i18next.t("catalogopuestos.titleTasksTable", { ns: "page2" }).toUpperCase()}</Callout>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger" style={{ paddingTop: 0 }}>{i18next.t("catalogopuestos.subTitleTasksTable", { ns: "page2" })}</Callout>
                        <FormGroup
                            label={i18next.t("catalogopuestos.tasks", { ns: "page2" })}
                            labelFor="tareas"
                        >
                            <Navbar>
                                <Navbar.Group align={Alignment.LEFT}>
                                    <Button 
                                        icon="add"
                                        className="bp3-minimal"
                                        onClick={abrirModalNuevaTarea}
                                        active={this.state.nuevaTareaActivado}
                                    />
                                    <Button 
                                        icon="edit"
                                        className="bp3-minimal"
                                        onClick={abrirModalActualizarTarea}
                                        active={this.state.editarTareaActivado}
                                    />
                                    <Button 
                                        icon="cross"
                                        className="bp3-minimal"
                                        onClick={abrirModalBorrarTarea}
                                        active={this.state.eliminarTareaActivado}
                                    />
                                </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField="id"
                            data={this.props.tareas}
                            columns={columns}
                            selectRow={selectRow}
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </FormGroup>
                    
                
            </div>
            </>
        )
    }
}