import React from 'react'
import { Navbar, Checkbox,ButtonGroup,FormGroup,  Alignment, Button, NavbarHeading, Dialog, InputGroup, Classes, Tooltip, AnchorButton, Intent, Card, Elevation } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from "../Cargando"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class CentrosTrabajo extends React.Component {
    state = {

        nuevoCentroActivado: true,
        editarCentroActivado: false,
        borrarCentroActivado: false,

        pendienteGuardarCentro:false,
        pendienteEditarCentro:false,
        pendienteBorrarCentro:false,

        nuevoSucursalActivado:true,
        editarSucursalActivado: false,
        borrarSucursalActivado: false,

        principalActivado: false,
        pendienteGuardarSucursal:false,
        pendienteEditarSucursal:false,
        pendienteBorrarSucursal:false,

        modalCentroAbierto: false,
        modalCentroBorrarAbierto: false,

        modalSucursalAbierto: false,
        modalSucursalBorrarAbierto: false,

        paises:[],
        tiposVia:[],
        nombreCentro:null,
        paisSeleccionadoCentro:false,
        localidadSeleccionadaCentro:false,
        provinciaSeleccionadaCentro:false,
        tipoViaSeleccionadaCentro:null,
        centroSeleccionado:null,
        provinciasCentros:[],
        localidadesCentros:[],
        viaCentro:null,
        kmCentro:null,
        cpCentro:null,
        restoCentro:null,
        centros: [
        ],
        controlesCentrosDesactivados:false,

        nombreSucursal:null,
        paisSeleccionadoSucursal:false,
        localidadSeleccionadaSucursal:false,
        provinciaSeleccionadaSucursal:false,
        tipoViaSeleccionadaSucursal:null,
        provinciasSucursal:[],
        localidadesSucursal:[],
        viaSucursal:null,
        kmSucursal:null,
        cpSucursal:null,
        restoSucursal:null,
        sucursales: [
        ],
        controlesSucursalesDesactivados:false,

        paisNuevo:null,
        provinciaNuevo:null,
        modalPais:false,
        modalProvincia:false,
        modalLocalidad:false,
        localidadNueva:null,

        cpInvalido:false,
        tipoLocalidad:null,
        tipoProvincia:null,
        domicilioSocial:false,
        cargando: false,
        cargandoCentros:false,
        cargandoSucursal:false
    }

    recargarCentros=async ()=>{

       await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_CENTRO_TRABAJO,NOMBRE,DOMICILIO_SOCIAL,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let copyCentros=[]
            for(let centro of result.data.centrosTrabajos){
                if(centro.DOMICILIO_SOCIAL===false){
                    copyCentros.push({"id":centro.ID_CENTRO_TRABAJO,"domicilioSocial":centro.DOMICILIO_SOCIAL,"nombre":centro.NOMBRE,"idLocalidad":centro.DIRECCION.LOCALIDAD.ID_LOCALIDAD,"idProvincia":centro.DIRECCION.LOCALIDAD.PROVINCIA.ID_PROVINCIA,"idPais":centro.DIRECCION.LOCALIDAD.PROVINCIA.PAIS.ID_PAIS,"idDireccion":centro.DIRECCION.ID_DIRECCION,"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM}${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""} ( ${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
                }else{
                    copyCentros.splice(0,0,{"id":centro.ID_CENTRO_TRABAJO,"domicilioSocial":centro.DOMICILIO_SOCIAL,"nombre":centro.NOMBRE,"idLocalidad":centro.DIRECCION.LOCALIDAD.ID_LOCALIDAD,"idProvincia":centro.DIRECCION.LOCALIDAD.PROVINCIA.ID_PROVINCIA,"idPais":centro.DIRECCION.LOCALIDAD.PROVINCIA.PAIS.ID_PAIS,"idDireccion":centro.DIRECCION.ID_DIRECCION,"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM}${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""} ( ${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
                }
            }   
         
            this.setState({centros:copyCentros,
            cargandoCentros:false});
        })

    }

    recargarSucursales=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                sucursales(FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_SUCURSAL,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let copySucursales=[]
            result.data.sucursales.forEach((sucursal,index)=>{
                copySucursales.push({"id":sucursal.ID_SUCURSAL,"nombre":sucursal.NOMBRE,"idLocalidad":sucursal.DIRECCION.LOCALIDAD.ID_LOCALIDAD,"idProvincia":sucursal.DIRECCION.LOCALIDAD.PROVINCIA.ID_PROVINCIA,"idPais":sucursal.DIRECCION.LOCALIDAD.PROVINCIA.PAIS.ID_PAIS,"idDireccion":sucursal.DIRECCION.ID_DIRECCION,"direccion":`${sucursal.DIRECCION.TIPO_VIA.DESCRIPCION} ${sucursal.DIRECCION.NOMBRE_VIA},${sucursal.DIRECCION.NUMERO_KM} ${sucursal.DIRECCION.RESTO_DIRECCION?`,${sucursal.DIRECCION.RESTO_DIRECCION}`:""}(${sucursal.DIRECCION.CP?sucursal.DIRECCION.CP:""} ${sucursal.DIRECCION.LOCALIDAD.DESCRIPCION} - ${sucursal.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
            })

            this.setState({sucursales:copySucursales,
            cargandoSucursal:false})
        })

    }

    cargarPaises=async()=>{

         await this.props.client
        .query({
            query: gql`
            {
                paises{ID_PAIS,DESCRIPCION}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let paises=[]
            for(let pais of result.data.paises){
                paises.push({"label":pais.DESCRIPCION,"value":pais.ID_PAIS})
            }

            this.setState({paises:paises})
        })

    }

    cargarProvincias=async(pais,tipo)=>{

        let copyProvincias=[]
        await this.props.client
        .query({
            query: gql`
            {
                provincias(FILTROS:{ID_PAIS:${pais}}){ID_PROVINCIA,DESCRIPCION}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            for(let provincia of result.data.provincias){
                copyProvincias.push({"label":provincia.DESCRIPCION,"value":provincia.ID_PROVINCIA})
            }
            if(tipo==="centro"){
                this.setState({provinciasCentros:copyProvincias})
            }else{
                this.setState({provinciasSucursal:copyProvincias})
            }
        })

    }

    cargarLocalidades=async(provincia,tipo)=>{

        let copyLocalidades=[]
        await this.props.client
        .query({
            query: gql`
            {
                localidades(FILTROS:{ID_PROVINCIA:${provincia}}){ID_LOCALIDAD,DESCRIPCION}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            for(let localidad of result.data.localidades){
                copyLocalidades.push({"label":localidad.DESCRIPCION,"value":localidad.ID_LOCALIDAD})
            }
            
        })

        if(tipo==="centro"){
            await this.setState({localidadesCentros:copyLocalidades})
        }else{
            await this.setState({localidadesSucursal:copyLocalidades})
        }

   }


    componentDidMount=async()=>{

        this.setState({cargando: true})

        await this.props.client
        .query({
            query: gql`
            {
                tiposVia{ID_TIPO_VIA,DESCRIPCION}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let tiposVia=[]
            for(let tipo of result.data.tiposVia){
                tiposVia.push({"label":tipo.DESCRIPCION,"value":tipo.ID_TIPO_VIA})
            }

            this.setState({tiposVia:tiposVia})

        })

        await this.recargarCentros()
        await this.recargarSucursales()
        await this.cargarPaises()

        this.setState({cargando: false})
              
    }

    guardarCentro =async (value) => {

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:'global'}))
            return
        }

        //si nos marca el domicilio principal, desmarcarmos el antiguo
        if(this.state.domicilioSocial){

            await  this.props.client
            .query({
                query: gql`
                {
                    centrosTrabajos(FILTROS:{DOMICILIO_SOCIAL:true,ID_PERSONA:${this.props.empresaSeleccionada}}){ID_CENTRO_TRABAJO}
                }
                `,
                fetchPolicy: 'network-only'

            }).then(async result=>{ 

                if(result.data.centrosTrabajos.length>0){

                    await this.props.client.mutate({
                        mutation: gql`
                            mutation{actualizarCentroTrabajo(ID_CENTRO_TRABAJO:${result.data.centrosTrabajos[0].ID_CENTRO_TRABAJO},VALORES:{DOMICILIO_SOCIAL:false}){ID_CENTRO_TRABAJO}}
                        `
                    }).catch(err=>{
                        alert(i18next.t("error",{ns:'global'}))
                    })
                }

            })

        }

        if(this.state.pendienteGuardarCentro){

            //insertar direccion
             this.props.client.mutate({
                mutation: gql`
                    mutation{crearDireccion(VALORES:{ID_TIPO_VIA:${this.state.tipoViaSeleccionadaCentro.value},NOMBRE_VIA:"${this.state.viaCentro}",NUMERO_KM:"${this.state.kmCentro}",ID_LOCALIDAD:${this.state.localidadSeleccionadaCentro.value},CP:${this.state.cpCentro?'"'+this.state.cpCentro+'"':null},RESTO_DIRECCION:${this.state.restoCentro?'"'+this.state.restoCentro+'"':null}}){ID_DIRECCION}}
                `
            }).then(result=>{

                //insertar el centro de trabajo.
                this.props.client.mutate({
                    mutation: gql`
                        mutation{crearCentroTrabajo(VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},NOMBRE:"${this.state.nombreCentro}",ID_DIRECCION:${result.data.crearDireccion.ID_DIRECCION},DOMICILIO_SOCIAL:${this.state.centros.length===1?true:this.state.domicilioSocial}}){ID_CENTRO_TRABAJO}}
                    `
                }).then(async result=>{

                    alert(i18next.t("insertcorrecto",{ns:'global'}))

                    await this.recargarCentros()
                    
                    this.setState({
                        pendienteGuardarCentro:false,
                        controlesCentrosDesactivados:false
                    })

                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })

            })
            
        }else if(this.state.pendienteEditarCentro){

            //insertar el centro de trabajo.
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarCentroTrabajo(ID_CENTRO_TRABAJO:${this.state.centroSeleccionado.id},VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},NOMBRE:"${this.state.nombreCentro}",DOMICILIO_SOCIAL:${this.state.centros.length===1?true:this.state.domicilioSocial}}){ID_CENTRO_TRABAJO}}
                `
            }).then(result=>{

                    //actualizar direccion.
                    this.props.client.mutate({
                        mutation: gql`
                            mutation{actualizarDireccion(ID_DIRECCION:${this.state.centroSeleccionado.datosdireccion.ID_DIRECCION},VALORES:{ID_TIPO_VIA:${this.state.tipoViaSeleccionadaCentro.value},ID_LOCALIDAD:${this.state.localidadSeleccionadaCentro.value},CP:${this.state.cpCentro?'"'+this.state.cpCentro+'"':null},NOMBRE_VIA:"${this.state.viaCentro}",NUMERO_KM:"${this.state.kmCentro}",RESTO_DIRECCION:${this.state.restoCentro?'"'+this.state.restoCentro+'"':null}}){ID_DIRECCION}}
                        `
                    }).then(async result=>{
                         
                        alert(i18next.t("insertcorrecto",{ns:'global'}))
                        await this.recargarCentros()
                        this.setState({
                            controlesCentrosDesactivados:false,
                            pendienteEditarCentro:false
                        })
                        
                        
                    }).catch(err=>{
                        console.log(err)
                        alert(i18next.t("error",{ns:'global'}))
                    })
                
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:'global'}))
            })

        }else if(this.state.pendienteBorrarCentro){

            this.props.client.mutate({
                mutation: gql`
                    mutation{eliminarCentroTrabajo(ID_CENTRO_TRABAJO:${this.state.centroSeleccionado.id}){ID_CENTRO_TRABAJO}}
                `
            }).then(result=>{
               
                alert(i18next.t("deletecorrecto",{ns:'global'}))
                this.setState({pendienteBorrarCentro:false})
                this.setState({controlesCentrosDesactivados:false})
                this.setState({centroSeleccionado:null})
                
            }).catch(err=>{

            })
        }

    }


    guardarSucursal = (value) => {

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:'global'}))
            return
        }

        if(this.state.pendienteGuardarSucursal){
            //insertar direccion
             this.props.client.mutate({
                mutation: gql`
                    mutation{crearDireccion(VALORES:{ID_TIPO_VIA:${this.state.tipoViaSeleccionadaSucursal.value},NOMBRE_VIA:"${this.state.viaSucursal}",NUMERO_KM:"${this.state.kmSucursal}",ID_LOCALIDAD:${this.state.localidadSeleccionadaSucursal.value},CP:${this.state.cpSucursal?'"'+this.state.cpSucursal+'"':null},RESTO_DIRECCION:${this.state.restoSucursal?'"'+this.state.restoSucursal+'"':null}}){ID_DIRECCION}}
                `
            }).then(result=>{

                //insertar el centro de trabajo.
                this.props.client.mutate({
                    mutation: gql`
                        mutation{crearSucursal(VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},NOMBRE:"${this.state.nombreSucursal}",ID_DIRECCION:${result.data.crearDireccion.ID_DIRECCION}}){ID_SUCURSAL}}
                    `
                }).then(async result=>{
                    
                    alert(i18next.t("insertcorrecto",{ns:'global'}))
                  
                    await this.recargarSucursales()

                   
                   
                    this.setState({
                        pendienteGuardarSucursal:false,
                        controlesSucursalesDesactivados:false
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })
            
        }else if(this.state.pendienteEditarSucursal){

            //insertar el centro de trabajo.
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarSucursal(ID_SUCURSAL:${this.state.sucursalSeleccionada.id},VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},NOMBRE:"${this.state.nombreSucursal}"}){ID_SUCURSAL}}
                `
            }).then(result=>{

                //actualizar direccion.
                this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarDireccion(ID_DIRECCION:${this.state.sucursalSeleccionada.datosdireccion.ID_DIRECCION},VALORES:{ID_TIPO_VIA:${this.state.tipoViaSeleccionadaSucursal.value},ID_LOCALIDAD:${this.state.localidadSeleccionadaSucursal.value},CP:${this.state.cpSucursal?'"'+this.state.cpSucursal+'"':null},NOMBRE_VIA:"${this.state.viaSucursal}",NUMERO_KM:"${this.state.kmSucursal}",RESTO_DIRECCION:${this.state.restoSucursal?'"'+this.state.restoSucursal+'"':null}}){ID_DIRECCION}}
                    `
                }).then(async result=>{

                    alert(i18next.t("updatecorrecto",{ns:'global'}))
                    await this.recargarSucursales()

                    
                    
                    this.setState({
                        pendienteEditarSucursal:false,
                        controlesSucursalesDesactivados:false
                    })

                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })


        }else if(this.state.pendienteBorrarSucursal){

            this.props.client.mutate({
                mutation: gql`
                    mutation{eliminarSucursal(ID_SUCURSAL:${this.state.sucursalSeleccionada.id}){ID_SUCURSAL}}
                `
            }).then(result=>{
              
                alert(i18next.t("deletecorrecto",{ns:'global'}))
                this.setState({pendienteBorrarSucursal:false})
                this.setState({controlesSucursalesDesactivados:false})
                this.setState({sucursalSeleccionada:null})
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })
            
        }


    }


    render () {
        document.title =i18next.t("lexcontrol",{ns:'global'})+" | "+i18next.t("empresa.centrostrabajo",{ns:'company'})

        ////console.log(this.state)
        
        const abrirmodalPais=()=>{
            this.setState({paisNuevo:null})
            this.setState({modalPais:true})
        }

        const abrirModalProvincia=(tipo)=>{
            this.setState({
                provinciaNueva:null,
                modalProvincia:true,
                tipoProvincia:tipo
            })

        }

        const abrirModalLocalidad=(tipo)=>{
            this.setState({
                localidadNueva:null,
                modalLocalidad:true,
                tipoLocalidad:tipo
            })
        }

        const cancelarCentro=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            /*
            this.setState({nombreCentro:null})
            this.setState({tipoViaSeleccionadaCentro:null})
            this.setState({provinciaSeleccionadaCentro:null})
            this.setState({localidadSeleccionadaCentro:null})
            this.setState({paisSeleccionadoCentro:null})
            this.setState({cpCentro:null})
            this.setState({viaCentro:null})
            this.setState({kmCentro:null})
            this.setState({restoCentro:null})
            this.setState({centroSeleccionado:null})
            */
            this.setState({
                pendienteBorrarCentro:false,
                pendienteEditarCentro:false,
                pendienteGuardarCentro:false,
                controlesCentrosDesactivados: false
            })

            this.recargarCentros(this.props.empresaSeleccionada)

        }

        const cancelarSucursal=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            /*
            this.setState({nombreSucursal:null})
            this.setState({tipoViaSeleccionadaSucursal:null})
            this.setState({cpSucursal:null})
            this.setState({viaSucursal:null})
            this.setState({kmSucursal:null})
            this.setState({restoSucursal:null})
            this.setState({paisSeleccionadoSucursal:null})
            this.setState({provinciaSeleccionadaSucursal:null})
            this.setState({localidadSeleccionadaSucursal:null})
            this.setState({sucursalSeleccionada:null})
            */

            this.setState({
                pendienteBorrarSucursal:false,
                pendienteEditarSucursal:false,
                pendienteGuardarSucursal:false,
                controlesSucursalesDesactivados: false
            },async () => {
                await this.recargarSucursales(this.props.empresaSeleccionada)
            })

        }


        const abrirModalCentroNuevo = () => {

             //si no tiene permisos para editar la pantalla
             if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            this.setState({
                modalCentroAbierto: true,
                nuevoCentroActivado: true,
                editarCentroActivado: false,
                borrarCentroActivado: false,
                nombreCentro:null,
                tipoViaSeleccionadaCentro:null,
                provinciaSeleccionadaCentro:null,
                localidadSeleccionadaCentro:null,
                paisSeleccionadoCentro:null,
                cpCentro:null,
                cpInvalido:false,
                viaCentro:null,
                kmCentro:null,
                restoCentro:null,
                centroSeleccionado:null,
                domicilioSocial:false,
                cargandoCentros:true
            })

        }

        const abrirModalCentroEditar = async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(!this.state.centroSeleccionado){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }

            this.setState({nombreCentro:this.state.centroSeleccionado.nombre})

            let tipoVia=this.state.tiposVia.find(via=>{
                return via.value===this.state.centroSeleccionado.datosdireccion.TIPO_VIA.ID_TIPO_VIA
            })
            this.setState({tipoViaSeleccionadaCentro:tipoVia})
        
            this.setState({cpCentro:this.state.centroSeleccionado.datosdireccion.CP})
            this.setState({viaCentro:this.state.centroSeleccionado.datosdireccion.NOMBRE_VIA})
            this.setState({kmCentro:this.state.centroSeleccionado.datosdireccion.NUMERO_KM})
            this.setState({restoCentro:this.state.centroSeleccionado.datosdireccion.RESTO_DIRECCION})
            this.setState({domicilioSocial:this.state.centroSeleccionado.domicilioSocial})

            this.setState({cpInvalido:false})            

            let pais=this.state.paises.find(pais=>{
                return pais.value===this.state.centroSeleccionado.idPais
            })
            this.setState({paisSeleccionadoCentro:pais})

            //recargamos las provincias en función del país seleccionado.
            let copyProvincias=[]
            let copyLocalidades=[]
             await this.props.client
            .query({
                query: gql`
                {
                    provincias(FILTROS:{ID_PAIS:${pais.value}}){ID_PROVINCIA,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.provincias.forEach((provincia,index)=>{
                    copyProvincias.push({"label":provincia.DESCRIPCION,"value":provincia.ID_PROVINCIA})
                })
                this.setState({provinciasCentros:copyProvincias})
            })

            let provincia=this.state.provinciasCentros.find(prov=>{
                return prov.value===this.state.centroSeleccionado.datosdireccion.LOCALIDAD.PROVINCIA.ID_PROVINCIA
            })
            this.setState({provinciaSeleccionadaCentro:provincia})

            //recarga de las localidades en función de la provincia seleccionada.
            await this.props.client
            .query({
                query: gql`
                {
                    localidades(FILTROS:{ID_PROVINCIA:${provincia.value}}){ID_LOCALIDAD,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.localidades.forEach((localidad,index)=>{
                    copyLocalidades.push({"label":localidad.DESCRIPCION,"value":localidad.ID_LOCALIDAD})
                })
                this.setState({localidadesCentros:copyLocalidades})
            })

            let localidad=this.state.localidadesCentros.find(local=>{
                return local.value===this.state.centroSeleccionado.datosdireccion.LOCALIDAD.ID_LOCALIDAD
            })
            this.setState({localidadSeleccionadaCentro:localidad})

            this.setState({nuevoCentroActivado: false})
            this.setState({editarCentroActivado: true})
            this.setState({borrarCentroActivado: false})
            this.setState({modalCentroAbierto: true,
                cargandoCentros:true})

        }

        const abrirModalSucursalNuevo = () => {

             //si no tiene permisos para editar la pantalla
             if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }
            
            this.setState({
                modalSucursalAbierto: true,
                borrarSucursalActivado: false,
                nuevoSucursalActivado: true,
                editarSucursalActivado: false,
                nombreSucursal:null,
                tipoViaSeleccionadaSucursal:null,
                cpSucursal:null,
                viaSucursal:null,
                kmSucursal:null,
                restoSucursal:null,
                paisSeleccionadoSucursal:null,
                provinciaSeleccionadaSucursal:null,
                localidadSeleccionadaSucursal:null,
                sucursalSeleccionada:null,
                cpSucursalInvalido:false,
                cargandoSucursal:true
            })

        }

        const abrirModalSucursalEditar = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(!this.state.sucursalSeleccionada){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }

            this.setState({nombreSucursal:this.state.sucursalSeleccionada.nombre})

            let tipoVia=this.state.tiposVia.find(via=>{
                return via.value===this.state.sucursalSeleccionada.datosdireccion.TIPO_VIA.ID_TIPO_VIA
            })
            this.setState({tipoViaSeleccionadaSucursal:tipoVia})
        
            this.setState({cpSucursal:this.state.sucursalSeleccionada.datosdireccion.CP})
            this.setState({viaSucursal:this.state.sucursalSeleccionada.datosdireccion.NOMBRE_VIA})
            this.setState({kmSucursal:this.state.sucursalSeleccionada.datosdireccion.NUMERO_KM})
            this.setState({restoSucursal:this.state.sucursalSeleccionada.datosdireccion.RESTO_DIRECCION})
            this.setState({cpSucursalInvalido:false})

            let pais=this.state.paises.find(pais=>{
                return pais.value===this.state.sucursalSeleccionada.idPais
            })
            this.setState({paisSeleccionadoSucursal:pais})

            let copyProvincias=[]
            let copyLocalidades=[]
            await this.props.client
            .query({
                query: gql`
                {
                    provincias(FILTROS:{ID_PAIS:${pais.value}}){ID_PROVINCIA,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.provincias.forEach((provincia,index)=>{
                    copyProvincias.push({"label":provincia.DESCRIPCION,"value":provincia.ID_PROVINCIA})
                })
                this.setState({provinciasSucursal:copyProvincias})
            })

            let provincia=this.state.provinciasSucursal.find(prov=>{
                return prov.value===this.state.sucursalSeleccionada.datosdireccion.LOCALIDAD.PROVINCIA.ID_PROVINCIA
            })
            this.setState({provinciaSeleccionadaSucursal:provincia})

            await this.props.client
            .query({
                query: gql`
                {
                    localidades(FILTROS:{ID_PROVINCIA:${provincia.value}}){ID_LOCALIDAD,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.localidades.forEach((localidad,index)=>{
                    copyLocalidades.push({"label":localidad.DESCRIPCION,"value":localidad.ID_LOCALIDAD})
                })
                this.setState({localidadesSucursal:copyLocalidades})
            })

            let localidad=this.state.localidadesSucursal.find(local=>{
                return local.value===this.state.sucursalSeleccionada.datosdireccion.LOCALIDAD.ID_LOCALIDAD
            })
            this.setState({localidadSeleccionadaSucursal:localidad})

            this.setState({modalSucursalAbierto: true,
                cargandoSucursal:true})
            this.setState({borrarSucursalActivado: false})
            this.setState({nuevoSucursalActivado: false})
            this.setState({editarSucursalActivado: true})

        }

        const abrirModalSucursalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }
            
            if(!this.state.sucursalSeleccionada){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }

            this.setState({modalSucursalBorrarAbierto: true})
            this.setState({borrarSucursalActivado: true})
            this.setState({nuevoSucursalActivado: false})
            this.setState({editarSucursalActivado: false})
        }

        const abrirModalCentroBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:'global'}))
                return
            }

            if(!this.state.centroSeleccionado){
                alert(i18next.t("noseleccionado",{ns:'global'}))
                return
            }

            this.setState({modalCentroBorrarAbierto: true})
            this.setState({nuevoCentroActivado: false})
            this.setState({editarCentroActivado: false})
            this.setState({borrarCentroActivado: true})

        }

        const validarForm=(tipo)=>{
            
            if(tipo==="centro"){

                if(!this.state.nombreCentro){
                    return false
                }
                if(!this.state.tipoViaSeleccionadaCentro){
                    return false
                }
                if(!this.state.viaCentro){
                    return false
                }
                if(!this.state.kmCentro){
                    return false
                }
                if(!this.state.paisSeleccionadoCentro){
                    return false
                }
                if(!this.state.provinciaSeleccionadaCentro){
                    return false
                }
                if(!this.state.localidadSeleccionadaCentro){
                    return false
                }

                //si el cp no viene vacío, procedemos a su validación
                if(this.state.cpCentro!=="" && this.state.cpCentro){
                    let patron=/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/
                    if(!patron.test(this.state.cpCentro)){
                        this.setState({cpInvalido:true})
                        return false
                    }else{
                        this.setState({cpInvalido:false})
                    }
                    
                }

                return true

            }else{

                
                if(!this.state.nombreSucursal){
                    return false
                }
                if(!this.state.tipoViaSeleccionadaSucursal){
                    return false
                }
                if(!this.state.viaSucursal){
                    return false
                }
                if(!this.state.kmSucursal){
                    return false
                }
                if(!this.state.paisSeleccionadoSucursal){
                    return false
                }
                if(!this.state.provinciaSeleccionadaSucursal){
                    return false
                }
                if(!this.state.localidadSeleccionadaSucursal){
                    return false
                }

                //si el cp no viene vacío, procedemos a su validación
                if(this.state.cpSucursal!==""  && this.state.cpSucursal){
                    let patron=/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/
                    if(!patron.test(this.state.cpSucursal)){
                        this.setState({cpSucursalInvalido:true})
                        return false
                    }else{
                        this.setState({cpSucursalInvalido:false})
                    }
                    
                }

                return true                
                
            }

        }

        const anadirCentroNuevo = () => {

    
            let valido=validarForm("centro")
            if(!valido)return

            //comporbar que no exista ya un centro con el nombre proporcionado.
            let existe=this.state.centros.find(centro=>{return centro.nombre===this.state.nombreCentro})
            if(existe){
                alert(i18next.t("yaexiste",{ns:'global'}))
                return
            }

            let tipoVia=this.state.tipoViaSeleccionadaCentro.label;
            let provincia=this.state.provinciaSeleccionadaCentro.label
            let localidad=this.state.localidadSeleccionadaCentro.label

            this.state.centros.push({
                "nombre": this.state.nombreCentro,
                "direccion": `${tipoVia} ${this.state.viaCentro},${this.state.kmCentro}${this.state.restoCentro?`,${this.state.restoCentro}`:""}(${this.state.cpCentro?this.state.cpCentro:""} ${localidad} - ${provincia})`
            })
            this.setState({
                controlesCentrosDesactivados:true,
                pendienteGuardarCentro:true
            })
            cerrarModal()
        }

        const anadirSucursalNuevo = () => {

            let valido=validarForm("sucursal")
            if(!valido)return

            //comprobar que no exista una sucursal con ese nombre
            let existe=this.state.sucursales.find(sucursal=>{return sucursal.nombre===this.state.nombreSucursal})
            if(existe){
                alert(i18next.t("yaexiste",{ns:'global'}))
                return
            }

            let tipoVia=this.state.tipoViaSeleccionadaSucursal.label;
            let provincia=this.state.provinciaSeleccionadaSucursal.label
            let localidad=this.state.localidadSeleccionadaSucursal.label

            this.state.sucursales.push({
                "nombre": this.state.nombreSucursal,
                "direccion": `${tipoVia} ${this.state.viaSucursal},${this.state.kmSucursal} ${this.state.restoSucursal?` ,${this.state.restoSucursal}`:""} ( ${this.state.cpSucursal?this.state.cpSucursal:""} ${localidad} - ${provincia})`
            })
            this.setState({pendienteGuardarSucursal:true})
            this.setState({controlesSucursalesDesactivados:true})

            cerrarModal()
        }

        const actualizarCentro = () => {
         
            //comporbar que no exista ya un centro con el nombre proporcionado.
            let existe=this.state.centros.find(centro=>{return centro.nombre===this.state.nombreCentro && centro.id!==this.state.centroSeleccionado.id})
            if(existe){
                alert(i18next.t("yaexiste",{ns:'global'}))
                return
            }
            let centros=this.state.centros
            this.state.centros.find((centro,index)=>{
                if(centro.id===this.state.centroSeleccionado.id){
                    centros[index].nombre=this.state.nombreCentro
                    centros[index].direccion=`${this.state.tipoViaSeleccionadaCentro.label} ${this.state.viaCentro},${this.state.kmCentro}${this.state.restoCentro?","+this.state.restoCentro:""} ( ${this.state.cpCentro?this.state.cpCentro:""} ${this.state.localidadSeleccionadaCentro.label} - ${this.state.provinciaSeleccionadaCentro.label})`
                    this.setState({
                        pendienteEditarCentro:true,
                        centros:centros,
                        controlesCentrosDesactivados:true,
                        cargandoCentros:true
                    })
                }
                return centro.id===this.state.centroSeleccionado.id
            })

            cerrarModal()
        }

        const actualizarSucursal = () => {

            //comprobar que no exista una sucursal con ese nombre
            let existe=this.state.sucursales.find(sucursal=>{return sucursal.nombre===this.state.nombreSucursal && sucursal.id!==this.state.sucursalSeleccionada.id})
            if(existe){
                alert(i18next.t("yaexiste",{ns:'global'}))
                return
            }
        
            let sucursales=this.state.sucursales
            this.state.sucursales.find((sucursal,index)=>{
                if(sucursal.id===this.state.sucursalSeleccionada.id){
                    sucursales[index].nombre=this.state.nombreSucursal
                    sucursales[index].direccion=`${this.state.tipoViaSeleccionadaSucursal.label} ${this.state.viaSucursal},${this.state.kmSucursal}${this.state.restoSucursal?","+this.state.restoSucursal:""} ( ${this.state.cpSucursal?this.state.cpSucursal:""} ${this.state.localidadSeleccionadaSucursal.label} - ${this.state.provinciaSeleccionadaSucursal.label})`
                    this.setState({
                        pendienteEditarSucursal:true,
                        sucursales:sucursales,
                        controlesSucursalesDesactivados:true
                    })
                }
                return sucursal.id===this.state.sucursalSeleccionada.id
            })
 
            cerrarModal()
        }

        const eliminarCentro=()=>{
            let centros=this.state.centros.filter(centro => centro.id !== this.state.centroSeleccionado.id)
            this.setState({centros:centros})
            cerrarModal()
            this.setState({controlesCentrosDesactivados:true})
            this.setState({pendienteBorrarCentro:true})
            this.setState({cargandoCentros:false})

        }

        const eliminarSucursal=()=>{
            let sucursales=this.state.sucursales.filter(sucursal => sucursal.id !== this.state.sucursalSeleccionada.id)
            this.setState({
                sucursales:sucursales,
                controlesSucursalesDesactivados:true,
                pendienteBorrarSucursal:true
            })
            cerrarModal()
        }

        const handleCambiarPaisCentro = (value) => {

            this.setState({
                paisSeleccionadoCentro:value,
                provinciaSeleccionadaCentro:null,
                localidadSeleccionadaCentro:null
            })

            this.props.client
            .query({
                query: gql`
                {
                    provincias(FILTROS:{ID_PAIS:${value.value}}){ID_PROVINCIA,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.provincias.forEach((provincia,index)=>{
                    result.data.provincias[index]={"label":provincia.DESCRIPCION,"value":provincia.ID_PROVINCIA}
                })
                this.setState({provinciasCentros:result.data.provincias})
            })
        }

        const handleCambiarNuevoPais=(ev)=>{
            this.setState({paisNuevo:ev.currentTarget.value})
        }

        const handleCambiarNuevoProvincia=(ev)=>{
            this.setState({provinciaNueva:ev.currentTarget.value})
        }

        const handleCambiarNuevaLocalidad=(ev)=>{
            this.setState({localidadNueva:ev.currentTarget.value})
        }

        const handleCambiarPaisSucursal = (value) => {
            
            //ademas de guardar en el estado el pais seleccionado,
            //limpiamos el select de provicias y de localidades
            this.setState({
                paisSeleccionadoSucursal:value,
                provinciaSeleccionadaSucursal:null,
                localidadSeleccionadaSucursal:null,
            })

            //llenamos el select de provincias con el nuevo pais seleccionado
            let provincias=[]
            this.props.client
            .query({
                query: gql`
                {
                    provincias(FILTROS:{ID_PAIS:${value.value}}){ID_PROVINCIA,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.provincias.forEach((provincia,index)=>{
                    provincias.push({"label":provincia.DESCRIPCION,"value":provincia.ID_PROVINCIA})
                })

                this.setState({provinciasSucursal:provincias})
            })
        }

        const handleCambiarProvinciaCentro = (value) => {
            this.setState({provinciaSeleccionadaCentro:value})

            //limpiamos las localidades viejas.
            this.setState({localidadSeleccionadaCentro:null})

            this.props.client
            .query({
                query: gql`
                {
                    localidades(FILTROS:{ID_PROVINCIA:${value.value}}){ID_LOCALIDAD,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.localidades.forEach((localidad,index)=>{
                    result.data.localidades[index]={"label":localidad.DESCRIPCION,"value":localidad.ID_LOCALIDAD}
                })
                this.setState({localidadesCentros:result.data.localidades})    
            })
        }

        const handleCambiarProvinciaSucursal = (value) => {

            this.setState({provinciaSeleccionadaSucursal:value})

             //limpiamos las localidades viejas.
             this.setState({localidadSeleccionadaSucursal:null})

            this.props.client
            .query({
                query: gql`
                {
                    localidades(FILTROS:{ID_PROVINCIA:${value.value}}){ID_LOCALIDAD,DESCRIPCION}
                }
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                result.data.localidades.forEach((localidad,index)=>{
                    result.data.localidades[index]={"label":localidad.DESCRIPCION,"value":localidad.ID_LOCALIDAD}
                })
                ////console.log(result.data.localidades)
                this.setState({localidadesSucursal:result.data.localidades})
    
            })
        }

        const handleCambiarLocalidadCentro = (value) => {
            this.setState({localidadSeleccionadaCentro:value})
        }

        const handleCambiarLocalidadSucursal = (value) => {
            this.setState({localidadSeleccionadaSucursal:value})
        }

        const handleCambiarViaCentro = (ev) => {
            this.setState({viaCentro:ev.currentTarget.value})
        }

        const handleCambiarViaSucursal = (ev) => {
            this.setState({viaSucursal:ev.currentTarget.value})
        }

        const handleCambiarKmCentro = (ev) => {
            this.setState({kmCentro:ev.currentTarget.value})
        }

        const handleCambiarKmSucursal = (ev) => {
            this.setState({kmSucursal:ev.currentTarget.value})
        }

        const handleCambiarCpCentro = (ev) => {
            ////console.log(ev.currentTarget.value)
            this.setState({cpCentro:ev.currentTarget.value})
            ////console.log(ev.currentTarget.value)
        }

        const handleCambiarCpSucursal = (ev) => {
            this.setState({cpSucursal:ev.currentTarget.value})
            ////console.log(ev.currentTarget.value)
        }

        const handleCambiarRestoCentro = (ev) => {
            this.setState({restoCentro:ev.currentTarget.value})
        }

        const handleCambiarRestoSucursal = (ev) => {
            this.setState({restoSucursal:ev.currentTarget.value})
        }

        const handleCambiarNombreCentro = (ev) => {
            this.setState({nombreCentro:ev.currentTarget.value})
        }

        const handleCambiarNombreSucursal = (ev) => {
            this.setState({nombreSucursal:ev.currentTarget.value})
        }

        const handleCambiarTipoViaCentro = (value) => {
            this.setState({tipoViaSeleccionadaCentro:value})
        }

        const handleCambiarTipoViaSucursal= (value) => {
            this.setState({tipoViaSeleccionadaSucursal:value})
        }

        const anadirPais=()=>{

                //si no se escribe nada.
                if(!this.state.paisNuevo)return

                
                let existe=this.state.paises.find(pais=>{return pais.label===this.state.paisNuevo})
                if(existe){
                    alert(i18next.t("yaexiste",{ns:'global'}))
                    return
                }                
    
                //insertamos el pais.
                this.props.client.mutate({
                mutation: gql`
                  mutation{crearPais(VALORES:{DESCRIPCION:"${this.state.paisNuevo}"}){ID_PAIS}}
                `
                }).then(async result=>{
        
                    await this.cargarPaises()

                    if(this.state.centroSeleccionado || this.state.modalCentroAbierto){
                        let paisSel=this.state.paises.find(pais=>{
                            return pais.value===result.data.crearPais.ID_PAIS
                        })
                        
                        this.setState({paisSeleccionadoCentro:paisSel})
                        handleCambiarPaisCentro(paisSel)
                    }
                    if(this.state.sucursalSeleccionada || this.state.modalSucursalAbierto ){
                        let paisSel=this.state.paises.find(pais=>{
                            return pais.value===result.data.crearPais.ID_PAIS
                        })
                        
                        this.setState({paisSeleccionadoSucursal:paisSel})
                        handleCambiarPaisSucursal(paisSel)
                    }

                }).catch(err=>{
                    alert(i18next.t("error",{ns:'global'}))
                })
                this.setState({modalPais:false})            
        }

        const anadirProvincia=()=>{

            
            //si no se escribe nada.
            if(!this.state.provinciaNueva)return

            let pais=(this.state.tipoProvincia==="centro"?this.state.paisSeleccionadoCentro.value:this.state.paisSeleccionadoSucursal.value)

            //insertamos el grupo empresarial.
            this.props.client.mutate({
            mutation: gql`
                mutation{crearProvincia(VALORES:{ID_PAIS:${pais},DESCRIPCION:"${this.state.provinciaNueva}"}){ID_PROVINCIA}}
            `
            }).then(async result=>{

                await this.cargarProvincias(pais,this.state.tipoProvincia)
                if(this.state.tipoProvincia==="centro"){
                    let provinciaSel=this.state.provinciasCentros.find(provincia=>{
                        return provincia.value===result.data.crearProvincia.ID_PROVINCIA
                    })                

                    this.setState({provinciaSeleccionadaCentro:provinciaSel})
                    handleCambiarProvinciaCentro(provinciaSel)

                }else{

                    let provinciaSel=this.state.provinciasSucursal.find(provincia=>{
                        return provincia.value===result.data.crearProvincia.ID_PROVINCIA
                    })                       

                    this.setState({provinciaSeleccionadaSucursal:provinciaSel})
                    handleCambiarProvinciaSucursal(provinciaSel)
                    
                }

            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

            this.setState({modalProvincia:false})
        
    }

    const anadirLocalidad=()=>{

        //si no se escribe nada.
        if(!this.state.localidadNueva)return

        let provincia=(this.state.tipoLocalidad==="centro"?this.state.provinciaSeleccionadaCentro.value:this.state.provinciaSeleccionadaSucursal.value)

        //insertamos el grupo empresarial.
        this.props.client.mutate({
        mutation: gql`
            mutation{crearLocalidad(VALORES:{ID_PROVINCIA:${provincia},DESCRIPCION:"${this.state.localidadNueva}"}){ID_LOCALIDAD}}
        `
        }).then(async result=>{

            await this.cargarLocalidades(provincia,this.state.tipoLocalidad)

            if(this.state.tipoLocalidad==="centro"){
                let localidadSel=this.state.localidadesCentros.find(localidad=>{
                    return localidad.value===result.data.crearLocalidad.ID_LOCALIDAD
                })
                
                this.setState({localidadSeleccionadaCentro:localidadSel})
                handleCambiarLocalidadCentro(localidadSel)
            }else{
                let localidadSel=this.state.localidadesSucursal.find(localidad=>{
                    return localidad.value===result.data.crearLocalidad.ID_LOCALIDAD
                })
                
                this.setState({localidadSeleccionadaSucursal:localidadSel})
                handleCambiarLocalidadCentro(localidadSel)
            }

        }).catch(err=>{
            alert(i18next.t("error",{ns:'global'}))
        })

        this.setState({modalLocalidad:false})
    }
    
    const cerrarModal = () => {
        this.setState({
            modalCentroAbierto: false,
            modalCentroBorrarAbierto: false,

            modalSucursalAbierto: false,
            modalSucursalBorrarAbierto: false,

            modalPais: false,
            
            cargandoCentros:false,
            cargandoSucursal:false
        
        })
    }

    const cerrarModal2 = () => {
        this.setState({
            modalPais:false,
            modalProvincia:false,
            modalLocalidad:false
        })
    }

    const handleChangeDomicilioSocial=()=>{
        this.setState({domicilioSocial:!this.state.domicilioSocial})
    }

    const marcarDomicilio=async()=>{

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:'global'}))
            return
        }

        let hayError=false

        if(!this.state.centroSeleccionado){
            alert(i18next.t("noseleccionado",{ns:'global'}))
            return
        }

        //quitamos la principal anterior.
        /*
        let cuentas=this.state.CCC
        
        cuentas.forEach((cuenta,index)=>{
            if(cuenta.id===this.state.cuentaSeleccionada.id){
                cuentas[index]["principal"]=true
            }else{
                cuentas[index]["principal"]=false
            }
        })
        */

        this.setState({cargandoCentros:true});

        await  this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{DOMICILIO_SOCIAL:true,ID_PERSONA:${this.props.empresaSeleccionada}}){ID_CENTRO_TRABAJO}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(async result=>{ 

            if(result.data.centrosTrabajos.length>0){

                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarCentroTrabajo(ID_CENTRO_TRABAJO:${result.data.centrosTrabajos[0].ID_CENTRO_TRABAJO},VALORES:{DOMICILIO_SOCIAL:false}){ID_CENTRO_TRABAJO}}
                    `
                }).catch(err=>{
                    hayError=true
                    alert(i18next.t("error",{ns:'global'}))
                })
            }

            if(hayError)return

            //actualizamos la nueva
            await this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarCentroTrabajo(ID_CENTRO_TRABAJO:${this.state.centroSeleccionado.id},VALORES:{DOMICILIO_SOCIAL:true}){ID_CENTRO_TRABAJO}}
                `
            }).catch(err=>{
                alert(i18next.t("error",{ns:'global'}))
            })

        })

        await this.recargarCentros()

    }

    const handleOnSelectCentro=(row, isSelect) => {
        if (isSelect) {
            let centroSeleccionado=row;
            this.setState({
            },async()=>{
                await this.props.client
                .query({
                    query: gql`
                    {
                        direcciones(FILTROS:{ID_DIRECCION:${centroSeleccionado.idDireccion}}){ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}
                    }
                    `,fetchPolicy:'network-only'
                }).then(result=>{ 

                    centroSeleccionado["datosdireccion"]=result.data.direcciones[0]
                    this.setState({
                        centroSeleccionado:centroSeleccionado
                    });
                
                });
            });
            
        }
        //console.log(row);
        return true; // return true or dont return to approve current select action
    }

    const handleOnSelectSucursal=(row, isSelect) => {
        if (isSelect) {
            let sucursalSeleccionada=row;
            this.setState({
                
            },async ()=>{
                await this.props.client
                .query({
                    query: gql`
                    {
                        direcciones(FILTROS:{ID_DIRECCION:${sucursalSeleccionada.idDireccion}}){ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}
                    }
                    `,fetchPolicy:'network-only'
                }).then(result=>{ 

                    sucursalSeleccionada["datosdireccion"]=result.data.direcciones[0]
                    this.setState({
                        sucursalSeleccionada:sucursalSeleccionada
                    });
                });
            });
            
        
        }
        //console.log(row);
        return true; // return true or dont return to approve current select action
    }

    const nameCentro=(cell,row)=>{
        let tipo='';
        if (row.domicilioSocial) {
            tipo="Principal: ";
        }
        return (
            <>
                <strong>{tipo}</strong><span>{cell}</span>
            </>
        );
    }

    const columnsCentros = [
        {
            dataField: 'nombre',
            text: i18next.t("empresa.tabcentrostrabajo.name",{ns:'company'}),
            sort: true,
            formatter: nameCentro
        },
        {
            dataField: 'direccion',
            text: i18next.t("empresa.tabcentrostrabajo.address",{ns:'company'}),
            sort: true
        }
    ];

    const columnsSucursales = [
        {
            dataField: 'nombre',
            text: i18next.t("empresa.tabcentrostrabajo.name",{ns:'company'}),
            sort: true
        },
        {
            dataField: 'direccion',
            text: i18next.t("empresa.tabcentrostrabajo.address",{ns:'company'}),
            sort: true
        }
    ];

    return (
        <>
        {this.state.cargando === true?
            <Cargando />
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoCentroActivado?i18next.t("empresa.tabcentrostrabajo.titleaddcentrotrabajo",{ns:'company'}):i18next.t("empresa.tabcentrostrabajo.titleeditcentrotrabajo",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    isOpen={this.state.modalCentroAbierto}
                    className="dialog-ancho pixelAlrededor"
                >
                    <div>
                    <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.namecenter",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nombreCentro?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.namecenter",{ns:'company'})}
                            >
                                    <InputGroup
                                        name="nombrecentro"
                                        placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.namecenter",{ns:'company'})}
                                        onChange={handleCambiarNombreCentro}
                                        intent={this.state.nombreCentro?"primary":"danger"}
                                        defaultValue={this.state.centroSeleccionado?this.state.centroSeleccionado.nombre:""}
                                    />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.tracktype",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.tipoViaSeleccionadaCentro?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.tracktype",{ns:'company'})}
                            >
                                <div style={this.state.tipoViaSeleccionadaCentro?{}:{border:"1px solid red"}}>
                                    <Select 
                                        name="tipo_via"
                                        placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.tracktype",{ns:'company'})}
                                        options={this.state.tiposVia}
                                        onChange={handleCambiarTipoViaCentro}
                                        defaultValue={this.state.tiposVia.filter(tipo => tipo.value === (this.state.centroSeleccionado?this.state.centroSeleccionado.datosdireccion.TIPO_VIA.ID_TIPO_VIA:0))}
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.nametrack",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.viaCentro?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.nametrack",{ns:'company'})}
                            >
                                <InputGroup
                                    name="nombre_via"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.nametrack",{ns:'company'})}
                                    onChange={handleCambiarViaCentro}
                                    intent={this.state.viaCentro?"primary":"danger"}
                                    defaultValue={this.state.centroSeleccionado?this.state.centroSeleccionado.datosdireccion.NOMBRE_VIA:""}
                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.km",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.kmCentro?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.km",{ns:'company'})}
                            >
                                <InputGroup
                                    name="number_km"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.km",{ns:'company'})}
                                    onChange={handleCambiarKmCentro}
                                    intent={this.state.kmCentro?"primary":"danger"}
                                    defaultValue={this.state.centroSeleccionado?this.state.centroSeleccionado.datosdireccion.NUMERO_KM:""}
                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.postalcode",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.cpInvalido?i18next.t("firstnotvalid",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.postalcode",{ns:'company'})+i18next.t("secondnotvalid",{ns:'global'}):""}
                            >
                                <InputGroup
                                    name="codigo_postal"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.postalcode",{ns:'company'})}
                                    maxLength={5}
                                    intent={this.state.cpInvalido?"danger":"primary"}
                                    onChange={handleCambiarCpCentro}
                                    defaultValue={this.state.centroSeleccionado?this.state.centroSeleccionado.datosdireccion.CP:""}
                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.restaddress",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                            >
                                <InputGroup
                                    name="resto_direccion"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.restaddress",{ns:'company'})}
                                    intent="primary"
                                    onChange={handleCambiarRestoCentro}
                                    defaultValue={this.state.centroSeleccionado?this.state.centroSeleccionado.datosdireccion.RESTO_DIRECCION:""}

                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.paisSeleccionadoCentro?"":i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                            >
                                <div>
                                    <div style={this.state.paisSeleccionadoCentro?{display:"inline-block",width:"80%"}:{border:"1px solid red",width:"80%",display:"inline-block"}}>
                                        <Select 
                                            name="pais"
                                            placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                                            options={this.state.paises}
                                            onChange={handleCambiarPaisCentro}
                                            value={this.state.paises.filter(pais => pais.value === (this.state.paisSeleccionadoCentro?this.state.paisSeleccionadoCentro.value:0))}
                                        />
                                    </div>
                                    <Button 
                                        alignText="right"
                                        icon="add"
                                        className="iconoIzquierda"
                                        onClick={abrirmodalPais}
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.provinciaSeleccionadaCentro?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                            >
                            <div>
                                <div style={this.state.provinciaSeleccionadaCentro?{display:"inline-block",width:"80%"}:{border:"1px solid red",width:"80%",display:"inline-block"}}>
                                    <Select 
                                        name="provincia"
                                        placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                                        options={this.state.provinciasCentros}
                                        onChange={handleCambiarProvinciaCentro}
                                        value={this.state.provinciasCentros.filter(provincia => provincia.value === (this.state.provinciaSeleccionadaCentro?this.state.provinciaSeleccionadaCentro.value:0))}
                                    />
                                </div>
                                <Button 
                                    alignText="right"
                                    icon="add"
                                    className="iconoIzquierda"
                                    onClick={() => abrirModalProvincia("centro")}
                                    disabled={!this.state.paisSeleccionadoCentro}
                                />
                            </div>                       
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.localidadSeleccionadaCentro?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                            >
                                <div>
                                    <div style={this.state.localidadSeleccionadaCentro?{display:"inline-block",width:"80%"}:{border:"1px solid red",width:"80%",display:"inline-block"}}>
                                        <Select 
                                            name="localidad"
                                            placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                                            options={this.state.localidadesCentros}
                                            onChange={handleCambiarLocalidadCentro}
                                            value={this.state.localidadesCentros.filter(localidad => localidad.value === (this.state.localidadSeleccionadaCentro?this.state.localidadSeleccionadaCentro.value:0))}

                                        />
                                    </div>
                                    <Button 
                                        alignText="right"
                                        icon="add"
                                        className="iconoIzquierda"
                                        onClick={() => abrirModalLocalidad("centro")}
                                        disabled={!this.state.provinciaSeleccionadaCentro}
                                    />
                                </div>
                            </FormGroup>

                        <FormGroup
                                labelFor="informacion"
                                className="pixelAlrededorGrande"
                        >
                            {this.state.centros.length===0?
                                <Checkbox checked={true} disabled label={i18next.t("empresa.tabcentrostrabajo.registerdoffice")} />
                            :
                                <Checkbox checked={this.state.domicilioSocial} disabled={this.state.centroSeleccionado && this.state.centroSeleccionado.domicilioSocial?true:false} onChange={handleChangeDomicilioSocial} label={i18next.t("empresa.tabcentrostrabajo.registeredoffice",{ns:'company'})}/>
                            }
                        </FormGroup>
                    </Card>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:'global'})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoCentroActivado?anadirCentroNuevo:actualizarCentro}
                            >
                            {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>

                
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabcentrostrabajo.titledelcentro",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    isOpen={this.state.modalCentroBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("empresa.tabcentrostrabajo.questiondelcentro",{ns:'company'})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarCentro}
                            >
                                {i18next.t("yes",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoSucursalActivado?i18next.t("empresa.tabcentrostrabajo.titleaddsucursal",{ns:'company'}):i18next.t("empresa.tabcentrostrabajo.titleeditsucursal",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalSucursalAbierto}
                >
                    <div>
                    <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.namebranch",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nombreSucursal?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.namebranch",{ns:'company'})}
                            >
                                    <InputGroup
                                        name="Nombre sucursal"
                                        placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.namebranch",{ns:'company'})}
                                        onChange={handleCambiarNombreSucursal}
                                        intent={this.state.nombreSucursal?"primary":"danger"}
                                        defaultValue={this.state.sucursalSeleccionada?this.state.sucursalSeleccionada.nombre:""}
                                    />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.tracktype",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.tipoViaSeleccionadaSucursal?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.tracktype",{ns:'company'})}
                            >
                                <div style={this.state.tipoViaSeleccionadaSucursal?{}:{border:"1px solid red"}}>
                                    <Select 
                                        name="tipo-via"
                                        placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.tracktype",{ns:'company'})}
                                        options={this.state.tiposVia}
                                        onChange={handleCambiarTipoViaSucursal}
                                        defaultValue={this.state.tiposVia.filter(tipo => tipo.value === (this.state.sucursalSeleccionada?this.state.sucursalSeleccionada.datosdireccion.TIPO_VIA.ID_TIPO_VIA:0))}
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.nametrack",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.viaSucursal?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.nametrack",{ns:'company'})}
                            >
                                <InputGroup
                                    name="nombrevia"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.nametrack",{ns:'company'})}
                                    onChange={handleCambiarViaSucursal}
                                    intent={this.state.viaSucursal?"primary":"danger"}
                                    defaultValue={this.state.sucursalSeleccionada?this.state.sucursalSeleccionada.datosdireccion.NOMBRE_VIA:""}
                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.km",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.kmSucursal?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.km",{ns:'company'})}
                            >
                                <InputGroup
                                    name="km"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.km",{ns:'company'})}
                                    onChange={handleCambiarKmSucursal}
                                    intent={this.state.kmSucursal?"primary":"danger"}
                                    defaultValue={this.state.sucursalSeleccionada?this.state.sucursalSeleccionada.datosdireccion.NUMERO_KM:""}
                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.postalcode",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.cpSucursalInvalido?i18next.t("firstnotvalid",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.postalcode",{ns:'company'})+i18next.t("secondnotvalid",{ns:'global'}):""}
                            >
                                <InputGroup
                                    name="codigopostal"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.postalcode",{ns:'company'})}
                                    maxLength={5}
                                    intent={this.state.cpSucursalInvalido?"danger":"primary"}
                                    onChange={handleCambiarCpSucursal}
                                    defaultValue={this.state.sucursalSeleccionada?this.state.sucursalSeleccionada.datosdireccion.CP:""}
                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.restaddress",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                            >
                                <InputGroup
                                    name="restodireccion"
                                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.restaddress",{ns:'company'})}
                                    intent="primary"
                                    onChange={handleCambiarRestoSucursal}
                                    defaultValue={this.state.sucursalSeleccionada?this.state.sucursalSeleccionada.datosdireccion.RESTO_DIRECCION:""}

                                />
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.paisSeleccionadoSucursal?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                            >
                                <div>
                                    <div style={this.state.paisSeleccionadoSucursal?{}:{border:"1px solid red",width:"80%",display:"inline-block"}}>
                                        <Select 
                                            name="pais"
                                            placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                                            options={this.state.paises}
                                            onChange={handleCambiarPaisSucursal}
                                            value={this.state.paises.filter(pais => pais.value === (this.state.paisSeleccionadoSucursal?this.state.paisSeleccionadoSucursal.value:0))}
                                        />
                                    </div>
                                    <Button 
                                        alignText="right"
                                        icon="add"
                                        className="iconoIzquierda"
                                        onClick={abrirmodalPais}
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.provinciaSeleccionadaSucursal?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                            >
                            <div>
                                <div style={this.state.provinciaSeleccionadaSucursal?{}:{border:"1px solid red",width:"80%",display:"inline-block"}}>
                                    <Select 
                                        name="provincia"
                                        placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                                        options={this.state.provinciasSucursal}
                                        onChange={handleCambiarProvinciaSucursal}
                                        value={this.state.provinciasSucursal.filter(provincia => provincia.value === (this.state.provinciaSeleccionadaSucursal?this.state.provinciaSeleccionadaSucursal.value:0))}
                                    />
                                </div>
                                <Button 
                                    alignText="right"
                                    icon="add"
                                    className="iconoIzquierda"
                                    onClick={() => abrirModalProvincia("sucursal")}
                                    disabled={!this.state.paisSeleccionadoSucursal}
                                />
                            </div>                       
                            </FormGroup>

                            <FormGroup
                                label={i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.localidadSeleccionadaSucursal?"":i18next.t("selectempty",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                            >
                                <div>
                                    <div style={this.state.localidadSeleccionadaSucursal?{}:{border:"1px solid red",width:"80%",display:"inline-block"}}>
                                        <Select 
                                            name="localidad"
                                            placeholder={i18next.t("selectfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                                            options={this.state.localidadesSucursal}
                                            onChange={handleCambiarLocalidadSucursal}
                                            value={this.state.localidadesSucursal.filter(localidad => localidad.value === (this.state.localidadSeleccionadaSucursal?this.state.localidadSeleccionadaSucursal.value:0))}

                                        />
                                    </div>
                                    <Button 
                                        alignText="right"
                                        icon="add"
                                        className="iconoIzquierda"
                                        onClick={() =>abrirModalLocalidad("sucursal")}
                                        disabled={!this.state.provinciaSeleccionadaSucursal}
                                    />
                                </div>
                            </FormGroup>
                        </Card>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:'global'})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoSucursalActivado?anadirSucursalNuevo:actualizarSucursal}
                            >
                            {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabcentrostrabajo.titledelsucursal",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    isOpen={this.state.modalSucursalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("empresa.tabcentrostrabajo.questiondelsucursal",{ns:'company'})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarSucursal}
                            >
                                {i18next.t("yes",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>

            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={i18next.t("empresa.tabcentrostrabajo.titleaddcountry",{ns:'company'})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                isOpen={this.state.modalPais}
                usePortal={true}
                className="zIndexAlto"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})}
                    labelFor="informacion"
                    >
                        <InputGroup id="texto-nombre-grupo" onChange={handleCambiarNuevoPais} placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.country",{ns:'company'})} intent="primary"/>
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirPais}
                            >
                                {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("aceptar",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("empresa.tabcentrostrabajo.titleaddprovince",{ns:'company'})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    isOpen={this.state.modalProvincia}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})}
                        labelFor="informacion"
                        >
                            <InputGroup id="texto-nombre-provincia" onChange={handleCambiarNuevoProvincia} placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.province",{ns:'company'})} intent="primary"/>
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirProvincia}
                            >
                                {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("cancelar",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={i18next.t("empresa.tabcentrostrabajo.titleaddlocation",{ns:'company'})}  
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                isOpen={this.state.modalLocalidad}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})}
                    labelFor="informacion"
                    >
                        <InputGroup id="texto-nombre-localidad" onChange={handleCambiarNuevaLocalidad} placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabcentrostrabajo.location",{ns:'company'})} intent="primary"/>
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirLocalidad}
                            >
                                {i18next.t("aceptar",{ns:'global'})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("cancelar",{ns:'global'})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>

            <div className="tablaMitadIzquierda pixelAlrededor">
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande">
                <Navbar className="navbar-mitad">
                <Navbar.Group>
                    <NavbarHeading>{i18next.t("empresa.tabcentrostrabajo.centrostrabajo",{ns:'company'})}</NavbarHeading>
                    <ButtonGroup align={Alignment.LEFT}>
                        {this.state.controlesCentrosDesactivados?[
                            <React.Fragment key="fragment">
                                <Tooltip content={i18next.t("new",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="add" active={this.state.nuevoCentroActivado} disabled />
                                </Tooltip>
                                <Tooltip content={i18next.t("edit",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="edit" active={this.state.editarCentroActivado} disabled/>
                                </Tooltip>
                                <Tooltip content={i18next.t("delete",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="delete" active={this.state.borrarCentroActivado}  disabled/>
                                </Tooltip>
                                <Tooltip content={i18next.t("favorite",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="home" disabled />
                                </Tooltip>
                            </React.Fragment>
                        ]:[
                            <React.Fragment key="fragment">
                                <Tooltip content={i18next.t("new",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="add"active={this.state.nuevoCentroActivado} onClick={abrirModalCentroNuevo} />
                                </Tooltip>
                                <Tooltip content={i18next.t("edit",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="edit" active={this.state.editarCentroActivado} onClick={abrirModalCentroEditar} />
                                </Tooltip>
                                <Tooltip content={i18next.t("delete",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="delete" active={this.state.borrarCentroActivado} disabled={this.state.centroSeleccionado?this.state.centroSeleccionado.domicilioSocial:false}  onClick={abrirModalCentroBorrar}/>
                                </Tooltip>
                                <Tooltip content={i18next.t("favorite",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="home" disabled={this.state.centroSeleccionado?this.state.centroSeleccionado.domicilioSocial:false} onClick={marcarDomicilio} />
                                </Tooltip>
                            </React.Fragment>
                        ]}
                        </ButtonGroup>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ButtonGroup align={Alignment.RIGHT}>
                        <Button 
                            intent="danger"
                            alignText="right"
                            icon="floppy-disk"
                            onClick={this.guardarCentro}
                            text={i18next.t("guardar",{ns:'global'})}
                            className="bp3-intent-primary iconoDerecha bp3-minimal"
                            disabled={!this.state.controlesCentrosDesactivados}
                        />
                        <Button 
                            intent="danger"
                            alignText="right"
                            icon="cross"
                            text={i18next.t("cancelar",{ns:'global'})}
                            onClick={cancelarCentro}
                            className="bp3-intent-primary iconoDerecha bp3-minimal"
                            disabled={!this.state.controlesCentrosDesactivados}
                        />
                    </ButtonGroup>
                </Navbar.Group>
                </Navbar>
                {this.state.cargandoCentros?
                    <Cargando />
                :
                    <BootstrapTable
                        keyField='id'
                        data={ this.state.centros }
                        columns={ columnsCentros }
                        selectRow={ const_table.selectRow(handleOnSelectCentro) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
                    />
                }
            </Card>
            </div>
            <div className="tablaMitadDerecha pixelAlrededor">
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande">
                <Navbar className="navbar-mitad">
                <Navbar.Group>
                    <NavbarHeading>{i18next.t("empresa.tabcentrostrabajo.sucursales",{ns:'company'})}</NavbarHeading>
                    <ButtonGroup align={Alignment.LEFT}>
                        {this.state.controlesSucursalesDesactivados?[
                            <React.Fragment key="fragment">
                                <Tooltip content={i18next.t("new",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="add" active={this.state.nuevoSucursalActivado} disabled />
                                </Tooltip>
                                <Tooltip content={i18next.t("edit",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarSucursalActivado} disabled />
                                </Tooltip>
                                <Tooltip content={i18next.t("delete",{ns:'global'})}>
                                    <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarSucursalActivado} disabled/>
                                </Tooltip>
                            </React.Fragment>
                        ]:[
                            <React.Fragment key="fragment">
                            <Tooltip content={i18next.t("new",{ns:'global'})}>
                                <Button className="bp3-minimal" icon="add" active={this.state.nuevoSucursalActivado} onClick={abrirModalSucursalNuevo} />
                            </Tooltip>
                            <Tooltip content={i18next.t("edit",{ns:'global'})}>
                                <Button className="bp3-minimal" icon="edit" active={this.state.editarSucursalActivado} onClick={abrirModalSucursalEditar} />
                            </Tooltip>
                            <Tooltip content={i18next.t("delete",{ns:'global'})}>
                                <Button className="bp3-minimal" icon="delete" active={this.state.borrarSucursalActivado} onClick={abrirModalSucursalBorrar}/>
                            </Tooltip>
                            </React.Fragment>
                        ]}

                    </ButtonGroup>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ButtonGroup>
                    <Button 
                        intent="danger"
                        alignText="right"
                        icon="floppy-disk"
                        onClick={this.guardarSucursal}
                        text={i18next.t("guardar",{ns:'global'})}
                        className="bp3-intent-primary bp3-minimal iconoDerecha"
                        disabled={!this.state.controlesSucursalesDesactivados}
                    />
                    <Button 
                        intent="danger"
                        alignText="right"
                        icon="cross"
                        text={i18next.t("cancelar",{ns:'global'})}
                        onClick={cancelarSucursal}
                        className="bp3-intent-primary bp3-minimal iconoDerecha"
                        disabled={!this.state.controlesSucursalesDesactivados}
                    />
                    </ButtonGroup>    
                </Navbar.Group>
                </Navbar>
                {this.state.cargandoSucursal?
                    <Cargando />
                :
                    <BootstrapTable
                        keyField='id'
                        data={ this.state.sucursales }
                        columns={ columnsSucursales }
                        selectRow={ const_table.selectRow(handleOnSelectSucursal) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
                    />
                }
                </Card>
            </div>
        </>
        }
        </>

        )
    }
}
export default withTranslation()(withApollo(CentrosTrabajo))