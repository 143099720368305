import React from 'react';
import i18next from 'i18next';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {i18next.t("mostrando", {ns: 'global'})} { from } {i18next.t("a", {ns: 'global'})} { to } {i18next.t("de", {ns: 'global'})} { size } {i18next.t("resultados", {ns: 'global'})}
    </span>
);

const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: i18next.t("firstPageText", {ns: 'global'}),
    prePageText: i18next.t("prePageText", {ns: 'global'}),
    nextPageText: i18next.t("nextPageText", {ns: 'global'}),
    lastPageText: i18next.t("lastPageText", {ns: 'global'}),
    nextPageTitle: i18next.t("nextPageTitle", {ns: 'global'}),
    prePageTitle: i18next.t("prePageTitle", {ns: 'global'}),
    firstPageTitle: i18next.t("firstPageTitle", {ns: 'global'}),
    lastPageTitle: i18next.t("lastPageTitle", {ns: 'global'}),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
        text: '10', value: 10
      }] // A numeric array is also available. the purpose of above example is custom the text
};

const selectRow=(handleOnSelect) => {
  return {
    mode: 'radio',
    clickToSelect: true,
    onSelect: handleOnSelect,
    style: { backgroundColor: '#c8e6c9' }
  }
};

const defaultSorted=(field,order) =>{
  return [{
    dataField: field,
    order: order
  }];
}

const capitalize=(str) => {
  return str.charAt(0).toUpperCase()+str.slice(1);
}


const generateOptions = (paginationSize, pageStartIndex, sizePerPageList) => {
  return {
    paginationSize: paginationSize,
    pageStartIndex: pageStartIndex,
    firstPageText: i18next.t("firstPageText", {ns: 'global'}),
    prePageText: i18next.t("prePageText", {ns: 'global'}),
    nextPageText: i18next.t("nextPageText", {ns: 'global'}),
    lastPageText: i18next.t("lastPageText", {ns: 'global'}),
    nextPageTitle: i18next.t("nextPageTitle", {ns: 'global'}),
    prePageTitle: i18next.t("prePageTitle", {ns: 'global'}),
    firstPageTitle: i18next.t("firstPageTitle", {ns: 'global'}),
    lastPageTitle: i18next.t("lastPageTitle", {ns: 'global'}),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: String(sizePerPageList), value: sizePerPageList
    }] // A numeric array is also available. the purpose of above example is custom the text
  };
};

export default {options,selectRow,defaultSorted,capitalize, generateOptions};