import React from 'react';
import { Card, Elevation, Callout } from "@blueprintjs/core"
import Memorias from "../Pantallas/Elementos/Memorias"
import Informes from "../Pantallas/Elementos/Informes"
import PlanesIgualdad from "../Pantallas/Elementos/PlanesIgualdad"
import PlanesMovilidad from "../Pantallas/Elementos/PlanesMovilidad"
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class RSC extends React.Component {

    render() {
        return (
            <>
                <div className="tablaMitadIzquierda cardDocumentosHorizontal">
                    <Card interactive={true} className="pixelAlrededorGrande" elevation={Elevation.TWO} id="memorias">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.memorias.title",{ns:"company"})}</Callout>
                        <Memorias editable={this.props.editable}/>
                    </Card>
                    <br />
                    <Card interactive={true} className="pixelAlrededorGrande" elevation={Elevation.TWO} id="planes-movilidad">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.planesmovilidad.title",{ns:"company"})}</Callout>
                        <PlanesMovilidad editable={this.props.editable}/>
                    </Card>
                </div>
                <div className="tablaMitadDerecha cardDocumentosHorizontal">
                    <Card interactive={true} className="pixelAlrededorGrande" elevation={Elevation.TWO} id="informes">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.informes.title",{ns:"company"})}</Callout>
                        <Informes editable={this.props.editable}/>
                    </Card>
                    <br />
                    <Card interactive={true} className="pixelAlrededorGrande" elevation={Elevation.TWO} id="planes-igualdad">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabdocumentos.planesigualdad.title",{ns:"company"})}</Callout>
                        <PlanesIgualdad editable={this.props.editable}/>
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(RSC);