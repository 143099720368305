/* eslint-disable array-callback-return */
import React from "react"
import { Spinner,Navbar, ButtonGroup,FormGroup,Button, Alignment, Dialog, Classes, Tooltip, AnchorButton, Intent, Card, Elevation } from "@blueprintjs/core"
import { Table, Cell, Column,RegionCardinality } from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import moment from "moment"
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});

export default  class ComiteCompliance extends React.Component {

    state={
        comiteCompliance: [
        ],
        empleados:[],
        modalNuevoAbierto: false,
        modalBorrarAbierto: false,
        personaSeleccionada:null,
        eliminados:[],
        miembrosAdmon:[],
        nuevoActivado:true,
        borrarActivado:false,
        regionSeleccionada:null,
        datosEmpresa:null,
        cargandoCC:false,
        cargandoImprimirCC:false,
        modalImprimir:false,
        imprimirActivado:false,
        columnWidths: [100,100],
        resized: false

    }

    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaComites.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaComites.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaComites.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaComites.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

    limpiarLista=()=>{
        this.tablaComites.clearSelection()
        this.setState({comiteCompliance:[]})
    }

    limpiarEliminados=()=>{

        this.setState({eliminados:[]})
    }

    reiniciarEstado=()=>{
        this.setState({
            personaSeleccionada:null,
            miembroSeleccionado:null,
            eliminados:[]
        })
        this.props.setComiteCompliance([])
        this.props.setComiteComplianceEliminados([])
        this.cargarComite()
    }

    cargarDatosEmpleado= async () => {
        //cargamos lo empleados
        let empleados = await client
        .query({
           query: gql`
               {empleados{ID_EMPLEADO}}
             `,
             fetchPolicy:'network-only'
          }).then(async result=>{
              return result.data.empleados
          })
          let puestos= await client
          .query({
              query:gql`
                    {empleadosPuestos{ID_EMPLEADO,PUESTO_TRABAJO{DESCRIPCION}}}
              `,
              fetchPolicy:'network-only'
          }).then(async result=>{
              return result.data.empleadosPuestos
          })

          let personasFisicas= await client
          .query({
            query: gql`
            {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,fetchPolicy:'network-only'
            })
            .then(result =>{
                return result.data.personasFisicas
            })
            let miembros= await client
            .query({
            query: gql`
                {miembrosOrganizacion{ID_PERSONA}}`,fetchPolicy:'network-only'
            }).then(async result =>{
                return result.data.miembrosOrganizacion
            })        

            let comite=[]
            for(let empleado of empleados){
                empleado.value=empleado.ID_EMPLEADO
                puestos.find(puesto=>{
                    if(puesto.ID_EMPLEADO===empleado.ID_EMPLEADO){
                        empleado.puesto= puesto.PUESTO_TRABAJO.DESCRIPCION
                    }                   
                })
                personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===empleado.ID_EMPLEADO){
                        empleado.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        empleado.label= empleado.nombre
                    }
                })
                miembros.find(miembro=>{
                    if(miembro.ID_PERSONA===empleado.ID_EMPLEADO){
                        comite.push({
                            "value":empleado.value,
                            "label":empleado.label,
                            "nombre":empleado.nombre,
                            "puesto":empleado.puesto
                        })
                    }
                })
            }
            this.setState({
                comiteCompliance:comite,
                empleados:empleados
            })
            //console.log(this.state.comiteCompliance)
            //console.log(this.state.empleados)

    }
//     obtenerDatosEmpleados=async(empleado,comite)=>{
        
//         //obtencion del puesto de trabajo del empleado.
//         await client
//         .query({
//         query: gql`
//             {empleadosPuestos(FILTROS:{ID_EMPLEADO:${empleado.ID_EMPLEADO}}){PUESTO_TRABAJO{DESCRIPCION}}}
//         `,fetchPolicy:'network-only'
//         })
//         .then(result =>{

//             if(result.data.empleadosPuestos.length>0){
//                 empleado.puesto=result.data.empleadosPuestos[0].PUESTO_TRABAJO.DESCRIPCION
//             }

//         })

//         // los empleados son personas físicas, asi que buscaremos su nombre en esta tabla.  
//         await client
//         .query({
//         query: gql`
//             {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
//         `,fetchPolicy:'network-only'
//         })
//         .then(result =>{
//             if(result.data.personasFisicas.length>0){
//                 let persona=result.data.personasFisicas[0]
//                 empleado.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
//                 empleado.label=empleado.nombre
//                 empleado.value=empleado.ID_EMPLEADO
//             }
//         });

       
//        await client
//         .query({
//         query: gql`
//             {miembrosOrganizacion(FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){ID_PERSONA}}`,fetchPolicy:'network-only'
//         })
//         .then(async result =>{
//             if(result.data.miembrosOrganizacion.length>0){

//                comite.push({
//                    "value":empleado.value,
//                    "label":empleado.label,
//                    "nombre":empleado.nombre,
//                    "puesto":empleado.puesto
//                })
//                console.log(comite)

//             }
//         })

//    }

    // cellRendererNombre = (rowIndex) => {
    //     return <Cell>{this.state.comiteCompliance[rowIndex].nombre}</Cell>
    // }

    // cellRendererPuesto = (rowIndex) => {
    //     return <Cell>{this.state.comiteCompliance[rowIndex].puesto}</Cell>
    // }

    cargarOrganosAdmon=async()=>{

        // let comite=[]
        // let empleados=await client
        // .query({
        //   query: gql`
        //     {empleados{ID_EMPLEADO}}
        //   `,fetchPolicy:'network-only'
        // })
        // .then(result =>{
        //     return result.data.empleados
        // });


        // //por cada empleado
        // for(let empleado of empleados){     
        //     await this.obtenerDatosEmpleados(empleado,comite)
        // }

        // this.setState({
        //     comiteCompliance:comite,
        //     empleados:empleados
        // })
        // console.log(this.state.comiteCompliance)
        // console.log(this.state.empleados)

        //buscamos a los miembros del organo
        let miembrosAdmon=await client.query({
            query:gql`{organosAdministracionMiembros{
                ID_PERSONA
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.organosAdministracionMiembros
        })
        this.setState({miembrosAdmon:miembrosAdmon})


    }

    obtenerLogoEmpresa=async()=>{
       //obtencion logo de la empresa
        await client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarEmpresa= async() => {
        
        //DATOS JURIDICOS
        let datosEmpresa= await client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

       return datosEmpresa
   
   }


    // comiteInvisible =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.tablaComitesAImprimir = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <Card interactive={true} elevation={Elevation.TWO}>
    //                     <Table  ref={(input) => { this.tablaComites= input; }} selectionModes="NONE"  defaultColumnWidth={330} numRows={this.state.comiteCompliance.length} className="tableAImprimir-pdf">
    //                         <Column name="Nombre"  cellRenderer={this.cellRendererNombre}/>   
    //                         <Column name="Puesto de trabajo" cellRenderer={this.cellRendererPuesto}/>   
    //                     </Table>
    //                 </Card>
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    componentDidMount(props){
        this.setState({
            cargandoCC:true
        }, async () =>{
            await this.cargarDatosEmpleado()       
            await this.cargarOrganosAdmon()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoCC:false})
        })       
   }
   generarImprimirCC= async() => {
       this.setState({
           cargandoImprimirCC:true,
           modalImprimirCC:true
       }, async () => {
            this.setState({cargandoImprimirCC:false}, () => {
                ReactDOM.render(this.ImprimirCC(), document.getElementById('generarCC-pdf'))
            })
       })
   }

   cargarDatos=async()=> {
           let datosEmpresa= await this.cargarEmpresa()
           this.setState({
               datosEmpresa:datosEmpresa
           })
      
   }

   ImprimirCC=()=>(
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>EMPRESA:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>INFORME:</Text>
                <Text style={styles.text}>ÓRGANO COMPLIANCE</Text>
            </View>
            <View style={styles.rowTitleOne}>
                <Text style={styles.labelTitle}>COMITÉ DE COMPLIANCE</Text>
            </View>
                {this.state.comiteCompliance?this.state.comiteCompliance.map((comite, index)=>{
                    return(
                        <View key={index}>
                           {index ===0 && (
                               <View style={styles.colHead}>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>NOMBRE</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>PUESTO DE TRABJO</Text>                                                                
                         </View>
                           )}
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{comite.nombre?comite.nombre:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{comite.puesto?comite.puesto:''}</Text>
                            </View>                               
                        
                    </View>
                    )
                }):<></>}      
        </View>

        <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
            fixed
          />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
        <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
      </Page>
    </Document>
  </PDFViewer>
   )

    render(){

        const cerrarModalImprimir= () =>{
            this.setState({
                modalImprimirCC:false
            })
        }

        const abrirModalNuevo = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            this.setState({
                modalNuevoAbierto: true,
                personaSeleccionada:null,
                nuevoActivado:true,
                borrarActivado:false
            })
        }

        const abrirModalBorrar = () => {
    
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.miembroSeleccionado){
                alert("Debes seleccionar una miembro")
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                borrarActivado:true
            })
        }

        const cellRendererNombre = (rowIndex) => {
            return <Cell>{this.state.comiteCompliance[rowIndex].nombre}</Cell>
        }

        const cellRendererPuesto = (rowIndex) => {
            return <Cell>{this.state.comiteCompliance[rowIndex].puesto}</Cell>
        }

        const onSeleccionarPersona=(value)=>{
            this.setState({personaSeleccionada:value})
        }

        const handleSeleccionarMiembro=(miembro)=>{

            if(miembro.length===0)return
            let sel=this.state.comiteCompliance[miembro[0].rows[0]]
            this.setState({
                miembroSeleccionado:sel,
                regionSeleccionada:miembro
            })
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalBorrarAbierto: false,
            })
        }
        

        const añadirNuevo = (value) => {

            if(!this.state.personaSeleccionada)return

            //comporbar que no este ya ne la tabla.
            let existe=this.state.comiteCompliance.find(comite=>{return comite.value===this.state.personaSeleccionada.value})
            if(existe){
                alert("La persona ya existe")
                return
            }
            /*
            existe=this.state.miembrosAdmon.find(miembro=>{return miembro.ID_PERSONA===this.state.personaSeleccionada.value})
            if(existe){
                alert("Los miembros del comité compliance no pueden formar parte del organo de administracion")
                return
            }*/


            let comite=this.state.comiteCompliance
            comite.push(this.state.personaSeleccionada)
            this.props.setComiteCompliance(comite)
            this.setState({comiteCompliance:comite})
            cerrarModal();
        }

        const eliminarMiembro = () => {

            var miembros=this.state.comiteCompliance.filter(miembro => miembro.value !== this.state.miembroSeleccionado.value)
            this.setState({comiteCompliance:miembros})
            let eliminados=this.state.eliminados
            eliminados.push(this.state.miembroSeleccionado)
            this.setState({eliminados:eliminados})
            this.setState({miembroSeleccionado:null})
            this.props.setComiteComplianceEliminados(eliminados)
            cerrarModal()

        };

        return (
            <>
                {this.state.cargandoCC===true?
                    <Spinner/>
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de Comité de Compliance"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirCC}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirCC?
                            <div style={{ width: '100%', height: '600px'}} id='generarCC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Comité de compliance"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                >
                <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                 <FormGroup
                            label="Seleccionar:"
                            labelFor="seleccionar"
                            intent="danger"
                            helperText={!this.state.personaSeleccionada?"Debe seleccionar una persona":""}
                >
                    <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                        <Select 
                            options={this.state.empleados}
                            onChange={onSeleccionarPersona}
                        />
                    </div>
                </FormGroup>
                </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content="Añadir Trabajador" position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={añadirNuevo}
                            >
                                Añadir Trabajador
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar comite"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el comite seleccionado?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar comite." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarMiembro}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup align={Alignment.LEFT}>
                            {this.props.desactivado?<Button className="bp3-minimal" icon="add" text="Nuevo"  disabled  active={this.state.nuevoActivado}  />:<Button className="bp3-minimal" icon="add" text="Nuevo"   active={this.state.nuevoActivado} onClick={abrirModalNuevo} />}
                            {this.props.desactivado?<Button className="bp3-minimal" icon="trash" text="Borrar" disabled active={this.state.borrarActivado} />:<Button className="bp3-minimal" icon="trash" text="Borrar"onClick={abrirModalBorrar} active={this.state.borrarActivado} />}
                            {this.props.desactivado?<Button className="bp3-minimal" icon="print" text="Imprimir" disabled  />:<Button className="bp3-minimal" icon="print" text="Imprimir" active={this.state.imprimirActivado} onClick={()=>this.generarImprimirCC()}/>}
                            
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <Table  ref={(input) => { this.tablaComites= input; }}  enableMultipleSelection={false} selectedRegions={this.state.regionSeleccionada} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} columnWidths={this.state.columnWidths} enableColumnResizing={false} onSelection={handleSeleccionarMiembro} numRows={this.state.comiteCompliance.length} onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}>
                    <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                    <Column name="Puesto de trabajo" cellRenderer={cellRendererPuesto}/>   
                </Table>
            </>
            }
         </>   
        )
    }
}
