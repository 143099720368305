/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import React, {Component} from 'react';
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { Icon, ButtonGroup, Button, Navbar, AnchorButton, Checkbox,Callout, Card,Alignment ,Elevation, Tooltip, InputGroup, FormGroup, Classes, Intent, Dialog, Position } from "@blueprintjs/core";
import Select from "react-select"
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

//conexion a la API.
let cliente = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/bdds",
    credentials: 'include',
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const today = new Date();

const DateFormatString = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  months_en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  weekDaysLong: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado'
  ],
  weekDaysLong_en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  weekDaysShort: [
    'Su',
    'Mo',
    'Tu',
    'We',
    'Th',
    'Fr',
    'Sa'
  ]
}
//obtencion de las bbds del usuario.
const obtenerBdds=gql`
{
  bdds(ORDEN:"NOMBRE,ASC"){ID_BDD,NOMBRE,CIF,RAZON_SOCIAL,ID_EMPRESA,NOM_EMPRESA}
}

`
class Bdds extends Component {


    state={
        showPassword:true,
        cargandoEmpresas: true,
        cargandoUsuarios: true,
        bdds:[],
        usuarios:[],
        rol:null,
        claveActualizada:null,
        modalCrearBdd: false,
        modalBorrarBdd: false,
        modalBackupBdd: false,
        modalCrearEditarUsuario: false,
        modalBorrarUsuario: false,
        nombreEmpresa: null,
        nombreUsuario: null,
        nombre:null,
        apellidos:null,
        CIF: null,
        razonSocial:null,
        empresas: [
        ],
        perfiles: [
        ],
        perfil: null,
        empresaSeleccionada: null,
        contrasena1: null,
        contrasena2: null,
        cifInvalido:false,
        correoIncorrecto:false,
        usuarioSeleccionado:null,
        nombreCliente:null,
        nuevoClienteActivado:true,
		editarClienteActivado:false,
		editarCliente2Activado: false,
        contraseñaNueva1: null,
        contraseñaNueva2: null,
        modalCliente:false,
        numUsuarios:1,
        numEmpresas:1,
        clienteSeleccionadoUsuario:null,
        fechaFuncionamiento:new Date(),
        datosEmpresaActual:null,
        nombreRol:null,
        marcarTodos:false,
        bddsSel:[],
        idUsuario:null,
        pasoGuia:0,
        arbol:[],
        arbolNoDatos:[

            {
                "id": 1,
                "ID_PADRE": null,
                "TEXTO": i18next.t("empresa",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false         
            },
            {
                "id": 8,
                "TEXTO": i18next.t("estructura_organizativa",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 13,
                "TEXTO": i18next.t("actividades",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 14,
                "TEXTO": i18next.t("instalaciones",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 15,
                "TEXTO": i18next.t("programas_de_seguro",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 78,
                "TEXTO": i18next.t("informe_empresa",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 72,
                "ID_PADRE": null,
                "TEXTO": i18next.t("recursos_humanos",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false         
            },
            {
                "id": 11,
                "TEXTO": i18next.t("plantilla_de_trabajadores",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 9,
                "TEXTO": i18next.t("catalogo_de_puestos_de_trabajo",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 10,
                "TEXTO": i18next.t("organigrama",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 12,
                "TEXTO": i18next.t("valoracion_del_rendimiento",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 73,
                "TEXTO": i18next.t("valoracion_de_puestos_de_trabajo",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 74,
                "TEXTO": i18next.t("registros_retributivos",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 75,
                "TEXTO": i18next.t("informes",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 80,
                "TEXTO": i18next.t("importar_trabajadores",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id":2,
                "ID_PADRE":null,
                "TEXTO": i18next.t("gobierno_corporativo",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,    

            },
            {  
                "id":16,
                "TEXTO": i18next.t("estructura_societaria",{ns:"menu"}),
                "EDITAR":false, 
                "deshabilitado":false,   
                "VER":false,   
            },
            {  
                "id":17,
                "TEXTO": i18next.t("modificaciones_estatutarias",{ns:"menu"}),
                "EDITAR":false, 
                "deshabilitado":false,   
                "VER":false,   
            },
            {  
                "id":18,
                "TEXTO": i18next.t("cuentas_anuales",{ns:"menu"}),
                "EDITAR":false,   
                "deshabilitado":false, 
                "VER":false,   
            },
            {  
                "id":19,
                "TEXTO": i18next.t("informacion_no_financiera",{ns:"menu"}),
                "EDITAR":false,  
                "deshabilitado":false,  
                "VER":false,   
            },
            {  
                "id":20,
                "TEXTO": i18next.t("toma_de_decisiones",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":21,
                "TEXTO": i18next.t("acuerdos_parasociales",{ns:"menu"}),
                "EDITAR":false,
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":22,
                "TEXTO": i18next.t("joint_ventures",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":23,
                "TEXTO": i18next.t("union_temporal_empresas",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":24,
                "TEXTO": i18next.t("capital_riesgo",{ns:"menu"}),
                "EDITAR":false, 
                "deshabilitado":false,   
                "VER":false,   
            },
            {  
                "id":25,
                "TEXTO": i18next.t("organo_de_administracion",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":26,
                "TEXTO": i18next.t("comisiones",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,          
            },
            {  
                "id":27,
                "TEXTO": i18next.t("organo_de_compliance",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":28,
                "TEXTO": i18next.t("contratos_con_directivos",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":29,
                "TEXTO": i18next.t("creditos_a_socios",{ns:"menu"}),
                "EDITAR":false,  
                "deshabilitado":false,  
                "VER":false,   
            },
            {  
                "id":30,
                "TEXTO": i18next.t("reuniones",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":31,
                "TEXTO": i18next.t("actas",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":32,
                "TEXTO": i18next.t("libros",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":3,
                "TEXTO": i18next.t("partes_interesadas",{ns:"menu"}),
                "ID_PADRE": null,
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":34,
                "TEXTO": i18next.t("administradores",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":35,
                "TEXTO": i18next.t("consejeros_delegados",{ns:"menu"}),
                "EDITAR":false,  
                "deshabilitado":false,  
                "VER":false,   
            },
            {  
                "id":36,
                "TEXTO": i18next.t("consejeros",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":33,
                "TEXTO": i18next.t("personal_alta_direccion",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":37,
                "TEXTO": i18next.t("apoderados",{ns:"menu"}),
                "EDITAR":false,  
                "deshabilitado":false,  
                "VER":false,   
            },
            {  
                "id":38,
                "TEXTO": i18next.t("auditores",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":39,
                "TEXTO": i18next.t("asesorias_externas",{ns:"menu"}),
                "EDITAR":false,
                "deshabilitado":false,    
                "VER":false,   
            },
            {  
                "id":40,
                "TEXTO": i18next.t("servicios_empresariales",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":41,
                "TEXTO": i18next.t("servicios_profesionales",{ns:"menu"}),
                "EDITAR":false, 
                "deshabilitado":false,   
                "VER":false,   
            },
            {  
                "id":42,
                "TEXTO": i18next.t("clientes",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":43,
                "TEXTO": i18next.t("proveedores",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":44,
                "TEXTO": i18next.t("acreedores_diversos",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":45,
                "TEXTO": i18next.t("socios_gestores",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
            {  
                "id":46,
                "TEXTO": i18next.t("fondos_de_inversion",{ns:"menu"}),
                "EDITAR":false,   
                "deshabilitado":false, 
                "VER":false,   
            },
            {  
                "id":47,
                "TEXTO": i18next.t("otras_partes_interesadas",{ns:"menu"}),
                "EDITAR":false,    
                "deshabilitado":false,
                "VER":false,   
            },
    
            {
                "id": 4,
                "TEXTO": i18next.t("compliance",{ns:"menu"}),
                "ID_PADRE": null,
                "deshabilitado":false,
                "VER": false,
                "EDITAR": false,
                
            },
            {
                "id": 48,
                "TEXTO": i18next.t("contrato_de_compliance",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 49,
                "TEXTO": i18next.t("edicion_de_riesgos",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 50,
                "TEXTO": i18next.t("identificacion_y_seguimiento",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 52,
                "TEXTO": i18next.t("canal_de_denuncias",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 53,
                "TEXTO": i18next.t("organo_de_instruccion",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 54,
                "TEXTO": i18next.t("organo_de_resolucion",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 55,
                "TEXTO": i18next.t("incidencias/siniestros",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 56,
                "TEXTO": i18next.t("declaraciones_previas",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 57,
                "TEXTO": i18next.t("certificado_lexcontrol",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 76,
                "TEXTO": i18next.t("procedimientos",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 79,
                "TEXTO": i18next.t("informe_compliance",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 5,
                "TEXTO": i18next.t("cuadro_de_mando",{ns:"menu"}),
                "ID_PADRE": null,
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 58,
                "TEXTO": i18next.t("gobierno_corporativo2",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 59,
                "TEXTO": i18next.t("identificacion_de_riesgos",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 60,
                "TEXTO": i18next.t("seguimiento_y_control",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                
            },
            {
                "id": 61,
                "TEXTO": i18next.t("alertas",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            
            },
            {
                "id": 62,
                "TEXTO": i18next.t("informe_anual",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                   
            },
            {
                "id": 77,
                "TEXTO": i18next.t("obligaciones_contractuales",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                   
            },
            {
                "id": 6,
                "TEXTO": i18next.t("configuracion",{ns:"menu"}),
                "ID_PADRE": null,
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                   
            },
            {
                "id": 63,
                "TEXTO": i18next.t("logotipo",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 7,
                "TEXTO": i18next.t("recursos",{ns:"menu"}),
                "ID_PADRE": null,
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                   
                
            },
            {
                "id": 66,
                "TEXTO": i18next.t("legislacion",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 67,
                "TEXTO": i18next.t("jurisprudencia",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                    
            },
            {
                "id": 68,
                "TEXTO": i18next.t("doctrina",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false                    
            },
            {
                "id": 69,
                "TEXTO": i18next.t("blog",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 70,
                "TEXTO": i18next.t("links",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            },
            {
                "id": 71,
                "TEXTO": i18next.t("soporte_tecnico_y_contacto",{ns:"menu"}),
                "EDITAR": false,
                "deshabilitado":false,
                "VER":false
            }
    
    ],
    stepsSuperAdmin: [
        {
          target: '#lista-bdds',
          content: 'Aqui se muestran las bases de datos a las cuáles tenemos acceso.Cada base de datos es una empresa que corresponde a uno de los clientes de Lexcontrol. Ej: Aislamientos pertenece a Ángel Sánchez.Como somos super administrador podremos ver todos los clientes de todas las empresas.Haz doble clic para acceder a la empresa.',
          selector: '#lista-bdds',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex: 1,
        },
        {
            target: '#boton-crear-bdd',
            content: "Para crear una nueva base de datos hacemos clic en el botón 'Crear bdd'.",
            selector: '#boton-crear-bdd',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 2,
        },
        {
            target: '#dialogo-crear-bdd',
            content: "A continuación se abrirá el siguiente diálogo solicitando los siguientes datos.",
            selector: '#dialogo-crear-bdd',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 3,
        },
        {
            target: '#datos-empresa',
            content: "Rellenamos los datos solicitados, necesarios para crear la nueva empresa.",
            selector: '#datos-empresa',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 4,
        },
        {
            target: '#select-cliente',
            content: "Como mencionamos, una empresa pertenece a un cliente de Lexcontrol. En este campo seleccionaremos a que cliente pertenece la nueva base de datos que vamos a crear.",
            selector: '#select-cliente',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 5,
        },
        {
            target: '#boton-crear-cliente',
            content: "Si un nuevo cliente se afiliase a Lexcontrol, habría que darlo de alta.Hacemos clic en 'Nuevo'",
            selector: '#boton-crear-cliente',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 6,
        },

        {
            target: '#input-maximo-usuarios',
            content: "En este campo insertaremos el número de usuarios que podrá tener este cliente. Cuando se alcance el número máximo, no se podrán crear mas usuarios.",
            selector: '#input-maximo-usuarios',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex:7,
        },
        {
            target: '#input-maximo-empresas',
            content: "En este campo insertaremos el número de empresas que podrá tener este cliente. Cuando se alcance el número máximo, no se podrán crear mas usuarios.",
            selector: '#input-maximo-empresas',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 8
        },
        {
            target: '#input-fecha-funcionamiento',
            content: "En el siguiente campo, seleccionaremos hasta que fecha podrá trabajar el cliente con Lexcontrol. Una vez alcanzada está fecha, si el cliente accede a la aplicación, aparecerá un diálogo avisando que debe renovar su contrato con Lexcontrol y no podrá hacer uso de la aplicación.",
            selector: '#input-maximo-empresas',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 9
        },
   
        {
            target: '#lista-usuarios',
            content: 'En el siguiente listado se muestran todos los usuarios junto al cliente de lexcontrol al que pertenecen.',
            selector: '#lista-usuarios',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 10,
        },
        {
            target: '#boton-crear-usuario',
            content: "Para agergar un nuevo usuario hacemos clic en le botón 'Crear usuario'.",
            selector: '#boton-crear-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 11,
        },
        {
            target: '#dialogo-crear-usuario',
            content: "A continuación se nos mostrará el diálogo de usuarios con los siguientes datos.",
            selector: '#dialogo-crear-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 12,
        },
        {
            target: '#email-usuario',
            content: "El correo del usuario (Se utilizará para iniciar sesión en la aplicación Lexcontrol).",
            selector: '#email-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 13,
        },
        {
            target: '#select-cliente-usuario',
            content: "Al igual que las empresas , los usuarios también pertenecen a un cliente de lexcontrol.",
            selector: '#select-cliente-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 14,
        },
        {
            target: '#select-perfil-usuario',
            content: "Dependiendo del perfil que el usuario tenga, podrá realizar unas acciones u otras en la aplicación.Existen 2 usuarios predefinidos: Administrador y Usuario común, aunque pueden darse de alta tantos perfiles como se desee.",
            selector: '#select-perfil-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 15,
        },
        {
            target: '#boton-crear-perfil',
            content: "Para crear un nuevo perfil haremos clic sobre el botón nuevo.Cada cliente de lexcontrol tendrá sus propios perfiles exceptuando los predefinidos, los cuales no se pueden modificar.",
            selector: '#boton-crear-perfil',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 16,
        },
        {
            target: '#select-empresas-perfil',
            content: "En el listado, seleccionaremos a que empresas tendrá el usuario acceso.Sólo aparecerán las empresas del cliente al que pertenece el usuario.",
            selector: '#select-empresas-perfil',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 17,
        },
        {
            target: '#tabla-permisos',
            content: "De la siguiente tabla seleccionaremos que áreas o secciones de la aplicación puede ver y/o modificar el usuario.",
            selector: '#tabla-permisos',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 18,
        },

        
    ],
    stepsAdmin: [
        {
          target: '#lista-bdds',
          content: 'Aqui se muestran las empresas a las cuáles tenemos acceso.Haz doble clic para acceder a la empresa deseada.',
          selector: '#lista-bdds',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex: 1,
        },

        {
            target: '#lista-usuarios',
            content: 'En el siguiente listado se muestran todos los usuarios que tenemos dados de alta.',
            selector: '#lista-usuarios',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 2,
        },
        {
            target: '#boton-crear-usuario',
            content: "Para agergar un nuevo usuario hacemos clic en le botón 'Crear usuario'.",
            selector: '#boton-crear-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 3,
        },
        {
            target: '#dialogo-crear-usuario',
            content: "A continuación se nos mostrará el diálogo de usuarios con los siguientes datos.",
            selector: '#dialogo-crear-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 4,
        },
        {
            target: '#email-usuario',
            content: "El correo que el usuario utilizará para iniciar sesión en la aplicación Lexcontrol.",
            selector: '#email-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 5,
        },
        {
            target: '#select-perfil-usuario',
            content: "Dependiendo del perfil que el usuario tenga, podrá realizar unas acciones u otras en la aplicación.Existen 2 usuarios predefinidos: Administrador y Usuario común, aunque pueden darse de alta tantos perfiles como se desee.",
            selector: '#select-perfil-usuario',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 6,
        },
        {
            target: '#boton-crear-perfil',
            content: "Para crear un nuevo perfil haremos clic sobre el botón nuevo.",
            selector: '#boton-crear-perfil',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 7,
        },
        {
            target: '#select-empresas-perfil',
            content: "En el listado, seleccionaremos a que empresas tendrá el usuario acceso.",
            selector: '#select-empresas-perfil',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex: 8,
        },
        {
            target: '#tabla-permisos',
            content: "De la siguiente tabla seleccionaremos que áreas o secciones de la aplicación puede ver y/o modificar el usuario.",
            selector: '#tabla-permisos',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex:9,
        },

        
    ],
    stepsOtros: [
        {
          target: '#lista-bdds',
          content: 'Aqui se muestran las empresas a las cuáles tenemos acceso.Haz doble clic para acceder a la empresa deseada.',
          selector: '#lista-bdds',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex: 1,
        },
    ],
    numUsuariosEmpresa:0,
    numClientesEmpresa:0,
    empresaCaducada:null,
	modalCuotaCaducada:true,
	clienteSeleccionado:null
    }

    cargarPerfiles=async(idEmpresa)=>{

        //seleccionamos la bdd en la API.
        let cliente = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/bdds",
            credentials: 'include',
        });
        
        let perfiles=[]

        await cliente
        .query({
            query: gql`{perfiles(ID_EMPRESA:${parseInt(this.state.rol)===1?idEmpresa:this.state.datosEmpresaActual.ID_EMPRESA}){ID_PERFIL,NOMBRE,ARBOL}}`,fetchPolicy:'network-only',
        })
        .then(result =>{
            console.log(result.data.perfiles)
            for(let perfil of result.data.perfiles){
                if(perfil.ID_PERFIL!==1){
                    perfiles.push({
                        "label":perfil.NOMBRE,
                        "value":perfil.ID_PERFIL,
                        "arbol":perfil.ARBOL
                    })
                }
            }
        })

    
        this.setState({perfiles:perfiles})
     


    }

    onSeleccionarBdd =async (bdd)=>{
        //seleccionamos la bdd en la API.
        let cliente = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/bdds",
            credentials: 'include',
        });
        
        cliente
        .mutate({
            mutation: gql`
            mutation{
                useBdd(NOMBRE:"${bdd}")
            }
            `
        }).then(()=>{
            window.location.reload()
        })
        
    }

    abrirCrearBdd = () => {
        this.setState({
            modalCrearBdd: true,
            empresaSeleccionada:null,
            CIF:null,
            razonSocial:null,
            nombreEmpresa:null
        })
    }

    abrirBackupBdd = () => {
        if(!this.state.bddSeleccionada){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        this.setState({modalBackupBdd: true})
    }

    abrirBorrarBdd = () => {
        if(!this.state.bddSeleccionada){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        this.setState({modalBorrarBdd: true})
    }

    abrirSitio = () => {
        if(!this.state.bddSeleccionada){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        this.onSeleccionarBdd(this.state.bddSeleccionadaNombre);
    }

    abrirBorrarCliente = () => {
        this.setState({modalBorrarCliente: true,clienteSeleccionado:null})
    }

    abrirCliente=()=>{
        this.setState({
            modalCliente:true,
            nuevoClienteActivado:true,
            editarClienteActivado:false,
            nombreCliente:null,
            numUsuarios:1,
            numEmpresas:1,
            fechaFuncionamiento:new Date()
        })
    }

    
    editarCliente=()=>{

        if(!this.state.empresaSeleccionada){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }

        /* COMENTO ESTO POR SI ACASO PETA, PERO SACA UN WARNING SI LO ACTIVO */ 
        /* let myDate = new Date(this.state.empresaSeleccionada.fechaMax); */

        this.setState({
            modalCliente:true,
            nuevoClienteActivado:false,
            editarClienteActivado:true,
            nombreCliente:this.state.empresaSeleccionada.label,
            numUsuarios:this.state.empresaSeleccionada.numUsuarios,
            numEmpresas:this.state.empresaSeleccionada.numEmpresas,
            fechaFuncionamiento:new Date(this.state.empresaSeleccionada.fechaMax)
        })
	}
	
    editarCliente2=()=>{

        this.setState({
			editarCliente2Activado:true,
			clienteSeleccionado: null,
		})
		
    }

    abrirEditarCliente=()=>{
        this.setState({
            modalCliente:true,
            nuevoClienteActivado:true,
            editarClienteActivado:false,
            nombreCliente:null,
            numUsuarios:1,
            numEmpresas:1,
            fechaFuncionamiento:new Date()
        })
    }

    abrirCrearUsuario = () => {

        this.setState({
            nombreUsuario:null,
            nombre:null,
            apellidos:null,
            contrasena1:null,
            contrasena2:null,
            clienteSeleccionadoUsuario:null,
            modalCrearEditarUsuario: true,
            usuarioSeleccionado:null
        })
    }

    abrirEditarUsuario = async() => {

        if(!this.state.usuarioSeleccionado){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }

        if(parseInt(this.state.rol)===1)await this.cargarPerfiles(this.state.usuarioSeleccionado.ID_EMPRESA)
      
        let perfilSel= await this.state.perfiles.find(perfil=>{return perfil.value===parseInt(this.state.usuarioSeleccionado.ID_PERFIL)})

        this.setState({
            nombreUsuario:this.state.usuarioSeleccionado.USUARIO,
            nombre:this.state.usuarioSeleccionado.NOMBRE,
            apellidos:this.state.usuarioSeleccionado.APELLIDOS,
            perfil:perfilSel,
            modalCrearEditarUsuario: true,
            contrasena1:null,
            contrasena2:null,
        })
    }
   
    abrirBorrarUsuario = () => {
        if(!this.state.usuarioSeleccionado){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        this.setState({modalBorrarUsuario: true})
    }

    validateCIF(cf)
	{
    var i = 0;
    var temp=0;
	// eslint-disable-next-line no-array-constructor
	var v1 = new Array(0,2,4,6,8,1,3,5,7,9);
	cf = cf.toUpperCase();
	if (!/^[A-Za-z0-9]{9}$/.test(cf)) return false
	else if (!/^[ABCDEFGHKLMNPQS]/.test(cf)) return false
    else
        {
            for(i=2; i<=6; i+=2)
                {
                temp += v1[parseInt(cf.substr(i-1, 1))];
                temp += parseInt(cf.substr(i, 1));
                }
            temp = temp + v1[parseInt(cf.substr(7,1))];
            temp = (10 - ( temp % 10));
            if(temp == 10 && (cf.charAt(cf.length-1) == 'J' || cf.charAt(cf.length-1) == 0)) return true;
            else if (cf.charAt(cf.length-1) == temp || cf.charAt(cf.length-1) == String.fromCharCode(64 + temp)) return true;
            else return false;
        }
	}

    guardarDatosBdd = async() => {

        if(!this.state.nombreEmpresa)return
        if(!this.state.empresaSeleccionada)return
        if(!this.state.CIF)return
        if(!this.state.razonSocial)return
        //if(!this.validateCIF(this.state.CIF)){
            this.setState({cifInvalido:true})
        //    return
        //}else{
        //    this.setState({cifInvalido:false})
        //}

        //comprobar que la bdd no exista.
        let existe=this.state.bdds.find(bdd=>{
            return bdd.NOMBRE===(this.state.nombreEmpresa+"_"+this.state.empresaSeleccionada.value)
        })

        if(existe){
            alert(i18next.t("yaexiste",{ns:"global"}))
            return
        }


        let existeCIF=this.state.bdds.find(bdd=>{
            return bdd.CIF===this.state.CIF
        })

        if(existeCIF){
            alert(i18next.t("yaexiste",{ns:"global"}))
            return
        }

        let existeRazon=this.state.bdds.find(bdd=>{
            console.log(bdd)
            return bdd.RAZON_SOCIAL===this.state.razonSocial
        })

        if(existeRazon){
            alert(i18next.t("yaexiste",{ns:"global"}))
            return
        }


        await cliente
        .query({
            query: gql`{obtenerNumBdds(ID_EMPRESA:${this.state.empresaSeleccionada.value})}`,fetchPolicy:'network-only',
        })
        .then(async result =>{
        
            await this.setState({numClientesEmpresa:result.data.obtenerNumBdds})
        });

        //si el numero de usuarios contratados es igual al dado de alta
        if(this.state.numClientesEmpresa>=this.state.empresaSeleccionada.numEmpresas){
            alert(i18next.t("validnrodeempresas",{ns:"main"}))
            return
        }


        cliente
        .mutate({
            mutation: gql`
            mutation{
                crearBdd(NOMBRE:"${this.state.nombreEmpresa.toUpperCase()}",ID_EMPRESA:${this.state.empresaSeleccionada.value},RAZON_SOCIAL:"${this.state.razonSocial}",CIF:"${this.state.CIF}"){ID_BDD}
            }
            `
        }).then((result)=>{
            alert(i18next.t("insertcorrecto",{ns:"global"}))

            //asociamos la bdd a la empresa
            cliente
            .mutate({
                mutation: gql`
                mutation{
                    crearEmpresaBdd(VALORES:{ID_EMPRESA:${this.state.empresaSeleccionada.value},ID_BDD:${result.data.crearBdd.ID_BDD}}){ID_EMPRESA,ID_BDD}
                }
                `
            })

        
            this.cargarBdds()

        }).catch(err=>{
            console.log(err)
            alert(i18next.t("error",{ns:"global"}))
        })

        this.cerrarModal()
    }

    guardarDatosUsuario = async() => {

        if(!this.state.perfil)return
        if(!this.state.nombre || this.state.nombre==="")return
        if(!this.state.apellidos || this.state.apellidos==="")return
        if(this.state.contrasena1!==this.state.contrasena2)return
        if(!this.state.contrasena1 || !this.state.contrasena2)return

        let patronClave=/(?=^.{6,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*/

        if(!patronClave.test(this.state.contrasena1)){
            alert(i18next.t("clavenovalida",{ns:"main"}))
            return
        }

        if(this.state.contrasena1.length<5){
            alert(i18next.t("limitclave",{ns:"main"}))
            return
        }
        //validamos que el usuario sea un correo.
        let patron=/^[\w]+@{1}[\w]+\.[a-z]{2,3}$/
        if(!patron.test(this.state.nombreUsuario)){
            this.setState({correoIncorrecto:true})
            return
        }else{
            this.setState({correoIncorrecto:false})
        }

        if(parseInt(this.state.rol)===1){

            if(!this.state.clienteSeleccionadoUsuario)return
            console.log(this.state.clienteSeleccionadoUsuario)
            await cliente
            .query({
                query: gql`{obtenerNumUsuarios(ID_EMPRESA:${this.state.clienteSeleccionadoUsuario.value})}`,fetchPolicy:'network-only',
            })
            .then(async result =>{
              
                await this.setState({numUsuariosEmpresa:result.data.obtenerNumUsuarios})

            });
            //si el numero de usuarios contratados es igual al dado de alta
            if(this.state.numUsuariosEmpresa>=this.state.clienteSeleccionadoUsuario.numUsuarios){
                alert(i18next.t("validnrodeusuarios",{ns:"main"}))
                return
            }
        }else{
             //si el numero de usuarios contratados es igual al dado de alta
            if(this.state.numUsuariosEmpresa>=this.state.datosEmpresaActual.EMPRESA.NUMERO_USUARIOS){
                alert(i18next.t("validnrodeusuarios",{ns:"main"}))
                return
            }
        }      

       
        
        //creamos el usuario
        await cliente
        .mutate({
            mutation: gql`
            mutation{
                crearUsuarioLogin(VALORES:{USUARIO:"${this.state.nombreUsuario}",NOMBRE:"${this.state.nombre}",APELLIDOS:"${this.state.apellidos}",CLAVE:"${this.state.contrasena1}",CLAVE_ACTUALIZADA:true,ID_PERFIL:${this.state.perfil.value}}){ID_USUARIO}
            }
            `
        }).then(async(result)=>{
            alert(i18next.t("insertcorrecto",{ns:"global"}))

            let queryAsociacion=""

            //si somos super admin creamos al usuario en la empresa seleccionado
            if(parseInt(this.state.rol)===1){

                queryAsociacion=gql`
                mutation{
                    crearEmpresaUsuario(VALORES:{ID_USUARIO:${result.data.crearUsuarioLogin.ID_USUARIO},ID_EMPRESA:${this.state.clienteSeleccionadoUsuario.value}}){ID_USUARIO}
                }
                `

            }else{

                //de lo contrario, lo creamos en la empresa a la que pertenezca el usuario
                queryAsociacion=gql`
                mutation{
                    crearEmpresaUsuario(VALORES:{ID_USUARIO:${result.data.crearUsuarioLogin.ID_USUARIO},ID_EMPRESA:${this.state.datosEmpresaActual.ID_EMPRESA}}){ID_USUARIO}
                }`
            }

            await cliente
            .mutate({
                mutation:queryAsociacion
            })

            await this.cargarUsuarios()
            await this.cerrarModal()
        }).catch(err=>{
            alert(i18next.t("usuarioenuso",{ns:"main"}))
        })

    }

    actualizarUsuario=()=>{

        if(!this.state.perfil)return
        if(!this.state.nombre || this.state.nombre==="")return
        if(!this.state.apellidos || this.state.apellidos==="")return
        if(this.state.contrasena1!==this.state.contrasena2)return
        if(this.state.contrasena1){
            let patronClave=/(?=^.{6,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*/

            if(!patronClave.test(this.state.contrasena1)){
                alert(i18next.t("clavenovalida",{ns:"main"}))
                return
            }
        }
      
        //validamos que el usuario sea un correo.
        let patron=/^[\w]+@{1}[\w]+\.[a-z]{2,3}$/
        if(!patron.test(this.state.nombreUsuario)){
            this.setState({correoIncorrecto:true})
            return
        }else{
            this.setState({correoIncorrecto:false})
        }

        //creamos el usuario
        let mutation=gql`
        mutation{
            actualizarUsuarioLogin(ID_USUARIO:${this.state.usuarioSeleccionado.ID_USUARIO},VALORES:{USUARIO:"${this.state.nombreUsuario}",NOMBRE:"${this.state.nombre}",APELLIDOS:"${this.state.apellidos}",ID_PERFIL:${this.state.perfil.value}}){ID_USUARIO}
        }
        `
        if (this.state.usuarioSeleccionado.ID_USUARIO!=14) { // solo actualizo clave distintos a usuarioweb
          //si no pasan clave, cambiaremos la mutation.
          if(this.state.contrasena1){
              mutation=gql`
              mutation{
                  actualizarUsuarioLogin(ID_USUARIO:${this.state.usuarioSeleccionado.ID_USUARIO},VALORES:{USUARIO:"${this.state.nombreUsuario}",NOMBRE:"${this.state.nombre}",APELLIDOS:"${this.state.apellidos}",CLAVE:"${this.state.contrasena1}",ID_PERFIL:${this.state.perfil.value}}){ID_USUARIO}
              }
              `
          }
        }
        
        cliente
        .mutate({
            mutation: mutation
        }).then(async()=>{
            alert(i18next.t("updatecorrecto",{ns:"global"}))
            await this.cargarUsuarios()
            await this.cerrarModal()
        }).catch(err=>{
            console.log(err)
            alert(i18next.t("usuarioenuso",{ns:"main"}))
        })

    }

    cerrarModal = () => {
        this.setState({
            modalCrearBdd: false,
            modalBorrarBdd: false,
            modalCrearEditarUsuario: false,
			modalBorrarUsuario: false,
			editarCliente2Activado: false,
			modalBorrarCliente: false,
			perfil: null,
            modalBackupBdd: false
        })
    }

    cerrarModal2 = () => {
        this.setState({
            modalCliente: false,
            modalRol:false
        })
    }

    cambiarNuevaEmpresa = (e) => {
        this.setState({nombreEmpresa: e.target.value})
    }

    cambiarNuevoCliente = (e) => {
        this.setState({nombreCliente: e.target.value})
    }

    cambiarNumUsuarios = (e) => {
        this.setState({numUsuarios: e.target.value})
    }

    cambiarNumEmpresas = (e) => {
        this.setState({numEmpresas: e.target.value})
    }

    cambiarFechaFuncionamiento = (value) => {
        this.setState({fechaFuncionamiento: value})
    }


    cambiarNombreUsuario = (e) => {
        this.setState({nombreUsuario: e.target.value})
    }

    cambiarNombre = (e) => {
        this.setState({nombre: e.target.value})
    }

    cambiarApellidos = (e) => {
        this.setState({apellidos: e.target.value})
    }

    cambiarContraseña1 = (e) => {
        this.setState({
            showPassword:false,
            contrasena1: e.target.value
        })
    }

    cambiarContraseña2 = (e) => {
        this.setState({
            showPassword:false,
            contrasena2: e.target.value
        })
    }

    cambiarNuevoCif = (e) => {
        this.setState({CIF: e.target.value})
    }

    cambiarNuevaRazon = (e) => {
        this.setState({razonSocial: e.target.value})
    }

    seleccionEmpresa = (value) => {
        this.setState({empresaSeleccionada: value})
    }

    seleccionClienteUsuario = (value) => {
        this.setState({clienteSeleccionadoUsuario: value,perfil:null})
        this.cargarPerfiles(value.value)
        
    }

    seleccionPerfil = (value) => {
        this.setState({perfil: value})
    }

    seleccionCliente = (value) => {
        this.setState({clienteSeleccionado: value,numEmpresas:value.numEmpresas,numUsuarios:value.numUsuarios,nombreCliente:value.label,fechaFuncionamiento:new Date(value.fechaMax)})
    }
   
    onSelBd=(ev)=>{
        this.setState({bddSeleccionada:ev.currentTarget.dataset.id})
        //document.getElementsByClassName("rowBdd").style.backgroundColor='#FFFFFF';
        let i=0
        for(i=0;i<document.getElementsByClassName("rowBdd").length;i++) {
            document.getElementsByClassName("rowBdd")[i].style.backgroundColor='#FFFFFF';
        }
        document.getElementById(ev.currentTarget.id).style.backgroundColor='#E8E8E8';
    }

    renderRow=({index, key, style, parent }) =>{

        cliente = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/bdds",
            credentials: 'include',
        });

        return (
          
            <div key={index} className="content">
                {parseInt(this.state.rol)===1 && index==0 && (
                    <div style={{width:"100%",display:"flex"}}>
                        <div className="listhead" style={{width:"20%"}}>
                        </div>
                        <div className="listhead" style={{width:"40%"}}>
                            <span>{"CLIENTE"}</span>
                        </div> 
                        <div className="listhead" style={{width:"40%"}}>
                            <span >{"EMPRESA"}</span>
                        </div> 
                    </div>
                )} 
                {parseInt(this.state.rol)!==1 && index==0 && (
                    <div style={{width:"100%",display:"flex"}}>
                        <div className="listhead" style={{width:"20%"}}>
                        </div>
                        <div className="listhead" style={{width:"80%"}}>
                            <span >{"EMPRESA"}</span>
                        </div> 
                    </div>
                )}
                {this.state.bdds.map((bdd,i) => (
                    <div id={"contenedor_"+i} key={"contenedor_"+i}  onClick={this.onSelBd} onDoubleClick={this.onSeleccionarBdd} data-id={bdd.ID_BDD} data-nombre={bdd.NOMBRE} className="row rowBdd">
                        <Icon key={"icono_"+i} color="black" icon="database" iconSize={20} />
                        {parseInt(this.state.rol)===1 && (
                            <div style={{width:"100%"}}>
                                <div style={{width:"55%",float:"left"}}>
                                    <p key={"nombre_cli"+i} >{bdd.NOM_EMPRESA}</p>
                                </div> 
                                <div style={{width:"45%",float:"left"}}>
                                    <p key={"nombre_"+i} >{bdd.NOMBRE.substr(0,bdd.NOMBRE.lastIndexOf("_"))}</p>
                                </div> 
                            </div> 
                        )}
                        {parseInt(this.state.rol)!==1 && (
                            <p key={"nombre_"+i} >{bdd.NOMBRE.substr(0,bdd.NOMBRE.lastIndexOf("_"))}</p>
                        )}
                        
                    </div>
                ))}
            </div>
              
        );
    }

    onSelUser=(ev)=>{
        let usuarioSel=this.state.usuarios.find(usuario=>{
            return usuario.USUARIO===ev.currentTarget.dataset.nom
        })
        this.setState({
            usuarioSeleccionado:usuarioSel
        })
        let i=0
        for(i=0;i<document.getElementsByClassName("rowUser").length;i++) {
            document.getElementsByClassName("rowUser")[i].style.backgroundColor='#FFFFFF';
        }
        document.getElementById(ev.currentTarget.id).style.backgroundColor='#E8E8E8';

    }

    renderRowUsuarios=({index, key, style, parent }) =>{

        
        return (
          
            <div key={index} className="content">
                {parseInt(this.state.rol)===1 && index==0 && (
                    <div style={{width:"100%",display:"flex"}}>
                        <div className="listhead" style={{width:"20%"}}>
                        </div>
                        <div className="listhead" style={{width:"40%"}}>
                            <span>{"CLIENTE"}</span>
                        </div> 
                        <div className="listhead" style={{width:"40%"}}>
                            <span >{"USUARIO"}</span>
                        </div> 
                    </div>
                )} 
                {parseInt(this.state.rol)!==1 && index==0 && (
                    <div style={{width:"100%",display:"flex"}}>
                        <div className="listhead" style={{width:"20%"}}>
                        </div>
                        <div className="listhead" style={{width:"80%"}}>
                            <span >{"USUARIO"}</span>
                        </div> 
                    </div>
                )}
                {this.state.usuarios.map((usuario,i) => (
                    <div id={"contenedorU_"+i} key={"contenedorU_"+i} onClick={this.onSelUser} data-nom={usuario.USUARIO} className="row rowUser">
                        <Icon key={"icono_"+i} color="black" icon="user" iconSize={20} />
                        {parseInt(this.state.rol)===1 && (
                            <div style={{width:"100%"}}>
                                <div style={{width:"55%",float:"left"}}>
                                    <p key={"nombre_cli"+i} >{usuario.NOM_EMPRESA}</p>
                                </div> 
                                <div style={{width:"45%",float:"left"}}>
                                    <p key={"nombre_"+i} >{usuario.USUARIO}</p>
                                </div> 
                            </div>
                        )}
                        {parseInt(this.state.rol)!==1 && (
                            <p key={"nombre_"+i} >{usuario.USUARIO}</p>
                        )}
                    </div>
                ))}
            </div>
              
        );
    }

    cargarClientes=async()=>{

        //obtenemos el rol del usuario actual
        await cliente
        .query({
            query: gql`{empresas{ID_EMPRESA,NOMBRE,NUMERO_USUARIOS,NUMERO_EMPRESAS,FECHA_FIN}}`,fetchPolicy:'network-only',
        })
        .then(result =>{
            let empresas=[]

            for(let empresa of result.data.empresas){
                empresas.push({ "numUsuarios":empresa.NUMERO_USUARIOS,"numEmpresas":empresa.NUMERO_EMPRESAS,"fechaMax":empresa.FECHA_FIN,"value":empresa.ID_EMPRESA, "label":empresa.NOMBRE
                })
            }

            this.setState({empresas:empresas})
        });

    }

    cargarBdds=async()=>{

        //si el componente esta mostrado.
        let bdds=await cliente
        .query({
            query: obtenerBdds,fetchPolicy:'network-only',
        })
        .then(result =>{
            return result.data.bdds
        });
        //obtenemos la empresa de cada usuario
        /*for(let bdd of bdds){

            await cliente
            .query({
                query: gql`{EmpresasBdds(FILTROS:{ID_BDD:${bdd.ID_BDD}}){ID_EMPRESA,EMPRESA{NOMBRE},ID_BDD}}`,fetchPolicy:'network-only',
            })
            .then(result =>{
                if(result.data.EmpresasBdds.length>0){
                    bdd.ID_EMPRESA=result.data.EmpresasBdds[0].ID_EMPRESA
                    bdd.NOM_EMPRESA=result.data.EmpresasBdds[0].EMPRESA.NOMBRE
                }
            });

        }*/


        this.setState({
            bdds:bdds,
            cargandoEmpresas: false
        })

    }

    cargarUsuarios=async()=>{

        if(this.state.rol<3){

           let usuarios= await cliente
            .query({
                query: gql`{obtenerUsuarios{ID_USUARIO,USUARIO,NOMBRE,APELLIDOS,ID_PERFIL,ID_EMPRESA,NOM_EMPRESA}}`,fetchPolicy:'network-only',
            })
            .then(result =>{  
                return result.data.obtenerUsuarios  
            });

            //obtenemos la empresa de cada usuario
            /*for(let usuario of usuarios){

                await cliente
                .query({
                    query: gql`{EmpresasUsuarios(FILTROS:{ID_USUARIO:${usuario.ID_USUARIO}}){ID_EMPRESA,EMPRESA{NOMBRE},ID_USUARIO}}`,fetchPolicy:'network-only',
                })
                .then(result =>{
                    usuario.ID_EMPRESA=result.data.EmpresasUsuarios[0].ID_EMPRESA
                    usuario.NOM_EMPRESA=result.data.EmpresasUsuarios[0].EMPRESA.NOMBRE
                });

            }*/

            await this.setState({
                usuarios:usuarios,
                cargandoUsuarios: false
            })


        }

    }



    componentDidMount=async(props)=>{

        this.setState({
            arbol:this.state.arbolNoDatos
        })
        //obtenemos el rol del usuario actual
        await cliente
        .query({
            query: gql`{obtenerUsuariosLoginActual{ID_USUARIO,ID_PERFIL,CLAVE_ACTUALIZADA}}`,fetchPolicy:'network-only',
        })
        .then(result =>{
            this.setState({
                idUsuario:result.data.obtenerUsuariosLoginActual.ID_USUARIO,
                rol:result.data.obtenerUsuariosLoginActual.ID_PERFIL,
                claveActualizada:result.data.obtenerUsuariosLoginActual.CLAVE_ACTUALIZADA
            })

            if(parseInt(result.data.obtenerUsuariosLoginActual.ID_PERFIL)!==1){
                //obtenemos el rol del usuario actual
                 cliente
                .query({
                    query: gql`{EmpresasUsuarios(FILTROS:{ID_USUARIO:${result.data.obtenerUsuariosLoginActual.ID_USUARIO}}){ID_EMPRESA,EMPRESA{NOMBRE,NUMERO_USUARIOS,FECHA_FIN},ID_USUARIO}}`,fetchPolicy:'network-only',
                })
                .then(result =>{
                    this.setState({datosEmpresaActual:result.data.EmpresasUsuarios[0]})
                    if(moment()>=moment(result.data.EmpresasUsuarios[0].EMPRESA.FECHA_FIN)){
                        this.setState({empresaCaducada:true})
                    }else{
                        this.setState({empresaCaducada:false})
                    }
                });

            }else{
                this.setState({empresaCaducada:false})
            }
        });
        
        await this.cargarBdds()
        await this.cargarUsuarios()

        //cargamos los select de los empresas
        await this.cargarClientes()

        //cargamos los perfiles de la empresa actual (solo sino somos super_admin)
        if(parseInt(this.state.rol)!==1){

            await this.cargarPerfiles(this.state.datosEmpresaActual.ID_EMPRESA)
            await cliente
            .query({
                query: gql`{obtenerNumUsuarios(ID_EMPRESA:${this.state.datosEmpresaActual.ID_EMPRESA})}`,fetchPolicy:'network-only',
            })
            .then(result =>{
              
                this.setState({numUsuariosEmpresa:result.data.obtenerNumUsuarios})

            });
            


        }

    }

  
    render(){

        const { stepsSuperAdmin,stepsAdmin,stepsOtros} = this.state;

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };
        
        const descargarArchivo = (ruta) => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+ruta.replace(".",""), '_blank')
            win.focus()
        }

        const backupBdd=async()=>{
            await cliente
            .mutate({
                mutation: gql`
                mutation{
                    backupBdd(NOMBRE:"${this.state.bddSeleccionadaNombre}")
                }
                `
            }).then((result)=>{
                descargarArchivo(result.data.backupBdd);
                alert(i18next.t("backupcorrecto",{ns:"main"}))
                this.cerrarModal()
            }).catch(err=>{
                console.log(err);
                alert(i18next.t("error",{ns:"global"}))
            })
        }


        const borrarBdd=async()=>{

            await cliente
            .mutate({
                mutation: gql`
                mutation{
                    eliminarEmpresaBdd(ID_BDD:${this.state.bddSeleccionada}){ID_BDD,ID_EMPRESA}
                }
                `
            })

            await cliente
            .mutate({
                mutation: gql`
                mutation{
                    eliminarbddsPerfilesByBdd(ID_BDD:${this.state.bddSeleccionada}){ID_BDD,ID_PERFIL}
                }
                `
            })

            await cliente
            .mutate({
                mutation: gql`
                mutation{
                    eliminarBdd(ID_BDD:${this.state.bddSeleccionada}){ID_BDD}
                }
                `
            }).then(()=>{
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({bddSeleccionada: null})
                this.cargarBdds()
                this.cerrarModal()
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            
        }

        const borrarUsuario=()=>{

            cliente
            .mutate({
                mutation: gql`
                mutation{
                    eliminarUsuarioLogin(ID_USUARIO:${this.state.usuarioSeleccionado.ID_USUARIO}){ID_USUARIO}
                }
                `
            }).then(()=>{
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({usuarioSeleccionado: null})
                this.cargarUsuarios()
                this.cerrarModal()
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            
        }

        const guardarCliente=()=>{

            if(!this.state.nombreCliente)return

            //comprobar que el cliente no exista
            let existe=this.state.empresas.find(cliente=>{return cliente.label===this.state.nombreCliente})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }


            cliente
            .mutate({
                mutation: gql`
                mutation{
                    crearEmpresa(NOMBRE:"${this.state.nombreCliente}",NUMERO_USUARIOS:${this.state.numUsuarios},NUMERO_EMPRESAS:${this.state.numEmpresas},FECHA_FIN:"${moment(this.state.fechaFuncionamiento).format("YYYY-MM-DD")}"){ID_EMPRESA}
                }
                `
            }).then(async(result)=>{
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.cargarClientes()
                await this.cerrarModal2()

                cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        crearEmpresasPerfil(VALORES:{ID_PERFIL:${3},ID_EMPRESA:${result.data.crearEmpresa.ID_EMPRESA}}){ID_PERFIL,ID_EMPRESA}
                    }
                    `
                })

                // Asociacion de la nueva empresa con el perfil 'Admin'
                cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        crearEmpresasPerfil(VALORES:{ID_PERFIL:${2},ID_EMPRESA:${result.data.crearEmpresa.ID_EMPRESA}}){ID_PERFIL,ID_EMPRESA}
                    }
                    `
                })

                let clienteCreado=this.state.empresas.find(cliente=>{return cliente.value===result.data.crearEmpresa.ID_EMPRESA})
                this.setState({empresaSeleccionada:clienteCreado})
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })

            
        }
    
        const actualizarCliente=()=>{

            if(!this.state.nombreCliente)return

            let existe=this.state.empresas.find(cliente=>{return cliente.label===this.state.nombreCliente && cliente.value!==this.state.empresaSeleccionada.value})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            cliente
            .mutate({
                mutation: gql`
                mutation{
                    actualizarEmpresa(ID_EMPRESA:${this.state.empresaSeleccionada.value},VALORES:{NOMBRE:"${this.state.nombreCliente}",NUMERO_USUARIOS:${this.state.numUsuarios},NUMERO_EMPRESAS:${this.state.numEmpresas},FECHA_FIN:"${moment(this.state.fechaFuncionamiento).format("YYYY-MM-DD")}"}){ID_EMPRESA}
                }
                `
            }).then(async(result)=>{
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                await this.cargarClientes()
                await this.cerrarModal2()
                let clienteActualizado=this.state.empresas.find(cliente=>{return cliente.value===this.state.empresaSeleccionada.value})
                this.setState({empresaSeleccionada:clienteActualizado})
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const limpiarArbol=()=>{
            let arbol=this.state.arbol
            for(let el of arbol){
                el.EDITAR=false
                el.VER=false
                if("ID_PADRE" in el===false){
                    el.deshabilitado=false
                }
            }
        }

        const marcarArbol=()=>{
            let arbol=this.state.arbol
            for(let el of arbol){
                el.EDITAR=true
                el.VER=true
                el.deshabilitado=false
            }
        }


        const comprobarNumOpciones=()=>{
            let opciones=0

            let arbol=this.state.arbol
            for(let el of arbol){
               if(el.EDITAR || el.VER)opciones++
            }

            return opciones
            
        }

        const estanTodasSeleccionadas=async()=>{
            let opciones=0

            let arbol=this.state.arbol
            for(let el of arbol){
               if(el.EDITAR && el.VER)opciones++
            }

            if(arbol.length===opciones)return true

            return false
            
        }

        const abrirModalNuevoRol=async()=>{

            if(!this.state.clienteSeleccionadoUsuario && !this.state.usuarioSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            await limpiarArbol()

            await this.setState({
                modalRol:true,
                nuevoRolActivado:true,
                marcarTodos:false,
                editarRolActivado:false,
                bddsSel:[],
                nombreRol:null
            })

        }

        const abrirModalEditarRol=async()=>{
            
            if(!this.state.perfil){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //obtencion de las bdds del perfil.
            await cliente
            .query({
                query: gql`{perfilesEmpBdds(ID_PERFIL:${this.state.perfil.value}){ID_BDD,NOMBRE}}`,fetchPolicy:'network-only',
            })
            .then(result =>{
                let bdds=[]
                for(let bdd of result.data.perfilesEmpBdds){
                    bdds.push({
                        "value":bdd.ID_BDD,
                        "label":bdd.NOMBRE
                    })
                }
                this.setState({bddsSel:bdds})
                let perf=this.state.perfil
                perf.bdds=bdds
                this.setState({perfil:perf})
            })

            let arbolActual=JSON.parse(JSON.stringify(this.state.arbolNoDatos))
            let arbolPerfil=JSON.parse(this.state.perfil.arbol.replace(/'/g, '"'))
            //comparamos el arbol con el arbol del perfil y marcamos las opciones que coincidan
            if(this.state.perfil.arbol){
                for(let hoja of arbolPerfil){
                    let hojaActual=arbolActual.find(ar=>{return ar.id===hoja.id})
                    //si econtramos la opcion del menu le pasamos sus valores
                    if(hojaActual){
                        hojaActual.VER=hoja.VER
                        hojaActual.EDITAR=hoja.EDITAR
                    }
                }
            }
            //si tenemos deshabilitado algun padre, deshabilitamos tambien los hijos.
            for(let hoja of arbolActual){
                //si es padre
                if("ID_PADRE" in hoja){

                    //obtenemos el index de la hoja en el arbol
                    arbolActual.find((hojaBuscada,index)=>{
                        if(hoja.id===hojaBuscada.id){
                            //si esta deshabilitado
                            if(!hoja.VER){
                                for(let i=index+1;i<arbolActual.length;i++){
                                    if("ID_PADRE" in arbolActual[i])break
                                    arbolActual[i].VER=false
                                    arbolActual[i].EDITAR=false
                                    arbolActual[i].deshabilitado=false
                                }
                            }

                        }

                        return hoja.id===hojaBuscada.id

                    })

                }
            }
            /*
            for(let i=rowIndex+1;i<arbol.length;i++){
                if("ID_PADRE" in arbol[i])break

                arbol[i].VER=false
                arbol[i].EDITAR=false
                arbol[i].deshabilitado=true

            }*/

            await this.setState({
                modalRol:true,
                nuevoRolActivado:false,
                editarRolActivado:true,
                nombreRol:this.state.perfil.label,
                arbol:arbolActual
            })

            //comporbar si estan todas las opciones marcadas, para dejar marcado el check
            //marcar todas las opciones.
            let todas= await estanTodasSeleccionadas()

            if(todas){
                this.setState({marcarTodos:true})
            }else{
                this.setState({marcarTodos:false})
            }

        }

        const nuevoRol=()=>{

            if(!this.state.nombreRol)return
            let existe=this.state.perfiles.find(perfil=>{return perfil.label===this.state.nombreRol})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            if(this.state.bddsSel.length===0)return

            if(comprobarNumOpciones()===0){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let cliente = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/bdds",
                credentials: 'include',
            });

            let arbolreplace=JSON.stringify(this.state.arbol).replace(/"/g, "'");

            cliente
            .mutate({
                mutation: gql`
                    mutation{crearPerfil(NOMBRE:"${this.state.nombreRol}",ARBOL:"${arbolreplace}"){ID_PERFIL,ARBOL}}
                `
            }).then(async(result)=>{
                alert(i18next.t("insertcorrecto",{ns:"global"}))

                //Asociamos el prefil a la empresa
                await  cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            crearEmpresasPerfil(VALORES:{ID_PERFIL:${result.data.crearPerfil.ID_PERFIL},ID_EMPRESA:${parseInt(this.state.rol)===1?(this.state.clienteSeleccionadoUsuario?this.state.clienteSeleccionadoUsuario.value:this.state.usuarioSeleccionado.ID_EMPRESA):this.state.datosEmpresaActual.ID_EMPRESA}}){ID_EMPRESA,ID_PERFIL}
                        }
                        `
                })

                //Las bdds a la que el perfil tiene acces
                for(let bdd of  this.state.bddsSel){
                   

                    await  cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            crearbddsPerfiles(VALORES:{ID_PERFIL:${result.data.crearPerfil.ID_PERFIL},ID_BDD:${bdd.value}}){ID_BDD,ID_PERFIL}
                        }
                        `
                    })

                    /*await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            useBdd(NOMBRE:"${parseInt(this.state.rol)===1?bdd.label:bdd.label.substr(0,bdd.label.lastIndexOf("_"))+"_"+this.state.datosEmpresaActual.ID_EMPRESA}")
                        }
                        `
                    })*/

                    //creamos los valores en la tabla de perfiles_opciones por cada una de las opciones del arbol
                    //let arbol=JSON.parse(result.data.crearPerfil.ARBOL.replace(/'/g, '"'))
                    /*for(let el of arbol){

                        await clienteAPI
                        .mutate({
                            mutation: gql`
                            mutation{
                                crearPerfilOpcion(VALORES:{ID_PERFIL:${result.data.crearPerfil.ID_PERFIL},ID_OPCION:${el.id},VER:${el.VER},EDITAR:${el.EDITAR}}){ID_PERFIL_OPCION,ID_PERFIL}
                            }
                            `
                        })
                    }*/
                    
                }

                //limpiamos la bdd seleccionada.
                await cliente
                .query({
                    query: gql`
                    {
                        limpiarBddSeleccionada
                    }
                    `
                })

                if(parseInt(this.state.rol)===1){
                    await this.cargarPerfiles(this.state.clienteSeleccionadoUsuario?this.state.clienteSeleccionadoUsuario.value:this.state.usuarioSeleccionado.ID_EMPRESA)
                }else{
                    await this.cargarPerfiles(this.state.datosEmpresaActual.ID_EMPRESA)
                }               
                await this.cerrarModal2()

                //autoSeleccionamos el nuevo perfil creado.
                let perfCreado=await this.state.perfiles.find(perfil=>{return perfil.value===result.data.crearPerfil.ID_PERFIL})
                await this.setState({perfil:perfCreado})
            

            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })
       


        }


        const editarRol=()=>{

            if(!this.state.nombreRol)return
            let existe=this.state.perfiles.find(perfil=>{return perfil.label===this.state.nombreRol && perfil.value!==this.state.perfil.value})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            if(this.state.bddsSel.length===0)return


            if(comprobarNumOpciones()===0){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let cliente = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/bdds",
                credentials: 'include',
            });

            let arbolreplace=JSON.stringify(this.state.arbol).replace(/"/g, "'");

            cliente
            .mutate({
                mutation: gql`
                mutation{
                    actualizarPerfil(ID_PERFIL:${this.state.perfil.value},VALORES:{NOMBRE:"${this.state.nombreRol}",ARBOL:"${arbolreplace}"}){ID_PERFIL,ARBOL}
                }
                `
            }).then(async(result)=>{
                alert(i18next.t("updatecorrecto",{ns:"global"}))

                //eliminamos los antiguos
                await cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        eliminarbddsPerfiles(ID_PERFIL:${this.state.perfil.value}){ID_BDD,ID_PERFIL}
                    }
                    `
                })

                //obtencion de las bdds del perfil.
                /*for(let bdd of await this.state.perfil.bdds){

                    //Accedemos a cada bdd y eliminamos el perfil de perfiles opciones
                    await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            useBdd(NOMBRE:"${parseInt(this.state.rol)===1?bdd.label:bdd.label.substr(0,bdd.label.lastIndexOf("_"))+"_"+this.state.datosEmpresaActual.ID_EMPRESA}")
                        }
                        `
                    })

                    /*await clienteAPI
                    .mutate({
                        mutation: gql`
                        mutation{
                            eliminarPerfilOpcion(ID_PERFIL:${this.state.perfil.value}){ID_PERFIL_OPCION,ID_PERFIL}
                        }
                        `
                    })*/

                //}
                
                
                //lo insertamos en su empresa correspondiente
                for(let bdd of this.state.bddsSel){

                    //Asociamos el perfil a la bdd
                    await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            crearbddsPerfiles(VALORES:{ID_BDD:${bdd.value},ID_PERFIL:${this.state.perfil.value}}){ID_PERFIL,ID_BDD}
                        }
                        `
                    })

                    /*await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            useBdd(NOMBRE:"${parseInt(this.state.rol)===1?bdd.label:bdd.label.substr(0,bdd.label.lastIndexOf("_"))+"_"+this.state.datosEmpresaActual.ID_EMPRESA}")
                        }
                        `
                    })*/

                    //creamos los valores en la tabla de perfiles_opciones por cada una de las opciones del arbol
                    //let arbol=JSON.parse(result.data.actualizarPerfil.ARBOL.replace(/'/g, '"'))
                    /*for(let el of arbol){

                        await clienteAPI
                        .mutate({
                            mutation: gql`
                                mutation{crearPerfilOpcion(VALORES:{ID_PERFIL:${result.data.actualizarPerfil.ID_PERFIL},ID_OPCION:${el.id},VER:${el.VER},EDITAR:${el.EDITAR}}){ID_PERFIL_OPCION,ID_PERFIL}}
                            `
                        })
                    }*/
                }

                //limpiamos la bdd seleccionada.Es decir cerramos la conexion
                await cliente
                .query({
                    query: gql`
                    {
                        limpiarBddSeleccionada
                    }
                    `
                })

                if(parseInt(this.state.rol)===1){
                    await this.cargarPerfiles(this.state.clienteSeleccionadoUsuario?this.state.clienteSeleccionadoUsuario.value:this.state.usuarioSeleccionado.ID_EMPRESA)
                }else{
                    await this.cargarPerfiles(this.state.empresaSeleccionada)
                }
                await this.cerrarModal2()

                //autoSeleccionamos el nuevo perfil creado.
                let perfCreado=await this.state.perfiles.find(perfil=>{return perfil.value===this.state.perfil.value})
                await this.setState({perfil:perfCreado})

            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })
            

        }

        const cambiarContraseñaNueva1 = (e) => {
            this.setState({contraseñaNueva1: e.target.value})
        }

        const cambiarContraseñaNueva2 = (e) => {
            this.setState({contraseñaNueva2: e.target.value})
        }


        const handleEnableVer = async(rowIndex) => {

            let arbol = this.state.arbol
            let nuevoValor=!arbol[rowIndex].VER
            arbol[rowIndex].VER = nuevoValor
            if("ID_PADRE" in arbol[rowIndex] && nuevoValor){
                for(let i=rowIndex+1;i<arbol.length;i++){
                    if("ID_PADRE" in arbol[i])break

                    arbol[i].VER=false
                    arbol[i].EDITAR=false
                    arbol[i].deshabilitado=false

                }
              
            }else if("ID_PADRE" in arbol[rowIndex] && !nuevoValor){
                for(let i=rowIndex+1;i<arbol.length;i++){
                    if("ID_PADRE" in arbol[i])break

                    arbol[i].VER=false
                    arbol[i].EDITAR=false
                    arbol[i].deshabilitado=false

                }
            }

            this.setState({arbol:arbol})
        }

        const handleEnableEditar = (rowIndex) => {

            let arbol = this.state.arbol

            arbol[rowIndex].EDITAR = !arbol[rowIndex].EDITAR
            this.setState({arbol:arbol})
        }

        const cambiarNombreRol=(ev)=>{
            this.setState({nombreRol:ev.currentTarget.value})
        }

        const marcarTodos=()=>{
            let val=!this.state.marcarTodos
            this.setState({marcarTodos:val})

            if(val){
                marcarArbol()
            }else{
                limpiarArbol()
            }
        }

        const elegirBdds=(val)=>{
            this.setState({bddsSel:val})
        }

        const actualizarClave=()=>{

            if(!this.state.contraseñaNueva1)return
            if(!this.state.contraseñaNueva2)return
            if(this.state.contraseñaNueva1!==this.state.contraseñaNueva2)return
            let patron=/(?=^.{6,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*/

            if(!patron.test(this.state.contraseñaNueva1)){
                alert(i18next.t("clavenovalida",{ns:"main"}))
                return
            }


            cliente
            .mutate({
                mutation: gql`
                mutation{
                    actualizarUsuarioLogin(ID_USUARIO:${this.state.idUsuario},VALORES:{
                        CLAVE:"${this.state.contraseñaNueva1}",
                        CLAVE_ACTUALIZADA:${true}
                    }){ID_USUARIO}
                }
                `
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
                return
            }).then(()=>{
                window.location.reload()
            })



        }

        const cerrarSesion=()=>{

            let client = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/bdds",
                credentials: 'include',
            });

            client
              .query({
                query: gql`
                  {
                    cerrarSesionUsuariosLoginActual
                  }
                `
            }).then(()=>{
              localStorage.clear();
              this.props.setLogueado(false);
              this.props.limpiarBddSeleccionada()
            })
        
        }
        
        const handleJoyrideCallback=async(data)=>{
            
            const { action, index, status, type } = data;
         
            if(data.action==="close"){

                localStorage.setItem("ayuda",false)
                await this.setState({
                    modalCrearBdd:false,
                    modalCliente:false,
                    // eslint-disable-next-line no-dupe-keys
                    modalCrearBdd:false,
                    modalCrearEditarUsuario:false,
                    modalRol:false
                })

            }else{


                if ([EVENTS.STEP_AFTER].includes(type)) {

                    let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
    
                    if(this.state.rol===1){
    
                        //paso en el que nos encontremos.
                        switch(paso){
                   
                            case 1:
                                await this.setState({modalCrearBdd:false})
                            break;
                            case 2:
                                await this.setState({modalCrearBdd:true})
                            break;
                            case 5:
                                await this.setState({modalCliente:false,modalCrearBdd:true})
                            break;
                            case 6:
                            case 7:
                            case 8:
                                await this.setState({modalCliente:true})
                            break;
                            case 9:
                                await this.setState({modalCliente:false,modalCrearBdd:false})
                            break;
                            case 10:
                                await this.setState({modalCrearEditarUsuario:false})
                            break;
                            case 11:
                                await this.setState({modalCrearEditarUsuario:true})
                            break;
                            case 15:
                            case 18:
                                await this.setState({modalRol:false,nuevoRolActivado:false})
                            break;
                            case 16:
                            case 17:
                                await this.setState({modalRol:true,nuevoRolActivado:true})
                            break;
    
                        }
    
                    }else if(this.state.rol===2){
    
                        //paso en el que nos encontremos.
                        switch(paso){
                   
                                case 1:
                                    await this.setState({modalCrearBdd:false})
                                break;
                      
                                case 2:
                                    await this.setState({modalCrearEditarUsuario:false})
                                break;
                                case 3:
                                    await this.setState({modalCrearEditarUsuario:true})
                                break;
                                case 6:
                                case 9:
                                    await this.setState({modalRol:false,nuevoRolActivado:false})
                                break;
                                case 7:
                                case 8:
                                    await this.setState({modalRol:true,nuevoRolActivado:true})
                                break;
    
                        }
                    
                    }
           
              
                    await this.setState({ pasoGuia: paso });   
    
    
                }
    
                //si termina la guia.
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                    localStorage.setItem("ayuda",false)
                    await this.setState({modalCrearEditarUsuario:false})
                    this.setState({pasoGuia:0})
                }
            }


        }

		const guardarPerfilEditado = () => {

            if(!this.state.clienteSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.nombreCliente)return

            //comprobar que el cliente no exista
            let existe=this.state.empresas.find(cliente=>{return cliente.label===this.state.nombreCliente})
            if(existe && existe.value!==this.state.clienteSeleccionado.value){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            cliente
            .mutate({
                mutation: gql`
                mutation{
                    actualizarEmpresa(ID_EMPRESA:${this.state.clienteSeleccionado.value},VALORES:{NOMBRE:"${this.state.nombreCliente}",NUMERO_USUARIOS:${this.state.numUsuarios},NUMERO_EMPRESAS:${this.state.numEmpresas},FECHA_FIN:"${moment(this.state.fechaFuncionamiento).format("YYYY-MM-DD")}"}){ID_EMPRESA}
                }
                `
            }).then(async(result)=>{
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                await this.cargarClientes()
                let clienteActualizado=this.state.empresas.find(cliente=>{return cliente.value===this.state.clienteSeleccionado.value})
                this.setState({clienteSeleccionado:clienteActualizado})
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })          

		}

        const padreFormato=(cell, row) => {
            if (row.ID_PADRE===null) {
                return (
                    <strong>{cell}</strong>
                );
            } else {
                return (
                    cell
                );
            }
        }

        const checkVerFormato=(cell,row,rowIndex)=>{
            return (
                <Checkbox 
                    checked={cell}
                    disabled={row.deshabilitado} 
                    onChange={()=>{handleEnableVer(rowIndex)}} 
                />
            );
        }

        const checkEditarFormato=(cell,row,rowIndex)=>{
            return (
                <Checkbox 
                    checked={cell}
                    disabled={row.deshabilitado} 
                    onChange={()=>{handleEnableEditar(rowIndex)}} 
                />
            );
        }

        const nameBdd=(cell)=>{
            return (
                cell.substr(0,cell.lastIndexOf("_"))
            );
        }

        const columnsPermisos = [
            {
                dataField: 'TEXTO',
                text: i18next.t("menu",{ns:"global"}),
                sort: false,
                formatter: padreFormato,
            },
            {
                dataField: 'VER',
                text: i18next.t("see",{ns:"global"}),
                sort: false,
                formatter: checkVerFormato,
            },
            {
                dataField: 'EDITAR',
                text: i18next.t("editar",{ns:"global"}),
                sort: false,
                formatter: checkEditarFormato,
            }
        ];

        const handleOnSelectUsuario=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    usuarioSeleccionado: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    bddSeleccionada: row,
                    bddSeleccionadaNombre: row.NOMBRE
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const columnsUsuarios = [
            {
                dataField: 'NOM_EMPRESA',
                text: i18next.t("colcliente",{ns:"main"}),
                sort: true
            },
            {
                dataField: 'USUARIO',
                text: i18next.t("colusuario",{ns:"main"}),
                sort: true
            }
        ];

        const columns = [
            {
                dataField: 'NOM_EMPRESA',
                text: i18next.t("colcliente",{ns:"main"}),
                sort: true
            },
            {
                dataField: 'NOMBRE',
                text: i18next.t("colempresa",{ns:"main"}),
                sort: true,
                formatter: nameBdd
            }
        ];
        
        const borrarCliente = async() => {
            
            if(!this.state.clienteSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let bddsCliente=await cliente
            .query({
                query: gql`{EmpresasBdds(FILTROS:{ID_EMPRESA:${this.state.clienteSeleccionado.value}}){ID_EMPRESA,BDD{NOMBRE},ID_BDD}}`,fetchPolicy:'network-only',
            })
            .then(result =>{
                return result.data.EmpresasBdds
            });

            for(let bdd of bddsCliente){

                await cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        eliminarEmpresaBdd(ID_BDD:${bdd.ID_BDD}){ID_BDD,ID_EMPRESA}
                    }
                    `
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

                await cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        eliminarbddsPerfilesByBdd(ID_BDD:${bdd.ID_BDD}){ID_BDD,ID_PERFIL}
                    }
                    `
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

                await cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        eliminarBdd(ID_BDD:${bdd.ID_BDD}){ID_BDD}
                    }
                    `
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

             


            }

          
            let empresasUsuarios= await cliente
            .query({
                query: gql`{EmpresasUsuarios(FILTROS:{ID_EMPRESA:${this.state.clienteSeleccionado.value}}){ID_EMPRESA,USUARIO{USUARIO},ID_USUARIO}}`,fetchPolicy:'network-only',
            })
            .then(result =>{
                return result.data.EmpresasUsuarios
            });

            for(let usuario of empresasUsuarios){


                await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            eliminarEmpresaUsuario(ID_EMPRESA:${this.state.clienteSeleccionado.value},ID_USUARIO:${usuario.ID_USUARIO}){ID_USUARIO}
                        }
                        `
                    }).catch(err=>{
                        console.log(err)
                        alert(i18next.t("error",{ns:"global"}))
                    })
                

                await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            eliminarUsuarioLoginDefinitivo(ID_USUARIO:${usuario.ID_USUARIO}){ID_USUARIO}
                        }
                        `
                    }).then(()=>{
                        this.setState({usuarioSeleccionado: null})
                    }).catch(err=>{
                        console.log(err)
                        alert(i18next.t("error",{ns:"global"}))
                    })
                    
            }


            let empresasPerfiles=await cliente
            .query({
                query: gql`{perfiles(ID_EMPRESA:${this.state.clienteSeleccionado.value}){ID_PERFIL,NOMBRE,ARBOL}}`,fetchPolicy:'network-only',
            })
            .then(result =>{
                return result.data.perfiles
            })

            for(let perfil of empresasPerfiles){

                await cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        eliminarEmpresasPerfil(ID_EMPRESA:${this.state.clienteSeleccionado.value},ID_PERFIL:${perfil.ID_PERFIL}){ID_PERFIL}
                    }
                    `
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                })


                if(perfil.ID_PERFIL!==2 && perfil.ID_PERFIL!==3){
                    await cliente
                    .mutate({
                        mutation: gql`
                        mutation{
                            eliminarPerfil(ID_PERFIL:${perfil.ID_PERFIL}){ID_PERFIL}
                        }
                        `
                    }).catch(err=>{
                        console.log(err)
                        alert(i18next.t("error",{ns:"global"}))
                    })
                }


            }

            await cliente
            .mutate({
                mutation: gql`
                mutation{
                    eliminarEmpresa(ID_EMPRESA:${this.state.clienteSeleccionado.value}){ID_EMPRESA}
                }
                `
            }).then(()=>{
                this.setState({clienteSeleccionado: null})
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })

            await this.cargarUsuarios()
            await this.cargarBdds()
            await this.cargarClientes()
            await this.setState({ clienteSeleccionado: null })
            
            alert(i18next.t("deletecorrecto",{ns:"global"}))
		}
        
        if(this.state.empresaCaducada===null || this.state.claveActualizada===null)return <></>
        return (
            {...this.state.rol===1 || (this.state.empresaCaducada===false && this.state.claveActualizada===true)?
            <>

            <Joyride
                run={localStorage.getItem("ayuda")==="true"?true:false}
                callback={handleJoyrideCallback}
                showProgress={true}
                stepIndex={this.state.pasoGuia}
                steps={this.state.rol===1?stepsSuperAdmin:this.state.rol===2?stepsAdmin:stepsOtros}
                continuous ={true}
                locale={{ back: i18next.t("return",{ns:"global"}), close: i18next.t("close",{ns:"global"}), last: i18next.t("last",{ns:"global"}), next: i18next.t("next",{ns:"global"}), skip: i18next.t("jump",{ns:"global"}) }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        primaryColor: '#E31E24',
                    }
                }}
            />
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={this.cerrarModal}
                title={i18next.t("empresas.titleaddempresa",{ns:"main"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                isOpen={this.state.modalCrearBdd}
            >  
                <div className="pixelAlrededorGrande" id="dialogo-crear-bdd">
                    <div id="datos-empresa">
                        <FormGroup
                            label={i18next.t("empresas.nombreempresa",{ns:"main"})+":"}
                            labelFor="empresa"
                        >
                                <InputGroup id="input-nombre-empresa" value={this.state.nombreEmpresa || ''} onChange={this.cambiarNuevaEmpresa}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nombreempresa",{ns:"main"})} intent={this.state.nombreEmpresa?"primary":"danger"} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("empresas.cif",{ns:"main"})+":"}
                            labelFor="CIF"
                            intent="danger"
                            helperText={this.state.cifInvalido?i18next.t("empresas.validcif",{ns:"main"}):""}
                        >
                            <InputGroup id="input-cif" value={this.state.CIF || ''} onChange={this.cambiarNuevoCif}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.cif",{ns:"main"})} intent={!this.state.CIF ||this.state.cifInvalido?"danger":"primary"} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("empresas.razonsocial",{ns:"main"})+":"}
                            labelFor="razon"
                            intent="danger"
                            helperText={!this.state.razonSocial?i18next.t("emptyfield",{ns:"global"})+i18next.t("empresas.razonsocial",{ns:"main"}):""}
                        >
                           <InputGroup id="input-razon-social" value={this.state.razonSocial || ''} onChange={this.cambiarNuevaRazon}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.razonsocial",{ns:"main"})} intent={!this.state.razonSocial?"danger":"primary"} />
                        </FormGroup>
                    </div>
                    <FormGroup
                        label={i18next.t("colcliente",{ns:"main"})+":"}
                        labelFor="Empresa"
                    >
                        <div id="select-cliente" style={!this.state.empresaSeleccionada?{border:"1px solid red"}:{}} >
                            <Select
                                options={this.state.empresas}
                                className="pixelAlrededor"
                                onChange={this.seleccionEmpresa}
                                value={this.state.empresaSeleccionada}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Button id="boton-crear-cliente" disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoClienteActivado} onClick={this.abrirCliente} />
                        <Button className="bp3-minimal" icon="edit" text={i18next.t("editar",{ns:"global"})} active={this.state.editarClienteActivado}  onClick={this.editarCliente}/>
                    </FormGroup> 
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={this.cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip  content={i18next.t("guardar",{ns:"global"})} position="top">
                                <AnchorButton
                                    id="boton-guardar-bdd"
                                    intent={Intent.PRIMARY}
                                    onClick={this.guardarDatosBdd}
                                >
                                {i18next.t("guardar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={this.cerrarModal2}
                title={this.state.nuevoClienteActivado?i18next.t("empresas.btncrearcliente",{ns:"main"}):i18next.t("empresas.btneditarcliente",{ns:"main"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalCliente}
            >  
                <div className="pixelAlrededorGrande">
                    <FormGroup
                        label={i18next.t("empresas.nombrecliente",{ns:"main"})+":"}
                        labelFor="empresa"
                    >
                            <InputGroup id="input-nombre-cliente" value={this.state.nombreCliente || ''} onChange={this.cambiarNuevoCliente}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nombrecliente",{ns:"main"})} intent={this.state.nombreCliente?"primary":"danger"} />
                    </FormGroup>

                    <FormGroup
                        label={i18next.t("empresas.nromaxusuario",{ns:"main"})+":"}
                        labelFor="cliente"
                    >
                            <InputGroup id="input-maximo-usuarios" type="number" min={1} step={1} value={this.state.numUsuarios || ''} onChange={this.cambiarNumUsuarios}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nromaxusuario",{ns:"main"})} intent={this.state.numUsuarios?"primary":"danger"} />
                    </FormGroup>

                    <FormGroup
                        label={i18next.t("empresas.nromaxempresa",{ns:"main"})+":"}
                        labelFor="empresas"
                    >
                            <InputGroup id="input-maximo-empresas" type="number" min={1} step={1} value={this.state.numEmpresas || ''} onChange={this.cambiarNumEmpresas}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nromaxempresa",{ns:"main"})} intent={this.state.numEmpresas?"primary":"danger"} />
                    </FormGroup>

                    <FormGroup
                        label={i18next.t("empresas.fechafuncionamiento",{ns:"main"})+":"}
                        labelFor="fecha-funcionamiento"
                        inline={true}
                        className="pixelAlrededor" 
                    >
                        <div id="input-fecha-funcionamiento">
                            <DateInput minDate={new Date(today.getFullYear()-5, 1 ,1)}
                                maxDate={new Date(today.getFullYear()+5, 11 ,31)}
                                {...jsDateFormatter} 
                                onChange={this.cambiarFechaFuncionamiento}
                                align={Alignment.RIGHT}
                                value={this.state.fechaFuncionamiento}
                                dayPickerProps={{
                                  locale: i18next.language,
                                  months: (i18next.language=='es')?DateFormatString.months:DateFormatString.months_en,
                                  weekdaysLong: (i18next.language=='es')?DateFormatString.weekDaysLong:DateFormatString.weekDaysLong_en,
                                  weekdaysShort: (i18next.language=='es')?DateFormatString.weekDaysShort:DateFormatString.weekDaysShort_en,
                                  firstDayOfWeek: 1,
                                }}
                            />
                        </div>
                    </FormGroup>
                    
                 
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={this.cerrarModal2}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                                <AnchorButton
                                    id="boton-guardar-cliente"
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoClienteActivado?guardarCliente:actualizarCliente}
                                >
                                {i18next.t("guardar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Dialog>


            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={this.cerrarModal}
                title={!this.state.usuarioSeleccionado?i18next.t("usuarios.btncrear",{ns:"main"}):i18next.t("usuarios.btneditar",{ns:"main"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                usePortal={true}
                isOpen={this.state.modalCrearEditarUsuario}
            >  
                <div id="dialogo-crear-usuario" className="pixelAlrededorGrande">
                    <FormGroup
                        label={i18next.t("usuarios.email",{ns:"main"})+":"}
                        labelFor="usuario"
                        intent="danger"
                        helperText={this.state.correoIncorrecto?i18next.t("emptyfield",{ns:"global"})+i18next.t("usuarios.email",{ns:"main"}):""}
                    >
                            <InputGroup id="email-usuario" value={this.state.nombreUsuario || ''} onChange={this.cambiarNombreUsuario}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("usuarios.email",{ns:"main"})} intent={!this.state.nombreUsuario || this.state.correoIncorrecto?"danger":"primary"} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("usuarios.name",{ns:"main"})+":"}
                        labelFor="usuario"
                        intent="danger"
                        helperText={!this.state.nombre || this.state.nombre===""?i18next.t("emptyfield",{ns:"global"})+i18next.t("usuarios.name",{ns:"main"}):""}
                    >
                            <InputGroup id="nombre-usuario" value={this.state.nombre || ''} onChange={this.cambiarNombre}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("usuarios.name",{ns:"main"})} intent={!this.state.nombre || this.state.nombre===""?"danger":"primary"} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("usuarios.lastname",{ns:"main"})+":"}
                        labelFor="usuario"
                        intent="danger"
                        helperText={!this.state.apellidos || this.state.apellidos===""?i18next.t("emptyfield",{ns:"global"})+i18next.t("usuarios.lastname",{ns:"main"}):""}
                    >
                            <InputGroup id="apellidos-usuario" value={this.state.apellidos || ''} onChange={this.cambiarApellidos}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("usuarios.lastname",{ns:"main"})} intent={!this.state.apellidos || this.state.apellidos===""?"danger":"primary"} />
                    </FormGroup>

                    {parseInt(this.state.rol)===1 && !this.state.usuarioSeleccionado
                    ?
                        <FormGroup
                            label={i18next.t("colcliente",{ns:"main"})+":"}
                            labelFor="cliente"
                        >
                            <div  id="select-cliente-usuario" style={!this.state.clienteSeleccionadoUsuario?{border:"1px solid red"}:{}} >
                                <Select
                                    options={this.state.empresas}
                                    className="pixelAlrededor"
                                    onChange={this.seleccionClienteUsuario}
                                    value={this.state.clienteSeleccionadoUsuario}
                                />
                            </div>
                        </FormGroup>
                    :<></>}

                    <FormGroup
                        label={i18next.t("usuarios.profile",{ns:"main"})+":"}
                        labelFor="perfil"
                    >
                        <div id="select-perfil-usuario" style={!this.state.perfil?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.perfiles}
                                className="pixelAlrededor"
                                onChange={this.seleccionPerfil}
                                value={this.state.perfil}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Button id="boton-crear-perfil" disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoRolActivado} onClick={abrirModalNuevoRol} />
                        <Button id="boton-editar-perfil" className="bp3-minimal" icon="edit" text={i18next.t("editar",{ns:"global"})} active={this.state.editarRolActivado}  onClick={abrirModalEditarRol}/>
                    </FormGroup> 
            
                    <FormGroup
                        label={i18next.t("usuarios.password",{ns:"main"})+":"}
                        labelFor="contraseña"
                    >
                        <p>{i18next.t("usuarios.longitudpermitida",{ns:"main"})}:</p>
                        <ul>
                            <li>{i18next.t("usuarios.letraminus",{ns:"main"})}</li>
                            <li>{i18next.t("usuarios.letramayus",{ns:"main"})}</li>
                            <li>{i18next.t("usuarios.unnro",{ns:"main"})}</li>
                            <li>{i18next.t("usuarios.caracteresespecial",{ns:"main"})}</li>
                        </ul>
                        <InputGroup id="clave-usuario" type={this.state.showPassword ? "text" : "password"}value={this.state.contrasena1 || ''} onChange={this.cambiarContraseña1}  placeholder={i18next.t("usuarios.newpassword",{ns:"main"})} intent={(this.state.contrasena1 !== this.state.contrasena2 || !this.state.contrasena1 && !this.state.usuarioSeleccionado) ?"danger":"primary"} />
                        <br />
                        <InputGroup type={this.state.showPassword ? "text" : "password"} value={this.state.contrasena2 || ''} onChange={this.cambiarContraseña2}  placeholder={i18next.t("usuarios.repitepassword",{ns:"main"})} intent={(this.state.contrasena2 !== this.state.contrasena1 || !this.state.contrasena2 && !this.state.usuarioSeleccionado) ?"danger":"primary"} />
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={this.cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                                <AnchorButton
                                    id="boton-guardar-usuario"
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.usuarioSeleccionado?this.actualizarUsuario:this.guardarDatosUsuario}
                                >
                                {i18next.t("guardar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("empresas.btnbackup",{ns:"main"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalBackupBdd}
            >
            <div className={Classes.DIALOG_BODY}>
                <h4>
                ¡{i18next.t("atencion",{ns:"main"}).toUpperCase()}!
                <br />
                	{i18next.t("empresas.questionbackup",{ns:"main"})} 
                </h4>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={backupBdd}>
                    {i18next.t("yes",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("no",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>

            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("empresas.btnborrar",{ns:"main"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalBorrarBdd}
            >
            <div className={Classes.DIALOG_BODY}>
                <h4>
                ¡{i18next.t("atencion",{ns:"main"}).toUpperCase()}!
                <br />
                	{i18next.t("empresas.questiondel",{ns:"main"})} 
                </h4>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={borrarBdd}>
                    {i18next.t("yes",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("no",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>

            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("usuarios.btnborrar",{ns:"main"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalBorrarUsuario}
            >
            <div className={Classes.DIALOG_BODY}>
                <p>
                ¡{i18next.t("atencion",{ns:"main"}).toUpperCase()}!
                <br />
                {i18next.t("usuarios.questiondel",{ns:"main"})}
                </p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={borrarUsuario}>
                    {i18next.t("yes",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("no",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
        </Dialog>

        <Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="info-sign"
			onClose={this.cerrarModal2}
			title={this.state.nuevoRolActivado?i18next.t("usuarios.titleaddperfil",{ns:"main"}):i18next.t("usuarios.titleeditperfil",{ns:"main"})}
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			usePortal={true}
			onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
			isOpen={this.state.modalRol}
			className="pixelAlrededor dialog-medio-ancho"
		>
            <FormGroup
                label={i18next.t("usuarios.nombreperfil",{ns:"main"})+":"}
                labelFor="perfil"
                intent={!this.state.nombreRol?"danger":"primary"}
                helperText={!this.state.nombreRol?i18next.t("emptyfield",{ns:"global"})+i18next.t("usuarios.nombreperfil",{ns:"main"}):""}
            >
                <InputGroup id="nombre-perfil" value={this.state.nombreRol || ''} intent={!this.state.nombreRol?"danger":"primary"} onChange={cambiarNombreRol} />
            </FormGroup> 

            {(this.state.editarRolActivado && (this.state.perfil && (this.state.perfil.value!==2 && this.state.perfil.value!==3))) || this.state.nuevoRolActivado?
                <FormGroup
                    label={i18next.t("usuarios.empresaacceso",{ns:"main"})+":"}
                    labelFor="Cliente"
                >
                    <div id="select-empresas-perfil" style={!this.state.bddsSel.length>0?{border:"1px solid red"}:{}} >
                        <Select
                            isMulti
                            options={this.state.bdds.filter(bd=>bd.ID_EMPRESA==(parseInt(this.state.rol)===1?(this.state.clienteSeleccionadoUsuario?this.state.clienteSeleccionadoUsuario.value:(this.state.usuarioSeleccionado?this.state.usuarioSeleccionado.ID_EMPRESA:null)):this.state.datosEmpresaActual.ID_EMPRESA)).map(bdd=>{return {"ID_EMPRESA":bdd.ID_EMPRESA,"value":bdd.ID_BDD,"label":(parseInt(this.state.rol)===1?bdd.NOMBRE:bdd.NOMBRE.substr(0,bdd.NOMBRE.lastIndexOf("_"))) }  }) }
                            className="pixelAlrededor"
                            onChange={elegirBdds}
                            value={this.state.bddsSel}
                        />
                    </div>
                </FormGroup>
            :<></>}
           

            <Checkbox onChange={marcarTodos} checked={this.state.marcarTodos} label={i18next.t("marcartodos",{ns:"global"})} className="checkbox-recortado" />

            <FormGroup
                label={i18next.t("usuarios.tablapermisos",{ns:"main"})+":"}
            >
            
                <div id="tabla-permisos">
                    <BootstrapTable
                        keyField='id'
                        data={ this.state.arbol }
                        columns={ columnsPermisos }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        classes="table-white"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                </div>
            </FormGroup>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={this.cerrarModal2}>{i18next.t("close",{ns:"global"})}</Button>
                    </Tooltip>
                    <Tooltip content={i18next.t("editar",{ns:"global"})} position="top">
                        <AnchorButton
                            id="boton-guardar-perfil"
                            intent={Intent.PRIMARY}
                            disabled={this.state.perfil?((this.state.perfil.value===2 || this.state.perfil.value===3) && this.state.editarRolActivado?true:false):false}
                            onClick={this.state.nuevoRolActivado?nuevoRol:editarRol}
                        >
                            {i18next.t("aceptar",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                </div>
            </div>
        </Dialog>

		<Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="info-sign"
			onClose={this.cerrarModal}
			title={i18next.t("empresas.btneditarcliente",{ns:"main"})}
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			usePortal={true}
			onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
			isOpen={this.state.editarCliente2Activado}
			className="pixelAlrededor dialog-medio-ancho"
		>

            <div className={Classes.DIALOG_BODY}>
				<Select
					options={this.state.empresas}
					className="pixelAlrededor"
					onChange={this.seleccionCliente}
					value={this.state.clienteSeleccionado}
					backspaceRemovesValue={true}
					isClearable={true}
				/>
				{
					this.state.clienteSeleccionado !== null?
						<Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
							<FormGroup
								label={i18next.t("empresas.nombrecliente",{ns:"main"})+":"}
								labelFor="empresa"
							>
									<InputGroup id="input-nombre-cliente" value={this.state.nombreCliente || ''} onChange={this.cambiarNuevoCliente}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nombrecliente",{ns:"main"})} intent={this.state.nombreCliente?"primary":"danger"} />
							</FormGroup>

							<FormGroup
								label={i18next.t("empresas.nromaxusuario",{ns:"main"})+":"}
								labelFor="cliente"
							>
									<InputGroup id="input-maximo-usuarios" type="number" min={1} step={1} value={this.state.numUsuarios || ''} onChange={this.cambiarNumUsuarios}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nromaxusuario",{ns:"main"})} intent={this.state.numUsuarios?"primary":"danger"} />
							</FormGroup>

							<FormGroup
								label={i18next.t("empresas.nromaxempresa",{ns:"main"})+":"}
								labelFor="empresas"
							>
									<InputGroup id="input-maximo-empresas" type="number" min={1} step={1} value={this.state.numEmpresas || ''} onChange={this.cambiarNumEmpresas}  placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresas.nromaxempresa",{ns:"main"})} intent={this.state.numEmpresas?"primary":"danger"} />
							</FormGroup>

							<FormGroup
								label={i18next.t("empresas.fechafuncionamiento",{ns:"main"})+":"}
								labelFor="fecha-funcionamiento"
								inline={true}
								className="pixelAlrededor" 
							>
								<div id="input-fecha-funcionamiento">
									<DateInput minDate={new Date(today.getFullYear()-5, 1 ,1)}
                                        maxDate={new Date(today.getFullYear()+5, 11 ,31)}
										{...jsDateFormatter} 
										onChange={this.cambiarFechaFuncionamiento}
										align={Alignment.RIGHT}
										value={this.state.fechaFuncionamiento}
                                        dayPickerProps={{
                                          locale: i18next.language,
                                          months: (i18next.language=='es')?DateFormatString.months:DateFormatString.months_en,
                                          weekdaysLong: (i18next.language=='es')?DateFormatString.weekDaysLong:DateFormatString.weekDaysLong_en,
                                          weekdaysShort: (i18next.language=='es')?DateFormatString.weekDaysShort:DateFormatString.weekDaysShort_en,
                                          firstDayOfWeek: 1,
                                        }}
									/>
								</div>
							</FormGroup>
						</Card>
					:
					<>
					</>
				}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={guardarPerfilEditado}>
                    {i18next.t("guardar",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("close",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>

		</Dialog>

		<Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="info-sign"
			onClose={this.cerrarModal}
			title={i18next.t("empresas.btnborrarcliente",{ns:"main"})}
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			usePortal={true}
			onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
			isOpen={this.state.modalBorrarCliente}
			className="pixelAlrededor dialog-medio-ancho"
		>

            <div className={Classes.DIALOG_BODY}>
				<Select
					options={this.state.empresas}
					className="pixelAlrededor"
					onChange={this.seleccionCliente}
					value={this.state.clienteSeleccionado}
					defaultValue={null}
					backspaceRemovesValue={true}
					isClearable={true}
				/>
				{
					this.state.clienteSeleccionado !== null?
						<Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
							<h4>
								¡{i18next.t("atencion",{ns:"main"}).toUpperCase()}!
                            <br />
                                {i18next.t("empresas.questiondelcliente",{ns:"main"})}
                            <br />
								{this.state.clienteSeleccionado.label?this.state.clienteSeleccionado.label.toUpperCase():""}
							</h4>
						</Card>
					:
					<>
					</>
				}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={borrarCliente}>
                    {i18next.t("guardar",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("close",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>

		</Dialog>

        <div className="pixelAlrededorGrande">
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande separacionCentralTarjetas separarBordes">
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresas.title",{ns:"main"}).toUpperCase()}</Callout>
                {this.state.cargandoEmpresas && (
                      <div className="sweet-loading separarBordes">
                        <MoonLoader
                          css={override}
                          size={60}
                          color={"red"}
                          loading={this.state.cargandoEmpresas}
                        />
                      </div>
                    )
                }
                <Navbar>
                <Navbar.Group>
                {parseInt(this.state.rol)===1?    
					<ButtonGroup minimal={true}>
						 <Tooltip content={i18next.t("empresas.btncrear",{ns:"main"})}>
							 <Button icon="database" id="boton-crear-bdd" minimal={true} intent="danger" onClick={this.abrirCrearBdd}>{i18next.t("empresas.btncrear",{ns:"main"})}</Button>
						 </Tooltip>
                         <Tooltip content={i18next.t("empresas.btnbackup",{ns:"main"})}>
							 <Button id="boton-backup-bdd" icon="cloud-download" minimal={true} intent="danger" onClick={this.abrirBackupBdd}>{i18next.t("empresas.btnbackup",{ns:"main"})}</Button>
						 </Tooltip>
						 <Tooltip content={i18next.t("empresas.btnborrar",{ns:"main"})}>
							 <Button id="boton-eliminar-bdd" icon="trash" minimal={true} intent="danger" onClick={this.abrirBorrarBdd}>{i18next.t("empresas.btnborrar",{ns:"main"})}</Button>
						 </Tooltip>
						 <Tooltip content={i18next.t("empresas.btncrearcliente",{ns:"main"})}>
							 <Button icon="user" id="boton-crear-cliente" minimal={true} intent="danger" onClick={this.abrirCliente}>{i18next.t("empresas.btncrearcliente",{ns:"main"})}</Button>
						 </Tooltip>
						 <Tooltip content={i18next.t("empresas.btneditarcliente",{ns:"main"})}>
							 <Button icon="edit" id="boton-editar-cliente" minimal={true} intent="danger" onClick={this.editarCliente2}>{i18next.t("empresas.btneditarcliente",{ns:"main"})}</Button>
						 </Tooltip>
						 <Tooltip content={i18next.t("empresas.btnborrarcliente",{ns:"main"})}>
							 <Button id="boton-eliminar-bdd" icon="delete" minimal={true} intent="danger" onClick={this.abrirBorrarCliente}>{i18next.t("empresas.btnborrarcliente",{ns:"main"})}</Button>
						 </Tooltip>
                         <Tooltip content={i18next.t("empresas.btningresar",{ns:"main"})}>
							 <Button id="boton-ingresar-bdd" icon="log-in" minimal={true} intent="danger" onClick={this.abrirSitio}>{i18next.t("empresas.btningresar",{ns:"main"})}</Button>
						 </Tooltip>
					 </ButtonGroup>
                    
                :<ButtonGroup minimal={true}>
                    <Tooltip content={i18next.t("empresas.btningresar",{ns:"main"})}>
                        <Button id="boton-ingresar-bdd" icon="log-in" minimal={true} intent="danger" onClick={this.abrirSitio}>{i18next.t("empresas.btningresar",{ns:"main"})}</Button>
                    </Tooltip>
				</ButtonGroup>	}
                </Navbar.Group>
                </Navbar>
                {!this.state.cargandoEmpresas && (
                    <BootstrapTable
                        keyField='ID_BDD'
                        data={ this.state.bdds }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        defaultSorted={ const_table.defaultSorted('NOM_EMPRESA','asc') }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    )
                }
                
            </Card>
            {parseInt(this.state.rol)<3?
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande separacionCentralTarjetas separarBordes">
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("usuarios.title",{ns:"main"}).toUpperCase()}</Callout>
                {this.state.cargandoUsuarios && (
                      <div className="sweet-loading separarBordes">
                        <MoonLoader
                          css={override}
                          size={60}
                          color={"red"}
                          loading={this.state.cargandoUsuarios}
                        />
                      </div>
                    )
                }
                <Navbar>
                <Navbar.Group>
                {parseInt(this.state.rol)===1 || parseInt(this.state.rol)===2?
                      <ButtonGroup minimal={true} position={Position.RIGHT}>
                      <Tooltip content={i18next.t("usuarios.btncrear",{ns:"main"})}>
                          <Button id="boton-crear-usuario" icon="user" minimal={true} intent="danger" onClick={this.abrirCrearUsuario}>{i18next.t("usuarios.btncrear",{ns:"main"})}</Button>
                      </Tooltip>
                      <Tooltip content={i18next.t("usuarios.btneditar",{ns:"main"})}>
                          <Button id="boton-editar-usuario" icon="edit" minimal={true} intent="danger" onClick={this.abrirEditarUsuario}>{i18next.t("usuarios.btneditar",{ns:"main"})}</Button>
                      </Tooltip>
                      <Tooltip content={i18next.t("usuarios.btnborrar",{ns:"main"})}>
                          <Button id="boton-eliminar-usuario" icon="graph-remove" minimal={true} intent="danger" onClick={this.abrirBorrarUsuario}>{i18next.t("usuarios.btnborrar",{ns:"main"})}</Button>
                      </Tooltip>
                  </ButtonGroup>
                :<></>}
                </Navbar.Group>
                </Navbar>
                {!this.state.cargandoUsuarios && (
                    <BootstrapTable
                        keyField='ID_USUARIO'
                        data={ this.state.usuarios }
                        columns={ columnsUsuarios }
                        selectRow={ const_table.selectRow(handleOnSelectUsuario) }
                        pagination={ paginationFactory(const_table.options) }
                        defaultSorted={ const_table.defaultSorted('NOM_EMPRESA','asc') }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    )
                }
                
              
            </Card>
            :
            <></>
            }
        </div> 
        </>
            :

                {...this.state.empresaCaducada?
                    <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    title={i18next.t("atencion",{ns:"main"})}
                    onClose={()=>{this.setState({modalCuotaCaducada:false})}}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalCuotaCaducada}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("contratofinalizado",{ns:"main"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={()=>{this.setState({modalCuotaCaducada:false})}}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                    </div>

                </Dialog>
                :
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    title="Atención"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={true}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>Por favor, escriba una nueva contraseña</h2>
                        <FormGroup
                            intent={this.state.contraseñaNueva1 === this.state.contraseñaNueva2? "primary" : "danger"}
                        >
                            <p>{i18next.t("usuarios.longitudpermitida",{ns:"main"})}:</p>
                            <ul>
                                <li>{i18next.t("usuarios.letraminus",{ns:"main"})}</li>
                                <li>{i18next.t("usuarios.letramayus",{ns:"main"})}</li>
                                <li>{i18next.t("usuarios.unnro",{ns:"main"})}</li>
                                <li>{i18next.t("usuarios.caracteresespecial",{ns:"main"})}</li>
                            </ul>
                            <InputGroup type="password" label={i18next.t("usuarios.password",{ns:"main"})} value={this.state.cambiarContraseñaNueva1 || ''} onChange={cambiarContraseñaNueva1} intent={this.state.contraseñaNueva1 === this.state.contraseñaNueva2 && this.state.contraseñaNueva1 ? "primary" : "danger"} />
                                <br />
                            <InputGroup type="password" label={i18next.t("usuarios.password",{ns:"main"})} value={this.state.cambiarContraseñaNueva2 || ''} onChange={cambiarContraseñaNueva2} intent={this.state.contraseñaNueva1 === this.state.contraseñaNueva2 && this.state.contraseñaNueva2 ? "primary" : "danger"} />
                        </FormGroup>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("header.cerrarsesion",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.DANGER}
                                onClick={cerrarSesion}
                                icon="log-out"
                            >
                                {i18next.t("header.cerrarsesion",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={actualizarClave}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                    </div>

                </Dialog>
            
            
                }
            
            }
        
        )
    }


}

export default withTranslation()(Bdds)
