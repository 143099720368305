import React, { Component } from 'react';
import { Spinner, Navbar, ButtonGroup, Alignment, Dialog, Button, Card, Elevation, Callout } from "@blueprintjs/core"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost'
import { Cargando } from "../Pantallas/Cargando"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"

import PropiedadesOrganoAdmon from "../Pantallas/Elementos/PropiedadesOrganoAdmon"
import MiembrosOrganoAdmon from "../Pantallas/Elementos/MiembrosOrganoAdmon"
import SuplentesOrgamoAdmon from "../Pantallas/Elementos/SuplentesOrgamoAdmon"
import ComitesConsultivos from "../Pantallas/Elementos/ComitesConsultivos"

import i18next from "i18next";
import { withTranslation } from "react-i18next";

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    width: '30%'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '60%'
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

class OrganoAdministracion extends Component {

    state = {
        pestaña: "propiedades",
        animacion: true,
        forma:null,
        reiniciarMiembros:false,
        reiniciarSuplentes:false,
        numeroMiembros:null,
        formaGuardada:null,
        cargandoImprimir:false,
        modalImprimir:false,
        datosEmpresa:null,
        logotipo:null,
        comite:[],
        miembros:[],
        miembrosSup:[],
        formaSeleccionada:null
        

    }
    setComites= (value) =>this.setState({comite:value})
    setMiembrosSuplentes= (value)=> this.setState({miembroSup:value})
    setMiembrosOrganoAdmon= (value)=> this.setState({miembros:value})
    setPropiedadesOrgano= (value) =>this.setState({formaSeleccionada:value })
    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });
    setFormaSeleccionada=(idForma)=> {this.setState({forma:idForma})}
    setReiniciarMiembros=(value)=> {this.setState({reiniciarMiembros:value})}
    setReiniciarSuplentes=(value)=> {this.setState({reiniciarSuplentes:value})}
    setNumeroMiembros=(value)=>{this.setState({numeroMiembros:value})}
    setFormaGuardada=(idForma)=> {this.setState({formaGuardada:idForma})}

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
 
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
 
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
 
             }
         })
     
     }

     cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                 }

            }
            
        })

       return datosEmpresa
   
   }
   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarEmpresa()
    this.setState({
        datosEmpresa:datosEmpresa
    })

}
cargarCargo=(data)=>{
    let cargo="";
   if(data.PRESIDENTE===true){
       cargo="PRESIDENTE"
   }else if(data.SECRETARIO===true){
       cargo="SECRETARIO"
   }else{
       cargo=""
   }      
    return cargo
}

    generarImprimir= async()=>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () =>{
            if(this.propiedades.state.formaSeleccionada.length===0){
                await this.propiedades.cargarFormas()
            }
            if(this.miembros.state.miembros.length===0){
                await this.miembros.cargarMiembros()
            }
            if(this.miembrosSup.state.miembros.length===0){
                await this.miembrosSup.cargarMiembros()
            }
            if(this.comite.state.comite.length===0){
                await this.comite.cargarComites()
            }
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
            })

        })
    }

    componentDidMount(){
        this.setState({
            cargandoOA:true}, async () =>{
                await this.obtenerLogoEmpresa()
                await this.cargarDatos()
                this.setState({
                    cargandoOA:false
                })
            })
    }

    Imprimir=()=>{
        let cargo=''
        let cargom=''
        return(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("organoadministracion.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("organoadministracion.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("organoadministracion.forma",{ns:"page"})}:</Text>
                    <Text style={styles.textData}>{this.propiedades.state.formaSeleccionada.label}</Text>

                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("organoadministracion.frecuencia",{ns:"page"})}:</Text>
                    <Text style={styles.textData}>{this.propiedades.state.periodicidad?this.propiedades.formatearPeriodicidad(this.propiedades.state.periodicidad):''}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("organoadministracion.suplentes",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.miembrosSup.state.miembros?this.miembrosSup.state.miembros.map((miembro, index)=>{
                         cargo= this.miembrosSup.cargarCargo(miembro)
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("organoadministracion.cargo",{ns:"page"}).toUpperCase()}</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{miembro.nombre?miembro.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{cargo}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
                
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>MIEMBROS DEL ÓRGANO DE ADMINISTRACIÓN</Text>
                </View>
                {this.miembros.state.miembros?this.miembros.state.miembros.map((miembro, index)=>{
                    cargom= this.miembros.cargarCargo(miembro)
                    return(
                        <View key={index}>
                           {index ===0 && (
                               <View style={styles.colHead}>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("organoadministracion.cargo",{ns:"page"}).toUpperCase()}</Text>                                                                
                         </View>
                           )}
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%',height:20}}>{miembro.nombre?miembro.nombre:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%',height:20}}>{cargom}</Text>
                            </View>                               
                        
                    </View>
                    )
                }):<></>}      
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("organoadministracion.comites",{ns:"page"}).toUpperCase()}</Text>
                </View>
                {this.comite.state.comite?this.comite.state.comite.map((comite, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("organoadministracion.nromiembro",{ns:"page"}).toUpperCase()}</Text>  
                                 </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{comite.nombre?comite.nombre:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{comite.miembros?(comite.miembros).length:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}  
                
                 </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
}    


    render() {
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false
            })
        }

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("organoadministracion.title",{ns:"page"})

        return (
            <>
            {this.state.cargandoOA===true?
                    <Cargando/>
                :
                <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generar-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup align={Alignment.LEFT}>
                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} onClick={()=>this.generarImprimir()}/>
                            
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <div className="tablaMitadIzquierda pixelAlrededorGrande">
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="propiedades-organo-administracion">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("organoadministracion.title",{ns:"page"})}</Callout>
                        <PropiedadesOrganoAdmon  ref={(input) => { this.propiedades= input; }} editable={this.props.editable} numeroMiembros={this.state.numeroMiembros}  setFormaGuardada={this.setFormaGuardada} setFormaSeleccionada={this.setFormaSeleccionada} setPropiedadesOrgano={this.setPropiedadesOrgano}  logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} i18next={i18next}/>
                        <br />
                    </Card>
                    <br />
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="miembros">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("organoadministracion.miembros",{ns:"page"})}</Callout>
                        <MiembrosOrganoAdmon ref={(input) => { this.miembros= input; }} editable={this.props.editable} setNumeroMiembros={this.setNumeroMiembros} setReiniciarSuplentes={this.setReiniciarSuplentes} reiniciarMiembros={this.state.reiniciarMiembros} formaGuardada={this.state.formaGuardada} formaSeleccionada={this.state.forma} setMiembrosOrganoAdmon={this.setMiembrosOrganoAdmon} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} i18next={i18next}/>
                        <br />
                    </Card>
                    
                </div>
                <div className="tablaMitadDerecha pixelAlrededorGrande">
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="suplentes">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("organoadministracion.suplentes",{ns:"page"})}</Callout>
                        <SuplentesOrgamoAdmon ref={(input) => {this.miembrosSup = input;}} editable={this.props.editable} setReiniciarMiembros={this.setReiniciarMiembros} reiniciarSuplentes={this.state.reiniciarSuplentes} formaGuardada={this.state.formaGuardada} formaSeleccionada={this.state.forma} setMiembrosSuplentes={this.setMiembrosSuplentes} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} i18next={i18next}/>
                    </Card>
                    <br />
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("organoadministracion.comites",{ns:"page"})}</Callout>
                        <ComitesConsultivos editable={this.props.editable} ref={(input) => { this.comite= input; }} setComites={this.setComites} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} i18next={i18next}/>
                    </Card>
                </div>

               
                
            </>
           }
          </>  
        )
    }
}

export default withTranslation()(OrganoAdministracion);