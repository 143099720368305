import React, { Component } from 'react';
import {
    Card,
    Elevation,
    Button,
    Callout,
    Dialog,
    Tooltip,
    Position,
    Icon,
    Checkbox,
    Alignment,
    TextArea,
    InputGroup,
    Intent,
    Classes,
    FormGroup,
    AnchorButton
} from "@blueprintjs/core";
import Select from "react-select";
import Periodicidad from "./Elementos/Periodicidad";
import { withApollo } from 'react-apollo';
import { Cargando } from "./Cargando";
import gql from "graphql-tag";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class IdentificacionRiesgo extends Component {

    state= {
        cargando:true,
        identificaciones:[],
        modalIdentificacionAbierto:false,
        dialogTextoRiesgo:false,
        nivelRiesgo: null,
        categoriaRiesgo:0,
        riesgoHaSucedidoAntes: false,
        nivelSancion: 1,
        nivelPerdida: 2,
        nivelSuspension: 3,
        nivelReputacional: 4,
        nivelOtros: 5,
        valoranSeleccionada:[],
        asignadoSeleccionada:[],
        recursosFinancieros: '',
        recursosOrganizacion: "",
        recursosExternos: "",
        observaciones:null,
        categoriasRiesgo: [i18next.t("identificacion.bajo",{ns:"page"}), i18next.t("identificacion.medio",{ns:"page"}), i18next.t("identificacion.alto",{ns:"page"}), i18next.t("identificacion.muyalto",{ns:"page"})],
        intentCategoriasRiesgo: ["celdaFondoVerde", "celdaFondoAmarillo", "celdaFondoNaranja", "celdaFondoRojo"],
        intentCategoriasRiesgo2: ["celdaFondoVerde", "celdaFondoAmarillo","celdaFondoAmarillo", "celdaFondoNaranja", "celdaFondoRojo"],
        modalPeriodicidad:false,
        areaSeleccionada:null,
        deptoSeleccionado:null,
        bloqueHijoSeleccionado:null,
        bloquePadreSeleccionado:null,
        valoran:[],
        asignados:[],
        fichas:[]
    }

    componentDidMount(){

        this.setState({
            cargando: true
        },async()=>{
            await this.cargarDatos();   
        })

    }

    cargarDatos=async()=> {
        let personasFisicas=await this.cargarPersonasFisicas();
        let personasJuridicas=await this.cargarPersonasJuridicas();

        let asignados=[];
        let personas=[];
        personasFisicas.forEach(persona=>{
            personas.push({label:`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,value:persona.ID_PERSONA})
            asignados.push({label:`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,value:persona.ID_PERSONA})
        });
        personasJuridicas.forEach(persona=>{
            asignados.push({label:persona.RAZON_SOCIAL,value:persona.ID_PERSONA})
        })
        this.setState({
            valoran:personas,
            asignados:asignados
        },async()=>{
            await this.cargarIdentificaciones();
        });

    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }

    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarIdentificaciones=async()=>{

        let identificaciones=await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoraciones
                    {
                        ID_DOCUMENTO,
                        ID_CONTRATO_DEPARTAMENTO,
                        COMPLIANCE_CONTRATOS_DEPARTAMENTOS{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION,AREA{ID_AREA,DESCRIPCION}}}
                        ID_RIESGO,
                        RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO,DESCRIPCION,BLOQUE{ID_BLOQUE,TITULO,LETRA,DESCRIPCION}}
                        FECHA,
                        NUMERO,
                        NIVEL_INTENSIDAD,
                        OCURRIDO_ANTES,
                        CONSECUENCIAS_SANCION,
                        CONSECUENCIAS_PERDIDA,
                        CONSECUENCIAS_SUSPENSION,
                        CONSECUENCIAS_DANO,
                        CONSECUENCIAS_OTROS,
                        RECURSOS_FINANCIEROS,
                        RECURSOS_ORGANIZACION,
                        RECURSOS_EXTERNOS,
                        PERIODICIDAD_REVISION,
                        OBSERVACIONES,
                        PERIODICIDAD_REVISION,
                        ID_USUARIO
                    }
                }
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return  result.data.complianceDocumentosValoraciones
        })
        //console.log(identificaciones);
        let val1_1,val1_2,val2_1,val2_2,val3_1,val3_2,val4_1,val4_2,val5_1,val5_2='';
        identificaciones.sort(function (a, b){
            val1_1=a.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION;
            val1_2=b.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION;
            val2_1=a.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION;
            val2_2=b.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION;
            val3_1=a.RIESGO.BLOQUE.LETRA;
            val3_2=b.RIESGO.BLOQUE.LETRA;
            val4_1=a.FECHA;
            val4_2=b.FECHA;
            val5_1=a.NUMERO;
            val5_2=b.NUMERO;
            return val1_1.localeCompare(val1_2) || val2_1.localeCompare(val2_2) || val3_1.localeCompare(val3_2) || val4_2.localeCompare(val4_1) || val5_2 - val5_1;
        });
        this.setState({
            identificaciones:identificaciones
        },async()=>{
            await this.cargarFichas();
        });
    
    }

    groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    cargarFichas=async ()=>{
        if (this.state.identificaciones.length>0) {
            let areas=[];
            let fichas=[];
            this.state.identificaciones.forEach((identificacion)=>{
                areas.push({
                    ID_DOCUMENTO: identificacion.ID_DOCUMENTO,
                    AREA: identificacion.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION,
                    DEPARTAMENTO: identificacion.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION,
                    ID_DEPARTAMENTO: identificacion.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.ID_DEPARTAMENTO,
                    BLOQUE: identificacion.RIESGO.BLOQUE.TITULO,
                    NIVEL_INTENSIDAD: identificacion.NIVEL_INTENSIDAD,
                    RIESGO: identificacion.RIESGO.TITULO,
                    ID_AREA: identificacion.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.ID_AREA,
                    ID_RIESGO: identificacion.RIESGO.ID_RIESGO,
                    NUMERO: identificacion.NUMERO,
                    FECHA:identificacion.FECHA,
                    CONSECUENCIAS_SANCION:identificacion.CONSECUENCIAS_SANCION,
                    CONSECUENCIAS_PERDIDA:identificacion.CONSECUENCIAS_PERDIDA,
                    CONSECUENCIAS_SUSPENSION:identificacion.CONSECUENCIAS_SUSPENSION,
                    CONSECUENCIAS_DANO:identificacion.CONSECUENCIAS_DANO,
                    CONSECUENCIAS_OTROS:identificacion.CONSECUENCIAS_OTROS,
                    OCURRIDO_ANTES:identificacion.OCURRIDO_ANTES,
                    RECURSOS_FINANCIEROS:identificacion.RECURSOS_FINANCIEROS,
                    RECURSOS_ORGANIZACION:identificacion.RECURSOS_ORGANIZACION,
                    RECURSOS_EXTERNOS:identificacion.RECURSOS_EXTERNOS,
                    OBSERVACIONES:identificacion.OBSERVACIONES,
                    PERIODICIDAD_REVISION:identificacion.PERIODICIDAD_REVISION,
                    ID_CONTRATO_DEPARTAMENTO: identificacion.ID_CONTRATO_DEPARTAMENTO,
                    LETRA: identificacion.RIESGO.BLOQUE.LETRA,
                    DESCRIPCION: identificacion.RIESGO.DESCRIPCION
                });
            });
            areas= await this.groupBy(areas,"AREA");
            for (let key of Object.keys(areas)) {
                let r = this.removeDuplicates(areas[key], "ID_RIESGO");
                for (let area of areas[key]) {
                    let existe = fichas.find(hijo => {
                        return hijo.ID_AREA === area.ID_AREA;
                    });
                    if (!existe) {
                        fichas.push({
                            AREA: area.AREA,
                            DEPARTAMENTO: area.DEPARTAMENTO,
                            ID_DEPARTAMENTO: area.ID_DEPARTAMENTO,
                            ID_AREA: area.ID_AREA,
                            CHILD: r
                        });
                    }
                }
            }
            console.log(fichas);
            this.setState({
                fichas: fichas,
                cargando:false
            })
        
        } else {
            this.setState({
                cargando:false
            });
        }
    }

    abrirModalPeriodicidad=()=>{
        this.setState({modalPeriodicidad:true})
    }

    cerrarPeriodicidad = () => {
        this.setState({
          modalPeriodicidad:false
        })
    }

    formatearPeriodicidad=(periodicidad)=>{
        
        if(typeof periodicidad==="string"){
            periodicidad=JSON.parse(periodicidad)
        }

        let format=""
        if(periodicidad.dia && periodicidad.dia>0){
            format+="El día "+periodicidad.dia+" de"
        }else if(periodicidad.diaSemana){
            format+="El "+periodicidad.ordinal+" "+periodicidad.diaSemana+" de"
        }

        if(periodicidad.numeroMes && periodicidad.numeroMes>0){
            format+=" cada "+periodicidad.numeroMes+" meses"
        }else if(periodicidad.meseseSeleccionados.length>0){
            let meses=periodicidad.meseseSeleccionados.map(function(elem){
                return elem.label;
            }).join(",");
            format+=" los meses "+meses
        }
        return format
    }

    recuperarAreaDptoBloqueRiesgo=async(identificador)=>{
        let area = {"value":identificador.ID_AREA,"label":identificador.AREA}
        let dpto = {"value":identificador.ID_DEPARTAMENTO,"contDept":identificador.ID_CONTRATO_DEPARTAMENTO,"label":identificador.DEPARTAMENTO}
        let bloque = {"value":identificador.ID_BLOQUE,"letra":identificador.LETRA,"label":i18next.t("identificacion.bloque",{ns:"page"})+' '+identificador.LETRA+" - "+identificador.BLOQUE,"titulo":identificador.BLOQUE}
        let riesgo = {"value":identificador.ID_RIESGO,"label":identificador.RIESGO,"descripcion":identificador.DESCRIPCION}
        
        this.setState({
            areaSeleccionada:area,
            deptoSeleccionado:dpto,
            bloqueHijoSeleccionado:riesgo,
            bloquePadreSeleccionado:bloque
        })
    }

    recuperarRecursos=async(ficha)=>{
        await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosRecursos(FILTROS:{ID_DOCUMENTO:${ficha.ID_DOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let recs=[]; 
            result.data.complianceDocumentosRecursos.forEach(async (recurso)=>{
                let asignadoSeleccionado=await this.state.asignados.find(val=>{
                    return val.value===recurso.ID_PERSONA
                })
                recs.push(asignadoSeleccionado);
            });
            this.setState({asignadoSeleccionada:recs});
        });
    }

    recuperarValoraciones=async(ficha)=>{
        await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoran(FILTROS:{ID_DOCUMENTO:${ficha.ID_DOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let vals=[]; 
            result.data.complianceDocumentosValoran.forEach(async (valoracion)=>{
                let valoranSeleccionada=await this.state.valoran.find(val=>{
                    return val.value===valoracion.ID_PERSONA
                })
                vals.push(valoranSeleccionada);
            });
            this.setState({valoranSeleccionada:vals});
        });
    }

    render() {
        const abrirModal = async(ficha) => {
            this.setState({
                cargando:true
            });
            
            await this.recuperarAreaDptoBloqueRiesgo(ficha);
            await this.recuperarValoraciones(ficha);
            await this.recuperarRecursos(ficha);
            let categoriaRiesgo=0;
            let value=ficha.NIVEL_INTENSIDAD;
            if(value < 8) {
                categoriaRiesgo=0;
            } else if(value < 15) {
                categoriaRiesgo=1;
            } else if(value < 22) {
                categoriaRiesgo=2;
            } else if(value < 31 ){
                categoriaRiesgo=3;
            }
            this.setState({
                modalIdentificacionAbierto:true,
                numero:ficha.NUMERO,
                fechaFicha:ficha.FECHA,
                nivelRiesgo:ficha.NIVEL_INTENSIDAD,
                nivelSancion:ficha.CONSECUENCIAS_SANCION,
                nivelPerdida:ficha.CONSECUENCIAS_PERDIDA,
                nivelSuspension:ficha.CONSECUENCIAS_SUSPENSION,
                nivelReputacional:ficha.CONSECUENCIAS_DANO,
                nivelOtros:ficha.CONSECUENCIAS_OTROS,
                riesgoHaSucedidoAntes:ficha.OCURRIDO_ANTES,
                recursosFinancieros:ficha.RECURSOS_FINANCIEROS,
                recursosOrganizacion:ficha.RECURSOS_ORGANIZACION,
                recursosExternos:ficha.RECURSOS_EXTERNOS,
                observaciones:ficha.OBSERVACIONES,
                periodicidad:ficha.PERIODICIDAD_REVISION,
                categoriaRiesgo:categoriaRiesgo                           
            })
        }
    
        const cerrarModal = () => {
            this.setState({
                modalIdentificacionAbierto:false,
                cargando:false
            })
        }

        const abrirDialogoTextoRiesgo=()=>{
   
            this.setState({ dialogTextoRiesgo: true });

        }

        const cerrarDialogoTextoRiesgo = () => {
            this.setState({ dialogTextoRiesgo: false });
        }

        const handleClickNivel = (value) => {
            this.setState({nivelRiesgo: value})
            
            if(value < 8) {
                this.setState({categoriaRiesgo: 0})
            } else if(value < 15) {
                this.setState({categoriaRiesgo: 1})
            } else if(value < 22) {
                this.setState({categoriaRiesgo: 2})
            } else if(value < 31 ){
                this.setState({categoriaRiesgo: 3})
            }
        }

        const handleClickSancion = (value) => {
            this.setState({nivelSancion: value})

            if(value < 8) {
                this.setState({categoriaSancion: 0})
            } else if(value < 15) {
                this.setState({categoriaSancion: 1})
            } else if(value < 22) {
                this.setState({categoriaSancion: 2})
            } else if(value < 31 ){
                this.setState({categoriaSancion: 3})
            }
        }

        const handleClickPerdida = (value) => {
            this.setState({nivelPerdida: value})
        }

        const handleClickSuspension = (value) => {
            this.setState({nivelSuspension: value})
        }

        const handleClickReputacional = (value) => {
            this.setState({nivelReputacional: value})
        }

        const handleClickOtros = (value) => {
            this.setState({nivelOtros: value})
        }

        const seleccionValoran  = (value) => { 
            this.setState({valoranSeleccionada: value})
        }

        const seleccionAsignados  = (value) => { 
            this.setState({asignadoSeleccionada: value})
        }

        const handleEnabledChange = () => {
            this.setState({riesgoHaSucedidoAntes: !this.state.riesgoHaSucedidoAntes})
        }

        const handleRecursosFinancieros = (ev) => {

            
            this.setState({recursosFinancieros:ev.currentTarget.value})
        }

        const handleCambiarObservaciones = (ev) => {
            this.setState({observaciones: ev.currentTarget.value})
        }

        const handleRecursosOrganizacion = (ev) => {
            this.setState({recursosOrganizacion: ev.target.value})
        }

        const handleRecursosExternos = (ev) => {
            this.setState({recursosExternos: ev.target.value})
        }
        
        let bloque=null;

        return (

            <>
                <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarDialogoTextoRiesgo}
                            title={i18next.t("identificacion.inforiesgo",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.dialogTextoRiesgo}
                            className="dialog-ancho"
                            >
                            <div className={Classes.DIALOG_BODY}>
                                <TextArea fill={true} disabled value={this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.descripcion:""} className="textarea-alta" />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={cerrarDialogoTextoRiesgo}
                                >
                                    {i18next.t("close",{ns:"global"})}
                                </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("identificacion.titledoc",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    isOpen={this.state.modalIdentificacionAbierto}
                    className="dialog-ancho dialog-scroll "
                >
                    {this.state.cargandoPrintFicha===true?
                        <Cargando/>
                    :
                    <div>
                    <div ref={(input) => { this.ficha= input; }} className="pixelAlrededor">
                    <br/>                   
                        <Card interactive={true}  elevation={Elevation.TWO} className="pixelAlrededor">
                            <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr >
                                            <td rowSpan="2" style={{width:'80%',padding:'5px 5px 5px 5px'}}><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.identificacionvaloracion",{ns:"page"})}</Callout></td>
                                            <td style={{width:'20%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.fechaFicha?moment(this.state.fechaFicha,"YYYY-MM-DD").format("DD-MM-YYYY"):moment().format("DD-MM-YYYY")}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'20%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.numero}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td style={{width:'15%'}}><span style={{fontWeight:'bold'}}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</span></td>
                                            <td style={{width:'35%'}}><span style={{fontWeight:'normal'}}>{this.state.areaSeleccionada?this.state.areaSeleccionada.label:''}</span></td>
                                            <td style={{width:'15%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</span></td>
                                            <td style={{width:'35%'}}><span style={{fontWeight:'normal'}}>{this.state.deptoSeleccionado?this.state.deptoSeleccionado.label:''}</span></td>
                                        </tr>
                                        <tr>
                                            <td  colSpan="4" style={{textAlign:'center'}}><span style={{fontWeight:'bold'}}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.titulo:""}</span></td>
                                        </tr>
                                        <tr>
                                            <td  colSpan="4"><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.label:""} <Tooltip content={i18next.t("see",{ns:"global"})}  position={Position.TOP} ><Button icon="info-sign" onClick={()=>{abrirDialogoTextoRiesgo()}}/></Tooltip></Callout></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.niveleintensidad",{ns:"page"})}</Callout></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                                <table className='tableFichaCheck'>
                                    <tbody>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.probabilidad",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.menor",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.critico",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</span></td>
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.escasa",{ns:"page"})}</span></td>
                                            {this.state.nivelRiesgo === 1 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(1)}>1</td> }
                                            {this.state.nivelRiesgo === 6 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(6)}>6</td> }
                                            {this.state.nivelRiesgo === 11 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(11)}>11</td> }
                                            {this.state.nivelRiesgo === 16 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(16)}>16</td> }
                                            {this.state.nivelRiesgo === 21 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(21)}>21</td> }
                                            {this.state.nivelRiesgo === 26 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(26)}>26</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.baja",{ns:"page"})}</span></td>
                                            {this.state.nivelRiesgo === 2 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(2)}>2</td> }
                                            {this.state.nivelRiesgo === 7 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(7)}>7</td> }
                                            {this.state.nivelRiesgo === 12 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(12)}>12</td> }
                                            {this.state.nivelRiesgo === 17 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(17)}>17</td> }
                                            {this.state.nivelRiesgo === 22 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(22)}>22</td> }
                                            {this.state.nivelRiesgo === 27 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(27)}>27</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.posible",{ns:"page"})}</span></td>
                                            {this.state.nivelRiesgo === 3 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(3)}>3</td> }
                                            {this.state.nivelRiesgo === 8 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(8)}>8</td> }
                                            {this.state.nivelRiesgo === 13 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(13)}>13</td> }
                                            {this.state.nivelRiesgo === 18 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(18)}>18</td> }
                                            {this.state.nivelRiesgo === 23 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(23)}>23</td> }
                                            {this.state.nivelRiesgo === 28 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(28)}>28</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.alta",{ns:"page"})}</span></td>
                                            {this.state.nivelRiesgo === 4 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(4)}>4</td> }
                                            {this.state.nivelRiesgo === 9 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(9)}>9</td> }
                                            {this.state.nivelRiesgo === 14 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(14)}>14</td> }
                                            {this.state.nivelRiesgo === 19 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(19)}>19</td> }
                                            {this.state.nivelRiesgo === 24 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(24)}>24</td> }
                                            {this.state.nivelRiesgo === 29 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(29)}>29</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.muyalta",{ns:"page"})}</span></td>
                                            {this.state.nivelRiesgo === 5 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(5)}>5</td> }
                                            {this.state.nivelRiesgo === 10 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(10)}>10</td> }
                                            {this.state.nivelRiesgo === 15 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(15)}>15</td> }
                                            {this.state.nivelRiesgo === 20 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(20)}>20</td> }
                                            {this.state.nivelRiesgo === 25 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(25)}>25</td> }
                                            {this.state.nivelRiesgo === 30 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(30)}>30</td> }
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="celdaFondoVerde" >{i18next.t("identificacion.nivelbajo",{ns:"page"})}</td>
                                            <td className="celdaFondoAmarillo" colSpan="2">{i18next.t("identificacion.nivelmedio",{ns:"page"})}</td>
                                            <td className="celdaFondoNaranja" >{i18next.t("identificacion.nivelalto",{ns:"page"})}</td>
                                            <td className="celdaFondoRojo" colSpan="2">{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='tableFicha'>
                                    <tbody>    
                                        <tr>
                                            <td>
                                                <Checkbox checked={this.state.riesgoHaSucedidoAntes} onChange={handleEnabledChange} label={i18next.t("identificacion.activarsisucedio",{ns:"page"})+":"} alignIndicator={Alignment.RIGHT} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.consecuencias",{ns:"page"})}</Callout></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                                <table className='tableFichaCheck'>
                                    <tbody>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.menor",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.critico",{ns:"page"})}</span></td>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</span></td>
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</span></td>
                                            {this.state.nivelSancion === 1 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickSancion(1)}>1</td> }
                                            {this.state.nivelSancion === 6 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSancion(6)}>6</td> }
                                            {this.state.nivelSancion === 11 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSancion(11)}>11</td> }
                                            {this.state.nivelSancion === 16 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickSancion(16)}>16</td> }
                                            {this.state.nivelSancion === 21 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSancion(21)}>21</td> }
                                            {this.state.nivelSancion === 26 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSancion(26)}>26</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</span></td>
                                            {this.state.nivelPerdida === 2 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickPerdida(2)}>2</td> }
                                            {this.state.nivelPerdida === 7 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickPerdida(7)}>7</td> }
                                            {this.state.nivelPerdida === 12 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickPerdida(12)}>12</td> }
                                            {this.state.nivelPerdida === 17 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickPerdida(17)}>17</td> }
                                            {this.state.nivelPerdida === 22 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickPerdida(22)}>22</td> }                                            
                                            {this.state.nivelPerdida === 27 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickPerdida(27)}>27</td> }

                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</span></td>
                                            {this.state.nivelSuspension === 3 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickSuspension(3)}>3</td> }
                                            {this.state.nivelSuspension === 8 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSuspension(8)}>8</td> }
                                            {this.state.nivelSuspension === 13 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSuspension(13)}>13</td> }
                                            {this.state.nivelSuspension === 18 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickSuspension(18)}>18</td> }
                                            {this.state.nivelSuspension === 23 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSuspension(23)}>23</td> }
                                            {this.state.nivelSuspension === 28 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSuspension(28)}>28</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</span></td>
                                            {this.state.nivelReputacional === 4 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickReputacional(4)}>4</td> }
                                            {this.state.nivelReputacional === 9 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickReputacional(9)}>9</td> }
                                            {this.state.nivelReputacional === 14 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickReputacional(14)}>14</td> }
                                            {this.state.nivelReputacional === 19 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickReputacional(19)}>19</td> }
                                            {this.state.nivelReputacional === 24 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickReputacional(24)}>24</td> }
                                            {this.state.nivelReputacional === 29 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickReputacional(29)}>29</td> }
                                        </tr>
                                        <tr>
                                            <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.otros",{ns:"page"})}</span></td>
                                            {this.state.nivelOtros === 5 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickOtros(5)}>5</td> }
                                            {this.state.nivelOtros === 10 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickOtros(10)}>10</td> }
                                            {this.state.nivelOtros === 15 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickOtros(15)}>15</td> }
                                            {this.state.nivelOtros === 20 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickOtros(20)}>20</td> }
                                            {this.state.nivelOtros === 25 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickOtros(25)}>25</td> }
                                            {this.state.nivelOtros === 30 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickOtros(30)}>30</td> }
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="celdaFondoVerde" >{i18next.t("identificacion.nivelbajo",{ns:"page"})}</td>
                                            <td className="celdaFondoAmarillo" colSpan="2">{i18next.t("identificacion.nivelmedio",{ns:"page"})}</td>
                                            <td className="celdaFondoNaranja" >{i18next.t("identificacion.nivelalto",{ns:"page"})}</td>
                                            <td className="celdaFondoRojo" colSpan="2">{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tg-wrap"  style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.resultados",{ns:"page"})}</Callout></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            {this.state.nivelRiesgo ===null?
                                                    <>
                                                        <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoVerde">{i18next.t("identificacion.bajo",{ns:"page"})}</Callout></td>
                                                        <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout className="bp3-calloutFicha" style={{background:"#bfccd6"}}>{this.state.nivelRiesgo}</Callout></td>
                                                    </>
                                                :
                                                this.state.nivelRiesgo < 8?
                                                    <>
                                                        <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoVerde">{i18next.t("identificacion.bajo",{ns:"page"})}</Callout></td>
                                                        <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoVerde">{this.state.nivelRiesgo}</Callout></td>
                                                    </>
                                                    :this.state.nivelRiesgo < 15?
                                                        <>
                                                            <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoAmarillo">{i18next.t("identificacion.medio",{ns:"page"})}</Callout></td>
                                                            <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoAmarillo">{this.state.nivelRiesgo}</Callout></td>
                                                        </>
                                                        :this.state.nivelRiesgo < 22?
                                                            <>
                                                                <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoNaranja">{i18next.t("identificacion.alto",{ns:"page"})}</Callout></td>
                                                                <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoNaranja">{this.state.nivelRiesgo}</Callout></td>
                                                            </>
                                                            :
                                                            <>
                                                                <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoRojo">{i18next.t("identificacion.muyalto",{ns:"page"})}</Callout></td>
                                                                <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoRojo">{this.state.nivelRiesgo}</Callout></td>
                                                            </>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{i18next.t("identificacion.riesgocierto",{ns:"page"})}</td>
                                            <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout className={this.state.riesgoHaSucedidoAntes?(this.state.categoriaRiesgo<3?this.state.intentCategoriasRiesgo[this.state.categoriaRiesgo+1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo[this.state.categoriaRiesgo]+' bp3-calloutFicha'):this.state.intentCategoriasRiesgo[this.state.categoriaRiesgo]+' bp3-calloutFicha'} >{ this.state.riesgoHaSucedidoAntes?(this.state.categoriaRiesgo<3?this.state.categoriasRiesgo[this.state.categoriaRiesgo+1]:this.state.categoriasRiesgo[this.state.categoriaRiesgo]):this.state.categoriasRiesgo[this.state.categoriaRiesgo]}</Callout></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.tipodanos",{ns:"page"})}:</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='tableFichaCheck'>
                                    <tbody>
                                        <tr>
                                            <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</span></td>
                                            <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</span></td>
                                            <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</span></td>
                                            <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</span></td>
                                            <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.otros",{ns:"page"})}</span></td>
                                        </tr>
                                        <tr>
                                            <td><Callout className={(Math.ceil(this.state.nivelSancion/5)-1)<=4?this.state.intentCategoriasRiesgo2[ Math.ceil(this.state.nivelSancion/5)-1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelSancion}</Callout> </td>
                                            <td><Callout className={(Math.ceil(this.state.nivelPerdida/5)-1)<=4?this.state.intentCategoriasRiesgo2[ Math.ceil(this.state.nivelPerdida/5) -1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'}>{this.state.nivelPerdida}</Callout> </td>
                                            <td><Callout className={(Math.round(this.state.nivelSuspension/5)-1)<=4?this.state.intentCategoriasRiesgo2[ Math.round(this.state.nivelSuspension/5)-1 ]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelSuspension}</Callout> </td>
                                            <td><Callout className={(Math.round(this.state.nivelReputacional/5)-1)<=3?this.state.intentCategoriasRiesgo2[ Math.round(this.state.nivelReputacional/5)-1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelReputacional}</Callout> </td>
                                            <td><Callout className={(Math.round(this.state.nivelOtros/5)-1)<=3?this.state.intentCategoriasRiesgo2[ Math.round(this.state.nivelOtros/5)-1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelOtros}</Callout> </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td >{i18next.t("identificacion.organo",{ns:"page"})}</td>
                                        </tr>
                                        <tr>
                                            <td>          
                                                {/* mismo código de recursos humanos */}
                                                <FormGroup
                                                    label=""
                                                    labelFor="nombre-proyecto"
                                                >
                                                    <Select  
                                                        isMulti
                                                        options={this.state.valoran}
                                                        className="pixelAlrededor"
                                                        onChange={seleccionValoran}
                                                        backspaceRemovesValue={true}
                                                        value={this.state.valoranSeleccionada}
                                                        isClearable={true}
                                                        onMenuOpen={ ()=> this.setState({brsEmpleados: true})}
                                                        onMenuClose={ ()=> this.setState({brsEmpleados: false})}
                                                    />
                                                    {
                                                        this.state.brsEmpleados === true?
                                                        <>
                                                            {this.state.valoran.map((beErres) =>
                                                                <br key={beErres} />
                                                            )}
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="tg-wrap">
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.recursosasignados",{ns:"page"})}</Callout></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                                <table className='tableFicha'>
                                    <tbody>
                                        <tr>
                                            <td style={{width:'15%'}}>{i18next.t("identificacion.personales",{ns:"page"})}</td>
                                            <td style={{width:'85%'}} className="tdAbajo">
                                                <Select  
                                                    isMulti
                                                    options={this.state.asignados}
                                                    className="pixelAlrededor"
                                                    onChange={seleccionAsignados}
                                                    backspaceRemovesValue={true}
                                                    isClearable={true}
                                                    value={this.state.asignadoSeleccionada}                                                        
                                                    classNamePrefix="selectArriba"
                                                    style={{fontSize:'12px'}}
                                                    onMenuOpen={ ()=> this.setState({brsPersonales: true})}
                                                    onMenuClose={ ()=> this.setState({brsPersonales: false})}
                                                />
                                                {
                                                    this.state.brsPersonales === true?
                                                    <>
                                                        {this.state.asignados.map((beErresAsignados) =>
                                                            <br key={beErresAsignados} />
                                                        )}
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </>
                                                    :
                                                    null
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'15%'}}>{i18next.t("identificacion.financieros",{ns:"page"})}</td>
                                            <td style={{width:'85%'}}><InputGroup style={{fontSize:'12px'}} value={this.state.recursosFinancieros?this.state.recursosFinancieros:''}  type="text"  onChange={handleRecursosFinancieros} /></td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'15%'}}>{i18next.t("identificacion.delaorganizacion",{ns:"page"})}</td>
                                            <td style={{width:'85%'}}>
                                                <TextArea style={{fontSize:'12px'}} fill={true} value={this.state.recursosOrganizacion?this.state.recursosOrganizacion:''} onChange={handleRecursosOrganizacion}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'15%'}}>{i18next.t("identificacion.externos",{ns:"page"})}</td>
                                            <td style={{width:'85%'}}>
                                                <TextArea style={{fontSize:'12px'}} fill={true} value={this.state.recursosExternos?this.state.recursosExternos:''} onChange={handleRecursosExternos}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'15%'}}>{i18next.t("identificacion.periodicidad",{ns:"page"})}</td>
                                            <td style={{width:'85%'}}>
                                            <Periodicidad cerrarModal={this.cerrarPeriodicidad} periodicidad={this.state.periodicidad} setPeriodicidad={this.setPeriodicidad} abierto={this.state.modalPeriodicidad} ref={(input) => { this.Periodicidad= input; }} i18next={i18next}/>
                                            <div style={!this.state.periodicidad?{border:"1px solid red"}:{}}>
                                                <InputGroup
                                                        style={{fontSize:'12px'}}
                                                        placeholder=""
                                                        type="text"
                                                        large="true"
                                                        readOnly={true}
                                                        value={this.state.periodicidad?this.formatearPeriodicidad(this.state.periodicidad):null}
                                                        rightElement={<Button icon="time" onClick={this.abrirModalPeriodicidad} />}
                                                />
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'15%'}}>{i18next.t("identificacion.observaciones",{ns:"page"})}</td>
                                            <td style={{width:'85%'}}>
                                                <FormGroup
                                                    intent="danger"
                                                    helperText={!this.state.observaciones?i18next.t("identificacion.emptyobservaciones",{ns:"page"}):""}
                                                >
                                                    <TextArea style={{fontSize:'12px'}} fill={true} value={this.state.observaciones} intent={!this.state.observaciones?"danger":"primary"} onChange={handleCambiarObservaciones}/>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                        <br />
                    </div>
                    <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]} >
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                
                    </div>
                    </div>
                    }
                </Dialog>
                {this.state.cargando?
                    <Cargando />
                :
                <>
                
                {this.state.fichas? this.state.fichas.length !== 0? (
                    <div className="tablaCien">
                        <div className="CMIflex">
                            {this.state.fichas.map(ficha => (
                                
                                <div className="cardCMI" key={ficha.ID_AREA}>
                                    <Card
                                        className="pixelAlrededorGrande"
                                        style={{width:'100%'}}
                                        elevation={Elevation.TWO}
                                        interactive={true}
                                    >
                                        <p><b>{i18next.t("identificacion.area",{ns:"page"})}: </b>{ficha.AREA}</p>
                                        <p><b>{i18next.t("identificacion.departamento",{ns:"page"})}: </b>{ficha.DEPARTAMENTO}</p>
                                            
                                        {ficha.CHILD.map((r,rindex) => {
                                            let elm=[];
                                            if (r.ID_DEPARTAMENTO+r.BLOQUE!==bloque) {
                                                bloque=r.ID_DEPARTAMENTO+r.BLOQUE;
                                                elm.push(<p><b>{i18next.t("identificacion.bloque",{ns:"page"})}: </b>{r.BLOQUE}</p>);     
                                            }
                                            elm.push(
                                            <div className="tg-wrap">
                                            <table className="tablaToAncha">
                                                <tbody>
                                        
                                                    <tr
                                                        id=""
                                                        className={
                                                            r.NIVEL_INTENSIDAD < 8
                                                            ? "celdaFondoVerde cuerpo-tabla-cuadro"
                                                            : r.NIVEL_INTENSIDAD < 15
                                                            ? "celdaFondoAmarillo cuerpo-tabla-cuadro"
                                                            : r.NIVEL_INTENSIDAD < 22
                                                            ? "celdaFondoNaranja cuerpo-tabla-cuadro"
                                                            : "celdaFondoRojo cuerpo-tabla-cuadro"
                                                        }
                                                        >
                                                        <td onClick={() => abrirModal(r)}>
                                                            <b>{r.NIVEL_INTENSIDAD + " " + r.RIESGO} </b>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            )
                                        return elm;
                                        })}
                                                
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                ) :(
                    /* SI NO hay nada en arbol, mandamos al usuario a crear los elementos */
                  <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                      <p>{i18next.t("identificacion.rutacompliance",{ns:'page'})}</p>
                      <Button onClick={this.irAcompliance}>{i18next.t("identificacion.pulseaquiseccion",{ns:'page'})}</Button>
                    </Callout>
                  </Card>
                ):
                <></>}
                </>
                }
            </>
        );
    }
}

export default withTranslation()(withApollo(IdentificacionRiesgo));