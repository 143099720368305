import React, { Component } from 'react';
import { Card, FileInput } from "@blueprintjs/core"
import axios from 'axios';
import gql from "graphql-tag";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { withApollo } from 'react-apollo';

class Logotipo extends Component {

    state = {
        file: '',imagePreviewUrl: '',
        logotipo:null,
        pasoGuia:0,
        steps: [
            {
              target: '#subida-imagen-cabecera',
              content: i18next.t("logotipo.seleccione",{ns:"page"}),
              selector: '#subida-imagen-cabecera',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex:0, 
            },      
        ]
    };

    _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log('manejar subida-', this.state.file);
    }
    
    _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = async() => {
        this.setState({
            file: file,
            imagePreviewUrl: reader.result
        });

        const api = axios.create({
            withCredentials: true
        });

        //eliminamos el anterior
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_TIPO_ADJUNTO:3,ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            if(result.data.adjuntos.length>0){
                await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${result.data.adjuntos[0].ID_ADJUNTO}){ID_ADJUNTO}}`)
            }
        })
 

        //actualizamos el logo de la empresa
        let formData = new FormData();
        formData.append("files",file);
        await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:3,NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"LOGOTIPO_EMPRESA"}){ID_ADJUNTO}}`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
    }

    reader.readAsDataURL(file)
    }

    
    componentDidMount=(props)=>{

        localStorage.setItem("ayuda",false)

        this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){
                this.setState({
                    imagePreviewUrl:process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".","")
                })
            }
        })

    }

    
    render() {

        const {steps} = this.state;

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("logotipo.title",{ns:"page"})

        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
          $imagePreview = (<img alt={i18next.t("logotipo.previsualizacion",{ns:"page"})} src={imagePreviewUrl} />);
        } else {
          $imagePreview = (<div className="previewText">{i18next.t("logotipo.previsualizarla",{ns:"page"})}</div>);
        }

        const handleJoyrideCallback=async(data)=>{
        

            const { action, index, status, type } = data;
      
            if ([EVENTS.STEP_AFTER].includes(type)) {
      
              let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
      
              await this.setState({ pasoGuia: paso });         
      
            }
      
            //si termina la guia.
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                localStorage.setItem("ayuda",false)
            }
      
        } 
    
        return (
            <>
                <Joyride
                    run={localStorage.getItem("ayuda")==="true"?true:false}
                    callback={handleJoyrideCallback}
                    showProgress={true}
                    stepIndex={this.state.pasoGuia}
                    steps={steps}
                    disableScrollParentFix={true}
                    continuous ={true}
                    locale={{ back: i18next.t("logotipo.back",{ns:"page"}), close: i18next.t("logotipo.close",{ns:"page"}), last: i18next.t("logotipo.last",{ns:"page"}), next: i18next.t("logotipo.next",{ns:"page"}), skip: i18next.t("logotipo.skip",{ns:"page"}) }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            primaryColor: '#E31E24',
                        }
                    }}
                />
                <Card>
                    <div className="previewComponent">
                        <form onSubmit={(e)=>this._handleSubmit(e)}>
                            <FileInput  id="subida-imagen-cabecera" text={i18next.t("logotipo.escoja",{ns:"page"})} disabled={!this.props.editable} onInputChange={(e)=>this._handleImageChange(e)} />
                        </form>
                        <div className="imgPreview">
                            {$imagePreview}
                        </div>
                    </div>
                </Card>
            </>
        )
      }
}
export default withTranslation()(withApollo(Logotipo))