/* eslint-disable array-callback-return */
import React from "react"
import { Tabs, Tab, Card, Elevation, Callout } from "@blueprintjs/core"
import PactosRelacion from "./Elementos/PactosRelacion"
import PactosAtribucion from "./Elementos/PactosAtribucion"
import PactosOrganizacion from "./Elementos/PactosOrganizacion"
import PactosParasociales from "./Elementos/PactosParasociales"
import ProtocolosFamiliares from "./Elementos/ProtocolosFamiliares"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { Cargando } from "../Pantallas/Cargando";
import i18next from "i18next";
import { withTranslation } from "react-i18next";


class AcuerdosParasociales extends React.Component {

    state = {
        pestaña: "pactos-relacion",
        animacion: true,
        modalNuevoAbierto: false,
        modalEditarAbierto: false,
        modalBorrarAbierto: false,
        logotipo:null,
        datosEmpresa:null,
        personasFisicas:null,
        personasJuridicas:null,
        miembrosTransmisiones:null,
        socios:[],
        cargando:true
    };

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    CargarDatosEmpresa= async () =>{
        let datosEmpresa= await this.props.client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
                {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
               {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    
    cargarMiembrosTransmisiones= async () => {
        let miembrosTransmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        
        return miembrosTransmisiones;
       
    }

    cargarDatos= async () => {
        let datosEmpresa= await this.CargarDatosEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        let miembrosTransmisiones=await this.cargarMiembrosTransmisiones()
        this.setState({
            datosEmpresa: datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
            miembrosTransmisiones: miembrosTransmisiones
        })
    }

    cargarSocios=async()=>{
        let capSocialInicialDistribucion=await this.props.client
        .query({
            query: gql` 
                {capSocialInicialDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `, 
            fetchPolicy: 'network-only'
        }).then(async result=>{ 
            return result.data.capSocialInicialDistribucion;
        })
        let socios=[]
        for(let socio of capSocialInicialDistribucion){
            if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if (persona.ID_PERSONA===socio.ID_PERSONA) {
                        socios.push({"value":socio.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`})
                    }
                })
            
            //Si es persona juridica
            }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if (persona.ID_PERSONA===socio.ID_PERSONA) {
                        socios.push({"value":socio.ID_PERSONA,"label":persona.RAZON_SOCIAL})
                    }
                })
            }
        }

        let capSocialActualDistribucion=await this.props.client
        .query({
            query: gql` 
                {capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `, 
            fetchPolicy: 'network-only'
        }).then(async result=>{ 
            return result.data.capSocialActualDistribucion;
        })
        for(let socio of capSocialActualDistribucion){
            if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if (persona.ID_PERSONA===socio.ID_PERSONA) {
                        socios.push({"value":socio.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`})
                    }
                })
            
            //Si es persona juridica
            }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if (persona.ID_PERSONA===socio.ID_PERSONA) {
                        socios.push({"value":socio.ID_PERSONA,"label":persona.RAZON_SOCIAL})
                    }
                })
            }
        }
        
        for(let mt of this.state.miembrosTransmisiones){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=socios.find(s=>{return s.value===mt.DESTINO})
            if(!existe){
                
                if(mt.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find(persona=>{
                        if (persona.ID_PERSONA===mt.DESTINO) {
                            socios.push({"value":mt.DESTINO,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`})
                        }
                    })
                    
                    //Si es persona juridica
                }else if(mt.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find(persona=>{
                        if (persona.ID_PERSONA===mt.DESTINO) {
                            socios.push({"value":mt.ID_PERSONA,"label":persona.RAZON_SOCIAL})
                        }
                    })
                    
                }

            }
        }
        this.setState({socios:socios})
        
    }

    componentDidMount(){

        this.setState({
            cargando: true
        },async () => {
            await this.obtenerLogoEmpresa();
            await this.cargarDatos();
            await this.cargarSocios();
            this.setState({cargando:false});
        });
        
        
                
    }

    render(){

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("acuerdosparasociales.title",{ns:"page"})

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="bp3-intent-danger">
                            {i18next.t("acuerdosparasociales.info",{ns:"page"})}
                        </Callout>
                    </Card>
                </div>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Tabs id="acuerdos-parasociales"
                            animate={this.animacion} 
                            onChange={this.cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                            renderActiveTabPanelOnly={true}
                        >
                            <Tab id="pactos-relacion" title={i18next.t("acuerdosparasociales.relacion",{ns:"page"})} panel={<PactosRelacion editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} socios={this.state.socios}  ref={(input) => { this.pactosRelacion= input; }} i18next={i18next} />} />
                            <Tab id="pactos-atribucion" title={i18next.t("acuerdosparasociales.atribucion",{ns:"page"})} panel={<PactosAtribucion editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} socios={this.state.socios} ref={(input) => { this.pactosAtribucion= input; }} i18next={i18next} />} />
                            <Tab id="pactos-organizacion" title={i18next.t("acuerdosparasociales.organizacion",{ns:"page"})} panel={<PactosOrganizacion editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} socios={this.state.socios} ref={(input) => { this.pactosOrganizacion= input; }}i18next={i18next} />} />
                            <Tab id="protocolos-familiares" title={i18next.t("acuerdosparasociales.protocolos",{ns:"page"})} panel={<ProtocolosFamiliares editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} socios={this.state.socios} ref={(input) => { this.ProtocolosFamiliares= input; }}i18next={i18next} />} />
                            <Tab id="otros-pactos" title={i18next.t("acuerdosparasociales.otros",{ns:"page"})} panel={<PactosParasociales editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} personasFisicas={this.state.personasFisicas} personasJuridicas={this.state.personasJuridicas} socios={this.state.socios} ref={(input) => { this.PactosParasociales= input; }} i18next={i18next} />} />
                        </Tabs>
                    </Card>
                </div>
            </>
            }
            </>
        )
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });
}

export default withTranslation()(withApollo(AcuerdosParasociales))
