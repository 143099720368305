/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import {
    Navbar,
    Card,
    Elevation,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    Callout,
    FormGroup,
    Classes,
    Tooltip,
    AnchorButton,
    Intent,
    Icon,
    Checkbox,
    Position,
    TextArea,
    InputGroup,
    Spinner
} from "@blueprintjs/core";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { DateInput } from "@blueprintjs/datetime";
import { withApollo } from 'react-apollo';
import { Cargando } from "../Pantallas/Cargando";
import gql from "graphql-tag";
import moment from 'moment';
import Select from "react-select";
import Periodicidad from "./Elementos/Periodicidad";
import AccionesSeguimiento from "./Elementos/AccionesSeguimiento";
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import { 
    StyleSheet
} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './stylesPrint';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import iconoFooter from '../../Assets/lc.png';
import iconoendorsed from '../../Assets/endorsed.png';

const sty = StyleSheet.create({
    dialog2:{
        width: '45%'
    }
});

const today = new Date();

let valoranList=[];
let asignadoList=[];
let imperativosList=[];
let recomendacionesList=[];
let formativasList=[];
let informativasList=[];


class Identificacion extends Component {
    state = {
        identificaciones:[],
        identificacionSeleccionada:null,
        seguimientos:[],
        seguimientoSeleccionada:null,
        cargando:true,
        recargando:false,
        recargandoSeguimiento:false,
        recargandoFormativa:false,
        recargandoInformativa:false,
        recargandoRecomendaciones:false,
        recargandoImperativos:false,
        nuevoActivado:true,
        editarActivado:false,
        nuevoActivadoSeguimiento:true,
        editarActivadoSeguimiento:false,
        modalNuevoAbierto: false,
        modalNuevoAbiertoSeguimiento: false,
        areaSeleccionada:null,
        areasSelect:[],
        deptoSeleccionado:null,
        deptosSelect:[],
        bloquePadreSeleccionado:null,
        bloquesPadreSelect:[],
        bloquesRiesgo:[],
        soloPenales: false,
        bloqueHijoSeleccionado:null,
        bloquesHijoSelect:[],
        logotipo:null,
        datosEmpresa:null,
        fechaFicha: null,
        fechaFichaSeguimiento:null,
        numero: "1",
        numeroSeguimiento:"1",
        nivelRiesgo: null,
        nivelSancion: 1,
        nivelPerdida: 2,
        nivelSuspension: 3,
        nivelReputacional: 4,
        nivelOtros: 5,
        categoriaRiesgo: 0,
        categoriaSancion: 0,
        riesgoHaSucedidoAntes: false,
        modalIdentificacionAbierto:false,
        dialogTextoRiesgo: false,
        categoriasRiesgo: [i18next.t("identificacion.bajo",{ns:"page"}), i18next.t("identificacion.medio",{ns:"page"}), i18next.t("identificacion.alto",{ns:"page"}), i18next.t("identificacion.muyalto",{ns:"page"})],
        intentCategoriasRiesgo: ["celdaFondoVerde", "celdaFondoAmarillo", "celdaFondoNaranja", "celdaFondoRojo"],
        intentCategoriasRiesgo2: ["celdaFondoVerde", "celdaFondoAmarillo","celdaFondoAmarillo", "celdaFondoNaranja", "celdaFondoRojo"],
        valoranSeleccionada:[],
        asignadoSeleccionada:[],
        valoran:[],
        asignados:[],
        personasFisicas:null,
        personasJuridicas:null,
        recursosFinancieros: '',
        recursosOrganizacion: "",
        recursosExternos: "",
        modalPeriodicidad:false,
        observaciones:null,
        periodicidad:null,
        idUsuario:null,
        modalImperativos:false,
        impertivosSeleccionados:[],
        nivelImperativas: [],
        nivelRecomendaciones: [],
        recomendacionesSeleccionados:[],
        modalRecomendaciones:false,
        imperativoSeleccionadoTabla: null,
        recomendadosSeleccionadoTabla: null,
        nuevaRecomendacion:null,
        modalNuevaRecomendacion:false,
        imperativos: [],
        recomendaciones: [],
        nuevoImperativo:null,
        modalNuevoImperativo:false,
        nombreInformante: "",
        DNIinformante:null,
        dniInvalido:false,
        certificado:false,
        explicacionImperativos: "",
        explicacionRecomendados: "",
        explicacionSalvedades: "",
        fechaProximaValoracionControles: new Date(),
        fechaProximaValoracion: new Date(),
        numFormacion: 0,
        numInformacion: 0,
        elegirAccionFormativa: false,
        elegirAccionInformativa: false,
        formacionSeleccionada:false,
        informacionSeleccionada:false,
        accionesFormativas:[],
        accionesInformativas:[],
        crearAccionFormativa:false,
        crearAccionInformativa:false,
        modalImprimirSeguimiento:false,
        modalImprimirIdentificaciones:false,
        modalImprimirFicha:false,
        modalImprimirFichaSeguimiento:false,
        cargandoImprimirGral:false,
        cargandoImprimirSegGral:false,
        cargandoPrintFicha:false,
        cargandoPrintFichaSeguimiento:false,
        modalidades:[],
        canales:[],
        intentCategoriasPrint: ["forestgreen", "yellow","yellow", "orangered", "#db3737"],
        intentCategoriasPrintColor: ["white", "black","black", "white", "white"]
    }

    componentDidMount=()=>{

        this.setState({ 
            cargando: true,
        },async ()=>{
            await this.obtenerLogoEmpresa();
            await this.cargarDatos();
            await this.obtenerContrato();
            await this.recargarBloquesPadres();
            await this.cargarModalidadesFormacion();
            await this.cargarCanales();
            await this.recargarIdentificaciones();
        });

    }

    cargarCanales=async()=>{

        await this.props.client.query({
            query:gql`{canalesInformacion{ID_CANAL_INFORMACION,DESCRIPCION}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let canales=[]
            for(let canal of result.data.canalesInformacion){
                canales[canal.ID_CANAL_INFORMACION]=canal.DESCRIPCION;
            }
            await this.setState({canales:canales})
  
        })
        
    }

    cargarModalidadesFormacion=async()=>{

        await this.props.client.query({
            query:gql`{modalidadesFormacion{ID_MODALIDAD_FORMACION,DESCRIPCION}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let modalidades=[]
            for(let modalidad of result.data.modalidadesFormacion){
                modalidades[modalidad.ID_MODALIDAD_FORMACION]=modalidad.DESCRIPCION;
            }
            await this.setState({modalidades:modalidades})
  
        })
        
    }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa();
        let personasFisicas=await this.cargarPersonasFisicas();
        let personasJuridicas=await this.cargarPersonasJuridicas();

        let usuarioActual=await this.props.client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obtenerUsuarioActual
        })

        let asignados=[];
        let personas=[];
        personasFisicas.forEach(persona=>{
            personas.push({label:`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,value:persona.ID_PERSONA})
            asignados.push({label:`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,value:persona.ID_PERSONA})
        });
        personasJuridicas.forEach(persona=>{
            asignados.push({label:persona.RAZON_SOCIAL,value:persona.ID_PERSONA})
        })
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            valoran:personas,
            personasJuridicas: personasJuridicas,
            asignados:asignados,
            idUsuario:usuarioActual
        });

    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }

    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    obtenerLogoEmpresa=async()=>{
    //obtencion logo de la empresa
        await this.props.client
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    recargarRecomendaciones2=async(IDDOCUMENTO)=>{
        let recomendaciones = await this.props.client
        .query({
        query: gql` 
                {SeguimientoRecomendaciones(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){
                    ID_DOCUMENTO_RECOMENDACION,
                    ID_DOCUMENTO,
                    ID_RECOMENDACION,
                    RECOMENDACION{DESCRIPCION},
                    VALOR
                }}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.SeguimientoRecomendaciones
        });
        
        recomendaciones.forEach((recomendacion,index)=>{
            recomendaciones[index].value=recomendacion.ID_RECOMENDACION
            recomendaciones[index].nivel=recomendacion.VALOR
            recomendaciones[index].label=recomendacion.RECOMENDACION.DESCRIPCION
        })
        
        return recomendaciones;
    }

    recargarRecomendaciones=async()=>{
        let recomendaciones = await this.props.client
        .query({
        query: gql` 
                {SeguimientoRecomendaciones(FILTROS:{ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO}}){
                    ID_DOCUMENTO_RECOMENDACION,
                    ID_DOCUMENTO,
                    ID_RECOMENDACION,
                    RECOMENDACION{DESCRIPCION},
                    VALOR
                }}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.SeguimientoRecomendaciones
        });
        
        recomendaciones.forEach((recomendacion,index)=>{
            recomendaciones[index].value=recomendacion.ID_RECOMENDACION
            recomendaciones[index].nivel=recomendacion.VALOR
            recomendaciones[index].label=recomendacion.RECOMENDACION.DESCRIPCION
        })
        
        return recomendaciones;
    }

    recargarImperativas2=async(IDDOCUMENTO)=>{
        let imperativas=await this.props.client
        .query({
          query: gql` 
                  {complianceSeguimientoImperativas(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){
                    ID_DOCUMENTO_IMPERATIVA,
                    ID_DOCUMENTO,
                    ID_IMPERATIVA,
                    IMPERATIVA{DESCRIPCION},
                    VALOR
                  }}
              `,
              fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.complianceSeguimientoImperativas
        });
        imperativas.forEach((imperativa,index)=>{
            imperativas[index].value=imperativa.ID_IMPERATIVA
            imperativas[index].nivel=imperativa.VALOR
            imperativas[index].label=imperativa.IMPERATIVA.DESCRIPCION
        })
        
        return imperativas;
    }

    recargarImperativas=async()=>{
        let imperativas=await this.props.client
        .query({
          query: gql` 
                  {complianceSeguimientoImperativas(FILTROS:{ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO}}){
                    ID_DOCUMENTO_IMPERATIVA,
                    ID_DOCUMENTO,
                    ID_IMPERATIVA,
                    IMPERATIVA{DESCRIPCION},
                    VALOR
                  }}
              `,
              fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.complianceSeguimientoImperativas
        });
        imperativas.forEach((imperativa,index)=>{
            imperativas[index].value=imperativa.ID_IMPERATIVA
            imperativas[index].nivel=imperativa.VALOR
            imperativas[index].label=imperativa.IMPERATIVA.DESCRIPCION
        })
        
        return imperativas;
    }

    obtenerDocumentos=async()=>{
        let idContratoDepartamento=this.state.deptoSeleccionado.contDept;
        let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
        if (riesgo>0) {
            await this.props.client.query({
                query:gql`{complianceDocumentosValoraciones(FILTROS:{ID_CONTRATO_DEPARTAMENTO:${idContratoDepartamento},ID_RIESGO:${riesgo}},ORDEN:"NUMERO,ASC"){ID_DOCUMENTO,ID_CONTRATO_DEPARTAMENTO,ID_RIESGO,NUMERO,FECHA}}`
                ,fetchPolicy:'network-only'
            }).then((result)=>{
                result.data.complianceDocumentosValoraciones.forEach(contr=>{
                    this.setState({
                        numero:contr.NUMERO+1
                    });
                })
            })  
        }
    }

    obtenerNumeroSeguimiento=async()=>{
        let idContratoDepartamento=this.state.deptoSeleccionado.contDept;
        let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
        if (riesgo>0) {
            await this.props.client.query({
                query:gql`{complianceDocumentosSeguimientos(FILTROS:{ID_CONTRATO_DEPARTAMENTO:${idContratoDepartamento},ID_RIESGO:${riesgo}},ORDEN:"NUMERO,ASC"){
                    ID_DOCUMENTO,
                    NUMERO
                }}`
                ,fetchPolicy:'network-only'
            }).then((result)=>{
                result.data.complianceDocumentosSeguimientos.forEach(contr=>{
                    this.setState({
                        numeroSeguimiento:contr.NUMERO+1
                    });
                });
            })  
        }
    }

    recargasSeguimiento=async(complianceDocumentosSeguimientos)=>{
        if (complianceDocumentosSeguimientos.length>0) {
            complianceDocumentosSeguimientos.map(async (seguimiento,index) => {
                imperativosList[index]=[];
                recomendacionesList[index]=[];
                formativasList[index]=[];
                informativasList[index]=[];
                imperativosList[index]=await this.recargarImperativas2(seguimiento.ID_DOCUMENTO);
                recomendacionesList[index]=await this.recargarRecomendaciones2(seguimiento.ID_DOCUMENTO);
                formativasList[index]=await this.cargarFormaciones2(seguimiento.ID_DOCUMENTO);
                informativasList[index]=await this.cargarInformacion2(seguimiento.ID_DOCUMENTO);
            if (index===complianceDocumentosSeguimientos.length-1) {
                this.setState({recargandoSeguimiento:false});
            }
            });
        } else {
            this.setState({recargandoSeguimiento:false});
        }
        
    }

    obtenerDocumentosSeguimiento=async()=>{
            await this.props.client.query({
                query:gql`{complianceDocumentosSeguimientos(FILTROS:{ID_DOCUMENTO_VALORACION:${this.state.identificacionSeleccionada.ID_DOCUMENTO}},ORDEN:"NUMERO,ASC"){
                    ID_DOCUMENTO,
                    ID_CONTRATO_DEPARTAMENTO,
                    CONTRATO_DEPARTAMENTO{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION,AREA{ID_AREA,DESCRIPCION}}}
                    ID_RIESGO,
                    RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO,DESCRIPCION,BLOQUE{ID_BLOQUE,TITULO,LETRA,DESCRIPCION}}
                    FECHA,
                    NUMERO,
                    VALORACION{ID_DOCUMENTO,NUMERO},
                    NOMBRE_APELLIDOS,
                    DOCUMENTO_IDENTIDAD,
                    EMPRESA,
                    PUESTO,
                    EXPLICACION_IMPERATIVOS,
                    EXPLICACION_RECOMENDADOS,
                    EXPLICACION_SALVEDADES,
                    FECHA_PROPUESTA_PROXIMA,
                    OBSERVACIONES,
                    FECHA_PROXIMA,
                    ID_USUARIO,
                    COMPLIANCE_CERTIFICA
                }}`
                ,fetchPolicy:'network-only'
            }).then((result)=>{
                this.setState({
                    seguimientos:result.data.complianceDocumentosSeguimientos
                },async ()=>{
                    await this.recargasSeguimiento(result.data.complianceDocumentosSeguimientos);
                    console.log("termino await seguimientos");
                    
                });
            })  
    }

    obtenerContrato=async()=>{
        let contratos= await this.props.client
        .query({
            query: gql`
            {complianceContratos{ID_CONTRATO,SOLO_PENAL}}
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.complianceContratos
        })
        this.setState({
            soloPenales:contratos.SOLO_PENAL
        })
    }

    recargarBloquesPadres=async()=>{

        await this.props.client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"${this.state.soloPenales?",FILTROS:{ID_BLOQUE:4}":""}){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let bloques=[];
            let indice=0;
            result.data.bloquesRiesgo.forEach(blq=>{
                indice+=1;
                bloques.push({"value":blq.ID_BLOQUE,"indice":indice.toString().padStart(2,"0"),"letra":blq.LETRA,"label":"Bloque "+blq.LETRA+" - "+blq.TITULO,"titulo":blq.TITULO});
            });
            this.setState({
                bloquesRiesgo:bloques
            });
        })
        
        

    }

    recargarBloquesHijos=async(idPadre)=>{

        await this.props.client.query({
            query:gql`{complianceRiesgos(FILTROS:{ID_BLOQUE:${idPadre.value}}){ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE,ID_BLOQUE}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let bloques=[];
            let indice=0;
            result.data.complianceRiesgos.forEach(blq=>{
                if (blq.ID_RIESGO_PADRE===null) {
                    indice+=1;
                    bloques.push({"value":blq.ID_RIESGO,"indice":indice.toString().padStart(2,"0"),"label":blq.TITULO,"descripcion":blq.DESCRIPCION})
                }
            })
            this.setState({bloquesHijoSelect:bloques});
        })

    }

    recargarDeptos=async(idArea)=>{

        await this.props.client.query({
            query:gql`{complianceContratosDepartamentos{ID_CONTRATO_DEPARTAMENTO,DEPARTAMENTO{ID_DEPARTAMENTO,ID_AREA,DESCRIPCION,PREDEFINIDO}}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let deptos=[];
            let existe=false;
            let pertenece=false;
            let indice=0;
            result.data.complianceContratosDepartamentos.forEach(dpto=>{
                existe=deptos.find(depto=>{return depto.value===dpto.DEPARTAMENTO.ID_DEPARTAMENTO});
                
                pertenece=(dpto.DEPARTAMENTO.ID_AREA===idArea.value)?true:false;

                if (!existe && pertenece) {
                    indice+=1;
                    deptos.push({"value":dpto.DEPARTAMENTO.ID_DEPARTAMENTO,"contDept":dpto.ID_CONTRATO_DEPARTAMENTO,"indice":indice,"label":dpto.DEPARTAMENTO.DESCRIPCION})
                }
            })
            this.setState({deptosSelect:deptos})
        })

    }

    recuperarValoraciones2=async(IDDOCUMENTO)=>{
        let rtdo =await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoran(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let vals=[]; 
            result.data.complianceDocumentosValoran.forEach(async (valoracion)=>{
                let valoranSel=await this.state.valoran.find(val=>{
                    return val.value===valoracion.ID_PERSONA
                })
                vals.push(valoranSel);
            });
            return vals;
        });
        return rtdo;
    }

    recuperarValoraciones=async()=>{
        await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoran(FILTROS:{ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let vals=[]; 
            result.data.complianceDocumentosValoran.forEach(async (valoracion)=>{
                let valoranSeleccionada=await this.state.valoran.find(val=>{
                    return val.value===valoracion.ID_PERSONA
                })
                vals.push(valoranSeleccionada);
            });
            this.setState({valoranSeleccionada:vals});
        });
    }

    recuperarRecursos2=async(IDDOCUMENTO)=>{
        let rtdo = await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosRecursos(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let recs=[]; 
            result.data.complianceDocumentosRecursos.forEach(async (recurso)=>{
                let asignadoSeleccionado=await this.state.asignados.find(val=>{
                    return val.value===recurso.ID_PERSONA
                })
                recs.push(asignadoSeleccionado);
            });
            return recs;
        });
        return rtdo;
    }

    recuperarRecursos=async()=>{
        await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosRecursos(FILTROS:{ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let recs=[]; 
            result.data.complianceDocumentosRecursos.forEach(async (recurso)=>{
                let asignadoSeleccionado=await this.state.asignados.find(val=>{
                    return val.value===recurso.ID_PERSONA
                })
                recs.push(asignadoSeleccionado);
            });
            this.setState({asignadoSeleccionada:recs});
        });
    }

    recuperarAreaDptoBloqueRiesgo=async()=>{
        let identificador =this.state.identificacionSeleccionada;
        let area = {"value":identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.ID_AREA,"label":identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION}
        let dpto = {"value":identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.ID_DEPARTAMENTO,"contDept":identificador.ID_CONTRATO_DEPARTAMENTO,"label":identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION}
        let bloque = {"value":identificador.RIESGO.BLOQUE.ID_BLOQUE,"letra":identificador.RIESGO.BLOQUE.LETRA,"label":"Bloque "+identificador.RIESGO.BLOQUE.LETRA+" - "+identificador.RIESGO.BLOQUE.TITULO,"titulo":identificador.RIESGO.BLOQUE.TITULO}
        let riesgo = {"value":identificador.ID_RIESGO,"label":identificador.RIESGO.TITULO,"descripcion":identificador.RIESGO.DESCRIPCION}
        
        this.setState({
            areaSeleccionada:area,
            deptoSeleccionado:dpto,
            bloqueHijoSeleccionado:riesgo,
            bloquePadreSeleccionado:bloque
        })
    }

    recargarAreas=async()=>{

        await this.props.client.query({
            query:gql`{complianceContratosDepartamentos{DEPARTAMENTO{ID_DEPARTAMENTO,ID_AREA,AREA{ID_AREA,DESCRIPCION,PREDEFINIDA},PREDEFINIDO}}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let areas=[];
            let existe=false;
            let indice=0;
            result.data.complianceContratosDepartamentos.forEach(dpto=>{
                existe=areas.find(area=>{return area.value===dpto.DEPARTAMENTO.AREA.ID_AREA});
                if (!existe) {
                    indice+=1;
                    areas.push({"value":dpto.DEPARTAMENTO.AREA.ID_AREA,"indice":indice,"label":dpto.DEPARTAMENTO.AREA.DESCRIPCION})
                }
            })
            this.setState({areasSelect:areas})
        })

    }

    recargas=async (identificaciones) => {
        if (identificaciones.length>0) {
            identificaciones.map(async (identificador,index) => {
                valoranList[index]=[];
                asignadoList[index]=[];
                valoranList[index]=await this.recuperarValoraciones2(identificador.ID_DOCUMENTO);
                asignadoList[index]=await this.recuperarRecursos2(identificador.ID_DOCUMENTO);
                if (index===identificaciones.length-1) {
                    this.setState({cargando:false});
                }
            });
        } else {
            this.setState({cargando:false});
        }
    }

    recargarIdentificaciones=async()=>{

        let identificaciones=await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoraciones
                    {
                        ID_DOCUMENTO,
                        ID_CONTRATO_DEPARTAMENTO,
                        COMPLIANCE_CONTRATOS_DEPARTAMENTOS{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION,AREA{ID_AREA,DESCRIPCION}}}
                        ID_RIESGO,
                        RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO,DESCRIPCION,BLOQUE{ID_BLOQUE,TITULO,LETRA,DESCRIPCION}}
                        FECHA,
                        NUMERO,
                        NIVEL_INTENSIDAD,
                        OCURRIDO_ANTES,
                        CONSECUENCIAS_SANCION,
                        CONSECUENCIAS_PERDIDA,
                        CONSECUENCIAS_SUSPENSION,
                        CONSECUENCIAS_DANO,
                        CONSECUENCIAS_OTROS,
                        RECURSOS_FINANCIEROS,
                        RECURSOS_ORGANIZACION,
                        RECURSOS_EXTERNOS,
                        PERIODICIDAD_REVISION,
                        OBSERVACIONES,
                        PERIODICIDAD_REVISION,
                        ID_USUARIO
                    }
                }
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return  result.data.complianceDocumentosValoraciones
        })
        //console.log(identificaciones);
        let val1_1,val1_2,val2_1,val2_2,val3_1,val3_2,val4_1,val4_2,val5_1,val5_2='';
        identificaciones.sort(function (a, b){
            val1_1=a.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION;
            val1_2=b.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION;
            val2_1=a.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION;
            val2_2=b.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION;
            val3_1=a.RIESGO.BLOQUE.LETRA;
            val3_2=b.RIESGO.BLOQUE.LETRA;
            val4_1=a.FECHA;
            val4_2=b.FECHA;
            val5_1=a.NUMERO;
            val5_2=b.NUMERO;
            return val1_1.localeCompare(val1_2) || val2_1.localeCompare(val2_2) || val3_1.localeCompare(val3_2) || val4_1.localeCompare(val4_2) || val5_1 - val5_2;
        });
        
        this.setState({
            identificaciones:identificaciones
        },async ()=>{
            await this.recargas(identificaciones);
            
            console.log("termino await identificaciones");
            
        })
    
    }

    cerrarPeriodicidad = () => {
        this.setState({
          modalPeriodicidad:false
        })
    }

    abrirModalPeriodicidad=()=>{
        this.setState({modalPeriodicidad:true})
    }

    setPeriodicidad=(valor)=>{
        this.setState({periodicidad:valor})
    }

    formatearPeriodicidad=(periodicidad)=>{
        
        if(typeof periodicidad==="string"){
            periodicidad=JSON.parse(periodicidad)
        }

        let format=""
        if(periodicidad.dia && periodicidad.dia>0){
            format+="El día "+periodicidad.dia+" de"
        }else if(periodicidad.diaSemana){
            format+="El "+periodicidad.ordinal+" "+periodicidad.diaSemana+" de"
        }

        if(periodicidad.numeroMes && periodicidad.numeroMes>0){
            format+=" cada "+periodicidad.numeroMes+" meses"
        }else if(periodicidad.meseseSeleccionados.length>0){
            let meses=periodicidad.meseseSeleccionados.map(function(elem){
                return elem.label;
            }).join(",");
            format+=" los meses "+meses
        }
        return format
    }

    async cargarRecomendaciones(idRiesgo){

        await this.props.client.query({
            query:gql`{complianceRecomendaciones(FILTROS:{ID_RIESGO:${idRiesgo}}){ID_RECOMENDACION,DESCRIPCION,PREDEFINIDA,ID_RIESGO}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{

          let recomendaciones=[]
            result.data.complianceRecomendaciones.forEach((recomendacion)=>{
                recomendaciones.push({"value":recomendacion.ID_RECOMENDACION,"label":recomendacion.DESCRIPCION,"predefinida":recomendacion.PREDEFINIDA,"idRiesgo":recomendacion.ID_RIESGO})
            })

            this.setState({recomendaciones:recomendaciones}) 
        })

    }

    async cargarImperativos(idRiesgo){

        await this.props.client.query({
            query:gql`{complianceImperativas(FILTROS:{ID_RIESGO:${idRiesgo}}){ID_IMPERATIVA,DESCRIPCION,PREDEFINIDA,ID_RIESGO}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{

          let imperativos=[]
           for(let imperativo of  result.data.complianceImperativas){
                imperativos.push({"value":imperativo.ID_IMPERATIVA,"label":imperativo.DESCRIPCION,"predefinida":imperativo.PREDEFINIDA,"idRiesgo":imperativo.ID_RIESGO})
            }
            this.setState({imperativos:imperativos}) 
        })

    }

    cargarFormaciones2=async(idDocumento)=>{
        //obtencion de las formaciones
        let formaciones = await this.props.client
        .query({
            query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"F"}){
                ID_ACCION
                ID_AREA,
                AREA{DESCRIPCION}
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION}
                ID_PUESTO_TRABAJO,
                PUESTO_TRABAJO{DESCRIPCION}
                ID_EMPLEADO,
                F_TITULO,
                F_NUMERO_HORAS,
                F_MODALIDAD,
                F_INICIO,
                F_FIN,
                F_HORARIO,
                F_PROCEDIMIENTO_EVALUACION,
                F_REQUISITOS_ACCESO,
                F_COMISION_ACADEMICA,
                F_PROFESORADO,
                F_COMPETENCIAS,
                F_OBJETIVOS,
                F_CONTENIDOS,
                LUGAR,
                OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
        })
        .then(async result => {
            
            return result.data.formacionesInformacion
                
        });

        return formaciones;
    }

    cargarFormaciones=async(idDocumento)=>{

        //obtencion de las formaciones
        await this.props.client
        .query({
            query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"F"}){
                ID_ACCION
                ID_AREA,
                AREA{DESCRIPCION}
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION}
                ID_PUESTO_TRABAJO,
                PUESTO_TRABAJO{DESCRIPCION}
                ID_EMPLEADO,
                F_TITULO,
                F_NUMERO_HORAS,
                F_MODALIDAD,
                F_INICIO,
                F_FIN,
                F_HORARIO,
                F_PROCEDIMIENTO_EVALUACION,
                F_REQUISITOS_ACCESO,
                F_COMISION_ACADEMICA,
                F_PROFESORADO,
                F_COMPETENCIAS,
                F_OBJETIVOS,
                F_CONTENIDOS,
                LUGAR,
                OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
        })
        .then(async result => {
    
            for(let formacion of result.data.formacionesInformacion){
    
                //obtenemos los riesgos de la formacion
                let riesgos=await this.props.client
                .query({
                    query: gql` 
                    {formacionesInformacionRiesgo(FILTROS:{ID_ACCION:${formacion.ID_ACCION}}){
                        RIESGO{ID_RIESGO,DESCRIPCION}
                    }}`,fetchPolicy:'network-only'
                    
                }).then(async result=>{ 
                    return result.data.formacionesInformacionRiesgo
                })
        
                formacion.riesgos=riesgos
        
                //obtenemos el comprobante
                if(formacion.ID_EMPLEADO){
        
                    let comprobante=await this.props.client
                    .query({
                        query: gql`
                        {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${formacion.ID_EMPLEADO},ID_ACCION:${formacion.ID_ACCION}}){ID_ACCION_PERSONA,ID_ACCION,ID_PERSONA,FECHA_CONFIRMACION,APROVECHAMIENTO}}
                        `
                        ,fetchPolicy:'network-only'
                    }).then(result=>{ 
                        return result.data.formacionInformacionPersonas[0]
                    })
        
    
                    if(comprobante){
        
                        formacion.comprobante=comprobante
            
                        //obtencion del documento del comprobante.
                        await this.props.client
                        .query({
                            query: gql`
                            {
                                adjuntos(CAMPOS:"ID_ADJUNTO,DATOS",FILTROS:{ID_TIPO_ADJUNTO:16,ID_ASOCIADO:${comprobante.ID_ACCION_PERSONA}}){ID_ADJUNTO,DATOS}
                            }
                            `,fetchPolicy:'network-only'
                        }).then(result=>{
                            if(result.data.adjuntos.length>0)formacion.documentoComprobante=result.data.adjuntos[0]
                        })
                    }
                
    
                }
    
            
            }
            
            await this.setState({
            numFormacion:result.data.formacionesInformacion.length,
            accionesFormativas:result.data.formacionesInformacion
            }) 
            
        });
    
    }

    cargarInformacion2=async(idDocumento)=>{
        let informaciones = await this.props.client
        .query({
            query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"I"}){
                    ID_ACCION
                    ID_AREA,
                    AREA{DESCRIPCION}
                    ID_DEPARTAMENTO,
                    DEPARTAMENTO{DESCRIPCION}
                    ID_PUESTO_TRABAJO,
                    PUESTO_TRABAJO{DESCRIPCION}
                    ID_EMPLEADO,
                    I_DESCRIPCION,
                    I_TEXTO,
                    I_CANAL,
                    I_FECHA,
                    I_DURACION,
                    LUGAR,
                    OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
        })
        .then(async result => {
            
            return result.data.formacionesInformacion
                
        });

        return informaciones;
    }

    cargarInformacion=async(idDocumento)=>{

        await this.props.client
            .query({
            query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"I"}){
                    ID_ACCION
                    ID_AREA,
                    AREA{DESCRIPCION}
                    ID_DEPARTAMENTO,
                    DEPARTAMENTO{DESCRIPCION}
                    ID_PUESTO_TRABAJO,
                    PUESTO_TRABAJO{DESCRIPCION}
                    ID_EMPLEADO,
                    I_DESCRIPCION,
                    I_TEXTO,
                    I_CANAL,
                    I_FECHA,
                    I_DURACION,
                    LUGAR,
                    OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
        })
        .then(async result => {
    
            //obtenemos los riesgos de la formacion
            for(let informacion of result.data.formacionesInformacion){
                let riesgos=await this.props.client
                .query({
                    query: gql` 
                    {formacionesInformacionRiesgo(FILTROS:{ID_ACCION:${informacion.ID_ACCION}}){
                        RIESGO{ID_RIESGO,DESCRIPCION}
                    }}`,fetchPolicy:'network-only'
                    
                }).then(async result=>{ 
                return result.data.formacionesInformacionRiesgo
                })
                informacion.riesgos=riesgos
    
                //obtenemos el comprobante
                if(informacion.ID_EMPLEADO){
    
                    let comprobante=await this.props.client
                    .query({
                        query: gql`
                        {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${informacion.ID_EMPLEADO},ID_ACCION:${informacion.ID_ACCION}}){ID_ACCION_PERSONA,ID_ACCION,ID_PERSONA,FECHA_CONFIRMACION,APROVECHAMIENTO}}
                        `
                        ,fetchPolicy:'network-only'
                    }).then(result=>{ 
                        return result.data.formacionInformacionPersonas[0]
                    })
    
    
                    if(comprobante){
        
                        informacion.comprobante=comprobante
        
                        //obtencion del documento del comprobante.
                        await this.props.client
                        .query({
                            query: gql`
                            {
                                adjuntos(CAMPOS:"ID_ADJUNTO,DATOS",FILTROS:{ID_TIPO_ADJUNTO:15,ID_ASOCIADO:${comprobante.ID_ACCION_PERSONA}}){ID_ADJUNTO,DATOS}
                            }
                            `,fetchPolicy:'network-only'
                        }).then(result=>{ 
                            if(result.data.adjuntos.length>0)informacion.documentoComprobante=result.data.adjuntos[0]
                        })
                    }
                
    
                }
    
            }
    
            await this.setState({
                numInformacion:result.data.formacionesInformacion.length,
                accionesInformativas:result.data.formacionesInformacion
            })
            
        });
    
    }

    imprimirFichaSeguimiento = async() => {
        this.setState({
            cargandoImprimirSegGral:true,
            modalImprimirFichaSeguimiento:true,
            cargandoPrintFichaSeguimiento:true
        }, async () => {
            this.setState({cargandoImprimirSegGral:false}, () => {
                ReactDOM.render(this.PrintSeguimiento(), document.getElementById('generar-pdf'))
            })
        })
    }

    imprimirSeguimientoGral = async() => {
        if(!this.state.identificacionSeleccionada){
            alert(i18next.t("noseleccionado",{ns:"global"}));
            return
        }

        if(!this.state.seguimientos.length===0){
            alert(i18next.t("identificacion.noexistprint",{ns:"page"}));
            return
        }

        this.setState({
            cargandoImprimirSegGral:true,
            modalImprimirSeguimiento:true,
            recargandoSeguimiento:true
        }, async () => {
            this.setState({cargandoImprimirSegGral:false}, () => {
                ReactDOM.render(this.ImprimirSegGral(), document.getElementById('generarSegGral-pdf'))
            })
        })
    }

    PrintSeguimiento=()=>{
        let inicio='';
        let fin='';
        let modalidad='';
        let fecha='';
        let canal='';
        return (
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.body}>
                            <View fixed style={styles.row}>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <Image
                                        style={styles.imageHead}
                                        src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                                        />
                                </View>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View fixed style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                                    </View>
                                    <View fixed style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{i18next.t("identificacion.titledocseg",{ns:"page"}).toUpperCase()}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder75Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.controlesimplantados",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                <View style={styles.rowTitleNoneBorder25}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.fechaFichaSeguimiento?moment(this.state.fechaFichaSeguimiento,"YYYY-MM-DD").format("DD/MM/YYYY"):moment().format("DD/MM/YYYY")}</Text>
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.numeroSeguimiento}/{this.state.numeroSeguimiento}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>{this.state.areaSeleccionada?this.state.areaSeleccionada.label:''}</Text>
                                    </View>
                                </View>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</Text>{this.state.deptoSeleccionado?this.state.deptoSeleccionado.label:''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>{this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.titulo:''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100}>
                                    <View  style={styles.rowHeadNoneBorderCallout}>
                                        <Text style={styles.textWrapCallout}>{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.label:''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.operativas",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'80%'}}>{i18next.t("identificacion.nivel",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.imperativas",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                {this.state.impertivosSeleccionados.length>0?this.state.impertivosSeleccionados.map((imperativo, indexImperativo)=>{
                                    return (
                                    <View style={styles.colHead} key={indexImperativo}>
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{imperativo.label}</Text>
                                        {imperativo.nivel === 1?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>
                                        }
                                        {imperativo.nivel === 2?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>2</Text>
                                        }
                                        {imperativo.nivel === 3?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>3</Text>
                                        }
                                        {imperativo.nivel === 4?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>4</Text>
                                        }
                                        {imperativo.nivel === 5?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>5</Text>
                                        }
                                        {imperativo.nivel === 6?
                                            <View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>6</Text>
                                        }
                                    </View>
                                    )
                                }):<></>} 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.recomendaciones",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                {this.state.recomendacionesSeleccionados.length>0?this.state.recomendacionesSeleccionados.map((recomendacion, indexRecomendacion)=>{
                                    return (
                                    <View style={styles.colHead} key={indexRecomendacion}>
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{recomendacion.label}</Text>
                                        {recomendacion.nivel === 1?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>
                                        }
                                        {recomendacion.nivel === 2?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>2</Text>
                                        }
                                        {recomendacion.nivel === 3?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>3</Text>
                                        }
                                        {recomendacion.nivel === 4?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>4</Text>
                                        }
                                        {recomendacion.nivel === 5?
                                            <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>5</Text>
                                        }
                                        {recomendacion.nivel === 6?
                                            <View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                            </View>
                                        :
                                        <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>6</Text>
                                        }
                                    </View>
                                    )
                                }):<></>} 
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informecumplimiento",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.don",{ns:"page"}).toUpperCase()}:</Text>{this.state.nombreInformante?this.state.nombreInformante:""}</Text>
                                    </View>
                                </View>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.condni",{ns:"page"}).toUpperCase()}:</Text>{this.state.DNIinformante?this.state.DNIinformante:""}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}>{i18next.t("identificacion.certifica",{ns:"page"}).toUpperCase()}:</Text>
                                </View>
                            </View>    
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicaimperativo",{ns:"page"}).toUpperCase()}:</Text>{this.state.explicacionImperativos?this.state.explicacionImperativos:""}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicarecomendado",{ns:"page"}).toUpperCase()}:</Text>{this.state.explicacionRecomendados?this.state.explicacionRecomendados:""}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicasalvedades",{ns:"page"}).toUpperCase()}:</Text>{this.state.explicacionSalvedades?this.state.explicacionSalvedades:""}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fechacontroles",{ns:"page"}).toUpperCase()}:</Text>{moment(this.state.fechaProximaValoracionControles,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informesupervisor",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.certifico",{ns:"page"}).toUpperCase()}:</Text>{this.state.certificado?i18next.t("yes",{ns:"global"}):i18next.t("no",{ns:"global"})}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.observaciones",{ns:"page"}).toUpperCase()}:</Text>{this.state.observaciones?this.state.observaciones:""}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Left}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fechavaloracion",{ns:"page"}).toUpperCase()}:</Text>{moment(this.state.fechaProximaValoracion,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.formacion",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("edicionriesgo.titulo",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.numerohoras",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.modalidad",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.inicio",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.fin",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.horario",{ns:"page"}).toUpperCase()}</Text>
                            </View>
                            
                            {this.state.accionesFormativas.length>0?this.state.accionesFormativas.map((formativa, indexFormativa)=>{
                                inicio='';
                                fin='';
                                if (formativa.F_INICIO) {
                                    inicio=moment(formativa.F_INICIO,"YYYY-MM-DD").format("DD/MM/YYYY");
                                }
                                if (formativa.F_FIN) {
                                    fin=moment(formativa.F_FIN,"YYYY-MM-DD").format("DD/MM/YYYY");
                                }
                                modalidad=this.state.modalidades[formativa.F_MODALIDAD];

                                return(
                                <View key={indexFormativa}>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{formativa.F_TITULO?formativa.F_TITULO:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{formativa.F_NUMERO_HORAS?formativa.F_NUMERO_HORAS:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{modalidad?modalidad:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{inicio?inicio:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{fin?fin:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%'}}>{formativa.F_HORARIO?formativa.F_HORARIO:''}</Text>
                                </View>
                                </View>
                                )
                            }):<></>}
                            <View style={styles.rowWhiteSpacing}></View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informacion",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{i18next.t("identificacion.descripcion",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.canal",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.numerohoras",{ns:"page"}).toUpperCase()}</Text>
                                <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'30%'}}>{i18next.t("identificacion.texto",{ns:"page"}).toUpperCase()}</Text>
                            </View>
                            {this.state.accionesInformativas.length>0?this.state.accionesInformativas.map((informativa, indexInformativa)=>{
                                fecha='';
                                if (informativa.I_FECHA) {
                                    fecha=moment(informativa.I_FECHA,"YYYY-MM-DD").format("DD/MM/YYYY");
                                }
                                canal=this.state.canales[informativa.I_CANAL];

                                return(
                                <View key={indexInformativa}>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{informativa.I_DESCRIPCION?informativa.I_DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{canal?canal:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{fecha?fecha:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{informativa.I_DURACION?informativa.I_DURACION:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'30%'}}>{informativa.I_TEXTO?informativa.I_TEXTO:''}</Text>
                                </View>
                                </View>
                                )
                            }):<></>}
                        </View>
                        <Text
                            style={styles.footerPage}
                            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                            fixed
                        />
                        <Image
                            style={styles.icono}
                            src={iconoFooter}
                            fixed
                        />
                        <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                    </Page>
                </Document>
            </PDFViewer>
        )
    }

    ImprimirSegGral=()=>{
    
        return (
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                    {this.state.seguimientos?this.state.seguimientos.map((seguimiento, index) =>{
                        let inicio='';
                        let fin='';
                        let modalidad='';
                        let fecha='';
                        let canal='';
                        return(    
                            <Page size="A4" style={styles.page} key={index}>
                                <View style={styles.body}>
                                    <View fixed style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <Image
                                                style={styles.imageHead}
                                                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                                                />
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                                            </View>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{i18next.t("identificacion.titledocseg",{ns:"page"}).toUpperCase()}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder75Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.controlesimplantados",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder25}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{seguimiento.FECHA?moment(seguimiento.FECHA,"YYYY-MM-DD").format("DD/MM/YYYY"):moment().format("DD/MM/YYYY")}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{seguimiento.NUMERO}/{seguimiento.NUMERO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>{this.state.areaSeleccionada?this.state.areaSeleccionada.label:''}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</Text>{this.state.deptoSeleccionado?this.state.deptoSeleccionado.label:''}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>{this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.titulo:''}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorderCallout}>
                                                <Text style={styles.textWrapCallout}>{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.label:''}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.operativas",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'80%'}}>{i18next.t("identificacion.nivel",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.imperativas",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        {imperativosList[index].length>0?imperativosList[index].map((imperativo, indexImperativo)=>{
                                            return (
                                            <View style={styles.colHead} key={indexImperativo}>
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{imperativo.label}</Text>
                                                {imperativo.nivel === 1?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>
                                                }
                                                {imperativo.nivel === 2?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>2</Text>
                                                }
                                                {imperativo.nivel === 3?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>3</Text>
                                                }
                                                {imperativo.nivel === 4?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>4</Text>
                                                }
                                                {imperativo.nivel === 5?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>5</Text>
                                                }
                                                {imperativo.nivel === 6?
                                                    <View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>6</Text>
                                                }
                                            </View>
                                            )
                                        }):<></>} 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.recomendaciones",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        {recomendacionesList[index].length>0?recomendacionesList[index].map((recomendacion, indexRecomendacion)=>{
                                            return (
                                            <View style={styles.colHead} key={indexRecomendacion}>
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{recomendacion.label}</Text>
                                                {recomendacion.nivel === 1?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>
                                                }
                                                {recomendacion.nivel === 2?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>2</Text>
                                                }
                                                {recomendacion.nivel === 3?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>3</Text>
                                                }
                                                {recomendacion.nivel === 4?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>4</Text>
                                                }
                                                {recomendacion.nivel === 5?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>5</Text>
                                                }
                                                {recomendacion.nivel === 6?
                                                    <View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>6</Text>
                                                }
                                            </View>
                                            )
                                        }):<></>}
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informecumplimiento",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.don",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.NOMBRE_APELLIDOS?seguimiento.NOMBRE_APELLIDOS:''}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.condni",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.DOCUMENTO_IDENTIDAD?seguimiento.DOCUMENTO_IDENTIDAD:''}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}>{i18next.t("identificacion.certifica",{ns:"page"}).toUpperCase()}:</Text>
                                        </View>
                                    </View>    
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicaimperativo",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.EXPLICACION_IMPERATIVOS?seguimiento.EXPLICACION_IMPERATIVOS:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicarecomendado",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.EXPLICACION_RECOMENDADOS?seguimiento.EXPLICACION_RECOMENDADOS:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicasalvedades",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.EXPLICACION_SALVEDADES?seguimiento.EXPLICACION_SALVEDADES:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fechacontroles",{ns:"page"}).toUpperCase()}:</Text>{moment(seguimiento.FECHA_PROPUESTA_PROXIMA,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informesupervisor",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.certifico",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.COMPLIANCE_CERTIFICA?i18next.t("yes",{ns:"global"}):i18next.t("no",{ns:"global"})}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                        <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.observaciones",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.OBSERVACIONES?seguimiento.OBSERVACIONES:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fechavaloracion",{ns:"page"}).toUpperCase()}:</Text>{moment(seguimiento.FECHA_PROXIMA,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.formacion",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("edicionriesgo.titulo",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.numerohoras",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.modalidad",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.inicio",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.fin",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.horario",{ns:"page"}).toUpperCase()}</Text>
                                    </View>
                                    
                                    {formativasList[index].length>0?formativasList[index].map((formativa, indexFormativa)=>{
                                        inicio='';
                                        fin='';
                                        if (formativa.F_INICIO) {
                                            inicio=moment(formativa.F_INICIO,"YYYY-MM-DD").format("DD/MM/YYYY");
                                        }
                                        if (formativa.F_FIN) {
                                            fin=moment(formativa.F_FIN,"YYYY-MM-DD").format("DD/MM/YYYY");
                                        }
                                        modalidad=this.state.modalidades[formativa.F_MODALIDAD];

                                        return(
                                        <View key={indexFormativa}>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{formativa.F_TITULO?formativa.F_TITULO:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{formativa.F_NUMERO_HORAS?formativa.F_NUMERO_HORAS:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{modalidad?modalidad:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{inicio?inicio:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{fin?fin:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%'}}>{formativa.F_HORARIO?formativa.F_HORARIO:''}</Text>
                                        </View>
                                        </View>
                                        )
                                    }):<></>}
                                    
                                    <View style={styles.rowWhiteSpacing}></View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informacion",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{i18next.t("identificacion.descripcion",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.canal",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.numerohoras",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'30%'}}>{i18next.t("identificacion.texto",{ns:"page"}).toUpperCase()}</Text>
                                    </View>
                                    {informativasList[index].length>0?informativasList[index].map((informativa, indexInformativa)=>{
                                        fecha='';
                                        if (informativa.I_FECHA) {
                                            fecha=moment(informativa.I_FECHA,"YYYY-MM-DD").format("DD/MM/YYYY");
                                        }
                                        canal=this.state.canales[informativa.I_CANAL];

                                        return(
                                        <View key={indexInformativa}>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{informativa.I_DESCRIPCION?informativa.I_DESCRIPCION:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{canal?canal:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{fecha?fecha:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{informativa.I_DURACION?informativa.I_DURACION:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'30%'}}>{informativa.I_TEXTO?informativa.I_TEXTO:''}</Text>
                                        </View>
                                        </View>
                                        )
                                    }):<></>}
                                    
                                </View>
                            </Page>
                        )
                    }):<></>}
                </Document>
            </PDFViewer>
        )
    }

    imprimirFicha = async () => {
        this.setState({
            cargandoImprimirGral:true,
            modalImprimirFicha:true,
            cargandoPrintFicha:true
        }, async () => {
            this.setState({cargandoImprimirGral:false}, () => {
                ReactDOM.render(this.PrintFicha(), document.getElementById('generar-pdf'))
            })
        })
    }
    

    imprimirIdentificacionesGral = async() => {
        if(!this.state.identificaciones.length===0){
            alert(i18next.t("identificacion.noexistprint",{ns:"page"}));
            return
        }

        this.setState({
            cargandoImprimirGral:true,
            modalImprimirIdentificaciones:true,
            recargando:true
        }, async () => {
            this.setState({cargandoImprimirGral:false}, () => {
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generarGral-pdf'))
            })
        })
    }

    PrintFicha=()=>{
        let categoriaRiesgo=0;
        if(this.state.nivelRiesgo < 8) {
            categoriaRiesgo=0;
        } else if(this.state.nivelRiesgo < 15) {
            categoriaRiesgo=1;
        } else if(this.state.nivelRiesgo < 22) {
            categoriaRiesgo=2;
        } else if(this.state.nivelRiesgo < 31 ){
            categoriaRiesgo=3;
        }
        let valoran='';
        if (this.state.valoranSeleccionada.length>0) {  
            this.state.valoranSeleccionada.map((valor) => {
                if (valoran==='') {
                    valoran = valor.label;
                } else {
                    valoran = valoran + ", " + valor.label;
                }
                return false;
            });
        }
        let personales='';
        if (this.state.asignadoSeleccionada.length>0) {  
            this.state.asignadoSeleccionada.map((valor) => {
                if (personales==='') {
                    personales = valor.label;
                } else {
                    personales = personales + ", " + valor.label;
                }
                return false;
            });
        }                    
        return (
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.body}>
                            <View fixed style={styles.row}>
                                <View style={styles.rowTitleNoneBorder60}>
                                    <Image
                                        style={styles.imageHead}
                                        src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                                        />
                                </View>
                                <View style={styles.rowTitleNoneBorder40}>
                                    <View fixed style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                                    </View>
                                    <View fixed style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{i18next.t("identificacion.titledoc",{ns:"page"}).toUpperCase()}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder75Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.identificacionvaloracion",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                <View style={styles.rowTitleNoneBorder25}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.fechaFicha?moment(this.state.fechaFicha,"YYYY-MM-DD").format("DD/MM/YYYY"):moment().format("DD/MM/YYYY")}</Text>
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.numero}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>{this.state.areaSeleccionada?this.state.areaSeleccionada.label:''}</Text>
                                    </View>
                                </View>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</Text>{this.state.deptoSeleccionado?this.state.deptoSeleccionado.label:''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>{this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.titulo:''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100}>
                                    <View  style={styles.rowHeadNoneBorderCallout}>
                                        <Text style={styles.textWrapCallout}>{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.label:''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.niveleintensidad",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.probabilidad",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.menor",{ns:"page"})}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.critico",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</Text>
                                </View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.escasa",{ns:"page"})}</Text>
                                    {this.state.nivelRiesgo === 1 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>}
                                    {this.state.nivelRiesgo === 6 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>6</Text>}
                                    {this.state.nivelRiesgo === 11 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>11</Text>}
                                    {this.state.nivelRiesgo === 16 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>16</Text>}
                                    {this.state.nivelRiesgo === 21 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>21</Text>}
                                    {this.state.nivelRiesgo === 26 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>26</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.baja",{ns:"page"})}</Text>
                                    {this.state.nivelRiesgo === 2 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>2</Text>}
                                    {this.state.nivelRiesgo === 7 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>7</Text>}
                                    {this.state.nivelRiesgo === 12 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>12</Text>}
                                    {this.state.nivelRiesgo === 17 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>17</Text>}
                                    {this.state.nivelRiesgo === 22 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>22</Text>}
                                    {this.state.nivelRiesgo === 27 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>27</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.posible",{ns:"page"})}</Text>
                                    {this.state.nivelRiesgo === 3 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>3</Text>}
                                    {this.state.nivelRiesgo === 8 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>8</Text>}
                                    {this.state.nivelRiesgo === 13 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>13</Text>}
                                    {this.state.nivelRiesgo === 18 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>18</Text>}
                                    {this.state.nivelRiesgo === 23 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>23</Text>}
                                    {this.state.nivelRiesgo === 28 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>28</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.alta",{ns:"page"})}</Text>
                                    {this.state.nivelRiesgo === 4 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>4</Text>}
                                    {this.state.nivelRiesgo === 9 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>9</Text>}
                                    {this.state.nivelRiesgo === 14 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>14</Text>}
                                    {this.state.nivelRiesgo === 19 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>19</Text>}
                                    {this.state.nivelRiesgo === 24 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>24</Text>}
                                    {this.state.nivelRiesgo === 29 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>29</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.muyalta",{ns:"page"})}</Text>
                                    {this.state.nivelRiesgo === 5 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>5</Text>}
                                    {this.state.nivelRiesgo === 10 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>10</Text>}
                                    {this.state.nivelRiesgo === 15 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>15</Text>}
                                    {this.state.nivelRiesgo === 20 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>20</Text>}
                                    {this.state.nivelRiesgo === 25 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>25</Text>}
                                    {this.state.nivelRiesgo === 30 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>30</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelbajo",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmedio",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelalto",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'25%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</Text>
                                </View>
                                </View>   
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorderLeft100}>
                                    {this.state.riesgoHaSucedidoAntes?
                                        <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.activarsisucedio",{ns:"page"}).toUpperCase()}:<Text style={styles.textWrap}>{i18next.t("yes",{ns:"global"})}</Text></Text>
                                    :
                                        <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.activarsisucedio",{ns:"page"}).toUpperCase()}:<Text style={styles.textWrap}>{i18next.t("no",{ns:"global"})}</Text></Text>
                                    }
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.consecuencias",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.menor",{ns:"page"})}</Text>                                 
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.critico",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</Text>
                                </View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</Text>
                                    {this.state.nivelSancion === 1 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>}
                                    {this.state.nivelSancion === 6 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>6</Text>}
                                    {this.state.nivelSancion === 11 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>11</Text>}
                                    {this.state.nivelSancion === 16 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>16</Text>}
                                    {this.state.nivelSancion === 21 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>21</Text>}
                                    {this.state.nivelSancion === 26 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>26</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</Text>
                                    {this.state.nivelPerdida === 2 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>2</Text>}
                                    {this.state.nivelPerdida === 7 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>7</Text>}
                                    {this.state.nivelPerdida === 12 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>12</Text>}
                                    {this.state.nivelPerdida === 17 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>17</Text>}
                                    {this.state.nivelPerdida === 22 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>22</Text>}
                                    {this.state.nivelPerdida === 27 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>27</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</Text>
                                    {this.state.nivelSuspension === 3 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>3</Text>}
                                    {this.state.nivelSuspension === 8 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>8</Text>}
                                    {this.state.nivelSuspension === 13 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>13</Text>}
                                    {this.state.nivelSuspension === 18 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>18</Text>}
                                    {this.state.nivelSuspension === 23 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>23</Text>}
                                    {this.state.nivelSuspension === 28 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>28</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</Text>
                                    {this.state.nivelReputacional === 4 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>4</Text>}
                                    {this.state.nivelReputacional === 9 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>9</Text>}
                                    {this.state.nivelReputacional === 14 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>14</Text>}
                                    {this.state.nivelReputacional === 19 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>19</Text>}
                                    {this.state.nivelReputacional === 24 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>24</Text>}
                                    {this.state.nivelReputacional === 29 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>29</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.otros",{ns:"page"})}</Text>
                                    {this.state.nivelOtros === 5 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>5</Text>}
                                    {this.state.nivelOtros === 10 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>10</Text>}
                                    {this.state.nivelOtros === 15 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>15</Text>}
                                    {this.state.nivelOtros === 20 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>20</Text>}
                                    {this.state.nivelOtros === 25 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>25</Text>}
                                    {this.state.nivelOtros === 30 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>30</Text>}
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelbajo",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmedio",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelalto",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'25%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</Text>
                                </View>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.resultados",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.nivelriesgo",{ns:"page"}).toUpperCase()}:</Text>
                                        {this.state.nivelRiesgo ===null?
                                            <Text style={styles.textWrapGray}>{i18next.t("identificacion.bajo",{ns:"page"})}</Text>
                                        :
                                            this.state.nivelRiesgo < 8?
                                            <Text style={styles.textWrapGreen}>{i18next.t("identificacion.bajo",{ns:"page"})}</Text>
                                            :
                                            this.state.nivelRiesgo < 15?
                                                <Text style={styles.textWrapYellow}>{i18next.t("identificacion.medio",{ns:"page"})}</Text>
                                            :
                                            this.state.nivelRiesgo < 22?
                                                <Text style={styles.textWrapOrange}>{i18next.t("identificacion.alto",{ns:"page"})}</Text>
                                            :
                                                <Text style={styles.textWrapRed}>{i18next.t("identificacion.muyalto",{ns:"page"})}</Text>
                                            
                                        }
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.riesgocierto",{ns:"page"}).toUpperCase()}:</Text>
                                    </View>
                                </View>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.intensidad",{ns:"page"}).toUpperCase()}:</Text>
                                        {this.state.nivelRiesgo ===null?
                                            <Text style={styles.textWrapGray}>{this.state.nivelRiesgo}</Text>
                                            :
                                            this.state.nivelRiesgo < 8?
                                                <Text style={styles.textWrapGreen}>{this.state.nivelRiesgo}</Text>
                                            :
                                            this.state.nivelRiesgo < 15?
                                                <Text style={styles.textWrapYellow}>{this.state.nivelRiesgo}</Text>
                                            :
                                            this.state.nivelRiesgo < 22?
                                                <Text style={styles.textWrapOrange}>{this.state.nivelRiesgo}</Text>
                                            : 
                                                <Text style={styles.textWrapRed}>{this.state.nivelRiesgo}</Text>
                                        }
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                        {this.state.nivelRiesgo ===null && 
                                            this.state.riesgoHaSucedidoAntes &&
                                            categoriaRiesgo<3?
                                                <Text style={styles.textWrapGreen}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo ===null && 
                                            (!this.state.riesgoHaSucedidoAntes ||
                                            categoriaRiesgo!==3)?
                                                <Text style={styles.textWrapGray}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo < 8 && 
                                            this.state.riesgoHaSucedidoAntes && 
                                            categoriaRiesgo<3?
                                                <Text style={styles.textWrapYellow}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo < 8 && 
                                            (!this.state.riesgoHaSucedidoAntes ||
                                            categoriaRiesgo!==3)?
                                                <Text style={styles.textWrapGreen}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo < 15 && 
                                            this.state.riesgoHaSucedidoAntes && 
                                            categoriaRiesgo<3?
                                                <Text style={styles.textWrapOrange}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo < 15 && 
                                            (!this.state.riesgoHaSucedidoAntes ||
                                            categoriaRiesgo!==3)?
                                                <Text style={styles.textWrapYellow}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo < 22 && 
                                            this.state.riesgoHaSucedidoAntes && 
                                            categoriaRiesgo<3?
                                                <Text style={styles.textWrapRed}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                        this.state.nivelRiesgo < 22 && 
                                            (!this.state.riesgoHaSucedidoAntes ||
                                            categoriaRiesgo!==3)?
                                                <Text style={styles.textWrapOrange}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        :
                                            <Text style={styles.textWrapRed}>{ this.state.riesgoHaSucedidoAntes?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                        }
                                    </View>
                                </View>
                                
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorderLeft100}>
                                    <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"}).toUpperCase()}</Text>                                 
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>{i18next.t("identificacion.otros",{ns:"page"}).toUpperCase()}</Text>
                                </View> 
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%',backgroundColor:(Math.ceil(this.state.nivelSancion/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(this.state.nivelSancion/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(this.state.nivelSancion/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(this.state.nivelSancion/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{this.state.nivelSancion}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%',backgroundColor:(Math.ceil(this.state.nivelPerdida/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(this.state.nivelPerdida/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(this.state.nivelPerdida/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(this.state.nivelPerdida/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{this.state.nivelPerdida}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%',backgroundColor:(Math.ceil(this.state.nivelSuspension/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(this.state.nivelSuspension/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(this.state.nivelSuspension/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(this.state.nivelSuspension/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{this.state.nivelSuspension}</Text>                                 
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%',backgroundColor:(Math.ceil(this.state.nivelReputacional/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(this.state.nivelReputacional/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(this.state.nivelReputacional/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(this.state.nivelReputacional/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{this.state.nivelReputacional}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'20%',backgroundColor:(Math.ceil(this.state.nivelOtros/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(this.state.nivelOtros/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(this.state.nivelOtros/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(this.state.nivelOtros/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{this.state.nivelOtros}</Text>
                                </View>
                                </View>   
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorderLeft100}>
                                    <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.organo",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                
                            </View>
                            <View  style={styles.row}>
                                <View  style={{flexDirection: 'row', width: '100%'}}>
                                    <Text style={styles.textWrap}>{valoran}</Text>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.recursosasignados",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.personales",{ns:"page"}).toUpperCase()}:</Text>{personales}</Text>
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.delaorganizacion",{ns:"page"}).toUpperCase()}:</Text>{this.state.recursosOrganizacion?this.state.recursosOrganizacion:''}</Text>
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.observaciones",{ns:"page"}).toUpperCase()}:</Text>{this.state.observaciones}</Text>
                                    </View>
                                </View>
                                <View style={styles.rowTitleNoneBorder50}>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.financieros",{ns:"page"}).toUpperCase()}:</Text>{this.state.recursosFinancieros?this.state.recursosFinancieros:''}</Text>
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.externos",{ns:"page"}).toUpperCase()}:</Text>{this.state.recursosExternos?this.state.recursosExternos:''}</Text>
                                    </View>
                                    <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.periodicidad",{ns:"page"}).toUpperCase()}:</Text>{this.state.periodicidad?this.formatearPeriodicidad(this.state.periodicidad):null}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.rowLineSpacing}></View>
                            <View  style={styles.row}>
                                <View style={styles.rowTitleNoneBorder100Callout}>
                                    <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.descripcionriesgo",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                            </View>
                            <View  style={styles.row}>
                                <View  style={styles.rowHeadNoneBorder}>
                                    <Text style={styles.textWrap}>{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.descripcion:""}</Text>
                                </View>
                            </View>
                        </View>
                        <Text
                            style={styles.footerPage}
                            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                            fixed
                        />
                        <Image
                            style={styles.icono}
                            src={iconoFooter}
                            fixed
                        />
                        <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                    </Page>
                </Document>
            </PDFViewer>
        )
    }

    ImprimirGral=()=>{
        
        return (
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                    {this.state.identificaciones?this.state.identificaciones.map((identificador, index) =>{
                        let categoriaRiesgo=0;
                        let valoran='';
                        let personales='';
                        valoranList[index].map((valor) => {
                            if (valoran==='') {
                                valoran = valor.label;
                            } else {
                                valoran = valoran + ", " + valor.label;
                            }
                            return false;
                        });
                        asignadoList[index].map((valor) => {
                            if (personales==='') {
                                personales = valor.label;
                            } else {
                                personales = personales + ", " + valor.label;
                            }
                            return false;
                        });
                        if(identificador.NIVEL_INTENSIDAD < 8) {
                            categoriaRiesgo=0;
                        } else if(identificador.NIVEL_INTENSIDAD < 15) {
                            categoriaRiesgo=1;
                        } else if(identificador.NIVEL_INTENSIDAD < 22) {
                            categoriaRiesgo=2;
                        } else if(identificador.NIVEL_INTENSIDAD < 31 ){
                            categoriaRiesgo=3;
                        }
                        return(
                            <Page size="A4" style={styles.page} key={index}>
                                <View style={styles.body}>
                                    <View fixed style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder60}>
                                            <Image
                                                style={styles.imageHead}
                                                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                                                />
                                        </View>
                                        <View style={styles.rowTitleNoneBorder40}>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                                            </View>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{i18next.t("identificacion.titledoc",{ns:"page"}).toUpperCase()}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder75Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.identificacionvaloracion",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder25}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{identificador.FECHA?moment(identificador.FECHA,"YYYY-MM-DD").format("DD/MM/YYYY"):moment().format("DD/MM/YYYY")}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{identificador.NUMERO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>{identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</Text>{identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>{identificador.RIESGO.BLOQUE.TITULO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorderCallout}>
                                                <Text style={styles.textWrapCallout}>{identificador.RIESGO.TITULO}</Text>
                                            </View>
                                        </View> 
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.niveleintensidad",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.probabilidad",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.menor",{ns:"page"})}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.critico",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</Text>
                                        </View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.escasa",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 1 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 6 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>6</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 11 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>11</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 16 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>16</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 21 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>21</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 26 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>26</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.baja",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 2 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>2</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 7 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>7</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 12 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>12</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 17 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>17</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 22 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>22</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 27 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>27</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.posible",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 3 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>3</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 8 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>8</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 13 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>13</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 18 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>18</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 23 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>23</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 28 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>28</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.alta",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 4 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>4</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 9 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>9</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 14 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>14</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 19 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>19</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 24 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>24</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 29 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>29</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.muyalta",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 5 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>5</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 10 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>10</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 15 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>15</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 20 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>20</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 25 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>25</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 30 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>30</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelbajo",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmedio",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelalto",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'25%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</Text>
                                        </View>
                                        </View>   
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorderLeft100}>
                                            {identificador.OCURRIDO_ANTES?
                                                <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.activarsisucedio",{ns:"page"}).toUpperCase()}:<Text style={styles.textWrap}>{i18next.t("yes",{ns:"global"})}</Text></Text>
                                            :
                                                <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.activarsisucedio",{ns:"page"}).toUpperCase()}:<Text style={styles.textWrap}>{i18next.t("no",{ns:"global"})}</Text></Text>
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.consecuencias",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.menor",{ns:"page"})}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.critico",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</Text>
                                        </View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_SANCION === 1 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 6 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>6</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 11 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>11</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 16 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>16</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 21 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>21</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 26 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>26</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_PERDIDA === 2 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>2</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 7 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>7</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 12 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>12</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 17 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>17</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 22 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>22</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 27 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>27</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_SUSPENSION === 3 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>3</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 8 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>8</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 13 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>13</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 18 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>18</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 23 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>23</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 28 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>28</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_DANO === 4 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>4</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 9 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>9</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 14 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>14</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 19 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>19</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 24 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>24</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 29 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>29</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.otros",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_OTROS === 5 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>5</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 10 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>10</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 15 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>15</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 20 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>20</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 25 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>25</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 30 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>30</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelbajo",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmedio",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelalto",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'25%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</Text>
                                        </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.resultados",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.nivelriesgo",{ns:"page"}).toUpperCase()}:</Text>
                                                {identificador.NIVEL_INTENSIDAD ===null?
                                                    <Text style={styles.textWrapGray}>{i18next.t("identificacion.bajo",{ns:"page"})}</Text>
                                                :
                                                    identificador.NIVEL_INTENSIDAD < 8?
                                                    <Text style={styles.textWrapGreen}>{i18next.t("identificacion.bajo",{ns:"page"})}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 15?
                                                        <Text style={styles.textWrapYellow}>{i18next.t("identificacion.medio",{ns:"page"})}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 22?
                                                        <Text style={styles.textWrapOrange}>{i18next.t("identificacion.alto",{ns:"page"})}</Text>
                                                    :
                                                        <Text style={styles.textWrapRed}>{i18next.t("identificacion.muyalto",{ns:"page"})}</Text>
                                                    
                                                }
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.riesgocierto",{ns:"page"}).toUpperCase()}:</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.intensidad",{ns:"page"}).toUpperCase()}:</Text>
                                                {identificador.NIVEL_INTENSIDAD ===null?
                                                <Text style={styles.textWrapGray}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                :
                                                    identificador.NIVEL_INTENSIDAD < 8?
                                                    <Text style={styles.textWrapGreen}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 15?
                                                    <Text style={styles.textWrapYellow}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 22?
                                                    <Text style={styles.textWrapOrange}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    :
                                                    <Text style={styles.textWrapRed}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    
                                                }
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                {identificador.NIVEL_INTENSIDAD ===null && 
                                                    identificador.OCURRIDO_ANTES &&
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapGreen}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD ===null && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                    <Text style={styles.textWrapGray}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 8 && 
                                                    identificador.OCURRIDO_ANTES && 
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapYellow}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 8 && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                        <Text style={styles.textWrapGreen}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 15 && 
                                                    identificador.OCURRIDO_ANTES && 
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapOrange}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 15 && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                        <Text style={styles.textWrapYellow}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 22 && 
                                                    identificador.OCURRIDO_ANTES && 
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapRed}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 22 && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                        <Text style={styles.textWrapOrange}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                    <Text style={styles.textWrapRed}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                }
                                            
                                            
                                            </View>
                                        </View>
                                        
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorderLeft100}>
                                            <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"}).toUpperCase()}</Text>                                 
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>{i18next.t("identificacion.otros",{ns:"page"}).toUpperCase()}</Text>
                                        </View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%', backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_SANCION}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%', backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_PERDIDA}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%', backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_SUSPENSION}</Text>                                 
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%',backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_DANO}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'20%',backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_OTROS}</Text>
                                        </View>
                                        </View>   
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorderLeft100}>
                                            <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.organo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        
                                    </View>
                                    <View  style={styles.row}>
                                        <View  style={{flexDirection: 'row', width: '100%'}}>
                                            <Text style={styles.textWrap}>{valoran}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.recursosasignados",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.personales",{ns:"page"}).toUpperCase()}:</Text>{personales}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.delaorganizacion",{ns:"page"}).toUpperCase()}:</Text>{identificador.RECURSOS_ORGANIZACION?identificador.RECURSOS_ORGANIZACION:''}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.observaciones",{ns:"page"}).toUpperCase()}:</Text>{identificador.OBSERVACIONES}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.financieros",{ns:"page"}).toUpperCase()}:</Text>{identificador.RECURSOS_FINANCIEROS?identificador.RECURSOS_FINANCIEROS:''}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.externos",{ns:"page"}).toUpperCase()}:</Text>{identificador.RECURSOS_EXTERNOS?identificador.RECURSOS_EXTERNOS:''}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.periodicidad",{ns:"page"}).toUpperCase()}:</Text>{identificador.PERIODICIDAD_REVISION?this.formatearPeriodicidad(identificador.PERIODICIDAD_REVISION):null}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.descripcionriesgo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}>{identificador.RIESGO.DESCRIPCION}</Text>
                                        </View>
                                    </View>
                                </View> 
                                <Text
                                    style={styles.footerPage}
                                    render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                                    fixed
                                />
                                <Image
                                    style={styles.icono}
                                    src={iconoFooter}
                                    fixed
                                />
                                <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                            </Page>
                            )
                        }):<></>}
                </Document>
            </PDFViewer>
        )
    }

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("identificacion.title",{ns:"page"});
        

        const añadirNuevaFormativa = async() => {

            if(!this.accionesSeguimiento.state.lugarAccion){
              await this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
              await ReactDOM.findDOMNode(this.accionesSeguimiento.inputLugarAccion).children[0].focus()
              return
            }
    
            if(this.accionesSeguimiento.state.tipoAccionAAplicar===1 && !this.accionesSeguimiento.state.areaSeleccionada){
              alert(i18next.t("identificacion.noselectarea",{ns:"page"}))
              this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
              return
            }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===2 && !this.accionesSeguimiento.state.departamentoSeleccionado){
              alert(i18next.t("identificacion.noselectdpto",{ns:"page"}))
              this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
              return
            }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===3 && !this.accionesSeguimiento.state.puestoSeleccionado){
              alert(i18next.t("identificacion.noselectpuesto",{ns:"page"}))
              this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
              return
            }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===4 && !this.accionesSeguimiento.state.empleadoSeleccionado){
              alert(i18next.t("identificacion.noselectempleado",{ns:"page"}))
              this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
              return
            }
    
            if(!this.accionesSeguimiento.state.tituloFormacion){
              await this.accionesSeguimiento.setState({pestaña:"formacion"})
              await ReactDOM.findDOMNode(this.accionesSeguimiento.inputTiutloFormacion).children[0].focus()
              return
            }
    
            if(this.state.formacionSeleccionada){
    
              await this.props.client
                .mutate({
                mutation: gql`
                  mutation{actualizarFormacionInformacion(
                    ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION},
                    VALORES:{
                    F_TITULO:"${this.accionesSeguimiento.state.tituloFormacion}"
                    F_NUMERO_HORAS:${this.accionesSeguimiento.state.numeroHoras}
                    F_MODALIDAD:${this.accionesSeguimiento.state.modalidadSeleccionada?this.accionesSeguimiento.state.modalidadSeleccionada.value:null}
                    F_INICIO:${this.accionesSeguimiento.state.bolFechaInicio?'"'+moment(this.accionesSeguimiento.state.fechaInicio).format("YYYY-MM-DD")+'"':null}
                    F_FIN:${this.accionesSeguimiento.state.bolFechaFin?'"'+moment(this.accionesSeguimiento.state.fechaFin).format("YYYY-MM-DD")+'"':null}
                    F_HORARIO:${this.accionesSeguimiento.state.horario?'"'+this.accionesSeguimiento.state.horario+'"':null}
                    F_PROCEDIMIENTO_EVALUACION:${this.accionesSeguimiento.state.procedimiento?'"'+this.accionesSeguimiento.state.procedimiento+'"':null}
                    F_REQUISITOS_ACCESO:${this.accionesSeguimiento.state.requisitos?'"'+this.accionesSeguimiento.state.requisitos+'"':null}
                    F_COMISION_ACADEMICA:${this.accionesSeguimiento.state.comision?'"'+this.accionesSeguimiento.state.comision.map(com=>{return com.name}).join("|")+'"':null}
                    F_PROFESORADO:${this.accionesSeguimiento.state.profesorado?'"'+this.accionesSeguimiento.state.profesorado.map(prof=>{return prof.name}).join("|")+'"':null}
                    F_OBJETIVOS:${this.accionesSeguimiento.state.objetivos?'"'+this.accionesSeguimiento.state.objetivos+'"':null}
                    F_CONTENIDOS:${this.accionesSeguimiento.state.contenidos?'"'+this.accionesSeguimiento.state.contenidos+'"':null}
                    LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                    OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
                  }){ID_ACCION}}`
                })
                .then(async result => {
    
    
                  //creamos los riesgos afectados.
                  
    
                  //eliminamos los viejos
                  await this.props.client.mutate({
                    mutation:gql`mutation{eliminarFormacionInformacionRiesgo(ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION}){ID_ACCION}}`
                  }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                    return
                  })
    
                  for(let riesgo of this.accionesSeguimiento.state.riesgosSeleccionados){
    
                    await this.props.client.mutate({
                      mutation:gql`mutation{crearFormacionInformacionRiesgo(VALORES:{ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION},ID_RIESGO:${riesgo.id.split("_").pop()}}){ID_ACCION}}`
                    }).catch(err=>{
                      alert(i18next.t("error",{ns:"global"}))
                      return
                    })
    
                  }
    
                  //comprobantes
                  if(this.state.formacionSeleccionada.ID_EMPLEADO){
    
                      //comprobamos si existe el comprobante.
                      
                      let accion=await this.props.client
                      .query({
                          query: gql`
                          {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${this.state.formacionSeleccionada.ID_EMPLEADO},ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION}}){ID_ACCION_PERSONA}}
                          `
                          ,fetchPolicy:'network-only'
                      }).then(result=>{ 
                          if(result.data.formacionInformacionPersonas.length>0)return result.data.formacionInformacionPersonas[0].ID_ACCION_PERSONA
                          return 0
                      })
    
                      if(accion===0){
    
                        await this.props.client.mutate({
                          mutation:gql`mutation{crearFormacionInformacionPersona(VALORES:{ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION},ID_PERSONA:${this.state.formacionSeleccionada.ID_EMPLEADO}}){ID_ACCION}}`
                        }).catch(err=>{
                          alert(i18next.t("error",{ns:"global"}))
                          return
                        })
        
                      }
    
                  }
    
    
        
                  alert(i18next.t("updatecorrecto",{ns:"global"}))
                  this.setState({crearAccionFormativa:false,elegirAccionFormativa:false})
                  await this.cargarFormaciones(this.state.seguimientoSeleccionada.ID_DOCUMENTO)
             
                  
                }).catch(err=>{
                  alert(i18next.t("error",{ns:"global"}))
                  return
                })
    
            }else{
    
                await this.props.client
                .mutate({
                mutation: gql`
                  mutation{crearFormacionInformacion(VALORES:{
                    ID_AREA:${this.accionesSeguimiento.state.areaSeleccionada?this.accionesSeguimiento.state.areaSeleccionada.id:null}
                    ID_DEPARTAMENTO:${this.accionesSeguimiento.state.departamentoSeleccionado?this.accionesSeguimiento.state.departamentoSeleccionado.id:null}
                    ID_PUESTO_TRABAJO:${this.accionesSeguimiento.state.puestoSeleccionado?this.accionesSeguimiento.state.puestoSeleccionado.id:null}
                    ID_EMPLEADO:${this.accionesSeguimiento.state.empleadoSeleccionado?this.accionesSeguimiento.state.empleadoSeleccionado.id:null}
                    TIPO_ACCION:"F"
                    F_TITULO:"${this.accionesSeguimiento.state.tituloFormacion}"
                    F_NUMERO_HORAS:${this.accionesSeguimiento.state.numeroHoras}
                    F_MODALIDAD:${this.accionesSeguimiento.state.modalidadSeleccionada?this.accionesSeguimiento.state.modalidadSeleccionada.value:null}
                    F_INICIO:${this.accionesSeguimiento.state.bolFechaInicio?'"'+moment(this.accionesSeguimiento.state.fechaInicio).format("YYYY-MM-DD")+'"':null}
                    F_FIN:${this.accionesSeguimiento.state.bolFechaFin?'"'+moment(this.accionesSeguimiento.state.fechaFin).format("YYYY-MM-DD")+'"':null}
                    F_HORARIO:${this.accionesSeguimiento.state.horario?'"'+this.accionesSeguimiento.state.horario+'"':null}
                    F_PROCEDIMIENTO_EVALUACION:${this.accionesSeguimiento.state.procedimiento?'"'+this.accionesSeguimiento.state.procedimiento+'"':null}
                    F_REQUISITOS_ACCESO:${this.accionesSeguimiento.state.requisitos?'"'+this.accionesSeguimiento.state.requisitos+'"':null}
                    F_COMISION_ACADEMICA:${this.accionesSeguimiento.state.comision?'"'+this.accionesSeguimiento.state.comision.map(com=>{return com.name}).join("|")+'"':null}
                    F_PROFESORADO:${this.accionesSeguimiento.state.profesorado?'"'+this.accionesSeguimiento.state.profesorado.map(prof=>{return prof.name}).join("|")+'"':null}
                    F_OBJETIVOS:${this.accionesSeguimiento.state.objetivos?'"'+this.accionesSeguimiento.state.objetivos+'"':null}
                    F_CONTENIDOS:${this.accionesSeguimiento.state.contenidos?'"'+this.accionesSeguimiento.state.contenidos+'"':null}
                    LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                    OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
                    ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO}
                  }){ID_ACCION}}`
                })
                .then(async result => {
        
                  alert(i18next.t("insertcorrecto",{ns:"global"}))
                  this.setState({crearAccionFormativa:false,elegirAccionFormativa:false})
                  await this.cargarFormaciones(this.state.seguimientoSeleccionada.ID_DOCUMENTO)
    
                }).catch(err=>{
                  alert(i18next.t("error",{ns:"global"}))
                  return
                })
    
            }
    
            
        }
    
        const añadirNuevaInformativa=async()=>{
    
          if(!this.accionesSeguimiento.state.lugarAccion){
            await this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
            await ReactDOM.findDOMNode(this.accionesSeguimiento.inputLugarAccion).children[0].focus()
            return
          }
    
          if(this.accionesSeguimiento.state.tipoAccionAAplicar===1 && !this.accionesSeguimiento.state.areaSeleccionada){
            alert(i18next.t("identificacion.noselectarea",{ns:"page"}))
            this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
            return
          }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===2 && !this.accionesSeguimiento.state.departamentoSeleccionado){
            alert(i18next.t("identificacion.noselectdpto",{ns:"page"}))
            this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
            return
          }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===3 && !this.accionesSeguimiento.state.puestoSeleccionado){
            alert(i18next.t("identificacion.noselectpuesto",{ns:"page"}))
            this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
            return
          }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===4 && !this.accionesSeguimiento.state.empleadoSeleccionado){
            alert(i18next.t("identificacion.noselectempleado",{ns:"page"}))
            this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
            return
          }
    
          if(!this.accionesSeguimiento.state.descripcionInfo){
            await this.accionesSeguimiento.setState({pestaña:"informacion"})
            await ReactDOM.findDOMNode(this.accionesSeguimiento.inputDescInfo).children[0].focus()
            return
          }
    
          if(this.state.informacionSeleccionada){
    
            await this.props.client
              .mutate({
              mutation: gql`
                mutation{actualizarFormacionInformacion(
                  ID_ACCION:${this.state.informacionSeleccionada.ID_ACCION},
                  VALORES:{
                    I_DESCRIPCION:"${this.accionesSeguimiento.state.descripcionInfo}",
                    I_TEXTO:${this.accionesSeguimiento.state.textoInfo?'"""'+this.accionesSeguimiento.state.textoInfo+'"""':null}
                    I_CANAL:${this.accionesSeguimiento.state.canalSeleccionada?this.accionesSeguimiento.state.canalSeleccionada.value:null}
                    I_FECHA:${this.accionesSeguimiento.state.bolFechaInformacion?'"'+moment(this.accionesSeguimiento.state.fechaInformacion).format("YYYY-MM-DD")+'"':null}
                    I_DURACION:${this.accionesSeguimiento.state.cambiarNumeroHorasInformacion?'"'+this.accionesSeguimiento.state.cambiarNumeroHorasInformacion+'"':null}
                    LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                    OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
                }){ID_ACCION}}`
              })
              .then(async result => {
    
                //creamos los riesgos afectados.
                  
    
                  //eliminamos los viejos
                  await this.props.client.mutate({
                    mutation:gql`mutation{eliminarFormacionInformacionRiesgo(ID_ACCION:${this.state.informacionSeleccionada.ID_ACCION}){ID_ACCION}}`
                  }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                    return
                  })
    
                  for(let riesgo of this.accionesSeguimiento.state.riesgosSeleccionados){
    
                    await this.props.client.mutate({
                      mutation:gql`mutation{crearFormacionInformacionRiesgo(VALORES:{ID_ACCION:${this.state.informacionSeleccionada.ID_ACCION},ID_RIESGO:${riesgo.id.split("_").pop()}}){ID_ACCION}}`
                    }).catch(err=>{
                      alert(i18next.t("error",{ns:"global"}))
                      return
                    })
    
                  }
      
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                this.setState({crearAccionInformativa:false,elegirAccionInformativa:false})
                await this.cargarInformacion(this.state.seguimientoSeleccionada.ID_DOCUMENTO)
    
      
              }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
              })
    
          }else{
    
            await this.props.client
                .mutate({
                mutation: gql`
                  mutation{crearFormacionInformacion(VALORES:{
                    ID_AREA:${this.accionesSeguimiento.state.areaSeleccionada?this.accionesSeguimiento.state.areaSeleccionada.id:null}
                    ID_DEPARTAMENTO:${this.accionesSeguimiento.state.departamentoSeleccionado?this.accionesSeguimiento.state.departamentoSeleccionado.id:null}
                    ID_PUESTO_TRABAJO:${this.accionesSeguimiento.state.puestoSeleccionado?this.accionesSeguimiento.state.puestoSeleccionado.id:null}
                    ID_EMPLEADO:${this.accionesSeguimiento.state.empleadoSeleccionado?this.accionesSeguimiento.state.empleadoSeleccionado.id:null}
                    TIPO_ACCION:"I"
                    I_DESCRIPCION:"${this.accionesSeguimiento.state.descripcionInfo}",
                    I_TEXTO:${this.accionesSeguimiento.state.textoInfo?'"""'+this.accionesSeguimiento.state.textoInfo+'"""':null}
                    I_CANAL:${this.accionesSeguimiento.state.canalSeleccionada?this.accionesSeguimiento.state.canalSeleccionada.value:null}
                    I_FECHA:${this.accionesSeguimiento.state.bolFechaInformacion?'"'+moment(this.accionesSeguimiento.state.fechaInformacion).format("YYYY-MM-DD")+'"':null}
                    I_DURACION:${this.accionesSeguimiento.state.cambiarNumeroHorasInformacion?'"'+this.accionesSeguimiento.state.cambiarNumeroHorasInformacion+'"':null}
                    LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                    OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
                    ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO}
                  }){ID_ACCION}}`
                })
                .then(async result => {
        
                  alert(i18next.t("insertcorrecto",{ns:"global"}))
                  this.setState({crearAccionInformativa:false,elegirAccionInformativa:false})
                  await this.cargarInformacion(this.state.seguimientoSeleccionada.ID_DOCUMENTO)
    
                }).catch(err=>{
                  alert(i18next.t("error",{ns:"global"}))
                  return
                })
    
          }
    
    
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const handleCertificar=()=>{
            this.setState({certificado:!this.state.certificado})
        }

        const columnsFormativa = [{
        dataField: 'F_TITULO',
        text: i18next.t("identificacion.name",{ns:"page"})
        }];

        const columnsInformativa = [{
            dataField: 'I_DESCRIPCION',
            text: i18next.t("identificacion.name",{ns:"page"})
        }];

        const escogeFechaValoracionControles = nuevaFecha => {
            this.setState({ fechaProximaValoracionControles: nuevaFecha });
        };
    
        const escogeFechaValoracion = nuevaFecha => {
            this.setState({ fechaProximaValoracion: nuevaFecha });
        };

        const cogerDNIInformante = ev => {
            this.setState({ DNIinformante: ev.currentTarget.value });
        };

        const cogerNombreInformante = ev => {
            this.setState({ nombreInformante: ev.currentTarget.value });
        };

        const cogerExplicacionImperativos = ev => {
            this.setState({ explicacionImperativos: ev.currentTarget.value });
        };
    
        const cogerExplicacionRecomendados = ev => {
            this.setState({ explicacionRecomendados: ev.currentTarget.value });
        };
    
        const cogerExplicacionSalvedades = ev => {
            this.setState({ explicacionSalvedades: ev.currentTarget.value });
        };
    
        const cogerObservaciones = ev => {
            this.setState({ observaciones: ev.currentTarget.value });
        };

        const anadirProcedimientoRecomendado=()=>{

            if(!this.state.nuevaRecomendacion){
                return
            }
        
            //comprobar que no existe
            let existe=this.state.recomendaciones.find(recomendacion=>{return recomendacion.label===this.state.nuevaRecomendacion})
            if(existe){
              alert(i18next.t("yaexiste",{ns:"global"}))
              return
            }
        
            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            //mutacion para crear el procedimiento imperativo
            this.props.client.mutate({
              mutation:gql`mutation{crearRecomendacion(VALORES:{DESCRIPCION:"${this.state.nuevaRecomendacion}",PREDEFINIDA:false,ID_RIESGO:${riesgo}}){ID_RECOMENDACION}}`
            }).then(async result=>{
         
              alert(i18next.t("insertcorrecto",{ns:"global"}))
              let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
              await this.cargarRecomendaciones(riesgo);
        
              let filaCreada=this.state.recomendaciones.find((recomendacion,index)=>{
                  return recomendacion.value===result.data.crearRecomendacion.ID_RECOMENDACION
        
              })
        
              this.setState({
                  recomendadosSeleccionadoTabla:filaCreada
              })
                
            }).catch(err=>{
              alert(i18next.t("error",{ns:"global"}))
            })
        
            cerrarModalNuevaRecomendacion();
        }

        const anadirProcedimientoImperativo=()=>{

            if(!this.state.nuevoImperativo){
                return
            }
      
            //comprobar que no existe
            let existe=this.state.imperativos.find(imperativo=>{return imperativo.label===this.state.nuevoImperativo})
            if(existe){
              alert(i18next.t("yaexiste",{ns:"global"}))
              return
            }
      
            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            //mutacion para crear el procedimiento imperativo
            this.props.client.mutate({
              mutation:gql`mutation{crearComplianceImperativa(VALORES:{DESCRIPCION:"${this.state.nuevoImperativo}",PREDEFINIDA:false,ID_RIESGO:${riesgo}}){ID_IMPERATIVA}}`
            }).then(async result=>{
          
              alert(i18next.t("insertcorrecto",{ns:"global"}))
              let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
              await this.cargarImperativos(riesgo)
      
              let filaCreada=this.state.imperativos.find((imperativa,index)=>{
                return imperativa.value===result.data.crearComplianceImperativa.ID_IMPERATIVA
      
              })
      
              this.setState({imperativoSeleccionadoTabla:filaCreada})
                
            }).catch(err=>{
              alert(i18next.t("error",{ns:"global"}))
            })
      
            
            cerrarModalNuevoImperativo();
        }

        const handleNuevaRecomendacion=(ev)=>{
            this.setState({
              nuevaRecomendacion:ev.currentTarget.value
            });
        }

        const handleNuevoImperativo=(ev)=>{
            this.setState({
              nuevoImperativo:ev.currentTarget.value
            });
        }

        const cerrarModalRecomendaciones = () => {
            this.setState({
              modalRecomendaciones:false
            });
        };

        const cerrarModalImperativos = () => {
            this.setState({
                modalImperativos:false
            });
        };

        const cerrarModalNuevaRecomendacion = () => {

            this.setState({
                modalNuevaRecomendacion: false,
                recargandoRecomendaciones:false
            });
      
        };

        const cerrarModalNuevoImperativo = () => {

            this.setState({
                modalNuevoImperativo: false,
                recargandoImperativos: false
            });
      
        };

        const handleOnSelectRecomendaciones=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    recomendadosSeleccionadoTabla: row
                })
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectImperativos=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    imperativoSeleccionadoTabla: row
                })
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const selectRowRecomendaciones = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectRecomendaciones,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowImperativos = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectImperativos,
            style: { backgroundColor: '#c8e6c9' }
        };

        const columnsRecomendaciones = [{
            dataField: 'label',
            text: i18next.t("edicionriesgo.procedimiento",{ns:"page"})
        }];

        const columnsImperativos = [{
            dataField: 'label',
            text: i18next.t("edicionriesgo.procedimiento",{ns:"page"})
        }];

        const abrirModalNuevaRecomendacion = () => {
            this.setState({
              modalNuevaRecomendacion:true,
              nuevaRecomendacion:null,
              recomendadosSeleccionadoTabla:null,
              recargandoRecomendaciones:true
            });
        };

        const abrirModalNuevoImpertativo = () => {
            this.setState({
              modalNuevoImperativo: true,
              nuevoImperativo:null,
              imperativoSeleccionadoTabla:null,
              recargandoImperativos:true
            });
        };

        const anadirRecomendado=()=>{
       
            if(!this.state.recomendadosSeleccionadoTabla){
              alert(i18next.t("noseleccionado",{ns:"global"}))
              return
            }
            let existe=this.state.recomendacionesSeleccionados.find(recomendacion=>{return recomendacion.value===this.state.recomendadosSeleccionadoTabla.value})
            if(existe){
              alert(i18next.t("identificacion.alreadyselect",{ns:"page"}))
              return
            }
            
            //agregamos la fila en la tabla
            let recomendaciones=this.state.recomendacionesSeleccionados
            let recoSel=this.state.recomendadosSeleccionadoTabla
            recoSel.nivel=2
            recomendaciones.push(recoSel)
      
            this.setState({
                recomendacionesSeleccionados:recomendaciones,
                recomendadosSeleccionadoTabla:null
            });
            
            cerrarModalRecomendaciones();
        }

        const anadirImperativo=()=>{
       
            if(!this.state.imperativoSeleccionadoTabla){
              alert(i18next.t("noseleccionado",{ns:"global"}))
              return
            }
            let existe=this.state.impertivosSeleccionados.find(imperativo=>{return imperativo.value===this.state.imperativoSeleccionadoTabla.value})
            if(existe){
              alert(i18next.t("identificacion.alreadyselect",{ns:"page"}))
              return
            }
            //agregamos la fila en la tabla
            let imperativos=this.state.impertivosSeleccionados
            let impSeleccionado=this.state.imperativoSeleccionadoTabla
            impSeleccionado.nivel=2// marcamos el nivel de riesgo a 2 por defecto
            imperativos.push(impSeleccionado)
            
            this.setState({impertivosSeleccionados:imperativos})
            this.setState({imperativoSeleccionadoTabla:null})
            cerrarModalImperativos()
        }

        const abrirModalImpertativos = async () => {
            await this.setState({
                modalImperativos: true,
                imperativoSeleccionadoTabla:null
            });
    
        };

        const borrarImperativo = index => {
            let imperativos=this.state.impertivosSeleccionados
            imperativos.splice(index,1)
            let niveles=this.state.nivelImperativas
            niveles.splice(index,1)
            this.setState({
                nivelImperativas:niveles,
                impertivosSeleccionados:imperativos
            });
        };

        const abrirModalRecomendacion = async() => {
            await this.setState({
              modalRecomendaciones:true,
              recomendadosSeleccionadoTabla:null,
            });
    
        };

        const borrarRecomendacion = index => {
            let recomendaciones=this.state.recomendacionesSeleccionados
            recomendaciones.splice(index,1)
            let niveles=this.state.nivelRecomendaciones
            niveles.splice(index,1)
            this.setState({
                nivelRecomendaciones:niveles,
                recomendacionesSeleccionados:recomendaciones
            });
        };

        const handleClickRecomendaciones = (fila,columna)  => {
            let valores=this.state.recomendacionesSeleccionados
            valores[fila].nivel=columna
            this.setState({ recomendacionesSeleccionados: valores });
        };

        const handleClickImperativas = (fila,columna) => {

            let valores=this.state.impertivosSeleccionados
            valores[fila].nivel=columna
            this.setState({ impertivosSeleccionados: valores });
        };

        const handleRecursosFinancieros = (ev) => {

            
            this.setState({recursosFinancieros:ev.currentTarget.value})
        }

        const handleCambiarObservaciones = (ev) => {
            this.setState({observaciones: ev.currentTarget.value})
        }

        const handleRecursosOrganizacion = (ev) => {
            this.setState({recursosOrganizacion: ev.target.value})
        }

        const handleRecursosExternos = (ev) => {
            this.setState({recursosExternos: ev.target.value})
        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }

        const numberFollowUp=(cell, row) => {
            return (
                cell +'/'+ cell
            );
        }

        const columns = [{
        dataField: 'FECHA',
        text: i18next.t("identificacion.fecha",{ns:"page"}),
        formatter: dateFormatter
        }, {
        dataField: 'NUMERO',
        text: i18next.t("identificacion.numero",{ns:"page"}),
        }, {
        dataField: 'COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION',
        text: i18next.t("identificacion.area",{ns:"page"}),
        filter: textFilter({
            placeholder: 'Filtrar por area...'
        })
        }, {
        dataField: 'COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION',
        text: i18next.t("identificacion.departamento",{ns:"page"}),
        filter: textFilter({
            placeholder: 'Filtrar por dpto...'
        })
        }, {
        dataField: 'RIESGO.BLOQUE.TITULO',
        text: i18next.t("identificacion.bloque",{ns:"page"}),
        filter: textFilter({
            placeholder: 'Filtrar por bloque...'
        })
        }, {
        dataField: 'RIESGO.TITULO',
        text: i18next.t("identificacion.riesgo",{ns:"page"}),
        filter: textFilter({
            placeholder: 'Filtrar por riesgo...'
        })
        }];

        /*const defaultSorted = [{
            dataField: 'FECHA',
            order: 'asc'
        }];*/

        const columnsSeguimiento = [{
        dataField: 'FECHA',
        text: i18next.t("identificacion.fecha",{ns:"page"}),
        formatter: dateFormatter
        }, {
        dataField: 'NUMERO',
        text: i18next.t("identificacion.numero",{ns:"page"}),
        formatter: numberFollowUp
        }];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    identificacionSeleccionada: row,
                    seguimientoSeleccionada:null,
                    recargandoSeguimiento:true
                },async()=>{
                    await this.recuperarAreaDptoBloqueRiesgo();
                    await this.obtenerDocumentosSeguimiento();
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectFollowUp=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    seguimientoSeleccionada: row
                })
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectInformativa=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    informacionSeleccionada:row,
                })
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectFormativa=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    formacionSeleccionada:row,
                })
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const selectRowFormativa = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectFormativa,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowInformativa = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectInformativa,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowSeguimiento = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectFollowUp,
            style: { backgroundColor: '#c8e6c9' }
        };

        const abrirModalEditar = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.identificacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}));
                return
            }

            this.setState({
                recargando:true,
                recargandoSeguimiento:true
            });
            //console.log(this.state.identificacionSeleccionada);

            await this.recuperarAreaDptoBloqueRiesgo();
            await this.recuperarValoraciones();
            await this.recuperarRecursos();
            let categoriaRiesgo=0;
            let value=this.state.identificacionSeleccionada.NIVEL_INTENSIDAD;
            if(value < 8) {
                categoriaRiesgo=0;
            } else if(value < 15) {
                categoriaRiesgo=1;
            } else if(value < 22) {
                categoriaRiesgo=2;
            } else if(value < 31 ){
                categoriaRiesgo=3;
            }
            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                nuevoActivadoSeguimiento:false,
                editarActivadoSeguimiento:false,
                modalNuevoAbierto:false,
                modalNuevoAbiertoSeguimiento:false,
                modalIdentificacionAbierto:true,
                numero:this.state.identificacionSeleccionada.NUMERO,
                fechaFicha:this.state.identificacionSeleccionada.FECHA,
                nivelRiesgo:this.state.identificacionSeleccionada.NIVEL_INTENSIDAD,
                nivelSancion:this.state.identificacionSeleccionada.CONSECUENCIAS_SANCION,
                nivelPerdida:this.state.identificacionSeleccionada.CONSECUENCIAS_PERDIDA,
                nivelSuspension:this.state.identificacionSeleccionada.CONSECUENCIAS_SUSPENSION,
                nivelReputacional:this.state.identificacionSeleccionada.CONSECUENCIAS_DANO,
                nivelOtros:this.state.identificacionSeleccionada.CONSECUENCIAS_OTROS,
                riesgoHaSucedidoAntes:this.state.identificacionSeleccionada.OCURRIDO_ANTES,
                recursosFinancieros:this.state.identificacionSeleccionada.RECURSOS_FINANCIEROS,
                recursosOrganizacion:this.state.identificacionSeleccionada.RECURSOS_ORGANIZACION,
                recursosExternos:this.state.identificacionSeleccionada.RECURSOS_EXTERNOS,
                observaciones:this.state.identificacionSeleccionada.OBSERVACIONES,
                periodicidad:this.state.identificacionSeleccionada.PERIODICIDAD_REVISION,
                categoriaRiesgo:categoriaRiesgo                           
            })

        }

        const abrirModalEditarSeguimiento = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.seguimientoSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}));
                return
            }

            if(!this.state.identificacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}));
                return
            }
            this.setState({
                recargandoSeguimiento:true
            });

            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            await this.cargarImperativos(riesgo);
            await this.cargarRecomendaciones(riesgo);
            let imperativasList=await this.recargarImperativas();
            let recomendacionesList=await this.recargarRecomendaciones();
            //Cargar formaciones
            this.cargarFormaciones(this.state.seguimientoSeleccionada.ID_DOCUMENTO);

            //Cargar Informaciones
            this.cargarInformacion(this.state.seguimientoSeleccionada.ID_DOCUMENTO);

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                nuevoActivadoSeguimiento:false,
                editarActivadoSeguimiento:true,
                modalNuevoAbierto:false,
                modalNuevoAbiertoSeguimiento:true,
                numero:this.state.identificacionSeleccionada.NUMERO,
                numeroSeguimiento:this.state.seguimientoSeleccionada.NUMERO,
                fechaFichaSeguimiento:this.state.seguimientoSeleccionada.FECHA,
                imperativoSeleccionadoTabla: null,
                recomendadosSeleccionadoTabla:null,
                impertivosSeleccionados:imperativasList,
                recomendacionesSeleccionados:recomendacionesList,
                nombreInformante:this.state.seguimientoSeleccionada.NOMBRE_APELLIDOS,
                DNIinformante:this.state.seguimientoSeleccionada.DOCUMENTO_IDENTIDAD,
                certificado:this.state.seguimientoSeleccionada.COMPLIANCE_CERTIFICA,
                observaciones:this.state.seguimientoSeleccionada.OBSERVACIONES,
                explicacionImperativos:this.state.seguimientoSeleccionada.EXPLICACION_IMPERATIVOS,
                explicacionRecomendados:this.state.seguimientoSeleccionada.EXPLICACION_RECOMENDADOS,
                explicacionSalvedades:this.state.seguimientoSeleccionada.EXPLICACION_SALVEDADES,
                fechaProximaValoracionControles:new Date(this.state.seguimientoSeleccionada.FECHA_PROPUESTA_PROXIMA),
                fechaProximaValoracion:new Date(this.state.seguimientoSeleccionada.FECHA_PROXIMA),
                recargandoSeguimiento:true
            });

        }

        const abrirModalNuevo = async() => {
            
            if (!window.confirm(i18next.t("identificacion.questionaddidentificacion",{ns:"page"}))) {
                return
            }

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            await this.recargarAreas();

            this.setState({
                identificacionSeleccionada:null,
                recargando:true,
                recargandoSeguimiento:true,
                areaSeleccionada:null,
                deptoSeleccionado:null,
                bloqueHijoSeleccionado:null,
                bloquePadreSeleccionado:null,
                nuevoActivado:true,
                editarActivado:false,
                nuevoActivadoSeguimiento:false,
                editarActivadoSeguimiento:false,
                modalNuevoAbierto: true,
                modalNuevoAbiertoSeguimiento:false
            })
        }

        const abrirModalNuevoSeguimiento = async() => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //si no selecciono una planilla de identificacion
            if(!this.state.identificacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if (!window.confirm(i18next.t("identificacion.questionaddseguimiento",{ns:"page"}))) {
                return
            }
            await this.obtenerNumeroSeguimiento();
            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            await this.cargarImperativos(riesgo);
            await this.cargarRecomendaciones(riesgo);
            
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                nuevoActivadoSeguimiento:true,
                editarActivadoSeguimiento:false,
                modalNuevoAbierto:false,
                modalNuevoAbiertoSeguimiento: true,
                numero:this.state.identificacionSeleccionada.NUMERO,
                imperativoSeleccionadoTabla: null,
                recomendadosSeleccionadoTabla:null,
                recomendacionesSeleccionados:[],
                impertivosSeleccionados:[],
                nombreInformante: null,
                DNIinformante:null,
                certificado:false,
                observaciones: null,
                explicacionImperativos:null,
                explicacionRecomendados:null,
                explicacionSalvedades:null,
                fechaProximaValoracion:new Date(),
                fechaProximaValoracionControles:new Date(),
                recargandoSeguimiento:true
            })
        }

        const cerrarModal = () => {
            this.setState({
                areaSeleccionada:null,
                deptoSeleccionado:null,
                bloquePadreSeleccionado:null,
                bloqueHijoSeleccionado:null,
                modalNuevoAbierto: false,
                modalNuevoAbiertoSeguimiento: false,
                modalIdentificacionAbierto: false,
                nuevoActivado:true,
                editarActivado:false,
                nuevoActivadoSeguimiento:false,
                editarActivadoSeguimiento:false,
                recargando:false,
                modalImprimirIdentificaciones:false,
                recargandoSeguimiento:false,
                seguimientos:[],
                seguimientoSeleccionada:null
            })
        }
        
        const cerrarModalFicha = () => {
            this.setState({
                cargandoPrintFicha:false,
                modalImprimirFicha:false
            })
        }

        const cerrarModalFichaSeguimiento = () => {
            this.setState({
                cargandoPrintFichaSeguimiento:false,
                modalImprimirFichaSeguimiento:false
            })
        
        }

        const cerrarModalSeguimiento = () => {
            this.setState({
                modalNuevoAbierto:false,
                modalNuevoAbiertoSeguimiento: false,
                nuevoActivado:false,
                editarActivado:false,
                nuevoActivadoSeguimiento:true,
                editarActivadoSeguimiento:false,
                numero:this.state.identificacionSeleccionada.NUMERO,
                imperativoSeleccionadoTabla: null,
                recomendadosSeleccionadoTabla:null,
                recomendacionesSeleccionados:[],
                impertivosSeleccionados:[],
                nombreInformante: null,
                DNIinformante:null,
                certificado:false,
                observaciones: null,
                explicacionImperativos:null,
                explicacionRecomendados:null,
                explicacionSalvedades:null,
                fechaProximaValoracion:new Date(),
                fechaProximaValoracionControles:new Date(),
                seguimientoSeleccionada:null,
                recargandoSeguimiento:false,
                modalImprimirSeguimiento:false
            })
        }

        const seleccionaArea  = (value) => {

            this.setState({
                areaSeleccionada: value
            },async () =>{
                await this.recargarDeptos(value);
            })
        }

        const seleccionaDepto  = (value) => {

            this.setState({
                deptoSeleccionado: value,
                bloquesPadreSelect:this.state.bloquesRiesgo
            });
        }

        const seleccionaBloquePadre  = (value) => {

            this.setState({
                bloquePadreSeleccionado: value
            },async ()=>{
                await this.recargarBloquesHijos(value);
            });
        }

        const seleccionaBloqueHijo  = (value) => {

            this.setState({
                bloqueHijoSeleccionado: value
            });
        }

        const abrirFicha =async () => {
            if(!this.state.bloqueHijoSeleccionado){
                return
            }

            await this.obtenerDocumentos();
            
            this.setState({
                modalNuevoAbierto: false,
                modalIdentificacionAbierto: true
            });
        }

        const abrirDialogoTextoRiesgo=()=>{
   
            this.setState({ dialogTextoRiesgo: true });

        }

        const cerrarDialogoTextoRiesgo = () => {
            this.setState({ dialogTextoRiesgo: false });
        }

        const handleEnabledChange = () => {
            this.setState({riesgoHaSucedidoAntes: !this.state.riesgoHaSucedidoAntes})
        }

        const handleClickNivel = (value) => {
            this.setState({nivelRiesgo: value})
            
            if(value < 8) {
                this.setState({categoriaRiesgo: 0})
            } else if(value < 15) {
                this.setState({categoriaRiesgo: 1})
            } else if(value < 22) {
                this.setState({categoriaRiesgo: 2})
            } else if(value < 31 ){
                this.setState({categoriaRiesgo: 3})
            }
        }

        const handleClickSancion = (value) => {
            this.setState({nivelSancion: value})

            if(value < 8) {
                this.setState({categoriaSancion: 0})
            } else if(value < 15) {
                this.setState({categoriaSancion: 1})
            } else if(value < 22) {
                this.setState({categoriaSancion: 2})
            } else if(value < 31 ){
                this.setState({categoriaSancion: 3})
            }
        }

        const handleClickPerdida = (value) => {
            this.setState({nivelPerdida: value})
        }

        const handleClickSuspension = (value) => {
            this.setState({nivelSuspension: value})
        }

        const handleClickReputacional = (value) => {
            this.setState({nivelReputacional: value})
        }

        const handleClickOtros = (value) => {
            this.setState({nivelOtros: value})
        }

        const seleccionValoran  = (value) => { 
            this.setState({valoranSeleccionada: value})
        }

        const seleccionAsignados  = (value) => { 
            this.setState({asignadoSeleccionada: value})
        }

        const actualizarCambios=async() =>{
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //comprobaciones
            if(!this.state.nivelRiesgo){
                alert(i18next.t("identificacion.noselectnivelriesgo",{ns:"page"}))
                return
            }
            if(!this.state.periodicidad){
                return
            }
            if(!this.state.observaciones){
                return
            }
            
            let error=false;
            let idContratoDepartamento=this.state.deptoSeleccionado.contDept;
            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            
            //si el documento existe
            await this.props.client.mutate({
                    mutation:gql`mutation{actualizarComplianceDocumentosValoracion(ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO},VALORES:{
                    ID_CONTRATO_DEPARTAMENTO:${idContratoDepartamento},
                    ID_RIESGO:${riesgo},
                    NIVEL_INTENSIDAD:${this.state.nivelRiesgo},
                    OCURRIDO_ANTES:${this.state.riesgoHaSucedidoAntes},
                    CONSECUENCIAS_SANCION:${this.state.nivelSancion},
                    CONSECUENCIAS_PERDIDA:${this.state.nivelPerdida},
                    CONSECUENCIAS_SUSPENSION:${this.state.nivelSuspension},
                    CONSECUENCIAS_DANO:${this.state.nivelReputacional},
                    CONSECUENCIAS_OTROS:${this.state.nivelOtros},
                    RECURSOS_FINANCIEROS:${this.state.recursosFinancieros?'"'+this.state.recursosFinancieros+'"':null},
                    RECURSOS_ORGANIZACION:${this.state.recursosOrganizacion?'"'+this.state.recursosOrganizacion+'"':null},
                    RECURSOS_EXTERNOS:${this.state.recursosExternos?'"'+this.state.recursosExternos+'"':null},
                    PERIODICIDAD_REVISION:${JSON.stringify(this.state.periodicidad)},
                    OBSERVACIONES:"""${this.state.observaciones}""",
                    ID_USUARIO:${this.state.idUsuario},    
                }){ID_DOCUMENTO}}`
            }).then(async result=>{

                //eliminamos los antiguos.
                await this.props.client.mutate({
                    mutation:gql`mutation{eliminarComplianceDocumentosValoran(ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO}){ID_VALORA}}`
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                    error=true
                    return
                });
                
                if(error)return

                //crear compliance documentos valoran
                if(this.state.valoranSeleccionada.length>0){
                    this.state.valoranSeleccionada.forEach(async valora=>{
                        await this.props.client.mutate({
                            mutation:gql`mutation{crearDocumentoValoran(VALORES:{
                                ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO},
                                ID_PERSONA:${valora.value},                
                            }){ID_VALORA}}`
                        }).catch(err=>{
                            alert(i18next.t("error",{ns:"global"}))
                            error=true
                            return
                        }) 
    
                    })
            
                }
                
                if(error)return

                await this.props.client.mutate({
                    mutation:gql`mutation{eliminarComplianceDocumentoRecurso(ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO}){ID_RECURSO}}`
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                    error=true
                    return
                }) 

                if(error)return

                if(this.state.asignadoSeleccionada.length>0){

                    this.state.asignadoSeleccionada.forEach(async asignada=>{

                        await this.props.client.mutate({
                            mutation:gql`mutation{crearComplianceDocumentoRecurso(VALORES:{
                                ID_DOCUMENTO:${this.state.identificacionSeleccionada.ID_DOCUMENTO},
                                ID_PERSONA:${asignada.value},                
                            }){ID_RECURSO}}`
                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error",{ns:"global"}))
                            return
                        })    

                    })                        
                
                }

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                error=true
                return
            });

            if(error)return

            alert(i18next.t("updatecorrecto",{ns:"global"}));

            await this.recargarIdentificaciones();
            cerrarModal();

                
        }

        /*const cerrarModalFormacion=()=>{
            this.setState({
                elegirAccionFormativa: false,	
                formacionSeleccionada:false
            });
        }*/

        const abrirFormacion=async()=>{
            this.setState({
                elegirAccionFormativa: true,	
                formacionSeleccionada:false
            });
        }

        /*const cerrarModalInformacion=()=>{
            this.setState({
                elegirAccionInformativa: false,	
                informacionSeleccionada:false
            });
        }*/

        const abrirInformacion=async()=>{
            this.setState({
                elegirAccionInformativa: true,	
                informacionSeleccionada:false
              });
        }

        const guardarCambiosSeguimiento=async ()=>{
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.nombreInformante){
                ReactDOM.findDOMNode(this.nombreInformanteInput).children[0].focus()
                return
            }
    
            if(!this.state.DNIinformante){
                ReactDOM.findDOMNode(this.DNIinformanteInput).children[0].focus()
                return
            }

            if(!this.state.explicacionImperativos){
              
                ReactDOM.findDOMNode(this.explicacionImperativosInput).focus()
                return
            }
    
            if(!this.state.explicacionRecomendados){
                ReactDOM.findDOMNode(this.explicacionRecomendadosInput).focus()
                return
            }
    
            if(!this.state.explicacionSalvedades){
                ReactDOM.findDOMNode(this.explicacionSalvedadesInput).focus()
                return
            }
    
            if(!this.state.observaciones){
                ReactDOM.findDOMNode(this.observacionesInput).focus()
                return
            }

            if (this.state.impertivosSeleccionados.length===0 && this.state.recomendacionesSeleccionados.length===0){
                alert(i18next.t("identificacion.noselectcontrol",{ns:"page"}));
                return;
            }

            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            
            //mutacion para crear el documento
            await this.props.client
                .mutate({
                mutation: gql`
                  mutation{crearDocumentoSeguimiento(VALORES:{
                      ID_CONTRATO_DEPARTAMENTO:${
                          this.state.identificacionSeleccionada.ID_CONTRATO_DEPARTAMENTO
                      },
                      ID_RIESGO:${riesgo},
                      ID_DOCUMENTO_VALORACION:${this.state.identificacionSeleccionada.ID_DOCUMENTO},
                      FECHA:"${moment().format("YYYY-MM-DD")}",
                      NUMERO:${this.state.numeroSeguimiento},
                      NOMBRE_APELLIDOS:"${this.state.nombreInformante}"
                      DOCUMENTO_IDENTIDAD:"${this.state.DNIinformante}"
                      EMPRESA:""
                      PUESTO:""
                      EXPLICACION_IMPERATIVOS:"""${
                          this.state.explicacionImperativos
                      }"""
                      EXPLICACION_RECOMENDADOS:"""${
                          this.state.explicacionRecomendados
                      }"""
                      EXPLICACION_SALVEDADES:"""${
                          this.state.explicacionSalvedades
                      }"""
                      FECHA_PROPUESTA_PROXIMA:"${
                          moment(this.state.fechaProximaValoracionControles).format("YYYY-MM-DD")
                      }"
                      OBSERVACIONES:"""${this.state.observaciones}"""
                      FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                      ID_USUARIO:${this.state.idUsuario}
                      COMPLIANCE_CERTIFICA:${this.state.certificado}
                  }){ID_DOCUMENTO}}`
                })
                .then(async result => {
                             
                    let idDocumento =  result.data.crearDocumentoSeguimiento.ID_DOCUMENTO;

                    //insercion de los controles imperativos/recomendaciones
                    let error=false

                    //agregamos los imperativos                 
                    this.state.impertivosSeleccionados.forEach(async(imperativo,index)=>{
                        await this.props.client.mutate({
                        mutation:gql`mutation{crearSeguimientoImperativa(VALORES:{ID_DOCUMENTO:${idDocumento},ID_IMPERATIVA:${imperativo.value},VALOR:${imperativo.nivel}}){ID_DOCUMENTO_IMPERATIVA}}`
                        }).catch(err=>{
                        error=true
                        alert(i18next.t("error",{ns:"global"}))
                        return
                        })
                    })

                    if(error)return

                    //agregamos las recomendaciones                 
                    this.state.recomendacionesSeleccionados.forEach(async(recomendacion,index)=>{
                        await this.props.client.mutate({
                        mutation:gql`mutation{crearSeguimientoRecomendacion(VALORES:{ID_DOCUMENTO:${idDocumento},ID_RECOMENDACION:${recomendacion.value},VALOR:${recomendacion.nivel}}){ID_DOCUMENTO_RECOMENDACION}}`
                        }).catch(err=>{
                        error=true
                        alert(i18next.t("error",{ns:"global"}))
                        return
                        })
                    
                    })

                    if(error)return
                    this.setState({recargandoSeguimiento:true});
                    await this.obtenerDocumentosSeguimiento();
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                });
            
            cerrarModalSeguimiento();
        }

        const actualizarCambiosSeguimiento=async ()=>{
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.nombreInformante){
                ReactDOM.findDOMNode(this.nombreInformanteInput).children[0].focus()
                return
            }
    
            if(!this.state.DNIinformante){
                ReactDOM.findDOMNode(this.DNIinformanteInput).children[0].focus()
                return
            }

            if(!this.state.explicacionImperativos){
              
                ReactDOM.findDOMNode(this.explicacionImperativosInput).focus()
                return
            }
    
            if(!this.state.explicacionRecomendados){
                ReactDOM.findDOMNode(this.explicacionRecomendadosInput).focus()
                return
            }
    
            if(!this.state.explicacionSalvedades){
                ReactDOM.findDOMNode(this.explicacionSalvedadesInput).focus()
                return
            }
    
            if(!this.state.observaciones){
                ReactDOM.findDOMNode(this.observacionesInput).focus()
                return
            }

            if (this.state.impertivosSeleccionados.length===0 && this.state.recomendacionesSeleccionados.length===0){
                alert(i18next.t("identificacion.noselectcontrol",{ns:"page"}));
                return;
            }

            //si existe el documento lo actualizamos
            //si el documento existe
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarDocumentoSeguimiento(ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO},VALORES:{
                    NOMBRE_APELLIDOS:"${this.state.nombreInformante}"
                    DOCUMENTO_IDENTIDAD:"${this.state.DNIinformante}"
                    EMPRESA:""
                    PUESTO:""
                    EXPLICACION_IMPERATIVOS:"""${
                        this.state.explicacionImperativos
                    }"""
                    EXPLICACION_RECOMENDADOS:"""${
                        this.state.explicacionRecomendados
                    }"""
                    EXPLICACION_SALVEDADES:"""${
                        this.state.explicacionSalvedades
                    }"""
                    FECHA_PROPUESTA_PROXIMA:"${
                        moment(this.state.fechaProximaValoracionControles).format("YYYY-MM-DD")
                    }"
                    OBSERVACIONES:"""${this.state.observaciones}"""
                    FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                    ID_USUARIO:${this.state.idUsuario}
                    COMPLIANCE_CERTIFICA:${this.state.certificado}
                }){ID_DOCUMENTO}}`
            }).then(async result=>{
           
                alert(i18next.t("updatecorrecto",{ns:"global"}))

                //insercion de los controles imperativos/recomendaciones
                //insercion de los controles imperativos/recomendaciones
                let error=false
                
                //eliminamos los viejeos
                await this.props.client.mutate({
                    mutation:gql`mutation{eliminarSeguimientoImperativa(ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO}){ID_DOCUMENTO_IMPERATIVA}}`
                }).catch(err=>{
                    error=true
                    alert(i18next.t("error",{ns:"global"}))
                    return
                })

                if(error)return

                //agregamos los imperativos                 
                this.state.impertivosSeleccionados.forEach(async(imperativo,index)=>{
                    await this.props.client.mutate({
                      mutation:gql`mutation{crearSeguimientoImperativa(VALORES:{ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO},ID_IMPERATIVA:${imperativo.value},VALOR:${imperativo.nivel}}){ID_DOCUMENTO_IMPERATIVA}}`
                    }).catch(err=>{
                        error=true
                        alert(i18next.t("error",{ns:"global"}))
                        return
                    })
                })

                if(error)return

                //eliminamos los viejeos
                await this.props.client.mutate({
                    mutation:gql`mutation{eliminarSeguimientoRecomendacion(ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO}){ID_DOCUMENTO_RECOMENDACION}}`
                }).catch(err=>{
                    error=true
                    alert(i18next.t("error",{ns:"global"}))
                    return
                })

                if(error)return

                //agregamos las recomendaciones                 
                this.state.recomendacionesSeleccionados.forEach(async(recomendacion,index)=>{
                    await this.props.client.mutate({
                      mutation:gql`mutation{crearSeguimientoRecomendacion(VALORES:{ID_DOCUMENTO:${this.state.seguimientoSeleccionada.ID_DOCUMENTO},ID_RECOMENDACION:${recomendacion.value},VALOR:${recomendacion.nivel}}){ID_DOCUMENTO_RECOMENDACION}}`
                    }).catch(err=>{
                        error=true
                        alert(i18next.t("error",{ns:"global"}))
                        return
                    })
                })

                if(error)return
                this.setState({recargandoSeguimiento:true});
                await this.obtenerDocumentosSeguimiento();
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })
           
            cerrarModalSeguimiento();
        }

        const guardarCambios=async ()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //comprobaciones
            if(!this.state.nivelRiesgo){
                alert(i18next.t("identificacion.noselectnivelriesgo",{ns:"page"}))
                return
            }
            if(!this.state.periodicidad){
                return
            }
            if(!this.state.observaciones){
                return
            }
            
            let idDocumento=0;
            let riesgo=this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0;
            let idContratoDepartamento=this.state.deptoSeleccionado.contDept;
            await this.props.client.mutate({
                mutation:gql`mutation{crearComplianceDocumentosValoracion(VALORES:{
                ID_CONTRATO_DEPARTAMENTO:${idContratoDepartamento},
                ID_RIESGO:${riesgo},
                FECHA:"${moment().format("YYYY-MM-DD")}",
                NUMERO:${this.state.numero},
                NIVEL_INTENSIDAD:${this.state.nivelRiesgo},
                OCURRIDO_ANTES:${this.state.riesgoHaSucedidoAntes},
                CONSECUENCIAS_SANCION:${this.state.nivelSancion},
                CONSECUENCIAS_PERDIDA:${this.state.nivelPerdida},
                CONSECUENCIAS_SUSPENSION:${this.state.nivelSuspension},
                CONSECUENCIAS_DANO:${this.state.nivelReputacional},
                CONSECUENCIAS_OTROS:${this.state.nivelOtros},
                RECURSOS_FINANCIEROS:${this.state.recursosFinancieros?'"'+this.state.recursosFinancieros+'"':null},
                RECURSOS_ORGANIZACION:${this.state.recursosOrganizacion?'"'+this.state.recursosOrganizacion+'"':null},
                RECURSOS_EXTERNOS:${this.state.recursosExternos?'"'+this.state.recursosExternos+'"':null},
                PERIODICIDAD_REVISION:${JSON.stringify(this.state.periodicidad)},
                OBSERVACIONES:"""${this.state.observaciones}""",
                ID_USUARIO:${this.state.idUsuario},
                
                }){ID_DOCUMENTO}}`
            }).then(async result=>{
                idDocumento= result.data.crearComplianceDocumentosValoracion.ID_DOCUMENTO

                let error=false
                //crear compliance documentos valoran
                if(this.state.valoranSeleccionada.length>0){

                    this.state.valoranSeleccionada.forEach(async valora=>{
                        await this.props.client.mutate({
                            mutation:gql`mutation{crearDocumentoValoran(VALORES:{
                                ID_DOCUMENTO:${idDocumento},
                                ID_PERSONA:${valora.value},                
                            }){ID_VALORA}}`
                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error",{ns:"global"}))
                        }) 
    
                    })
            
                }

                if(error)return

                if(this.state.asignadoSeleccionada.length>0){

                    this.state.asignadoSeleccionada.forEach(async asignada=>{

                        await this.props.client.mutate({
                            mutation:gql`mutation{crearComplianceDocumentoRecurso(VALORES:{
                                ID_DOCUMENTO:${idDocumento},
                                ID_PERSONA:${asignada.value},                
                            }){ID_RECURSO}}`
                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error",{ns:"global"}))
                            return
                        })    

                    })

                }

                if(error)return
                alert(i18next.t("insertcorrecto",{ns:"global"}));

                await this.recargarIdentificaciones();
                cerrarModal();
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
        }

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'Primero',
            prePageText: 'Atrás',
            nextPageText: 'Próxima',
            lastPageText: 'Última',
            nextPageTitle: 'Primera página',
            prePageTitle: 'página previa',
            firstPageTitle: 'Siguiente página',
            lastPageTitle: 'Última página',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '8', value: 8
              }] // A numeric array is also available. the purpose of above example is custom the text
        };

        return(
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                    <>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModalFicha}
                            title={i18next.t("identificacion.titledoc",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimirFicha}
                            style={styles.dialogL}
                        >
                            {!this.state.cargandoImprimirGral?
                                    <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModalFichaSeguimiento}
                            title={i18next.t("identificacion.titledocseg",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimirFichaSeguimiento}
                            style={styles.dialogL}
                        >
                            {!this.state.cargandoImprimirSegGral?
                                    <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModal}
                            title={i18next.t("identificacion.titledoc",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimirIdentificaciones}
                            style={styles.dialogL}
                        >
                            {!this.state.cargandoImprimirGral?
                                    <div style={{ width: '100%', height: '500px'}} id='generarGral-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModalSeguimiento}
                            title={i18next.t("identificacion.titledocseg",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimirSeguimiento}
                            style={styles.dialogL}
                        >
                            {!this.state.cargandoImprimirSegGral?
                                    <div style={{ width: '100%', height: '500px'}} id='generarSegGral-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModalSeguimiento}
                            title={i18next.t("identificacion.titledocseg",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            isOpen={this.state.modalNuevoAbiertoSeguimiento}
                            className="dialog-ancho dialog-scroll "
                            >
                            {this.state.cargandoPrintFichaSeguimiento===true?
                                <Cargando/>
                            :
                            <div>
                            <div ref={(input) => { this.fichaSeguimiento= input; }} className="pixelAlrededor">
                            <br/>                   
                                <Card interactive={true} id="modalIdentificacion" elevation={Elevation.TWO} className="pixelAlrededor">
                                    <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr >
                                                    <td rowSpan="2" style={{width:'80%',padding:'5px 5px 5px 5px'}}><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.controlesimplantados",{ns:"page"}).toUpperCase()}</Callout></td>
                                                    <td style={{width:'20%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.fechaFichaSeguimiento?moment(this.state.fechaFichaSeguimiento,"YYYY-MM-DD").format("DD-MM-YYYY"):moment().format("DD-MM-YYYY")}</span></td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'20%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.numeroSeguimiento}/{this.state.numeroSeguimiento}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'15%'}}><span style={{fontWeight:'bold'}}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</span></td>
                                                    <td style={{width:'35%'}}><span style={{fontWeight:'normal'}}>{this.state.areaSeleccionada?this.state.areaSeleccionada.label:''}</span></td>
                                                    <td style={{width:'15%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</span></td>
                                                    <td style={{width:'35%'}}><span style={{fontWeight:'normal'}}>{this.state.deptoSeleccionado?this.state.deptoSeleccionado.label:''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td  colSpan="4" style={{textAlign:'center'}}><span style={{fontWeight:'bold'}}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.titulo:""}</span></td>
                                                </tr>
                                                <tr>
                                                    <td  colSpan="4"><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.label:""} <Tooltip content={i18next.t("see",{ns:"global"})}  position={Position.TOP} ><Button icon="info-sign" onClick={()=>{abrirDialogoTextoRiesgo()}}/></Tooltip></Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.operativas",{ns:"page"}).toUpperCase()}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFichaCheck'>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan={6} style={{textAlign:'center'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.nivel",{ns:"page"}).toUpperCase()}</span></td>
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.imperativas",{ns:"page"}).toUpperCase()}</span> <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                                        <Button icon="add" onClick={abrirModalImpertativos}/>
                                                    </Tooltip></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</span></td>
                                                </tr>
                                                {this.state.impertivosSeleccionados.length===0?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                :
                                                    <></>
                                                }
                                        
                                                {this.state.impertivosSeleccionados.map((imperativo,index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                                                                <Button icon="delete" onClick={() => borrarImperativo(index)} />
                                                            </Tooltip> 
                                                            &nbsp;
                                                            {imperativo.label}
                                                        </td>
                                                    {imperativo.nivel === 1?
                                                        <>
                                                            <td className="celdaFondoVerde">
                                                                <Icon icon="endorsed" style={{ color: "#000" }} />
                                                            </td>
                                                        </>
                                                    :
                                                        <>
                                                            <td className="celdaFondoVerde" onClick={() => handleClickImperativas(index,1)}>
                                                                1
                                                            </td>
                                                        </>
                                                    }
                                                    {imperativo.nivel === 2?
                                                        <>
                                                            <td className="celdaFondoAmarillo">
                                                                <Icon icon="endorsed" style={{ color: "#000" }} />
                                                            </td>
                                                        </>
                                                    :
                                                        <>
                                                            <td className="celdaFondoAmarillo" onClick={() => handleClickImperativas(index,2)}>
                                                                2
                                                            </td>
                                                        </>
                                                    }
                                                    {imperativo.nivel === 3?
                                                        <>
                                                            <td className="celdaFondoAmarillo">
                                                                <Icon icon="endorsed" style={{ color: "#000" }} />
                                                            </td>
                                                        </>
                                                    :
                                                        <>
                                                            <td className="celdaFondoAmarillo" onClick={() => handleClickImperativas(index,3)}>
                                                                3
                                                            </td>
                                                        </>
                                                    }
                                                    {imperativo.nivel=== 4?
                                                        <>
                                                            <td className="celdaFondoNaranja">
                                                                <Icon icon="endorsed" style={{ color: "#000" }} />
                                                            </td>
                                                        </>
                                                    :
                                                        <>
                                                            <td className="celdaFondoNaranja" onClick={() => handleClickImperativas(index,4)}>
                                                                4
                                                            </td>
                                                        </>
                                                    }
                                                    {imperativo.nivel === 5?
                                                        <>
                                                            <td className="celdaFondoRojo">
                                                                <Icon icon="endorsed" style={{ color: "#000" }} />
                                                            </td>
                                                        </>
                                                    :
                                                        <>
                                                            <td className="celdaFondoRojo" onClick={() => handleClickImperativas(index,5)}>
                                                                5
                                                            </td>
                                                        </>
                                                    }
                                                    {imperativo.nivel === 6?
                                                        <>
                                                            <td className="celdaFondoRojo">
                                                                <Icon icon="endorsed" style={{ color: "#000" }} />
                                                            </td>
                                                        </>
                                                    :
                                                        <>
                                                            <td className="celdaFondoRojo" onClick={() => handleClickImperativas(index,6)}>
                                                                6
                                                            </td>
                                                        </>
                                                    }
                                                    </tr>
                                                )
                                                })}
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.recomendaciones",{ns:"page"}).toUpperCase()}</span> <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                                        <Button icon="add" onClick={abrirModalRecomendacion}/>
                                                    </Tooltip></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</span></td>
                                                </tr>
                                                {this.state.recomendacionesSeleccionados.length===0?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                :
                                                    <></>
                                                }
                                        
                                                {this.state.recomendacionesSeleccionados.map((recomendacion,index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                                                                <Button icon="delete" onClick={() => borrarRecomendacion(index)} />
                                                            </Tooltip> 
                                                            &nbsp;
                                                            {recomendacion.label}

                                                        </td>
                                                        {recomendacion.nivel === 1?
                                                            <>
                                                                <td className="celdaFondoVerde">
                                                                    <Icon icon="endorsed" style={{ color: "#000" }} />
                                                                </td>
                                                            </>
                                                        :
                                                            <>
                                                                <td className="celdaFondoVerde" onClick={() => handleClickRecomendaciones(index,1)}>
                                                                    1
                                                                </td>
                                                            </>
                                                        }
                                                        {recomendacion.nivel === 2?
                                                            <>
                                                                <td className="celdaFondoAmarillo">
                                                                    <Icon icon="endorsed" style={{ color: "#000" }} />
                                                                </td>
                                                            </>
                                                        :
                                                            <>
                                                                <td className="celdaFondoAmarillo" onClick={() => handleClickRecomendaciones(index,2)}>
                                                                    2
                                                                </td>
                                                            </>
                                                        }
                                                        {recomendacion.nivel === 3?
                                                            <>
                                                                <td className="celdaFondoAmarillo">
                                                                    <Icon icon="endorsed" style={{ color: "#000" }} />
                                                                </td>
                                                            </>
                                                        :
                                                            <>
                                                                <td className="celdaFondoAmarillo" onClick={() => handleClickRecomendaciones(index,3)}>
                                                                    3
                                                                </td>
                                                            </>
                                                        }
                                                        {recomendacion.nivel === 4?
                                                            <>
                                                                <td className="celdaFondoNaranja">
                                                                    <Icon icon="endorsed" style={{ color: "#000" }} />
                                                                </td>
                                                            </>
                                                        :
                                                            <>
                                                                <td className="celdaFondoNaranja" onClick={() => handleClickRecomendaciones(index,4)}>
                                                                    4
                                                                </td>
                                                            </>
                                                        }
                                                        {recomendacion.nivel === 5?
                                                            <>
                                                                <td className="celdaFondoRojo">
                                                                    <Icon icon="endorsed" style={{ color: "#000" }} />
                                                                </td>
                                                            </>
                                                        :
                                                            <>
                                                                <td className="celdaFondoRojo" onClick={() => handleClickRecomendaciones(index,5)}>
                                                                    5
                                                                </td>
                                                            </>
                                                        }
                                                        {recomendacion.nivel === 6?
                                                            <>
                                                                <td className="celdaFondoRojo">
                                                                    <Icon icon="endorsed" style={{ color: "#000" }} />
                                                                </td>
                                                            </>
                                                        :
                                                            <>
                                                                <td className="celdaFondoRojo" onClick={() => handleClickRecomendaciones(index,6)}>
                                                                    6
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.informecumplimiento",{ns:"page"}).toUpperCase()}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'50%'}}>
                                                        {i18next.t("identificacion.don",{ns:"page"})}:
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.nombreInformante?i18next.t("identificacion.emptyname",{ns:"page"}):""}
                                                        >
                                                            <InputGroup value={this.state.nombreInformante?this.state.nombreInformante:""} ref={(input) => { this.nombreInformanteInput= input; }}  onChange={cogerNombreInformante} intent={!this.state.nombreInformante?"danger":"primary"} />
                                                        </FormGroup>
                                                    </td>
                                                    <td style={{width:'50%'}}>
                                                        {i18next.t("identificacion.condni",{ns:"page"})}:
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.DNIinformante?i18next.t("identificacion.emptydni",{ns:"page"}):(this.state.dniInvalido?i18next.t("identificacion.invaliddni",{ns:"page"}):"")}
                                                        >
                                                            <InputGroup value={this.state.DNIinformante?this.state.DNIinformante:""} maxLength={10} ref={(input) => { this.DNIinformanteInput= input; }} intent={!this.state.DNIinformante || this.state.dniInvalido?"danger":"primary"} onChange={cogerDNIInformante} />{" "}
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <span style={{fontWeight:'bold'}}>{i18next.t("identificacion.certifica",{ns:"page"})}:</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        {i18next.t("identificacion.explicaimperativo",{ns:"page"})}
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.explicacionImperativos?i18next.t("identificacion.emptyimperativo",{ns:"page"}):""}
                                                        >
                                                            <TextArea
                                                                fill={true}
                                                                onChange={cogerExplicacionImperativos}
                                                                value={this.state.explicacionImperativos?this.state.explicacionImperativos:""}
                                                                intent={!this.state.explicacionImperativos?"danger":"primary"}
                                                                ref={(input) => { this.explicacionImperativosInput= input; }}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        {i18next.t("identificacion.explicarecomendado",{ns:"page"})}
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.explicacionRecomendados?i18next.t("identificacion.emptyrecomendado",{ns:"page"}):""}
                                                        >
                                                            <TextArea
                                                            fill={true}
                                                            onChange={cogerExplicacionRecomendados}
                                                            value={this.state.explicacionRecomendados?this.state.explicacionRecomendados:""}
                                                            intent={!this.state.explicacionRecomendados?"danger":"primary"}
                                                            ref={(input) => { this.explicacionRecomendadosInput= input; }}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        {i18next.t("identificacion.explicasalvedades",{ns:"page"})}
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.explicacionSalvedades?i18next.t("identificacion.emptysalvedades",{ns:"page"}):""}
                                                        >
                                                            <TextArea
                                                            fill={true}
                                                            onChange={cogerExplicacionSalvedades}
                                                            value={this.state.explicacionSalvedades?this.state.explicacionSalvedades:""}
                                                            intent={!this.state.explicacionSalvedades?"danger":"primary"}
                                                            ref={(input) => { this.explicacionSalvedadesInput= input; }}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        {i18next.t("identificacion.fechacontroles",{ns:"page"})}:
                                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                                        maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                                                        {...jsDateFormatter}
                                                        onChange={escogeFechaValoracionControles}
                                                        value={this.state.fechaProximaValoracionControles}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tg-wrap">
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.informesupervisor",{ns:"page"}).toUpperCase()}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'10%'}}>
                                                        <Checkbox onChange={handleCertificar} checked={this.state.certificado} />
                                                    </td>
                                                    <td style={{width:'90%'}}>
                                                        {i18next.t("identificacion.certifico",{ns:"page"})}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        {i18next.t("identificacion.observaciones",{ns:"page"})}
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.observaciones?i18next.t("identificacion.emptyobservaciones",{ns:"page"}):""}
                                                        >
                                                            <TextArea fill={true} value={this.state.observaciones?this.state.observaciones:""}  ref={(input) => { this.observacionesInput= input; }} intent={!this.state.observaciones?"danger":"primary"} onChange={cogerObservaciones} />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        {i18next.t("identificacion.fechavaloracion",{ns:"page"})}:
                                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                                        maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                                                        {...jsDateFormatter}
                                                        onChange={escogeFechaValoracion}
                                                        value={this.state.fechaProximaValoracion}
                                                        />
                                                    </td>
                                                </tr>    
                                            </tbody>
                                        </table>    
                                    </div>
                                </Card>
                            </div>
                            <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("identificacion.formacion",{ns:"page"})} position="top">
                                        <Button  onClick={abrirFormacion}>{i18next.t("identificacion.formacion",{ns:"page"})} <span className="badge">{this.state.numFormacion}</span></Button>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("identificacion.informacion",{ns:"page"})} position="top">
                                        <Button  onClick={abrirInformacion}>{i18next.t("identificacion.informacion",{ns:"page"})} <span className="badge">{this.state.numInformacion}</span></Button>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModalSeguimiento}>{i18next.t("close",{ns:"global"})}</Button>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("print",{ns:"global"})} position="top">
                                        <Button intent={Intent.DANGER} icon="print" text={i18next.t("print",{ns:"global"})} onClick={this.imprimirFichaSeguimiento} />
                                    </Tooltip>
                                    {this.state.nuevoActivadoSeguimiento?
                                    <Tooltip content={this.state.nuevoActivadoSeguimiento?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                        <AnchorButton
                                        icon="floppy-disk"
                                        intent={Intent.DANGER}
                                        onClick={this.state.nuevoActivadoSeguimiento?guardarCambiosSeguimiento:actualizarCambiosSeguimiento}
                                        className="bp3-minimal"
                                        disabled={this.state.bloqueHijoSeleccionado?false:true}
                                        >
                                        {this.state.nuevoActivadoSeguimiento?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    :<></>}
                                </div>
                            </div>
                            </div>
                            }
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={()=>{this.setState({elegirAccionFormativa:false})}}
                            title={i18next.t("identificacion.selectformativa",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.elegirAccionFormativa}
                        >       

                            <div className={Classes.DIALOG_BODY}>
                                {this.props.editable===true && this.state.nuevoActivadoSeguimiento?
                                    <p>{i18next.t("identificacion.deseacrearformativa",{ns:"page"})}, <a href="#top" onClick={(e)=>{              
                                        e.preventDefault()
                                        this.setState({crearAccionFormativa:true,formacionSeleccionada:null,recargandoFormativa:true})
                                    }}>{i18next.t("identificacion.pulseaqui",{ns:"page"})}</a></p>
                                :<></> }
                                {this.state.nuevoActivadoSeguimiento?
                                    <p>{i18next.t("identificacion.deseaeditarformativa",{ns:"page"})}.</p>
                                :<></>}
                                {this.state.recargandoFormativa===true?
                                    <Cargando/>
                                :
                                <BootstrapTable
                                    keyField='ID_ACCION'
                                    data={ this.state.accionesFormativas }
                                    columns={ columnsFormativa }
                                    selectRow={ selectRowFormativa }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                }
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    {this.state.nuevoActivadoSeguimiento?
                                        <AnchorButton intent={Intent.PRIMARY} onClick={()=>{
                                        if(!this.state.formacionSeleccionada){
                                            alert(i18next.t("noseleccionado",{ns:"global"}))
                                            return
                                        }
                                        this.setState({crearAccionFormativa:true})
                                        }}>
                                            {i18next.t("aceptar",{ns:"global"})}
                                        </AnchorButton>
                                    :<></>}
                                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{
                                        this.setState({elegirAccionFormativa:false})
                                    }}>
                                        {i18next.t("cancelar",{ns:"global"})}
                                    </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={()=>{this.setState({elegirAccionInformativa:false})}}
                            title={i18next.t("identificacion.selectinformativa",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.elegirAccionInformativa}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                {this.props.editable===true && this.state.nuevoActivadoSeguimiento?
                                <p>{i18next.t("identificacion.deseacrearinformativa",{ns:"page"})}, <a href="#top" onClick={(e)=>{
                                    e.preventDefault()
                                    this.setState({crearAccionInformativa:true,informacionSeleccionada:null,recargandoInformativa:true})}
                                }>{i18next.t("identificacion.pulseaqui",{ns:"page"})}</a></p>
                                :<></>}
                                {this.state.nuevoActivadoSeguimiento?
                                    <p>{i18next.t("identificacion.deseaeditarinformativa",{ns:"page"})}.</p>
                                :<></>}
                                {this.state.recargandoInformativa===true?
                                    <Cargando/>
                                :
                                <BootstrapTable
                                    keyField='ID_ACCION'
                                    data={ this.state.accionesInformativas }
                                    columns={ columnsInformativa }
                                    selectRow={ selectRowInformativa }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                }
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    {this.state.nuevoActivadoSeguimiento?
                                        <AnchorButton intent={Intent.PRIMARY} onClick={()=>{
                                        if(!this.state.informacionSeleccionada){
                                            alert(i18next.t("noseleccionado",{ns:"global"}))
                                            return
                                        }
                                        this.setState({crearAccionInformativa:true})
                                        }}>
                                            {i18next.t("aceptar",{ns:"global"})}
                                        </AnchorButton>
                                    :<></>}
                                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({elegirAccionInformativa:false})}}>
                                        {i18next.t("cancelar",{ns:"global"})}
                                    </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={()=>{this.setState({crearAccionInformativa:false,recargandoInformativa:false})}}
                            title={i18next.t("identificacion.createinformativa",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.crearAccionInformativa}
                            className="dialog-extra-ancho"
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <AccionesSeguimiento editable={this.props.editable} deshabilitado={this.state.bloqueHijoSeleccionado?false:true} area={this.state.areaSeleccionada?this.state.areaSeleccionada.value:null} departamento={this.state.deptoSeleccionado?this.state.deptoSeleccionado.value:null} ref={(input) => { this.accionesSeguimiento= input; }} tipo="informacion" datos={this.state.informacionSeleccionada} />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <AnchorButton disabled={(this.props.editable || this.state.bloqueHijoSeleccionado) ?false:true} intent={Intent.PRIMARY} onClick={añadirNuevaInformativa}>
                                        {i18next.t("aceptar",{ns:"global"})}
                                    </AnchorButton>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({crearAccionInformativa:false,recargandoInformativa:false})}}>
                                        {i18next.t("cancelar",{ns:"global"})}
                                    </AnchorButton>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={()=>{this.setState({crearAccionFormativa:false,recargandoFormativa:false})}}
                            title={i18next.t("identificacion.createformativa",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                            isOpen={this.state.crearAccionFormativa}
                            className="dialog-extra-ancho"
                        >
                            <div id="modal-crear-formativa" className={Classes.DIALOG_BODY}>
                                <AccionesSeguimiento editable={this.props.editable} deshabilitado={this.state.bloqueHijoSeleccionado?false:true} area={this.state.areaSeleccionada?this.state.areaSeleccionada.value:null} departamento={this.state.deptoSeleccionado?this.state.deptoSeleccionado.value:null} ref={(input) => { this.accionesSeguimiento= input; }} tipo="formacion" datos={this.state.formacionSeleccionada} />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <AnchorButton disabled={(this.props.editable || this.state.bloqueHijoSeleccionado) ?false:true} intent={Intent.PRIMARY} onClick={añadirNuevaFormativa}>
                                        {i18next.t("aceptar",{ns:"global"})}
                                    </AnchorButton>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({crearAccionFormativa:false,recargandoFormativa:false})}}>
                                        {i18next.t("cancelar",{ns:"global"})}
                                    </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModal}
                            title={i18next.t("identificacion.selectriesgo",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalNuevoAbierto}
                            style={sty.dialog2}
                            >
                            <div className={Classes.DIALOG_BODY}>
                                <FormGroup
                                    label={i18next.t("identificacion.area",{ns:"page"})+":"}
                                    labelFor="nombre-area"
                                    intent="danger"
                                    helperText={this.state.areaSeleccionada?"":i18next.t("identificacion.emptyarea",{ns:"page"})}
                                >
                                    <div style={this.state.areaSeleccionada?{}:{border:"1px solid red"}}>
                                        <Select
                                            id="elegir-area"
                                            options={this.state.areasSelect}
                                            className="pixelAlrededor"
                                            onChange={seleccionaArea}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            value={this.state.areasSelect.filter(area => area.value === (this.state.areaSeleccionada?this.state.areaSeleccionada.value:0))}

                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("identificacion.departamento",{ns:"page"})+":"}
                                    labelFor="nombre-depto"
                                    intent="danger"
                                    helperText={this.state.deptoSeleccionado?"":i18next.t("identificacion.emptydepartamento",{ns:"page"})}
                                >
                                    <div style={this.state.deptoSeleccionado?{}:{border:"1px solid red"}}>
                                        <Select
                                            id="elegir-depto"
                                            options={this.state.deptosSelect}
                                            className="pixelAlrededor"
                                            onChange={seleccionaDepto}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            value={this.state.deptosSelect.filter(depto => depto.value === (this.state.deptoSeleccionado?this.state.deptoSeleccionado.value:0))}

                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("identificacion.bloque",{ns:"page"})+":"}
                                    labelFor="nombre-bloque"
                                    intent="danger"
                                    helperText={this.state.bloquePadreSeleccionado?"":i18next.t("identificacion.emptybloque",{ns:"page"})}
                                >
                                    <div style={this.state.bloquePadreSeleccionado?{}:{border:"1px solid red"}}>
                                        <Select
                                            id="elegir-bloque-padre"
                                            options={this.state.bloquesPadreSelect}
                                            className="pixelAlrededor"
                                            onChange={seleccionaBloquePadre}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            value={this.state.bloquesPadreSelect.filter(bloque => bloque.value === (this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.value:0))}

                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("identificacion.riesgo",{ns:"page"})+":"}
                                    labelFor="nombre-riesgo"
                                    intent="danger"
                                    helperText={this.state.bloqueHijoSeleccionado?"":i18next.t("identificacion.emptyriesgo",{ns:"page"})}
                                >
                                    <div style={this.state.bloqueHijoSeleccionado?{}:{border:"1px solid red"}}>
                                        <Select
                                            id="elegir-bloque-hijo"
                                            options={this.state.bloquesHijoSelect}
                                            className="pixelAlrededor"
                                            onChange={seleccionaBloqueHijo}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            value={this.state.bloquesHijoSelect.filter(bloque => bloque.value === (this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.value:0))}

                                        />
                                    </div>
                                </FormGroup>
                                
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("close",{ns:"global"})} position="top">
                                        <AnchorButton
                                            onClick={cerrarModal}
                                        >
                                            {i18next.t("close",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("identificacion.openficha",{ns:"page"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={abrirFicha}
                                        >
                                            {i18next.t("identificacion.openficha",{ns:"page"})}
                                        </AnchorButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarDialogoTextoRiesgo}
                            title={i18next.t("identificacion.inforiesgo",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.dialogTextoRiesgo}
                            className="dialog-ancho"
                            >
                            <div className={Classes.DIALOG_BODY}>
                                <TextArea fill={true} disabled value={this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.descripcion:""} className="textarea-alta" />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={cerrarDialogoTextoRiesgo}
                                >
                                    {i18next.t("close",{ns:"global"})}
                                </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title={i18next.t("identificacion.titledoc",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            isOpen={this.state.modalIdentificacionAbierto}
                            className="dialog-ancho dialog-scroll "
                        >
                            {this.state.cargandoPrintFicha===true?
                                <Cargando/>
                            :
                            <div>
                            <div ref={(input) => { this.ficha= input; }} className="pixelAlrededor">
                            <br/>                   
                                <Card interactive={true}  elevation={Elevation.TWO} className="pixelAlrededor">
                                    <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr >
                                                    <td rowSpan="2" style={{width:'80%',padding:'5px 5px 5px 5px'}}><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.identificacionvaloracion",{ns:"page"})}</Callout></td>
                                                    <td style={{width:'20%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.fechaFicha?moment(this.state.fechaFicha,"YYYY-MM-DD").format("DD-MM-YYYY"):moment().format("DD-MM-YYYY")}</span></td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'20%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.numero}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'15%'}}><span style={{fontWeight:'bold'}}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</span></td>
                                                    <td style={{width:'35%'}}><span style={{fontWeight:'normal'}}>{this.state.areaSeleccionada?this.state.areaSeleccionada.label:''}</span></td>
                                                    <td style={{width:'15%'}}><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</span></td>
                                                    <td style={{width:'35%'}}><span style={{fontWeight:'normal'}}>{this.state.deptoSeleccionado?this.state.deptoSeleccionado.label:''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td  colSpan="4" style={{textAlign:'center'}}><span style={{fontWeight:'bold'}}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</span> <span style={{fontWeight:'normal'}}>{this.state.bloquePadreSeleccionado?this.state.bloquePadreSeleccionado.titulo:""}</span></td>
                                                </tr>
                                                <tr>
                                                    <td  colSpan="4"><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.bloqueHijoSeleccionado?this.state.bloqueHijoSeleccionado.label:""} <Tooltip content={i18next.t("see",{ns:"global"})}  position={Position.TOP} ><Button icon="info-sign" onClick={()=>{abrirDialogoTextoRiesgo()}}/></Tooltip></Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.niveleintensidad",{ns:"page"})}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFichaCheck'>
                                            <tbody>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.probabilidad",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.menor",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.critico",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</span></td>
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.escasa",{ns:"page"})}</span></td>
                                                    {this.state.nivelRiesgo === 1 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(1)}>1</td> }
                                                    {this.state.nivelRiesgo === 6 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(6)}>6</td> }
                                                    {this.state.nivelRiesgo === 11 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(11)}>11</td> }
                                                    {this.state.nivelRiesgo === 16 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(16)}>16</td> }
                                                    {this.state.nivelRiesgo === 21 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(21)}>21</td> }
                                                    {this.state.nivelRiesgo === 26 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(26)}>26</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.baja",{ns:"page"})}</span></td>
                                                    {this.state.nivelRiesgo === 2 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(2)}>2</td> }
                                                    {this.state.nivelRiesgo === 7 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(7)}>7</td> }
                                                    {this.state.nivelRiesgo === 12 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(12)}>12</td> }
                                                    {this.state.nivelRiesgo === 17 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(17)}>17</td> }
                                                    {this.state.nivelRiesgo === 22 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(22)}>22</td> }
                                                    {this.state.nivelRiesgo === 27 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(27)}>27</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.posible",{ns:"page"})}</span></td>
                                                    {this.state.nivelRiesgo === 3 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(3)}>3</td> }
                                                    {this.state.nivelRiesgo === 8 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(8)}>8</td> }
                                                    {this.state.nivelRiesgo === 13 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(13)}>13</td> }
                                                    {this.state.nivelRiesgo === 18 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(18)}>18</td> }
                                                    {this.state.nivelRiesgo === 23 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(23)}>23</td> }
                                                    {this.state.nivelRiesgo === 28 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(28)}>28</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.alta",{ns:"page"})}</span></td>
                                                    {this.state.nivelRiesgo === 4 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(4)}>4</td> }
                                                    {this.state.nivelRiesgo === 9 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(9)}>9</td> }
                                                    {this.state.nivelRiesgo === 14 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(14)}>14</td> }
                                                    {this.state.nivelRiesgo === 19 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(19)}>19</td> }
                                                    {this.state.nivelRiesgo === 24 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(24)}>24</td> }
                                                    {this.state.nivelRiesgo === 29 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(29)}>29</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.muyalta",{ns:"page"})}</span></td>
                                                    {this.state.nivelRiesgo === 5 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickNivel(5)}>5</td> }
                                                    {this.state.nivelRiesgo === 10 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickNivel(10)}>10</td> }
                                                    {this.state.nivelRiesgo === 15 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(15)}>15</td> }
                                                    {this.state.nivelRiesgo === 20 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickNivel(20)}>20</td> }
                                                    {this.state.nivelRiesgo === 25 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(25)}>25</td> }
                                                    {this.state.nivelRiesgo === 30 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickNivel(30)}>30</td> }
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="celdaFondoVerde" >{i18next.t("identificacion.nivelbajo",{ns:"page"})}</td>
                                                    <td className="celdaFondoAmarillo" colSpan="2">{i18next.t("identificacion.nivelmedio",{ns:"page"})}</td>
                                                    <td className="celdaFondoNaranja" >{i18next.t("identificacion.nivelalto",{ns:"page"})}</td>
                                                    <td className="celdaFondoRojo" colSpan="2">{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='tableFicha'>
                                            <tbody>    
                                                <tr>
                                                    <td>
                                                        <Checkbox checked={this.state.riesgoHaSucedidoAntes} onChange={handleEnabledChange} label={i18next.t("identificacion.activarsisucedio",{ns:"page"})+":"} alignIndicator={Alignment.RIGHT} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tg-wrap" style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.consecuencias",{ns:"page"})}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFichaCheck'>
                                            <tbody>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.menor",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.critico",{ns:"page"})}</span></td>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</span></td>
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</span></td>
                                                    {this.state.nivelSancion === 1 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickSancion(1)}>1</td> }
                                                    {this.state.nivelSancion === 6 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSancion(6)}>6</td> }
                                                    {this.state.nivelSancion === 11 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSancion(11)}>11</td> }
                                                    {this.state.nivelSancion === 16 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickSancion(16)}>16</td> }
                                                    {this.state.nivelSancion === 21 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSancion(21)}>21</td> }
                                                    {this.state.nivelSancion === 26 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSancion(26)}>26</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</span></td>
                                                    {this.state.nivelPerdida === 2 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickPerdida(2)}>2</td> }
                                                    {this.state.nivelPerdida === 7 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickPerdida(7)}>7</td> }
                                                    {this.state.nivelPerdida === 12 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickPerdida(12)}>12</td> }
                                                    {this.state.nivelPerdida === 17 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickPerdida(17)}>17</td> }
                                                    {this.state.nivelPerdida === 22 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickPerdida(22)}>22</td> }                                            
                                                    {this.state.nivelPerdida === 27 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickPerdida(27)}>27</td> }

                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</span></td>
                                                    {this.state.nivelSuspension === 3 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickSuspension(3)}>3</td> }
                                                    {this.state.nivelSuspension === 8 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSuspension(8)}>8</td> }
                                                    {this.state.nivelSuspension === 13 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickSuspension(13)}>13</td> }
                                                    {this.state.nivelSuspension === 18 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickSuspension(18)}>18</td> }
                                                    {this.state.nivelSuspension === 23 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSuspension(23)}>23</td> }
                                                    {this.state.nivelSuspension === 28 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickSuspension(28)}>28</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</span></td>
                                                    {this.state.nivelReputacional === 4 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickReputacional(4)}>4</td> }
                                                    {this.state.nivelReputacional === 9 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickReputacional(9)}>9</td> }
                                                    {this.state.nivelReputacional === 14 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickReputacional(14)}>14</td> }
                                                    {this.state.nivelReputacional === 19 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickReputacional(19)}>19</td> }
                                                    {this.state.nivelReputacional === 24 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickReputacional(24)}>24</td> }
                                                    {this.state.nivelReputacional === 29 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickReputacional(29)}>29</td> }
                                                </tr>
                                                <tr>
                                                    <td ><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.otros",{ns:"page"})}</span></td>
                                                    {this.state.nivelOtros === 5 ?  <td className="celdaFondoVerde"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoVerde" onClick={()=>handleClickOtros(5)}>5</td> }
                                                    {this.state.nivelOtros === 10 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickOtros(10)}>10</td> }
                                                    {this.state.nivelOtros === 15 ?  <td className="celdaFondoAmarillo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoAmarillo" onClick={()=>handleClickOtros(15)}>15</td> }
                                                    {this.state.nivelOtros === 20 ?  <td className="celdaFondoNaranja"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoNaranja" onClick={()=>handleClickOtros(20)}>20</td> }
                                                    {this.state.nivelOtros === 25 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickOtros(25)}>25</td> }
                                                    {this.state.nivelOtros === 30 ?  <td className="celdaFondoRojo"><Icon icon="endorsed" style={{color: "#000"}} /></td> : <td className="celdaFondoRojo" onClick={()=>handleClickOtros(30)}>30</td> }
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="celdaFondoVerde" >{i18next.t("identificacion.nivelbajo",{ns:"page"})}</td>
                                                    <td className="celdaFondoAmarillo" colSpan="2">{i18next.t("identificacion.nivelmedio",{ns:"page"})}</td>
                                                    <td className="celdaFondoNaranja" >{i18next.t("identificacion.nivelalto",{ns:"page"})}</td>
                                                    <td className="celdaFondoRojo" colSpan="2">{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tg-wrap"  style={{borderBottomStyle:'solid',borderBottomColor:'black',borderBottomWidth:'1px',paddingBottom:'10px',marginBottom:'10px'}}>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.resultados",{ns:"page"})}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    {this.state.nivelRiesgo ===null?
                                                            <>
                                                                <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoVerde">{i18next.t("identificacion.bajo",{ns:"page"})}</Callout></td>
                                                                <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout className="bp3-calloutFicha" style={{background:"#bfccd6"}}>{this.state.nivelRiesgo}</Callout></td>
                                                            </>
                                                        :
                                                        this.state.nivelRiesgo < 8?
                                                            <>
                                                                <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoVerde">{i18next.t("identificacion.bajo",{ns:"page"})}</Callout></td>
                                                                <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoVerde">{this.state.nivelRiesgo}</Callout></td>
                                                            </>
                                                            :this.state.nivelRiesgo < 15?
                                                                <>
                                                                    <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoAmarillo">{i18next.t("identificacion.medio",{ns:"page"})}</Callout></td>
                                                                    <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoAmarillo">{this.state.nivelRiesgo}</Callout></td>
                                                                </>
                                                                :this.state.nivelRiesgo < 22?
                                                                    <>
                                                                        <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoNaranja">{i18next.t("identificacion.alto",{ns:"page"})}</Callout></td>
                                                                        <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout className="bp3-calloutFicha celdaFondoNaranja">{this.state.nivelRiesgo}</Callout></td>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <td>{i18next.t("identificacion.nivelriesgo",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoRojo">{i18next.t("identificacion.muyalto",{ns:"page"})}</Callout></td>
                                                                        <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout  className="bp3-calloutFicha celdaFondoRojo">{this.state.nivelRiesgo}</Callout></td>
                                                                    </>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>{i18next.t("identificacion.riesgocierto",{ns:"page"})}</td>
                                                    <td>{i18next.t("identificacion.intensidad",{ns:"page"})}: <Callout className={this.state.riesgoHaSucedidoAntes?(this.state.categoriaRiesgo<3?this.state.intentCategoriasRiesgo[this.state.categoriaRiesgo+1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo[this.state.categoriaRiesgo]+' bp3-calloutFicha'):this.state.intentCategoriasRiesgo[this.state.categoriaRiesgo]+' bp3-calloutFicha'} >{ this.state.riesgoHaSucedidoAntes?(this.state.categoriaRiesgo<3?this.state.categoriasRiesgo[this.state.categoriaRiesgo+1]:this.state.categoriasRiesgo[this.state.categoriaRiesgo]):this.state.categoriasRiesgo[this.state.categoriaRiesgo]}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.tipodanos",{ns:"page"})}:</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='tableFichaCheck'>
                                            <tbody>
                                                <tr>
                                                    <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</span></td>
                                                    <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</span></td>
                                                    <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</span></td>
                                                    <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</span></td>
                                                    <td><span style={{fontWeight:'bold'}}>{i18next.t("identificacion.otros",{ns:"page"})}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><Callout className={(Math.ceil(this.state.nivelSancion/5)-1)<=4?this.state.intentCategoriasRiesgo2[ Math.ceil(this.state.nivelSancion/5)-1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelSancion}</Callout> </td>
                                                    <td><Callout className={(Math.ceil(this.state.nivelPerdida/5)-1)<=4?this.state.intentCategoriasRiesgo2[ Math.ceil(this.state.nivelPerdida/5) -1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'}>{this.state.nivelPerdida}</Callout> </td>
                                                    <td><Callout className={(Math.round(this.state.nivelSuspension/5)-1)<=4?this.state.intentCategoriasRiesgo2[ Math.round(this.state.nivelSuspension/5)-1 ]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelSuspension}</Callout> </td>
                                                    <td><Callout className={(Math.round(this.state.nivelReputacional/5)-1)<=3?this.state.intentCategoriasRiesgo2[ Math.round(this.state.nivelReputacional/5)-1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelReputacional}</Callout> </td>
                                                    <td><Callout className={(Math.round(this.state.nivelOtros/5)-1)<=3?this.state.intentCategoriasRiesgo2[ Math.round(this.state.nivelOtros/5)-1]+' bp3-calloutFicha':this.state.intentCategoriasRiesgo2[4]+' bp3-calloutFicha'} >{this.state.nivelOtros}</Callout> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td >{i18next.t("identificacion.organo",{ns:"page"})}</td>
                                                </tr>
                                                <tr>
                                                    <td>          
                                                        {/* mismo código de recursos humanos */}
                                                        <FormGroup
                                                            label=""
                                                            labelFor="nombre-proyecto"
                                                        >
                                                            <Select  
                                                                isMulti
                                                                options={this.state.valoran}
                                                                className="pixelAlrededor"
                                                                onChange={seleccionValoran}
                                                                backspaceRemovesValue={true}
                                                                value={this.state.valoranSeleccionada}
                                                                isClearable={true}
                                                                onMenuOpen={ ()=> this.setState({brsEmpleados: true})}
                                                                onMenuClose={ ()=> this.setState({brsEmpleados: false})}
                                                            />
                                                            {
                                                                this.state.brsEmpleados === true?
                                                                <>
                                                                    {this.state.valoran.map((beErres) =>
                                                                        <br key={beErres} />
                                                                    )}
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="tg-wrap">
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td><Callout className="bp3-calloutFicha pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.recursosasignados",{ns:"page"})}</Callout></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <table className='tableFicha'>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:'15%'}}>{i18next.t("identificacion.personales",{ns:"page"})}</td>
                                                    <td style={{width:'85%'}} className="tdAbajo">
                                                        <Select  
                                                            isMulti
                                                            options={this.state.asignados}
                                                            className="pixelAlrededor"
                                                            onChange={seleccionAsignados}
                                                            backspaceRemovesValue={true}
                                                            isClearable={true}
                                                            value={this.state.asignadoSeleccionada}                                                        
                                                            classNamePrefix="selectArriba"
                                                            style={{fontSize:'12px'}}
                                                            onMenuOpen={ ()=> this.setState({brsPersonales: true})}
                                                            onMenuClose={ ()=> this.setState({brsPersonales: false})}
                                                        />
                                                        {
                                                            this.state.brsPersonales === true?
                                                            <>
                                                                {this.state.asignados.map((beErresAsignados) =>
                                                                    <br key={beErresAsignados} />
                                                                )}
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'15%'}}>{i18next.t("identificacion.financieros",{ns:"page"})}</td>
                                                    <td style={{width:'85%'}}><InputGroup style={{fontSize:'12px'}} value={this.state.recursosFinancieros?this.state.recursosFinancieros:''}  type="text"  onChange={handleRecursosFinancieros} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'15%'}}>{i18next.t("identificacion.delaorganizacion",{ns:"page"})}</td>
                                                    <td style={{width:'85%'}}>
                                                        <TextArea style={{fontSize:'12px'}} fill={true} value={this.state.recursosOrganizacion?this.state.recursosOrganizacion:''} onChange={handleRecursosOrganizacion}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'15%'}}>{i18next.t("identificacion.externos",{ns:"page"})}</td>
                                                    <td style={{width:'85%'}}>
                                                        <TextArea style={{fontSize:'12px'}} fill={true} value={this.state.recursosExternos?this.state.recursosExternos:''} onChange={handleRecursosExternos}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'15%'}}>{i18next.t("identificacion.periodicidad",{ns:"page"})}</td>
                                                    <td style={{width:'85%'}}>
                                                    <Periodicidad cerrarModal={this.cerrarPeriodicidad} periodicidad={this.state.periodicidad} setPeriodicidad={this.setPeriodicidad} abierto={this.state.modalPeriodicidad} ref={(input) => { this.Periodicidad= input; }} i18next={i18next}/>
                                                    <div style={!this.state.periodicidad?{border:"1px solid red"}:{}}>
                                                        <InputGroup
                                                                style={{fontSize:'12px'}}
                                                                placeholder=""
                                                                type="text"
                                                                large="true"
                                                                readOnly={true}
                                                                value={this.state.periodicidad?this.formatearPeriodicidad(this.state.periodicidad):null}
                                                                rightElement={<Button icon="time" onClick={this.abrirModalPeriodicidad} />}
                                                        />
                                                    </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:'15%'}}>{i18next.t("identificacion.observaciones",{ns:"page"})}</td>
                                                    <td style={{width:'85%'}}>
                                                        <FormGroup
                                                            intent="danger"
                                                            helperText={!this.state.observaciones?i18next.t("identificacion.emptyobservaciones",{ns:"page"}):""}
                                                        >
                                                            <TextArea style={{fontSize:'12px'}} fill={true} value={this.state.observaciones} intent={!this.state.observaciones?"danger":"primary"} onChange={handleCambiarObservaciones}/>
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                                <br />
                            </div>
                            <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]} >
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("print",{ns:"global"})} position="top">
                                        <Button intent={Intent.DANGER} icon="print" text={i18next.t("print",{ns:"global"})} onClick={this.imprimirFicha} />
                                    </Tooltip>
                                    {this.state.nuevoActivado?
                                    <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                        <AnchorButton
                                            icon="floppy-disk"  intent={Intent.DANGER}
                                            onClick={this.state.nuevoActivado?guardarCambios:actualizarCambios}
                                            disabled={this.state.bloqueHijoSeleccionado?false:true}
                                            className="bp3-minimal"
                                        >
                                        {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    :<></>}
                                </div>
                        
                            </div>
                            </div>
                            }
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModalRecomendaciones}
                            title={i18next.t("identificacion.recomendaciones",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalRecomendaciones}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <p>{i18next.t("identificacion.advertenciaprocedimiento",{ns:"page"})}</p>
                                {this.state.recargandoRecomendaciones===true?
                                    <Cargando/>
                                :
                                <BootstrapTable
                                    keyField='value'
                                    data={ this.state.recomendaciones }
                                    columns={ columnsRecomendaciones }
                                    selectRow={ selectRowRecomendaciones }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                }
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevaRecomendacion}>
                                        {i18next.t("identificacion.newprocedimiento",{ns:"page"})}
                                    </AnchorButton>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={anadirRecomendado}>
                                        {i18next.t("aceptar",{ns:"global"})}
                                    </AnchorButton>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModalRecomendaciones}>
                                        {i18next.t("close",{ns:"global"})}
                                    </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModalNuevaRecomendacion}
                            title={i18next.t("identificacion.createrecomendado",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalNuevaRecomendacion}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <FormGroup
                                label={i18next.t("identificacion.procedimientorecomendado",{ns:"page"})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nuevaRecomendacion?"":i18next.t("identificacion.emptyprocedimientorecomendado",{ns:"page"})}
                                >
                                    <InputGroup id="texto-nombre-recomendado" onChange={handleNuevaRecomendacion}  intent={this.state.nuevaRecomendacion?"primary":"danger"} />
                                </FormGroup>

                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={anadirProcedimientoRecomendado}
                                        >
                                            {i18next.t("aceptar",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModalNuevaRecomendacion}>{i18next.t("cancelar",{ns:"global"})}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModalImperativos}
                            title={i18next.t("identificacion.imperativas",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImperativos}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <p>{i18next.t("identificacion.advertenciaimperativo",{ns:"page"})}</p>
                                {this.state.recargandoImperativos===true?
                                    <Cargando/>
                                :
                                <BootstrapTable
                                    keyField='value'
                                    data={ this.state.imperativos }
                                    columns={ columnsImperativos }
                                    selectRow={ selectRowImperativos }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                }
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevoImpertativo}>
                                        {i18next.t("identificacion.newprocedimiento",{ns:"page"})}
                                    </AnchorButton>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={anadirImperativo}>
                                        {i18next.t("aceptar",{ns:"global"})}
                                    </AnchorButton>
                                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModalImperativos}>
                                        {i18next.t("close",{ns:"global"})}
                                    </AnchorButton>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModalNuevoImperativo}
                            title={i18next.t("identificacion.createimperativo",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalNuevoImperativo}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <FormGroup
                                label={i18next.t("identificacion.procedimientoimperativo",{ns:"page"})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nuevoImperativo?"":i18next.t("identificacion.emptyprocedimientoimperativo",{ns:"page"})}
                                >
                                    <InputGroup id="texto-nombre-imperativo" onChange={handleNuevoImperativo}  intent={this.state.nuevoImperativo?"primary":"danger"} />
                                </FormGroup>

                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={anadirProcedimientoImperativo}
                                        >
                                            {i18next.t("aceptar",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModalNuevoImperativo}>{i18next.t("cancelar",{ns:"global"})}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <br></br>
                        <div className="tablaSetenta separacionCentralTarjetas">
                            <Card interactive={true} elevation={Elevation.TWO}>
                            {this.state.recargando===true?
                                <Cargando/>
                            :
                            <>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.identificacionanalisis",{ns:"page"})}</Callout>
                                <Navbar>
                                    <Navbar.Group>
                                        <ButtonGroup align={Alignment.LEFT}>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})}  active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="eye-open" text={i18next.t("see",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} onClick={this.imprimirIdentificacionesGral} />
                                        </ButtonGroup>
                                    </Navbar.Group>
                                </Navbar>
                                <BootstrapTable
                                    keyField='ID_DOCUMENTO'
                                    data={ this.state.identificaciones }
                                    columns={ columns }
                                    selectRow={ selectRow }
                                    pagination={ paginationFactory(options) }
                                    filter={ filterFactory() }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            </>
                            }
                            </Card>
                            
                        </div>
                        <div className="tablaTreinta">
                            <Card interactive={true} elevation={Elevation.TWO}>
                            {this.state.recargandoSeguimiento===true?
                                <Cargando/>
                            :
                                <>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("identificacion.seguimiento",{ns:"page"})}</Callout>
                                <Navbar>
                                    <Navbar.Group>
                                        <ButtonGroup align={Alignment.LEFT}>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivadoSeguimiento} onClick={abrirModalNuevoSeguimiento} />
                                            <Button className="bp3-minimal" icon="eye-open" text={i18next.t("see",{ns:"global"})} active={this.state.editarActivadoSeguimiento} onClick={abrirModalEditarSeguimiento}/>
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} onClick={this.imprimirSeguimientoGral} />
                                        </ButtonGroup>
                                    </Navbar.Group>
                                </Navbar>
                                <BootstrapTable
                                    keyField='ID_DOCUMENTO'
                                    data={ this.state.seguimientos }
                                    columns={ columnsSeguimiento }
                                    selectRow={ selectRowSeguimiento }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                                </>
                            }
                            </Card>
                        </div>
                    
                    </>
                }
            </>
        )
    }
}

export default withTranslation()(withApollo(Identificacion));