import React from "react"
import { Card, Elevation } from "@blueprintjs/core"
import { DatePicker } from "@blueprintjs/datetime"
import "moment/locale/es"
import MomentLocaleUtils from 'react-day-picker/moment';

export default class VistaCalendarioReuniones extends React.Component {

    state = { selectedDate: new Date() };

    render(){
    
    const handleChange = (date) => {
        this.setState({ selectedDate: date });
        
    }

    const esDiaConvocatoria=(date)=>{
        let esdia=false
        if(this.props.diasConvocatorias){
            
            this.props.diasConvocatorias.forEach(dia=>{
                if(date.setHours(0)===dia.setHours(0))esdia= true
            })
        }

        return esdia

    }

    const modifiers = { esDiaConvocatoria };

        return(
            <>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <DatePicker minDate={new Date(1900, 1 ,1)} 
                        localeUtils={MomentLocaleUtils}
                        modifiers={modifiers}
                        onChange={(newDate) => handleChange(newDate)}
                        value={this.state.selectedDate}
                        locale={"es"}
                        className="centrar"
                    />
                </Card>
            </>
        )
    }
}