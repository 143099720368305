import React from "react"
import { FormGroup, Card, Alignment, TextArea, Switch, Elevation } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import const_table from "../BootstrapTable";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


class Desestimacion extends React.Component {

    state={
        desestimada: false,
        fechaDesestimacion: new Date(),
        fechaInformacion: new Date(),
        medioInformacionDesestimante: [
        ],
        motivoDesestimacion: null,
        medioInformacionDenuncianteSeleccionado:null,
    }

    reiniciarEstado() {
        this.setState({
            desestimada: false,
            fechaDesestimacion: new Date(),
            fechaInformacion: new Date(),
            motivoDesestimacion: null,
            medioInformacionDenuncianteSeleccionado:null,
        });
    }

    cargarMedios=async ()=>{

        //Medios informacion
        await client.query({
            query:gql`{tiposMediosContacto{ID_TIPO_MEDIO_CONTACTO,DESCRIPCION}}            `
        }).then(async(result)=>{
            //console.log(result)
            let medios=[]
            result.data.tiposMediosContacto.forEach(async(medio)=>{
             
                medios.push({"value":medio.ID_TIPO_MEDIO_CONTACTO,"label":medio.DESCRIPCION}) 

            })
            this.setState({medioInformacionDesestimante:medios})
        })

        if(this.props.datosDesestimada && Object.keys(this.props.datosDesestimada).length>0){

            this.setState({desestimada:this.props.datosDesestimada})
            this.setState({fechaDesestimacion:this.props.datosDesestimada?new Date(this.props.datosDesestimada.FECHA_DESESTIMACION):new Date()})
            this.setState({fechaInformacion:this.props.datosDesestimada?new Date(this.props.datosDesestimada.NOTIFICACION_FECHA):new Date()})
            this.setState({motivoDesestimacion:this.props.datosDesestimada?this.props.datosDesestimada.MOTIVO:null})

            //buscamos el medio .
            let medioSel=this.state.medioInformacionDesestimante.find(medio=>{
                return medio.value===this.props.datosDesestimada.NOTIFICACION_MEDIO 
            })

            this.setState({medioInformacionDenuncianteSeleccionado:medioSel}) 
        }
        else{
            this.setState({noexiste:true})
        }
    }

    componentDidMount=async()=>{

        await this.cargarMedios()

    }

    render(){


        const handleDenunciaDesestimada=(value)=>{
            this.setState({desestimada:!this.state.desestimada})
        }

        const handleCambiarFechaDesestimacion=(value)=>{
            this.setState({fechaDesestimacion:value})
        }

        const handleCambiarFechaInformacionDenunciante=(value)=>{
            this.setState({fechaInformacion:value})
        }

        const handleCambiarMedioInformacionDenunciante=(value)=>{
            this.setState({medioInformacionDenuncianteSeleccionado:value})
        }

        const handleCambiarMotivoDesestimacion=(ev)=>{
            this.setState({motivoDesestimacion:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        return (
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO} >
                        {(this.state.desestimada && (this.props.datosDesestimada && Object.keys(this.props.datosDesestimada).length >0 ))?
                            <Switch checked={this.state.desestimada} disabled label={i18next.t("denuncias.dardenunciadesestimada",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleDenunciaDesestimada} />
                        :
                            <Switch id="booleano-desestimada" checked={this.state.desestimada} label={i18next.t("denuncias.dardenunciadesestimada",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleDenunciaDesestimada} />
                        }        
                        <div id="datos-desestimacion">              
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.fechadesestimacion",{ns:"page"}).toLowerCase())}
                                labelFor="fecha-desestimacion"
                                disabled={!this.state.desestimada}
                                inline={true}
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={handleCambiarFechaDesestimacion}
                                    value={this.state.fechaDesestimacion}
                                    align={Alignment.RIGHT}
                                    disabled={!this.state.desestimada}
                            />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.fechanotificacion",{ns:"page"}).toLowerCase())}
                                labelFor="fecha-informacion-deseestimacion"
                                disabled={!this.state.desestimada}
                                inline={true}
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={handleCambiarFechaInformacionDenunciante}
                                    value={this.state.fechaInformacion}
                                    align={Alignment.RIGHT}
                                    disabled={!this.state.desestimada}
                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.medionotificacion",{ns:"page"}).toLowerCase())}
                                labelFor="medio-deseestimacion"
                                disabled={!this.state.desestimada}
                            >
                                <Select
                                    options={this.state.medioInformacionDesestimante}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={handleCambiarMedioInformacionDenunciante}
                                    value={this.state.medioInformacionDesestimante.filter(medio => medio.value === (this.state.medioInformacionDenuncianteSeleccionado?this.state.medioInformacionDenuncianteSeleccionado.value:0))}
                                    isDisabled={!this.state.desestimada}

                                />
                            </FormGroup>
                            <FormGroup
                                label={const_table.capitalize(i18next.t("denuncias.motivodesestimacion",{ns:"page"}).toLowerCase())}
                                labelFor="medio-deseestimacion"
                                disabled={!this.state.desestimada}
                                intent="danger"
                                helperText={this.state.motivoDesestimacion || !this.state.desestimada ?"":i18next.t("denuncias.emptymotivodesestimacion",{ns:"page"})}
                            >
                                <TextArea fill={true} disabled={!this.state.desestimada} value={this.state.motivoDesestimacion} disa intent={this.state.motivoDesestimacion || !this.state.desestimada ?"primary":"danger"} onChange={handleCambiarMotivoDesestimacion} />
                            </FormGroup>
                        </div>
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Desestimacion);