import React, { Component } from 'react';
import { Card, Elevation, Navbar, Alignment, Button, ButtonGroup, Dialog, FormGroup, Classes, Tooltip, AnchorButton, InputGroup } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality,SelectionModes,Regions } from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';

class GestionUsuarios extends Component {

    state={
        nuevoActivado: false,
        editarActivado: false,
        nombre:null,
        perfilSeleccionado:null,
        pendienteGuardar:false,
        pendienteEditar:false,
        usuarioSeleccionado:null,
        usuarios: [
    
        ],
        perfiles: [

        ],
        controlesDesactivados:false,
        regionSeleccionada:null
    }

    async cargarUsuarios(){

        this.setState({nombre:null})
        this.setState({perfilSeleccionado:null})

        await this.props.client
        .query({
          query: gql`
          {usuarios{ID_USUARIO,NOMBRE,PERFIL{ID_PERFIL,NOMBRE}}}
          `,fetchPolicy:'network-only'
        })
        .then(result =>{

            let usuarios=[]
            result.data.usuarios.forEach(usuario=>{

                usuarios.push({"id":usuario.ID_USUARIO,"nombre":usuario.NOMBRE,"perfil":usuario.PERFIL.NOMBRE,"idPerfil":usuario.PERFIL.ID_PERFIL})
            })
            this.setState({usuarios:usuarios})
          
        });

    }

    componentDidMount(props){


        this.props.client
        .query({
          query: gql`
          {perfiles{ID_PERFIL,NOMBRE}}
          `,fetchPolicy:'network-only'
        })
        .then(result =>{

            let perfiles=[]
            result.data.perfiles.forEach(perfil=>{

                perfiles.push({"value":perfil.ID_PERFIL,"label":perfil.NOMBRE})

            })

            this.setState({perfiles:perfiles})
          
        });

        this.cargarUsuarios()

    }

    render() {

        document.title ="LexControl | Perfiles"

        const abrirModalNuevo = () => {
            this.setState({
                modalAbierto:true,
                nuevoActivado: true,
                editarActivado: false,
                nombre:null,
                perfilSeleccionado:null
            })
        }

        const abrirModalEditar = () => {

            if(!this.state.usuarioSeleccionado){
                alert("Debe seleccionar un usuario")
                return
            }
            let perfilSel=this.state.perfiles.find(perfil=>{return perfil.value===this.state.usuarioSeleccionado.idPerfil})
            this.setState({
                modalAbierto:true,
                nombre:this.state.usuarioSeleccionado.nombre,
                editarActivado: true,
                nuevoActivado: false,
                perfilSeleccionado:perfilSel
            })
        }

        const handleCambiarNombre=(ev)=>{
            this.setState({nombre:ev.currentTarget.value})
        }

        const handleCambiarPerfil=(value)=>{
            this.setState({perfilSeleccionado:value})
        }

        const cellRendererNombre = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}}>{this.state.usuarios[rowIndex].nombre}</Cell>
        }

        const cellRendererPerfil = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}}>{this.state.usuarios[rowIndex].perfil}</Cell>
        }

        const cerrarModal = () => {
            this.setState({
               modalAbierto:false
            })
        }

        const añadirNuevo = () => {

            if(!this.state.nombre){
                return
            }

            if(!this.state.perfilSeleccionado){
                return
            }   

            //comprobar que no exista un usuario con ese nombre
            let existe=this.state.usuarios.find(usuario=>{return usuario.nombre===this.state.nombre})
            if(existe){
                alert("Ya existe un usuario con este nombre")
                return
            }

            let usuarios=this.state.usuarios
            usuarios.push({"nombre":this.state.nombre,"perfil":this.state.perfilSeleccionado.label})
            this.setState({usuarios:usuarios})
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            this.setState({regionSeleccionada:null})
            this.tablaUsuarios.clearSelection()

            cerrarModal()
            
        }

        const actualizarUsuario=()=>{

            if(!this.state.nombre){
                return
            }

            if(!this.state.perfilSeleccionado){
                return
            }   

            //comprobar que no exista un usuario con ese nombre
            let existe=this.state.usuarios.find(usuario=>{return usuario.nombre===this.state.nombre && usuario.id!==this.state.usuarioSeleccionado.id})
            if(existe){
                alert("Ya existe un usuario con este nombre")
                return
            }
 

            let usuarios=this.state.usuarios
            this.state.usuarios.find((usuario,index) =>{
                if(usuario.id===this.state.usuarioSeleccionado.id){
                    usuarios[index]["nombre"]=this.state.nombre?this.state.nombre:this.state.usuarioSeleccionado.nombre
                    usuarios[index]["perfil"]=this.state.perfilSeleccionado?this.state.perfilSeleccionado.label:this.state.usuarioSeleccionado.perfil
                    this.setState({usuarios:usuarios})
                }
                return usuario.id===this.state.usuarioSeleccionado.id
            })

            this.setState({pendienteEditar:true})
            this.setState({controlesDesactivados:true})
            this.setState({regionSeleccionada:null})
            this.tablaUsuarios.clearSelection()

            cerrarModal()

        }

        const handleSeleccionarUsuario=(value)=>{

            if(value.length===0)return

            let seleccionada=this.state.usuarios[value[0].rows[0]]                
            this.setState({usuarioSeleccionado:seleccionada})
        }

        const guardarCambios=()=>{

            if(this.state.pendienteGuardar){

                this.props.client
                .mutate({
                mutation: gql`
                        mutation{crearUsuario(VALORES:{NOMBRE:"${this.state.nombre}",CONTRASENA:"lexcontrol",ID_PERFIL:${this.state.perfilSeleccionado.value}}){ID_USUARIO}}
                `
                })
                .then(async result => {
                  
                    alert("El registro se ha insertado correctamente.")
                    await this.cargarUsuarios()

                    let filaCreada=this.state.usuarios.find((usuario,index)=>{

                        if(usuario.id===result.data.crearUsuario.ID_USUARIO){
                            this.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                        }
                        return usuario.id===result.data.crearUsuario.ID_USUARIO

                    })

                    this.setState({
                        pendienteGuardar:false,
                        controlesDesactivados:false,
                        usuarioSeleccionado:filaCreada
                    })


                }).catch(err=>{
                    alert("Ha ocurrido un error al crear el usuario.Pongase en contacto con el soporte tecnico")
                })
            }
            if(this.state.pendienteEditar){

                this.props.client
                .mutate({
                mutation: gql`
                        mutation{actualizarUsuario(ID_USUARIO:${this.state.usuarioSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre?this.state.nombre:this.state.usuarioSeleccionado.nombre}",ID_PERFIL:${this.state.perfilSeleccionado?this.state.perfilSeleccionado.value:this.state.usuarioSeleccionado.idPerfil}}){ID_USUARIO}}
                `
                })
                .then(async result => {
                   
                    alert("El registro se ha actualizado correctamente.")
                    this.cargarUsuarios()
                    
                    await this.cargarUsuarios()

                    let filaCreada=this.state.usuarios.find((usuario,index)=>{

                        if(usuario.id===this.state.usuarioSeleccionado.id){
                            this.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                        }
                        return usuario.id===this.state.usuarioSeleccionado.id

                    })
                    this.setState({
                        pendienteEditar:false,
                        controlesDesactivados:false,
                        usuarioSeleccionado:filaCreada
                    })
                    
                }).catch(err=>{
                    alert("Ha ocurrido un error al actualizar el usuario.Pongase en contacto con el soporte tecnico")
                })
            }

        }

        const cancelarCambios=()=>{
            this.setState({
                pendienteEditar:false,
                pendienteGuardar:false,
                controlesDesactivados:false,
                nombre:null,
                perfilSeleccionado:null,
                usuarioSeleccionado:null,
                regionSeleccionada:null
            },()=>{this.tablaUsuarios.clearSelection()})
            this.cargarUsuarios()
        }

        return (
            <>
                <div className="pixelAlrededor">
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title="Propiedades del usuario"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                        className="dialogo-denuncias"
                    >
                        <FormGroup
                            label="Nombre de usuario:"
                            labelFor="nombre-usuario"
                            intent="danger"
                            helperText={!this.state.nombre?"Debe rellenar el nombre de usuario":""}
                        >
                            <InputGroup onChange={handleCambiarNombre} intent={!this.state.nombre?"danger":"primary"} fill={true} value={this.state.nombre} />
                        </FormGroup>
                        <FormGroup
                            label="Perfil:"
                            labelFor="nombre-usuario"
                            intent="danger"
                            helperText={!this.state.perfilSeleccionado?"Debe seleccionar un perfil para el usuario":""}
                        >
                            <div style={!this.state.perfilSeleccionado?{border:"1px solid red"}:{}}>
                                <Select
                                    options={this.state.perfiles}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    value={this.state.perfilSeleccionado}
                                    onChange={handleCambiarPerfil}
                                />
                            </div>
                        </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?"Añadir usuario.":"Editar Usuario"} position="top">
                                <AnchorButton
                                    intent="primary"
                                    onClick={this.state.nuevoActivado?añadirNuevo:actualizarUsuario}
                                >
                                    {this.state.nuevoActivado?"Añadir usuario":"Editar Usuario"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    
                    <Card interactive={true} elevation={Elevation.TWO} >
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} disabled={this.state.controlesDesactivados} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado}  disabled={this.state.controlesDesactivados} onClick={abrirModalEditar} />
                                </ButtonGroup>
                            </Navbar.Group>
                            <Navbar.Group align={Alignment.RIGHT}>
                                <Button 
                                    alignText="right"
                                    icon="floppy-disk"
                                    text="Guardar"
                                    onClick={guardarCambios}
                                    className="bp3-intent-danger iconoDerecha bp3-minimal"
                                    disabled={!this.state.controlesDesactivados}
                                />
                                 <Button 
                                    alignText="right"
                                    icon="cross"
                                    text="Cancelar"
                                    onClick={cancelarCambios}
                                    className="bp3-intent-danger iconoDerecha bp3-minimal"
                                    disabled={!this.state.controlesDesactivados}
                                />
                            </Navbar.Group>
                        </Navbar>
                        <Table enableMultipleSelection={false} selectionModes={!this.state.controlesDesactivados?[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]:SelectionModes.NONE} ref={(input) => { this.tablaUsuarios= input; }} selectedRegions={this.state.regionSeleccionada} onSelection={handleSeleccionarUsuario} defaultColumnWidth={500} numRows={this.state.usuarios.length}>
                            <Column name="Nombre del usuario" cellRenderer={cellRendererNombre}/>   
                            <Column name="Perfil de acceso" cellRenderer={cellRendererPerfil}/>   
                        </Table>
                    </Card>
                </div>
            </>
        )
    }
}

export default withApollo(GestionUsuarios)