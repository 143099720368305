import React, { Component } from 'react';
import {Card, Elevation, Spinner } from "@blueprintjs/core"
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';

class ObligacionesEmitidas extends Component {
 
    state={ 
            idPersona:null,
            contenidoEstrategicasHtml:null,
            editroEstrategicasHtml:null,
            editorState: 
            {
                options: ['inline', 'fontFamily', 'list', 'textAlign','link', 'embedded', 'image', 'remove', 'history']
            },
            toolbarState: 
            {
                options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'history'],
                inline: {
                    options: ['bold', 'italic', 'underline'],
                },
            },
            onContentStateChangeEstrategicas:"",
        }
    

    onEditorStateChangeEstrategicas = (editorState) => {
        this.setState({
            editorState,
        });

    };
    

    onContentStateChangeEstrategicas = (contentState) => {
        this.setState({
          contentState,
        });
        this.setState({contenidoEstrategicasHtml:'"'+draftToHtml(contentState).replace(/(?:\r\n|\r|\n)/g,"").replace(/"/g, "'")+'"'})
        console.log('"'+draftToHtml(contentState).replace(/(?:\r\n|\r|\n)/g,"").replace(/"/g, "'")+'"')
    };



    componentWillMount(props){

        
      let html=""
      if(this.props.Observaciones){
        html=this.props.Observaciones
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            let editroEstrategicasHtml = EditorState.createWithContent(contentState);
            this.setState({editroEstrategicasHtml:editroEstrategicasHtml})
        }
      }

    }

    render() {


      
      return (
          <>
        {this.props.loading && <Spinner/>}      
        <div className="pixelAlrededor">
        <Card interactive={true} elevation={Elevation.TWO} className="tarjetaAlturaCien">
                <Editor 
                    defaultEditorState={this.state.editroEstrategicasHtml}
                    wrapperClassName="editor-menu bp3-card bp3-elevation-2"
                    editorClassName="editor-texto bp3-card bp3-elevation-2"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={this.state.toolbarState}
                    onContentStateChange={this.onContentStateChangeEstrategicas}
                    initialContentState={this.contenidoEstrategicasHtml}
                />
            <br />
            <br />
            <br />
        </Card>
        </div>
        <div className="tablaMitad pixelAlrededor">
        </div>
    </>
      )
    }
}

export default ObligacionesEmitidas