/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { Component } from 'react';
import {Elevation,Card,Checkbox,Callout, Button, Dialog, Spinner } from "@blueprintjs/core"
import { Cargando } from "../Pantallas/Cargando"
import ComiteCompliance from "../Pantallas/Elementos/ComiteCompliance"
import ProfesionalesExternos from "../Pantallas/Elementos/ProfesionalesExternos"
import DelegadosAutorizados from "../Pantallas/Elementos/DelegadosAutorizados"
import ActasNombramiento from "../Pantallas/Elementos/ActasNombramiento"
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost'
import { Regions} from "@blueprintjs/table"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });


let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    width: '70%'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '20%'
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});


export default class OrganoAdministracion extends Component {

    state = {
        pestaña: "comite-compliance",
        animacion: true,
        desactivado: false,
        profesionalesExternos:[],
        profesionalesExternosEliminados:[],
        delegadosAutorizados:[],
        delegadosAutorizadosEliminados:[],
        comiteCompliance:[],
        comiteComplianceEliminados:[],
        adjuntos:[],
        existeOrgano:false,
        miembrosOrganizacion:null,
        cargandoImprimir:false,
        modalImprimir:false,
        datosEmpresa:null,
        logotipo:null,
        comiteCompliancePrint: [
        ],
        
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });

    handleEnabledChange = async() =>{

        
        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert("No tienes permisos para realizar esta acción")
            return
        }

        /*
        let hayMiembros=false
        await client
        .query({
        query: gql`
                 {miembrosOrganizacion{ID_PERSONA}}

        `,fetchPolicy:'network-only'
        })
        .then(result =>{
            //si no existe en la tabla lo insertamos.
            if(result.data.miembrosOrganizacion.length>0){

                hayMiembros=true

            }
        
        });*/
            

        this.setState({ desactivado: !this.state.desactivado })
        /*
        if(this.state.desactivado){

            if(this.state.comiteCompliance.length>0 || hayMiembros){
                this.setState({
                    modalAdvertenciaAbierto: true,
                })
            }
        }*/

    }

    miembros= async () =>{
        let miembros = await client
        .query({
        query: gql`
            {miembrosOrganizacion{ID_PERSONA}}
        `,fetchPolicy:'network-only'
        })
        .then(result => {
            return result.data.miembrosOrganizacion
        })

        this.setState({miembrosOrganizacion:miembros})
       
    }       

    crearComites=async (comite)=>{
        let existe= false;
        this.state.miembrosOganizacion.find(miembro=>{
            if(miembro.ID_PERSONA===comite.value){
                 existe = true
            }else{
                 client
                .mutate({
                    mutation:gql`mutation{crearMiembroOrganizacion(VALORES:{ID_PERSONA:${comite.value}}){ID_PERSONA}}`
                }).then(async result=>{

                    alert("El registro se ha insertado correctamente.")
                    //recargamos los miembros del comite.
                    await this.comite.cargarComite()

                    //seleccionamos el nuevo registro.
                    let idCreada=result.data.crearMiembroOrganizacion.ID_PERSONA
                    let filaCreada=this.comite.state.comiteCompliance.find((comite,index)=>{
                        if(comite.ID_EMPLEADO===idCreada){
                            this.comite.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                        }
                        return comite.ID_EMPLEADO===idCreada

                    })
                    this.comite.setState({miembroSeleccionado:filaCreada})
                    
                }).catch(err=>{
                    alert("Ha ocurrido un error al crear el miembro.Pongase en contacto con el soporte técnico")
                })
            }
        })

        // await client
        //     .query({
        //     query: gql`
        //         {miembrosOrganizacion(FILTROS:{ID_PERSONA:${comite.value}}){ID_PERSONA}}
        //     `,fetchPolicy:'network-only'
        //     })
        //     .then(result =>{
        //         //si no existe en la tabla lo insertamos.
        //         if(result.data.miembrosOrganizacion.length===0){

        //             client.mutate({
        //                 mutation:gql`mutation{crearMiembroOrganizacion(VALORES:{ID_PERSONA:${comite.value}}){ID_PERSONA}}`
        //             }).then(async result=>{

        //                 alert("El registro se ha insertado correctamente.")
        //                 //recargamos los miembros del comite.
        //                 await this.comite.cargarComite()

        //                 //seleccionamos el nuevo registro.
        //                 let idCreada=result.data.crearMiembroOrganizacion.ID_PERSONA
        //                 let filaCreada=this.comite.state.comiteCompliance.find((comite,index)=>{
        //                     if(comite.ID_EMPLEADO===idCreada){
        //                         this.comite.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
        //                     }
        //                     return comite.ID_EMPLEADO===idCreada

        //                 })
        //                 this.comite.setState({miembroSeleccionado:filaCreada})
                        
        //             }).catch(err=>{
        //                 alert("Ha ocurrido un error al crear el miembro.Pongase en contacto con el soporte técnico")
        //             })

        //         }
            
        //     });
    }

    guardarCambios = async() =>{ 

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert("No tienes permisos para realizar esta acción")
            return
        }

        //actualizamos asumido.
        if(this.state.existeOrgano){
            client.mutate({
                mutation:gql`mutation{actualizarOrganoCompliance(VALORES:{ASUMIDO:${this.state.desactivado}}){ASUMIDO}}`
            })

        }else{
            client.mutate({
                mutation:gql`mutation{crearOrganoCompliance(VALORES:{ASUMIDO:${this.state.desactivado}}){ASUMIDO}}`
            })
            this.setState({existeOrgano:true})
        } 


        if(this.state.desactivado){
            this.state.miembrosOrganizacion.forEach(miembro=>{
                client.mutate({
                    mutation:gql`mutation{eliminarMiembroOrganizacion(ID_PERSONA:${miembro.ID_PERSONA}){ID_PERSONA}}`
                 })
            })
        //     client
        //     .query({
        //     query: gql`
        //             {miembrosOrganizacion{ID_PERSONA}}
        //    `,fetchPolicy:'network-only'
        //    })
        //    .then(result =>{
        //         //si no existe en la tabla lo insertamos.
        //         result.data.miembrosOrganizacion.forEach(miembro=>{

        //             client.mutate({
        //                mutation:gql`mutation{eliminarMiembroOrganizacion(ID_PERSONA:${miembro.ID_PERSONA}){ID_PERSONA}}`
        //             })

        //         })
   
        //    });

        }else{

            for(let comite of this.state.comiteCompliance){
                        
                this.crearComites(comite)
           
            }
            
        }

        this.setState({comiteCompliance:[]})

        //ELIMINADO COMITE COMPLIANCE
        this.state.comiteComplianceEliminados.forEach(async comite=>{

            //console.log(this.state.delegadosAutorizadosEliminados)
            await client
            .mutate({
            mutation: gql`
                mutation{eliminarMiembroOrganizacion(ID_PERSONA:${comite.value}){ID_PERSONA}}
            `
            })
            .then(result =>{
                alert("El registro se ha eliminado correctamente.")

                this.comite.setState({
                    miembroSeleccionado:null,
                    regionSeleccionada:null
                },()=>{this.comite.tablaComites.clearSelection()})


            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar el miembro.Pongase en contacto con el soporte técnico")
            })

        })
        this.setState({comiteComplianceEliminados:[]})
        this.comite.limpiarEliminados()

        this.state.profesionalesExternos.forEach(async profesional=>{
            
            await client
            .query({
            query: gql`
                     {ProfesionalesExternos(FILTROS:{ID_PERSONA:${profesional.value}}){ID_PERSONA}}

            `,fetchPolicy:'network-only'
            })
            .then(async result =>{


                //si no existe en la tabla lo insertamos.
                if(result.data.ProfesionalesExternos.length===0){

                   await client.mutate({
                        mutation:gql`mutation{crearProfesionalExterno(VALORES:{ID_PERSONA:${profesional.value}}){ID_PERSONA}}`
                    }).then(async result=>{
                      
                        alert("El registro se ha insertado correctamente.")
                        await this.profesionales.cargarPersonas()

                        //seleccionamos el nuevo registro.
                        let idCreada=result.data.crearProfesionalExterno.ID_PERSONA
                        let filaCreada=this.profesionales.state.organoProfesionalesExternos.find((profesional,index)=>{
                            if(profesional.ID_PERSONA===idCreada){
                                this.profesionales.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                            }
                            return profesional.ID_PERSONA===idCreada

                        })
                        this.profesionales.setState({profesionalesExternoSeleccionado:filaCreada})
                          
                        
                    }).catch(err=>{
                        console.log(err)
                        alert("Ha ocurrido un error al crear el profesional.Pongase en contacto con el soporte técnico")
                    })

                }
            
            });
                
        })
        this.setState({profesionalesExternos:[]})

        //eliminando los profesionales externos
        this.state.profesionalesExternosEliminados.forEach(async profesional=>{

            
            await client
            .mutate({
            mutation: gql`
                mutation{eliminarProfesionalExterno(ID_PERSONA:${profesional.value}){ID_PERSONA}}
            `
            })
            .then(result =>{
           
                alert("El registro se ha eliminado correctamente.")

                this.profesionales.setState({
                    profesionalesExternoSeleccionado:null,
                    regionSeleccionada:null
                },()=>{this.profesionales.tablaProfesionales.clearSelection()})

            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar el delegado.Pongase en contacto con el soporte técnico")
            })
        
        })
      
        this.setState({profesionalesExternosEliminados:[]})
        this.profesionales.limpiarEliminados()

        this.state.delegadosAutorizados.forEach(async delegado=>{
            
            await client
            .query({
            query: gql`
                     {DelegadosAutorizados(FILTROS:{ID_PERSONA:${delegado.value}}){ID_PERSONA}}

            `,fetchPolicy:'network-only'
            })
            .then(async result =>{
               
                //si no existe en la tabla lo insertamos.
                if(result.data.DelegadosAutorizados.length===0){
                    await  client.mutate({
                        mutation:gql`mutation{crearDelegadoAutorizado(VALORES:{ID_PERSONA:${delegado.value}}){ID_PERSONA}}`
                    }).then(async result=>{
                      
                        alert("El registro se ha insertado correctamente.")
                        await this.delegados.cargarEmpleados()

                        //seleccionamos el nuevo registro.
                        let idCreada=result.data.crearDelegadoAutorizado.ID_PERSONA

                        let filaCreada=this.delegados.state.delegadosAutorizados.find((delegado,index)=>{
                            if(delegado.value===idCreada){
                                this.delegados.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                            }
                            return delegado.value===idCreada

                        })
                        this.delegados.setState({delegadoSeleccionado:filaCreada})
                        
                    }).catch(err=>{
                        alert("Ha ocurrido un error al crear el delegado.Pongase en contacto con el soporte técnico")
                    })

                }
            
            });
                
        })
        this.setState({delegadosAutorizados:[]})

        //eliminando los profesionales externos
        this.state.delegadosAutorizadosEliminados.forEach(async delegado=>{

            await client
            .mutate({
            mutation: gql`
                mutation{eliminarDelegadoAutorizado(ID_PERSONA:${delegado.value}){ID_PERSONA}}
            `
            })
            .then(result =>{
              
                alert("El registro se ha eliminado correctamente.")

                
                this.delegados.setState({
                    delegadoSeleccionado:null,
                    regionSeleccionada:null
                },()=>{this.delegados.tablaDelegados.clearSelection()})

            }).catch(err=>{
                alert("Ha ocurrido un error al crear el miembro.Pongase en contacto con el soporte técnico")
            })

        })
        this.setState({delegadosAutorizadosEliminados:[]})
        this.delegados.limpiarEliminados()

        
     }

    cancelarCambios=()=>{

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert("No tienes permisos para realizar esta acción")
            return
        }

        client
        .query({
        query: gql`
                 {organosCompliance{ASUMIDO}}

        `,fetchPolicy:'network-only'
        })
        .then(result =>{
            //si no existe en la tabla lo insertamos.
            if(result.data.organosCompliance.length>0){

                this.setState({
                    desactivado:result.data.organosCompliance[0].ASUMIDO,
                })

            }
        
        });

        this.delegados.reiniciarEstado()
        this.comite.reiniciarEstado()
        this.profesionales.reiniciarEstado()

    }

    setProfesionalesExternos = (value) => this.setState({ profesionalesExternos:value })

    setProfesionalesExternosEliminados = (value) => this.setState({ profesionalesExternosEliminados:value })

    setDelegadosAutorizados  = (value) => this.setState({ delegadosAutorizados:value })

    setAdjuntos  = (value) => this.setState({ adjuntos:value })

    setDelegadosAutorizadosEliminados  = (value) => this.setState({ delegadosAutorizadosEliminados:value })

    setComiteCompliance  = (value) => this.setState({ comiteCompliance:value })

    setComiteComplianceEliminados  = (value) => this.setState({ comiteComplianceEliminados:value })
    cargarOrgano= async () => {
        //comprobar si existe el organo compliance
        await client
        .query({
        query: gql`
                 {organosCompliance{ASUMIDO}}

        `,fetchPolicy:'network-only'
        })
        .then(result =>{
            //si no existe en la tabla lo insertamos.
            if(result.data.organosCompliance.length>0){

                this.setState({
                    desactivado:result.data.organosCompliance[0].ASUMIDO,
                    existeOrgano:true
                })

            }
        
        });
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await client 
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
 
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
 
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
 
             }
         })
     
     }
     cargarEmpresa= async() => {
         
         //DATOS JURIDICOS
         let datosEmpresa= await client
         .query({
             query: gql`
             {
                 personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
             }
             `,
             fetchPolicy: 'network-only'
 
         }).then(result=>{
             //si tenemos razon social.
             if(result.data.personasJuridicas.length>0){
                 return {
                     "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                  }
 
             }
             
         })
 
        return datosEmpresa
    
    }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    generarImprimir= async() => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            if (this.comite.state.comiteCompliance.length===0) {
                await this.comite.cargarComite()
            }
            if (this.delegados.state.delegadosAutorizados.length===0) {
                await this.delegados.cargarEmpleados()
            }
            if (this.profesionales.state.profesionalesExternos.length===0) {
                await this.profesionales.cargarPersonas()
            }
            if (this.actas.state.adjuntos.length===0) {
                await this.actas.recargarAdjuntos()
            }
            
            this.setState({cargandoImprimir:false}, () => {
                 ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
             })
        })
    }

    
    componentDidMount(){
        this.setState({
            cargandoOC:true}, async () =>{
                await this.miembros()
                await this.cargarOrgano()
                await this.obtenerLogoEmpresa()
                await this.cargarDatos()
                this.setState({
                cargandoOC:false
                })
            })
       
    }

    Imprimir=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ÓRGANO COMPLIANCE</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>ÓRGANO COMPLIANCE</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>¿El órgano de compliance es asumido por el órgano de administración?:</Text>
                    <Text style={styles.textData}>{this.state.desactivado?'SI':'NO'}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>COMITÉ DE COMPLIANCE</Text>
                </View>
                {this.comite.state.comiteCompliance?this.comite.state.comiteCompliance.map((comite, index)=>{
                    return(
                        <View key={index}>
                           {index ===0 && (
                               <View style={styles.colHead}>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>NOMBRE</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>PUESTO DE TRABJO</Text>                                                                
                         </View>
                           )}
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%',height:20}}>{comite.nombre?comite.nombre:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%',height:20}}>{comite.puesto?comite.puesto:''}</Text>
                            </View>                               
                        
                    </View>
                    )
                }):<></>}      
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>DELEGADOS AUTORIZADOS</Text>
                </View>
                {this.delegados.state.delegadosAutorizados?this.delegados.state.delegadosAutorizados.map((delegado, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>NOMBRE</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>PUESTO DE TRABJO</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{delegado.nombre?delegado.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{delegado.puesto?delegado.puesto:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>PROFESIONALES EXTERNOS</Text>
                </View>
                {this.profesionales.state.profesionalesExternos?this.profesionales.state.profesionalesExternos.map((profesional, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'100%'}}>NOMBRE</Text>
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'100%'}}>{profesional.label?profesional.label:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}  
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>ACTAS DE NOMBRAMIENTO</Text>
                </View>
                {this.actas.state.adjuntos?this.actas.state.adjuntos.map((adjunto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>NOMBRE</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>TIPO</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>TIPO DE ARCHIVO</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{adjunto.nombre?adjunto.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{adjunto.tipo?adjunto.tipo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{adjunto.mime?adjunto.mime:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
       )

    render() {

        document.title ="LexControl | Organo compliance"
        
        /*
        const cerrarModal = () => {
            this.setState({
                modalAdvertenciaAbierto: false,
            })
        }

        const eliminarComites=()=>{
           cerrarModal()
           this.comite.limpiarLista()
       }*/

       const cerrarModalImprimir= () =>{
        this.setState({
            modalImprimir:false
        })
    }
        return (
            <>
                {this.state.cargandoOC===true?
                    <Cargando/>
                :
                <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de Órgano de compliance"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generar-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                 {/*<Dialog
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title="Asumir"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAdvertenciaAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <p>Si el órgano compliance es asumido por el órgano de administración, se eliminarán todas las personas asociadas de la lista Comite de Compliance</p>
                            <br></br>
                            <p>¿Desea continuar?</p>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar delegado." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarComites}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                 </Dialog>*/}
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                    <Checkbox checked={this.state.desactivado} large={true} onChange={this.handleEnabledChange} label="¿El órgano de compliance es asumido por el órgano de administración?" />
                </Callout>


                <div className="tablaMitadIzquierda pixelAlrededorGrande">
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="comite-compliance">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Comité de compliance</Callout>
                        <ComiteCompliance setComiteCompliance={this.setComiteCompliance} editable={this.props.editable}  ref={(input) => { this.comite= input; }} setComiteComplianceEliminados={this.setComiteComplianceEliminados}  />
                    </Card>
                    <br />
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="profesionales-externos">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Profesionales externos</Callout>
                        <ProfesionalesExternos ref={(input) => { this.profesionales= input; }} editable={this.props.editable}  setProfesionalesExternosEliminados={this.setProfesionalesExternosEliminados} setProfesionalesExternos={this.setProfesionalesExternos}  />
                    </Card>
                </div>
                <div className="tablaMitadDerecha pixelAlrededorGrande">
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="delegados-autorizados">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Delegados Autorizados</Callout>
                        <DelegadosAutorizados setDelegadosAutorizados={this.setDelegadosAutorizados} editable={this.props.editable} ref={(input) => { this.delegados= input; }} setDelegadosAutorizadosEliminados ={this.setDelegadosAutorizadosEliminados} />
                    </Card>
                    <br />
                    <Card className="pixelAlrededor" interactive={true} elevation={Elevation.TWO} id="Actas de nombramiento">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Actas de nombramiento</Callout>
                        <ActasNombramiento setAdjuntos={this.setAdjuntos} ref={(input) => { this.actas= input; }} editable={this.props.editable}  />
                    </Card>
                </div>
                <Button 
                    alignText="right"
                    icon="print"
                    text="Imprimir"
                    onClick={this.generarImprimir}
                    className="bp3-intent-danger bp3-minimal iconoDerecha"
                />

                <Button 
                    alignText="right"
                    icon="floppy-disk"
                    text="Guardar"
                    onClick={this.guardarCambios}
                    className="bp3-intent-danger bp3-minimal iconoDerecha"
                />
                 <Button 
                    alignText="right"
                    icon="cross"
                    text="Cancelar"
                    onClick={this.cancelarCambios}
                    className="bp3-intent-danger bp3-minimal iconoDerecha"
                />

                
                
            </>
           }
        </> 
        )
    }
}