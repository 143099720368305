import React, {Component} from 'react';
import { 
    Card, 
    Callout, 
    Elevation,
    Navbar,
    ButtonGroup,
    Button,
    Alignment,
    Dialog,
    Classes,
    FormGroup,
    InputGroup,
    TextArea,
    Tooltip,
    AnchorButton,
    Intent,
    Spinner
} from "@blueprintjs/core";
import {withApollo} from 'react-apollo';
import moment from 'moment';
import { Cargando } from "../Pantallas/Cargando";
import gql from "graphql-tag";
import ReactDOM from 'react-dom';
import Select from "react-select"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import iconoFooter from '../../Assets/lc.png';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './stylesPrint';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
/* eslint-disable */

const OPT_CONTEXTO = 1;
const OPT_OBLIGACIONES = 2;
const OPT_CONTRATOS = 3;
const OPT_RIESGOS_PENALES = 4;
const OPT_DIGITAL = 5;
const OPT_BLANQUEOS = 6;
const OPT_SERVICIOS = 7;

class EdicionRiesgo extends Component {

    state={
        cargando:true,
        idUsuarioActual:null,
        logotipo:null,
        datosEmpresa:null,
        bloques:[],
        bloqueSeleccionado:null,
        riesgos:[],
        riesgoSeleccionado:null,
        valNull:null,
        cargandoRiesgo:false,
        imprimirRiesgoActivado:false,
        nuevoRiesgoActivado:true,
        editarRiesgoActivado:false,
        borrarRiesgoActivado:false,
        modalImperativoAbierto:false,
        modalBorrarImperativo:false,
        modalRecomendacionAbierto:false,
        modalBorrarRecomendacion:false,
        modalRiesgoAbierto: false,
        modalBorrarRiesgo: false,
        bloque: null,
        codigo: null,
        titulo: null,
        tipo:null,
        tipos:[],
        descripcionRiesgo:null,
        modalImprimirB:false,
        modalImprimirI:false,
        modalImprimirR:false,
        cargandoImprimir:false,
        blqInforme:[],
        imperativos:[],
        recomendaciones:[],
        nuevoImperativoActivado:false,
        editarImperativoActivado:false,
        borrarImperativosActivado:false,
        imperativoSeleccionado: null,
        recomendacionSeleccionado: null,
        imperativo:null,
        nuevoRecomendacionesActivado:false,
        editarRecomendacionesActivado:false,
        borrarRecomendacionesActivado:false,
    }

    componentDidMount=async(props)=> {
        let usuarioActual=await this.props.client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obtenerUsuarioActual
        });

        await this.cargarDatos();

        await this.obtenerLogoEmpresa();

        await this.cargarBloques();

        this.setState({ cargando:false,idUsuarioActual:usuarioActual});

    }

    async cargarImperativos(idRiesgo){

        await this.props.client.query({
            query:gql`{complianceImperativas(FILTROS:{ID_RIESGO:${idRiesgo}}){ID_IMPERATIVA,DESCRIPCION,PREDEFINIDA,ID_RIESGO}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let imperativos=[]
            for(let imperativo of result.data.complianceImperativas){
                imperativos.push({"id":imperativo.ID_IMPERATIVA,"nombre":imperativo.DESCRIPCION,"predefinida":imperativo.PREDEFINIDA,"idRiesgo":imperativo.ID_RIESGO})
            }
            this.setState({imperativos:imperativos}) 
        })

    }

    async cargarRecomendaciones(idRiesgo){

        await this.props.client.query({
            query:gql`{complianceRecomendaciones(FILTROS:{ID_RIESGO:${idRiesgo}}){ID_RECOMENDACION,DESCRIPCION,PREDEFINIDA,ID_RIESGO}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let recomendaciones=[]
            for(let recomendacion of result.data.complianceRecomendaciones){
                recomendaciones.push({"id":recomendacion.ID_RECOMENDACION,"nombre":recomendacion.DESCRIPCION,"predefinida":recomendacion.PREDEFINIDA,"idRiesgo":recomendacion.ID_RIESGO})
            }

            this.setState({recomendaciones:recomendaciones}) 
        })

    }

    generarInformeI =async () => {
        this.setState({
            modalImprimirI: true,
            cargandoImprimir:true
        }, async () => {
            this.setState({
                cargandoImprimir:false,
            },()=>{
                ReactDOM.render(this.InformeI(), document.getElementById('informe-pdf-i'))
            })
        });
        
    }

    generarInformeR =async () => {
        this.setState({
            modalImprimirR: true,
            cargandoImprimir:true
        }, async () => {
            this.setState({
                cargandoImprimir:false,
            },()=>{
                ReactDOM.render(this.InformeR(), document.getElementById('informe-pdf-r'))
            });
        })
        
    }

    generarInformeRiesgos =async () => {
        this.setState({
            modalImprimirB: true,
            cargandoImprimir:true
        }, async () => {
            this.setState({
                cargandoImprimir:false
            },async()=>{
                await this.cargarDatosAimprimir();
                ReactDOM.render(this.InformeB(), document.getElementById('informe-pdf-b'))
            });
        })
        
    }

    InformeI = () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.body}>
                  <View fixed style={styles.row}>
                    <Image
                      style={styles.imageHead}
                      src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                      />
                  </View>
                  <View fixed style={styles.rowHead}>
                      <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                  </View>
                  <View fixed style={styles.rowHeadTwo}>
                      <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{i18next.t("edicionriesgo.title",{ns:"page"}).toUpperCase()}</Text>
                  </View>
                  <View  fixed style={styles.rowTitleOne}>
                      <Text style={styles.labelTitle}>{i18next.t("edicionriesgo.controlesimperativos",{ns:"page"})}</Text>
                  </View>
                  <View style={styles.row}>
                            <Text style={styles.labelData}>{i18next.t("edicionriesgo.riesgo",{ns:"page"}).toUpperCase()}:</Text>
                            <Text style={styles.textData}>{this.state.bloqueSeleccionado.LETRA}{this.state.riesgoSeleccionado.ID_RIESGO} - {this.state.riesgoSeleccionado.TITULO}</Text> 
                    </View>
                  {this.state.imperativos?this.state.imperativos.map((imperativo, index) => {
                      return(
                          
                          <View key={index}>
                                  
                                     
                              {index===0 && (
                                  <View style={styles.colHead}>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>Nº</Text>
                                      <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'90%'}}>{i18next.t("edicionriesgo.control",{ns:"page"}).toUpperCase()}</Text>
                                  </View>                               
                              )}
                             
                              <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{index+1}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'90%'}}>{imperativo.nombre}</Text>
                              </View> 
                                             
                              
                          </View>
                          
                      )
                  }):<></>}
                  
              </View>
              <Text
              style={styles.footerPage}
              render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
              fixed
              />
              <Image
                      style={styles.icono}
                      src={iconoFooter}
                      fixed
                      />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
          </Document>
        </PDFViewer>
    )

    InformeR = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("edicionriesgo.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View  fixed style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("edicionriesgo.recomendaciones",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                            <Text style={styles.labelData}>{i18next.t("edicionriesgo.riesgo",{ns:"page"}).toUpperCase()}:</Text>
                            <Text style={styles.textData}>{this.state.bloqueSeleccionado.LETRA}{this.state.riesgoSeleccionado.ID_RIESGO} - {this.state.riesgoSeleccionado.TITULO}</Text> 
                    </View>
                {this.state.recomendaciones?this.state.recomendaciones.map((recomendacion, index) => {
                    return(
                        
                        <View key={index}>
                            
                                   
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>Nº</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',  fontSize:9, fontFamily: 'Open Sans Bold', width:'90%'}}>{i18next.t("edicionriesgo.control",{ns:"page"}).toUpperCase()}</Text>
                                </View>                               
                            )}
                           
                            <View style={styles.colHead}>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8, fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{index+1}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',fontSize:8, fontFamily: 'Open Sans', width:'90%'}}>{recomendacion.nombre}</Text>
                            </View> 
                                           
                            
                        </View>
                        
                    )
                }):<></>}
                
            </View>
            <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
            fixed
            />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    InformeB = () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.body}>
                  <View fixed style={styles.row}>
                    <Image
                      style={styles.imageHead}
                      src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                      />
                  </View>
                  <View fixed style={styles.rowHead}>
                      <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                  </View>
                  <View fixed style={styles.rowHeadTwo}>
                      <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{i18next.t("edicionriesgo.riesgos",{ns:"page"}).toUpperCase()}</Text>
                  </View>
                  <View  fixed style={styles.rowTitleOne}>
                      <Text style={styles.labelTitle}>{i18next.t("edicionriesgo.riesgos",{ns:"page"}).toUpperCase()}</Text>
                  </View>
                  <View style={styles.row}>
                            <Text style={styles.labelData}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>
                            <Text style={styles.textData}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()} {this.state.bloqueSeleccionado.LETRA} - {this.state.bloqueSeleccionado.TITULO}</Text> 
                    </View>
                  {this.state.blqInforme?this.state.blqInforme.map((bloque, index) => {
                      return(
                          
                          <View key={index}>
                                    
                              {index===0 && (
                                  <View style={styles.colHead}>
                                      <Text style={{textAlign:'center',flexDirection: 'column', fontSize:9, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("edicionriesgo.riesgo",{ns:"page"}).toUpperCase()}</Text>
                                  </View>                               
                              )}
                             
                              <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{bloque.RIESGO}</Text>
                              </View> 
                                             
                              
                          </View>
                          
                      )
                  }):<></>}
                  
              </View>
              <Text
              style={styles.footerPage}
              render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
              fixed
              />
              <Image
                      style={styles.icono}
                      src={iconoFooter}
                      fixed
                      />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
          </Document>
        </PDFViewer>
    )

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            datosEmpresa:datosEmpresa
        })
    }

    cargarDatosAimprimir = () => {
        let blqInforme=[];
        if (this.state.riesgos.length>0) {
            for (let riesgo of this.state.riesgos) {
                if (riesgo.ID_BLOQUE===this.state.bloqueSeleccionado.ID_BLOQUE ) {
                    blqInforme.push({"BLOQUE":"","RIESGO":this.state.bloqueSeleccionado.LETRA+riesgo.ID_RIESGO+" - "+riesgo.TITULO,"SUBRIESGO":""});
                }   
            }
        }
        this.setState({blqInforme:blqInforme});
    }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
        
        return datosEmpresa
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarBloques=async()=>{
        let bloquesRiesgo=await this.props.client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.bloquesRiesgo;
        });
        this.setState({bloques:bloquesRiesgo});
    }

    cargarRiesgos=async()=>{
        let complianceRiesgos=await this.props.client.query({
            query:gql`{complianceRiesgos(FILTROS:{ID_BLOQUE:${this.state.bloqueSeleccionado.ID_BLOQUE}}){ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_BLOQUE,TIPO}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceRiesgos
        });
        let riesgos=[];
        let existe=false;

        for(let padre of complianceRiesgos){
            riesgos.push({"ID_BLOQUE":this.state.bloqueSeleccionado.ID_BLOQUE,"ID_RIESGO":padre.ID_RIESGO,"TITULO":padre.TITULO,"DESCRIPCION":padre.DESCRIPCION,"TIPO":padre.TIPO});
        }

        this.setState({riesgos:riesgos,cargandoRiesgo:false});
    }

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("edicionriesgo.title",{ns:"page"});
        

        const actualizarRecomendacion=()=>{

            if(!this.state.recomendacion)return

            let recomendaciones=this.state.recomendaciones
            recomendaciones.find((recomendacion,index)=>{
                if(recomendacion.id===this.state.recomendacionSeleccionado.id){
                    recomendaciones[index].nombre=this.state.recomendacion
                }
                return recomendacion.id===this.state.recomendacionSeleccionado.id
            })
            this.setState({
                recomendaciones:recomendaciones             
            })
            
            this.props.client.mutate({
                mutation:gql`mutation{actualizarRecomendacion(ID_RECOMENDACION:${this.state.recomendacionSeleccionado.id},VALORES:{DESCRIPCION:"""${this.state.recomendacion}""",PREDEFINIDA:false,ID_RIESGO:${this.state.riesgoSeleccionado.ID_RIESGO}}){ID_RECOMENDACION}}`
            }).then(async result=>{
            
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                
                    
                    await this.cargarRecomendaciones(this.state.riesgoSeleccionado.ID_RIESGO)
                    cerrarModal()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            

        }

        const handleCambiarRecomendacion=(ev)=>{
            this.setState({recomendacion:ev.currentTarget.value})
        }

        const anadirNuevaRecomendacion=()=>{
            
            if(!this.state.recomendacion)return

            let recomendaciones=this.state.recomendaciones
            recomendaciones.push({"nombre":this.state.recomendacion})

            this.setState({recomendaciones:recomendaciones})

            //hacemos la insercion
            this.props.client.mutate({
                mutation:gql`mutation{crearRecomendacion(VALORES:{DESCRIPCION:"""${this.state.recomendacion}""",PREDEFINIDA:false,ID_RIESGO:${this.state.riesgoSeleccionado.ID_RIESGO}}){ID_RECOMENDACION}}`
            }).then(async result=>{
           
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                
                    await this.cargarRecomendaciones(this.state.riesgoSeleccionado.ID_RIESGO)
                    cerrarModal();
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            

        }

        const handleCambiarImperativo=(ev)=>{
            this.setState({imperativo:ev.currentTarget.value})
        }

        const actualizarImpertaivo=()=>{

            if(!this.state.imperativo)return
            
            let imperativos=this.state.imperativos
            imperativos.find((imperativo,index)=>{
                if(imperativo.id===this.state.imperativoSeleccionado.id){
                    imperativos[index].nombre=this.state.imperativo
                }
                return imperativo.id===this.state.imperativoSeleccionado.id
            })

            this.setState({
                imperativos:imperativos             
            })

               
            this.props.client.mutate({
                mutation:gql`mutation{actualizarComplianceImperativa(ID_IMPERATIVA:${this.state.imperativoSeleccionado.id},VALORES:{DESCRIPCION:"""${this.state.imperativo}""",PREDEFINIDA:false,ID_RIESGO:${this.state.riesgoSeleccionado.ID_RIESGO}}){ID_IMPERATIVA}}`
            }).then(async result=>{
             
                alert(i18next.t("updatecorrecto",{ns:"global"}))

                //reseleccionamos la que teniamos seleccionada
                
                await this.cargarImperativos(this.state.riesgoSeleccionado.ID_RIESGO)
                cerrarModal()
                
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}));
            })

            

        }

        const anadirNuevoImperativo=()=>{

            if(!this.state.imperativo)return

            let imperativos=this.state.imperativos
            imperativos.push({"nombre":this.state.imperativo})
            this.setState({imperativos:imperativos})

            this.props.client.mutate({
                mutation:gql`mutation{crearComplianceImperativa(VALORES:{DESCRIPCION:"""${this.state.imperativo}""",PREDEFINIDA:false,ID_RIESGO:${this.state.riesgoSeleccionado.ID_RIESGO}}){ID_IMPERATIVA}}`
            }).then(async result=>{
     
                alert(i18next.t("insertcorecto",{ns:"global"}))

                await this.cargarImperativos(this.state.riesgoSeleccionado.ID_RIESGO)
                cerrarModal();
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            
        }

        const abrirModalRecomendacionesNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.riesgoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({
                recomendacion:null,
                recomendacionSeleccionado:null,
                modalRecomendacionAbierto:true,
                nuevoRecomendacionesActivado:true,
                editarRecomendacionesActivado:false,
                borrarRecomendacionesActivado:false
            });
            
        }

        const abrirModalRecomendacionesEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.recomendacionSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                recomendacion:this.state.recomendacionSeleccionado.nombre,
                modalRecomendacionAbierto:true,
                nuevoRecomendacionesActivado:false,
                editarRecomendacionessActivado:true,
                borrarRecomendacionesActivado:false
            });
            
        }

        const abrirModalRecomendacionesBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.recomendacionSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalBorrarRecomendacion:true,
                nuevoRecomendacionesActivado:false,
                editarRecomendacionesActivado:false,
                borrarRecomendacionesActivado:true
            })
        }

        const abrirModalImperativosEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.imperativoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalImperativoAbierto:true,
                imperativo:this.state.imperativoSeleccionado.nombre,
                nuevoImperativoActivado:false,
                editarImperativosActivado:true,
                borrarImperativosActivado:false
            })
        }

        const abrirModalImperativosBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.imperativoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({modalBorrarImperativo:true})
            this.setState({nuevoImperativoActivado:false})
            this.setState({editarImperativosActivado:false})
            this.setState({borrarImperativosActivado:true})
        }

        const abrirModalNuevoImperativo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.riesgoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({
                imperativo:null,
                imperativoSeleccionado:null,
                modalImperativoAbierto:true,
                nuevoImperativoActivado:true,
                editarImperativosActivado:false,
                borrarImperativosActivado:false
            })


        }

        const cerrarModalImprimirB=()=>{
            this.setState({modalImprimirB: false})
        }

        const cerrarModalImprimirI=()=>{
            this.setState({modalImprimirI: false})
        }

        const cerrarModalImprimirR=()=>{
            this.setState({modalImprimirR: false})
        }

        const abrirModalNuevoRiesgo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({
                modalRiesgoAbierto: true,
                nuevoRiesgoActivado:true,
                editarRiesgoActivado:false,
                borrarRiesgoActivado:false,
                bloque:"BLOQUE "+this.state.bloqueSeleccionado.LETRA+" - "+this.state.bloqueSeleccionado.TITULO,
                codigo: null,
                titulo: null,
                descripcionRiesgo: null,
                tipo:null
            })


        }

        const abrirModalEditarRiesgo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.riesgoSeleccionado === false){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            let tipo=this.state.tipos.find(tipo=>{return tipo.value===this.state.riesgoSeleccionado.TIPO})

            
            this.setState({
                modalRiesgoAbierto: true,
                nuevoRiesgoActivado:false,
                editarRiesgoActivado:true,
                borrarRiesgoActivado:false,
                bloque:"BLOQUE "+this.state.bloqueSeleccionado.LETRA+" - "+this.state.bloqueSeleccionado.TITULO,
                titulo: this.state.riesgoSeleccionado.TITULO,
                descripcionRiesgo: this.state.riesgoSeleccionado.DESCRIPCION,
                tipo:tipo
            })


        }

        const abrirModalBorrarRiesgo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.riesgoSeleccionado === false){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarRiesgo: true,
                nuevoRiesgoActivado:false,
                editarRiesgoActivado:false,
                borrarRiesgoActivado:true
            })

        }

        const cerrarModal = () => {
            this.setState({
              modalImperativoAbierto:false,
              modalBorrarImperativo:false,
              modalRecomendacionAbierto:false,
              modalBorrarRecomendacion:false,
              modalRiesgoAbierto: false,
              modalBorrarRiesgo: false,
            })
        }

        const handleCambiarBloque=(ev)=>{
            this.setState({bloque:ev.currentTarget.value})
        }

        const handleCambiarTitulo=(ev)=>{
            this.setState({titulo:ev.currentTarget.value})
        }

        const handleCambiarDescripcion=(ev)=>{
            this.setState({descripcionRiesgo:ev.currentTarget.value})
        }

        const crearRiesgo=()=>{

            //validaciones 
            if(!this.state.titulo){
                return
            }
            if(!this.state.descripcionRiesgo){
                return
            }

            //obtenemos el nuevo codigo del riesgo.
            let idBloque=this.state.bloqueSeleccionado.ID_BLOQUE;

            this.props.client.mutate({
                mutation:gql`mutation{crearComplianceRiesgo(VALORES:{
                    ID_BLOQUE:${idBloque},
                    DESCRIPCION:"""${this.state.descripcionRiesgo}""",
                    TITULO:"${this.state.titulo}",
                    TIPO:"${this.state.tipo.value}"
                }){ID_BLOQUE,ID_RIESGO}}`
            }).then(async result=>{

                this.props.client.mutate({
                    mutation:gql`mutation{crearRiesgosH(VALORES:{
                        ID_RIESGO:${result.data.crearComplianceRiesgo.ID_RIESGO},
                        ID_BLOQUE:${idBloque},
                        DESCRIPCION:"""${this.state.descripcionRiesgo}""",
                        TITULO:"${this.state.titulo}",
                        FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
                        ID_USUARIO:${this.state.idUsuarioActual}
                    }){ID_BLOQUE,ID_RIESGO}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                    return
                })
     
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                this.setState({
                    modalRiesgoAbierto:false,
                    riesgoSeleccionado:null,
                    cargandoRiesgo:true
                },async()=>{
                    await this.cargarBloques();
                    await this.cargarRiesgos();
                })
                
            
              
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            });
            
        }

        const editarRiesgo=()=>{
            
            //validaciones 
            if(!this.state.titulo){
                return
            }
            if(!this.state.descripcionRiesgo){
                return
            }

            let idRiesgo=this.state.riesgoSeleccionado.ID_RIESGO;
         
            //mutation para crear el bloque de riesgo
            this.props.client.mutate({
                mutation:gql`mutation{actualizarComplianceRiesgo( ID_RIESGO:${idRiesgo},VALORES:{
                    DESCRIPCION:"""${this.state.descripcionRiesgo}""",
                    TITULO:"${this.state.titulo}",
                    TIPO:"${this.state.tipo.value}"
                }){ID_BLOQUE,ID_RIESGO,CODIGO_RIESGO}}`
            }).then(async resultado1=>{

                
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                this.setState({
                    modalRiesgoAbierto:false,
                    riesgoSeleccionado:null,
                    cargandoRiesgo:true
                },async()=>{
                    await this.cargarBloques();
                    await this.cargarRiesgos();

                });
                
            
            
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })

            
        }

        const eliminarRecomendacion=async()=>{

            if(!this.state.recomendacionSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //comprobar que no este en uso en algun documento de seguimiento
            let recomendacionExiste=await this.props.client.query({
                query:gql`{SeguimientoRecomendaciones(FILTROS:{ID_RECOMENDACION:${this.state.recomendacionSeleccionado.id}}){ID_RECOMENDACION}}`
                ,fetchPolicy:'network-only'
            }).then(async(result)=>{
                return result
            })

            if(recomendacionExiste.data.SeguimientoRecomendaciones.length>0){
                alert(i18next.t("edicionriesgo.validdeleterecomendacion",{ns:"page"}));
                return
            }

            let recomendaciones=this.state.recomendaciones.filter(recomendacion=>{
                    return recomendacion.id!==this.state.recomendacionSeleccionado.id
            })
            this.setState({
                recomendaciones:recomendaciones
            })

            this.props.client.mutate({
                mutation:gql`mutation{eliminarRecomendacion(ID_RECOMENDACION:${this.state.recomendacionSeleccionado.id}){ID_RECOMENDACION}}`
            }).then(async result=>{
               
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({
                    recomendacionSeleccionado:null,
                },async()=>{
                    await this.cargarRecomendaciones(this.state.riesgoSeleccionado.ID_RIESGO)

                })
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            
            cerrarModal()

        }

        const eliminarImperativo=async()=>{

            if(!this.state.imperativoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //comprobar que no este en uso en algun documento de seguimiento
            let imperativaExiste=await this.props.client.query({
                query:gql`{complianceSeguimientoImperativas(FILTROS:{ID_IMPERATIVA:${this.state.imperativoSeleccionado.id}}){ID_IMPERATIVA}}`
                ,fetchPolicy:'network-only'
            }).then(async(result)=>{
                return result
            })

            if(imperativaExiste.data.complianceSeguimientoImperativas.length>0){
                alert(i18next.t("edicionriesgo.validdeleteimperativo",{ns:"page"}));
                return
            }

            let imperativos=this.state.imperativos.filter(imperativo=>{
                    return imperativo.id!==this.state.imperativoSeleccionado.id
            })

            this.setState({
                imperativos:imperativos
            })

            this.props.client.mutate({
                mutation:gql`mutation{eliminarComplianceImperativa(ID_IMPERATIVA:${this.state.imperativoSeleccionado.id}){ID_IMPERATIVA}}`
            }).then(result=>{
                
                alert(i18next.t("deletecorrecto",{ns:"global"}))

                this.setState({
                    imperativoSeleccionado:null
                },async()=>{
                    await this.cargarImperativos(this.state.riesgoSeleccionado.ID_RIESGO);
                    
                })
                
                

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            
            cerrarModal()

        }

        const eliminarRiesgo=()=>{
            //mutation para crear el bloque de riesgo
            this.props.client.mutate({
                mutation:gql`mutation{eliminarComplianceRiesgo(ID_RIESGO:${this.state.riesgoSeleccionado.ID_RIESGO}){ID_RIESGO}}`
            }).then(async result=>{
    
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({
                    modalBorrarRiesgo:false,
                    riesgoSeleccionado:null
                },async()=>{
                    await this.cargarBloques();
                    await this.cargarRiesgos();

                })
                
            
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        
        }

        const seleccionTipo  = (value) => {
            this.setState({tipo: value})
        }

        const bloqueFormato=(cell,row)=>{
            let bloque='BLOQUE '+row.LETRA+' - '+cell;
            return (
                <>
                    <span>{bloque}</span>
                </>
            );
        }

        const riesgoFormato=(cell,row)=>{
            let riesgo=this.state.bloqueSeleccionado.LETRA+row.ID_RIESGO+' - '+cell;
            return (
                <>
                    <span>{riesgo}</span>
                </>
            );
        }

        const columns = [
            {
                dataField: 'TITULO',
                text: i18next.t("edicionriesgo.bloque",{ns:"page"}),
                sort: true,
                formatter: bloqueFormato,
            }
        ];

        const columnsRiesgo = [
            {
                dataField: 'TITULO',
                text: i18next.t("edicionriesgo.riesgo",{ns:"page"}),
                sort: true,
                formatter: riesgoFormato,
            }
        ];

        const columnsImperativo = [
            {
                dataField: 'nombre',
                text: i18next.t("edicionriesgo.control",{ns:"page"}),
                sort: true
            }
        ];

        const columnsRecomendacion = [
            {
                dataField: 'nombre',
                text: i18next.t("edicionriesgo.control",{ns:"page"}),
                sort: true
            }
        ];

        const handleOnSelectBlock=(row, isSelect) => {
            if (isSelect) {
                let tipos=[];
                switch(row.ID_BLOQUE) {
                    case OPT_CONTEXTO:
                        tipos.push({"value":"contexto","label":"contexto"});
                        tipos.push({"value":"licencias","label":"licencias"});
                        tipos.push({"value":"competividad","label":"competividad"});
                        tipos.push({"value":"procedimientos operativos","label":"procedimientos operativos"});
                        tipos.push({"value":"otros contextos","label":"otros contextos"});
                        
                        break;
                    case OPT_OBLIGACIONES:
                        tipos.push({"value":"municipales","label":"municipales"});
                        tipos.push({"value":"provinciales","label":"provinciales"});
                        tipos.push({"value":"autonomicas","label":"autonomicas"});
                        tipos.push({"value":"nacionales","label":"nacionales"});
                        tipos.push({"value":"comunitarias","label":"comunitarias"});
                        tipos.push({"value":"sectoriales","label":"sectoriales"});
                        tipos.push({"value":"otras obligaciones","label":"otras obligaciones"});
                        
                        break;
                    case OPT_CONTRATOS:
                        tipos.push({"value":"privados","label":"privados"});
                        tipos.push({"value":"publicos","label":"publicos"});
                        tipos.push({"value":"con socios","label":"con socios"});
                        tipos.push({"value":"con administradores","label":"con administradores"});
                        tipos.push({"value":"de alta direccion","label":"de alta direccion"});
                        tipos.push({"value":"internacionales","label":"internacionales"});
                        tipos.push({"value":"otros contratos","label":"otros contratos"});
                        
                        break;
                    case OPT_RIESGOS_PENALES:
                        tipos.push({"value":"economicos","label":"economicos"});
                        tipos.push({"value":"seguridad","label":"seguridad"});
                        tipos.push({"value":"urbanismo","label":"urbanismo"});
                        tipos.push({"value":"medio ambiente","label":"medio ambiente"});
                        tipos.push({"value":"salud publica","label":"salud publica"});
                        tipos.push({"value":"administracion","label":"administracion"});
                        tipos.push({"value":"otros riesgos penales","label":"otros riesgos penales"});
                        
                        break;
                    case OPT_DIGITAL:
                        tipos.push({"value":"ciberseguridad","label":"ciberseguridad"});
                        tipos.push({"value":"lopd","label":"lopd"});
                        tipos.push({"value":"lssi y ce","label":"lssi y ce"});
                        tipos.push({"value":"marcas y dominios","label":"marcas y dominios"});
                        tipos.push({"value":"otros digital","label":"otros digital"});
                        
                        break;
                    case OPT_BLANQUEOS:
                        tipos.push({"value":"ocultacion","label":"ocultacion"});
                        tipos.push({"value":"adquisicion","label":"adquisicion"});
                        tipos.push({"value":"conversion","label":"conversion"});
                        tipos.push({"value":"inversion","label":"inversion"});
                        tipos.push({"value":"suministro","label":"suministro"});
                        tipos.push({"value":"distribucion","label":"distribucion"});
                        tipos.push({"value":"otros blanqueos","label":"otras blanqueos"});
                        
                        break;
                    case OPT_SERVICIOS:
                        tipos.push({"value":"subcontratacion","label":"subcontratacion"});
                        tipos.push({"value":"etts","label":"etts"});
                        tipos.push({"value":"cesion","label":"cesion"});
                        tipos.push({"value":"subrogaciones","label":"subrogaciones"});
                        tipos.push({"value":"otros servicios","label":"otros servicios"});
                        
                        break;
                    default:
                        tipos=[];
                        break;
                }
                this.setState({
                    riesgoSeleccionado:null,
                    bloqueSeleccionado:row,
                    cargandoRiesgo:true,
                    imperativos:[],
                    recomendaciones:[],
                    tipos:tipos
                },async()=>{
                    await this.cargarRiesgos();
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectRisk=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    riesgoSeleccionado:row
                },async()=>{
                    await this.cargarImperativos(row.ID_RIESGO);
                    await this.cargarRecomendaciones(row.ID_RIESGO);
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectImperativo=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    imperativoSeleccionado:row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectRecomendacion=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    recomendacionSeleccionado:row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        return (
            <>
                {this.state.cargando?
                    <Cargando/>
                :
                <>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoRecomendacionesActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalRecomendacionAbierto}
                    >
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label={i18next.t("edicionriesgo.procedimiento",{ns:"page"})+":"}
                                    labelFor="procedimiento"
                                    intent="danger"
                                    helperText={this.state.imperativo?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("edicionriesgo.procedimiento",{ns:"page"})}
                                >
                                    <TextArea fill={true} onChange={handleCambiarRecomendacion} defaultValue={this.state.recomendacion?this.state.recomendacion:""} intent={this.state.recomendacion?"primary":"danger"} className="textareaControlesyRecomendaciones" />
                                </FormGroup>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                                        </Tooltip>
                                        <Tooltip content={this.state.nuevoRecomendacionesActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                            <AnchorButton
                                                intent={Intent.PRIMARY}
                                                onClick={this.state.nuevoRecomendacionesActivado?anadirNuevaRecomendacion:actualizarRecomendacion}
                                            >
                                            {this.state.nuevoRecomendacionesActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                            </AnchorButton>
                                        </Tooltip>
                                </div>
                            </div>
                        </Card>
                    </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoImperativoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        isOpen={this.state.modalImperativoAbierto}
                        className="dialog-ancho dialog-scroll "
                    >
                    <Card id="dialogo-imperativos" interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("edicionriesgo.procedimiento",{ns:"page"})+":"}
                                labelFor="procedimiento"
                                intent="danger"
                                helperText={this.state.imperativo?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("edicionriesgo.procedimiento",{ns:"page"})}
                            >
                                <TextArea fill={true} onChange={handleCambiarImperativo} defaultValue={this.state.imperativo?this.state.imperativo:""} intent={this.state.imperativo?"primary":"danger"} className="textareaControlesyRecomendaciones" />
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoImperativoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoImperativoActivado?anadirNuevoImperativo:actualizarImpertaivo}
                                >
                                {this.state.nuevoImperativoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                        </div>
                    </Card>
                    </Dialog>
                    
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})+" "+i18next.t("edicionriesgo.controlesimperativos",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarImperativo}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("edicionriesgo.questiondelimperativo",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarImperativo}
                                    >
                                        {i18next.t("yes",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})+" "+i18next.t("edicionriesgo.recomendaciones",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarRecomendacion}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("edicionriesgo.questiondelrecomendacion",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarRecomendacion}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimirB}
                        /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                        title={i18next.t("print",{ns:"global"})+" "+i18next.t("edicionriesgo.riesgos",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        usePortal={true}
                        isOpen={this.state.modalImprimirB}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-b">
                            </div>
                            :
                                <Spinner />
                        }

                        
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimirI}
                        /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                        title={i18next.t("print",{ns:"global"})+" "+i18next.t("edicionriesgo.controlesimperativos",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        usePortal={true}
                        isOpen={this.state.modalImprimirI}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '600px'}} id="informe-pdf-i">
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>

                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimirR}
                        /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                        title={i18next.t("print",{ns:"global"})+" "+i18next.t("edicionriesgo.recomendaciones",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        usePortal={true}
                        isOpen={this.state.modalImprimirR}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '600px'}} id="informe-pdf-r">
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>

                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={i18next.t("edicionriesgo.riesgo",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        isOpen={this.state.modalRiesgoAbierto}
                        className="dialog-ancho dialog-scroll "
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <div id="dialogo-riesgos" className="bp3-card separarBordes">
                                <FormGroup
                                    label={i18next.t("edicionriesgo.bloque",{ns:"page"})+":"}
                                    labelFor="bloque"
                                >
                                    <InputGroup fill={true} onChange={handleCambiarBloque} defaultValue={this.state.bloque?this.state.bloque:""} readOnly={true}/>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("edicionriesgo.tipo",{ns:"page"})+":"}
                                    labelFor="tipo"
                                >
                                    <div style={!this.state.tipo?{border:"1px solid red"}:{}}>
                                        <Select
                                            options={this.state.tipos}
                                            className="pixelAlrededor"
                                            onChange={seleccionTipo}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            value={this.state.tipo}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("edicionriesgo.titulo",{ns:"page"})+":"}
                                    labelFor="titulo"
                                    intent={this.state.titulo?"":"danger"}
                                    helperText={this.state.titulo?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("edicionriesgo.titulo",{ns:"page"})}
                                >
                                    <InputGroup fill={true} onChange={handleCambiarTitulo} defaultValue={this.state.titulo?this.state.titulo:""} intent={this.state.titulo?"primary":"danger"}/>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("edicionriesgo.descripcion",{ns:"page"})+":"}
                                    labelFor="descripcion"
                                    intent={this.state.descripcionRiesgo?"":"danger"}
                                    helperText={this.state.descripcionRiesgo?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("edicionriesgo.descripcion",{ns:"page"})}
                                >
                                    <TextArea fill={true} className="textareaEdicion" onChange={handleCambiarDescripcion} defaultValue={this.state.descripcionRiesgo?this.state.descripcionRiesgo:""} intent={this.state.descripcionRiesgo?"primary":"danger"}/>
                                </FormGroup>
                            </div>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={this.state.nuevoRiesgoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={this.state.nuevoRiesgoActivado?crearRiesgo:editarRiesgo}
                                    >
                                        {this.state.nuevoRiesgoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("return",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})+" "+i18next.t("edicionriesgo.riesgo",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarRiesgo}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("edicionriesgo.questiondelriesgo",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarRiesgo}
                                    >
                                        {i18next.t("yes",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                    <div id="contenedor-principal-edicion-riesgos">
                        <div className="tablaMitadIzquierda pixelAlrededor">
                            <Card interactive={true} id="card-bloques" elevation={Elevation.TWO}>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("edicionriesgo.bloques",{ns:"page"})}</Callout>
                                <br></br>
                                <BootstrapTable
                                    keyField='ID_BLOQUE'
                                    data={ this.state.bloques }
                                    columns={ columns }
                                    selectRow={ const_table.selectRow(handleOnSelectBlock) }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            </Card>
                            <br></br>
                            <Card interactive={true} id="card-riesgos" elevation={Elevation.TWO}>
                                {this.state.cargandoRiesgo?
                                    <Cargando/>
                                :
                                <>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("edicionriesgo.riesgos",{ns:"page"})}</Callout>
                                    <Navbar>
                                        <Navbar.Group>
                                            <ButtonGroup align={Alignment.LEFT}>
                                                    <Button className="bp3-minimal" id="btn-nuevo-riesgo" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoRiesgoActivado} disabled={this.state.bloqueSeleccionado || this.state.riesgoSeleccionado ?false:true} onClick={abrirModalNuevoRiesgo} />
                                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarRiesgoActivado} disabled={this.state.riesgoSeleccionado?false:true}  onClick={abrirModalEditarRiesgo}/>
                                                    <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarRiesgoActivado} disabled={this.state.riesgoSeleccionado?false:true}  onClick={abrirModalBorrarRiesgo}/>
                                                    <Button key="botonImprimir" className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} id="boton-imprimir" active={this.state.imprimirRiesgoActivado} disabled={this.state.bloqueSeleccionado?false:true} onClick={() => this.generarInformeRiesgos()} />
                                            </ButtonGroup>
                                        </Navbar.Group>
                                    </Navbar>
                                    <BootstrapTable
                                        keyField='ID_RIESGO'
                                        data={ this.state.riesgos }
                                        columns={ columnsRiesgo }
                                        selectRow={ const_table.selectRow(handleOnSelectRisk) }
                                        pagination={ paginationFactory(const_table.options) }
                                        headerClasses="header-class"
                                        rowClasses="row-class"
                                        hover
                                        condensed
                                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                    />
                                </>
                                }
                            </Card>
                        </div>
                        <div id="controles-recomendaciones" className="tablaMitadDerecha pixelAlrededor">
                            <Card interactive={true} elevation={Elevation.TWO}>
                                <Callout className="bp3-intent-danger">
                                    {i18next.t("edicionriesgo.controlesimperativos",{ns:"page"})} {this.state.riesgoSeleccionado ? " PARA " + this.state.bloqueSeleccionado.LETRA:""}{this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.ID_RIESGO+ " - "+this.state.riesgoSeleccionado.TITULO : ""}
                                </Callout>
                                <Navbar>
                                    <Navbar.Group>
                                        <ButtonGroup align={Alignment.LEFT}>
                                                <Button className="bp3-minimal" id="btn-nuevo-control" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoImperativoActivado} disabled={this.state.riesgoSeleccionado?false:true} onClick={abrirModalNuevoImperativo} />
                                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarImperativoActivado} disabled={this.state.imperativoSeleccionado?false:true}  onClick={abrirModalImperativosEditar} />
                                                <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarImperativosActivado} disabled={this.state.imperativoSeleccionado?false:true}  onClick={abrirModalImperativosBorrar}/>
                                                <Button key="botonImprimirI" className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} id="boton-imprimir-i" disabled={this.state.riesgoSeleccionado?false:true} onClick={() => this.generarInformeI()} />
                                                
                                        </ButtonGroup>
                                    </Navbar.Group>
                                </Navbar>
                                <BootstrapTable
                                    keyField='id'
                                    data={ this.state.imperativos }
                                    columns={ columnsImperativo }
                                    selectRow={ const_table.selectRow(handleOnSelectImperativo) }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            </Card>
                            <br></br>
                            <Card interactive={true} elevation={Elevation.TWO}>
                                <Callout className="bp3-intent-danger">
                                    {i18next.t("edicionriesgo.recomendaciones",{ns:"page"}).toUpperCase()} {this.state.riesgoSeleccionado ? " "+i18next.t("for",{ns:"global"}).toUpperCase()+" " + this.state.bloqueSeleccionado.LETRA:""}{this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.ID_RIESGO+ " - "+this.state.riesgoSeleccionado.TITULO : ""}
                                </Callout>
                                <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoRecomendacionesActivado} disabled={this.state.riesgoSeleccionado?false:true} onClick={abrirModalRecomendacionesNuevo}   />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarRecomendacionesActivado} onClick={abrirModalRecomendacionesEditar} disabled={this.state.recomendacionSeleccionado?false:true} />
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarRecomendacionesActivado} onClick={abrirModalRecomendacionesBorrar} disabled={this.state.recomendacionSeleccionado?false:true}/>
                                            <Button key="botonImprimirR" className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} id="boton-imprimir-r" disabled={this.state.riesgoSeleccionado?false:true}  onClick={() => this.generarInformeR()} />
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <BootstrapTable
                                keyField='id'
                                data={ this.state.recomendaciones }
                                columns={ columnsRecomendacion }
                                selectRow={ const_table.selectRow(handleOnSelectRecomendacion) }
                                pagination={ paginationFactory(const_table.options) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                            </Card>
                        </div>
                    </div>
                </>
                }
            </>
        )
    }

}

export default withTranslation()(withApollo(EdicionRiesgo));