/* eslint-disable array-callback-return */
import React from "react"
import { Spinner,Card, Elevation, Navbar, Alignment, Button, ButtonGroup, Classes, Tooltip, AnchorButton, Intent, Dialog, FormGroup, TextArea } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import gql from "graphql-tag";
import moment from 'moment';
import ApolloClient from "apollo-boost";
import i18next from 'i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';




let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

class DiligenciasSiniestros extends React.Component {

    state={
        diligencias:[
        ],
        personas:[
        ],
        personaSeleccionada: null,
        diligenciasInstruidas: null,
        fechaDiligencia:new Date(),
        siniestro:null,
        diligenciaSeleccionada:null,
        editarActivado:false,
        nuevoActivado:true,
        cargandoDS:false,
        personasFisicas:null,
        personasJuridicas:null
    }

    componentDidUpdate=(props)=>{
        if(this.props!==props){
            this.setState({siniestro:this.props.siniestro})
        }

    }
    cargarOrgano=async()=>{
          //rellenamos el combo de personas.
          let organo=await client
          .query({
            query: gql`
              {
              complianceOrganosInstruccion {
                  ID_PERSONA,
                  PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
                }
              }
            `,
            fetchPolicy: "network-only"
          })
          .then(result => {
              return JSON.parse(JSON.stringify(result.data.complianceOrganosInstruccion))
          });
  
  
          for(let org of organo){
            let nombre=''
            if(org.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                //obtenemos el nombre
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===org.ID_PERSONA){
                        nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                    }
                })
    
            }else if(org.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
               this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===org.ID_PERSONA){
                        nombre=persona.RAZON_SOCIAL
                    }
                })
            }
  
            //   await this.obtenerNombrePersona(org)
              org.label=nombre
              org.value=org.ID_PERSONA
  
          }
  
          this.setState({ personas: organo });
  
  
    }
    cargarDatos=async() =>{
        let personasFisicas= await this.cargarPersonasFisicas()
        let personasJuridicas= await this.cargarPersonasJuridicas()
        this.setState({
            personasJuridicas:personasJuridicas,
            personasFisicas:personasFisicas
        })
    }

    componentDidMount=async()=>{
        this.setState({cargandoDS:true}, async () =>{
            await this.cargarDatos()
            await this.cargarOrgano()
            await this.cargarDiligencias()
            this.setState({cargandoDS:false})
        })
      
    }
    cargarPersonasFisicas= async()=>{
        let personasFisicas= await client.query({
            query:gql`{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasFisicas
        })
        return personasFisicas
    }
    cargarPersonasJuridicas=async() =>{
        let personasJuridicas=  await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasJuridicas
        })
        return personasJuridicas
    }

    obtenerNombrePersona=async(miembro)=>{
        let personasFisicas= await client.query({
            query:gql`{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasFisicas
        })
        let personasJuridicas=  await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasJuridicas
        })    

        if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            //obtenemos el nombre
            personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                }
            })

        }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
            personasJuridicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.nombre=persona.RAZON_SOCIAL
                }
            })
        }
        
    }
   
    cargarDiligencias=async()=>{

        //obtencion de los daots de diligencias.
        let diligencias=await client
        .query({
            query: gql`
            {siniestrosInstruccionDiligencias(FILTROS:{ID_SINIESTRO:${this.props.siniestro}}){
                ID_DILIGENCIA,
                ID_SINIESTRO,
                DILIGENCIAS,
                FECHA,
                ID_PERSONA,
                ORGANO_INSTRUCCION{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}
            }}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.siniestrosInstruccionDiligencias
        })

        for(let diligencia of diligencias){
            if(diligencia.ORGANO_INSTRUCCION.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                //obtenemos el nombre
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===diligencia.ID_PERSONA){
                        diligencia.ORGANO_INSTRUCCION.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                    }
                })
    
            }else if(diligencia.ORGANO_INSTRUCCION.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===diligencia.ID_PERSONA){
                        diligencia.ORGANO_INSTRUCCION.nombre=persona.RAZON_SOCIAL
                    }
                })
            }
            // await this.obtenerNombrePersona(diligencia.ORGANO_INSTRUCCION)
        }
        this.setState({
            diligencias:diligencias
        })
        
    }

    render(){

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };
        
        const escogeFecha = (nuevaFecha) => {
            this.setState({fechaDiligencia: nuevaFecha})
        }

        const cambiarDiligencias = (ev) => { 
            this.setState({diligenciasInstruidas: ev.currentTarget.value})
        }

        const abrirModalNuevo = () => {
            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                diligenciasInstruidas:null,
                fechaDiligencia:new Date(),
                personaSeleccionada:null
            })
        }

        const abrirModalEditar = () => {
            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                diligenciasInstruidas:this.state.diligenciaSeleccionada.DILIGENCIAS,
                fechaDiligencia:new Date(this.state.diligenciaSeleccionada.FECHA),
                personaSeleccionada:this.state.personas.find(persona=>{return persona.value===this.state.diligenciaSeleccionada.ID_PERSONA})
            })
        }

        const cerrarModal = () => {
            this.setState({modalAbierto: false})
        }

        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD-MM-YYYY")
            );
        }

        const handleOnSelect =(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    diligenciaSeleccionada: row
                },async()=>{
                })
                
            }
            return true; 
        }

        const columns = [
            {
                dataField: 'FECHA',
                text: i18next.t("siniestros.diligenceDate", { ns: "page2" }),
                formatter: dateFormatter

            },
            {
                dataField: 'ORGANO_INSTRUCCION.nombre',
                text: i18next.t("siniestros.informationGatherer", { ns: "page2" })
                
            }
        ];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };


        const guardarCambios=()=>{

            if(!this.state.personaSeleccionada)return
            if(!this.state.diligencias)return

            //si estamos editando
            if(this.state.editarActivado){

                client.mutate({
                    mutation:gql`mutation{actualizarSiniestroInstruccionDiligencia(
                    ID_DILIGENCIA:${this.state.diligenciaSeleccionada.ID_DILIGENCIA},
                    VALORES:{
                        DILIGENCIAS:"""${this.state.diligenciasInstruidas}"""
                        FECHA:"${moment(this.state.fechaDiligencia).format("YYYY-MM-DD HH:mm:ss")}"
                    }){ID_DILIGENCIA}}`
                }).then(async result=>{
          
                    alert(i18next.t("siniestros.diligenceUpdatedSuccessfully", { ns: "page2" }))
                    
                    await this.cargarDiligencias()
                    
                    this.setState({
                        modalAbierto:false
                    })
                
                    
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })            

            }else{

                client.mutate({
                    mutation:gql`mutation{crearSiniestroInstruccionDiligencia(VALORES:{
                        ID_SINIESTRO:${this.state.siniestro}
                        DILIGENCIAS:"""${this.state.diligenciasInstruidas}"""
                        FECHA:"${moment(this.state.fechaDiligencia).format("YYYY-MM-DD HH:mm:ss")}"
                        ID_PERSONA:${this.state.personaSeleccionada.value}
                    }){ID_DILIGENCIA}}`
                }).then(async result=>{
          
                    alert(i18next.t("insertcorrecto", { ns: "global" }))
                    await this.cargarDiligencias()
                    await this.cargarDiligencias()
                   
    
                    this.setState({
                        modalAbierto:false
                    })
                    this.setState({modalAbierto:false})
                    
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
    

            }
           
        }

        return (
            <> 
            {this.state.cargandoDS===true?
                <Spinner/>
            :
            <>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("siniestros.diligenceInstruction", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.props.recargar()}}
                    isOpen={this.state.modalAbierto}
                >
                    <div id="modal-diligencias-siniestros" className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label={`${i18next.t("siniestros.diligenceInstructionDate", { ns: "page2" })}:`}
                            labelFor="fecha"
                            inline={true}
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                defaultValue={new Date()}
                                align={Alignment.RIGHT}
                                value={this.state.fechaDiligencia}
                            />
                        </FormGroup>
                        <FormGroup
                            label={`${i18next.t("siniestros.informationGatherer", { ns: "page2" })}:`}
                            labelFor="persona"
                        >
                             <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                                <Select
                                    options={this.state.personas}
                                    className="pixelAlrededor"
                                    onChange={seleccionPersona}
                                    value={this.state.personaSeleccionada}
                                    isDisabled={this.state.editarActivado}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup
                            label={`${i18next.t("siniestros.instructedDiligences", { ns: "page2" })}:`}
                            labelFor="diligencias-instruidas"
                        >
                            <div style={!this.state.diligenciasInstruidas?{border:"1px solid red"}:{}}>
                               <TextArea id="diligencias-instruidas" value={this.state.diligenciasInstruidas} onChange={cambiarDiligencias} className="textareaAlta" fill={true} />
                            </div>
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("siniestros.addDiligences", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={guardarCambios}
                            >
                                {i18next.t("yes", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Navbar>
                        <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" id="btn-crear-diligencia-siniestro" icon="add" text={i18next.t("new", { ns: "global" })} disabled={this.props.deshabilitado} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} disabled={this.props.deshabilitado} active={this.state.editarActivado} onClick={abrirModalEditar} />
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <div className="pixelAlrededor">
                            <BootstrapTable
                                keyField="ID_DILIGENCIA"
                                data={this.state.diligencias}
                                columns={columns}
                                selectRow={selectRow}
                                pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        </div>
                    </Card>
                </div>
            </>
            }
           </> 
        )
    }
}export default DiligenciasSiniestros
