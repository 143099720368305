import React, { Component } from 'react';
import {Spinner, FormGroup, Button, Card, Elevation, Classes,Dialog,Tooltip,Intent,AnchorButton, Callout } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select"
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from 'react-apollo';
import { Cargando } from "../Pantallas/Cargando"

import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import styles from './stylesPrint';




class FormaSocietaria extends Component {

    state={
        tiposSociedad:[],
        tiposCapitalSocial:[],
        tipoSociedadSeleccionada:null,
        tipoCapitalSeleccionado:null,
        fechaFinalizacion:null,
        idPersona:null,
        hayTipoCapital:false,
        hayTipoSociedad:false,
        cargando: false,
        caragandoImprimir:false,
        modalImprimir:false,
        imprimirActivado:false
    }
    cargarFormaSocietaria=async()=>{
        let personasJuridicas= this.props.empresaPrincipal
        // await this.props.client
        // .query({
        //     query: gql`
        //     {
        //         personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){
        //             ID_PERSONA,
        //             ID_TIPO_SOCIEDAD,
        //             TIPO_SOCIEDAD{ID_TIPO_SOCIEDAD,DESCRIPCION},
        //             ID_TIPO_CAPITAL_SOCIAL,
        //             TIPO_CAPITAL_SOCIAL{ID_TIPO_CAPITAL_SOCIAL,DESCRIPCION},
        //             FECHA_FINALIZACION}
        //     }
        //     `,
        //     fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     return result.data.personasJuridicas
        // })
            personasJuridicas.forEach(persona=>{
            this.setState({idPersona:persona.ID_PERSONA})
            if(persona.ID_TIPO_SOCIEDAD){
                this.setState({
                    hayTipoSociedad:true,
                    tipoSociedadSeleccionada:
                                            {"label":persona.TIPO_SOCIEDAD.DESCRIPCION,
                                            "value":persona.TIPO_SOCIEDAD.ID_TIPO_SOCIEDAD}
                })
            }    
            if(persona.ID_TIPO_CAPITAL_SOCIAL){
                this.setState({
                    hayTipoCapital:true,
                    tipoCapitalSeleccionado:{
                        "label":persona.TIPO_CAPITAL_SOCIAL.DESCRIPCION,
                        "value":persona.ID_TIPO_CAPITAL_SOCIAL}
                })
            }    
            if(persona.FECHA_FINALIZACION){
                this.setState({fechaFinalizacion:persona.FECHA_FINALIZACION})
            }else{
                this.setState({fechaFinalizacion:new Date()})
            }
        })    
    
    }

    cargarTipoSociedad= async()=>{
    
        //TIPOS SOCIEDADES
        let tiposSoc= await this.props.client
        .query({
            query: gql`
            {
                tiposSociedad{ID_TIPO_SOCIEDAD,DESCRIPCION}
            }
            `,
            fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.tiposSociedad
        })     
            let tiposSociedad=[]
            tiposSoc.forEach(tipo=>{
                tiposSociedad.push({"label":tipo.DESCRIPCION,"value":tipo.ID_TIPO_SOCIEDAD})
        
            this.setState({tiposSociedad:tiposSociedad})
            })
    }
    cargarTipoCapital=async()=>{
        let tiposCapital=[];
         //TIPOS CAPITAL SOCIAL
        let tiposCapitalSoc= await this.props.client
         .query({
             query: gql`
             {
                 tiposCapitalSocial{ID_TIPO_CAPITAL_SOCIAL,DESCRIPCION}
             }
             `,
             fetchPolicy:'network-only'
         }).then(result=>{ 
             return result.data.tiposCapitalSocial
         })    
            console.log(tiposCapitalSoc)
             tiposCapitalSoc.forEach(tipo=>{
             tiposCapital.push({"label":tipo.DESCRIPCION,"value":tipo.ID_TIPO_CAPITAL_SOCIAL})
             
             this.setState({tiposCapitalSocial:tiposCapital})
             }) 
    }
    generarImprimirForm= async()=>{
        this.setState({
            cargadoImprimir:true,
            modalImprimir:true
        }, async()=>{
            this.setState({ cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirForma(), document.getElementById('generarImprimir-pdf'))
            })
        })
    }
    componentDidMount(){

        this.setState({cargando: true}, async()=>{
            await this.cargarFormaSocietaria()
            await this.cargarTipoCapital()
            await this.cargarTipoSociedad()
            this.setState({cargando: false})
        })
    }
    ImprimirForma=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("estructurasocietaria.forma",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.hayTipoSociedad===true?
                            
                                <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("estructurasocietaria.tiposociedad",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.tipoSociedadSeleccionada.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("estructurasocietaria.tipoparcipaciones",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.tipoCapitalSeleccionado.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("estructurasocietaria.fecha",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{moment(this.state.fechaFinalizacion).format('DD-MM-YYYY')}</Text>
                                </View>
                                
                            </View>
                        
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
 )

    render() {

        document.title ="LexControl | Estructura societaria"

        const seleccionTipoSociedad=(value)=>{
            this.setState({tipoSociedadSeleccionada:value})
        }

        const seleccionTipoCapital=(value)=>{
            this.setState({tipoCapitalSeleccionado:value})
        }

        const cambiarFecha=(value)=>{
            this.setState({fechaFinalizacion:value})
        }

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({modalAdvertenciaAbierto:false})

            //console.log(this.state)
            if(!this.state.tipoSociedadSeleccionada){
               alert(this.props.i18next.t("estructurasocietaria.selecttiposociedad",{ns:"page"}))
               return
            }
            if(!this.state.tipoCapitalSeleccionado){
                alert(this.props.i18next.t("estructurasocietaria.selecttipoparticipacion",{ns:"page"}))
                return
            }
            var fechaFinalizacion=(this.state.fechaFinalizacion?'"'+moment(this.state.fechaFinalizacion).format('YYYY-MM-DD')+'"':null)
            //console.log(fechaFinalizacion)
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarPersonaJuridica(ID_PERSONA:${this.state.idPersona},VALORES:{ID_TIPO_CAPITAL_SOCIAL:${this.state.tipoCapitalSeleccionado.value},ID_TIPO_SOCIEDAD:${this.state.tipoSociedadSeleccionada.value},FECHA_FINALIZACION:${this.state.tipoSociedadSeleccionada?(this.state.tipoSociedadSeleccionada.value===20?fechaFinalizacion:null):null}}){ID_PERSONA}}
                `
            }).then(result=>{
               
                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                if(this.state.tipoCapitalSeleccionado)this.setState({hayTipoCapital:true})
                if(this.state.tipoSociedadSeleccionada)this.setState({hayTipoSociedad:true})

                this.cargarFormaSocietaria()

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })
            
        }

        function getMomentFormatter(format) {
            // note that locale argument comes from locale prop and may be undefined
            return {
                formatDate: (date, locale) => moment(date).locale(locale).format(format),
                parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
                placeholder: format,
            }
        }
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false
            })
        }
        
        return (
            <>
                {this.state.cargando === true?
                    <Cargando />
                    :
                    <>
                     <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={this.props.i18next.t("print",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '600px'}} id='generarImprimir-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                        <Dialog 
                        transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={()=>{this.setState({modalAdvertenciaAbierto:false})}}
                            title={this.props.i18next.t("delete",{ns:"global"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalAdvertenciaAbierto}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <h2>{this.props.i18next.t("estructurasocietaria.atencion",{ns:"page"})}</h2>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={guardarCambios}
                                        >
                                            {this.props.i18next.t("yes",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={()=>{this.setState({modalAdvertenciaAbierto:false})}}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>

                        <div className="pixelAlrededorGrande">
                            <div className="tablaSesenta">
                                <Card interactive={true} elevation={Elevation.TWO}>
                                    <FormGroup
                                        label={this.props.i18next.t("estructurasocietaria.tiposociedad",{ns:"page"})+":"}
                                    >
                                        <Select 
                                            onChange={seleccionTipoSociedad}
                                            options={this.state.tiposSociedad}
                                            value={this.state.tipoSociedadSeleccionada}
                                            isDisabled={this.state.hayTipoSociedad}
                                        />                
                                    </FormGroup>
                                    <FormGroup
                                        label={this.props.i18next.t("estructurasocietaria.tipoparticipaciones",{ns:"page"})+":"}
                                    >
                                        <Select 
                                            onChange={seleccionTipoCapital}
                                            options={this.state.tiposCapitalSocial}
                                            value={this.state.tipoCapitalSeleccionado}
                                            isDisabled={this.state.hayTipoCapital}

                                        /> 
                                    </FormGroup>
                                    <FormGroup
                                        label={this.props.i18next.t("estructurasocietaria.fecha",{ns:"page"})+":"}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}disabled={this.state.tipoSociedadSeleccionada?(this.state.tipoSociedadSeleccionada.value===20 && !this.state.hayTipoCapital?false:true):true} onChange={cambiarFecha} {...getMomentFormatter("DD-MM-YYYY")} locale="es" value={this.state.fechaFinalizacion?this.state.fechaFinalizacion:new Date()} />
                                    
                                    </FormGroup>
                                    <Button 
                                        large={true}
                                        alignText="right"
                                        icon="floppy-disk"
                                        text={this.props.i18next.t("guardar",{ns:"global"})}
                                        disabled={this.state.hayTipoCapital}
                                        onClick={()=>{this.setState({modalAdvertenciaAbierto:true})}}
                                        className="bp3-intent-danger iconoDerecha bp3-minimal"
                                    />
                                    <Button 
                                        large={true}
                                        alignText="right"
                                        icon="print"
                                        text={this.props.i18next.t("print",{ns:"global"})}
                                        disabled={this.state.imprimirActivado}
                                        onClick={()=>this.generarImprimirForm()}
                                        className="bp3-intent-danger iconoDerecha bp3-minimal"
                                    />
                                    <br />
                                    <br />
                                </Card>
                            </div>
                            <div className="tablaCuarenta">
                            <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("estructurasocietaria.titleley1",{ns:"page"})} <br /> {this.props.i18next.t("estructurasocietaria.titleley2",{ns:"page"})}</Callout>
                            <br />
                                <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544", "_blank")}>{this.props.i18next.t("estructurasocietaria.ley1",{ns:"page"})}</AnchorButton>
                                <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544", "_blank")}>{this.props.i18next.t("estructurasocietaria.ley2",{ns:"page"})}</AnchorButton>
                            </Card>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default  withApollo(FormaSocietaria)
