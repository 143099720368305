import React, {Component} from 'react';
import {
    Card,
    Elevation,
    Callout,
    Navbar,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    Classes,
    Tooltip,
    AnchorButton,
    Intent,
    Tabs,
    Tab,
    Spinner
} from '@blueprintjs/core';
import gql from "graphql-tag";
import {withApollo} from 'react-apollo';
import { Cargando } from './Cargando';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatosContrato from "./Elementos/Contrato";
import PartesContrato from "./Elementos/Partes";
import ExtraContrato from "./Elementos/ExtraContrato";
import moment from 'moment';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import styles from './stylesPrint';
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class ObligacionesContractuales extends Component {
    state={
        cargando: false,
        contratos: [],
        cargandoDerechos:false,
        cargandoObligaciones:false,
        contratoSeleccionado:null,
        modalNuevoAbierto:false,
        derechos:[],
        obligaciones:[],
        derechoSeleccionado:null,
        obligacionSeleccionado:null,
        modalNuevoAbiertoDerecho:false,
        modalNuevoAbiertoObligacion:false,
        nuevoContrato:false,
        nuevoDerecho:false,
        nuevaObligacion:false,
        tiposContrato:[],
        pestañaDialog:"datos-contrato",
        animacion:true,
        personas:[],
        partes:[],
        datosEmpresa:null,
        logotipo:null,
        modalImprimir:false,
        cargandoImprimir:false,
        title:''
    }

    componentDidMount=async()=>{
        this.setState({ 
            cargando: true,
            cargandoDerechos:true,
            cargandoObligaciones:true
        },async ()=>{
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarContratos();
            await this.cargarTiposContrato();
            await this.cargarPersonas();

            this.setState({
                cargando: false,
                cargandoDerechos:false,
                cargandoObligaciones:false
            });
        });
    }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        
        this.setState({
            datosEmpresa:datosEmpresa,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
    }

    generarPDF= async () =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            this.setState({cargandoImprimir:false,title:'Imprimir Contratos'}, () => {
                ReactDOM.render(this.ImprimirContratos(), document.getElementById("generarInforme-pdf"))
            })
        })
    }

    generarPDFDerechos= async () =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            this.setState({cargandoImprimir:false,title:'Imprimir Derechos'}, () => {
                ReactDOM.render(this.ImprimirDerechos(), document.getElementById("generarInforme-pdf"))
            })
        })
    }

    generarPDFObligaciones= async () =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            this.setState({cargandoImprimir:false,title:'Imprimir Obligaciones'}, () => {
                ReactDOM.render(this.ImprimirObligaciones(), document.getElementById("generarInforme-pdf"))
            })
        })
    }

    ImprimirContratos=() => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
            <Page size='A4' orientation="landscape" style={styles.page}>
                <View style={styles.body}>
                     <View fixed style={styles.row}>
                         <Image style={styles.imageHead} src={this.state.logotipo?this.state.logotipo:pantallaInicio} />
                     </View>
                <View fixed style={styles.rowHead}>
                  <Text style={styles.labelHead}>EMPRESA:</Text>
                  <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
              </View>
              <View fixed style={styles.rowHeadTwo}>
                  <Text style={styles.labelHead}>INFORME:</Text>
                  <Text style={styles.text}>CONTRATOS</Text>
              </View>
              <View style={styles.rowTitleOne}>
                  <Text style={styles.labelTitle}>INFORME DE CONTRATOS</Text>
              </View>
              {this.state.contratos?this.state.contratos.map((contrato, index) => {
                  return(
                    <View key={index}>   
                         {index===0 && (                   
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>DENOMINACION</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>ID_RIESGO</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>FECHA_FIRMA</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>VIGENCIA</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>EXTINCION</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>FECHA_EXTINCION</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>EXTRACTO</Text>
                            </View>
                         )}                   
                        <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{contrato.DENOMINACION?contrato.DENOMINACION:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{contrato.ID_RIESGO?contrato.ID_RIESGO:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{contrato.FECHA_FIRMA?moment(contrato.FECHA_FIRMA).format('DD/MM/YYYY'):''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{contrato.VIGENCIA?contrato.VIGENCIA:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{contrato.EXTINCION?contrato.EXTINCION:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{contrato.FECHA_EXTINCION?moment(contrato.FECHA_EXTINCION).format('DD/MM/YYYY'):''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{contrato.EXTRACTO?contrato.EXTRACTO:''}</Text>
                        </View>
                    </View>
                  )
              }):<></>}
                           
             </View> 
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                        fixed
                      />
                    <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                    />
                    <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
                </Page>
        </Document>
    </PDFViewer>

    )

    ImprimirDerechos=() => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.body}>
                     <View fixed style={styles.row}>
                         <Image style={styles.imageHead} src={this.state.logotipo?this.state.logotipo:pantallaInicio} />
                     </View>
                <View fixed style={styles.rowHead}>
                  <Text style={styles.labelHead}>EMPRESA:</Text>
                  <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
              </View>
              <View fixed style={styles.rowHeadTwo}>
                  <Text style={styles.labelHead}>INFORME:</Text>
                  <Text style={styles.text}>CONTRATOS DERECHOS</Text>
              </View>
              <View style={styles.rowTitleOne}>
                  <Text style={styles.labelTitle}>INFORME DE DERECHOS</Text>
              </View>
              {this.state.derechos?this.state.derechos.map((derecho, index) => {
                  return(
                    <View key={index}>   
                         {index===0 && (                   
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'65%'}}>DESCRIPCIÓN</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>PLAZO</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>CUMPLIMIENTO</Text>
                            </View>
                         )}                   
                        <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'65%'}}>{derecho.DESCRIPCION?derecho.DESCRIPCION:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{derecho.PLAZO?derecho.PLAZO:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{derecho.CUMPLIMIENTO?derecho.CUMPLIMIENTO:''}</Text>
                        </View>
                    </View>
                  )
              }):<></>}
                           
             </View> 
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                        fixed
                      />
                    <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                    />
                    <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
                </Page>
        </Document>
    </PDFViewer>

    )

    ImprimirObligaciones=() => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.body}>
                     <View fixed style={styles.row}>
                         <Image style={styles.imageHead} src={this.state.logotipo?this.state.logotipo:pantallaInicio} />
                     </View>
                <View fixed style={styles.rowHead}>
                  <Text style={styles.labelHead}>EMPRESA:</Text>
                  <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
              </View>
              <View fixed style={styles.rowHeadTwo}>
                  <Text style={styles.labelHead}>INFORME:</Text>
                  <Text style={styles.text}>CONTRATOS OBLIGACIONES</Text>
              </View>
              <View style={styles.rowTitleOne}>
                  <Text style={styles.labelTitle}>INFORME DE OBLIGACIONES</Text>
              </View>
              {this.state.obligaciones?this.state.obligaciones.map((obligacion, index) => {
                  return(
                    <View key={index}>   
                         {index===0 && (                   
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'65%'}}>DESCRIPCIÓN</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>PLAZO</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>CUMPLIMIENTO</Text>
                            </View>
                         )}                   
                        <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'65%'}}>{obligacion.DESCRIPCION?obligacion.DESCRIPCION:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{obligacion.PLAZO?obligacion.PLAZO:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'15%'}}>{obligacion.CUMPLIMIENTO?obligacion.CUMPLIMIENTO:''}</Text>
                        </View>
                    </View>
                  )
              }):<></>}
                           
             </View> 
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                        fixed
                      />
                    <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                    />
                    <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
                </Page>
        </Document>
    </PDFViewer>

    )

    cargarContratos=async()=>{
        let contratos=await this.props.client.query({
            query:gql`{contratos{
                ID_CONTRATO,
                DENOMINACION,
                ID_RIESGO,
                FECHA_FIRMA,
                VIGENCIA,
                EXTINCION,
                FECHA_EXTINCION,
                EXTRACTO,
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.contratos))
        })

        this.setState({contratos:contratos}) 

    }

    recargarDerechos=()=>{
        this.setState({
            cargandoDerechos:true
        },async ()=>{
            await this.cargarDerechos(this.state.contratoSeleccionado.ID_CONTRATO);
            this.setState({cargandoDerechos:false});
        })
    }

    recargarObligaciones=()=>{
        this.setState({
            cargandoObligaciones:true
        },async ()=>{
            await this.cargarObligaciones(this.state.contratoSeleccionado.ID_CONTRATO);
            this.setState({cargandoObligaciones:false});
        })
    }

    cargarDerechos=async(idContrato)=>{
        let derechos=await this.props.client.query({
            query:gql`
            {
            contratosDerechos(FILTROS:{ID_CONTRATO:${idContrato}}){
              ID_DERECHO
              DESCRIPCION,
              PLAZO,
              CUMPLIMIENTO
            }
            }
          `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.contratosDerechos))
        })

        this.setState({derechos:derechos}) 

    }

    cargarObligaciones=async(idContrato)=>{
        let obligaciones=await this.props.client.query({
            query:gql`
            {
            contratosObligaciones(FILTROS:{ID_CONTRATO:${idContrato}}){
              ID_OBLIGACION
              DESCRIPCION,
              PLAZO,
              CUMPLIMIENTO
            }
            }
          `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.contratosObligaciones))
        })

        this.setState({obligaciones:obligaciones}) 

    }

    cargarPersonas=async ()=>{

        let personas=[]

        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},NOMBRE,APELLIDO1,APELLIDO2,DOMICILIO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            result.data.personasFisicas.forEach(async(persona)=>{
                let nif = await this.props.client
                .query({
                    query: gql`
                    {
                        documentosIdentificativos(
                        LIMIT: 1
                        FILTROS: { ID_TIPO_DOCUMENTO: 1, ID_PERSONA:  ${persona.ID_PERSONA}}
                        ) {
                        ID_DOCUMENTO_IDENTIFICATIVO,VALOR
                        }
                    }
                    `,fetchPolicy:'network-only'
                })
                .then(result => {
                    if(result.data.documentosIdentificativos.length>0){
                        return result.data.documentosIdentificativos[0].VALOR;
                    } else {
                        return '';
                    }
                });
                
                personas.push({
                    "value":persona.ID_PERSONA,
                    "ID_PERSONA":persona.ID_PERSONA,
                    "label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,
                    tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA,
                    nif:nif,
                    domicilio:persona.DOMICILIO
                }) 

            })
            
        })

        await this.props.client.query({
            query:gql`{personasJuridicas{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},RAZON_SOCIAL,DOMICILIO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            result.data.personasJuridicas.forEach(async(persona)=>{
                let cif = await this.props.client
                .query({
                    query: gql`
                    {
                        documentosIdentificativos(
                        LIMIT: 1
                        FILTROS: { ID_TIPO_DOCUMENTO: 4, ID_PERSONA:  ${persona.ID_PERSONA}}
                        ) {
                        ID_DOCUMENTO_IDENTIFICATIVO,VALOR
                        }
                    }
                    `,fetchPolicy:'network-only'
                })
                .then(result => {
                    if(result.data.documentosIdentificativos.length>0){
                        return result.data.documentosIdentificativos[0].VALOR;
                    } else {
                        return '';
                    }
                });
                personas.push({
                    "value":persona.ID_PERSONA,
                    "ID_PERSONA":persona.ID_PERSONA,
                    "label":persona.RAZON_SOCIAL,
                    tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA,
                    nif:cif,
                    domicilio:persona.DOMICILIO
                }) 

            })

        })

        this.setState({
            personas:personas
        })

    }

    setPartes=(partes)=>{
        this.setState({
            partes:partes
        });
    }

    cargarTiposContrato=async()=>{
        await this.props.client.query({
            query:gql`{complianceRiesgos(FILTROS:{ID_BLOQUE:3}){
                ID_RIESGO,
                ID_BLOQUE,
                TIPO,
                DESCRIPCION,
                CODIGO_RIESGO,
                TITULO}}
            `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            let tipos=[];
            result.data.complianceRiesgos.forEach(tipo=>{
                tipos.push({
                    label: tipo.TITULO,
                    value: tipo.ID_RIESGO,
                })
            })
            this.setState({tiposContrato:tipos}) 
        })   
    }

    obtenerNombreMiembros=async(miembro)=>{
        let personaSel=this.state.personas.find(persona=>{return (persona.ID_PERSONA===miembro.ID_PERSONA && persona.tipo===miembro.TIPO_PERSONA.ID_TIPO_PERSONA)});
        miembro.label=personaSel.label;
        miembro.domicilio=personaSel.domicilio;
        miembro.nif=personaSel.nif;        
        
    }

    cargarPartes=async()=>{
        if (this.state.contratoSeleccionado) {
            this.props.client.query({
                query:gql`{contratosPartes(FILTROS:{ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}}){
                    ID_CONTRATO,
                    ID_PARTE,
                    PARTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}},
                    CALIDAD
                }}`,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
                let partes=[]
                for(let parte of JSON.parse(JSON.stringify(result.data.contratosPartes))){
                    await this.obtenerNombreMiembros(parte.PARTE)
                    await partes.push({
                        value:parte.ID_PARTE,
                        label:parte.PARTE.label,
                        domicilio:parte.PARTE.domicilio,
                        nif:parte.PARTE.nif,
                        calidad:parte.CALIDAD
                    })
                }
                this.setPartes(partes);
            })
        }
    }

    render() {
        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalNuevoAbiertoDerecho:false,
                modalNuevoAbiertoObligacion:false,
                cargando:false,
                cargandoDerechos:false,
                cargandoObligaciones:false
            })
        }

        const anadirContrato = async() => {
            this.setState({pestañaDialog:"datos-contrato"});
            if(!this.DatosContrato.state.denominacion){
                alert("Debe ingresar denominación");
                return
            }
            if(!this.DatosContrato.state.tipo_contrato){
                alert("Debe seleccionar tipo de contrato");
                return
            }
            if(!this.DatosContrato.state.fechaFirma){
                alert("Debe ingresar fecha de firma");
                return
            }
            if(!this.DatosContrato.state.vigencia){
                alert("Debe ingresar vigencia");
                return
            }
            if(!this.DatosContrato.state.extincion){
                alert("Debe seleccionar extinción");
                return
            }
            if(!this.DatosContrato.state.fechaExtincion){
                alert("Debe ingresar fecha de extinción");
                return
            }
            if(!this.DatosContrato.state.extracto){
                alert("Debe ingresar extracto");
                return
            }

            if(this.state.partes.length===0){
                this.setState({pestañaDialog:"partes"});
                alert("El contrato debe tener al menos una persona parte del mismo")
                return
            }

            this.DatosContrato.state.cargando=true;

            //creamos el contrato.
            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearContrato(VALORES:{
                        DENOMINACION:"${this.DatosContrato.state.denominacion}"
                        ID_RIESGO:${this.DatosContrato.state.tipo_contrato.value}
                        FECHA_FIRMA:"${moment(this.DatosContrato.state.fechaFirma).format("YYYY-MM-DD")}"
                        VIGENCIA:"${this.DatosContrato.state.vigencia}"
                        EXTINCION:"${this.DatosContrato.state.extincion.value}"
                        FECHA_EXTINCION:"${moment(this.DatosContrato.state.fechaExtincion).format("YYYY-MM-DD")}"
                        EXTRACTO:"${this.DatosContrato.state.extracto}"
                    }){ID_CONTRATO}}
                `
            }).then(async result=>{
                if(this.state.partes.length>0){
                    for(let parte of this.state.partes){
                        //creamos el asistente
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearContratoParte(VALORES:{
                                    ID_CONTRATO:${result.data.crearContrato.ID_CONTRATO}
                                    ID_PARTE:${parte.value}
                                    CALIDAD:"${parte.calidad}"
                                }){ID_CONTRATO,ID_PARTE,CALIDAD}}
                            `
                        })

                    }
                }
            
                alert("El contrato ha sido creado correctamente");
                this.setState({ 
                    cargando: true,
                    cargandoDerechos:true,
                    cargandoObligaciones:true
                },async ()=>{
                    await this.cargarContratos();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.DatosContrato.state.cargando=false;
                this.setState({ 
                    cargando: true,
                    cargandoDerechos:true,
                    cargandoObligaciones:true
                },async ()=>{
                    await this.cargarContratos();
                    await cerrarModal()
                });
                alert("Ha ocurrido un error al crear contrato.Pongase en contacto con el servicio tecnico")
            });
        }

        const actualizarContrato = async() => {
            this.setState({pestañaDialog:"datos-contrato"});
            if(!this.DatosContrato.state.denominacion){
                alert("Debe ingresar denominación");
                return
            }
            if(!this.DatosContrato.state.tipo_contrato){
                alert("Debe seleccionar tipo de contrato");
                return
            }
            if(!this.DatosContrato.state.fechaFirma){
                alert("Debe ingresar fecha de firma");
                return
            }
            if(!this.DatosContrato.state.vigencia){
                alert("Debe ingresar vigencia");
                return
            }
            if(!this.DatosContrato.state.extincion){
                alert("Debe seleccionar extinción");
                return
            }
            if(!this.DatosContrato.state.fechaExtincion){
                alert("Debe ingresar fecha de extinción");
                return
            }
            if(!this.DatosContrato.state.extracto){
                alert("Debe ingresar extracto");
                return
            }
            if(this.state.partes.length===0){
                this.setState({pestañaDialog:"partes"});
                alert("El contrato debe tener al menos una persona parte del mismo")
                return
            }
            this.DatosContrato.state.cargando=true;
            
            //creamos el contrato.
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarContrato(
                        ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO},
                        VALORES:{
                            DENOMINACION:"${this.DatosContrato.state.denominacion}"
                            ID_RIESGO:${this.DatosContrato.state.tipo_contrato.value}
                            FECHA_FIRMA:"${moment(this.DatosContrato.state.fechaFirma).format("YYYY-MM-DD")}"
                            VIGENCIA:"${this.DatosContrato.state.vigencia}"
                            EXTINCION:"${this.DatosContrato.state.extincion.value}"
                            FECHA_EXTINCION:"${moment(this.DatosContrato.state.fechaExtincion).format("YYYY-MM-DD")}"
                            EXTRACTO:"${this.DatosContrato.state.extracto}"
                    }){ID_CONTRATO}}
                `
            }).then(async result=>{
                //eliminamos los antiguos partes.
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{eliminarContratoParte(ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}){ID_CONTRATO}}
                    `
                });
                if(this.state.partes.length>0){
                    for(let parte of this.state.partes){
                        //creamos el asistente
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearContratoParte(VALORES:{
                                    ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}
                                    ID_PARTE:${parte.value}
                                    CALIDAD:"${parte.calidad}"
                                }){ID_CONTRATO,ID_PARTE,CALIDAD}}
                            `
                        }).catch(err=>{
                            alert("Ha ocurrido un problema al crear parte del contrato.Pongase en contacto con el soporte técnico")
                            this.DatosContrato.state.cargando=false;
                            this.setState({ 
                                cargando: true,
                                cargandoDerechos:true,
                                cargandoObligaciones:true
                            },async ()=>{
                                await this.cargarContratos();
                                await cerrarModal()
                            });
                            console.log(err)
                        })

                    }
                }
                alert("El contrato ha sido actualizado correctamente");
                this.setState({ 
                    cargando: true,
                    cargandoDerechos:true,
                    cargandoObligaciones:true
                },async ()=>{
                    await this.cargarContratos();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err)
                this.DatosContrato.state.cargando=false;
                this.setState({ 
                    cargando: true,
                    cargandoDerechos:true,
                    cargandoObligaciones:true
                },async ()=>{
                    await this.cargarContratos();
                    await cerrarModal()
                });
                alert("Ha ocurrido un error al actualizar el contrato.Pongase en contacto con el servicio tecnico")
            })
        }

        const anadirDerecho = async() => {
            if(!this.ExtraContrato.state.descripcion){
                alert("Debe ingresar descripcion");
                return
            }
            if(!this.ExtraContrato.state.plazo){
                alert("Debe ingresar plazo");
                return
            }
            if(!this.ExtraContrato.state.cumplimiento){
                alert("Debe seleccionar nivel de cumplimiento");
                return
            }
            this.ExtraContrato.state.cargando=true;

            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearContratoDerecho(VALORES:{
                        ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}
                        DESCRIPCION:${this.ExtraContrato.state.descripcion?'"""'+this.ExtraContrato.state.descripcion+'"""':null}
                        PLAZO:"${this.ExtraContrato.state.plazo}"
                        CUMPLIMIENTO:${this.ExtraContrato.state.cumplimiento}
                    }){ID_DERECHO}}
                `
            }).then(async ()=>{

                alert("El derecho ha sido creado correctamente");
                this.setState({ 
                    cargandoDerechos:true
                },async ()=>{
                    await this.recargarDerechos();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.ExtraContrato.state.cargando=true;
                this.setState({ 
                    cargandoDerechos: false,
                },async ()=>{
                    await cerrarModal()
                });
                alert("Ha ocurrido un error al crear derecho.Pongase en contacto con el servicio tecnico")
            })
        }

        const actualizarDerecho = async() => {
            if(!this.ExtraContrato.state.descripcion){
                alert("Debe ingresar descripcion");
                return
            }
            if(!this.ExtraContrato.state.plazo){
                alert("Debe ingresar plazo");
                return
            }
            if(!this.ExtraContrato.state.cumplimiento){
                alert("Debe seleccionar nivel de cumplimiento");
                return
            }
            this.ExtraContrato.state.cargando=true;
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarContratoDerecho(
                        ID_DERECHO:${this.state.derechoSeleccionado.ID_DERECHO},
                        VALORES:{
                            ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}
                            DESCRIPCION:${this.ExtraContrato.state.descripcion?'"""'+this.ExtraContrato.state.descripcion+'"""':null}
                            PLAZO:"${this.ExtraContrato.state.plazo}"
                            CUMPLIMIENTO:${this.ExtraContrato.state.cumplimiento}
                    }){ID_DERECHO}}
                `
            }).then(async ()=>{
            
                alert("El derecho ha sido actualizado correctamente");
                this.setState({ 
                    cargandoDerechos:true
                },async ()=>{
                    await this.recargarDerechos();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.ExtraContrato.state.cargando=true;
                this.setState({ 
                    cargandoDerechos: false,
                },async ()=>{
                    await cerrarModal()
                });
                alert("Ha ocurrido un error al actualizar derecho.Pongase en contacto con el servicio tecnico")
            })
        }

        const anadirObligacion = async() => {
            if(!this.ExtraContrato.state.descripcion){
                alert("Debe ingresar descripcion");
                return
            }
            if(!this.ExtraContrato.state.plazo){
                alert("Debe ingresar plazo");
                return
            }
            if(!this.ExtraContrato.state.cumplimiento){
                alert("Debe seleccionar nivel de cumplimiento");
                return
            }
            this.ExtraContrato.state.cargando=true;

            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearContratoObligacion(VALORES:{
                        ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}
                        DESCRIPCION:${this.ExtraContrato.state.descripcion?'"""'+this.ExtraContrato.state.descripcion+'"""':null}
                        PLAZO:"${this.ExtraContrato.state.plazo}"
                        CUMPLIMIENTO:${this.ExtraContrato.state.cumplimiento}
                    }){ID_OBLIGACION}}
                `
            }).then(async ()=>{

                alert("La obligación ha sido creada correctamente");
                this.setState({ 
                    cargandoObligaciones:true
                },async ()=>{
                    await this.recargarObligaciones();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.ExtraContrato.state.cargando=true;
                this.setState({ 
                    cargandoObligaciones: false,
                },async ()=>{
                    await cerrarModal()
                });
                alert("Ha ocurrido un error al crear obligación.Pongase en contacto con el servicio tecnico")
            })
        }

        const actualizarObligacion = async() => {
            if(!this.ExtraContrato.state.descripcion){
                alert("Debe ingresar descripcion");
                return
            }
            if(!this.ExtraContrato.state.plazo){
                alert("Debe ingresar plazo");
                return
            }
            if(!this.ExtraContrato.state.cumplimiento){
                alert("Debe seleccionar nivel de cumplimiento");
                return
            }
            this.ExtraContrato.state.cargando=true;
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarContratoObligacion(
                        ID_OBLIGACION:${this.state.obligacionSeleccionado.ID_OBLIGACION},
                        VALORES:{
                            ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}
                            DESCRIPCION:${this.ExtraContrato.state.descripcion?'"""'+this.ExtraContrato.state.descripcion+'"""':null}
                            PLAZO:"${this.ExtraContrato.state.plazo}"
                            CUMPLIMIENTO:${this.ExtraContrato.state.cumplimiento}
                    }){ID_OBLIGACION}}
                `
            }).then(async ()=>{
            
                alert("La obligación ha sido actualizada correctamente");
                this.setState({ 
                    cargandoObligaciones:true
                },async ()=>{
                    await this.recargarObligaciones();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.ExtraContrato.state.cargando=true;
                this.setState({ 
                    cargandoObligaciones: false,
                },async ()=>{
                    await cerrarModal()
                });
                alert("Ha ocurrido un error al actualizar obligación.Pongase en contacto con el servicio tecnico")
            });
        }

        const abrirModalNuevoDerecho = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            /*if(!this.state.contratoSeleccionado){
                alert("Debe seleccionar un contrato")
                return
            }*/
            
            if (!window.confirm('¿Desea agregar nuevo derecho?')) {
                return
            }

            this.setState({
                modalNuevoAbiertoDerecho: true,
                derechoSeleccionado:null,
                cargandoDerechos:true,
                nuevoDerecho:true
            })

        }

        const abrirModalNuevoObligaciones = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.contratoSeleccionado){
                alert("Debe seleccionar un contrato")
                return
            }
            
            if (!window.confirm('¿Desea agregar nueva obligación?')) {
                return
            }

            this.setState({
                modalNuevoAbiertoObligacion: true,
                obligacionSeleccionado:null,
                cargandoObligaciones:true,
                nuevaObligacion:true
            })

        }

        const abrirModalNuevo = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if (!window.confirm('¿Desea agregar nuevo contrato?')) {
                return
            }

            this.setState({
                modalNuevoAbierto: true,
                contratoSeleccionado:null,
                cargando:true,
                cargandoDerechos:true,
                cargandoObligaciones:true,
                nuevoContrato:true,
                pestañaDialog:"datos-contrato"
            })

        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.contratoSeleccionado){
                alert("Debe seleccionar un contrato")
                return
            }

            if (!window.confirm('¿Desea modificar el contrato seleccionado?')) {
                return
            }

            this.setState({
                modalNuevoAbierto: true,
                nuevoContrato:false
            });

        }

        const abrirModalEditarObligaciones = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.contratoSeleccionado){
                alert("Debe seleccionar un contrato")
                return
            }

            if(!this.state.obligacionSeleccionado){
                alert("Debe seleccionar una obligación")
                return
            }

            if (!window.confirm('¿Desea modificar la obligación seleccionada?')) {
                return
            }

            this.setState({
                modalNuevoAbiertoObligacion: true,
                nuevaObligacion:false
            });

        }

        const abrirModalEditarDerecho = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.contratoSeleccionado){
                alert("Debe seleccionar un contrato")
                return
            }

            if(!this.state.derechoSeleccionado){
                alert("Debe seleccionar un derecho")
                return
            }

            if (!window.confirm('¿Desea modificar el derecho seleccionado?')) {
                return
            }

            this.setState({
                modalNuevoAbiertoDerecho: true,
                nuevoDerecho:false
            });

        }

        const eliminarContrato = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.contratoSeleccionado){
                alert("Debe seleccionar un contrato");
                return
            }

            if (!window.confirm('¿Desea eliminar el contrato seleccionado?')) {
                return
            }

            this.props.client.mutate({
                mutation:gql`mutation{eliminarContrato(ID_CONTRATO:${this.state.contratoSeleccionado.ID_CONTRATO}){ID_CONTRATO}}`
            }).then(async ()=>{
               
                alert("El registro se ha eliminado correctamente.")
                this.setState({
                    contratoSeleccionado:null,
                    cargando: true,
                    cargandoDerechos:true,
                    cargandoObligaciones:true
                },async ()=>{
                    await this.cargarContratos();
                    await this.recargarDerechos();
                    await this.recargarObligaciones();
                    await cerrarModal();
                })
                
            }).catch(()=>{
                alert("Ha ocurrido un error al eliminar contrato.Pongase en contacto con el soporte tecnico")
            })
             
            
        }

        const eliminarContratoDerecho = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.derechoSeleccionado){
                alert("Debe seleccionar un derecho");
                return
            }

            if (!window.confirm('¿Desea eliminar el derecho seleccionado?')) {
                return
            }

            this.props.client.mutate({
                mutation:gql`mutation{eliminarContratoDerecho(ID_DERECHO:${this.state.derechoSeleccionado.ID_DERECHO}){ID_DERECHO}}`
            }).then(async ()=>{
               
                alert("El registro se ha eliminado correctamente.")
                this.setState({
                    derechoSeleccionado:null,
                    cargandoDerechos: true
                },async ()=>{
                    await this.recargarDerechos();
                    await cerrarModal();
                })
                
            }).catch(()=>{
                alert("Ha ocurrido un error al eliminar el derecho.Pongase en contacto con el soporte tecnico")
            })
               
            
        }

        const eliminarContratoObligaciones = async() => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.obligacionSeleccionado){
                alert("Debe seleccionar una obligación");
                return
            }

            if (!window.confirm('¿Desea eliminar la obligación seleccionada?')) {
                return
            }

            this.props.client.mutate({
                mutation:gql`mutation{eliminarContratoObligacion(ID_OBLIGACION:${this.state.obligacionSeleccionado.ID_OBLIGACION}){ID_OBLIGACION}}`
            }).then(async ()=>{
               
                alert("El registro se ha eliminado correctamente.")
                this.setState({
                    obligacionSeleccionado:null,
                    cargandoObligaciones: true
                },async ()=>{
                    await this.recargarObligaciones();
                    await cerrarModal();
                })
                
            }).catch(()=>{
                alert("Ha ocurrido un error al eliminar el oblicación.Pongase en contacto con el soporte tecnico")
            })   
            
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    contratoSeleccionado: row,
                    cargandoDerechos:true,
                    cargandoObligaciones:true
                },async()=>{
                    await this.cargarDerechos(this.state.contratoSeleccionado.ID_CONTRATO);
                    await this.cargarObligaciones(this.state.contratoSeleccionado.ID_CONTRATO);
                    await this.cargarPartes();
                    this.setState({
                        cargandoDerechos:false,
                        cargandoObligaciones:false
                    });
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectDerecho=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    derechoSeleccionado: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const handleOnSelectObligacion=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    obligacionSeleccionado: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowDerecho = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectDerecho,
            style: { backgroundColor: '#c8e6c9' }
        };

        const selectRowObligacion = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelectObligacion,
            style: { backgroundColor: '#c8e6c9' }
        };

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando { from } a { to } de { size } Resultados
            </span>
        );

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }
        
        const columns = [{
            dataField: 'DENOMINACION',
            text: 'Denominación',
            sort: true
            }, {
            dataField: 'ID_RIESGO',
            text: 'Tipo',
            sort: true
            }, {
            dataField: 'FECHA_FIRMA',
            text: 'Fec. firma',
            formatter: dateFormatter
            }, {
            dataField: 'VIGENCIA',
            text: 'Vigencia',
            sort: true
            }, {
            dataField: 'EXTINCION',
            text: 'Extinción',
            sort: true
            }, {
            dataField: 'FECHA_EXTINCION',
            text: 'Fec. extinción',
            formatter: dateFormatter
            }, {
            dataField: 'EXTRACTO',
            text: 'Extracto',
            sort: true
            }
        ];

        const columnsSons = [{
            dataField: 'DESCRIPCION',
            text: 'Descripción',
            sort: true
            }, {
            dataField: 'PLAZO',
            text: 'Plazo',
            sort: true
            }, {
            dataField: 'CUMPLIMIENTO',
            text: 'Cumplimiento',
            sort: true
            }
        ];

        const options = {
            paginationSize: 4,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'Primero',
            prePageText: 'Atrás',
            nextPageText: 'Próxima',
            lastPageText: 'Última',
            nextPageTitle: 'Primera página',
            prePageTitle: 'página previa',
            firstPageTitle: 'Siguiente página',
            lastPageTitle: 'Última página',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
              }] // A numeric array is also available. the purpose of above example is custom the text
        };

        const cambiarPestaña = (navbarTabId) => this.setState({ pestañaDialog: navbarTabId });

        const cerrarModalImprimir = () => {
            this.setState({
                modalImprimir:false
            })
        }

        return (
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.state.title}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '500px'}} id='generarInforme-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    className="dialog-ancho dialog-scroll"
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoContrato?"Añadir nuevo contrato":"Editar contrato"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                >
                    <div id="dialogo-contratos">
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <Tabs 
                                id="obligaciones-contractuales"
                                animate={this.state.animacion} 
                                onChange={cambiarPestaña} 
                                selectedTabId={this.state.pestañaDialog}
                                renderActiveTabPanelOnly={false}
                            >
                                <Tab id="datos-contrato" title="Datos del contrato" panel={<DatosContrato editable={this.props.editable} ref={(input) => { this.DatosContrato= input; }}  contrato={this.state.contratoSeleccionado} tiposContrato={this.state.tiposContrato}/>} />
                                <Tab id="partes" title="Partes" panel={<PartesContrato editable={this.props.editable} ref={(input) => { this.PartesContrato= input; }} contrato={this.state.contratoSeleccionado} partes={this.state.partes} setPartes={this.setPartes} personas={this.state.personas} cargarPersonas={this.cargarPersonas}  />} />
                            </Tabs>
                        </Card>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content="Añadir contrato" position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoContrato?anadirContrato:actualizarContrato}
                                >
                                    {this.state.nuevoContrato?"Añadir Contrato":"Actualizar Contrato"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>    
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    className="dialog-ancho dialog-scroll"
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoDerecho?"Añadir nuevo derecho":"Editar derecho"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbiertoDerecho}
                >
                    <div id="dialogo-derechos">
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <ExtraContrato editable={this.props.editable} ref={(input) => { this.ExtraContrato= input; }}  info={this.state.derechoSeleccionado} />
                        </Card>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content="Añadir derecho." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoDerecho?anadirDerecho:actualizarDerecho}
                                >
                                    {this.state.nuevoDerecho?"Añadir Derecho":"Actualizar Derecho"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>    
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    className="dialog-ancho dialog-scroll"
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevaObligacion?"Añadir nueva obligación":"Editar obligación"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbiertoObligacion}
                >
                    <div id="dialogo-contratos">
                        <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                            <ExtraContrato editable={this.props.editable} ref={(input) => { this.ExtraContrato= input; }}  info={this.state.obligacionSeleccionado} />
                        </Card>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content="Añadir obligación." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevaObligacion?anadirObligacion:actualizarObligacion}
                                >
                                    {this.state.nuevaObligacion?"Añadir Obligación":"Actualizar Obligación"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>    
                </Dialog>
                <div className="pixelAlrededor tablaCien">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.cargando===true?
                                <Cargando/>
                        :
                        <>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Contratos</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text="Nuevo"  onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text="Editar"  onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="delete" text="Borrar" onClick={eliminarContrato} />
                                    <Button  className="bp3-minimal" icon="print" text="Imprimir" onClick={()=>{this.generarPDF()}}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='ID_CONTRATO'
                            data={ this.state.contratos }
                            columns={ columns }
                            selectRow={ selectRow }
                            pagination={ paginationFactory(options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }
                    </Card>
                </div>
                <br></br>
                <div className="pixelAlrededor tablaMitadIzquierda">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.cargandoDerechos===true?
                                <Cargando/>
                        :
                        <>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Derechos</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text="Nuevo" disabled={!this.state.contratoSeleccionado}  onClick={abrirModalNuevoDerecho} />
                                    <Button className="bp3-minimal" icon="edit" text="Editar"  disabled={!this.state.contratoSeleccionado}  onClick={abrirModalEditarDerecho}/>
                                    <Button className="bp3-minimal" icon="delete" text="Borrar" disabled={!this.state.contratoSeleccionado} onClick={eliminarContratoDerecho} />
                                    <Button  className="bp3-minimal" icon="print" text="Imprimir" disabled={!this.state.contratoSeleccionado} onClick={()=>{this.generarPDFDerechos()}}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='ID_DERECHO'
                            data={ this.state.derechos }
                            columns={ columnsSons }
                            selectRow={ selectRowDerecho }
                            pagination={ paginationFactory(options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }
                    </Card>
                </div>
                <div className="pixelAlrededor tablaMitadDerecha">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.cargandoObligaciones===true?
                                <Cargando/>
                        :
                        <>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Obligaciones</Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text="Nuevo" disabled={!this.state.contratoSeleccionado}  onClick={abrirModalNuevoObligaciones} />
                                    <Button className="bp3-minimal" icon="edit" text="Editar" disabled={!this.state.contratoSeleccionado}  onClick={abrirModalEditarObligaciones}/>
                                    <Button className="bp3-minimal" icon="delete" text="Borrar" disabled={!this.state.contratoSeleccionado} onClick={eliminarContratoObligaciones} />
                                    <Button  className="bp3-minimal" icon="print" text="Imprimir" disabled={!this.state.contratoSeleccionado} onClick={()=>{this.generarPDFObligaciones()}}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='ID_OBLIGACION'
                            data={ this.state.obligaciones }
                            columns={ columnsSons }
                            selectRow={ selectRowObligacion }
                            pagination={ paginationFactory(options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(withApollo(ObligacionesContractuales));