import React from 'react';
import { Card, Button, Tooltip, Position, Spinner } from "@blueprintjs/core"
import ReactExport from "react-export-excel";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RecursosHumanosInformes extends React.Component {
    state={
        datosXLS:[],
        exportar:false,
        areas: [],
        departamentos: [],
        cargando:false
    }
    componentDidMount(props){

        this.setState({
            cargando: true
        },async () => {
            await this.cargarAD()
            this.setState({cargando: false})  
        });
        

    }
    cargarAD=async()=>{
        let departamentos=await this.props.client.query({
            query:gql`{departamentos{ID_DEPARTAMENTO,DESCRIPCION,ID_AREA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.departamentos;
        })
        let areas=await this.props.client.query({
            query:gql`{departamentosAreas{ID_AREA,DESCRIPCION,PREDEFINIDA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.departamentosAreas
        })
        this.setState({departamentos:departamentos,areas:areas});
    }
    exportarRegistrosRetributivos=()=>{
        this.setState({
            cargando: true,
            datosXLS:[]
        },async () => {
            await this.cargarDatosRegistrosRetributivos()
            this.setState({
                exportar:true,
                cargando:false
            })
        })
    }
    cargarDatosRegistrosRetributivos=async ()=>{
        let dataXLS = this.state.datosXLS
        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {empleados(CAMPOS:"ID_EMPLEADO,FECHA_ALTA,FECHA_ANTIGUEDAD,CATEGORIA,CLAVE_CONTRATO,GRUPO_COTIZACION",ORDEN:"ID_EMPLEADO,ASC"){ID_EMPLEADO,FECHA_ALTA,FECHA_ANTIGUEDAD,CATEGORIA,CLAVE_CONTRATO,GRUPO_COTIZACION}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            if (result.data.empleados.length>0){
                let empleados=JSON.parse(JSON.stringify(result.data.empleados))
                for(let empleado of empleados){
                    await this.props.client
                    .query({
                        query: gql`
                        {personasFisicas(FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}},LIMIT:1){FECHA_NACIMIENTO,GENERO,NOMBRE,APELLIDO1,APELLIDO2}}
                        `
                        ,fetchPolicy:'network-only'
                    }).then(async resultPF=>{ 
                        await this.props.client
                        .query({
                            query: gql` 
                                {empleadosPuestos(FILTROS:{ID_EMPLEADO:${empleado.ID_EMPLEADO},FECHA_BAJA:null},LIMIT:1,ORDEN:"FECHA_ALTA,ID_PUESTO,DESC"){PUESTO_TRABAJO{DESCRIPCION,ID_DEPARTAMENTO,DEPARTAMENTO{ID_AREA}}}}
                            `,
                            fetchPolicy:'network-only'
                        }).then(async resultPto=>{ 
                            let departamento='';
                            let area='';
                            let puesto='';
                            let fecha_nacimiento='';
                            let sexo='';
                            let nombre='';
                            if(resultPto.data.empleadosPuestos.length>0){
                                if (resultPto.data.empleadosPuestos[0].PUESTO_TRABAJO.ID_DEPARTAMENTO) {
                                    let dptoSel=this.state.departamentos.find(dpto=>{return resultPto.data.empleadosPuestos[0].PUESTO_TRABAJO.ID_DEPARTAMENTO===dpto.ID_DEPARTAMENTO})
                                    let areaSel=this.state.areas.find(area=>{return resultPto.data.empleadosPuestos[0].PUESTO_TRABAJO.DEPARTAMENTO.ID_AREA===area.ID_AREA})
                                    area=areaSel.DESCRIPCION;
                                    departamento=dptoSel.DESCRIPCION;
                                    puesto=resultPto.data.empleadosPuestos[0].PUESTO_TRABAJO.DESCRIPCION
                                }
                            }
                            if(resultPF.data.personasFisicas.length>0){

                                    fecha_nacimiento=resultPF.data.personasFisicas[0].FECHA_NACIMIENTO?moment(resultPF.data.personasFisicas[0].FECHA_NACIMIENTO).format("DD-MM-YYYY"):null
                                    sexo=resultPF.data.personasFisicas[0].GENERO
                                    nombre=`${resultPF.data.personasFisicas[0].APELLIDO1} ${resultPF.data.personasFisicas[0].APELLIDO2?resultPF.data.personasFisicas[0].APELLIDO2:""},${resultPF.data.personasFisicas[0].NOMBRE}`
                            }
                               dataXLS.push({
                                    "id":empleado.ID_EMPLEADO,
                                    "fecha_de_contratacion":empleado.FECHA_ALTA?moment(empleado.FECHA_ALTA).format("DD-MM-YYYY"):null,
                                    "fecha_nacimiento":fecha_nacimiento,
                                    "sexo":sexo,
                                    "fecha_de_antiguedad":empleado.FECHA_ANTIGUEDAD?moment(empleado.FECHA_ANTIGUEDAD).format("DD-MM-YYYY"):null,
                                    "categoria":empleado.CATEGORIA,
                                    "area":area,
                                    "dpto":departamento,
                                    "puesto":puesto,
                                    "nombre":nombre,
                                    "clave_contrato":empleado.CLAVE_CONTRATO,
                                    "grupo_cotizacion":empleado.GRUPO_COTIZACION
                                })
                            
                        })
                         
                    })
                } 
                this.setState({datosXLS:dataXLS})
            }
        })

    }
    render() {

        document.title ="LexControl | Recursos Humanos Informes";
        return (
            
            <>
                {
                    this.state.cargando &&
                        <Spinner />
                } 
                {
                    (this.state.exportar) &&
                    <ExcelFile hideElement={true} filename="RegistrosRetributivos" fileExtension="xlsx">       
                        <ExcelSheet data={this.state.datosXLS} name="Retribuciones">
                            <ExcelColumn label="Trabajador" value="nombre"/>
                            <ExcelColumn label="Id" value="id"/>
                            <ExcelColumn label="Sexo" value="sexo"/>
                            <ExcelColumn label="Fecha Nacimiento" value="fecha_nacimiento"/>
                            <ExcelColumn label="Nivel de estudios" value="nivel_de_estudios"/>
                            <ExcelColumn label="Situación familiar" value="situacion_familiar"/>
                            <ExcelColumn label="Hijos/as" value="hijos"/>
                            <ExcelColumn label="Fecha de contratación" value="fecha_de_contratacion"/>
                            <ExcelColumn label="Fecha fin contrato" value="fecha_fin_contrato"/>
                            <ExcelColumn label="Fecha de antigüedad" value="fecha_de_antiguedad"/>
                            <ExcelColumn label="Fecha inicio sit. contract." value="fecha_ini_sit_contr"/>
                            <ExcelColumn label="Fecha fin sit. contract." value="fecha_fin_sit_contr"/>
                            <ExcelColumn label="% de jornada" value="porc_de_jornada"/>
                            <ExcelColumn label="% jornada reducida" value="porc_jornada_reducida"/>
                            <ExcelColumn label="Motivo reducción" value="motivo_reduccion"/>
                            <ExcelColumn label="Clave de contrato" value="clave_contrato"/>
                            <ExcelColumn label="Área-empresa" value="area"/>
                            <ExcelColumn label="Dpto-empresa" value="dpto"/>
                            <ExcelColumn label="Puesto-empresa" value="puesto"/>
                            <ExcelColumn label="Horario (Continuo/partido)" value="horario"/>
                            <ExcelColumn label="Trabajo a turnos" value="trabajo_a_turno"/>
                            <ExcelColumn label="Escala-empresa" value="escala"/>
                            <ExcelColumn label="AGRUP. CLAS. PROF." value="agrup_clas_prof"/>
                            <ExcelColumn label="AGRUP. VALOR. PTO." value="agrup_valor_pto"/>
                            <ExcelColumn label="Convenio/Área" value="convenio"/>
                            <ExcelColumn label="Categoría profesional" value="categoria"/>
                            <ExcelColumn label="Grupo profesional" value="grupo"/>
                            <ExcelColumn label="Nivel" value="nivel"/>
                            <ExcelColumn label="Grupo de cotización" value="grupo_cotizacion"/>
                            <ExcelColumn label="Seg. social" value="seg_social"/>
                            <ExcelColumn label="S. BASE" value="s_base"/>
                        </ExcelSheet>
                        
                    </ExcelFile>
                }
                <div className="pixelAlrededor">
                    <Card id="informes">
                        <p>Descargar los informes de recusos humanos en lexcontrol</p>
                        <div className="tablaMitad">
                            <Tooltip content="Exportar informe de retribuciones" position={Position.RIGHT}>
                                <Button
                                    className="bp3-minimal"
                                    icon="git-repo"
                                    text="Registros Retributivos"
                                    onClick={() => this.exportarRegistrosRetributivos()}
                                >
                                </Button>
                            </Tooltip>
                        </div>
                        <br />
                        <br />
                        <br />
                    </Card>
                </div>
                            
            </>
        )
    }
}

export default withApollo(RecursosHumanosInformes)