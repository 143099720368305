import React, { Component } from 'react';

export default class Inicio extends Component {
    render() {

        document.title ="LexControl | Inicio"

        return (
            <div className="contenedorInicio">
            </div>
        )
    }
}