import React, {Component} from "react";
import {Card,Elevation,FormGroup,InputGroup,Button,Callout} from "@blueprintjs/core";
import { withApollo } from "react-apollo";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Cargando } from "./Cargando";
import styles from "./stylesPrint";
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png';
import gql from "graphql-tag";
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import moment from 'moment';
import iconoFooter from '../../Assets/lc.png';
import Select from "react-select";
import iconoendorsed from '../../Assets/endorsed.png';

let data=[];
let procesado=0;
let procesar=0;

class InformeCompliance extends Component {
    state= {
        cargando:true,
        logotipo: null,
        datosEmpresa:null,
        valoran:[],
        asignados:[],
        categoriasRiesgo: [i18next.t("identificacion.bajo",{ns:"page"}), i18next.t("identificacion.medio",{ns:"page"}), i18next.t("identificacion.alto",{ns:"page"}), i18next.t("identificacion.muyalto",{ns:"page"})],
        modalidades:[],
        canales:[],
        count:0,
        cargandoPrint:false,
        identificaciones:[],
        areasSelect:[],
        areaSeleccionada:null,
        deptoSeleccionado:null,
        deptosSelect:[],
        intentCategoriasPrint: ["forestgreen", "yellow","yellow", "orangered", "#db3737"],
        intentCategoriasPrintColor: ["white", "black","black", "white", "white"]
    }
    componentDidMount(){

        this.setState({
            cargando: true
        },async()=>{
            await this.cargarModalidadesFormacion();   
        })

    }

    sortData=()=>{
        let val1_1,val1_2,val2_1,val2_2=0;
        data.sort(function (a, b){
            val1_1=a.identificador;
            val1_2=b.identificador;
            val2_1=a.seguimiento;
            val2_2=b.seguimiento;
            return val1_1 - val1_2 || val2_1 -val2_2;
        });
    }

    handleGenerarBloque =() => {
        if (this.state.areaSeleccionada && this.state.deptoSeleccionado) {
            this.setState({
                cargandoPrint: true
            },async()=>{
                data=[];
                procesar=0;
                procesado=0;
                await this.cargarIdentificaciones();   
            })
        } else {
            alert(i18next.t("informecompliance.validSelect",{ns:"page"}));
        }
    }

    recargarDeptos=async(idArea)=>{

        await this.props.client.query({
            query:gql`{complianceContratosDepartamentos{ID_CONTRATO_DEPARTAMENTO,DEPARTAMENTO{ID_DEPARTAMENTO,ID_AREA,DESCRIPCION,PREDEFINIDO}}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let deptos=[];
            let existe=false;
            let pertenece=false;
            let indice=0;
            let arr=[];
            let count=0;
            result.data.complianceContratosDepartamentos.forEach(dpto=>{
                existe=deptos.find(depto=>{return depto.value===dpto.DEPARTAMENTO.ID_DEPARTAMENTO});
                
                pertenece=(dpto.DEPARTAMENTO.ID_AREA===idArea.value)?true:false;

                
                if (!existe && pertenece) {
                    indice+=1;
                    //busco el total de registros para el departamento tenemos
                    arr=this.state.identificaciones.filter((identificador)=>identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.ID_DEPARTAMENTO === dpto.DEPARTAMENTO.ID_DEPARTAMENTO);
                    count+=arr.length;
                    deptos.push({"value":dpto.DEPARTAMENTO.ID_DEPARTAMENTO,"contDept":dpto.ID_CONTRATO_DEPARTAMENTO,"indice":indice,"label":dpto.DEPARTAMENTO.DESCRIPCION+" ("+arr.length+" "+i18next.t("informecompliance.registros",{ns:"page"})+")"})
                }
            });
            let val1_1,val1_2='';
            deptos.sort(function (a, b){
                val1_1=a.label;
                val1_2=b.label;
                return val1_1.localeCompare(val1_2);
            
            });
            this.setState({deptosSelect:deptos,count:count})
        })

    }

    recargarAreas=async()=>{

        await this.props.client.query({
            query:gql`{complianceContratosDepartamentos{DEPARTAMENTO{ID_DEPARTAMENTO,ID_AREA,AREA{ID_AREA,DESCRIPCION,PREDEFINIDA},PREDEFINIDO}}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            let areas=[];
            let existe=false;
            let indice=0;
            result.data.complianceContratosDepartamentos.forEach(dpto=>{
                existe=areas.find(area=>{return area.value===dpto.DEPARTAMENTO.AREA.ID_AREA});
                if (!existe) {
                    indice+=1;
                    areas.push({"value":dpto.DEPARTAMENTO.AREA.ID_AREA,"indice":indice,"label":dpto.DEPARTAMENTO.AREA.DESCRIPCION})
                }
            })
            let val1_1,val1_2='';
            areas.sort(function (a, b){
                val1_1=a.label;
                val1_2=b.label;
                return val1_1.localeCompare(val1_2);
            
            });
            this.setState({areasSelect:areas},async()=>{
                await this.cargarCountIdentificaciones();
            })
        })

    }

    cargarCanales=async()=>{

        await this.props.client.query({
            query:gql`{canalesInformacion{ID_CANAL_INFORMACION,DESCRIPCION}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let canales=[]
            for(let canal of result.data.canalesInformacion){
                canales[canal.ID_CANAL_INFORMACION]=canal.DESCRIPCION;
            }
            await this.setState({
                canales:canales
            },async ()=>{
                await this.obtenerLogoEmpresa();
            })
  
        })
        
    }

    cargarModalidadesFormacion=async()=>{

        await this.props.client.query({
            query:gql`{modalidadesFormacion{ID_MODALIDAD_FORMACION,DESCRIPCION}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let modalidades=[]
            for(let modalidad of result.data.modalidadesFormacion){
                modalidades[modalidad.ID_MODALIDAD_FORMACION]=modalidad.DESCRIPCION;
            }
            await this.setState({
                modalidades:modalidades
            },async ()=>{
                await this.cargarCanales();
            })
  
        })
        
    }

    
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(async (result)=>{ 
            if(result.data.adjuntos.length>0){
                
                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),async  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                        let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                        this.setState({
                            logotipo:data
                        },async ()=>{
                            await this.cargarEmpresa();
                        });
                    } else {
                        await this.cargarEmpresa();        
                    }
                });
            } else {
                await this.cargarEmpresa();
            }
        })
    }

    cargarDatos=async()=> {
        let personasFisicas=await this.cargarPersonasFisicas();
        let personasJuridicas=await this.cargarPersonasJuridicas();

        let asignados=[];
        let personas=[];
        personasFisicas.forEach(persona=>{
            personas.push({label:`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,value:persona.ID_PERSONA})
            asignados.push({label:`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,value:persona.ID_PERSONA})
        });
        personasJuridicas.forEach(persona=>{
            asignados.push({label:persona.RAZON_SOCIAL,value:persona.ID_PERSONA})
        })
        this.setState({
            valoran:personas,
            asignados:asignados
        },async()=>{
            await this.recargarAreas();
        });

    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }

    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })
        this.setState({
            datosEmpresa:datosEmpresa
        },async ()=>{
            await this.cargarDatos();
        })
    
    }

    recuperarValoraciones2=async(IDDOCUMENTO)=>{
        let rtdo =await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoran(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let vals=[]; 
            result.data.complianceDocumentosValoran.forEach(async (valoracion)=>{
                let valoranSel=await this.state.valoran.find(val=>{
                    return val.value===valoracion.ID_PERSONA
                })
                vals.push(valoranSel);
            });
            return vals;
        });
        return rtdo;
    }

    recuperarRecursos2=async(IDDOCUMENTO)=>{
        let rtdo = await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosRecursos(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){ID_DOCUMENTO,ID_PERSONA}}
            `,fetchPolicy:'network-only'
        }).then(result=>{
            let recs=[]; 
            result.data.complianceDocumentosRecursos.forEach(async (recurso)=>{
                let asignadoSeleccionado=await this.state.asignados.find(val=>{
                    return val.value===recurso.ID_PERSONA
                })
                recs.push(asignadoSeleccionado);
            });
            return recs;
        });
        return rtdo;
    }

    recargas=async (identificador,index) => {
            await this.cargarSeguimientos(identificador.ID_DOCUMENTO,identificador,index);
                
    }

    recargarImperativas2=async(IDDOCUMENTO,response,index,count)=>{
        let imperativas=await this.props.client
        .query({
          query: gql` 
                  {complianceSeguimientoImperativas(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){
                    ID_DOCUMENTO_IMPERATIVA,
                    ID_DOCUMENTO,
                    ID_IMPERATIVA,
                    IMPERATIVA{DESCRIPCION},
                    VALOR
                  }}
              `,
              fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.complianceSeguimientoImperativas
        });
        imperativas.forEach((imperativa,index)=>{
            imperativas[index].value=imperativa.ID_IMPERATIVA
            imperativas[index].nivel=imperativa.VALOR
            imperativas[index].label=imperativa.IMPERATIVA.DESCRIPCION
        })
        
        response['imperativosList']=imperativas;
        await this.recargarRecomendaciones2(IDDOCUMENTO,response,index,count);
    }

    recargarRecomendaciones2=async(IDDOCUMENTO,response,index,count)=>{
        let recomendaciones = await this.props.client
        .query({
        query: gql` 
                {SeguimientoRecomendaciones(FILTROS:{ID_DOCUMENTO:${IDDOCUMENTO}}){
                    ID_DOCUMENTO_RECOMENDACION,
                    ID_DOCUMENTO,
                    ID_RECOMENDACION,
                    RECOMENDACION{DESCRIPCION},
                    VALOR
                }}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.SeguimientoRecomendaciones
        });
        
        recomendaciones.forEach((recomendacion,index)=>{
            recomendaciones[index].value=recomendacion.ID_RECOMENDACION
            recomendaciones[index].nivel=recomendacion.VALOR
            recomendaciones[index].label=recomendacion.RECOMENDACION.DESCRIPCION
        })
        
        response['recomendacionesList']=recomendaciones;
        await this.cargarFormaciones2(IDDOCUMENTO,response,index,count);
    }

    cargarFormaciones2=async(idDocumento,response,index,count)=>{
        //obtencion de las formaciones
        let formaciones = await this.props.client
        .query({
            query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"F"}){
                ID_ACCION
                ID_AREA,
                AREA{DESCRIPCION}
                ID_DEPARTAMENTO,
                DEPARTAMENTO{DESCRIPCION}
                ID_PUESTO_TRABAJO,
                PUESTO_TRABAJO{DESCRIPCION}
                ID_EMPLEADO,
                F_TITULO,
                F_NUMERO_HORAS,
                F_MODALIDAD,
                F_INICIO,
                F_FIN,
                F_HORARIO,
                F_PROCEDIMIENTO_EVALUACION,
                F_REQUISITOS_ACCESO,
                F_COMISION_ACADEMICA,
                F_PROFESORADO,
                F_COMPETENCIAS,
                F_OBJETIVOS,
                F_CONTENIDOS,
                LUGAR,
                OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
        })
        .then(async result => {
            
            return result.data.formacionesInformacion
                
        });

        response['formativasList']=formaciones;
        await this.cargarInformacion2(idDocumento,response,index,count);
    }

    cargarInformacion2=async(idDocumento,response,index,count)=>{
        let informaciones = await this.props.client
        .query({
            query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"I"}){
                    ID_ACCION
                    ID_AREA,
                    AREA{DESCRIPCION}
                    ID_DEPARTAMENTO,
                    DEPARTAMENTO{DESCRIPCION}
                    ID_PUESTO_TRABAJO,
                    PUESTO_TRABAJO{DESCRIPCION}
                    ID_EMPLEADO,
                    I_DESCRIPCION,
                    I_TEXTO,
                    I_CANAL,
                    I_FECHA,
                    I_DURACION,
                    LUGAR,
                    OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
        })
        .then(async result => {
            
            return result.data.formacionesInformacion
                
        });

        response['informativasList']=informaciones;
        //console.log(response);
        data.push(response);
        await this.verificarFinProceso(index,count);
        
    }

    verificarFinProceso=async(index,count)=>{
        if (index===count) {
            procesado+=1;
            if (procesado===procesar) {
                console.log("termino await identificaciones");
                //console.log(data);
                this.sortData();
                //console.log(data);
                this.setState({cargandoPrint:false}, () => {
                    ReactDOM.render(this.ImprimirGral(), document.getElementById('generarGral-pdf'))
                });
            }
        }
    }

    recargasSeguimiento=async(complianceDocumentosSeguimientos,identificador,indice)=>{
        let response=[];
        if (complianceDocumentosSeguimientos.length>0) {
            response=[];
            response['type']='identificador';
            response['value']=identificador;
            response['identificador']=indice;
            response['seguimiento']=0;
            response['valoranList']=await this.recuperarValoraciones2(identificador.ID_DOCUMENTO);
            response['asignadoList']=await this.recuperarRecursos2(identificador.ID_DOCUMENTO);
            
            data.push(response);
            //console.log(complianceDocumentosSeguimientos);
            await complianceDocumentosSeguimientos.map(async (seguimiento,index) => {
                
                response=[];
                response['type']='seguimiento';
                response['value']=seguimiento;
                response['identificador']=indice;
                response['seguimiento']=index+1;
                await this.recargarImperativas2(seguimiento.ID_DOCUMENTO,response,index,complianceDocumentosSeguimientos.length-1);
                
            });
        }
        
    }

    cargarSeguimientos=async(IDDOCUMENTO,identificador,index)=>{
        await this.props.client.query({
            query:gql`{complianceDocumentosSeguimientos(FILTROS:{ID_DOCUMENTO_VALORACION:${IDDOCUMENTO}},ORDEN:"NUMERO,ASC"){
                ID_DOCUMENTO,
                ID_CONTRATO_DEPARTAMENTO,
                CONTRATO_DEPARTAMENTO{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION,AREA{ID_AREA,DESCRIPCION}}}
                ID_RIESGO,
                RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO,DESCRIPCION,BLOQUE{ID_BLOQUE,TITULO,LETRA,DESCRIPCION}}
                FECHA,
                NUMERO,
                VALORACION{ID_DOCUMENTO,NUMERO},
                NOMBRE_APELLIDOS,
                DOCUMENTO_IDENTIDAD,
                EMPRESA,
                PUESTO,
                EXPLICACION_IMPERATIVOS,
                EXPLICACION_RECOMENDADOS,
                EXPLICACION_SALVEDADES,
                FECHA_PROPUESTA_PROXIMA,
                OBSERVACIONES,
                FECHA_PROXIMA,
                ID_USUARIO,
                COMPLIANCE_CERTIFICA
            }}`
            ,fetchPolicy:'network-only'
        }).then(async (result)=>{
                if (result.data.complianceDocumentosSeguimientos.length>0) {
                    await this.recargasSeguimiento(result.data.complianceDocumentosSeguimientos,identificador,index);
                } else {
                    let response=[];
                    procesado+=1;
                    //console.log(procesado);
                    response['type']='identificador';
                    response['value']=identificador;
                    response['identificador']=index;
                    response['seguimiento']=0;
                    response['valoranList']=await this.recuperarValoraciones2(identificador.ID_DOCUMENTO);
                    response['asignadoList']=await this.recuperarRecursos2(identificador.ID_DOCUMENTO);
                    
                    data.push(response);
                    if (procesado===procesar) {
                        console.log("termino await identificaciones");
                        this.sortData();
                        this.setState({cargandoPrint:false}, () => {
                            ReactDOM.render(this.ImprimirGral(), document.getElementById('generarGral-pdf'))
                        });
                    }
                }
        })
    }

    cargarCountIdentificaciones=async()=>{

        let identificaciones=await this.props.client
        .query({
            query: gql` 
                {complianceDocumentosValoraciones
                    {
                        ID_DOCUMENTO,
                        ID_CONTRATO_DEPARTAMENTO,
                        COMPLIANCE_CONTRATOS_DEPARTAMENTOS{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION,AREA{ID_AREA,DESCRIPCION}}}
                        ID_RIESGO,
                        RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO,DESCRIPCION,BLOQUE{ID_BLOQUE,TITULO,LETRA,DESCRIPCION}}
                        FECHA,
                        NUMERO,
                        NIVEL_INTENSIDAD,
                        OCURRIDO_ANTES,
                        CONSECUENCIAS_SANCION,
                        CONSECUENCIAS_PERDIDA,
                        CONSECUENCIAS_SUSPENSION,
                        CONSECUENCIAS_DANO,
                        CONSECUENCIAS_OTROS,
                        RECURSOS_FINANCIEROS,
                        RECURSOS_ORGANIZACION,
                        RECURSOS_EXTERNOS,
                        PERIODICIDAD_REVISION,
                        OBSERVACIONES,
                        PERIODICIDAD_REVISION,
                        ID_USUARIO
                    }
                }
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return  result.data.complianceDocumentosValoraciones
        })
        let val1_1,val1_2,val2_1,val2_2,val3_1,val3_2,val4_1,val4_2,val5_1,val5_2='';
        if (identificaciones) {
            identificaciones.sort(function (a, b){
                val1_1=a.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION;
                val1_2=b.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION;
                val2_1=a.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION;
                val2_2=b.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION;
                val3_1=a.RIESGO.BLOQUE.LETRA;
                val3_2=b.RIESGO.BLOQUE.LETRA;
                val4_1=a.FECHA;
                val4_2=b.FECHA;
                val5_1=a.NUMERO;
                val5_2=b.NUMERO;
                return val1_1.localeCompare(val1_2) || val2_1.localeCompare(val2_2) || val3_1.localeCompare(val3_2) || val4_1.localeCompare(val4_2) || val5_1 - val5_2;
            });
            this.setState({cargando:false, count:identificaciones.length, identificaciones:identificaciones});
        } else {
            this.setState({cargando:false});
        }
        
    }

    cargarIdentificaciones=async()=>{
        if (this.state.identificaciones.length>0) {
            //console.log(this.state.deptoSeleccionado.value);
            let arr=this.state.identificaciones.filter((identificador)=>identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.ID_DEPARTAMENTO === this.state.deptoSeleccionado.value);
            //console.log(arr);
            procesar=arr.length;
            //console.log(procesar);
            if (arr.length>0) {
                arr.map(async (identificador,index)=>{
                    await this.recargas(identificador,index);
                });
            } else {
                this.sortData();
                this.setState({cargandoPrint:false}, () => {
                    alert(i18next.t("informecompliance.noexiste",{ns:"page"}));
                    ReactDOM.render(this.ImprimirGral(), document.getElementById('generarGral-pdf'))
                });
                    
            } 
        } else {
           this.sortData();
            this.setState({cargandoPrint:false}, () => {
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generarGral-pdf'))
            });
        }
        
    }

    formatearPeriodicidad=(periodicidad)=>{
        
        if(typeof periodicidad==="string"){
            periodicidad=JSON.parse(periodicidad)
        }

        let format=""
        if(periodicidad.dia && periodicidad.dia>0){
            format+="El día "+periodicidad.dia+" de"
        }else if(periodicidad.diaSemana){
            format+="El "+periodicidad.ordinal+" "+periodicidad.diaSemana+" de"
        }

        if(periodicidad.numeroMes && periodicidad.numeroMes>0){
            format+=" cada "+periodicidad.numeroMes+" meses"
        }else if(periodicidad.meseseSeleccionados.length>0){
            let meses=periodicidad.meseseSeleccionados.map(function(elem){
                return elem.label;
            }).join(",");
            format+=" los meses "+meses
        }
        return format
    }

    ImprimirGral=()=>{
        let identificador={};
        let seguimiento={};
        return (
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                {data?data.map((val, index) =>{
                    if (val['type']==='identificador') {
                        let categoriaRiesgo=0;
                        let valoran='';
                        let personales='';
                        val['valoranList'].map((valor) => {
                            if (valoran==='') {
                                valoran = valor.label;
                            } else {
                                valoran = valoran + ", " + valor.label;
                            }
                            return false;
                        });
                        val['asignadoList'].map((valor) => {
                            if (personales==='') {
                                personales = valor.label;
                            } else {
                                personales = personales + ", " + valor.label;
                            }
                            return false;
                        });
                        identificador=val['value'];
                        if(identificador.NIVEL_INTENSIDAD < 8) {
                            categoriaRiesgo=0;
                        } else if(identificador.NIVEL_INTENSIDAD < 15) {
                            categoriaRiesgo=1;
                        } else if(identificador.NIVEL_INTENSIDAD < 22) {
                            categoriaRiesgo=2;
                        } else if(identificador.NIVEL_INTENSIDAD < 31 ){
                            categoriaRiesgo=3;
                        }
                        return(
                            <Page size="A4" style={styles.page} key={index}>
                                <View style={styles.body}>
                                    <View fixed style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder60}>
                                            <Image
                                                style={styles.imageHead}
                                                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                                                />
                                        </View>
                                        <View style={styles.rowTitleNoneBorder40}>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                                            </View>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{i18next.t("identificacion.titledoc",{ns:"page"}).toUpperCase()}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder75Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.identificacionvaloracion",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder25}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{identificador.FECHA?moment(identificador.FECHA,"YYYY-MM-DD").format("DD/MM/YYYY"):moment().format("DD/MM/YYYY")}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{identificador.NUMERO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>{identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.AREA.DESCRIPCION}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</Text>{identificador.COMPLIANCE_CONTRATOS_DEPARTAMENTOS.DEPARTAMENTO.DESCRIPCION}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>{identificador.RIESGO.BLOQUE.TITULO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorderCallout}>
                                                <Text style={styles.textWrapCallout}>{identificador.RIESGO.TITULO}</Text>
                                            </View>
                                        </View> 
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.niveleintensidad",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.probabilidad",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.menor",{ns:"page"})}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.critico",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</Text>
                                        </View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.escasa",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 1 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 6 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>6</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 11 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>11</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 16 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>16</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 21 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>21</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 26 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>26</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.baja",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 2 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>2</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 7 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>7</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 12 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>12</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 17 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>17</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 22 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>22</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 27 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>27</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.posible",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 3 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>3</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 8 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>8</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 13 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>13</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 18 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>18</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 23 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>23</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 28 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>28</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.alta",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 4 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>4</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 9 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>9</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 14 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>14</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 19 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>19</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 24 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>24</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 29 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>29</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.muyalta",{ns:"page"})}</Text>
                                            {identificador.NIVEL_INTENSIDAD === 5 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>5</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 10 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>10</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 15 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>15</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 20 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>20</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 25 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>25</Text>}
                                            {identificador.NIVEL_INTENSIDAD === 30 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>30</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelbajo",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmedio",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelalto",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'25%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</Text>
                                        </View>
                                        </View>   
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorderLeft100}>
                                            {identificador.OCURRIDO_ANTES?
                                                <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.activarsisucedio",{ns:"page"}).toUpperCase()}:<Text style={styles.textWrap}>{i18next.t("yes",{ns:"global"})}</Text></Text>
                                            :
                                                <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.activarsisucedio",{ns:"page"}).toUpperCase()}:<Text style={styles.textWrap}>{i18next.t("no",{ns:"global"})}</Text></Text>
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.consecuencias",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()} \ {i18next.t("identificacion.impacto",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.irrelevante",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.menor",{ns:"page"})}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.moderado",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.apreciable",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.critico",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.catastrofico",{ns:"page"})}</Text>
                                        </View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_SANCION === 1 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 6 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>6</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 11 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>11</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 16 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>16</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 21 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>21</Text>}
                                            {identificador.CONSECUENCIAS_SANCION === 26 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>26</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_PERDIDA === 2 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>2</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 7 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>7</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 12 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>12</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 17 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>17</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 22 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>22</Text>}
                                            {identificador.CONSECUENCIAS_PERDIDA === 27 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>27</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_SUSPENSION === 3 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>3</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 8 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>8</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 13 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>13</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 18 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>18</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 23 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>23</Text>}
                                            {identificador.CONSECUENCIAS_SUSPENSION === 28 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>28</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_DANO === 4 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>4</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 9 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>9</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 14 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>14</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 19 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>19</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 24 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>24</Text>}
                                            {identificador.CONSECUENCIAS_DANO === 29 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>29</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.otros",{ns:"page"})}</Text>
                                            {identificador.CONSECUENCIAS_OTROS === 5 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>5</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 10 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>10</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 15 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>15</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 20 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>20</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 25 ?<View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>25</Text>}
                                            {identificador.CONSECUENCIAS_OTROS === 30 ?<View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}><Image style={{height:'12px',width:'12px'}} src={iconoendorsed} /></View>: <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>30</Text>}
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelbajo",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmedio",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelalto",{ns:"page"})}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'25%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>{i18next.t("identificacion.nivelmuyalto",{ns:"page"})}</Text>
                                        </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.resultados",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.nivelriesgo",{ns:"page"}).toUpperCase()}:</Text>
                                                {identificador.NIVEL_INTENSIDAD ===null?
                                                    <Text style={styles.textWrapGray}>{i18next.t("identificacion.bajo",{ns:"page"})}</Text>
                                                :
                                                    identificador.NIVEL_INTENSIDAD < 8?
                                                    <Text style={styles.textWrapGreen}>{i18next.t("identificacion.bajo",{ns:"page"})}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 15?
                                                        <Text style={styles.textWrapYellow}>{i18next.t("identificacion.medio",{ns:"page"})}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 22?
                                                        <Text style={styles.textWrapOrange}>{i18next.t("identificacion.alto",{ns:"page"})}</Text>
                                                    :
                                                        <Text style={styles.textWrapRed}>{i18next.t("identificacion.muyalto",{ns:"page"})}</Text>
                                                    
                                                }
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.riesgocierto",{ns:"page"}).toUpperCase()}:</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.intensidad",{ns:"page"}).toUpperCase()}:</Text>
                                                {identificador.NIVEL_INTENSIDAD ===null?
                                                <Text style={styles.textWrapGray}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                :
                                                    identificador.NIVEL_INTENSIDAD < 8?
                                                    <Text style={styles.textWrapGreen}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 15?
                                                    <Text style={styles.textWrapYellow}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    :
                                                    identificador.NIVEL_INTENSIDAD < 22?
                                                    <Text style={styles.textWrapOrange}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    :
                                                    <Text style={styles.textWrapRed}>{identificador.NIVEL_INTENSIDAD}</Text>
                                                    
                                                }
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                {identificador.NIVEL_INTENSIDAD ===null && 
                                                    identificador.OCURRIDO_ANTES &&
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapGreen}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD ===null && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                    <Text style={styles.textWrapGray}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 8 && 
                                                    identificador.OCURRIDO_ANTES && 
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapYellow}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 8 && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                        <Text style={styles.textWrapGreen}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 15 && 
                                                    identificador.OCURRIDO_ANTES && 
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapOrange}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 15 && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                        <Text style={styles.textWrapYellow}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 22 && 
                                                    identificador.OCURRIDO_ANTES && 
                                                    categoriaRiesgo<3?
                                                        <Text style={styles.textWrapRed}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                identificador.NIVEL_INTENSIDAD < 22 && 
                                                    (!identificador.OCURRIDO_ANTES ||
                                                    categoriaRiesgo!==3)?
                                                        <Text style={styles.textWrapOrange}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                :
                                                    <Text style={styles.textWrapRed}>{ identificador.OCURRIDO_ANTES?(categoriaRiesgo<3?this.state.categoriasRiesgo[categoriaRiesgo+1]:this.state.categoriasRiesgo[categoriaRiesgo]):this.state.categoriasRiesgo[categoriaRiesgo]}</Text>
                                                }
                                            
                                            
                                            </View>
                                        </View>
                                        
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorderLeft100}>
                                            <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.tipodanos",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.sancioneconomica",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.perdidapatrimonial",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.suspensionclausura",{ns:"page"}).toUpperCase()}</Text>                                 
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.danoreputacional",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>{i18next.t("identificacion.otros",{ns:"page"}).toUpperCase()}</Text>
                                        </View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%', backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_SANCION/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_SANCION}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%', backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_PERDIDA/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_PERDIDA}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%', backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_SUSPENSION/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_SUSPENSION}</Text>                                 
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%',backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_DANO/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_DANO}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'20%',backgroundColor:(Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1)<=4?this.state.intentCategoriasPrint[Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1]:this.state.intentCategoriasPrint[4],color:(Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1)<=4?this.state.intentCategoriasPrintColor[Math.ceil(identificador.CONSECUENCIAS_OTROS/5)-1]:this.state.intentCategoriasPrintColor[4]}}>{identificador.CONSECUENCIAS_OTROS}</Text>
                                        </View>
                                        </View>   
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorderLeft100}>
                                            <Text style={styles.labelTitleLeft}>{i18next.t("identificacion.organo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        
                                    </View>
                                    <View  style={styles.row}>
                                        <View  style={{flexDirection: 'row', width: '100%'}}>
                                            <Text style={styles.textWrap}>{valoran}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.recursosasignados",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.personales",{ns:"page"}).toUpperCase()}:</Text>{personales}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.delaorganizacion",{ns:"page"}).toUpperCase()}:</Text>{identificador.RECURSOS_ORGANIZACION?identificador.RECURSOS_ORGANIZACION:''}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.observaciones",{ns:"page"}).toUpperCase()}:</Text>{identificador.OBSERVACIONES}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.financieros",{ns:"page"}).toUpperCase()}:</Text>{identificador.RECURSOS_FINANCIEROS?identificador.RECURSOS_FINANCIEROS:''}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.externos",{ns:"page"}).toUpperCase()}:</Text>{identificador.RECURSOS_EXTERNOS?identificador.RECURSOS_EXTERNOS:''}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.periodicidad",{ns:"page"}).toUpperCase()}:</Text>{identificador.PERIODICIDAD_REVISION?this.formatearPeriodicidad(identificador.PERIODICIDAD_REVISION):null}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.descripcionriesgo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}>{identificador.RIESGO.DESCRIPCION}</Text>
                                        </View>
                                    </View>
                                </View> 
                                <Text
                                    style={styles.footerPage}
                                    render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                                    fixed
                                />
                                <Image
                                    style={styles.icono}
                                    src={iconoFooter}
                                    fixed
                                />
                                <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                                
                            </Page>
                            
                            
                        )
                    } else {
                            let inicio='';
                            let fin='';
                            let modalidad='';
                            let fecha='';
                            let canal='';
                            seguimiento=val['value'];
                            return (
                            <Page size="A4" style={styles.page} key={index}>
                                <View style={styles.body}>
                                    <View fixed style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <Image
                                                style={styles.imageHead}
                                                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                                                />
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                                            </View>
                                            <View fixed style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text><Text style={styles.text}>{i18next.t("identificacion.titledocseg",{ns:"page"}).toUpperCase()}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder75CalloutGreen}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.controlesimplantados",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder25}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{seguimiento.FECHA?moment(seguimiento.FECHA,"YYYY-MM-DD").format("DD/MM/YYYY"):moment().format("DD/MM/YYYY")}</Text>
                                            </View>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.numero",{ns:"page"}).toUpperCase()}:</Text><Text style={styles.text}>{seguimiento.NUMERO}/{seguimiento.NUMERO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("organizationalstructure.area",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO.AREA.DESCRIPCION}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.departamento",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO.DESCRIPCION}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                                <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("edicionriesgo.bloque",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.RIESGO.BLOQUE.TITULO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100}>
                                            <View  style={styles.rowHeadNoneBorderCallout}>
                                                <Text style={styles.textWrapCallout}>{seguimiento.RIESGO.TITULO}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.operativas",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View> 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}></Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'80%'}}>{i18next.t("identificacion.nivel",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.imperativas",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        {val['imperativosList'].length>0?val['imperativosList'].map((imperativo, indexImperativo)=>{
                                            return (
                                            <View style={styles.colHead} key={indexImperativo}>
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{imperativo.label}</Text>
                                                {imperativo.nivel === 1?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>
                                                }
                                                {imperativo.nivel === 2?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>2</Text>
                                                }
                                                {imperativo.nivel === 3?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>3</Text>
                                                }
                                                {imperativo.nivel === 4?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>4</Text>
                                                }
                                                {imperativo.nivel === 5?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>5</Text>
                                                }
                                                {imperativo.nivel === 6?
                                                    <View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>6</Text>
                                                }
                                            </View>
                                            )
                                        }):<></>} 
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("identificacion.recomendaciones",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.optimo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.encurso",{ns:"page"}).toUpperCase()}</Text>                                 
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.parcial",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.salvedades",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.malo",{ns:"page"}).toUpperCase()}</Text>
                                            <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.muymalo",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                        {val['recomendacionesList'].length>0?val['recomendacionesList'].map((recomendacion, indexRecomendacion)=>{
                                            return (
                                            <View style={styles.colHead} key={indexRecomendacion}>
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{recomendacion.label}</Text>
                                                {recomendacion.nivel === 1?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'forestgreen',paddingLeft:'2px'}}>1</Text>
                                                }
                                                {recomendacion.nivel === 2?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>2</Text>
                                                }
                                                {recomendacion.nivel === 3?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'black',backgroundColor:'yellow',paddingLeft:'2px'}}>3</Text>
                                                }
                                                {recomendacion.nivel === 4?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%',color:'white',backgroundColor:'orangered',paddingLeft:'2px'}}>4</Text>
                                                }
                                                {recomendacion.nivel === 5?
                                                    <View style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>5</Text>
                                                }
                                                {recomendacion.nivel === 6?
                                                    <View style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>
                                                        <Image style={{height:'12px',width:'12px'}} src={iconoendorsed} />
                                                    </View>
                                                :
                                                <Text style={{textAlign:'left',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%',color:'white',backgroundColor:'#db3737',paddingLeft:'2px'}}>6</Text>
                                                }
                                            </View>
                                            )
                                        }):<></>}
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informecumplimiento",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.don",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.NOMBRE_APELLIDOS?seguimiento.NOMBRE_APELLIDOS:''}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.rowTitleNoneBorder50}>
                                            <View  style={styles.rowHeadNoneBorder}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.condni",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.DOCUMENTO_IDENTIDAD?seguimiento.DOCUMENTO_IDENTIDAD:''}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}>{i18next.t("identificacion.certifica",{ns:"page"}).toUpperCase()}:</Text>
                                        </View>
                                    </View>    
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicaimperativo",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.EXPLICACION_IMPERATIVOS?seguimiento.EXPLICACION_IMPERATIVOS:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicarecomendado",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.EXPLICACION_RECOMENDADOS?seguimiento.EXPLICACION_RECOMENDADOS:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.explicasalvedades",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.EXPLICACION_SALVEDADES?seguimiento.EXPLICACION_SALVEDADES:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fechacontroles",{ns:"page"}).toUpperCase()}:</Text>{moment(seguimiento.FECHA_PROPUESTA_PROXIMA,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informesupervisor",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.certifico",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.COMPLIANCE_CERTIFICA?i18next.t("yes",{ns:"global"}):i18next.t("no",{ns:"global"})}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                        <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.observaciones",{ns:"page"}).toUpperCase()}:</Text>{seguimiento.OBSERVACIONES?seguimiento.OBSERVACIONES:""}</Text>
                                        </View>
                                    </View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Left}>
                                            <Text style={styles.textWrap}><Text style={styles.labelHeadNoneBorder}>{i18next.t("identificacion.fechavaloracion",{ns:"page"}).toUpperCase()}:</Text>{moment(seguimiento.FECHA_PROXIMA,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.formacion",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("edicionriesgo.titulo",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.numerohoras",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.modalidad",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.inicio",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.fin",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'15%'}}>{i18next.t("identificacion.horario",{ns:"page"}).toUpperCase()}</Text>
                                    </View>

                                    {val['formativasList'].length>0?val['formativasList'].map((formativa, indexFormativa)=>{
                                        inicio='';
                                        fin='';
                                        if (formativa.F_INICIO) {
                                            inicio=moment(formativa.F_INICIO,"YYYY-MM-DD").format("DD/MM/YYYY");
                                        }
                                        if (formativa.F_FIN) {
                                            fin=moment(formativa.F_FIN,"YYYY-MM-DD").format("DD/MM/YYYY");
                                        }
                                        modalidad=this.state.modalidades[formativa.F_MODALIDAD];

                                        return(
                                        <View key={indexFormativa}>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{formativa.F_TITULO?formativa.F_TITULO:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{formativa.F_NUMERO_HORAS?formativa.F_NUMERO_HORAS:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{modalidad?modalidad:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{inicio?inicio:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{fin?fin:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'15%'}}>{formativa.F_HORARIO?formativa.F_HORARIO:''}</Text>
                                        </View>
                                        </View>
                                        )
                                    }):<></>}
                                    
                                    
                                    <View style={styles.rowWhiteSpacing}></View>
                                    <View style={styles.rowLineSpacing}></View>
                                    <View  style={styles.row}>
                                        <View style={styles.rowTitleNoneBorder100Callout}>
                                            <Text style={styles.labelTitleCenter}>{i18next.t("identificacion.informacion",{ns:"page"}).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{i18next.t("identificacion.descripcion",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.canal",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("identificacion.fecha",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("identificacion.numerohoras",{ns:"page"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'30%'}}>{i18next.t("identificacion.texto",{ns:"page"}).toUpperCase()}</Text>
                                    </View>
                                    {val['informativasList'].length>0?val['informativasList'].map((informativa, indexInformativa)=>{
                                        fecha='';
                                        if (informativa.I_FECHA) {
                                            fecha=moment(informativa.I_FECHA,"YYYY-MM-DD").format("DD/MM/YYYY");
                                        }
                                        canal=this.state.canales[informativa.I_CANAL];

                                        return(
                                        <View key={indexInformativa}>
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{informativa.I_DESCRIPCION?informativa.I_DESCRIPCION:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{canal?canal:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{fecha?fecha:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{informativa.I_DURACION?informativa.I_DURACION:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans', width:'30%'}}>{informativa.I_TEXTO?informativa.I_TEXTO:''}</Text>
                                        </View>
                                        </View>
                                        )
                                    }):<></>}
                                </View>
                                <Text
                                    style={styles.footerPage}
                                    render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                                    fixed
                                />
                                <Image
                                    style={styles.icono}
                                    src={iconoFooter}
                                    fixed
                                />
                                <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                            </Page>
                        )
                    }   
                    }):<></>}
                
                </Document>
                
            </PDFViewer>
        );
    }

    render() {
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("informecompliance.title",{ns:"page"});
        
        const seleccionaArea  = (value) => {

            this.setState({
                areaSeleccionada: value
            },async () =>{
                await this.recargarDeptos(value);
            })
        }

        const seleccionaDepto  = (value) => {

            this.setState({
                deptoSeleccionado: value
            });
        }

        return (
            <>
                {this.state.cargando?
                    <Cargando />
                :
                    <> 
                        <div className="tablaCien">
                            <Card interactive={true} elevation={Elevation.TWO}>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("informecompliance.title",{ns:'page'})}</Callout>
                                
                                <table style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{width: '35%',verticalAlign:'sub'}}>
                                                <FormGroup
                                                    label={i18next.t("identificacion.area",{ns:'page'})+":"}
                                                    labelFor="data"
                                                >
                                                    <Select
                                                            id="elegir-area"
                                                            options={this.state.areasSelect}
                                                            className="pixelAlrededor"
                                                            onChange={seleccionaArea}
                                                            backspaceRemovesValue={true}
                                                            isClearable={true}
                                                            value={this.state.areasSelect.filter(area => area.value === (this.state.areaSeleccionada?this.state.areaSeleccionada.value:0))}

                                                        />
                                                </FormGroup>
                                            </td>
                                            <td style={{width: '35%',verticalAlign:'sub'}}>
                                                <FormGroup
                                                    label={i18next.t("identificacion.departamento",{ns:'page'})+":"}
                                                    labelFor="data"
                                                >
                                                    <Select
                                                            id="elegir-depto"
                                                            options={this.state.deptosSelect}
                                                            className="pixelAlrededor"
                                                            onChange={seleccionaDepto}
                                                            backspaceRemovesValue={true}
                                                            isClearable={true}
                                                            value={this.state.deptosSelect.filter(depto => depto.value === (this.state.deptoSeleccionado?this.state.deptoSeleccionado.value:0))}

                                                        />
                                                </FormGroup>
                                            </td>
                                            <td style={{width: '20%',verticalAlign:'sub'}}>
                                                <FormGroup
                                                    label={i18next.t("identificacion.title",{ns:'page'})+":"}
                                                    labelFor="texto-razon-social"
                                                >
                                                    <InputGroup style={{height:'38px'}} className="pixelAlrededor" id="texto-razon-social" value={this.state.count+' '+i18next.t("informecompliance.registros",{ns:"page"})} readOnly={true} />
                                                </FormGroup>
                                            </td>
                                            <td style={{width: '10%',verticalAlign:'bottom'}}>
                                                <Button
                                                    alignText="right"
                                                    icon="print"
                                                    text={i18next.t("print",{ns:'global'})}
                                                    intent="danger"
                                                    onClick={this.handleGenerarBloque}
                                                    className="iconoDerecha bp3-minimal"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        <div className="tablaCien">
                            <Card interactive={true} elevation={Elevation.TWO}>
                             {this.state.cargandoPrint?
                                <Cargando />
                            :
                                <div style={{ width: '100%', height: '800px'}} id='generarGral-pdf'>
                                </div>
                            }           
                            </Card>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default withTranslation()(withApollo(InformeCompliance));