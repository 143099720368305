import * as React from "react";
import { ITreeNode, Tree, Tooltip, Button } from "@blueprintjs/core";
import { IExampleProps } from "@blueprintjs/docs-theme";
import { RouteComponentProps, withRouter} from "react-router-dom";
import LogoReg from "../Assets/LOGO REGISTRADO PEQUEÑO.png"
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost'
import { withTranslation } from 'react-i18next';

/* tslint:enable:object-literal-sort-keys */
let cliente = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


export interface ITreeExampleState {
    nodes: ITreeNode[];
}

// use Component so it re-renders everytime: `nodes` are not a primitive type
// and therefore aren't included in shallow prop comparison
class Menu extends React.Component<IExampleProps, ITreeExampleState, RouteComponentProps<{}>> {
    state: ITreeExampleState = { nodes: null,mostrarMenuCapital:false };

    componentWillUpdate=(props)=>{
        if(props.recargarMenu!==this.props.recargarMenu){
            this.refrescarMenu()
        }

    }
    normalizeString=(text)=> {
        text=text.replaceAll(' ','_');
        text=text.toLowerCase();
        text=text.replaceAll('á','a');
        text=text.replaceAll('é','e');
        text=text.replaceAll('í','i');
        text=text.replaceAll('ó','o');
        text=text.replaceAll('ú','u');
        return text;
    }
    refrescarMenu=async()=>{

        cliente = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });

        this.setState({nodes:[]})
        await cliente
        .query({
          query: gql`
            {
                menus(ORDEN:"ORDEN,ASC"){ID_MENU,ID_PADRE,TEXTO,KEY,EDITAR}
            }
          `
        })
        .then(result =>{

            let hijos=[];
            let text='';
            let claves=[];
            result.data.menus.forEach((menu,index)=> {
                this.tieneHijos(menu,result.data.menus)
                //si tiene hijos
                text=this.normalizeString(result.data.menus[index].TEXTO);
                if("childNodes" in menu){
                    result.data.menus[index].icon="folder-close"
                    result.data.menus[index].isExpanded=false

                    menu.childNodes.forEach(hijo=>{
                        hijos.push(hijo);
                    });
                    result.data.menus[index].label=this.props.i18next.t((claves.includes(text))?text+"2":text,{ns:"menu"}).toUpperCase();
                }else{
                    result.data.menus[index].icon="share"
                    result.data.menus[index].label=this.props.i18next.t((claves.includes(text))?text+"2":text,{ns:"menu"}).toLowerCase();
                }
                result.data.menus[index].clave=(claves.includes(text))?text+"2":text;
                claves.push(text);
                result.data.menus[index].id="menu-"+result.data.menus[index].KEY;
            });
            //eliminamos los hijos de la raiz.
            hijos.forEach(hijo=>{
                result.data.menus = result.data.menus.filter(function( obj ) {
                    return obj.ID_MENU !== hijo.ID_MENU;
                });
            })
            let menus=JSON.stringify(result.data.menus);
            menus=menus.replace(/KEY/g,"link");
            menus=menus.replace(/ID_MENU/g,"id");
            let nodos: ITreeNode[] = JSON.parse(menus);
            this.setState({nodes:nodos})
        });


    }
    // si el menu  se ha cargado, 
    componentDidMount=()=> { 
       this.refrescarMenu()
    }

    cerrarModal=()=>{
        this.setState({mostrarMenuCapital:false})
    }

    enviarCapSocial=()=>{
        this.props.history.push("/forma-societaria")
        this.cerrarModal()
    }

/*     sacarRaton = () => {
        this.refrescarMenu()
    } */

    render() {

        
        const desplegarArbol=(hijos)=>{
            
            hijos.forEach(hijo=>{
                  hijo.isExpanded=true
                  if(hijo.childNodes)desplegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
        
        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }

        return (
            <div className="App-menu"  ref={(input) => { this.menu= input; }} /* onMouseLeave={this.sacarRaton} */>
                <div>
                    <a href="/"><img src={LogoReg} alt="Logo LexControl" className="logoMenu" /></a>
                    <div>
                    <Tooltip content={this.props.i18next.t("tree.replegar",{ns:'global'})} position="auto" ><Button icon="exclude-row" onClick={()=>replegarArbol(this.state.nodes)}/></Tooltip>
                    <Tooltip content={this.props.i18next.t("tree.desplegar",{ns:'global'})} position="auto" ><Button icon="add-column-right" onClick={()=>desplegarArbol(this.state.nodes)}/></Tooltip>
                    </div>
                    <Tree
                        contents={this.state.nodes}
                        onNodeClick={this.handleNodeClick}
                        onNodeCollapse={this.handleNodeCollapse}
                        onNodeExpand={this.handleNodeExpand}
                    />
                </div>
            </div>
        );
    }

    handleNodeClick = async(nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
        
        //chequeamos si tiene los datos de la empresa.
        /*
        let razonSocial=await cliente
        .query({
        query: gql`
            {
                personasJuridicas(CAMPOS:"RAZON_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){RAZON_SOCIAL}
            }
        `,fetchPolicy:'network-only'
        })
        .then(result =>{

            return result.data.personasJuridicas[0].RAZON_SOCIAL
        });
        if((!razonSocial || razonSocial==="") && (nodeData.link!=="empresa" && nodeData.link!=="/")){
            alert("Antes debe guardar los datos de la empresa.")
            return
        }*/

        const originallyshareed = nodeData.isshareed;
        if (!e.shiftKey) {
            this.forEachNode(this.state.nodes, n => (n.isshareed = false));
        }
        //si tiene hijos sera un carpeta y no hará nada.
        if(nodeData.link===null)return;
        nodeData.isshareed = originallyshareed == null ? true : !originallyshareed;
        this.props.setMenuSeleccionado(nodeData.clave)

        /*
        if(nodeData.link==="capital-social"){

            cliente = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/api",
                credentials: 'include',
            });
    
            //chequeamos si hay datos de la forma societaria.
            await cliente
            .query({
            query: gql`
                {
                    personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_SOCIEDAD,ID_TIPO_CAPITAL_SOCIAL,FECHA_FINALIZACION}
                }
            `
            })
            .then(result =>{
                // //console.log(result)
                if(!result.data.personasJuridicas[0].ID_TIPO_SOCIEDAD && !result.data.personasJuridicas[0].ID_CAPITAL_SOCIAL){
                    this.setState({mostrarMenuCapital:true})
                }else{
                    this.setState({mostrarMenuCapital:false})
                    this.props.setEditable(nodeData.EDITAR)
                    this.props.history.push(nodeData.link)
                }
            });
           return

        }*/

        this.setState(this.state);
        this.props.setEditable(nodeData.EDITAR)
        if(nodeData.link.includes("http")){
            let win = window.open (nodeData.link, '_blank')
            win.focus()
        }else{
            this.props.history.push(nodeData.link)
        }

        localStorage.setItem("ayuda",false)
    };

    handleNodeCollapse = (nodeData: ITreeNode) => {
        nodeData.isExpanded = false;
        this.setState(this.state);
    };

    handleNodeExpand = (nodeData: ITreeNode) => {
        nodeData.isExpanded = true;
        this.setState(this.state);
    };

    tieneHijos=(menu,arrayMenus)=>{
        let hijos=arrayMenus.filter(function(element) {
            return element.ID_PADRE===menu.ID_MENU
        });
  
        if(hijos.length>0)menu.childNodes=hijos;
        return menu;
    }

    forEachNode(nodes: ITreeNode[], callback: (node: ITreeNode) => void) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }
}

export default withTranslation()(withRouter(Menu))