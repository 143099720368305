import React, { Component } from 'react';
import { Card } from "@blueprintjs/core"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class Jurisprudencia extends Component {
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"}) + " | " + i18next.t("jurisprudencia.title",{ns:"page"})

        return (
            <>
                <div className="pixelAlrededor">
                    <Card id="jurisprudencia">
                        <h5><a href="#jurisprudencia">{i18next.t("jurisprudencia.descargar",{ns:"page"})}</a></h5>
                        <p>{i18next.t("jurisprudencia.descargar",{ns:"page"})}</p>
                        <div className="tablaMitad">
                            <a role="button" target="_blank"  rel="noopener noreferrer" href="http://www.poderjudicial.es/cgpj/es/Poder-Judicial/Tribunal-Supremo/Sala-de-prensa/Notas-de-prensa/-El-Tribunal-Supremo-destaca-la-importancia-de-implantar-planes-de--compliance-program--para-prevenir-delitos-dentro-de-las-empresas" class="bp3-button bp3-icon-git-repo">{i18next.t("jurisprudencia.tribunal",{ns:"page"})}</a>
                        </div>
                        <br />
                        <br />
                        <br />
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Jurisprudencia)