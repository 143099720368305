import React from "react"
import { Tabs, Tab, Card, Elevation, Callout } from "@blueprintjs/core"
import PactosBloqueo from "./PactosBloqueo"
import PactosDefensa from "./PactosDefensa"
import PactosNoAgresion from "./PactosNoAgresion"
import AcuerdosConversionSalida from "./AcuerdosConversionSalida"

export default class PactoRelacion extends React.Component {

    state = {
        pestaña: "pactos-bloqueo",
        animacion: true,
        modalNuevoAbierto: false,
        modalEditarAbierto: false,
        modalBorrarAbierto: false,
        
    };

    render(){

        return (
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="bp3-intent-danger">
                        {this.props.i18next.t("acuerdosparasociales.descrelacion",{ns:"page"})}
                    </Callout>
                    </Card>
                </div>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Tabs id="pactos-bloqueo"
                            animate={this.animacion} 
                            onChange={this.cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                            renderActiveTabPanelOnly={true}
                        >
                            <Tab id="pactos-bloqueo" title={this.props.i18next.t("acuerdosparasociales.bloqueo",{ns:"page"})} panel={<PactosBloqueo editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios}  ref={(input) => { this.pactosBloqueo= input; }} i18next={this.props.i18next} />} />
                            <Tab id="pactos-defensa" title={this.props.i18next.t("acuerdosparasociales.defensa",{ns:"page"})} panel={<PactosDefensa editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios} ref={(input) => { this.pactosDefensa= input; }}  i18next={this.props.i18next} />} />
                            <Tab id="pactos-no-agresion" title={this.props.i18next.t("acuerdosparasociales.noagresion",{ns:"page"})} panel={<PactosNoAgresion editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios} ref={(input) => { this.pactoNoAgresion= input; }}  i18next={this.props.i18next} />} />
                            <Tab id="acuerdos-conversion-salida" title={this.props.i18next.t("acuerdosparasociales.acuerdos",{ns:"page"})} panel={<AcuerdosConversionSalida editable={this.props.editable} logotipo={this.props.logotipo} datosEmpresa={this.props.datosEmpresa} personasFisicas={this.props.personasFisicas} personasJuridicas={this.props.personasJuridicas} socios={this.props.socios}  ref={(input) => { this.AcuerdosConversionSalida= input; }} i18next={this.props.i18next} />} />
                        </Tabs>
                    </Card>
                </div>
            </>
            
        )
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });
}