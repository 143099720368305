import React from "react"
import { FormGroup,TextArea, Intent, Button,AnchorButton,Tooltip,Classes,InputGroup,Dialog, Card, Elevation, Navbar, Alignment, ButtonGroup, Callout } from "@blueprintjs/core"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { Cargando } from "../Cargando"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class Vinculos extends React.Component {
    state = {
        fusiones: "",
        escisiones: "",
        filiales: "",
        nuevoActivado:true,
        actualizarActivado:false,
        borrarActivado:false,
        abrirModalSociedades:false,
        abrirModalBorrarSociedades:false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        sociedadSeleccionada:null,
        idPersona:null,
        nombre:null,
        comentarios:null,
        controlesDesactivados:false,
        participacion:1.00,
        sociedades: [
            {
                "nombre": "test",
                "Participacion": "2",
                "Comentarios": "testeando ando"
            }
        ],
        cargando: false,
        cargandoVinculos: false,
    }

    cargarSociedades=async ()=>{

        await this.props.client
        .query({
            query: gql`
            {
                sociedadesVinculadas{ID_SOCIEDAD_VINCULADA,NOMBRE,PORCENTAJE_PARTICIPACION,COMENTARIOS}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 

            let sociedades=result.data.sociedadesVinculadas;
            let copySociedades=[]
            sociedades.forEach((sociedad,index)=>{

                copySociedades.push({
                    "id":sociedad.ID_SOCIEDAD_VINCULADA,
                    "nombre":sociedad.NOMBRE,
                    "Participacion": sociedad.PORCENTAJE_PARTICIPACION,
                    "Comentarios": sociedad.COMENTARIOS
                })
            })
            this.setState({sociedades:copySociedades,
            cargandoVinculos:false})
        })
    }

    cargarPersona=()=>{

        this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ESCISIONES,FILIALES,FUSIONES}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 

            this.setState({idPersona:result.data.personasJuridicas[0].ID_PERSONA})
            this.setState({filiales:result.data.personasJuridicas[0].FILIALES})
            this.setState({escisiones:result.data.personasJuridicas[0].ESCISIONES})
            this.setState({fusiones:result.data.personasJuridicas[0].FUSIONES})

        })

    }
    componentDidMount(props){

        this.setState({cargando: true})

        //CARGA DE FUSIONES,ESCISIONES Y FILIALES
        this.cargarPersona()
        //OBTENCION SOCIEDADES VINCULADAS
        this.cargarSociedades()

        this.setState({cargando: false})
        
    }

    guardarCambios = (rowIndex) => {

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:"global"}))
            return
        }

        let filiales=this.state.filiales?'"""'+this.state.filiales+'"""':null
        let escisiones=this.state.escisiones?'"""'+this.state.escisiones+'"""':null
        let fusiones=this.state.fusiones?'"""'+this.state.fusiones+'"""':null

        //actualizar filiales,escisiones y fusiones.
        this.props.client.mutate({
            mutation: gql`
                mutation{actualizarPersonaJuridica(ID_PERSONA:${this.state.idPersona},VALORES:{FILIALES:${filiales},ESCISIONES:${escisiones},FUSIONES:${fusiones}}){ID_PERSONA}}
            `
        }).catch(err=>{
            alert(i18next.t("error",{ns:"global"}))
        })

        if(this.state.pendienteGuardar){

            this.props.client.mutate({
                mutation: gql`
                    mutation{crearSociedadVinculada(VALORES:{NOMBRE:"${this.state.nombre}",PORCENTAJE_PARTICIPACION:${this.state.participacion},COMENTARIOS:${this.state.comentarios?'"""'+this.state.comentarios+'"""':null}}){ID_SOCIEDAD_VINCULADA}}
                `
            }).then(async result=>{

                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.cargarSociedades()

                this.setState({pendienteGuardar:false})
                this.setState({controlesDesactivados:false})
            
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }else if(this.state.pendienteActualizar){

            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarSociedadVinculada(ID_SOCIEDAD_VINCULADA:${this.state.sociedadSeleccionada.id},VALORES:{NOMBRE:"${this.state.nombre}",PORCENTAJE_PARTICIPACION:${this.state.participacion},COMENTARIOS:${this.state.comentarios?'"""'+this.state.comentarios+'"""':null}}){ID_SOCIEDAD_VINCULADA}}
                `
            }).then(async result=>{

                alert(i18next.t("updatecorrecto",{ns:"global"}))
                await this.cargarSociedades()

                
                this.setState({pendienteActualizar:false})
                this.setState({controlesDesactivados:false})                
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }else if(this.state.pendienteBorrar){

            this.props.client.mutate({
                mutation: gql`
                    mutation{eliminarSociedadVinculada(ID_SOCIEDAD_VINCULADA:${this.state.sociedadSeleccionada.id}){ID_SOCIEDAD_VINCULADA}}
                `
            }).then(result=>{

                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({sociedadSeleccionada:null})
                this.setState({pendienteBorrar:false})
                this.setState({controlesDesactivados:false})

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }


    };

    render(){
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("empresa.vinculos",{ns:"company"});
        
        const handleCambiarEscisiones = (e) => {
            this.setState({escisiones:e.currentTarget.value})
        };

        const handleCambiarFusiones = (e) => {
            this.setState({fusiones:e.currentTarget.value})
        };

        const handleCambiarFiliales = (e) => {
            this.setState({filiales:e.currentTarget.value})
        };

        const handleCambiarNombre = (e) => {
            this.setState({nombre:e.currentTarget.value})
        };

        const handleCambiarComentarios = (e) => {
            this.setState({comentarios:e.currentTarget.value})
        };

        const handleCambiarParticipacion = (e) => {
            this.setState({participacion:e.currentTarget.value})
        };

        const anadirSociedad = () => {

            if(!this.state.nombre){
                return
            }
            if(!this.state.participacion || !(this.state.participacion>=0 && this.state.participacion<=100)){
                return
            }
            let sociedades=this.state.sociedades

            sociedades.push({
                "nombre": this.state.nombre,
                "Participacion":this.state.participacion
            })

            this.setState({sociedades:sociedades})
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        };

        const actualizarSociedad = () => {

            this.state.sociedades.find((sociedad,index)=>{
                if(sociedad.id===this.state.sociedadSeleccionada.id){
                    sociedad.nombre=(this.state.nombre?this.state.nombre:this.state.sociedadSeleccionada.nombre)
                    sociedad.Participacion=(this.state.participacion?this.state.participacion:this.state.sociedadSeleccionada.Participacion)
                    sociedad.Comentarios=(this.state.comentarios?this.state.comentarios:this.state.sociedadSeleccionada.Comentarios)
                }
                return sociedad.id===this.state.sociedadSeleccionada.id
            })
            
            this.setState(this.state)
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            
            cerrarModal()
        };

        const cerrarModal = () => {
            this.setState({
                abrirModalSociedades: false,
                abrirModalBorrarSociedades: false,
                cargandoVinculos:false
            })
        }
        const abrirModal = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //limpia de estados.
            this.setState({nombre:null})
            this.setState({participacion:1.00})
            this.setState({comentarios:null})
            this.setState({sociedadSeleccionada:null})

            this.setState({actualizarActivado:false})
            this.setState({borrarActivado:false})
            this.setState({nuevoActivado:true})
            this.setState({
                abrirModalSociedades: true,
                cargandoVinculos:true
            })

        }
        const editarModal = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.sociedadSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({nombre:this.state.sociedadSeleccionada.nombre})
            this.setState({participacion:this.state.sociedadSeleccionada.Participacion})
            this.setState({comentarios:this.state.sociedadSeleccionada.comentarios})

            this.setState({actualizarActivado:true})
            this.setState({borrarActivado:false})
            this.setState({nuevoActivado:false})

            this.setState({
                abrirModalSociedades: true,
                cargandoVinculos:true
            })
        }
        const borrarModal = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }


            if(!this.state.sociedadSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({actualizarActivado:false})
            this.setState({borrarActivado:true})
            this.setState({nuevoActivado:false})

            this.setState({
                abrirModalBorrarSociedades: true,
            })
        }

        const eliminarSociedad= () => {
            var sociedades=this.state.sociedades.filter(sociedad => sociedad.id !== this.state.sociedadSeleccionada.id)
            this.setState({sociedades:sociedades})
            cerrarModal()
            this.setState({pendienteBorrar:true})
            this.setState({controlesDesactivados:true})
            
        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            //limpia de estados.
            this.setState({nombre:null})
            this.setState({participacion:1.00})
            this.setState({comentarios:null})
            this.setState({sociedadSeleccionada:null})
            this.setState({controlesDesactivados:false})
            this.cargarPersona()
            this.cargarSociedades()

        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    sociedadSeleccionada: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const columns = [
            {
                dataField: 'nombre',
                text: i18next.t("empresa.tabvinculos.colname",{ns:"company"}),
                sort: true
            },
            {
                dataField: 'Participacion',
                text: i18next.t("empresa.tabvinculos.colparticipacion",{ns:"company"}),
                sort: true
            }
        ];

        return(
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("empresa.tabvinculos.titleadd",{ns:"company"}):i18next.t("empresa.tabvincules.titleedit",{ns:"company"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.abrirModalSociedades}
                    >
                        <div>
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label={i18next.t("empresa.tabvinculos.name",{ns:"company"})}
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.nombre?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("empresa.tabvinculos.name",{ns:"company"})}
                                >
                                    <InputGroup
                                        name="Nombre"
                                        placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresa.tabvinculos.name",{ns:"company"})}
                                        onChange={handleCambiarNombre}
                                        intent={this.state.nombre?"primary":"danger"}
                                        defaultValue={this.state.sociedadSeleccionada?this.state.sociedadSeleccionada.nombre:""}
                                    />
                                </FormGroup>
                            </div>
                            <div className="pixelAlrededor">
                                <FormGroup
                                        label={i18next.t("empresa.tabvinculos.porcparticipacion",{ns:"company"})}
                                        labelFor="informacion"
                                        intent="danger"
                                        helperText={this.state.participacion?(this.state.participacion>=0 && this.state.participacion<=100)?"":i18next.t("empresa.tabvinculos.validateporcparticipacion",{ns:"company"}):i18next.t("emptyfield",{ns:"global"})+i18next.t("empresa.tabvinculos.porcparticipacion",{ns:"company"})}
                                >
                                    <InputGroup
                                        name="Participacion"
                                        placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresa.tabvinculos.porcparticipacion",{ns:"company"})}
                                        onChange={handleCambiarParticipacion}
                                        intent={(this.state.participacion && (this.state.participacion>=0 && this.state.participacion<=100))?"primary":"danger"}
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        max="100"
                                        defaultValue={this.state.sociedadSeleccionada?this.state.sociedadSeleccionada.Participacion:this.state.participacion}
                                    />
                                </FormGroup>
                            </div>
                            <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("empresa.tabvinculos.comments",{ns:"company"})}
                                labelFor="comentarios"
                                intent="danger"
                                fill={true}
                            >
                            <TextArea
                                fill={true}
                                intent={Intent.PRIMARY}
                                onChange={handleCambiarComentarios}
                                defaultValue={this.state.sociedadSeleccionada?this.state.sociedadSeleccionada.Comentarios:""}
                            />
                            </FormGroup>
                        </div>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirSociedad:actualizarSociedad}
                                >
                                {i18next.t("aceptar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("empresa.tabvinculos.titledel",{ns:"company"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.abrirModalBorrarSociedades}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h4>{i18next.t("empresa.tabvinculos.questiondel",{ns:"company"})}</h4>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarSociedad}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                    <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">

                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabvinculos.title",{ns:"company"})}</Callout>
                    <Navbar>
                        <Navbar.Group>

                    {this.state.controlesDesactivados?[
                        <>
                                <Button alignText="right" icon="add" disabled active={this.state.nuevoActivado} text={i18next.t("new",{ns:"global"})} className="iconoIzquierda"/>
                                <Button alignText="right" icon="edit" disabled active={this.state.actualizarActivado} text={i18next.t("edit",{ns:"global"})} className="iconoIzquierda"/>            
                                <Button alignText="right" icon="delete" disabled active={this.state.borrarActivado} text={i18next.t("delete",{ns:"global"})} className="iconoIzquierda"/>
                        </>
                    ]:[
                        <>
                                <Button alignText="right" icon="add" onClick={abrirModal} active={this.state.nuevoActivado} text={i18next.t("new",{ns:"global"})} className="iconoIzquierda"/>
                                <Button alignText="right" icon="edit"onClick={editarModal} active={this.state.actualizarActivado} text={i18next.t("edit",{ns:"global"})} className="iconoIzquierda"/>            
                                <Button alignText="right" icon="delete" onClick={borrarModal} active={this.state.borrarActivado} text={i18next.t("delete",{ns:"global"})} className="iconoIzquierda"/>
                        </>
                    ]}
                    </Navbar.Group>
                    <Navbar.Group align={Alignment.RIGHT}>
                        <ButtonGroup>
                            <Button 
                                alignText="right"
                                icon="floppy-disk"
                                text={i18next.t("guardar",{ns:"global"})}
                                disabled={!this.state.controlesDesactivados}
                                intent="danger"
                                onClick={this.guardarCambios}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="cross"
                                intent="danger"
                                text={i18next.t("cancelar",{ns:"global"})}
                                disabled={!this.state.controlesDesactivados}
                                onClick={cancelarCambios}
                                className="iconoDerecha bp3-minimal"
                            />
                        </ButtonGroup>                        
                        </Navbar.Group>
                    </Navbar>
                    {this.state.cargandoVinculos?
                        <Cargando />
                    :
                        <BootstrapTable
                            keyField='id'
                            data={ this.state.sociedades }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            defaultSorted={ const_table.defaultSorted('nombre','asc') }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    }
                    
                    <br />
                    <br />

                    <div className="tablaTercio pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabvinculos.fusiones",{ns:"company"})}</Callout>
                            <TextArea
                                large={true}
                                fill={true}
                                intent={Intent.PRIMARY}
                                onChange={handleCambiarFusiones}
                                value={this.state.fusiones?this.state.fusiones:""}
                            />
                        </Card>
                    </div>
                    <div className="tablaTercio pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabvinculos.escisiones",{ns:"company"})}</Callout>
                            <TextArea
                                large={true}
                                fill={true}
                                intent={Intent.PRIMARY}
                                onChange={handleCambiarEscisiones}
                                value={this.state.escisiones?this.state.escisiones:""}
                            />
                        </Card>
                    </div>
                    <div className="tablaTercio pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabvinculos.filiales",{ns:"company"})}</Callout>
                            <TextArea
                                large={true}
                                fill={true}
                                intent={Intent.PRIMARY}
                                onChange={handleCambiarFiliales}
                                value={this.state.filiales?this.state.filiales:""}
                            />
                        </Card>
                    </div>
                    <ButtonGroup>
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text={i18next.t("guardar",{ns:"global"})}
                            intent="danger"
                            onClick={this.guardarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text={i18next.t("cancelar",{ns:"global"})}
                            onClick={cancelarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                    </ButtonGroup>   

                    </Card>
                </>
            }
            </>
        )
    }
}
export default withTranslation()(withApollo(Vinculos))