import React from "react";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class _404 extends React.Component {
    render(){
        return (
            <>
                <h1>{i18next.t("pagenotfound",{ns:"global"})}</h1>
            </>
        )
    }
}

export default withTranslation()(_404);