import React, { Component } from 'react';
import {Spinner,Card, Elevation,FormGroup,Button, Dialog, Radio, RadioGroup, Classes, Tooltip, AnchorButton, Intent, Callout } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import moment from 'moment';
import { Cargando } from "./Cargando";
import Certificados from "../Pantallas/Elementos/AdjuntosCertificadosCompliance"
import Contratos from "../Pantallas/Elementos/AdjuntosContratosCompliance"
import { withApollo } from 'react-apollo';
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './stylesPrint';


class ContratoCompliance extends Component {

    state={
        contratoCompliance: false,
        soloPenales:true,
        listadoDepartamentos: [],
        departamentosSeleccionados: [],
        menuAbierto: false,
        modalAdvertencia1:false,
        modalAdvertencia2:false,
        idUsuario:null,
        hayContrato:null,
        aceptarPulsado:false,
        modalCreado:false,
        cargando:false,
        seguro: false,
        logotipo:null,
        datosEmpresa:[],
        departamentos:null,
        cargandoImprimirC:false,
        cargandoImprimirCC:false,
        modalImprimirC:false,
        modalImprimirCC:false,
        imprimirContratoActivado:false,
        imprimirCertActivado:false
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }
   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarEmpresa() 
    this.setState({
        datosEmpresa:datosEmpresa,
    })

}
cargarUsuario=async()=>{
      //obtener el id del usuario actual logueado en la aplicacion.
      await this.props.client
      .query({
          query: gql`
          {obtenerUsuarioActual}
          `,fetchPolicy:'network-only'
      }).then(result=>{ 
          this.setState({idUsuario:result.data.obtenerUsuarioActual}) 
      })
  
}
  
cargarListaDepartamentos=async()=>{
    let contratos= await this.props.client
    .query({
        query: gql`
        {complianceContratos{ID_CONTRATO}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.complianceContratos
    }) 
     let departamento= await this.props.client.query({
            query:gql`{departamentos{ID_DEPARTAMENTO,DESCRIPCION}}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.departamentos
        })
    
        if(contratos && contratos.length>0){
            this.setState({hayContrato:true})
        }else{
            let departamentos=[]
           departamento.forEach(dpto=>{
                departamentos.push({"label":dpto.DESCRIPCION,"value":dpto.ID_DEPARTAMENTO})
            })
            console.log(departamentos)
            this.setState({hayContrato:false,
                         listadoDepartamentos:departamentos})
        }

}

     componentDidMount(props){
         this.setState({cargando:true}, async()=>{
             await this.cargarDatos()
             await this.obtenerLogoEmpresa()
             await this.cargarUsuario()
             await this.cargarListaDepartamentos()
             this.setState({cargando:false})
         })
    }
    generarCertificado=async()=>{
        this.setState({
            cargandoImprimirC:true,
            modalImprimirC:true
        }, async () =>{
            this.setState({cargandoImprimirC:false}, () =>{
                ReactDOM.render(this.ImprimirCertificado(), document.getElementById('generarC-pdf'))
            })
        })
    }

    generarContrato=async()=>{
        this.setState({
            cargandoImprimirCC:true,
            modalImprimirCC:true
        }, async () =>{
            this.setState({cargandoImprimirCC:false}, () =>{
                ReactDOM.render(this.ImprimirContrato(), document.getElementById('generarCC-pdf'))
            })
        })
    }
    ImprimirCertificado=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("contratoscompliance.certificados",{ns:"page"})}</Text>
                </View>
                <View style={styles.rowTitleCompliance}>
                    <Text style={styles.labelTitleCompliance}>CERTIFICADO DE IMPLANTACIÓN DEL SISTEMA DE GESTIÓN DE RIESGOS Y DE GOBIERNO
                            CORPORATIVO DE LA SOCIEDAD</Text>
                </View>                   
                            <View >
                                <View style={styles.rowTitleCompliance}>
                                    <Text style={styles.textCompliance}>
                                    D./Dª. _______________________________________________________________________ titular del
                        D.N.I. número _________________, en calidad de ____________________________________________
                        De la entidad ______________________________________________________________________________
                        Que tiene su domicilio en la calle __________________________________________________________
                        De la localidad de _____________________________________ y con C.I.F. ________________________
                                    </Text>

                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>DESCRIPCIÓN:</Text>
                                </View>
                                <View style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}>I.- Que se ha implantado en la empresa un sistema de GRC, consistente en la gestión integrada del
                                    gobierno corporativo, de los recursos humanos y de los riesgos de compliance.
                                    </Text>
                                </View>
                                <View style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}> II.- Que se ha designado el Órgano de Cumplimiento y adoptado procedimientos de evaluación, control y
                                     seguimiento, a través de la aplicación Lex Control.
                                    </Text>
                                </View>
                                <View style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}> Y para que así conste y surta los efectos que fueren procedentes en orden a la acreditación de que la
                                        empresa sigue estándares de dirección adecuados a políticas de cumplimiento normativo, lo expido yfirmo
                                    </Text>
                                </View>
                                <View style={styles.rowFooterCompliance}>
                                    <Text style={styles.textFooterCompliance}> en ________________a _________ de _________________________ de _________.</Text>
                                </View>

                            </View>
                      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    ImprimirContrato=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("contratoscompliance.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                          
                            <View>
                                <View style={styles.rowTitleComplianceCC}>
                                    <Text style={styles.textContrato}>
                                    CONTRATO DE PRESTACIÓN DE SERVICIOS JURÍDICOS 
                                    </Text>
                                </View>
                                <View style={styles.rowContrato}>
                                    <Text style={styles.textCompliance}> En _______________ a ___________________________________ INTERVIENEN  De una parte,
                                    D. _____________________________________________, mayor de edad, abogado, colegiado nº ______________ del Ilustre Colegio de Abogados de Madrid, en calidad de Administrador Único de la empresa _________________________________________, que tiene su domicilio en ________________, en la _______________________________________, código postal ___________ y C.I.F. número ___________________ (en adelante ASESOR). Y de otra parte,
                                    D.	____________________________________________,	mayor	de	edad,	en	calidad	de Administrador de la empresa _________________________________________________      que tiene	su	domicilio	en	____________________,en la ___________________________________________, código postal _____________y C.I.F. número ______________________ (en adelante CLIENTE). MANIFIESTAN
                                    Comparecen ambos manifestando estar vigentes sus cargos y tener facultades suficientes para actuar	en	representación	de	su	respectiva	sociedad,	así	como	que	por	Don __________________________, poseer los títulos académicos de Licenciado en Derecho y de __________________________ y de pertenecer al Ilustre Colegio de Abogados de ____________________. 
                                    ACUERDAN
                                    </Text>
                                </View>
                                <View style={styles.rowContrato}>
                                    <Text style={styles.textCompliance}>
                                    Comparecen ambos manifestando estar vigentes sus cargos y tener facultades suficientes para actuar	en	representación	de	su	respectiva	sociedad,	así	como	que	por	Don __________________________, poseer los títulos académicos de Licenciado en Derecho y de __________________________ y de pertenecer al Ilustre Colegio de Abogados de ____________________. 
                                    acuerdan celebrar un contrato de ARRENDAMIENTO DE SERVICIOS, atendiendo a las siguientes CLAUSULAS:
                                    </Text>
                                </View>
                                <View style={styles.rowTitleComplianceCC}>
                                    <Text style={styles.textCompliance}>
                                    PRIMERA.- La empresa _____________________________________________________, contrata los servicios de la empresa ________________________________________________________.
                                    </Text>
                                </View>
                                <View  style={styles.rowTitleComplianceCC}>
                                    <Text style={styles.textCompliance}>SEGUNDA.- OBJETO. La actividad que debe prestar el ASESOR es la de implantación, seguimiento y supervisión del Sistema de Gestión de Compliance y del Gobierno Corporativo en la empresa CLIENTE.

                                    </Text>
                                 </View> 
                                 <View  style={styles.rowTitleComplianceCC}>
                                    <Text style={styles.textCompliance}> 
                                            TERCERA.- PRECIO. El precio establecido por implantación, seguimiento y supervisión del Sistema de Gestión de Compliance y del Gobierno Corporativo en la empresa CLIENTE es de //_______.-// €. (____________ euros), más IVA, por todos los conceptos y según el siguiente desglose: 
                                    </Text>
                                 </View>
                                 <View  style={styles.rowContrato}>
                                    <Text style={styles.textCompliance}>
                                    A) COSTE DE IMPLANTACIÓN DEL PROGRAMA. Siendo el coste establecido para la implantación del Sistema de Gestión de Gobierno Corporativo y Compliance de _______€ (____ euros) por cada millón de €uros de facturación total, con un precio mínimo de _____, y teniendo en cuenta el volumen de operaciones de la empresa Cliente durante el pasado ejercicio se establece un precio objetivo de //_______.-// €uros (_________________________ euros). No obstante lo anterior y atendiendo a que la empresa Cliente tiene vinculación con el abogado asesor en virtud de otros contratos de prestación de servicios, se conviene en acordar como precio final para la implantación de todo el programa de Gestión de Gobierno Corporativo y Compliance, el de //_______.-// €. (_______________ euros).
                                    </Text>
                                 </View>
                                 <View  style={styles.rowContrato}>
                                    <Text style={styles.textCompliance}>
                                    B) COSTE DE LA LICENCIA DE LA APLICACIÓN LEX CONTROL®. El precio de la aplicación informática Lex Control® que sirve de soporte para la implantación del sistema de gobierno corporativo y compliance es de //________.-// €. (________________________________________ euros). No obstante lo anterior y atendiendo a que la empresa Cliente tiene vinculación con el abogado asesor en virtud de otros contratos de prestación de servicios, se conviene en acordar que el precio final de la licencia de la aplicación Lex Control® es 0 €uros (cero euros). 
                                    </Text>
                                 </View> 
                                 <View  style={styles.rowTitleComplianceCC}>
                                    <Text style={styles.textCompliance}>
                                    CUARTA.- CONDICIONES DE LA PRESTACIÓN DEL SERVICIO
                                    1ª.- El sistema de gestión del gobierno corporativo y de compliance en la empresa se estructura por las áreas funcionales y los departamentos con que cuenta la empresa Cliente, siendo el ámbito de aplicación del presente contrato la totalidad de las áreas y departamentos existentes y de esta forma serán dados de alta en la aplicación Lex Control®, excepto aquellas áreas y/o departamentos expresamente excluidas por la empresa Cliente y que figurarán en documento suscrito al efecto. Ello no obstante no alterará en modo alguno el precio estipulado en la cláusula tercera.
                                    2ª.- La empresa Cliente se compromete al nombramiento de un responsable o departamento de cumplimiento en el seno de la sociedad, que será interlocutor con el responsable de la empresa Asesor y al que se le dispensará la formación precisa para el desempeño de su función. E igualmente se compromete la empresa Cliente a dotar de los medios materiales y humanos necesarios para llevar a cabo un eficaz programa de cumplimiento legal y de prevención de los delitos en el seno de la sociedad, obligándose a facilitar a la empresa Asesor información veraz sobre las actividades y  funcionamiento de la empresa, así como a actuar en el cumplimiento de este contrato de prestación de servicios conforme a las reglas de la buena fe.
                                    3ª.- La implantación inicial, y el establecimiento de los procesos de seguimiento y supervisión del Sistema de Gestión de Compliance y del Gobierno Corporativo en la empresa CLIENTE se llevará a cabo en un período de 12 meses a contar desde la firma de este contrato, dividiéndose por ello el pago del coste total del servicio en 12 mensualidades iguales de //____.-// €. (___________ euros), más el correspondiente IVA.
                                    4ª.- Durante el proceso de implantación inicial se procederá a instalar la aplicación Lex Control® en todos los puestos de trabajo que la empresa Cliente considere necesarios, resultando recomendable hacerlo en todos aquellos correspondientes a jefes de cada departamento, otorgándose los distintos permisos de acceso en función del nivel de responsabilidad definido por el órgano de administración de la sociedad. El representante de la empresa Asesor dispondrá de un acceso con permiso de administrador a la aplicación con el objeto de poder supervisar todo cuanto forme parte de la aplicación sin ningún tipo de limitación.
                                    5ª.- La empresa Asesor se compromete a alojar la base de datos creada a partir de la aplicación Lex Control® con un proveedor de reconocido prestigio y previa comprobación de que se cumplen las normas de seguridad y de protección de datos. Actualmente la base de datos de la empresa Cliente estará alojada en los servidores AZURE de Microsof Corporation.
                                    6ª.- La empresa Asesor se reserva el derecho de desistir y renunciar al cumplimiento de este contrato en el caso de la empresa Cliente se negara a disponer las medidas que se consideraran imprescindibles para la implantación del sistema de prevención de delitos en la empresa, así como si incurriera deliberadamente en su incumplimiento o lo tolerara.
                                    7ª.- Este contrato de prestación de servicios no comprende la representación y defensa de la sociedad o de cualquiera de sus administradores en procedimientos administrativos o judiciales de cualquier orden jurisdiccional.
                                    </Text>
                                 </View> 
                                 <View  style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}>
                                    QUINTA.- SEGUIMIENTO Y SUPERVISIÓN ANUAL. Tras la implantación llevada a cabo durante los primeros doce meses de vigencia de este contrato, se producirá la renovación automática por períodos anuales, a fin de llevar a cabo las tareas de seguimiento, control y supervisión del sistema de gestión del cumplimiento y del gobierno corporativo, para lo que se establece un coste del ___% (____________ por ciento) estipulado en la cláusula tercera, es decir de //_______.-// €. (______________________ euros) anuales, más IVA.	En todo caso están incluidas las actualizaciones y nuevas versiones de la aplicación LexControl®.
                                    Y para que así conste, se extiende el presente documento por duplicado ejemplar, que es firmado por los comparecientes en prueba de conformidad y señal de cumplimiento.
                                    </Text>                           
                                 </View>         
                                
                              
                                <View style={styles.rowFooterCompliance}>
                                    <Text style={styles.textFooterCompliance}> en ________________a _________ de _________________________ de _________.</Text>
                                </View>
                             </View>                     
                      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

   

    
    // Certificado =()=> (
    //     <div  ref={(input) => { this.PdfCertificadoCompliance= input; }}>
    //         <div className="html-pdf" ref={(input) => { this.tablaPersonasAImprimir = input; }} >
    //             <div className="body-pdf">
    //                     <br />
    //                     <img
    //                         className="App-logo-pdf"
    //                         src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                         alt="logotipo"
    //                     />
    //                     <br />
    //                     <Card interactive={true} elevation={Elevation.TWO}>
    //                     <h4>
    //                         {`CERTIFICADO DE IMPLANTACIÓN DEL SISTEMA DE GESTIÓN DE RIESGOS Y DE GOBIERNO
    //                         CORPORATIVO DE LA SOCIEDAD __________________________________________________________`}
    //                     </h4>
    //                     <p>
    //                     D./Dª. _______________________________________________________________________ titular del
    //                     D.N.I. número _________________, en calidad de ____________________________________________
    //                     De la entidad __________________________________________________________________________
    //                     Que tiene su domicilio en la calle __________________________________________________________
    //                     De la localidad de _____________________________________ y con C.I.F. ________________________
    //                     </p>

    //                     <h4>CERTIFICA:</h4>
    //                     <p>
    //                     I.- Que se ha implantado en la empresa un sistema de GRC, consistente en la gestión integrada del
    //                     gobierno corporativo, de los recursos humanos y de los riesgos de compliance.
    //                     </p>
    //                     <p>
    //                     II.- Que se ha designado el Órgano de Cumplimiento y adoptado procedimientos de evaluación, control y
    //                     seguimiento, a través de la aplicación Lex Control.
    //                     </p>
    //                     <p>
    //                     Y para que así conste y surta los efectos que fueren procedentes en orden a la acreditación de que la
    //                     empresa sigue estándares de dirección adecuados a políticas de cumplimiento normativo, lo expido y
    //                     firmo
    //                     </p>
    //                     <br />
    //                     <br />
    //                     <br />
    //                     <h4>en ________________a _________ de _________________________ de _________.</h4>
    //                 </Card>
    //             </div>
    //         </div>
    //     </div>
    // );

    // Contrato =()=> (
    //     <div  ref={(input) => { this.PdfContratoCompliance = input; }}>
    //         <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <Card interactive={true} elevation={Elevation.TWO}>
    //                 <div>
    //                     <p>
    //                         {`CONTRATO DE PRESTACIÓN DE SERVICIOS JURÍDICOS

    //                         En _______________ a ________________________________

    //                         INTERVIENEN

    //                         De una parte,

    //                         D. __________________________, mayor de edad, abogado, colegiado nº ___________ del Ilustre Colegio de Abogados de Madrid, en calidad de Administrador Único de la empresa _________________________________________, que tiene su domicilio en ________________, en la _______________________________________, código postal _______ y C.I.F. número ___________________ (en adelante ASESOR).



    //                         Y de otra parte,

    //                         D.	____________________________________________,	mayor	de	edad,	en	calidad	de Administrador de la empresa _________________________________________________      que tiene	su	domicilio	en	________________,                 en                 la ___________________________________________, código postal _____________y C.I.F. número ________________ (en adelante CLIENTE).

    //                         MANIFIESTAN

    //                         Comparecen ambos manifestando estar vigentes sus cargos y tener facultades suficientes para actuar	en	representación	de	su	respectiva	sociedad,	así	como	que	por	Don __________________________, poseer los títulos académicos de Licenciado en Derecho y de __________________________ y de pertenecer al Ilustre Colegio de Abogados de _____________.

    //                         ACUERDAN`
    //                         }
    //                     </p>
    //                 </div>
    //                 <div >
    //                     <p>
    //                         {`
    //                             Celebrar un contrato de ARRENDAMIENTO DE SERVICIOS, atendiendo a las siguientes:

    //                             CLAUSULAS

    //                             PRIMERA.- La empresa _____________________________________________________, contrata los servicios de la empresa ________________________________________________________.
                                
    //                             SEGUNDA.- OBJETO. La actividad que debe prestar el ASESOR es la de implantación, seguimiento y supervisión del Sistema de Gestión de Compliance y del Gobierno Corporativo en la empresa CLIENTE.

    //                             TERCERA.- PRECIO. El precio establecido por implantación, seguimiento y supervisión del Sistema de Gestión de Compliance y del Gobierno Corporativo en la empresa CLIENTE es de //_______.-// €. (____________ euros), más IVA, por todos los conceptos y según el siguiente desglose:

    //                             A) COSTE DE IMPLANTACIÓN DEL PROGRAMA. Siendo el coste establecido para la implantación del Sistema de Gestión de Gobierno Corporativo y Compliance de _______€ (____ euros) por cada millón de €uros de facturación total, con un precio mínimo de _____, y teniendo en cuenta el volumen de operaciones de la empresa Cliente durante el pasado ejercicio se establece un precio objetivo de //_______.-// €uros (_________________________ euros). No obstante lo anterior y atendiendo a que la empresa Cliente tiene vinculación con el abogado asesor en virtud de otros contratos de prestación de servicios, se conviene en acordar como precio final para la implantación de todo el programa de Gestión de Gobierno Corporativo y Compliance, el de //_______.-// €. (_______________ euros).
    //                         `}
    //                     </p>
    //                 </div>
    //                 <div >
    //                     <p>
    //                         {`
    //                             B) COSTE DE LA LICENCIA DE LA APLICACIÓN LEX CONTROL®. El precio de la aplicación informática Lex Control® que sirve de soporte para la implantación del sistema de gobierno corporativo y compliance es de //________.-// €. (________________________________________ euros). No obstante lo anterior y atendiendo a que la empresa Cliente tiene vinculación con el abogado asesor en virtud de otros contratos de prestación de servicios, se conviene en acordar que el precio final de la licencia de la aplicación Lex Control® es 0 €uros (cero euros).

    //                             CUARTA.- CONDICIONES DE LA PRESTACIÓN DEL SERVICIO

    //                             1ª.- El sistema de gestión del gobierno corporativo y de compliance en la empresa se estructura por las áreas funcionales y los departamentos con que cuenta la empresa Cliente, siendo el ámbito de aplicación del presente contrato la totalidad de las áreas y departamentos existentes y de esta forma serán dados de alta en la aplicación Lex Control®, excepto aquellas áreas y/o departamentos expresamente excluidas por la empresa Cliente y que figurarán en documento suscrito al efecto. Ello no obstante no alterará en modo alguno el precio estipulado en la cláusula tercera.

    //                             2ª.- La empresa Cliente se compromete al nombramiento de un responsable o departamento de cumplimiento en el seno de la sociedad, que será interlocutor con el responsable de la empresa Asesor y al que se le dispensará la formación precisa para el desempeño de su función. E igualmente se compromete la empresa Cliente a dotar de los medios materiales y humanos necesarios para llevar a cabo un eficaz programa de cumplimiento legal y de prevención de los delitos en el seno de la sociedad, obligándose a facilitar a la empresa Asesor información veraz sobre las actividades y
                                
    //                             funcionamiento de la empresa, así como a actuar en el cumplimiento de este contrato de prestación de servicios conforme a las reglas de la buena fe.
    //                         `}
    //                     </p>
    //                 </div>
    //                 <div >
    //                     <p>
    //                         {`
    //                             3ª.- La implantación inicial, y el establecimiento de los procesos de seguimiento y supervisión del Sistema de Gestión de Compliance y del Gobierno Corporativo en la empresa CLIENTE se llevará a cabo en un período de 12 meses a contar desde la firma de este contrato, dividiéndose por ello el pago del coste total del servicio en 12 mensualidades iguales de //____.-// €. (___________ euros), más el correspondiente IVA.

    //                             4ª.- Durante el proceso de implantación inicial se procederá a instalar la aplicación Lex Control® en todos los puestos de trabajo que la empresa Cliente considere necesarios, resultando recomendable hacerlo en todos aquellos correspondientes a jefes de cada departamento, otorgándose los distintos permisos de acceso en función del nivel de responsabilidad definido por el órgano de administración de la sociedad. El representante de la empresa Asesor dispondrá de un acceso con permiso de administrador a la aplicación con el objeto de poder supervisar todo cuanto forme parte de la aplicación sin ningún tipo de limitación.

    //                             5ª.- La empresa Asesor se compromete a alojar la base de datos creada a partir de la aplicación Lex Control® con un proveedor de reconocido prestigio y previa comprobación de que se cumplen las normas de seguridad y de protección de datos. Actualmente la base de datos de la empresa Cliente estará alojada en los servidores AZURE de Microsof Corporation.
                                
    //                             6ª.- La empresa Asesor se reserva el derecho de desistir y renunciar al cumplimiento de este contrato en el caso de la empresa Cliente se negara a disponer las medidas que se consideraran imprescindibles para la implantación del sistema de prevención de delitos en la empresa, así como si incurriera deliberadamente en su incumplimiento o lo tolerara.

    //                             7ª.- Este contrato de prestación de servicios no comprende la representación y defensa de la sociedad o de cualquiera de sus administradores en procedimientos administrativos o judiciales de cualquier orden jurisdiccional.
    //                         `}
    //                     </p>
    //                 </div>
    //                 <div>
    //                     <p>
    //                         {`             

    //                             QUINTA.- SEGUIMIENTO Y SUPERVISIÓN ANUAL. Tras la implantación llevada a cabo durante los primeros doce meses de vigencia de este contrato, se producirá la renovación automática por períodos anuales, a fin de llevar a cabo las tareas de seguimiento, control y supervisión del sistema de gestión del cumplimiento y del gobierno corporativo, para lo que se establece un coste del ___% (____________ por ciento) estipulado en la cláusula tercera, es decir de //_______.-// €. (______________________ euros) anuales, más IVA.	En todo caso están incluidas las actualizaciones y nuevas versiones de la aplicación LexControl®.

    //                             Y para que así conste, se extiende el presente documento por duplicado ejemplar, que es firmado por los comparecientes en prueba de conformidad y señal de cumplimiento.
    //                         `}
    //                     </p>
    //                 </div>
    //                 </Card>
    //                 <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </div>
    // );


    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("contratoscompliance.title",{ns:"page"});
        
        const abrirModalNuevo = () => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            this.setState({
                modalNuevoAbierto: true,
                departamentosSeleccionados:[],
                soloPenales:true
            })
        }

        const añadirNuevo =async () => {
        

            //departamentos seleccionados...
            if(this.state.departamentosSeleccionados.length===0){
                return
            }

            //comprobar que el doocumento historico esta relleno.
            let numRespuestas=await this.props.client.query({
                query:gql`{documentosHistoricos{ID_ITEM}}`,fetchPolicy:'network-only'
            }).then(async(result)=>{
                return result.data.documentosHistoricos.length
            })

            if(numRespuestas!==24){
                alert(i18next.t("contratoscompliance.advertencia",{ns:"page"}))
                return
            }

            if(!this.state.aceptarPulsado){
                //mostrar modal de advertencia 1.
                this.setState({modalAdvertencia1:true})
            }else{
                //mostrar modal de advertencia 1.
                this.setState({modalAdvertencia2:true})
            }
            

            
        }

        const cerrarModal2=()=>{
            this.setState({modalAdvertencia2:false})
        }

        const aceptarContrato=()=>{
            this.setState({modalCreado:false})
            window.location.reload()
        }
        
        const crearContrato=async ()=>{
            
            //insertamos el documento en la bdd.
            await this.props.client.mutate({
                mutation:gql`mutation{crearComplianceContrato(VALORES:{SOLO_PENAL:${this.state.soloPenales},ID_USUARIO:${this.state.idUsuario},FECHA:"${moment().format("YYYY-MM-DD HH:mm:ss")}"}){ID_CONTRATO}}`
            }).then(result=>{
             
                //damos de alta los departamentos del contrato
                this.state.departamentosSeleccionados.forEach(async departamento=>{

                    await this.props.client.mutate({
                        mutation:gql`mutation{crearComplianceContratoDepartamento(VALORES:{ID_CONTRATO:${result.data.crearComplianceContrato.ID_CONTRATO},ID_DEPARTAMENTO:${departamento.value}}){ID_CONTRATO,ID_DEPARTAMENTO}}`
                    })

                })
                this.setState({modalCreado:true})
                cerrarModal()
                cerrarModal2()
                   
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
            })
        }
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirCC:false,
                modalImprimirC:false
            })
        }

        const activarAceptar=()=>{
            this.setState({
                aceptarPulsado:true,
                modalAdvertencia1:false
            })
        }

        const handleCambiarContrato=()=>{
            this.setState({soloPenales: !this.state.soloPenales})
        }

        const seleccionDepartamento  = (value) => { 
            this.setState({departamentosSeleccionados: value})
        }

        const seleccionarTodosDepartamentos = () => {
            this.setState({departamentosSeleccionados: this.state.listadoDepartamentos, seguro: true})
        }

        if(this.state.hayContrato===null)return <Cargando/>

        if(this.state.hayContrato===false){
            return (
                <>
                 {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={i18next.t("new",{ns:"global"})+' '+i18next.t("contratoscompliance.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalNuevoAbierto}
                    >
                        <div className="pixelAlrededor">
                            <Callout className="bp3-intent-danger">
                                {i18next.t("contratoscompliance.requisitos",{ns:"page"})}
                            </Callout>

                            {/* este select es igual que el de auditores, a pesar de los parámetros */}
                            <FormGroup
                            label={i18next.t("contratoscompliance.departamentos",{ns:"page"})+":"}
                            labelFor="departamentos"
                            intent="danger"
                            helperText={this.state.departamentosSeleccionados.length===0?i18next.t("selectempty",{ns:"global"})+i18next.t("contratoscompliance.departamentos",{ns:"page"}):""}
                            >
                                <div style={this.state.departamentosSeleccionados.length===0?{border:"1px solid red"}:{}}>
                                <Select
                                    isMulti
                                    isClearable
                                    isSearchable
                                    name="departamentos"
                                    value={this.state.departamentosSeleccionados}
                                    onChange={seleccionDepartamento}
                                    options={this.state.listadoDepartamentos}
                                />
                            </div>
                            </FormGroup>
                            <RadioGroup
                                label={i18next.t("contratoscompliance.optionsriesgos",{ns:"page"})}
                                name="group"
                                onChange={handleCambiarContrato}
                                selectedValue={this.state.soloPenales}
                                inline={true}
                            >
                                <Radio label={i18next.t("contratoscompliance.optionsolopenal",{ns:"page"})} value={true}/>
                                <Radio label={i18next.t("contratoscompliance.optionall",{ns:"page"})} value={false}/>
                            </RadioGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("contratoscompliance.selectalldptos",{ns:"page"})} position="top">
                                <Button onClick={seleccionarTodosDepartamentos} intent="danger" text={i18next.t("all",{ns:"global"})} icon="small-tick" />
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={añadirNuevo}
                                >
                                    {i18next.t("agregar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("contratoscompliance.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAdvertencia1}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>{i18next.t("contratoscompliance.wariningadd",{ns:"page"})}
                            <br/>
                            <br/>
                           {i18next.t("contratoscompliance.warning",{ns:"page"})}
                        </p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={activarAceptar}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                    </Dialog>
                    <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("contratoscompliance.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAdvertencia2}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>{i18next.t("contratoscompliance.questionadd",{ns:"page"})}</p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("yes",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={crearContrato}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("no",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={cerrarModal2}
                            >
                                {i18next.t("no",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                    </Dialog>
                    <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("contratoscompliance.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalCreado}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <p>{i18next.t("contratoscompliance.addcorrect",{ns:"page"})}</p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={aceptarContrato}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                    </div>
                </div>
                    </Dialog>
                
                    <div className="pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <h3>{i18next.t("contratoscompliance.contratosvacios",{ns:"page"})}</h3>
                            <Button icon="add" text={i18next.t("agregar",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} large={true} intent="danger" />
                        </Card>
                    </div>
                </>
                }
             </>    
            )
        } else {
            return (
                <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})+' '+i18next.t("contratoscompliance.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirCC}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirCC?
                            <div style={{ width: '100%', height: '500px'}} id='generarCC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})+' '+i18next.t("contratoscompliance.certificados",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirC}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirC?
                            <div style={{ width: '100%', height: '500px'}} id='generarC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                   
                  
                    <div className="tablaMitadIzquierda pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO}>
                              <Button className="bp3-intent-danger" icon="print" active={this.state.imprimirContratoActivado} onClick={()=>this.generarContrato()}>{i18next.t("print",{ns:"global"})+' '+i18next.t("contratoscompliance.title",{ns:"page"})}</Button>

                            {/* <ReactToPrint
                                trigger={() => <Button className="bp3-intent-danger" icon="print">Imprimir Contrato de compliance</Button>}
                                content={() => this.PdfContratoCompliance}
                            /> */}
                            <br />
                            <br />
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("contratoscompliance.title",{ns:"page"})}</Callout>
                            <br />
                            <Contratos editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}/>
                        </Card>
                    </div>
                    <div className="tablaMitadDerecha pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO}>
                        <Button className="bp3-intent-danger" icon="print" active={this.state.imprimirCertActivado} onClick={()=>this.generarCertificado()}>{i18next.t("contratoscompliance.buttonprintcertificado",{ns:"page"})}</Button>
                          
                            {/* <ReactToPrint
                                trigger={() => <Button className="bp3-intent-danger" icon="print">Imprimir certificado de implantación del sistema de gestión de riesgos y de gobierno corporativo de la sociedad</Button>}
                                content={() => this.PdfCertificadoCompliance}
                            /> */}
                            
                            <br />
                            <br />
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("contratoscompliance.certificados",{ns:"page"})}</Callout>
                            <br />
                            <Certificados editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}/>
                        </Card>
                    </div>
                </>
            }
            </> 
        
            )
        }
    }
}export default withTranslation()(withApollo(ContratoCompliance));