/* eslint-disable default-case */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
import React, { Component } from "react";
import { Card, Elevation, Tree, FormGroup, Drawer, Button, Dialog, Position, Classes, Tooltip, AnchorButton, Intent, Icon, Checkbox, InputGroup, TextArea, Callout,} from "@blueprintjs/core";
import { Table, Column, Cell,RegionCardinality,Regions } from "@blueprintjs/table";
import gql from "graphql-tag";
import moment from "moment";
import ContratoCompliance from "../Pantallas/ContratoCompliance";
import { DateInput } from "@blueprintjs/datetime";
import ReactToPrint from "react-to-print";
import { Cargando } from "./Cargando";
import { withApollo } from "react-apollo";
import ReactDOM from 'react-dom'
import AccionesSeguimiento from "./Elementos/AccionesSeguimiento"
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const areasPredefinidas=6

class Seguimiento extends Component {
  state = {
    /* esto lo dejo en false para ahorrar un click, según te comenté */
    contratoCompliance: true,
    cargandoRI: true,
    arbol: [],
    imperativoSeleccionadoTabla: null,
    recomendadosSeleccionadoTabla: null,
    recomendacionSeleccionado: null,
    imperativos: [],
    recomendaciones: [],
    identificacion: null,
    nuevoIdentificacionActivado: false,
    editarIdentificacionActivado: false,
    borrarIdentificacionActivado: false,

    recomendacion: null,
    nuevoRecomendacionesActivado: false,
    editarRecomendacionesActivado: false,
    borrarRecomendacionesActivado: false,

    modalIdentificacionAbierto: false,
    modalBorrarImperativo: false,

    modalRecomendacionAbierto: false,
    modalBorrarRecomendacion: false,

    riesgoSeleccionadoFalso: "Riesgo seleccionado",
    fechaFicha: new Date(),
    departamento: "Departamento Falso",
    numero: 1,
    numeroIdentificacion:1,
    numeroIdentificacionActual:1,
    codigoRiesgo: "01A01",
    nombreRiesgo: "EXCESIVO NIVEL DE ENDEUDAMIENTO",
    riesgoHaSucedidoAntes: false,
    nivelRiesgo: null,
    nivelSancion: 1,
    nivelPerdida: 2,
    nivelSuspension: 3,
    nivelReputacional: 4,
    nivelOtros: 5,
    categoriaRiesgo: 0,
    categoriaSancion: 0,
    categoriasRiesgo: ["BAJO", "MEDIO", "ALTO", "MUY ALTO"],
    intentCategoriasRiesgo: [
      "celdaFondoVerde",
      "celdaFondoAmarillo",
      "celdaFondoAmarillo",
      "celdaFondoNaranja",
      "celdaFondoRojo"
    ],
    puestosDepartamento: [],
    valoran: [],
    asignados: [],
    recursosFinancieros: 0.0,
    recursosOrganizacion: "",
    recursosExternos: "",
    periodicidad: null,
    periodicidadAbierto: false,
    radio1: "1",
    bloque: null,
    observaciones: null,
    departamentoSeleccionado: null,
    idUsuario: null,
    valoranSeleccionada: null,
    asignadoSeleccionada: null,
    existeDocumento: false,
    documentoSeleccionado: null,
    modalAdvertencia1Abierto: false,
    fechaDiferente: false,
    rutaArbol: null,
    hayContrato: null,
    soloPenales: false,
    bloquesRiesgo: [],
    modalPeriodicidad: false,
    nivelImperativas: [],
    nivelRecomendaciones: [],
    nombreInformante: "",
    DNIinformante: null,
    nombreEmpresa: "",
    puestoTrabajo: "",
    explicacionImperativos: "",
    explicacionRecomendados: "",
    explicacionSalvedades: "",
    fechaProximaValoracionControles: new Date(),
    fechaProximaValoracion: new Date(),
    certificado:false,
    dniInvalido:false,
    modalImperativos:false,
    modalRecomendaciones:false,
    impertivosSeleccionados:[],
    recomendacionesSeleccionados:[],
    nuevoImperativo:null,
    nuevaRecomendacion:null,
    modalNuevoImperativo:false,
    modalNuevaRecomendacion:false,
    cargando:false,
    regionImperativa:null,
    regionRecomendacion:null,
    numFormacion: 0,
    numInformacion: 0,
    elegirAccionFormativa: false,
    elegirAccionInformativa: false,
    accionesFormativas:[
    ],
    accionesInformativas:[
    ],
    crearNuevaHoja:false,
    docIdentifiacionActual:null,
    datosFormacion:null,
    datosInformacin:null,
    pasoGuia:0,
    steps: [
        {
            target: '#arbol-seguimiento',
            content: "En el siguiente árbol, se muestran los departamentos seleccionados en el proceso de creación del contrato del compliance, con todos los riesgos y subriesgos exisitentes, tanto los que vienen por defecto en lexcontrol, como los creados por nosotros.",
            selector: '#arbol-seguimiento',
            position: 'auto',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            stepIndex:0, 
        },
        {
          target: '#arbol-seguimiento .bp3-tree-node-content-3',
          content: "Al igual que en identificación de riesgos,para crear la hoja de seguimiento del riesgo, hacemos doble clic sobre el riesgo del departamento y bloque deseado.Sin embargo para poder realizar la hoja de seguimiento, previamente tendremos que haber creado la hoja de identificación.Suponiendo que ya tenemos creada la hoja de identificación de este riesgo, hacemos doble clic.",
          target: '#arbol-seguimiento .bp3-tree-node-content-3',
          position: 'auto',
          disableBeacon: true,
          type: 'hover',
          isFixed: true,
          stepIndex:1, 
       },
       {
        target: '#modal-seguimiento',
        content: "Se nos abrirá el siguiente modal, con la información del seguimiento, la cuál debemos cumplimentar.",
        target: '#modal-seguimiento',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:2, 
      },
      {
        target: '#numeracion-seguimiento',
        content: "Como podemos observar, las hojas de seguimiento tienen una numeración diferente a las de identifiación.Un documento de seguimiento, pertenecerá a un documento de indetificación, Por lo tanto el digito a la izquierda de la barra corresponderá con el número de documento de indentificación y el de la derecha corresponde al número de seguimiento.",
        target: '#numeracion-seguimiento',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:3, 
      },
      
      {
        target: '#btn-nueva-hoja',
        content: "Una hoja de seguimiento no es modificada cada 24 hrs como en el caso de las hojas de identificación.Cuando editemos una hoja de seguimiento, podemos crear una nueva versión ,haciendo clic en 'Nuevo documento seguimiento'.Se podrán crear tantas versiones como se desee",
        target: '#btn-nueva-hoja',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:4, 
      },
      {
        target: '#numeracion-seguimiento',
        content: "Una vez hecho clic en el botón se creará un nueva versión del documento de seguimiento, con los datos de la anterior versión.",
        target: '#numeracion-seguimiento',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:5, 
      },
      {
        target: '#arbol-seguimiento .bp3-tree-node-content-4',
        content: "Después de crear la nueva versión, podremos consultar la anterior versión desplegando el riesgo y haciendo doble clic en la anterior versión, Siendo está inmodificable.",
        selector: '#arbol-seguimiento .bp3-tree-node-content-4',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:6, 
      },
      {
        target: '#btn-formacion-informacion',
        content: "También al editar un seguimiento podremos crear la formación/información del mismo.Como se puede deducir el número que aparece en los botones, son el número de formaciones/informaciones que se han creado en la hoja del seguimiento.El siguiente proceso es el mismo tanto para las formaciones, como para las informaciones.",
        selector: '#btn-formacion-informacion',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:7, 
      },
      {
        target: '#btn-formacion',
        content: "Para crear una formación, hacemos clic en el botón y se abrirá el siguiente diálogo.",
        selector: '#btn-formacion',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:8, 
      },
      {
        target: '#nueva-formacion',
        content: "Pulsamos para crear una formación.",
        selector: '#nueva-formacion',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:9, 
      },
      {
        target: '#modal-crear-formativa',
        content: "Y finalmente rellenamos los datos solicitados de la información.",
        selector: '#modal-crear-formativa',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:10, 
      },
      {
        target: '#bp3-tab-title_tabs-acciones_riesgos-relacionados',
        content: "Al editar una acción o formación, podremos ver una nueva pestaña llamada riesgos relacionados en el cuál podremos seleccionar los mismos.",
        selector: '#bp3-tab-title_tabs-acciones_riesgos-relacionados',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:11, 
      },
      {
        target: '#bp3-tab-title_tabs-acciones_personas',
        content: "Si la formación es aplicada a un empleado, al editar la misma también podremos ver esta nueva pestaña dónde podremos agregar el comprobante de la formación.",
        selector: '#bp3-tab-title_tabs-acciones_personas',
        position: 'auto',
        disableBeacon: true,
        type: 'hover',
        isFixed: true,
        stepIndex:12, 
      },
      
    ]
  };

  tieneHijos=(riesgo,arrayRiesgos)=>{
    var arrayHijos=[];
    let codigo=riesgo.label.split("-")[0].trim()
    let index=1
    var pad = "00"

    arrayRiesgos.find((element) =>{
        if(element.ID_RIESGO_PADRE===parseInt(riesgo.id.split("_").pop())){
            arrayHijos.push({"depth":(element.childNodes?element.childNodes.length:0),id:"riesgo_"+element.ID_RIESGO,"label":codigo+pad.substring(0, pad.length - String(index).length) + index+" - "+element.TITULO,childNodes:(element.childNodes?element.childNodes:null),"descripcion":element.DESCRIPCION,"icon":"warning-sign"})
            index++
        }
        return 
    });
    if(arrayHijos.length>0){
        riesgo.icon="warning-sign"
        riesgo.childNodes=arrayHijos;
    }
    return riesgo;
  }

  cerrarPeriodicidad = () => {
    this.setState({
      modalPeriodicidad: false
    });
  };

  formatearPeriodicidad = periodicidad => {
    if (typeof periodicidad === "string")
      periodicidad = JSON.parse(periodicidad);

    let format = "";
    if (periodicidad.dia && periodicidad.dia > 0) {
      format += "El día " + periodicidad.dia + " de";
    } else if (periodicidad.diaSemana) {
      format +=
        "El " + periodicidad.ordinal + " " + periodicidad.diaSemana + " de";
    }

    if (periodicidad.numeroMes && periodicidad.numeroMes > 0) {
      format += " cada " + periodicidad.numeroMes + " meses";
    } else if (periodicidad.meseseSeleccionados.length > 0) {
      let meses = periodicidad.meseseSeleccionados
        .map(function(elem) {
          return elem.label;
        })
        .join(",");
      format += " los meses " + meses;
    }
    return format;
  };

  comprobacionDocumentos = async (riesgo, docs) => {

    //si la ayuda esta activada, no mostramos los riesgos realizados por el cliente.
    //ya que sino puede haber inconsistencias con sus datos y el ejemplo, y podria llevar a confusión.
    if(localStorage.getItem("ayuda")==="true")return

    var arrayHijos = [];
    //versiones anteriores del documento.
    if (docs) {
      let idRiesgo = riesgo.id.split("_").pop();

      let versiones = await docs.filter(documento => {
        return documento.ID_RIESGO === parseInt(idRiesgo);
      });

      //ordenar las versiones de menor a mayor.
      await versiones.sort(function(a, b) {
        return a.VALORACION.NUMERO - b.VALORACION.NUMERO
      });

      //le asignamos al riesgo el numero de documento mas actual
      if (versiones.length > 0) {
        riesgo.icon = "small-tick";
        riesgo.numero = versiones[versiones.length - 1].NUMERO;
        riesgo.numeroIdentificacion =versiones[versiones.length - 1].VALORACION.NUMERO
        riesgo.idDocIdentificacion =versiones[versiones.length - 1].VALORACION.ID_DOCUMENTO
        riesgo.fechaDoc = versiones[versiones.length - 1].FECHA;
        riesgo.deshabilitado = false;
      } else {
        riesgo.icon = "warning-sign";
      }


      //debajo del riesgo, ponemos sus versiones anteriores de documento
      let index=0
      for(let v of versiones) {

        if (index < versiones.length - 1) {

          await arrayHijos.push({
            depth: 0,
            id: "riesgo_" + v.ID_RIESGO,
            label: "Versión anterior: Número "+v.VALORACION.NUMERO+"/"+ v.NUMERO,
            numero: v.NUMERO,
            numeroIdentificacion:v.VALORACION.NUMERO,
            idDocIdentificacion:v.VALORACION.ID_DOCUMENTO,
            deshabilitado:true,
            version:true
          });
          
         
        }
        index++
      }

      if (arrayHijos.length > 0) {
        let indiceHijo=0
        if (riesgo.childNodes) {
          arrayHijos.forEach(hijo => {
            riesgo.childNodes.splice(indiceHijo, 0, hijo);
            indiceHijo++
          });
        } else {
          riesgo.childNodes = arrayHijos;
        }
      }

    }

    //si el riesgo tiene hijos, realizamos la comprobacion para cada uno de ellos
    if (riesgo.childNodes) {
      riesgo.childNodes.forEach(hijo => {
        if (!hijo.version) {
          this.comprobacionDocumentos(hijo, docs);
        }
      });
    }

    return riesgo;
  };

  obtenerNumeroDep=async(idArea,idDep)=>{

    let departamentos= await this.props.client.query({
        query:gql`{departamentos(FILTROS:{ID_AREA:${idArea },PREDEFINIDO:false}){ID_DEPARTAMENTO,DESCRIPCION,PREDEFINIDO}}`
        ,fetchPolicy:'network-only'
    }).then(async(result)=>{
        return result.data.departamentos
    })  


    let indice=await departamentos.find((dep,index)=>{
        dep.index=index
        return dep.ID_DEPARTAMENTO===idDep
    })

    let rtaindex=null
    if (indice !== undefined) 
      rtaindex=indice.index
    return rtaindex

  }
  obtenerBloques = async (idDep,departamento,idArea) => {
    //obtencion de los documentos para el departamento.
    let documentos = await this.props.client
    .query({
      query: gql`{complianceDocumentosSeguimientos(FILTROS:{ID_CONTRATO_DEPARTAMENTO:${idDep}}){ID_DOCUMENTO,ID_CONTRATO_DEPARTAMENTO,ID_RIESGO,NUMERO,FECHA,VALORACION{ID_DOCUMENTO,NUMERO}}}`,
      fetchPolicy:'network-only'
    })
    .then(result => {
      return result.data.complianceDocumentosSeguimientos;
    });

    let deps=await this.obtenerNumeroDep(idArea,departamento)
    let bloquesRiesgo = await JSON.parse(JSON.stringify(this.state.bloquesRiesgo));


    for(let elem of bloquesRiesgo){

      let longRiesgos=elem.childNodes.length
      let numTicks=0
      
      for(let riesgo of elem.childNodes) {

        riesgo.idConDep=idDep
        await this.comprobacionDocumentos(riesgo, documentos);
        riesgo.label=(String(idArea-areasPredefinidas)+String(deps+1)+riesgo.label)
        riesgo.codDep=departamento
        riesgo.idArea=idArea
        if(riesgo.icon==="small-tick"){
          numTicks++
        }

        if(riesgo.childNodes){
          let longitudHijos=riesgo.childNodes.length
          for(let hijo of riesgo.childNodes){
            if(!hijo.version){
              hijo.label=(String(idArea-areasPredefinidas)+String(deps+1)+hijo.label)
            }  
            hijo.codDep=departamento
            hijo.idConDep=idDep
            hijo.idArea=idArea
            if( hijo.version)longitudHijos--
            if(hijo.icon==="small-tick")numTicks++
          }
          longRiesgos+=longitudHijos
        }

      }

      //si hemos completado todos documentos del bloque, marcamos el bloque riesgo con un tick
      if(longRiesgos===numTicks)elem.icon="small-tick"

    }

    return bloquesRiesgo;
  };

  cargarBloquesDep = async () => {

    this.setState({cargando:true})

    //obtencion de los departamentos
    let departamentos = await this.props.client
      .query({
        query: gql`
          {
            complianceContratosDepartamentos{
              ID_CONTRATO_DEPARTAMENTO
              DEPARTAMENTO {
                ID_DEPARTAMENTO,
                ID_AREA,
                AREA{
                  ID_AREA,
                  DESCRIPCION,
                  PREDEFINIDA
                }
                DESCRIPCION
              }
            }
          }
        `,fetchPolicy:'network-only'
    })
      .then(result => {
        return result.data.complianceContratosDepartamentos;
    });

    departamentos=await departamentos.sort(function(a, b) { 
        return a.DEPARTAMENTO.ID_DEPARTAMENTO- b.DEPARTAMENTO.ID_DEPARTAMENTO;
    })

    let i=0
    let index=0
    let areas=[]

    for(let departamento of departamentos) {

        let bloques = await this.obtenerBloques(departamento.ID_CONTRATO_DEPARTAMENTO,departamento.DEPARTAMENTO.ID_DEPARTAMENTO,departamento.DEPARTAMENTO.ID_AREA);
        departamentos[index].depth = bloques.length;
        departamentos[index].id = departamento.DEPARTAMENTO.ID_DEPARTAMENTO;
        departamentos[index].idDepCon = departamento.ID_CONTRATO_DEPARTAMENTO;
        departamentos[index].label ="Departamento: " + departamento.DEPARTAMENTO.DESCRIPCION;
        departamentos[index].icon = "warning-sign";
        departamentos[index].childNodes = bloques;
        i++

        let existe=areas.find(area=>{return area.ID_AREA===departamento.DEPARTAMENTO.ID_AREA})

        if(existe){
            if(!existe.childNodes)existe.childNodes=[]
            existe.childNodes.push(departamentos[index])
        }else{

            areas.push({
                ID_AREA:departamento.DEPARTAMENTO.ID_AREA,
                icon:"warning-sign",
                label:"Area: "+departamento.DEPARTAMENTO.AREA.DESCRIPCION,
                childNodes:[departamentos[index]]
            })
        }

        if (i === departamentos.length){
            this.setState({ arbol: areas });         
        }
        index++
    }

    this.setState({cargando:false})

  };

  cargaInicial = async () => {

    //comprobamos si hay documento compliance
    await this.props.client
      .query({
        query: gql`
          {
            complianceContratos {
              ID_CONTRATO
              SOLO_PENAL
            }
          }
        `,
        fetchPolicy:'network-only'
      })
      .then(async result => {
        
          this.setState({ hayContrato: true });
          if (
            result.data.complianceContratos &&
            result.data.complianceContratos.length > 0
          ) {
          this.setState({
            soloPenales: result.data.complianceContratos[0].SOLO_PENAL
          });
          }
          //SI TENEMOS CONTRATO, CARGAMOS LOS DATOS DE LA PANTALLA
          let asignados = [];
          //carga desplegable valoran.
          await this.props.client
            .query({
              query: gql`
                {
                  personasFisicas {
                    ID_PERSONA
                    NOMBRE
                  }
                }
              `,
              fetchPolicy:'network-only'
            })
            .then(result => {
              let personas = [];
              result.data.personasFisicas.forEach(persona => {
                personas.push({
                  label: persona.NOMBRE,
                  value: persona.ID_PERSONA
                });
                asignados.push({
                  label: persona.NOMBRE,
                  value: persona.ID_PERSONA
                });
              });

              this.setState({ asignados: asignados });
              this.setState({ valoran: personas });
            });

          await this.props.client
            .query({
              query: gql`
                {
                  personasJuridicas {
                    ID_PERSONA
                    RAZON_SOCIAL
                  }
                }
              `,
              fetchPolicy:'network-only'
            })
            .then(result => {
              result.data.personasJuridicas.forEach(persona => {
                asignados.push({
                  label: persona.RAZON_SOCIAL,
                  value: persona.ID_PERSONA
                });
              });
              this.setState({ asignados: asignados });
            });

          //obtener el id del usuario actual logueado en la aplicacion.
          await this.props.client
            .query({
              query: gql`
                {
                  obtenerUsuarioActual 
                }
              `,
              fetchPolicy:'network-only'
            })
            .then(result => {
              this.setState({
                idUsuario: result.data.obtenerUsuarioActual
              });
            });

          //obtencion de los bloques de riesgo
          let bloquesRiesgo = await this.props.client
            .query({
              query: gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"${
                this.state.soloPenales ? ",FILTROS:{ID_BLOQUE:4}" : ""
              }){ID_BLOQUE,LETRA,TITULO}}`,
              fetchPolicy:'network-only'
            })
            .then(result => {
              return result.data.bloquesRiesgo;
            });

            var pad = "00"
            let index=0
            for(let bloque of bloquesRiesgo){

              //obtencion de los riesgos de cada bloque.
            let hijos = [];
            let indiceRiesgo=1
            await this.props.client
              .query({
                query: gql`{complianceRiesgos(FILTROS:{ID_BLOQUE:${
                  bloque.ID_BLOQUE
                }}){ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE}}`,
                fetchPolicy:'network-only'
              })
              // eslint-disable-next-line no-loop-func
              .then(async result => {


                for(let hijo of result.data.complianceRiesgos){
                  if (hijo.ID_RIESGO_PADRE === null) {

                    let nuevoHijo={
                      depth: hijo.childNodes ? hijo.childNodes.length : 0,
                      id: "riesgo_" + hijo.ID_RIESGO,
                      descripcion:hijo.DESCRIPCION,
                      label: bloque.LETRA+pad.substring(0, pad.length - String(indiceRiesgo).length) + indiceRiesgo+" - "+hijo.TITULO,
                      icon: "warning-sign",
                      childNodes: hijo.childNodes ? hijo.childNodes : null
                    }
                    await hijos.push(nuevoHijo);
                    await this.tieneHijos(nuevoHijo,result.data.complianceRiesgos)
                    indiceRiesgo++
                  }
                }
                
                bloquesRiesgo[index].depth = hijos.length;
                bloquesRiesgo[index].id = "bloque_" + bloque.ID_BLOQUE;
                bloquesRiesgo[index].label =
                  "Bloque " + bloque.LETRA + " - " + bloque.TITULO;
                bloquesRiesgo[index].icon = "warning-sign";
                bloquesRiesgo[index].childNodes = hijos;
              });

              index++
            }
                      

          await this.setState({ bloquesRiesgo: bloquesRiesgo },async () => {
            await this.cargarBloquesDep();
            this.setState({cargandoRI:false})
          });

        //} else {
        //  this.setState({ hayContrato: false });
        //}


      });

  }

  setPeriodicidad = valor => {
    this.setState({ periodicidad: valor });
  };

    componentDidMount(props) {
      this.setState({cargandoRI:true}, async() =>{
        this.cargaInicial();
      })
    }

    buscarRiesgo=(arbol,idArea,idDepartamento,idRiesgo)=>{

      var nodePath=[];
      arbol.forEach((area,indexArea) =>{

          //si es el area que buscamos.
          if(idArea===area.ID_AREA){

              nodePath.push(indexArea)

              area.childNodes.forEach((departamento,indexDep)=>{

                  //si es el departamento que buscamos
                  if(departamento.DEPARTAMENTO.ID_DEPARTAMENTO===idDepartamento){

                    nodePath.push(indexDep)

                    //recorremos los bloqeues en busca del riesgo o subriesgo.
                    departamento.childNodes.forEach((bloque,indexBloque)=>{

                        bloque.childNodes.forEach((riesgo,indexRiesgo)=>{

                          if(parseInt(riesgo.id.split("_").pop())===idRiesgo && riesgo.id.includes("riesgo_")){

                              //si el riesgo es el buscado, pasamo la ruta del bloque y del riesgo
                              nodePath.push(indexBloque)
                              nodePath.push(indexRiesgo)
                              this.setState({riesgosModalSeleccionado:riesgo})
                              this.setState({riesgosModalSeleccionadoPath:nodePath})
                              return 

                          }else{

                              if(riesgo.childNodes){

                                  riesgo.childNodes.forEach((subriesgo,indexSub)=>{
                                    
                                    if(parseInt(subriesgo.id.split("_").pop())===idRiesgo && subriesgo.id.includes("riesgo_")){

                                        nodePath.push(indexBloque)
                                        nodePath.push(indexRiesgo)
                                        nodePath.push(indexSub)
                                        this.setState({riesgosModalSeleccionado:subriesgo})
                                        this.setState({riesgosModalSeleccionadoPath:nodePath})

                                    }

                                  })

                              }

                          }

                        })

                    })

                  }
              })

          }
      });
    }

    componentDidUpdate=async(props)=>{

      if(this.props!==props){
        if(this.props.abierto){
          await this.buscarRiesgo(this.state.arbol,this.props.area,this.props.departamento,this.props.riesgo)
          await this.handleNodeClick(this.state.riesgosModalSeleccionado,this.state.riesgosModalSeleccionadoPath)
        }
      }

    }

    async cargarRecomendaciones(idRiesgo){

        await this.props.client.query({
            query:gql`{complianceRecomendaciones(FILTROS:{ID_RIESGO:${idRiesgo}}){ID_RECOMENDACION,DESCRIPCION,PREDEFINIDA,ID_RIESGO}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{

          let recomendaciones=[]
            result.data.complianceRecomendaciones.forEach((recomendacion)=>{
                recomendaciones.push({"value":recomendacion.ID_RECOMENDACION,"label":recomendacion.DESCRIPCION,"predefinida":recomendacion.PREDEFINIDA,"idRiesgo":recomendacion.ID_RIESGO})
            })

            this.setState({recomendaciones:recomendaciones}) 
        })

    }

    async cargarImperativos(idRiesgo){

        await this.props.client.query({
            query:gql`{complianceImperativas(FILTROS:{ID_RIESGO:${idRiesgo}}){ID_IMPERATIVA,DESCRIPCION,PREDEFINIDA,ID_RIESGO}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{

          let imperativos=[]
           for(let imperativo of  result.data.complianceImperativas){
                imperativos.push({"value":imperativo.ID_IMPERATIVA,"label":imperativo.DESCRIPCION,"predefinida":imperativo.PREDEFINIDA,"idRiesgo":imperativo.ID_RIESGO})
            }
            this.setState({imperativos:imperativos}) 
        })

    }


    cargarFormaciones=async(idDocumento)=>{

      //obtencion de las formaciones
      await this.props.client
      .query({
        query: gql` 
                {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"F"}){
                  ID_ACCION
                  ID_AREA,
                  AREA{DESCRIPCION}
                  ID_DEPARTAMENTO,
                  DEPARTAMENTO{DESCRIPCION}
                  ID_PUESTO_TRABAJO,
                  PUESTO_TRABAJO{DESCRIPCION}
                  ID_EMPLEADO,
                  F_TITULO,
                  F_NUMERO_HORAS,
                  F_MODALIDAD,
                  F_INICIO,
                  F_FIN,
                  F_HORARIO,
                  F_PROCEDIMIENTO_EVALUACION,
                  F_REQUISITOS_ACCESO,
                  F_COMISION_ACADEMICA,
                  F_PROFESORADO,
                  F_COMPETENCIAS,
                  F_OBJETIVOS,
                  F_CONTENIDOS,
                  LUGAR,
                  OBSERVACIONES
                }}
            `,
            fetchPolicy:'network-only'
      })
      .then(async result => {

        for(let formacion of result.data.formacionesInformacion){

          //obtenemos los riesgos de la formacion
          let riesgos=await this.props.client
          .query({
              query: gql` 
              {formacionesInformacionRiesgo(FILTROS:{ID_ACCION:${formacion.ID_ACCION}}){
                  RIESGO{ID_RIESGO,DESCRIPCION}
              }}`,fetchPolicy:'network-only'
              
          }).then(async result=>{ 
            return result.data.formacionesInformacionRiesgo
          })

          formacion.riesgos=riesgos

          //obtenemos el comprobante
          if(formacion.ID_EMPLEADO){

            let comprobante=await this.props.client
            .query({
                query: gql`
                {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${formacion.ID_EMPLEADO},ID_ACCION:${formacion.ID_ACCION}}){ID_ACCION_PERSONA,ID_ACCION,ID_PERSONA,FECHA_CONFIRMACION,APROVECHAMIENTO}}
                `
                ,fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.formacionInformacionPersonas[0]
            })


            if(comprobante){

              formacion.comprobante=comprobante

               //obtencion del documento del comprobante.
                await this.props.client
                .query({
                    query: gql`
                    {
                        adjuntos(CAMPOS:"ID_ADJUNTO,DATOS",FILTROS:{ID_TIPO_ADJUNTO:16,ID_ASOCIADO:${comprobante.ID_ACCION_PERSONA}}){ID_ADJUNTO,DATOS}
                    }
                    `,fetchPolicy:'network-only'
                }).then(result=>{
                  if(result.data.adjuntos.length>0)formacion.documentoComprobante=result.data.adjuntos[0]
                })
            }
           

          }

          
        }


       
        await this.setState({
          numFormacion:result.data.formacionesInformacion.length,
          accionesFormativas:result.data.formacionesInformacion
        }) 
        
      });

    }

    cargarInformacion=async(idDocumento)=>{

      await this.props.client
        .query({
          query: gql` 
                  {formacionesInformacion(FILTROS:{ID_DOCUMENTO:${idDocumento},TIPO_ACCION:"I"}){
                    ID_ACCION
                    ID_AREA,
                    AREA{DESCRIPCION}
                    ID_DEPARTAMENTO,
                    DEPARTAMENTO{DESCRIPCION}
                    ID_PUESTO_TRABAJO,
                    PUESTO_TRABAJO{DESCRIPCION}
                    ID_EMPLEADO,
                    I_DESCRIPCION,
                    I_TEXTO,
                    I_CANAL,
                    I_FECHA,
                    I_DURACION,
                    LUGAR,
                    OBSERVACIONES
                  }}
              `,
              fetchPolicy:'network-only'
        })
        .then(async result => {

          //obtenemos los riesgos de la formacion
          for(let informacion of result.data.formacionesInformacion){
            let riesgos=await this.props.client
            .query({
                query: gql` 
                {formacionesInformacionRiesgo(FILTROS:{ID_ACCION:${informacion.ID_ACCION}}){
                    RIESGO{ID_RIESGO,DESCRIPCION}
                }}`,fetchPolicy:'network-only'
                
            }).then(async result=>{ 
              return result.data.formacionesInformacionRiesgo
            })
            informacion.riesgos=riesgos

            //obtenemos el comprobante
            if(informacion.ID_EMPLEADO){

              let comprobante=await this.props.client
              .query({
                  query: gql`
                  {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${informacion.ID_EMPLEADO},ID_ACCION:${informacion.ID_ACCION}}){ID_ACCION_PERSONA,ID_ACCION,ID_PERSONA,FECHA_CONFIRMACION,APROVECHAMIENTO}}
                  `
                  ,fetchPolicy:'network-only'
              }).then(result=>{ 
                  return result.data.formacionInformacionPersonas[0]
              })


              if(comprobante){

                  informacion.comprobante=comprobante

                  //obtencion del documento del comprobante.
                  await this.props.client
                  .query({
                      query: gql`
                      {
                          adjuntos(CAMPOS:"ID_ADJUNTO,DATOS",FILTROS:{ID_TIPO_ADJUNTO:15,ID_ASOCIADO:${comprobante.ID_ACCION_PERSONA}}){ID_ADJUNTO,DATOS}
                      }
                      `,fetchPolicy:'network-only'
                  }).then(result=>{ 
                    if(result.data.adjuntos.length>0)informacion.documentoComprobante=result.data.adjuntos[0]
                  })
              }
             

            }

          }

          await this.setState({
            numInformacion:result.data.formacionesInformacion.length,
            accionesInformativas:result.data.formacionesInformacion
          })
          
        });

    }

    
    handleNodeClick = async (nodeData, _nodePath, e ) => {


      console.log(this.state.riesgosModalSeleccionadoPath)
      //  PACO: comento esto para probar el dialog que hay que abrir aqui, luego le pasas la id y tal, de momento, lo saco "en blanco"
      //si es un riesgo...
      this.setState({
        departamentoSeleccionado: this.state.arbol[_nodePath[0]].childNodes[_nodePath[1]]
      });

      
      this.setState({ rutaArbol: _nodePath });
      if (typeof nodeData.id === "string" && nodeData.id.includes("riesgo_")) {
        
        //si el riesgo no tiene la identifiacion creada, mostramos un alerta que debe crearlo.
        //buscamos si tiene documento.
        let existeIdentificacion=await this.props.client
        .query({
            query: gql` 
            {complianceDocumentosValoraciones(LIMIT:1,CAMPOS:"ID_DOCUMENTO,NUMERO",FILTROS:{ID_RIESGO:${nodeData.id.split("_").pop()},ID_CONTRATO_DEPARTAMENTO:${this.state.arbol[_nodePath[0]].childNodes[_nodePath[1]].idDepCon}},ORDEN:"NUMERO,DESC"){
                ID_DOCUMENTO,
                NUMERO
            }}`,fetchPolicy:'network-only'
            
        }).then(async result=>{ 

          if(result.data.complianceDocumentosValoraciones.length>0){
            this.setState({numeroIdentificacionActual:result.data.complianceDocumentosValoraciones[0].NUMERO,docIdentifiacionActual:result.data.complianceDocumentosValoraciones[0].ID_DOCUMENTO})
            return true
          }
          return false

        })

        if(!existeIdentificacion){
          alert("Para poder crear la hoja de seguimiento, debe crear primero la hoja de identifiación desde Compliance->Indentificación y análisis.")
          return
        }

        if (this.state.riesgoSeleccionado) {
            this.state.riesgoSeleccionado.isSelected=false
        }
        nodeData.isSelected = true;


        let datDep = this.state.arbol[_nodePath[0]].childNodes[_nodePath[1]].label.split(":");
        let datBloque = this.state.arbol[_nodePath[0]].childNodes[
          _nodePath[1]
        ].childNodes[_nodePath[2]].label.split(" - ");
        let datRiesgos=[]
        let path=JSON.parse(JSON.stringify(_nodePath))

        if(nodeData.version)path.splice(path.length-1,1)

        if(path.length===4){
            datRiesgos= this.state.arbol[_nodePath[0]].childNodes[
              _nodePath[1]
            ].childNodes[_nodePath[2]].childNodes[_nodePath[3]].label.split(" - ");
        }else if(path.length===5){
            datRiesgos= this.state.arbol[_nodePath[0]].childNodes[
              _nodePath[1]
            ].childNodes[_nodePath[2]].childNodes[_nodePath[3]].childNodes[_nodePath[4]].label.split(" - ");
        }
      
        if(nodeData.version)nodeData.labelPadre=datRiesgos[0]
         
        this.setState({ departamento: datDep[1] });
        this.setState({ bloque: datBloque[1] });
        this.setState({ codigoRiesgo: datRiesgos[0] });
        this.setState({ nombreRiesgo: datRiesgos[1] });
        this.setState({ riesgoSeleccionado: nodeData });

        //cargamos los select de impertaivas y recomendaciones
        this.cargarImperativos(nodeData.id.split("_").pop())
        this.cargarRecomendaciones(nodeData.id.split("_").pop())
        
        //limpiamos los campos.
        this.setState({
            documentoSeleccionado: null,
            fechaFicha:new Date(),
            numero: 1,
            numeroIdentificacion:1,
            nombreInformante:null,
            DNIinformante:null,
            nombreEmpresa:null,
            puestoTrabajo:null,
            explicacionImperativos:null,
            explicacionRecomendados:null,
            explicacionSalvedades:null,
            observaciones: null,
            certificado:false,
            existeDocumento: false,
            fechaDiferente: false,
            imperativoSeleccionadoTabla: null,
            recomendacionSeleccionadoSelect: null,
            fechaProximaValoracion:new Date(),
            fechaProximaValoracionControles:new Date(),
            recomendacionesSeleccionados:[],
            impertivosSeleccionados:[],
            crearNuevaHoja:false,
            numFormacion:0,
            numInformacion:0
        });

        // si el documento tiene datos.
        if (nodeData.numero) {

          await this.props.client
            .query({
              query: gql` 
                {complianceDocumentosSeguimientos(FILTROS:{ID_CONTRATO_DEPARTAMENTO:${
                    this.state.arbol[_nodePath[0]].childNodes[_nodePath[1]].idDepCon
                },ID_RIESGO:${nodeData.id.split("_").pop()},NUMERO:${
                     nodeData.numero
                },ID_DOCUMENTO_VALORACION:${nodeData.idDocIdentificacion}}
                ){
                    ID_DOCUMENTO,
                    ID_CONTRATO_DEPARTAMENTO,
                    CONTRATO_DEPARTAMENTO{DEPARTAMENTO{ID_DEPARTAMENTO,DESCRIPCION}}
                    ID_RIESGO,
                    RIESGO{ID_RIESGO,TITULO,CODIGO_RIESGO}
                    FECHA,
                    NUMERO,
                    VALORACION{ID_DOCUMENTO,NUMERO},
                    NOMBRE_APELLIDOS,
                    DOCUMENTO_IDENTIDAD,
                    EMPRESA,
                    PUESTO,
                    EXPLICACION_IMPERATIVOS,
                    EXPLICACION_RECOMENDADOS,
                    EXPLICACION_SALVEDADES,
                    FECHA_PROPUESTA_PROXIMA,
                    OBSERVACIONES,
                    FECHA_PROXIMA,
                    ID_USUARIO,
                    COMPLIANCE_CERTIFICA
                }}`,
                fetchPolicy:'network-only'
            })
            .then(async result => {
              result.data.complianceDocumentosSeguimientos.forEach(
                async documento => {
                  //si es una version anterior mostramos el modal de advertencia,
                  //de lo contrario simplemente abrimos el documento.
                  if (nodeData.deshabilitado) {
                    this.setState({ modalAdvertencia1Abierto: true });
                  } else {

                    //si tenemos el numero identifiacion actual mayor al seguimiento actual... 
                    if(nodeData.numeroIdentificacion!==this.state.numeroIdentificacionActual && !nodeData.version){
                      await this.setState({
                        numero:1,
                      })
                      await this.setState({modalAdvertencia2Abierto:true})
                    }else{
                      this.setState({ modalIdentificacionAbierto: true });
                    }
                  }


                  //obtencion de las imperativas.
                  let imperativas = await this.props.client
                  .query({
                    query: gql` 
                            {complianceSeguimientoImperativas(FILTROS:{ID_DOCUMENTO:${documento.ID_DOCUMENTO}}){
                              ID_DOCUMENTO_IMPERATIVA,
                              ID_DOCUMENTO,
                              ID_IMPERATIVA,
                              IMPERATIVA{DESCRIPCION},
                              VALOR
                            }}
                        `,
                        fetchPolicy:'network-only'
                  })
                  .then(result => {
                    return result.data.complianceSeguimientoImperativas;
                  });
                  
                  imperativas.forEach((imperativa,index)=>{
                    imperativas[index].value=imperativa.ID_IMPERATIVA
                    imperativas[index].nivel=imperativa.VALOR
                    imperativas[index].label=imperativa.IMPERATIVA.DESCRIPCION
                  })

                  //obtencion de las recomendaciones.
                  let recomendaciones = await this.props.client
                  .query({
                    query: gql` 
                            {SeguimientoRecomendaciones(FILTROS:{ID_DOCUMENTO:${documento.ID_DOCUMENTO}}){
                              ID_DOCUMENTO_RECOMENDACION,
                              ID_DOCUMENTO,
                              ID_RECOMENDACION,
                              RECOMENDACION{DESCRIPCION},
                              VALOR
                            }}
                        `,
                        fetchPolicy:'network-only'
                  })
                  .then(result => {
                    return result.data.SeguimientoRecomendaciones;
                  });
                  
                  recomendaciones.forEach((recomendacion,index)=>{
                    recomendaciones[index].value=recomendacion.ID_RECOMENDACION
                    recomendaciones[index].nivel=recomendacion.VALOR
                    recomendaciones[index].label=recomendacion.RECOMENDACION.DESCRIPCION
                  })


                  
                  //Cargar formaciones
                  this.cargarFormaciones(documento.ID_DOCUMENTO)

                  //Cargar Informaciones
                  this.cargarInformacion(documento.ID_DOCUMENTO)
  
                  this.setState({
                    documentoSeleccionado: documento.ID_DOCUMENTO,
                    fechaFicha: new Date(documento.FECHA),
                    departamento:documento.CONTRATO_DEPARTAMENTO.DEPARTAMENTO.DESCRIPCION,
                    numero:documento.NUMERO,
                    numeroIdentificacion:documento.VALORACION.NUMERO,
                    codigoRiesgo: documento.RIESGO.CODIGO_RIESGO,
                    nombreRiesgo: documento.RIESGO.TITULO,
                    nombreInformante:documento.NOMBRE_APELLIDOS,
                    DNIinformante:documento.DOCUMENTO_IDENTIDAD,
                    nombreEmpresa:documento.EMPRESA,
                    puestoTrabajo:documento.PUESTO,
                    explicacionImperativos:documento.EXPLICACION_IMPERATIVOS,
                    explicacionRecomendados:documento.EXPLICACION_RECOMENDADOS,
                    explicacionSalvedades:documento.EXPLICACION_SALVEDADES,
                    fechaProximaValoracionControles:new Date(documento.FECHA_PROPUESTA_PROXIMA),
                    fechaProximaValoracion:new Date(documento.FECHA_PROXIMA),
                    certificado:documento.COMPLIANCE_CERTIFICA,
                    observaciones:documento.OBSERVACIONES,
                    impertivosSeleccionados:imperativas,
                    recomendacionesSeleccionados:recomendaciones,
                    existeDocumento:true
                  });
                }
              );
            });
            
        } else {
          //si no tiene datos simplemente abrimos el modal.
          this.setState({ modalIdentificacionAbierto: true });
        }
      }
    };


  render() {

    const {steps} = this.state;

    document.title ="LexControl | Seguimiento  "

    const jsDateFormatter = {
      // note that the native implementation of Date functions differs between browsers
      formatDate: date => moment(date).format("DD/MM/YYYY"),
      parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
      placeholder: "DD/MM/YYYY"
    };

    
    const handleNodeCollapse = (nodeData) => {
      nodeData.isExpanded = false;
      this.setState(this.state);
    };

    const handleNodeExpand = (nodeData) => {
      nodeData.isExpanded = true;
      this.setState(this.state);
    };

    const cerrarModal = () => {

      this.setState({
        modalIdentificacionAbierto: false,
        borrarIdentificacionActivado: false,
        modalPeriodicidad: false,
        elegirAccionFormativa: false,
        crearAccionFormativa: false,
        elegirAccionInformativa: false,
        crearAccionInformativa: false,
      });

      if(this.props.modal){
        this.props.setModalAbierto(false)
      }

    };

    const abrirFormacion = () => {
      this.setState({
        elegirAccionFormativa: true,	
        formacionSeleccionada:false
      });
	  
      if(this.props.modal){
        this.props.setModalAbierto(false)
      }
	};
	
    const abrirInformacion = () => {
      this.setState({
        elegirAccionInformativa: true,	
        informacionSeleccionada:false
      });
	  
      if(this.props.modal){
        this.props.setModalAbierto(false)
      }
    };
    const cerrarModal2 = () => {
      this.setState({
        modalAdvertencia1Abierto: false,
        modalAdvertencia2Abierto: false,
        modalIdentificacionAbierto: true,
        modalImperativos:false,
        modalRecomendaciones:false
      });
    };
    const cerrarModal3 = () => {
      this.setState({
        modalAdvertencia2Abierto: false,
        modalBorrarImperativo:false,
        modalNuevoImperativo:false,
        modalNuevaRecomendacion:false
      });
    };

    const guardarCambios = async () => {

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert("No tienes permisos para realizar esta acción")
            return
        }

        //Validaciones

        if(!this.state.nombreInformante){
            ReactDOM.findDOMNode(this.nombreInformanteInput).children[0].focus()
            return
        }

        if(!this.state.DNIinformante){
            ReactDOM.findDOMNode(this.DNIinformanteInput).children[0].focus()
            return
        }

        if(!this.state.nombreEmpresa){
            ReactDOM.findDOMNode(this.nombreEmpresaInput).children[0].focus()
            return
        }

        if(!this.state.puestoTrabajo){
          ReactDOM.findDOMNode(this.puestoTrabajoInput).children[0].focus()
            return
        }

        if(!this.state.explicacionImperativos){
          
            ReactDOM.findDOMNode(this.explicacionImperativosInput).focus()
            return
        }

        if(!this.state.explicacionRecomendados){
            ReactDOM.findDOMNode(this.explicacionRecomendadosInput).focus()
            return
        }

        if(!this.state.explicacionSalvedades){
            ReactDOM.findDOMNode(this.explicacionSalvedadesInput).focus()
            return
        }

        if(!this.state.observaciones){
            ReactDOM.findDOMNode(this.observacionesInput).focus()
            return
        }

        //if(!validarDni(this.state.DNIinformante)){
        //    this.setState({dniInvalido:true})
        //    ReactDOM.findDOMNode(this.DNIinformanteInput).children[0].focus()
        //    return
        //}else{
            this.setState({dniInvalido:false})
        //}


        if (this.state.impertivosSeleccionados.length===0 && this.state.recomendacionesSeleccionados.length===0){
            alert("Debe seleccionar un control imperativo o recomendacion");
            return;
        }
    
        //si el documento de seguimiento para este riesgo no existe o creamos una nueva hoja.
        if(!this.state.existeDocumento || this.state.crearNuevaHoja){

            //mutacion para crear el documento
            await this.props.client
                .mutate({
                mutation: gql`
                  mutation{crearDocumentoSeguimiento(VALORES:{
                      ID_CONTRATO_DEPARTAMENTO:${
                          this.state.departamentoSeleccionado.idDepCon
                      },
                      ID_RIESGO:${this.state.riesgoSeleccionado.id
                          .split("_")
                          .pop()},
                      ID_DOCUMENTO_VALORACION:${this.state.docIdentifiacionActual},
                      FECHA:"${moment().format("YYYY-MM-DD")}",
                      NUMERO:${this.state.numero},
                      NOMBRE_APELLIDOS:"${this.state.nombreInformante}"
                      DOCUMENTO_IDENTIDAD:"${this.state.DNIinformante}"
                      EMPRESA:"${this.state.nombreEmpresa}"
                      PUESTO:"${this.state.puestoTrabajo}"
                      EXPLICACION_IMPERATIVOS:"""${
                          this.state.explicacionImperativos
                      }"""
                      EXPLICACION_RECOMENDADOS:"""${
                          this.state.explicacionRecomendados
                      }"""
                      EXPLICACION_SALVEDADES:"""${
                          this.state.explicacionSalvedades
                      }"""
                      FECHA_PROPUESTA_PROXIMA:"${
                          moment(this.state.fechaProximaValoracionControles).format("YYYY-MM-DD")
                      }"
                      OBSERVACIONES:"""${this.state.observaciones}"""
                      FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                      ID_USUARIO:${this.state.idUsuario}
                      COMPLIANCE_CERTIFICA:${this.state.certificado}
                  }){ID_DOCUMENTO}}`
                })
                .then(async result => {
                             
                  let idDocumento =  result.data.crearDocumentoSeguimiento.ID_DOCUMENTO;

                  //insercion de los controles imperativos/recomendaciones
                  let error=false

                  //agregamos los imperativos                 
                  this.state.impertivosSeleccionados.forEach(async(imperativo,index)=>{
                    await this.props.client.mutate({
                      mutation:gql`mutation{crearSeguimientoImperativa(VALORES:{ID_DOCUMENTO:${idDocumento},ID_IMPERATIVA:${imperativo.value},VALOR:${imperativo.nivel}}){ID_DOCUMENTO_IMPERATIVA}}`
                    }).catch(err=>{
                      error=true
                      alert("Ha ocurrido un error al crear la imperativa.Pongase en contacto con el soporte tecnico")
                      return
                    })
                  })

                  if(error)return

                  //agregamos las recomendaciones                 
                  this.state.recomendacionesSeleccionados.forEach(async(recomendacion,index)=>{
                    await this.props.client.mutate({
                      mutation:gql`mutation{crearSeguimientoRecomendacion(VALORES:{ID_DOCUMENTO:${idDocumento},ID_RECOMENDACION:${recomendacion.value},VALOR:${recomendacion.nivel}}){ID_DOCUMENTO_RECOMENDACION}}`
                    }).catch(err=>{
                      error=true
                      alert("Ha ocurrido un error al crear la recomendacion.Pongase en contacto con el soporte tecnico")
                      return
                    })
                
                  })
                  
                  if(error)return
                  
                  alert("Insertado correctamente")

                  if(this.state.crearNuevaHoja){

                      //esta se hace de esta manera porque sino se alteraba el original directamente
                      let copyArbol = JSON.parse(JSON.stringify(this.state.arbol));

                      let doc=(this.state.rutaArbol.length===4?
                        copyArbol[this.state.rutaArbol[0]]
                        .childNodes[this.state.rutaArbol[1]]
                        .childNodes[this.state.rutaArbol[2]]
                        .childNodes[this.state.rutaArbol[3]]
                        :
                        copyArbol[this.state.rutaArbol[0]]
                        .childNodes[this.state.rutaArbol[1]]
                        .childNodes[this.state.rutaArbol[2]]
                        .childNodes[this.state.rutaArbol[3]]
                        .childNodes[this.state.rutaArbol[4]]
                      )


                      //esta se hace de esta manera porque sino se alteraba el original directamente
                      let docViejo=JSON.parse(JSON.stringify(doc));
                      let numero=(docViejo.numeroIdentificacion!==this.state.numeroIdentificacionActual?1:doc.numero+1)
                      docViejo.deshabilitado=true
              
                      doc.numero=numero
                      doc.numeroIdentificacion=this.state.numeroIdentificacionActual
                      doc.idDocIdentificacion=this.state.docIdentifiacionActual
                      doc.fechaDoc=new Date()
                      docViejo.label="Versión anterior: Número "+docViejo.numeroIdentificacion+"/"+docViejo.numero
                      docViejo.childNodes=null
                      docViejo.icon=null
                      docViejo.version=true

                      if(!doc.childNodes)doc.childNodes=[]
                      //doc.childNodes.push(docViejo)

                      let numDocsViejos=doc.childNodes.filter(child=>{
                          return child.version===true
                      })

                      doc.childNodes.splice(numDocsViejos.length, 0, docViejo)
                      //.splice(index, 0, item)

                      doc.isSelected=false
                      doc.isExpanded=true

                      docViejo.isSelected=true
                      this.setState({riesgoSeleccionado:docViejo})

                      this.setState({arbol:copyArbol})
                  }else{     
                    
                      //si es un nuevo documento solo cambiamos el icono.
                      let copyArbol = JSON.parse(JSON.stringify(this.state.arbol));

                      let doc=(this.state.rutaArbol.length===4?
                        copyArbol[this.state.rutaArbol[0]]
                        .childNodes[this.state.rutaArbol[1]]
                        .childNodes[this.state.rutaArbol[2]]
                        .childNodes[this.state.rutaArbol[3]]
                        :
                        copyArbol[this.state.rutaArbol[0]]
                        .childNodes[this.state.rutaArbol[1]]
                        .childNodes[this.state.rutaArbol[2]]
                        .childNodes[this.state.rutaArbol[3]]
                        .childNodes[this.state.rutaArbol[4]]
                      )


                      doc.icon="small-tick"
                      doc.numero=1
                      doc.numeroIdentificacion=this.state.numeroIdentificacionActual
                      doc.idDocIdentificacion=this.state.docIdentifiacionActual

                      this.setState({
                        riesgoSeleccionado:doc,
                        arbol:copyArbol
                      })
                    
                      //obtencion del departamento que estamo modificando
                      this.state.arbol.forEach(area=>{

                        area.childNodes.find(departamento=>{
    
                            if(departamento.ID_CONTRATO_DEPARTAMENTO===this.state.departamentoSeleccionado.idDepCon){
                              departamento.childNodes.forEach(elem => {
        
                                let longRiesgos=elem.childNodes.length
                                let numTicks=0
        
                                for(let riesgo of elem.childNodes) {
                                                
                                  if(riesgo.icon==="small-tick"){
                                    numTicks++
                                  }
                          
                                  if(riesgo.childNodes){
                                    let longitudHijos=riesgo.childNodes.length
                                    for(let hijo of riesgo.childNodes){
                                      if(hijo.deshabilitado)longitudHijos--
                                      if(hijo.icon==="small-tick")numTicks++
                                    }
                                    longRiesgos+=longitudHijos
                                  }
                          
                                }
        
                                if(longRiesgos===numTicks){
                                  elem.icon="small-tick"                              
                                }
        
                              })
        
                            }
                            return departamento.ID_CONTRATO_DEPARTAMENTO===this.state.departamentoSeleccionado.idDepCon
                            
                        })
        
                    })

                  }
                  

                
            }).catch(err=>{
              alert("Ha ocurrido un error al crear el documento.Pongase en contacto con el soporte tecnico")
            })
        
        }else{

            //si existe el documento lo actualizamos
            //si el documento existe
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarDocumentoSeguimiento(ID_DOCUMENTO:${this.state.documentoSeleccionado},VALORES:{
                    NOMBRE_APELLIDOS:"${this.state.nombreInformante}"
                    DOCUMENTO_IDENTIDAD:"${this.state.DNIinformante}"
                    EMPRESA:"${this.state.nombreEmpresa}"
                    PUESTO:"${this.state.puestoTrabajo}"
                    EXPLICACION_IMPERATIVOS:"""${
                        this.state.explicacionImperativos
                    }"""
                    EXPLICACION_RECOMENDADOS:"""${
                        this.state.explicacionRecomendados
                    }"""
                    EXPLICACION_SALVEDADES:"""${
                        this.state.explicacionSalvedades
                    }"""
                    FECHA_PROPUESTA_PROXIMA:"${
                        moment(this.state.fechaProximaValoracionControles).format("YYYY-MM-DD")
                    }"
                    OBSERVACIONES:"""${this.state.observaciones}"""
                    FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                    ID_USUARIO:${this.state.idUsuario}
                    COMPLIANCE_CERTIFICA:${this.state.certificado}
                }){ID_DOCUMENTO}}`
            }).then(async result=>{
           
                alert("El documento se ha actualizado correctamente.")

                //insercion de los controles imperativos/recomendaciones
                
                //eliminamos los viejeos
                await this.props.client.mutate({
                  mutation:gql`mutation{eliminarSeguimientoImperativa(ID_DOCUMENTO:${this.state.documentoSeleccionado}){ID_DOCUMENTO_IMPERATIVA}}`
                })
                //agregamos los imperativos                 
                this.state.impertivosSeleccionados.forEach(async(imperativo,index)=>{
                  await this.props.client.mutate({
                    mutation:gql`mutation{crearSeguimientoImperativa(VALORES:{ID_DOCUMENTO:${this.state.documentoSeleccionado},ID_IMPERATIVA:${imperativo.value},VALOR:${imperativo.nivel}}){ID_DOCUMENTO_IMPERATIVA}}`
                  })
                })


                //eliminamos los viejeos
                await this.props.client.mutate({
                  mutation:gql`mutation{eliminarSeguimientoRecomendacion(ID_DOCUMENTO:${this.state.documentoSeleccionado}){ID_DOCUMENTO_RECOMENDACION}}`
                })
                  //agregamos las recomendaciones                 
                  this.state.recomendacionesSeleccionados.forEach(async(recomendacion,index)=>{
                  await this.props.client.mutate({
                    mutation:gql`mutation{crearSeguimientoRecomendacion(VALORES:{ID_DOCUMENTO:${this.state.documentoSeleccionado},ID_RECOMENDACION:${recomendacion.value},VALOR:${recomendacion.nivel}}){ID_DOCUMENTO_RECOMENDACION}}`
                  })
                })

                
            }).catch(err=>{
              alert("Ha ocurrido un error al actualizar el documento.Pongase en contacto con el soporte tecnico")
              return
            })

        }

        if(this.props.modal){
          this.props.setRecargar(true)
        }


        cerrarModal();
    };

  
    const cellRendererImperativo = (rowIndex) => {
      return <Cell wrapText="false" truncated="false">{`${this.state.imperativos[rowIndex].label}`}</Cell>
    }

    const cellRendererRecomendaciones = (rowIndex) => {
      return <Cell wrapText="false" truncated="false">{`${this.state.recomendaciones[rowIndex].label}`}</Cell>
    }

    const abrirModalImpertativos = async () => {
      await this.setState({
        modalImperativos: true,
        imperativoSeleccionadoTabla:null,
        regionImperativa:null
      });

    };

    const abrirModalNuevoImpertativo = () => {
      this.setState({
        modalNuevoImperativo: true,
        nuevoImperativo:null,
        regionImperativa:null,
        imperativoSeleccionadoTabla:null
      },()=>{this.tablaImperativas.clearSelection()});
    };

    const abrirModalRecomendacion = async() => {
      await this.setState({
        modalRecomendaciones:true,
        recomendadosSeleccionadoTabla:null,
        regionRecomendacion:null
      });

      await this.tablaRecomendaciones.clearSelection()
    };

    const abrirModalNuevaRecomendacion = () => {
      this.setState({
        modalNuevaRecomendacion:true,
        nuevaRecomendacion:null,
        regionRecomendacion:null,
        recomendadosSeleccionadoTabla:null
      });
    };

    const handelNuevoImperativo=(ev)=>{
      this.setState({
        nuevoImperativo:ev.currentTarget.value
      });
    }

    const handelNuevaRecomendacion=(ev)=>{
      this.setState({
        nuevaRecomendacion:ev.currentTarget.value
      });
    }


    const borrarImperativo = index => {
      let imperativos=this.state.impertivosSeleccionados
      imperativos.splice(index,1)
      let niveles=this.state.nivelImperativas
      niveles.splice(index,1)
      this.setState({nivelImperativas:niveles})
      this.setState({impertivosSeleccionados:imperativos})
    };

    const borrarRecomendacion = index => {
      let recomendaciones=this.state.recomendacionesSeleccionados
      recomendaciones.splice(index,1)
      let niveles=this.state.nivelRecomendaciones
      niveles.splice(index,1)
      this.setState({nivelRecomendaciones:niveles})
      this.setState({recomendacionesSeleccionados:recomendaciones})
    };

    const seleccionarImperativo = value => {
      if(value.length===0)return

      let seleccionada=this.state.imperativos[value[0].rows[0]]
      this.setState({
        imperativoSeleccionadoTabla: seleccionada,
        regionImperativa:value
      });
    };

    const seleccionarRecomendacion= value => {
      if(value.length===0)return

      let seleccionada=this.state.recomendaciones[value[0].rows[0]]
      this.setState({ 
        recomendadosSeleccionadoTabla: seleccionada,
        regionRecomendacion:value
      });
    };

    const cogerNombreInformante = ev => {
      this.setState({ nombreInformante: ev.currentTarget.value });
    };

    const cogerNombreEmpresa = ev => {
      this.setState({ nombreEmpresa: ev.currentTarget.value });
    };

    const cogerPuesto = ev => {
      this.setState({ puestoTrabajo: ev.currentTarget.value });
    };

    const cogerDNIInformante = ev => {
      this.setState({ DNIinformante: ev.currentTarget.value });
    };

    const cogerExplicacionImperativos = ev => {
      this.setState({ explicacionImperativos: ev.currentTarget.value });
    };

    const cogerExplicacionRecomendados = ev => {
      this.setState({ explicacionRecomendados: ev.currentTarget.value });
    };

    const cogerExplicacionSalvedades = ev => {
      this.setState({ explicacionSalvedades: ev.currentTarget.value });
    };

    const cogerObservaciones = ev => {
      this.setState({ observaciones: ev.currentTarget.value });
    };

    const escogeFechaValoracionControles = nuevaFecha => {
      this.setState({ fechaProximaValoracionControles: nuevaFecha });
    };

    const escogeFechaValoracion = nuevaFecha => {
      this.setState({ fechaProximaValoracion: nuevaFecha });
    };

    const handleClickRecomendaciones = (fila,columna)  => {
      let valores=this.state.recomendacionesSeleccionados
      valores[fila].nivel=columna
      this.setState({ recomendacionesSeleccionados: valores });
    };

    const handleClickImperativas = (fila,columna) => {

      let valores=this.state.impertivosSeleccionados
      valores[fila].nivel=columna
      this.setState({ impertivosSeleccionados: valores });
    };

    const handleCertificar=()=>{
        this.setState({certificado:!this.state.certificado})
	}
	
	const handleSeleccionarFormativa = (value) => {
    if(value.length===0)return
		this.setState({
      formacionSeleccionada:this.state.accionesFormativas[value[0].rows[0]],
		})
	}
	
	const handleSeleccionarInformativa = (value) => {
    if(value.length===0)return
		this.setState({
      informacionSeleccionada:this.state.accionesInformativas[value[0].rows[0]],
		})
	}

    if (!this.state.hayContrato && !this.state.bloquesRiesgo) {
      return <></>;
    }

    /*const validarDni=(value)=>{

        var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
        var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
        var str = value.toString().toUpperCase();
      
        if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
      
        var nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');
      
        var letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;
      
        if (validChars.charAt(charIndex) === letter) return true;
      
        return false;
    }*/

    const anadirImperativo=()=>{
       
      if(!this.state.imperativoSeleccionadoTabla){
        alert("Debe seleccionar un procedimiento imperativo.")
        return
      }
      let existe=this.state.impertivosSeleccionados.find(imperativo=>{return imperativo.value===this.state.imperativoSeleccionadoTabla.value})
      if(existe){
        alert("Este procedimiento ya esta seleccionado")
        return
      }
      //agregamos la fila en la tabla
      let imperativos=this.state.impertivosSeleccionados
      let impSeleccionado=this.state.imperativoSeleccionadoTabla
      impSeleccionado.nivel=2// marcamos el nivel de riesgo a 2 por defecto
      imperativos.push(impSeleccionado)
      
      this.setState({impertivosSeleccionados:imperativos})
      this.setState({imperativoSeleccionadoTabla:null})
      cerrarModal2()
    }

    const anadirRecomendado=()=>{
       
      if(!this.state.recomendadosSeleccionadoTabla){
        alert("Debe seleccionar un procedimiento recomendado.")
        return
      }
      let existe=this.state.recomendacionesSeleccionados.find(recomendacion=>{return recomendacion.value===this.state.recomendadosSeleccionadoTabla.value})
      if(existe){
        alert("Este procedimiento ya esta seleccionado")
        return
      }
      
      //agregamos la fila en la tabla
      let recomendaciones=this.state.recomendacionesSeleccionados
      let recoSel=this.state.recomendadosSeleccionadoTabla
      recoSel.nivel=2
      recomendaciones.push(recoSel)

      this.setState({recomendacionesSeleccionados:recomendaciones})
      this.setState({recomendadosSeleccionadoTabla:null})

      cerrarModal2()
    }

    const anadirProcedimientoImperativo=()=>{

      if(!this.state.nuevoImperativo){
          return
      }

      //comprobar que no existe
      let existe=this.state.imperativos.find(imperativo=>{return imperativo.label===this.state.nuevoImperativo})
      if(existe){
        alert("Ya existe un procedimiento con esta descripcion")
        return
      }

      //mutacion para crear el procedimiento imperativo
      this.props.client.mutate({
        mutation:gql`mutation{crearComplianceImperativa(VALORES:{DESCRIPCION:"${this.state.nuevoImperativo}",PREDEFINIDA:false,ID_RIESGO:${this.state.riesgoSeleccionado.id.split("_").pop()}}){ID_IMPERATIVA}}`
      }).then(async result=>{
    
        alert("El procedimiento se ha creado correctamente.")
        await this.cargarImperativos(this.state.riesgoSeleccionado.id.split("_").pop())

        let filaCreada=this.state.imperativos.find((imperativa,index)=>{
          if(imperativa.value===result.data.crearComplianceImperativa.ID_IMPERATIVA){
              this.setState({regionImperativa:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
          } 
          return imperativa.value===result.data.crearComplianceImperativa.ID_IMPERATIVA

        })

        this.setState({imperativoSeleccionadoTabla:filaCreada})
          
      }).catch(err=>{
        alert("Ha ocurrido un error al crear el procedimiento.Pongase en contacto con el soporte tecnico")
      })

      
      cerrarModal3()
   }

   const anadirProcedimientoRecomendado=()=>{

    if(!this.state.nuevaRecomendacion){
        return
    }

    //comprobar que no existe
    let existe=this.state.recomendaciones.find(recomendacion=>{return recomendacion.label===this.state.nuevaRecomendacion})
    if(existe){
      alert("Ya existe un procedimiento con esta descripcion")
      return
    }

    //mutacion para crear el procedimiento imperativo
    this.props.client.mutate({
      mutation:gql`mutation{crearRecomendacion(VALORES:{DESCRIPCION:"${this.state.nuevaRecomendacion}",PREDEFINIDA:false,ID_RIESGO:${this.state.riesgoSeleccionado.id.split("_").pop()}}){ID_RECOMENDACION}}`
    }).then(async result=>{
 
      alert("El procedimiento se ha creado correctamente.")
      await this.cargarRecomendaciones(this.state.riesgoSeleccionado.id.split("_").pop())

      let filaCreada=this.state.recomendaciones.find((recomendacion,index)=>{
          if(recomendacion.value===result.data.crearRecomendacion.ID_RECOMENDACION){
              this.setState({regionRecomendacion:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
          }
          return recomendacion.value===result.data.crearRecomendacion.ID_RECOMENDACION

      })

      this.setState({
          recomendadosSeleccionadoTabla:filaCreada
      })
        
    }).catch(err=>{
      alert("Ha ocurrido un error al crear el procedimiento.Pongase en contacto con el soporte tecnico")
    })

    cerrarModal3()
  }

  const desplegarArbol=(hijos)=>{
            
    hijos.forEach(hijo=>{
          hijo.isExpanded=true
          if(hijo.childNodes)desplegarArbol(hijo.childNodes)
    })  
    this.setState({arbol:hijos})

  }

  const replegarArbol=async(hijos,asincrono)=>{

    if(asincrono){
        await hijos.forEach(async(hijo)=>{
            hijo.isExpanded=false
            if(hijo.childNodes)replegarArbol(hijo.childNodes,true)
        })  
        await this.setState({arbol:hijos})

    }else{

        hijos.forEach((hijo)=>{
            hijo.isExpanded=false
            if(hijo.childNodes)replegarArbol(hijo.childNodes,false)
        })  
        this.setState({arbol:hijos})

    }

  }

    const cellRendererNombreFormativa = (rowIndex) => {
      return <Cell >{`${this.state.accionesFormativas[rowIndex].F_TITULO}`}</Cell>
    }

    const cellRendererNombreInformativa = (rowIndex) => {
      return <Cell >{`${this.state.accionesInformativas[rowIndex].I_DESCRIPCION}`}</Cell>
    }

    const añadirNuevaFormativa = async() => {

        if(!this.accionesSeguimiento.state.lugarAccion){
          await this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
          await ReactDOM.findDOMNode(this.accionesSeguimiento.inputLugarAccion).children[0].focus()
          return
        }

        if(this.accionesSeguimiento.state.tipoAccionAAplicar===1 && !this.accionesSeguimiento.state.areaSeleccionada){
          alert("Debe seleccionar el área")
          this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
          return
        }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===2 && !this.accionesSeguimiento.state.departamentoSeleccionado){
          alert("Debe seleccionar el departamento")
          this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
          return
        }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===3 && !this.accionesSeguimiento.state.puestoSeleccionado){
          alert("Debe seleccionar el puesto de trabajo")
          this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
          return
        }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===4 && !this.accionesSeguimiento.state.empleadoSeleccionado){
          alert("Debe seleccionar el empleado")
          this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
          return
        }

        if(!this.accionesSeguimiento.state.tituloFormacion){
          await this.accionesSeguimiento.setState({pestaña:"formacion"})
          await ReactDOM.findDOMNode(this.accionesSeguimiento.inputTiutloFormacion).children[0].focus()
          return
        }

        if(this.state.formacionSeleccionada){

          await this.props.client
            .mutate({
            mutation: gql`
              mutation{actualizarFormacionInformacion(
                ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION},
                VALORES:{
                F_TITULO:"${this.accionesSeguimiento.state.tituloFormacion}"
                F_NUMERO_HORAS:${this.accionesSeguimiento.state.numeroHoras}
                F_MODALIDAD:${this.accionesSeguimiento.state.modalidadSeleccionada?this.accionesSeguimiento.state.modalidadSeleccionada.value:null}
                F_INICIO:${this.accionesSeguimiento.state.bolFechaInicio?'"'+moment(this.accionesSeguimiento.state.fechaInicio).format("YYYY-MM-DD")+'"':null}
                F_FIN:${this.accionesSeguimiento.state.bolFechaFin?'"'+moment(this.accionesSeguimiento.state.fechaFin).format("YYYY-MM-DD")+'"':null}
                F_HORARIO:${this.accionesSeguimiento.state.horario?'"'+this.accionesSeguimiento.state.horario+'"':null}
                F_PROCEDIMIENTO_EVALUACION:${this.accionesSeguimiento.state.procedimiento?'"'+this.accionesSeguimiento.state.procedimiento+'"':null}
                F_REQUISITOS_ACCESO:${this.accionesSeguimiento.state.requisitos?'"'+this.accionesSeguimiento.state.requisitos+'"':null}
                F_COMISION_ACADEMICA:${this.accionesSeguimiento.state.comision?'"'+this.accionesSeguimiento.state.comision.map(com=>{return com.name}).join("|")+'"':null}
                F_PROFESORADO:${this.accionesSeguimiento.state.profesorado?'"'+this.accionesSeguimiento.state.profesorado.map(prof=>{return prof.name}).join("|")+'"':null}
                F_OBJETIVOS:${this.accionesSeguimiento.state.objetivos?'"'+this.accionesSeguimiento.state.objetivos+'"':null}
                F_CONTENIDOS:${this.accionesSeguimiento.state.contenidos?'"'+this.accionesSeguimiento.state.contenidos+'"':null}
                LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
              }){ID_ACCION}}`
            })
            .then(async result => {


              //creamos los riesgos afectados.
              

              //eliminamos los viejos
              await this.props.client.mutate({
                mutation:gql`mutation{eliminarFormacionInformacionRiesgo(ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION}){ID_ACCION}}`
              }).catch(err=>{
                alert("Ha ocurrido un error al crear la formación.Pongase en contacto con el soporte técnico.")
                return
              })

              for(let riesgo of this.accionesSeguimiento.state.riesgosSeleccionados){

                await this.props.client.mutate({
                  mutation:gql`mutation{crearFormacionInformacionRiesgo(VALORES:{ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION},ID_RIESGO:${riesgo.id.split("_").pop()}}){ID_ACCION}}`
                }).catch(err=>{
                  alert("Ha ocurrido un error al actualizar la formación.Pongase en contacto con el soporte técnico.")
                  return
                })

              }

              //comprobantes
              if(this.state.formacionSeleccionada.ID_EMPLEADO){

                  //comprobamos si existe el comprobante.
                  
                  let accion=await this.props.client
                  .query({
                      query: gql`
                      {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${this.state.formacionSeleccionada.ID_EMPLEADO},ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION}}){ID_ACCION_PERSONA}}
                      `
                      ,fetchPolicy:'network-only'
                  }).then(result=>{ 
                      if(result.data.formacionInformacionPersonas.length>0)return result.data.formacionInformacionPersonas[0].ID_ACCION_PERSONA
                      return 0
                  })

                  if(accion===0){

                    await this.props.client.mutate({
                      mutation:gql`mutation{crearFormacionInformacionPersona(VALORES:{ID_ACCION:${this.state.formacionSeleccionada.ID_ACCION},ID_PERSONA:${this.state.formacionSeleccionada.ID_EMPLEADO}}){ID_ACCION}}`
                    }).catch(err=>{
                      alert("Ha ocurrido un error al actualizar la formación.Pongase en contacto con el soporte técnico.")
                      return
                    })
    
                  }

              }


    
              alert("La formación ha sido actualizado correctamente.")
              this.setState({crearAccionFormativa:false,elegirAccionFormativa:false})
              await this.cargarFormaciones(this.state.documentoSeleccionado)
         
              
            }).catch(err=>{
              alert("Ha ocurrido un error al actualizar la formación.Pongase en contacto con el soporte técnico.")
              return
            })

        }else{

            await this.props.client
            .mutate({
            mutation: gql`
              mutation{crearFormacionInformacion(VALORES:{
                ID_AREA:${this.accionesSeguimiento.state.areaSeleccionada?this.accionesSeguimiento.state.areaSeleccionada.id:null}
                ID_DEPARTAMENTO:${this.accionesSeguimiento.state.departamentoSeleccionado?this.accionesSeguimiento.state.departamentoSeleccionado.id:null}
                ID_PUESTO_TRABAJO:${this.accionesSeguimiento.state.puestoSeleccionado?this.accionesSeguimiento.state.puestoSeleccionado.id:null}
                ID_EMPLEADO:${this.accionesSeguimiento.state.empleadoSeleccionado?this.accionesSeguimiento.state.empleadoSeleccionado.id:null}
                TIPO_ACCION:"F"
                F_TITULO:"${this.accionesSeguimiento.state.tituloFormacion}"
                F_NUMERO_HORAS:${this.accionesSeguimiento.state.numeroHoras}
                F_MODALIDAD:${this.accionesSeguimiento.state.modalidadSeleccionada?this.accionesSeguimiento.state.modalidadSeleccionada.value:null}
                F_INICIO:${this.accionesSeguimiento.state.bolFechaInicio?'"'+moment(this.accionesSeguimiento.state.fechaInicio).format("YYYY-MM-DD")+'"':null}
                F_FIN:${this.accionesSeguimiento.state.bolFechaFin?'"'+moment(this.accionesSeguimiento.state.fechaFin).format("YYYY-MM-DD")+'"':null}
                F_HORARIO:${this.accionesSeguimiento.state.horario?'"'+this.accionesSeguimiento.state.horario+'"':null}
                F_PROCEDIMIENTO_EVALUACION:${this.accionesSeguimiento.state.procedimiento?'"'+this.accionesSeguimiento.state.procedimiento+'"':null}
                F_REQUISITOS_ACCESO:${this.accionesSeguimiento.state.requisitos?'"'+this.accionesSeguimiento.state.requisitos+'"':null}
                F_COMISION_ACADEMICA:${this.accionesSeguimiento.state.comision?'"'+this.accionesSeguimiento.state.comision.map(com=>{return com.name}).join("|")+'"':null}
                F_PROFESORADO:${this.accionesSeguimiento.state.profesorado?'"'+this.accionesSeguimiento.state.profesorado.map(prof=>{return prof.name}).join("|")+'"':null}
                F_OBJETIVOS:${this.accionesSeguimiento.state.objetivos?'"'+this.accionesSeguimiento.state.objetivos+'"':null}
                F_CONTENIDOS:${this.accionesSeguimiento.state.contenidos?'"'+this.accionesSeguimiento.state.contenidos+'"':null}
                LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
                ID_DOCUMENTO:${this.state.documentoSeleccionado}
              }){ID_ACCION}}`
            })
            .then(async result => {
    
              alert("Formación creada correctamente")
              this.setState({crearAccionFormativa:false,elegirAccionFormativa:false})
              await this.cargarFormaciones(this.state.documentoSeleccionado)

            }).catch(err=>{
              alert("Ha ocurrido un error al crear la formación.Pongase en contacto con el soporte técnico.")
              return
            })

        }

        
    }

    const añadirNuevaInformativa=async()=>{

      if(!this.accionesSeguimiento.state.lugarAccion){
        await this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
        await ReactDOM.findDOMNode(this.accionesSeguimiento.inputLugarAccion).children[0].focus()
        return
      }

      if(this.accionesSeguimiento.state.tipoAccionAAplicar===1 && !this.accionesSeguimiento.state.areaSeleccionada){
        alert("Debe seleccionar el área")
        this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
        return
      }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===2 && !this.accionesSeguimiento.state.departamentoSeleccionado){
        alert("Debe seleccionar el departamento")
        this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
        return
      }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===3 && !this.accionesSeguimiento.state.puestoSeleccionado){
        alert("Debe seleccionar el puesto de trabajo")
        this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
        return
      }else if(this.accionesSeguimiento.state.tipoAccionAAplicar===4 && !this.accionesSeguimiento.state.empleadoSeleccionado){
        alert("Debe seleccionar el empleado")
        this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
        return
      }

      if(!this.accionesSeguimiento.state.descripcionInfo){
        await this.accionesSeguimiento.setState({pestaña:"informacion"})
        await ReactDOM.findDOMNode(this.accionesSeguimiento.inputDescInfo).children[0].focus()
        return
      }

      if(this.state.informacionSeleccionada){

        await this.props.client
          .mutate({
          mutation: gql`
            mutation{actualizarFormacionInformacion(
              ID_ACCION:${this.state.informacionSeleccionada.ID_ACCION},
              VALORES:{
                I_DESCRIPCION:"${this.accionesSeguimiento.state.descripcionInfo}",
                I_TEXTO:${this.accionesSeguimiento.state.textoInfo?'"""'+this.accionesSeguimiento.state.textoInfo+'"""':null}
                I_CANAL:${this.accionesSeguimiento.state.canalSeleccionada?this.accionesSeguimiento.state.canalSeleccionada.value:null}
                I_FECHA:${this.accionesSeguimiento.state.bolFechaInformacion?'"'+moment(this.accionesSeguimiento.state.fechaInformacion).format("YYYY-MM-DD")+'"':null}
                I_DURACION:${this.accionesSeguimiento.state.cambiarNumeroHorasInformacion?'"'+this.accionesSeguimiento.state.cambiarNumeroHorasInformacion+'"':null}
                LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
            }){ID_ACCION}}`
          })
          .then(async result => {

            //creamos los riesgos afectados.
              

              //eliminamos los viejos
              await this.props.client.mutate({
                mutation:gql`mutation{eliminarFormacionInformacionRiesgo(ID_ACCION:${this.state.informacionSeleccionada.ID_ACCION}){ID_ACCION}}`
              }).catch(err=>{
                alert("Ha ocurrido un error al actualizar la formación.Pongase en contacto con el soporte técnico.")
                return
              })

              for(let riesgo of this.accionesSeguimiento.state.riesgosSeleccionados){

                await this.props.client.mutate({
                  mutation:gql`mutation{crearFormacionInformacionRiesgo(VALORES:{ID_ACCION:${this.state.informacionSeleccionada.ID_ACCION},ID_RIESGO:${riesgo.id.split("_").pop()}}){ID_ACCION}}`
                }).catch(err=>{
                  alert("Ha ocurrido un error al actualizar la formación.Pongase en contacto con el soporte técnico.")
                  return
                })

              }
  
            alert("Formación actualizada correctamente")
            this.setState({crearAccionInformativa:false,elegirAccionInformativa:false})
            await this.cargarInformacion(this.state.documentoSeleccionado)

  
          }).catch(err=>{
            alert("Ha ocurrido un error al actualizar la formación información.Pongase en contacto con el soporte técnico.")
            return
          })

      }else{

        await this.props.client
            .mutate({
            mutation: gql`
              mutation{crearFormacionInformacion(VALORES:{
                ID_AREA:${this.accionesSeguimiento.state.areaSeleccionada?this.accionesSeguimiento.state.areaSeleccionada.id:null}
                ID_DEPARTAMENTO:${this.accionesSeguimiento.state.departamentoSeleccionado?this.accionesSeguimiento.state.departamentoSeleccionado.id:null}
                ID_PUESTO_TRABAJO:${this.accionesSeguimiento.state.puestoSeleccionado?this.accionesSeguimiento.state.puestoSeleccionado.id:null}
                ID_EMPLEADO:${this.accionesSeguimiento.state.empleadoSeleccionado?this.accionesSeguimiento.state.empleadoSeleccionado.id:null}
                TIPO_ACCION:"I"
                I_DESCRIPCION:"${this.accionesSeguimiento.state.descripcionInfo}",
                I_TEXTO:${this.accionesSeguimiento.state.textoInfo?'"""'+this.accionesSeguimiento.state.textoInfo+'"""':null}
                I_CANAL:${this.accionesSeguimiento.state.canalSeleccionada?this.accionesSeguimiento.state.canalSeleccionada.value:null}
                I_FECHA:${this.accionesSeguimiento.state.bolFechaInformacion?'"'+moment(this.accionesSeguimiento.state.fechaInformacion).format("YYYY-MM-DD")+'"':null}
                I_DURACION:${this.accionesSeguimiento.state.cambiarNumeroHorasInformacion?'"'+this.accionesSeguimiento.state.cambiarNumeroHorasInformacion+'"':null}
                LUGAR:${this.accionesSeguimiento.state.lugarAccion?'"'+this.accionesSeguimiento.state.lugarAccion+'"':null}
                OBSERVACIONES:${this.accionesSeguimiento.state.observaciones?'"""'+this.accionesSeguimiento.state.observaciones+'"""':null}
                ID_DOCUMENTO:${this.state.documentoSeleccionado}
              }){ID_ACCION}}`
            })
            .then(async result => {
    
              alert("Formación creada correctamente")
              this.setState({crearAccionInformativa:false,elegirAccionInformativa:false})
              await this.cargarInformacion(this.state.documentoSeleccionado)

            }).catch(err=>{
              alert("Ha ocurrido un error al crear la formación información.Pongase en contacto con el soporte técnico.")
              return
            })

      }


    }
    const abrirDialogoTextoRiesgo=()=>{
    
      this.setState({ dialogTextoRiesgo: true });

    }

    const cerrarSoloEsteModal = () => {
      this.setState({ dialogTextoRiesgo: false });
    };

    
    const nuevaHoja=async()=>{

      //si no tiene permisos para editar la pantalla
      if(this.props.editable===false){
        alert("No tienes permisos para realizar esta acción")
        return
      }

      if(!this.state.modalIdentificacionAbierto)await this.setState({modalIdentificacionAbierto:true})
      let numero=(this.state.numeroIdentificacion!==this.state.numeroIdentificacionActual?1:this.state.numero+1)
      let riesgoSel=this.state.riesgoSeleccionado
      riesgoSel.deshabilitado=false
      //limpiamos los campos
      await this.setState({
        documentoSeleccionado: null,
        fechaFicha:new Date(),
        numero: numero,
        numeroIdentificacion:this.state.numeroIdentificacionActual,
        crearNuevaHoja:true,
        riesgoSeleccionado:riesgoSel,
        modalAdvertencia2Abierto:false
      });

      const modalDOM =await ReactDOM.findDOMNode(this.dialogoIdentificacion).getElementsByClassName("dialog-scroll")[0]
      modalDOM.scrollTop = 0
      

    }  

    const handleJoyrideCallback=async(data)=>{
        

      const { action, index, status, type } = data;

      if(data.action==="close"){

        localStorage.setItem("ayuda",false)
        this.setState({
          riesgoSeleccionado:null,
          formacionSeleccionada:null,
          elegirAccionFormativa:false,
          crearAccionFormativa:false,
          modalIdentificacionAbierto:false,
          modalAdvertencia1Abierto:false,
          modalAdvertencia2Abierto:false
        })
        this.cargaInicial()
        replegarArbol(this.state.arbol)

      }else{

        
      if ([EVENTS.STEP_AFTER].includes(type)) {

        let paso=index+ (action === ACTIONS.PREV ? -1 : 1)

        //paso en el que nos encontremos.
        switch(paso){
          case 0:
              await replegarArbol(this.state.arbol,true)   
          break;
          case 1:
              await replegarArbol(this.state.arbol,true)
              let arbol=this.state.arbol
              arbol[0].isExpanded=true
              arbol[0].childNodes[0].isExpanded=true
              arbol[0].childNodes[0].childNodes[0].isExpanded=true

              await this.setState({arbol:arbol,modalIdentificacionAbierto:false})
          break;
          case 2:
              await this.setState({modalIdentificacionAbierto:true,existeDocumento:false,crearNuevaHoja:true,riesgoSeleccionado:null})
          break;
          case 3:
              await this.setState({numeroIdentificacion:1,numero:1,existeDocumento:true,crearNuevaHoja:false,riesgoSeleccionado:{label:"11A01 - EXCESIVO ENDEUDAMIENTO",labelPadre:"Bloque A - RIESGOS RELACIONADOS CON EL CONTEXTO DE LA ORGANIZACIÓN"}})
              let  modalDOM =await ReactDOM.findDOMNode(this.dialogoIdentificacion).getElementsByClassName("dialog-scroll")[0]
              document.querySelector(".bp3-overlay-scroll-container").scrollTo(0,0)
              modalDOM.scrollTop = 0
          break;
          case 4:
              modalDOM =await ReactDOM.findDOMNode(this.dialogoIdentificacion).getElementsByClassName("dialog-scroll")[0]
              document.querySelector(".bp3-overlay-scroll-container").scrollTo(0,document.querySelector(".bp3-overlay-scroll-container").scrollHeight)
              modalDOM.scrollTop=modalDOM.scrollHeight
              await this.setState({numeroIdentificacion:1,numero:2})

          break;
          case 5:
              await this.setState({modalIdentificacionAbierto:true,existeDocumento:true,crearNuevaHoja:false,riesgoSeleccionado:{label:"11A01 - EXCESIVO ENDEUDAMIENTO",labelPadre:"Bloque A - RIESGOS RELACIONADOS CON EL CONTEXTO DE LA ORGANIZACIÓN"}})
              modalDOM =await ReactDOM.findDOMNode(this.dialogoIdentificacion).getElementsByClassName("dialog-scroll")[0]
              modalDOM.scrollTop = 0
              
          break;
          case 6:
              arbol=this.state.arbol
              arbol[0].childNodes[0].childNodes[0].childNodes[0].isExpanded=true
              arbol[0].childNodes[0].childNodes[0].childNodes[0].childNodes=[]
              arbol[0].childNodes[0].childNodes[0].childNodes[0].childNodes.unshift({
                  label:"Versión anterior: Número 1/2"
              })
              await this.setState({modalIdentificacionAbierto:false,existeDocumento:false,crearNuevaHoja:true,riesgoSeleccionado:null})
          break;
          case 7:
              await this.setState({modalIdentificacionAbierto:true,existeDocumento:true,crearNuevaHoja:false,riesgoSeleccionado:{numero:1,label:"11A01 - EXCESIVO ENDEUDAMIENTO",labelPadre:"Bloque A - RIESGOS RELACIONADOS CON EL CONTEXTO DE LA ORGANIZACIÓN"}})
              modalDOM =await ReactDOM.findDOMNode(this.dialogoIdentificacion).getElementsByClassName("dialog-scroll")[0]
              document.querySelector(".bp3-overlay-scroll-container").scrollTo(0,document.querySelector(".bp3-overlay-scroll-container").scrollHeight)
              modalDOM.scrollTop=modalDOM.scrollHeight
          break;
          case 8:
              await this.setState({elegirAccionFormativa:false})
          break;
          case 9:
              await this.setState({elegirAccionFormativa:true,crearAccionFormativa:false})
          break;
          case 10:
              await this.setState({crearAccionFormativa:true,formacionSeleccionada:null})
              await this.accionesSeguimiento.setState({pestaña:"accion-formativa"})
          break;
          case 11:
              await this.setState({formacionSeleccionada:{ID_EMPLEADO:0}})
              await this.accionesSeguimiento.setState({pestaña:"riesgos-relacionados"})
          break;
          case 12:
              await this.accionesSeguimiento.setState({pestaña:"personas"})
          break;
        }

        await this.setState({ pasoGuia: paso });         

      }

      //si termina la guia.
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
          localStorage.setItem("ayuda",false)
          this.setState({
            riesgoSeleccionado:null,
            formacionSeleccionada:null,
            elegirAccionFormativa:false,
            crearAccionFormativa:false,
            modalIdentificacionAbierto:false
          })
          this.cargaInicial()
          replegarArbol(this.state.arbol)
      }

      }


    } 

    if(!this.state.hayContrato===null || this.state.cargandoRI){
      return <Cargando />
    }

    if (this.state.hayContrato===false) {
      return (
        <>
          <Card interactive={true} elevation={Elevation.TWO}>
            <ContratoCompliance editable={this.props.editable}/>
          </Card>
        </>
      );
    } else {
      return (
    <>
      <Joyride
          run={localStorage.getItem("ayuda")==="true"?true:false}
          callback={handleJoyrideCallback}
          showProgress={true}
          stepIndex={this.state.pasoGuia}
          steps={steps}
          disableScrollParentFix={true}
          continuous ={true}
          locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
          styles={{
              options: {
                  arrowColor: '#e3ffeb',
                  backgroundColor: '#e3ffeb',
                  primaryColor: '#E31E24',
              }
          }}
    />
		<Drawer 
			isOpen={this.state.leyendaAbierta}
			isCloseButtonShown={true}
			hasBackdrop={false}
			enforceFocus={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			transitionDuration={400}
			onClose={()=>this.setState({ leyendaAbierta: !this.state.leyendaAbierta })}
		>
			<Card interactive={true} elevation={Elevation.TWO}>
				<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Descripción de los iconos:</Callout>
			</Card>
			<Card interactive={true} elevation={Elevation.TWO}>
				<h4><b>PARA DEPARTAMENTOS Y/O BLOQUES DE RIESGO</b></h4>
				<h4><Icon icon="small-tick" className="bp3-intent-success" />: Todas las fichas del bloque o departamento están rellenas.</h4>
				<h4><Icon icon="warning-sign" className="iconoNaranja" />: Faltan fichas por rellenar en el departamento o bloque.</h4>
				<h4><b>PARA LAS FICHAS DE IDENTIFICACIÓN Y ANALISIS</b></h4>
				<h4><Icon icon="small-tick" className="bp3-intent-success" />: La ficha está rellenada correctamente</h4>
				<h4><Icon icon="warning-sign" className="iconoNaranja" />: La ficha no está rellenada o está incompleta.</h4>
			</Card>
		</Drawer>

           <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarSoloEsteModal}
                    title="LexControl"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.dialogTextoRiesgo}
                    className="dialog-ancho"
                    >
                    <div className={Classes.DIALOG_BODY}>
                        <TextArea fill={true} disabled className="textarea-alta" value={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.descripcion:""} />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={cerrarSoloEsteModal}
                        >
                            CERRAR
                        </AnchorButton>
                        </div>
                    </div>
          </Dialog>
        {this.props.modal?
        <>
          <Dialog
          transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModal}
          title={"Documento de seguimiento y control de cumplimiento"}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          isOpen={this.state.modalIdentificacionAbierto}
          className="dialog-ancho dialog-scroll "
          ref={(input) => { this.dialogoIdentificacion= input; }}
        >
          <div  ref={(input) => { this.ficha= input; }}  className="pixelAlrededor">
            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <div className="tg-wrap">
                <table className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                  <tbody>
                    <tr>
                      <th className="tg-baqh" colSpan="9">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">CONTROLES IMPLANTADOS Y SEGUIMIENTO</Callout>
                      </th>
                      <th className="tg-0lax" colSpan="3">
                        Fecha {moment(this.state.fechaFicha,"YYYY-MM-DD").format("DD-MM-YYYY")}
                      </th>
                    </tr>
                    <tr>
                      <td className="tg-0lax" colSpan="2">
                        DEPARTAMENTO
                      </td>
                      <td className="tg-0lax" colSpan="2">{this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.label.split("-")[0].substr(0,/[a-z]/i.exec(this.state.riesgoSeleccionado.label.split("-")[0]).index):""}</td>
                      <td className="tg-0lax" colSpan="5">
                        {this.state.departamento}
                      </td>
                      <td className="tg-0lax" colSpan="3">
                        NUMERO: {this.state.numeroIdentificacion}/{this.state.numero}
                      </td>
                    </tr>
                    <tr>
                      <td className="tg-baqh" colSpan="12">
                        {this.state.bloque}
                      </td>
                    </tr>
                    <tr>
                      <td className="tg-baqh"><Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">RIESGO</Callout></td>
                      <td className="tg-baqh"><Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.riesgoSeleccionado?(this.state.riesgoSeleccionado.version?this.state.riesgoSeleccionado.labelPadre:this.state.riesgoSeleccionado.label.split("-")[0]):""}</Callout></td>
                      <td className="tg-baqh" colSpan="10">
                      <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                        {this.state.nombreRiesgo}{" "}
                        <Tooltip
                          content="Ver información relacionada con el riesgo seleccionado."
                          position={Position.TOP}
                        >
                          <Button icon="info-sign" onClick={()=>{abrirDialogoTextoRiesgo()}}/>
                        </Tooltip>{" "}
                        </Callout>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
            <br />
            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <h3>
                OPERATIVAS Y PROCEDIMENTOS A SEGUIR. NIVEL DEL CUMPLIMIENTO.
              </h3>
              <div className="tg-wrap">
                <table className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                  <tbody>
                    <tr>
                      <th className="cell-vacia" />
                      <th className="celdaFondoGris" colSpan="7">
                        NIVEL DE CUMPLIMIENTO
                      </th>
                    </tr>
                    <tr>
                      <td className="celdaFondoGris">IMPERATIVAS &nbsp;
                        <Tooltip
                          content="Seleccionar imperativa."
                          position="top"
                        >
                          <Button
                            icon="add"
                            onClick={abrirModalImpertativos}
                          />
                        </Tooltip>
                      </td>
                      <td className="celdaFondoGris">Óptimo</td>
                      <td className="celdaFondoGris">En Curso</td>
                      <td className="celdaFondoGris">Parcial</td>
                      <td className="celdaFondoGris">Salvedades</td>
                      <td className="celdaFondoGris">Malo</td>
                      <td className="celdaFondoGris">Muy Malo</td>
                    </tr>
                    {this.state.impertivosSeleccionados.length===0?
                       <tr>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                     </tr>
                    :
                      <></>

                    }
                   
                      {this.state.impertivosSeleccionados.map((imperativo,index) => {

                      return (
                        <tr>
                          <td>
                              <Tooltip content="Borrar imperativa." position="top">
                                <Button icon="delete" onClick={() => borrarImperativo(index)} />
                              </Tooltip> 
                              &nbsp;
                              {imperativo.label}

                          </td>
                          {imperativo.nivel === 1?
                              <>
                                <td className="celdaFondoVerde">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoVerde"
                                  onClick={() => handleClickImperativas(index,1)}
                                >
                                  1
                                </td>
                              </>
                          }
                           {imperativo.nivel === 2?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickImperativas(index,2)}
                                >
                                  2
                                </td>
                              </>
                          }
                           {imperativo.nivel === 3?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickImperativas(index,3)}
                                >
                                  3
                                </td>
                              </>
                          }
                           {imperativo.nivel=== 4?
                              <>
                                <td className="celdaFondoNaranja">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoNaranja"
                                  onClick={() => handleClickImperativas(index,4)}
                                >
                                  4
                                </td>
                              </>
                          }
                           {imperativo.nivel === 5?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickImperativas(index,5)}
                                >
                                  5
                                </td>
                              </>
                          }
                           {imperativo.nivel === 6?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickImperativas(index,6)}
                                >
                                  6
                                </td>
                              </>
                          }
                        </tr>
                      )
                    })}
                    <tr>
                      <td className="celdaFondoGris">
                        RECOMENDACIONES
                        &nbsp; 
                        <Tooltip
                          content="Seleccionar recomendacion."
                          position="top"
                        >
                          <Button
                            icon="add"
                            onClick={abrirModalRecomendacion}
                          />
                        </Tooltip>
                      </td>
                      <td className="celdaFondoGris">Óptimo</td>
                      <td className="celdaFondoGris">En Curso</td>
                      <td className="celdaFondoGris">Parcial</td>
                      <td className="celdaFondoGris">Salvedades</td>
                      <td className="celdaFondoGris">Malo</td>
                      <td className="celdaFondoGris">Muy Malo</td>
                    </tr>  
                      {this.state.recomendacionesSeleccionados.length===0?
                       <tr>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                     </tr>
                    :
                      <></>

                    }
                   
                      {this.state.recomendacionesSeleccionados.map((recomendacion,index) => {

                      return (
                        <tr>
                          <td>
                              <Tooltip content="Borrar recomendacion." position="top">
                                <Button icon="delete" onClick={() => borrarRecomendacion(index)} />
                              </Tooltip> 
                              &nbsp;
                              {recomendacion.label}

                          </td>
                          {recomendacion.nivel === 1?
                              <>
                                <td className="celdaFondoVerde">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoVerde"
                                  onClick={() => handleClickRecomendaciones(index,1)}
                                >
                                  1
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 2?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickRecomendaciones(index,2)}
                                >
                                  2
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 3?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickRecomendaciones(index,3)}
                                >
                                  3
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 4?
                              <>
                                <td className="celdaFondoNaranja">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoNaranja"
                                  onClick={() => handleClickRecomendaciones(index,4)}
                                >
                                  4
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 5?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickRecomendaciones(index,5)}
                                >
                                  5
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 6?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickRecomendaciones(index,6)}
                                >
                                  6
                                </td>
                              </>
                          }
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Card>

            <br />

            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <h3> INFORME DE CUMPLIMIENTO </h3>
            </Card>

            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <div className="tg-wrap">
                <table className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive celdaFondoGris">
                  <tbody>
                    <tr>
                      <th
                        colSpan="2"
                      >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">RIESGO: {this.state.riesgoSeleccionado?(this.state.riesgoSeleccionado.version?this.state.riesgoSeleccionado.labelPadre:this.state.riesgoSeleccionado.label.split("-")[0]):""}</Callout>
                      </th>
                      <th
                        colSpan="2"
                      >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.nombreRiesgo}</Callout>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        Don/Doña:
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.nombreInformante?"Debe rellenar el nombre del informante":""}
                          >
                              <InputGroup value={this.state.nombreInformante?this.state.nombreInformante:""} ref={(input) => { this.nombreInformanteInput= input; }}  onChange={cogerNombreInformante} intent={!this.state.nombreInformante?"danger":"primary"} />
                          </FormGroup>
                      </td>
                      <td>
                        Con DNI Nº:
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.DNIinformante?"Debe rellenar el dni del informante":(this.state.dniInvalido?"Debe insertar un válido":"")}
                          >
                              <InputGroup value={this.state.DNIinformante?this.state.DNIinformante:""} maxLength={10} ref={(input) => { this.DNIinformanteInput= input; }} intent={!this.state.DNIinformante || this.state.dniInvalido?"danger":"primary"} onChange={cogerDNIInformante} />{" "}
                          </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        TRABAJADOR DE LA EMPRESA:
                           <FormGroup
                              intent="danger"
                              helperText={!this.state.nombreEmpresa?"Debe rellenar el nombre de la empresa":""}
                          >
                              <InputGroup value={this.state.nombreEmpresa?this.state.nombreEmpresa:""} ref={(input) => { this.nombreEmpresaInput= input; }} intent={!this.state.nombreEmpresa?"danger":"primary"} onChange={cogerNombreEmpresa} />{" "}
                          </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        DESEMPEÑANDO EL PUESTO DE TRABAJO
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.puestoTrabajo?"Debe rellenar el puesto de trabajo":""}
                          >
                              <InputGroup value={this.state.puestoTrabajo?this.state.puestoTrabajo:""} ref={(input) => { this.puestoTrabajoInput= input; }}  intent={!this.state.puestoTrabajo?"danger":"primary"} onChange={cogerPuesto} />
                          </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        CERTIFICA bajo su responsabilidad la veracidad de la
                        información aquí contenida y la aplicación de los
                        controles establecidos con el siguiente resultado:
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        EXPLICA SOBRE EL NIVEL DE CUMPLIMIENTO DE CONTROLES
                        IMPERATIVOS
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.explicacionImperativos?"Debe rellenar la explicación del nivel de cumplimientos imperativos":""}
                      >
                          <TextArea
                              fill={true}
                              onChange={cogerExplicacionImperativos}
                              value={this.state.explicacionImperativos?this.state.explicacionImperativos:""}
                              intent={!this.state.explicacionImperativos?"danger":"primary"}
                              ref={(input) => { this.explicacionImperativosInput= input; }}
                          />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        EXPLICA SOBRE EL NIVEL DE CUMPLIMIENTO DE CONTROLES
                        RECOMENDADOS
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.explicacionRecomendados?"Debe rellenar la explicación del nivel de cumplimientos recomendados":""}
                      >
                        <TextArea
                          fill={true}
                          onChange={cogerExplicacionRecomendados}
                          value={this.state.explicacionRecomendados?this.state.explicacionRecomendados:""}
                          intent={!this.state.explicacionRecomendados?"danger":"primary"}
                          ref={(input) => { this.explicacionRecomendadosInput= input; }}
                        />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        SALVEDADES, SUGERENCIAS Y MEDIDAS CONCRETAS QUE SE
                        PROPONEN
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.explicacionSalvedades?"Debe rellenar la explicación del las salvedades":""}
                      >
                        <TextArea
                          fill={true}
                          onChange={cogerExplicacionSalvedades}
                          value={this.state.explicacionSalvedades?this.state.explicacionSalvedades:""}
                          intent={!this.state.explicacionSalvedades?"danger":"primary"}
                          ref={(input) => { this.explicacionSalvedadesInput= input; }}
                        />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <p>
                          FECHA PROPUESTA DE LA PRÓXIMA VALORACIÓN DE
                          CONTROLES:
                        </p>{" "}
                      </td>
                      <td>
                        <DateInput minDate={new Date(1900, 1 ,1)}
                          {...jsDateFormatter}
                          onChange={escogeFechaValoracionControles}
                          value={this.state.fechaProximaValoracionControles}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th
                        colSpan="6"
                      >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">RIESGO: {this.state.riesgoSeleccionado?(this.state.riesgoSeleccionado.version?this.state.riesgoSeleccionado.labelPadre:this.state.riesgoSeleccionado.label.split("-")[0]):""}</Callout>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="6">
                        <Checkbox onChange={handleCertificar} checked={this.state.certificado} /> Certifico que el nivel de cumplimiento es
                        tal y como se recoge en este documento.
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="6">OBSERVACIONES</td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.observaciones?"Debe rellenar las observaciones":""}
                      >
                          <TextArea fill={true} value={this.state.observaciones?this.state.observaciones:""}  ref={(input) => { this.observacionesInput= input; }} intent={!this.state.observaciones?"danger":"primary"} onChange={cogerObservaciones} />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <p>FECHA PROPUESTA DE LA PRÓXIMA VALORACIÓN:</p>
                      </td>
                      <td>
                        <DateInput minDate={new Date(1900, 1 ,1)}
                          {...jsDateFormatter}
                          onChange={escogeFechaValoracion}
                          value={this.state.fechaProximaValoracion}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {this.state.riesgoSeleccionado && this.state.riesgoSeleccionado.numero && !this.state.crearNuevaHoja?
              <>
                <Button  onClick={abrirFormacion}>Formación <span className="badge">{this.state.numFormacion}</span></Button>
                <Button  onClick={abrirInformacion}>Información <span className="badge">{this.state.numInformacion}</span></Button>
              </>
            :
            <></>}
				<Tooltip content="Cerrar y volver." position="top">
					<Button onClick={cerrarModal}>Cerrar</Button>
				</Tooltip>
				<Tooltip content="Imprimir." position="top">
					<ReactToPrint
						trigger={() => <AnchorButton intent={Intent.DANGER} icon="print" text="Imprimir"/>}
						content={() => this.ficha}
					/>
				</Tooltip>
        {this.state.existeDocumento && !this.state.crearNuevaHoja && (this.state.riesgoSeleccionado && !this.state.riesgoSeleccionado.version)?
            <Tooltip content={"Nueva hoja de seguimiento"} position="top">
              <AnchorButton
                icon="document"
                intent={Intent.DANGER}
                onClick={nuevaHoja}
                className="bp3-minimal" 
              >
                Nuevo documento seguimiento
              </AnchorButton>
            </Tooltip>  
          :<></>}
				<Tooltip content={"Guardar"} position="top">
					<AnchorButton
					icon="floppy-disk"
					intent={Intent.DANGER}
					onClick={guardarCambios}
					className="bp3-minimal"
					disabled={
						this.state.riesgoSeleccionado
						? this.state.riesgoSeleccionado.deshabilitado 
						: true
					}
					>
					Guardar
					</AnchorButton>
				</Tooltip>
            </div>
          </div>
        </Dialog>  
        <Dialog
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="warning-sign"
        onClose={cerrarModal2}
        title="LexControl"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={this.state.modalAdvertencia1Abierto}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            El documento seleccionado no es la última versión de la
            evaluación de este seguimiento. Se abrirá en modo de sólo lectura
            para su consulta exclusivamente.Para crear un nueva hoja de seguimiento 
            abra la última versión y haga clic en 'Nuevo documento seguimiento'.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
              Aceptar
            </AnchorButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="warning-sign"
        onClose={cerrarModal2}
        title="LexControl"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={this.state.modalAdvertencia2Abierto}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            ¡ATENCIÓN!
            <br />
            Esta modificación implicará la creación de un nuevo documento
            con el numero {this.state.numeroIdentificacionActual}/1
            <br />
            ¿Desea continuar?
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton intent={Intent.PRIMARY} onClick={nuevaHoja}>
              SI
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal3}>
              NO
            </AnchorButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="warning-sign"
        onClose={cerrarModal2}
        title="LexControl"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={this.state.modalImperativos}
      >
        <div className={Classes.DIALOG_BODY}>
        <p>Seleccione el procedimiento imperativo desedo y pulse "Aceptar". Si el procedimiento no está en la lista, pulse el botón "Nuevo procedimiento"  para crearlo</p>
          <Table className="acortarOtraTablaRebelde" defaultColumnWidth={400} fill={true} ref={(input) => { this.tablaImperativas= input; }} selectedRegions={this.state.regionImperativa} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarImperativo} numRows={this.state.imperativos.length}>
              <Column name="Procedimiento"  cellRenderer={cellRendererImperativo}/>
          </Table>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevoImpertativo}>
              Nuevo procedimiento
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={anadirImperativo}>
              Aceptar
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
              Cerrar
            </AnchorButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="warning-sign"
        onClose={cerrarModal2}
        title="LexControl"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={this.state.modalRecomendaciones}
      >
        <div className={Classes.DIALOG_BODY}>
        <p>Seleccione el procedimiento recomendado desedo y pulse "Aceptar". Siel procedimiento no está en la lista, pulse el botón "Nuevo procedimiento para crearlo"</p>
          <Table className="acortarOtraTablaRebelde" defaultColumnWidth={400} fill={true} ref={(input) => { this.tablaRecomendaciones= input; }} selectedRegions={this.state.regionRecomendacion} onSelection={seleccionarRecomendacion} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.recomendaciones.length}>
              <Column name="Procedimiento" cellRenderer={cellRendererRecomendaciones}/>
          </Table>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevaRecomendacion}>
              Nuevo procedimiento
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={anadirRecomendado}>
              Aceptar
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
              Cerrar
            </AnchorButton>
          </div>
        </div>
      </Dialog>
      <Dialog 
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title="Crear nuevo procedimiento imperativo"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevoImperativo}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                label="Introduzca el nombre del procedimiento imperativo y pulse aceptar"
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nuevoImperativo?"":"Debe insertar el procedimiento imperativo"}
                >
                    <InputGroup id="texto-nombre-imperativo" onChange={handelNuevoImperativo}  placeholder="Escriba aqui el procedimiento imperativo" intent={this.state.nuevoImperativo?"primary":"danger"} />
                </FormGroup>

            </div>
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content="Agregar imperativo." position="top">
                    <AnchorButton
                        intent={Intent.PRIMARY}
                        onClick={anadirProcedimientoImperativo}
                    >
                        Aceptar
                    </AnchorButton>
                </Tooltip>
                <Tooltip content="Cerrar y volver." position="top">
                    <Button onClick={cerrarModal3}>Cancelar</Button>
                </Tooltip>
            </div>
        </div>
      </Dialog>
      <Dialog 
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="info-sign"
        onClose={cerrarModal}
        title="Crear nuevo procedimiento recomendado"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={this.state.modalNuevaRecomendacion}
    >
        <div className={Classes.DIALOG_BODY}>
            <FormGroup
            label="Introduzca el nombre del procedimiento recomendado y pulse aceptar"
            labelFor="informacion"
            intent="danger"
            helperText={this.state.nuevaRecomendacion?"":"Debe insertar el procedimiento recomendado"}
            >
                <InputGroup id="texto-nombre-recomendado" onChange={handelNuevaRecomendacion}  placeholder="Escriba aqui el procedimiento recomendado" intent={this.state.nuevaRecomendacion?"primary":"danger"} />
            </FormGroup>

        </div>
        <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="Agregar recomendacion." position="top">
                <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={anadirProcedimientoRecomendado}
                >
                    Aceptar
                </AnchorButton>
            </Tooltip>
            <Tooltip content="Cerrar y volver." position="top">
                <Button onClick={cerrarModal3}>Cancelar</Button>
            </Tooltip>
        </div>
    </div>
  </Dialog>
  <Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({elegirAccionFormativa:false})}}
				title="Selección de acción formativa"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.elegirAccionFormativa}
			>       

			<div className={Classes.DIALOG_BODY}>
        {this.props.editable===true && this.state.riesgoSeleccionado && !this.state.riesgoSeleccionado.deshabilitado?
          <p>Si desea crear una nueva acción formativa, <a href="#top" onClick={(e)=>{              
              e.preventDefault()
              this.setState({crearAccionFormativa:true,formacionSeleccionada:null},()=>{this.tablaFormativas.clearSelection()})
          }}>Pulse aqui</a></p>
        :<></> }
	
				<p>Si desea editar una acción formativa existente, selecciónela y pulse aceptar.</p>
				<Table className="acortarOtraTablaRebelde"  defaultColumnWidth={200} ref={(input) => { this.tablaFormativas= input; }} onSelection={handleSeleccionarFormativa} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.accionesFormativas.length}>
					<Column name="Nombre"  cellRenderer={cellRendererNombreFormativa}/>    
				</Table>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{
          if(!this.state.formacionSeleccionada){
            alert("Debe seleccionar una formación.")
            return
          }
          this.setState({crearAccionFormativa:true})
        }}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{
            this.setState({elegirAccionFormativa:false})
          }}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({crearAccionFormativa:false})}}
				title="Creación de acciones formativas"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
        isOpen={this.state.crearAccionFormativa}
        className="dialog-extra-ancho"
			>
			<div className={Classes.DIALOG_BODY}>
				<AccionesSeguimiento editable={this.props.editable} deshabilitado={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.deshabilitado:null} area={localStorage.getItem("ayuda")==="true"?null:(this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.idArea:null)} departamento={localStorage.getItem("ayuda")==="true"?null:(this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.codDep:null)} ref={(input) => { this.accionesSeguimiento= input; }} tipo="formacion" datos={this.state.formacionSeleccionada} />
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<AnchorButton disabled={this.props.editable===false || (this.state.riesgoSeleccionado && this.state.riesgoSeleccionado.deshabilitado) ?true:false} intent={Intent.PRIMARY} onClick={añadirNuevaFormativa}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({crearAccionFormativa:false})}}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({elegirAccionInformativa:false})}}
				title="Selección de acción informativa"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.elegirAccionInformativa}
			>
			<div className={Classes.DIALOG_BODY}>
        {this.props.editable===true && this.state.riesgoSeleccionado && !this.state.riesgoSeleccionado.deshabilitado?
          <p>Si desea crear una nueva acción informativa, <a href="#top" onClick={(e)=>{
              e.preventDefault()
              this.setState({crearAccionInformativa:true,informacionSeleccionada:null},()=>{this.tablaInformativas.clearSelection()})}
          }>Pulse aqui</a></p>
        :<></>}

				<p>Si desea editar una acción formativa existente, selecciónela y pulse aceptar.</p>
				<Table ref={(input) => { this.tablaInformativas= input; }} defaultColumnWidth={150} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={handleSeleccionarInformativa} numRows={this.state.accionesInformativas.length}>
					<Column name="Nombre"  cellRenderer={cellRendererNombreInformativa}/>    
				</Table>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <AnchorButton intent={Intent.PRIMARY} onClick={()=>{
          if(!this.state.informacionSeleccionada){
            alert("Debe señeccionar una información.")
            return
          }
          this.setState({crearAccionInformativa:true})
        }}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({elegirAccionInformativa:false})}}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({crearAccionInformativa:false})}}
				title="Creación de acciones informativas"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
        isOpen={this.state.crearAccionInformativa}
        className="dialog-extra-ancho"
			>
			<div className={Classes.DIALOG_BODY}>
				<AccionesSeguimiento editable={this.props.editable} deshabilitado={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.deshabilitado:null} area={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.idArea:null} departamento={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.codDep:null} ref={(input) => { this.accionesSeguimiento= input; }} tipo="informacion" datos={this.state.informacionSeleccionada} />
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<AnchorButton disabled={this.props.editable===false || (this.state.riesgoSeleccionado && this.state.riesgoSeleccionado.deshabilitado) ?true:false} intent={Intent.PRIMARY} onClick={añadirNuevaInformativa}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({crearAccionInformativa:false})}}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>
        </>
        :
        <>         
          <Dialog
          transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModal}
          title={"Documento de seguimiento y control de cumplimiento"}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
          isOpen={this.state.modalIdentificacionAbierto}
          className="dialog-ancho dialog-scroll "
          ref={(input) => { this.dialogoIdentificacion= input; }}
        >
          <div id="modal-seguimiento" ref={(input) => { this.ficha= input; }}  className="pixelAlrededor">
            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <div className="tg-wrap">
                <table className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                  <tbody>
                    <tr>
                      <th className="tg-baqh" colSpan="9">
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">CONTROLES IMPLANTADOS Y SEGUIMIENTO</Callout>
                      </th>
                      <th className="tg-0lax" colSpan="3">
                        Fecha {moment(this.state.fechaFicha,"YYYY-MM-DD").format("DD-MM-YYYY")}
                      </th>
                    </tr>
                    <tr>
                      <td className="tg-0lax" colSpan="2">
                        DEPARTAMENTO
                      </td>
                      <td className="tg-0lax" colSpan="2">{this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.labelPadre?this.state.riesgoSeleccionado.labelPadre.split("-")[0].substr(0,/[a-z]/i.exec(this.state.riesgoSeleccionado.labelPadre.split("-")[0]).index):this.state.riesgoSeleccionado.label.split("-")[0].substr(0,/[a-z]/i.exec(this.state.riesgoSeleccionado.label.split("-")[0]).index):""}</td>
                      <td className="tg-0lax" colSpan="5">
                        {this.state.departamento}
                      </td>
                      <td id="numeracion-seguimiento" className="tg-0lax" colSpan="3">
                        NUMERO: {this.state.numeroIdentificacion}/{this.state.numero}
                      </td>
                    </tr>
                    <tr>
                      <td className="tg-baqh" colSpan="12">
                        {this.state.bloque}
                      </td>
                    </tr>
                    <tr>
                      <td className="tg-baqh"><Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">RIESGO</Callout></td>
                      <td className="tg-baqh"><Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.riesgoSeleccionado?(this.state.riesgoSeleccionado.version ?this.state.riesgoSeleccionado.labelPadre:this.state.riesgoSeleccionado.label.split("-")[0]):""}</Callout></td>
                      <td className="tg-baqh" colSpan="10">
                      <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                        {this.state.nombreRiesgo}{" "}
                        <Tooltip
                          content="Ver información relacionada con el riesgo seleccionado."
                          position={Position.TOP}
                        >
                          <Button icon="info-sign" onClick={()=>{abrirDialogoTextoRiesgo()}} />
                        </Tooltip>{" "}
                        </Callout>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
            <br />
            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <h3>
                OPERATIVAS Y PROCEDIMENTOS A SEGUIR. NIVEL DEL CUMPLIMIENTO.
              </h3>
              <div className="tg-wrap">
                <table className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
                  <tbody>
                    <tr>
                      <th className="cell-vacia" />
                      <th className="celdaFondoGris" colSpan="7">
                        NIVEL DE CUMPLIMIENTO
                      </th>
                    </tr>
                    <tr>
                      <td className="celdaFondoGris">IMPERATIVAS &nbsp;
                        <Tooltip
                          content="Seleccionar imperativa."
                          position="top"
                        >
                          <Button
                            icon="add"
                            onClick={abrirModalImpertativos}
                          />
                        </Tooltip>
                      </td>
                      <td className="celdaFondoGris">Óptimo</td>
                      <td className="celdaFondoGris">En Curso</td>
                      <td className="celdaFondoGris">Parcial</td>
                      <td className="celdaFondoGris">Salvedades</td>
                      <td className="celdaFondoGris">Malo</td>
                      <td className="celdaFondoGris">Muy Malo</td>
                    </tr>
                    {this.state.impertivosSeleccionados.length===0?
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    :
                      <></>

                    }
                  
                      {this.state.impertivosSeleccionados.map((imperativo,index) => {

                      return (
                        <tr>
                          <td>
                              <Tooltip content="Borrar imperativa." position="top">
                                <Button icon="delete" onClick={() => borrarImperativo(index)} />
                              </Tooltip> 
                              &nbsp;
                              {imperativo.label}

                          </td>
                          {imperativo.nivel === 1?
                              <>
                                <td className="celdaFondoVerde">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoVerde"
                                  onClick={() => handleClickImperativas(index,1)}
                                >
                                  1
                                </td>
                              </>
                          }
                          {imperativo.nivel === 2?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickImperativas(index,2)}
                                >
                                  2
                                </td>
                              </>
                          }
                          {imperativo.nivel === 3?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickImperativas(index,3)}
                                >
                                  3
                                </td>
                              </>
                          }
                          {imperativo.nivel=== 4?
                              <>
                                <td className="celdaFondoNaranja">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoNaranja"
                                  onClick={() => handleClickImperativas(index,4)}
                                >
                                  4
                                </td>
                              </>
                          }
                          {imperativo.nivel === 5?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickImperativas(index,5)}
                                >
                                  5
                                </td>
                              </>
                          }
                          {imperativo.nivel === 6?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickImperativas(index,6)}
                                >
                                  6
                                </td>
                              </>
                          }
                        </tr>
                      )
                    })}
                    <tr>
                      <td className="celdaFondoGris">
                        RECOMENDACIONES
                        &nbsp; 
                        <Tooltip
                          content="Seleccionar recomendacion."
                          position="top"
                        >
                          <Button
                            icon="add"
                            onClick={abrirModalRecomendacion}
                          />
                        </Tooltip>
                      </td>
                      <td className="celdaFondoGris">Óptimo</td>
                      <td className="celdaFondoGris">En Curso</td>
                      <td className="celdaFondoGris">Parcial</td>
                      <td className="celdaFondoGris">Salvedades</td>
                      <td className="celdaFondoGris">Malo</td>
                      <td className="celdaFondoGris">Muy Malo</td>
                    </tr>  
                      {this.state.recomendacionesSeleccionados.length===0?
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    :
                      <></>

                    }
                  
                      {this.state.recomendacionesSeleccionados.map((recomendacion,index) => {

                      return (
                        <tr>
                          <td>
                              <Tooltip content="Borrar recomendacion." position="top">
                                <Button icon="delete" onClick={() => borrarRecomendacion(index)} />
                              </Tooltip> 
                              &nbsp;
                              {recomendacion.label}

                          </td>
                          {recomendacion.nivel === 1?
                              <>
                                <td className="celdaFondoVerde">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoVerde"
                                  onClick={() => handleClickRecomendaciones(index,1)}
                                >
                                  1
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 2?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickRecomendaciones(index,2)}
                                >
                                  2
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 3?
                              <>
                                <td className="celdaFondoAmarillo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoAmarillo"
                                  onClick={() => handleClickRecomendaciones(index,3)}
                                >
                                  3
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 4?
                              <>
                                <td className="celdaFondoNaranja">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoNaranja"
                                  onClick={() => handleClickRecomendaciones(index,4)}
                                >
                                  4
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 5?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickRecomendaciones(index,5)}
                                >
                                  5
                                </td>
                              </>
                          }
                          {recomendacion.nivel === 6?
                              <>
                                <td className="celdaFondoRojo">
                                  <Icon icon="endorsed" style={{ color: "#000" }} />
                                </td>
                              </>
                          :
                              <>
                                <td
                                  className="celdaFondoRojo"
                                  onClick={() => handleClickRecomendaciones(index,6)}
                                >
                                  6
                                </td>
                              </>
                          }
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Card>

            <br />

            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <h3> INFORME DE CUMPLIMIENTO </h3>
            </Card>

            <Card
              interactive={true}
              elevation={Elevation.TWO}
              className="pixelAlrededor"
            >
              <div className="tg-wrap">
                <table className="tg bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive celdaFondoGris">
                  <tbody>
                    <tr>
                      <th
                        colSpan="2"
                      >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">RIESGO: {this.state.riesgoSeleccionado?(this.state.riesgoSeleccionado.version ?this.state.riesgoSeleccionado.labelPadre:this.state.riesgoSeleccionado.label.split("-")[0]):""}</Callout>
                      </th>
                      <th
                        colSpan="2"
                      >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.state.nombreRiesgo}</Callout>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        Don/Doña:
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.nombreInformante?"Debe rellenar el nombre del informante":""}
                          >
                              <InputGroup value={this.state.nombreInformante?this.state.nombreInformante:""} ref={(input) => { this.nombreInformanteInput= input; }}  onChange={cogerNombreInformante} intent={!this.state.nombreInformante?"danger":"primary"} />
                          </FormGroup>
                      </td>
                      <td>
                        Con DNI Nº:
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.DNIinformante?"Debe rellenar el dni del informante":(this.state.dniInvalido?"Debe insertar un válido":"")}
                          >
                              <InputGroup value={this.state.DNIinformante?this.state.DNIinformante:""} maxLength={10} ref={(input) => { this.DNIinformanteInput= input; }} intent={!this.state.DNIinformante || this.state.dniInvalido?"danger":"primary"} onChange={cogerDNIInformante} />{" "}
                          </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        TRABAJADOR DE LA EMPRESA:
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.nombreEmpresa?"Debe rellenar el nombre de la empresa":""}
                          >
                              <InputGroup value={this.state.nombreEmpresa?this.state.nombreEmpresa:""} ref={(input) => { this.nombreEmpresaInput= input; }} intent={!this.state.nombreEmpresa?"danger":"primary"} onChange={cogerNombreEmpresa} />{" "}
                          </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        DESEMPEÑANDO EL PUESTO DE TRABAJO
                          <FormGroup
                              intent="danger"
                              helperText={!this.state.puestoTrabajo?"Debe rellenar el puesto de trabajo":""}
                          >
                              <InputGroup value={this.state.puestoTrabajo?this.state.puestoTrabajo:""} ref={(input) => { this.puestoTrabajoInput= input; }}  intent={!this.state.puestoTrabajo?"danger":"primary"} onChange={cogerPuesto} />
                          </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        CERTIFICA bajo su responsabilidad la veracidad de la
                        información aquí contenida y la aplicación de los
                        controles establecidos con el siguiente resultado:
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        EXPLICA SOBRE EL NIVEL DE CUMPLIMIENTO DE CONTROLES
                        IMPERATIVOS
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.explicacionImperativos?"Debe rellenar la explicación del nivel de cumplimientos imperativos":""}
                      >
                          <TextArea
                              fill={true}
                              onChange={cogerExplicacionImperativos}
                              value={this.state.explicacionImperativos?this.state.explicacionImperativos:""}
                              intent={!this.state.explicacionImperativos?"danger":"primary"}
                              ref={(input) => { this.explicacionImperativosInput= input; }}
                          />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        EXPLICA SOBRE EL NIVEL DE CUMPLIMIENTO DE CONTROLES
                        RECOMENDADOS
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.explicacionRecomendados?"Debe rellenar la explicación del nivel de cumplimientos recomendados":""}
                      >
                        <TextArea
                          fill={true}
                          onChange={cogerExplicacionRecomendados}
                          value={this.state.explicacionRecomendados?this.state.explicacionRecomendados:""}
                          intent={!this.state.explicacionRecomendados?"danger":"primary"}
                          ref={(input) => { this.explicacionRecomendadosInput= input; }}
                        />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                        SALVEDADES, SUGERENCIAS Y MEDIDAS CONCRETAS QUE SE
                        PROPONEN
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.explicacionSalvedades?"Debe rellenar la explicación del las salvedades":""}
                      >
                        <TextArea
                          fill={true}
                          onChange={cogerExplicacionSalvedades}
                          value={this.state.explicacionSalvedades?this.state.explicacionSalvedades:""}
                          intent={!this.state.explicacionSalvedades?"danger":"primary"}
                          ref={(input) => { this.explicacionSalvedadesInput= input; }}
                        />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <p>
                          FECHA PROPUESTA DE LA PRÓXIMA VALORACIÓN DE
                          CONTROLES:
                        </p>{" "}
                      </td>
                      <td>
                        <DateInput minDate={new Date(1900, 1 ,1)}
                          {...jsDateFormatter}
                          onChange={escogeFechaValoracionControles}
                          value={this.state.fechaProximaValoracionControles}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th
                        colSpan="6"
                      >
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">RIESGO: {this.state.riesgoSeleccionado?(this.state.riesgoSeleccionado.version?this.state.riesgoSeleccionado.labelPadre:this.state.riesgoSeleccionado.label.split("-")[0]):""}</Callout>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="6">
                        <Checkbox onChange={handleCertificar} checked={this.state.certificado} /> Certifico que el nivel de cumplimiento es
                        tal y como se recoge en este documento.
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="6">OBSERVACIONES</td>
                    </tr>
                    <tr>
                      <td colSpan="6">
                      <FormGroup
                          intent="danger"
                          helperText={!this.state.observaciones?"Debe rellenar las observaciones":""}
                      >
                          <TextArea fill={true} value={this.state.observaciones?this.state.observaciones:""}  ref={(input) => { this.observacionesInput= input; }} intent={!this.state.observaciones?"danger":"primary"} onChange={cogerObservaciones} />
                      </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <p>FECHA PROPUESTA DE LA PRÓXIMA VALORACIÓN:</p>{" "}
                      </td>
                      <td>
                        <DateInput minDate={new Date(1900, 1 ,1)}
                          {...jsDateFormatter}
                          onChange={escogeFechaValoracion}
                          value={this.state.fechaProximaValoracion}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {this.state.riesgoSeleccionado && this.state.riesgoSeleccionado.numero && !this.state.crearNuevaHoja?
                <>
                  <div id="btn-formacion-informacion">
                    <Button id="btn-formacion" onClick={abrirFormacion}>Formación <span className="badge">{this.state.numFormacion}</span></Button>
				            <Button id="btn-informacion" onClick={abrirInformacion}>Información <span className="badge">{this.state.numInformacion}</span></Button>
                  </div>
                </>
              :
              <></>}
              <Tooltip content="Cerrar y volver." position="top">
                <Button onClick={cerrarModal}>Cerrar</Button>
              </Tooltip>
              <Tooltip content="Imprimir." position="top">
                  <ReactToPrint
                      trigger={() => <AnchorButton intent={Intent.DANGER} icon="print" text="Imprimir"/>}
                      content={() => this.ficha}
                  />
              </Tooltip>
              {this.state.existeDocumento && !this.state.crearNuevaHoja && (this.state.riesgoSeleccionado && !this.state.riesgoSeleccionado.version)?
                <Tooltip content={"Nueva hoja de seguimiento"} position="top">
                  <AnchorButton
                    icon="document"
                    intent={Intent.DANGER}
                    onClick={nuevaHoja}
                    id="btn-nueva-hoja"
                    className="bp3-minimal" 
                  >
                    Nuevo documento seguimiento
                  </AnchorButton>
               </Tooltip>  
              :<></>}
              
              <Tooltip content={"Guardar"} position="top">
                <AnchorButton
                  icon="floppy-disk"
                  intent={Intent.DANGER}
                  onClick={guardarCambios}
                  className="bp3-minimal"
                  disabled={
                    this.state.riesgoSeleccionado
                      ? this.state.riesgoSeleccionado.deshabilitado
                      : true
                  }
                >
                  Guardar
                </AnchorButton>
              </Tooltip>
            </div>
          </div>
        </Dialog>
		<Dialog
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="warning-sign"
			onClose={cerrarModal2}
			title="LexControl"
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			usePortal={true}
			isOpen={this.state.modalAdvertencia1Abierto}
		>
		<div className={Classes.DIALOG_BODY}>
			<p>
			  El documento seleccionado no es la última versión de la
        evaluación de este seguimiento. Se abrirá en modo de sólo lectura
        para su consulta exclusivamente.Para crear un nueva hoja de seguimiento 
        abra la última versión y haga clic en 'Nueva hoja'.
			</p>
		</div>
		<div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			<AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
				Aceptar
			</AnchorButton>
			</div>
		</div>
		</Dialog>
		<Dialog
		transitionDuration={400}
		autoFocus={true}
		enforceFocus={true}
		icon="warning-sign"
		onClose={cerrarModal2}
		title="LexControl"
		canEscapeKeyClose={true}
		canOutsideClickClose={true}
		isCloseButtonShown={true}
		usePortal={true}
		isOpen={this.state.modalAdvertencia2Abierto}
		>
		<div className={Classes.DIALOG_BODY}>
			<p>
			¡ATENCIÓN!
			<br />
			Esta modificación implicará la creación de un nuevo documento
      con el numero {this.state.numeroIdentificacionActual}/1
			<br />
			¿Desea continuar?
			</p>
		</div>
		<div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			<AnchorButton intent={Intent.PRIMARY} onClick={nuevaHoja}>
				SI
			</AnchorButton>
			<AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal3}>
				NO
			</AnchorButton>
			</div>
		</div>
		</Dialog>
		<Dialog
		transitionDuration={400}
		autoFocus={true}
		enforceFocus={true}
		icon="warning-sign"
		onClose={cerrarModal}
		title="LexControl"
		canEscapeKeyClose={true}
		canOutsideClickClose={true}
		isCloseButtonShown={true}
		usePortal={true}
		isOpen={this.state.elegirAccionFormativa}
		>
		<div className={Classes.DIALOG_BODY}>
		<p>Seleccione el procedimiento imperativo desedo y pulse "Aceptar". Si el procedimiento no está en la lista, pulse el botón "Nuevo procedimiento"  para crearlo</p>
			<Table className="acortarOtraTablaRebelde" defaultColumnWidth={400} fill={true} ref={(input) => { this.tablaImperativas= input; }} selectedRegions={this.state.regionImperativa} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarImperativo} numRows={this.state.imperativos.length}>
				<Column name="Procedimiento"  cellRenderer={cellRendererImperativo}/>
			</Table>
		</div>
		<div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			<AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevoImpertativo}>
				Nuevo procedimiento
			</AnchorButton>
			<AnchorButton intent={Intent.PRIMARY} onClick={anadirImperativo}>
				Aceptar
			</AnchorButton>
			<AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
				Cerrar
			</AnchorButton>
			</div>
		</div>
		</Dialog>
    <Dialog
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="warning-sign"
        onClose={cerrarModal2}
        title="LexControl"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={this.state.modalImperativos}
      >
        <div className={Classes.DIALOG_BODY}>
        <p>Seleccione el procedimiento imperativo desedo y pulse "Aceptar". Si el procedimiento no está en la lista, pulse el botón "Nuevo procedimiento"  para crearlo</p>
          <Table className="acortarOtraTablaRebelde" defaultColumnWidth={400} fill={true} ref={(input) => { this.tablaImperativas= input; }} selectedRegions={this.state.regionImperativa} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarImperativo} numRows={this.state.imperativos.length}>
              <Column name="Procedimiento"  cellRenderer={cellRendererImperativo}/>
          </Table>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevoImpertativo}>
              Nuevo procedimiento
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={anadirImperativo}>
              Aceptar
            </AnchorButton>
            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
              Cerrar
            </AnchorButton>
          </div>
        </div>
      </Dialog>
		<Dialog
      transitionDuration={400}
      autoFocus={true}
      enforceFocus={true}
      icon="warning-sign"
      onClose={cerrarModal2}
      title="LexControl"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isCloseButtonShown={true}
      usePortal={true}
      isOpen={this.state.modalRecomendaciones}
		>
		<div className={Classes.DIALOG_BODY}>
		<p>Seleccione el procedimiento recomendado desedo y pulse "Aceptar". Siel procedimiento no está en la lista, pulse el botón "Nuevo procedimiento para crearlo"</p>
			<Table className="acortarOtraTablaRebelde" defaultColumnWidth={400} fill={true} ref={(input) => { this.tablaRecomendaciones= input; }} selectedRegions={this.state.regionRecomendacion} onSelection={seleccionarRecomendacion} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.recomendaciones.length}>
				<Column name="Procedimiento"  cellRenderer={cellRendererRecomendaciones}/>
			</Table>
		</div>
		<div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			<AnchorButton intent={Intent.PRIMARY} onClick={abrirModalNuevaRecomendacion}>
				Nuevo procedimiento
			</AnchorButton>
			<AnchorButton intent={Intent.PRIMARY} onClick={anadirRecomendado}>
				Aceptar
			</AnchorButton>
			<AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
				Cerrar
			</AnchorButton>
			</div>
		</div>
		</Dialog>
		<Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="info-sign"
			onClose={cerrarModal}
			title="Crear nuevo procedimiento imperativo"
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			usePortal={true}
			isOpen={this.state.modalNuevoImperativo}
		>
			<div className={Classes.DIALOG_BODY}>
				<FormGroup
				label="Introduzca el nombre del procedimiento imperativo y pulse aceptar"
				labelFor="informacion"
				intent="danger"
				helperText={this.state.nuevoImperativo?"":"Debe insertar el procedimiento imperativo"}
				>
					<InputGroup id="texto-nombre-imperativo" onChange={handelNuevoImperativo}  placeholder="Escriba aqui el procedimiento imperativo" intent={this.state.nuevoImperativo?"primary":"danger"} />
				</FormGroup>

			</div>
			<div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<Tooltip content="Agregar imperativo." position="top">
					<AnchorButton
						intent={Intent.PRIMARY}
						onClick={anadirProcedimientoImperativo}
					>
						Aceptar
					</AnchorButton>
				</Tooltip>
				<Tooltip content="Cerrar y volver." position="top">
					<Button onClick={cerrarModal3}>Cancelar</Button>
				</Tooltip>
			</div>
		</div>
		</Dialog>

    <Dialog 
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal}
				title="Crear nuevo procedimiento recomendado"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.modalNuevaRecomendacion}
			>
				<div className={Classes.DIALOG_BODY}>
					<FormGroup
					label="Introduzca el nombre del procedimiento recomendado y pulse aceptar"
					labelFor="informacion"
					intent="danger"
					helperText={this.state.nuevaRecomendacion?"":"Debe insertar el procedimiento recomendado"}
					>
						<InputGroup id="texto-nombre-recomendado" onChange={handelNuevaRecomendacion}  placeholder="Escriba aqui el procedimiento recomendado" intent={this.state.nuevaRecomendacion?"primary":"danger"} />
					</FormGroup>

				</div>
				<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content="Agregar recomendacion." position="top">
						<AnchorButton
							intent={Intent.PRIMARY}
							onClick={anadirProcedimientoRecomendado}
						>
							Aceptar
						</AnchorButton>
					</Tooltip>
					<Tooltip content="Cerrar y volver." position="top">
						<Button onClick={cerrarModal3}>Cancelar</Button>
					</Tooltip>
				</div>
			</div>
		</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({elegirAccionFormativa:false})}}
				title="Selección de acción formativa"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
        usePortal={true}
        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
				isOpen={this.state.elegirAccionFormativa}
			>       

			<div className={Classes.DIALOG_BODY}>
        {this.props.editable===true && this.state.riesgoSeleccionado && !this.state.riesgoSeleccionado.deshabilitado?
          <p>Si desea crear una nueva acción formativa, <a href="#top"  id="nueva-formacion"  onClick={(e)=>{
              e.preventDefault()
              this.setState({crearAccionFormativa:true,formacionSeleccionada:null},()=>{this.tablaFormativas.clearSelection()})
          }}>Pulse aqui</a></p>
        :<></> }
	
				<p>Si desea editar una acción formativa existente, selecciónela y pulse aceptar.</p>
				<Table  defaultColumnWidth={200} ref={(input) => { this.tablaFormativas= input; }} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={handleSeleccionarFormativa} numRows={this.state.accionesFormativas.length}>
					<Column name="Nombre"  cellRenderer={cellRendererNombreFormativa}/>    
				</Table>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{
          if(!this.state.formacionSeleccionada){
            alert("Debe señeccionar una formación.")
            return
          }
          this.setState({crearAccionFormativa:true})
        }}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{
            this.setState({elegirAccionFormativa:false})
          }}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({crearAccionFormativa:false})}}
				title="Creación de acciones formativas"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
				isOpen={this.state.crearAccionFormativa}
				className="dialog-extra-ancho"
			>
			<div id="modal-crear-formativa" className={Classes.DIALOG_BODY}>
				<AccionesSeguimiento editable={this.props.editable} deshabilitado={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.deshabilitado:null} area={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.idArea:null} departamento={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.codDep:null} ref={(input) => { this.accionesSeguimiento= input; }} tipo="formacion" datos={this.state.formacionSeleccionada} />
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<AnchorButton disabled={this.props.editable===false || (this.state.riesgoSeleccionado && this.state.riesgoSeleccionado.deshabilitado) ?true:false} intent={Intent.PRIMARY} onClick={añadirNuevaFormativa}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({crearAccionFormativa:false})}}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({elegirAccionInformativa:false})}}
				title="Selección de acción informativa"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.elegirAccionInformativa}
			>
			<div className={Classes.DIALOG_BODY}>
				{this.props.editable===true && this.state.riesgoSeleccionado && !this.state.riesgoSeleccionado.deshabilitado?
				<p>Si desea crear una nueva acción informativa, <a href="#top" onClick={(e)=>{
					e.preventDefault()
					this.setState({crearAccionInformativa:true,informacionSeleccionada:null},()=>{this.tablaInformativas.clearSelection()})}
				}>Pulse aqui</a></p>
				:<></>}

				<p>Si desea editar una acción formativa existente, selecciónela y pulse aceptar.</p>
				<Table ref={(input) => { this.tablaInformativas= input; }}  defaultColumnWidth={200} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={handleSeleccionarInformativa} numRows={this.state.accionesInformativas.length}>
					<Column name="Nombre"  cellRenderer={cellRendererNombreInformativa}/>    
				</Table>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <AnchorButton intent={Intent.PRIMARY} onClick={()=>{
          if(!this.state.informacionSeleccionada){
            alert("Debe señeccionar una información.")
            return
          }
          this.setState({crearAccionInformativa:true})
        }}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({elegirAccionInformativa:false})}}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={()=>{this.setState({crearAccionInformativa:false})}}
				title="Creación de acciones informativas"
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.crearAccionInformativa}
				className="dialog-extra-ancho"
			>
			<div className={Classes.DIALOG_BODY}>
				<AccionesSeguimiento editable={this.props.editable} deshabilitado={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.deshabilitado:null} area={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.idArea:null} departamento={this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.codDep:null} ref={(input) => { this.accionesSeguimiento= input; }} tipo="informacion" datos={this.state.informacionSeleccionada} />
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<AnchorButton disabled={this.props.editable===false || (this.state.riesgoSeleccionado && this.state.riesgoSeleccionado.deshabilitado) ?true:false} intent={Intent.PRIMARY} onClick={añadirNuevaInformativa}>
					Aceptar
				</AnchorButton>
				<AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({crearAccionInformativa:false})}}>
					Cancelar
				</AnchorButton>
				</div>
			</div>
			</Dialog>

			<div className="pixelAlrededor">
				<Card interactive={true} id="arbol-seguimiento" elevation={Elevation.TWO}>
				<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">CONTROL Y SEGUIMIENTO DE RIESGOS</Callout>
					<Tooltip content="Replegar arbol." position={Position.TOP} ><Button icon="exclude-row" onClick={()=>replegarArbol(this.state.arbol)}/></Tooltip>
					<Tooltip content="Desplegar arbol." position={Position.TOP} ><Button icon="add-column-right" onClick={()=>desplegarArbol(this.state.arbol)}/></Tooltip>
					<Tooltip content="Ver leyenda." position={Position.TOP} ><Button icon="info-sign" onClick={()=>this.setState({ leyendaAbierta: !this.state.leyendaAbierta })}/></Tooltip>
					<Tree
            contents={this.state.arbol}
            onNodeDoubleClick={this.handleNodeClick}
            onNodeCollapse={handleNodeCollapse}
            onNodeExpand={handleNodeExpand}
					/>
				</Card>
			</div>
        </>  
	}
        
          
        </>
      );
    }
  }
}

export default withApollo(Seguimiento)
