import React from "react"

import { Card, Elevation } from "@blueprintjs/core"

import PartesInteresadas1 from "../Assets/PARTES-INTERESADAS-1.png"

export default class PartesInteresadas extends React.Component {

    render(){
        
        document.title ="LexControl | Partes interesadas"

        return (
            <Card interactive={true} elevation={Elevation.TWO} className="bp3-running-text">
                <img src={PartesInteresadas1} className="imagenCentrada"  alt="Partes interesadas"/>
            </Card>
        )
    }
}