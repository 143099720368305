import React , { Component } from "react"
import {TextArea} from "@blueprintjs/core"

export default class DatosActa extends Component{

    state={
        resumen:''
    }
    
    componentDidMount=()=>{
        if(this.props.acta && Object.keys(this.props.acta).length >0){
            this.setState({resumen:this.props.acta.RESUMEN?this.props.acta.RESUMEN:''})
        }
    }

    render(){

        const cambiarResumen=(ev)=>{
            this.setState({resumen:ev.currentTarget.value})
        }

        return(
            <TextArea id="textarea-resumen" fill="true" value={this.state.resumen} onChange={cambiarResumen} />
        )
    }
}