import React from "react"
import CapitalSocial from "../Pantallas/CapitalSocial"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class EstructuraSocietaria extends React.Component {
    render(){
        return (
            <>
                <CapitalSocial editable={this.props.editable} i18next={i18next}/>
            </>
        )
    }
}

export default withTranslation()(EstructuraSocietaria);