import React from "react"
import { Card, Elevation, TextArea } from "@blueprintjs/core"

export default class TextoActas extends React.Component {
    state={
        texto:''
    }

    componentDidMount=()=>{
        if(this.props.acuerdo && Object.keys(this.props.acuerdo).length>0){

            this.setState({
                texto:this.props.acuerdo.TEXTO,
            })

        }
    }

    render(){

        const cambiarTexto=(ev)=>{
            this.setState({texto:ev.currentTarget.value})
        }

        return(
            <>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <TextArea id="texto-actas" fill={true} intent={!this.state.texto?"danger":"primary"} onChange={cambiarTexto} value={this.state.texto} />
                </Card>
            </>
        )
    }
}