import React from "react"
import { Switch, Card, Elevation, FormGroup, NumericInput, Alignment } from "@blueprintjs/core";

export default class DatosAcuerdo extends React.Component {

    state = { 
        favor: 0, 
        porcFavor:0.0,
        contra: 0, 
        porcContra:0.0,
        abstencion: 0,
        porcAbstencion:0.0,
        numAcuerdo:0,
        inscripcionRegistro:false,
    }

    calcularVotos=(votos)=>{
        let votosFavor=votos.filter(voto=>{return voto.voto===1})
        let votosContra=votos.filter(voto=>{return voto.voto===-1})
        let votosAbstencion=votos.filter(voto=>{return voto.voto===0})
        let total=votosFavor.length+votosContra.length+votosAbstencion.length
        let porcentajeFavor= total>0?Math.round((votosFavor.length / total)*100):0;
        let porcentajeContra=total>0?Math.round((votosContra.length/ total)*100):0;
        let porcentajeAbstencion=total>0?Math.round((votosAbstencion.length / total)*100):0;

        this.setState({
            favor:votosFavor.length,
            contra:votosContra.length,
            abstencion:votosAbstencion.length,
            porcAbstencion:porcentajeAbstencion,
            porcFavor:porcentajeFavor,
            porcContra:porcentajeContra
        })
    }

    componentDidMount = () => {
        //si estamos en edicion...
        if(this.props.acuerdo && Object.keys(this.props.acuerdo).length>0){

            this.setState({
                numAcuerdo:this.props.acuerdo.NUMERO,
                inscripcionRegistro:this.props.acuerdo.INSCRIBIBLE_RM
            })

            this.calcularVotos(this.props.acuerdo.votos)
        


        }else{
              console.log(this.props.acta.asistentes)
            this.setState({
                numAcuerdo:this.props.numeroAcuerdo,
                abstencion:this.props.acta?this.props.acta.asistentes.length:0,
                porcAbstencion:this.props.acta?this.props.acta.asistentes.length>0?100:0:0
            })
        }

    }
    
    componentDidUpdate=(props)=>{

        if(this.props!==props){
            this.calcularVotos(this.props.votos)
        }

    }

    
    render(){


        return(
            <>
                <Card interactive={true} elevation={Elevation.TWO}>

                <FormGroup
                    label={this.props.i18next.t("actas.nrodelacuerdo",{ns:"page"})+":"}
                    labelFor="numero-acuerdo"
                    inline={true}
                >
                    <NumericInput id="numero-acuerdo" disabled fill={true} value={this.state.numAcuerdo} />
                </FormGroup>
                    <Switch id="boleano-registro-mercantil" alignIndicator={Alignment.RIGHT} checked={this.state.inscripcionRegistro} onChange={() => this.setState({ inscripcionRegistro: !this.state.inscripcionRegistro })} label={this.props.i18next.t("actas.questioninscribir",{ns:"page"})} innerLabel={this.props.i18next.t("no",{ns:"global"})} innerLabelChecked={this.props.i18next.t("yes",{ns:"global"})} />
                    <div id="votacion-acuerdo">
                        <p><span>{this.props.i18next.t("actas.votosfavor",{ns:"page"})}: </span><span> {this.state.favor} </span><span> {`(${this.state.porcFavor}%)`} </span></p>
                        <p><span>{this.props.i18next.t("actas.votosencontra",{ns:"page"})}: </span><span> {this.state.contra}</span><span> {`(${this.state.porcContra}%)`} </span></p>
                        <p><span>{this.props.i18next.t("actas.abstenciones",{ns:"page"})}: </span><span> {this.state.abstencion} </span><span>{`(${this.state.porcAbstencion}%)`} </span></p>
                    </div>
                </Card>
            </>
        )
    }
}