import React, {Component} from 'react';
import { FormGroup,
    InputGroup,
    Spinner,
    TextArea
} from "@blueprintjs/core";
import { Rating } from 'react-simple-star-rating';

class ExtraContrato extends Component {
    state={
        descripcion:'',
        plazo:'',
        cumplimiento:0,
        cargando:false,
        tooltipArray:[
            "1 pleno",
            "2 pleno",
            "3 pleno",
            "4 pleno",
            "5 pleno"
          ]
    }

    componentDidMount=async()=>{
        this.setState({cargando:true}, async () => {
            if(this.props.info && Object.keys(this.props.info).length >0){
                this.setState({
                    descripcion:this.props.info.DESCRIPCION,
                    plazo:this.props.info.PLAZO,
                    cumplimiento:this.props.info.CUMPLIMIENTO
                })
            }
            this.setState({cargando:false})
        });
        
    }
    render() {
        const changeCumplimiento = (newRating) => {
            this.setState({ cumplimiento: newRating })
        }
        const handleDescripcion = (e) => {
            this.setState({ descripcion: e.target.value })
        }
        const handlePlazo = (e) => {
            this.setState({ plazo: e.target.value })
        }
        return (
            <>
            {this.state.cargando?
                <Spinner/>
            :
            <>
                <div id="dialogo-extra-contrato" className="bp3-card separarBordes">
                    <FormGroup
                        label="Descripción:"
                        labelFor="descripcion"
                    >
                        <TextArea fill={true} onChange={handleDescripcion} value={this.state.descripcion} />
                    </FormGroup>
                    <FormGroup
                        label="Plazo:"
                        labelFor="plazo"
                    >
                        <InputGroup id="plazo" value={this.state.plazo} onChange={handlePlazo} intent={this.state.plazo?"primary":"danger"} />   
                    </FormGroup>
                    <FormGroup
                        label="Cumplimiento:"
                        labelFor="cumplimiento"
                    >
                        <Rating
                            initialValue={this.state.cumplimiento}
                            onClick={changeCumplimiento}
                            showTooltip
                            tooltipArray={this.state.tooltipArray}
                        />
                    </FormGroup>
                </div>
            </>
            }
            </>
        );
    }
}

export default ExtraContrato;