/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import {Spinner, Callout, Navbar, ButtonGroup, Checkbox,Alignment, Button, Dialog, FormGroup, InputGroup, Elevation, Classes, Tooltip, AnchorButton, Intent, Card } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import moment from 'moment';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });


const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});
class JointVentures extends Component {

    state={
        modalAbierto: false,
        proyectos: [
        ],
        personas: [

        ],
        personasSeleccionadas:[],
        nombreProyecto:null,
        bolFechaVigencia:true,
        fechaInicio:new Date(),
        fechaFin:new Date(),
        proyectoSeleccionado:null,
        imprimirActivado:false,
        modalImprimir:false,
        datosEmpresa: null,
        personasJuridicas:null,
        cargandoJV:false,
        cargandoImprimir:false,
        cargandoGrid:false
    }

    cargarJoints=async()=>{
        //obtencion de los proyectos
        let proyectos=await client
        .query({
            query: gql`
                {jointVentures{ID_JOINT_VENTURE,NOMBRE_PROYECTO,VIGENCIA_INICIO,VIGENCIA_FIN}}
            `
        }).then(result=>{ 
            return result.data.jointVentures
        })

        let miembros=await client
            .query({
                query: gql`
                    {jointVentureEmpresas{ID_JOINT_VENTURE,ID_EMPRESA}}
                `
            }).then(result=>{ 
                return result.data.jointVentureEmpresas
            })

        //obtencion de los miembros
        proyectos.forEach(async proyecto=>{
            let miembrosValue=[];
            miembros.forEach(async(miembro)=>{
                if(miembro.ID_JOINT_VENTURE===proyecto.ID_JOINT_VENTURE){
                    this.state.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===miembro.ID_EMPRESA){
                            miembro.label=persona.RAZON_SOCIAL
                            miembro.value=persona.ID_PERSONA
                            miembrosValue.push(miembro)
                        }
                    })
                }
                // await client
                // .query({
                //     query: gql`
                //         {personasJuridicas(FILTROS:{ID_PERSONA:${miembro.ID_EMPRESA}}){RAZON_SOCIAL}}
                //     `
                // }).then(res=>{ 
                //     miembros[index].label=res.data.personasJuridicas[0].RAZON_SOCIAL
                //     miembros[index].value=miembro.ID_EMPRESA
                // })
            })

            proyecto.miembros=miembrosValue
        })
        this.setState({proyectos:proyectos})
        //console.log(this.state.proyectos)
    }

    cargarPersonasJuridicas= async () => {
        let personas=[];
        let personasJuridicas= await client
        .query({
            query: gql`
               {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
       this.setState({
           personasJuridicas: personasJuridicas
       }, async () =>{
           await this.state.personasJuridicas.filter(persona=>{
                personas.push({value:persona.ID_PERSONA,label:persona.RAZON_SOCIAL})
           })
           this.setState({
               personas: personas
           })
           console.log(this.state.personas)
       })
       console.log(this.state.personasJuridicas)
    }
    CargarDatosEmpresa= async() => {
        let datosEmpresa= await client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }

    componentDidMount(){
        this.setState({cargandoJV:true
        }, async () => {
            await this.cargarPersonasJuridicas()
            await this.cargarJoints()
            await this.obtenerLogoEmpresa()
             this.setState({cargandoJV:false})
        }) 
        
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
        await client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarDatos= async () =>{
        let datosEmpresa= await this.CargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa: datosEmpresa
        })
    }

    
    generarImprimirJV= async() => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir: true,
            cargandoGrid:true
        }, async () => {
            if(this.state.datosEmpresa==null){
                await this.cargarDatos();
                ReactDOM.render(this.ImprimirJV(), document.getElementById('generarJV-pdf'))
            }else{
                this.setState({
                    cargandoImprimir:false
                }, () => {
                    ReactDOM.render(this.ImprimirJV(), document.getElementById('generarJV-pdf'))
                })
            }
        })
    }

    ImprimirJV=() => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("jointventures.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("jointventures.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.proyectos?this.state.proyectos.map((proyecto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'33%'}}>{i18next.t("jointventures.name",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'33%'}}>{i18next.t("jointventures.fechainicio",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'34%'}}>{i18next.t("jointventures.fechafin",{ns:"page"}).toUpperCase()}</Text>                                     
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'33%'}}>{proyecto.NOMBRE_PROYECTO?proyecto.NOMBRE_PROYECTO:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'33%'}}>{proyecto.VIGENCIA_INICIO?moment(proyecto.VIGENCIA_INICIO).format('DD/MM/YYYY'):''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'34%'}}>{proyecto.VIGENCIA_FIN?moment(proyecto.VIGENCIA_FIN).format('DD/MM/YYYY'):''}</Text>
                                </View>                               
                           
                        </View>
                        )
                    }):<></>}      
            </View>

            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("jointventures.title",{ns:"page"})

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalImprimir:false,
                imprimirActivado:false,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalAbierto: true,
                fechaInicio:new Date(),
                fechaFin:new Date(),
                bolFechaVigencia:true,
                nombreProyecto:null,
                personasSeleccionadas:[],
                proyectoSeleccionado:null,
                cargandoGrid:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.proyectoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalImprimir:false,
                imprimirActivado:false,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalAbierto: true,
                fechaInicio:new Date(this.state.proyectoSeleccionado.VIGENCIA_INICIO),
                fechaFin:this.state.proyectoSeleccionado.VIGENCIA_FIN?new Date(this.state.proyectoSeleccionado.VIGENCIA_FIN):null,
                bolFechaVigencia:this.state.proyectoSeleccionado.VIGENCIA_FIN,
                nombreProyecto:this.state.proyectoSeleccionado.NOMBRE_PROYECTO,
                personasSeleccionadas:this.state.proyectoSeleccionado.miembros,
                cargandoGrid:true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.proyectoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalImprimir:false,
                imprimirActivado:false,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
            })
        }

        function dateFormatter(cell, row) {
            let fecha=(cell)?moment(cell).format("DD/MM/YYYY"):'';
            return (
                fecha
            );
        }
        
        const columns = [
        {
        dataField: 'NOMBRE_PROYECTO',
        text: i18next.t("jointventures.name",{ns:"page"})
        },{
            dataField: 'VIGENCIA_INICIO',
            text: i18next.t("jointventures.fechainicio",{ns:"page"}),
            formatter: dateFormatter
            },{
                dataField: 'VIGENCIA_FIN',
                text: i18next.t("jointventures.fechafin",{ns:"page"}),
                formatter: dateFormatter
                }
        ];
        
        const escogeFechaInicio = (nuevaFecha) => {
            this.setState({fechaInicio: nuevaFecha})
        }

        const escogeFechaFin = (nuevaFecha) => {
            this.setState({fechaFin: nuevaFecha})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false
            })
        }

        const añadirNuevo = async(value) => {
            
            if(!this.state.nombreProyecto)return
            if(this.state.personasSeleccionadas.length===0)return
            if(this.state.personasSeleccionadas.length<2){
                alert(i18next.t("jointventures.valid",{ns:"page"}))
                return
            }

            //mutation para crear el proyecto
            await client.mutate({
                mutation:gql`mutation{crearJointVenture(VALORES:{NOMBRE_PROYECTO:"${this.state.nombreProyecto}",VIGENCIA_INICIO:"${moment(this.state.fechaInicio).format("YYYY-MM-DD")}",VIGENCIA_FIN:${this.state.bolFechaVigencia?'"'+moment(this.state.fechaFin).format("YYYY-MM-DD")+'"':null}}){ID_JOINT_VENTURE}}`

            }).then(async result=>{
              
                alert(i18next.t("insertcorrecto",{ns:"global"}))

                //insercion de los miembros seleccionados
                this.state.personasSeleccionadas.forEach(async persona=>{
                    await client.mutate({
                        mutation:gql`mutation{crearJointVentureEmpresa(VALORES:{ID_JOINT_VENTURE:${result.data.crearJointVenture.ID_JOINT_VENTURE},ID_EMPRESA:${persona.value}}){ID_JOINT_VENTURE}}`
        
                    })
                })

                await this.cargarJoints()

                 
                cerrarModal()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const editarJoint=async()=>{

            if(!this.state.nombreProyecto)return
            if(this.state.personasSeleccionadas.length===0)return
            if(this.state.personasSeleccionadas.length<2){
                alert(i18next.t("jointventures.valid",{ns:"page"}))
                return
            }

            //mutation para actualizar el proyecto
            await client.mutate({
                mutation:gql`mutation{actualizarJointVenture(ID_JOINT_VENTURE:${this.state.proyectoSeleccionado.ID_JOINT_VENTURE},VALORES:{NOMBRE_PROYECTO:"${this.state.nombreProyecto}",VIGENCIA_INICIO:"${moment(this.state.fechaInicio).format("YYYY-MM-DD")}",VIGENCIA_FIN:${this.state.bolFechaVigencia?'"'+moment(this.state.fechaFin).format("YYYY-MM-DD")+'"':null}}){ID_JOINT_VENTURE}}`

            }).then(async result=>{
             
                alert(i18next.t("insertcorrecto",{ns:"global"}))

                //eliminamos los antiguos miembros
                await client.mutate({
                    mutation:gql`mutation{eliminarJointVentureEmpresa(ID_JOINT_VENTURE:${this.state.proyectoSeleccionado.ID_JOINT_VENTURE}){ID_JOINT_VENTURE}}`
    
                })
                //insercion de los miembros seleccionados
                this.state.personasSeleccionadas.forEach(async persona=>{
                    await client.mutate({
                        mutation:gql`mutation{crearJointVentureEmpresa(VALORES:{ID_JOINT_VENTURE:${this.state.proyectoSeleccionado.ID_JOINT_VENTURE},ID_EMPRESA:${persona.value}}){ID_JOINT_VENTURE}}`
        
                    })
                })
                
                await this.cargarJoints()

                
                cerrarModal()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const eliminarProyecto=async()=>{

            //eliminamos los miembros
            await client.mutate({
                mutation:gql`mutation{eliminarJointVentureEmpresa(ID_JOINT_VENTURE:${this.state.proyectoSeleccionado.ID_JOINT_VENTURE}){ID_JOINT_VENTURE}}`

            })

            //eliminamos el joint venture
            await client.mutate({
                mutation:gql`mutation{eliminarJointVenture(ID_JOINT_VENTURE:${this.state.proyectoSeleccionado.ID_JOINT_VENTURE}){ID_JOINT_VENTURE}}`

            }).then(async result=>{
                
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({
                    proyectoSeleccionado:null,
                })
                
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })

            await this.cargarJoints()
            cerrarModal()
            
        }

        const handleEnabledChange = () => {
            let valor=!this.state.bolFechaVigencia
            this.setState({bolFechaVigencia: !this.state.bolFechaVigencia})
            if(valor){
                this.setState({fechaFin:new Date()})
            }else{ 
                this.setState({fechaFin:null})
            }
        }

        const cambiarProyecto = (ev) => {
            this.setState({nombreProyecto: ev.currentTarget.value})
        }

        const seleccionarPersonas=(value)=>{
            this.setState({personasSeleccionadas:value})
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        proyectoSeleccionado: row
        });
        }
        return true;
        }        

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        return (
            <>
                {this.state.cargandoJV===true?
                    <Cargando/>
                :
                <>
                     <Dialog
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialog}
                    >
                         {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarJV-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                    </Dialog>               
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={i18next.t("jointventures.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >            
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <FormGroup
                            label={i18next.t("jointventures.name",{ns:"page"})+":"}
                            labelFor="nombre-proyecto"
                            intent="danger"
                            helperText={!this.state.nombreProyecto?i18next.t("emptyfield",{ns:"global"})+i18next.t("jointventures.name",{ns:"page"}):""}
                        >
                    <InputGroup id="nombre-proyecto" className="pixelAlrededor" onChange={cambiarProyecto} intent={!this.state.nombreProyecto?"danger":"primary"} value={this.state.nombreProyecto} fill={true} />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("jointventures.fechainicio",{ns:"page"})+":"}
                        labelFor="fecha-inicio"
                        inline={true}
                        className="pixelAlrededor" 
                    >
                        <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter} 
                            onChange={escogeFechaInicio}
                            align={Alignment.RIGHT}
                            value={this.state.fechaInicio}
                        />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("jointventures.fechafin",{ns:"page"})}
                        labelFor="fecha-fin"
                        inline={true}
                        className="pixelAlrededor" 
                    >
                        <Checkbox checked={this.state.bolFechaVigencia} onChange={handleEnabledChange} />
                        <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter} 
                            onChange={escogeFechaFin}
                            align={Alignment.RIGHT}
                            disabled={!this.state.bolFechaVigencia}
                            value={this.state.bolFechaVigencia?this.state.fechaFin:null}

                        />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("jointventures.personasjuridicas",{ns:"page"})+":"}
                        labelFor="personas"
                        className="pixelAlrededor" 
                        intent="danger"
                        helperText={this.state.personasSeleccionadas.length===0?i18next.t("selectempty",{ns:"global"})+i18next.t("jointventures.personasjuridicas",{ns:"page"}):null}
                    >
                        <div style={this.state.personasSeleccionadas.length===0?{border:"1px solid red"}:{}}>
                            <Select
                                isMulti
                                options={this.state.personas}
                                className="pixelAlrededor"
                                backspaceRemovesValue={true}
                                onChange={seleccionarPersonas}
                                isClearable={true}
                                value={this.state.personasSeleccionadas}
                            />
                        </div>
                    </FormGroup>
 
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:editarJoint}
                                >
                                    {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}  
                                   
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Card>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("jointventures.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarProyecto}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededorGrande">
                    <div className="pixelAlrededor tablaSesenta">
                        <Card interactive={true} elevation={Elevation.TWO} >
                        {this.state.cargandoGrid?
                        <Cargando />
                        :
                        <>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={() => this.generarImprimirJV()}/>
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                                        <BootstrapTable
                                        keyField='ID_JOINT_VENTURE'
                                        data={ this.state.proyectos }
                                        columns={ columns }
                                        selectRow={ const_table.selectRow(handleOnSelect) }
                                        pagination={ paginationFactory(const_table.options) }
                                        headerClasses="header-class"
                                        rowClasses="row-class"
                                        hover
                                        condensed
                                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                        />
                        </>
                        }
                        </Card>
                    </div>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("jointventures.titlearticulo",{ns:"page"})}</Callout>
                            <br />
                            <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&tn=5&bj=art1255#", "_blank")}>{i18next.t("jointventures.articulo",{ns:"page"})}</AnchorButton>
                            <br />
                        </Card>
                    </div>
                </div>
            </>
            }
           </> 
        )
    }
}

export default withTranslation()(JointVentures);