import React from "react"
import { Card, Elevation } from "@blueprintjs/core"
import Documento1 from "../../Assets/RIESGOS D&O Y CIBERNETICOS.pdf"
import Documento2 from "../../Assets/Ejemplos de reclamaciones D&O.pdf"
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class InformacionPolizasSeguro extends React.Component {
    render(){
        //<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">LEY DE SOCIEDADES DE CAPITAL <br /> REGLAMENTO DEL REGISTRO MERCANTIL </Callout>
        //            <br></br>
        return(
            <> 
                <Card interactive={true} elevation={Elevation.TWO} >
                    
                   <p><a role="button" target="_blank"  rel="noopener noreferrer" href={Documento1} className="bp3-button bp3-icon-git-repo">{i18next.t("programaseguros.inforiesgo",{ns:"page"})}</a></p>
                   <p><a role="button" target="_blank"  rel="noopener noreferrer" href={Documento2} className="bp3-button bp3-icon-git-repo">{i18next.t("programaseguros.infosiniestro",{ns:"page"})}</a></p>
                </Card>
            </>
        )
    }
}

export default withTranslation()(InformacionPolizasSeguro)