/* eslint-disable no-useless-escape */

import React, { Component } from 'react';
import { Alignment, Button, Dialog, FormGroup, InputGroup, Classes, Tooltip, AnchorButton, Intent, Spinner } from "@blueprintjs/core"
import Select from "react-select"
import { DateInput } from "@blueprintjs/datetime";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});
const today = new Date();

class PolizasSeguro extends Component {

    state={
        modalNuevoAbierto: false,
        seguros: [
        ],
        tiposSeguro: [

        ],
        aseguradoras: [

        ],
        editorState: 
        {
            options: ['inline', 'fontSize', 'list', 'textAlign','history'],
        },
        toolbarState: 
        {
            options: ['inline', 'fontSize', 'list', 'textAlign','history'],
            inline: {
                options: ['bold', 'italic', 'underline'],
            },
        },
        fileName: '',
        contentState: null,
        nuevaAseguradora:null,
        modalNuevaAseguradora:null,
        aseguradoraSeleccionada:null,
        tipoSeguroSeleccionado:null,
        nuevoSeguro:null,
        modalNuevoSeguro:false,
        numeroPoliza:null,
        inicioSeguro:new Date(),
        finSeguro:new Date(),
        capitalAsegurado:0.00,
        capitalAseguradoValido:false
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    }

    onContentStateChange = (contentState) => {
        this.setState({
          contentState,
        });
    }

    cargarAseguradoras=async ()=>{

        client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });

        await client
        .query({
            query: gql`
            {aseguradoras{
                ID_ASEGURADORA,
                DESCRIPCION,
            }}
            `
        }).then(async result=>{ 

            let aseguradoras=[]
            result.data.aseguradoras.forEach(aseguradora=>{
                aseguradoras.push({"value":aseguradora.ID_ASEGURADORA,"label":aseguradora.DESCRIPCION})
            })
            this.setState({aseguradoras:aseguradoras})

            if(this.props.DatosPoliza && Object.keys(this.props.DatosPoliza).length>0){

                let asegSel=this.state.aseguradoras.find(aseguradora=>{
                    return aseguradora.value===this.props.DatosPoliza.idAseguradora
                })
            
                this.setState({aseguradoraSeleccionada:asegSel})
            }

        })

    }

    cargarTiposSeguro=async ()=>{

        client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });

        await client
        .query({
            query: gql`
            {tipoSeguros{
                ID_TIPO_SEGURO,
                DESCRIPCION,
            }}
            `
        }).then(async result=>{ 

            let tiposSeguro=[]
            result.data.tipoSeguros.forEach(tipo=>{
                tiposSeguro.push({"value":tipo.ID_TIPO_SEGURO,"label":tipo.DESCRIPCION})
            })
            this.setState({tiposSeguro:tiposSeguro})

            if(this.props.DatosPoliza && Object.keys(this.props.DatosPoliza).length>0){

                let tipoSel=this.state.tiposSeguro.find(tipo=>{
                    return tipo.value===this.props.DatosPoliza.idTipoSeguro
                })
            
                this.setState({tipoSeguroSeleccionado:tipoSel})
            }

        })

    }

    componentDidMount(props){

        this.cargarAseguradoras()
        this.cargarTiposSeguro()

        
        if(this.props.DatosPoliza && Object.keys(this.props.DatosPoliza).length>0){
            this.setState({
                numeroPoliza:this.props.DatosPoliza.numeroPoliza,
                capitalAsegurado:Intl.NumberFormat('es-ES').format(this.props.DatosPoliza.capital),
                capitalAseguradoValido:true,
                inicioSeguro:new Date(this.props.DatosPoliza.fechaDesde),
                finSeguro:new Date(this.props.DatosPoliza.fechaHasta),
                
            })
        }
    }

   
    render() {


        const abrirModalAseguradora = () => {
            this.setState({nuevaAseguradora: null})
            this.setState({modalNuevaAseguradora: true})
        }

        const abrirModalSeguros = () => {
            this.setState({nuevoSeguro: null})
            this.setState({modalNuevoSeguro: true})
        }
        
        
        const handleNuevaAseguradora = (e) => {
            this.setState({nuevaAseguradora:e.currentTarget.value})
        }

        const handleNuevoTipo = (e) => {
            this.setState({nuevoSeguro:e.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalNuevaAseguradora: false,
                modalNuevoSeguro:false
            })
        }

        const escogeFechaInicio = (nuevaFecha) => {
            this.setState({inicioSeguro: nuevaFecha})
        }

        const escogeFechaFin = (nuevaFecha) => {
            this.setState({finSeguro: nuevaFecha})
        }

        
        const anadirAseguradora=async (ev)=>{

            if(!this.state.nuevaAseguradora)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.aseguradoras.find(aseguradora=>{
                return aseguradora.label===this.state.nuevaAseguradora
            })

            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos la estado instruccion.
            await client.mutate({
                mutation: gql`
                  mutation{crearAseguradora(VALORES:{DESCRIPCION:"${this.state.nuevaAseguradora}"}){ID_ASEGURADORA}}
                `
            }).then(async result=>{
             
                await this.cargarAseguradoras()

                let aseguradoraSel=this.state.aseguradoras.find(aseguradora=>{
                    return aseguradora.value===result.data.crearAseguradora.ID_ASEGURADORA
                })
                this.setState({aseguradoraSeleccionada:aseguradoraSel})
                cerrarModal2()
                

            }).catch(err=>{
                console.log(err)
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }


        const anadirTipoSeguro=async (ev)=>{

            if(!this.state.nuevoSeguro)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.tiposSeguro.find(seguro=>{
                return seguro.label===this.state.nuevoSeguro
            })

            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos la estado instruccion.
            await client.mutate({
                mutation: gql`
                  mutation{crearTipoSeguro(VALORES:{DESCRIPCION:"${this.state.nuevoSeguro}"}){ID_TIPO_SEGURO}}
                `
            }).then(async result=>{
             
                await this.cargarTiposSeguro()

                let tipoSel=this.state.tiposSeguro.find(tipo=>{
                    return tipo.value===result.data.crearTipoSeguro.ID_TIPO_SEGURO
                })

                this.setState({tipoSeguroSeleccionado:tipoSel})
                cerrarModal2()
                
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })


        }


        const cambiarAseguradora = (value) => {
            this.setState({aseguradoraSeleccionada:value})
        }

        const cambiarSeguro = (value) => {
            this.setState({tipoSeguroSeleccionado:value})
        }

        const cambiarNumeroPoliza = (ev) => {
            this.setState({numeroPoliza:ev.currentTarget.value})
        }

        
        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }
    
        const cambiarCapitalAsegurado = (ev) => {
            
            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({capitalAseguradoValido:true})
            }else{
                this.setState({capitalAseguradoValido:false})
            }

            this.setState({capitalAsegurado:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
        }

        const onFileChange = event => {
    
          // Update the state
          this.setState({ fileName: event.target.files[0].name });
        
        };
        const abrirSubida = () => {
            this.refs.fileUploader.click()
        }
        
        return (
            <>
            {this.props.loading && <Spinner/>}
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("programaseguros.addaseguradora",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevaAseguradora}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={this.props.i18next.t("programaseguros.aseguradora",{ns:"page"})}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevaAseguradora?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("programaseguros.aseguradora",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-aseguradora" onChange={handleNuevaAseguradora}  placeholder={this.props.i18next.t("enterfield",{ns:"global"})+this.props.i18next.t("programaseguros.aseguradora",{ns:"page"})} intent={this.state.nuevaAseguradora?"primary":"danger"} />
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirAseguradora}
                            >
                                {this.props.i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("programaseguros.addtiposeguro",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoSeguro}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={this.props.i18next.t("programaseguros.tiposeguro",{ns:"page"})}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoSeguro?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("programaseguros.tiposeguro",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-aseguradora" onChange={handleNuevoTipo}  placeholder={this.props.i18next.t("enterfield",{ns:"global"})+this.props.i18next.t("programaseguros.tiposeguro",{ns:"page"})} intent={this.state.nuevoSeguro?"primary":"danger"} />
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirTipoSeguro}
                            >
                                {this.props.i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <FormGroup
                    label={this.props.i18next.t("programaseguros.aseguradora",{ns:"page"})}
                    labelFor="compañia"
                    intent="danger"
                    helperText={this.state.aseguradoraSeleccionada?"":this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("programaseguros.aseguradora",{ns:"page"})}
                >
                    <div style={this.state.aseguradoraSeleccionada?{}:{border:"1px solid red"}}>
                        <Select
                            options={this.state.aseguradoras}
                            className="pixelAlrededor"
                            onChange={cambiarAseguradora}
                            value={this.state.aseguradoras.filter(aseguradora => aseguradora.value === (this.state.aseguradoraSeleccionada?this.state.aseguradoraSeleccionada.value:0))}

                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Tooltip content={this.props.i18next.t("programaseguros.addaseguradora",{ns:"page"})}>
                        <Button icon="add" onClick={abrirModalAseguradora} />
                    </Tooltip>
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("programaseguros.tiposeguro",{ns:"page"})}
                    labelFor="tipo-seguro"
                    intent="danger"
                    helperText={this.state.tipoSeguroSeleccionado?"":this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("programaseguros.tiposeguro",{ns:"page"})}
                >
                    <div style={this.state.tipoSeguroSeleccionado?{}:{border:"1px solid red"}}>
                        <Select
                            options={this.state.tiposSeguro}
                            className="pixelAlrededor"
                            onChange={cambiarSeguro}
                            value={this.state.tiposSeguro.filter(seguro => seguro.value === (this.state.tipoSeguroSeleccionado?this.state.tipoSeguroSeleccionado.value:0))}
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Tooltip content={this.props.i18next.t("programaseguros.addtiposeguro",{ns:"page"})}>
                        <Button icon="add" onClick={abrirModalSeguros} />
                    </Tooltip>
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("programaseguros.numeropoliza",{ns:"page"})}
                    labelFor="numero-poliza"
                    intent="danger"
                    helperText={this.state.numeroPoliza?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("programaseguros.numeropoliza",{ns:"page"})}
                >
                    <InputGroup intent={this.state.numeroPoliza?"primary":"danger"}  id="numero-poliza" className="pixelAlrededor" onChange={cambiarNumeroPoliza} value={this.state.numeroPoliza?this.state.numeroPoliza:''} fill={true} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("programaseguros.capital",{ns:"page"})}
                    labelFor="capital-asegurado"
                    intent="danger"
                    helperText={this.state.capitalAsegurado?(this.state.capitalAseguradoValido?"":this.props.i18next.t("programaseguros.validcapital",{ns:"page"})):this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("programaseguros.capital",{ns:"page"})}

                >
                    <InputGroup id="capital-asegurado" type="text"  intent={this.state.capitalAsegurado && this.state.capitalAseguradoValido?"primary":"danger"} className="pixelAlrededor" onChange={cambiarCapitalAsegurado} value={this.state.capitalAsegurado?this.state.capitalAsegurado:''} fill={true} />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("programaseguros.fechadesde",{ns:"page"})}
                    labelFor="fecha-inicio"
                    inline={true}
                    className="pixelAlrededor" 
                >
                    <DateInput minDate={new Date(1900, 1 ,1)}
                        maxDate={new Date(today.getFullYear()+10, 11 ,31)}
                        {...jsDateFormatter} 
                        onChange={escogeFechaInicio}
                        value={this.state.inicioSeguro}
                        align={Alignment.RIGHT}
                    />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("programaseguros.fechahasta",{ns:"page"})}
                    labelFor="fecha-fin"
                    inline={true}
                    className="pixelAlrededor" 
                >
                    <DateInput minDate={new Date(1900, 1 ,1)}
                        maxDate={new Date(today.getFullYear()+10, 11 ,31)}
                        {...jsDateFormatter} 
                        onChange={escogeFechaFin}
                        value={this.state.finSeguro}
                        align={Alignment.RIGHT}
                    />
                </FormGroup>
                
                <FormGroup
                >
                <input type="file" id="file" ref="fileUploader" onChange={onFileChange}  style={{display: "none"}}/>
                {this.props.DatosPoliza && Object.keys(this.props.DatosPoliza).length>0 &&
                  <p>{this.props.i18next.t("programaseguros.remplazardoc",{ns:"page"})} <button onClick={abrirSubida}>{this.props.i18next.t("here",{ns:"global"})}</button>
                    <span style={{marginLeft: 10 }}>{this.state.fileName}</span>
                  </p>
                }
                {this.props.DatosPoliza && Object.keys(this.props.DatosPoliza).length>0?
                  <p>{this.props.i18next.t("programaseguros.consultardoc",{ns:"page"})} <a href={process.env.REACT_APP_DOMINIO+""+this.props.DatosPoliza.rutaDocumento.replace(".","")} target="_blank" rel="noopener noreferrer">{this.props.i18next.t("here",{ns:"global"})}</a></p>
                :
                  <p>{this.props.i18next.t("programaseguros.subirdoc",{ns:"page"})} <button onClick={abrirSubida}>{this.props.i18next.t("here",{ns:"global"})}</button><span style={{marginLeft: 10 }}>{this.state.fileName}</span></p>
                }
                </FormGroup>
            </>
        )

    }
}

export default PolizasSeguro