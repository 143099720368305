import React, { Component } from 'react';
import { Card, FormGroup, TextArea, Elevation, Button, Callout, AnchorButton, Dialog, Spinner } from '@blueprintjs/core';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from '@react-pdf/renderer';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
      dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:135,
    paddingTop:30,
    paddingLeft: 40,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '90%'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
  
})

class Instalaciones extends Component {

    state={
        modalImprimirI:false,
        cargandoImprimir:false,
        fijas:null,
        moviles:null,
        especiales:null,
        transporte:null,
        maquinaria:null,
        equipos:null,
        software:null,
        existeInstalaciones:null,
        abierto1: false,
        abierto2: false,
        cargando: false,
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    handleGuardar = () => {
        
        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert(i18next.t("sinpermiso",{ns:"global"}))
            return
        }

        if(this.state.existeInstalaciones){           
            this.props.client
            .mutate({
            mutation: gql`
                 mutation{actualizarInstalacion(VALORES:{
                    FIJAS:"""${this.state.fijas?this.state.fijas:""}""",
                    MOVILES:"""${this.state.moviles?this.state.moviles:""}""",
                    ESPECIALES:"""${this.state.especiales?this.state.especiales:""}""",
                    TRANSPORTE:"""${this.state.transporte?this.state.transporte:""}"""
                    ,MAQUINARIA:"""${this.state.maquinaria?this.state.maquinaria:""}""",
                    INFORMATICOS:"""${this.state.equipos?this.state.equipos:""}""",
                    SOFTWARE:"""${this.state.software?this.state.software:""}"""
                }){FIJAS}}                    `
            
            })
            .then(result =>{
                alert(i18next.t("updatecorrecto",{ns:"global"}))
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }else{           
            this.props.client
            .mutate({
                mutation: gql`
                mutation{crearInstalacion(VALORES:{
                    FIJAS:"""${this.state.fijas?this.state.fijas:""}""",
                    MOVILES:"""${this.state.moviles?this.state.moviles:""}""",
                    ESPECIALES:"""${this.state.especiales?this.state.especiales:""}""",
                    TRANSPORTE:"""${this.state.transporte?this.state.transporte:""}""",
                    MAQUINARIA:"""${this.state.maquinaria?this.state.maquinaria:""}""",
                    INFORMATICOS:"""${this.state.equipos?this.state.equipos:""}""",
                    SOFTWARE:"""${this.state.software?this.state.software:""}"""
                }){FIJAS}}`                
            })
            .then(result =>{

                alert(i18next.t("insertcorrecto",{ns:"global"}))    
                this.setState({existeInstalaciones:true})
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }
    }

    instalacionesInvisibles =()=> (
        <>
            <div className="html-pdf" ref={(input) => { this.instalacionesAImprimir = input; }} >
                <div className="body-pdf">
                    <br />
                    <img
                        className="App-logo-pdf"
                        src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                        alt="logotipo"
                    />
                    <br />
                    <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={i18next.t("instalaciones.instalacionesfijas",{ns:"page"})}
                                labelFor="instalaciones-fijas"
                            >
                                <TextArea maxlength={300} id="instalaciones-fijas" fill={true} value={this.state.fijas} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.instalacionesmoviles",{ns:"page"})}
                                labelFor="instalaciones-moviles"
                            >
                                <TextArea maxlength={300} id="instalaciones-moviles:" fill={true} value={this.state.moviles}/>
                            </FormGroup>
                            <FormGroup
                                    label={i18next.t("instalaciones.instalacionesespeciales",{ns:"page"})}
                                labelFor="instalaciones-especiales"
                            >
                                <TextArea maxlength={300} id="instalaciones-especiales:" fill={true} value={this.state.especiales}/>
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.elementostransporte",{ns:"page"})}
                                labelFor="elementos-transporte"
                            >
                                <TextArea maxlength={300} id="elementos-transporte" fill={true} value={this.state.transporte}/>
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.maquinaria",{ns:"page"})}
                                labelFor="maquinaria"
                            >
                                <TextArea maxlength={300} id="maquinaria" fill={true} value={this.state.maquinaria} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.equiposinformaticos",{ns:"page"})}
                                labelFor="equipos-informaticos"
                                >
                                <TextArea maxlength={300} id="equipos-informaticos" fill={true} value={this.state.equipos}/>
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.software",{ns:"page"})}
                                labelFor="software"
                            >
                                <TextArea maxlength={300} id="software" fill={true} value={this.state.software}/>
                            </FormGroup>
                        <br />
                    </Card>
                </div>
                <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
            </div>
        </>    
    )

    componentDidMount(props){

        this.setState({cargando: true})

        this.props.client
       .query({
         query: gql`
           {instalaciones{
            FIJAS,
            MOVILES,
            ESPECIALES,
            TRANSPORTE,
            MAQUINARIA,
            INFORMATICOS,
            SOFTWARE}}
         `
       })
       .then(result =>{
           if(result.data.instalaciones.length>0){

                this.setState({existeInstalaciones:true})
                this.setState({fijas:result.data.instalaciones[0].FIJAS})
                this.setState({moviles:result.data.instalaciones[0].MOVILES})
                this.setState({especiales:result.data.instalaciones[0].ESPECIALES})
                this.setState({transporte:result.data.instalaciones[0].TRANSPORTE})
                this.setState({maquinaria:result.data.instalaciones[0].MAQUINARIA})
                this.setState({equipos:result.data.instalaciones[0].INFORMATICOS})
                this.setState({software:result.data.instalaciones[0].SOFTWARE})

           }
       });     
       
       this.obtenerLogoEmpresa()

       this.setState({cargando: false})
   }

   cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
        
        return datosEmpresa
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }

    generarInformeI =async () => {
        this.setState({
            modalImprimirI: true,
            cargandoImprimir:true
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeI(), document.getElementById('informe-pdf-i'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeI(), document.getElementById('informe-pdf-i'))
                })
            }
        })
        
    }

    InformeI = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("instalaciones.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.instalacionesfijas",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.fijas}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.instalacionesmoviles",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.moviles}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.instalacionesespeciales",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.especiales}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.elementostransporte",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.transporte}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.maquinaria",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.maquinaria}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.equiposinformaticos",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.equipos}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("instalaciones.software",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.software}</Text>
                </View>
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
               fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("instalaciones.title",{ns:"page"});
        
        const cerrarModalImprimirI=()=>{
            this.setState({modalImprimirI: false})
        }

        const handleCambiarFijas=(ev)=>{
            this.setState({fijas:ev.currentTarget.value})
        }
        const handleCambiarMoviles=(ev)=>{
            this.setState({moviles:ev.currentTarget.value})
        }
        const handleCambiarEspeciales=(ev)=>{
            this.setState({especiales:ev.currentTarget.value})
        }
        const handleCambiarTransportes=(ev)=>{
            this.setState({transporte:ev.currentTarget.value})
        }
        const handleCambiarMaquinaria=(ev)=>{
            this.setState({maquinaria:ev.currentTarget.value})
        }
        const handleCambiarEquipos=(ev)=>{
            this.setState({equipos:ev.currentTarget.value})
        }
        const handleCambiarSoftware=(ev)=>{
            this.setState({software:ev.currentTarget.value})
        }

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimirI}
                        /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                        title={i18next.t("print",{ns:"global"})+" "+i18next.t("instalaciones.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        usePortal={true}
                        isOpen={this.state.modalImprimirI}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '600px'}} id="informe-pdf-i">
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <div className="pixelAlrededor tablaSesenta">
                        <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.instalaciones= input; }}>
                                <FormGroup
                                    label={i18next.t("instalaciones.instalacionesfijas",{ns:"page"})}
                                    labelFor="instalaciones-fijas"
                                >
                                    <TextArea maxlength={300} id="instalaciones-fijas" fill={true} onChange={handleCambiarFijas} value={this.state.fijas} />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("instalaciones.instalacionesmoviles",{ns:"page"})}
                                    labelFor="instalaciones-moviles"
                                >
                                    <TextArea maxlength={300} id="instalaciones-moviles:" fill={true} onChange={handleCambiarMoviles} value={this.state.moviles}/>
                                </FormGroup>
                                <FormGroup
                                        label={i18next.t("instalaciones.instalacionesespeciales",{ns:"page"})}
                                    labelFor="instalaciones-especiales"
                                >
                                    <TextArea maxlength={300} id="instalaciones-especiales:" fill={true} onChange={handleCambiarEspeciales} value={this.state.especiales}/>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("instalaciones.elementostransporte",{ns:"page"})}
                                    labelFor="elementos-transporte"
                                >
                                    <TextArea maxlength={300} id="elementos-transporte" fill={true} onChange={handleCambiarTransportes} value={this.state.transporte}/>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("instalaciones.maquinaria",{ns:"page"})}
                                labelFor="maquinaria"
                            >
                                <TextArea maxlength={300} id="maquinaria" fill={true}onChange={handleCambiarMaquinaria} value={this.state.maquinaria} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.equiposinformaticos",{ns:"page"})}
                                labelFor="equipos-informaticos"
                                >
                                <TextArea maxlength={300} id="equipos-informaticos" fill={true} onChange={handleCambiarEquipos} value={this.state.equipos}/>
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("instalaciones.software",{ns:"page"})}
                                labelFor="software"
                            >
                                <TextArea maxlength={300} id="software" fill={true} onChange={handleCambiarSoftware} value={this.state.software}/>
                            </FormGroup>
                            <Button className="iconoDerecha bp3-intent-danger" icon="print" onClick={()=>this.generarInformeI()}>{i18next.t("print",{ns:"global"})}</Button>
                            <Button className="iconoDerecha bp3-intent-danger" icon="floppy-disk" onClick={this.handleGuardar}>{i18next.t("guardar",{ns:"global"})}</Button>
                            <br />
                        </Card>
                    </div>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("instalaciones.leydescripcion",{ns:"page"})}</Callout>
                            <br />
                            <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/doc.php?id=BOE-A-2004-21216", "_blank")}>{i18next.t("instalaciones.ley",{ns:"page"})}</AnchorButton>
                            <br />
                        </Card>
                    </div>
                </>
            }
            </>
        )
    }
}
export default withTranslation()(withApollo(Instalaciones))