/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import {Spinner, Callout, Navbar, ButtonGroup, Checkbox,Alignment, Button, Dialog, FormGroup, InputGroup, Elevation, Classes, Tooltip, AnchorButton, Intent, Card } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select"
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });


const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});


class PrivateEquity extends Component {

    state={
        modalAbierto: false,
        privates: [
        ],
        personas: [

        ],
        personasSeleccionadas:[],
        nombrePrivate:null,
        bolFechaVigencia:true,
        fechaInicio:new Date(),
        fechaFin:new Date(),
        cargando:false,
        imprimirActivado:false,
        modalImprimir:false,
        personasFisicas: null,
        personasJuridicas:null,
        datosEmpresa: null,
        logotipo:null,
    }

    
    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
                {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }

    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
               {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
    }

    cargarPrivates=async()=>{


        //obtencion de los privates
        let privates=await this.props.client
        .query({
            query: gql`
                {privatesEquity{ID_PRIVATE_EQUITY,NOMBRE_PROYECTO,VIGENCIA_INICIO,VIGENCIA_FIN}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.privatesEquity
        })

        let miembros=await this.props.client
        .query({
            query: gql`
                {privateEquityInversores{ID_PERSONA,ID_PRIVATE_EQUITY,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy:'network-only'

        }).then(result=>{ 
            return result.data.privateEquityInversores
        })

        

        //obtencion de los miembros
        privates.forEach(async privateEquity=>{

            let miembrosVal=[];
            miembros.forEach(async(miembro)=>{
                if (miembro.ID_PRIVATE_EQUITY===privateEquity.ID_PRIVATE_EQUITY) {
                    if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                        this.state.personasFisicas.find(persona=>{
                            if(persona.ID_PERSONA===miembro.ID_PERSONA){
                                miembro.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                                miembro.value=persona.ID_PERSONA
                                miembrosVal.push(miembro)
                            }
                        })
                    }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                        this.state.personasJuridicas.find(persona=> {
                            if(persona.ID_PERSONA===miembro.ID_PERSONA){
                                miembro.value=persona.ID_PERSONA
                                miembro.label=persona.RAZON_SOCIAL
                                miembrosVal.push(miembro)
                            }
                        })
                    }
                }
            })
            
            
            privateEquity.miembros=miembrosVal
        })
        this.setState({privates:privates})
        //console.log(this.state.privates)
    }

    cargarPersonas=async()=>{

        let personas=[]

        let fondos=await this.props.client.query({
            query:gql`{fondosInversiones{ID_FONDO_INVERSION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.fondosInversiones
        })
     
        for(let fondo of fondos){

            if(fondo.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===fondo.ID_FONDO_INVERSION){
                    personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 
                    }
                })
                // await this.props.client
                //  .query({
                //      query: gql`
                //          {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${fondo.ID_FONDO_INVERSION}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                //      `
                //  }).then(result=>{ 
         
                //     if(result.data.personasFisicas.length===0)alert(fondo.ID_FONDO_INVERSION)
                //     let persona=result.data.personasFisicas[0]
                //     personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 

                //  })
     
             //Si es persona juridica
             }else if(fondo.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                 this.state.personasJuridicas.find(persona=>{
                     if(persona.ID_PERSONA===fondo.ID_FONDO_INVERSION){
                        personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL})
                     }
                 })
                 
                //  await this.props.client
                //  .query({
                //      query: gql`
                //          {personasJuridicas(FILTROS:{ID_PERSONA:${fondo.ID_FONDO_INVERSION}}){ID_PERSONA,RAZON_SOCIAL}}
                //      `
                //  }).then(result=>{ 
     
                //     let persona=result.data.personasJuridicas[0]
                //     personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL}) 
 
                //  })
     
             }

        }


        this.setState({personas:personas})


    }
    CargarDatosEmpresa= async() => {
        let datosEmpresa= await this.props.client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }


    cargarDatos= async () =>{
        let personasFisicas= await this.cargarPersonasFisicas();
        let personasJuridicas= await this.cargarPersonasJuridicas();
        let datosEmpresa=await this.CargarDatosEmpresa()
        this.setState({
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
            datosEmpresa: datosEmpresa
        })
    }

    
    componentDidMount(props){
        this.setState({cargando: true}, async ()=>{
            await this.cargarDatos()
            await this.cargarPrivates()
            await this.cargarPersonas()
            this.setState({cargando:false})
        })
    }

    generarInforme= async() =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true,
            cargandoGrid:true,
            privateSeleccionado:null
        }, async () => {
            await this.obtenerLogoEmpresa()
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.ImprimirPE(), document.getElementById('generarCR-pdf'))
            })
        })
    }
    ImprimirPE= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("private.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("private.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.privates?this.state.privates.map((data, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'33%'}}>{i18next.t("private.nombre",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'33%'}}>{i18next.t("private.fechainicio",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'34%'}}>{i18next.t("private.fechafin",{ns:"page"}).toUpperCase()}</Text>                                     
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'33%'}}>{data.NOMBRE_PROYECTO?data.NOMBRE_PROYECTO:''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'33%'}}>{data.VIGENCIA_INICIO?moment(data.VIGENCIA_INICIO).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'34%'}}>{data.VIGENCIA_FIN?moment(data.VIGENCIA_FIN).format('DD-MM-YYYY'):''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>

            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
                <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"}) + " | " + i18next.t("private.title",{ns:"page"})
        const cerrarModalImprimir= () =>{
            this.setState({
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalAbierto: true,
                fechaInicio:new Date(),
                fechaFin:new Date(),
                bolFechaVigencia:true,
                nombrePrivate:null,
                personasSeleccionadas:[],
                privateSeleccionado:null,
                cargandoGrid:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.privateSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalAbierto: true,
                fechaInicio:new Date(this.state.privateSeleccionado.VIGENCIA_INICIO),
                fechaFin:this.state.privateSeleccionado.VIGENCIA_FIN?new Date(this.state.privateSeleccionado.VIGENCIA_FIN):null,
                bolFechaVigencia:this.state.privateSeleccionado.VIGENCIA_FIN,
                nombrePrivate:this.state.privateSeleccionado.NOMBRE_PROYECTO,
                personasSeleccionadas:this.state.privateSeleccionado.miembros,
                cargandoGrid:true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.privateSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
            })
        }
        
        function dateFormatter(cell, row) {
            let fecha=(cell)?moment(cell).format("DD/MM/YYYY"):""
            return (
                fecha
            );
        }

        const columns = [
        {
        dataField: 'NOMBRE_PROYECTO',
        text: i18next.t("private.nombre",{ns:"page"})
        },
        {
            dataField: 'VIGENCIA_INICIO',
            text: i18next.t("private.fechainicio",{ns:"page"}),
            formatter: dateFormatter
        },
        {
            dataField: 'VIGENCIA_FIN',
            text: i18next.t("private.fechafin",{ns:"page"}),
            formatter: dateFormatter
        }
        ];

        const escogeFechaInicio = (nuevaFecha) => {
            this.setState({fechaInicio: nuevaFecha})
        }

        const escogeFechaFin = (nuevaFecha) => {
            this.setState({fechaFin: nuevaFecha})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false,
                privateSeleccionado: null
            })
        }

        const añadirNuevo = async(value) => {
            
            if(!this.state.nombrePrivate)return
            if(this.state.personasSeleccionadas.length===0)return


            //mutation para crear el privateEquity
            await this.props.client.mutate({
                mutation:gql`mutation{crearPrivateEquity(VALORES:{NOMBRE_PROYECTO:"${this.state.nombrePrivate}",VIGENCIA_INICIO:"${moment(this.state.fechaInicio).format("YYYY-MM-DD")}",VIGENCIA_FIN:${this.state.bolFechaVigencia?'"'+moment(this.state.fechaFin).format("YYYY-MM-DD")+'"':null}}){ID_PRIVATE_EQUITY}}`

            }).then(async result=>{
           
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                //insercion de los miembros seleccionados
                this.state.personasSeleccionadas.forEach(async persona=>{
                    await this.props.client.mutate({
                        mutation:gql`mutation{crearPrivateEquityInversores(VALORES:{ID_PRIVATE_EQUITY:${result.data.crearPrivateEquity.ID_PRIVATE_EQUITY},ID_PERSONA:${persona.value}}){ID_PRIVATE_EQUITY,ID_PERSONA}}`
                    })
                })
                await this.cargarPrivates()

                cerrarModal()
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const editarPrivate=async()=>{

            if(!this.state.nombrePrivate)return
            if(this.state.personasSeleccionadas.length===0)return
       

            //mutation para actualizar el private equity
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarPrivateEquity(ID_PRIVATE_EQUITY:${this.state.privateSeleccionado.ID_PRIVATE_EQUITY},VALORES:{NOMBRE_PROYECTO:"${this.state.nombrePrivate}",VIGENCIA_INICIO:"${moment(this.state.fechaInicio).format("YYYY-MM-DD")}",VIGENCIA_FIN:${this.state.bolFechaVigencia?'"'+moment(this.state.fechaFin).format("YYYY-MM-DD")+'"':null}}){ID_PRIVATE_EQUITY}}`

            }).then(async result=>{
              
                alert(i18next.t("updatecorrecto",{ns:"global"}))

                //eliminamos los antiguos miembros
                await this.props.client.mutate({
                    mutation:gql`mutation{eliminarPrivateEquityInversores(ID_PRIVATE_EQUITY:${this.state.privateSeleccionado.ID_PRIVATE_EQUITY}){ID_PRIVATE_EQUITY}}`
    
                })
                //insercion de los miembros seleccionados
                this.state.personasSeleccionadas.forEach(async persona=>{
                    await this.props.client.mutate({
                        mutation:gql`mutation{crearPrivateEquityInversores(VALORES:{ID_PRIVATE_EQUITY:${this.state.privateSeleccionado.ID_PRIVATE_EQUITY},ID_PERSONA:${persona.value}}){ID_PRIVATE_EQUITY}}`
        
                    })
                })
                
                await this.cargarPrivates()

                 
                cerrarModal()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const eliminarPrivate=async()=>{

            //eliminamos los miembros
            await this.props.client.mutate({
                mutation:gql`mutation{eliminarPrivateEquityInversores(ID_PRIVATE_EQUITY:${this.state.privateSeleccionado.ID_PRIVATE_EQUITY}){ID_PRIVATE_EQUITY,ID_PERSONA}}`

            })

            //eliminamos el capital
            await this.props.client.mutate({
                mutation:gql`mutation{eliminarPrivateEquity(ID_PRIVATE_EQUITY:${this.state.privateSeleccionado.ID_PRIVATE_EQUITY}){ID_PRIVATE_EQUITY}}`

            }).then(async result=>{
              
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            this.setState({privateSeleccionado:null})
            await this.cargarPrivates()
            cerrarModal()
            
        }

        const handleEnabledChange = () => {
            let valor=!this.state.bolFechaVigencia
            this.setState({bolFechaVigencia: !this.state.bolFechaVigencia})
            if(valor){
                this.setState({fechaFin:new Date()})
            }else{ 
                this.setState({fechaFin:null})
            }
        }

        const cambiarPrivate = (ev) => {
            this.setState({nombrePrivate: ev.currentTarget.value})
        }

        const seleccionarPersonas=(value)=>{
            this.setState({personasSeleccionadas:value})
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        privateSeleccionado: row
        });
        }
        return true;
        }

        return (
            <>
               {this.state.cargando===true?
                    <Cargando/>
                :
                <> 
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarCR-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={i18next.t("private.prop",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">

                        <FormGroup
                            label={i18next.t("private.nombre",{ns:"page"})+":"}
                            labelFor="nombre-proyecto"
                            intent="danger"
                            helperText={!this.state.nombrePrivate?i18next.t("emptyfield",{ns:"global"})+" "+i18next.t("private.nombre",{ns:"page"}):""}
                
                        >
                            <InputGroup id="nombre-proyecto" className="pixelAlrededor" onChange={cambiarPrivate} intent={!this.state.nombrePrivate?"danger":"primary"} value={this.state.nombrePrivate} fill={true} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("private.fechainicio",{ns:"page"})+":"}
                                labelFor="fecha-inicio"
                                inline={true}
                                className="pixelAlrededor" 
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaInicio}
                                    align={Alignment.RIGHT}
                                    value={this.state.fechaInicio}
                                />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("private.fechafin",{ns:"page"})+":"}
                                labelFor="fecha-fin"
                                inline={true}
                                className="pixelAlrededor" 
                            >
                                <Checkbox checked={this.state.bolFechaVigencia} onChange={handleEnabledChange} />
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaFin}
                                    align={Alignment.RIGHT}
                                    disabled={!this.state.bolFechaVigencia}
                                    value={this.state.bolFechaVigencia?this.state.fechaFin:null}

                                />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("private.participantes",{ns:"page"})}
                                labelFor="personas"
                                className="pixelAlrededor" 
                                intent="danger"
                                helperText={this.state.personasSeleccionadas.length===0?i18next.t("selectempty",{ns:"page"})+" "+i18next.t("private.participantes",{ns:"page"}):null}
                            >
                                <div style={this.state.personasSeleccionadas.length===0?{border:"1px solid red"}:{}}>
                                    <Select
                                        isMulti
                                        options={this.state.personas}
                                        className="pixelAlrededor"
                                        backspaceRemovesValue={true}
                                        onChange={seleccionarPersonas}
                                        isClearable={true}
                                        value={this.state.personasSeleccionadas}
                                    />
                                </div>
                            </FormGroup>
                        
                        </Card>
 
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"page"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:editarPrivate}
                                >
                                    {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}  
                                   
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("private.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarPrivate}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"page"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededorGrande tablaSesenta">
                    <Card interactive={true} elevation={Elevation.TWO} >
                    {this.state.cargandoGrid?
                        <Cargando />
                        :
                        <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                    <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} actibe={this.state.imprimirActivado} onClick={()=>this.generarInforme()}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                        keyField='ID_PRIVATE_EQUITY'
                        data={ this.state.privates }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    </>
                    }
                    </Card>
                </div>
                <div className="pixelAlrededorGrande tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("private.leydescription",{ns:"page"})}</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2014-11714#contenido", "_blank")}>{i18next.t("private.ley",{ns:"page"})}</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
             }
        </>  
        )
    }
}

export default withTranslation()(withApollo(PrivateEquity))