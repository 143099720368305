
import React from "react"
import {Spinner, Card, Elevation, FormGroup,Dialog,TextArea, Classes, NavbarDivider,Tooltip, AnchorButton, Intent, Navbar, ButtonGroup, Button, InputGroup, Alignment,FileInput} from "@blueprintjs/core"
import gql from "graphql-tag";
import axios from 'axios';
import { withApollo } from "react-apollo";
import moment from "moment"
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import ReactDOM from 'react-dom';
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from '../stylesPrint';
import global from './Global';
 
 class AdjuntosContratosCompliance extends React.Component {

    state = {
        modalAbierto: false,
        modalBorrarAbierto: false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        nombre:null,
        nuevoActivado:true,
        editarActivado:false,
        borrarActivado:false,
        descripcion:null,
        adjuntoSeleccionado:null,
        archivo:null,
        controlesDesactivados:false,
        adjuntos: [
        ],
        cargandoImprimirC:false,
        carganDoImprimirCG: false,
        modalImprimirC:false,
        modalImprimirCG:false
    
    }

    recargarAdjuntos=async()=>{

        let adjuntos=await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:18}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{

            return result.data.adjuntos
        })

            let copyAdjuntos=[]
            adjuntos.forEach((adjunto)=>{
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({adjuntos:copyAdjuntos})
    }

    componentDidMount= async()=>{  
       await this.recargarAdjuntos();       
    }

    generarImprimirCG=async() =>{
        this.setState({
            cargandoImprimirCG:true,
            modalImprimirCG:true,
        }, async() =>{
            this.setState({cargandoImprimirCG:false}, () =>{
                ReactDOM.render(this.ImprimirCG(), document.getElementById('generarCG-pdf'))
            })
        })
    }

    ImprimirCG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"})}:</Text>
                    <Text style={styles.text}>{i18next.t("contratoscompliance.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("contratoscompliance.titleprintcontract",{ns:"page"})}</Text>
                </View>
                    {this.state.adjuntos?this.state.adjuntos.map((adjunto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{i18next.t("contratoscompliance.name",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'35%'}}>{i18next.t("contratoscompliance.type",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'30%'}}>{i18next.t("contratoscompliance.mime",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'35%'}}>{adjunto.nombre?adjunto.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'35%'}}>{adjunto.tipo?adjunto.tipo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'30%'}}>{adjunto.mime?adjunto.mime:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    generarImprimirC=async() =>{
        this.setState({
            cargandoImprimirC:true,
            modalImprimirC:true
        }, async () =>{
            this.setState({cargandoImprimirC:false}, () =>{
                ReactDOM.render(this.ImprimirC(), document.getElementById('generarC-pdf'))
            })            
        })
    }
    ImprimirC=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("contratoscompliance.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("contratoscompliance.titlepringcontractindividual",{ns:"page"})}</Text>
                </View>
                    {this.state.adjuntoSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("contratoscompliance.name",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.adjuntoSeleccionado.nombre?this.state.adjuntoSeleccionado.nombre:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("contratoscompliance.description",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.adjuntoSeleccionado.descripcion?this.state.adjuntoSeleccionado.descripcion:''}</Text>
                                </View>
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    render() {

        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirC:false,
                modalImprimirCG:false
            })
        }
        
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
            })

        }
        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({
                nombre:this.state.adjuntoSeleccionado.nombre,
                descripcion:this.state.adjuntoSeleccionado.descripcion,
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false
            })
    
        }
        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({
                modalBorrarAbierto: true,
                modalAbierto: false,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true
            })
 
        }

        const anadirNuevo = () => {

            if(!this.state.archivo)return
            if(!this.state.nombre)return
            if(!this.state.descripcion)return

            let extension=global.obtenerTipo("."+this.state.archivo.name.split(".").pop())

            this.state.adjuntos.push({
                "nombre": this.state.nombre?this.state.nombre:this.state.nombre,
                "tipo": "Contrato Compliance",
                "idTipo":18,
                "mime": extension
            })
            
            this.setState({
                pendienteGuardar:true,
                controlesDesactivados:true
            })
            this.setState({})
            cerrarModal()
        }

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            const api = axios.create({
                withCredentials: true
            });

            if(this.state.pendienteGuardar){
              
                var formData = new FormData();
                formData.append("files",this.state.archivo);
                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:18,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then(async(response)=> {
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    await this.recargarAdjuntos()
                    this.setState({
                        controlesDesactivados:false,
                        archivo:null,
                        pendienteGuardar:false
                    })
           

                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                })

              

            }else if(this.state.pendienteActualizar){

                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre}",ID_TIPO_ADJUNTO:${18},ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`).then( async(response)=> {
                    // handle success
                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    await this.recargarAdjuntos()
                    
                    this.setState({
                        controlesDesactivados:false,
                        pendienteActualizar:false,
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then((response)=>{
                    // handle success  
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.recargarAdjuntos();
                    this.setState({controlesDesactivados:false})
                    this.setState({pendienteBorrar:false})
                    this.setState({adjuntoSeleccionado:null})
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }

        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                pendienteActualizar:false,
                pendienteGuardar:false,
                pendienteBorrar:false,
                controlesDesactivados:null,
            })

            this.recargarAdjuntos()

        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    adjuntoSeleccionado: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }
        
        const ActualizarAdjunto = () => {
           
            if(!this.state.nombre)return
            if(!this.state.descripcion)return      

            let adjuntos=this.state.adjuntos
            //si hemos rellenado los dos campos, agregamos los valores al listado.
            this.state.adjuntos.find((adjunt,index) =>{
                if(adjunt.id===this.state.adjuntoSeleccionado.id){
                    adjuntos[index]["nombre"]=this.state.nombre?this.state.nombre:this.state.adjuntoSeleccionado.nombre
                }
                return adjunt.id===this.state.adjuntoSeleccionado.id
            })
            this.setState({adjuntos:adjuntos})
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        };

        const eliminarAdjunto = () => {
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            var adjuntos=this.state.adjuntos.filter(adjunto => adjunto.id !== this.state.adjuntoSeleccionado.id)
            this.setState({adjuntos:adjuntos})
            cerrarModal()
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
        };
 
        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({archivo:ev.target.files[0]})
            this.setState({tipoArchivo:ev.target.files[0].type})
            this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        const columns = [
            {
                dataField: 'nombre',
                text: i18next.t("contratoscompliance.name",{ns:"page"}),
                sort: true
            },
            {
                dataField: 'tipo',
                text: i18next.t("contratoscompliance.type",{ns:"page"}),
                sort: true
            },
            {
                dataField: 'mime',
                text: i18next.t("contratoscompliance.mime",{ns:"page"}),
                sort: true
            }
        ];

        return (
            <>
             <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})+' '+i18next.t("contratoscompliance.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirCG}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirCG?
                            <div style={{ width: '100%', height: '500px'}} id='generarCG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})+' '+i18next.t("contratoscompliance.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirC}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirC?
                            <div style={{ width: '100%', height: '500px'}} id='generarC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalAbierto}
            >
                <Card interactive={true} elevation={Elevation.TWO}>
                    {this.state.nuevoActivado?
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("contratoscompliance.file",{ns:"page"})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.archivo?"":i18next.t("selectfield",{ns:"global"})+i18next.t("contratoscompliance.file",{ns:"page"})}
                            >
                                <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                            </FormGroup>
                        </div>
                        :<></>
                    }
                    
                    <div className="pixelAlrededor">
                    </div>
                    <div className="pixelAlrededor">
                        <FormGroup
                                label={i18next.t("contratoscompliance.name",{ns:"page"})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nombre?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("contratoscompliance.name",{ns:"page"})}
                        >
                            <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre} placeholder={i18next.t("placeholder",{ns:"global"})} intent={this.state.nombre?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className="pixelAlrededor">
                        <FormGroup
                                label={i18next.t("contratoscompliance.description",{ns:"page"})}
                                labelFor="descripcion"
                                intent="danger"
                                helperText={this.state.descripcion?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("contratoscompliance.description",{ns:"page"})}
                        >
                            <TextArea
                                large={true}
                                fill={true}
                                intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                                onChange={handleCambiarDescripcion}
                                defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.descripcion:""}
                            />                    
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?anadirNuevo:ActualizarAdjunto}
                            >
                                {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Card>
            </Dialog>
            
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("contratoscompliance.titledelfile",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("contratoscompliance.questiondelfile",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAdjunto}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("no",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Navbar>
                <Navbar.Group>
                    <ButtonGroup align={Alignment.LEFT}>
                        {this.state.controlesDesactivados?[
                            <>
                                <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled />
                                <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled />
                                <Button className="bp3-minimal" icon="print"  text={i18next.t("print",{ns:"global"})} disabled/>
                                <NavbarDivider />
                                <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled/>
                            </>
                        ]:[
                            <>
                                <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                <Button className="bp3-minimal" icon="print" disabled={this.state.adjuntoSeleccionado?false:true} text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirC()}/>
                                <NavbarDivider />
                                <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                            </>
                        ]}
                    </ButtonGroup>
                </Navbar.Group>
            </Navbar>
            <BootstrapTable
                keyField='id'
                data={ this.state.adjuntos }
                columns={ columns }
                selectRow={ const_table.selectRow(handleOnSelect) }
                pagination={ paginationFactory(const_table.options) }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
            />
            <br/>
            <br/>
            <Button 
                    className="iconoDerecha bp3-minimal" 
                    intent="danger"
                    alignText="right"
                    icon="print" 
                    text={i18next.t("printgral",{ns:"global"})} 
                    onClick={()=>this.generarImprimirCG()}
                />
            <Button 
                alignText="right"
                 icon="floppy-disk"
                text={i18next.t("guardar",{ns:"global"})}
                intent="danger"
                onClick={guardarCambios}
                className="iconoDerecha bp3-minimal"
                disabled={!this.state.controlesDesactivados}
            />
         <Button 
                alignText="right"
                icon="cross"
                intent="danger"
                text={i18next.t("cancelar",{ns:"global"})}
                onClick={cancelarCambios}
                disabled={!this.state.controlesDesactivados}
                className="iconoDerecha bp3-minimal"
         />
            <br/>
            <br/>          
        </>
        )
    }
}
export default withTranslation()(withApollo(AdjuntosContratosCompliance));



    



