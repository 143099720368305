import React from "react"

import ServiciosEmpresariales from "./ServiciosEmpresariales"
import ServiciosProfesionales from "./ServiciosProfesionales"

export default class ServiciosIndependientes extends React.Component {
    render(){
        return (
            <>
                <div className="tablaMitadIzquierda pixelAlrededor">
                    <ServiciosProfesionales />
                </div>
                <div className="tablaMitadDerecha pixelAlrededor">
                    <ServiciosEmpresariales />
                </div>
            </>
        )
    }
}