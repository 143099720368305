/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, Elevation, FormGroup, Button, Callout, Spinner } from "@blueprintjs/core"
import pantallaInicio from '../../../Assets/LOGO REGISTRADO PEQUEÑO.png';
import iconoFooter from '../../../Assets/lc.png';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from '@react-pdf/renderer';
import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 40,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:10,
    fontFamily: 'Open Sans Bold',
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: 250
  },
  textDataCentros: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    marginLeft: 120
  },
  textDataSucursales: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    marginLeft: 80
  },
  textDataCodigos: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    marginLeft: 160
  },
  textDataSociedades: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    marginLeft: 100
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
});



class ImprimirInformacionEmpresa extends Component {
    
    state={
        abierto1: false,
        abierto2: false,
        logotipo:null,
        cargando:false,
        cif:null
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarCodigos=async()=>{

        //OBTENCION DE LAS CCC DE LA EMPRESA.
        let codigos=await this.props.client
        .query({
            query: gql`
            {
                personasJuridicasCcc(ORDEN:"ID_CCC,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_CCC,CCC,PRINCIPAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
            if(result.data.personasJuridicasCcc.length>0){
                return result.data.personasJuridicasCcc
            }
            
        })
        return codigos;
    }

    cargarCIF=async ()=>{

        //OBTENCION DE LA PERSONA JURIDICA Y EL CIF DE LA EMPRESA.
        let cif=await this.props.client
        .query({
            query: gql`
            {
                documentosIdentificativos(FILTROS:{ID_PERSONA:1,ID_TIPO_DOCUMENTO:4}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR,FECHA_EMISION,FECHA_CADUCIDAD}
            }
            `,
            fetchPolicy: 'network-only'
        }).then(async result=>{ 
            if(result.data.documentosIdentificativos.length>0){
                return result.data.documentosIdentificativos[0].VALOR
            }
        })

        return cif
    }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        //MEDIO DE CONTACTO
        await this.props.client
        .query({
          query: gql`
            {
              personasMediosContacto(LIMIT: 2, FILTROS: { ID_PERSONA: 1, PREFERIDO:true}) {
                MEDIO_CONTACTO{VALOR}
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
            if(result.data.personasMediosContacto.length>0){
                datosEmpresa["medio"]=result.data.personasMediosContacto[0].MEDIO_CONTACTO.VALOR
            }
        });

        //DOMICILIO
        await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:1,DOMICILIO_SOCIAL:true}){ID_CENTRO_TRABAJO,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            result.data.centrosTrabajos.forEach((centro)=>{
                /* console.log(centro) */
                datosEmpresa["domicilio"]=`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM} ${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""}(${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})`
            })
        })

        return datosEmpresa
    }

    cargarMedios=async()=>{

        //MEDIO DE CONTACTO
        let medios=await this.props.client
             .query({
               query: gql`
                 {
                   personasMediosContacto(FILTROS: { ID_PERSONA: 1}) {
                     MEDIO_CONTACTO{VALOR,TIPO_CONTACTO{DESCRIPCION}}
                   }
                 }
               `,fetchPolicy:'network-only'
        })
        .then(result => {
            return result.data.personasMediosContacto
        });

        return medios
    }


    cargarCentros=async()=>{

        let centros=await this.props.client
        .query({
            query: gql`
            {
                centrosTrabajos(FILTROS:{ID_PERSONA:1}){ID_CENTRO_TRABAJO,NOMBRE,DIRECCION{ID_DIRECCION,CP,TIPO_VIA{ID_TIPO_VIA,DESCRIPCION},NOMBRE_VIA,NUMERO_KM,RESTO_DIRECCION,LOCALIDAD{ID_LOCALIDAD,DESCRIPCION,PROVINCIA{ID_PROVINCIA,DESCRIPCION,PAIS{ID_PAIS,DESCRIPCION}}}}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let copyCentros=[]
            result.data.centrosTrabajos.forEach((centro,index)=>{
                copyCentros.push({"direccion":`${centro.DIRECCION.TIPO_VIA.DESCRIPCION} ${centro.DIRECCION.NOMBRE_VIA},${centro.DIRECCION.NUMERO_KM} ${centro.DIRECCION.RESTO_DIRECCION?`,${centro.DIRECCION.RESTO_DIRECCION}`:""}(${centro.DIRECCION.CP?centro.DIRECCION.CP:""} ${centro.DIRECCION.LOCALIDAD.DESCRIPCION} - ${centro.DIRECCION.LOCALIDAD.PROVINCIA.DESCRIPCION})` })
            })

            return copyCentros
        })

        return centros
    }

    cargarSucursales=async()=>{

        let sucursales=await this.props.client
        .query({
            query: gql`
            {
                sucursales(FILTROS:{ID_PERSONA:1}){ID_SUCURSAL,NOMBRE}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.sucursales
        })

        return sucursales
    }


    cargarMemorias=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:7}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarInformes=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:8}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarPlanesIgualdad=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:9}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarPlanesMovilidad=async()=>{

        return await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:10}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                copyAdjuntos.push({"descripcion":adjunto.DESCRIPCION})
            })
            return copyAdjuntos
        })

    }

    cargarSociedadesVinculadas=async()=>{

        let sociedades=await this.props.client
        .query({
            query: gql`
            {
                sociedadesVinculadas{ID_SOCIEDAD_VINCULADA,NOMBRE,PORCENTAJE_PARTICIPACION,COMENTARIOS}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.sociedadesVinculadas
        })

        return sociedades

    }

    cargarDatos = async () => {
        /*this.obtenerLogoEmpresa()
        let cif=await this.cargarCIF()
        let datosEmpresa=await this.cargarDatosEmpresa()
        let medios=await this.cargarMedios()
        let sucursales=await this.cargarSucursales()
        let centros=await this.cargarCentros()
        let memorias=await this.cargarMemorias()
        let informes=await this.cargarInformes()
        let planesIgualdad=await this.cargarPlanesIgualdad()
        let planesMovilidad=await this.cargarPlanesMovilidad()
        let sociedades=await this.cargarSociedadesVinculadas()

        this.setState({
            cif:cif,
            cargando:false,
            datosEmpresa:datosEmpresa,
            centros:centros,
            medios:medios,
            sucursales,
            memorias:memorias,
            informes:informes,
            planesIgualdad:planesIgualdad,
            planesMovilidad:planesMovilidad,
            sociedades:sociedades
        })*/
        this.obtenerLogoEmpresa();
        let cif=await this.cargarCIF()
        let datosEmpresa=await this.cargarDatosEmpresa();
        let medios=await this.cargarMedios();
        let centros=await this.cargarCentros();
        let sucursales=await this.cargarSucursales();
        let sociedades=await this.cargarSociedadesVinculadas();
        let codigos=await this.cargarCodigos();
        let correo="";
        let correo_denuncias="";
        let tel_fijo="";
        let tel_movil="";
        let fax="";
        medios.map(medio=>{
            if (medio.MEDIO_CONTACTO.TIPO_CONTACTO.DESCRIPCION === 'E-mail') {
                correo=medio.MEDIO_CONTACTO.VALOR;
            } else if (medio.MEDIO_CONTACTO.TIPO_CONTACTO.DESCRIPCION === 'E-mail canal denuncias') {
                correo_denuncias=medio.MEDIO_CONTACTO.VALOR;
            } else if (medio.MEDIO_CONTACTO.TIPO_CONTACTO.DESCRIPCION === 'Teléfono fijo') {
                tel_fijo=medio.MEDIO_CONTACTO.VALOR;
            } else if (medio.MEDIO_CONTACTO.TIPO_CONTACTO.DESCRIPCION === 'Teléfono móvil') {
                tel_movil=medio.MEDIO_CONTACTO.VALOR;
            } else if (medio.MEDIO_CONTACTO.TIPO_CONTACTO.DESCRIPCION === 'Fax') {
                fax=medio.MEDIO_CONTACTO.VALOR;
            }
        });
        datosEmpresa.inicioAct=moment(datosEmpresa.inicioAct).format("DD/MM/YYYY");
        datosEmpresa.finAct=moment(datosEmpresa.finAct).format("DD/MM/YYYY");
        this.setState({
            cargando:false,
            cif:cif,
            datosEmpresa:datosEmpresa,
            correo:correo,
            correo_denuncias:correo_denuncias,
            tel_fijo:tel_fijo,
            tel_movil:tel_movil,
            fax:fax,
            centros:centros,
            sucursales:sucursales,
            sociedades:sociedades,
            codigos:codigos,
        })
    }

    
    generarInforme =async () => {
        this.setState({
            cargando:true
        }, async () => {
            await this.cargarDatos();
            ReactDOM.render(this.Informe(), document.getElementById('informe-pdf'))
        })
        
    }

    Informe = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("empresa.tabimprimir.title",{ns:"company"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("empresa.informacion",{ns:"company"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.CIF",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.cif}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.razonsocial",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.nombrecomercial",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.nombreComercial:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.convenioscolectivosaplicables",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.conveniosColectivos:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.codigoscuentacotizacion",{ns:"company"})}:</Text>
                    <Text style={styles.textData}></Text>
                </View>
                {this.state.codigos?this.state.codigos.map((codigo, index)=>{
                        return (<Text key={index} style={styles.textDataCodigos}>{codigo.PRINCIPAL?"Principal:":""}{codigo.CCC}</Text>)
                    }):<></>}
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.grupoempresarial",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.grupoEmpresarial:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.sitiowebcorporativo",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.sitioWeb:""}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabinformacion.iniciofinalejercicio",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.datosEmpresa?this.state.datosEmpresa.inicioAct+" - "+this.state.datosEmpresa.finAct:""}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("empresa.medioscontacto",{ns:"company"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabmedioscontacto.landline",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.tel_fijo}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabmedioscontacto.mobile",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.tel_movil}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabmedioscontacto.fax",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.fax}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabmedioscontacto.correo",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.correo}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabmedioscontacto.correodenuncias",{ns:"company"})}:</Text>
                    <Text style={styles.textData}>{this.state.correo_denuncias}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("empresa.centrostrabajo",{ns:"company"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabcentrostrabajo.centrostrabajo",{ns:"company"})}:</Text>
                    <Text style={styles.textData}></Text>
                </View>
                {this.state.centros?this.state.centros.map((centro, index)=>{
                        return (<Text key={index} style={styles.textDataCentros}>{centro.direccion}</Text>)
                    }):<></>}
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabcentrostrabajo.sucursales",{ns:"company"})}:</Text>
                    <Text style={styles.textData}></Text>
                </View>
                {this.state.sucursales?this.state.sucursales.map((sucursal, index)=>{
                        return (<Text key={index} style={styles.textDataSucursales}>{sucursal.NOMBRE}</Text>)
                    }):<></>}
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("empresa.vinculos",{ns:"company"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.labelData}>{i18next.t("empresa.tabvinculos.title",{ns:"company"})}:</Text>
                    <Text style={styles.textData}></Text>
                </View>
                {this.state.sociedades?this.state.sociedades.map((sociedad, index)=>{
                        return (<Text key={index} style={styles.textDataSociedades}>{sociedad.NOMBRE}</Text>)
                    }):<></>}
            </View>
            <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
            fixed
          />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

        document.title =i18next.t("lexcontrol",{ns:'global'})+" | "+i18next.t("empresa.tabimprimir.title",{ns:'company'})

        return (
            <>
            <div>
                <Card interactive={true} elevation={Elevation.TWO} id="informe-anual">
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("empresa.tabimprimir.title", { ns: 'company' })}</Callout>

                        {!this.state.cargando?
                                <FormGroup
                                    label={i18next.t("generatedocument",{ns:'global'})+": "}
                                    labelFor="informacion"
                                    inline={true}
                                >
                                    <Button className="bp3-danger" icon="document" onClick={() => this.generarInforme()} >{i18next.t("generate",{ns:'global'})}</Button>
                                </FormGroup>
                            :
                                <Spinner />
                        }

                    <div style={{ width: '100%', height: '600px'}} id="informe-pdf">
                    </div>
                </Card>
            </div>
            
            </>
        )
    }
}


export default withTranslation()(withApollo(ImprimirInformacionEmpresa))