import React from "react"
import { Card, Elevation, Radio, RadioGroup, InputGroup, FormGroup } from "@blueprintjs/core"

export default class EmpleadosIRPF extends React.Component {

    state={
        irpfValue: null,
        nifConyuge:null
    }

    render(){

        const handleRadioChange = (event) => {
            this.setState({irpfValue: event.currentTarget.value})
            if(event.currentTarget.value!==2)this.setState({nifConyuge:null})
        }

        const handleCambiarNifConyuge = (event) => {
            this.setState({nifConyuge: event.currentTarget.value})
        }

        return (
            <>
                <Card interactive={true} elevation={Elevation.TWO}>
                        <RadioGroup
                            label="Situación familiar a efectos de IRPF:"
                            name="irpf-radio"
                            onChange={handleRadioChange}
                            selectedValue={this.state.irpfValue}
                        >
                            <Radio label="Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente y sometidos a patria potestad prorrograda o rehabilitada que conviven exclusivamente con Vd., sin convivir también con el otro progenitor, siempre que proceda consignar al menos un hijo o descendiente en el apartado 2 de este documento." value="1" />
                            <Radio label="Casado/a y no separado/a legalmente cuyo cónyuge no obtiene rentas superiores a 1500 euros anuales, excluidas las rentas." value="2" />
                                {this.state.irpfValue === "2"? 
                                <FormGroup
                                    label="NIF del conyuge:"
                                    labelFor="nif-conyuge"
                                >
                                    <InputGroup maxLength={10} value={this.state.nifConyuge} onChange={handleCambiarNifConyuge} />
                                </FormGroup>
                                : null}
                            <Radio label="Situación familiar distinta de las dos anteriores (Solteros sin hijos, casados cuyo cónyuge obtiene rentas superioes a 1500 euros anuales, etc.) (Marque también esta casilla si no desea manifestar su situación familiar)." value="3" />
                        </RadioGroup>
                </Card>
            </>
        )
    }
}