import React, { Component } from 'react';
import { Card } from "@blueprintjs/core"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class Links extends Component {
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("links.title",{ns:"page"})

        return (
            <>
                <div className="pixelAlrededor">
                    <Card id="enlaces-interes">
                        <h5><a href="#enlaces-interes">{i18next.t("links.enlaces",{ns:"page"})}</a></h5>
                        <div className="tablaTercio">
                            <a class="bp3-button bp3-icon-git-repo" role="button" target="_blank"  rel="noopener noreferrer" href="http://www.congreso.es/">{i18next.t("links.congreso",{ns:"page"})}</a>
                        </div>
                        <div className="tablaTercio">
                            <a class="bp3-button bp3-icon-git-repo" role="button" target="_blank"  rel="noopener noreferrer" href="https://boe.es">{i18next.t("links.boletin",{ns:"page"})}</a>
                        </div>
                        <div className="tablaTercio">
                            <a class="bp3-button bp3-icon-git-repo" role="button" target="_blank"  rel="noopener noreferrer" href="https://boe.es/legislacion/">{i18next.t("links.legislacion",{ns:"page"})}</a>
                        </div>
                        <br />
                        <br />
                        <br />
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Links)