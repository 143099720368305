import React, { Component } from "react";
import {Card, Elevation, InputGroup, FormGroup, Button} from "@blueprintjs/core"
import Logotipo from "../../Assets/logo_lex.png"
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost'
import moment from 'moment';

export default class PerfilUsuario extends Component {

    state = {
        username:null,
        nombre:null,
        apellidos:null,
        fechaInicio: null,
        fechaFin: null,
        numUsuariosContratados: 0,
        numEmpresasContratadas: 0,
        numClientesEmpresa:0,
        numUsuariosEmpresa:0,
        contraseñaNueva1: null,
        contraseñaNueva2: null,
        logotipo: Logotipo,
        idUsuario:null,
        correoIncorrecto:false,
        usuarioAcutal:null
    }

    cambiarUsername = (e) => {
        this.setState({username: e.target.value})
    }

    cambiarNombre = (e) => {
        this.setState({nombre: e.target.value})
    }

    cambiarApellido = (e) => {
        this.setState({apellidos: e.target.value})
    }

    cambiarEmail = (e) => {
        this.setState({email: e.target.value})
    }

    cambiarContraseñaVieja = (e) => {
        this.setState({contraseñavieja: e.target.value})
    }

    cambiarContraseñaNueva1 = (e) => {
        this.setState({contraseñaNueva1: e.target.value})
    }

    cambiarContraseñaNueva2 = (e) => {
        this.setState({contraseñaNueva2: e.target.value})
    }

    componentDidMount=async()=>{

        //obtenemos los datos del usuario logueado en la aplicacion
        let cliente = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/bdds",
            credentials: 'include',
        });

    
        await cliente
        .query({
            query: gql`
            {obtenerUsuariosLoginActual{ID_USUARIO,USUARIO,NOMBRE,APELLIDOS,ID_PERFIL}}
            `,
            fetchPolicy:'network-only'
        }).then(async result1=>{    
                console.log(result1)
            this.setState({
                username:result1.data.obtenerUsuariosLoginActual.USUARIO,
                nombre:result1.data.obtenerUsuariosLoginActual.NOMBRE,
                apellidos:result1.data.obtenerUsuariosLoginActual.APELLIDOS,
                idUsuario:result1.data.obtenerUsuariosLoginActual.ID_USUARIO
            })

            await cliente
            .query({
                query: gql`{EmpresasUsuarios(FILTROS:{ID_USUARIO:${result1.data.obtenerUsuariosLoginActual.ID_USUARIO}}){ID_EMPRESA,EMPRESA{NOMBRE,FECHA_CREACION,FECHA_FIN,NUMERO_USUARIOS,NUMERO_EMPRESAS},ID_USUARIO}}`,fetchPolicy:'network-only',
            })
            .then(async result =>{

                if(result1.data.obtenerUsuariosLoginActual.ID_PERFIL==="2"){

                    this.setState({
                        numEmpresasContratadas:result.data.EmpresasUsuarios[0].EMPRESA.NUMERO_EMPRESAS,
                        numUsuariosContratados:result.data.EmpresasUsuarios[0].EMPRESA.NUMERO_USUARIOS,
                        fechaInicio:moment(result.data.EmpresasUsuarios[0].EMPRESA.FECHA_CREACION).format("DD-MM-YYYY"),
                        fechaFin:moment(result.data.EmpresasUsuarios[0].EMPRESA.FECHA_FIN).format("DD-MM-YYYY")
                    })
    
                    await cliente
                    .query({
                        query: gql`{obtenerNumUsuarios(ID_EMPRESA:${result.data.EmpresasUsuarios[0].ID_EMPRESA})}`,fetchPolicy:'network-only',
                    })
                    .then(result =>{
                        this.setState({numUsuariosEmpresa:result.data.obtenerNumUsuarios})
            
                    });
            
                    await cliente
                    .query({
                        query: gql`{obtenerNumBdds(ID_EMPRESA:${result.data.EmpresasUsuarios[0].ID_EMPRESA})}`,fetchPolicy:'network-only',
                    })
                    .then(async result =>{
                    
                        await this.setState({numClientesEmpresa:result.data.obtenerNumBdds})
                    });

                }

            });
            this.setState({usuarioAcutal:result1.data.obtenerUsuariosLoginActual})
        })
        
       
    
    }

    render() {

        const guardarCambios=()=>{

            if(!this.state.username)return
            if(!this.state.nombre || this.state.nombre==="")return
            if(!this.state.apellidos || this.state.apellidos==="")return

            //validamos que el usuario sea un correo.
            let patron=/^[\w]+@{1}[\w]+\.[a-z]{2,3}$/
            if(!patron.test(this.state.username)){
                this.setState({correoIncorrecto:true})
                return
            }else{
                this.setState({correoIncorrecto:false})
            }
            

            if(this.state.contraseñaNueva1 !== this.state.contraseñaNueva2){
                return
            }

            //actualizamos el usuarios.
            let cliente = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/bdds",
                credentials: 'include',
            });
            
            //si no tenemos clave.
            if(!this.state.contraseñaNueva1){

                cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        actualizarUsuarioLogin(ID_USUARIO:${this.state.idUsuario},VALORES:{
                            USUARIO:"${this.state.username}",
                            NOMBRE:"${this.state.nombre}"
                            APELLIDOS:"${this.state.apellidos}"
                        }){ID_USUARIO,USUARIO}
                    }
                    `
                }).then((result)=>{
                    alert("El usuario se ha actualizado correctamente")
                }).catch(err=>{
                    alert("El nombre de usuario está en uso por otro usario de la aplicación.")
                })

            }else{

                //si nos han pasado una clave
                let patronClave=/(?=^.{6,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*/

                if(!patronClave.test(this.state.contraseñaNueva1)){
                    alert("La clave no es válida")
                    return
                }
        
                cliente
                .mutate({
                    mutation: gql`
                    mutation{
                        actualizarUsuarioLogin(ID_USUARIO:${this.state.idUsuario},VALORES:{
                            USUARIO:"${this.state.username}",
                            NOMBRE:"${this.state.nombre}"
                            APELLIDOS:"${this.state.apellidos}"
                            CLAVE:"${this.state.contraseñaNueva1}"
                        }){ID_USUARIO,USUARIO}
                    }
                    `
                }).then((result)=>{
                    alert("El usuario se ha actualizado correctamente")
                }).catch(err=>{
                    alert("El nombre de usuario está en uso por otro usario de la aplicación.")
                })

            }
  

        }

        const cancelarCambios=()=>{

            //obtenemos los datos del usuario logueado en la aplicacion
            let cliente = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/bdds",
                credentials: 'include',
            });
            
            cliente
            .query({
                query: gql`
                {
                    obtenerUsuariosLoginActual{
                        ID_USUARIO,
                        USUARIO,    
                        NOMBRE,
                        APELLIDOS
                    }
                }
                `
            }).then((result)=>{
                this.setState({
                    username:result.data.obtenerUsuariosLoginActual.USUARIO,
                    nombre:result.data.obtenerUsuariosLoginActual.NOMBRE,
                    apellidos:result.data.obtenerUsuariosLoginActual.APELLIDOS,
                    idUsuario:result.data.obtenerUsuariosLoginActual.ID_USUARIO
                })
            })

        }

        return(
            <>
            <div className="pixelAlrededorGrande">
                <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.actividades= input; }} className="tablaMitadIzquierda pixelAlrededor" >
                    <FormGroup
                        label="Nombre de usuario"
                        labelFor="nombre-usuario"
                        intent="danger"
                        helperText={!this.state.username?"Debe rellenar el nombre de usuario":(this.state.correoIncorrecto?"Debe escribir un correo válido":"")}
                    >
                        <InputGroup value={this.state.username} intent={!this.state.username?"danger":(this.state.correoIncorrecto?"danger":"primary")} onChange={this.cambiarUsername} />
                    </FormGroup>
                    <FormGroup
                        label="Nombre"
                        labelFor="nombre"
                        intent="danger"
                        helperText={!this.state.nombre || this.state.nombre===""?"Debe rellenar el nombre":""}
                    >
                        <InputGroup value={this.state.nombre} intent={!this.state.nombre || this.state.nombre===""?"danger":"primary"} onChange={this.cambiarNombre} />
                    </FormGroup>
                    <FormGroup
                        label="Apellidos"
                        labelFor="apellidos"
                        intent="danger"
                        helperText={!this.state.apellidos || this.state.apellidos===""?"Debe rellenar el apellido":""}
                    >
                        <InputGroup value={this.state.apellidos} intent={!this.state.apellidos || this.state.apellidos===""?"danger":"primary"} onChange={this.cambiarApellido} />
                    </FormGroup>
                    <br />
                    <br />
                    <FormGroup
                        label={this.state.contraseñaNueva1 === this.state.contraseñaNueva2? "Contraseña Nueva" : "Contraseña Nueva (Las contraseñas deben coincidir)"}
                        labelFor="contrasena-nueva"
                        intent={this.state.contraseñaNueva1 === this.state.contraseñaNueva2? "primary" : "danger"}
                    >
                        <p>La longitud mínima es de 6 carácteres y debe cumplir 3 de los siguientes criterios:</p>
                        <ul>
                            <li>Una letra minúscula</li>
                            <li>Una letra mayúscula</li>
                            <li>Un número</li>
                            <li>Un carácter especial</li>
                        </ul>
                        <InputGroup type="password" label="Contraseña nueva" value={this.state.cambiarContraseñaNueva1} onChange={this.cambiarContraseñaNueva1} intent={this.state.contraseñaNueva1 === this.state.contraseñaNueva2? "primary" : "danger"} />
                            <br />
                        <InputGroup type="password" label="Contraseña nueva" value={this.state.cambiarContraseñaNueva2} onChange={this.cambiarContraseñaNueva2} intent={this.state.contraseñaNueva1 === this.state.contraseñaNueva2? "primary" : "danger"} />
                    </FormGroup>
                    <Button 
                        alignText="right"
                        icon="cross"
                        text="Cancelar"
                        intent="danger"
                        onClick={cancelarCambios}
                        className="bp3-intent-primary iconoDerecha bp3-minimal"
                    />
                    <Button 
                        alignText="right"
                        icon="floppy-disk"
                        text="Guardar"
                        intent="danger"
                        onClick={guardarCambios}
                        className="bp3-intent-primary iconoDerecha bp3-minimal"
                    />
                </Card>
                {this.state.usuarioAcutal?
                    this.state.usuarioAcutal.ID_PERFIL==="2"?
                        <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.actividades= input; }} className="tablaMitadDerecha pixelAlrededor" >
                        <img src={this.state.logotipo} alt="logo de su empresa" className="logoMenu" />
                        <p>Periodo contratado, desde: {this.state.fechaInicio} hasta: {this.state.fechaFin} </p>
                        <p>Número de usuarios: <b>{this.state.numUsuariosEmpresa}/{this.state.numUsuariosContratados}</b></p>
                        <p>Número de empresas: <b>{this.state.numClientesEmpresa}/{this.state.numEmpresasContratadas}</b></p>
                        </Card>
                    :
                    <></>
                :<></>}
         
            </div>
        </>
        )
    }
}