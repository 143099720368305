import React from "react"
import { Card, FormGroup, InputGroup, Radio, RadioGroup,Intent,Classes,Tooltip,AnchorButton,Button,Dialog } from "@blueprintjs/core"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import const_table from "../BootstrapTable";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

class Denunciante extends React.Component {
    
    state={
        nombreApellidos:null,
        dni:null,
        telefono:null,
        correo:null,
        testigo:true,
        relacionEmpresaSeleccionada:null,
        relacionEmpresa:[
        ],
        nuevaRelacionEmpresa:null,
        abrirModalRelacion:false
    }
    
    reiniciarEstado() {
        this.setState({

            testigo: false,
            nombreApellidos:null,
            dni:null,
            telefono:null,
            correo:null,
            relacionEmpresaSeleccionada:null,
            nuevaRelacionEmpresa:null,
            abrirModalRelacion:false
            
        });
    }

    cargarRelaciones=async()=>{
        

        await client.query({
            query:gql`{relacionesEmpresa{ID_RELACION_EMPRESA,DESCRIPCION}}`
        }).then(async(result)=>{
            //console.log(result)
            let relaciones=[]
            result.data.relacionesEmpresa.forEach(async(relacion)=>{
             
                relaciones.push({"value":relacion.ID_RELACION_EMPRESA,"label":relacion.DESCRIPCION}) 

            })
            this.setState({relacionEmpresa:relaciones})
        })

        if(this.props.datosDenuncia){
            this.setState({nombreApellidos:this.props.datosDenuncia?this.props.datosDenuncia.denunciante:""})
            this.setState({dni:this.props.datosDenuncia?this.props.datosDenuncia.dni:""})
            this.setState({testigo:this.props.datosDenuncia?this.props.datosDenuncia.testigo:""})
            this.setState({telefono:this.props.datosDenuncia?this.props.datosDenuncia.telefono:""})
            this.setState({correo:this.props.datosDenuncia?this.props.datosDenuncia.correo:""})
            //buscamos la relacion
            let relSel=this.state.relacionEmpresa.find(relacion=>{
                return relacion.value===this.props.datosDenuncia.idRelacion
            })
            this.setState({relacionEmpresaSeleccionada:relSel})
        }

    }
    componentDidMount=async()=>{

        await this.cargarRelaciones()
    }


    render(){


        const handleCambiarNombreApellidos=(ev)=>{
            this.setState({nombreApellidos:ev.currentTarget.value})
        }  
        
        const handleCambiarDni=(ev)=>{
            this.setState({dni:ev.currentTarget.value})
        } 
        
        const handleCambiarTelefono=(ev)=>{
            this.setState({telefono:ev.currentTarget.value})
        }  

        const handleCambiarCorreo=(ev)=>{
            this.setState({correo:ev.currentTarget.value})
        }  

        const handleCambiarTestigo=(ev)=>{
            this.setState({testigo:!this.state.testigo})
        }

        const handleSeleccionarRelacion=(value)=>{
            this.setState({relacionEmpresaSeleccionada:value})
        }

        const abrirModalRelacion=()=>{
            this.setState({
                nuevaRelacionEmpresa:null,
                modalRelacionAbierto:true
            })
        }

        const cerrarModal=()=>{
            this.setState({modalRelacionAbierto:false})
        }

        const handlenuevaRelacionEmpresa=(ev)=>{
            this.setState({nuevaRelacionEmpresa:ev.currentTarget.value})
        }

        const anadirRelacion=(ev)=>{

            if(!this.state.nuevaRelacionEmpresa)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.relacionEmpresa.find(relacion=>{
                return relacion.label===this.state.nuevaRelacionEmpresa
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos la relacion empresa.
            client.mutate({
                mutation: gql`
                  mutation{crearRelacionEmpresa(VALORES:{DESCRIPCION:"${this.state.nuevaRelacionEmpresa}"}){ID_RELACION_EMPRESA}}
                `
                }).then(async result=>{
                   
                    await this.cargarRelaciones()

                    let relacionCreada=result.data.crearRelacionEmpresa
                    let relacionSel=this.state.relacionEmpresa.find(relacion=>{
                        return relacion.value===relacionCreada.ID_RELACION_EMPRESA
                    })
                    this.setState({relacionEmpresaSeleccionada:relacionSel})
                    cerrarModal()

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

        }
        return (
            <>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("denuncias.relacionempresa",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}nuevaRelacionEmpresa
                    isOpen={this.state.modalRelacionAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("denuncias.enterrelacionempresa",{ns:"page"})+":"}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevaRelacionEmpresa?"":i18next.t("denuncias.emptyrelacionempresa",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-relacion" onChange={handlenuevaRelacionEmpresa} intent={this.state.nuevaRelacionEmpresa?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirRelacion}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.nombreyapellido",{ns:"page"}).toLowerCase())}
                            labelFor="nombre-apellidos"
                            intent="danger"
                            helperText={this.state.nombreApellidos?"":i18next.t("denuncias.emptynombreyapellido",{ns:"page"})}
                        >
                            <InputGroup onChange={handleCambiarNombreApellidos} value={this.state.nombreApellidos} intent={this.state.nombreApellidos?"":"danger"} id="nombre-apellidos" />
                        </FormGroup>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.documentoidentificatorio",{ns:"page"}).toLowerCase())}
                            labelFor="documento-identificativo"
                            intent="danger"
                            helperText={this.state.dni?(this.props.dniInvalido?i18next.t("identificacion.invaliddni",{ns:"page"}):""):i18next.t("denuncias.emptydni",{ns:"page"})}
                        >
                            <InputGroup onChange={handleCambiarDni} placeholder={"12345678N"} value={this.state.dni} maxLength={10} intent={this.state.dni && !this.props.dniInvalido?"primary":"danger"} id="documento-identificativo" />
                        </FormGroup>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.telefono",{ns:"page"}).toLowerCase())}
                            labelFor="telefono"
                            intent="danger"
                            helperText={this.state.telefono?(this.props.telefonoInvalido?i18next.t("denuncias.validtelefono",{ns:"page"}):""):i18next.t("denuncias.emptytelefono",{ns:"page"})}
                        >
                            <InputGroup value={this.state.telefono} maxLength={9} onChange={handleCambiarTelefono}  intent={this.state.telefono && !this.props.telefonoInvalido?"primary":"danger"} id="telefono" />
                        </FormGroup>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.correo",{ns:"page"}).toLowerCase())}
                            labelFor="email"
                            intent="danger"
                            helperText={this.props.correoInvalido?i18next.t("denuncias.validcorreo",{ns:"page"}):""}
                        >
                            <InputGroup value={this.state.correo} intent={!this.props.correoInvalido?"primary":"danger"} onChange={handleCambiarCorreo} id="email" />
                        </FormGroup>
                        <RadioGroup
                            label={i18next.t("denuncias.estestigodirecto",{ns:"page"})}
                            name="group"
                            onChange={handleCambiarTestigo}
                            selectedValue={this.state.testigo}
                            inline={true}
                        >
                            <Radio label={i18next.t("yes",{ns:"global"})} value={true}/>
                            <Radio label={i18next.t("no",{ns:"global"})} value={false}/>
                        </RadioGroup>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.relacionempresa",{ns:"page"}).toLowerCase())}
                            labelFor="relacion-empresa"
                            intent="danger"
                            helperText={this.state.relacionEmpresaSeleccionada?"":i18next.t("denuncias.noselectrelacion",{ns:"page"})}
                        >
                            <div style={this.state.relacionEmpresaSeleccionada?{}:{border:"1px solid red"}}>
                                <Select
                                    options={this.state.relacionEmpresa}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={handleSeleccionarRelacion}
                                    value={this.state.relacionEmpresa.filter(relacion => relacion.value === (this.state.relacionEmpresaSeleccionada?this.state.relacionEmpresaSeleccionada.value:null))}
                                />
                            </div>
                        </FormGroup>
                        <Button 
                            alignText="right"
                            icon="add"
                            id="btn-agregar-relacion"
                            className="iconoIzquierda"
                            onClick={abrirModalRelacion}
                        />
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Denunciante);