import React from "react"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class Doctrina extends React.Component {
    render(){

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("doctrina.title",{ns:"page"})

        return (
            <>
                <h1>{i18next.t("doctrina.title",{ns:"page"})}</h1>
            </>
        )
    }
}

export default withTranslation()(Doctrina)