import React, { Component } from 'react';
import { Card, FormGroup, TextArea, Elevation, Button, Callout, AnchorButton, Dialog, Spinner } from '@blueprintjs/core';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from '@react-pdf/renderer';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
      dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:135,
    paddingTop:30,
    paddingLeft: 40,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '90%'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
  
})

class Actividades extends Component {

    state={
        modalImprimirA:false,
        cargandoImprimir:false,
        datosEmpresa:null,
        objeto:null,
        prodcutos:null,
        servicios:null,
        distribucion:null,
        zonas:null,
        actividades:null,
        activiadesEntorno:null,
        actividadesMedio:null,
        actividadesMedioRiesgo:null,
        existeActividad:false
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    handleCambiarObjeto = (ev) => {
        //console.log(ev)
        //////console.log("imprimir");
        this.setState({objeto:ev.currentTarget.value})
    }
    handleCambiarProductos = (ev) => {
        //////console.log("imprimir");
        this.setState({prodcutos:ev.currentTarget.value})
    }
    handleCambiarServicios = (ev) => {
        //////console.log("imprimir");
        this.setState({servicios:ev.currentTarget.value})
    }
    handleCambiarDistribucion = (ev) => {
        //////console.log("imprimir");
        this.setState({distribucion:ev.currentTarget.value})
    }
    handleCambiarZona = (ev) => {
        //////console.log("imprimir");
        this.setState({zonas:ev.currentTarget.value})
    }
    handleCambiarActividades = (ev) => {
        //////console.log("imprimir");
        this.setState({actividades:ev.currentTarget.value})
    }
    handleCambiarActividadesEntorno = (ev) => {
        //////console.log("imprimir");
        this.setState({activiadesEntorno:ev.currentTarget.value})
    }
    handleCambiarActividadMedio = (ev) => {
        this.setState({actividadesMedio:ev.currentTarget.value})

        //////console.log("imprimir");
    }
    handleCambiarActividadMedioRiesgo = (ev) => {
        //////console.log("imprimir");
        this.setState({actividadesMedioRiesgo:ev.currentTarget.value})
    }

    componentDidMount(props){

        this.props.client
       .query({
         query: gql`
           {actividades{
            OBJETO_SOCIAL,
            PRODUCTOS,
            SERVICIOS,
            CANALES,
            ZONAS,
            RIESGO_INTRINSECO,
            RIESGO_ENTORNO,
            IMPACTO_MEDIOAMBIENTAL,
            RIESGO_MEDIOAMBIENTAL}}
         `,
         fetchPolicy:'network-only'
       })
       .then(result =>{
           if(result.data.actividades.length>0){

                this.setState({existeActividad:true})
                this.setState({objeto:result.data.actividades[0].OBJETO_SOCIAL})
                this.setState({prodcutos:result.data.actividades[0].PRODUCTOS})
                this.setState({servicios:result.data.actividades[0].SERVICIOS})
                this.setState({distribucion:result.data.actividades[0].CANALES})
                this.setState({zonas:result.data.actividades[0].ZONAS})
                this.setState({actividades:result.data.actividades[0].RIESGO_INTRINSECO})
                this.setState({activiadesEntorno:result.data.actividades[0].RIESGO_ENTORNO})
                this.setState({actividadesMedio:result.data.actividades[0].IMPACTO_MEDIOAMBIENTAL})
                this.setState({actividadesMedioRiesgo:result.data.actividades[0].RIESGO_MEDIOAMBIENTAL})

           }
       });

       this.obtenerLogoEmpresa()
       
   }

    
    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
        
        return datosEmpresa
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }

    generarInformeA =async () => {
        this.setState({
            modalImprimirA: true,
            cargandoImprimir:true
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeA(), document.getElementById('informe-pdf-a'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeA(), document.getElementById('informe-pdf-a'))
                })
            }
        })
        
    }

    InformeA = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("actividades.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.objetosocial",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.objeto}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.productos",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.prodcutos}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.servicios",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.servicios}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.canales",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.distribucion}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.zonas",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.zonas}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.actividadesintrínseco",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.actividades}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.actividadesentorno",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.activiadesEntorno}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.actividadesimpactomedioambiental",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.actividadesMedio}</Text>
                </View>
                <View style={styles.rowTitle}>
                    <Text style={styles.labelTitle}>{i18next.t("actividades.actividadesriesgomedioambiental",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textData}>{this.state.actividadesMedioRiesgo}</Text>
                </View>
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {
        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("actividades.title",{ns:"page"});
        const cerrarModalImprimirA=()=>{
            this.setState({modalImprimirA: false})
        }

        const guardarCambios=()=>{            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.existeActividad){
               
                this.props.client
                .mutate({
                mutation: gql`
                     mutation{actualizarActividad(VALORES:{
                         OBJETO_SOCIAL:"""${this.state.objeto?this.state.objeto:""}""",
                         PRODUCTOS:"""${this.state.prodcutos?this.state.prodcutos:""}""",
                         SERVICIOS:"""${this.state.servicios?this.state.servicios:""}""",
                         CANALES:"""${this.state.distribucion?this.state.distribucion:""}""",
                         ZONAS:"""${this.state.zonas?this.state.zonas:""}""",
                         RIESGO_INTRINSECO:"""${this.state.actividades?this.state.actividades:""}""",
                         RIESGO_ENTORNO:"""${this.state.activiadesEntorno?this.state.activiadesEntorno:""}""",
                         IMPACTO_MEDIOAMBIENTAL:"""${this.state.actividadesMedio?this.state.actividadesMedio:""}""",
                         RIESGO_MEDIOAMBIENTAL:"""${this.state.actividadesMedioRiesgo?this.state.actividadesMedioRiesgo:""}"""
                        }){OBJETO_SOCIAL}}`
                })
                .then(result =>{
                
                    alert(i18next.t("updatecorrecto",{ns:"global"}))

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else{
               
                this.props.client
                .mutate({
                    mutation: gql`
                    mutation{crearActividad(VALORES:{
                        OBJETO_SOCIAL:"""${this.state.objeto?this.state.objeto:""}""",
                        PRODUCTOS:"""${this.state.prodcutos?this.state.prodcutos:""}""",
                        SERVICIOS:"""${this.state.servicios?this.state.servicios:""}""",
                        CANALES:"""${this.state.distribucion?this.state.distribucion:""}""",
                        ZONAS:"""${this.state.zonas?this.state.zonas:""}""",
                        RIESGO_INTRINSECO:"""${this.state.actividades?this.state.actividades:""}""",
                        RIESGO_ENTORNO:"""${this.state.activiadesEntorno?this.state.activiadesEntorno:""}""",
                        IMPACTO_MEDIOAMBIENTAL:"""${this.state.actividadesMedio?this.state.actividadesMedio:""}""",
                        RIESGO_MEDIOAMBIENTAL:"""${this.state.actividadesMedioRiesgo?this.state.actividadesMedioRiesgo:""}"""
                    }){OBJETO_SOCIAL}}`
                }).then(result =>{
                
                    alert(i18next.t("insertcorrecto",{ns:"global"}))    
                    this.setState({
                        existeActividad:true
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }
        }

        return (

            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirA}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title={i18next.t("print",{ns:"global"})+" "+i18next.t("actividades.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirA}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-a">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <div className="pixelAlrededor tablaSesenta">
                    <Card interactive={true} elevation={Elevation.TWO} ref={(input) => { this.actividades= input; }} >
                        <FormGroup
                            label={i18next.t("actividades.objetosocial",{ns:"page"})}
                            labelFor="objeto-social"
                        >
                            <TextArea maxlength={300} id="objeto-social" fill={true} onChange={this.handleCambiarObjeto} value={this.state.objeto} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.productos",{ns:"page"})}
                            labelFor="productos-ofrecen"
                        >
                            <TextArea maxlength={300} id="productos-ofrecen" fill={true} onChange={this.handleCambiarProductos} value={this.state.prodcutos} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.servicios",{ns:"page"})}
                            labelFor="servicios-prestan"
                        >
                            <TextArea maxlength={300} id="servicios-prestan" fill={true} onChange={this.handleCambiarServicios} value={this.state.servicios} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.canales",{ns:"page"})}
                            labelFor="canales-distribucion"
                        >
                            <TextArea maxlength={300} id="canales-distribucion" fill={true} onChange={this.handleCambiarDistribucion} value={this.state.distribucion} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.zonas",{ns:"page"})}
                            labelFor="zonas-geograficas"
                        >
                            <TextArea maxlength={300} id="zonas-geograficas" fill={true} onChange={this.handleCambiarZona} value={this.state.zonas} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.actividadesintrínseco",{ns:"page"})}
                            labelFor="actividades-riesgo"
                        >
                            <TextArea maxlength={300} id="actividades-riesgo" fill={true} onChange={this.handleCambiarActividades} value={this.state.actividades} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.actividadesentorno",{ns:"page"})}
                            labelFor="actividades-riesgo-entorno"
                        >
                            <TextArea maxlength={300} id="actividades-riesgo-entorno" fill={true} onChange={this.handleCambiarActividadesEntorno} value={this.state.activiadesEntorno} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.actividadesimpactomedioambiental",{ns:"page"})}
                            labelFor="actividades-impacto-medioambiental"
                        >
                            <TextArea maxlength={300} id="actividades-impacto-medioambiental" fill={true} onChange={this.handleCambiarActividadMedio} value={this.state.actividadesMedio}/>
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("actividades.actividadesriesgomedioambiental",{ns:"page"})}
                            labelFor="actividades-riesgo-medioambiental"
                        >
                            <TextArea maxlength={300} id="actividades-riesgo-medioambiental" fill={true} onChange={this.handleCambiarActividadMedioRiesgo} value={this.state.actividadesMedioRiesgo}/>
                        </FormGroup>
                        <Button className="iconoDerecha bp3-intent-danger" icon="floppy-disk" onClick={guardarCambios}>{i18next.t("guardar",{ns:"global"})}</Button>
                        <Button className="iconoDerecha bp3-intent-danger" icon="print" onClick={()=>this.generarInformeA()}>{i18next.t("print",{ns:"global"})}</Button>
                        <br />
                        <br />
                    </Card>
                </div>
                <div className="pixelAlrededor tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("actividades.leydescripcion",{ns:"page"})}</Callout>
                    <br />
                    <AnchorButton onClick={()=> window.open("https://boe.es/diario_boe/txt.php?id=BOE-A-2012-15595", "_blank")}>{i18next.t("actividades.ley",{ns:"page"})}</AnchorButton>
                    <br />
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(withApollo(Actividades)) 