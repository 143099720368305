import React from "react"
import { Navbar, Button, Alignment, Tooltip, Dialog, Classes, AnchorButton, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { Table, Column, Cell,RegionCardinality } from "@blueprintjs/table"

export default class Votos extends React.Component {

    state={
        votos: [],
        modalVotos: false,
        votacion: 1,
        votoSeleccionado:null
    }

    componentDidMount=()=>{


        //si editamos votos
        if(this.props.acuerdo && Object.keys(this.props.acuerdo).length>0){

            this.setState({
                votos:this.props.acuerdo.votos,
            })


        }else{

            if(!this.props.acta) return
            //si no rellenamos la tabla con los asistentes del acta 
            //con valor del voto en abstencion.
            let votos=[]
            for(let voto of this.props.asistentes){

                votos.push({
                    idPersona:voto.value,
                    nombre:voto.label,
                    voto:0
                })
    
            }
            this.setState({votos:votos})

        }
       

    }

    render(){

        const abrirModal = () => {

            if(!this.state.votoSeleccionado){
                alert("Debe seleccionar un voto")
                return
            }
            this.setState({votacion:this.state.votoSeleccionado.voto})

            this.setState({ modalVotos: true })
        }

        const cerrarModal = () => {
            this.setState({ modalVotos: false })
        }


        const cellRendererNombre = (rowIndex) => {
            return <Cell>{this.state.votos[rowIndex].nombre}</Cell>
        }

        const cellRendererVoto = (rowIndex) => {

            let tipo=""
            // eslint-disable-next-line default-case
            switch(this.state.votos[rowIndex].voto){
                case -1:
                    tipo="En contra"
                break;
                case 1:
                    tipo="A Favor"
                break;
                case 0:
                    tipo="Abstención"
                break;
            }
          

            return <Cell>{tipo}</Cell>
        }

        const añadirNuevo = () => {
            let voto=this.state.votoSeleccionado
            voto.voto=this.state.votacion
            cerrarModal()
        }

        const handleVotacion = (event) => {
            this.setState({votacion: parseInt(event.currentTarget.value)})
        }

        const seleccionarVoto=(value)=>{

            if(value.length===0)return

            let seleccionada=this.state.votos[value[0].rows[0]]                
            this.setState({
                votoSeleccionado:seleccionada,
            })

        }


        return(
            <>
                 <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="LexControl"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalVotos}
                >
                <div className={Classes.DIALOG_BODY}>
                <p>Seleccione el sentido del voto:</p>
                    <div id="sentido-voto">
                        <RadioGroup
                            onChange={handleVotacion}
                            selectedValue={this.state.votacion}
                            large={true}
                        >
                            <Radio label="A Favor" value={1} />
                            <Radio label="En contra" value={-1} />
                            <Radio label="Abstención" value={0} />
                        </RadioGroup>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton id="guardar-sentido-voto" intent={Intent.PRIMARY} onClick={añadirNuevo}>
                        Aceptar
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        Cancelar
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>

                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Tooltip content="Añadir resultado de votación">
                            <Button
                                icon="insert"
                                id="btn-cambiar-voto"
                                text="Voto"
                                intent="danger"
                                onClick={abrirModal}
                                className="iconoDerecha bp3-minimal"
                            />
                        </Tooltip>
                    </Navbar.Group>
                </Navbar>
                <div id="tabla-votos">
                    <Table enableRowHeader={false} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarVoto} defaultColumnWidth={550} numRows={this.state.votos.length}>
                        <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                        <Column name="Voto"  cellRenderer={cellRendererVoto}/>   
                    </Table>
                </div>
            </>
        )
    }
}