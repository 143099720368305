/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, FormGroup, Navbar, InputGroup,ButtonGroup,Popover, Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, Callout, Elevation, Spinner} from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { DateInput } from "@blueprintjs/datetime"
import moment from 'moment';
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';
import styles from './stylesPrint';

class Auditores extends Component {

    state={
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        personas:[],
        auditores: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        actividadesSelect: [
        ],
        tipoSectorSelect: [
        ],
        tipoSectorVolumen: [
        ],
        perfilesRiesgo: [
        ],
        personaSeleccionada:null,
        sectorActividadSeleccionado:null,
        auditorSectorSeleccionado:null,
        tipoVolumenSeleccionado:null,
        perfilesRiesgoSeleccionado:null,
        nuevoSector:null,
        auditorSeleccionado:null,
        todosAsesorias:[],
        fechaSeleccionada:null,
        cargando:false,
        cargandoForm:false,
        columnWidths: [100,100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirA:false,
        modalImprimirA: false,
        cargandoImprimirAG:false,
        modalImprimirAG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas: null,
        reloadTable: false

        
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    cargarauditores=async()=>{

        this.setState({cargando:true})
    
        await this.props.client.query({
            query:gql`{auditores{ID_AUDITOR,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA_NOMBRAMIENTO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            //console.log(result)
            let auditores=[]
            for(let auditor of result.data.auditores){

                let nombreauditor=""
                ////console.log(auditor)
                //Si es persona fisica...
                if(auditor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                   this.state.personasFisicas.find((persona)=>{
                       if (persona.ID_PERSONA===auditor.ID_AUDITOR) {
                        nombreauditor=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                       }
                   }) 
                   
                //Si es persona juridica
                }else if(auditor.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find((persona)=>{
                        if (persona.ID_PERSONA===auditor.ID_AUDITOR) {
                         nombreauditor=`${persona.RAZON_SOCIAL}`
                        }
                    }) 
                    
                }
                auditores.push({"id":auditor.ID_AUDITOR,"nombre":nombreauditor,"fechaNombramiento":auditor.FECHA_NOMBRAMIENTO}) 
            }
  
            this.setState({
                auditores:auditores,
                cargando:false
            })

        })

    }

    cargarActividades=async ()=>{

    

        //cargar combo sectores.
        await this.props.client.query({
            query:gql`{sectoresActividad{ID_SECTOR_ACTIVIDAD,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let actividades=[]
            result.data.sectoresActividad.forEach(async(actividad)=>{
             
                actividades.push({"value":actividad.ID_SECTOR_ACTIVIDAD,"label":actividad.DESCRIPCION}) 

            })
            this.setState({actividadesSelect:actividades})
        })
    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarPersonas=async ()=>{

        let personas=[]

        for(let persona of this.state.personasFisicas){
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        }

        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }
        
        this.setState({personas:personas})


    }

    cargarClasificacionesVolumen=async ()=>{
        //cargar combo tipo auditor sector.
        this.props.client.query({
            query:gql`{clasificacionesVolumen{ID_CLASIFICACION_VOLUMEN,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let clasificacionesVolumen=[]
            result.data.clasificacionesVolumen.forEach(async(tipo)=>{
            
                clasificacionesVolumen.push({"value":tipo.ID_CLASIFICACION_VOLUMEN,"label":tipo.DESCRIPCION}) 

            })
            this.setState({tipoSectorVolumen:clasificacionesVolumen})
        })
    }

    cargarPerfilesRiesgo=async ()=>{
        //cargar combo tipo auditor volumen.
        this.props.client.query({
            query:gql`{perfilesRiesgo{ID_PERFIL_RIESGO,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let perfilesRiesgo=[]
            result.data.perfilesRiesgo.forEach(async(tipo)=>{
            
                perfilesRiesgo.push({"value":tipo.ID_PERFIL_RIESGO,"label":tipo.DESCRIPCION}) 

            })
            this.setState({perfilesRiesgo:perfilesRiesgo})
        })
    }

    componentDidMount(props){

        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarauditores()
            
            this.setState({cargandoForm: false})
        });

        
    }


    generarImprimirA= async() => {
        this.setState({
            cargandoImprimirA:true,
            modalImprimirA: true,
            reloadTable: true
        }, async () => {
             this.setState({cargandoImprimirA:false}, () => {
                 ReactDOM.render(this.ImprimirA(), document.getElementById('generarA-pdf'))
             })
        })
    }

    generarImprimirAG= async() => {
        this.setState({
            cargandoImprimirAG:true,
            modalImprimirAG:true
        }, async () => {
             this.setState({cargandoImprimirAG:false}, () => {
                 ReactDOM.render(this.ImprimirAG(), document.getElementById('generarAG-pdf'))
             })
        })
    }

    ImprimirA=()=>{
        let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.auditorSeleccionado.id})
        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.auditors", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.auditor", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.auditorSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("name", { ns: "global" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{personaSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.appointmentDate", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{moment(this.state.auditorSeleccionado.fechaNombramiento).format('DD-MM-YYYY')}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirAG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.auditors", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.auditorsList", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.auditores?this.state.auditores.map((auditor, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'80%'}}>{i18next.t("name", { ns: "global" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>{i18next.t("partesInteresadas.appointmentDate", { ns: "page2" }).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{paddigLeft:2,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'80%'}}>{auditor.nombre?auditor.nombre:''}</Text>
                                    <Text style={{paddigLeft:2,textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'20%'}}>{auditor.fechaNombramiento?moment(auditor.fechaNombramiento).format('DD-MM-YYYY'):''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )
    
    render() {

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("partesInteresadas.auditors", { ns: "page2" })}`

        const abrirModalNuevo =async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }
            if (this.state.actividadesSelect.length===0) {
                await this.cargarActividades();
            }
            if (this.state.tipoSectorVolumen.length===0) {
                await this.cargarClasificacionesVolumen();
            }
            if (this.state.perfilesRiesgo.length===0) {
                await this.cargarPerfilesRiesgo();
            }
            
            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaSeleccionada:null,
                sectorActividadSeleccionado:null,
                auditorSectorSeleccionado:null,
                tipoVolumenSeleccionado:null,
                perfilesRiesgoSeleccionado:null,
                auditorSeleccionado:null,
                fechaSeleccionada:new Date()

            })
    
        }

        const abrirModalEditar =async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.auditorSeleccionado){
                alert(i18next.t("partesInteresadas.selectAuditor", { ns: "page2" }))
                return
            }

            if (this.state.actividadesSelect.length===0) {
                await this.cargarActividades();
            }
            if (this.state.tipoSectorVolumen.length===0) {
                await this.cargarClasificacionesVolumen();
            }
            if (this.state.perfilesRiesgo.length===0) {
                await this.cargarPerfilesRiesgo();
            }
            

            let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.auditorSeleccionado.id})

            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaSeleccionada:personaSel,
                fechaSeleccionada: new Date(this.state.auditorSeleccionado.fechaNombramiento),
                reloadTable: true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.auditorSeleccionado){
                alert(i18next.t("partesInteresadas.selectAuditor", { ns: "page2" }))
                return
            }

            this.setState({modalBorrarAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({
                borrarActivado: true,
                reloadTable: true

            })
        }        

        const escogeFecha = (nuevaFecha) => {
            this.setState({fechaSeleccionada: nuevaFecha})
        }

      
        const cambiarnuevoSector=(ev)=>{
            this.setState({nuevoSector:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };


        const añadirNuevo = () => {


            if(!this.state.personaSeleccionada){
                return
            }

            if(!this.state.fechaSeleccionada){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.auditores.find(auditor=>{return auditor.id===this.state.personaSeleccionada.value})
            if(existe){
                alert(i18next.t("partesInteresadas.auditorExists", { ns: "page2" }))
                return
            }

            this.setState({controlesDesactivados:true})
            
            this.props.client.mutate({
                mutation:gql`mutation{crearAuditor(VALORES:{ID_AUDITOR:${this.state.personaSeleccionada.value},FECHA_NOMBRAMIENTO:"${moment(this.state.fechaSeleccionada).format('YYYY-MM-DD')}"}){ID_AUDITOR}}`

            }).then(async ()=>{
            
                alert(i18next.t("insertcorrecto", { ns: "global" }))
                await this.cargarauditores()
                

                this.setState({
                    controlesDesactivados: false,
                    reloadTable: false

                })
                cerrarModal();
                
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })

        }

        const actualizarAuditor = () => {
            console.log(this.state.personaSeleccionada)
            if(!this.state.personaSeleccionada ){
                return
            }


            if(!this.state.fechaSeleccionada){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.auditores.find(auditor=>{return (auditor.id===this.state.personaSeleccionada.value && auditor.id!==this.state.auditorSeleccionado.id)})
            if(existe){
                alert(i18next.t("partesInteresadas.auditorExists", { ns: "page2" }))
                return
            }
            
            this.setState({controlesDesactivados:true})
            
            this.props.client.mutate({
                mutation:gql`mutation{actualizarAuditor(ID_AUDITOR:${this.state.auditorSeleccionado.id},VALORES:{FECHA_NOMBRAMIENTO:"${this.state.fechaSeleccionada?moment(this.state.fechaSeleccionada).format('YYYY-MM-DD'):this.state.auditorSeleccionado.fechaNombramiento}"}){ID_AUDITOR}}`
            }).then(async result=>{
         
                alert(i18next.t("updatecorrecto", { ns: "global" }))
                await this.cargarauditores()

                this.setState({
                    controlesDesactivados: false,
                    reloadTable: false

                })
                cerrarModal()
          
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })
        
        }

        const eliminarAsesoria = () => {
            this.setState({controlesDesactivados:true})
            this.props.client.mutate({
                mutation:gql`mutation{eliminarAuditor(ID_AUDITOR:${this.state.auditorSeleccionado.id}){ID_AUDITOR}}`
            }).then(async ()=>{
          
                alert(i18next.t("deletecorrecto", { ns: "global" }))
                await this.cargarauditores()

                this.setState({
                    controlesDesactivados:false,
                    auditorSeleccionado: null,
                    reloadTable: false
                })
                cerrarModal()
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })
            
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                reloadTable: false

            })
        }


        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }


        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.cargarauditores()
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false
            })
        }

        const anadirSector=(ev)=>{


            if(!this.state.nuevoSector)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.actividadesSelect.find(sector=>{
                return sector.label===this.state.nuevoSector
            })

            if(existe){
                alert(i18next.t("partesInteresadas.sectorExists", { ns: "page2" }))
                return
            }

            //creamos el modo convocatoria.
            this.props.client.mutate({
            mutation: gql`
                mutation{crearSectorActividad(VALORES:{DESCRIPCION:"${this.state.nuevoSector}"})}
            `
            }).then(async result=>{
                if(result.data.crearSectorActividad.includes("ERROR") ){
                    alert(i18next.t("error", { ns: "global" }))
                }else{
                    await this.cargarActividades()

                    let sectorCreado=JSON.parse(result.data.crearSectorActividad)
                    console.log(sectorCreado)
                    let sectorSel=this.state.actividadesSelect.find(sector=>{
                        return sector.value===sectorCreado.ID_SECTOR_ACTIVIDAD
                    })
                    console.log(sectorSel)
                    this.setState({sectorActividadSeleccionado:sectorSel})
                    cerrarModal2()
                }

            })
        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                //cerrar el modal
                cerrarModal2();
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaActualizada})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                await this.cargarauditores()
                //cerrar el modal
                cerrarModal2();
            }
        };

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text={i18next.t("newpersfisica", { ns: "global" })} fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text={i18next.t("newpersjuridica", { ns: "global" })} onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({personaSeleccionada:null})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            this.setState({modalPersonaAbierto:true})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            
        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert(i18next.t("partesInteresadas.selectPerson", { ns: "page2" }))
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.personaSeleccionada.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionada.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }
        function dateFormatter(cell, row) {
            return (
                cell? moment(cell).format("DD/MM/YYYY") : ""
            );
        }

        const guardarPersonaJuridica=async ()=>{

            let result=await this.informacion.handleGuardarDatos() 
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirA:false,
                modalImprimirAG: false,
                auditorSeleccionado: null,
                reloadTable: false

            })
        }

        const columns = [
            {
                dataField: 'nombre',
                text: i18next.t("name", { ns: "global" }),
            },
            {
                dataField: 'fechaNombramiento',
                text: i18next.t("partesInteresadas.appointmentDate", { ns: "page2" }),
                formatter: dateFormatter

            }
        ];

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                this.setState({
                    auditorSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };



        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.auditors", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirAG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirAG?
                            <div style={{ width: '100%', height: '500px'}} id='generarAG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirA}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirA?
                            <div style={{ width: '100%', height: '500px'}} id='generarA-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?`${i18next.t("addnew", { ns: "global" })} ${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`:`${i18next.t("edit", { ns: "global" })} ${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className="pixelAlrededor bp3-card separarBordes">
                        <FormGroup
                            label={`${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`}
                            labelFor="auditor"
                            intent="danger"
                            helperText={!this.state.personaSeleccionada?i18next.t("partesInteresadas.selectAuditor", { ns: "page2" }):""}
                        >
                        <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.personas}
                                className="pixelAlrededor"
                                onChange={seleccionPersona}
                                isDisabled={this.state.editarActivado}
                                value={this.state.personaSeleccionada}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup>
                            <Popover disabled={this.state.editarActivado} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                        </FormGroup>   
                        <FormGroup
                            label={`${i18next.t("partesInteresadas.appointmentDate", { ns: "page2" })}:`}
                            labelFor="fecha-nombramiento"
                            inline={true}
                            className="pixelAlrededor" 
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                defaultValue={new Date()}
                                align={Alignment.RIGHT}
                                value={this.state.fechaSeleccionada}
                            />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?`${i18next.t("addnew", { ns: "global" })}`:`${i18next.t("edit", { ns: "global" })} ${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:actualizarAuditor}
                            >
                            {this.state.nuevoActivado?`${i18next.t("addnew", { ns: "global" })} ${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`:`${i18next.t("edit", { ns: "global" })} ${i18next.t("partesInteresadas.auditor", { ns: "page2" })}`}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("partesInteresadas.createSector", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalSectorAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={`${i18next.t("partesInteresadas.sectorNameInput", { ns: "page2" })}:`}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoSector?"":i18next.t("partesInteresadas.sectorNameRequired", { ns: "page2" })}
                        >
                            <InputGroup id="texto-modo-convocatoria" onChange={cambiarnuevoSector}  placeholder={i18next.t("partesInteresadas.sectorNamePlaceholder", { ns: "page2" })} intent={this.state.nuevoSector?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("partesInteresadas.addSector", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirSector}
                            >
                                {i18next.t("aceptar", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("cancelar", { ns: "global" })}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar una auditor"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el auditor?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("partesInteresadas.deleteCredit", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAsesoria}
                            >
                                {i18next.t("yes", { ns: "global" })}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}o</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal2}
                title={i18next.t("partesInteresadas.personProperties", { ns: "page2" })}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                className="dialog-ancho"
                isOpen={this.state.modalPersonaAbierto}
                >
                <DatosPersonasFisicas
                    datosPersona={
                    this.state.personaSeleccionada
                        ? this.state.personaSeleccionada.value
                        : null
                    }
                    ref={input => {
                    this.datosPersona = input;
                    }}
                    i18next={i18next}
                />
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                        <Button onClick={cerrarModal2}>{i18next.t("close", { ns: "global" })}</Button>
                    </Tooltip>
                    <Tooltip
                        content={
                        this.state.nuevaPresonaActivado
                            ? i18next.t("addPerson", { ns: "global" })
                            : i18next.t("editPerson", { ns: "global" })
                        }
                        position="top"
                    >
                        <AnchorButton
                        intent={Intent.PRIMARY}
                        onClick={
                            this.state.nuevaPresonaActivado
                            ? crearPersona
                            : actualizarPersona
                        }
                        >
                        {this.state.nuevaPresonaActivado
                            ? i18next.t("addPerson", { ns: "global" })
                            : i18next.t("editPerson", { ns: "global" })}
                        </AnchorButton>
                    </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("partesInteresadas.companyProp", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEditarPersonaJuridica}
                    className="datos-empresa-dialog"
                    >
                    <DatosPersonasJuridicas
                        animacion={this.animacion}
                        cambiarPestaña={this.cambiarPestaña}
                        pestaña={this.state.pestaña}
                        empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                        <ButtonGroup>
                            <Button 
                                alignText="right"
                                icon="log-out"
                                intent="danger"
                                text={i18next.t("close", { ns: "global" })}
                                onClick={cerrarModal2}
                            />
                        </ButtonGroup>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                    title={i18next.t("partesInteresadas.companyProp", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevaJuridicaAbierto}
                    className="datos-empresa-dialog"
                >
                        <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={i18next}/>  
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cancelar", { ns: "global" })} position="top">          
                            <Button
                                alignText="left"
                                icon="cross"
                                text={i18next.t("cancelar", { ns: "global" })}
                                intent="danger"
                                onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                                className="iconoIzquierda bp3-minimal"
                            />
                        </Tooltip>
                        <Tooltip content={i18next.t("guardar", { ns: "global" })} position="top">          
                            <Button
                                alignText="left"
                                icon="floppy-disk"
                                text={i18next.t("guardar", { ns: "global" })}
                                intent="danger"
                                onClick={guardarPersonaJuridica}
                                className="iconoIzquierda bp3-minimal"
                            />
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                
                <div className="pixelAlrededor tablaSesenta">
                <Card>
                    <h4>{i18next.t("partesInteresadas.auditors", { ns: "page2" })}</h4>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <NavbarDivider />
                                {this.state.controlesDesactivados?[
                                    <>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} disabled />
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} active={this.state.borrarActivado} disabled/>
                                        <Button className="bp3-minimal" icon="print"  text={i18next.t("print", { ns: "global" })} disabled/>
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("printgral", { ns: "global" })} disabled/>
                                    </>
                                ]:[
                                    <>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} onClick={abrirModalEditar} />
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Tooltip 
                                            content={i18next.t("partesInteresadas.printAuditor", { ns: "page2" })} 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                disabled={this.state.auditorSeleccionado?false:true}  
                                                text={i18next.t("print", { ns: "global" })} onClick={()=>this.generarImprimirA()}
                                            />
                                        </Tooltip>  
                                        <Tooltip 
                                            content={i18next.t("partesInteresadas.printAllAuditors", { ns: "page2" })} 
                                            position="right" 
                                        >
                                            <Button 
                                                className="bp3-minimal" 
                                                icon="print" 
                                                text={i18next.t("printgral", { ns: "global" })} 
                                                onClick={()=>this.generarImprimirAG()}
                                            />
                                        </Tooltip>
                                    </>
                                ]}
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    {this.state.reloadTable?
                            <Cargando/>
                            :
                            <BootstrapTable
                                keyField="id"
                                data={this.state.auditores}
                                columns={columns}
                                selectRow={selectRow}
                                pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                    }
                    <br />
                </Card>
                </div>
                <div className="pixelAlrededor tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("partesInteresadas.article263Title", { ns: "page2" })}</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a263", "_blank")}>{i18next.t("partesInteresadas.article263Title", { ns: "page2" })}</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}
export default withTranslation()(withApollo(Auditores))