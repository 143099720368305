import React, { Component } from 'react';
import { Spinner,Collapse, Dialog, FormGroup, Classes, AnchorButton, Tooltip, Intent, Callout, Button, Navbar, ButtonGroup, Alignment, InputGroup, Elevation, Card, TextArea } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from "../Pantallas/Cargando"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    width: '30%'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '60%'
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});




class ModificacionesEstatutarias extends Component {

    state={
        nuevoActivado: true,
        editarActivado: false,
        controlesDesactivados:false,
        borrarActivado: false,
        fecha:new Date(),
        articulo:null,
        anterior:null,
        posterior:null,
        modificaciones: [
        ],
        existeEstatuto:false,
        rutaAdjunto:null,
        modificacionSeleccionada:null,
        cargando: false,
        datosEmpresa:null,
        cargandoImprimirME:false,
        modalImprimirME: false,
        cargandoImprimirGral:false,
        modalImprimirGral: false,
        logotipo:null,
        cargandoGrid:false
    }
    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client 
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
 
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
 
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
 
             }
         })
     
     }
     cargarEmpresa= async() => {
         
         //DATOS JURIDICOS
         let datosEmpresa= await this.props.client
         .query({
             query: gql`
             {
                 personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
             }
             `,
             fetchPolicy: 'network-only'
 
         }).then(result=>{
             //si tenemos razon social.
             if(result.data.personasJuridicas.length>0){
                 return {
                     "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                 }
 
             }
             
         })
 
        return datosEmpresa
    
    }

    cargarModificaciones=async ()=>{

        await this.props.client.query({
            query:gql`{modificacionesEstatutos{ID_MODIFICACION_ESTATUTOS,ARTICULO,REDACCION_ANTERIOR,REDACCION_POSTERIOR,FECHA_EFECTO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            let modificaciones=[]
            result.data.modificacionesEstatutos.forEach(async(modificacion)=>{
                modificaciones.push({"id":modificacion.ID_MODIFICACION_ESTATUTOS,"nombre":modificacion.ARTICULO,"fecha":modificacion.FECHA_EFECTO,"anterior":modificacion.REDACCION_ANTERIOR,"posterior":modificacion.REDACCION_POSTERIOR}) 
            })
            this.setState({modificaciones:modificaciones})
        })

    }
    cargarAdjuntos=async()=>{
       let adjuntos=await this.props.client
       .query({
            query:gql`{adjuntos(FILTROS:{ID_TIPO_ADJUNTO:4},ORDEN:"ID_ADJUNTO,DESC",LIMIT:1){ID_ADJUNTO,DATOS}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.adjuntos
        })
            console.log(adjuntos)
            if(adjuntos.length>0){
                this.setState({existeEstatuto:true})
                this.setState({rutaAdjunto:adjuntos.DATOS})
            }
        }
        cargarDatos=async()=> {
            let datosEmpresa= await this.cargarEmpresa()
            this.setState({
                datosEmpresa:datosEmpresa
            })
    
        }
        generarImprimirGral= async () => {
            this.setState({
                cargandoImprimirGral:true,
                modalImprimirGral:true,
                cargandoGrid:true,
                modificacionSeleccionada:null
            }, async () => {
                 this.setState({cargandoImprimirGral:false}, () => {
                     ReactDOM.render(this.ImprimirGRAL(), document.getElementById("generarG-pdf"))
                 })
            })
        }
        generarImprimirME= async() => {
            this.setState({
                cargandoImprimirME:true,
                modalImprimirME:true,
                cargandoGrid:true
            }, async () => {
                 this.setState({cargandoImprimirME:false}, () => {
                     ReactDOM.render(this.ImprimirME(), document.getElementById('generarME-pdf'))
                 })
            })
        }
    

    componentDidMount(props){

        this.setState({cargando: true}, async () => {
            await this.cargarAdjuntos()
            await this.cargarModificaciones()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargando:false})
        })
    }   
    ImprimirGRAL=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("estatutos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("estatutos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.modificaciones?this.state.modificaciones.map((modificacion, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("estatutos.articulomodificado",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{i18next.t("estatutos.fechaefecto",{ns:"page"}).toUpperCase()}</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{modificacion.nombre?modificacion.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{modificacion.fecha?moment(modificacion.fecha).format('DD/MM/YYYY'):''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    ImprimirME=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("estatutos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("estatutos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.modificacionSeleccionada?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("estatutos.articulomodificado",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.modificacionSeleccionada.nombre}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("estatutos.redaccionanterior",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.modificacionSeleccionada.anterior}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("estatutos.redaccionposterior",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.modificacionSeleccionada.posterior}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("estatutos.fechaefecto",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{moment(this.state.modificacionSeleccionada.fecha).format('DD/MM/YYYY')}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
       )
       
       
       

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("estatutos.title",{ns:"page"})
        const cerrarModalImprimir= () =>{
            this.setState({
                modalImprimirME:false,
                modalImprimirGral:false,
                cargandoGrid:false,
                modificacionSeleccionada:null
            })
        }

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.existeEstatuto){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalAbierto: true,
                articulo:null,
                anterior:null,
                posterior:null,
                fecha:new Date(),
                modificacionSeleccionada:null,
                cargandoGrid:true
            })

        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.modificacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalAbierto: true,
                articulo:this.state.modificacionSeleccionada.nombre,
                anterior:this.state.modificacionSeleccionada.anterior,
                posterior:this.state.modificacionSeleccionada.posterior,
                fecha:new Date(this.state.modificacionSeleccionada.fecha),
                cargandoGrid:true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.modificacionSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true
            })

        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }

        const columns = [
        {
        dataField: 'nombre',
        text: i18next.t("estatutos.articulomodificado",{ns:"page"})
        },
        {
            dataField: 'fecha',
            text: i18next.t("estatutos.fechaefecto",{ns:"page"}),
            formatter: dateFormatter
            }
        ];

        const añadirNuevo = (value) => {
            if(!this.state.articulo){
                return
            }

            if(!this.state.anterior){
                return
            }

            if(!this.state.posterior){
                return
            }

            this.state.modificaciones.push({
                "id":0,
                "nombre": this.state.articulo,
                "fecha":this.state.fecha
            })

            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            
            cerrarModal()
        }


        const actualizarModificacion = () => {

            if(!this.state.articulo){
                return
            }

            if(!this.state.anterior){
                return
            }

            if(!this.state.posterior){
                return
            }
           
            let modificaciones=this.state.modificaciones
            this.state.modificaciones.find((modificacion,index) =>{
                if(modificacion.id===this.state.modificacionSeleccionada.id){
    
                    modificaciones[index]["nombre"]=this.state.articulo?this.state.articulo:this.state.modificacionSeleccionada.nombre
                    modificaciones[index]["fecha"]=this.state.fecha?this.state.fecha:this.state.modificacionSeleccionada.fecha                 

                }
                return modificacion.id===this.state.modificacionSeleccionada.id
            })
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            this.setState({modificaciones:modificaciones})
            
            cerrarModal()
        }

        const eliminarModificacion = () => {
            var modificaciones=this.state.modificaciones.filter(modificacion => modificacion.id !== this.state.modificacionSeleccionada.id)
            this.setState({
                modificaciones:modificaciones,
                pendienteBorrar:true,
                controlesDesactivados:true,
                modificacionSeleccionada:null
            })

            cerrarModal()
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false,
                modificacionSeleccionada:null
            })
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        modificacionSeleccionada: row
        });
        }
        return true;
        }
        
        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.pendienteGuardar){

            
                this.props.client.mutate({
                    mutation:gql`mutation{crearModificacionEstatuto(VALORES:{ARTICULO:"${this.state.articulo}",REDACCION_ANTERIOR:"""${this.state.anterior}""",REDACCION_POSTERIOR:"""${this.state.posterior}""",FECHA_EFECTO:"${moment(this.state.fecha).format('YYYY-MM-DD')}"}){ID_MODIFICACION_ESTATUTOS}}`
                }).then(async result=>{

                        alert(i18next.t("insertcorrecto",{ns:"global"}))
                        await this.cargarModificaciones()
                        this.setState({pendienteGuardar:false})
                        this.setState({controlesDesactivados:false})

                        
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteActualizar){

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarModificacionEstatuto(ID_MODIFICACION_ESTATUTOS:${this.state.modificacionSeleccionada.id},VALORES:{ARTICULO:"${this.state.articulo?this.state.articulo:this.state.modificacionSeleccionada.nombre}",REDACCION_ANTERIOR:"""${this.state.anterior?this.state.anterior:this.state.modificacionSeleccionada.anterior}""",REDACCION_POSTERIOR:"""${this.state.posterior?this.state.posterior:this.state.modificacionSeleccionada.posterior}""",FECHA_EFECTO:"${this.state.fecha?moment(this.state.fecha).format('YYYY-MM-DD'):moment(this.state.modificacionSeleccionada.fecha,'DD-MM-YYYY').format('YYYY-MM-DD')}"}){ID_MODIFICACION_ESTATUTOS}}`
                }).then(async result=>{
                 
                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    await this.cargarModificaciones()
                    
                    
                    this.setState({
                        pendienteActualizar:false,
                        controlesDesactivados:false,
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarModificacionEstatuto(ID_MODIFICACION_ESTATUTOS:${this.state.modificacionSeleccionada.id}){ID_MODIFICACION_ESTATUTOS}}`
                }).then(result=>{

                    if(result.data.errors){
                        alert(i18next.t("error",{ns:"global"}))
                    }else{
                        alert(i18next.t("deletecorrecto",{ns:"global"}))
                        this.cargarModificaciones()
                        this.setState({pendienteBorrar:false})
                        this.setState({controlesDesactivados:false})
                        this.setState({modificacionSeleccionada:null})
                    }

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
            }

        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
        
                articulo:null,
                anterior:null,
                posterior:null,
                fecha:new Date(),
                modificacionSeleccionada:null,
                pendienteActualizar:false,
                pendienteBorrar:false,
                pendienteGuardar:false,
                controlesDesactivados:false
            })

            this.cargarModificaciones()
        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({fecha: nuevaFecha})
        }

        const cambiarArticulo = (ev) => {
            this.setState({articulo: ev.currentTarget.value})
        }

        const cambiarAnterior = (ev) => {
            this.setState({anterior: ev.currentTarget.value})
        }

        const cambiarPosterior = (ev) => {
            this.setState({posterior: ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };
  
        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1 });
        }

        return (
            <>
            {
                this.state.cargando === true?
                <Cargando />
                :
                <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirME}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirAI?
                            <div style={{ width: '100%', height: '600px'}} id='generarME-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirGral}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirGral?
                            <div style={{ width: '100%', height: '600px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={i18next.t("estatutos.titleadd",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor separarBordes">
                        <div>
                            <FormGroup
                                label={i18next.t("estatutos.articulomodificado",{ns:"page"})+":"}
                                labelFor="articulo-modificado"
                                intent="danger"
                                fill={true}
                                helperText={!this.state.articulo?i18next.t("emptyfield",{ns:"global"})+i18next.t("estatutos.articulomodificado",{ns:"page"}):""}
                            >
                                <InputGroup id="articulo-modificado" intent={!this.state.articulo?"danger":"primary"} onChange={cambiarArticulo} value={this.state.articulo} className="pixelAlrededor" fill={true} />
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("estatutos.redaccionanterior",{ns:"page"})+":"}
                                labelFor="redaccion-anterior"
                                fill={true}
                                intent="danger"
                                helperText={!this.state.anterior?i18next.t("emptyfield",{ns:"global"})+i18next.t("estatutos.redaccionanterior",{ns:"page"}):""}
                            >
                                <div style={!this.state.anterior?{border:"1px solid red"}:{}}>
                                    <TextArea id="redaccion-anterior" onChange={cambiarAnterior} value={this.state.anterior} className="pixelAlrededor" fill={true} />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("estatutos.redaccionposterior",{ns:"page"})+":"}
                                labelFor="redaccion-posterior"
                                fill={true}
                                intent="danger"
                                helperText={!this.state.posterior?i18next.t("emptyfield",{ns:"global"})+i18next.t("estatutos.redaccionposterior",{ns:"page"}):""}
                            >
                                <div style={!this.state.anterior?{border:"1px solid red"}:{}}>
                                    <TextArea id="redaccion-posterior" onChange={cambiarPosterior} value={this.state.posterior} className="pixelAlrededor" fill={true} />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label={i18next.t("estatutos.fechaefecto",{ns:"page"})+":"}
                                labelFor="redaccion-posterior"
                                fill={true}
                            >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                value={this.state.fecha}
                                align={Alignment.RIGHT}
                            />
                            </FormGroup>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:actualizarModificacion}
                                >
                                {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} 
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </div>
                        </Card>
                    </Dialog>  

                
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("estatutos.questiondel",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarModificacion}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <div className="pixelAlrededor tablaSesenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                {this.state.existeEstatuto
                ?
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                        {i18next.t("estatutos.para",{ns:"page"})} <a href={this.state.rutaAdjunto?process.env.REACT_APP_DOMINIO+""+this.state.rutaAdjunto.replace(".",""):""} target="_blank" rel="noopener noreferrer"> {i18next.t("estatutos.aqui",{ns:"page"})}</a>

                    </Callout>
                :
                    <div></div> 
                }
                {
                this.state.cargandoGrid === true?
                <Cargando />
                :
                <>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup align={Alignment.LEFT}>
                            {this.state.controlesDesactivados?[
                                <React.Fragment>
                                    <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled/>
                                    <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled/>
                                    <Button className="bp3-minimal" icon="print" active={this.state.modificacionSeleccionada?false:true} text={i18next.t("print",{ns:"global"})} disabled/>
                                    <Button className="bp3-minimal" icon="print" text={i18next.t("printGral",{ns:"global"})} disabled/>

                                </React.Fragment>
                            ]:[
                                <React.Fragment>
                                    <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado}  onClick={abrirModalEditar} />
                                    <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                    <Tooltip content="Desea imprimir la modificación seleccionada" position="right" >
                                        <Button className="bp3-minimal" icon="print" disabled={this.state.modificacionSeleccionada?false:true}  text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirME()}/>
                                    </Tooltip>
                                    <Tooltip content="Desea imprimir todas las modificaciones" position="right" >
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("printGral",{ns:"global"})} onClick={()=>this.generarImprimirGral()}/>
                                    </Tooltip>
                                </React.Fragment>
                            ]}
                        </ButtonGroup>
                    </Navbar.Group>
                    
                   
                </Navbar>
                <BootstrapTable
                keyField='id'
                data={ this.state.modificaciones }
                columns={ columns }
                selectRow={ const_table.selectRow(handleOnSelect) }
                pagination={ paginationFactory(const_table.options) }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                />
                </>
                }
                </Card>
                </div>
                <div className="pixelAlrededor tablaCuarenta"> 
                <Card interactive={true} elevation={Elevation.TWO}>
                    
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("estatutos.ley",{ns:"page"})}</Callout>

                    <br />
                    
                    <div className="tablaMitad">
                        <a role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#tviii" class="bp3-button bp3-icon-git-repo">{i18next.t("estatutos.titulo",{ns:"page"})}</a>
                    </div>

                    <div className="tablaMitad">
                        <Button icon="git-repo" onClick={handleClick1}>
                            {i18next.t("estatutos.art",{ns:"page"})}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <div>
                        <Collapse isOpen={this.state.abierto1}>
                            <Card> 
                                <p>{i18next.t("estatutos.info",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                    </div>
                </Card>
                </div>
                <br/>
                <br/>
    
                 <Button 
                     alignText="right"
                     icon="floppy-disk"
                     text={i18next.t("guardar",{ns:"global"})}
                     onClick={guardarCambios}
                     className="bp3-intent-danger iconoDerecha bp3-minimal"
                     disabled={!this.state.controlesDesactivados}
                />
                <Button 
                    alignText="right"
                    icon="cross"
                    text={i18next.t("cancelar",{ns:"global"})}
                    onClick={cancelarCambios}
                    className="bp3-intent-danger iconoDerecha bp3-minimal"
                    disabled={!this.state.controlesDesactivados}
                />
            <br/>
            <br/>
            <br/>    
                
            </>
            }
            </>
        )
    }
}
export default withTranslation()(withApollo(ModificacionesEstatutarias))