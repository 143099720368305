import React from 'react';
import { confirmable } from 'react-confirm';
import { Button,Dialog, Classes, Tooltip, AnchorButton, Intent } from "@blueprintjs/core"


const YourDialog = ({show, proceed, dismiss, cancel, confirmation, options}) => {
  return(
        <Dialog 
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="warning-sign"
        title="Cambiar empleado"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        usePortal={true}
        isOpen={true}
    >
        <div className={Classes.DIALOG_BODY}>
            {confirmation}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="Cambiar empleado." position="top">
                <AnchorButton
                onClick={() => proceed()}
                    intent={Intent.PRIMARY}
                  
                >
                        Si
                    </AnchorButton>
                </Tooltip>
                <Tooltip content="Cerrar y volver." position="top">
                    <Button onClick={() => cancel()}>No</Button>
                </Tooltip>
            </div>
        </div>
    </Dialog>

  )
}


// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(YourDialog);
