import React from "react"
import {Spinner,FormGroup,Dialog,TextArea, Classes, Callout, NavbarDivider,Tooltip, AnchorButton, Intent, Navbar, ButtonGroup, Button, InputGroup, Alignment,FileInput,Card, Elevation} from "@blueprintjs/core"
import gql from "graphql-tag";
import axios from 'axios';
import { withApollo } from "react-apollo";
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import moment from "moment"
import { Cargando } from "../Pantallas/Cargando"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    width: '30%'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '60%'
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});

 
 class Contratos extends React.Component {

    state = {
        modalAbierto: false,
        modalBorrarAbierto: false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        nombre:null,
        nuevoActivado:true,
        editarActivado:false,
        borrarActivado:false,
        descripcion:null,
        adjuntoSeleccionado:null,
        archivo:null,
        controlesDesactivados:false,
        adjuntos: [
        ],
        tipoadjuntos:[
            {"label": "Estatutos de la empresa","value":4},
            {"label": "Otros","value":3},
        ],
        datosEmpresa:null,
        cargandoImprimir:false,
        modalImprimir: false,
        cargandoImprimirCD:false,
        modalImprimirCD: false,
        logotipo:null,
        cargando:false,
        cargandoGrid: false
    }

    recargarAdjuntos=async()=>{

        let adjuntos= await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:2}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.adjuntos
        })   
            
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto)=>{
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({adjuntos:copyAdjuntos})

        
    }

    obtenterTipo(nombreExtension){

        let extension=""
        switch(nombreExtension){
            case ".pdf":
                extension="Documento PDF"
            break;
            case ".doc":
            case ".docx":
                extension="Documento de Microsoft Word"
            break;
            case ".xls":
            case ".xlsx":
                extension="Documento de Microsoft Excel"
            break;
            case ".txt":
                extension="Documento de Texto"
            break;
            case ".png":
            case ".jpg":
            case ".jpeg":
            case ".svg":
                extension="Imagen"
            break;
            default:
                extension="Otros"
            break;
        }
        return extension

    }
    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }
   obtenerLogoEmpresa=async()=>{
    //obtencion logo de la empresa
     await this.props.client
     .query({
         query: gql`
             {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
         `,fetchPolicy:'network-only'             
     }).then(result=>{ 
         if(result.data.adjuntos.length>0){

             var request = require('request').defaults({ encoding: null,withCredentials:true });

             request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                 if (!error && response.statusCode === 200) {
                 let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                 this.setState({logotipo:data})
                 }
             });

         }
     })
 
 }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }


    componentDidMount(props){  
        this.setState({cargando:true},
            async () =>{
                await this.recargarAdjuntos()
                await this.obtenerLogoEmpresa()
                await this.cargarDatos()
                this.setState({cargando:false})
            })       
    }
    generarImprimirCD= async() => {
        this.setState({
            cargandoImprimirCD:true,
            modalImprimirCD:true
        }, async () => {
             this.setState({cargandoImprimirCD:false}, () => {
                 ReactDOM.render(this.ImprimirCD(), document.getElementById('generarCD-pdf'))
             })
        })
    }
    generarImprimirC= async() => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true,
            cargandoGrid:true,
            adjuntoSeleccionado:null
        }, async () => {
             this.setState({cargandoImprimir:false}, () => {
                 ReactDOM.render(this.ImprimirC(), document.getElementById('generarC-pdf'))
             })
        })
    }

    ImprimirCD=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("contratos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("contratos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.adjuntoSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("contratos.name",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.adjuntoSeleccionado.nombre}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("contratos.descripcion",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.adjuntoSeleccionado.descripcion}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

       ImprimirC=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("contratos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("contratos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.adjuntos?this.state.adjuntos.map((adjunto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>{i18next.t("contratos.name",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{i18next.t("contratos.type",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>{i18next.t("contratos.typefile",{ns:"page"}).toUpperCase()}</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{adjunto.nombre?adjunto.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{adjunto.tipo?adjunto.tipo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{adjunto.mime?adjunto.mime:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )


    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("contratos.title",{ns:"page"})

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimir:false,
                modalImprimirCD:false,
                cargandoGrid:false
            })
        }
        
        const abrirModalNuevo = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                cargandoGrid:true
            })

        }
        const abrirModalEditar = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({nombre:this.state.adjuntoSeleccionado.nombre})
            this.setState({descripcion:this.state.adjuntoSeleccionado.descripcion})


            this.setState({modalAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:true})
            this.setState({borrarActivado:false,cargandoGrid:true})
        }
        const abrirModalBorrar = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({modalBorrarAbierto: true})
            this.setState({modalAbierto: false})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        const anadirNuevo = () => {

            if(!this.state.archivo)return
            if(!this.state.nombre)return
            if(!this.state.descripcion)return

            let extension=this.obtenterTipo("."+this.state.archivo.name.split(".").pop())

            this.state.adjuntos.push({
                    "nombre": this.state.nombre?this.state.nombre:this.state.nombre,
                    "tipo": "Contrato",
                    "idTipo":7,
                    "mime": extension
    
            })
            this.setState(this.state)
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()
        }

        const guardarCambios=()=>{

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            const api = axios.create({
                withCredentials: true
            });

            if(this.state.pendienteGuardar){
              
                var formData = new FormData();
                formData.append("files",this.state.archivo);
                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:2,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"""${this.state.descripcion}"""}){ID_ADJUNTO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then(async(response)=> {
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    await this.recargarAdjuntos()
                    
                    this.setState({
                        controlesDesactivados:false,
                        archivo:null,
                        pendienteGuardar:false
                    })
           

                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                })

              

            }else if(this.state.pendienteActualizar){

                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre}",ID_TIPO_ADJUNTO:${2},ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"""${this.state.descripcion}"""}){ID_ADJUNTO}}`).then( async(response)=> {
                    // handle success
                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    await this.recargarAdjuntos()
                    
                    this.setState({
                        controlesDesactivados:false,
                        pendienteActualizar:false,
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then((response)=>{
                    // handle success  
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.recargarAdjuntos();
                    this.setState({controlesDesactivados:false})
                    this.setState({pendienteBorrar:false})
                    this.setState({adjuntoSeleccionado:null})
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }

        }

        const cancelarCambios=()=>{

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                pendienteActualizar:false,
                pendienteGuardar:false,
                pendienteBorrar:false,
                controlesDesactivados:null
            })

            this.recargarAdjuntos()

        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false,
                adjuntoSeleccionado:null
            })
        }

        const columns = [
        {
        dataField: 'nombre',
        text: i18next.t("contratos.name",{ns:"page"})
        },
        {
            dataField: 'tipo',
            text: i18next.t("contratos.type",{ns:"page"})
            },
            {
                dataField: 'mime',
                text: i18next.t("contratos.typefile",{ns:"page"})
                }
        ];

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        adjuntoSeleccionado: row
        });
        }
        return true;
        }
        
        const ActualizarAdjunto = () => {
           
            if(!this.state.nombre)return
            if(!this.state.descripcion)return      

            let adjuntos=this.state.adjuntos
            //si hemos rellenado los dos campos, agregamos los valores al listado.
            this.state.adjuntos.find((adjunt,index) =>{
                if(adjunt.id===this.state.adjuntoSeleccionado.id){
                    adjuntos[index]["nombre"]=this.state.nombre?this.state.nombre:this.state.adjuntoSeleccionado.nombre
                }
                return adjunt.id===this.state.adjuntoSeleccionado.id
            })
            this.setState({adjuntos:adjuntos})
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        };

        const eliminarAdjunto = () => {
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            var adjuntos=this.state.adjuntos.filter(adjunto => adjunto.id !== this.state.adjuntoSeleccionado.id)
            this.setState({adjuntos:adjuntos,adjuntoSeleccionado:null})
            cerrarModal()
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
        };
 
        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({archivo:ev.target.files[0]})
            this.setState({tipoArchivo:ev.target.files[0].type})
            this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        return (
            <>
            {this.state.cargando===true?
                <Cargando/>
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirCD}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirCD?
                            <div style={{ width: '100%', height: '600px'}} id='generarCD-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalAbierto}
            >
                <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                    {this.state.nuevoActivado?
                        <div className="pixelAlrededor">
                            <FormGroup
                                label={i18next.t("contratos.file",{ns:"page"})+":"}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.archivo?"":i18next.t("selectempty",{ns:"global"})+' '+i18next.t("contratos.file",{ns:"page"})}
                            >
                                <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                            </FormGroup>
                        </div>
                        :<></>
                    }
                    
                    <div className="pixelAlrededor">
                    </div>
                    <div className="pixelAlrededor">
                        <FormGroup
                                label={i18next.t("contratos.name",{ns:"page"})+":"}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nombre?"":i18next.t("emptyfield",{ns:"global"})+' '+i18next.t("contratos.name",{ns:"page"})}
                        >
                            <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre} intent={this.state.nombre?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className="pixelAlrededor">
                        <FormGroup
                                label={i18next.t("contratos.descripcion",{ns:"page"})+":"}
                                labelFor="descripcion"
                                intent="danger"
                                helperText={this.state.descripcion?"":i18next.t("emptyfield",{ns:"global"})+' '+i18next.t("contratos.descripcion",{ns:"page"})}
                        >
                            <TextArea
                                large={true}
                                fill={true}
                                intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                                onChange={handleCambiarDescripcion}
                                defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.descripcion:""}
                            />                    
                        </FormGroup>
                    </div>
                </Card>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                    </Tooltip>
                    <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={this.state.nuevoActivado?anadirNuevo:ActualizarAdjunto}
                        >
                            {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                </div>
            </div>
            </Dialog>
            
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("contratos.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAdjunto}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <div className="tablaSesenta pixelAlrededorGrande">
                <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
                {this.state.cargandoGrid===true?
                        <Cargando/>
                    :
                    <>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                {this.state.controlesDesactivados?[
                                    <>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled />
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled />
                                        <Button className="bp3-minimal" icon="print"  text={i18next.t("print",{ns:"global"})} disabled/>
                                        <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} disabled/>

                                        <NavbarDivider />
                                        <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled/>
                                    </>
                                ]:[
                                    <>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Tooltip content="Desea imprimir el contrato seleccionado" position="right" >
                                            <Button className="bp3-minimal" icon="print" disabled={this.state.adjuntoSeleccionado?false:true}  text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirCD()}/>
                                        </Tooltip>
                                        <Tooltip content="Desea imprimir todas los contratos" position="right" >
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} onClick={()=>this.generarImprimirC()}/>
                                        </Tooltip>
                                        <NavbarDivider />
                                        <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                                    </>
                                ]}
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                    keyField='id'
                    data={ this.state.adjuntos }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    </>
                }
                </Card>
            </div>
            <div className="pixelAlrededorGrande tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("contratos.articulo",{ns:"page"})}</Callout>
                    <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a220", "_blank")}>{i18next.t("contratos.articulo",{ns:"page"})}</AnchorButton>
                    <br />
                </Card>
            </div>
            <br/>
            <br/>
            <Button 
              alignText="right"
              icon="floppy-disk"
              text={i18next.t("guardar",{ns:"global"})}
              intent="danger"
              onClick={guardarCambios}
              className="iconoDerecha bp3-minimal"
              disabled={!this.state.controlesDesactivados}
            />
            <Button 
              alignText="right"
              icon="cross"
              intent="danger"
              text={i18next.t("cancelar",{ns:"global"})}
              onClick={cancelarCambios}
              disabled={!this.state.controlesDesactivados}
              className="iconoDerecha bp3-minimal"
            />
        <br/>
        <br/> 
        </>
        }
        </>
        )
    }
}
export default withTranslation()(withApollo(Contratos))



    



