/* eslint-disable array-callback-return */
import React , { Component } from "react"
import { Card, Elevation, FormGroup, InputGroup, Button, Tooltip,Dialog, Classes, AnchorButton, Intent, NumericInput, Switch, Spinner } from "@blueprintjs/core"
import { DateInput, DatePicker } from "@blueprintjs/datetime";
import "moment/locale/es"
import MomentLocaleUtils from 'react-day-picker/moment';
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import { Table, Column, Cell,RegionCardinality } from "@blueprintjs/table"
import ObtenerRecursos from './ObtenerRecursosNecesarios';

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class DatosActa extends Component{

    state={
        convocatoriaSeleccionada: null,
        convocatorias:[
        ],
        modalConvocatoria: false,
        numeroActa: 1,
        fechaReunion: new Date(),
        fechaBusquedaReunion: new Date(),
        lugarReunion: null,
        asistentesDirectos: 0,
        asistentesRepresentados: 0,
        porcentaje1: 0.00,
        porcentaje2: 0.00,
        sistemaAprobacion: null,
        actaAprobada: false,
        actaFirmada: false,
        tiposReuniones: [
        ],
        tipoReunionSeleccionada:null,
        comisionSeleccionada:null,
        fechaAprobacion:new Date(),
        fechaConvocatoria:new Date(),
        seleccionLista:null,
        comisiones:[],
        modalAdvertenciaAsistentes:false,
        comite:[],
        comiteSeleccionado: null,
        modalComites: false,
        miembros:[],
        cargandoAC:false,
        personasFisicas:null,
        personasJuridicas:null
    }


    buscarConvocatorias=async(fecha)=>{

        //obtenemos las convocatorias del mes:
        const startOfMonth = moment(fecha).startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment(fecha).endOf('month').format('YYYY-MM-DD');
        //carga de convocatorias y señalarlas en el calendario
        await client.query({
          query:gql`{convocatorias(FILTROS:{FECHA:{DESDE:"${startOfMonth}",HASTA:"${endOfMonth}"}}){ID_CONVOCATORIA,ID_TIPO_CONVOCATORIA,COMISION{TIPO_COMISION{DESCRIPCION}},TIPO_CONVOCATORIA{DESCRIPCION},MODO_CONVOCATORIA{DESCRIPCION},FECHA,ID_CONVOCANTE,HORA_1,HORA_2,ID_COMISION,TITULO,LUGAR,TEXTO,ORDEN_DEL_DIA}}`,
          fetchPolicy:'network-only'

        }).then(async(result)=>{

          let convocatorias=[]
          let diasConvocatorias=[]
          for(let convocatoria of result.data.convocatorias){

            let tipoReunion=""
            switch(convocatoria.ID_TIPO_CONVOCATORIA){
                case 1:
                case 2:
                case 4:
                case 5:
                    tipoReunion="Convocatoria de reunión de la "+convocatoria.TIPO_CONVOCATORIA.DESCRIPCION
                break
                case 3:
                case 6:
                case 7:
                case 8:
                    tipoReunion="Convocatoria de reunión del "+convocatoria.TIPO_CONVOCATORIA.DESCRIPCION
                break;
                default:
                break;
            }
            convocatorias.push({
                value:convocatoria.ID_CONVOCATORIA,
                label: tipoReunion,
                fecha:convocatoria.FECHA,
            })
            //marcamos la fecha en el calendario.
            diasConvocatorias.push(new Date(convocatoria.FECHA))

          }
          let convocatoriasDias= convocatorias.filter(conv=>{return moment(conv.fecha).format("YYYY-MM-DD")===moment(fecha).format("YYYY-MM-DD")})

          this.setState({
              convocatorias:convocatoriasDias,
              reuniones:convocatorias,
              diasConvocatorias:diasConvocatorias,
          })

      })
    }
    cargarPersonasFisicas= async() =>{
        let personasFisicas= await client.query({
            query:gql`{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-o.nly'
        }).then((result)=>{
            return result.data.personasFisicas
        })
        return personasFisicas
    }
    cargarPersonasJuridicas=async() =>{
        let personasJuridicas= await client.query({
            query:gql`{personasJuridicas{RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasJuridcas
        })   
        return personasJuridicas
    }
    cargarDatos=async() =>{
        let personasFisicas= await this.cargarPersonasFisicas()
        let personasJuridicas= await this.cargarPersonasJuridicas()
        this.setState({
            personasFisicas:personasFisicas,
            personasJuridicas: personasJuridicas
        })
    }

    obtenerNombreMiembros=async(miembro)=>{
        // let personasFisicas= await client.query({
        //     query:gql`{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
        //     fetchPolicy:'network-o.nly'
        // }).then((result)=>{
        //     return result.data.personasFisicas
        // })
        // let personasJuridicas= await client.query({
        //     query:gql`{personasJuridicas(FILTROS:{ID_PERSONA:${miembro.ID_PERSONA}}){RAZON_SOCIAL}}`,
        //     fetchPolicy:'network-only'
        // }).then((result)=>{
        //     return result.data.personasJuridcas
        // })    

        if(miembro.PERSONA){

            if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                //obtenemos el nombre
                if (this.state.personasFisicas) {
                  this.state.personasFisicas.find(persona=>{
                      if(persona.ID_PERSONA===miembro.ID_PERSONA){
                          miembro.value=miembro.ID_PERSONA
                          miembro.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                          miembro.vota=true
                      }
                  })
                }    
            }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                if (this.state.personasJuridicas) {
                  this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_PERSONA){
                        miembro.value=miembro.ID_PERSONA
                        miembro.label=persona.RAZON_SOCIAL
                        miembro.vota=true
                    }
                  })
                }
            }    
        }else{

            if(miembro.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                //obtenemos el nombre
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.DESTINO){
                        miembro.value=miembro.DESTINO
                        miembro.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                        miembro.vota=true
                    }
                })    
            }else if(miembro.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.DESTINO){                        
                        miembro.value=miembro.DESTINO
                        miembro.label=persona.RAZON_SOCIAL
                        miembro.vota=true
                    }
                })
            } 
    
            
        }       
        
    }


    obtenerMiembrosComision=async(comision)=>{

        let miembros=await client.query({
            query:gql`{comisionesMiembros(FILTROS:{ID_COMISION:${comision.ID_COMISION}}){
                ID_MIEMBRO
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.comisionesMiembros
        })
        /*let personasFisicas=  await client.query({
            query:gql`{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-o.nly'
        }).then((result)=>{
            return result.data.personasFisicas
        })*/ 
       
            miembros.forEach(async(miembro,index)=>{
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_MIEMBRO){
                        miembros[index].label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE} `
                        miembros[index].ID_PERSONA=miembros[index].ID_MIEMBRO
                        miembros[index].value=miembros[index].ID_MIEMBRO
                        miembros[index].vota=true
    
                    }
                })
            })
        
            comision.miembros=miembros
    
    
    }


    cargarMiembrosCapital=async()=>{

        //carga de filtro tipo convocatoria
        let miembros=await client.query({
            query:gql`{capSocialInicialDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.capSocialInicialDistribucion
        })
        let miembrosActuales=await client.query({
            query:gql`{capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.capSocialActualDistribucion
        })
        let miembrosTransmisiones=await client.query({
            query:gql`{capSocialTransmisiones{
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        for(let miembro of miembrosTransmisiones){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=miembros.find(m=>{return m.ID_PERSONA===miembro.DESTINO})
            if(!existe)miembros.push(miembro)
        }
        for(let miembro of miembrosActuales){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=miembros.find(m=>{return m.ID_PERSONA===miembro.ID_PERSONA})
            if(!existe)miembros.push(miembro)
        }
        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }

        await this.props.setConvocados(miembros)
        await this.props.setAsistentes(miembros)
        await this.props.setNumeroAsistentes(miembros.length)
        this.setState({
            asistentesDirectos:miembros.length,
            porcentaje1:parseInt(miembros.length)>0?100:0,
            asistentesRepresentados:0,
            porcentaje2:0
        },()=>{console.log(this.state)})

    }

    cargarMiembrosOrganoAdmon=async()=>{

        //carga de filtro tipo convocatoria
        let miembros=await client.query({
            query:gql`{organosAdministracionMiembros{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.organosAdministracionMiembros
        })

        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }
        await this.props.setConvocados(miembros)
        await this.props.setAsistentes(miembros)
        await this.props.setNumeroAsistentes(miembros.length)

        this.setState({
            asistentesDirectos:miembros.length,
            porcentaje1:miembros.length>0?100:0,
            asistentesRepresentados:0,
            porcentaje2:0
        })

    }

    
    cargarMiembrosCompliance=async()=>{

        //carga de filtro tipo convocatoria
        let miembros=await client.query({
            query:gql`{miembrosOrganizacion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.miembrosOrganizacion
        })

        for(let miembro of miembros){
            //obtenemos el nombre
            await this.obtenerNombreMiembros(miembro)
        }

        await this.props.setConvocados(miembros)
        await this.props.setAsistentes(miembros)
        await this.props.setNumeroAsistentes(miembros.length)

        this.setState({
            asistentesDirectos:miembros.length,
            porcentaje1:miembros.length>0?100:0,
            asistentesRepresentados:0,
            porcentaje2:0
        })

    }

    cargarMiembrosOrganoInstruccion=async()=>{

        //carga de filtro tipo convocatoria
        let miembros=await client.query({
            query:gql`{complianceOrganosInstruccion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceOrganosInstruccion
        })

        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }

        await this.props.setConvocados(miembros)
        await this.props.setAsistentes(miembros)
        await this.props.setNumeroAsistentes(miembros.length)

        this.setState({
            asistentesDirectos:miembros.length,
            porcentaje1:miembros.length>0?100:0,
            asistentesRepresentados:0,
            porcentaje2:0
        })

    }

    cargarMiembrosOrganoResolucion=async()=>{

        //carga de filtro tipo convocatoria
        let miembros=await client.query({
            query:gql`{complianceOrganosResolucion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceOrganosResolucion
        })

        for(let miembro of miembros){
            await this.obtenerNombreMiembros(miembro)
        }
        await this.props.setConvocados(miembros)
        await this.props.setAsistentes(miembros)
        await this.props.setNumeroAsistentes(miembros.length)

        this.setState({
            asistentesDirectos:miembros.length,
            porcentaje1:miembros.length>0?100:0,
            asistentesRepresentados:0,
            porcentaje2:0
        })

    }

    cargarComisiones=async()=>{

        //cargar la tabla de comisiones.
        let comisiones=await client.query({
            query:gql`{comisiones{
                ID_COMISION,
                NOMBRE,
                ID_TIPO_COMISION,
                TIPO_COMISION{DESCRIPCION}
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.comisiones
        })

        //buscamos los miembros de cada comision.
        for(let comision of comisiones){
            await this.obtenerMiembrosComision(comision)
        }
        console.log(comisiones)
        this.setState({comisiones:comisiones})

    }
    
    cargarComites=async ()=>{

   

      let agrupaciones= await client.query({
            query:gql`{agrupaciones{ID_AGRUPACION,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return result.data.agrupaciones
        })

        for( let agrupacion of agrupaciones ){
            await this.cargarMiembrosComites(agrupacion)
        }
            console.log(agrupaciones)

        this.setState({comite:agrupaciones})       

    }  
    
    cargarMiembrosComites=async(comite) =>{
        let agrupacionesPersonas=await client.query({
            query:gql`{agrupacionesPersonas(FILTROS:{ID_AGRUPACION:${comite.ID_AGRUPACION}}){
                ID_AGRUPACION,ID_PERSONA
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.agrupacionesPersonas
        })
        // let personasFisicas=  await client.query({
        //     query:gql`{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
        //     fetchPolicy:'network-o.nly'
        // }).then((result)=>{
        //     return result.data.personasFisicas
        // })
        agrupacionesPersonas.forEach(async(miembro,index)=>{
            this.state.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE} `
                    //miembro.ID_PERSONA=miembro.ID_PERSONA
                    miembro.value=miembro.ID_PERSONA
                    miembro.vota=true

                }
            })
        }) 
        comite.miembros=agrupacionesPersonas
   
    }

    calcularVotos=async(asistentes)=>{


        //obtener los votos directos
        let votosDirectos=asistentes.filter(asistente=>{ return !asistente.idRepresenta && asistente.vota===true })
        if(votosDirectos){
         this.setState({asistentesDirectos:votosDirectos.length})
        }
    
        //obtener los votos representados
        let votosRepresentados=asistentes.filter(asistente=>{ return asistente.idRepresenta && asistente.vota===true })
        if(votosRepresentados){
            this.setState({asistentesRepresentados:votosRepresentados.length})
        }

        //recalculamos el porcentaje.
        let total=this.state.asistentesDirectos+this.state.asistentesRepresentados

        if(total>0){
            let porcentajeDirectos= (this.state.asistentesDirectos / total)*100;
            let porcentajeRepresentados=(this.state.asistentesRepresentados / total)*100;
            this.setState({
                asistentesDirectos:this.state.asistentesDirectos,
                asistentesRepresentados:this.state.asistentesRepresentados,
                porcentaje1:Math.round(porcentajeDirectos),
                porcentaje2:Math.round(porcentajeRepresentados)
            })
        }else{

            this.setState({
                asistentesDirectos:votosDirectos.length,
                asistentesRepresentados:votosRepresentados.length,
                porcentaje1:0,
                porcentaje2:0
            })
        }
 
    }
    cargarConvocatoria= async () => {
        const tiposReunionesFiltros=await ObtenerRecursos.obtenerConvocatoria();

        this.setState({tiposReunionesFiltros:tiposReunionesFiltros},
            async()=>{
                //eliminamos el primer elemento
                let tipoSinFiltros=tiposReunionesFiltros.filter(tipo=>{
                    return tipo.value!==0
                })
                this.setState({tiposReuniones:tipoSinFiltros})
                this.setState({fechaReunion:this.props.fechaReunion})

        });
            
        
    }

    componentDidUpdate=async(props)=>{
        if(this.props!==props){
          if (this.props.asistentes) {
            this.calcularVotos(this.props.asistentes)
          }
        }
    }

    componentDidMount=async()=>{
        this.setState({cargandoAC:true}, async () => {
            await this.cargarDatos()
            await this.cargarConvocatoria()
            await this.buscarConvocatorias(new Date())
            await this.cargarComisiones()
            await this.cargarComites()
            if(this.props.acta && Object.keys(this.props.acta).length >0){

                //obtener el tipo seleccionado
                let tipoReunion=this.state.tiposReuniones.find(tipo=>{return tipo.value===this.props.acta.ID_TIPO_CONVOCATORIA})
                if(this.props.acta.CONVOCATORIA)await this.buscarConvocatorias(new Date(this.props.acta.CONVOCATORIA.FECHA))
                let convocatoria=this.state.convocatorias.find(convocatoria=>{return convocatoria.value===this.props.acta.ID_CONVOCATORIA})
                if(convocatoria)this.setState({fechaConvocatoria:new Date(convocatoria.fecha)})
                let comision=this.state.comisiones.find(comision=>{return comision.ID_COMISION===this.props.acta.ID_COMISION})
                this.setState({
                    numeroActa:this.props.acta.NUMERO,
                    fechaReunion:new Date(this.props.acta.FECHA_REUNION),
                    lugarReunion:this.props.acta.LUGAR,
                    asistentesDirectos:this.props.acta.SOCIOS_VOTO_PERSONAL,
                    porcentaje1:this.props.acta.SOCIOS_VOTO_PERSONAL_PORCENTAJE,
                    asistentesRepresentados:this.props.acta.SOCIOS_VOTO_REPRESENTADOS,
                    porcentaje2:this.props.acta.SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE,
                    actaAprobada:this.props.acta.APROBADA,
                    fechaAprobacion:this.props.acta.FECHA_APROBACION?new Date(this.props.acta.FECHA_APROBACION):new Date(),
                    actaFirmada:this.props.acta.FIRMADA,
                    sistemaAprobacion:this.props.acta.SISTEMA_APROBACION,
                    tipoReunionSeleccionada:tipoReunion,
                    convocatoriaSeleccionada:convocatoria?convocatoria:null,
                    comisionSeleccionada:(comision?comision:null),
                    seleccionLista:(convocatoria?{value:convocatoria.value,label:convocatoria.label}:null)
                })
            }
            this.setState({cargandoAC:false})

        })
       
      
        

       
        //si editamos un acta...    
      
        


    }

    render(){

        const cerrarModal = () => {
            this.setState({
                modalConvocatoria: false,
            })
        }   

        const guardarConvocatoria = () => {
            if(!this.state.seleccionLista){
                alert("Debe seleccionar un elemento de la lista")
                return
            }
            this.setState({convocatoriaSeleccionada:this.state.seleccionLista})
            cerrarModal()
        }

        const handleNumeroActa = (e) => {
            this.setState({ numeroActa: e })
        }

        const handleLugarReunion = (e) => {
            this.setState({ lugarReunion: e.target.value })
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFecha = (nuevaFecha) => {
            this.setState({ fechaReunion: nuevaFecha })
        }

        const escogeFechaAprobacion = (nuevaFecha) => {
            this.setState({ fechaAprobacion: nuevaFecha })
        }

        const handleAsistentesDirectos = (e) => {
            this.setState({ asistentesDirectos: e })
        }

        const handleAsistentesRepresentados = (e) => {
            this.setState({ asistentesRepresentados: e })
        }

        const handleAsistentesPorcentaje1 = (e) => {
            this.setState({ porcentaje1: e })
        }

        const handleAsistentesPorcentaje2 = (e) => {
            this.setState({ porcentaje2: e })
        }

        const handleSistemaAprobacion = (e) => {
            this.setState({ sistemaAprobacion: e.target.value })
        }

        const handleActaAprobada = () => {
            this.setState({ actaAprobada: !this.state.actaAprobada})
        }

        const handleActaFirmada = () => {
            this.setState({ actaFirmada: !this.state.actaFirmada})
        }

        const abrirModalConvocatoria = () => {
            this.setState({ modalConvocatoria: true,seleccionLista:null })
        }

        
        const cellRendererComisionNombre = (rowIndex) => {
            return <Cell>{this.state.comisiones[rowIndex].NOMBRE}</Cell>
        }

        const cellRendererComisionTipo = (rowIndex) => {
            return <Cell>{this.state.comisiones[rowIndex].TIPO_COMISION.DESCRIPCION}</Cell>
        }

        const cellRendererComisionNMiembros = (rowIndex) => {
            return <Cell>{this.state.comisiones[rowIndex].miembros.length}</Cell>
        }
        const cellRendererComiteNombre = (rowIndex) => {
            return <Cell>{this.state.comite[rowIndex].DESCRIPCION}</Cell>
        }
        
        const cellRendererComiteNMiembros = (rowIndex) => {
            return <Cell>{this.state.comite[rowIndex].miembros.length}</Cell>
        }

        const esDiaConvocatoria=(date)=>{
            let esdia=false
            if(this.state.diasConvocatorias){
                
                this.state.diasConvocatorias.forEach(dia=>{
                    if(date.setHours(0)===dia.setHours(0))esdia= true
                })
            }
    
            return esdia
    
        }

       
        const modifiers = { esDiaConvocatoria }
    

        const handleChange = async(date) => {
            this.setState({ fechaConvocatoria: date });
            this.buscarConvocatorias(date)
        }

        const seleccionarConvocatoria = (ev) =>{
            this.setState({seleccionLista:{value:ev.currentTarget.dataset.id,label:ev.currentTarget.textContent}})            
        }

        const abrirModalComisiones=()=>{
            this.setState({modalComisiones:true})
        }
        const abrirModalComites=() => {
            this.setState({modalComites: true})
        }

        const cargarAsistentes=async()=>{

            this.setState({modalAdvertenciaAsistentes:false})

            switch(this.state.tipoReunionSeleccionada.value){
                case 1:
                case 2:
                case 4:
                   await this.cargarMiembrosCapital() 
                break;
                case 3:
                   await this.cargarMiembrosOrganoAdmon() 
                break;
                case 5:
                    abrirModalComisiones() 
                break;
                case 6:
                  await  this.cargarMiembrosCompliance()
                break;
                case 7:
                  await  this.cargarMiembrosOrganoInstruccion()
                break;
                case 8:
                   await  this.cargarMiembrosOrganoResolucion()
                break;
                case 9:
                    abrirModalComites()
                break;
                default:
                break;
            }
            if(this.state.tipoReunionSeleccionada.value!==5)this.setState({comisionSeleccionada:null})
        }

        const seleccionarTipoReunion  = async(value) => { 
             await this.setState({tipoReunionSeleccionada: value})
             console.log(this.state.tipoReunionSeleccionada)

            if(this.props.numeroAsistentes>0){
                this.setState({modalAdvertenciaAsistentes:true})
                return
            }
            await cargarAsistentes()
            
        }

        const cerrarModal2 = () => {
            this.setState({
                modalComisiones:false,
                modalAdvertenciaAsistentes:false,
                modalComites:false
            })
        }
        
        const seleccionarComision=(value)=>{
            if(value.length===0)return
            let seleccionada=this.state.comisiones[value[0].rows[0]]  
            this.setState({comisionSeleccionada:seleccionada})
        }
        const seleccionarComite=(value)=>{
            if(value.length===0) return
            let seleccionado= this.state.comite[value[0].rows[0]]
            this.setState({comiteSeleccionado:seleccionado})
        }

        const aceptarComision=async()=>{

            await this.props.setConvocados(this.state.comisionSeleccionada.miembros)
            await this.props.setAsistentes(this.state.comisionSeleccionada.miembros)
            await this.props.setNumeroAsistentes(this.state.comisionSeleccionada.miembros.length)

             this.setState({
                asistentesDirectos:this.state.comisionSeleccionada.miembros.length,
                porcentaje1:this.state.comisionSeleccionada.miembros.length>0?100:0,
                asistentesRepresentados:0,
                porcentaje2:0
            })

            cerrarModal2()
        }
        

        const cancelarComision = () => {
            alert("Debe seleccionar una comision")
            this.setState({
                comisionSeleccionada: null,
                tipoReunionSeleccionada:null
            })
            cerrarModal2()
        }
        const aceptarComite= async() =>{
            await this.props.setConvocados(this.state.comiteSeleccionado.miembros)
            await this.props.setAsistentes(this.state.comiteSeleccionado.miembros)
            await this.props.setNumeroAsistentes(this.state.comiteSeleccionado.miembros.length)

             this.setState({
                asistentesDirectos:this.state.comiteSeleccionado.miembros.length,
                porcentaje1:this.state.comiteSeleccionado.miembros.length>0?100:0,
                asistentesRepresentados:0,
                porcentaje2:0
            })

            cerrarModal2()

        }
        const cancelarComite = () => {
            alert("Debe seleccionar una comité")
            this.setState({
                comiteSeleccionado: null,
                tipoReunionSeleccionada:null
            })
            cerrarModal2()
        }

        
        return(
            <>
            {this.state.cargandoAC?
                <Spinner/>
            :
            <>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Comisiones"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalComisiones}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Seleccione la comision"
                        labelFor="informacion"
                        >
                            <Table defaultColumnWidth={150} onSelection={seleccionarComision} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.comisiones.length}>
                                <Column loading={this.state.cargandoComisiones} name="Nombre" cellRenderer={cellRendererComisionNombre}/>   
                                <Column loading={this.state.cargandoComisiones} name="Tipo" cellRenderer={cellRendererComisionTipo}/>   
                                <Column loading={this.state.cargandoComisiones} name="Numero Miembros" cellRenderer={cellRendererComisionNMiembros}/>  
                            </Table>                       
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.state.editandoPuesto?"Editar punto.":"Agregar punto."} position="top">
                            <AnchorButton
                                disabled={this.state.comisionSeleccionada?false:true}
                                intent={Intent.PRIMARY}
                                onClick={aceptarComision}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cancelarComision}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>

            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Comité"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalComites}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label="Seleccione el Comité"
                        labelFor="informacion"
                        >
                            <Table defaultColumnWidth={250} onSelection={seleccionarComite} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} numRows={this.state.comite.length}>
                                <Column loading={this.state.cargandoComites} name="Nombre" cellRenderer={cellRendererComiteNombre}/>   
                                <Column loading={this.state.cargandoComites} name="Numero Miembros" cellRenderer={cellRendererComiteNMiembros}/>  
                            </Table>                       
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.state.editandoPuesto?"Editar punto.":"Agregar punto."} position="top">
                            <AnchorButton
                                disabled={this.state.comiteSeleccionado?false:true}
                                intent={Intent.PRIMARY}
                                onClick={aceptarComite}
                            >
                                Aceptar
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cancelarComite}>Cancelar</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={this.cerrarModal}
                    title="Elegir convocatoria"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalConvocatoria}
                >
                    <div className={Classes.DIALOG_BODY + "pixelAlrededor"} >
                        <div className="tablaMitadIzquierda" id="calendario-convocatorias">
                        <DatePicker minDate={new Date(1900, 1 ,1)} 
                                localeUtils={MomentLocaleUtils}
                                modifiers={modifiers}
                                onChange={(newDate) => handleChange(newDate)}
                                value={this.state.fechaConvocatoria}
                                locale={"es"}
                            />
                        </div>
                        <div className="tablaMitadDerecha" id="listado-convocatorias">
                        <ul>
                            {this.state.convocatorias.map((orden) => 
                                <li key={orden.value} data-id={orden.value} onClick={seleccionarConvocatoria}>{orden.label}</li>)
                            }
                        </ul>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Seleccionar convocatoria" position="top">
                            <AnchorButton
                                id="btn-seleccionar-convocatoria"
                                intent={Intent.PRIMARY}
                                onClick={guardarConvocatoria}
                            >
                                Seleccionar convocatoria
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal2}
                        title="Borrar una cuenta"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAdvertenciaAsistentes}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Ya introducido asistentes a esta reunión, desea reemplazarlos?</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar administrador." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={cargarAsistentes}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal2}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>


                <Card interactive={true} elevation={Elevation.TWO}>
                    <FormGroup
                        label="Si existe una convocatoria de reunión para este acta, selecciónela:"
                        labelFor="selecionar-convocatoria"
                    >
                        <InputGroup id="convocatoria-seleccionada" value={this.state.convocatoriaSeleccionada?this.state.convocatoriaSeleccionada.label:null} disabled />
                            <Tooltip content="Seleccionar reunión">
                                <Button id="btn-seleccion-convocatoria" icon="send-to-map" onClick={abrirModalConvocatoria} />
                            </Tooltip>
                    </FormGroup>  
                    <div className="tablaMitadIzquierda">
                        <FormGroup
                            label="Número del acta"
                            labelFor="numero-acta"
                            inline={true}
                        >
                            <NumericInput
                                id="numeracion-acta"
                                onValueChange={handleNumeroActa}
                                value={this.state.numeroActa}
                                min={1}
                            />
                        </FormGroup>
                    </div>
                    <div className="tablaMitadDerecha">
                        <FormGroup
                            label="Fecha de la reunión:"
                            labelFor="fecha-reunion"
                            inline={true}
                        >
                            <div id="fecha-acta">
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFecha}
                                    value={this.state.fechaReunion}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className="tablaMitadIzquierda">
                        <FormGroup
                            label="Reunión de:"
                            labelFor="numero-acta"
                        >
                            <div id="select-tipo-reunion" style={!this.state.tipoReunionSeleccionada?{border:"1px solid red"}:{}}>
                                <Select
                                    options={this.state.tiposReuniones}
                                    className="pixelAlrededor"
                                    onChange={seleccionarTipoReunion}
                                    value={this.state.tipoReunionSeleccionada}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className="tablaMitadDerecha">
                        <FormGroup
                            label="Lugar de la reunión:"
                            labelFor="lugar-reunion"
                            inline={true}
                        >
                            <InputGroup id="lugar-reunion" value={this.state.lugarReunion} onChange={handleLugarReunion} intent={this.state.lugarReunion?"primary":"danger"} />   
                        </FormGroup>
                    </div>
                    <div id="datos-votacion">
                        <div className="tablaSesenta">
                            <FormGroup
                                label="Asistentes con derecho a voto que han acudido personalmente:"
                                labelFor="asistentes-directos"
                                inline={true}
                            >
                                <InputGroup
                                    onValueChange={handleAsistentesDirectos}
                                    value={this.state.asistentesDirectos}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </div>
                        <div className="tablaCuarenta">
                            <FormGroup
                                label="% del total:"
                                labelFor="porcentaje-total"
                                inline={true}
                            >
                                <InputGroup
                                    onValueChange={handleAsistentesPorcentaje1}
                                    value={this.state.porcentaje1}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </div>
                        <div className="tablaSesenta">
                            <FormGroup
                                label="Asistentes con derecho a voto que han acudido representados por otra persona:"
                                labelFor="asistentes-representados"
                                inline={true}
                                fill={true}
                            >
                                <InputGroup
                                    onValueChange={handleAsistentesRepresentados}
                                    value={this.state.asistentesRepresentados}
                                    readOnly={true}
                                />
                            </FormGroup>
                        </div>
                        <div className="tablaCuarenta">
                        <FormGroup
                            label="% del total:"
                            labelFor="porcentaje-total"
                            inline={true}
                            fill={true}
                        >
                            <InputGroup
                                onValueChange={handleAsistentesPorcentaje2}
                                value={this.state.porcentaje2}
                                readOnly={true}
                            />
                        </FormGroup>
                    </div>
                    </div>

                    <div className="tablaMitadIzquierda">
                        <FormGroup
                            label="¿El acta está aprobada?"
                            labelFor="acta-aprobada"
                            inline={true}
                        >
                            <Switch id="boleano-acta-aprobada" innerLabelChecked="Si" innerLabel="No" onChange={handleActaAprobada} checked={this.state.actaAprobada} />
                        </FormGroup>
                    </div>
                    <div className="tablaMitadIzquierda">
                        <FormGroup
                            label="Fecha de aprobación:"
                            labelFor="fecha-aprobacion"
                            inline={true}
                        >
                            <div id="fecha-aprobacion-acta">
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    onChange={escogeFechaAprobacion}
                                    disabled={!this.state.actaAprobada}
                                    value={this.state.fechaAprobacion}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className="tablaMitadDerecha">
                        <FormGroup
                            label="Sistema de aprobación:"
                            labelFor="sistema-aprobacion"
                            inline={true}
                        >
                            <InputGroup id="sistema-aprobacion-acta" value={this.state.sistemaAprobacion}  disabled={!this.state.actaAprobada} onChange={handleSistemaAprobacion} />   
                        </FormGroup>
                    </div>

                    <div className="tablaMitadDerecha">
                        <FormGroup
                            label="¿El acta está firmada?"
                            labelFor="acta-firmada"
                            inline={true}
                        >
                            <Switch id="boleano-acta-firmada" innerLabelChecked="Si" innerLabel="No" onChange={handleActaFirmada} checked={this.state.actaFirmada} />
                        </FormGroup>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Card>
            </>
            }
          </>  
        )
    }
}