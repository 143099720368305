import React, { Component } from 'react';
import { Card } from "@blueprintjs/core"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class Legislacion extends Component {
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"}) + " | " + i18next.t("legislacion.title",{ns:"page"})

        return (
            <>
                <div className="pixelAlrededor">
                    <Card id="legislacion-aplicable">
                        <h5><a href="#legislacion-aplicable">{i18next.t("legislacion.legislacionaplicable",{ns:"page"})}</a></h5>
                        <p>{i18next.t("legislacion.leyes",{ns:"page"})}</p>
                        <div className="tablaTercio">
                            <a class="bp3-button bp3-icon-git-repo" role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544">{i18next.t("legislacion.leycapital",{ns:"page"})}</a>
                        </div>
                        <div className="tablaTercio">
                            <a class="bp3-button bp3-icon-git-repo" role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-1996-17533">{i18next.t("legislacion.mercantil",{ns:"page"})}</a>
                        </div>
                        <div className="tablaTercio">
                            <a class="bp3-button bp3-icon-git-repo" role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-9953#:~:text=Se añade el artículo 31,de hecho o de derecho.">{i18next.t("legislacion.codigopenal",{ns:"page"})}</a>
                        </div>
                        <br />
                        <br />
                        <br />
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Legislacion);