/* eslint-disable array-callback-return */
import React from "react"
import {Spinner, AnchorButton, Card, Navbar, ButtonGroup, Alignment, Button, Dialog, Classes, Tooltip, Intent, FormGroup, Elevation, Collapse, TextArea, Callout } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import moment from 'moment';
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";

import styles from '../stylesPrint';

class PactosReservados extends React.Component {

    
    state = {
        nuevoActivado: false,
        pactos: [
        ],
        abierto1: false,
        abierto2: false,
        personaSeleccionada: null,
        listaPersonas: [],
        textoPacto: "",
        fecha:new Date(),
        personasSeleccionadas:[],
        editarActivado:false,
        borrarActivado:false,
        pactoSeleccionado:null,
        cargando:false,
        cargandoImprimir:false,
        cargandoImprimirPR:false,
        modalImprimir:false,
        modalImprimirPR:false,
        cargandoGrid:false
    }

    obtenerNombreMiembros=async(persona)=>{
        let nombre=''
        if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_PERSONA){
                    nombre= `${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })    
           
         //Si es persona juridica
         }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===persona.ID_PERSONA){
                     nombre= miembro.RAZON_SOCIAL
                 }
             })
            //  await this.props.client
            //  .query({
            //      query: gql`
            //          {personasJuridicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
            //      `,
            //      fetchPolicy:'network-only'

            //  }).then(result=>{ 
 
            //     persona.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

            //  })
 
         }
         persona.nombre=nombre
    }

    cargarCapital=async()=>{

        let capital=this.props.capSocialInicialDistribucion
        // await this.props.client.query({
        //   query:gql`{capSocialInicialDistribucion{
        //     ID_PERSONA,
        //     PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
        //   }}`,fetchPolicy:'network-only'
        // }).then(async(result)=>{
        //     return JSON.parse(JSON.stringify(result.data.capSocialInicialDistribucion))
        // })

        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })

        let cap_new=[];
        for(let cap of capital){
          //buscamos el nombre de la persona
          let nombre=""
          if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
              this.props.personasFisicas.find(persona=>{
                  if(persona.ID_PERSONA===cap.ID_PERSONA){
                      nombre= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}` 
                  }
              })
    
          //Si es persona juridica
          }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
              this.props.personasJuridicas.find(persona=>{
                  if(persona.ID_PERSONA===cap.ID_PERSONA){
                      nombre= persona.RAZON_SOCIAL
                  }
              })
    
            }
            cap_new.push({
                "label":nombre,
                "value":cap.ID_PERSONA
            })
            cap.label=nombre
            cap.value=cap.ID_PERSONA

      }

    
      for (let transmision of transmisiones){
        let nombre=''
        if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                    nombre= `${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })    
           
         //Si es persona juridica
         }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                     nombre= miembro.RAZON_SOCIAL
                 }
             })
        }
        transmision.FK_DESTINO.nombre=nombre     

        //   await this.obtenerNombreMiembros(transmision.FK_DESTINO)
          cap_new.push({
              "label":transmision.FK_DESTINO.nombre,
              "value":transmision.FK_DESTINO.ID_PERSONA
          })
      }

      this.setState({listaPersonas:cap_new})

    }

    cargarPactos=async()=>{

        let pactos=await this.props.client.query({
            query:gql`{sociosPactosReservados{
                ID_PACTO,
                FECHA,
                TEXTO,
                SOCIOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.sociosPactosReservados))
        })
        console.log(pactos)
        this.setState({pactos:pactos})
    }

    componentDidMount=()=>{
        this.setState({
            cargando:true
        }, async() =>{
          await  this.cargarCapital()
          await this.cargarPactos()
          this.setState({cargando:false})
        })        
    }

    generarImprimirPR= async() =>{
        console.log(this.state.personasSeleccionadas)
        this.setState({
            cargandoImprimirPR:true,
            modalImprimirPR:true,
            cargandoGrid:true
        }, async () =>{
            this.setState({
                cargandoImprimirPR:false
            }, () =>{
                 ReactDOM.render(this.ImprimirPR(), document.getElementById('generarPR-pdf'))
            })
        })
    }

    generarImprimirGral=async() =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true,
            cargandoGrid:true
        }, async () =>{
            this.setState({
                cargandoImprimir:false
            }, () =>{
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generar-pdf'))
            })
        })
    }

    ImprimirGral=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("pactosreservados.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.pactos?this.state.pactos.map((pacto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column' , fontSize:10, fontFamily: 'Open Sans Bold' , width:'100%'}}>{this.props.i18next.t("pactosreservados.pactos",{ns:"page"}).toUpperCase()}</Text>
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans',width:'100%'}}>{this.props.i18next.t("pactosreservados.pactodate",{ns:"page"}).toUpperCase()}: {moment(pacto.FECHA).format("DD-MM-YYYY")}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    ImprimirPR= () =>(
    <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("pactosreservados.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.pactoSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("pactosreservados.fecha",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{moment(this.state.pactoSeleccionado.FECHA).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("pactosreservados.persona",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.pactoSeleccionado.SOCIOS}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("pactosreservados.texto",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.pactoSeleccionado.TEXTO}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
 

    render(){

        document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("pactosreservados.title",{ns:"page"})
        
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalNuevoAbierto: true,
                pactoSeleccionado:null,
                textoPacto:null,
                personasSeleccionadas:[],
                fecha:new Date(),
                cargandoGrid:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.pactoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            let personas=[]
            let sociosPacto=this.state.pactoSeleccionado.SOCIOS.split(",")
            for(let socio of sociosPacto){
                let personasSel=this.state.listaPersonas.find(persona=>{return persona.value===parseInt(socio)})
                personas.push(personasSel)
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalNuevoAbierto: true,
                cargandoGrid:true,
                fecha:new Date(this.state.pactoSeleccionado.FECHA),
                textoPacto:this.state.pactoSeleccionado.TEXTO,
                personasSeleccionadas:personas
            })
        }

        const abrirModalBorrar= () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.pactoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
                cargandoGrid:true
            })
            
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const añadirNuevo = () => {

            if(!this.state.fecha)return
            if(!this.state.personasSeleccionadas.length>0)return
            if(!this.state.textoPacto)return

            this.props.client.mutate({
                mutation:gql`mutation{crearSocioPactoReservado(VALORES:{
                    FECHA:"${moment(this.state.fecha).format("YYYY-MM-DD")}"
                    TEXTO:"""${this.state.textoPacto}"""
                    SOCIOS:"${this.state.personasSeleccionadas.map(persona=>{return persona.value}).join(",")}"
                }){ID_PACTO}}`
            }).then(async result=>{
            
                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                cerrarModal()
                await this.cargarPactos()

                
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })
        }

        const editarPacto=()=>{

            if(!this.state.fecha)return
            if(!this.state.personasSeleccionadas.length>0)return
            if(!this.state.textoPacto)return

            this.props.client.mutate({
                mutation:gql`mutation{actualizarSocioPactoReservado(
                    ID_PACTO:${this.state.pactoSeleccionado.ID_PACTO},
                    VALORES:{
                    FECHA:"${moment(this.state.fecha).format("YYYY-MM-DD")}"
                    TEXTO:"""${this.state.textoPacto}"""
                    SOCIOS:"${this.state.personasSeleccionadas.map(persona=>{return persona.value}).join(",")}"
                }){ID_PACTO}}`
            }).then(async result=>{
            
                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                cerrarModal()
                await this.cargarPactos()

                

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }

        const eliminarPacto=()=>{

            this.props.client.mutate({
                mutation:gql`mutation{eliminarSocioPactoReservado(
                    ID_PACTO:${this.state.pactoSeleccionado.ID_PACTO}
                ){ID_PACTO}}`
            }).then(async result=>{
            
                alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))
                cerrarModal()
                await this.cargarPactos()

                this.setState({
                    pactoSeleccionado:null
                })


            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false
            })
        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({fecha: nuevaFecha})
        }

        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false });
        }

        const onSeleccionarPersona=(value)=>{
            this.setState({personasSeleccionadas:value})
        }

        const handleCambiarTexto = (ev) => {
            this.setState({textoPacto:ev.currentTarget.value})
        }

        const pactodate=this.props.i18next.t("pactosreservados.pactodate",{ns:"page"});

        function pactoFormatter(cell, row) {
            return (
                pactodate+": "+cell
            );
        }

        const columns = [
        {
        dataField: 'FECHA',
        text: this.props.i18next.t("pactosreservados.pactos",{ns:"page"}),
        formatter: pactoFormatter
        }
        ];

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            pactoSeleccionado: row
        });
        }
        return true;
        }

        const cerrarModalImprimir= () =>{
            this.setState({
                modalImprimir:false,
                modalImprimirPR:false,
                cargandoGrid:false
            })
        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/> 
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPR}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirPR?
                            <div style={{ width: '100%', height: '600px'}} id='generarPR-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generar-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <div className="tablaSesenta">
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    className="dialog-ancho"
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label={this.props.i18next.t("pactosreservados.fecha",{ns:"page"})+":"}
                            labelFor="fecha"
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                value={this.state.fecha}
                            />
                        </FormGroup>
                        <br />
                        <FormGroup
                                label={this.props.i18next.t("pactosreservados.persona",{ns:"page"})+":"}
                                labelFor="selecionar-persona"
                                intent={!this.state.personasSeleccionadas.length>0?"danger":""}
                                helperText={!this.state.personasSeleccionadas.length>0?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("pactosreservados.persona",{ns:"page"}):""}
                        >  
                        <div style={!this.state.personasSeleccionadas.length>0?{border:"1px solid red"}:{}}>
                            <Select 
                                options={this.state.listaPersonas}
                                onChange={onSeleccionarPersona}
                                isMulti={true}
                                value={this.state.personasSeleccionadas}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup
                                label={this.props.i18next.t("pactosreservados.texto",{ns:"page"})+":"}
                                labelFor="selecionar-persona"
                                intent="danger"
                                helperText={!this.state.textoPacto?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("pactosreservados.texto",{ns:"page"}):""}
                        >
                            <div style={!this.state.textoPacto?{border:"1px solid red"}:{}}>
                                <TextArea fill={true} value={this.state.textoPacto} onChange={handleCambiarTexto} />
                            </div>
                        </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:editarPacto}
                            >
                                {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={this.props.i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{this.props.i18next.t("pactosreservados.questiondel",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("delete",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarPacto}
                                >
                                    {this.props.i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Card>
                    {this.state.cargandoGrid?
                            <Cargando/>
                        :
                    <>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                <Tooltip content="Desea imprimir el pacto seleccionado" position="right" >
                                    <Button className="bp3-minimal" icon="print" disabled={this.state.pactoSeleccionado?false:true}  text={this.props.i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirPR()}/>
                                </Tooltip>
                                <Tooltip content={this.props.i18next.t("printgral",{ns:"global"})} position="right" >
                                    <Button className="bp3-minimal" icon="print" text={this.props.i18next.t("printgral",{ns:"global"})} onClick={()=>this.generarImprimirGral()}/>
                                </Tooltip>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                    keyField='ID_PACTO'
                    data={ this.state.pactos }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                    />
                    </>
                    }
                </Card>
                </div>
                <div className="tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("pactosreservados.titleley",{ns:"page"})}</Callout>
                    <br />
                    <div className="tablaMitad">
                        <Button icon="git-repo" onClick={handleClick1}>
                            {this.props.i18next.t("pactosreservados.articulo29",{ns:"page"})} 
                        </Button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                        <Collapse isOpen={this.state.abierto1}>
                            <Card> 
                            <b>{this.props.i18next.t("pactosreservados.articulo29",{ns:"page"})}</b>
                            <p>{this.props.i18next.t("pactosreservados.descarticulo",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                </Card>
                </div>
            </>
            }
           </> 
        )
    }
}export default withApollo(PactosReservados)