import React, {Component} from 'react';
import {
    Card,
    Elevation,
    Label,
    Switch,
    Alignment
} from '@blueprintjs/core';

class DataAcuerdo extends Component {
    state={
        numAcuerdo:0,
        inscripcionRegistro:false
    }

    componentDidMount = () => {
        if(this.props.acuerdo && Object.keys(this.props.acuerdo).length>0){
            this.setState({
                numAcuerdo:this.props.acuerdo.NUMERO,
                inscripcionRegistro:this.props.acuerdo.INSCRIBIBLE_RM
            })
        } else {
            this.setState({
                numAcuerdo:this.props.numeroAcuerdo,
            })
        }
    }
    
    render() {
        return (
            <>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Label>{this.props.i18next.t("actas.nrodelacuerdo",{ns:"page"})}: <b>{this.state.numAcuerdo}</b></Label>
                    <br></br>
                    <Label>{this.props.i18next.t("actas.votosfavor",{ns:"page"})}: <b>{this.props.favor}</b> (<b>{this.props.porcFavor}%</b>)</Label>
                    <Label>{this.props.i18next.t("actas.votosencontra",{ns:"page"})}: <b>{this.props.contra}</b> (<b>{this.props.porcContra}%</b>)</Label>
                    <Label>{this.props.i18next.t("actas.abstenciones",{ns:"page"})}: <b>{this.props.abstencion}</b> (<b>{this.props.porcAbstencion}%</b>)</Label>
                    <br></br>
                    <Switch id="boleano-registro-mercantil" alignIndicator={Alignment.RIGHT} checked={this.state.inscripcionRegistro} onChange={() => this.setState({ inscripcionRegistro: !this.state.inscripcionRegistro })} label={this.props.i18next.t("actas.questioninscribir",{ns:"page"})} innerLabel={this.props.i18next.t("no",{ns:"global"})} innerLabelChecked={this.props.i18next.t("yes",{ns:"global"})} />
                    
                </Card>
            </>
        );
    }
}

export default DataAcuerdo;