import React from "react"
import { FormGroup, Card, Alignment, TextArea, Switch,Tree, Classes,AnchorButton,Tooltip,Dialog, InputGroup, Intent, Button} from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import const_table from "../BootstrapTable";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

class Instruccion extends React.Component {

    state={
        comenzada: false,
        estadoInstruccion: [
        ],
        fechaInstruccion:new Date(),
        estadoInstruccionSeleccionado:null,
        riesgosRelacionados:null,
        motivoDenuncia:null,
        conclusiones:null,
        nuevoEstado:null,
        modalEstadoAbierto:false,
        arbolRiesgos: [
        
        ],
        riesgosSeleccionados: [],
        riesgosSel:null,
        riesgoSeleccionadoTabla:null
    }

    reiniciarEstado() {
        this.setState({
            comenzada: false,
            fechaInstruccion:new Date(),
            estadoInstruccionSeleccionado:null,
            riesgosRelacionados:null,
            motivoDenuncia:null,
            conclusiones:null,
            nuevoEstado:null,
            modalEstadoAbierto:false,
            arbolRiesgos: [
        
            ],
            riesgosSeleccionados: [],
            riesgosSel:null,
            riesgoSeleccionadoTabla:null
        })
    }

    cargarEstadosInstruccion=async ()=>{
        //Estados instruccion
        await client.query({
            query:gql`{estadosInstruccionDenuncias{ID_ESTADO_INSTRUCCION_DENUNCIA,DESCRIPCION}}            `
        }).then(async(result)=>{
            //console.log(result)
            let estados=[]
            result.data.estadosInstruccionDenuncias.forEach(async(estado)=>{
             
                estados.push({"value":estado.ID_ESTADO_INSTRUCCION_DENUNCIA,"label":estado.DESCRIPCION}) 

            })
            this.setState({estadoInstruccion:estados})
        })

        
        if(this.props.datosInstruccion && Object.keys(this.props.datosInstruccion).length >0){

            let riesgos=this.props.datosInstruccion.RIESGOS_RELACIONADOS?this.props.datosInstruccion.RIESGOS_RELACIONADOS.split(","):""
            for(let r of riesgos){
                this.buscarRiesgo(this.state.arbolRiesgos,parseInt(r))
            }

            this.setState({comenzada:this.props.datosInstruccion})
            this.setState({fechaInstruccion:this.props.datosInstruccion?new Date(this.props.datosInstruccion.FECHA_ALTA):new Date()})
            this.setState({motivoDenuncia:this.props.datosInstruccion?this.props.datosInstruccion.MOTIVO_DENUNCIA:""})
            this.setState({conclusiones:this.props.datosInstruccion?this.props.datosInstruccion.CONCLUSIONES:""})

            //buscamos el estado instruccion.
            let estadoSel=this.state.estadoInstruccion.find(estado=>{
                return estado.value===this.props.datosInstruccion.ID_ESTADO_INSTRUCCION 
            })

            this.setState({estadoInstruccionSeleccionado:estadoSel})    
        }else{
            this.setState({noexiste:true})
        }

    }

    buscarRiesgo=async(arbol,idRiesgo)=>{
         
        for(let hijo of arbol){
            if(parseInt(hijo.id.split("_").pop())===idRiesgo && hijo.id.includes("riesgo_")){
                let riesgos=this.state.riesgosSeleccionados
                riesgos.push(hijo)
                this.setState({riesgosSeleccionados:riesgos})
                return 
            }else{
                if(hijo.childNodes)this.buscarRiesgo(hijo.childNodes,idRiesgo)
            }
        }

    }

    tieneHijos=(riesgo,arrayRiesgos)=>{
        var arrayHijos=[];
        let codigo=riesgo.label.split("-")[0].trim()
        let index=1
        var pad = "00"

        arrayRiesgos.find((element) =>{
            if(element.ID_RIESGO_PADRE===parseInt(riesgo.id.split("_").pop())){
                arrayHijos.push({"depth":(element.childNodes?element.childNodes.length:0),id:"riesgo_"+element.ID_RIESGO,"label":codigo+pad.substring(0, pad.length - String(index).length) + index+" - "+element.TITULO,childNodes:(element.childNodes?element.childNodes:null),"descripcion":element.DESCRIPCION,"icon":(element.childNodes?"folder-close":"share")})
                index++
            }
            // eslint-disable-next-line array-callback-return
            return 
        });
        if(arrayHijos.length>0){
            riesgo.icon="folder-close"
            riesgo.childNodes=arrayHijos;
        }
        return riesgo;
    }

    cargarBloques=async ()=>{
        
        //obtencion de los bloques de riesgo
        var pad = "00"

        //obtencion de los bloques de riesgo
        let bloquesRiesgo=await client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.bloquesRiesgo;
        })

        let complianceRiesgos=await client.query({
            query:gql`{complianceRiesgos{ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE,ID_BLOQUE}}`
            ,fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.complianceRiesgos
        })
        let bloques=[]
        for(let bloque of bloquesRiesgo){        
            let index=1
            //obtencion de los riesgos de cada bloque.
            let hijos=[]
            // eslint-disable-next-line array-callback-return
            complianceRiesgos.find((hijo)=>{
                if (hijo.ID_BLOQUE===bloque.ID_BLOQUE) {
                    if(hijo.ID_RIESGO_PADRE===null){
                        let nuevoHijo={"depth":(hijo.childNodes?hijo.childNodes.length:0),"id":"riesgo_"+hijo.ID_RIESGO,"label":bloque.LETRA+pad.substring(0, pad.length - String(index).length) + index+" - "+hijo.TITULO,"descripcion":hijo.DESCRIPCION,"icon":"share","childNodes":(hijo.childNodes?hijo.childNodes:null)}
                        hijos.push(nuevoHijo)
                        this.tieneHijos(nuevoHijo,complianceRiesgos)
                        index++
                    }
                
                }
            })
            bloques.push({"depth":hijos.length,"id":"bloque_"+bloque.ID_BLOQUE,"label":"Bloque "+bloque.LETRA+" - "+bloque.TITULO,"icon":"folder-close",childNodes:hijos}) 
     
        }
            this.setState({arbolRiesgos:bloques})
    }

    componentDidMount=async()=>{
        await this.cargarBloques()
        await this.cargarEstadosInstruccion()        
    }

    handleNodeCollapse = (nodeData) => {
        nodeData.isExpanded = false;
        this.setState(this.state);
        };
        
        handleNodeExpand = (nodeData) => {
            nodeData.isExpanded = true;
            this.setState(this.state);
        };
        
        forEachNode(nodes, callback) {
            if (nodes == null) {
                return;
            }
    
            for (const node of nodes) {
                callback(node);
                this.forEachNode(node.childNodes, callback);
            }
    }
    
    handleNodeClick = (nodeData, _nodePath, e) => {

        
        if(this.state.riesgosSel){
            let riesgoSel=this.state.riesgosSel
            riesgoSel.isSelected=false
        }


        //si es un riesgo...
        if(nodeData.id.includes("riesgo_")){    
            this.setState({riesgosSel:nodeData})
            nodeData.isSelected=true
        } else {
            this.setState({riesgosSel:null})
        }
    }

    render(){


        const handleCambiarComienzaInstruccion=(ev)=>{
            this.setState({comenzada:!this.state.comenzada})
        }

        const handleCambiarFechaInstruccion=(value)=>{
            this.setState({fechaInstruccion:value})
        }

        const handleCambiarEstadoInstruccion=(value)=>{
            this.setState({estadoInstruccionSeleccionado:value})
        }

        const handleCambiarMotivoDenuncia=(ev)=>{
            this.setState({motivoDenuncia:ev.currentTarget.value})
        }

        const handleCambiarConclusiones=(ev)=>{
            this.setState({conclusiones:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const abrirModalNuevoEstado=()=>{
            this.setState({modalEstadoAbierto:true})
        }

        const handlenuevoEstado=(ev)=>{
            this.setState({nuevoEstado:ev.currentTarget.value})
        }

        const anadirEstado=(ev)=>{

            if(!this.state.nuevoEstado)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.estadoInstruccion.find(estado=>{
                return estado.label===this.state.nuevoEstado
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos la estado instruccion.
            client.mutate({
                mutation: gql`
                  mutation{crearEstadoInstruccionDenuncia(VALORES:{DESCRIPCION:"${this.state.nuevoEstado}"}){ID_ESTADO_INSTRUCCION_DENUNCIA}}
                `
            }).then(async result=>{
               
                await this.cargarEstadosInstruccion()

                let estadoCreado=result.data.crearEstadoInstruccionDenuncia
                let estadoSel=this.state.estadoInstruccion.find(estado=>{
                    return estado.value===estadoCreado.ID_ESTADO_INSTRUCCION_DENUNCIA
                })
                this.setState({estadoInstruccionSeleccionado:estadoSel})
                cerrarModal()

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const replegarArbol=(hijos)=>{
              
            hijos.forEach(hijo=>{
                hijo.isExpanded=false
                if(hijo.childNodes)replegarArbol(hijo.childNodes)
            })  
            this.setState({arbol:hijos})
        
        }
    

        const abrirModalRiesgos = () => {

            //cerramos el arbol
            replegarArbol(this.state.arbolRiesgos)

            if(this.state.riesgosSel){
                let riesgoSel=this.state.riesgosSel
                riesgoSel.isSelected=false
            }
            this.setState({modalRiesgos: true})
        }

        const cerrarModal = () => {
            this.setState({modalEstadoAbierto:false,modalRiesgos: false})
        }

        const seleccionarRiesgo = () => {
         
            if(!this.state.riesgosSel){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            //agregamos el riesgo a la tabla.
            let riesgos=this.state.riesgosSeleccionados
            //comprobamos que no este ya seleccionado.
            let existe=this.state.riesgosSeleccionados.find(ries=>{return ries.id.split("_").pop()===this.state.riesgosSel.id.split("_").pop()})
            if(existe){
                alert(i18next.t("yaseleccionado",{ns:"global"}))
                return
            }
            riesgos.push(this.state.riesgosSel)
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:this.state.riesgosSel
            })

            cerrarModal()
      
        }

        const eliminarRiesgoTabla = () => {
         
            if(!this.state.riesgoSeleccionadoTabla){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let riesgos=this.state.riesgosSeleccionados
            riesgos=riesgos.filter(riesgo=>{return riesgo.id!==this.state.riesgoSeleccionadoTabla.id})
            this.setState({
                riesgosSeleccionados:riesgos,
                riesgoSeleccionadoTabla:null
            });
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    riesgoSeleccionadoTabla: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const columns = [{
            dataField: 'label',
            text: i18next.t("identificacion.name",{ns:"page"})
            }
        ];

        return (
            <>

                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("edicionriesgo.riesgos",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalRiesgos}
                >
                <div className={Classes.DIALOG_BODY}>
                    <Tree
                        contents={this.state.arbolRiesgos}
                        onNodeDoubleClick={this.handleNodeClick}
                        onNodeCollapse={this.handleNodeCollapse}
                        onNodeExpand={this.handleNodeExpand}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={seleccionarRiesgo}>
                        {i18next.t("identificacion.escogerriesgo",{ns:"page"})}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        {i18next.t("close",{ns:"global"})}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("denuncias.estadointruccion",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}nuevoEstado
                    isOpen={this.state.modalEstadoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("denuncias.enterestadoinstruccion",{ns:"page"})}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoEstado?"":i18next.t("denuncias.emptyestadoinstruccion",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-estado" onChange={handlenuevoEstado} intent={this.state.nuevoEstado?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirEstado}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card>
                        {(this.state.comenzada && (this.props.datosInstruccion && Object.keys(this.props.datosInstruccion).length >0 ))?
                         <Switch checked={true} disabled label={i18next.t("denuncias.comenzadainstruccion",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaInstruccion} />
                        :
                        <Switch  id="boleano-comenzar-instruccion" checked={this.state.comenzada} label={i18next.t("denuncias.comenzadainstruccion",{ns:"page"})} alignIndicator={Alignment.RIGHT} onChange={handleCambiarComienzaInstruccion} />
                        }                       
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.fechainstruccion",{ns:"page"}).toLowerCase())}
                            labelFor="fecha-alta-instruccion"
                            disabled={!this.state.comenzada}
                            inline={true}
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={handleCambiarFechaInstruccion}
                                value={this.state.fechaInstruccion}
                                align={Alignment.RIGHT}
                                disabled={!this.state.comenzada}
                            />
                        </FormGroup>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.estadoinstruccion",{ns:"page"}).toLowerCase())}
                            labelFor="estado-instruccion"
                            disabled={!this.state.comenzada}
                            intent="danger"
                            helperText={this.state.estadoInstruccionSeleccionado || !this.state.comenzada?"":i18next.t("denuncias.noselectinstruccion",{ns:"page"})}
                        >
                                <div style={this.state.estadoInstruccionSeleccionado || !this.state.comenzada?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.state.estadoInstruccion}
                                        className="pixelAlrededor"
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        isDisabled={!this.state.comenzada}
                                        onChange={handleCambiarEstadoInstruccion}
                                        value={this.state.estadoInstruccion.filter(estado => estado.value === (this.state.estadoInstruccionSeleccionado?this.state.estadoInstruccionSeleccionado.value:0))}
                                    />
                                </div>
                        </FormGroup>
                        <FormGroup>
                            <Button 
                                alignText="right"
                                icon="add"
                                id="btn-crear-estado"
                                className="iconoIzquierda"
                                disabled={!this.state.comenzada}
                                onClick={abrirModalNuevoEstado}
                            />
                        </FormGroup>
                        <div className="tablaTercio">
                            <FormGroup
                                label={i18next.t("identificacion.riesgosrelacionados",{ns:"page"})}
                                labelFor="riesgos-relacionados"
                                disabled={!this.state.comenzada}
                            >
                                {this.state.riesgosSeleccionados.length>0?
                                        <BootstrapTable
                                            keyField='id'
                                            data={ this.state.riesgosSeleccionados }
                                            columns={ columns }
                                            selectRow={ const_table.selectRow(handleOnSelect) }
                                            pagination={ paginationFactory(const_table.options) }
                                            headerClasses="header-class"
                                            rowClasses="row-class"
                                            hover
                                            condensed
                                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                        />
                                    :
                                        <p>{i18next.t("identificacion.debeaddriesgo",{ns:"page"})}</p>
                                }    
                                <div id="botones-riesgos-instruccion">                      
                                    <Button label={i18next.t("denuncias.addriesgo",{ns:"page"})} disabled={!this.state.comenzada} icon="add" onClick={abrirModalRiesgos} />
                                    <Button label={i18next.t("denuncias.delriesgo",{ns:"page"})} disabled={!this.state.comenzada} icon="delete" onClick={eliminarRiesgoTabla} />                          
                                </div>
                        </FormGroup>
                        </div>
                            <div className="tablaTercio">
                                <FormGroup
                                    label={const_table.capitalize(i18next.t("denuncias.motivodenuncia",{ns:"page"}).toLowerCase())}
                                    labelFor="motivo-denuncia"
                                    disabled={!this.state.comenzada}
                                    intent="danger"
                                    helperText={this.state.motivoDenuncia || !this.state.comenzada ?"":i18next.t("denuncias.emptymotivodenuncia",{ns:"page"})}
                                >
                                    <TextArea onChange={handleCambiarMotivoDenuncia} value={this.state.motivoDenuncia} intent={this.state.motivoDenuncia || !this.state.comenzada ?"primary":"danger"} fill={true} disabled={!this.state.comenzada} />
                                </FormGroup>
                            </div>
                            <div className="tablaTercio">
                                <FormGroup
                                    label={const_table.capitalize(i18next.t("denuncias.conclusiones",{ns:"page"}).toLowerCase())}
                                    labelFor="conclusiones"
                                    disabled={!this.state.comenzada}
                                >
                                    <TextArea onChange={handleCambiarConclusiones} value={this.state.conclusiones} fill={true} disabled={!this.state.comenzada} />
                                </FormGroup>
                            </div>
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Instruccion);