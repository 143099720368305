import React from 'react';
import { Card, Elevation, Navbar, NavbarHeading, Alignment, ButtonGroup, Button, Dialog, FormGroup, InputGroup, Classes, Tooltip, AnchorButton, Intent, TextArea } from "@blueprintjs/core"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { Cargando } from "../Cargando";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class MarcasDominios extends React.Component {

  state={
    cargando: false,

    modalMarcaAbierto:false,
    modalMarcaBorrar:false,
    nuevaMarcaActivo:false,
    editarMarca:false,
    eliminarMarca:false,
    marcaSeleccionada:null,
    marcas: [],
    marca:null,
    marca_nro_registro:null,
    marca_nacional_internacional:null,
    marca_clasificacion_niza:null,
    marca_descripcion_clase:null,
    marca_validez:null,
    
    modalNombreAbierto:false,
    modalNombreBorrar:false,
    nuevaNombreActivo:false,
    editarNombre:false,
    eliminarNombre:false,
    nombreSeleccionada:null,
    nombres: [],
    nombre:null,
    nombre_nro_registro:null,
    nombre_nacional_internacional:null,
    nombre_clasificacion_niza:null,
    nombre_descripcion_clase:null,
    nombre_validez:null,

    modalPatenteAbierto:false,
    modalPatenteBorrar:false,
    nuevaPatenteActivo:false,
    editarPatente:false,
    eliminarPatente:false,
    patenteSeleccionada:null,
    patentes: [],
    patente_descripcion:null,
    patente_validez:null,

    modalPropiedadAbierto:false,
    modalPropiedadBorrar:false,
    nuevaPropiedadActivo:false,
    editarPropiedad:false,
    eliminarPropiedad:false,
    propiedades: [],
    propiedad_software:null,
    propiedad_otro:null,
    propiedad_validez:null,

    modalDominioAbierto:false,
    modalDominioBorrar:false,
    nuevaDominioActivo:false,
    editarDominio:false,
    eliminarDominio:false,
    dominioSeleccionada:null,
    dominios: [],
    dominio_nombre:null,
    dominio_hosting:null,
    dominio_validez:null,

    cargandoMarcas:false,
    cargandoNombres:false,
    cargandoPatentes:false,
    cargandoPropiedades:false,
    cargandoDominios:false
  }

  componentDidMount=async()=>{
    await this.setState({
      cargando: true
    },async () =>{
      await this.cargarMarcas();
      await this.cargarNombresComerciales();
      await this.cargarPatentes();
      await this.cargarPropiedades();
      await this.cargarDominios();
      this.setState({
        cargando:false
      })
    })
    
  }

  cargarMarcas=async()=>{

    await this.props.client
    .query({
        query: gql`
        {
          personasJuridicasMarcas(ORDEN:"ID_MARCA,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_MARCA,NOMBRE,NRO_REGISTRO,NACIONAL_INTERNACIONAL,CLASIFICACION_NIZA,DESCRIPCION_CLASE,VALIDEZ}
        }
        `,
        fetchPolicy: 'network-only'

    }).then(result=>{ 
    
        let marcas=[]
        for(let marca of result.data.personasJuridicasMarcas){
          marcas.push({"id":marca.ID_MARCA,"nombre":marca.NOMBRE,"nro_registro":marca.NRO_REGISTRO,"nacional_internacional":marca.NACIONAL_INTERNACIONAL,"clasificacion_niza":marca.CLASIFICACION_NIZA,"descripcion_clase":marca.DESCRIPCION_CLASE,"validez":marca.VALIDEZ})
        }

        this.setState({
          marcas:marcas,
        })
    
    })
    
  }

  cargarNombresComerciales=async()=>{

    await this.props.client
    .query({
        query: gql`
        {
          personasJuridicasComerciales(ORDEN:"ID_COMERCIAL,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_COMERCIAL,NOMBRE,NRO_REGISTRO,NACIONAL_INTERNACIONAL,CLASIFICACION_NIZA,DESCRIPCION_CLASE,VALIDEZ}
        }
        `,
        fetchPolicy: 'network-only'

    }).then(result=>{ 
    
        let nombres=[]
        for(let nombre of result.data.personasJuridicasComerciales){
          nombres.push({"id":nombre.ID_COMERCIAL,"nombre":nombre.NOMBRE,"nro_registro":nombre.NRO_REGISTRO,"nacional_internacional":nombre.NACIONAL_INTERNACIONAL,"clasificacion_niza":nombre.CLASIFICACION_NIZA,"descripcion_clase":nombre.DESCRIPCION_CLASE,"validez":nombre.VALIDEZ})
        }

        this.setState({
          nombres:nombres,
        })
    
    })
    
  }

  cargarPatentes=async()=>{

    await this.props.client
    .query({
        query: gql`
        {
          personasJuridicasPatentes(ORDEN:"ID_PATENTE,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PATENTE,DESCRIPCION,VALIDEZ}
        }
        `,
        fetchPolicy: 'network-only'

    }).then(result=>{ 
    
        let patentes=[]
        for(let patente of result.data.personasJuridicasPatentes){
          patentes.push({"id":patente.ID_PATENTE,"descripcion":patente.DESCRIPCION,"validez":patente.VALIDEZ})
        }

        this.setState({
          patentes:patentes,
        })
    
    })
    
  }

  cargarPropiedades=async()=>{

    await this.props.client
    .query({
        query: gql`
        {
          personasJuridicasPropiedades(ORDEN:"ID_PROPIEDAD,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PROPIEDAD,SOFTWARE,OTRO,VALIDEZ}
        }
        `,
        fetchPolicy: 'network-only'

    }).then(result=>{ 
    
        let propiedades=[]
        for(let propiedad of result.data.personasJuridicasPropiedades){
          propiedades.push({"id":propiedad.ID_PROPIEDAD,"software":propiedad.SOFTWARE,"otro":propiedad.OTRO,"validez":propiedad.VALIDEZ})
        }

        this.setState({
          propiedades:propiedades,
        })
    
    })
    
  }

  cargarDominios=async()=>{

    await this.props.client
    .query({
        query: gql`
        {
          personasJuridicasDominios(ORDEN:"ID_DOMINIO,ASC",FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_DOMINIO,NOMBRE,HOSTING,VALIDEZ}
        }
        `,
        fetchPolicy: 'network-only'

    }).then(result=>{ 
    
        let dominios=[]
        for(let dominio of result.data.personasJuridicasDominios){
          dominios.push({"id":dominio.ID_DOMINIO,"nombre":dominio.NOMBRE,"hosting":dominio.HOSTING,"validez":dominio.VALIDEZ})
        }

        this.setState({
          dominios:dominios,
        })
    
    })
    
  }

  render() {
    if (this.state.cargando) {
      return <Cargando />
    }
    const abrirModalMarcaNuevo = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
 
      this.setState({
        modalMarcaAbierto: true,
        nuevaMarcaActivo: true,
        editarMarca: false,
        eliminarMarca: false,
        marcaSeleccionada:null,
        cargandoMarcas:true
      });
      
    }

    const abrirModalMarcaAbierto = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
   
      if(!this.state.marcaSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalMarcaAbierto: true,
        nuevaMarcaActivo: false,
        editarMarca: true,
        eliminarMarca: false,
        marca:this.state.marcaSeleccionada.nombre,
        marca_nro_registro:this.state.marcaSeleccionada.nro_registro,
        marca_nacional_internacional:this.state.marcaSeleccionada.nacional_internacional,
        marca_clasificacion_niza:this.state.marcaSeleccionada.clasificacion_niza,
        marca_descripcion_clase:this.state.marcaSeleccionada.descripcion_clase,
        marca_validez:this.state.marcaSeleccionada.validez,
        cargandoMarcas:true
      });

      
    }

    const abrirModalMarcaEliminar = () =>{

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }

      if(!this.state.marcaSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalMarcaBorrar: true,
        nuevaMarcaActivo: false,
        editarMarca: false,
        eliminarMarca: true,
      });
      
    }
    
    const abrirModalNombreNuevo = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
 
      this.setState({
        modalNombreAbierto: true,
        nuevaNombreActivo: true,
        editarNombre: false,
        eliminarNombre: false,
        nombreSeleccionada:null,
        cargandoNombres:true
      });
      
    }

    const abrirModalNombreAbierto = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
   
      if(!this.state.nombreSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalNombreAbierto: true,
        nuevaNombreActivo: false,
        editarNombre: true,
        eliminarNombre: false,
        cargandoNombres:true,
        nombre:this.state.nombreSeleccionada.nombre,
        nombre_nro_registro:this.state.nombreSeleccionada.nro_registro,
        nombre_nacional_internacional:this.state.nombreSeleccionada.nacional_internacional,
        nombre_clasificacion_niza:this.state.nombreSeleccionada.clasificacion_niza,
        nombre_descripcion_clase:this.state.nombreSeleccionada.descripcion_clase,
        nombre_validez:this.state.nombreSeleccionada.validez,
      });

      
    }

    const abrirModalNombreEliminar = () =>{

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }

      if(!this.state.nombreSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalNombreBorrar: true,
        nuevaNombreActivo: false,
        editarNombre: false,
        eliminarNombre: true,
      });
      
    }
    
    const abrirModalPatenteNuevo = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
 
      this.setState({
        modalPatenteAbierto: true,
        nuevaPatenteActivo: true,
        editarPatente: false,
        eliminarPatente: false,
        patenteSeleccionada:null,
        cargandoPatentes:true
      });
      
    }

    const abrirModalPatenteAbierto = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
   
      if(!this.state.patenteSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalPatenteAbierto: true,
        nuevaPatenteActivo: false,
        editarPatente: true,
        eliminarPatente: false,
        patente_descripcion:this.state.patenteSeleccionada.descripcion,
        patente_validez:this.state.patenteSeleccionada.validez,
        cargandoPatentes:true
      });

      
    }

    const abrirModalPatenteEliminar = () =>{

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }

      if(!this.state.patenteSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalPatenteBorrar: true,
        nuevaPatenteActivo: false,
        editarPatente: false,
        eliminarPatente: true,
      });
      
    }
    
    const abrirModalPropiedadNuevo = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
 
      this.setState({
        modalPropiedadAbierto: true,
        nuevaPropiedadActivo: true,
        editarPropiedad: false,
        eliminarPropiedad: false,
        propiedadSeleccionada:null,
        cargandoPropiedades:true
      });
      
    }

    const abrirModalPropiedadAbierto = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
   
      if(!this.state.propiedadSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalPropiedadAbierto: true,
        nuevaPropiedadActivo: false,
        editarPropiedad: true,
        eliminarPropiedad: false,
        propiedad_software:this.state.propiedadSeleccionada.software,
        propiedad_otro:this.state.propiedadSeleccionada.otro,
        propiedad_validez:this.state.propiedadSeleccionada.validez,
        cargandoPropiedades:true
      });

      
    }

    const abrirModalPropiedadEliminar = () =>{

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }

      if(!this.state.propiedadSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalPropiedadBorrar: true,
        nuevaPropiedadActivo: false,
        editarPropiedad: false,
        eliminarPropiedad: true,
      });
      
    }
    
    const abrirModalDominioNuevo = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
 
      this.setState({
        modalDominioAbierto: true,
        nuevaDominioActivo: true,
        editarDominio: false,
        eliminarDominio: false,
        dominioSeleccionada:null,
        cargandoDominios:true
      });
      
    }

    const abrirModalDominioAbierto = () => {

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }
   
      if(!this.state.dominioSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalDominioAbierto: true,
        nuevaDominioActivo: false,
        editarDominio: true,
        eliminarDominio: false,
        dominio_nombre:this.state.dominioSeleccionada.nombre,
        dominio_hosting:this.state.dominioSeleccionada.hosting,
        dominio_validez:this.state.dominioSeleccionada.validez,
        cargandoDominios:true
      });

      
    }

    const abrirModalDominioEliminar = () =>{

      if(this.props.editable===false){
          alert(i18next.t("sinpermiso",{ns:'global'}))
          return
      }

      if(!this.state.dominioSeleccionada){
          alert(i18next.t("noseleccionado",{ns:'global'}))
          return
      }

      this.setState({
        modalDominioBorrar: true,
        nuevaDominioActivo: false,
        editarDominio: false,
        eliminarDominio: true,       
      
      });
      
    }
    
    const cerrarModalBorrar=()=>{
      this.setState({
        modalMarcaBorrar:false,
        modalNombreBorrar:false,
        modalPatenteBorrar:false,
        modalPropiedadBorrar:false,
        modalDominioBorrar:false
      })
    }

    const cerrarModalMarca=()=>{
      this.setState({modalMarcaAbierto:false,
      cargandoMarcas:false})
    }

    const cerrarModalNombreComercial=()=>{
      this.setState({modalNombreAbierto:false,
      cargandoNombres:false})
    }

    const cerrarModalPatente=()=>{
      this.setState({modalPatenteAbierto:false,
      cargandoPatentes:false})
    }

    const cerrarModalPropiedad=()=>{
      this.setState({modalPropiedadAbierto:false,
        cargandoPropiedades:false})
    }

    const cerrarModalDominio=()=>{
      this.setState({modalDominioAbierto:false,
        cargandoDominios:false})
    }

    const handleCambiarMarca=(e)=>{
      this.setState({marca:e.currentTarget.value})  
    }

    const handleCambiarNroRegistro=(e)=>{
      this.setState({marca_nro_registro:e.currentTarget.value})  
    }

    const handleCambiarNacInter=(e)=>{
      this.setState({marca_nacional_internacional:e.currentTarget.value})  
    }

    const handleCambiarClasificacionNiza=(e)=>{
      this.setState({marca_clasificacion_niza:e.currentTarget.value})  
    }

    const handleCambiarDescripcionClase=(e)=>{
      this.setState({marca_descripcion_clase:e.currentTarget.value})  
    }

    const handleCambiarValidez=(e)=>{
      this.setState({marca_validez:e.currentTarget.value})  
    }

    const handleCambiarNombreComercial=(e)=>{
      this.setState({nombre:e.currentTarget.value})  
    }

    const handleCambiarNombreNroRegistro=(e)=>{
      this.setState({nombre_nro_registro:e.currentTarget.value})  
    }

    const handleCambiarNombreNacInter=(e)=>{
      this.setState({nombre_nacional_internacional:e.currentTarget.value})  
    }

    const handleCambiarNombreClasificacionNiza=(e)=>{
      this.setState({nombre_clasificacion_niza:e.currentTarget.value})  
    }

    const handleCambiarNombreDescripcionClase=(e)=>{
      this.setState({nombre_descripcion_clase:e.currentTarget.value})  
    }

    const handleCambiarNombreValidez=(e)=>{
      this.setState({nombre_validez:e.currentTarget.value})  
    }

    const handleCambiarPatenteDescripcion=(e)=>{
      this.setState({patente_descripcion:e.currentTarget.value})  
    }

    const handleCambiarPatenteValidez=(e)=>{
      this.setState({patente_validez:e.currentTarget.value})  
    }

    const handleCambiarPropiedadSoftware=(e)=>{
      this.setState({propiedad_software:e.currentTarget.value})  
    }

    const handleCambiarPropiedadOtro=(e)=>{
      this.setState({propiedad_otro:e.currentTarget.value})  
    }

    const handleCambiarPropìedadValidez=(e)=>{
      this.setState({propiedad_validez:e.currentTarget.value})  
    }

    const handleCambiarDominioNombre=(e)=>{
      this.setState({dominio_nombre:e.currentTarget.value})  
    }

    const handleCambiarDominioHosting=(e)=>{
      this.setState({dominio_hosting:e.currentTarget.value})  
    }

    const handleCambiarDominioValidez=(e)=>{
      this.setState({dominio_validez:e.currentTarget.value})  
    }

    const anadirMarca = async() => {

      if(!this.state.marca ||
        !this.state.marca_nro_registro ||
        !this.state.marca_nacional_internacional ||
        !this.state.marca_clasificacion_niza ||
        !this.state.marca_descripcion_clase ||
        !this.state.marca_validez ) return

      //si nos marcan es principal, desmarcamos la antigua.
      //añadimos la marca a la bdd
      await this.props.client.mutate({
          mutation: gql`
              mutation{crearPersonaJuridicaMarca(VALORES:{NOMBRE:"${this.state.marca}",NRO_REGISTRO:"${this.state.marca_nro_registro}",NACIONAL_INTERNACIONAL:"${this.state.marca_nacional_internacional}",CLASIFICACION_NIZA:"${this.state.marca_clasificacion_niza}",DESCRIPCION_CLASE:${this.state.marca_descripcion_clase?'"""'+this.state.marca_descripcion_clase+'"""':null},VALIDEZ:"${this.state.marca_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_MARCA}}
          `
      }).then(async result=>{
         
          //si todo ha ido ok.
          alert(i18next.t("insertcorrecto",{ns:'global'}))
          await this.cargarMarcas()

          //seleccionamos en la tabla la nueva marca creada
          let creada=this.state.marcas.find((marca,index)=>{
              return marca.id===result.data.crearPersonaJuridicaMarca.ID_MARCA

          })
          this.setState({marcaSeleccionada:creada})
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalMarca()
    }

    const anadirNombreComercial = async() => {

      if(!this.state.nombre ||
        !this.state.nombre_nro_registro ||
        !this.state.nombre_nacional_internacional ||
        !this.state.nombre_clasificacion_niza ||
        !this.state.nombre_descripcion_clase ||
        !this.state.nombre_validez )return

     
      let existe=this.state.nombres.find(nombre=>{return nombre.nombre===this.state.nombre})
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }

      //si nos marcan es principal, desmarcamos la antigua.
      //añadimos la marca a la bdd
      await this.props.client.mutate({
          mutation: gql`
              mutation{crearPersonaJuridicaComercial(VALORES:{NOMBRE:"${this.state.nombre}",NRO_REGISTRO:"${this.state.nombre_nro_registro}",NACIONAL_INTERNACIONAL:"${this.state.nombre_nacional_internacional}",CLASIFICACION_NIZA:"${this.state.nombre_clasificacion_niza}",DESCRIPCION_CLASE:${this.state.nombre_descripcion_clase?'"""'+this.state.nombre_descripcion_clase+'"""':null},VALIDEZ:"${this.state.nombre_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_COMERCIAL}}
          `
      }).then(async result=>{
         
          //si todo ha ido ok.
          alert(i18next.t("insertcorrecto",{ns:'global'}))
          await this.cargarNombresComerciales()

          let creada=this.state.nombres.find((nombre,index)=>{
              return nombre.id===result.data.crearPersonaJuridicaComercial.ID_COMERCIAL

          })
          this.setState({nombreSeleccionada:creada})
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalNombreComercial()
    }

    const anadirPatente = async() => {

      if(!this.state.patente_descripcion ||
        !this.state.patente_validez )return

     
      let existe=this.state.patentes.find(patente=>{return patente.descripcion===this.state.patente_descripcion})
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }

      await this.props.client.mutate({
          mutation: gql`
              mutation{crearPersonaJuridicaPatente(VALORES:{DESCRIPCION:${this.state.patente_descripcion?'"""'+this.state.patente_descripcion+'"""':null},VALIDEZ:"${this.state.patente_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PATENTE}}
          `
      }).then(async result=>{
         
          //si todo ha ido ok.
          alert(i18next.t("insertcorrecto",{ns:'global'}))
          await this.cargarPatentes()

          let creada=this.state.patentes.find((patente,index)=>{
              return patente.id===result.data.crearPersonaJuridicaPatente.ID_PATENTE

          })
          this.setState({patenteSeleccionada:creada})
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalPatente()
    }

    const anadirPropiedad = async() => {

      if(!this.state.propiedad_software ||
        !this.state.propiedad_otro ||
        !this.state.propiedad_validez )return

     
      let existe=this.state.propiedades.find(propiedad=>{return (propiedad.software===this.state.propiedad_software && propiedad.otro===this.state.propiedad_otro && propiedad.validez===this.state.propiedad_validez)})
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }

      await this.props.client.mutate({
          mutation: gql`
              mutation{crearPersonaJuridicaPropiedad(VALORES:{SOFTWARE:"${this.state.propiedad_software}",OTRO:"${this.state.propiedad_otro}",VALIDEZ:"${this.state.propiedad_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PROPIEDAD}}
          `
      }).then(async result=>{
         
          //si todo ha ido ok.
          alert(i18next.t("insertcorrecto",{ns:'global'}))
          await this.cargarPropiedades()

          let creada=this.state.propiedades.find((propiedad,index)=>{
              return propiedad.id===result.data.crearPersonaJuridicaPropiedad.ID_PROPIEDAD

          })
          this.setState({propiedadSeleccionada:creada})
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalPropiedad()
    }

    const anadirDominio = async() => {

      if(!this.state.dominio_nombre ||
        !this.state.dominio_hosting || 
        !this.state.dominio_validez ) return

     
      let existe=this.state.dominios.find(dominio=>{return dominio.nombre===this.state.dominio_nombre})
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }

      await this.props.client.mutate({
          mutation: gql`
              mutation{crearPersonaJuridicaDominio(VALORES:{NOMBRE:"${this.state.dominio_nombre}",HOSTING:"${this.state.dominio_hosting}",VALIDEZ:"${this.state.dominio_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_DOMINIO}}
          `
      }).then(async result=>{
         
          //si todo ha ido ok.
          alert(i18next.t("insertcorrecto",{ns:'global'}))
          await this.cargarDominios()

          let creada=this.state.dominios.find((dominio,index)=>{
              return dominio.id===result.data.crearPersonaJuridicaDominio.ID_DOMINIO

          })
          this.setState({dominioSeleccionada:creada})
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalDominio()
    }

    const actualizarMarca = async() => {

      if(!this.state.marca ||
        !this.state.marca_nro_registro ||
        !this.state.marca_nacional_internacional ||
        !this.state.marca_clasificacion_niza ||
        !this.state.marca_descripcion_clase ||
        !this.state.marca_validez )return

      //aCtualizamos la marca
      await this.props.client.mutate({
          mutation: gql`
              mutation{actualizarPersonaJuridicaMarca(ID_MARCA:${this.state.marcaSeleccionada.id},VALORES:{NOMBRE:"${this.state.marca}",NRO_REGISTRO:"${this.state.marca_nro_registro}",NACIONAL_INTERNACIONAL:"${this.state.marca_nacional_internacional}",CLASIFICACION_NIZA:"${this.state.marca_clasificacion_niza}",DESCRIPCION_CLASE:${this.state.marca_descripcion_clase?'"""'+this.state.marca_descripcion_clase+'"""':null},VALIDEZ:"${this.state.marca_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_MARCA}}
          `
      }).then(async result=>{

          alert(i18next.t("updatecorrecto",{ns:'global'}))
          await this.cargarMarcas()
          //reseleccionamos la fila con los nuevos datos.
          let sel=this.state.marcas.find(marca=>{return marca.id===this.state.marcaSeleccionada.id})
          this.setState({marcaSeleccionada:sel})

      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })
      
      cerrarModalMarca()

    }

    const actualizarNombreComercial = async() => {

      if(!this.state.nombre ||
        !this.state.nombre_nro_registro ||
        !this.state.nombre_nacional_internacional ||
        !this.state.nombre_clasificacion_niza ||
        !this.state.nombre_descripcion_clase ||
        !this.state.nombre_validez )return

      let existe=this.state.nombres.find(nombre=>{
          return nombre.nombre===this.state.nombre && nombre.id!==this.state.nombreSeleccionada.id
      })
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }
 
      await this.props.client.mutate({
          mutation: gql`
              mutation{actualizarPersonaJuridicaComercial(ID_COMERCIAL:${this.state.nombreSeleccionada.id},VALORES:{NOMBRE:"${this.state.nombre}",NRO_REGISTRO:"${this.state.nombre_nro_registro}",NACIONAL_INTERNACIONAL:"${this.state.nombre_nacional_internacional}",CLASIFICACION_NIZA:"${this.state.nombre_clasificacion_niza}",DESCRIPCION_CLASE:${this.state.nombre_descripcion_clase?'"""'+this.state.nombre_descripcion_clase+'"""':null},VALIDEZ:"${this.state.nombre_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_COMERCIAL}}
          `
      }).then(async result=>{

          alert(i18next.t("updatecorrecto",{ns:'global'}))
          await this.cargarNombresComerciales()
          //reseleccionamos la fila con los nuevos datos.
          let sel=this.state.nombres.find(nombre=>{return nombre.id===this.state.nombreSeleccionada.id})
          this.setState({nombreSeleccionada:sel})

      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })
      
      cerrarModalNombreComercial()

    }

    const actualizarPatente = async() => {

      if(!this.state.patente_descripcion ||
        !this.state.patente_validez ) return

      let existe=this.state.patentes.find(patente=>{
          return patente.descripcion===this.state.patente_descripcion && patente.id!==this.state.patenteSeleccionada.id
      })
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }
 
      await this.props.client.mutate({
          mutation: gql`
              mutation{actualizarPersonaJuridicaPatente(ID_PATENTE:${this.state.patenteSeleccionada.id},VALORES:{DESCRIPCION:${this.state.patente_descripcion?'"""'+this.state.patente_descripcion+'"""':null},VALIDEZ:"${this.state.patente_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PATENTE}}
          `
      }).then(async result=>{

          alert(i18next.t("updatecorrecto",{ns:'global'}))
          await this.cargarPatentes()
          //reseleccionamos la fila con los nuevos datos.
          let sel=this.state.patentes.find(patente=>{return patente.id===this.state.patenteSeleccionada.id})
          this.setState({patenteSeleccionada:sel})

      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })
      
      cerrarModalPatente()

    }

    const actualizarPropiedad = async() => {

      if(!this.state.propiedad_software ||
        !this.state.propiedad_otro ||
        !this.state.propiedad_validez ) return

      let existe=this.state.propiedades.find(propiedad=>{
          return propiedad.software===this.state.propiedad_software && propiedad.otro===this.state.propiedad_otro && propiedad.validez===this.state.propiedad_validez && propiedad.id!==this.state.propiedadSeleccionada.id
      })
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }
 
      await this.props.client.mutate({
          mutation: gql`
              mutation{actualizarPersonaJuridicaPropiedad(ID_PROPIEDAD:${this.state.propiedadSeleccionada.id},VALORES:{SOFTWARE:"${this.state.propiedad_software}",OTRO:"${this.state.propiedad_otro}",VALIDEZ:"${this.state.propiedad_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PROPIEDAD}}
          `
      }).then(async result=>{

          alert(i18next.t("updatecorrecto",{ns:'global'}))
          await this.cargarPropiedades()
          //reseleccionamos la fila con los nuevos datos.
          let sel=this.state.propiedades.find(propiedad=>{return propiedad.id===this.state.propiedadSeleccionada.id})
          this.setState({propiedadSeleccionada:sel})

      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })
      
      cerrarModalPropiedad()

    }

    const actualizarDominio = async() => {

      if(!this.state.dominio_nombre ||
        !this.state.dominio_hosting ||
        !this.state.dominio_validez ) return

      let existe=this.state.dominios.find(dominio=>{
          return dominio.nombre===this.state.dominio_nombre && dominio.id!==this.state.dominioSeleccionada.id
      })
      if(existe){
          alert(i18next.t("yaexiste",{ns:'global'}))
          return
      }
 
      await this.props.client.mutate({
          mutation: gql`
              mutation{actualizarPersonaJuridicaDominio(ID_DOMINIO:${this.state.dominioSeleccionada.id},VALORES:{NOMBRE:"${this.state.dominio_nombre}",HOSTING:"${this.state.dominio_hosting}",VALIDEZ:"${this.state.dominio_validez}",ID_PERSONA:${this.props.empresaSeleccionada}}){ID_DOMINIO}}
          `
      }).then(async result=>{

          alert(i18next.t("updatecorrecto",{ns:'global'}))
          await this.cargarDominios()
          //reseleccionamos la fila con los nuevos datos.
          let sel=this.state.dominios.find(dominio=>{return dominio.id===this.state.dominioSeleccionada.id})
          this.setState({dominioSeleccionada:sel})

      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })
      
      cerrarModalDominio()

    }

    const eliminarMarca=async()=>{

        await this.props.client.mutate({
            mutation: gql`
                mutation{eliminarPersonaJuridicaMarca(ID_MARCA:${this.state.marcaSeleccionada.id}){ID_MARCA}}
            `
        }).then(result=>{
        
            alert(i18next.t("deletecorrecto",{ns:'global'}))
            this.setState({
                marcaSeleccionada:null
            })
            this.cargarMarcas()
            
        }).catch(err=>{
            alert(i18next.t("error",{ns:'global'}))
        })

        cerrarModalBorrar()

    }
    const eliminarNombre=async()=>{

      await this.props.client.mutate({
          mutation: gql`
              mutation{eliminarPersonaJuridicaComercial(ID_COMERCIAL:${this.state.nombreSeleccionada.id}){ID_COMERCIAL}}
          `
      }).then(result=>{
      
          alert(i18next.t("deletecorrecto",{ns:'global'}))
          this.setState({
              nombreSeleccionada:null
          })
          this.cargarNombresComerciales()
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalBorrar()

    }

    const eliminarPatente=async()=>{

      await this.props.client.mutate({
          mutation: gql`
              mutation{eliminarPersonaJuridicaPatente(ID_PATENTE:${this.state.patenteSeleccionada.id}){ID_PATENTE}}
          `
      }).then(result=>{
      
          alert(i18next.t("deletecorrecto",{ns:'global'}))
          this.setState({
              patenteSeleccionada:null
          })
          this.cargarPatentes()
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalBorrar()

    }

    const eliminarPropiedad=async()=>{

      await this.props.client.mutate({
          mutation: gql`
              mutation{eliminarPersonaJuridicaPropiedad(ID_PROPIEDAD:${this.state.propiedadSeleccionada.id}){ID_PROPIEDAD}}
          `
      }).then(result=>{
      
          alert(i18next.t("deletecorrecto",{ns:'global'}))
          this.setState({
              propiedadSeleccionada:null
          })
          this.cargarPropiedades()
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalBorrar()

    }

    const eliminarDominio=async()=>{

      await this.props.client.mutate({
          mutation: gql`
              mutation{eliminarPersonaJuridicaDominio(ID_DOMINIO:${this.state.dominioSeleccionada.id}){ID_DOMINIO}}
          `
      }).then(result=>{
      
          alert(i18next.t("deletecorrecto",{ns:'global'}))
          this.setState({
              dominioSeleccionada:null
          })
          this.cargarDominios()
          
      }).catch(err=>{
          alert(i18next.t("error",{ns:'global'}))
      })

      cerrarModalBorrar()

    }

    const handleOnSelectMarca=(row, isSelect) => {
      if (isSelect) {
          this.setState({
            marcaSeleccionada: row
          });
      }
      //console.log(row);
      return true; // return true or dont return to approve current select action
    }

    const handleOnSelectNombre=(row, isSelect) => {
      if (isSelect) {
          this.setState({
            nombreSeleccionada: row
          });
      }
      //console.log(row);
      return true; // return true or dont return to approve current select action
    }

    const handleOnSelectPatente=(row, isSelect) => {
      if (isSelect) {
          this.setState({
            patenteSeleccionada: row
          });
      }
      //console.log(row);
      return true; // return true or dont return to approve current select action
    }

    const handleOnSelectPropiedad=(row, isSelect) => {
      if (isSelect) {
          this.setState({
            propiedadSeleccionada: row
          });
      }
      //console.log(row);
      return true; // return true or dont return to approve current select action
    }

    const handleOnSelectDominio=(row, isSelect) => {
      if (isSelect) {
          this.setState({
            dominioSeleccionada: row
          });
      }
      //console.log(row);
      return true; // return true or dont return to approve current select action
    }

    const columnsMarcas = [
      {
          dataField: 'nombre',
          text: i18next.t("empresa.tabmarcasdominios.brand",{ns:'company'}),
          sort: true,
      },
      {
        dataField: 'nro_registro',
        text: i18next.t("empresa.tabmarcasdominios.registrationno",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'nacional_internacional',
        text: i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'clasificacion_niza',
        text: i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'descripcion_clase',
        text: i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'validez',
        text: i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}),
        sort: true,
      }
    ];

    const columnsNombres = [
      {
          dataField: 'nombre',
          text: i18next.t("empresa.tabmarcasdominios.tradename",{ns:'company'}),
          sort: true,
      },
      {
        dataField: 'nro_registro',
        text: i18next.t("empresa.tabmarcasdominios.registrationno",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'nacional_internacional',
        text: i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'clasificacion_niza',
        text: i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'descripcion_clase',
        text: i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'validez',
        text: i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}),
        sort: true,
      }
    ];

    const columnsPatentes = [
      {
          dataField: 'descripcion',
          text: i18next.t("empresa.tabmarcasdominios.description",{ns:'company'}),
          sort: true,
      },
      {
        dataField: 'validez',
        text: i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}),
        sort: true,
      }
    ];

    const columnsPropiedades = [
      {
          dataField: 'software',
          text: i18next.t("empresa.tabmarcasdominios.software",{ns:'company'}),
          sort: true,
      },
      {
        dataField: 'otro',
        text: i18next.t("empresa.tabmarcasdominios.other",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'validez',
        text: i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}),
        sort: true,
      }
    ];

    const columnsDominios = [
      {
          dataField: 'nombre',
          text: i18next.t("empresa.tabmarcasdominios.domain",{ns:'company'}),
          sort: true,
      },
      {
        dataField: 'hosting',
        text: i18next.t("empresa.tabmarcasdominios.hosting",{ns:'company'}),
        sort: true,
      },
      {
        dataField: 'validez',
        text: i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'}),
        sort: true,
      }
    ];

    return (
      <>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="warning-sign"
            onClose={cerrarModalBorrar}
            title={i18next.t("empresa.tabmarcasdominios.gridmarca.titledel",{ns:'company'})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalMarcaBorrar}
        >
            <div className={Classes.DIALOG_BODY}>
                <h2>{i18next.t("empresa.tabmarcasdominios.gridmarca.questiondel",{ns:'company'})}</h2>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                      <AnchorButton
                          intent={Intent.PRIMARY}
                          onClick={eliminarMarca}
                      >
                        {i18next.t("yes",{ns:'global'})}
                      </AnchorButton>
                  </Tooltip>
                  <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                      <Button onClick={cerrarModalBorrar}>{i18next.t("yes",{ns:'global'})}</Button>
                  </Tooltip>
              </div>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="warning-sign"
            onClose={cerrarModalBorrar}
            title={i18next.t("empresa.tabmarcasdominios.gridnombrescomerciales.titledel",{ns:'company'})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNombreBorrar}
        >
            <div className={Classes.DIALOG_BODY}>
                <h2>{i18next.t("empresa.tabmarcasdominios.gridnombrescomerciales.questiondel",{ns:'company'})}</h2>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                      <AnchorButton
                          intent={Intent.PRIMARY}
                          onClick={eliminarNombre}
                      >
                          {i18next.t("yes",{ns:'global'})}
                      </AnchorButton>
                  </Tooltip>
                  <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                      <Button onClick={cerrarModalBorrar}>{i18next.t("no",{ns:'global'})}</Button>
                  </Tooltip>
              </div>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="warning-sign"
            onClose={cerrarModalBorrar}
            title={i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.titledel",{ns:'company'})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalPatenteBorrar}
        >
            <div className={Classes.DIALOG_BODY}>
                <h2>{i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.questiondel",{ns:'company'})}</h2>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                      <AnchorButton
                          intent={Intent.PRIMARY}
                          onClick={eliminarPatente}
                      >
                          {i18next.t("yes",{ns:'global'})}
                      </AnchorButton>
                  </Tooltip>
                  <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                      <Button onClick={cerrarModalBorrar}>{i18next.t("no",{ns:'global'})}</Button>
                  </Tooltip>
              </div>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="warning-sign"
            onClose={cerrarModalBorrar}
            title={i18next.t("empresa.tabmarcasdominios.gridpropiedadintelectual.titledel",{ns:'company'})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalPropiedadBorrar}
        >
            <div className={Classes.DIALOG_BODY}>
                <h2>{i18next.t("empresa.tabmarcasdominios.gridpropiedadintelectual.questiondel",{ns:'company'})}</h2>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                      <AnchorButton
                          intent={Intent.PRIMARY}
                          onClick={eliminarPropiedad}
                      >
                          {i18next.t("yes",{ns:'global'})}
                      </AnchorButton>
                  </Tooltip>
                  <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                      <Button onClick={cerrarModalBorrar}>{i18next.t("no",{ns:'global'})}</Button>
                  </Tooltip>
              </div>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="warning-sign"
            onClose={cerrarModalBorrar}
            title={i18next.t("empresa.tabmarcasdominios.griddominiosinternet.titledel",{ns:'company'})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalDominioBorrar}
        >
            <div className={Classes.DIALOG_BODY}>
                <h2>{i18next.t("empresa.tabmarcasdominios.griddominiosinternet.questiondel",{ns:'company'})}</h2>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Tooltip content={i18next.t("eliminar",{ns:'global'})} position="top">
                      <AnchorButton
                          intent={Intent.PRIMARY}
                          onClick={eliminarDominio}
                      >
                          {i18next.t("yes",{ns:'global'})}
                      </AnchorButton>
                  </Tooltip>
                  <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                      <Button onClick={cerrarModalBorrar}>{i18next.t("no",{ns:'global'})}</Button>
                  </Tooltip>
              </div>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModalMarca}
          title={this.state.nuevaMarcaActivo?i18next.t("empresa.tabmarcasdominios.gridmarca.titleadd",{ns:'company'}):i18next.t("empresa.tabmarcasdominios.gridmarca.titleedit",{ns:'company'})}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          usePortal={true}
          isOpen={this.state.modalMarcaAbierto}
        >
          <div className="pixelAlrededor">
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.gridmarca.namebrand",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.marca?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridmarca.namebrand",{ns:'company'})}
            >
                <InputGroup
                    name="Nombre"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridmarca.namebrand",{ns:'company'})}
                    onChange={handleCambiarMarca}
                    intent={this.state.marca?"primary":"danger"}
                    defaultValue={this.state.marcaSeleccionada?this.state.marcaSeleccionada.nombre:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.gridmarca.numberregister",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.marca_nro_registro?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridmarca.numberregister",{ns:'company'})}
            >
                <InputGroup
                    name="Nro_registro"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridmarca.numberregister",{ns:'company'})}
                    onChange={handleCambiarNroRegistro}
                    intent={this.state.marca_nro_registro?"primary":"danger"}
                    defaultValue={this.state.marcaSeleccionada?this.state.marcaSeleccionada.nro_registro:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.marca_nacional_internacional?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'})}
            >
                <InputGroup
                    name="Nacional_internacional"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'})}
                    onChange={handleCambiarNacInter}
                    intent={this.state.marca_nacional_internacional?"primary":"danger"}
                    defaultValue={this.state.marcaSeleccionada?this.state.marcaSeleccionada.nacional_internacional:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.marca_clasificacion_niza?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'})}
            >
                <InputGroup
                    name="Clasificacion_niza"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'})}
                    onChange={handleCambiarClasificacionNiza}
                    intent={this.state.marca_clasificacion_niza?"primary":"danger"}
                    defaultValue={this.state.marcaSeleccionada?this.state.marcaSeleccionada.clasificacion_niza:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.marca_validez?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
            >
                <InputGroup
                    name="Validez"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                    onChange={handleCambiarValidez}
                    intent={this.state.marca_validez?"primary":"danger"}
                    defaultValue={this.state.marcaSeleccionada?this.state.marcaSeleccionada.validez:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.marca_descripcion_clase?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'})}
            >
                <TextArea 
                  fill={true}
                  placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'})}
                  onChange={handleCambiarDescripcionClase}
                  intent={this.state.marca_descripcion_clase?"primary":"danger"} 
                  value={this.state.marca_descripcion_clase} />
                
            </FormGroup>
            
          </div>
          <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                  <Button onClick={cerrarModalMarca}>{i18next.t("cancelar",{ns:'global'})}</Button>
              </Tooltip>
              <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                  <AnchorButton
                      intent={Intent.PRIMARY}
                      onClick={this.state.nuevaMarcaActivo?anadirMarca:actualizarMarca}
                  >
                  {i18next.t("aceptar",{ns:'global'})}
                  </AnchorButton>
              </Tooltip>
          </div>
        </div>
        </Dialog>

        <Dialog transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModalNombreComercial}
          title={this.state.nuevaNombreActivo?i18next.t("empresa.tabmarcasdominios.gridnombrescomerciales.titleadd",{ns:'company'}):i18next.t("empresa.tabmarcasdominios.gridnombrescomerciales.titleedit",{ns:'company'})}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          usePortal={true}
          isOpen={this.state.modalNombreAbierto}
        >
          <div className="pixelAlrededor">
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.tradename",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nombre?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.tradename",{ns:'company'})}
            >
                <InputGroup
                    name="Nombre_comercial"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.tradename",{ns:'company'})}
                    onChange={handleCambiarNombreComercial}
                    intent={this.state.nombre?"primary":"danger"}
                    defaultValue={this.state.nombreSeleccionada?this.state.nombreSeleccionada.nombre:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.gridmarca.numberregister",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nombre_nro_registro?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridmarca.numberregister",{ns:'company'})}
            >
                <InputGroup
                    name="Nro_registro_comercial"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridmarca.numberregister",{ns:'company'})}
                    onChange={handleCambiarNombreNroRegistro}
                    intent={this.state.nombre_nro_registro?"primary":"danger"}
                    defaultValue={this.state.nombreSeleccionada?this.state.nombreSeleccionada.nro_registro:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nombre_nacional_internacional?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'})}
            >
                <InputGroup
                    name="Nacional_internacional_comercial"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nationalinternational",{ns:'company'})}
                    onChange={handleCambiarNombreNacInter}
                    intent={this.state.nombre_nacional_internacional?"primary":"danger"}
                    defaultValue={this.state.nombreSeleccionada?this.state.nombreSeleccionada.nacional_internacional:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nombre_clasificacion_niza?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'})}
            >
                <InputGroup
                    name="Clasificacion_niza_comercial"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.nizaclassification",{ns:'company'})}
                    onChange={handleCambiarNombreClasificacionNiza}
                    intent={this.state.nombre_clasificacion_niza?"primary":"danger"}
                    defaultValue={this.state.nombreSeleccionada?this.state.nombreSeleccionada.clasificacion_niza:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nombre_validez?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
            >
                <InputGroup
                    name="Validez_comercial"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                    onChange={handleCambiarNombreValidez}
                    intent={this.state.nombre_validez?"primary":"danger"}
                    defaultValue={this.state.nombreSeleccionada?this.state.nombreSeleccionada.validez:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.nombre_descripcion_clase?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'})}
            >
                <TextArea 
                  fill={true}
                  placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.descriptionclass",{ns:'company'})} 
                  onChange={handleCambiarNombreDescripcionClase}
                  intent={this.state.nombre_descripcion_clase?"primary":"danger"} 
                  value={this.state.nombre_descripcion_clase} />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                  <Button onClick={cerrarModalNombreComercial}>{i18next.t("cancelar",{ns:'global'})}</Button>
              </Tooltip>
              <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                  <AnchorButton
                      intent={Intent.PRIMARY}
                      onClick={this.state.nuevaNombreActivo?anadirNombreComercial:actualizarNombreComercial}
                  >
                  {i18next.t("aceptar",{ns:'global'})}
                  </AnchorButton>
              </Tooltip>
          </div>
        </div>
        </Dialog>

        <Dialog transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModalPatente}
          title={this.state.nuevaPatenteActivo?i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.titleadd",{ns:'company'}):i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.titleedit",{ns:'company'})}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          usePortal={true}
          isOpen={this.state.modalPatenteAbierto}
        >
          <div className="pixelAlrededor">
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.patente_validez?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
            >
                <InputGroup
                    name="Patente_validez"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                    onChange={handleCambiarPatenteValidez}
                    intent={this.state.patente_validez?"primary":"danger"}
                    defaultValue={this.state.patenteSeleccionada?this.state.patenteSeleccionada.validez:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.descriptionpatent",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.patente_descripcion?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.descriptionpatent",{ns:'company'})}
            >
                <TextArea 
                  fill={true}
                  placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.gridpatentesinvenciones.descriptionpatent",{ns:'company'})}
                  onChange={handleCambiarPatenteDescripcion}
                  intent={this.state.patente_descripcion?"primary":"danger"} 
                  value={this.state.patente_descripcion} />
            </FormGroup>
            
          </div>
          <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                  <Button onClick={cerrarModalPatente}>{i18next.t("cancelar",{ns:'global'})}</Button>
              </Tooltip>
              <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                  <AnchorButton
                      intent={Intent.PRIMARY}
                      onClick={this.state.nuevaPatenteActivo?anadirPatente:actualizarPatente}
                  >
                  {i18next.t("aceptar",{ns:'global'})}
                  </AnchorButton>
              </Tooltip>
          </div>
        </div>
        </Dialog>
        <Dialog transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModalPropiedad}
          title={this.state.nuevaPropiedadActivo?i18next.t("empresa.tabmarcasdominios.gridpropiedadintelectual.titleadd",{ns:'company'}):i18next.t("empresa.tabmarcasdominios.gridpropiedadintelectual.titleedit",{ns:'company'})}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          usePortal={true}
          isOpen={this.state.modalPropiedadAbierto}
        >
          <div className="pixelAlrededor">
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.software",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.propiedad_software?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.software",{ns:'company'})}
            >
                <InputGroup
                    name="Software"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.software",{ns:'company'})}
                    onChange={handleCambiarPropiedadSoftware}
                    intent={this.state.propiedad_software?"primary":"danger"}
                    defaultValue={this.state.propiedadSeleccionada?this.state.propiedadSeleccionada.software:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.other",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.propiedad_otro?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.other",{ns:'company'})}
            >
                <InputGroup
                    name="Otro"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.other",{ns:'company'})}
                    onChange={handleCambiarPropiedadOtro}
                    intent={this.state.propiedad_otro?"primary":"danger"}
                    defaultValue={this.state.propiedadSeleccionada?this.state.propiedadSeleccionada.otro:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.propiedad_validez?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
            >
                <InputGroup
                    name="Propiedad_validez"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                    onChange={handleCambiarPropìedadValidez}
                    intent={this.state.propiedad_validez?"primary":"danger"}
                    defaultValue={this.state.propiedadSeleccionada?this.state.propiedadSeleccionada.validez:""}
                />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                  <Button onClick={cerrarModalPropiedad}>{i18next.t("cancelar",{ns:'global'})}</Button>
              </Tooltip>
              <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                  <AnchorButton
                      intent={Intent.PRIMARY}
                      onClick={this.state.nuevaPropiedadActivo?anadirPropiedad:actualizarPropiedad}
                  >
                  {i18next.t("aceptar",{ns:'global'})}
                  </AnchorButton>
              </Tooltip>
          </div>
        </div>
        </Dialog>
        <Dialog transitionDuration={400}
          autoFocus={true}
          enforceFocus={true}
          icon="info-sign"
          onClose={cerrarModalDominio}
          title={this.state.nuevaDominioActivo?i18next.t("empresa.tabmarcasdominios.griddominiosinternet.titleadd",{ns:'company'}):i18next.t("empresa.tabmarcasdominios.griddominiosinternet.titleedit",{ns:'company'})}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          usePortal={true}
          isOpen={this.state.modalDominioAbierto}
        >
          <div className="pixelAlrededor">
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.domain",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.dominio_nombre?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.domain",{ns:'company'})}
            >
                <InputGroup
                    name="Dominio"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.domain",{ns:'company'})}
                    onChange={handleCambiarDominioNombre}
                    intent={this.state.dominio_nombre?"primary":"danger"}
                    defaultValue={this.state.dominioSeleccionada?this.state.dominioSeleccionada.nombre:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.hosting",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.dominio_hosting?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.hosting",{ns:'company'})}
            >
                <InputGroup
                    name="Otro"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.hosting",{ns:'company'})}
                    onChange={handleCambiarDominioHosting}
                    intent={this.state.dominio_hosting?"primary":"danger"}
                    defaultValue={this.state.dominioSeleccionada?this.state.dominioSeleccionada.hosting:""}
                />
            </FormGroup>
            <FormGroup
                label={i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                labelFor="informacion"
                intent="danger"
                helperText={this.state.dominio_validez?"":i18next.t("emptyfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
            >
                <InputGroup
                    name="Propiedad_validez"
                    placeholder={i18next.t("enterfield",{ns:'global'})+i18next.t("empresa.tabmarcasdominios.validity",{ns:'company'})}
                    onChange={handleCambiarDominioValidez}
                    intent={this.state.dominio_validez?"primary":"danger"}
                    defaultValue={this.state.dominioSeleccionada?this.state.dominioSeleccionada.validez:""}
                />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Tooltip content={i18next.t("cerraryvolver",{ns:'global'})} position="top">
                  <Button onClick={cerrarModalDominio}>{i18next.t("cancelar",{ns:'global'})}</Button>
              </Tooltip>
              <Tooltip content={i18next.t("aceptar",{ns:'global'})} position="top">
                  <AnchorButton
                      intent={Intent.PRIMARY}
                      onClick={this.state.nuevaDominioActivo?anadirDominio:actualizarDominio}
                  >
                  {i18next.t("aceptar",{ns:'global'})}
                  </AnchorButton>
              </Tooltip>
          </div>
        </div>
        </Dialog>
        <div className="separacionCentralTarjetas">
          <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande celdasInfoGeneral">
            <Navbar>
              <Navbar.Group>
                <NavbarHeading>{i18next.t("empresa.tabmarcasdominios.marcas",{ns:'company'})}</NavbarHeading>
                <ButtonGroup align={Alignment.LEFT}>
                  <React.Fragment key="fragment">
                    <Button className="bp3-minimal" icon="add"  active={this.state.nuevaMarcaActivo} onClick={abrirModalMarcaNuevo} />
                    <Button className="bp3-minimal" icon="edit" active={this.state.editarMarca} onClick={abrirModalMarcaAbierto}  />
                    <Button className="bp3-minimal" icon="delete" active={this.state.eliminarMarca} onClick={abrirModalMarcaEliminar}  disabled={this.state.marcaSeleccionada?false:true} />
                  </React.Fragment>
                </ButtonGroup>
              </Navbar.Group>
            </Navbar>
            {this.state.cargandoMarcas?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.marcas }
                columns={ columnsMarcas }
                selectRow={ const_table.selectRow(handleOnSelectMarca) }
                pagination={ paginationFactory(const_table.options) }
                defaultSorted={ const_table.defaultSorted('nombre','asc') }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
            />
            }
            <br />
            <Navbar>
              <Navbar.Group>
                <NavbarHeading>{i18next.t("empresa.tabmarcasdominios.nombrescomerciales",{ns:'company'})}</NavbarHeading>
                <ButtonGroup align={Alignment.LEFT}>
                  <React.Fragment key="fragment">
                    <Button className="bp3-minimal" icon="add"  active={this.state.nuevaNombreActivo} onClick={abrirModalNombreNuevo} />
                    <Button className="bp3-minimal" icon="edit" active={this.state.editarNombre} onClick={abrirModalNombreAbierto}  />
                    <Button className="bp3-minimal" icon="delete" active={this.state.eliminarNombre} onClick={abrirModalNombreEliminar}  disabled={this.state.nombreSeleccionada?false:true} />
                  </React.Fragment>
                </ButtonGroup>
              </Navbar.Group>
            </Navbar>
            {this.state.cargandoNombres?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.nombres }
                columns={ columnsNombres }
                selectRow={ const_table.selectRow(handleOnSelectNombre) }
                pagination={ paginationFactory(const_table.options) }
                defaultSorted={ const_table.defaultSorted('nombre','asc') }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
            />
            }
            <br />
            <Navbar>
              <Navbar.Group>
                <NavbarHeading>{i18next.t("empresa.tabmarcasdominios.patentesinvenciones",{ns:'company'})}</NavbarHeading>
                <ButtonGroup align={Alignment.LEFT}>
                  <React.Fragment key="fragment">
                    <Button className="bp3-minimal" icon="add"  active={this.state.nuevaPatenteActivo} onClick={abrirModalPatenteNuevo} />
                    <Button className="bp3-minimal" icon="edit" active={this.state.editarPatente} onClick={abrirModalPatenteAbierto}  />
                    <Button className="bp3-minimal" icon="delete" active={this.state.eliminarPatente} onClick={abrirModalPatenteEliminar}  disabled={this.state.patenteSeleccionada?false:true} />
                  </React.Fragment>
                </ButtonGroup>
              </Navbar.Group>
            </Navbar>
            {this.state.cargandoPatentes?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.patentes }
                columns={ columnsPatentes }
                selectRow={ const_table.selectRow(handleOnSelectPatente) }
                pagination={ paginationFactory(const_table.options) }
                defaultSorted={ const_table.defaultSorted('descripcion','asc') }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
            />
            }
            <br />
            <Navbar>
              <Navbar.Group>
                <NavbarHeading>{i18next.t("empresa.tabmarcasdominios.propiedadintelectual",{ns:'company'})}</NavbarHeading>
                <ButtonGroup align={Alignment.LEFT}>
                  <React.Fragment key="fragment">
                    <Button className="bp3-minimal" icon="add"  active={this.state.nuevaPropiedadActivo} onClick={abrirModalPropiedadNuevo} />
                    <Button className="bp3-minimal" icon="edit" active={this.state.editarPropiedad} onClick={abrirModalPropiedadAbierto}  />
                    <Button className="bp3-minimal" icon="delete" active={this.state.eliminarPropiedad} onClick={abrirModalPropiedadEliminar}  disabled={this.state.propiedadSeleccionada?false:true} />
                  </React.Fragment>
                </ButtonGroup>
              </Navbar.Group>
            </Navbar>
            {this.state.cargandoPropiedades?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.propiedades }
                columns={ columnsPropiedades }
                selectRow={ const_table.selectRow(handleOnSelectPropiedad) }
                pagination={ paginationFactory(const_table.options) }
                defaultSorted={ const_table.defaultSorted('software','asc') }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
            />
            }
            <br />
            <Navbar>
              <Navbar.Group>
                <NavbarHeading>{i18next.t("empresa.tabmarcasdominios.dominiosinternet",{ns:'company'})}</NavbarHeading>
                <ButtonGroup align={Alignment.LEFT}>
                  <React.Fragment key="fragment">
                    <Button className="bp3-minimal" icon="add"  active={this.state.nuevaDominioActivo} onClick={abrirModalDominioNuevo} />
                    <Button className="bp3-minimal" icon="edit" active={this.state.editarDominio} onClick={abrirModalDominioAbierto}  />
                    <Button className="bp3-minimal" icon="delete" active={this.state.eliminarDominio} onClick={abrirModalDominioEliminar}  disabled={this.state.dominioSeleccionada?false:true} />
                  </React.Fragment>
                </ButtonGroup>
              </Navbar.Group>
            </Navbar>
            {this.state.cargandoDominios?
                <Cargando />
            :
            <BootstrapTable
                keyField='id'
                data={ this.state.dominios }
                columns={ columnsDominios }
                selectRow={ const_table.selectRow(handleOnSelectDominio) }
                pagination={ paginationFactory(const_table.options) }
                defaultSorted={ const_table.defaultSorted('nombre','asc') }
                headerClasses="header-class"
                rowClasses="row-class"
                hover
                condensed
                noDataIndication={ i18next.t("datavacio",{ns:'global'}) }
            />
            }
          </Card>  
        </div>
      </>
    )
  }
}

export default withTranslation()(withApollo(MarcasDominios))
