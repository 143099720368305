import React from "react"
import {Spinner, Dialog, Card, Button, FormGroup, Elevation } from "@blueprintjs/core"
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import moment from 'moment';
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import { Cargando } from "./Cargando";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';




import styles from './stylesPrint';


class Certificado extends React.Component {

    state={
        logotipo:null,
        cargandoImprimir:false,
        modalImprimir:false,
        cargando:false
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    componentDidMount=async()=>{
        this.setState({cargando:true}, async () =>{
            await this.obtenerLogoEmpresa()
            this.setState({cargando:false})
        })
    }
    generarCertificado=async() =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async()=>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirCertificado(), document.getElementById('generarC-pdf'))
            })
        })
    }

    ImprimirCertificado=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("certificadoLexcontrol.lexControlCertificate", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleCompliance}>
                            <Text style={styles.labelTitleCompliance}>{i18next.t("certificadoLexcontrol.implementationCertificate", { ns: "page2" }).toUpperCase()}</Text>
                </View>                   
                            <View >
                                <View style={styles.rowTitleCompliance}>
                                    <Text style={styles.textCompliance}>
                                    {`${i18next.t("certificadoLexcontrol.namePlaceholder", { ns: "page2" })} _______________________________________________________________________ ${i18next.t("certificadoLexcontrol.dniNumberPlaceholder", { ns: "page2" })} ________________________________________,
                                        ${i18next.t("certificadoLexcontrol.positionPlaceholder", { ns: "page2" })} _________________________________________________________________ ${i18next.t("certificadoLexcontrol.entityNamePlaceholder", { ns: "page2" })} ________________________
                                        _____________ ${i18next.t("certificadoLexcontrol.addressPlaceholder", { ns: "page2" })} _______________________________________________ ${i18next.t("certificadoLexcontrol.cityPlaceholder", { ns: "page2" })} ___________
                                        ______________________________________ ${i18next.t("certificadoLexcontrol.cifPlaceholder", { ns: "page2" })} _________________________________`}
                                    </Text>

                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("certificadoLexcontrol.certifyText", { ns: "page2" }).toUpperCase()}</Text>
                                </View>
                                <View style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}>{`I.- ${i18next.t("certificadoLexcontrol.implantationText", { ns: "page2" })}`}</Text>
                                </View>
                                <View style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}>{` II.- ${i18next.t("certificadoLexcontrol.complianceOfficerText", { ns: "page2" })}`}</Text>
                                </View>
                                <View style={styles.rowCompliance}>
                                    <Text style={styles.textCompliance}> {i18next.t("certificadoLexcontrol.issueDateText", { ns: "page2" })}</Text>
                                </View>
                                <View style={styles.rowFooterCompliance}>
                                    <Text style={styles.textFooterCompliance}> {`${i18next.t("certificadoLexcontrol.in", { ns: "page2" })} ________________${i18next.t("certificadoLexcontrol.placeHolderDay", { ns: "page2" })} _________${i18next.t("certificadoLexcontrol.datePlaceholder", { ns: "page2" })} _________________________ ${i18next.t("certificadoLexcontrol.datePlaceholder", { ns: "page2" })} _________.`}</Text>
                                </View>

                            </View>
                      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )


    Certificado=() => (
    <div className="html-pdf" ref={(input) => { this.PdfCertificado = input; }} >
        <div className="body-pdf">
            <br />
            <img
                className="App-logo-pdf"
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                alt="logotipo"
            />
            <br />
                <h4>{`${i18next.t("certificadoLexcontrol.implementationCertificate", { ns: "page2" }).toUpperCase()}__________________________________________________________ `}</h4>
                <p>{`${i18next.t("certificadoLexcontrol.namePlaceholder", { ns: "page2" })} _______________________________________________________________________ ${i18next.t("certificadoLexcontrol.dniNumberPlaceholder", { ns: "page2" })} _________________, ${i18next.t("certificadoLexcontrol.positionPlaceholder", { ns: "page2" })} ____________________________________________ ${i18next.t("certificadoLexcontrol.entityNamePlaceholder", { ns: "page2" })} _____________________________________ ${i18next.t("certificadoLexcontrol.addressPlaceholder", { ns: "page2" })} __________________________________________________________ ${i18next.t("certificadoLexcontrol.cityPlaceholder", { ns: "page2" })} ______________________ ${i18next.t("certificadoLexcontrol.cifPlaceholder", { ns: "page2" })} ________________________ `}</p>
                <p><b>{i18next.t("certificadoLexcontrol.certifyText", { ns: "page2" }).toUpperCase()}</b></p>
                <p>{`I.- ${i18next.t("certificadoLexcontrol.implantationText", { ns: "page2" })}`}</p>
                <p>{`II.- ${i18next.t("certificadoLexcontrol.complianceOfficerText", { ns: "page2" })} `}</p>
                <p> {i18next.t("certificadoLexcontrol.issueDate", { ns: "page2" })}</p>
                <br />
                <b><p>{`${i18next.t("certificadoLexcontrol.issueAndsign", { ns: "page2" })} ________________${i18next.t("certificadoLexcontrol.in", { ns: "page2" })} _________ ${i18next.t("certificadoLexcontrol.placeHolderDay", { ns: "page2" })} _________________________ ${i18next.t("certificadoLexcontrol.datePlaceholder", { ns: "page2" })} _________.`}</p></b>
        </div>
        <p>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</p>
    </div>
    );


    render(){

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("certificadoLexcontrol.lexControlCertificate", { ns: "page2" })}`
        const cerrarModalImprimir=() =>{
            this.setState({
                modalImprimir:false
            })
        }
        return (
            <>

            {this.state.cargando===true?
                <Cargando/>
            :
            <>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("certificadoLexcontrol.printCertificate", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '500px'}} id='generarC-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
               
                <Card interactive={true} elevation={Elevation.TWO} id="certificado-lc">
                    <h5><a href="#certificado-lc">{i18next.t("certificadoLexcontrol.printCertificate", { ns: "page2" })}</a></h5>
                    <FormGroup
                        label={i18next.t("certificadoLexcontrol.viewAttachmentMessage", { ns: "page2" })}
                        labelFor="certificado"
                        inline={true}
                    >
                        <Button className="bp3-minimal" icon="print" onClick={()=>this.generarCertificado()}>{i18next.t("print", { ns: "global" })}</Button>

                    </FormGroup>
                </Card>
            </>
        }
        </>
        )
    }
}export default withTranslation()(withApollo(Certificado))