import React from "react"
import {FormGroup,Dialog,TextArea, Classes, NavbarDivider,Tooltip, AnchorButton, Intent, Navbar, ButtonGroup, Button, InputGroup, Alignment,FileInput,Card, Elevation} from "@blueprintjs/core"
import { Table, Column, Cell, RegionCardinality,Regions,SelectionModes} from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import axios from 'axios';
import { withApollo } from "react-apollo";

class ArchivosAdjuntosImpugnaciones extends React.Component {

    state = {
        modalAbierto: false,
        modalBorrarAbierto: false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        nombre:null,
        nuevoActivado:true,
        editarActivado:false,
        borrarActivado:false,
        descripcion:null,
        adjuntoSeleccionado:null,
        tipoSeleccionado:null,
        archivo:null,
        controlesDesactivados:false,
        adjuntos: [
        ],
        tipoadjuntos:[
            {"label": "Impugnación de acuerdo","value":13},
            {"label": "Anulación de acuerdo impugnado","value":14},
            {"label": "Otros","value":3}
        ]
    }

    recargarAdjuntos=async()=>{

        let copyAdjuntos=[]

        //obtener los tipos de documento 'otros'
        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_ASOCIADO:${this.props.acuerdo.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:8,ID_TIPO_ADJUNTO:3}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            }
        })

        //obtener los tipos de documento 'impugnacion de acuerdo'
        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_ASOCIADO:${this.props.acuerdo.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:8,ID_TIPO_ADJUNTO:13}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            }
        })

        //obtener los tipos de documento 'Anulacion de acuerdo impugnado'
        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_ASOCIADO:${this.props.acuerdo.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:8,ID_TIPO_ADJUNTO:14}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            }
        })

        this.setState({adjuntos:copyAdjuntos})

    }

    obtenterTipo(nombreExtension){

        let extension=""
        switch(nombreExtension){
            case ".pdf":
                extension="Documento PDF"
            break;
            case ".doc":
            case ".docx":
                extension="Documento de Microsoft Word"
            break;
            case ".xls":
            case ".xlsx":
                extension="Documento de Microsoft Excel"
            break;
            case ".txt":
                extension="Documento de Texto"
            break;
            case ".png":
            case ".jpg":
            case ".jpeg":
            case ".svg":
                extension="Imagen"
            break;
            default:
                extension="Otros"
            break;
        }
        return extension

    }


    componentDidMount(props){  

        this.recargarAdjuntos();

    }

    guardarCambios=()=>{

        //si no tiene permisos para editar la pantalla
        if(this.props.editable===false){
            alert("No tienes permisos para realizar esta acción")
            return
        }

        const api = axios.create({
            withCredentials: true
        });

        if(this.state.pendienteGuardar){
          
            var formData = new FormData();
            formData.append("files",this.state.archivo);
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:${this.state.tipoSeleccionado.value},NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:8,ID_ASOCIADO:${this.props.acuerdo.ID_ACUERDO},DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                alert("Adjunto creado")
                
                await this.recargarAdjuntos()
                //seleccionamos el creado
                let creado=this.state.adjuntos.find((adjunto,index)=>{
                    if(adjunto.id===response.data.data.crearAdjunto.ID_ADJUNTO){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return adjunto.id===response.data.data.crearAdjunto.ID_ADJUNTO
                })

                this.setState({
                    controlesDesactivados:false,
                    archivo:null,
                    pendienteGuardar:false,
                    adjuntoSeleccionado:creado
                })
            }).catch(err=>{
                alert("Ha ocurrido un error al crear el adjunto. Contacte con el soporte técnico.")
            })

        }else if(this.state.pendienteActualizar){

            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre?this.state.nombre:this.state.adjuntoSeleccionado.nombre}",ID_TIPO_ADJUNTO:${this.state.tipoSeleccionado?this.state.tipoSeleccionado.value:this.state.adjuntoSeleccionado.idTipo},DESCRIPCION:"${this.state.descripcion?this.state.descripcion:this.state.adjuntoSeleccionado.descripcion}"}){ID_ADJUNTO}}`).then(async (response)=> {
                // handle success
                alert("actualizado correctamente")
                await this.recargarAdjuntos()
                //seleccionamos el creado
                let creado=this.state.adjuntos.find((adjunto,index)=>{
                    if(adjunto.id===this.state.adjuntoSeleccionado.id){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return adjunto.id===this.state.adjuntoSeleccionado.id
                })

                this.setState({
                    controlesDesactivados:false,
                    pendienteActualizar:false,
                    adjuntoSeleccionado:creado
                })
            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar el adjunto. Contacte con el soporte técnico.")
            })

        }else if(this.state.pendienteBorrar){

            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then((response)=>{
                // handle success  
                alert("eliminado correctamente")
                this.recargarAdjuntos();
                this.setState({
                    controlesDesactivados:false,
                    pendienteBorrar:false,
                    adjuntoSeleccionado:null
                })
            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar el adjunto. Contacte con el soporte técnico.")
            })

        }

    }


    render() {
        
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                tipoSeleccionado:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                regionSeleccionada:null
            },()=>{this.tablaAdjuntos.clearSelection()})

        }
        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }
            
            this.setState({nombre:this.state.adjuntoSeleccionado.nombre})
            this.setState({descripcion:this.state.adjuntoSeleccionado.descripcion})

            let tipoSel=this.state.tipoadjuntos.find(tipo=>{
               return tipo.value===this.state.adjuntoSeleccionado.idTipo
            })
            this.setState({tipoSeleccionado:tipoSel})

            this.setState({modalAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:true})
            this.setState({borrarActivado:false})
        }
        const abrirModalBorrar = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }
            
            this.setState({modalBorrarAbierto: true})
            this.setState({modalAbierto: false})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        const anadirNuevo = () => {

            if(!this.state.archivo)return
            if(!this.state.tipoSeleccionado)return
            if(!this.state.nombre)return
            if(!this.state.descripcion)return

            let extension=this.obtenterTipo("."+this.state.archivo.name.split(".").pop())

            this.state.adjuntos.push({
                    "nombre": this.state.nombre?this.state.nombre:this.state.nombre,
                    "tipo": this.state.tipoSeleccionado.label,
                    "idTipo":this.state.tipoSeleccionado.value,
                    "mime": extension
    
            })
            this.setState(this.state)
            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()
        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            this.setState({
                nombre:null,
                archivo:null,
                tipoSeleccionado:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                pendienteActualizar:false,
                pendienteGuardar:false,
                pendienteBorrar:false,
                regionSeleccionada:null,
                controlesDesactivados:null
            },()=>{this.tablaAdjuntos.clearSelection()})

            this.recargarAdjuntos(this.state.empresaSeleccionada)

        }

        const seleccionTipo  = (value) => { 
            this.setState({tipoSeleccionado: value})
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const cellRendererNombre = (rowIndex) => {
                return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}} wrapText="false" truncated="false">{`${this.state.adjuntos[rowIndex].nombre}`}</Cell>
        };

        const cellRendererTipo = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}}>{`${this.state.adjuntos[rowIndex].tipo}`}</Cell>
        };

        const cellRendererTipoArchivo = (rowIndex) => {
            return <Cell style={this.state.controlesDesactivados?{background:"#eaedf2",color:"#ADB7BF"}:{}}>{`${this.state.adjuntos[rowIndex].mime}`}</Cell>
        };

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const handleSeleccionarAdjunto = (adjunto) => {
            if(adjunto.length===0)return
            this.setState({adjuntoSeleccionado:this.state.adjuntos[adjunto[0].rows[0]]})
            this.setState({regionSeleccionada:adjunto})

        };
        
        const ActualizarAdjunto = () => {
           
            if(!this.state.tipoSeleccionado)return
            if(!this.state.nombre)return
            if(!this.state.descripcion)return      

            let adjuntos=this.state.adjuntos
            //si hemos rellenado los dos campos, agregamos los valores al listado.
            this.state.adjuntos.find((adjunt,index) =>{
                if(adjunt.id===this.state.adjuntoSeleccionado.id){
                    adjuntos[index]["nombre"]=this.state.nombre?this.state.nombre:this.state.adjuntoSeleccionado.nombre
                    adjuntos[index]["tipo"]=this.state.tipoSeleccionado?this.state.tipoSeleccionado.label:this.state.adjuntoSeleccionado.tipo
                }
                return adjunt.id===this.state.adjuntoSeleccionado.id
            })
            this.setState({adjuntos:adjuntos})
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        };

        const eliminarAdjunto = () => {
            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }
            var adjuntos=this.state.adjuntos.filter(adjunto => adjunto.id !== this.state.adjuntoSeleccionado.id)
            this.setState({adjuntos:adjuntos})
            cerrarModal()
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
        };
 
        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({archivo:ev.target.files[0]})
            this.setState({tipoArchivo:ev.target.files[0].type})
            this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        return (
            <>
            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.state.nuevoActivado?"Añadir nuevo archivo adjunto":"Editar archivo adjunto"}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalAbierto}
            > 
                {this.state.nuevoActivado?
                    <div className="pixelAlrededor">
                        <FormGroup
                            label="Archivo adjunto"
                            labelFor="informacion"
                            intent="danger"
                            helperText={this.state.archivo?"":"Seleccione el archivo adjunto."}
                        >
                            <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                        </FormGroup>
                    </div>
                    :<></>
                }
                
                <div className="pixelAlrededor">
                    <FormGroup
                        label="Tipo Documento"
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.tipoSeleccionado?"":"Seleccione el tipo de adjunto."}
                    >
                        <div style={this.state.tipoSeleccionado?{}:{border:"1px solid red"}}>
                            <Select 
                                options={this.state.tipoadjuntos}
                                onChange={seleccionTipo}
                                placeholder="Seleccion el tipo de archivo"
                                defaultValue={this.state.tipoadjuntos.filter(tipo => tipo.value === (this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.idTipo:0))}
                            />
                        </div>
                    </FormGroup>
                </div>
                <div className="pixelAlrededor">
                    <FormGroup
                            label="Nombre Documento"
                            labelFor="informacion"
                            intent="danger"
                            helperText={this.state.nombre?"":"Escriba el nombre del documento."}
                    >
                        <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre} placeholder="Escriba aqui..." intent={this.state.nombre?"primary":"danger"} />
                    </FormGroup>
                </div>
                <div className="pixelAlrededor">
                    <FormGroup
                            label="Descripción"
                            labelFor="descripcion"
                            intent="danger"
                            helperText={this.state.descripcion?"":"Escriba una descripción para el documento."}
                    >
                        <TextArea
                            large={true}
                            fill={true}
                            intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                            onChange={handleCambiarDescripcion}
                            defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.descripcion:""}
                        />                    
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content="Cerrar y volver." position="top">
                        <Button onClick={cerrarModal}>Cerrar</Button>
                    </Tooltip>
                    <Tooltip content={this.state.nuevoActivado?"Añadir adjunto":"Editar adjunto"} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={this.state.nuevoActivado?anadirNuevo:ActualizarAdjunto}
                        >
                            {this.state.nuevoActivado?"Añadir adjunto":"Editar adjunto"}
                        </AnchorButton>
                    </Tooltip>
                </div>
            </div>
            </Dialog>
            
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar un archivo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el archivo adjunto seleccionado ?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar el archivo adjunto." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAdjunto}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            
            <Card id="card-adjuntos-impugnacion" interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
            <Navbar>
                <Navbar.Group>
                    <ButtonGroup align={Alignment.LEFT}>
                        {this.state.controlesDesactivados?[
                            <>
                                <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} disabled />
                                <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} disabled />
                                <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} disabled />
                                <NavbarDivider />
                                <Button className="bp3-minimal" icon="document-open" text="Abrir el archivo" disabled/>
                            </>
                        ]:[
                            <>
                                <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                <NavbarDivider />
                                <Button className="bp3-minimal" icon="document-open" text="Abrir el archivo" disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                            </>
                        ]}
                    </ButtonGroup>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ButtonGroup>
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text="Guardar"
                            intent="danger"
                            onClick={this.guardarCambios}
                            className="iconoDerecha bp3-minimal"
                            disabled={!this.state.controlesDesactivados}
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text="Cancelar"
                            onClick={cancelarCambios}
                            disabled={!this.state.controlesDesactivados}
                            className="iconoDerecha bp3-minimal"
                        />
                    </ButtonGroup>                        
                </Navbar.Group>
            </Navbar>
            <Table  enableRowHeader={false} enableMultipleSelection={false} ref={(input) => { this.tablaAdjuntos= input; }} selectionModes={!this.state.controlesDesactivados?[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]:SelectionModes.NONE} selectedRegions={this.state.regionSeleccionada}  onSelection={handleSeleccionarAdjunto}  defaultColumnWidth={350} numRows={this.state.adjuntos.length}>
                <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                <Column name="Tipo" cellRenderer={cellRendererTipo}/>   
                <Column name="Tipo de archivo" cellRenderer={cellRendererTipoArchivo}/>   
            </Table>
            </Card>
        </>
        )
    }
}

export default withApollo(ArchivosAdjuntosImpugnaciones)