/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import {Spinner, Navbar, ButtonGroup, Alignment,Tab,Tabs, Button, Dialog, Classes, Tooltip, AnchorButton, Intent } from "@blueprintjs/core"
import gql from "graphql-tag";
import PropiedadesAdministrador from "./PropiedadesAdministrador";
import Retribucion from "./Retribucion";
import ReactDOM from 'react-dom'
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import moment from "moment"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
import { Cargando } from "../Cargando";
import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import ApolloClient from "apollo-boost";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }, 
  
});
export default class MiembrosOrganoAdmon extends Component {

    state={
        imprimirActivado:false,
        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        pendienteBorrar:false,
        pendienteActualizar:false,
        pendienteGuardar:false,
        comisiones: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        tipoComision: [
        ],
        miembros:[],
        miembrosSeleccionados:[],
        nombre:null,
        miembroSeleccionado:null,
        modalBorrarAbierto:false,
        presidente:false,
        secretario:false,
        administrador:null,
        representante:null,
        cargo:null,
        idPresidente:null,
        idSecretario:null,
        cargando: false,
        asignacion: 0,
        dietas: null,
        beneficios: null,
        retribucion: null,
        acciones: null,
        indemnizacion: null,
        ahorro: null,
        cargandoMO:false,
        modalImprimir:false,
        cargandoImprimir:false,
        cargandoGrid:false
    }
    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }
    
    cargarMiembros=async () =>{
        
        let miembros=  await client
        .query({
            query:gql`{organosAdministracionMiembros(ORDEN:"ID_PERSONA,ASC"){
                ID_PERSONA,
                PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
                PRESIDENTE,
                SECRETARIO,
                ASIGNACION_FIJA,
                DIETAS_ASISTENCIA,
                PARTICIPACION_BENEFICIOS,
                RETRIBUCION_VARIABLE,
                REMUNERACION_ACCIONES,
                INDEMNIZACIONES_CESE,
                PRESTACION_SERVICIOS,
                ID_REPRESENTANTE,
                SUPLENTE,
                SISTEMAS_AHORRO,
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
                return result.data.organosAdministracionMiembros
        })
        let personasFisicas=await client
        .query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasFisicas
        })
        let personasJuridicas= await client
        .query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasJuridicas 
        })
        for(let miembro of miembros){
            if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_PERSONA){
                        miembro.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                    }
                })               
            }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===miembro.ID_PERSONA){
                        miembro.nombre=persona.RAZON_SOCIAL
                    }
                })
            }
            let noSuplentes= miembros.filter(miembro=>!miembro.SUPLENTE)            
            let numMiembros= miembros.filter(miembro=> miembro.SUPLENTE===false).length
             this.setState({miembros:noSuplentes})
             this.props.setNumeroMiembros(numMiembros)
        }
        this.setState({todosMiembros:miembros})
        this.props.setMiembrosOrganoAdmon(miembros)
}

    // cargarMiembros=async ()=>{
        
    //     //buscamos a los miembros del organo
    //     await client.query({
    //         query:gql`{organosAdministracionMiembros(ORDEN:"ID_PERSONA,ASC"){
    //             ID_PERSONA,
    //             PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
    //             PRESIDENTE,
    //             SECRETARIO,
    //             ASIGNACION_FIJA,
    //             DIETAS_ASISTENCIA,
    //             PARTICIPACION_BENEFICIOS,
    //             RETRIBUCION_VARIABLE,
    //             REMUNERACION_ACCIONES,
    //             INDEMNIZACIONES_CESE,
    //             PRESTACION_SERVICIOS,
    //             ID_REPRESENTANTE,
    //             SUPLENTE,
    //             SISTEMAS_AHORRO,
    //         }}`,fetchPolicy:'network-only'
    //     }).then(async(result)=>{

    //         let miembros=result.data.organosAdministracionMiembros
    //         for(let miembro of miembros){
    //             await this.obtenerNombreMiembros(miembro)
                
    //         }
    //         console.log(miembros)
    //         this.setState({todosMiembros:miembros})
            
    //         let noSuplentes=miembros.filter(miembro=>{return !miembro.SUPLENTE})
            
    //         this.setState({miembros:noSuplentes})

    //         this.props.setNumeroMiembros(result.data.organosAdministracionMiembros.filter(miembro=>{return miembro.SUPLENTE===false}).length)
            
    //     })
                        
    // }

    

    componentDidMount(props){
        this.setState({cargandoMO: true}, async () => {
            await this.cargarMiembros()
            this.setState({cargandoMO:false})
        })
    }
    
    componentDidUpdate(props){
        if(props!==this.props){
            if(this.props.reiniciarMiembros!==props.reiniciarMiembros)this.cargarMiembros()
        }
    }
    cargarCargo=(data)=>{
        let cargo="";
       if(data.PRESIDENTE===true){
           cargo="PRESIDENTE"
       }else if(data.SECRETARIO===true){
           cargo="SECRETARIO"
       }else{
           cargo=""
       }      
        return cargo
    }

    generarImprimirMO= async()=> {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true,
            cargandoGrid:true,
            miembroSeleccionado:null
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.ImprimirMO(), document.getElementById("generarMO-pdf"))
            })
        })
    }
    ImprimirMO= () =>{
    let cargo='';
    return(
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.body}>
                        <View fixed style={styles.row}>
                        <Image
                            style={styles.imageHead}
                            src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                            />
                        </View>
                        <View fixed style={styles.rowHead}>
                            <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                        </View>
                        <View fixed style={styles.rowHeadTwo}>
                            <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.props.i18next.t("organoadministracion.title",{ns:"page"}).toUpperCase()}</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{this.props.i18next.t("organoadministracion.miembros",{ns:"page"}).toUpperCase()}</Text>
                        </View>
                            {this.state.miembros?this.state.miembros.map((miembro, index)=>{
                                cargo= this.cargarCargo(miembro) 
                                return(
                                    <View key={index}>
                                    {index ===0 && (
                                        <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{this.props.i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'50%'}}>{this.props.i18next.t("organoadministracion.cargo",{ns:"page"}).toUpperCase()}</Text>                                                                
                                    </View>
                                    )}
                                        <View style={styles.colHead}>
                                            <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{miembro.nombre?miembro.nombre:''}</Text>
                                            <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'50%'}}>{cargo}</Text>
                                        </View>                               
                                    
                                </View>
                                )
                            }):<></>}      
                    </View>
        
                    <Text
                        style={styles.footerPage}
                        render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                        fixed
                    />
                    <Image
                            style={styles.icono}
                            src={iconoFooter}
                            fixed
                            />
                    <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                </Page>
                </Document>
             </PDFViewer>
        )
    }   

    render() {
        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            
            if(this.props.formaSeleccionada===1 && this.state.miembros.length===1){
                alert(this.props.i18next.t("organoadministracion.valid4",{ns:"page"}))
                return
            }

            cambiarPestaña("propiedades-administrador" )

            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,

                administrador:null,
                representante:null,
                cargo:null,
                presidente:false,
                idPresidente:null,
                secretario:false,
                idSecretario:null,
                miembroSeleccionado:null,
                servicios:null,
                cargandoGrid:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.miembroSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            cambiarPestaña("propiedades-administrador" )

            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                cargandoGrid:true
            })

        }

        const abrirModalEliminar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.miembroSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
            })

        }

        function cargoFormatter(cell, row) {
            let cargo="";
            if(row.PRESIDENTE)cargo="Presidente"
            if(row.SECRETARIO)cargo="Secretario"
            return (
                cargo
            );
        }

        const columns = [
        {
        dataField: 'nombre',
        text: this.props.i18next.t("name",{ns:"global"})
        },{
            dataField: 'nombre',
            text: this.props.i18next.t("organoadministracion.cargo",{ns:"page"}),
            formatter: cargoFormatter
            }
        ];

        const anadirNuevo = (value) => {
            
            if(!this.propiedades.state.adminstradorSeleccionado){
                cambiarPestaña("propiedades-administrador" )
                ReactDOM.findDOMNode(this.propiedades.selecAdministrador).children[0].focus()
                return
            }

            if(this.propiedades.state.adminstradorSeleccionado.tipo===2 && !this.propiedades.state.representanteSeleccionado){
                cambiarPestaña("propiedades-administrador" )
                ReactDOM.findDOMNode(this.propiedades.selecAdministrador).children[0].focus()
                return
            }

            if(!this.retribucion.state.asignacionValida){
                cambiarPestaña("retribucion" )
                ReactDOM.findDOMNode(this.retribucion.inputAsignacion).children[0].focus()
                return
            }

            //comprobar que la persona no esta en la lista.
            let existe=this.state.miembros.find(miembro=>{return miembro.ID_PERSONA===this.propiedades.state.adminstradorSeleccionado.value})

            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }
            let miembros=this.state.miembros

            let presidente=this.propiedades.state.cargoSeleccionado?(this.propiedades.state.cargoSeleccionado.value===1?true:false):false
            let secretario=this.propiedades.state.cargoSeleccionado?(this.propiedades.state.cargoSeleccionado.value===2?true:false):false

            if(presidente){
                //quitamos al antiguo presidente
                this.state.miembros.forEach((miembro,index)=>{
                    if(miembro.PRESIDENTE){
                        miembros[index].PRESIDENTE=false
                        this.setState({idPresidente:miembros[index].ID_PERSONA})
                    }
                })

            }

            if(secretario){
                //quitamos al antiguo presidente
                this.state.miembros.forEach((miembro,index)=>{
                    if(miembro.SECRETARIO)
                    {
                        miembros[index].SECRETARIO=false
                        this.setState({idSecretario:miembros[index].ID_PERSONA})
                    }
                    

                })

            }

            /*miembros.push({
                nombre:this.propiedades.state.adminstradorSeleccionado.label,
                PRESIDENTE:presidente,
                SECRETARIO:secretario
            })*/

            this.setState({administrador:this.propiedades.state.adminstradorSeleccionado})
            this.setState({
                presidente:presidente,
                asignacion: this.retribucion.state.asignacion,
                dietas: this.retribucion.state.dietas,
                beneficios: this.retribucion.state.beneficios,
                retribucion: this.retribucion.state.retribucion,
                acciones: this.retribucion.state.acciones,
                indemnizacion: this.retribucion.state.indemnizacion,
                ahorro: this.retribucion.state.ahorro,
            })
            this.retribucion.setState({asignacionValida:true})
            this.setState({secretario:secretario})
            this.setState({representante:this.propiedades.state.representanteSeleccionado?this.propiedades.state.representanteSeleccionado:null})
            this.setState({servicios:this.propiedades.state.servicios})
            this.setState({miembros:miembros})

            this.setState({
                miembros:miembros,
                pendienteGuardar:true,
                controlesDesactivados:true,
            }, () => {
                guardarCambios()
            })


            cerrarModal()

        }

        const editarMiembro = (value) => {

            
            if(!this.propiedades.state.adminstradorSeleccionado){
                cambiarPestaña("propiedades-administrador" )
                ReactDOM.findDOMNode(this.propiedades.selecAdministrador).children[0].focus()
                return
            }

            if(this.propiedades.state.adminstradorSeleccionado.tipo===2 && !this.propiedades.state.representanteSeleccionado){
                cambiarPestaña("propiedades-administrador" )
                ReactDOM.findDOMNode(this.propiedades.selecAdministrador).children[0].focus()
                return
            }

            if(!this.retribucion.state.asignacionValida){
                cambiarPestaña("retribucion" )
                ReactDOM.findDOMNode(this.retribucion.inputAsignacion).children[0].focus()
                return
            }

            //comprobar que la persona no esta en la lista.
            let existe=this.state.miembros.find(miembro=>{ return (miembro.ID_PERSONA===this.propiedades.state.adminstradorSeleccionado.value && miembro.ID_PERSONA!==this.state.miembroSeleccionado.ID_PERSONA)})

            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }
            let miembros=this.state.miembros

            let presidente=this.propiedades.state.cargoSeleccionado?(this.propiedades.state.cargoSeleccionado.value===1?true:false):false
            let secretario=this.propiedades.state.cargoSeleccionado?(this.propiedades.state.cargoSeleccionado.value===2?true:false):false

            if(presidente){
                //quitamos al antiguo presidente
                this.state.miembros.forEach((miembro,index)=>{
                    if(miembro.PRESIDENTE){
                        miembros[index].PRESIDENTE=false
                        this.setState({idPresidente:miembros[index].ID_PERSONA})
                    }
                })

            }

            if(secretario){
                //quitamos al antiguo presidente
                this.state.miembros.forEach((miembro,index)=>{
                    if(miembro.SECRETARIO)
                    {
                        miembros[index].SECRETARIO=false
                        this.setState({idSecretario:miembros[index].ID_PERSONA})
                    }
                    

                })

            }

            //buscamos al miembro y actualizamos.
            this.state.miembros.find((miembro,index)=>{
                if(miembro.ID_PERSONA===this.state.miembroSeleccionado.ID_PERSONA){
                    miembros[index].nombre=this.propiedades.state.adminstradorSeleccionado.label
                    miembros[index].PRESIDENTE=presidente
                    miembros[index].SECRETARIO=secretario
                }
                return miembro.ID_PERSONA===this.state.miembroSeleccionado.ID_PERSONA
            })
    

            this.setState({administrador:this.propiedades.state.adminstradorSeleccionado})
            this.setState({
                presidente:presidente,
                asignacion: this.retribucion.state.asignacion,
                dietas: this.retribucion.state.dietas,
                beneficios: this.retribucion.state.beneficios,
                retribucion: this.retribucion.state.retribucion,
                acciones: this.retribucion.state.acciones,
                indemnizacion: this.retribucion.state.indemnizacion,
                ahorro: this.retribucion.state.ahorro,
            })
            this.setState({secretario:secretario})
            this.setState({representante:this.propiedades.state.representanteSeleccionado?this.propiedades.state.representanteSeleccionado:null})
            this.setState({servicios:this.propiedades.state.servicios})

            this.setState({miembros:miembros})

            this.setState({
                miembros:miembros,
                pendienteActualizar:true,
                controlesDesactivados:true,
            }, () => {
                guardarCambios()
            })

            cerrarModal()


        }

        const eliminarMiembro=()=>{

            let miembros=this.state.miembros.filter(miembro => miembro.ID_PERSONA !== this.state.miembroSeleccionado.ID_PERSONA)
            this.setState({miembros:miembros})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true}, () => {
                guardarCambios()
            })
            cerrarModal()

        }


        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid: false
            })
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        miembroSeleccionado: row
        });
        }
        return true;
        }
        

        const guardarCambios=async()=>{


            if(this.state.pendienteGuardar){
              
                if(this.state.idPresidente){

                    await client
                    .mutate({
                    mutation: gql`
                        mutation{actualizarOrganoAdministracionMiembro(ID_PERSONA:${this.state.idPresidente},VALORES:{PRESIDENTE:false}){ID_PERSONA}}
                    `
                    })

                }

                if(this.state.idSecretario){

                    await client
                    .mutate({
                    mutation: gql`
                        mutation{actualizarOrganoAdministracionMiembro(ID_PERSONA:${this.state.idSecretario},VALORES:{SECRETARIO:false}){ID_PERSONA}}
                    `
                    })

                }

                //comprobar si existe ya en la tabla de miembros.
                let suplente=this.state.todosMiembros.find(miembro=>{
                    return miembro.ID_PERSONA===this.state.administrador.value
                })

                if(!suplente){

                    //insertamos la comision en la bdd.
                    await client
                    .mutate({
                    mutation: gql`
                        mutation{crearOrganoAdministracionMiembros(VALORES:{
                            ID_PERSONA:${this.state.administrador.value},
                            ID_REPRESENTANTE:${this.state.representante?this.state.representante.value:null},
                            PRESIDENTE:${this.state.presidente},SECRETARIO:${this.state.secretario},
                            SUPLENTE:false,
                            PRESTACION_SERVICIOS:${this.state.servicios?'"'+this.state.servicios+'"':null},
                            ASIGNACION_FIJA:${this.state.asignacion?this.state.asignacion.replace(/\./g, '').replace(/\,/g, '.'):null},
                            DIETAS_ASISTENCIA:${this.state.dietas?'"'+this.state.dietas+'"':null},
                            PARTICIPACION_BENEFICIOS:${this.state.beneficios?'"'+this.state.beneficios+'"':null},
                            RETRIBUCION_VARIABLE:${this.state.retribucion?'"'+this.state.retribucion+'"':null},
                            REMUNERACION_ACCIONES:${this.state.acciones?'"'+this.state.acciones+'"':null},
                            INDEMNIZACIONES_CESE:${this.state.indemnizacion?'"'+this.state.indemnizacion+'"':null},
                            SISTEMAS_AHORRO:${this.state.ahorro?'"'+this.state.ahorro+'"':null},
                        
                        }){ID_PERSONA}}
                    `
                    })
                    .then(async result => {

                            alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))

                            //recargamos las comisiones
                            await this.cargarMiembros()

                            this.setState({pendienteGuardar:false})
                            this.setState({controlesDesactivados:false})
                        
                    }).catch(err=>{
                        alert(this.props.i18next.t("error",{ns:"global"}))
                    })


                }else{

                    await client
                    .mutate({
                    mutation: gql`
                        mutation{actualizarOrganoAdministracionMiembro(
                                ID_PERSONA:${this.state.administrador.value},
                                VALORES:{
                                        ID_REPRESENTANTE:${this.state.representante?this.state.representante.value:null},
                                        PRESIDENTE:${this.state.presidente},
                                        SECRETARIO:${this.state.secretario},
                                        SUPLENTE:false,
                                        PRESTACION_SERVICIOS:${this.state.servicios?'"'+this.state.servicios+'"':null},
                                        ASIGNACION_FIJA:${this.state.asignacion?this.state.asignacion.replace(/\./g, '').replace(/\,/g, '.'):null},
                                        DIETAS_ASISTENCIA:${this.state.dietas?'"'+this.state.dietas+'"':null},
                                        PARTICIPACION_BENEFICIOS:${this.state.beneficios?'"'+this.state.beneficios+'"':null},
                                        RETRIBUCION_VARIABLE:${this.state.retribucion?'"'+this.state.retribucion+'"':null},
                                        REMUNERACION_ACCIONES:${this.state.acciones?'"'+this.state.acciones+'"':null},
                                        INDEMNIZACIONES_CESE:${this.state.indemnizacion?'"'+this.state.indemnizacion+'"':null},
                                        SISTEMAS_AHORRO:${this.state.ahorro?'"'+this.state.ahorro+'"':null}
                                    }){ID_PERSONA}}
                    `
                    })
                    .then(async result => {
                
                        alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                
                        //recargamos las comisiones
                        await this.cargarMiembros()

                        this.setState({pendienteGuardar:false})
                        this.setState({controlesDesactivados:false})
                        this.props.setReiniciarSuplentes(true)

                    }).catch(err=>{
                        alert(this.props.i18next.t("error",{ns:"global"}))
                    })
    
                }
                

            }else if(this.state.pendienteActualizar){

                if(this.state.idPresidente){

                    await client
                    .mutate({
                    mutation: gql`
                        mutation{actualizarOrganoAdministracionMiembro(ID_PERSONA:${this.state.idPresidente},VALORES:{PRESIDENTE:false}){ID_PERSONA}}
                    `
                    })

                }

                if(this.state.idSecretario){

                    await client
                    .mutate({
                    mutation: gql`
                        mutation{actualizarOrganoAdministracionMiembro(ID_PERSONA:${this.state.idSecretario},VALORES:{SECRETARIO:false}){ID_PERSONA}}
                    `
                    })

                }
                //insertamos la comision en la bdd.
                await client
                .mutate({
                mutation: gql`
                    mutation{actualizarOrganoAdministracionMiembro(
                            ID_PERSONA:${this.state.miembroSeleccionado.ID_PERSONA},
                            VALORES:{
                                    ID_REPRESENTANTE:${this.state.representante?this.state.representante.value:null},
                                    PRESIDENTE:${this.state.presidente},
                                    SECRETARIO:${this.state.secretario},
                                    SUPLENTE:false,
                                    PRESTACION_SERVICIOS:${this.state.servicios?'"'+this.state.servicios+'"':null},
                                    ASIGNACION_FIJA:${this.state.asignacion?this.state.asignacion.replace(/\./g, '').replace(/\,/g, '.'):null},
                                    DIETAS_ASISTENCIA:${this.state.dietas?'"'+this.state.dietas+'"':null},
                                    PARTICIPACION_BENEFICIOS:${this.state.beneficios?'"'+this.state.beneficios+'"':null},
                                    RETRIBUCION_VARIABLE:${this.state.retribucion?'"'+this.state.retribucion+'"':null},
                                    REMUNERACION_ACCIONES:${this.state.acciones?'"'+this.state.acciones+'"':null},
                                    INDEMNIZACIONES_CESE:${this.state.indemnizacion?'"'+this.state.indemnizacion+'"':null},
                                    SISTEMAS_AHORRO:${this.state.ahorro?'"'+this.state.ahorro+'"':null}
                                }){ID_PERSONA}}
                `
                })
                .then(async result => {
                
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
            
                    //recargamos las comisiones
                    await this.cargarMiembros()

                    this.setState({pendienteGuardar:false})
                    this.setState({controlesDesactivados:false})
                    
                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

               
            }else if(this.state.pendienteBorrar){

                //eliminamos la comision en la bdd.
                await client
                .mutate({
                mutation: gql`
                    mutation{eliminarOrganoAdministracionMiembro(ID_PERSONA:${this.state.miembroSeleccionado.ID_PERSONA}){ID_PERSONA}}
                `
                })
                .then(async result => {
                
                    alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))
                    
                    //recargamos las comisiones
                    this.setState({
                        pendienteBorrar:false,
                        controlesDesactivados:false,
                        miembroSeleccionado:null,
                    })


                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })
                
            }

            this.props.setNumeroMiembros(this.state.miembros.length)


        }
        
        const cambiarPestaña=(value)=>{
            this.setState({pestaña:value})
        }

        return (
            <>
            {
                this.state.cargandoMO === true?
                <Spinner />
                :
                <>
                    <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarMO-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div className="pixelAlrededor">
                        <Tabs id="datos-empresa"
                            animate={this.animacion} 
                            onChange={cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                        >
                            <Tab id="propiedades-administrador" title={this.props.i18next.t("organoadministracion.propiedad",{ns:"page"})} panel={ <PropiedadesAdministrador formaGuardada={this.props.formaGuardada} formaSeleccionada={this.props.formaSeleccionada}  datos={this.state.miembroSeleccionado} ref={(input) => { this.propiedades= input; }} cargarMiembros={this.cargarMiembros}  i18next={this.props.i18next}/> }/>
                            <Tab id="retribucion" title={this.props.i18next.t("organoadministracion.retribucion",{ns:"page"})} panel={ <Retribucion datos={this.state.miembroSeleccionado} ref={(input) => { this.retribucion= input; }} i18next={this.props.i18next}/> } />
                        </Tabs>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirNuevo:editarMiembro}
                                >
                                {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={this.props.i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{this.props.i18next.t("organoadministracion.questiondelmiembro",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={this.props.i18next.t("eliminar",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarMiembro}
                                    >
                                        {this.props.i18next.t("yes",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div> 
                        </Dialog>
                        {this.state.cargandoGrid?
                        <Cargando />
                        :
                        <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado}  onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="delete" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado}  onClick={abrirModalEliminar} />
                                    <Button className="bp3-minimal" icon="print" text={this.props.i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={()=>this.generarImprimirMO()}/>
                                    
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                        keyField='ID_PERSONA'
                        data={ this.state.miembros }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }                    
                </>
            }
            </>
        )
    }
}
