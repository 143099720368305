import React, { Component } from 'react';
import { Spinner } from '@blueprintjs/core';
import Cabecera from '../Componentes/Cabecera'
import Perfil from '../Componentes/Pantallas/PerfilUsuario'
import AreaTrabajo from '../Componentes/AreaTrabajo'
import Login from '../Componentes/Login.js'
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import Bdds from "../Componentes/Pantallas/Bdds.js";
import  context  from '../App'
import { ApolloProvider } from 'react-apollo';
import { withRouter} from "react-router-dom";
import i18next from 'i18next';

let clienteLogin = new ApolloClient({
  uri:process.env.REACT_APP_DOMINIO+"/login",
  credentials: 'include',
});

let clienteBdd = new ApolloClient({
  uri:process.env.REACT_APP_DOMINIO+"/bdds",
  credentials: 'include',
});

let cliente = new ApolloClient({
  uri:process.env.REACT_APP_DOMINIO+"/api",
  credentials: 'include',
});

const obtenerBdds=gql`
{
  bdds{ID_BDD,NOMBRE,CIF,RAZON_SOCIAL,ID_EMPRESA,NOM_EMPRESA}
}

`
class ContenedorPrincipal extends Component {

  static contextType = context;


  state={
    logueado:null,
    menu:"Inicio",
    recargarMenu:false,
    bdd:null,
    usuario:null,
    cargando:false
  }

  setLogueado = async (logueado) => {

    //al loguear ponemos perfil a false, para que aparezcan nada mas entrar el listado de bdds y usuarios.
    localStorage.setItem("perfil",false)
    if (logueado!==false) {
      let bddsUser=await clienteBdd
        .query({
            query: obtenerBdds,fetchPolicy:'network-only',
        })
        .then(result =>{
            return result.data.bdds
        });
      if (bddsUser.length===1) {
        
        await clienteBdd
        .mutate({
            mutation: gql`
            mutation{
                useBdd(NOMBRE:"${bddsUser[0].NOMBRE}")
            }
            `
        }).then(async ()=>{
          window.location.reload()
        })
      } else {
        this.setState({logueado: logueado})
      }
    } else {
      this.setState({logueado: logueado})
    }
  }

  setPerfil = (perfil) => {
    localStorage.setItem("perfil",perfil)
    this.setState(this.state)
  }

  setAyuda = () => {

    //let rutas=["/estructura-organizativa"]
    //if(rutas.find(r=>{return r===this.props.location.pathname})){
      localStorage.setItem("ayuda",true)
      this.setState(this.state)
    //}

  }

  limpiarBddSeleccionada = () => {

    this.setState({ bdd: false })

  }

  setMenuSeleccionado = (menu) => {
    this.setState({ menu: menu })
    document.title = i18next.t("lexcontrol",{ns:"global"})+" | " + i18next.t(menu,{ns:"menu"})
  }

  comprobarLogin=async()=>{

    let bddActual=null
    let logueado=await clienteLogin
    .query({
      query: gql`
        {
          estaLogueado
        }
      `
    })
    .then(result =>{
      return result.data.estaLogueado
    })
    
    if(logueado){

      bddActual=await clienteBdd
      .query({
          query: gql`
          {obtenerBddActual}
          `
      }).then(result=>{ 
          return result.data.obtenerBddActual
      })

    }
  

    if(!logueado)this.props.history.push("/")

    this.setState({ 
      logueado:logueado,
      bdd:(bddActual?bddActual.substr(0,bddActual.lastIndexOf("_")):false),
    })    
     
  }
 
  componentDidMount=async(props)=>{ 
      await this.comprobarLogin()
  }
 
  render() {
    const changeLngES=()=>{
      this.setState({
        cargando:true
      },()=>{
        i18next.changeLanguage('es');
        this.setState({cargando:false})
      })
    }
    const changeLngEN=()=>{
      this.setState({
        cargando:true
      },()=>{
        i18next.changeLanguage('en');
        this.setState({cargando:false})
      })
    }
  if(this.state.logueado===null)return null
    return(
      {...!this.state.cargando?
      {...this.state.logueado===false?
        <div className="login">
          <Login setLogueado={this.setLogueado} />
        </div>
        :
        {...this.state.bdd === false?

            <>
            <Cabecera changeLngES={changeLngES} changeLngEN={changeLngEN} setAyuda={this.setAyuda} setLogueado={this.setLogueado} setPerfil={this.setPerfil} limpiarBddSeleccionada={this.limpiarBddSeleccionada}  />
            {localStorage.getItem("perfil")==="false"
              ?
              <div className="bdds">
                <Bdds setLogueado={this.setLogueado} limpiarBddSeleccionada={this.limpiarBddSeleccionada} />
              </div>
              : 
              <Perfil/>
            }
              
            </>
          :
          <div className="AreaTrabajo">
            <Cabecera changeLngES={changeLngES} changeLngEN={changeLngEN} setAyuda={this.setAyuda} setLogueado={this.setLogueado} setPerfil={this.setPerfil} limpiarBddSeleccionada={this.limpiarBddSeleccionada}  bdd={this.state.bdd} menu={this.state.menu} />
            <ApolloProvider client={cliente}>
              <AreaTrabajo i18next={i18next} logueado={this.state.logueado} recargarMenu={this.state.recargarMenu} setMenuSeleccionado={this.setMenuSeleccionado} />
            </ApolloProvider>
          </div>
       }
      }
      :
        <Spinner />
      }
    )
  }


}

export default withRouter(ContenedorPrincipal);
