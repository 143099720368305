import React from "react"
import { Card, Elevation, Callout } from "@blueprintjs/core"

export default class InformacionReuniones extends React.Component {

    state={
        abierto1: false,
        abierto2: false,
        abierto3: false,
    }

    render(){
        return(
            <> 
                <Card interactive={true} elevation={Elevation.TWO} >
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">LEY DE SOCIEDADES DE CAPITAL <br /> REGLAMENTO DEL REGISTRO MERCANTIL </Callout>
                    <a role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a163" class="bp3-button bp3-icon-git-repo">Artículo 163 LSC</a>
                    <a role="button" target="_blank"  rel="noopener noreferrer" href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a250" class="bp3-button bp3-icon-git-repo">Artículo 250 LSC</a>
                    <a role="button" target="_blank"  rel="noopener noreferrer" href="https://boe.es/buscar/act.php?id=BOE-A-1996-17533&tn=1&p=20170304#s2-3" class="bp3-button bp3-icon-git-repo">Artículo 97 RRM</a>
                </Card>
            </>
        )
    }
}