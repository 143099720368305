import React, { Component } from 'react';
import { Card, Elevation, Navbar, Alignment, Button, ButtonGroup, FormGroup, InputGroup, Dialog, Classes, Tooltip, AnchorButton, Intent, Checkbox } from "@blueprintjs/core"
import { Table, Cell, Column } from "@blueprintjs/table"
import { withApollo } from 'react-apollo';
import gql from "graphql-tag";

 class PerfilesAcceso extends Component {

    state={
        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        perfiles: [
            {
                nombre: "Admin",
            },
            {
                nombre: "User",
            }
        ],
        seleccion: null,
        arbol: [
            {
                permiso: "Datos de la empresa",
                ver: true,
                editar: false,
            },
            {
                permiso: "Gobierno corporativo",
                ver: true,
                editar: false,
            },
        ]
    }

    componentDidMount=async()=>{

        let menus=await this.props.client
        .query({
          query: gql`
            {
                menus{ID_MENU,ID_PADRE,TEXTO,KEY,EDITAR}
            }
          `
        })
        .then(result =>{
            return JSON.parse(JSON.stringify(result.data.menus))
        })

        for(let menu of menus){

            menu.ver=false
            menu.editar=false
        }

        //OBTENCION DE LOS PADRES
        let padres=menus.filter(menu=>{return menu.ID_PADRE===null})
        let arbolOrdenado=[]
        for(let padre of padres){

            //por cada padre obtenemos sus hijos
            let hijos=menus.filter(menu=>{return menu.ID_PADRE===padre.ID_MENU})
            arbolOrdenado.push(padre)
            for(let hijo of hijos){
                arbolOrdenado.push(hijo)
            }

        }


        this.setState({arbol:arbolOrdenado})
    }

    render() {

        document.title ="LexControl | Perfiles"

        const abrirModalNuevo = () => {
            this.setState({modalNuevoAbierto: true})
        }

        const cellRendererPerfil = (rowIndex) => {
            return <Cell wrapText="false" truncated="false">{`${this.state.perfiles[rowIndex].nombre}`}</Cell>
        }

        const cellRendererTablaOpcion = (rowIndex) => {
        return <Cell wrapText="false" truncated="false">{this.state.arbol[rowIndex].ID_PADRE===null?<span style={{fontWeight:"bold"}}>{this.state.arbol[rowIndex].TEXTO}</span>:<span>{this.state.arbol[rowIndex].TEXTO}</span>}</Cell>
        }

        const cellRendererTablaVer = (rowIndex) => {
            return <Cell wrapText="false" truncated="false"><Checkbox checked={this.state.arbol[rowIndex].ver} onChange={()=>{handleEnableVer(rowIndex)}} /></Cell>
        }

        const cellRendererTablaEditar = (rowIndex) => {
            return <Cell wrapText="false" truncated="false"><Checkbox checked={this.state.arbol[rowIndex].editar} onChange={()=>{handleEnableEditar(rowIndex)}} /></Cell>
        }

        const cerrarModal=()=>{
            this.setState({modalNuevoAbierto:false})
        }

        const añadirNuevo = (value) => {
            //console.log(value);
        }

        const handleEnableVer = (rowIndex) => {

            let arbol = this.state.arbol

            arbol[rowIndex].ver = !arbol[rowIndex].ver
            this.setState({arbol:arbol})
        }

        const handleEnableEditar = (rowIndex) => {

            let arbol = this.state.arbol

            arbol[rowIndex].editar = !arbol[rowIndex].editar
            this.setState({arbol:arbol})
        }

        const handleSeleccionarUsuario = (value) => {

            if(value.length===0)return

            let seleccionada=[value[0].rows[0]]                
            this.setState({
                seleccion: seleccionada
            })
        }

        return (
            <>            
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={this.cerrarModal}
                    title="Añadir nuevo perfil de acceso"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    className="pixelAlrededor"
                >
                <FormGroup
                    label="Nombre del perfil:"
                    labelFor="acreedor"
                >
                    <InputGroup />
                </FormGroup>
                
                <FormGroup
                    label="Tabla de permisos:"
                    labelFor="tabla-permisos"
                >
                    <Table numRows={this.state.arbol.length} onSelection={handleSeleccionarUsuario} defaultRowHeight={40}>
                        <Column name="Usuario" cellRenderer={cellRendererTablaOpcion}/>     
                        <Column name="Ver" cellRenderer={cellRendererTablaVer}/>     
                        <Column name="Editar" cellRenderer={cellRendererTablaEditar}/>     
                    </Table>
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content="Editar perfil." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={añadirNuevo}
                            >
                                Editar perfil
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Card interactive={true} elevation={Elevation.TWO} >
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text="Editar" />
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <Table defaultColumnWidth={1440} numRows={this.state.perfiles.length} onSelection={handleSeleccionarUsuario}>
                        <Column name="Usuario" cellRenderer={cellRendererPerfil}/>     
                    </Table>
                </Card>
            </>
        )
    }
}export default withApollo(PerfilesAcceso)