/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Spinner, Elevation, Collapse, Card, FormGroup, Button, Dialog, Classes, Tooltip, AnchorButton, Intent, Navbar, Alignment, ButtonGroup, InputGroup, TextArea, Callout } from "@blueprintjs/core"
import Select from "react-select"
import { DateInput } from "@blueprintjs/datetime";
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });


const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});



class CreditosSocios extends Component {

    state={
        cargandoNuevo:false,
        modalAbierto: false,
        modalBorrarAbierto: false,
        tipoGarantiaSeleccionada:null,
        personaSeleccionada:null,
        creditoSeleccionado:null,
        condiciones:'',
        creditos: [
        ],
        tiposGarantia: [
        ],
        socios: [
        ],
        totalPartidas: 0.00,
        abiertoNuevo: false,
        abiertoEditar: false,
        abiertoBorrar: false,
        fecha:new Date(),
        nuevoTipoGarantia:null,
        totalPartidasValido:true, 
        personasFisicas: null,
        personasJuridicas:null, 
        cargando:false,
        datosEmpresa:null,
        cargandoImprimir:false,
        modalImprimir:false,
        imprimirActivado:false,
        cargandoGrid:false
    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
                {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }

    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
               {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
    }
    
    CargarDatosEmpresa= async () =>{
        let datosEmpresa= await this.props.client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }

    cargarDatos= async()=> {
        let personasFisicas= await this.cargarPersonasFisicas();
        let personasJuridicas= await this.cargarPersonasJuridicas();
        let datosEmpresa=await this.CargarDatosEmpresa()
        this.setState({
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
            datosEmpresa: datosEmpresa
        })
    }
    // obtenerNombreMiembros=async(miembro)=>{

    //     if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
    //         //obtenemos el nombre
    //         await this.props.client.query({
    //             query:gql`{personasFisicas(FILTROS:{ID_PERSONA:${miembro.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
    //             fetchPolicy:'network-only'
    //         }).then((result)=>{

    //             let persona=result.data.personasFisicas[0]

    //             miembro.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
    //             console.log(miembro.nombre)

    //         })

    //     }else if(miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){

    //         await this.props.client.query({
    //             query:gql`{personasJuridicas(FILTROS:{ID_PERSONA:${miembro.ID_PERSONA}}){RAZON_SOCIAL}}`,
    //             fetchPolicy:'network-only'
    //         }).then((result)=>{

    //             let persona=result.data.personasJuridicas[0]
    //             miembro.nombre=persona.RAZON_SOCIAL

    //         })

    //     }
        
    // }
    
    cargarGarantias=async()=>{

        let garantias=await this.props.client.query({
            query:gql`{garantias{ID_GARANTIA,ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA,CANTIDAD,CONDICIONES,TIPO_GARANTIA{ID_TIPO_GARANTIA,DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.garantias
        })

        for(let garantia of garantias){

            garantia.id=garantia.ID_GARANTIA
            garantia.idPersona=garantia.ID_PERSONA
            garantia.fecha=garantia.FECHA
            garantia.importe=garantia.CANTIDAD
            garantia.condiciones=garantia.CONDICIONES
            garantia.idGarantia=garantia.TIPO_GARANTIA.ID_TIPO_GARANTIA
            garantia.garantia=garantia.TIPO_GARANTIA.DESCRIPCION

            if(garantia.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===garantia.ID_PERSONA){
                        garantia.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                    }
                })
            }
            if(garantia.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===garantia.ID_PERSONA){
                        garantia.nombre=persona.RAZON_SOCIAL
                    }
                })
            }

            // await this.obtenerNombreMiembros(garantia)
        }
        
        this.setState({creditos:garantias})
        //console.log(this.state.creditos)
    }

    obtenerSocios=async()=>{


        let administradores=await this.props.client
        .query({
            query: gql`
                {organosAdministracionMiembros{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{ 
            
            return result.data.organosAdministracionMiembros
        })

    let socios=[]
    // let existe=false        
        //obtencion del nombre de los administradores.
        for(let administrador of administradores){
            if(administrador.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===administrador.ID_PERSONA){
                        
                        administrador.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        administrador.value=persona.ID_PERSONA
                        
                    }
                })
            //    await this.props.client
            //     .query({
            //         query: gql`
            //             {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${administrador.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            //         `,
            //         fetchPolicy:'network-only'
            //     }).then(result=>{ 
        
            //         administrador.label=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
            //         administrador.value=result.data.personasFisicas[0].ID_PERSONA

            //     })
    
            //Si es persona juridica
            }else if(administrador.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===administrador.ID_PERSONA){
                        administrador.label=persona.RAZON_SOCIAL
                        administrador.value=persona.ID_PERSONA
                    }

                })
                // await this.props.client
                // .query({
                //     query: gql`
                //         {personasJuridicas(FILTROS:{ID_PERSONA:${administrador.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                //     `,
                //     fetchPolicy:'network-only'
                // }).then(result=>{ 
    
                //     administrador.label=result.data.personasJuridicas[0].RAZON_SOCIAL
                //     administrador.value=result.data.personasJuridicas[0].ID_PERSONA

                // })
    
            }
            let existe=false;
            socios.find((socio)=>{
                if (socio.value===administrador.value) {
                    existe=true;
                }
            })
            if (!existe) {
                socios.push(administrador)
            }
        }
        
        let apoderados=await this.props.client
        .query({
            query: gql`
                {apoderados{ID_APODERADO,APODERADO{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{ 
            
            return result.data.apoderados
        })


         //obtencion del nombre de los apoderados.
        for(let apoderado of apoderados){
            if(apoderado.APODERADO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.state.personasFisicas.find(persona=>{
                    if(persona.ID_PERSONA===apoderado.ID_APODERADO){
                        apoderado.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                        apoderado.value=persona.ID_PERSONA
                    }
                })
            //    await this.props.client
            //     .query({
            //         query: gql`
            //             {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${apoderado.ID_APODERADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            //         `,
            //         fetchPolicy:'network-only'
            //     }).then(result=>{ 
                    
            //         apoderado.label=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
            //         apoderado.value=result.data.personasFisicas[0].ID_PERSONA

            //     })
    
            //Si es persona juridica
            }else if(apoderado.APODERADO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.state.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===apoderado.ID_APODERADO){
                        apoderado.label=persona.RAZON_SOCIAL
                        apoderado.value= persona.ID_PERSONA
                    }
                })
                // await this.props.client
                // .query({
                //     query: gql`
                //         {personasJuridicas(FILTROS:{ID_PERSONA:${apoderado.ID_APODERADO}}){ID_PERSONA,RAZON_SOCIAL}}
                //     `,
                //     fetchPolicy:'network-only'
                // }).then(result=>{ 
    
                //     apoderado.label=result.data.personasJuridicas[0].RAZON_SOCIAL
                //     apoderado.value=result.data.personasJuridicas[0].ID_PERSONA

                // })
    
            }
            let existe=false;
            socios.find((socio)=>{
                if (socio.value===apoderado.value) {
                    existe=true;
                }
            })
            if (!existe) {
                socios.push(apoderado)
            }
        }


        let consejeros=await this.props.client
        .query({
            query: gql`
                {consejerosDelegados{ID_CONSEJERO_DELEGADO}}
            `,
            fetchPolicy:'network-only'
        }).then(async(result)=>{ 
            
            return result.data.consejerosDelegados
        })


         //obtencion del nombre de los apoderados.
         for(let consejero of consejeros){
             this.state.personasFisicas.find(persona=>{
                 if(persona.ID_PERSONA===consejero.ID_CONSEJERO_DELEGADO){
                     consejero.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.apellido2:''} ${persona.NOMBRE}`
                     consejero.value=persona.ID_PERSONA
                 }
             })
            // await this.props.client
            // .query({
            //     query: gql`
            //         {personasFisicas(FILTROS:{ID_PERSONA:${consejero.ID_CONSEJERO_DELEGADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            //     `,
            //     fetchPolicy:'network-only'
            // }).then(result=>{ 
    
            //     consejero.label=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
            //     consejero.value=result.data.personasFisicas[0].ID_PERSONA

            // })
           let existe=false;
            socios.find((socio)=>{
                if (socio.value===consejero.value) {
                    existe=true;
                }
            })
            if (!existe) {
                socios.push(consejero)
            }
            
        }

        //capital social distribucion

        let distribucion=await this.props.client
        .query({
            query: gql` 
                {capSocialInicialDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `, 
            fetchPolicy: 'network-only'
        }).then(result=>{ 
            
            return result.data.capSocialInicialDistribucion
        })

        let miembrosActuales=await this.props.client
        .query({
            query: gql` 
                {capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `, 
            fetchPolicy: 'network-only'
        }).then(result=>{ 
            
            return result.data.capSocialActualDistribucion
        })

        let miembrosTransmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        for(let miembro of miembrosTransmisiones){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=distribucion.find(d=>{return d.ID_PERSONA===miembro.DESTINO})
            if(!existe)distribucion.push(miembro)
        }

        for(let miembro of miembrosActuales){
            //si no existe la persona al array de miembros lo pasamos.
            let existe=distribucion.find(d=>{return d.ID_PERSONA===miembro.ID_PERSONA})
            if(!existe)distribucion.push(miembro)
        }


        for(let socio of distribucion){

            if(socio.PERSONA){

                if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===socio.ID_PERSONA){
                            socio.value= persona.ID_PERSONA
                            socio.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                        }
                    })

                    // this.props.client
                    //  .query({
                    //      query: gql` 
                    //          {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                    //      `,
                    //      fetchPolicy: 'network-only'
    
                    //  }).then(result=>{ 
                    //     socio.value=result.data.personasFisicas[0].ID_PERSONA
                    //     socio.label=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                    //  })
    
                 //Si es persona juridica
                 }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                     this.state.personasJuridicas.find(persona=>{
                         if(persona.ID_PERSONA===socio.ID_PERSONA){
                             socio.value=persona.ID_PERSONA
                             socio.label=persona.RAZON_SOCIAL
                         }
                     })
                    // this.props.client
                    //  .query({
                    //      query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                    //      `,
                    //      fetchPolicy: 'network-only'
    
                    //  }).then(result=>{ 
                    //     socio.value=result.data.personasJuridicas[0].ID_PERSONA
                    //     socio.label=result.data.personasJuridicas[0].RAZON_SOCIAL
                    // })
    
                 }
            
            
            }else{

                if(socio.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    //obtenemos el nombre
                    this.state.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===socio.DESTINO){
                            socio.value=socio.DESTINO
                            socio.label=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                        }
                    })
                    // await this.props.client.query({
                    //     query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${socio.DESTINO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
                    //     fetchPolicy:'network-only'
                    // }).then((result)=>{
        
                    //     let persona=result.data.personasFisicas[0]
        
                    //     socio.value=socio.DESTINO
                    //     socio.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
        
                    // })
        
                }else if(socio.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===socio.DESTINO){
                            socio.value= socio.DESTINO
                            socio.label=persona.RAZON_SOCIAL
                        }
                    })
        
                    // await this.props.client.query({
                    //     query:gql`{personasJuridicas(FILTROS:{ID_PERSONA:${socio.DESTINO}}){RAZON_SOCIAL}}`,
                    //     fetchPolicy:'network-only'
                    // }).then((result)=>{
        
                    //     let persona=result.data.personasJuridicas[0]
        
                    //     socio.value=socio.DESTINO
                    //     socio.label=persona.RAZON_SOCIAL
        
                    // })
        
                }

            }
            let existe=false;
            socios.find((child)=>{
                if (child.value===socio.value) {
                    existe=true;
                }
            })
            if (!existe) {
                socios.push(socio)
            }
            
        }
         // unimos los arrays en uno.
        // let socios=[].concat(administradores, apoderados, consejeros, distribucion)
       
        // let socios=administradores.concat(apoderados)
        // socios=socios.concat(consejeros)
        // socios=socios.concat(distribucion)


        /*FIJATE CUAL DE LAS 3 FUNCIONES VA, NO ME DEVUELVEN LO MISMO, PERO LA QUE DEJO TARDA MAS EN EJECUTARSE */
        //hacemos un unique, para que no se repitan valores.      

        // let unicos = new Set(socios);      
        // this.setState({
        //     socios:[...unicos]
        // })
        // console.log(this.state.socios)

     
        // let uniqs = socios.filter(function(item, index, socio) {
        //     return array.indexOf(item) === index;
        //   })
        //   console.log(uniqs); 
        // this.setState({socios:uniqs})

        /*var unique = {};
        var distinct = [];
        for(let x of socios){
            if (!unique[x.value]) {
                distinct.push(x);
                unique[x.value] = true;
            }
        }
        socios=distinct*/
        this.setState({socios:socios})
        //console.log(this.state.socios)
        
    }

    cargarTiposGarantia=async()=>{
        await this.props.client.query({
            query:gql`{tipoGarantias{ID_TIPO_GARANTIA,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(result=>{
            //console.log(result)
            let garantias=[]
            result.data.tipoGarantias.forEach(capital=>{
               garantias.push({"value":capital.ID_TIPO_GARANTIA,"label":capital.DESCRIPCION}) 
            })
            this.setState({tiposGarantia:garantias})
        })
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    componentDidMount(){
        this.setState({
            cargando:true
        }, async () => {
            await  this.cargarDatos()
            await  this.cargarTiposGarantia()
            await  this.cargarGarantias()
            await  this.obtenerLogoEmpresa()
            this.setState({cargando:false})
        }) 
        
        
    }
    generarDocumento= async () => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir: true,
            cargandoGrid:true,
            creditoSeleccionado:null
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.ImprimirCreditos(), document.getElementById('generar-pdf-CS'))
            })
        })
    }

    ImprimirCreditos= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("creditosocio.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("creditosocio.title",{ns:"page"}).toUpperCase}</Text>
                </View>
                    {this.state.creditos?this.state.creditos.map((credito, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>CONCEDIDA A:</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>TIPO DE GARANTÍA</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>IMPORTE</Text>                                     
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>FECHA CONCESIÓN</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>CONDICIONES</Text>
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{paddingLeft:2,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{credito.nombre?credito.nombre:''}</Text>
                                    <Text style={{paddingLeft:2,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{credito.garantia?credito.garantia:''}</Text>
                                    <Text style={{paddingLeft:2,textAlign:'right',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{credito.importe?credito.importe:''}</Text>
                                    <Text style={{paddingLeft:2,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{credito.fecha?moment(credito.fecha).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{paddingLeft:2,textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:9,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{credito.condiciones?credito.condiciones:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>

            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"}) + " | " + i18next.t("creditosocio.title",{ns:"page"})

        const cerrarModalImprimir= () => {
            this.setState({modalImprimir:false,cargandoGrid:false})
        }

        const abrirModalNuevo = () => {
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                cargandoNuevo:true,
                modalAbierto: true,
                cargandoGrid:true,
                creditoSeleccionado:null
            },async() =>{
                if (this.state.socios.length===0) {
                    await  this.obtenerSocios()
                }
                this.setState({
                    cargandoNuevo:false,
                    nuevoActivado:true,
                    editarActivado:false,
                    borrarActivado:false,       
                    
                    personaSeleccionada:null,
                    creditoSeleccionado:null,
                    tipoGarantiaSeleccionada:null,
                    totalPartidas:'',
                    condiciones:'',
                    fecha:new Date()
                })
            })
            

            
        }
        const abrirModalEditar = () => {
 
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.creditoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                cargandoNuevo:true,
                modalAbierto: true,
                cargandoGrid: true
            },async() =>{
                if (this.state.socios.length===0) {
                    await  this.obtenerSocios()
                }

                //buscamos la persona seleccionada
                let personaSel=this.state.socios.find(socio=>{return socio.value===this.state.creditoSeleccionado.idPersona})

                //buscamos la garantia seleccionada
                let garantiaSel=this.state.tiposGarantia.find(garantia=>{return garantia.value===this.state.creditoSeleccionado.idGarantia})

                this.setState({
                    cargandoNuevo:false,
                    nuevoActivado:false,
                    editarActivado:true,
                    borrarActivado:false,

                    personaSeleccionada:personaSel,
                    tipoGarantiaSeleccionada:garantiaSel,
                    totalPartidas: Intl.NumberFormat('es-ES').format(this.state.creditoSeleccionado.importe),
                    condiciones:this.state.creditoSeleccionado.condiciones,
                    fecha:new Date(this.state.creditoSeleccionado.fecha)
                })
            })
        }
        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.creditoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalBorrarAbierto:true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,   
            })
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid: false,
                creditoSeleccionado:null
            })
        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }

        const columns = [
        {
        dataField: 'nombre',
        text: i18next.t("creditosocio.concedidaa",{ns:"page"})
        },
        {
            dataField: 'garantia',
            text: i18next.t("creditosocio.tipogarantia",{ns:"page"})
            },
            {
                dataField: 'importe',
                text: i18next.t("creditosocio.importe",{ns:"page"})
                },
                {
                    dataField: 'fecha',
                    text: i18next.t("creditosocio.fecha",{ns:"page"}),
                    formatter: dateFormatter
                    },
                    {
                        dataField: 'condiciones',
                        text: i18next.t("creditosocio.condiciones",{ns:"page"})
                        }
        ];

        const escogeFecha = (nuevaFecha) => {
            this.setState({fecha: nuevaFecha})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        
        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }
    
        const cambiarTotal = (ev) => {

            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({totalPartidasValido:true})
            }else{
                this.setState({totalPartidasValido:false})
            }

            this.setState({totalPartidas:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
        
        }
        
        const cambiarCondiciones = (ev) => {
            this.setState({condiciones: ev.currentTarget.value})
            ////console.log(this.state.totalPartidas);
        }

        const handleClick = () => {
            this.setState({ abierto: !this.state.abierto });
        }

        const handleSeleccionarTipo = (value) => {
            this.setState({ tipoGarantiaSeleccionada: value });
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        creditoSeleccionado: row
        });
        }
        return true;
        }

        const handleSeleccionarPersona = (value) => {
            this.setState({ personaSeleccionada:value  });
        }

        const añadirNuevo = async() => {

            if(!this.state.personaSeleccionada){
                return
            }

            if(!this.state.tipoGarantiaSeleccionada){
                return
            }

            if(!this.state.condiciones){
                return
            }

            if(!this.state.totalPartidasValido || !this.state.totalPartidas){
                return
            }

            //mutacion para guardar el credioto
            await this.props.client.mutate({
                mutation:gql`mutation{crearGarantia(VALORES:{ID_PERSONA:${this.state.personaSeleccionada.value},ID_TIPO_GARANTIA:${this.state.tipoGarantiaSeleccionada.value},CANTIDAD:${parseFloat(this.state.totalPartidas.replace(/\./g, '').replace(/\,/g, '.'))},FECHA:"${moment(this.state.fecha).format('YYYY-MM-DD')}",CONDICIONES:"""${this.state.condiciones}"""}){ID_GARANTIA}}`
            }).then(async result=>{
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.cargarGarantias()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })


            cerrarModal()
        }

        const actualizarCredito =async () => {

            if(!this.state.personaSeleccionada && !this.state.creditoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.tipoGarantiaSeleccionada && !this.state.creditoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.condiciones && !this.state.creditoSeleccionado){
                alert(i18next.t("emptyfield",{ns:"global"})+i18next.t("creditosocio.condiciones",{ns:"page"}))
                return
            }

            if(parseInt(this.state.totalPartidas)<=0 && parseInt(this.state.creditoSeleccionado.importe<=0) || !this.state.totalPartidasValido){
                alert(i18next.t("emptyfield",{ns:"global"})+i18next.t("creditosocio.importe",{ns:"page"}))
                return
            }

           
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarGarantia(ID_GARANTIA:${this.state.creditoSeleccionado.id},VALORES:{ID_PERSONA:${this.state.personaSeleccionada?this.state.personaSeleccionada.value:this.state.creditoSeleccionado.idPersona},ID_TIPO_GARANTIA:${this.state.tipoGarantiaSeleccionada?this.state.tipoGarantiaSeleccionada.value:this.state.creditoSeleccionado.idGarantia},CANTIDAD:${parseFloat(this.state.totalPartidas?this.state.totalPartidas.replace(/\./g, '').replace(/\,/g, '.'):this.state.creditoSeleccionado.importe.replace(/\./g, '').replace(/\,/g, '.'))},FECHA:"${this.state.fecha?moment(this.state.fecha).format('YYYY-MM-DD'):this.state.creditoSeleccionado.fecha}",CONDICIONES:"""${this.state.condiciones?this.state.condiciones:this.state.creditoSeleccionado.condiciones}"""}){ID_GARANTIA}}`
            }).then(async result=>{
                alert(i18next.t("updatecorrecto",{ns:"global"}))
                await this.cargarGarantias()

                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

    
            cerrarModal()
        }
        const eliminarCredito = () => {

            this.props.client.mutate({
                mutation:gql`mutation{eliminarGarantia(ID_GARANTIA:${this.state.creditoSeleccionado.id}){ID_GARANTIA}}`
            }).then(async result=>{
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                await this.cargarGarantias()
                this.setState({
                    creditoSeleccionado:null
                })
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            
            cerrarModal()
        };

        const abrirModalTipo=()=>{
            this.setState({
                modalTipoAbierto:true,
                nuevoTipoGarantia:null
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalTipoAbierto: false,
            })
        }

        const cambiarnuevotTipoGarantia=(ev)=>{
            this.setState({nuevoTipoGarantia:ev.currentTarget.value})
        }


        const anadirTipoGarantia=(ev)=>{

            if(!this.state.nuevoTipoGarantia)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.tiposGarantia.find(tipo=>{
                return tipo.label===this.state.nuevoTipoGarantia
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos el modo convocatoria.
            this.props.client.mutate({
                mutation: gql`
                  mutation{crearTipoGarantia(VALORES:{DESCRIPCION:"${this.state.nuevoTipoGarantia}"}){ID_TIPO_GARANTIA}}
                `
            }).then(async result=>{
               
                    await this.cargarTiposGarantia()

                    let tipoSel=this.state.tiposGarantia.find(tipo=>{
                        return tipo.value===result.data.crearTipoGarantia.ID_TIPO_GARANTIA
                    })
                    this.setState({tipoGarantiaSeleccionada:tipoSel})
                    cerrarModal2()

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf-CS">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            
            <div className="pixelAlrededor">
                <div className="tablaSesenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title={i18next.t("creditosocio.title",{ns:"page"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalAbierto}
                            className="dialog-ancho"
                        >
                            {!this.state.cargandoNuevo?
                            <>
                            <div className="pixelAlrededorGrande bp3-card separarBordes">
                                <FormGroup
                                    label={i18next.t("creditosocio.concedidaa",{ns:"page"})+":"}
                                    labelFor="concedida-a"
                                    intent="danger"
                                    helperText={!this.state.personaSeleccionada?i18next.t("selectempty",{ns:"global"})+i18next.t("creditosocio.concedidaa",{ns:"page"}):""}
                                >
                                    <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                                        <Select
                                            options={this.state.socios}
                                            className="pixelAlrededor"
                                            onChange={handleSeleccionarPersona}
                                            value={this.state.personaSeleccionada}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("creditosocio.tipogarantia",{ns:"page"})+":"}
                                    labelFor="tipo-garantia"
                                    intent="danger"
                                    helperText={!this.state.tipoGarantiaSeleccionada?i18next.t("selectempty",{ns:"global"})+i18next.t("creditosocio.tipogarantia",{ns:"page"}):""}
                                >
                                <div style={!this.state.tipoGarantiaSeleccionada?{border:"1px solid red"}:{}}>
                                    <Select
                                        options={this.state.tiposGarantia}
                                        className="pixelAlrededor"
                                        onChange={handleSeleccionarTipo}
                                        value={this.state.tipoGarantiaSeleccionada}

                                    />
                                </div>
                                </FormGroup>
                                <Button 
                                    alignText="right"
                                    icon="add"
                                    className="iconoIzquierda"
                                    onClick={abrirModalTipo}
                                />
                                <FormGroup
                                    label={i18next.t("creditosocio.importe",{ns:"page"})+":"}
                                    labelFor="cuentas-anuales"
                                    intent="danger"
                                    helperText={this.state.totalPartidas<=0?i18next.t("emptyfield",{ns:"global"})+i18next.t("creditosocio.importe",{ns:"page"}):""}

                                >
                                    <InputGroup id="cuentas-anuales" type="text"  className="pixelAlrededor" value={this.state.totalPartidas} fill={true} onChange={cambiarTotal}  intent={!this.state.totalPartidasValido || !this.state.totalPartidas?"danger":"primary"}/>
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("creditosocio.fecha",{ns:"page"})+":"}
                                    labelFor="fecha-concesion"
                                    inline={true}
                                    className="pixelAlrededor" 
                                >
                                    <DateInput minDate={new Date(1900, 1 ,1)}
                                        {...jsDateFormatter} 
                                        onChange={escogeFecha}
                                        value={this.state.fecha}
                                        align={Alignment.RIGHT}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={i18next.t("creditosocio.condiciones",{ns:"page"})+":"}
                                    labelFor="condiciones"
                                    className="pixelAlrededor" 
                                    intent="danger"
                                    helperText={!this.state.condiciones?i18next.t("emptyfield",{ns:"global"})+i18next.t("creditosocio.condiciones",{ns:"page"}):""}
                                >
                                    <TextArea fill={true} value={this.state.condiciones} onChange={cambiarCondiciones} intent={!this.state.condiciones?"danger":"primary"}/>
                                </FormGroup>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                                </Tooltip>
                                <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={this.state.nuevoActivado?añadirNuevo:actualizarCredito}
                                    >
                                        {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                            </div>
                        </div>
                        </>
                        :
                        <Spinner />
                        }
                        </Dialog>
                        
                        <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("creditosocio.questiondel",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarCredito}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("creditosocio.titleadd",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalTipoAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("creditosocio.tipogarantia",{ns:"page"})+":"}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoTipoGarantia?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("creditosocio.tipogarantia",{ns:"page"})}
                        >
                            <InputGroup id="texto-modo-convocatoria" onChange={cambiarnuevotTipoGarantia}  placeholder={i18next.t("placeholder",{ns:"global"})} intent={this.state.nuevoTipoGarantia?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirTipoGarantia}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("creditosocio.header",{ns:"page"})}</Callout>
                        {this.state.cargandoGrid===true?
                            <Cargando/>
                        :
                        <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <>
                                    <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="trash" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado}  onClick={abrirModalBorrar}/>
                                    <Button className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={()=>this.generarDocumento()}/>
                                    </>
                                </ButtonGroup>
                            </Navbar.Group>                            
                        </Navbar>
                        <BootstrapTable
                        keyField='id'
                        data={ this.state.creditos }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }
                    </Card>
                </div>
                
                <div className="tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("creditosocio.ley",{ns:"page"})}</Callout>
                    <br />
                    <Button icon="git-repo" onClick={handleClick}>
                        {i18next.t("creditosocio.articulo",{ns:"page"})}
                    </Button>                    
                    <Collapse isOpen={this.state.abierto}>
                        <Card> 
                            <ol>
                                <li>{i18next.t("creditosocio.info1",{ns:"page"})}</li>
                                <li>{i18next.t("creditosocio.info2",{ns:"page"})}</li>
                            </ol>
                        </Card>
                    </Collapse>
                    </Card>
                </div>
                </div>
    
            </>
        }
        </> 
        )
      
    }
}

export default withTranslation()(withApollo(CreditosSocios))