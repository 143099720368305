/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import { Alignment, TextArea, InputGroup,Slider,Button,Navbar, Callout, FileInput,Tabs, Tab, FormGroup, RadioGroup,Checkbox ,Radio, Card, Elevation, Dialog, Classes, Tree, AnchorButton, Intent, Tooltip, Position, NumericInput } from "@blueprintjs/core"
import Select from "react-select"
import { DateInput } from "@blueprintjs/datetime"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import axios from 'axios';
import moment from "moment";
import { Cargando } from "../../Pantallas/Cargando";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});
class AccionesSegumiento extends React.Component {

    state = {
        loading:true,
        pestaña: "accion-formativa",
        tipoAccion: {
            "formacion": "Formacion",
            "informacion": "Informacion"
        },
        tipoAccionAAplicar: 1,
        areaSeleccionadaPrev:null,
        areaSeleccionada: null,
        departamentoSeleccionadoPrev:null,
        departamentoSeleccionado:null,
        puestoSeleccionado:null,
        puestoSeleccionadoPrev:null,
        empleadoSeleccionado:null,
        empleadoSeleccionadoPrev:null,
        lugarAccion: null,
        observaciones: null,
        dialogArea: false,
        dialogDepartamento: false,
        dialogPuesto: false,
        dialogEmpleado: false,
        dialogAñadirPersona: false,
        dialogSeleccionRiesgo: false,
        tituloFormacion: "",
        numeroHoras: 1,
        cambiarNumeroHorasInformacion: 1,
        modalidadSeleccionada: null,
        canalSeleccionada: null,
        fechaInicio: null,
        fechaFin: null,
        fechaInformacion: null,
        horario: null,
        procedimiento: null,
        requisitos: null,
        objetivos: null,
        contenidos:null,
        modalidades: [

        ],
        canales: [

        ],
        comision: [
        ],
        profesorado: [
        ],
        personasAsistentes:[
            {
                "empleado": "Rafael",
                "fecha": "13/3/2019",
                "comprobante": true
            },
            {
                "empleado": "Gabriel",
                "fecha": "13/3/2019",
                "comprobante": false
            },
            {
                "empleado": "Miguel",
                "fecha": "13/3/2019",
                "comprobante": false
            },
        ],
        arbolAreas:[],
        arbolDepartamentos:[],
        arbolPuestosTrabajo:[],
        arbolEmpleados:[],
        bolFechaInicio:false,
        bolFechaFin:false,
        nombrePersonaAcademica:null,
        nombreProfesorado:null,
        arbolRiesgos:[],
        riesgoSel:null,
        riesgosSeleccionados:[],
        descripcionInfo:null,
        textoInfo:null,
        bolFechaInformacion:null,
        modalComporbanteAbierto:null,
        comprobanteSeleccionado:null,
        empleados:[],
        fechaComprobante:new Date(),
        aprovechamiento:1,
        documentoComprobante:null
    }

    columns = [{
        dataField: 'nombre',
        text: i18next.t("identificacion.name",{ns:"page"})
    },{
        dataField: 'fechaComprobante',
        text: i18next.t("identificacion.fecha",{ns:"page"})
    },{
        dataField: 'nombre',
        text: i18next.t("identificacion.comprobanteadjunto",{ns:"page"}),
        formatter: this.adjuntoFormatter
    }]

    adjuntoFormatter() {
        let rta="NO";
        if (this.state.documentoComprobante) rta="SI";
        return (
            rta
        );
    }

    columnsComision = [{
        dataField: 'name',
        text: i18next.t("identificacion.name",{ns:"page"})
    }]

    columnsRiesgo = [{
        dataField: 'label',
        text: i18next.t("identificacion.name",{ns:"page"})
    }]

    handleOnSelect=(row, isSelect) => {
        if (isSelect) {
            this.setState({
                comisionSeleccionada:row,
            })
        }
        //console.log(row);
        return true; // return true or dont return to approve current select action
    }
    handleOnSelectProfesorado=(row, isSelect) => {
        if (isSelect) {
            this.setState({
                profesoradoSeleccionado:row,
            })
        }
        //console.log(row);
        return true; // return true or dont return to approve current select action
    }
    handleOnSelectRiesgo=(row, isSelect) => {
        if (isSelect) {
            this.setState({
                riesgoSeleccionadoTabla:row,
            })
        }
        //console.log(row);
        return true; // return true or dont return to approve current select action
    }
    handleSelect=(row, isSelect) => {
        if (isSelect) {
            this.setState({
                comprobanteSeleccionado:row,
            })
        }
        //console.log(row);
        return true; // return true or dont return to approve current select action
    }

    cargarArboles=async()=>{


        //arbol de ares
        let areas=[]

        // obtencion de las areas
        await client.query({
            query:gql`{departamentosAreas(FILTROS:{ID_AREA:${this.props.area},PREDEFINIDA:false}){ID_AREA,DESCRIPCION,PREDEFINIDA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

           
            result.data.departamentosAreas.forEach(async(area)=>{
                areas.push({id:area.ID_AREA,value:area.ID_AREA,label:area.DESCRIPCION,tipo:"area"})
            })  
            this.setState({
                arbolAreas: JSON.parse(JSON.stringify(areas))
            })
  
        })
     
        //obtencion de los departamentos
        let index=0
        for(let area of areas){

            await client.query({
                query:gql`{departamentos(FILTROS:{ID_DEPARTAMENTO:${this.props.departamento}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,fetchPolicy:'network-only'
            // eslint-disable-next-line no-loop-func
            }).then(async(result)=>{
    
                let deps=[]
                for(let dep of result.data.departamentos){

                    await deps.push({id:dep.ID_DEPARTAMENTO,label:dep.DESCRIPCION,tipo:"departamento",padre:area.id})

                }
               
                areas[index].childNodes=deps
               
            })
          
            await this.setState({
                arbolDepartamentos: JSON.parse(JSON.stringify(areas))
            })

            
            index++
        }

        let areasPuestos=JSON.parse(JSON.stringify(this.state.arbolDepartamentos))

    
        for(let area of areasPuestos){

            for(let departamento of area.childNodes){

                let puestos=[]
                //obtencion de los puestos de trabajo
                await client
                .query({
                    query: gql` 
                        {puestosTrabajo(CAMPOS:"ID_PUESTO,DESCRIPCION",FILTROS:{ID_DEPARTAMENTO:${departamento.id}}){ID_PUESTO,DESCRIPCION}}
                    `,fetchPolicy:'network-only'
                }).then(async result=>{ 
                    for(let puesto of result.data.puestosTrabajo){
                        puestos.push({
                            id:puesto.ID_PUESTO,
                            value:puesto.ID_PUESTO,
                            label:puesto.DESCRIPCION,
                            tipo:"puesto_trabajo"
                        })
    
                    }
    
                    if(puestos.length>0){
                        departamento.childNodes=puestos
                    }
                
                })
    
            }    
        }
        

        console.log(areasPuestos)

        await this.setState({
            arbolPuestosTrabajo: JSON.parse(JSON.stringify(areasPuestos))
        })


        let areasEmpleado=JSON.parse(JSON.stringify(this.state.arbolDepartamentos))


        for(let areaEmpleado of areasEmpleado){

            for(let departamento of areaEmpleado.childNodes){

                //obtencion de los puestos de trabajo
                let empleadosDep=await client
                .query({
                    query: gql`
                    {departamentosEmpleados(FILTROS:{ID_DEPARTAMENTO:${departamento.id}}){ID_EMPLEADO}}
                    `,fetchPolicy:'network-only'
                }).then(result=>{ 
                    return result.data.departamentosEmpleados
                })

                for(let empleado of empleadosDep){

                    //obtencion del nombre del empleado.
                    let nombre=await client.query({
                        query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}`
                        ,fetchPolicy:'network-only'
                    }).then(async(result)=>{
                        //console.log(result)
                        let persona=result.data.personasFisicas[0]
                        return `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                    }) 
                    empleado.label=nombre
                    empleado.id=empleado.ID_EMPLEADO
                    empleado.value=empleado.ID_EMPLEADO
                    empleado.tipo="empleado"

                }
                if(empleadosDep.length>0){
                    departamento.childNodes=empleadosDep
                }
            }

        }

        await this.setState({
            arbolEmpleados: JSON.parse(JSON.stringify(areasEmpleado))
        })

    }

    cargarModalidadesFormacion=async()=>{

        await client.query({
            query:gql`{modalidadesFormacion{ID_MODALIDAD_FORMACION,DESCRIPCION}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let modalidades=[]
            for(let modalidad of result.data.modalidadesFormacion){
                modalidades.push({
                    value:modalidad.ID_MODALIDAD_FORMACION,
                    label:modalidad.DESCRIPCION
                })
            }
            await this.setState({modalidades:modalidades})
  
        })
        
    }

    cargarCanales=async()=>{

        await client.query({
            query:gql`{canalesInformacion{ID_CANAL_INFORMACION,DESCRIPCION}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let canales=[]
            for(let canal of result.data.canalesInformacion){
                canales.push({
                    value:canal.ID_CANAL_INFORMACION,
                    label:canal.DESCRIPCION
                })
            }
            await this.setState({canales:canales})
  
        })
        
    }

    desplegarArbol=(hijos)=>{
            
        hijos.forEach(hijo=>{
              hijo.isExpanded=true
              hijo.isSelected=false
              if(hijo.childNodes)this.desplegarArbol(hijo.childNodes)
        })  
        this.setState({arbol:hijos})
    
    }

    replegarArbol=(hijos)=>{
              
        hijos.forEach(hijo=>{
            hijo.isExpanded=false
            hijo.isSelected=false
            if(hijo.childNodes)this.replegarArbol(hijo.childNodes)
        })  
        this.setState({arbol:hijos})
    
    }

    //funcion para marcar nodo en el arbol especificado con el path especificado
    marcarArbol=async(arbol,path)=>{


        await this.replegarArbol(arbol)
        switch(path.length){
            case 1:
                arbol[path[0]].isSelected=true
            break;

            case 2:
                arbol[path[0]].isExpanded=true
                arbol[path[0]].childNodes[path[1]].isSelected=true
            break;

            case 3:
                arbol[path[0]].isExpanded=true
                arbol[path[0]].childNodes[path[1]].isExpanded=true
                arbol[path[0]].childNodes[path[1]].childNodes[path[2]].isSelected=true
            break;

            default:
            
            break;
        }


    }

    buscarArea=async(idArea)=>{

        let index=0
        //console.log(this.state.arbolEmpleados)
        for(let area of this.state.arbolEmpleados){
           
            if(area.id===idArea){
                this.marcarArbol(this.state.arbolAreas,[index])
                return area
            }
            index++
        }

    }

    buscarDepartamento=async(idDep)=>{
        let index=0
        let index1=0
        //console.log(this.state.arbolEmpleados)
        for(let area of this.state.arbolDepartamentos){
            index1=0
            for(let dep of area.childNodes){
                    console.log(dep)
                    if(dep.id===idDep){
                        this.marcarArbol(this.state.arbolDepartamentos,[index,index1])
                        return dep
                    }
              
                index1++
            }
            index++
        }

    }

    buscarPuesto=async(idPuesto)=>{

        let index=0
        let index1=0
        let index2=0
        //console.log(this.state.arbolEmpleados)
        for(let area of this.state.arbolPuestosTrabajo){
            index1=0
            for(let dep of area.childNodes){
                index2=0
                //console.log(dep.childNodes)
                if(dep.childNodes){
                    for(let puesto of dep.childNodes){
                        //console.log(empleado)
                        if(puesto.id===idPuesto){
                            this.marcarArbol(this.state.arbolPuestosTrabajo,[index,index1,index2])
                            return puesto
                        }
                        index2++
                    }
                }
              
                index1++
            }
            index++
        }

    }

    buscarEmpleado=async(idEmpleado)=>{

        let index=0
        let index1=0
        let index2=0
        //console.log(this.state.arbolEmpleados)
        for(let area of this.state.arbolEmpleados){
            index1=0
            for(let dep of area.childNodes){
                index2=0
                //console.log(dep.childNodes)
                if(dep.childNodes){
                    for(let empleado of dep.childNodes){
                        //console.log(empleado)
                        if(empleado.id===idEmpleado){
                            this.marcarArbol(this.state.arbolEmpleados,[index,index1,index2])
                            console.log([index,index1,index2])
                            return empleado
                        }
                        index2++
                    }
                }
              
                index1++
            }
            index++
        }

    }

    buscarRiesgo=async(arbol,idRiesgo)=>{
         
        for(let hijo of arbol){
            if(parseInt(hijo.id.split("_").pop())===idRiesgo && hijo.id.includes("riesgo_")){
                let riesgos=this.state.riesgosSeleccionados
                riesgos.push(hijo)
                this.setState({riesgosSeleccionados:riesgos})
                return 
            }else{
                if(hijo.childNodes)this.buscarRiesgo(hijo.childNodes,idRiesgo)
            }
        }

    }

    tieneHijos=(riesgo,arrayRiesgos)=>{
        var arrayHijos=[];
        arrayRiesgos.find((element) =>{
            if(element.ID_RIESGO_PADRE===riesgo.ID_RIESGO){
                this.tieneHijos(element,arrayRiesgos)
                arrayHijos.push({"depth":(element.childNodes?element.childNodes.length:0),id:"riesgo_"+element.ID_RIESGO,"label":element.TITULO,childNodes:(element.childNodes?element.childNodes:null),"descripcion":element.DESCRIPCION,"icon":(element.childNodes?"folder-close":"share")})
            }
            // eslint-disable-next-line array-callback-return
            return 
        });
        if(arrayHijos.length>0)riesgo.childNodes=arrayHijos;
        return riesgo;
    }



    cargarBloques=async ()=>{
        
        //obtencion de los bloques de riesgo
        await client.query({
            query:gql`{bloquesRiesgo(ORDEN:"LETRA,ASC"){ID_BLOQUE,LETRA,TITULO}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{

            let bloques=[]
            for(let bloque of result.data.bloquesRiesgo){        
                
                //obtencion de los riesgos de cada bloque.
                let hijos=[]
                await client.query({
                    query:gql`{complianceRiesgos(FILTROS:{ID_BLOQUE:${bloque.ID_BLOQUE}}){ID_RIESGO,DESCRIPCION,CODIGO_RIESGO,TITULO,ID_RIESGO_PADRE}}`
                    ,fetchPolicy:'network-only'
                }).then((result)=>{
                    let results=JSON.parse(JSON.stringify(result.data.complianceRiesgos))
                    results.forEach((hijo)=>{
                        this.tieneHijos(hijo,JSON.parse(JSON.stringify(result.data.complianceRiesgos)))
                        if(hijo.ID_RIESGO_PADRE===null){
                            hijos.push({"depth":(hijo.childNodes?hijo.childNodes.length:0),"id":"riesgo_"+hijo.ID_RIESGO,"label":hijo.TITULO,"descripcion":hijo.DESCRIPCION,"icon":(hijo.childNodes?"folder-close":"share"),"childNodes":(hijo.childNodes?hijo.childNodes:null)})
                        }
                    })
                    bloques.push({"depth":hijos.length,"id":"bloque_"+bloque.ID_BLOQUE,"label":"Bloque "+bloque.LETRA+" - "+bloque.TITULO,"icon":"folder-close",childNodes:hijos}) 
                })

            }
            this.setState({arbolRiesgos:bloques})

        })
    }


    componentDidMount=async()=>{

        if(this.props.tipo==="formacion"){
            await this.cargarModalidadesFormacion()
        }else{
            await this.cargarCanales()
        }

        if(this.props.datos){

            await this.cargarBloques()


            if(this.props.tipo==="formacion"){
                let modalidadSel=await this.props.datos.F_MODALIDAD?this.state.modalidades.find(modalidad=>{return modalidad.value===this.props.datos.F_MODALIDAD}):null

                await this.setState({
                    lugarAccion:this.props.datos.LUGAR,
                    observaciones:this.props.datos.OBSERVACIONES,
                    tituloFormacion:this.props.datos.F_TITULO,
                    numeroHoras:this.props.datos.F_NUMERO_HORAS,
                    modalidadSeleccionada:modalidadSel,
                    fechaInicio:this.props.datos.F_INICIO?new Date(this.props.datos.F_INICIO):null,
                    bolFechaInicio:this.props.datos.F_INICIO?true:false,
                    fechaFin:this.props.datos.F_FIN?new Date(this.props.datos.F_FIN):null,
                    bolFechaFin:this.props.datos.F_FIN?true:false,
                    horario:this.props.datos.F_HORARIO,
                    procedimiento:this.props.datos.F_PROCEDIMIENTO_EVALUACION,
                    requisitos:this.props.datos.F_REQUISITOS_ACCESO,
                    objetivos:this.props.datos.F_OBJETIVOS,
                    contenidos:this.props.datos.F_CONTENIDOS,
                    comision:this.props.datos.F_COMISION_ACADEMICA?this.props.datos.F_COMISION_ACADEMICA.split("|").map(com=>{return {"name":com}}):[],
                    profesorado:this.props.datos.F_PROFESORADO?this.props.datos.F_PROFESORADO.split("|").map(prof=>{return {"name":prof}}):[],
                })

                if(this.props.datos.comprobante){
                    this.setState({
                        fechaComprobante:this.props.datos.comprobante.FECHA_CONFIRMACION?new Date(this.props.datos.comprobante.FECHA_CONFIRMACION):new Date(),
                        aprovechamiento:this.props.datos.comprobante.APROVECHAMIENTO?this.props.datos.comprobante.APROVECHAMIENTO:1,
                        documentoComprobante:this.props.datos.documentoComprobante?this.props.datos.documentoComprobante:null
                    })
                }

            }else{

                let canalSel=await this.props.datos.I_CANAL?this.state.canales.find(canal=>{return canal.value===this.props.datos.I_CANAL}):null
                await this.setState({
                    lugarAccion:this.props.datos.LUGAR,
                    observaciones:this.props.datos.OBSERVACIONES,
                    descripcionInfo:this.props.datos.I_DESCRIPCION,
                    canalSeleccionada:canalSel,
                    bolFechaInformacion:this.props.datos.I_FECHA?true:false,
                    fechaInformacion:this.props.datos.I_FECHA?new Date(this.props.datos.I_FECHA):null,
                    cambiarNumeroHorasInformacion:this.props.datos.I_DURACION?parseInt(this.props.datos.I_DURACION):null,
                    textoInfo:this.props.datos.I_TEXTO?this.props.datos.I_TEXTO:null,
                })

                if(this.props.datos.comprobante){
                    this.setState({
                        fechaComprobante:this.props.datos.comprobante.FECHA_CONFIRMACION?new Date(this.props.datos.comprobante.FECHA_CONFIRMACION):new Date(),
                        documentoComprobante:this.props.datos.documentoComprobante?this.props.datos.documentoComprobante:null
                    })
                }
            }
            

            if(this.props.datos.ID_AREA){
                let area={"label":this.props.datos.AREA.DESCRIPCION}
                await this.setState({areaSeleccionada:area,tipoAccionAAplicar:1})
            }else if(this.props.datos.ID_DEPARTAMENTO){
                let dep={"label":this.props.datos.DEPARTAMENTO.DESCRIPCION}
                await this.setState({departamentoSeleccionado:dep,tipoAccionAAplicar:2})
            }else if(this.props.datos.ID_PUESTO_TRABAJO){
                let puesto={"label":this.props.datos.PUESTO_TRABAJO.DESCRIPCION}
                await this.setState({puestoSeleccionado:puesto,tipoAccionAAplicar:3})
            }else if(this.props.datos.ID_EMPLEADO){

                let nombre=await client
                .query({
                    query: gql`
                    {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${this.props.datos.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}
                    `
                    ,fetchPolicy:'network-only'
                }).then(result=>{ 
                    let empleado=result.data.personasFisicas[0]
                    return  `${empleado.APELLIDO2?empleado.APELLIDO2:""} ${empleado.APELLIDO1},${empleado.NOMBRE}`
                })
                
    
                await this.setState({
                    empleados:[{
                        "ID_EMPLEADO":this.props.datos.ID_EMPLEADO,
                        "nombre":nombre,
                        "fechaComprobante":this.props.datos.comprobante && this.props.datos.comprobante.FECHA_CONFIRMACION ?moment(this.props.datos.comprobante.FECHA_CONFIRMACION).format("DD-MM-YYYY"):null
                    }],
                    empleadoSeleccionado:{"label":nombre},
                    tipoAccionAAplicar:4
                })

            }

          
            
            await console.log(this.state.empleados)
            if(this.props.datos.riesgos){
                for(let riesgo of this.props.datos.riesgos){
                    this.buscarRiesgo(this.state.arbolRiesgos,parseInt(riesgo.RIESGO.ID_RIESGO))
                }
            }

        }else{
            await this.cargarArboles()
        }

        this.setState({loading:false});

    }

    Accion = () => {
        return (
            <>

                <FormGroup
                    label={i18next.t("identificacion.tipoaccionformativa",{ns:"page"})+":"}
                    labelFor="tipo-accion"
                    inline={true}
                >
                    <InputGroup disabled value={this.props.tipo} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.lugaraccionformativa",{ns:"page"})+":"}
                    labelFor="lugar-accion"
                    fill={true}
                    intent="danger"
                    helperText={!this.state.lugarAccion?i18next.t("identificacion.emptylugaraccionformativa",{ns:"page"}):""}
                >
                    <InputGroup ref={(input) => { this.inputLugarAccion= input; }} onChange={this.handleLugarAccion} intent={!this.state.lugarAccion?"danger":"primary"} value={this.state.lugarAccion} />
                </FormGroup>
                <RadioGroup
                    label={i18next.t("identificacion.aplicaraccionformativa",{ns:"page"})+":"}
                    onChange={this.handleAccionChange}
                    selectedValue={this.state.tipoAccionAAplicar}
                >
                    <Radio value={1} label={i18next.t("identificacion.area",{ns:"page"})} disabled={this.props.datos? true : false } /><InputGroup readOnly={true} defaultValue={this.state.areaSeleccionada?this.state.areaSeleccionada.label:""}  disabled={this.state.tipoAccionAAplicar === 1? false: true} rightElement={<Button icon="search" disabled={this.state.tipoAccionAAplicar === 1 && !this.props.datos ? false: true} onClick={this.abrirSeleccionarArea} />} />
                    <Radio value={2} label={i18next.t("identificacion.departamento",{ns:"page"})} disabled={this.props.datos? true : false } /><InputGroup readOnly={true} defaultValue={this.state.departamentoSeleccionado?this.state.departamentoSeleccionado.label:""} disabled={this.state.tipoAccionAAplicar === 2? false: true} rightElement={<Button icon="search" disabled={this.state.tipoAccionAAplicar === 2  && !this.props.datos ? false: true} onClick={this.abrirSeleccionarDepartamento} />} />
                    <Radio value={3} label={i18next.t("identificacion.puestotrabajo",{ns:"page"})} disabled={this.props.datos? true : false } /><InputGroup readOnly={true} defaultValue={this.state.puestoSeleccionado?this.state.puestoSeleccionado.label:""} disabled={this.state.tipoAccionAAplicar === 3? false: true} rightElement={<Button icon="search" disabled={this.state.tipoAccionAAplicar === 3  && !this.props.datos ? false: true} onClick={this.abrirSeleccionarPuesto} />} />
                    <Radio value={4} label={i18next.t("identificacion.empleado",{ns:"page"})} disabled={this.props.datos? true : false } /><InputGroup readOnly={true} defaultValue={this.state.empleadoSeleccionado?this.state.empleadoSeleccionado.label:""} disabled={this.state.tipoAccionAAplicar === 4? false: true} rightElement={<Button icon="search" disabled={this.state.tipoAccionAAplicar === 4  && !this.props.datos ? false: true} onClick={this.abrirSeleccionarEmpleado} />} />
                </RadioGroup>
                <FormGroup
                    label={const_table.capitalize(i18next.t("identificacion.observaciones",{ns:"page"}).toLowerCase())}
                    labelFor="Observaciones"
                >
                    <TextArea fill={true} onChange={this.handleObservaciones} value={this.state.observaciones} />
                </FormGroup>
            </>
        )
    }
    
    handleEnabledChangeFInicio=()=>{
        let val=!this.state.bolFechaInicio
        this.setState({bolFechaInicio:val})
        if(val){
            this.setState({fechaInicio:new Date()})
        }else{
            this.setState({fechaInicio:null})
        }
    }

    handleEnabledChangeFFin=()=>{
        let val=!this.state.bolFechaFin
        this.setState({bolFechaFin:val})
        if(val){
            this.setState({fechaFin:new Date()})
        }else{
            this.setState({fechaFin:null})
        }
    }

    handleEnabledChangeFInformacion=()=>{
        let val=!this.state.bolFechaInformacion
        this.setState({bolFechaInformacion:val})
        if(val){
            this.setState({fechaInformacion:new Date()})
        }else{
            this.setState({fechaInformacion:null})
        }
    }

    Formacion = () => {
        return (
            <>
                <FormGroup
                    label={i18next.t("edicionriesgo.titulo",{ns:"page"})+":"}
                    labelFor="titulo"
                    fill={true}
                    intent="danger"
                    helperText={!this.state.tituloFormacion?i18next.t("identificacion.emptytitulo",{ns:"page"}):""}
                >
                    <InputGroup value={this.state.tituloFormacion} ref={(input) => { this.inputTiutloFormacion= input; }} intent={!this.state.tituloFormacion?"danger":"primary"} onChange={this.cambiarTituloFormacion} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.numerohoras",{ns:"page"})+":"}
                    labelFor="horas"
                    fill={true}
                >
                    <NumericInput onValueChange={this.cambiarNumeroHoras} min={1} value={this.state.numeroHoras} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.modalidad",{ns:"page"})+":"}
                    labelFor="modalidad"
                    fill={true}
                >
                    <Select
                        options={this.state.modalidades}
                        className="pixelAlrededor"
                        onChange={this.seleccionPersona}
                        value={this.state.modalidadSeleccionada}
                    />
                </FormGroup>
                <Checkbox checked={this.state.bolFechaInicio} onChange={this.handleEnabledChangeFInicio} />
                <FormGroup
                    label={i18next.t("identificacion.inicio",{ns:"page"})+":"}
                    labelFor="inicio"
                    fill={true}
                >
                    <DateInput minDate={new Date(1900, 1 ,1)}
                        {...this.jsDateFormatter} 
                        onChange={this.escogeFechaInicio}
                        value={this.state.fechaInicio}
                        disabled={!this.state.bolFechaInicio}
                    />
                </FormGroup>
                <Checkbox checked={this.state.bolFechaFin} onChange={this.handleEnabledChangeFFin} />
                <FormGroup
                    label={i18next.t("identificacion.fin",{ns:"page"})+":"}
                    labelFor="fin"
                    fill={true}
                >
                    <DateInput minDate={new Date(1900, 1 ,1)}
                        {...this.jsDateFormatter} 
                        onChange={this.escogeFechaFin}
                        value={this.state.fechaFin}
                        disabled={!this.state.bolFechaFin}
                    />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.horario",{ns:"page"})+":"}
                    labelFor="horario"
                    fill={true}
                >
                    <InputGroup value={this.state.horario} onChange={this.cambiarHorario} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.procedimientoevaluacion",{ns:"page"})+":"}
                    labelFor="procedimiento-evaluacion"
                    fill={true}
                >
                    <InputGroup value={this.state.procedimiento} onChange={this.cambiarProcedimiento} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.requisitos",{ns:"page"})+":"}
                    labelFor="requisitos"
                    fill={true}
                >
                    <InputGroup value={this.state.requisitos} onChange={this.cambiarRequisitos} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.objetivos",{ns:"page"})+":"}
                    labelFor="objetivos"
                    fill={true}
                >
                    <InputGroup value={this.state.objetivos} onChange={this.cambiarObjetivos} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.contenidos",{ns:"page"})+":"}
                    labelFor="contenidos"
                    fill={true}
                >
                    <InputGroup value={this.state.contenidos} onChange={this.cambiarContenidos} />
                </FormGroup>
                <div>
                    <div className="tablaMitadIzquierda pixelAlrededor">
                        <Callout className="callout-centrado bp3-intent-danger">Comisión académica</Callout>
                        <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                                <Button 
                                    alignText="right"
                                    icon="floppy-disk"
                                    text={i18next.t("identificacion.addpersona",{ns:"page"})}
                                    disabled={this.props.editable===false || this.props.deshabilitado===true?true:false}
                                    intent="danger"
                                    onClick={this.abrirModalComision}
                                    className="iconoDerecha bp3-minimal"
                                />
                                <Button 
                                    alignText="right"
                                    icon="cross"
                                    intent="danger"
                                    text={i18next.t("identificacion.delpersona",{ns:"page"})}
                                    disabled={this.props.editable===false || this.props.deshabilitado===true?true:false}
                                    onClick={this.borrarComision}
                                    className="iconoDerecha bp3-minimal"
                                />
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='id'
                            data={ this.state.comision }
                            columns={ this.columnsComision }
                            selectRow={ const_table.selectRow(this.handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            defaultSorted={ const_table.defaultSorted('nombre','asc') }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    </div>
                    <div className="tablaMitadDerecha pixelAlrededor">
                        <Callout className="callout-centrado bp3-intent-danger">{i18next.t("identificacion.profesorado",{ns:"page"})}</Callout>
                        <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                                <Button 
                                    alignText="right"
                                    icon="floppy-disk"
                                    text={i18next.t("identificacion.addpersona",{ns:"page"})}
                                    disabled={this.props.editable===false || this.props.deshabilitado===true?true:false}
                                    intent="danger"
                                    onClick={this.abrirModalProfesorado}
                                    className="iconoDerecha bp3-minimal"
                                />
                                <Button 
                                    alignText="right"
                                    icon="cross"
                                    intent="danger"
                                    disabled={this.props.editable===false || this.props.deshabilitado===true?true:false}
                                    text={i18next.t("identificacion.delpersona",{ns:"page"})}
                                    onClick={this.borrarProfesorado}
                                    className="iconoDerecha bp3-minimal"
                                />
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='id'
                            data={ this.state.profesorado }
                            columns={ this.columnsComision }
                            selectRow={ const_table.selectRow(this.handleOnSelectProfesorado) }
                            pagination={ paginationFactory(const_table.options) }
                            defaultSorted={ const_table.defaultSorted('nombre','asc') }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        )
    }

    cambiarDescripcionInfo=(e)=>{
        this.setState({descripcionInfo: e.target.value})
    }

    cambiarTextoInfo=(e)=>{
        this.setState({textoInfo: e.target.value})
    }
    
    Informacion = () => {
        return (
            <>
                <FormGroup
                    label={i18next.t("identificacion.descripcion",{ns:"page"})+":"}
                    labelFor="descripcion"
                    intent="danger"
                    helperText={!this.state.descripcionInfo?i18next.t("identificacion.emptydescripcion",{ns:"page"}):""}
                    fill={true}
                >
                    <InputGroup ref={(input) => { this.inputDescInfo= input; }} value={this.state.descripcionInfo} intent={!this.state.descripcionInfo?"danger":"primary"} onChange={this.cambiarDescripcionInfo} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.canal",{ns:"page"})+":"}
                    labelFor="canal"
                    fill={true}
                >
                    <Select
                        options={this.state.canales}
                        className="pixelAlrededor"
                        onChange={this.seleccionCanal}
                        value={this.state.canalSeleccionada}
                    />
                </FormGroup>
                <Checkbox checked={this.state.bolFechaInformacion} onChange={this.handleEnabledChangeFInformacion} />
                <FormGroup
                    label={i18next.t("identificacion.fecha",{ns:"page"})+":"}
                    labelFor="fecha"
                    fill={true}
                >
                    <DateInput minDate={new Date(1900, 1 ,1)}
                        {...this.jsDateFormatter} 
                        onChange={this.escogeFechaInformacion}
                        disabled={!this.state.bolFechaInformacion}
                        value={this.state.fechaInformacion}
                    />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.numerohoras",{ns:"page"})+":"}
                    labelFor="numerohoras"
                    fill={true}
                >
                    <NumericInput onValueChange={this.cambiarNumeroHorasInformacion} min={1} value={this.state.cambiarNumeroHorasInformacion} />
                </FormGroup>
                <FormGroup
                    label={i18next.t("identificacion.texto",{ns:"page"})+":"}
                    labelFor="texto"
                    fill={true}
                >
                    <TextArea fill={true} onChange={this.cambiarTextoInfo} value={this.state.textoInfo} />
                </FormGroup>
            </>
        )
    }

    handleCambiarNombre=(e)=>{
        this.setState({nombre: e.target.value})
    }

    handleCambiarDescripcion=(e)=>{
        this.setState({descripcion: e.target.value})
    }
    
    archivoSubido=(ev)=>{           

        let extension=ev.target.files[0].name.split('.').pop();
        this.setState({archivo:ev.target.files[0]})
        this.setState({tipoArchivo:ev.target.files[0].type})
        this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
      
    }

    crearComprobante=async()=>{
        
        let accion=await client
        .query({
            query: gql`
            {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${this.props.datos.ID_EMPLEADO},ID_ACCION:${this.props.datos.ID_ACCION}}){ID_ACCION_PERSONA}}
            `
            ,fetchPolicy:'network-only'
        }).then(result=>{ 
            if(result.data.formacionInformacionPersonas.length>0)return result.data.formacionInformacionPersonas[0].ID_ACCION_PERSONA
            return 0
        })

        if(accion===0){

            await client.mutate({
                mutation:gql`mutation{crearFormacionInformacionPersona(VALORES:{ID_ACCION:${this.props.datos.ID_ACCION},ID_PERSONA:${this.props.datos.ID_EMPLEADO}}){ID_ACCION_PERSONA}}`
            }).then(result=>{
                accion=result.data.crearFormacionInformacionPersona.ID_ACCION_PERSONA
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }

        if(this.state.archivo){

            const api = axios.create({
                withCredentials: true
            });

            if(!this.state.nombre)return
            if(!this.state.descripcion)return
    
            var formData = new FormData();
            formData.append("files",this.state.archivo);
            await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:${this.props.tipo==="formacion"?16:15},NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:10,ID_ASOCIADO:${accion},DESCRIPCION:"""${this.state.descripcion}"""}){ID_ADJUNTO,DATOS}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(res=>{
                console.log(res)
                this.setState({documentoComprobante:res.data.data.crearAdjunto})

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }


        alert(i18next.t("insertcorrecto",{ns:"global"}))
        this.setState({modalComporbanteAbierto:false})
    }
    
    actualizarComprobante=async()=>{

        let accion=await client
        .query({
            query: gql`
            {formacionInformacionPersonas(FILTROS:{ID_PERSONA:${this.props.datos.ID_EMPLEADO},ID_ACCION:${this.props.datos.ID_ACCION}}){ID_ACCION_PERSONA}}
            `
            ,fetchPolicy:'network-only'
        }).then(result=>{ 
            if(result.data.formacionInformacionPersonas.length>0)return result.data.formacionInformacionPersonas[0].ID_ACCION_PERSONA
            return 0
        })

        if(accion===0){

            await client.mutate({
                mutation:gql`mutation{crearFormacionInformacionPersona(VALORES:{ID_ACCION:${this.props.datos.ID_ACCION},ID_PERSONA:${this.props.datos.ID_EMPLEADO}}){ID_ACCION_PERSONA}}`
            }).then(result=>{
                accion=result.data.crearFormacionInformacionPersona.ID_ACCION_PERSONA
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }

        if(this.props.tipo==="formacion"){
            
            await client.mutate({
                mutation:gql`mutation{actualizarFormacionInformacionPersona(ID_ACCION_PERSONA:${accion},VALORES:{FECHA_CONFIRMACION:"${moment(this.state.fechaComprobante).format("YYYY-MM-DD")}",APROVECHAMIENTO:${this.state.aprovechamiento}}){ID_ACCION_PERSONA}}`
            }).then(result=>{
                accion=result.data.actualizarFormacionInformacionPersona.ID_ACCION_PERSONA
                this.state.empleados[0].fechaComprobante=moment(this.state.fechaComprobante).format("DD-MM-YYYY")
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })

        }else{

            await client.mutate({
                mutation:gql`mutation{actualizarFormacionInformacionPersona(ID_ACCION_PERSONA:${accion},VALORES:{FECHA_CONFIRMACION:"${moment(this.state.fechaComprobante).format("YYYY-MM-DD")}"}){ID_ACCION_PERSONA}}`
            }).then(result=>{
                accion=result.data.actualizarFormacionInformacionPersona.ID_ACCION_PERSONA
                this.state.empleados[0].fechaComprobante=moment(this.state.fechaComprobante).format("DD-MM-YYYY")
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
                return
            })
        }
       

        alert(i18next.t("updatecorrecto",{ns:"global"}))
        this.setState({modalFechaComprobante:false})
    }

    handleSliderAprovechamiento = (value) => {
        this.setState({ aprovechamiento: value })
    }

    abrirDocumentoComprobante=()=>{
        
        let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.documentoComprobante.DATOS.replace(".",""), '_blank')
        win.focus()

    }

    Personas = () => {
        return (
            <>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={()=>{this.setState({modalFechaComprobante:false})}}
                    title={i18next.t("identificacion.comprobante",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalFechaComprobante}
                >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label={i18next.t("identificacion.fechacomprobante",{ns:"page"})+":"}
                        labelFor="informacion"
                    >
                        <DateInput minDate={new Date(1900, 1 ,1)}
                            {...this.jsDateFormatter} 
                            onChange={this.escogeFechaComprobante}
                            value={this.state.fechaComprobante}
                        />
                    </FormGroup>
                    {this.props.tipo==="formacion"?
                        <FormGroup
                        label={i18next.t("identificacion.aprovechamiento",{ns:"page"})+":"}
                        labelFor="aprovechamiento-interes"
                        >
                            <Slider 
                                initialValue={this.state.riesgoSeleccionado}
                                min={1}
                                max={5}
                                onChange={this.handleSliderAprovechamiento}
                                onRelease={this.handleSliderAprovechamiento}
                                value={this.state.aprovechamiento}
                            />
                        </FormGroup>
                    :<></>}
                    
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={this.actualizarComprobante}>
                        {i18next.t("aceptar",{ns:"global"})}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({modalFechaComprobante:false})}}>
                        {i18next.t("close",{ns:"global"})}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalComporbanteAbierto:false})}}
                    title={i18next.t("identificacion.comprobantes",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalComporbanteAbierto}
                    className="dialog-ancho"
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <div className="pixelAlrededor">
                    </div>
                    <div className="pixelAlrededor">
                        <FormGroup
                            label={i18next.t("identificacion.adjunto",{ns:"page"})}
                            labelFor="informacion"
                            intent="danger"
                            className="pixelAlrededor"
                            helperText={this.state.archivo?"":i18next.t("identificacion.selectadjunto",{ns:"page"})}
                        >
                            <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={this.archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red", padding: "15px"}} className="pixelAlrededor" fill={true} />
                        </FormGroup>
                    </div>
                    
                    <div className="pixelAlrededor">
                        <FormGroup
                                label={i18next.t("identificacion.documento",{ns:"page"})}
                                labelFor="informacion"
                                intent="danger"
                                helperText={this.state.nombre?"":i18next.t("identificacion.emptydocumento",{ns:"page"})}
                        >
                            <InputGroup id="nombre-documento" onChange={this.handleCambiarNombre}  value={this.state.nombre} placeholder="Escriba aqui..." intent={this.state.nombre?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className="pixelAlrededor">
                        <FormGroup
                                label={i18next.t("identificacion.descripcion",{ns:"page"})}
                                labelFor="descripcion"
                                intent="danger"
                                helperText={this.state.descripcion?"":i18next.t("identificacion.emptydescripcion",{ns:"page"})}
                        >
                            <TextArea
                                large={true}
                                fill={true}
                                intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                                onChange={this.handleCambiarDescripcion}
                                value={this.state.descripcion}
                            />                    
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={()=>{this.setState({modalComporbanteAbierto:false})}}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.crearComprobante}
                            >
                               {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Card>
                </Dialog>

                <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Tooltip content={i18next.t("identificacion.editcomprobante",{ns:"page"})} position={Position.TOP}>
                        <Button 
                            alignText="right"
                            icon="edit"
                            intent="danger"
                            disabled={!this.state.comprobanteSeleccionado || this.props.editable===false || this.props.deshabilitado===true?true:false}
                            onClick={()=>{
                                this.setState({modalFechaComprobante:true
                            })}}
                            className="iconoDerecha bp3-minimal"
                        />
                    </Tooltip>
                    <Navbar.Divider />
                    <Tooltip content={i18next.t("identificacion.adjuntarcomprobante",{ns:"page"})} position={Position.TOP}>
                        <Button 
                            alignText="right"
                            icon="paperclip"
                            intent="danger"
                            disabled={!this.state.comprobanteSeleccionado || this.state.documentoComprobante || this.props.editable===false ||this.props.deshabilitado===true ?true:false}
                            onClick={()=>{
                                this.setState({modalComporbanteAbierto:true})
                            }}
                            className="iconoDerecha bp3-minimal"
                        />
                    </Tooltip>
                    <Tooltip content={i18next.t("identificacion.vercomprobante",{ns:"page"})} position={Position.TOP}>
                        <Button 
                            alignText="right"
                            icon="folder-shared-open"
                            intent="danger"
                            disabled={!this.state.documentoComprobante}
                            onClick={this.abrirDocumentoComprobante}
                            className="iconoDerecha bp3-minimal"
                        />
                    </Tooltip>
                    </Navbar.Group>
                </Navbar>
                <BootstrapTable
                    keyField='id'
                    data={ this.state.empleados }
                    columns={ this.columns }
                    selectRow={ const_table.selectRow(this.handleSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    defaultSorted={ const_table.defaultSorted('nombre','asc') }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                />
            </>
        )
    }

    handleNodeClickRiesgos = (nodeData, _nodePath, e) => {

        
        if(this.state.riesgosSel){
            let riesgoSel=this.state.riesgosSel
            riesgoSel.isSelected=false
        }


        //si es un riesgo...
        if(nodeData.id.includes("riesgo_")){    
            this.setState({riesgosSel:nodeData})
            nodeData.isSelected=true
        } else {
            this.setState({riesgosSel:null})
        }
    }

    seleccionarRiesgo = () => {
         
        if(!this.state.riesgosSel){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }

        //agregamos el riesgo a la tabla.
        let riesgos=this.state.riesgosSeleccionados
        //comprobamos que no este ya seleccionado.
        let existe=this.state.riesgosSeleccionados.find(ries=>{return ries.id.split("_").pop()===this.state.riesgosSel.id.split("_").pop()})
        if(existe){
            alert(i18next.t("yaexiste",{ns:"global"}))
            return
        }
        riesgos.push(this.state.riesgosSel)
        this.setState({
            riesgosSeleccionados:riesgos,
            riesgoSeleccionadoTabla:this.state.riesgosSel
        })

        this.setState({modalRiesgos:false})
    }

    eliminarRiesgoTabla = () => {
         
        if(!this.state.riesgoSeleccionadoTabla){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }

        let riesgos=this.state.riesgosSeleccionados
        riesgos=riesgos.filter(riesgo=>{return riesgo.id!==this.state.riesgoSeleccionadoTabla.id})
        this.setState({
            riesgosSeleccionados:riesgos,
            riesgoSeleccionadoTabla:null
        })
    }



    Riesgos = () => {
        return (
            <>
                 <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={()=>{this.setState({modalRiesgos:false})}}
                    title={i18next.t("identificacion.escogerriesgo",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalRiesgos}
                >
                <div className={Classes.DIALOG_BODY}>
                    <div>
                        <Tooltip content={i18next.t("tree.replegar",{ns:"global"})} position="auto" ><Button icon="exclude-row" onClick={()=>this.replegarArbol(this.state.arbolRiesgos)}/></Tooltip>
                        <Tooltip content={i18next.t("tree.desplegar",{ns:"global"})} position="auto" ><Button icon="add-column-right" onClick={()=>this.desplegarArbol(this.state.arbolRiesgos)}/></Tooltip>
                    </div>
                    <Tree
                        contents={this.state.arbolRiesgos}
                        onNodeDoubleClick={this.handleNodeClickRiesgos}
                        onNodeCollapse={this.handleNodeCollapse}
                        onNodeExpand={this.handleNodeExpand}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={this.seleccionarRiesgo}>
                        {i18next.t("identificacion.escogerriesgo",{ns:"page"})}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({modalRiesgos:false})}}>
                        {i18next.t("close",{ns:"global"})}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                    <Tooltip content={i18next.t("identificacion.selectriesgo",{ns:"page"})} position={Position.TOP}>
                        <Button 
                            alignText="right"
                            icon="add"
                            intent="danger"
                            disabled={this.props.editable===false || this.props.deshabilitado===true?true:false}
                            onClick={() => {
                                this.replegarArbol(this.state.arbolRiesgos)
                                this.setState({modalRiesgos: true})
                            }}
                            className="iconoDerecha bp3-minimal"
                        />
                    </Tooltip>
                    <Tooltip content={i18next.t("identificacion.delriesgo",{ns:"page"})} position={Position.TOP}>
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            disabled={this.props.editable===false || this.props.deshabilitado===true?true:false}
                            onClick={this.eliminarRiesgoTabla}
                            className="iconoDerecha bp3-minimal"
                        />
                    </Tooltip>
                    </Navbar.Group>
                </Navbar>
                {this.state.riesgosSeleccionados.length>0?
                    <BootstrapTable
                        keyField='id'
                        data={ this.state.riesgosSeleccionados }
                        columns={ this.columnsRiesgo }
                        selectRow={ const_table.selectRow(this.handleOnSelectRiesgo) }
                        pagination={ paginationFactory(const_table.options) }
                        defaultSorted={ const_table.defaultSorted('nombre','asc') }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    :
                    <p>{i18next.t("identificacion.debeaddriesgo",{ns:"page"})}</p>
                }
            </>
        )
    }

    escogeFechaInicio = (nuevaFecha) => {
        this.setState({ fechaInicio: nuevaFecha })
    }

    escogeFechaFin = (nuevaFecha) => {
        this.setState({ fechaFin: nuevaFecha })
    }

    escogeFechaInformacion = (nuevaFecha) => {
        this.setState({ fechaInformacion: nuevaFecha })
    }

    escogeFechaComprobante = (nuevaFecha) => {
        this.setState({ fechaComprobante: nuevaFecha })
    }

    seleccionPersona  = (value) => { 
        this.setState({modalidadSeleccionada: value})
    }

    seleccionCanal  = (value) => { 
        this.setState({canalSeleccionada: value})
    }
    
    handleAccionChange = (event) => {
        this.setState({
            tipoAccionAAplicar: parseInt(event.currentTarget.value),
            areaSeleccionada:null,
            areaSeleccionadaPrev:null,
            departamentoSeleccionado:null,
            departamentoSeleccionadoPrev:null,
            puestoSeleccionado:null,
            puestoSeleccionadoPrev:null,
            empleadoSeleccionado:null,
            empleadoSeleccionadoPrev:null
        })
     
    }
    
    handleLugarAccion = (e) => {
        this.setState({lugarAccion: e.target.value})
    }
    
    handleObservaciones = (e) => {
        this.setState({observaciones: e.target.value})
    }
    
    handleTexto = (e) => {
        this.setState({texto: e.target.value})
    }
    
    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });

    abrirSeleccionarArea = async() => {
        await this.replegarArbol(this.state.arbolAreas)
        if(this.state.areaSeleccionada)await this.buscarArea(this.state.areaSeleccionada.id)
        await this.setState({ dialogArea: true })
    }

    abrirSeleccionarDepartamento = async() => {
        await this.replegarArbol(this.state.arbolDepartamentos)
        if(this.state.departamentoSeleccionado){
            await this.buscarDepartamento(this.state.departamentoSeleccionado.id)
        }
        await this.setState({ dialogDepartamento: true })
    }

    abrirSeleccionarPuesto =async () => {
        await this.replegarArbol(this.state.arbolPuestosTrabajo)
        if(this.state.puestoSeleccionado)await this.buscarPuesto(this.state.puestoSeleccionado.id)
        await this.setState({ dialogPuesto: true })
    }

    abrirSeleccionarEmpleado = async() => {
        await this.replegarArbol(this.state.arbolEmpleados)
        if(this.state.empleadoSeleccionado)await this.buscarEmpleado(this.state.empleadoSeleccionado.id)
        await this.setState({ dialogEmpleado: true })
    }

    añadirNuevo = () => {
        this.setState({
            areaSeleccionada:this.state.areaSeleccionadaPrev,
            departamentoSeleccionado:this.state.departamentoSeleccionadoPrev,
            puestoSeleccionado:this.state.puestoSeleccionadoPrev,
            empleadoSeleccionado:this.state.empleadoSeleccionadoPrev,
            dialogArea:false,
            dialogDepartamento:false,
            dialogPuesto:false,
            dialogEmpleado:false
        })
    }

    cambiarTituloFormacion = (e) => {
        this.setState({ tituloFormacion: e.target.value })
    }

    cambiarHorario = (e) => {
        this.setState({ horario: e.target.value })
    }

    cambiarProcedimiento = (e) => {
        this.setState({ procedimiento: e.target.value })
    }

    cambiarRequisitos = (e) => {
        this.setState({ requisitos: e.target.value })
    }

    cambiarObjetivos = (e) => {
        this.setState({ objetivos: e.target.value })
    }

    cambiarContenidos = (e) => {
        this.setState({ contenidos: e.target.value })
    }

    cambiarNumeroHoras = (e) => {
        this.setState({numeroHoras: e})
    }

    cambiarNumeroHorasInformacion = (e) => {
        this.setState({cambiarNumeroHorasInformacion: e})
    }

    cerrarModal = () => {
        this.setState({
            dialogArea: false,
            dialogDepartamento: false,
            dialogPuesto: false,
            dialogEmpleado: false,
            dialogSeleccionRiesgo: false,
        })
    }

    abrirModalComision = () => {
        this.setState({
            modalPersonaAcademica:true,
            nombrePersonaAcademica:null
        })
    }

    abrirModalProfesorado = () => {
        this.setState({
            modalPersonaProfesorado:true,
            nombreProfesorado:null
        })
    }

    borrarComision = () => {
        if(!this.state.comisionSeleccionada){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        let comisiones=this.state.comision
        comisiones=comisiones.filter(comision=>{return comision.name!==this.state.comisionSeleccionada.name})
        this.setState({comision:comisiones})
    }

    borrarProfesorado = () => {
        if(!this.state.profesoradoSeleccionado){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        let profesorado=this.state.profesorado
        profesorado=profesorado.filter(prof=>{return prof.name!==this.state.profesoradoSeleccionado.name})
        this.setState({profesorado:profesorado})
    }

    jsDateFormatter = {
        // note that the native implementation of Date functions differs between browsers
        formatDate: date => moment(date).format("DD/MM/YYYY"),
        parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
        placeholder: "DD/MM/YYYY"
    };

    handleNodeCollapse = (nodeData) => {
        nodeData.isExpanded = false;
        this.setState(this.state);
    }

    handleNodeExpand = (nodeData) => {
        nodeData.isExpanded = true;
        this.setState(this.state);
    }

    handleNodeClickAreas = (nodeData, _nodePath, e) => {
        
        if(this.state.areaSeleccionadaPrev)this.state.areaSeleccionadaPrev.isSelected=false
        nodeData.isSelected=true
        this.setState({
            areaSeleccionadaPrev:nodeData
        })
   
    }

    handleNodeClickDepartamento = (nodeData, _nodePath, e) => {
        
        if(nodeData.tipo==="departamento"){
        
            if(this.state.departamentoSeleccionadoPrev)this.state.departamentoSeleccionadoPrev.isSelected=false
            nodeData.isSelected=true
            this.setState({
                departamentoSeleccionadoPrev:nodeData
            })

        }
       
    }

    handleNodeClickPuestoTrabajo = (nodeData, _nodePath, e) => {
        
        if(nodeData.tipo==="puesto_trabajo"){
        
            if(this.state.puestoSeleccionadoPrev)this.state.puestoSeleccionadoPrev.isSelected=false
            nodeData.isSelected=true
            this.setState({
                puestoSeleccionadoPrev:nodeData
            })

        }
       
    }

    handleNodeClickEmpleados = (nodeData, _nodePath, e) => {
        
        if(nodeData.tipo==="empleado"){
        
            if(this.state.empleadoSeleccionadoPrev)this.state.empleadoSeleccionadoPrev.isSelected=false
            nodeData.isSelected=true
            this.setState({
                empleadoSeleccionadoPrev:nodeData
            })

        }
       
    }

    render(){

        

        const handleNombreAcademica = (e) => {
            this.setState({nombrePersonaAcademica:e.currentTarget.value})
        }

        const anadirPersonaAcademica=()=>{
            if(!this.state.nombrePersonaAcademica){return}

            //si ya existe la persona.
            let existe=this.state.comision.find(com=>{return com.name===this.state.nombrePersonaAcademica})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            let personasAcademicas=this.state.comision
            personasAcademicas.push({"name":this.state.nombrePersonaAcademica})
            this.setState({
                comision:personasAcademicas,
                modalPersonaAcademica:false
            })
        }

        const handleNombreProfesorado = (e) => {
            this.setState({nombreProfesorado:e.currentTarget.value})
        }

        const anadirPersonaProfesorado=()=>{
            if(!this.state.nombreProfesorado)return
            
             //si ya existe la persona.
             let existe=this.state.profesorado.find(prof=>{return prof.name===this.state.nombreProfesorado})
             if(existe){
                 alert(i18next.t("yaexiste",{ns:"global"}))
                 return
             }

            let personasProfesorado=this.state.profesorado
            personasProfesorado.push({"name":this.state.nombreProfesorado})
            this.setState({
                profesorado:personasProfesorado,
                modalPersonaProfesorado:false
            })
        }

        
        return(
            <>
            {this.state.loading===true?
                <Cargando/>
            :
            <>
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("identificacion.selectarea",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.dialogArea}
            >
            <div className={Classes.DIALOG_BODY}>
            <Card interactive={true} elevation={Elevation.TWO}>
                <Tree
                    contents={this.state.arbolAreas}
                    onNodeClick={this.handleNodeClickAreas}
                    onNodeCollapse={this.handleNodeCollapse}
                    onNodeExpand={this.handleNodeExpand}
                />
            </Card>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton disabled={this.props.editable===false || this.props.deshabilitado===true?true:false} intent={Intent.PRIMARY} onClick={this.añadirNuevo}>
                    {i18next.t("aceptar",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("cancelar",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>

            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("identificacion.selectdepartamento",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.dialogDepartamento}
            >
            <div className={Classes.DIALOG_BODY}>
            <Card interactive={true} elevation={Elevation.TWO}>
                <div>
                    <Tooltip content={i18next.t("tree.replegar",{ns:"global"})} position="auto" ><Button icon="exclude-row" onClick={()=>this.replegarArbol(this.state.arbolDepartamentos)}/></Tooltip>
                    <Tooltip content={i18next.t("tree.desplegar",{ns:"global"})} position="auto" ><Button icon="add-column-right" onClick={()=>this.desplegarArbol(this.state.arbolDepartamentos)}/></Tooltip>
                </div>
                <Tree
                    contents={this.state.arbolDepartamentos}
                    onNodeClick={this.handleNodeClickDepartamento}
                    onNodeCollapse={this.handleNodeCollapse}
                    onNodeExpand={this.handleNodeExpand}
                />
            </Card>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.añadirNuevo}>
                    {i18next.t("yes",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("no",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>

            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("identificacion.selectpuesto",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.dialogPuesto}
            >
            <div className={Classes.DIALOG_BODY}>
            <Card interactive={true} elevation={Elevation.TWO}>
                <div>
                    <Tooltip content={i18next.t("tree.replegar",{ns:"global"})} position="auto" ><Button icon="exclude-row" onClick={()=>this.replegarArbol(this.state.arbolPuestosTrabajo)}/></Tooltip>
                    <Tooltip content={i18next.t("tree.desplegar",{ns:"global"})} position="auto" ><Button icon="add-column-right" onClick={()=>this.desplegarArbol(this.state.arbolPuestosTrabajo)}/></Tooltip>
                </div>
                <Tree
                    contents={this.state.arbolPuestosTrabajo}
                    onNodeClick={this.handleNodeClickPuestoTrabajo}
                    onNodeCollapse={this.handleNodeCollapse}
                    onNodeExpand={this.handleNodeExpand}
                />
            </Card>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.añadirNuevo}>
                    {i18next.t("yes",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("no",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>

            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={this.cerrarModal}
                title={i18next.t("identificacion.selectpersona",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.dialogEmpleado}
            >
            <div className={Classes.DIALOG_BODY}>
            <Card interactive={true} elevation={Elevation.TWO}>
                <div>
                    <Tooltip content={i18next.t("tree.replegar",{ns:"global"})} position="auto" ><Button icon="exclude-row" onClick={()=>this.replegarArbol(this.state.arbolEmpleados)}/></Tooltip>
                    <Tooltip content={i18next.t("tree.desplegar",{ns:"global"})} position="auto" ><Button icon="add-column-right" onClick={()=>this.desplegarArbol(this.state.arbolEmpleados)}/></Tooltip>
                </div>
                <Tree
                    contents={this.state.arbolEmpleados}
                    onNodeClick={this.handleNodeClickEmpleados}
                    onNodeCollapse={this.handleNodeCollapse}
                    onNodeExpand={this.handleNodeExpand}
                />
            </Card>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.añadirNuevo}>
                    {i18next.t("yes",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={this.cerrarModal}>
                    {i18next.t("no",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>

            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={()=>{this.setState({modalPersonaAcademica:false})}}
                title={i18next.t("identificacion.titleaddpersona",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalPersonaAcademica}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={i18next.t("identificacion.emptypersona",{ns:"page"})+":"}
                    labelFor="informacion"
                    >
                        <InputGroup id="comision-academica" onChange={handleNombreAcademica} placeholder="" value={this.state.nombrePersonaAcademica} intent={this.state.nombrePersonaAcademica?"primary":"danger"} />
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={anadirPersonaAcademica}
                        >
                            {i18next.t("aceptar",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={()=>{this.setState({modalPersonaAcademica:false})}}>{i18next.t("cancelar",{ns:"global"})}</Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
            
        <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={()=>{this.setState({modalPersonaProfesorado:false})}}
                title={i18next.t("identificacion.titleaddpersona",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalPersonaProfesorado}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={i18next.t("identificacion.emptypersona",{ns:"page"})+":"}
                    labelFor="informacion"
                    >
                        <InputGroup id="comision-academica" onChange={handleNombreProfesorado} placeholder="" value={this.state.nombreProfesorado} intent={this.state.nombreProfesorado?"primary":"danger"} />
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={anadirPersonaProfesorado}
                        >
                            {i18next.t("aceptar",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={()=>{this.setState({modalPersonaProfesorado:false})}}>{i18next.t("cancelar",{ns:"global"})}</Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>

            <Card interactive={true} elevation={Elevation.TWO}>
                <Tabs
                    id="tabs-acciones"
                    onChange={this.cambiarPestaña} 
                    selectedTabId={this.state.pestaña}
                    renderActiveTabPanelOnly={false} 
                >
                    <Tab id="accion-formativa" title={i18next.t("identificacion.accionformativa",{ns:"page"})} panel={<this.Accion />} />
                    {this.props.tipo === "formacion"?
                        <Tab id="formacion" title={i18next.t("identificacion.formacion",{ns:"page"})} panel={<this.Formacion />} />
                        :
                        <Tab id="informacion" title={i18next.t("identificacion.informacion",{ns:"page"})} panel={<this.Informacion />} />
                    }
                    {
                        this.props.datos && (this.props.datos.ID_EMPLEADO || localStorage.getItem("ayuda")==="true")?
                            <Tab id="personas" title={i18next.t("identificacion.personas",{ns:"page"})} panel={<this.Personas />} />
                        :<></>
                    }
                    {
                        this.props.datos?
                            <Tab id="riesgos-relacionados" title={i18next.t("identificacion.riesgosrelacionados",{ns:"page"})} panel={<this.Riesgos />} />
                        :<></>
                    }
                    
                </Tabs>
            </Card>
            </>
            }
        </>
        )
    }
}

export default withTranslation()(AccionesSegumiento);