/* eslint-disable array-callback-return */
import React from 'react';
import { Spinner, AnchorButton, Navbar, Button, ButtonGroup, Alignment, Dialog, Classes, Intent, Tooltip, Card, Elevation, Callout, InputGroup, FormGroup, Radio, RadioGroup , TextArea, NumericInput } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality,Regions} from "@blueprintjs/table"
import Autosuggest from 'react-autosuggest';
import Select from "react-select"
import chroma from "chroma-js"
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import ReactToPrint from "react-to-print";
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
    dialog2:{
        width: '45%'    
    },
    page: {
        paddingBottom:95,
        paddingTop:30,
        paddingLeft: 30,
        paddingRight: 20
      },
      body: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        marginLeft:20,
      },
      imageHead: {
        width: '20%',
        maxHeight: 60,
        marginBottom: 20
      },
      rowHeadGral: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:10
      },
      rowHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      rowHeadTwo: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginBottom: 20,
        marginTop:-1
      },
      row: {
        flexDirection: 'row',
        width: '100%',
        marginTop:3
      },
      label: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
      },
      labelHead: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      colHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      labelA: {
        flexDirection: 'row',
        width: '40%',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      labelD: {
        flexDirection: 'row',
        width: '60%'
      },
      labelDataA: {
        flexDirection: 'column',
        width: '20%',
        fontSize:10,
        fontFamily: 'Open Sans Bold'
      },
      valueDataA: {
        flexDirection: 'column',
        width: '80%',
        fontSize:10,
        fontFamily: 'Open Sans'
      },
      labelDataD: {
        flexDirection: 'column',
        width: '35%',
        fontSize:10,
        fontFamily: 'Open Sans Bold'
      },
      valueDataD: {
        flexDirection: 'column',
        width: '65%',
        fontSize:10,
        fontFamily: 'Open Sans'
      },
      rowTitleOne: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:5
      },
      rowTitle: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000'
      },
      labelTitle: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        textAlign: 'center',
        
      },
      text: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
      },
      footer: {
        position: 'absolute',
        fontSize: 8,
        bottom: 10,
        left: 20,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        fontFamily: 'Open Sans',
      },
      footerPage: {
        position: 'absolute',
        fontSize: 8,
        bottom: 60,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'black',
        fontFamily: 'Open Sans Bold',
      },
      icono: {
        position: 'absolute',
        float: 'right',
        width: 40,
        bottom: 10,
        right: 20,
      },
      labelData: {
        flexDirection: 'column',
        marginRight: 10,
        fontSize:10,
        fontFamily: 'Open Sans Bold',
      },
      textData: {
        flexDirection: 'column',
        fontSize:10,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
        flexWrap: 'wrap',
        width: 250
      }
      
     
})    

// Imagine you have a list of languages that you'd like to autosuggest.
const sugerencias = [

      ]

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : sugerencias.filter(empl =>
    empl.nombre.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.nombre;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombre}
  </div>
);

class Evaluacion extends React.Component {

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    state = {
        value: '',
        suggestions: [],
        modalNuevoAbierto: false,
        modalEvaluacionAbierto: false,
        evaluaciones:[],
        nuevoActivado:true,
        editarActivado:false,
        empleados: [ ],
        trabajadorSeleccionado: null,
        puestosDeTrabajo: [ ],
        codigoEmpleadoMal: null,
        riesgoOptions: [
            { value: 1, label: "Bajo", color: "green" },
            { value: 2, label: "Medio", color: "yellow" },
            { value: 3, label: "Alto", color: "orange"},
            { value: 4, label: "Muy Alto", color: "red"},
        ],
        responsabilidad: 1,
        adaptabilidad: 3,
        calidadTrabajo: 3,
        capacidadTrabajo: 3,
        cumplimientoObjetivos: 3,
        cumplimientoPlazos: 3,
        compromisoEmpresa: 3,
        capacidadDecision: 3,
        capacidadTrabajoEquipo: 3,
        conocimientos: 3,
        fiabilidad: 3,
        flexibilidad: 3,
        organizacion: 3,
        iniciativa: 3,
        disponibilidadAprender: 3,
        capacidadResolucion: 3,
        puntualidad: 3,
        liderazgo: 3,
        motivacion: 3,
        radioConclusion:1,
        observacionesEvaluacion: null,
        riesgoEvaluacion: 1,
        observacionesConclusion: null,
        fechaProximaValoracion: new Date(),
        fechaValoracion: new Date(),
        evaluadorSeleccionado:null,
        evaluacionSeleccionada:null,
        regionSeleccionada:null,
        modalBorrar:false,
        busquedaEval:"",
        cargando: false,
        imprimirActivado:false,
        modalImprimir:false,
        imprimirFichaActivado:false,
        modalImprimirFicha:false,
        riesgoDescr:null,
        responsabilidadDesc:null,
        conclusionDesc:null,
        areas: [],
        evaluacionesTablaGral: [],
        columnWidthsTrab: [100,100,100],
        resizedTrab: false,
        columnWidths: [100,100,100,100,100,100,100],
        resized: false,
    }

    resizeColumns() {
        let containerSize = document.getElementsByClassName("tablaEvaluaciones")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        if (this.tablaEvaluaciones) {
            for (let index = 0; index < this.tablaEvaluaciones.locator.grid.numCols; index++) {
                totalMinSizeReq = totalMinSizeReq + this.tablaEvaluaciones.locator.getWidestVisibleCellInColumn(index);
                cumulativeColumnWidths.push(this.tablaEvaluaciones.locator.getWidestVisibleCellInColumn(index));
            }
            if (totalMinSizeReq > containerSize) {
                this.setState({
                    columnWidths: cumulativeColumnWidths,
                    resized: true
                });
            } else {
                let diff = ((containerSize - totalMinSizeReq) / this.tablaEvaluaciones.locator.grid.numCols);
                this.setState({
                    columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                    resized: true
                });
            }
        }
        
    }

    resizeColumnsTrab() {
        let containerSize = document.getElementsByClassName("tablaTrabajadores")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        if (this.tablaTrabajadores) {
            for (let index = 0; index < this.tablaTrabajadores.locator.grid.numCols; index++) {
                totalMinSizeReq = totalMinSizeReq + this.tablaTrabajadores.locator.getWidestVisibleCellInColumn(index);
                cumulativeColumnWidths.push(this.tablaTrabajadores.locator.getWidestVisibleCellInColumn(index));
            }
            if (totalMinSizeReq > containerSize) {
                this.setState({
                    columnWidthsTrab: cumulativeColumnWidths,
                    resizedTrab: true
                });
            } else {
                let diff = ((containerSize - totalMinSizeReq) / this.tablaTrabajadores.locator.grid.numCols);
                this.setState({
                    columnWidthsTrab: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                    resizedTrab: true
                });
            }
        }
        
    }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  recargarEmpleadosProps=async ()=>{
        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {empleados(FILTROS:{ID_EMPLEADO:${this.props.empleado.ID_EMPLEADO}},ORDEN:"ID_EMPLEADO,ASC"){ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,EMPLEADO{ID_PERSONA}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 

            let empleados=JSON.parse(JSON.stringify(result.data.empleados))
            for(let empleado of empleados){
                
                //esto es para el select del evaluador
                empleado.value=empleado.ID_EMPLEADO

                //OBTENCION NOMBRE DE LA PERSONA
                await this.props.client
                .query({
                    query: gql` 
                        {personasFisicas(FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}
                    `,
                    fetchPolicy:'network-only'
                }).then(result=>{ 

                    for(let persona of result.data.personasFisicas){
                
                        empleado.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                        empleado.label=empleado.nombre

                    }

                })


                //Obtencion del puesto de trabajo actual del empleado
                await this.props.client
                .query({
                    query: gql` 
                        {empleadosPuestos(FILTROS:{ID_EMPLEADO:${empleado.ID_EMPLEADO},FECHA_BAJA:null},LIMIT:1,ORDEN:"FECHA_ALTA,ID_PUESTO,DESC"){ID_EMPLEO,FECHA_ALTA,FECHA_BAJA,PUESTO_TRABAJO{ID_PUESTO,DESCRIPCION,GRADO_RESPONSABILIDAD,ID_RIESGO_OBJETIVO,ID_DEPARTAMENTO,DEPARTAMENTO{ID_AREA},DESC_CORTA,DESC_LARGA}}}
                    `,
                    fetchPolicy:'network-only'
                }).then(async result=>{ 

                    //SI TENEMOS PUESTO.
                    result.data.empleadosPuestos.forEach(async puesto=>{

                        empleado.idPuesto=puesto.PUESTO_TRABAJO.ID_PUESTO
                        empleado.idDepartamento=puesto.PUESTO_TRABAJO.ID_DEPARTAMENTO
                        empleado.idArea=puesto.PUESTO_TRABAJO.DEPARTAMENTO.ID_AREA
                        empleado.puestoActual=puesto.PUESTO_TRABAJO.DESCRIPCION
                        empleado.riesgoPuesto=puesto.PUESTO_TRABAJO.ID_RIESGO_OBJETIVO
                        empleado.fechaPuesto=puesto.FECHA_ALTA

                    })
            
                })

            }
            await this.setState({empleados:empleados})

        })

    }

  recargarEmpleados=async ()=>{

        //cargar el select de personas.
        let empleados=await this.props.client
        .query({
            query: gql` 
                {empleados(ORDEN:"ID_EMPLEADO,ASC"){ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,CODIGO_EMPLEADO,EMPLEADO{ID_PERSONA}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.empleados
        })

        let personasFisicas=await this.props.client
        .query({
            query: gql` 
                {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.personasFisicas
        })

        let puestos=await this.props.client
        .query({
            query: gql` 
                {empleadosPuestos(FILTROS:{FECHA_BAJA:null},ORDEN:"FECHA_ALTA,ID_PUESTO,DESC"){
                    ID_EMPLEO,
                    FECHA_ALTA,
                    FECHA_BAJA,
                    ID_EMPLEADO,
                    PUESTO_TRABAJO{ID_PUESTO,CODIGO_PUESTO,DESCRIPCION,GRADO_RESPONSABILIDAD,ID_RIESGO_OBJETIVO,DEPARTAMENTO{AREA{DESCRIPCION}},ID_DEPARTAMENTO,DEPARTAMENTO{ID_AREA,DESCRIPCION},DESC_CORTA,DESC_LARGA}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{
            return result.data.empleadosPuestos
        })
                    
        for(let empleado of empleados){
                empleado.value=empleado.ID_EMPLEADO
                empleado.codigoEmpleado= empleado.CODIGO_EMPLEADO
                
                personasFisicas.find(persona=>{
                  if (persona.ID_PERSONA===empleado.ID_EMPLEADO) {
                    empleado.nombre=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                    empleado.label=empleado.nombre 
                  } 
                })
                puestos.find(puesto=>{
                    if (puesto.ID_EMPLEADO===empleado.ID_EMPLEADO) {
                        empleado.idPuesto=puesto.PUESTO_TRABAJO.ID_PUESTO
                        empleado.idDepartamento=puesto.PUESTO_TRABAJO.ID_DEPARTAMENTO
                        empleado.area=puesto.PUESTO_TRABAJO.DEPARTAMENTO.AREA.DESCRIPCION
                        empleado.idArea=puesto.PUESTO_TRABAJO.DEPARTAMENTO.ID_AREA
                        empleado.puestoActual=puesto.PUESTO_TRABAJO.DESCRIPCION
                        empleado.riesgoPuesto=puesto.PUESTO_TRABAJO.ID_RIESGO_OBJETIVO
                        empleado.fechaPuesto=puesto.FECHA_ALTA
                        empleado.codigoPuesto=puesto.PUESTO_TRABAJO.CODIGO_PUESTO
                        empleado.departamento=puesto.PUESTO_TRABAJO.DEPARTAMENTO.DESCRIPCION
                    }
                })

            
            }
            this.setState({empleados:empleados})
    
    }

    recargarEvaluaciones=async()=>{

        //cargar tabla evaluaciones.
        let evaluaciones=await this.props.client
        .query({
            query: gql` 
                {empleadosValoracion
                    {
                        ID_VALORACION,
                        ID_EMPLEADO,
                        ANTIGUEDAD_PUESTO,
                        ID_PUESTO,
                        GRADO_RESPONSABILIDAD,
                        ADAPTABILIDAD,
                        CALIDAD_TRABAJO,
                        CAPACIDAD_TRABAJO,
                        CUMPLIDOR_OBJETIVOS,
                        CUMPLIMIENTO_PLAZOS,
                        COMPROMISO_EMPRESA,
                        CAPACIDAD_DECISION,
                        CAPACIDAD_TRABAJO_EQUIPO,
                        CONOCIMIENTOS_PROFESIONALES,
                        FIABILIDAD,
                        FLEXIBILIDAD,
                        ORGANIZACION,
                        INICIATIVA,
                        DISPONIBILIDAD_APRENDER,
                        CAPACIDAD_RESOLUCION,
                        PUNTUALIDAD,
                        LIDERAZGO,
                        MOTIVACION,
                        OBSERVACIONES,
                        ID_PERFIL_RIESGO,
                        CONCLUSION,
                        DECISION,
                        FECHA_PROXIMA,
                        FECHA,
                        ID_RESPONSABLE,
                        ID_PUESTO_RESPONSABLE
                    }
                }
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            return result.data.empleadosValoracion
        })

        for(let ev of evaluaciones){
            let trabajador=this.state.empleados.find(empleado=>{return empleado.ID_EMPLEADO===ev.ID_EMPLEADO})
            let evaluador=this.state.empleados.find(empleado=>{return empleado.ID_EMPLEADO===ev.ID_RESPONSABLE})

            ev.trabajador=trabajador
            ev.evaluador=evaluador
        }

        this.setState({evaluaciones:evaluaciones})
        console.log(this.state.evaluaciones)
        this.setState({copyEvaluaciones:evaluaciones})

    }

    cargarEmpresa= async() => {
        
         //DATOS JURIDICOS
         let datosEmpresa= await this.props.client
         .query({
             query: gql`
             {
                 personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
             }
             `,
             fetchPolicy: 'network-only'
 
         }).then(result=>{
             //si tenemos razon social.
             if(result.data.personasJuridicas.length>0){
                 return {
                     "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                     "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                     "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                     "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                     "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                     "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                     "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                     "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                     "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                     "fusiones":result.data.personasJuridicas[0].FUSIONES,
                     "filiales":result.data.personasJuridicas[0].FILIALES,
                     "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                     "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                     "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                     "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                 }
 
             }
             
         })
 
        return datosEmpresa
    
    }

    componentDidMount=async()=>{

        this.setState({ cargando: true })

        if(this.props.empleado){
            await this.recargarEmpleadosProps()
        } else {
            this.cargarAreas()
            await this.recargarEmpleados()
            await this.recargarEvaluaciones()
        }        
        this.setState({cargando: false})
        
    }

    // obtenerCodigo=async(empleado)=>{

    //     if(!empleado.idPuesto)return empleado
    //     //OBTENER EL NUMERO DEL AREA.
    //     let indiceArea=await this.props.client
    //     .query({
    //         query: gql` 
    //             {departamentosAreas(CAMPOS:"ID_AREA",FILTROS:{PREDEFINIDA:false}){ID_AREA}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.departamentosAreas.find((area)=>{
    //             index++
    //             return parseInt(area.ID_AREA)===parseInt(empleado.idArea)
    //         })
    //         return index
    //     })

    //     let indiceDepartamento=await this.props.client
    //     .query({
    //         query: gql` 
    //             {departamentos(CAMPOS:"ID_DEPARTAMENTO",FILTROS:{PREDEFINIDO:false,ID_AREA:${empleado.idArea}}){ID_DEPARTAMENTO}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.departamentos.find(departamento=>{
    //             index++
    //             return parseInt(departamento.ID_DEPARTAMENTO)===parseInt(empleado.idDepartamento)
    //         })
    //         return index
    //     })

    //     let indicePuesto=await this.props.client
    //     .query({
    //         query: gql` 
    //             {puestosTrabajo(CAMPOS:"ID_PUESTO",FILTROS:{ID_DEPARTAMENTO:${empleado.idDepartamento}}){ID_PUESTO}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.puestosTrabajo.find(pt=>{
    //             index++
    //             return parseInt(pt.ID_PUESTO)===parseInt(empleado.idPuesto)
    //         })
    //         return index
    //     })

    //     let indiceEmpleado=await this.props.client
    //     .query({
    //         query: gql` 
    //             {departamentosEmpleados(FILTROS:{ID_DEPARTAMENTO:${empleado.idDepartamento}}){ID_EMPLEADO}}
    //         `,
    //         fetchPolicy:'network-only'
    //     }).then(async result=>{ 
    //         let index=0
    //         result.data.departamentosEmpleados.find(ed=>{
    //             index++
    //             return parseInt(ed.ID_EMPLEADO)===parseInt(empleado.ID_EMPLEADO)
    //         })
    //         return index
    //     })

    //     empleado.codigoPuesto=indiceArea+""+indiceDepartamento+""+indicePuesto
    //     empleado.CODIGO_EMPLEADO=indiceArea+""+indiceDepartamento+""+indicePuesto+""+indiceEmpleado

    //     return empleado

    // }
    CargarDatosEmpr= async () =>{
        let datosEmpresa = await this.cargarEmpresa()
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }




    // componentDidUpdate=async(props)=>{
    //     if(this.props!==props){
    //         if(this.props.abierto){
  
    //             await this.setState({
    //                 responsabilidad: 1,
    //                 adaptabilidad: 3,
    //                 calidadTrabajo: 3,
    //                 capacidadTrabajo: 3,
    //                 cumplimientoObjetivos: 3,
    //                 cumplimientoPlazos: 3,
    //                 compromisoEmpresa: 3,
    //                 capacidadDecision: 3,
    //                 capacidadTrabajoEquipo: 3,
    //                 conocimientos: 3,
    //                 fiabilidad: 3,
    //                 flexibilidad: 3,
    //                 organizacion: 3,
    //                 iniciativa: 3,
    //                 disponibilidadAprender: 3,
    //                 capacidadResolucion: 3,
    //                 puntualidad: 3,
    //                 liderazgo: 3,
    //                 motivacion: 3,
    //                 radioConclusion:1,
    //                 observacionesEvaluacion: null,
    //                 riesgoEvaluacion: 1,
    //                 observacionesConclusion: null,
    //                 fechaProximaValoracion: new Date(),
    //                 fechaValoracion: new Date(),
    //                 evaluadorSeleccionado:null,
    //                 evaluacionSeleccionada:null,
    //                 modalEvaluacionAbierto: true,
    //                 regionSeleccionada:null
    //             })
    //         }

    //         if(this.props.empleado){

       
    //             let empleado=await this.state.empleados.find(emp=>{return emp.ID_EMPLEADO===this.props.empleado.id})
    //             if(typeof empleado==="undefined"){

    //                 await this.recargarEmpleados().then(async ()=>{
    //                     empleado=await this.state.empleados.find(emp=>{return emp.ID_EMPLEADO===this.props.empleado.id})
    //                 })

    //             }

    //             //Calculamos el tiempo del puesto de trabajo 
    //             let fechaActual = moment(this.state.fechaValoracion);
    //             let fechaAlta = moment(empleado.fechaPuesto);

    //             let meses = fechaActual.diff(fechaAlta, 'months');
    //             fechaAlta.add(meses, 'months');

    //             empleado.antiguedad=meses+" meses"   
    //             await this.setState({trabajadorSeleccionado:await this.obtenerCodigo(empleado)})

    //         }
       
    //     }
        
    // }
    conclusionDesc=(data) =>{
        let conclusion='';
        switch (data) {
            case 1:
                conclusion="1. Rendimiento muy insuficiente. Formación ineficaz. Sin cohesión ni compromiso con la empresa."            
                break;
            case 2:
                conclusion="2. Rendimiento insuficiente. Puede mejorar. Valorable formación y/o cambio de puesto de trabajo."                
                break;
            case 3:
                conclusion="3. Rendimiento óptimo. Mantener motivación. Valorar promoción y formación."             
                break;
             case 4:
                 conclusion="4. Rendimiento de referencia. Comprometido con la cultura empresarial."        
                break;    
            default:
                break;
        }
        this.setState({conclusionDesc:conclusion})

    }
    gradoResp= (data) =>{
        let responsabilidad=''
        switch (data) {
            case 1:
                responsabilidad="1. Areas de escasa iniciativa, sin precisar conocimientos específicos."
                break;
            case 2:
                responsabilidad="2. Tareas auxiliares con alguna iniciativa, bajo estrecha supervisión." 
                break;
            case 3:
              responsabilidad="3. Tareas que precisan iniciativa y conocimientos técnicos específicos." 
            break;
            case 4:
                responsabilidad="4. Tareas ejecutivas, debiendo tomar decisiones sobre los métodos a emplear."
            break;
            case 5:
                responsabilidad="5. Tareas de jefatura de otros trabajadores, con responsabilidad en el departamento."
            break;
            case 6:
                responsabilidad="6. Tareas de planeamiento y supervisión de varios departamentos." 
            break;
            case 7:
                responsabilidad="7. Tareas de alta especialización con titulación superior y experiencia, asesorando a la dirección."
            break;
            case 8:
                responsabilidad= "8. Interpreta y aplica políticas de empresa en varios deparamentos, reportando a la alta dirección."
            break;         
        
            default:
                break;
        }

        this.setState({responsabilidadDesc: responsabilidad})
        console.log(this.state.responsabilidadDesc)

    }

    riesgoDescr= (data) =>{
        let riesgoDesc=[]
        if(this.state.trabajadorSeleccionado.riesgoPuesto){
        let color=''
        let value=''
        switch (data) {
            case 1:
                color= "green"
                value= "Bajo"                
                break;
            case 2:
                color= "yellow"
                value= "Medio"                
                break;
            case 3:
                color= "orange"
                value= "Alto"                
                break;
             case 4:
                 color="red"
                 value="Muy Alto"          
                break;    
            default:
                break;
        }
        riesgoDesc.push({"color":color, "value":value});
    }
        this.setState({riesgoDescr:riesgoDesc})
        console.log(this.state.riesgoDescr)
        

    }
    ImprimirFicha= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                {this.riesgoDescr(this.state.trabajadorSeleccionado.riesgoPuesto)}
                {this.gradoResp(this.state.responsabilidad)}

                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>VALORACIÓN DEL RENDIMIENTO</Text>
                </View>
                
                    <View style={styles.rowTitleOne}>
                         <Text style={styles.labelTitle}>INFORMACIÓN TRABAJADOR</Text>                            
                    </View> 

                           <View style={styles.row}>
                                <Text style={styles.labelData}>NOMBRE Y APELLIDO:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.nombre:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>CODIGO EMPLEADO:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.codigoEmpleado:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ÁREA:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.area:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>DEPARTAMENTO:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.departamento:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>PUESTO DE TRABAJO:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.puestoActual:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>ANTIGUEDAD:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.antiguedad:''}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.labelData}>CÓDIGO DE PUESTO DE TRABAJO:</Text>
                                <Text style={styles.textData}>{this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.codigoPuesto:''}</Text>
                            </View>
                            {this.state.riesgoDescr.map((riesgo, index) => {
                                let color= riesgo.color;
                                let value= riesgo.value
                                console.log(value)
                                return(
                                    <View style={styles.row}>              
                                        <Text style={styles.labelData}>RIESGO OBJETIVO:</Text>
                                        <Text style={{backgroundColor:`${color}`, paddingHorizontal: 8, flexDirection:'column', fontSize:10, fontFamily: 'Open Sans', width: 70, border:'1 solid #00000'}}>{value}</Text>
                                </View>
                                 )
                            })}
                           
                            
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>GRADO DE RESPONSABILIDAD AL PUESTO DE TRABAJO</Text>                            
                    </View> 
                    
                    <View style={styles.row}>              
                        <Text style={{textAlign:'center',flexDirection: 'row',fontSize:10,fontFamily: 'Open Sans', width:'100%', paddingHorizontal: 12}}>{this.state.responsabilidad?this.state.responsabilidadDesc:''}</Text>
                    </View>
                    
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>EVALUACION (Valores del 1 al 5)</Text>                            
                    </View>
                    
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Adaptabilidad:</Text>
                        <Text style={styles.textData}>{this.state.adaptabilidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Calidad del trabajo:</Text>
                        <Text style={styles.textData}>{this.state.calidadTrabajo}</Text>
                    </View>
                    <View style={styles.row}>             
                        <Text style={styles.labelData}>Capacidad de trabajo:</Text>
                        <Text style={styles.textData}>{this.state.capacidadTrabajo}</Text>
                    </View>        
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Cumplimiento de objetivos:</Text>
                        <Text style={styles.textData}>{this.state.cumplimientoObjetivos}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Cumplimiento de plazos:</Text>
                        <Text style={styles.textData}>{this.state.cumplimientoPlazos}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Compromiso con la empresa:</Text>
                        <Text style={styles.textData}>{this.state.compromisoEmpresa}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Capacidad de decisión:</Text>
                        <Text style={styles.textData}>{this.state.capacidadDecision}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Capacidad de trabajo en equipo:</Text>
                        <Text style={styles.textData}>{this.state.capacidadTrabajoEquipo}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Conocimientos profesionales:</Text>
                        <Text style={styles.textData}>{this.state.conocimientos}</Text>
                    </View>   
                  
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Fiabilidad:</Text>
                        <Text style={styles.textData}>{this.state.fiabilidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Flexibilidad:</Text>
                        <Text style={styles.textData}>{this.state.flexibilidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Organización y orden:</Text>
                        <Text style={styles.textData}>{this.state.organizacion}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Iniciativa:</Text>
                        <Text style={styles.textData}>{this.state.iniciativa}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Disponibilidad para aprender:</Text>
                        <Text style={styles.textData}>{this.state.disponibilidadAprender}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Capacidad de resolución:</Text>
                        <Text style={styles.textData}>{this.state.capacidadResolucion}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Puntualidad:</Text>
                        <Text style={styles.textData}>{this.state.puntualidad}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Liderazgo:</Text>
                        <Text style={styles.textData}>{this.state.liderazgo}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>Motivación:</Text>
                        <Text style={styles.textData}>{this.state.motivacion}</Text>
                    </View>         
                   
                    
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>OBSERVACIONES:</Text>
                        <Text style={styles.textData}>{this.state.observacionesEvaluacion?this.state.observacionesEvaluacion:''}</Text>
                    </View>
                    {this.riesgoDescr(this.state.riesgoEvaluacion)}{this.state.riesgoEvaluacion?this.state.riesgoDescr.map((riesgo) => {
                                let color= riesgo.color;
                                let value= riesgo.value
                            return(
                                <View style={styles.row}>              
                                <Text style={styles.labelData}>PERFIL DE RIESGO:</Text>
                                <Text style={{backgroundColor:`${color}`, paddingHorizontal: 8, flexDirection:'column', fontSize:10, fontFamily: 'Open Sans', width: 70, border:'1 solid #00000'}}>{value}</Text>
                            </View>
                            )
                    }):<></>
                }
                    
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>CONCLUSIÓN</Text>                            
                    </View>
                    {this.conclusionDesc(this.state.radioConclusion)}
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>CONCLUSIÓN EVALUACIÓN:</Text>
                        <Text style={styles.textData}>{this.state.radioConclusion?this.state.conclusionDesc:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>DECISIÓN PROPUESTA:</Text>
                        <Text style={styles.textData}>{this.state.observacionesConclusion?this.state.observacionesConclusion:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>FECHA PROPUESTA PARA LA PRÓXIMA VALORACIÓN:</Text>
                        <Text style={styles.textData}>{moment(this.state.fechaProximaValoracion).format("DD/MM/YYYY")}</Text>
                    </View>
                    <View style={styles.rowTitle}>
                         <Text style={styles.labelTitle}>EVALUADOR</Text>                            
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>NOMBRE EVALUADOR:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.nombre:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>CÓDIGO DE EMPLEADO:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.codigoEmpleado:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>PUESTO DE TRABAJO:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.puestoActual:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>CÓDIGO DE PUESTO DE TRABAJO:</Text>
                        <Text style={styles.textData}>{this.state.evaluadorSeleccionado?this.state.evaluadorSeleccionado.codigoPuesto:''}</Text>
                    </View>
                    <View style={styles.row}>              
                        <Text style={styles.labelData}>FECHA DE LA EVALUACIÓN:</Text>
                        <Text style={styles.textData}>{moment(this.state.fechaValoracion).format("DD/MM/YYYY")}</Text>
                    </View>
                                        
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
                />
            <Image
                        style={styles.icono}
                        src={iconoFooter}
                        fixed
                        />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    generarFicha = async () => {
        this.setState({
            modalImprimirFicha:true,
            cargandoImprimir:true
        }, async () => {
            if(this.state.datosEmpresa==null){
                await this.CargarDatosEmpr()
                ReactDOM.render(this.ImprimirFicha(), document.getElementById('generarFicha-pdf'))
            }else{
                this.setState({
                    cargandoImprimir:false,
                }, () =>{
                    ReactDOM.render(this.ImprimirFicha(), document.getElementById('generarFicha-pdf'))
                })
            }
        }) 
    }


     ImprimirTabla= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>VALORACIÓN DEL RENDIMIENTO</Text>
                </View>
                {this.state.evaluacionesTablaGral?this.state.evaluacionesTablaGral.map((evaluacionAD, indexG) => {
                    
                    return(
                        
                        <View key={indexG}>
                                <View style={styles.rowHeadGral}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>ÁREA:</Text>
                                        <Text style={styles.valueDataA}>{evaluacionAD.area}</Text> 
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>DEPARTAMENTO:</Text>
                                        <Text style={styles.valueDataD}>{evaluacionAD.dpto}</Text> 
                                    </View>
                                </View>
                            {evaluacionAD.evaluaciones.map((evaluacion, index)=>{
                            return (
                            <View key={index}>       
                            {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>Nº</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>TRABAJADOR</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CÓDIGO PUESTO:</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>FECHA EVALUACIÓN</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>PROXIMA EVALUACION</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>CONCLUSION</Text>
                                </View>                               
                            )}
                           
                            <View style={styles.colHead}>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{index+1}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{evaluacion.trabajador.nombre?evaluacion.trabajador.nombre:''}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{evaluacion.trabajador.codigoPuesto!==null?evaluacion.trabajador.codigoPuesto:''}</Text>                                  
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{evaluacion.FECHA!==null?moment(evaluacion.FECHA).format("DD-MM-YYYY "):''}</Text>                               
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{this.state.fechaProximaValoracion!==null?moment(this.state.fechaProximaValoracion).format("DD-MM-YYYY "):''}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{evaluacion.CONCLUSION?evaluacion.CONCLUSION:''}</Text>
                            </View> 
                                           
                            </View>
                        )
                        })}
                        </View>
                        
                    )
                }):<></>}
                
                        
            </View>
            <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
            fixed
            />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarAreas=async()=>{

        let areas=[]
        
        // obtencion de las areas
        await this.props.client.query({
            query:gql`{departamentosAreas(FILTROS:{PREDEFINIDA:false}){ID_AREA,DESCRIPCION,PREDEFINIDA}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

           
            result.data.departamentosAreas.forEach(async(area)=>{
                areas.push({id:area.ID_AREA,value:area.ID_AREA,label:area.DESCRIPCION,tipo:"area"})
                
            })  
            this.setState({
                areas:areas,
            })
             
        })
     
        areas.forEach(async (area,index)=>{

            await this.props.client.query({
                query:gql`{departamentos(FILTROS:{ID_AREA:${area.id}}){ID_DEPARTAMENTO,DESCRIPCION}}`
                ,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
    
                let deps=[]
                result.data.departamentos.forEach(async(dep)=>{
                    deps.push({id:dep.ID_DEPARTAMENTO,label:dep.DESCRIPCION,tipo:"departamento",padre:area.id})
                })  
                areas[index].childNodes=deps
               
            })

        })
      
    }

    cargarEvaluacionGral= async(area,dpto,idDep) =>{
        
        let evaluacionesGral = this.state.evaluacionesTablaGral
        let existe=false
        let evaluacionFind=[]
        this.state.evaluaciones.find(evaluacion=>{
            if (evaluacion.trabajador.idDepartamento===idDep) {
                existe=true
                evaluacionFind.push(evaluacion)
            }
        })
        if (existe) {
            evaluacionesGral.push({area:area,dpto:dpto,evaluaciones:evaluacionFind})
        }
        this.setState({evaluacionesTablaGral : evaluacionesGral })
      }

    cargarEvaluacion = async () => {
        if (this.state.areas!==null) {
            this.state.areas.map((area, index)=>{
                area.childNodes.map(async (child, indexC)=>{
                    await this.cargarEvaluacionGral(area.label,child.label,child.id)
                })
            })
        }
    }  

    generarTablaImprimir = async () => {
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true,
            evaluacionesTablaGral:[]
        }, async() => {
            await this.cargarEvaluacion();
            console.log(this.state.evaluacionesTablaGral)
            if(this.state.datosEmpresa==null){
                await this.CargarDatosEmpr()
                ReactDOM.render(this.ImprimirTabla(), document.getElementById ('generarTabla-pdf'))
            }else{
                this.setState({
                    cargandoImprimir:false,
                }, () =>{
                    ReactDOM.render(this.ImprimirTabla(), document.getElementById('generarTabla-pdf'))
                })
            }
        }) 
    }

  


   
    

    render() {

        document.title ="LexControl | Valoración del rendimiento"

        const { suggestions } = this.state

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const buscarTrabajador=(ev)=>{
            this.setState({busquedaEval:ev.currentTarget.value})

            if(ev.currentTarget.value==="" || ev.currentTarget.value===null){
                this.setState({evaluaciones:this.state.copyEvaluaciones})
            }else{
                this.setState({evaluaciones:this.state.copyEvaluaciones})

                let evalEcontradas=this.state.copyEvaluaciones.filter(evaluacion=>{
                    return evaluacion.trabajador.nombre.includes(ev.currentTarget.value)
                })

                this.setState({evaluaciones:evalEcontradas})

            }
        }

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Escriba el nombre del trabajador',
            value:this.state.busquedaEval,
            onChange: buscarTrabajador
        }

        

        const abrirModalNuevo = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalNuevoAbierto: true,
                imprimirActivado:false
            })
       }
       const cerrarModalImprimir= () =>{
           this.setState({
               modalImprimir:false
           })
       } 
       const cerrarModalImprimirFicha= () =>{
        this.setState({
            modalImprimirFicha:false
        })
    } 

        const abrirModalEditar =async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.evaluacionSeleccionada){
                alert("Debe seleccionar una evaluación")
                return
            }

            let evaluacion=this.state.evaluacionSeleccionada

            //Calculamos el tiempo del puesto de trabajo 
            let fechaActual = moment(this.state.fechaValoracion);
            let fechaAlta = moment(evaluacion.trabajador.fechaPuesto);

            let meses = fechaActual.diff(fechaAlta, 'months');
            fechaAlta.add(meses, 'months');

            evaluacion.trabajador.antiguedad=meses+" meses"


            await this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                responsabilidad: this.state.evaluacionSeleccionada.GRADO_RESPONSABILIDAD,
                adaptabilidad: this.state.evaluacionSeleccionada.ADAPTABILIDAD,
                calidadTrabajo: this.state.evaluacionSeleccionada.CALIDAD_TRABAJO,
                capacidadTrabajo: this.state.evaluacionSeleccionada.CAPACIDAD_TRABAJO,
                cumplimientoObjetivos: this.state.evaluacionSeleccionada.CUMPLIDOR_OBJETIVOS,
                cumplimientoPlazos:this.state.evaluacionSeleccionada.CUMPLIMIENTO_PLAZOS,
                compromisoEmpresa:this.state.evaluacionSeleccionada.COMPROMISO_EMPRESA,
                capacidadDecision: this.state.evaluacionSeleccionada.CAPACIDAD_DECISION,
                capacidadTrabajoEquipo: this.state.evaluacionSeleccionada.CAPACIDAD_TRABAJO_EQUIPO,
                conocimientos: this.state.evaluacionSeleccionada.CONOCIMIENTOS_PROFESIONALES,
                fiabilidad: this.state.evaluacionSeleccionada.FIABILIDAD,
                flexibilidad: this.state.evaluacionSeleccionada.FLEXIBILIDAD,
                organizacion: this.state.evaluacionSeleccionada.ORGANIZACION,
                iniciativa: this.state.evaluacionSeleccionada.INICIATIVA,
                disponibilidadAprender: this.state.evaluacionSeleccionada.DISPONIBILIDAD_APRENDER,
                capacidadResolucion: this.state.evaluacionSeleccionada.CAPACIDAD_RESOLUCION,
                puntualidad: this.state.evaluacionSeleccionada.PUNTUALIDAD,
                liderazgo: this.state.evaluacionSeleccionada.LIDERAZGO,
                motivacion: this.state.evaluacionSeleccionada.MOTIVACION,
                radioConclusion:this.state.evaluacionSeleccionada.CONCLUSION,
                observacionesEvaluacion: this.state.evaluacionSeleccionada.OBSERVACIONES,
                riesgoEvaluacion: this.state.evaluacionSeleccionada.ID_PERFIL_RIESGO,
                observacionesConclusion:this.state.evaluacionSeleccionada.DECISION,
                fechaProximaValoracion: new Date(this.state.evaluacionSeleccionada.FECHA_PROXIMA),
                fechaValoracion: new Date(this.state.evaluacionSeleccionada.FECHA),
                trabajadorSeleccionado:this.state.evaluacionSeleccionada.trabajador,
                evaluadorSeleccionado:this.state.evaluacionSeleccionada.evaluador,
                modalEvaluacionAbierto:true
            })

            // await this.setState({trabajadorSeleccionado:await this.obtenerCodigo(this.state.trabajadorSeleccionado)})
            // await this.setState({evaluadorSeleccionado:await this.obtenerCodigo(this.state.evaluadorSeleccionado)})

        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.evaluacionSeleccionada){
                alert("Debe seleccionar una evaluación")
                return
            }
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrar:true
            })
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrar: false,
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalEvaluacionAbierto: false,
            })
            if(this.props.modal){
                this.props.setModalEvaluacion(false)
            }
        }

        const escogeFecha= (nuevaFecha) => {
            this.setState({ fechaProximaValoracion: nuevaFecha })
            console.log(this.state.fechaProximaValoracion)
        }

        const escogeFechaActual= (nuevaFecha) => {
            this.setState({ fechaValoracion: nuevaFecha })
            
        }

        const abrirDialogTrabajador = async(value) => {

            //Calculamos el tiempo del puesto de trabajo 
            let fechaActual = moment(this.state.fechaValoracion);
            let fechaAlta = moment(this.state.trabajadorSeleccionado.fechaPuesto);

            let meses = fechaActual.diff(fechaAlta, 'months');
            fechaAlta.add(meses, 'months');

            let trabajador=this.state.trabajadorSeleccionado
            trabajador.antiguedad=meses+" meses"           

            // this.setState({trabajadorSeleccionado:await this.obtenerCodigo(this.state.trabajadorSeleccionado)})


            //reiniciamos los campos y abrimos el modal
            this.setState({
                responsabilidad: 1,
                adaptabilidad: 3,
                calidadTrabajo: 3,
                capacidadTrabajo: 3,
                cumplimientoObjetivos: 3,
                cumplimientoPlazos: 3,
                compromisoEmpresa: 3,
                capacidadDecision: 3,
                capacidadTrabajoEquipo: 3,
                conocimientos: 3,
                fiabilidad: 3,
                flexibilidad: 3,
                organizacion: 3,
                iniciativa: 3,
                disponibilidadAprender: 3,
                capacidadResolucion: 3,
                puntualidad: 3,
                liderazgo: 3,
                motivacion: 3,
                radioConclusion:1,
                observacionesEvaluacion: null,
                riesgoEvaluacion: 1,
                observacionesConclusion: null,
                fechaProximaValoracion: new Date(),
                fechaValoracion: new Date(),
                evaluadorSeleccionado:null,
                evaluacionSeleccionada:null,
                modalEvaluacionAbierto: true,
                regionSeleccionada:null
            },()=>{ this.tablaEvaluaciones.clearSelection()})
               

        }

        const cellRendererNombre = (rowIndex) => {
            return <Cell>{this.state.empleados[rowIndex].nombre}</Cell>
        }
        const cellRendererCodigoPuesto = (rowIndex) => {
            return <Cell>{this.state.evaluaciones[rowIndex].trabajador.codigoPuesto}</Cell>
        }

        const cellRendererArea = (rowIndex) => {
            return <Cell>{this.state.evaluaciones[rowIndex].trabajador.area}</Cell>
        }
        
        const cellRendererDpto = (rowIndex) => {
            return <Cell>{this.state.evaluaciones[rowIndex].trabajador.departamento}</Cell>
        }
        
        const cellRendererNombreTrabEv = (rowIndex) => {
            return <Cell>{this.state.evaluaciones[rowIndex].trabajador.nombre}</Cell>
        }
        
        const cellRendererFecha = (rowIndex) => {
            return <Cell>{moment(this.state.empleados[rowIndex].FECHA_ALTA).format("DD-MM-YYYY")}</Cell>
        }

        const cellRendererFechaEv = (rowIndex) => {
            return <Cell>{moment(this.state.evaluaciones[rowIndex].FECHA).format("DD-MM-YYYY")}</Cell>
        }

        const cellRendererFechaProximaEv = (rowIndex) => {
            return <Cell>{moment(this.state.empleados[rowIndex].FECHA_PROXIMA).format("DD-MM-YYYY")}</Cell>
        }
        const cellRendererConclusion = (rowIndex) => {
            return <Cell>{this.state.evaluaciones[rowIndex].CONCLUSION}</Cell>
        }

        const cellRendererPuesto = (rowIndex) => {
            return <Cell>{this.state.empleados[rowIndex].puestoActual}</Cell>
        }

        const seleccionarTrabajador = (value) => {
            if(value.length===0)return

            let seleccionada=this.state.empleados[value[0].rows[0]]
            
            this.setState({
                trabajadorSeleccionado: seleccionada,
            })
            console.log(this.state.trabajadorSeleccionado)
            console.log(this.state.evaluacionSeleccionada)
            console.log(this.state.evaluaciones)
        }

        const seleccionarEvaluacion = (value) => {
            if(value.length===0)return

            let seleccionada=this.state.evaluaciones[value[0].rows[0]]
            
            this.setState({
                evaluacionSeleccionada: seleccionada,
                regionSeleccionada:value
            })
        }

        const añadirNuevo =async () => {

            //validaciones
        

            if(!this.state.trabajadorSeleccionado.puestoActual){
                alert("El empleado a ser evaluado debe tener un puesto de trabajo.")
                return
            }
            if(!this.state.observacionesEvaluacion)return
            if(!this.state.observacionesConclusion)return
            if(!this.state.evaluadorSeleccionado)return
   

            let puestoEmp=await this.props.client
            .query({
                query: gql` 
                    {empleadosPuestos(CAMPOS:"ID_EMPLEO",FILTROS:{ID_EMPLEADO:${this.state.evaluadorSeleccionado.value},FECHA_BAJA:null}){ID_EMPLEO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.empleadosPuestos
            })

            if(puestoEmp.length===0){
                alert("Actualmente el evaluador no se encuentra asignado a ningún puesto o está dado de baja en su puesto de trabajo actual.Por favor asigne el evaluador a un nuevo puesto.")
                return
            }

            //creacion de la evaluacion
            this.props.client.mutate({
                mutation: gql`
                mutation{crearEmpleadosValoracion(VALORES:{
                    ID_EMPLEADO:${this.state.trabajadorSeleccionado.ID_EMPLEADO},
                    ANTIGUEDAD_PUESTO:"${this.state.trabajadorSeleccionado.antiguedad}",
                    ID_PUESTO:${this.state.trabajadorSeleccionado.idPuesto},
                    GRADO_RESPONSABILIDAD:${this.state.responsabilidad},
                    ADAPTABILIDAD:${this.state.adaptabilidad},
                    CALIDAD_TRABAJO:${this.state.calidadTrabajo},
                    CAPACIDAD_TRABAJO:${this.state.capacidadTrabajo},
                    CUMPLIDOR_OBJETIVOS:${this.state.cumplimientoObjetivos},
                    CUMPLIMIENTO_PLAZOS:${this.state.cumplimientoPlazos},
                    COMPROMISO_EMPRESA:${this.state.compromisoEmpresa},
                    CAPACIDAD_DECISION:${this.state.capacidadDecision},
                    CAPACIDAD_TRABAJO_EQUIPO:${this.state.capacidadTrabajoEquipo},
                    CONOCIMIENTOS_PROFESIONALES:${this.state.conocimientos},
                    FIABILIDAD:${this.state.fiabilidad},
                    FLEXIBILIDAD:${this.state.flexibilidad},
                    ORGANIZACION:${this.state.organizacion},
                    INICIATIVA:${this.state.iniciativa},
                    DISPONIBILIDAD_APRENDER:${this.state.disponibilidadAprender},
                    CAPACIDAD_RESOLUCION:${this.state.capacidadResolucion},
                    PUNTUALIDAD:${this.state.puntualidad},
                    LIDERAZGO:${this.state.liderazgo},
                    MOTIVACION:${this.state.motivacion},
                    OBSERVACIONES:"""${this.state.observacionesEvaluacion}"""
                    ID_PERFIL_RIESGO:${this.state.riesgoEvaluacion},
                    CONCLUSION:${this.state.radioConclusion},
                    DECISION:"""${this.state.observacionesConclusion}"""
                    FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                    FECHA:"${moment(this.state.fechaValoracion).format("YYYY-MM-DD")}"
                    ID_RESPONSABLE:${this.state.evaluadorSeleccionado.ID_EMPLEADO},
                    ID_PUESTO_RESPONSABLE:${this.state.evaluadorSeleccionado.idPuesto}
                }){ID_VALORACION}}`

            }).then(async result=>{
                
                alert("Evaluación creada correctamente")

                this.setState({
                    modalEvaluacionAbierto:false,
                    modalNuevoAbierto:false
                })

                await this.recargarEvaluaciones()

                let filaCreada=this.state.evaluaciones.find((evaluacion,index)=>{
                    if(evaluacion.ID_VALORACION===result.data.crearEmpleadosValoracion.ID_VALORACION){
                        this.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                    }
                    return evaluacion.ID_VALORACION===result.data.crearEmpleadosValoracion.ID_VALORACION

                })

                this.setState({
                    evaluacionSeleccionada:filaCreada
                })


            }).catch(err=>{
                alert("Ha ocurrido un error al crear al crear la evaluación.Pongase en contacto con el servicio tecnico")
            })

            if(this.props.modal){
                this.props.setModalEvaluacion(false)
            }

        }

        const actualizarEvaluacion=()=>{

             //validaciones

             if(!this.state.trabajadorSeleccionado.puestoActual){
                alert("El empleado a ser evaluado debe tener un puesto de trabajo.")
                return
            }
            if(!this.state.observacionesEvaluacion)return
            if(!this.state.observacionesConclusion)return
            if(!this.state.evaluadorSeleccionado)return
            if(!this.state.evaluadorSeleccionado.puestoActual){
                alert("El evaluador debe tener un puesto de trabajo.")
                return
            }

            //creacion de la evaluacion
            this.props.client.mutate({
                mutation: gql`
                mutation{actualizarEmpleadoValoracion(
                ID_VALORACION:${this.state.evaluacionSeleccionada.ID_VALORACION}
                VALORES:{
                    ID_EMPLEADO:${this.state.trabajadorSeleccionado.ID_EMPLEADO},
                    ANTIGUEDAD_PUESTO:"${this.state.trabajadorSeleccionado.antiguedad}",
                    ID_PUESTO:${this.state.trabajadorSeleccionado.idPuesto},
                    GRADO_RESPONSABILIDAD:${this.state.responsabilidad},
                    ADAPTABILIDAD:${this.state.adaptabilidad},
                    CALIDAD_TRABAJO:${this.state.calidadTrabajo},
                    CAPACIDAD_TRABAJO:${this.state.capacidadTrabajo},
                    CUMPLIDOR_OBJETIVOS:${this.state.cumplimientoObjetivos},
                    CUMPLIMIENTO_PLAZOS:${this.state.cumplimientoPlazos},
                    COMPROMISO_EMPRESA:${this.state.compromisoEmpresa},
                    CAPACIDAD_DECISION:${this.state.capacidadDecision},
                    CAPACIDAD_TRABAJO_EQUIPO:${this.state.capacidadTrabajoEquipo},
                    CONOCIMIENTOS_PROFESIONALES:${this.state.conocimientos},
                    FIABILIDAD:${this.state.fiabilidad},
                    FLEXIBILIDAD:${this.state.flexibilidad},
                    ORGANIZACION:${this.state.organizacion},
                    INICIATIVA:${this.state.iniciativa},
                    DISPONIBILIDAD_APRENDER:${this.state.disponibilidadAprender},
                    CAPACIDAD_RESOLUCION:${this.state.capacidadResolucion},
                    PUNTUALIDAD:${this.state.puntualidad},
                    LIDERAZGO:${this.state.liderazgo},
                    MOTIVACION:${this.state.motivacion},
                    OBSERVACIONES:"""${this.state.observacionesEvaluacion}"""
                    ID_PERFIL_RIESGO:${this.state.riesgoEvaluacion},
                    CONCLUSION:${this.state.radioConclusion},
                    DECISION:"""${this.state.observacionesConclusion}"""
                    FECHA_PROXIMA:"${moment(this.state.fechaProximaValoracion).format("YYYY-MM-DD")}"
                    FECHA:"${moment(this.state.fechaValoracion).format("YYYY-MM-DD")}"
                    ID_RESPONSABLE:${this.state.evaluadorSeleccionado.ID_EMPLEADO},
                    ID_PUESTO_RESPONSABLE:${this.state.evaluadorSeleccionado.idPuesto}
                }){ID_VALORACION}}`

            }).then(async result=>{
                
                alert("Evaluación actualizada correctamente")

                this.setState({
                    modalEvaluacionAbierto:false,
                    modalNuevoAbierto:false
                })

                await this.recargarEvaluaciones()

                let filaCreada=this.state.evaluaciones.find((evaluacion,index)=>{
                    if(evaluacion.ID_VALORACION===this.state.evaluacionSeleccionada.ID_VALORACION){
                        this.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                    }
                    return evaluacion.ID_VALORACION===this.state.evaluacionSeleccionada.ID_VALORACION

                })

                this.setState({
                    evaluacionSeleccionada:filaCreada
                })

            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar al crear la evaluación.Pongase en contacto con el servicio tecnico")
            })

        }

        const eliminarEvaluacion=()=>{

           //creacion de la evaluacion
           this.props.client.mutate({
               mutation: gql`
               mutation{eliminarEmpleadoValoracion(
                 ID_VALORACION:${this.state.evaluacionSeleccionada.ID_VALORACION}
              ){ID_VALORACION}}`

           }).then(async result=>{
               
               alert("Evaluación eliminada correctamente")

               this.recargarEvaluaciones()

               cerrarModal()

               this.setState({
                   evaluacionSeleccionada:null,
                   regionSeleccionada:null
               },()=>{this.tablaEvaluaciones.clearSelection()})

           }).catch(err=>{
               alert("Ha ocurrido un error al eliminar la evaluación.Pongase en contacto con el servicio tecnico")
           })

       }
      //  CONSULTAR SI PONER CAMBIO ANTIGUEDAD  
        const cambiarAntiguedad = (e) => {

            let trabajadoresACambiar = this.state.trabajadorSeleccionado.antiguedad

            let valor = e.currentTarget.value

            console.log(trabajadoresACambiar);

            this.setState({
                trabajadoresACambiar : valor
            })

            alert("Andres, no me sale lo de cambiar el valor de la array, lo dejo aqui para seguir con el UI")
        }

        const seleccionRiesgos  = (value) => { 
            this.setState({riesgoEvaluacion: value.value})
        }

        const dot = (color = '#ccc') => ({
            alignItems: 'center',
            display: 'flex',
        
            ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
            },
        });

        const estilosColorSelect = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2 ? 'gray' : 'gray'
                    : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
            },
            input: styles => ({ ...styles, ...dot() }),
            placeholder: styles => ({ ...styles, ...dot() }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
        }

        const handleResponsabilidad = (event) => {
            this.setState({responsabilidad: parseInt(event.currentTarget.value)})
        }

        const handleRadioConclusion = (event) => {
            this.setState({radioConclusion: parseInt(event.currentTarget.value)})
        }

        const handleAdaptabilidad = (e) => {
            this.setState({ adaptabilidad: e })
        }

        const handleCalidadTrabajo = (e) => {
            this.setState({ calidadTrabajo: e})
        }

        const handleCapacidadTrabajo = (e) => {
            this.setState({ capacidadTrabajo: e})
        }

        const handleCumplimientoObjetivos = (e) => {
            this.setState({ cumplimientoObjetivos: e})
        }

        const handleCumplimientoPlazos = (e) => {
            this.setState({ cumplimientoPlazos: e})
        }

        const handleCompromisoEmpresa = (e) => {
            this.setState({ compromisoEmpresa: e})
        }

        const handleCapacidadDecision = (e) => {
            this.setState({ capacidadDecision: e })
        }

        const handleCapacidadTrabajoEquipo = (e) => {
            this.setState({ capacidadTrabajoEquipo: e })
        }

        const handleConocimientosProfesionales = (e) => {
            this.setState({ conocimientos: e })
        }

        const handleFiabilidad = (e) => {
            this.setState({ fiabilidad: e })
        }

        const handleFlexibilidad = (e) => {
            this.setState({ flexibilidad: e})
        }

        const handleOrganizacion = (e) => {
            this.setState({ organizacion: e})
        }

        const handleIniciativa = (e) => {
            this.setState({ iniciativa: e })
        }

        const handleDisponibilidadAprender = (e) => {
            this.setState({ disponibilidadAprender: e})
        }

        const handleCapacidadResolucion = (e) => {
            this.setState({ capacidadResolucion: e })
        }

        const handlePuntualidad = (e) => {
            this.setState({ puntualidad: e })
        }

        const handleLiderazgo = (e) => {
            this.setState({ liderazgo: e})
        }

        const handleMotivacion = (e) => {
            this.setState({ motivacion: e})
        }

        const handleObservacionesEvaluacion = (e) => {
            this.setState({ observacionesEvaluacion: e.currentTarget.value })
        }

        const handleObservacionesConclusion = (e) => {
            this.setState({ observacionesConclusion: e.currentTarget.value })
        }

        const seleccionarEvaluador=async(val)=>{
            this.setState({evaluadorSeleccionado:val})
            // this.setState({evaluadorSeleccionado:await this.obtenerCodigo(val)})
            console.log(this.state.evaluadorSeleccionado)

        }

        // Finally, render it!
        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                
            <>
                {this.props.modal?
               
                    <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModal2}
                    title="Evaluación empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEvaluacionAbierto}
                    className="dialog-ancho"
                    >
                    <div className={Classes.DIALOG_BODY} ref={(input) => { this.Ficha= input; }}>
                        <>
                            <Card interactive={true} elevation={Elevation.TWO} >
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Datos de la persona evaluada</Callout>
                                <FormGroup
                                    label="Nombre y apellidos:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.nombre:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Código de empleado:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.CODIGO_EMPLEADO?this.state.trabajadorSeleccionado.CODIGO_EMPLEADO:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Antigüedad en el puesto:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.antiguedad:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Puesto de trabajo:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.puestoActual:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Código de puesto de trabajo:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.codigoPuesto?this.state.trabajadorSeleccionado.codigoPuesto:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Riesgo objetivo:"
                                    fill={true}
                                >
                                    <Select
                                        options={this.state.riesgoOptions}
                                        readOnly={true}
                                        className="pixelAlrededor"
                                        onChange={seleccionRiesgos}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        styles={estilosColorSelect}
                                        value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.riesgoPuesto:1))}
                                    />
                                </FormGroup>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Grado de responsabilidad asignado al puesto de trabajo</Callout>
                                <RadioGroup
                                    onChange={handleResponsabilidad}
                                    selectedValue={this.state.responsabilidad}
                                    large={true}
                                >
                                    <Radio label="1. Tareas de escasa iniciativa, sin precisar conocimientos específicos." value={1} />
                                    <Radio label="2. Tareas auxiliares con alguna iniciativa, bajo estrecha supervisión." value={2} />
                                    <Radio label="3. Tareas que precisan iniciativa y conicimientos écnicos específicos." value={3} />
                                    <Radio label="4. Tareas ejecutivas, debiendo tomar decisiones sobre los métodos a emplear." value={4} />
                                    <Radio label="5. Tareas de jefatura de otros trabajadores, con responsabilidad en el departamento." value={5} />
                                    <Radio label="6. Tareas de planeamiento y supervisión de varios departamentos." value={6} />
                                    <Radio label="7. Tareas de alta especialización con titulación superior y experiencia, asesorando a la dirección." value={7} />
                                    <Radio label="8. Interpreta y aplica políticas de empresa en varios deparamentos, reportando a la alta dirección." value={8} />
                                </RadioGroup>
                                </Card>
                                <Card interactive={true} elevation={Elevation.TWO}>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Evaluación (Valores del 1 al 5, siendo 1 la peor valoración u 5 la mejor)</Callout>
                                    <div className="pixelAlrededor">
                                        <FormGroup className="tablaTercio" label="Adaptabilidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.adaptabilidad} onValueChange={handleAdaptabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Calidad del trabajo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.calidadTrabajo} onValueChange={handleCalidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de trabajo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajo} onValueChange={handleCapacidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Cumplimiento de objetivos">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoObjetivos} onValueChange={handleCumplimientoObjetivos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Cumplimiento de plazos">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoPlazos} onValueChange={handleCumplimientoPlazos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Compromiso con la empresa">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.compromisoEmpresa} onValueChange={handleCompromisoEmpresa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de decisión">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadDecision} onValueChange={handleCapacidadDecision} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de trabajo en equipo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajoEquipo} onValueChange={handleCapacidadTrabajoEquipo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Conocimientos profesionales">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.conocimientos} onValueChange={handleConocimientosProfesionales} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Fiabilidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.fiabilidad} onValueChange={handleFiabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Flexibilidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.flexibilidad} onValueChange={handleFlexibilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Organización y orden">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.organizacion} onValueChange={handleOrganizacion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Iniciativa">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.iniciativa} onValueChange={handleIniciativa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Disponibilidad para aprender">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.disponibilidadAprender} onValueChange={handleDisponibilidadAprender} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de resolución">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadResolucion} onValueChange={handleCapacidadResolucion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Puntualidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.puntualidad} onValueChange={handlePuntualidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Liderazgo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.liderazgo} onValueChange={handleLiderazgo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Motivación">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.motivacion} onValueChange={handleMotivacion} />
                                        </FormGroup>
                                    </div>
                                    <FormGroup label="Observaciones" fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesEvaluacion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesEvaluacion} onChange={handleObservacionesEvaluacion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label="Perfil riesgo:"
                                        fill={true}
                                        className="pixelAlrededorGrande"
                                    >
                                        <Select
                                            options={this.state.riesgoOptions}
                                            className="pixelAlrededor"
                                            onChange={seleccionRiesgos}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            styles={estilosColorSelect}
                                            value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.riesgoEvaluacion))}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Conclusión</Callout>
                                    <RadioGroup
                                        onChange={handleRadioConclusion}
                                        selectedValue={this.state.radioConclusion}
                                        large={true}
                                    >
                                        <Radio label="1. Rendimiento muy insuficiente. Formación ineficaz. Sin cohesión ni compromiso con la empresa." value={1} />
                                        <Radio label="2. Rendimiento insuficiente. Puede mejorar. Valorable formación y/o cambio de puesto de trabajo." value={2} />
                                        <Radio label="3. Rendimiento óptimo. Mantener motivación. Valorar promoción y formación." value={3} />
                                        <Radio label="4. Rendimiento de referencia. Comprometido con la cultura empresarial." value={4} />
                                    </RadioGroup>
                                    <FormGroup label="Decisión propuesta" fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesConclusion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesConclusion} onChange={handleObservacionesConclusion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label="Fecha propuesta para próxima valoración:"
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFecha}
                                            value={this.state.fechaProximaValoracion}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Grado de responsabilidad asignado al puesto de trabajo</Callout>
                                    <FormGroup
                                        label="Evaluador:"
                                        fill={true}
                                    >
                                        <div style={!this.state.evaluadorSeleccionado?{border:"1px solid red"}:{}}>
                                            <Select
                                                options={this.state.trabajadorSeleccionado?this.state.empleados.filter(emp=>{return emp.ID_EMPLEADO!==this.state.trabajadorSeleccionado.ID_EMPLEADO && !emp.FECHA_BAJA}):[]}
                                                className="pixelAlrededor"
                                                onChange={seleccionarEvaluador}
                                                value={this.state.evaluadorSeleccionado}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label="Código de empleado:"
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.CODIGO_EMPLEADO?this.state.evaluadorSeleccionado.CODIGO_EMPLEADO:null} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label="Puesto de trabajo:"
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.puestoActual?this.state.evaluadorSeleccionado.puestoActual:""} onChange={cambiarAntiguedad} fill={true} />
        
                                    </FormGroup>
                                    <FormGroup
                                        label="Código de puesto de trabajo:"
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.codigoPuesto?this.state.evaluadorSeleccionado.codigoPuesto:""} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label="Fecha de la evaluación:"
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFechaActual}
                                            value={this.state.fechaValoracion}
                                        />
                                    </FormGroup>
                            </Card>
                        </>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.DANGER} className="bp3-minimal" icon="floppy-disk" onClick={this.state.nuevoActivado?añadirNuevo:actualizarEvaluacion}>
                                Guardar
                            </Button>
                            <ReactToPrint
                                trigger={() => <Button intent={Intent.DANGER} className="bp3-minimal" icon="print">Generar Documento</Button>}
                                content={() => this.Ficha}
                            />  
                        </div>
                    </div>
                    </Dialog>
            
                :
                <></> }
                    <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Seleccionar trabajador a evaluar"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    style={styles.dialog2}
                    >
                    <div className={Classes.DIALOG_BODY}>
                        <Table ref={(input) => { this.tablaTrabajadores= input; }} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarTrabajador} numRows={this.state.empleados.length} className="tablaTrabajadores" enableColumnResizing={false} columnWidths={this.state.columnWidthsTrab} onCompleteRender={() => {
                                    if (!this.state.resizedTrab) {
                                        this.resizeColumnsTrab();
                                    }
                                }}>
                            <Column name="Nombre del trabajador"  cellRenderer={cellRendererNombre}/>   
                            <Column name="Fecha de alta"  cellRenderer={cellRendererFecha}/>   
                            <Column name="Puesto de trabajo"  cellRenderer={cellRendererPuesto}/>   
                        </Table>
                    </div>
                        <br />
                        <br />
                        <br />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content="Escoger trabajador" position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.trabajadorSeleccionado !== null? abrirDialogTrabajador : () => alert("Debe seleccionar un trabajador para poder continuar")}
                                >
                                    Escoger Trabajador
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
        
                    <Dialog
                   
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal2}
                    title="Evaluación empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEvaluacionAbierto}
                    className="dialog-ancho"
                    >
                    <div className={Classes.DIALOG_BODY} ref={(input) => { this.Ficha= input; }}>
                        <>
                            <Card interactive={true} elevation={Elevation.TWO} >
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Datos de la persona evaluada</Callout>
                                <FormGroup
                                    label="Nombre y apellidos:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.nombre:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Código de empleado:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.codigoEmpleado?this.state.trabajadorSeleccionado.codigoEmpleado:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Antigüedad en el puesto:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.antiguedad:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Puesto de trabajo:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.puestoActual:null} onChange={cambiarAntiguedad} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Código de puesto de trabajo:"
                                    fill={true}
                                >
                                    <InputGroup readOnly={true} value={this.state.trabajadorSeleccionado && this.state.trabajadorSeleccionado.codigoPuesto?this.state.trabajadorSeleccionado.codigoPuesto:null} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Riesgo objetivo:"
                                    fill={true}
                                >
                                    <Select
                                        options={this.state.riesgoOptions}
                                        readOnly={true}
                                        className="pixelAlrededor"
                                        onChange={seleccionRiesgos}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        styles={estilosColorSelect}
                                        value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.trabajadorSeleccionado?this.state.trabajadorSeleccionado.riesgoPuesto:1))}
                                    />
                                </FormGroup>
                                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Grado de responsabilidad asignado al puesto de trabajo</Callout>
                                <RadioGroup
                                    onChange={handleResponsabilidad}
                                    selectedValue={this.state.responsabilidad}
                                    large={true}
                                >
                                    <Radio label="1. Tareas de escasa iniciativa, sin precisar conocimientos específicos." value={1} />
                                    <Radio label="2. Tareas auxiliares con alguna iniciativa, bajo estrecha supervisión." value={2} />
                                    <Radio label="3. Tareas que precisan iniciativa y conicimientos écnicos específicos." value={3} />
                                    <Radio label="4. Tareas ejecutivas, debiendo tomar decisiones sobre los métodos a emplear." value={4} />
                                    <Radio label="5. Tareas de jefatura de otros trabajadores, con responsabilidad en el departamento." value={5} />
                                    <Radio label="6. Tareas de planeamiento y supervisión de varios departamentos." value={6} />
                                    <Radio label="7. Tareas de alta especialización con titulación superior y experiencia, asesorando a la dirección." value={7} />
                                    <Radio label="8. Interpreta y aplica políticas de empresa en varios deparamentos, reportando a la alta dirección." value={8} />
                                </RadioGroup>
                                </Card>
                                <Card interactive={true} elevation={Elevation.TWO}>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Evaluación (Valores del 1 al 5, siendo 1 la peor valoración u 5 la mejor)</Callout>
                                    <div className="pixelAlrededor">
                                        <FormGroup className="tablaTercio" label="Adaptabilidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.adaptabilidad} onValueChange={handleAdaptabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Calidad del trabajo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.calidadTrabajo} onValueChange={handleCalidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de trabajo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajo} onValueChange={handleCapacidadTrabajo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Cumplimiento de objetivos">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoObjetivos} onValueChange={handleCumplimientoObjetivos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Cumplimiento de plazos">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.cumplimientoPlazos} onValueChange={handleCumplimientoPlazos} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Compromiso con la empresa">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.compromisoEmpresa} onValueChange={handleCompromisoEmpresa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de decisión">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadDecision} onValueChange={handleCapacidadDecision} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de trabajo en equipo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadTrabajoEquipo} onValueChange={handleCapacidadTrabajoEquipo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Conocimientos profesionales">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.conocimientos} onValueChange={handleConocimientosProfesionales} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Fiabilidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.fiabilidad} onValueChange={handleFiabilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Flexibilidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.flexibilidad} onValueChange={handleFlexibilidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Organización y orden">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.organizacion} onValueChange={handleOrganizacion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Iniciativa">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.iniciativa} onValueChange={handleIniciativa} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Disponibilidad para aprender">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.disponibilidadAprender} onValueChange={handleDisponibilidadAprender} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Capacidad de resolución">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.capacidadResolucion} onValueChange={handleCapacidadResolucion} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Puntualidad">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.puntualidad} onValueChange={handlePuntualidad} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Liderazgo">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.liderazgo} onValueChange={handleLiderazgo} />
                                        </FormGroup>
                                        <FormGroup className="tablaTercio" label="Motivación">
                                            <NumericInput min={1} max={5} clampValueOnBlur={true} value={this.state.motivacion} onValueChange={handleMotivacion} />
                                        </FormGroup>
                                    </div>
                                    <FormGroup label="Observaciones" fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesEvaluacion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesEvaluacion} onChange={handleObservacionesEvaluacion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label="Perfil riesgo:"
                                        fill={true}
                                        className="pixelAlrededorGrande"
                                    >
                                        <Select
                                            options={this.state.riesgoOptions}
                                            className="pixelAlrededor"
                                            onChange={seleccionRiesgos}
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            styles={estilosColorSelect}
                                            value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.riesgoEvaluacion))}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Conclusión</Callout>
                                    <RadioGroup
                                        onChange={handleRadioConclusion}
                                        selectedValue={this.state.radioConclusion}
                                        large={true}
                                    >
                                        <Radio label="1. Rendimiento muy insuficiente. Formación ineficaz. Sin cohesión ni compromiso con la empresa." value={1} />
                                        <Radio label="2. Rendimiento insuficiente. Puede mejorar. Valorable formación y/o cambio de puesto de trabajo." value={2} />
                                        <Radio label="3. Rendimiento óptimo. Mantener motivación. Valorar promoción y formación." value={3} />
                                        <Radio label="4. Rendimiento de referencia. Comprometido con la cultura empresarial." value={4} />
                                    </RadioGroup>
                                    <FormGroup label="Decisión propuesta" fill={true} className="pixelAlrededorGrande">
                                        <div style={!this.state.observacionesConclusion?{border:"1px solid red"}:{}}>
                                            <TextArea fill={true} value={this.state.observacionesConclusion} onChange={handleObservacionesConclusion} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label="Fecha propuesta para próxima valoración:"
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFecha}
                                            value={this.state.fechaProximaValoracion}
                                        />
                                    </FormGroup>
                                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Evaluador</Callout>
                                    <FormGroup
                                        label="Evaluador:"
                                        fill={true}
                                    >
                                        <div style={!this.state.evaluadorSeleccionado?{border:"1px solid red"}:{}}>
                                            <Select
                                                options={this.state.trabajadorSeleccionado?this.state.empleados.filter(emp=>{return emp.ID_EMPLEADO!==this.state.trabajadorSeleccionado.ID_EMPLEADO}):[]}
                                                className="pixelAlrededor"
                                                onChange={seleccionarEvaluador}
                                                value={this.state.evaluadorSeleccionado}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        label="Código de empleado:"
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.CODIGO_EMPLEADO?this.state.evaluadorSeleccionado.CODIGO_EMPLEADO:null} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label="Puesto de trabajo:"
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.puestoActual?this.state.evaluadorSeleccionado.puestoActual:""} onChange={cambiarAntiguedad} fill={true} />
        
                                    </FormGroup>
                                    <FormGroup
                                        label="Código de puesto de trabajo:"
                                        fill={true}
                                    >
                                        <InputGroup readOnly={true} value={this.state.evaluadorSeleccionado && this.state.evaluadorSeleccionado.codigoPuesto?this.state.evaluadorSeleccionado.codigoPuesto:""} fill={true} />
                                    </FormGroup>
                                    <FormGroup
                                        label="Fecha de la evaluación:"
                                        inline={true}
                                    >
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFechaActual}
                                            value={this.state.fechaValoracion}
                                        />
                                    </FormGroup>
                            </Card>
                        </>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.DANGER} className="bp3-minimal" icon="floppy-disk" onClick={this.state.nuevoActivado?añadirNuevo:actualizarEvaluacion}>
                                Guardar
                            </Button>
                            <Button intent={Intent.DANGER} className="bp3-minimal" icon="print"  active={this.state.imprimirActivadoFicha} onClick={() => this.generarFicha()}>Generar Documento</Button>

                            {/* <ReactToPrint
                                trigger={() => <Button intent={Intent.DANGER} className="bp3-minimal" icon="print">Generar Documento</Button>}
                                content={() => this.Ficha}
                            />   */}
                        </div>
                    </div>
                    </Dialog>
        
                    <Dialog transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModal}
                            title="Eliminar Evaluación"
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalBorrar}
                        >
                        <div className={Classes.DIALOG_BODY}>
                                <h2>¿Desea eliminar la evaluación seleccionada?</h2>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content="Eliminar evaluación." position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarEvaluacion}
                                    >
                                        Si
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content="Cerrar y volver." position="top">
                                    <Button onClick={cerrarModal}>No</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Registros de Evaluaciones de Desempeño"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarTabla-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirFicha}
                    title="Imprimir Valoración del rendimiento"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirFicha}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarFicha-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                {this.props.empleado? <></>:
                    <div className="pixelArriba">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="trash" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar} />
                                        <Button className="bp3-minimal" id="imprimir" icon="print" text="Imprimir" active={this.state.imprimirActivado} onClick={() => this.generarTablaImprimir()} />
                                    </ButtonGroup>
                                    
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                        />
                                </Navbar.Group>
                            </Navbar>
                            <Table ref={(input) => { this.tablaEvaluaciones= input; }}  selectedRegions={this.state.regionSeleccionada} enableMultipleSelection={false} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} onSelection={seleccionarEvaluacion} numRows={this.state.evaluaciones.length} className="tablaEvaluaciones" enableColumnResizing={false} columnWidths={this.state.columnWidths} onCompleteRender={() => {
                                    if (!this.state.resized) {
                                        this.resizeColumns();
                                    }
                                }}>
                                <Column name="Área"  cellRenderer={cellRendererArea}/> 
                                <Column name="Departamento"  cellRenderer={cellRendererDpto}/> 
                                <Column name="Nombre del trabajador"  cellRenderer={cellRendererNombreTrabEv}/> 
                                <Column name="Código puesto" cellRenderer={cellRendererCodigoPuesto}/>   
                                <Column name="Fecha Evaluación" cellRenderer={cellRendererFechaEv}/>   
                                <Column name="Fecha próxima evaluación" cellRenderer={cellRendererFechaProximaEv}/>
                                <Column name="Conclusión" cellRenderer={cellRendererConclusion}/>   
                            </Table>
                        </Card>
                    </div>
                    }                
                    </>
                }
{/*             
                </>
            } */}
            </>
        );
    }
}

export default withApollo(Evaluacion)