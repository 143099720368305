/* eslint-disable array-callback-return */
import React from "react"
import { Spinner, Dialog, FormGroup, Button, Collapse, Card, Elevation, Callout } from "@blueprintjs/core"
import pantallaInicio from '../../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from "react-apollo";
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import styles from '../stylesPrint';


class Libros extends React.Component {

    state={
        abierto1: false,
        abierto2: false,
        abierto3: false,
        variaciones:[],
        distribuciones:[],
        distribucionesActuales:[],
        fechaCapitalInicial:moment().format("DD-MM-YYYY"),
        valorTitulolInicial:0.00,
        numTitInicial:0,
        valorCapitalInicial:0.00,
        nTitulosFinal:0.00,
        valorFinal:0.00,
        cargando:true,
        imprimirActivado:false,
        cargandoImprimir:false,
    }

    
    valTitulo=0
    distribucionesActuales=[]
    dist=[]

    obtenerDistribuciones=async()=>{
        let capInicialDist=this.props.capSocialInicialDistribucion
      
       
        let variaciones=await this.props.client 
        .query({
            query:gql`
            {capSocialVariaciones(ORDEN:"FECHA,CAMBIO_VALOR_TITULO,ASC"){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.capSocialVariaciones
        })  
    
        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                ID_TRANSMISION,
                FECHA,
                ORIGEN,
                FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                TIPO_ACCION,
                NUMERO_TITULOS,
                NUMERACION_TITULOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        let capInicial=this.props.capSocialInicial

        
        let numTitulos=0
        let socios=[]

            capInicialDist.forEach(socio=> {
                numTitulos+=socio.NUMERO_TITULOS    
                socios.push(socio)
            })
            //buscamos los nombres de los socios
        for(let socio of socios){  
           console.log(socio)
            let nombre=""
            //Si es persona fisica...
            if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.props.personasFisicas.find(persona=>{
                    if (persona.ID_PERSONA===socio.ID_PERSONA){
                        nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                    }
                })


            //Si es persona juridica
            }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.props.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===socio.ID_PERSONA){
                        nombre= persona.RAZON_SOCIAL
                    }
                })

            }
            socio.nombre=nombre
        }

        //agrupacion del array de socios por ID_PERSONA
        let sociosAgrupados = [];
        await socios.reduce(function(res, value) {
            if (!res[value.ID_PERSONA]) {
                res[value.ID_PERSONA] = { ID_PERSONA: value.ID_PERSONA,NOMBRE:value.nombre, NUMERO_TITULOS: 0 };
                sociosAgrupados.push(res[value.ID_PERSONA])
            }
            res[value.ID_PERSONA].NUMERO_TITULOS += value.NUMERO_TITULOS;
            return res;
        }, {});



        let nTitulos=0
        let valorTitulo=0

        for(let variacion of variaciones){

            //variacion de incremento o decremento del número de títulos.

            if(variacion.ID_TIPO_VARIACION===1){
                //obtener las variaciones distribucion.
                let distribucion=await this.props.client
                .query({
                    query: gql`
                    {capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){ID_PERSONA,VARIACION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
                    `,fetchPolicy:'network-only'
                }).then(result=>{
                    return result.data.capSocialVariacionesDistribucion
                })
                

                for(let dis of distribucion){
                        
                        //buscamos la persona de la variacion.
                        let nombre=""
                        nTitulos+=dis.VARIACION
                        //Si es persona fisica...
                        if(dis.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                            this.props.personasFisicas.find(persona=>{
                                if(persona.ID_PERSONA===dis.ID_PERSONA){
                                    nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                                }
                            })

                        //Si es persona juridica
                        }else if(dis.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                            this.props.personasJuridicas.find(persona=>{
                                if(persona.ID_PERSONA===dis.ID_PERSONA){
                                    nombre= persona.RAZON_SOCIAL
                                }
                            })

                        }
                        dis.nombre=nombre
                } 
                
                variacion.distribucion=distribucion

            }else{

                //variación del valor del título.
                valorTitulo+=variacion.CAMBIO_VALOR_TITULO
            }

        }

        let sociosAgrupadosActuales=JSON.parse(JSON.stringify(sociosAgrupados))

        //por cada transmision sumamos los titulos.
        for(let transmision of transmisiones){
            await this.obtenerNombreMiembros(transmision.FK_ORIGEN)
            await this.obtenerNombreMiembros(transmision.FK_DESTINO)

            let existe=await sociosAgrupadosActuales.find(socio=>{
                return socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA})
            //si no existe el socios lo insertamos.
            if(!existe){
                sociosAgrupadosActuales.push({
                    "ID_PERSONA":transmision.FK_DESTINO.ID_PERSONA,
                    "NOMBRE":transmision.FK_DESTINO.nombre,
                    "NUMERO_TITULOS":transmision.NUMERO_TITULOS
                })
            }else{

                await sociosAgrupadosActuales.find(socio=>{
                    if(socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                        socio.NUMERO_TITULOS+=transmision.NUMERO_TITULOS
                    }
                })
                //si existe ya le sumamos el numero de titulos al origen
            }

            //descontamos los titulos al origen de la transmision.
            await sociosAgrupadosActuales.find(socio=>{
                if(socio.ID_PERSONA===transmision.FK_ORIGEN.ID_PERSONA){
                    socio.NUMERO_TITULOS-=transmision.NUMERO_TITULOS
                }
            })
        }




            //si tenemos capita social inicial.
            if(capInicial.length>0){
                capInicial.forEach(capital=>{

              

                    this.setState({

                        numTitInicial:numTitulos,
                        existeCapitaSocial:true,
                        valorTitulolInicial:capital.VALOR_TITULO,
                        fechaCapitalInicial:capital.FECHA_VALIDEZ,
                        valorCapitalInicial:parseFloat(capital.VALOR_TITULO*numTitulos).toFixed(2),
                        distribuciones:sociosAgrupados,
                        distribucionesActuales:sociosAgrupadosActuales,
                        nTitulosFinal:parseInt(nTitulos)+parseInt(numTitulos),
                        valorFinal:parseFloat(parseFloat(valorTitulo).toFixed(2))+parseFloat(parseFloat(capital.VALOR_TITULO).toFixed(2)),
                        variaciones:variaciones,
                        transmisiones:transmisiones,
                        cargando:false

                    })

                })
                
            }
    

    }

    //cuando se haya montado el componente lo rellenamos con sus datos.
     componentDidMount(){
        this.setState({
            cargandoL:true
        }, async () => {
             await this.obtenerDistribuciones()
             this.setState({cargandoL:false})
        })
        

    }
    generarImprimirLibro= async () =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async() =>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirLibro(), document.getElementById('generarImprimirLibro-pdf'))
            })
        })
    }
    ImprimirLibro=()=>{
        let distribucion= this.cargarDist();
    return(
		
        <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.body}>
                        <View fixed style={styles.row}>
                        <Image
                            style={styles.imageHead}
                            src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                            />
                        </View>
                        <View fixed style={styles.rowHead}>
                            <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                        </View>
                        <View fixed style={styles.rowHeadTwo}>
                            <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{this.props.i18next.t("libros.title",{ns:"page"}).toUpperCase()}</Text>
                        </View>							
							<View >
								<View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>{this.props.i18next.t("libros.capitalsocialinicial",{ns:"page"})}</Text>
                				</View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.fechacapitalinicial",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{moment(this.state.fechaCapitalInicial).format('DD-MM-YYYY')}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.valortitulo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorTitulolInicial).toFixed(2)}€</Text>
                                </View>
								<View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.numerotitulo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.numTitInicial}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.valorcapitalsocial",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.valorCapitalInicial}€</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>{this.props.i18next.t("libros.distribucion",{ns:"page"})}</Text>
                				</View>
                                {this.state.distribuciones?this.state.distribuciones.map((distribucion,index) => {
                                   return(
                                       <View key={index}>                                           
                                                <View style={styles.row}>
                                                    <Text style={styles.labelData}>{distribucion.NOMBRE}:</Text>
                                                    <Text style={styles.textData}>{distribucion.NUMERO_TITULOS},{parseFloat(this.state.valorTitulolInicial*distribucion.NUMERO_TITULOS).toFixed(2)}€</Text>
                                                </View>
                                            
                                       </View>
                                   )

                                }):<></>}
                                 <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>{this.props.i18next.t("libros.variaciones",{ns:"page"})}</Text>
                				</View>
                                {distribucion?distribucion.map((dist, index)=>{
                                    return(
                                        <View key={index} style={styles.row}>
                                            <Text style={styles.textData}>{dist}:</Text>
                                        </View>
                                    )
                                }):<></>}
                                 <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>{this.props.i18next.t("libros.capitalsocialactual",{ns:"page"})}</Text>
                				</View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.valortitulo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorFinal).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.numerotitulo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.nTitulosFinal}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("libros.valorcapital",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{(parseFloat(parseFloat(this.state.valorFinal).toFixed(2))*parseInt(this.state.nTitulosFinal)).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>{this.props.i18next.t("libros.distribucion",{ns:"page"})}</Text>
                				</View>
                                {this.state.distribucionesActuales?this.state.distribucionesActuales.map((distribuciones, index)=>{
                                    return(
                                        <View key={index} style={styles.row}>
                                            <Text style={styles.labelData}>{distribuciones.NOMBRE}:</Text>
                                            <Text style={styles.textData}>{distribuciones.NUMERO_TITULOS}, {parseFloat(this.state.valorFinal*distribuciones.NUMERO_TITULOS).toFixed(2)} € </Text>
                                        </View>
                                    )
                                }):<></>}

                            </View>     
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
     )
    }     
     cargarDist=() =>{
        this.state.variaciones.forEach((variacion,index) => {    
                            console.log(variacion)          
                            if(variacion.ID_TIPO_VARIACION===2){
                                let anterior=this.valTitulo
                                this.valTitulo=parseFloat(this.valTitulo)+parseFloat(variacion.CAMBIO_VALOR_TITULO)
                                this.dist.push("-Con fecha "+moment(variacion.FECHA).format("DD-MM-YYYY")+ " el valor del título pasó de "+parseFloat(anterior).toFixed(2)+" a "+ parseFloat(this.valTitulo).toFixed(2)) 
                                
                            }else if(variacion.ID_TIPO_VARIACION===1){
                                for(let dis of variacion.distribucion){
                                    //aumentamos en el array la variacion.
                                    this.distribucionesActuales.find((soc,index)=>{
                                        if(soc.ID_PERSONA===dis.ID_PERSONA){
                                            this.distribucionesActuales[index].NUMERO_TITULOS+=dis.VARIACION
                                        }
                                        return soc.ID_PERSONA===dis.ID_PERSONA
                                    })
                                    this.dist.push(`Con fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} el número de títulos en un número de ${Math.abs(dis.VARIACION)}
                                    títulos según las siguiente distribución:
                                         ${dis.nombre} ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} en un total de ${Math.abs(dis.VARIACION)}`)
                                    
                                }
                                
                            }                   
                        })
                        return this.dist
     }

    obtenerNombreMiembros=async(persona)=>{
        let nombre=''
        if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_PERSONA){
                    nombre=`${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })
          
         //Si es persona juridica
         }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===persona.ID_PERSONA){
                     nombre= miembro.RAZON_SOCIAL
                 }
             })
 
         }
         persona.nombre=nombre
    }

    render(){

        document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+ " | "+this.props.i18next.t("libros.title",{ns:"page"})
        const cerrarModalImprimir=()=>{
            this.setState({modalImprimir:false})
        }

        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false, abierto3: false });
        }

        const handleClick2 = () => {
            this.setState({ abierto2: !this.state.abierto2, abierto1: false, abierto3: false });
        }

        const handleClick3 = () => {
            this.setState({ abierto3: !this.state.abierto3, abierto2: false, abierto1: false });
        }

        this.valTitulo=this.state.valorTitulolInicial
        this.distribucionesActuales=this.state.distribucionesActuales
        this.dist=[]
    
        return (
            <>
                {this.state.cargandoL===true?
                    <Cargando/>
                :
                <>
                <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={this.props.i18next.t("libros.titleprint",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '600px'}} id='generarImprimirLibro-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                </Dialog>

           
              
                <div className="tablaSesenta">
                    <div>
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label={this.props.i18next.t("libros.addlibro",{ns:"page"})+":"}
                                labelFor="informacion"
                                inline={true}
                            >
                            {!this.state.cargando
                            ?
                             <Button className="bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={()=>this.generarImprimirLibro()}>{this.props.i18next.t("libros.btnprint",{ns:"page"})}</Button>
                            :
                                <></>
                            }
                        
                            </FormGroup>
                        </Card>
                    </div>
                    <div>
                </div>
                </div>
                <div className="tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>                    
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("libros.titleley",{ns:"page"})}</Callout>
                <br />
                        <div className="tablaTercio">
                            <Button icon="git-repo"onClick={handleClick1}>
                                {this.props.i18next.t("libros.articulo104",{ns:"page"})}
                            </Button>
                        </div>
                        <div className="tablaTercio">
                            <Button icon="git-repo"  onClick={handleClick2}>
                                {this.props.i18next.t("libros.articulo105",{ns:"page"})}
                            </Button>
                        </div>
                        <div className="tablaTercio">
                            <Button icon="git-repo" onClick={handleClick3}>
                                {this.props.i18next.t("libros.articulo116",{ns:"page"})}
                            </Button>
                        </div>
                    <br />
                    <br />
                        <Collapse isOpen={this.state.abierto1}>
                            <ol>
                                <li>{this.props.i18next.t("libros.data1",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data2",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data3",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data4",{ns:"page"})}</li>
                            </ol>
                            <p>{this.props.i18next.t("libros.data5",{ns:"page"})}</p>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto2}>
                            <ol>
                                <li>{this.props.i18next.t("libros.data6",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data7",{ns:"page"})}</li>
                            </ol>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto3}>
                            <ol>
                                <li>{this.props.i18next.t("libros.data8",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data9",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data10",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data11",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("libros.data12",{ns:"page"})}</li>
                            </ol>
                        </Collapse>
                    </Card>
                </div>
            </>
        }
    </>
        )
    }
}

export default withApollo(Libros)