import React, { Component } from "react";
import {Route, Switch } from "react-router-dom";
import gql from "graphql-tag";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Menu from "../Componentes/Menu"
import Inicio from "./Pantallas/Inicio"
import DatosEmpresa from "./Pantallas/DatosEmpresa"
import FormaSocietaria from "./Pantallas/FormaSocietaria"
import CapitalSocial from "./Pantallas/CapitalSocial"
import ObligacionesEmitidas from "./Pantallas/ObligacionesEmitidas"
import CuentasAnuales from "./Pantallas/CuentasAnuales"
import ProcesosTomaDecisiones from "./Pantallas/ProcesosTomaDecisiones"
import OrganoAdministracion from "./Pantallas/OrganoAdministracion"
import OrganoCompliance from "./Pantallas/OrganoCompliance"
import CreditosSocios from "./Pantallas/CreditosSocios";
import PolizasSeguro from "./Pantallas/PolizasSeguro";
import Contratos from "./Pantallas/Contratos";
import ModificacionesEstatutarias from "./Pantallas/ModificacionesEstatutarias"
import Actividades from "./Pantallas/Actividades"
import Instalaciones from "./Pantallas/Instalaciones"
import RSC from "./Pantallas/RSC"
import PersonalAltaDireccion from "./Pantallas/PersonalAltaDireccion"
import Apoderados from "./Pantallas/Apoderados"
import ConsejerosDelegados from "./Pantallas/ConsejerosDelegados"
import Proveedores from "./Pantallas/Proveedores"
import AcreedoresDiversos from "./Pantallas/AcreedoresDiversos"
import Clientes from "./Pantallas/Clientes"
import ServiciosEmpresariales from "./Pantallas/ServiciosEmpresariales"
import ServiciosProfesionales from "./Pantallas/ServiciosProfesionales"
import AsesoriasExternas from "./Pantallas/AsesoriasExternas"
import Auditores from "./Pantallas/Auditores"
import FondosInversion from "./Pantallas/FondosInversion"
import SociosGestores from "./Pantallas/SociosGestores"
import PartesInteresadas from "../Contenedores/PartesInteresadas"
import ProtocoloFamiliar from "./Pantallas/ProtocoloFamiliar"
import JointVentures from "./Pantallas/JointVentures"
import PrivateEquity from "./Pantallas/PrivateEquity"
import Comisiones from "./Pantallas/Comisiones"
import CalendarioReuniones from "./Pantallas/CalendarioReuniones"
import CalendarioActas from "./Pantallas/CalendarioActas"
import Actas from "./Pantallas/Actas"
import CodigoEtico from "./Pantallas/CodigoEtico"
import CuadroMando from "./Pantallas/CuadroMando"
import LibroSocios from "./Pantallas/LibroSocios"
import Alertas from "./Pantallas/Alertas"
import InformeAnual from "./Pantallas/InformeAnual"
import Evaluacion from "./Pantallas/Evaluacion"
import Organigrama from "./Pantallas/Organigrama"
import CatalogoPuestos from "./Pantallas/CatalogoPuestos"
import ConceptoAlcance from "./Pantallas/ConceptoAlcance"
import OrganosInstruccion from "./Pantallas/OrganosInstruccion"
import OrganosResolucion from "./Pantallas/OrganosResolucion"
import EdicionRiesgo from "./Pantallas/EdicionRiesgo"
import IdentificacionRiesgo from "./Pantallas/IdentificacionRiesgo"
import Seguimiento from "./Pantallas/Seguimiento"
import Denuncias from "./Pantallas/Denuncias"
import Siniestros from "./Pantallas/Siniestros"
import Logotipo from "./Pantallas/Logotipo"
import PerfilesAcceso from "./Pantallas/PerfilesAcceso"
import GestionUsuarios from "./Pantallas/GestionUsuarios"
import Legislacion from "./Pantallas/Legislacion"
import Jurisprudencia from "./Pantallas/Jurisprudencia"
import Links from "./Pantallas/Links"
import EstructuraOrganizativa from "./Pantallas/EstructuraOrganizativa"
import RecursosHumanos from "./Pantallas/RecursosHumanos"
import EstructuraSocietaria from "./Pantallas/EstructuraSocietaria"
import AcuerdosParasociales from "./Pantallas/AcuerdosParasociales"
import Documentos from "./Pantallas/Elementos/Documentos"
import ContratoCompliance from "./Pantallas/ContratoCompliance"
import DocumentosCompliance from "./Pantallas/Elementos/DocumentosCompliance"
import Certificado from "./Pantallas/Cerficado"
import GobiernoCorporativo from "./Pantallas/GobiernoCorporativo"
import Identificacion from "./Pantallas/Identificacion"
import ControlCumplimiento from "./Pantallas/ControlCumplimiento"
import Doctrina from "./Pantallas/Doctrina"
import _404 from "./Pantallas/_404"
import ServiciosIndependientes from "./Pantallas/ServiciosIndependientes"
import OtrasPartesInteresadas from "./Pantallas/OtrasPartesInteresadas"
import InformacionNoFinanciera from "./Pantallas/InformacionNoFinanciera"
import PerfilUsuario from "./Pantallas/PerfilUsuario"
import Compliance from "./Pantallas/Compliance"
import Ute from "./Pantallas/Ute"
import Administradores from "./Pantallas/Administradores"
import Consejeros from "./Pantallas/Consejeros"
import OrganoInstruccion from "./Pantallas/OrganosInstruccion"
import OrganoResolucion from "./Pantallas/OrganosResolucion"
import CuadroMandoDocumentacion from "./Pantallas/CuadroMandoDocumentacion"
import Fichajes from "./Pantallas/Fichajes"
import GestionFichajes from "./Pantallas/GestionFichajes"
import ExportarDatos from "./Pantallas/ExportarDatos";
import RegistrosRetributivos from "./Pantallas/RegistrosRetributivos";
import RecursosHumanosInformes from "./Pantallas/RecursosHumanosInformes";
import ValoracionPuestosTrabajo from "./Pantallas/ValoracionPuestos";
import Procedimientos from "./Pantallas/Procedimientos";
import ObligacionesContractuales from "./Pantallas/ObligacionesContractuales";
import InformeEmpresa from "./Pantallas/InformeEmpresa";
import InformeCompliance from "./Pantallas/InformeCompliance";
import { withApollo } from "react-apollo";
import ImportarTrabajadores from "./Pantallas/ImportarTrabajadores";

class AreaTrabajo extends Component {
  state={
	editable:true,
	alertaAsunto: "", 
	alertaTexto: "", 
	alertaPersistente: "", 
	abrirAlerta: true
  }
  setEditable=(editable)=>{
	localStorage.setItem('editable', editable);
  }


  componentDidMount=async(props)=>{
	await this.mostrarAlertas()
  }
 
  mostrarAlertas=async()=>{

	let usuarioActual=await this.props.client
	.query({
		query: gql`
		{obtenerUsuarioActual}
		`,
		fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.obtenerUsuarioActual
	});

	let alertasPublicas=await this.props.client
	.query({
		query: gql`
		{alertas(FILTROS:{PUBLICA:true},CAMPOS:"ASUNTO,TEXTO,PERSISTENTE,PUBLICA,DESDE,HASTA,TIPO"){ASUNTO,TEXTO,PERSISTENTE,PUBLICA,DESDE,HASTA,TIPO}}
		`,
		fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.alertas
	})

	alertasPublicas=await alertasPublicas.filter(alerta=>{
	  return (alerta.DESDE===null && alerta.HASTA===null) || (new Date(alerta.DESDE)<new Date() && alerta.HASTA===null)  || (alerta.DESDE===null && new Date(alerta.HASTA)>new Date()) || (new Date(alerta.DESDE)<new Date() && new Date(alerta.HASTA)>new Date())
	})

	alertasPublicas.forEach(alerta=>{
		if (alerta.TIPO==='ADVERTENCIA') {
			NotificationManager.warning(`${alerta.TEXTO}`, `${alerta.ASUNTO}`);
		} else if (alerta.TIPO==='ERROR') {
			NotificationManager.error(`${alerta.TEXTO}`, `${alerta.ASUNTO}`);
		} else {
			NotificationManager.info(`${alerta.TEXTO}`, `${alerta.ASUNTO}`);
		}
	})

	let alertasPrivadas=await this.props.client
	.query({
		query: gql`
		{alertas(FILTROS:{PUBLICA:false,ID_USUARIO:${usuarioActual}},CAMPOS:"ASUNTO,TEXTO,PERSISTENTE,PUBLICA,DESDE,HASTA,TIPO"){ASUNTO,TEXTO,PERSISTENTE,PUBLICA,DESDE,HASTA,TIPO}}
		`,
		fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.alertas
	})

	alertasPrivadas=alertasPrivadas.filter(alerta=>{
	  return (alerta.DESDE===null && alerta.HASTA===null) || (new Date(alerta.DESDE)<new Date() && alerta.HASTA===null)  || (alerta.DESDE===null && new Date(alerta.HASTA)>new Date()) || (new Date(alerta.DESDE)<new Date() && new Date(alerta.HASTA)>new Date())
	})

	alertasPrivadas.forEach(alerta=>{
		if (alerta.TIPO==='ADVERTENCIA') {
			NotificationManager.warning(`${alerta.TEXTO}`, `${alerta.ASUNTO}`);
		} else if (alerta.TIPO==='ERROR') {
			NotificationManager.error(`${alerta.TEXTO}`, `${alerta.ASUNTO}`);
		} else {
			NotificationManager.info(`${alerta.TEXTO}`, `${alerta.ASUNTO}`);
		}
	})

  }

  cerrarAlerta = () => {
	  this.setState({
		  abrirAlerta: false,
	  })
  }

  render() {
	return (
			<>
			<NotificationContainer/>
			<Menu i18next={this.props.i18next} setEditable={this.setEditable} recargarMenu={this.props.recargarMenu} setMenuSeleccionado={this.props.setMenuSeleccionado}/>
			
			<Switch>
				<Route exact path="/" component={() => <Inicio editable={localStorage.getItem('editable')==="false"?false:true} />} />
				<Route path="/estructura-organizativa" component={() => <EstructuraOrganizativa editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/estructura-societaria" component={() => <EstructuraSocietaria editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/acuerdos-parasociales" component={() => <AcuerdosParasociales editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/recursos-humanos" component={() => <RecursosHumanos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/empresa" component={() => <DatosEmpresa editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/forma-societaria" component={() => <FormaSocietaria editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/capital-social" component={() => <CapitalSocial editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/obligaciones-emitidas" component={() => <ObligacionesEmitidas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/cuentas-anuales" component={() => <CuentasAnuales editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/toma-decisiones" component={() => <ProcesosTomaDecisiones editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/organo-administracion" component={() => <OrganoAdministracion editable={localStorage.getItem('editable')==="false"?false:true}/>}/>
				<Route path="/organo-compliance" component={() => <OrganoCompliance editable={localStorage.getItem('editable')==="false"?false:true}/>}/>
				<Route path="/garantias" component={() => <CreditosSocios editable={localStorage.getItem('editable')==="false"?false:true}/> } />
				<Route path="/programas-seguro" component={() => <PolizasSeguro editable={localStorage.getItem('editable')==="false"?false:true}/> }/>
				<Route path="/contratos" component={() => <Contratos editable={localStorage.getItem('editable')==="false"?false:true}/>}/>
				<Route path="/modificaciones-estatutos" component={() => <ModificacionesEstatutarias editable={localStorage.getItem('editable')==="false"?false:true}/>}/>
				<Route path="/actividades" component={() => <Actividades editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/instalaciones" component={() => <Instalaciones editable={localStorage.getItem('editable')==="false"?false:true}/>}/>
				<Route path="/rsc" component={() => <RSC editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/personal-alta-direccion" component={ () => <PersonalAltaDireccion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/apoderados" component={ () => <Apoderados editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/consejeros-delegados" component={ () => <ConsejerosDelegados editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/proveedores" component={ () => <Proveedores editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/acreedores-diversos" component={ () => <AcreedoresDiversos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/clientes" component={ () => <Clientes editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/servicios-independientes-empresariales" component={ () => <ServiciosEmpresariales editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/servicios-independientes-profesionales" component={ () => <ServiciosProfesionales editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/asesorias-externas" component={ () => <AsesoriasExternas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/auditores" component={ () => <Auditores editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/fondos-inversion" component={ () => <FondosInversion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/socios-gestores" component={ () => <SociosGestores editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/partes-interesadas" component={ () => <PartesInteresadas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/protocolo-familiar" component={ () => <ProtocoloFamiliar editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/joint-ventures" component={ () => <JointVentures editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/private-equity" component={ () => <PrivateEquity editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/comisiones" component={ () => <Comisiones editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/reuniones" component={ () => <CalendarioReuniones editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/actas_old" component={ () => <CalendarioActas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/actas" component={ () => <Actas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/codigo-etico" component={ () => <CodigoEtico editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/reglamento-conducta" component={ () => <CodigoEtico editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/manual-penales" component={ () => <CodigoEtico editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/cuadro-mando" component={ () => <CuadroMandoDocumentacion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/cuadro-mando-riesgos" component={ () => <IdentificacionRiesgo editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/cuadro-gobierno-corporativo" component={ () => <CuadroMando editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/libros" component={ () => <LibroSocios editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/libro-registro-acciones-nominativas" component={() => <LibroSocios editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/libro-actas" component={() => <LibroSocios editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/libro-actas-consejo" component={() => <LibroSocios editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/alertas-gestion" component={() => <Alertas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/informe-anual-gc" component={() => <InformeAnual editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/evaluaciones-empleado" component={() => <Evaluacion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/organigrama" component={() => <Organigrama editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/catalogo-puestos" component={() => <CatalogoPuestos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/compliance-concepto" component={() => <ConceptoAlcance editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/compliance-organo-instruccion" component={() => <OrganosInstruccion  editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/compliance-organo-resolucion" component={() => <OrganosResolucion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/edicion-riesgos" component={() => <EdicionRiesgo editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/identificacion" component={() => <Identificacion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/seguimiento" component={() => <Seguimiento editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/denuncias" component={() => <Denuncias editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/incidencias-siniestros" component={() => <Siniestros  editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/logotipo" component={() => <Logotipo  editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/perfiles" component={() => <PerfilesAcceso  editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/gestion-usuarios" component={() => <GestionUsuarios  editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/legislacion" component={() => <Legislacion  editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/jurisprudencia" component={() => <Jurisprudencia editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/links" component={() => <Links editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/documentos" component={() => <Documentos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/declaraciones-previas" component={() => <DocumentosCompliance editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/contrato-compliance" component={() => <ContratoCompliance editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/certificado" component={() => <Certificado editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/gobierno-corporativo" component={() => <GobiernoCorporativo editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/identificacion-riesgos" component={() => <IdentificacionRiesgo editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/control-cumplimiento" component={() => <ControlCumplimiento editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/doctrina" component={() => <Doctrina editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/administradores" component={() => <Administradores editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/consejeros" component={() => <Consejeros editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/servicios-independientes" component={() => <ServiciosIndependientes editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/otras-partes-interesadas" component={() => <OtrasPartesInteresadas editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/informe-no-financiera" component={() => <InformacionNoFinanciera editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/union-empresas" component={() => <Ute editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/perfil" component={() => <PerfilUsuario editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/compliance" component={() => <Compliance editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/organo-instruccion" component={() => <OrganoInstruccion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/organo-resolucion" component={() => <OrganoResolucion editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/fichajes" component={() => <Fichajes editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/gestion-fichajes" component={() => <GestionFichajes editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/exportar-datos" component={() => <ExportarDatos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/registros-retributivos" component={() => <RegistrosRetributivos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/recursos-humanos-informes" component={() => <RecursosHumanosInformes editable={localStorage.getItem('editable')==="false"?false:true}/>} />
        		<Route path="/valoracion-puestos" component={() => <ValoracionPuestosTrabajo editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/procedimientos" component={()=> <Procedimientos editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/obligaciones-contractuales" component={() => <ObligacionesContractuales editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/informe-empresa" component={() => <InformeEmpresa editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/informe-compliance" component={() => <InformeCompliance editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route path="/recursos-humanos-importar" component={() => <ImportarTrabajadores editable={localStorage.getItem('editable')==="false"?false:true}/>} />
				<Route component={_404} />
		</Switch>
	  </>
	);
  }
}export default withApollo(AreaTrabajo)
