
import React from "react"
import {Card, Elevation, FormGroup, Dialog, TextArea, Classes, NavbarDivider, Tooltip, AnchorButton, Intent, Navbar, ButtonGroup, Button, InputGroup, Alignment, FileInput} from "@blueprintjs/core"
import gql from "graphql-tag";
import axios from 'axios';
import { withApollo } from "react-apollo";
import { Cargando } from "../Cargando";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import global from './Global';

 class Memorias extends React.Component {

    state = {
        modalAbierto: false,
        modalBorrarAbierto: false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        nombre:null,
        nuevoActivado:true,
        editarActivado:false,
        borrarActivado:false,
        descripcion:null,
        adjuntoSeleccionado:null,
        archivo:null,
        controlesDesactivados:false,
        adjuntos: [
        ],
        cargando: false,
        cargandoMemoria:false
    }

    recargarAdjuntos=async()=>{

        await this.props.client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:7}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto,index)=>{
                let extension=global.obtenerTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({adjuntos:copyAdjuntos,
            cargandoMemoria:false})

        })
    }

    componentDidMount(props){

        this.setState({cargando: true})

        this.recargarAdjuntos();
        
        this.setState({cargando: false})
    }
    render() {
        
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                cargandoMemoria:true
            });

        }
        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({
                nombre:this.state.adjuntoSeleccionado.nombre,
                descripcion:this.state.adjuntoSeleccionado.descripcion,
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                cargandoMemoria:true
            })
    
        }
        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({
                modalBorrarAbierto: true,
                modalAbierto: false,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true
            })
 
        }

        const anadirNuevo = () => {

            if(!this.state.archivo)return
            if(!this.state.nombre)return
            if(!this.state.descripcion)return

            let extension=global.obtenerTipo("."+this.state.archivo.name.split(".").pop())

            this.state.adjuntos.push({
                "nombre": this.state.nombre?this.state.nombre:this.state.nombre,
                "tipo": "Memoria",
                "idTipo":7,
                "mime": extension
            })
            
            this.setState({
                pendienteGuardar:true,
                controlesDesactivados:true
            })
            this.setState({})
            cerrarModal()
        }

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            const api = axios.create({
                withCredentials: true
            });

            if(this.state.pendienteGuardar){
              
                var formData = new FormData();
                formData.append("files",this.state.archivo);
                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:7,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then(async(response)=> {
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    await this.recargarAdjuntos()
                    //seleccionamos el creado
                    
                    this.setState({
                        controlesDesactivados:false,
                        archivo:null,
                        pendienteGuardar:false,
                    })
           

                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                })

              

            }else if(this.state.pendienteActualizar){

                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre}",ID_TIPO_ADJUNTO:${7},ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`).then( async(response)=> {
                    // handle success
                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    await this.recargarAdjuntos()
                    //seleccionamos el creado
                    this.setState({
                        controlesDesactivados:false,
                        pendienteActualizar:false,
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }else if(this.state.pendienteBorrar){

                api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then((response)=>{
                    // handle success  
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.recargarAdjuntos();
                    this.setState({controlesDesactivados:false})
                    this.setState({pendienteBorrar:false})
                    this.setState({adjuntoSeleccionado:null})
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

            }

        }

        const cancelarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                pendienteActualizar:false,
                pendienteGuardar:false,
                pendienteBorrar:false,
                controlesDesactivados:null,
            });

            this.recargarAdjuntos()

        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                cargandoMemoria:false
            })
        }

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const ActualizarAdjunto = () => {
           
            if(!this.state.nombre)return
            if(!this.state.descripcion)return      

            let adjuntos=this.state.adjuntos
            //si hemos rellenado los dos campos, agregamos los valores al listado.
            this.state.adjuntos.find((adjunt,index) =>{
                if(adjunt.id===this.state.adjuntoSeleccionado.id){
                    adjuntos[index]["nombre"]=this.state.nombre?this.state.nombre:this.state.adjuntoSeleccionado.nombre
                }
                return adjunt.id===this.state.adjuntoSeleccionado.id
            })
            this.setState({adjuntos:adjuntos})
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            cerrarModal()

        };

        const eliminarAdjunto = () => {
            if(!this.state.adjuntoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            var adjuntos=this.state.adjuntos.filter(adjunto => adjunto.id !== this.state.adjuntoSeleccionado.id)
            this.setState({adjuntos:adjuntos})
            cerrarModal()
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
        };
 
        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({archivo:ev.target.files[0]})
            this.setState({tipoArchivo:ev.target.files[0].type})
            this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    adjuntoSeleccionado: row
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        const columns = [
            {
                dataField: 'nombre',
                text: i18next.t("empresa.tabdocumentos.memorias.name",{ns:"company"}),
                sort: true
            },
            {
                dataField: 'tipo',
                text: i18next.t("empresa.tabdocumentos.memorias.type",{ns:"company"}),
                sort: true
            },
            {
                dataField: 'mime',
                text: i18next.t("empresa.tabdocumentos.memorias.mime",{ns:"company"}),
                sort: true
            }
        ];
        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("addnew",{ns:"global"})+" "+i18next.t("empresa.tabdocumentos.memorias.file",{ns:"company"}):i18next.t("edit",{ns:"global"})+" "+i18next.t("empresa.tabdocumentos.memorias.file",{ns:"company"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    > 
                        <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.nuevoActivado?
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label={i18next.t("empresa.tabdocumentos.memorias.file",{ns:"company"})}
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.archivo?"":i18next.t("selectempty",{ns:"global"})+i18next.t("empresa.tabdocumentos.memorias.file",{ns:"company"})}
                                >
                                    <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                                </FormGroup>
                            </div>
                            :<></>
                        }
                        
                        <div className="pixelAlrededor">
                        </div>
                        <div className="pixelAlrededor">
                            <FormGroup
                                    label={i18next.t("empresa.tabdocumentos.memorias.namedoc",{ns:"company"})}
                                    labelFor="informacion"
                                    intent="danger"
                                    helperText={this.state.nombre?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("empresa.tabdocumentos.memorias.namedoc",{ns:"company"})}
                            >
                                <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre} placeholder={i18next.t("enterfield",{ns:"global"})+i18next.t("empresa.tabdocumentos.memorias.namedoc",{ns:"company"})} intent={this.state.nombre?"primary":"danger"} />
                            </FormGroup>
                        </div>
                        <div className="pixelAlrededor">
                            <FormGroup
                                    label={i18next.t("empresa.tabdocumentos.memorias.description",{ns:"company"})}
                                    labelFor="descripcion"
                                    intent="danger"
                                    helperText={this.state.descripcion?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("empresa.tabdocumentos.memorias.description",{ns:"company"})}
                            >
                                <TextArea
                                    large={true}
                                    fill={true}
                                    intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                                    onChange={handleCambiarDescripcion}
                                    defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.descripcion:""}
                                />                    
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirNuevo:ActualizarAdjunto}
                                >
                                    {i18next.t("aceptar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Card>
                    </Dialog>
                    
                    <Dialog transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="warning-sign"
                            onClose={cerrarModal}
                            title={i18next.t("empresa.tabdocumentos.memorias.titledel",{ns:"company"})}
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalBorrarAbierto}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <h2>{i18next.t("empresa.tabdocumentos.memorias.questiondel",{ns:"company"})}</h2>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("eliminar",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarAdjunto}
                                    >
                                        {i18next.t("yes",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                {this.state.controlesDesactivados?[
                                    <>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled />
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled />
                                        <NavbarDivider />
                                        <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled/>
                                    </>
                                ]:[
                                    <>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <NavbarDivider />
                                        <Button className="bp3-minimal" icon="document-open" text={i18next.t("openfile",{ns:"global"})} disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                                    </>
                                ]}
                            </ButtonGroup>
                                <Button 
                                    alignText="right"
                                    icon="floppy-disk"
                                    text={i18next.t("guardar",{ns:"global"})}
                                    intent="danger"
                                    onClick={guardarCambios}
                                    className="iconoDerecha bp3-minimal"
                                    disabled={!this.state.controlesDesactivados}
                                />
                                <Button 
                                    alignText="right"
                                    icon="cross"
                                    intent="danger"
                                    text={i18next.t("cancelar",{ns:"global"})}
                                    onClick={cancelarCambios}
                                    disabled={!this.state.controlesDesactivados}
                                    className="iconoDerecha bp3-minimal"
                                />
                        </Navbar.Group>
                    </Navbar>
                    {this.state.cargandoMemoria?
                        <Cargando />
                    :
                        <BootstrapTable
                            keyField='id'
                            data={ this.state.adjuntos }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            defaultSorted={ const_table.defaultSorted('nombre','asc') }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    }
                </>
            }
            </>
        )
    }
}
export default withTranslation()(withApollo(Memorias))



    



