/* eslint-disable array-callback-return */
import React from "react"
import {Spinner, Button, Dialog, InputGroup, Navbar,FormGroup, TextArea, Classes, Tooltip, AnchorButton, Intent, Switch, Elevation, Tabs, Tab } from "@blueprintjs/core"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import { Callout, Card } from "@blueprintjs/core";
import moment from "moment";
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";

import styles from '../stylesPrint';
import { DatosPersonasFisicas } from "./DatosPersonasFisicas";
import InformacionGeneralNuevo from "./InformacionGeneralNuevo";
import MediosContacto from "./MediosContacto";
import CentrosTrabajo from "./CentrosTrabajo";
import ArchivosAdjuntos from "./ArchivosAdjuntos";
import Documentos from "./Documentos";
import Vinculos from "./Vinculos";


let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


export default class OtrosDatos extends React.Component {

    state={
        socios:[],
        modalAbierto: false,
        socioSeleccionado: null,
        prestaciones: null,
        ventajas: null,
        excluido: false,
        cargandoImprimir:false,
        cargandoImprimirGral:false,
        modalImprimir:false,
        modalImprimirGral: false,
        modalEditarPersona: false,
        editarPersonaActivado: false,
        modalEditarPersonaJuridica: false,
        cargandoPersonas: false,
        cargandoGrid:false
    }

    
   cargarSocios=async()=>{
    let personasFisicas= await client
    .query({
        query: gql`
            {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
    return result.data.personasFisicas
    }) 
    let personasJuridicas=  await client
    .query({
        query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.personasJuridicas
    })          
    let documentos = await client
    .query({
        query: gql`
        {
            documentosIdentificativos{
                ID_TIPO_DOCUMENTO,
                ID_PERSONA,  
                VALOR
            }
        }
        `,fetchPolicy:'network-only'
    })
        .then(result => {       
        return result.data.documentosIdentificativos;
    });
    let transmisiones=await client.query({
        query:gql`{capSocialTransmisiones{
            FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
        }}`,fetchPolicy:'network-only'
    }).then(async(result)=>{
        return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
    })
    let otrosDatos= await client
    .query({
        query: gql`
        {sociosOtrosDatos{
            ID_SOCIO,
            PRESTACIONES_ACCESORIAS,
            EXCLUIDO,
            VENTAJAS,
            }
        }
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.sociosOtrosDatos
    })
    let capitalActual=await client
    .query({
        query: gql`
            {capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.capSocialActualDistribucion
    })        
            let socios=[]
            for(let socio of this.props.capSocialInicialDistribucion){
               
                let nombreSocio=""
                let id = 0
                let tipo_persona = ""
                let documento= ""

                //Si es persona fisica...
             
                if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    personasFisicas.find(persona => {
                        if(persona.ID_PERSONA===socio.ID_PERSONA){
                            documentos.forEach(doc => {                                
                                if (doc.ID_TIPO_DOCUMENTO === 1 && doc.ID_PERSONA === socio.ID_PERSONA) {
                                    documento= doc.VALOR                                    
                                }
                            })
                            
                                id = persona.ID_PERSONA
                                tipo_persona= socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                                nombreSocio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                                socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento
                                })       
                            
                        }
                    })
                

                }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    personasJuridicas.find(persona => {
                        if (persona.ID_PERSONA === socio.ID_PERSONA) {                            
                            documentos.forEach(doc=>{
                                if(doc.ID_TIPO_DOCUMENTO===4 && doc.ID_PERSONA===socio.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })
                            id = persona.ID_PERSONA
                            tipo_persona= socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                            nombreSocio=persona.RAZON_SOCIAL
                            socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento})
                        }
                    })
                }

            }
            for(let transmision of transmisiones){
                let nombreSocio=""
                let id = 0
                let tipo_persona = ""
                let documento= ""
                //Si es persona fisica...
                if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    // eslint-disable-next-line array-callback-return
                    personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if( doc.ID_TIPO_DOCUMENTO===1 && doc.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                                    documento = doc.VALOR                            
                                }
                            })
                            
                                    id = persona.ID_PERSONA
                                    tipo_persona=transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA
                                    nombreSocio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                                    socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento
                                })
                        }
                    })
                   //Si es persona juridica
                }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if( doc.ID_TIPO_DOCUMENTO===4 && doc.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })      

                                    id=persona.ID_PERSONA
                                    tipo_persona=transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA
                                    nombreSocio=persona.RAZON_SOCIAL
                                    socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento
                                })
                        }
                    })
                }

            }
           
            for(let socActual of capitalActual){
                let nombreSocio=""
                let id = 0
                let tipo_persona = ""
                let documento= ""
                if(socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    // eslint-disable-next-line array-callback-return
                    personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===socActual.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if(doc.ID_TIPO_DOCUMENTO===1 && doc.ID_PERSONA===socActual.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })
                            id = persona.ID_PERSONA
                            tipo_persona= socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                            nombreSocio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                            socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,"docIdentificativo":documento})
                        
                        }
                    })
                }else if(socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===socActual.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if(doc.ID_TIPO_DOCUMENTO===4 && doc.ID_PERSONA===socActual.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })
                            id = persona.ID_PERSONA
                            tipo_persona= socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                            nombreSocio=persona.RAZON_SOCIAL
                            socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento})
                        }
                    })
                }
            }

            let socio= socios.filter((e, i) => {
                return socios.findIndex((x) => {
                return x.id ===  e.id}) === i;
            });

            for(let soc of socio){
                //    Obtenemos los socios otros datos.
                if(otrosDatos.length>0){
                    otrosDatos.find(datos=>{
                        if(datos.ID_SOCIO===soc.id){
                            soc.datosSocios=datos
                        }
                    })       
                }
       }
       this.setState({ socios: socio })
                

   }

    generarImprimirOD= async()=>{
        this.setState({cargandoImprimir:true, modalImprimir:true}, async() =>{
            this.setState({cargandoImprimir:false},() =>{
                ReactDOM.render(this.ImprimirOD(), document.getElementById('generarImprimir-pdf'))
            })
        })
    }

    ImprimirOD=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.body}>
                    <View fixed style={styles.row}>
                    <Image
                        style={styles.imageHead}
                        src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                        />
                    </View>
                    <View fixed style={styles.rowHead}>
                        <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                        <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                    </View>
                    <View fixed style={styles.rowHeadTwo}>
                        <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                        <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                    </View>
                    <View style={styles.rowTitleOne}>
                        <Text style={styles.labelTitle}>{this.props.i18next.t("otrosdatos.title",{ns:"page"}).toUpperCase()}</Text>
                    </View>
                        {this.state.socioSeleccionado?
                             
                            <View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("otrosdatos.socio",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.nombre?this.state.socioSeleccionado.nombre:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("otrosdatos.prestaciones",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.datosSocios?this.state.socioSeleccionado.datosSocios.PRESTACIONES_ACCESORIAS:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("otrosdatos.ventajassocio",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.datosSocios?this.state.socioSeleccionado.datosSocios.VENTAJAS:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("otrosdatos.excluido",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.datosSocios && this.state.socioSeleccionado.datosSocios.EXCLUIDO===true?this.props.i18next.t("yes",{ns:"global"}).toUpperCase():this.props.i18next.t("no",{ns:"global"}).toUpperCase()}</Text>
                                </View>
                                
                            </View>
                                                  
                        :<></>}      
                </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>   
    )

    generarImprimirGral= async() =>{
        this.setState({
            modalImprimirGral:true,
            cargandoImprimirGral:true
        }, async () =>{
            this.setState({
                cargandoImprimirGral:false
            }, () =>{
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generarImprimirGral-pdf'))
            })
        })
    }

    ImprimirGral=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("otrosdatos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.socios?this.state.socios.map((socio, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("otrosdatos.socio",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>{this.props.i18next.t("otrosdatos.nif",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.nacionalidad",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.domicilio",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.localidad",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.fechaalta",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.fechabaja",{ns:"page"}).toUpperCase()}</Text>                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.observaciones",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.prestaciones",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("otrosdatos.ventajas",{ns:"page"}).toUpperCase()}</Text>                                                                
                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{socio.nombre?socio.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{socio.docIdentificativo?socio.docIdentificativo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.nacionalidad?socio.nacionalidad.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.domicilio?socio.domicilio:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.localidad?socio.localidad:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.fecha_alta?moment(socio.fecha_alta).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.fecha_baja?moment(socio.fecha_baja).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.observaciones?socio.observaciones:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.datosSocios && socio.datosSocios.PRESTACIONES_ACCESORIAS?"SI":"NO"}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.datosSocios && socio.datosSocios.VENTAJAS?"SI":"NO"}</Text>
                                 </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    componentDidMount(){
        this.setState({cargando:true}, async()=>{
            await this.cargarSocios()
            this.setState({cargando:false})
        })
    }
    

    render(){

        document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("otrosdatos.title",{ns:"page"})
        
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirGral:false,
                modalImprimir:false,
                cargandoGrid:false
            })
        }

        function nacionalidadFormatter(cell, row) {
            return (
             (cell)?cell.DESCRIPCION:""
            );
        }

        function dateFormatter(cell, row) {
        return (
        (cell)?moment(cell).format("DD/MM/YYYY"):""
        );
        }

        const si=this.props.i18next.t("yes",{ns:"global"}).toUpperCase();
        const no=this.props.i18next.t("no",{ns:"global"}).toUpperCase();

        function boolFormatter(cell, row) {
            return (
            (cell)?si:no
            );
        }

        const columns = [
        {
            dataField: 'nombre',
            text: this.props.i18next.t("otrosdatos.socio",{ns:"page"})
        },
        {
            dataField: 'docIdentificativo',
            text: this.props.i18next.t("otrosdatos.nif",{ns:"page"})
        },
        {
            dataField: 'nacionalidad',
            text: this.props.i18next.t("otrosdatos.nacionalidad",{ns:"page"}),
            formatter: nacionalidadFormatter
        },
        {
            dataField: 'domicilio',
            text: this.props.i18next.t("otrosdatos.domicilio",{ns:"page"})
        },
        {
            dataField: 'localidad',
            text: this.props.i18next.t("otrosdatos.localidad",{ns:"page"})
        },
        {
            dataField: 'fecha_alta',
            text: this.props.i18next.t("otrosdatos.fechaalta",{ns:"page"}),
            formatter: dateFormatter
        },
        {
            dataField: 'fecha_baja',
            text: this.props.i18next.t("otrosdatos.fechabaja",{ns:"page"}),
            formatter: dateFormatter
        },
        {
            dataField: 'observaciones',
            text: this.props.i18next.t("otrosdatos.observaciones",{ns:"page"})
        },
        {
            dataField: 'datosSocios.PRESTACIONES_ACCESORIAS',
            text: this.props.i18next.t("otrosdatos.prestaciones",{ns:"page"}),
            formatter: boolFormatter
        },
        {
            dataField: 'datosSocios.VENTAJAS',
            text: this.props.i18next.t("otrosdatos.ventajas",{ns:"page"}),
            formatter: boolFormatter
        }
        ];

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
            this.setState({
                ventajas:null,
                prestaciones:null,
                excluido:false
            })
            if(row.datosSocios){
                this.setState({
                    ventajas:row.datosSocios.VENTAJAS,
                    prestaciones:row.datosSocios.PRESTACIONES_ACCESORIAS,
                    excluido:row.datosSocios.EXCLUIDO
                });
            }

            this.setState({
                modalAbierto: true,
                socioSeleccionado:row,
                cargandoGrid:true
            })
        }
        return true;
        }
        
        const abrirModalEditarPersona = () => {
            if (!this.state.socioSeleccionado) {
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            //no se si hace falta ya que no hay otro estado que abra el componente
            this.setState({ editarPersonaActivado: true })
            
            if (this.state.socioSeleccionado.tipo_persona === 1) {
                this.setState({modalEditarPersona: true})
            } else if (this.state.socioSeleccionado.tipo_persona === 2) {
                this.setState({modalEditarPersonaJuridica: true})
            }
        }
            

        
        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                cargandoGrid: false
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalEditarPersona: false,
                editarPersonaActivado:false,
            })
        }

        const cerrarModalPersonasJurid =  () => {
            this.setState({
                cargandoPersonas :true
            },async () => {
                await this.cargarSocios()
                this.setState({                
                    modalEditarPersonaJuridica: false,
                    cargandoPersonas: false,
                })
                
            })
            
           
        }

        const handlePrestaciones = (e) => {
            this.setState({ prestaciones: e.target.value })
        }

        const handleVentajas = (e) => {
            this.setState({ ventajas: e.target.value })
        }

        const handleExcluido = () => {
            this.setState({ excluido: !this.state.excluido })
        }

        const actualizarPersona = async () => {
            console.log(this.state.socioSeleccionado)
            await this.datosPersona.actualizarPersona()
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarSocios()
            //cerrar el modal
            cerrarModal2()
            }
        }

        const guardarDatos = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //si ya tenemos los datos de los socios.
            if(this.state.socioSeleccionado.datosSocios){

                client.mutate({
                    mutation:gql`mutation{actualizarSociosOtrosDatos(
                        ID_SOCIO:${this.state.socioSeleccionado.id},
                        VALORES:{
                            PRESTACIONES_ACCESORIAS:${this.state.prestaciones?'"""'+this.state.prestaciones+'"""':null},
                            EXCLUIDO:${this.state.excluido},
                            VENTAJAS:${this.state.ventajas?'"""'+this.state.ventajas+'"""':null}
                        }){ID_SOCIO}}`
                }).then(async result=>{
                  
                        alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                        await this.cargarSocios()

                        cerrarModal()

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

                
            }else{

                client.mutate({
                    mutation:gql`mutation{crearSociosOtrosDatos(
                        VALORES:{
                            ID_SOCIO:${this.state.socioSeleccionado.id}
                            PRESTACIONES_ACCESORIAS:${this.state.prestaciones?'"""'+this.state.prestaciones+'"""':null},
                            EXCLUIDO:${this.state.excluido},
                            VENTAJAS:${this.state.ventajas?'"""'+this.state.ventajas+'"""':null}
                        }){ID_SOCIO}}`
                }).then(async result=>{
                  
                        alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                        await this.cargarSocios()

                        cerrarModal()

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })


            }
        
        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                    <>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalEditarPersona}
                >
                    <DatosPersonasFisicas i18next={this.props.i18next} datosPersona={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPresonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={actualizarPersona} 
                            >
                           {this.props.i18next.t("editperson",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModalPersonasJurid}
                title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalEditarPersonaJuridica}
                className="datos-empresa-dialog"
            >
                <Tabs id="datos-empresa"
                    animate={this.animacion} 
                    onChange={this.cambiarPestaña} 
                    selectedTabId={this.state.pestaña}
                >
                    <Tab id="informacion-general" title="Información general" panel={ <InformacionGeneralNuevo empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null}  ref={(input) => { this.datos= input; }} i18next={this.props.i18next} /> } />
                    <Tab id="medios de contacto" title="Medios de Contacto" panel={<MediosContacto empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} i18next={this.props.i18next}/>} />
                    <Tab id="centros de trabajo" title="Centros de trabajo / Sucursales" panel={<CentrosTrabajo empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null}  i18next={this.props.i18next}/>} />
                    {this.state.socioSeleccionado && this.state.socioSeleccionado.esPrincipal?<Tab id="documentos" title="Documentos" panel={<Documentos empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} i18next={this.props.i18next}/>} />:<></>}
                    {this.state.socioSeleccionado && this.state.socioSeleccionado.esPrincipal?<Tab id="vinculos" title="Vínculos" panel={<Vinculos empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} i18next={this.props.i18next}/>} />:<></>}
                    <Tab id="archivos-adjuntos" title="Archivos adjuntos" panel={<ArchivosAdjuntos   empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} i18next={this.props.i18next}/>} />
                </Tabs>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                </div>
            </div>  
            </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimir-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirGral}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirGral?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimirGral-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("otrosdatos.title",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                        >
                            
                            {!this.state.cargandoPersonas ?
                                <div className="pixelAlrededorGrande">
                                    <Card interactive={true} elevation={Elevation.TWO} id="vista-general">
                                        <FormGroup
                                            label={this.props.i18next.t("otrosdatos.socio",{ns:"page"})+":"}
                                            labelFor="socio"
                                        >
                                            <InputGroup dsabled value={this.state.socioSeleccionado ? this.state.socioSeleccionado.nombre : null} />
                                        
                                            <FormGroup>
                                                <Button id="boton-editar-socio" icon="edit" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                                            </FormGroup>
                                        </FormGroup>
                                        <FormGroup
                                            label={this.props.i18next.t("otrosdatos.prestaciones",{ns:"page"})+":"}
                                            labelFor="prestaciones-accesorias"

                                        >
                                            <TextArea
                                                onChange={handlePrestaciones}
                                                value={this.state.prestaciones}
                                                fill={true}
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            label={this.props.i18next.t("otrosdatos.ventajassocio",{ns:"page"})+":"}
                                            labelFor="ventajas"
                                        >
                                            <TextArea
                                                onChange={handleVentajas}
                                                value={this.state.ventajas}
                                                fill={true}
                                            />
                                        </FormGroup>
                                        <Switch innerLabel={this.props.i18next.t("no",{ns:"global"}).toUpperCase()} innerLabelChecked={this.props.i18next.t("yes",{ns:"global"}).toUpperCase()} checked={this.state.excluido} label={this.props.i18next.t("otrosdatos.excluido",{ns:"page"})} onChange={handleExcluido} />
                                        <div className={Classes.DIALOG_FOOTER}>
                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                <Tooltip content={this.props.i18next.t("print",{ns:"global"})} position="right" >
                                                    <Button icon="print" disabled={this.state.socioSeleccionado ? false : true} text={this.props.i18next.t("print",{ns:"global"})} onClick={() => this.generarImprimirOD()} />
                                                </Tooltip>
                                                <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                                    <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                                                </Tooltip>
                                                <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                                                    <AnchorButton
                                                        intent={Intent.PRIMARY}
                                                        onClick={guardarDatos}
                                                    >
                                                        {this.props.i18next.t("guardar",{ns:"global"})}
                                                    </AnchorButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                         : <Spinner /> }
                </Dialog>

                    <Card>
                    <Callout className="bp3-intent-danger">
                            {this.props.i18next.t("otrosdatos.info",{ns:"page"})}:
                        </Callout>
                        {this.state.cargandoGrid?
                            <Cargando/>
                        :
                        <>
                        <Navbar>
					    <Navbar.Group>
                        		<Tooltip content={this.props.i18next.t("printgral",{ns:"global"})} position="right" >
                            		<Button className="bp3-minimal" icon="print" text={this.props.i18next.t("printgral",{ns:"global"})} onClick={()=>this.generarImprimirGral()}/>
                        		</Tooltip>
                        </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                        keyField='id'
                        data={ this.state.socios }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                        />
                        </>
                        }
                        
                    </Card>
            </>
            }
         </>   
        )
    }
}