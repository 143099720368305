/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import {Spinner,Tabs, Tab, Navbar, ButtonGroup, Alignment, Button, Dialog, Classes, Tooltip, AnchorButton, Intent, Card, Elevation } from "@blueprintjs/core"
import gql from "graphql-tag";
import moment from 'moment';
import axios from 'axios';
import ReactDOM from 'react-dom';
import DatosPoliza from "./DatosPoliza.js"
import Editarlo from "./ObservacionesPoliza"
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import InformacionPolizasSeguro from "./InformacionPolizasSeguro";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';

import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'


Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });



const styles = StyleSheet.create({
    dialog:{
        width: '65%'
    },
    page:{
        paddingBottom:95,
        paddingTop:30,
        paddingLeft: 40,
        paddingRight: 20
    },
    body:{
        flex: 1,
        backgroundColor: '#FFFFFF',
        marginLeft:20,
    },
    row: {
        flexDirection: 'row',
        width: '100%'
      },
    imageHead: {
        width: '20%',
        maxHeight: 60,
        marginBottom: 20
    },
    rowHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
    },
    rowHeadTwo: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginBottom: 20,
        marginTop:-1
      },
      labelHead: {
        flexDirection: 'column',
        width: '20%',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: '#000000'
      },
      colHead: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        marginTop:-1
      },
      rowTitleOne: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000000'
      },
      labelTitle: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans Bold',
        textAlign: 'center'
      },
      text: {
        flexDirection: 'column',
        fontSize:12,
        fontFamily: 'Open Sans',
        alignItems: 'flex-start',
        justifySelf: 'flex-start',
      },
      footerPage: {
        position: 'absolute',
        fontSize: 8,
        bottom: 60,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'black',
        fontFamily: 'Open Sans Bold',
      },
      icono: {
        position: 'absolute',
        float: 'right',
        width: 40,
        bottom: 10,
        right: 20,
      },
      footer: {
        position: 'absolute',
        fontSize: 8,
        bottom: 10,
        left: 20,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        fontFamily: 'Open Sans',
      }
   
})
class PolizasSeguro extends Component {

    state={
        modalInfo:false,
        logotipo: null,
        imprimirActivado: false,
        modalImprimirPoliza: false,
        cargandoImprimir:false,
        datosEmpresa: null,
        modalAbierto: false,
        seguros: [
        ],
        polizaSeleccionada:null,
        modalBorrarAbierto:false,
        cargando: false,
        loading: false
    }

    cargarDatos = async () => {
        let datosEmpresa = await this.CargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa: datosEmpresa
        })
    }
    CargarDatosEmpresa= async () =>{
        let datosEmpresa= await this.props.client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }

    cargarPolizas=async ()=>{

        //carga de las polizas 
        await this.props.client
        .query({
            query: gql`
            {polizasSeguro{
                ID_POLIZA,
                ID_TIPO_SEGURO,
                TIPO_SEGURO{DESCRIPCION},
                CAPITAL_ASEGURADO,
                GARANTIAS_ASEGURADAS,
                FECHA_DESDE,
                FECHA_HASTA,
                NOTAS,
                ID_ASEGURADORA,
                ASEGURADORA{DESCRIPCION},
                NUMERO_POLIZA,
                ID_ADJUNTO,
                DOC_ADJUNTO{DATOS}
                }}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            let polizas=[]
            result.data.polizasSeguro.forEach(poliza=>{

                polizas.push({
                    "id":poliza.ID_POLIZA,
                    "idTipoSeguro":poliza.ID_TIPO_SEGURO,
                    "tipoSeguro":poliza.TIPO_SEGURO.DESCRIPCION,
                    "capital":poliza.CAPITAL_ASEGURADO,
                    "garantias":poliza.GARANTIAS_ASEGURADAS,
                    "fechaDesde":poliza.FECHA_DESDE,
                    "fechaHasta":poliza.FECHA_HASTA,
                    "notas":poliza.NOTAS,
                    "idAseguradora":poliza.ID_ASEGURADORA,
                    "aseguradora":poliza.ASEGURADORA.DESCRIPCION,
                    "numeroPoliza":poliza.NUMERO_POLIZA,
                    "idDocumento":poliza.ID_ADJUNTO,
                    "rutaDocumento":poliza.DOC_ADJUNTO.DATOS
                })
            })
            this.setState({seguros:polizas,cargando:false})
        })
    }

    componentDidMount(){

        this.setState({cargando: true})

        this.obtenerLogoEmpresa()
        this.cargarPolizas()
        
    
    }

    InformePoliza= () => (
        <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
                <Page size='A4' style={styles.page}>
                    <View style={styles.body}>
                         <View fixed style={styles.row}>
                             <Image style={styles.imageHead} src={this.state.logotipo?this.state.logotipo:pantallaInicio} />
                         </View>
                    <View fixed style={styles.rowHead}>
                      <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                  </View>
                  <View fixed style={styles.rowHeadTwo}>
                      <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                      <Text style={styles.text}>{i18next.t("programaseguros.title",{ns:"page"}).toUpperCase()}</Text>
                  </View>
                  <View style={styles.rowTitleOne}>
                      <Text style={styles.labelTitle}>{i18next.t("programaseguros.title",{ns:"page"}).toUpperCase()}</Text>
                  </View>
                  {this.state.seguros?this.state.seguros.map((seguro, index) =>{
                      return(
                          <View key={index}>
                              {index===0 && (
                                <View style={styles.colHead}>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("programaseguros.tiposeguro",{ns:"page"}).toUpperCase()}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("programaseguros.aseguradora",{ns:"page"}).toUpperCase()}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("programaseguros.numeropoliza",{ns:"page"}).toUpperCase()}</Text>                                 
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("programaseguros.fechadesde",{ns:"page"}).toUpperCase()}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("programaseguros.fechahasta",{ns:"page"}).toUpperCase()}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("programaseguros.notas",{ns:"page"}).toUpperCase()}</Text>
                              </View>   
                              )}
                              <View style={styles.colHead}>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{seguro.tipoSeguro}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{seguro.aseguradora}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{seguro.numeroPoliza}</Text>                                 
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{seguro.fechaDesde!==null?moment(seguro.fechaDesde).format("DD-MM-YYYY "):''}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{seguro.fechaHasta!==null?moment(seguro.fechaHasta).format("DD-MM-YYYY "):''}</Text>
                                  <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{seguro.notas!==null?seguro.notas.replace(/<[^>]*>?/g, ''):''}</Text>
                              </View>   
                          </View>
                      )
                  }):<></>}
                 </View> 
                        <Text
                            style={styles.footerPage}
                            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                            fixed
                          />
                        <Image
                            style={styles.icono}
                            src={iconoFooter}
                            fixed
                        />
                      <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
                    </Page>
            </Document>
        </PDFViewer>
    )

   

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

   
    generarPoliza = async () => {
        console.log(this.state.seguros)
        if(this.state.seguros.length===0){
            alert(i18next.t("noseleccionado",{ns:"global"}))
            return
        }
        this.setState({
            modalImprimirPoliza : true,
            cargandoImprimir: true
        }, async () => {
            if(this.state.datosEmpres==null){
                await this.cargarDatos();
                ReactDOM.render(this.InformePoliza(), document.getElementById('imprimir-pdf-poliza'))
            }else{
                this.setState({cargandoImprimir:false}, () => {
                    ReactDOM.render(this.InformePoliza(), document.getElementById('imprimir-pdf-poliza'))
                })
            }
        })
    } 

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("programaseguros.title",{ns:"page"});
        const cerrarModalImprimir = () => {
            this.setState({modalImprimirPoliza:false})
        }
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({pestaña:"propiedades-poliza"})
            this.setState({
                polizaSeleccionada:null,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalAbierto: !this.state.modalAbierto,
                modalImprimirPoliza:false
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.polizaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({pestaña:"propiedades-poliza"})
            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalAbierto: !this.state.modalAbierto
            })
        }

        
        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.polizaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({pestaña:"propiedades-poliza"})
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: !this.state.modalBorrarAbierto
            })
        }

        const eliminarPoliza=()=>{


            //mutaciones para eliminar la poliza
            this.props.client.mutate({
                mutation:gql`mutation{eliminarPolizaSeguro(ID_POLIZA:${this.state.polizaSeleccionada.id}){ID_POLIZA}}`
            }).then(result=>{
              
                 alert(i18next.t("deletecorrecto",{ns:"global"}))
                //eliminamos su documento adjunto de la bdd.
                this.props.client.mutate({
                    mutation:gql`mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.polizaSeleccionada.idDocumento}){ID_ADJUNTO}}`
                })
                
                
                this.setState({
                    polizaSeleccionada:null,
                    cargando:true
                },async () =>{
                    await this.cargarPolizas()

                });
               
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            cerrarModal()

        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    polizaSeleccionada: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }

        const notasFormatter=(cell,row)=>{
            let notas='';
            if (cell!==null) {
                notas=cell;
            }
            return (
                <div dangerouslySetInnerHTML={{__html: notas}}/>
            );
        }

        const columns = [{
            dataField: 'tipoSeguro',
            text: i18next.t("programaseguros.tiposeguro",{ns:"page"})
            }, {
            dataField: 'aseguradora',
            text: i18next.t("programaseguros.aseguradora",{ns:"page"})
            }, {
            dataField: 'numeroPoliza',
            text: i18next.t("programaseguros.numeropoliza",{ns:"page"})
            }, {
            dataField: 'fechaDesde',
            text: i18next.t("programaseguros.fechadesde",{ns:"page"}),
            formatter: dateFormatter
            }, {
            dataField: 'fechaHasta',
            text: i18next.t("programaseguros.fechahasta",{ns:"page"}),
            formatter: dateFormatter
            }, {
            dataField: 'notas',
            text: i18next.t("programaseguros.notas",{ns:"page"}),
            formatter: notasFormatter
        }];

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
                modalImprimirPoliza:false
            })
        }
        
        const cambiarPestaña = (value) => {
            this.setState({
                pestaña: value
            })
        }

        const anadirNuevo = async(value) => {
            if(!this.tabDatosPoliza.state.aseguradoraSeleccionada){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

            if(!this.tabDatosPoliza.state.tipoSeguroSeleccionado){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

            if(!this.tabDatosPoliza.state.numeroPoliza){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

            if(!this.tabDatosPoliza.state.capitalAsegurado || !this.tabDatosPoliza.state.capitalAseguradoValido){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

           
            
            
            if(!this.tabDatosPoliza.refs.fileUploader.files[0]){

                alert(i18next.t("noseleccionado",{ns:"global"}))
                return

            }else{

                //comprobar la extension del archivo.
                if(this.tabDatosPoliza.refs.fileUploader.files[0].type!=="application/pdf"){
                    alert(i18next.t("noseleccionado",{ns:"global"}))
                    return
                }

            }
            this.setState({ loading: true }, async () => {
                //creacion del registro en la bdd.
                await this.props.client.mutate({
                    mutation: gql`mutation{crearPolizaSeguro(VALORES:{ID_TIPO_SEGURO:${this.tabDatosPoliza.state.tipoSeguroSeleccionado.value},CAPITAL_ASEGURADO:${this.tabDatosPoliza.state.capitalAsegurado.replace(/\./g, '').replace(/\,/g, '.')},GARANTIAS_ASEGURADAS:${this.tabDatosEditarloGarantia.state.contenidoEstrategicasHtml ? this.tabDatosEditarloGarantia.state.contenidoEstrategicasHtml : null},FECHA_DESDE:"${moment(this.tabDatosPoliza.state.inicioSeguro).format("YYYY-MM-DD")}",FECHA_HASTA:"${moment(this.tabDatosPoliza.state.finSeguro).format("YYYY-MM-DD")}",NUMERO_POLIZA:"${this.tabDatosPoliza.state.numeroPoliza}",NOTAS:${this.tabDatosEditarlo.state.contenidoEstrategicasHtml ? this.tabDatosEditarlo.state.contenidoEstrategicasHtml : null},ID_ASEGURADORA:${this.tabDatosPoliza.state.aseguradoraSeleccionada.value}}){ID_POLIZA}}`
                }).then(async result => {       
                    

                    //subida del archivo
                    var formData = new FormData();
                    formData.append("files", this.tabDatosPoliza.refs.fileUploader.files[0]);
                    const api = axios.create({
                        withCredentials: true
                    });
                    api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:1,NOMBRE:"DOCUMENTO_POLIZA",ID_ENTIDAD_ASOCIADA:3,ID_ASOCIADO:1,DESCRIPCION:"DOCUMENTO DE LA POLIZA ${result.data.crearPolizaSeguro.ID_POLIZA}"}){ID_ADJUNTO}}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(async (response) => {

                        //actualizamos la poliza con el id del adjunto
                        await this.props.client.mutate({
                            mutation: gql`mutation{actualizarPolizaSeguro(ID_POLIZA:${result.data.crearPolizaSeguro.ID_POLIZA},VALORES:{ID_ADJUNTO:${response.data.data.crearAdjunto.ID_ADJUNTO}}){ID_POLIZA}}`
                        }).then(async res => {

                            await this.cargarPolizas()

                            this.setState({
                                loading: false,
                            },()=>{
                                alert(i18next.t("insertcorrecto",{ns:"global"}))
                            })
                            
                            cerrarModal()
                        }).catch(err => {
                            alert(i18next.t("error",{ns:"global"}))
                            this.setState({loading:false})
                        })

                    })
                
                }).catch(err => {
                    console.log(err)
                    alert(i18next.t("error",{ns:"global"}))
                    this.setState({loading:false})
                })
                
            })
            

        }

        const editarPoliza = (value) => {          
                
           
            if(!this.tabDatosPoliza.state.aseguradoraSeleccionada){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

            if(!this.tabDatosPoliza.state.tipoSeguroSeleccionado){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

            if(!this.tabDatosPoliza.state.numeroPoliza){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }

            if(!this.tabDatosPoliza.state.capitalAsegurado || !this.tabDatosPoliza.state.capitalAseguradoValido){
                this.setState({pestaña:"propiedades-poliza"})
                return
            }
            
            if (this.tabDatosPoliza.state.fileName!=='') {
              if(!this.tabDatosPoliza.refs.fileUploader.files[0]){

                alert(i18next.t("noseleccionado",{ns:"global"}))
                return

              }else{

                  //comprobar la extension del archivo.
                  if(this.tabDatosPoliza.refs.fileUploader.files[0].type!=="application/pdf"){
                      alert(i18next.t("noseleccionado",{ns:"global"}))
                      return
                  }

              }
            }
            this.setState({ loading: true }, async () => {
                let garantias=null;
                let notas=null;
                if (this.state.polizaSeleccionada) {
                    garantias='"'+this.state.polizaSeleccionada.garantias+'"';
                    notas='"'+this.state.polizaSeleccionada.notas+'"';
                }
            //editar del registro en la bdd.
            this.props.client.mutate({
                mutation:gql`mutation{actualizarPolizaSeguro(ID_POLIZA:${this.state.polizaSeleccionada.id},VALORES:{ ID_TIPO_SEGURO:${this.tabDatosPoliza.state.tipoSeguroSeleccionado.value},CAPITAL_ASEGURADO:${this.tabDatosPoliza.state.capitalAsegurado.replace(/\./g, '').replace(/\,/g, '.')},GARANTIAS_ASEGURADAS:${this.tabDatosEditarloGarantia.state.contenidoEstrategicasHtml?this.tabDatosEditarloGarantia.state.contenidoEstrategicasHtml:garantias},FECHA_DESDE:"${moment(this.tabDatosPoliza.state.inicioSeguro).format("YYYY-MM-DD")}",FECHA_HASTA:"${moment(this.tabDatosPoliza.state.finSeguro).format("YYYY-MM-DD")}",NUMERO_POLIZA:"${this.tabDatosPoliza.state.numeroPoliza}",NOTAS:${this.tabDatosEditarlo.state.contenidoEstrategicasHtml?this.tabDatosEditarlo.state.contenidoEstrategicasHtml:notas},ID_ASEGURADORA:${this.tabDatosPoliza.state.aseguradoraSeleccionada.value}}){ID_POLIZA}}`
            }).then(async result=>{
               
                
                if (this.tabDatosPoliza.state.fileName!=='') {
                  //subida del archivo
                  var formData = new FormData();
                  formData.append("files",this.tabDatosPoliza.refs.fileUploader.files[0]);
                  const api = axios.create({
                      withCredentials: true
                  });    
                  api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:1,NOMBRE:"DOCUMENTO_POLIZA",ID_ENTIDAD_ASOCIADA:3,ID_ASOCIADO:1,DESCRIPCION:"DOCUMENTO DE LA POLIZA ${this.state.polizaSeleccionada.id}"}){ID_ADJUNTO}}`, formData, {
                      headers: {
                      'Content-Type': 'multipart/form-data'
                      }
                  }).then(async (response)=>{

                      //actualizamos la poliza con el id del adjunto
                      await this.props.client.mutate({
                          mutation:gql`mutation{actualizarPolizaSeguro(ID_POLIZA:${this.state.polizaSeleccionada.id},VALORES:{ID_ADJUNTO:${response.data.data.crearAdjunto.ID_ADJUNTO}}){ID_POLIZA}}`
                      }).then(async res=>{
                          await this.cargarPolizas()

                          
                          this.setState({
                              loading:false
                          },()=>{
                            alert(i18next.t("updatecorrecto",{ns:"global"}))
                            cerrarModal()
                        })

                      }).catch(err => {
                         this.setState({loading:false})
                          alert(i18next.t("error",{ns:"global"}))
                      })

                  })
                } else {
                  await this.cargarPolizas()

                  this.setState({
                      loading:false
                  },()=>{
                    cerrarModal()
                  })

                }
                
            }).catch(err => {
                this.setState({loading:false})
                alert(i18next.t("error",{ns:"global"}))
            })
                
         })
            
        }  

        const abrirModalInfo = async() => {
            this.setState({
                modalInfo:true
            });
        }

        const cerrarModalInfo = () => {
            this.setState({
                modalInfo: false
            })
        }

        return (
            <>
            {this.state.cargando?
                <Cargando />
                :
                <>
                    <Dialog
                        transitionDuration={400}
                        className="dialog-ancho dialog-scroll"
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModalInfo}
                        title={i18next.t("programaseguros.titlesee",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalInfo}
                    >
                        <div id="dialogo-actas">
                            <InformacionPolizasSeguro />
                        </div>
                        <br></br>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModalInfo}>{i18next.t("close",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                    
                    <Dialog
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("programaseguros.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirPoliza}
                        style={styles.dialog}
                    >
                         {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="imprimir-pdf-poliza">
                            </div>
                            :
                                <Spinner />
                        }
                        
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("programaseguros.titledel",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                            <div className={Classes.DIALOG_BODY}>
                                <h2>{i18next.t("programaseguros.questiondel",{ns:"page"})}</h2>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("yes",{ns:"global"})} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={eliminarPoliza}
                                    >
                                        {i18next.t("yes",{ns:"global"})}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                        <Dialog 
                                transitionDuration={400}
                                autoFocus={true}
                                enforceFocus={true}
                                icon="info-sign"
                                onClose={cerrarModal}
                                title={i18next.t("programaseguros.title",{ns:"page"})}
                                canEscapeKeyClose={true}
                                canOutsideClickClose={true}
                                isCloseButtonShown={true}
                                className="dialog-ancho pixelAlrededorGrande"
                                isOpen={this.state.modalAbierto}
                                style={styles.dialog}
                            >
                                <br />
                                <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
                                    <Tabs id="polizas-seguro"
                                        animate={this.animacion} 
                                        onChange={cambiarPestaña} 
                                        selectedTabId={this.state.pestaña}
                                    >
                                    <Tab id="propiedades-poliza" title={i18next.t("programaseguros.title",{ns:"page"})} panel={<DatosPoliza DatosPoliza={this.state.polizaSeleccionada ? (this.state.polizaSeleccionada ? this.state.polizaSeleccionada : null) : null} ref={(input) => { this.tabDatosPoliza = input; }} loading={this.state.loading} i18next={i18next}/>} />
                                        <Tab id="garantias" title={i18next.t("programaseguros.garantias",{ns:"page"})} panel={<Editarlo  Observaciones={this.state.polizaSeleccionada?this.state.polizaSeleccionada.garantias:null}  ref={(input) => { this.tabDatosEditarloGarantia= input; }} loading={this.state.loading} i18next={i18next}/>} />
                                        <Tab id="observaciones" title={i18next.t("programaseguros.notas",{ns:"page"})} panel={<Editarlo  Observaciones={this.state.polizaSeleccionada?this.state.polizaSeleccionada.notas:null}  ref={(input) => { this.tabDatosEditarlo= input; }} loading={this.state.loading} i18next={i18next}/>} />
                                    </Tabs>
                                </Card>
                                <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                                    </Tooltip>
                                    <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={this.state.nuevoActivado?anadirNuevo:editarPoliza}

                                        >
                                            {i18next.t("aceptar",{ns:"global"})}
                                        </AnchorButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                    <div className="tablaCien">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                        <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Button  className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})} active={this.state.imprimirActivado} onClick={() => this.generarPoliza()}/>
                                        <Button className="bp3-minimal" icon="envelope" text={i18next.t("programaseguros.pedirpresupuesto",{ns:"page"})}  onClick={() => window.location = 'mailto:soporte@lexcontrol.es'}/>
                                        <Button className="bp3-minimal" icon="eye-open" text={i18next.t("see",{ns:"global"})}  onClick={abrirModalInfo}/>
                                
                                    </ButtonGroup>
                                </Navbar.Group>

                            </Navbar>
                            <BootstrapTable
                                keyField='id'
                                data={ this.state.seguros }
                                columns={ columns }
                                selectRow={ selectRow }
                                pagination={ paginationFactory(const_table.options) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        </Card>
                    </div>
                </>
            }
            </>
        )
    }
}
export default withTranslation()(withApollo(PolizasSeguro))