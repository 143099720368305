import React, { Component } from 'react';
import { Card, Blockquote } from "@blueprintjs/core"

export default class ConceptoAlcance extends Component {
    render() {
        return (
            <>
                <div className="pixelAlrededor">
                    <Card>
                            <p>Compliance es la gestión del riesgo que tiene una organización de ser objeto de sanciones legales, de pérdidas económicas o de daños a la reputación como consecuencia del incumplimiento del ordenamiento jurídico, de las políticas internas o de los códigos éticos que afectan a la organización.</p>
                            <p>El presente sistema de gestión de compliance sigue las directrices y recomendaciones de la norma internacional UNE-ISO 1960 que proporciona orientación para establecer, desarrollar, implementar, evaluar, mantener y mejorar un sistema de gestión de compliance eficaz y que genera respuesta por parte de la organización.</p>
                            <p>Las directrices sobre sistemas de gestión de compliance son aplicables a todo tipo de organizaciones. El alcance de la aplicación de estas directrices depende del tamaño, estructura, naturaleza y complejidad de la organización. Esta norma internacional se basa en los principios de buen gobierno, proporcionalidad, transparencia y sostenibilidad.</p>
                            <p>En la gestión de riesgos Lex Control, se articulan tres niveles de compliance: el que pretende atajar el riesgo penal (criminal compliance); el que se fija como objetivo el cumplimiento de todas las demás normas que afectan a la actividad y; el control de pactos, contratos, normas de calidad y excelencia asumidos voluntariamente por la empresa.</p>
                            <p>A continuación se transcribe el texto del Código Penal, en el que ya se incorpora la responsabilidad penal de las personas jurídicas, previéndose igualmente las posibilidades de exención o atenuación de la responsabilidad si se han adoptado y ejecutado con eficacia, antes de la comisión del delito, modelos de organización y gestión que incluyen las medidas de vigilancia y control idóneas para prevenir delitos de la misma naturaleza o para reducir de forma significativa el riesgo de su comisión.</p>
                            <h3>Código Penal. Ley Orgánica 10/1995, de 23 de noviembre</h3>
                            <h4>BOE 281/1995, de 24 de noviembre de 1995</h4>
                            <h4>VIGENTE DESDE 28/10/2015</h4>
                            <p>“ARTÍCULO 31   El que actúe como administrador de hecho o de derecho de una persona jurídica, o en nombre o representación legal o voluntaria de otro, responderá personalmente, aunque no concurran en él las condiciones, cualidades o relaciones que la correspondiente figura de delito requiera para poder ser sujeto activo del mismo, si tales circunstancias se dan en la entidad o persona en cuyo nombre o representación obre.</p>
                            <p>ARTÍCULO 31 BIS:</p>
                            <ol>
                                <li>En los supuestos previstos en este Código, las personas jurídicas serán penalmente responsables:
                                    <ul>
                                        <li>De los delitos cometidos en nombre o por cuenta de las mismas, y en su beneficio directo o indirecto, por sus representantes legales o por aquellos que actuando individualmente o como integrantes de un órgano de la persona jurídica, están autorizados para tomar decisiones en nombre de la persona jurídica u ostentan facultades de organización y control dentro de la misma.</li>
                                        <li>De los delitos cometidos, en el ejercicio de actividades sociales y por cuenta y en beneficio directo o indirecto de las mismas, por quienes, estando sometidos a la autoridad de las personas físicas mencionadas en el párrafo anterior, han podido realizar los hechos por haberse incumplido gravemente por aquéllos los deberes de supervisión, vigilancia y control de su actividad atendidas las concretas circunstancias del caso.</li>
                                    </ul>
                                </li>
                                <li>Si el delito fuere cometido por las personas indicadas en la letra a) del apartado anterior, la persona jurídica quedará exenta de responsabilidad si se cumplen las siguientes condiciones:
                                    <ol>
                                        <li>el órgano de administración ha adoptado y ejecutado con eficacia, antes de la comisión del delito, modelos de organización y gestión que incluyen las medidas de vigilancia y control idóneas para prevenir delitos de la misma naturaleza o para reducir de forma significativa el riesgo de su comisión;</li>
                                        <li>la supervisión del funcionamiento y del cumplimiento del modelo de prevención implantado ha sido confiada a un órgano de la persona jurídica con poderes autónomos de iniciativa y de control o que tenga encomendada legalmente la función de supervisar la eficacia de los controles internos de la persona jurídica;</li>
                                        <li>los autores individuales han cometido el delito eludiendo fraudulentamente los modelos de organización y de prevención y</li>
                                        <li>no se ha producido una omisión o un ejercicio insuficiente de sus funciones de supervisión, vigilancia y control por parte del órgano al que se refiere la condición 2ª</li>
                                    </ol>
                                    <p>En los casos en los que las anteriores circunstancias solamente puedan ser objeto de acreditación parcial, esta circunstancia será valorada a los efectos de atenuación de la pena.</p>
                                </li>
                                <li>En las personas jurídicas de pequeñas dimensiones, las funciones de supervisión a que se refiere la condición 2ª del apartado 2 podrán ser asumidas directamente por el órgano de administración. A estos efectos, son personas jurídicas de pequeñas dimensiones aquéllas que, según la legislación aplicable, estén autorizadas a presentar cuenta de pérdidas y ganancias abreviada.</li>
                                <li>Si el delito fuera cometido por las personas indicadas en la letra b) del apartado 1, la persona jurídica quedará exenta de responsabilidad si, antes de la comisión del delito, ha adoptado y ejecutado eficazmente un modelo de organización y gestión que resulte adecuado para prevenir delitos de la naturaleza del que fue cometido o para reducir de forma significativa el riesgo de su comisión.</li>
                                <p>En este caso resultará igualmente aplicable la atenuación prevista en el párrafo segundo del apartado 2 de este artículo.</p>
                                <Blockquote>
                                    <li>Los modelos de organización y gestión a que se refieren la condición 1ª del apartado 2 y el apartado anterior deberán cumplir los siguientes requisitos:
                                        <ol>
                                            <li>Identificarán las actividades en cuyo ámbito puedan ser cometidos los delitos que deben ser prevenidos.</li>
                                            <li>Establecerán los protocolos o procedimientos que concreten el proceso de formación de la voluntad de la persona jurídica, de adopción de decisiones y de ejecución de las mismas con relación a aquéllos.</li>
                                            <li>Dispondrán de modelos de gestión de los recursos financieros adecuados para impedir la comisión de los delitos que deben ser prevenidos.</li>
                                            <li>Impondrán la obligación de informar de posibles riesgos e incumplimientos al organismo encargado de vigilar el funcionamiento y observancia del modelo de prevención.</li>
                                            <li>Establecerán un sistema disciplinario que sancione adecuadamente el incumplimiento de las medidas que establezca el modelo.</li>
                                            <li>Realizarán una verificación periódica del modelo y de su eventual modificación cuando se pongan de manifiesto infracciones relevantes de sus disposiciones, o cuando se produzcan cambios en la organización, en la estructura de control o en la actividad desarrollada que los hagan necesarios.</li>
                                        </ol>
                                    </li>
                                </Blockquote>
                            </ol>
                            <h4>ARTÍCULO 31 TER</h4>
                            <ol>
                                <li>La responsabilidad penal de las personas jurídicas será exigible siempre que se constate la comisión de un delito que haya tenido que cometerse por quien ostente los cargos o funciones aludidas en el artículo anterior, aun cuando la concreta persona física responsable no haya sido individualizada o no haya sido posible dirigir el procedimiento contra ella. Cuando como consecuencia de los mismos hechos se impusiere a ambas la pena de multa, los Jueces o Tribunales modularán las respectivas cuantías, de modo que la suma resultante no sea desproporcionada en relación con la gravedad de aquéllos.</li>
                                <li>La concurrencia, en las personas que materialmente hayan realizado los hechos o en las que los hubiesen hecho posibles por no haber ejercido el debido control, de circunstancias que afecten a la culpabilidad del acusado o agraven su responsabilidad, o el hecho de que dichas personas hayan fallecido o se hubieren sustraído a la acción de la justicia, no excluirá ni modificará la responsabilidad penal de las personas jurídicas, sin perjuicio de lo que se dispone en el artículo siguiente.</li>
                            </ol>
                            <h4>ARTÍCULO 31 QUATER</h4>
                            <p>ARTÍCULO 31 QUATER</p>
                            <ul>
                                <li>Haber procedido, antes de conocer que el procedimiento judicial se dirige contra ella, a confesar la infracción a las autoridades.</li>
                                <li>Haber colaborado en la investigación del hecho aportando pruebas, en cualquier momento del proceso, que fueran nuevas y decisivas para esclarecer las responsabilidades penales dimanantes de los hechos.</li>
                                <li>Haber procedido en cualquier momento del procedimiento y con anterioridad al juicio oral a reparar o disminuir el daño causado por el delito.</li>
                                <li>Haber establecido, antes del comienzo del juicio oral, medidas eficaces para prevenir y descubrir los delitos que en el futuro pudieran cometerse con los medios o bajo la cobertura de la persona jurídica.</li>
                            </ul>
                            <h4>ARTÍCULO 31 QUINQUIES</h4>
                            <ol>
                                <li>Las disposiciones relativas a la responsabilidad penal de las personas jurídicas no serán aplicables al Estado, a las Administraciones públicas territoriales e institucionales, a los Organismos Reguladores, las Agencias y Entidades públicas Empresariales, a las organizaciones internacionales de derecho público, ni a aquellas otras que ejerzan potestades públicas de soberanía o administrativas.</li>
                                <li>En el caso de las Sociedades mercantiles públicas que ejecuten políticas públicas o presten servicios de interés económico general, solamente les podrán ser impuestas las penas previstas en las letras a) y g) del apartado 7 del art. 33. Esta limitación no será aplicable cuando el Juez o Tribunal aprecie que se trata de una forma jurídica creada por sus promotores, fundadores, administradores o representantes con el propósito de eludir una eventual responsabilidad penal”.</li>
                            </ol>
                    </Card>
                </div>
            </>
        )
    }
}