import React from "react"
import { Card, FormGroup, InputGroup,Classes,Tooltip,AnchorButton,Button,Dialog,Intent } from "@blueprintjs/core"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import const_table from "../BootstrapTable";
let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

class Denunciado extends React.Component {

    state={
        relacionEmpresa:[
        ],
        nombreApellidosDenunciado:null,
        relacionEmpresaDenunciado:null,
        nuevaRelacionEmpresa:null,
        abrirModalRelacion:false
    }

    reiniciarEstado() {
        this.setState({
            nombreApellidosDenunciado:null,
            relacionEmpresaDenunciado:null,
            nuevaRelacionEmpresa:null,
            abrirModalRelacion:false
        });
    }

    cargarRelaciones=async()=>{

        await client.query({
            query:gql`{relacionesEmpresa{ID_RELACION_EMPRESA,DESCRIPCION}}`
        }).then(async(result)=>{
            //console.log(result)
            let relaciones=[]
            result.data.relacionesEmpresa.forEach(async(relacion)=>{
             
                relaciones.push({"value":relacion.ID_RELACION_EMPRESA,"label":relacion.DESCRIPCION}) 

            })
            this.setState({relacionEmpresa:relaciones})
        })

        if(this.props.datosDenuncia){
            this.setState({nombreApellidosDenunciado:this.props.datosDenuncia?this.props.datosDenuncia.denunciado:""})

            //buscamos la relacion
            let relSel=this.state.relacionEmpresa.find(relacion=>{
                return relacion.value===this.props.datosDenuncia.idRelacionDenunciado
            })
            this.setState({relacionEmpresaDenunciado:relSel})
        }

    }

    componentDidMount=async()=>{
       await this.cargarRelaciones()
    }

    render(){

        const handleCambiarNombreApellidosDenunciado=(ev)=>{
            this.setState({nombreApellidosDenunciado:ev.currentTarget.value})
        }

        const handleSeleccionarRelacionDenunciado=(value)=>{
            this.setState({relacionEmpresaDenunciado:value})
        }

        const cerrarModal=()=>{
            this.setState({modalRelacionAbierto:false})
        }

        const abrirModalRelacion=()=>{
            this.setState({
                nuevaRelacionEmpresa:null,
                modalRelacionAbierto:true
            })
        }

        const handlenuevaRelacionEmpresa=(ev)=>{
            this.setState({nuevaRelacionEmpresa:ev.currentTarget.value})
        }

        const anadirRelacion=(ev)=>{

            if(!this.state.nuevaRelacionEmpresa)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.relacionEmpresa.find(relacion=>{
                return relacion.label===this.state.nuevaRelacionEmpresa
            })

            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //creamos la relacion empresa.
            client.mutate({
                mutation: gql`
                  mutation{crearRelacionEmpresa(VALORES:{DESCRIPCION:"${this.state.nuevaRelacionEmpresa}"}){ID_RELACION_EMPRESA}}
                `
                }).then(async result=>{
                 
                    await this.cargarRelaciones()

                    let relacionCreada=result.data.crearRelacionEmpresa
                    let relacionSel=this.state.relacionEmpresa.find(relacion=>{
                        return relacion.value===relacionCreada.ID_RELACION_EMPRESA
                    })
                    this.setState({relacionEmpresaDenunciado:relacionSel})
                    cerrarModal()

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })

        }


        return (
            <>
             <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("denuncias.relacionempresa",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}nuevaRelacionEmpresa
                    isOpen={this.state.modalRelacionAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("denuncias.enterrelacionempresa",{ns:"page"})+":"}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevaRelacionEmpresa?"":i18next.t("denuncias.emptyrelacionempresa",{ns:"page"})}
                        >
                            <InputGroup id="texto-nombre-relacion" onChange={handlenuevaRelacionEmpresa} intent={this.state.nuevaRelacionEmpresa?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirRelacion}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor">
                    <Card>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.nombreyapellido",{ns:"page"}).toLowerCase())}
                            labelFor="nombre-apellidos"
                            intent="danger"
                            helperText={this.state.nombreApellidosDenunciado?"":i18next.t("denuncias.emptynombredenunciado",{ns:"page"})}
                        >
                            <InputGroup onChange={handleCambiarNombreApellidosDenunciado} value={this.state.nombreApellidosDenunciado} intent={this.state.nombreApellidosDenunciado?"intent":"danger"} id="nombre-apellidos" />
                        </FormGroup>
                        <FormGroup
                            label={const_table.capitalize(i18next.t("denuncias.relacionempresa",{ns:"page"}).toLowerCase())}
                            labelFor="relacion-empresa"
                            intent="danger"
                            helperText={this.state.relacionEmpresaDenunciado?"":i18next.t("denuncias.noselectrelacion",{ns:"page"})}
                        >
                            <div style={this.state.relacionEmpresaDenunciado?{}:{border:"1px solid red"}}>
                                <Select
                                    options={this.state.relacionEmpresa}
                                    className="pixelAlrededor"
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    onChange={handleSeleccionarRelacionDenunciado}
                                    value={this.state.relacionEmpresa.filter(relacion => relacion.value === (this.state.relacionEmpresaDenunciado?this.state.relacionEmpresaDenunciado.value:null))}
                                />
                            </div>
                        </FormGroup>
                        <Button 
                            alignText="right"
                            icon="add"
                            className="iconoIzquierda"
                            onClick={abrirModalRelacion}
                        />
                    </Card>
                </div>
            </>
        )
    }
}

export default withTranslation()(Denunciado);