
import React from "react"
import {Spinner, FormGroup,Dialog,TextArea, Classes, NavbarDivider,Tooltip, AnchorButton, Intent, Navbar, ButtonGroup, Button, InputGroup, Alignment,FileInput,Card, Elevation} from "@blueprintjs/core"
import { Table, Column, Cell, RegionCardinality,Regions } from "@blueprintjs/table"
import gql from "graphql-tag";
import axios from 'axios';
import ApolloClient from "apollo-boost";
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import moment from "moment"

import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });


let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  labelData: {
    flexDirection: 'column',
    marginRight: 10,
    fontSize:10,
    fontFamily: 'Open Sans Bold',
    width: '30%'
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '60%'
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});

export default  class ActasNombramiento extends React.Component {

    state = {
        modalAbierto: false,
        modalBorrarAbierto: false,
        nombre:null,
        nuevoActivado:true,
        editarActivado:false,
        borrarActivado:false,
        descripcion:null,
        adjuntoSeleccionado:null,
        archivo:null,
        adjuntos: [
        ],
        tipoadjuntos:[
            {"label": "Estatutos de la empresa","value":4},
            {"label": "Otros","value":3},
        ],
        cargandoAN:false,
        columnWidths: [100,100,100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirA:false,
        modalImprimirA: false,
        cargandoImprimirAI:false,
        modalImprimirAI: false,
        logotipo:null
    }

    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaActas.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaActas.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaActas.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaActas.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

    recargarAdjuntos=async()=>{

      let adjuntos= await client
        .query({
            query: gql`
            {
                adjuntos(FILTROS:{ID_TIPO_ADJUNTO:17}){ID_ADJUNTO,DESCRIPCION,NOMBRE,EXTENSION,DATOS,TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.adjuntos;
        })    
            let copyAdjuntos=[]
            adjuntos.forEach((adjunto)=>{
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            })
            this.setState({adjuntos:copyAdjuntos})
            //console.log(this.state.adjuntos)

    }

    obtenterTipo(nombreExtension){

        let extension=""
        switch(nombreExtension){
            case ".pdf":
                extension="Documento PDF"
            break;
            case ".doc":
            case ".docx":
                extension="Documento de Microsoft Word"
            break;
            case ".xls":
            case ".xlsx":
                extension="Documento de Microsoft Excel"
            break;
            case ".txt":
                extension="Documento de Texto"
            break;
            case ".png":
            case ".jpg":
            case ".jpeg":
            case ".svg":
                extension="Imagen"
            break;
            default:
                extension="Otros"
            break;
        }
        return extension

    }

    generarImprimirAI= async() => {
        this.setState({
            cargandoImprimirAI:true,
            modalImprimirAI:true
        }, async () => {
             this.setState({cargandoImprimirAI:false}, () => {
                 ReactDOM.render(this.ImprimirAI(), document.getElementById('generarAI-pdf'))
             })
        })
    }

    generarImprimirA= async() => {
        this.setState({
            cargandoImprimirA:true,
            modalImprimirA:true
        }, async () => {
             this.setState({cargandoImprimirA:false}, () => {
                 ReactDOM.render(this.ImprimirA(), document.getElementById('generarA-pdf'))
             })
        })
    }

    ImprimirAI=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ORGANO COMPLIANCE</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>ACTA DE NOMBRAMIENTO</Text>
                </View>
                    {this.state.adjuntoSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>NOMBRE DOCUMENTO:</Text>
                                    <Text style={styles.textData}>{this.state.adjuntoSeleccionado.nombre}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>DESCRIPCIÓN:</Text>
                                    <Text style={styles.textData}>{this.state.adjuntoSeleccionado.descripcion}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
       )

    ImprimirA=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ORGANO COMPLIANCE</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>ACTAS DE NOMBRAMIENTO</Text>
                </View>
                    {this.state.adjuntos?this.state.adjuntos.map((adjunto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>NOMBRE</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>TIPO</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'30%'}}>TIPO DE ARCHIVO</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{adjunto.nombre?adjunto.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{adjunto.tipo?adjunto.tipo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'30%'}}>{adjunto.mime?adjunto.mime:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
       )

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await client 
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
 
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
 
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
 
             }
         })
     
     }
     cargarEmpresa= async() => {
         
         //DATOS JURIDICOS
         let datosEmpresa= await client
         .query({
             query: gql`
             {
                 personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
             }
             `,
             fetchPolicy: 'network-only'
 
         }).then(result=>{
             //si tenemos razon social.
             if(result.data.personasJuridicas.length>0){
                 return {
                     "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                 }
 
             }
             
         })
 
        return datosEmpresa
    
    }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }


    componentDidMount(props){
        this.setState({
            cargandoAN:true
        }, async () => {
            await this.recargarAdjuntos();
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoAN:false})
        })  
        
    }
    render() {
        
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                nombre:null,
                archivo:null,
                descripcion:null,
                adjuntoSeleccionado:null,
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                regionSeleccionada:null
            },()=>{this.tablaActas.clearSelection()})

        }
        const abrirModalEditar = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }
            
            this.setState({nombre:this.state.adjuntoSeleccionado.nombre})
            this.setState({descripcion:this.state.adjuntoSeleccionado.descripcion})


            this.setState({modalAbierto: true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:true})
            this.setState({borrarActivado:false})
        }
        const abrirModalBorrar = () => {
    
          //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if(!this.state.adjuntoSeleccionado){
                alert("Debes seleccionar un adjunto")
                return
            }
            
            this.setState({modalBorrarAbierto: true})
            this.setState({modalAbierto: false})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        const anadirNuevo = () => {

            if(!this.state.nombre)return
            if(!this.state.descripcion)return   

            const api = axios.create({
                withCredentials: true
            });

            var formData = new FormData();
            formData.append("files",this.state.archivo);
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:17,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(async(response)=> {
                alert("creado correctamente")
                await this.recargarAdjuntos()
                //seleccionamos el creado
                let creado=this.state.adjuntos.find((adjunto,index)=>{
                    if(adjunto.id===response.data.data.crearAdjunto.ID_ADJUNTO){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return adjunto.id===response.data.data.crearAdjunto.ID_ADJUNTO

                })

                this.setState({
                    archivo:null,
                    adjuntoSeleccionado:creado
                })

                cerrarModal()

            }).catch(err=>{
                alert("Ha ocurrido un problema al crear el adjunto.Pongase en contacto con el soporte técnico")
            })
  
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const cellRendererNombre = (rowIndex) => {
                return <Cell wrapText="false" truncated="false">{`${this.state.adjuntos[rowIndex].nombre}`}</Cell>
        };

        const cellRendererTipo = (rowIndex) => {
            return <Cell>{`${this.state.adjuntos[rowIndex].tipo}`}</Cell>
        };

        const cellRendererTipoArchivo = (rowIndex) => {
            return <Cell>{`${this.state.adjuntos[rowIndex].mime}`}</Cell>
        };

        const handleCambiarDescripcion = (ev) => {
            this.setState({descripcion:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const handleSeleccionarAdjunto = (adjunto) => {
            if(adjunto.length===0)return
            this.setState({
                adjuntoSeleccionado:this.state.adjuntos[adjunto[0].rows[0]],
                regionSeleccionada:adjunto
            })
        };
        
        const ActualizarAdjunto = () => {
           
            if(!this.state.nombre)return
            if(!this.state.descripcion)return      

            const api = axios.create({
                withCredentials: true
            });
            
            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{actualizarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id},VALORES:{NOMBRE:"${this.state.nombre}",DESCRIPCION:"${this.state.descripcion}"}){ID_ADJUNTO}}`).then( async(response)=> {
                // handle success
                alert("actualizado correctamente")
                await this.recargarAdjuntos()
                //seleccionamos el creado
                let actualizado=this.state.adjuntos.find((adjunto,index)=>{
                    if(adjunto.id===this.state.adjuntoSeleccionado.id){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return adjunto.id===this.state.adjuntoSeleccionado.id
                })

                this.setState({
                    adjuntoSeleccionado:actualizado
                })

                cerrarModal()
                
            }).catch(err=>{
                alert("Ha ocurrido un problema al actualizar el adjunto.Pongase en contacto con el soporte técnico")
            })

        };

        const eliminarAdjunto = () => {

            const api = axios.create({
                withCredentials: true
            });

            api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoSeleccionado.id}){ID_ADJUNTO}}`).then((response)=>{
                // handle success  
                alert("eliminado correctamente")
                this.recargarAdjuntos();
                this.setState({
                    adjuntoSeleccionado:null,
                    regionSeleccionada:null
                })
                this.tablaActas.clearSelection()
                cerrarModal()
            }).catch(err=>{
                alert("Ha ocurrido un problema al eliminar el adjunto.Pongase en contacto con el soporte técnico")
            })
         
            
        };
 
        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({archivo:ev.target.files[0]})
            this.setState({tipoArchivo:ev.target.files[0].type})
            this.setState({nombre:ev.target.files[0].name.replace("."+extension,"")})
          
        }

        const descargarArchivo = () => {
            let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoSeleccionado.ruta.replace(".",""), '_blank')
            win.focus()
        }

        const cerrarModalImprimir= () =>{
            this.setState({
                modalImprimirA:false,
                modalImprimirAI:false
            })
        }
        
        return (
            <>
            {this.state.cargandoAN===true?
                <Spinner/>
            :
            <>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Acta de nombramiento seleccionada"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirAI}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirAI?
                            <div style={{ width: '100%', height: '600px'}} id='generarAI-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de actas de nombramiento"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirA}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirA?
                            <div style={{ width: '100%', height: '600px'}} id='generarA-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.state.nuevoActivado?"Añadir nuevo archivo adjunto":"Editar archivo adjunto"}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalAbierto}
            > 
                <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                {this.state.nuevoActivado?
                    <div className="pixelAlrededor">
                        <FormGroup
                            label="Archivo adjunto"
                            labelFor="informacion"
                            intent="danger"
                            helperText={this.state.archivo?"":"Seleccione el archivo adjunto."}
                        >
                            <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files" style={this.state.archivo?{}:{border:"1px solid red"}} />
                        </FormGroup>
                    </div>
                    :<></>
                }
                <div className="pixelAlrededor">
                    <FormGroup
                            label="Nombre Documento"
                            labelFor="informacion"
                            intent="danger"
                            helperText={this.state.nombre?"":"Escriba el nombre del documento."}
                    >
                        <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre} placeholder="Escriba aqui..." intent={this.state.nombre?"primary":"danger"} />
                    </FormGroup>
                </div>
                <div className="pixelAlrededor">
                    <FormGroup
                            label="Descripción"
                            labelFor="descripcion"
                            intent="danger"
                            helperText={this.state.descripcion?"":"Escriba una descripción para el documento."}
                    >
                        <TextArea
                            large={true}
                            fill={true}
                            intent={this.state.descripcion?Intent.PRIMARY:Intent.DANGER}
                            onChange={handleCambiarDescripcion}
                            defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.descripcion:""}
                        />                    
                    </FormGroup>
                </div>
                </Card>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content="Cerrar y volver." position="top">
                        <Button onClick={cerrarModal}>Cerrar</Button>
                    </Tooltip>
                    <Tooltip content={this.state.nuevoActivado?"Añadir adjunto":"Editar adjunto"} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={this.state.nuevoActivado?anadirNuevo:ActualizarAdjunto}
                        >
                            {this.state.nuevoActivado?"Añadir adjunto":"Editar adjunto"}
                        </AnchorButton>
                    </Tooltip>
                </div>
            </div>
            </Dialog>
            
            <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar un archivo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el archivo adjunto seleccionado ?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar el archivo adjunto." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAdjunto}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Navbar>
                <Navbar.Group>
                    <ButtonGroup align={Alignment.LEFT}>
                        <Button className="bp3-minimal" icon="add"  active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                        <Button className="bp3-minimal" icon="edit"  active={this.state.editarActivado} onClick={abrirModalEditar}/>
                        <Button className="bp3-minimal" icon="delete"  active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                        <Tooltip content="Desea imprimir acta seleccionada" position="right" >
                            <Button className="bp3-minimal" icon="print" disabled={this.state.adjuntoSeleccionado?false:true}  text="Imprimir Acta" onClick={()=>this.generarImprimirAI()}/>
                        </Tooltip>
                        <Tooltip content="Desea imprimir todas las actas" position="right" >
                            <Button className="bp3-minimal" icon="print" text="Imprimir General" onClick={()=>this.generarImprimirA()}/>
                        </Tooltip>
                        <NavbarDivider />
                        <Button className="bp3-minimal" icon="document-open" text="Abrir el archivo" disabled={this.state.adjuntoSeleccionado?false:true} onClick={descargarArchivo}></Button>
                    </ButtonGroup>
                </Navbar.Group>
            </Navbar>
            <Table enableRowHeader={false} enableMultipleSelection={false} ref={(input) => { this.tablaActas= input; }} selectedRegions={this.state.regionSeleccionada} onSelection={handleSeleccionarAdjunto}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} columnWidths={this.state.columnWidths} enableColumnResizing={false} numRows={this.state.adjuntos.length} onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}>
                <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                <Column name="Tipo" cellRenderer={cellRendererTipo}/>   
                <Column name="Tipo de archivo" cellRenderer={cellRendererTipoArchivo}/>   
            </Table>
        </>
        }
      </>  
        )
    }
}
