import React, { Component } from 'react';
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import { Tab, Tabs } from "@blueprintjs/core";
import { Query } from "react-apollo";

import InformacionGeneral from "../Pantallas/Elementos/InformacionGeneral"
import MarcasDominios from "../Pantallas/Elementos/MarcasDominios"
import MediosContacto from "../Pantallas/Elementos/MediosContacto"
import CentrosTrabajo from "../Pantallas/Elementos/CentrosTrabajo"
import Documentos from "../Pantallas/Elementos/Documentos"
import Vinculos from "../Pantallas/Elementos/Vinculos"
import ArchivosAdjuntos from "../Pantallas/Elementos/ArchivosAdjuntos"
import ImprimirInformacionEmpresa from "./Elementos/ImprimirInformacionEmpresa"
import { Cargando } from "./Cargando";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';



let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

const obtenerEmpresaPrincial=gql`{personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}}`

class DatosEmpresa extends Component {

    state = {
        pestaña: "informacion",
        animacion: true,
        idPersona:null,
    }
    

    render() {
        
        document.title =i18next.t("empresa.title",{ns:'company'});

        return(
            <Query query={obtenerEmpresaPrincial} client={client}>
                {({ loading, error, data }) => {
                if (loading)  return <Cargando />;
                if (error) return `Error! ${error.message}`;
                return (
                    <Tabs id="datos-empresa"
                        animate={this.animacion} 
                        onChange={this.cambiarPestaña} 
                        selectedTabId={localStorage.getItem("pestanaEmpresa")?localStorage.getItem("pestanaEmpresa"):"informacion"}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="informacion" title={i18next.t("empresa.informacion", { ns: 'company' })} panel={<InformacionGeneral editable={this.props.editable}  empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA} />} />
                        <Tab id="marcas_dominios" title={i18next.t("empresa.marcasdominios", { ns: 'company' })} panel={<MarcasDominios editable={this.props.editable}  empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA} />} />
                        <Tab id="medios de contacto" title={i18next.t("empresa.medioscontacto", { ns: 'company' })} panel={<MediosContacto editable={this.props.editable} empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA} />} />
                        <Tab id="centros de trabajo" title={i18next.t("empresa.centrostrabajo", { ns: 'company' })} panel={<CentrosTrabajo editable={this.props.editable} empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA} />} />
                        <Tab id="documentos" title={i18next.t("empresa.documentos", { ns: 'company' })} panel={<Documentos editable={this.props.editable} empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA }/>} />
                        <Tab id="vinculos" title={i18next.t("empresa.vinculos", { ns: 'company' })} panel={<Vinculos editable={this.props.editable} empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA}/>} />
                        <Tab id="adjuntos" title={i18next.t("empresa.adjuntos", { ns: 'company' })} panel={<ArchivosAdjuntos editable={this.props.editable}  empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA}/>} />
                        <Tab id="imprimir" title={i18next.t("empresa.imprimir", { ns: 'company' })} panel={<ImprimirInformacionEmpresa editable={this.props.editable}  empresaSeleccionada={data.personasJuridicas[0].ID_PERSONA}/>} />
                    </Tabs>
                )
                }}
            </Query>
        )
    }

    cambiarPestaña = (navbarTabId) =>{
        localStorage.setItem("pestanaEmpresa",navbarTabId);
        this.setState(this.state)
    } 
}

export default withTranslation()(DatosEmpresa);