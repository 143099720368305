/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Spinner,Tab, Tabs, Card, Elevation, Navbar, ButtonGroup, Alignment, Button, Dialog,  Classes, Tooltip, AnchorButton } from "@blueprintjs/core"
import DatosSiniestro from "./DatosSiniestro.js"
import InstruccionSiniestro from "./InstruccionSiniestro"
import DiligenciasSiniestros from "./DiligenciasSiniestros"
import Resolucion from "./Elementos/ResolucionSiniestros"
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "./Cargando";
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import const_table from './BootstrapTable';
import paginationFactory from 'react-bootstrap-table2-paginator';



import styles from './stylesPrint';

class Siniestros extends Component {

    state = {
        nuevoActivado: true,
        editarActivado: false,
        idUsuarioActual:null,
        siniestroSeleccionado:null,
        siniestros: [
        ],
        pasoGuia:0,
        steps: [
            {
                target: '#contenedor-siniestros',
                content: i18next.t("siniestros.step0", { ns: "page2" }),
                selector: '#contenedor-siniestros',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:0, 
            },  
            {
                target: '#btn-crear-siniestro',
                content: i18next.t("siniestros.step1", { ns: "page2" }),
                selector: '#btn-crear-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:1, 
            },  
            {
                target: '#bp3-tab-title_denuncias_datos-siniestro',
                content: i18next.t("siniestros.step2", { ns: "page2" }),
                selector: '#bp3-tab-title_denuncias_datos-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:2, 
            },  
            {
                target: '#bp3-tab-title_denuncias_instrucciones-siniestro',
                content: i18next.t("siniestros.step3", { ns: "page2" }),
                selector: '#bp3-tab-title_denuncias_instrucciones-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:3, 
            }, 
            {
                target: '#boleano-comenzar-instruccion-siniestro',
                content: i18next.t("siniestros.step4", { ns: "page2" }),
                selector: '#boleano-comenzar-instruccion-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:4, 
            }, 
            {
                target: '#btn-crear-estado-instruccion-siniestro',
                content: i18next.t("siniestros.step5", { ns: "page2" }),
                selector: '#btn-crear-estado-instruccion-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:5, 
            }, 
            {
                target: '#bp3-tab-title_denuncias_diligencias-siniestro',
                content: i18next.t("siniestros.step6", { ns: "page2" }),
                selector: '#bp3-tab-title_denuncias_diligencias-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:6, 
            },
            {
                target: '#btn-crear-diligencia-siniestro',
                content: i18next.t("siniestros.step7", { ns: "page2" }),
                selector: '#btn-crear-diligencia-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:7, 
            },
            {
                target: '#modal-diligencias-siniestros',
                content: i18next.t("siniestros.step8", { ns: "page2" }),
                selector: '#modal-diligencias-siniestros',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:8, 
            },
            {
                target: '#boleano-resolucion-siniestro',
                content: i18next.t("siniestros.step9", { ns: "page2" }),
                selector: '#boleano-resolucion-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:9, 
            },
            {
                target: '#datos-resolucion-siniestro',
                content: i18next.t("siniestros.step10", { ns: "page2" }),
                selector: '#datos-resolucion-siniestro',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:10, 
            },
        ],
        cargando:false,
        cargandoImprimir:false,
        modalImprimir:false,
        logotipo: null,
        datosEmpresa:[],
        columnWidths:[100,100,100,100],
        resized: false,
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }
   cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa() 
        this.setState({
            datosEmpresa:datosEmpresa,
        
        })
    }

    cargarSiniestros=async ()=>{

        let tablaSiniestros=[]
         //cargamos los siniestros en la tabla
        let siniestros=await this.props.client
         .query({
             query: gql`
             {siniestros{
                 ID_SINIESTRO,
                 ID_DENUNCIA,
                 RIESGOS_ASOCIADOS,
                 FECHA,
                 DESCRIPCION,
                 }}
             `,fetchPolicy:'network-only'
         }).then(async result=>{ 
 
            return result.data.siniestros
         })
         let siniestrosInstr= await this.props.client
         .query({
             query: gql`
             {siniestrosInstrucciones{
                 ID_SINIESTRO,
                 FECHA_ALTA,
                 ID_ESTADO_INSTRUCCION,
                 ESTADO_INSTRUCCION{DESCRIPCION},
                 CONCLUSIONES
             }}
             `,fetchPolicy:'network-only'
 
         }).then(result=>{             
             return result.data.siniestrosInstrucciones 
         })
         let siniestrosResol=await this.props.client
         .query({
             query: gql`
             {siniestrosResolucion{
                 ID_SINIESTRO,
                 DECISIONES_TOMADAS,
                 ID_RESPONSABLE,
                 FECHA_RESOLUCION,
                 RIESGOS_AFECTADOS
             }}
             `,fetchPolicy:'network-only'
         }).then(result=>{ 
             return result.data.siniestrosResolucion      
 
         })
 
         //let index= 0;
         for(let siniestro of siniestros){
             let instrVal=[]
            siniestrosInstr.find(instruccion=>{
                if(instruccion.ID_SINIESTRO===siniestro.ID_SINIESTRO){
                        instrVal.push(instruccion)    
                }
            })
            let resVal=[]
            siniestrosResol.find(resolucion=>{
                if(resolucion.ID_SINIESTRO===siniestro.ID_SINIESTRO){
                    resVal.push(resolucion)
                }
            })
            // await this.obtenerDatosSiniestro(siniestro)

            let instruccion=(instrVal.length>0?true:false)
            let resolucion=(resVal.length>0?true:false)
            let estadoIns=(instruccion?instrVal[0].ESTADO_INSTRUCCION.DESCRIPCION:"")


            tablaSiniestros.push({
                datosSiniestro:{
                    "id":siniestro.ID_SINIESTRO,
                    "idDenuncia":siniestro.ID_DENUNCIA,
                    "riesgos":siniestro.RIESGOS_ASOCIADOS,
                    "fechaSiniestro":siniestro.FECHA,
                    "descripcion":siniestro.DESCRIPCION,
                    "estadoInstruccion": estadoIns,
                    "instruccion":instruccion,
                    "resolucion":resolucion},
                    
                datosInstruccion:instrVal.length>0?instrVal[0]:null,
                datosResolucion:resVal.length>0?resVal[0]:null})
            //index++
            
        }
        this.setState({siniestros:tablaSiniestros})    
    }
    cargarUsuario=async() =>{
        
       this.props.client
       .query({
           query: gql`
           {obtenerUsuarioActual}
           `,fetchPolicy:'network-only'
       }).then(result=>{ 
           this.setState({idUsuarioActual:result.data.obtenerUsuarioActual}) 
       })
    }
    componentDidMount(){
        this.setState({cargando:true}, async () =>{
            await this.cargarSiniestros()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarUsuario()
            this.setState({
                cargando:false
            })
        })
    }

    generarImprimirS=async() =>{
        this.setState({
            modalImprimir: true,
            cargandoImprimir:true
        }, async () =>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirSiniestros(), document.getElementById('generarS-pdf'))
            })
        })
    }
    ImprimirSiniestros=() =>(
         <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4"  style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHeadL}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                <Text style={styles.text}>{i18next.t("siniestros.incidents", { ns: "page2" }).toUpperCase()} - {i18next.t("siniestros.claims", { ns: "page2" }).toUpperCase()}</Text>
            </View>
            <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{i18next.t("siniestros.incidents", { ns: "page2" }).toUpperCase()} | {i18next.t("siniestros.claims", { ns: "page2" }).toUpperCase()}</Text>
            </View>
                {this.state.siniestros?this.state.siniestros.map((siniestro, index)=>{
                    return(
                        <View key={index}>
                           {index ===0 && (
                            <View style={styles.colHead}>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("siniestros.claimDate", { ns: "page2" }).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("siniestros.instruction", { ns: "page2" }).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{i18next.t("siniestros.instructionStatus", { ns: "page2" }).toUpperCase()}</Text>
                               <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'25%'}}>{i18next.t("siniestros.resolved", { ns: "page2" }).toUpperCase()}</Text>
                            </View>
                           )}
                            <View style={styles.colHead}>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{siniestro.datosSiniestro?moment(siniestro.datosSiniestro.fechaSiniestro).format('DD-MM-YYY'):''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{siniestro.datosSiniestro.instruccion?i18next.t("yes", { ns: "global" }):i18next.t("no", { ns: "global" })}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{siniestro.datosSiniestro?siniestro.datosSiniestro.estadoInstruccion:''}</Text>
                                <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'25%'}}>{siniestro.datosSiniestro.resolucion?i18next.t("yes", { ns: "global" }):i18next.t("no", { ns: "global" })}</Text>
                            </View> 
                        
                    </View>
                    )
                }):<></>}      
        </View>

        <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
            fixed
          />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
              <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
      </Page>
    </Document>
  </PDFViewer>
    )
    recargar=async()=>this.setState(this.state)

    render() {

        const {steps} = this.state;

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("siniestros.incidents", {ns:"page2"})} ${i18next.t("siniestros.claims", {ns:"page2"})}`

        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false
            })
        }

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({modalAbierto: true})
            this.setState({nuevoActivado: true})
            this.setState({editarActivado: false})
            this.setState({siniestroSeleccionado: null})
            this.setState({pestaña: "datos-siniestro"})
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.siniestroSeleccionado){
                alert(`${i18next.t("global.emptyfield", { ns: "page" })} ${i18next.t("siniestros.claim", { ns: "page2" })}`)
                return
            }
            this.setState({modalAbierto: true})
            this.setState({nuevoActivado: false})
            this.setState({editarActivado: true})
            this.setState({pestaña: "datos-siniestro"})

        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD-MM-YYYY")
            );
        }
        const yes=i18next.t("yes", { ns: "global" });
        const no=i18next.t("no", { ns: "global" });
        function boolFormatter(cell, row) {
            return (
                (cell)?yes:no
            );
        }

        const handleOnSelect =(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    siniestroSeleccionado: row
                },async()=>{
                })
                
            }
            return true; 
        }

        const columns = [
            {
                dataField: 'datosSiniestro.fechaSiniestro',
                text: i18next.t("siniestros.claimDate", { ns: "page2" }),
                formatter: dateFormatter

            },
            {
                dataField: 'datosInstruccion',
                text: i18next.t("siniestros.instruction", { ns: "page2" }),
                formatter: boolFormatter
            },
            {
                dataField: 'datosInstruccion.ESTADO_INSTRUCCION.DESCRIPCION',
                text: i18next.t("siniestros.instructionStatus", { ns: "page2" }),

            },
            {
                dataField: 'datosResolucion',
                text: i18next.t("siniestros.resolved", { ns: "page2" }),
                formatter: boolFormatter

            }
        ];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };
        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
            })
        }

        const anadirNuevo=async ()=>{

            if(!this.tabDatosSiniestro.state.denunciaSeleccionada){
                this.setState({pestaña:"datos-siniestro"})
                return
            }

            if(!this.tabDatosSiniestro.state.descripcion){
                this.setState({pestaña:"datos-siniestro"})
                return
            }

            if(this.tabInstruccionSiniestro.state.comenzada && !this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado ){
                this.setState({pestaña:"instrucciones-siniestro"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.personaResponsableSeleccionada){
                this.setState({pestaña:"resolucion"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.decisionesTomadas){
                this.setState({pestaña:"resolucion"})
                return
            }

            //mutacion para crear el siniestro.
            await this.props.client
            .mutate({
            mutation: gql`
                    mutation{crearSiniestro(VALORES:{
                    ID_DENUNCIA:${this.tabDatosSiniestro.state.denunciaSeleccionada.value},
                    RIESGOS_ASOCIADOS:${this.tabDatosSiniestro.state.riesgosSeleccionados.length>0?'"'+this.tabDatosSiniestro.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                    FECHA:"${moment(this.tabDatosSiniestro.state.fechaSiniestro).format("YYYY-MM-DD")}",
                    DESCRIPCION:"""${this.tabDatosSiniestro.state.descripcion}"""}){
                        ID_SINIESTRO,
                        ID_DENUNCIA,
                        RIESGOS_ASOCIADOS,
                        FECHA,
                        DESCRIPCION
                    }}
            `
            })
            .then(async result => {
              
                let datosSiniestro=result.data.crearSiniestro
                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{crearSiniestroH(VALORES:{
                        ID_SINIESTRO:${datosSiniestro.ID_SINIESTRO}
                        ID_DENUNCIA:${datosSiniestro.ID_DENUNCIA},
                        RIESGOS_ASOCIADOS:${datosSiniestro.RIESGOS_ASOCIADOS?'"'+datosSiniestro.RIESGOS_ASOCIADOS+'"':null},
                        FECHA:"${datosSiniestro.FECHA}",
                        DESCRIPCION:"""${datosSiniestro.DESCRIPCION}""",
                        FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                        ID_USUARIO:${this.state.idUsuarioActual},
                    }){ID_SINIESTRO}}
                `
                })

                let error=false
                //creacion del siniestro instruccion.
                //INSERCCION EN DENUNCIAS_INSTRUCCION
                if(this.tabInstruccionSiniestro && this.tabInstruccionSiniestro.state.comenzada){
                    await this.props.client.mutate({
                            mutation:gql`mutation{crearSiniestroInstruccion(VALORES:{
                                    ID_SINIESTRO:${datosSiniestro.ID_SINIESTRO},
                                    FECHA_ALTA:${this.tabInstruccionSiniestro.state.fechaInstruccion?'"'+moment(this.tabInstruccionSiniestro.state.fechaInstruccion,"DD-MM-YYYY").format('YYYY-MM-DD')+'"':null},
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado?this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado.value:null},
                                    CONCLUSIONES:${this.tabInstruccionSiniestro.state.conclusiones?'"""'+this.tabInstruccionSiniestro.state.conclusiones+'"""':null},
                                }){
                                    ID_SINIESTRO,
                                    FECHA_ALTA,
                                    ID_ESTADO_INSTRUCCION,
                                    CONCLUSIONES,
                                }}`
                        }).then(async result=>{

                        let datosSiniestroInstruccion=result.data.crearSiniestroInstruccion
                        await this.props.client.mutate({
                            mutation:gql`mutation{crearSiniestroInstruccionH(VALORES:{
                                ID_SINIESTRO:${datosSiniestroInstruccion.ID_SINIESTRO},
                                FECHA_ALTA:"${datosSiniestroInstruccion.FECHA_ALTA}",
                                ID_ESTADO_INSTRUCCION:${datosSiniestroInstruccion.ID_ESTADO_INSTRUCCION},
                                CONCLUSIONES:${datosSiniestroInstruccion.CONCLUSIONES?'"""'+datosSiniestroInstruccion.CONCLUSIONES+'"""':null},
                                FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                ID_USUARIO:${this.state.idUsuarioActual}
                            }){ID_SINIESTRO}}`
                        })

                    }).catch(err=>{
                        error=true
                        alert(i18next.t("error", { ns: "global" }))
                        return
                    })

                } 

                if(error)return

                //INSERCCION EN DENUNCIAS_RESOLUCION
                if(this.tabResolucion && this.tabResolucion.state.resuelta){

                    await this.props.client.mutate({
                        mutation:gql`mutation{crearSiniestroResolucion(VALORES:{
                                ID_SINIESTRO:${datosSiniestro.ID_SINIESTRO},
                                FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                            }){
                                ID_SINIESTRO,
                                FECHA_RESOLUCION,
                                DECISIONES_TOMADAS,
                                RIESGOS_AFECTADOS,
                                ID_RESPONSABLE
                            }}`
                    }).then(async result=>{

                        let datosSiniestroResolucion=result.data.crearSiniestroResolucion
                        await this.props.client.mutate({
                            mutation:gql`mutation{crearSiniestroResolucionH(VALORES:{
                                ID_SINIESTRO:${datosSiniestroResolucion.ID_SINIESTRO},
                                FECHA_RESOLUCION:"${moment(datosSiniestroResolucion.FECHA_RESOLUCION).format('YYYY-MM-DD')}",
                                DECISIONES_TOMADAS:"""${datosSiniestroResolucion.DECISIONES_TOMADAS}""",
                                RIESGOS_AFECTADOS:${datosSiniestroResolucion.RIESGOS_AFECTADOS?'"'+datosSiniestroResolucion.RIESGOS_AFECTADOS+'"':null},
                                ID_RESPONSABLE:${datosSiniestroResolucion.ID_RESPONSABLE}
                                FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}"
                                ID_USUARIO:${this.state.idUsuarioActual}
                            }){ID_SINIESTRO}}`
                        })

                    }).catch(err=>{
                        error=true
                        alert(i18next.t("error", { ns: "global" }))
                        return
                    })

                }

                alert(i18next.t("insertcorrecto", { ns: "global" }))

                await cerrarModal()
                
                await this.cargarSiniestros()
    
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })

       
        }

        const editarSiniestro=async ()=>{

            if(!this.tabDatosSiniestro.state.denunciaSeleccionada){
                this.setState({pestaña:"datos-siniestro"})
                return
            }

            if(!this.tabDatosSiniestro.state.descripcion){
                this.setState({pestaña:"datos-siniestro"})
                return
            }

            if(this.tabInstruccionSiniestro.state.comenzada && !this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado ){
                this.setState({pestaña:"instrucciones-siniestro"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.personaResponsableSeleccionada){
                this.setState({pestaña:"resolucion"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.decisionesTomadas){
                this.setState({pestaña:"resolucion"})
                return
            }
            
            //mutacion para crear el siniestro.
            await this.props.client
            .mutate({
            mutation: gql`
                    mutation{actualizarSiniestro(ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},VALORES:{
                    ID_DENUNCIA:${this.tabDatosSiniestro.state.denunciaSeleccionada.value},
                    RIESGOS_ASOCIADOS:${this.tabDatosSiniestro.state.riesgosSeleccionados.length>0?'"'+this.tabDatosSiniestro.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                    FECHA:"${moment(this.tabDatosSiniestro.state.fechaSiniestro).format("YYYY-MM-DD")}",
                    DESCRIPCION:"""${this.tabDatosSiniestro.state.descripcion}"""}){ID_SINIESTRO}}
            `
            })
            .then(async result => {
             
                await this.props.client
                .mutate({
                mutation: gql`
                        mutation{actualizarSiniestroH(ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},VALORES:{
                        ID_DENUNCIA:${this.tabDatosSiniestro.state.denunciaSeleccionada.value},
                        RIESGOS_ASOCIADOS:${this.tabDatosSiniestro.state.riesgosSeleccionados.length>0?'"'+this.tabDatosSiniestro.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                        FECHA:"${moment(this.tabDatosSiniestro.state.fechaSiniestro).format("YYYY-MM-DD")}",
                        DESCRIPCION:"""${this.tabDatosSiniestro.state.descripcion}"""
                        FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                        ID_USUARIO:${this.state.idUsuarioActual}
                    }){ID_SINIESTRO}}
                        
                `
                })
                
                let error=false
                //creacion del siniestro instruccion.
                //INSERCCION EN DENUNCIAS_INSTRUCCION
                if(this.tabInstruccionSiniestro && this.tabInstruccionSiniestro.state.comenzada){

                    //si no existe
                    if(this.tabInstruccionSiniestro.state.noexiste){

                        await this.props.client.mutate({
                            mutation:gql`mutation{crearSiniestroInstruccion(VALORES:{
                                    ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},
                                    FECHA_ALTA:${this.tabInstruccionSiniestro.state.fechaInstruccion?'"'+moment(this.tabInstruccionSiniestro.state.fechaInstruccion,"DD-MM-YYYY").format('YYYY-MM-DD')+'"':null},
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado?this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado.value:null},
                                    CONCLUSIONES:${this.tabInstruccionSiniestro.state.conclusiones?'"""'+this.tabInstruccionSiniestro.state.conclusiones+'"""':null},
                                }){
                                    ID_SINIESTRO,
                                    FECHA_ALTA,
                                    ID_ESTADO_INSTRUCCION,
                                    CONCLUSIONES
                                }}`
                        }).then(async result=>{

                            let datosSiniestroInstruccion=result.data.crearSiniestroInstruccion
                            await this.props.client.mutate({
                                mutation:gql`mutation{crearSiniestroInstruccionH(VALORES:{
                                    ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},
                                    FECHA_ALTA:"${datosSiniestroInstruccion.FECHA_ALTA}",
                                    ID_ESTADO_INSTRUCCION:${datosSiniestroInstruccion.ID_ESTADO_INSTRUCCION},
                                    CONCLUSIONES:${datosSiniestroInstruccion.CONCLUSIONES?'"""'+datosSiniestroInstruccion.CONCLUSIONES+'"""':null},
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_SINIESTRO}}`
                            })

                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error", { ns: "global" }))
                            return
                        })

                    }else{

                        await this.props.client.mutate({
                            mutation:gql`mutation{actualizarSiniestroInstruccion(ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},VALORES:{
                                    FECHA_ALTA:${this.tabInstruccionSiniestro.state.fechaInstruccion?'"'+moment(this.tabInstruccionSiniestro.state.fechaInstruccion,"DD-MM-YYYY").format('YYYY-MM-DD')+'"':null},
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado?this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado.value:null},
                                    CONCLUSIONES:${this.tabInstruccionSiniestro.state.conclusiones?'"'+this.tabInstruccionSiniestro.state.conclusiones+'"':null},
                                }){ID_SINIESTRO}}`
                        }).then(async result=>{

                            await this.props.client.mutate({
                            mutation:gql`mutation{actualizarSiniestroInstruccionH(ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},
                                VALORES:{
                                    FECHA_ALTA:${this.tabInstruccionSiniestro.state.fechaInstruccion?'"'+moment(this.tabInstruccionSiniestro.state.fechaInstruccion,"DD-MM-YYYY").format('YYYY-MM-DD')+'"':null},
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado?this.tabInstruccionSiniestro.state.estadoInstruccionSeleccionado.value:null},
                                    CONCLUSIONES:${this.tabInstruccionSiniestro.state.conclusiones?'"'+this.tabInstruccionSiniestro.state.conclusiones+'"':null},
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_SINIESTRO}}`
                            })

                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error", { ns: "global" }))
                            return
                        })

                    }                        

                } 

                if(error)return

                //INSERCCION EN DENUNCIAS_RESOLUCION
                if(this.tabResolucion && this.tabResolucion.state.resuelta){

                    //si no existe la creamos...
                    if(this.tabResolucion.state.noexiste){

                        await this.props.client.mutate({
                            mutation:gql`mutation{crearSiniestroResolucion(VALORES:{
                                    ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                }){ID_SINIESTRO}}`
                        }).then(async result=>{

                            await this.props.client.mutate({
                                mutation:gql`mutation{crearSiniestroResolucionH(VALORES:{
                                    ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value},
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}"
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_SINIESTRO}}`
                            })

                        }).catch(err=>{
                            alert(i18next.t("error", { ns: "global" }))
                            return
                        })

                    }else{
                        
                        await this.props.client.mutate({
                            mutation:gql`mutation{actualizarSiniestroResolucion(ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},VALORES:{
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"${this.tabResolucion.state.decisionesTomadas}",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                }){ID_SINIESTRO}}`
                        }).then(async result=>{

                            await this.props.client.mutate({
                                mutation:gql`mutation{actualizarSiniestroResolucionH(
                                    ID_SINIESTRO:${this.state.siniestroSeleccionado.datosSiniestro.id},
                                VALORES:{
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"${this.tabResolucion.state.decisionesTomadas}",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value},
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}"
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_SINIESTRO}}`
                            })

                        }).catch(err=>{
                            alert(i18next.t("error", { ns: "global" }))
                            return
                        })    
                    
                    }

                }

                alert(i18next.t("insertcorrecto", { ns: "global" }))

                await cerrarModal()
                
                await this.cargarSiniestros()

                
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error", { ns: "global" }))
                return
            })

       
        }

        const cambiarPestaña=(value)=>{
            this.setState({pestaña:value})
         }

        const handleJoyrideCallback=async(data)=>{
        

            const { action, index, status, type } = data;
      
            if ([EVENTS.STEP_AFTER].includes(type)) {
      
              let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
      
              //paso en el que nos encontremos.
              // eslint-disable-next-line default-case
              switch(paso){
                case 0:
                    await this.setState({siniestroSeleccionado:null})
                break;
                case 1:
                    await this.setState({modalAbierto:false})
                break;
                case 2:
                    await this.setState({modalAbierto:true,pestaña:"datos-siniestro"})
                break;
                case 3:
                    await this.setState({modalAbierto:true,pestaña:"instrucciones-siniestro"})
                break;
                case 4:
                    await this.tabInstruccionSiniestro.setState({comenzada:false})
                break;
                case 5:
                    await this.tabInstruccionSiniestro.setState({comenzada:true})
                    await this.setState({pestaña:"instrucciones-siniestro"})
                break;
                case 6:
                    await this.setState({pestaña:"diligencias-siniestro"})
                break;
                case 7:
                    await this.tabDiligenciasSiniestros.setState({modalAbierto:false})
                break;
                case 8:
                    await this.tabDiligenciasSiniestros.setState({modalAbierto:true})
                    await this.setState({pestaña:"diligencias-siniestro"})
                break;
                case 9:
                    await this.tabDiligenciasSiniestros.setState({modalAbierto:false})
                    await this.tabResolucion.setState({resuelta:false})
                    await this.setState({pestaña:"resolucion"})
                break;
                case 10:
                    await this.tabResolucion.setState({resuelta:true})
                break;
              }
      
              await this.setState({ pasoGuia: paso });
      
            }
      
            //si termina la guia.
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                localStorage.setItem("ayuda",false)
                await this.setState({modalAbierto:false})

            }
      
        } 

        return (
            <>  
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("siniestros.printClaimsReport", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '500px'}} id='generarS-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Joyride
                    run={localStorage.getItem("ayuda")==="true"?true:false}
                    callback={handleJoyrideCallback}
                    showProgress={true}
                    stepIndex={this.state.pasoGuia}
                    steps={steps}
                    disableScrollParentFix={true}
                    continuous ={true}
                    locale={{ back: i18next.t("return", { ns: "global" }), close: i18next.t("close", { ns: "global" }), last: i18next.t("last", { ns: "global" }), next: i18next.t("next", { ns: "global" }), skip: i18next.t("jump", { ns: "global" }) }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            primaryColor: '#E31E24',
                        }
                    }}
                />
            
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("siniestros.claimProperties", { ns: "page2" })}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    className="dialogo-denuncias"
                >
                    <div className="pixelAlrededor">
                        <Tabs id="denuncias"
                            animate={this.animacion} 
                            onChange={cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                        >
                            <Tab id="datos-siniestro" title={i18next.t("siniestros.claimDetails", { ns: "page2" })} panel={ <DatosSiniestro  ref={(input) => { this.tabDatosSiniestro= input; }}  datosSiniestro={this.state.siniestroSeleccionado?(this.state.siniestroSeleccionado.datosSiniestro?this.state.siniestroSeleccionado.datosSiniestro:null):null} /> } />
                            <Tab id="instrucciones-siniestro" title={i18next.t("siniestros.instruction", { ns: "page2" })} panel={ <InstruccionSiniestro ref={(input) => { this.tabInstruccionSiniestro= input; }} datosInstruccion={this.state.siniestroSeleccionado ?(this.state.siniestroSeleccionado.datosInstruccion?this.state.siniestroSeleccionado.datosInstruccion:null):null} /> } />
                                    <Tab id="diligencias-siniestro" title={i18next.t("siniestros.proceedings", { ns: "page2" }) } panel={ <DiligenciasSiniestros recargar={this.recargar} siniestro={this.state.siniestroSeleccionado?this.state.siniestroSeleccionado.datosSiniestro.id:null} ref={(input) => { this.tabDiligenciasSiniestros= input; }} deshabilitado={this.state.siniestroSeleccionado && this.state.siniestroSeleccionado.datosInstruccion?(Object.keys(this.state.siniestroSeleccionado.datosInstruccion).length>0?false:true):localStorage.getItem("ayuda")==="true"?false:true} /> } />
                            <Tab id="resolucion" title={i18next.t("siniestros.resolution", { ns: "page2" })} panel={ <Resolucion ref={(input) => { this.tabResolucion= input; }} datosResolucion={this.state.siniestroSeleccionado?(this.state.siniestroSeleccionado.datosResolucion?this.state.siniestroSeleccionado.datosResolucion:null):null}/> } />
                        </Tabs>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("siniestros.addClaim", { ns: "page2" }):i18next.t("siniestros.editClaim", { ns: "page2" })} position="top">
                            <AnchorButton
                                intent="primary"
                                onClick={this.state.nuevoActivado?anadirNuevo:editarSiniestro} 
                            >
                                {this.state.nuevoActivado?i18next.t("siniestros.addClaim", { ns: "page2" }):i18next.t("siniestros.updateClaim", { ns: "page2" })} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div id="contenedor-siniestros" className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO} >
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Button className="bp3-minimal" id="btn-crear-siniestro" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                    <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                    <Button className="bp3-minimal" icon="print" text={i18next.t("printgral", { ns: "global" })} onClick={()=>this.generarImprimirS()}/>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField="datosSiniestro.id"
                            data={this.state.siniestros}
                            columns={columns}
                            selectRow={selectRow}
                            pagination={ paginationFactory(const_table.options)}
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }

                        />
                    </Card>
                </div>
            </>
            }
          </>  
        )
    }
}
export default withTranslation()(withApollo(Siniestros))