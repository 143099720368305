/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import * as React from "react";
import { Button, Classes, Dialog,InputGroup } from "@blueprintjs/core";
import {IExampleProps } from "@blueprintjs/docs-theme";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import logo from '../Assets/LOGO REGISTRADO PEQUEÑO.png';
import { Link,BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import axios from 'axios';

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/login",
    credentials: 'include',
});


export interface IDialogExampleState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    isOpen: boolean;
    usePortal: boolean;
    usuario: "";
    clave: "";
    errorUsuario: boolean;
    errorClave: boolean;
    errorLogin: boolean;
    isCloseButtonShown:boolean;
    mostrarClave:boolean;
}
export class DialogExample extends React.PureComponent<IExampleProps, IDialogExampleState> {

    state: IDialogExampleState = {
        autoFocus: true,
        canEscapeKeyClose: false,
        canOutsideClickClose: false,
        enforceFocus: true,
        isOpen: true,
        usePortal: true,
        usuario: "usuarioweb@example.com",
        clave: "usuarioweb1853*",
        errorUsuario: false,
        errorClave: false,
        errorLogin: false,
        isCloseButtonShown:false,
        mostrarClave:false

    };
    
    handleUsuarioChange = (evt) => {
        this.setState({ usuario: evt.target.value });
        this.setState({ errorUsuario: false });

    }
    handleClaveChange = (evt) => {
        this.setState({ clave: evt.target.value });
        this.setState({ errorClave: false });
    }
    handleLockClick = (evt) => {
        this.setState({mostrarClave:true})
    }
    handleKeyDown = function (e, cb) {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            cb();
        }
    };

    recordarClave = (e)=> {

        e.preventDefault()

        if(!this.state.usuario){
            alert("Debe escribir un usuario.")
            return
        }

        if (this.state.usuario==="usuarioweb@example.com") {
            alert("Usuario web no se puede recuperar clave.")
            return
        }

        const api = axios.create({
            withCredentials: true
        });
        api.post(`${process.env.REACT_APP_DOMINIO}/recuperar/${this.state.usuario}`)

        alert("Si el usuario existe en nuestro sistema, se le enviará un correo de recuperación.Gracias.")
        
    };

    render() {
       

        var errorUsuario=((this.state.errorUsuario)?"mostrar":"ocultar");
        var errorClave=((this.state.errorClave)?"mostrar":"ocultar");
        var errorLogin=((this.state.errorLogin)?"mostrar":"ocultar");

        return (
            <form
            onKeyDown={(e) => { this.handleKeyDown(e, this.handleSubmit); }}

            >
                <Dialog transitionDuration={400}
                    icon="log-in"
                    title="Login"
                    transitionName=""
                    {...this.state}
                    className="formLogin"
                >
                    <img src={logo} alt="logo" className="form-imagen"/>

                        <div className={Classes.DIALOG_BODY}>
                            <div className={Classes.FORM_GROUP} >
                                <InputGroup
                                    placeholder="Ingrese su usuario"
                                    leftIcon="user"
                                    type="text"
                                    large="true"
                                    ref={(input) => { this.campoUsuario= input; }} 
                                    onChange={this.handleUsuarioChange}
                                    value={this.state.usuario}
                                />
                                <p className={errorUsuario}>El campo usuario no puede quedar vacío.</p>
                            </div>
                            <div className={Classes.FORM_GROUP} >
                                <InputGroup
                                    placeholder="Ingrese su clave"
                                    leftIcon="lock"
                                    type="password"
                                    large="true"
                                    ref={(input) => { this.campoClave= input; }} 
                                    onChange={this.handleClaveChange}
                                    value={this.state.clave}
                                />
                                <p className={errorClave}>El campo clave no puede quedar vacío.</p>
                            </div>
                        </div>
                        <p className={errorLogin} style={{width:"100%",textAlign:"center"}}>Usuario o clave incorrecto.</p>
                        <div className={Classes.DIALOG_FOOTER}>
                            <Router>
                                <Link className="linkRecordar" to="/" onClick={this.recordarClave}><p>Recordar Clave</p></Link>
                            </Router>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button large="true" fill="true" className={Classes.INTENT_PRIMARY} onClick={this.handleSubmit}>Acceder</Button>
                            </div>
                        </div>
                </Dialog>
            </form>
        );
        
    }

    //Envio de las credenciales a la API.
    handleSubmit = () => {
        
        if (this.state.usuario==="") {
            this.setState({errorUsuario:true});
            ReactDOM.findDOMNode(this.campoUsuario).children[1].focus()
            return;
        }
        if (this.state.clave === "") {
            this.setState({errorClave:true});
            ReactDOM.findDOMNode(this.campoClave).children[1].focus()
            return;
        }

     
        //si no hay errores, intentamos realizar el login.
  
        client
        .mutate({
        mutation: gql`
                mutation{loginUsuariosLogin(USUARIO:"${this.state.usuario}",CLAVE:"${this.state.clave}")}
        `
        })
        .then(result => {
            if(result.data.loginUsuariosLogin!=="false"){
                localStorage.setItem('token',result.data.loginUsuariosLogin);
                this.props.setLogueado(result.data.loginUsuariosLogin);                
            }else{
                this.setState({errorLogin:true})
            }
        });
        

    };

}

export default DialogExample;
