import React, { Component } from "react";
import gql from "graphql-tag";
import moment from "moment";
import { Card, Elevation, Icon, Switch } from "@blueprintjs/core";
import { Cargando } from "./Cargando";
import ReactDOM from 'react-dom';
import { withApollo } from "react-apollo";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

class CuadroMando extends Component {
  state = {
    todosCuadros:[],
    cuadros: [],
    tamanoIconos: 30,
    filtroActasyConvocatorias: true,
    filtroCapitalSocial: true,
    filtroInfoEmpresa: true,
    filtroPeriodicidadReuniones: true,
    fitlroOtrosAvisos: true,
    filtroAccion: false,
    primerCarga:true,
    pasoGuia:0,
        steps: [
            {
                target: '#contenedor-cuadro-mandos',
                content: "Esta pantalla le muestra una serie de avisos referentes a diversas actividades o elementos de la empresa, según un código de colores (rojo para avisos muy graves o importantes, naranjas para avisos graves o importantes, amarillos para avisos leves o verde para informaciones de elementos correctos).",
                selector: '#contenedor-cuadro-mandos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:1, 
            },  
            {
                target: '#filtros-cuadro-mando',
                content: <div>Aquí podemos filtrar los tipos de avisos que que queremos ver activando o desactivando las diferentes opciones.Si en las tarjetas hacemos clic sobre el icono <Icon style={{color:"black"}} icon="info-sign"/> nos llevará a la pantalla que necesita ser revisada y si hacemos clic sobre el icono <Icon style={{color:"black"}} icon="confirm"/> se ocultará la tarjeta.</div>,
                selector: '#filtros-cuadro-mando',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:1, 
            },  
        ],
    cargandoCM:false,    
  };

  cargarCuadros = async () => {
    let valores = [];

    /*//reuniones sin actas
    let reuniones = await this.props.client
      .query({
        query: gql`
          {
            convocatoriasSinActas
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.convocatoriasSinActas;
      });

    //comporbar que la reunion tenga acta
    if (JSON.parse(reuniones)[0]["convocatorias"] > 0) {
      for (let i = 0; i < JSON.parse(reuniones)[0]["convocatorias"]; i++) {
        valores.push({
          color: "orange",
          link: "reuniones",
          tipo:"actas y convocatorias",
          texto:
            "Se ha celebrado una reunión cuya acta no se ha dado de alta en la aplicación"
        });
      }
    }*/

    //actas sin documentos
    let actasSinDoc = await this.props.client
      .query({
        query: gql`
          {
            actasSinPdf
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.actasSinPdf;
      });

    if (JSON.parse(actasSinDoc)[0]["numActas"] > 0) {
      for (let i = 0; i < JSON.parse(actasSinDoc)[0]["numActas"]; i++) {
        valores.push({
          color: "orange",
          link: "actas",
          tipo:"actas y convocatorias",
          texto:
            "Se ha guardado un acta a la cuál no se ha adjuntado el documento del acta escaneado"
        });
      }
    }
    
    //capital social
    let titulosCapital = await this.props.client
      .query({
        query: gql`
          {
            capSocialInicialDistribucion(LIMIT: 1) {
              ID_DISTRIBUCION
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.capSocialInicialDistribucion;
      });
    if (titulosCapital.length === 0) {
      valores.push({
        color: "red",
        link: "estructura-societaria",
        tipo:"capital social",
        texto: "No existe información del capital social"
      });
    } else {
      valores.push({
        color: "green",
        tipo:"capital social",
        texto:
          "El 100% de títulos del Capital Social están asignados a socios"
      });
    }
    

    //documento historico
    let documentoHistorico = await this.props.client
      .query({
        query: gql`
          {
            documentosHistoricos(LIMIT: 1) {
              ID_ITEM
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.documentosHistoricos;
      });
    if (documentoHistorico.length === 0) {
      valores.push({
        color: "orange",
        link: "documentos-compliance",
        tipo:"empresa",
        texto:
          "No se ha introducido el documento histórico de variacion del accionariado"
      });
    } else {
        valores.push({
          color: "green",
          tipo:"empresa",
          texto:
            "El documento histórico de variacion del accionariado está introducido correctamente"
        });
    }

    //evaluaciones empleado
    let empleados = await this.props.client
      .query({
        query: gql`
          {
            obtenerNumerEmpleados
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.obtenerNumerEmpleados;
      });
    let totalEmpleados = JSON.parse(empleados)[0]["numeEmps"];

    if (totalEmpleados === 0) {
      valores.push({
        color: "orange",
        link: "recursos-humanos",
        tipo:"empresa",
        texto: "No se ha introducido ninguna información sobre empleados"
      });
    }

    if (totalEmpleados > 0) {
      let empleadosSinValorar = await this.props.client
        .query({
          query: gql`
            {
              empleadosSinValorar
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.empleadosSinValorar;
        });
      let nEmpleados = JSON.parse(empleadosSinValorar)[0].numeEmps;
      //si hay algun empleado sin valorar.
      if (nEmpleados === 0) {
        //calculamos el porcentaje.
        valores.push({
          color: "orange",
          link: "evaluaciones-empleado",
          tipo:"empresa",
          texto:
            "No se ha introducido ninguna evaluación  de empleados del año en curso"
        });
      } else if (nEmpleados > 0) {
        if (nEmpleados === totalEmpleados) {
          valores.push({
            color: "green",
            tipo:"empresa",
            texto:
              "Se han introducido todas las evaluaciones de empleados del año en curso"
          });
        } else {
          let porcentaje = (nEmpleados / totalEmpleados) * 100;
          valores.push({
            color: "yellow",
            link: "evaluaciones-empleado",
            tipo:"empresa",
            texto:
              "Se han introducido un %" +
              Math.round(porcentaje) +
              " de evaluaciones de empleado del año en curso"
          });
        }
      }
    }

    //medios contacto
    let medios = await this.props.client
      .query({
        query: gql`
          {
            personasMediosContacto(LIMIT: 2, FILTROS: { ID_PERSONA: 1 }) {
              ID_PERSONA
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.personasMediosContacto;
      });
    if (medios.length === 0) {
      valores.push({
        color: "red",
        link: "empresa",
        tipo:"empresa",
        texto: "No se ha introducido ningún medio de contacto"
      });
    } else if (medios.length === 1) {
      valores.push({
        color: "orange",
        link: "empresa",
        tipo:"empresa",
        texto: "Solo se ha introducido un medio de contacto de la empresa"
      });
    } else if (medios.length === 2) {
      valores.push({
        color: "green",
        tipo:"empresa",
        texto:
          "Se han introducido almenos dos medios de contacto de la empresa"
      });
    }

    //centros de trabajo
    let centros = await this.props.client
      .query({
        query: gql`
          {
            centrosTrabajos(LIMIT: 1, FILTROS: { ID_PERSONA: 1 }) {
              ID_CENTRO_TRABAJO
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.centrosTrabajos;
      });
    if (centros.length === 0) {
      valores.push({
        color: "orange",
        link: "empresa",
        tipo:"empresa",
        texto: "No se ha introducido ningún centro de trabajo de la empresa"
      });
    } else if (centros.length === 1) {
      valores.push({
        color: "green",
        tipo:"empresa",
        texto:
          "Se ha introducido almenos un centro de trabajo para la empresa"
      });
    }

    //cif
    let cif = await this.props.client
      .query({
        query: gql`
          {
            documentosIdentificativos(
              LIMIT: 1
              FILTROS: { ID_TIPO_DOCUMENTO: 4, ID_PERSONA: 1 }
            ) {
              ID_DOCUMENTO_IDENTIFICATIVO
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.documentosIdentificativos;
      });
    if (cif.length === 0) {
      valores.push({
        color: "orange",
        link: "empresa",
        tipo:"empresa",
        texto: "No se ha introducido el CIF de la empresa"
      });
    } else if (cif.length === 1) {
      valores.push({
        color: "green",
        tipo:"empresa",
        texto: "Se ha introducido el CIF la empresa"
      });
    }

    //cuentas cotización
    let cuentas = await this.props.client
      .query({
        query: gql`
          {
            personasJuridicasCcc(LIMIT: 1, FILTROS: { ID_PERSONA: 1 }) {
              ID_CCC
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.personasJuridicasCcc;
      });

    if (cuentas.length === 0) {
      valores.push({
        color: "orange",
        link: "empresa",
        tipo:"empresa",
        texto:
          "No se ha introducido ningún código de cuenta de cotización de la empresa"
      });
    } else if (cuentas.length === 1) {
      valores.push({
        color: "green",
        tipo:"empresa",
        texto:
          "Se ha introducido almenos un código de cuenta de cotización para la empresa"
      });
    }

    //sociedades vínculadas
    let sociedades = await this.props.client
      .query({
        query: gql`
          {
            sociedadesVinculadas(LIMIT: 1) {
              ID_SOCIEDAD_VINCULADA
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.sociedadesVinculadas;
      });
    if (sociedades.length === 0) {
      valores.push({
        color: "yellow",
        link: "empresa",
        tipo:"empresa",
        texto: "No se ha introducido ningún vínculo de la empresa"
      });
    } else if (sociedades.length === 1) {
      valores.push({
        color: "green",
        tipo:"empresa",
        texto: "Se ha introducido almenos un vínculo para la empresa"
      });
    }

  //adjuntos
  let adjuntosOtros = await this.props.client
    .query({
      query: gql`
        {
          adjuntos(
            LIMIT: 1
            FILTROS: { ID_ASOCIADO: 1, ID_TIPO_ADJUNTO: 3 }
          ) {
            ID_ADJUNTO
          }
        }
      `,fetchPolicy:'network-only'
    })
    .then(result => {
      return result.data.adjuntos;
    });

    let adjuntosEstatutos = await this.props.client
      .query({
        query: gql`
          {
            adjuntos(
              LIMIT: 1
              FILTROS: { ID_ASOCIADO: 1, ID_TIPO_ADJUNTO: 4 }
            ) {
              ID_ADJUNTO
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.adjuntos;
      });

    if (adjuntosOtros.length === 0 && adjuntosEstatutos.length === 0) {
      valores.push({
        color: "orange",
        link: "empresa",
        tipo:"empresa",
        texto: "No se ha introducido ningún archivo adjunto de la empresa"
      });
    } else if (
      adjuntosOtros.length === 1 ||
      (adjuntosEstatutos.length === 1)
    ) {
      valores.push({
        color: "green",
        tipo:"empresa",
        texto: "Se ha introducido almenos un archivo adjunto para la empresa"
      });
    }
    


    //reuniones organo
    let fInicio = moment()
      .subtract(3, "month")
      .format("YYYY-MM-DD");
    let fFin = moment().format("YYYY-MM-DD");

    let reuniones = await this.props.client
      .query({
        query: gql`
              {convocatorias(LIMIT:1,FILTROS:{ID_TIPO_CONVOCATORIA:3,FECHA:{DESDE:"${fInicio}",HASTA:"${fFin}"}}){ID_CONVOCATORIA}}`,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.convocatorias;
      });

    if (reuniones.length === 0) {
      valores.push({
        color: "orange",
        link: "reuniones",
        tipo:"reuniones",
        texto:
          "No se ha celebrado una reunión del órgano de administración desde hace 3 meses o mas"
    });
      

    //reuniones junta
    let thisYear = new Date().getFullYear();
    let start = new Date("1/1/" + thisYear);
    fInicio = moment(start.valueOf()).format("YYYY-MM-DD");
    fFin = moment(start.valueOf())
      .add(6, "month")
      .format("YYYY-MM-DD");

    reuniones = await this.props.client
      .query({
        query: gql`
              {convocatorias(LIMIT:1,FILTROS:{ID_TIPO_CONVOCATORIA:1,FECHA:{DESDE:"${fInicio}",HASTA:"${fFin}"}}){ID_CONVOCATORIA}}`,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.convocatorias;
      });

    if (reuniones.length === 0) {
      valores.push({
        color: "orange",
        link: "reuniones",
        tipo:"reuniones",
        texto:
          "No se ha celebrado una reunión de la Junta General Ordinaria en el primer semestre"
      });
    }
  }

    //obligaciones emitidas
    let obligaciones = await this.props.client
      .query({
        query: gql`
          {
            obligacionesEmitidas(LIMIT: 1) {
              ID_OBLIGACION
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.obligacionesEmitidas;
      });

    if (obligaciones.length === 0) {
      valores.push({
        color: "yellow",
        link: "obligaciones-emitidas",
        tipo:"otros avisos",
        texto:
          "No se ha introducido ninguna obligación de obligaciones emitidas"
      });
    } else {
      valores.push({
        color: "green",
        tipo:"otros avisos",
        texto: "Se ha introducido información de obligaciones emitidas"
      });
    }

    //cuentas anuales
    let cuentasAnuales = await this.props.client
      .query({
        query: gql`
          {
            cuentasAnuales(LIMIT: 1) {
              ID_CUENTA_ANUAL
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.cuentasAnuales;
      });

    if (cuentasAnuales.length === 0) {
      valores.push({
        color: "yellow",
        link: "cuentas-anuales",
        tipo:"otros avisos",
        texto:
          "No se ha introducido ninguna obligación de presentación de cuentas anuales en el Registro Mercantil"
      });
    } else {
      valores.push({
        color: "green",
        tipo:"otros avisos",
        texto:
          "Se ha introducido información de presentación de cuentas anuales en el registro mercantil"
      });
    }

    //organo administracion
    let miembrosAdmin = await this.props.client
      .query({
        query: gql`
          {
            organosAdministracionMiembros(LIMIT: 1) {
              ID_PERSONA
            }
          }
        `,fetchPolicy:'network-only'
      })
      .then(result => {
        return result.data.organosAdministracionMiembros;
      });

    if (miembrosAdmin.length === 0) {
      valores.push({
        color: "yellow",
        link: "organo-administracion",
        tipo:"otros avisos",
        texto:
          "No se ha introducido ningún mimembro del órgano de administración"
      });
    } else {
      valores.push({
        color: "green",
        tipo:"otros avisos",
        texto:
          "Se ha introducido información de miembros del órgano de administración"
      });
    }

      //organo compliance
      let miembros = await this.props.client
        .query({
          query: gql`
            {
              miembrosOrganizacion(LIMIT: 1) {
                ID_PERSONA
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.miembrosOrganizacion;
        });

      if (miembros.length === 0) {
        valores.push({
          color: "yellow",
          link: "organo-compliance",
          tipo:"otros avisos",
          texto: "No se ha introducido ningún mimembro del órgano de compliance"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto:
            "Se ha introducido información de miembros del órgano de compliance"
        });
      
      }

      //creditos a socios
      let creditos = await this.props.client
        .query({
          query: gql`
            {
              garantias(LIMIT: 1) {
                ID_GARANTIA
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.garantias;
        });

      if (creditos.length === 0) {
        valores.push({
          color: "yellow",
          link: "garantias",
          tipo:"otros avisos",
          texto:
            "No se ha introducido información de créditos y garantias a socios y administradores"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto:
            "Se ha introducido información de  créditos y garantias a socios y administradores"
        });
      
      }

      //polizas seguro
      let polizas = await this.props.client
        .query({
          query: gql`
            {
              polizasSeguro(LIMIT: 1) {
                ID_POLIZA
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.polizasSeguro;
        });

      if (polizas.length === 0) {
        valores.push({
          color: "yellow",
          link: "polizas-seguro",
          tipo:"otros avisos",
          texto: "No se ha introducido información de pólizas de seguro"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de pólizas de seguro"
        });
      }

      //modificaciones estatuarias
      let modifiaciones = await this.props.client
        .query({
          query: gql`
            {
              modificacionesEstatutos(LIMIT: 1) {
                ID_MODIFICACION_ESTATUTOS
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.modificacionesEstatutos;
        });

      if (modifiaciones.length === 0) {
        valores.push({
          color: "yellow",
          link: "modificaciones-estatutos",
          tipo:"otros avisos",
          texto:
            "No se ha introducido información de modificaciones estatuarias"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de modificaciones estatuarias"
        });
      }

      //puestos trabajo
      let puestos = await this.props.client
        .query({
          query: gql`
            {
              puestosTrabajo(LIMIT: 1) {
                ID_PUESTO
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.puestosTrabajo;
        });

      if (puestos.length === 0) {
        valores.push({
          color: "yellow",
          link: "recursos-humanos",
          tipo:"otros avisos",
          texto: "No se ha introducido información de puestos de trabajo"
        });
      } else {
          valores.push({
            color: "green",
            tipo:"otros avisos",
            texto: "Se ha introducido información de puestos de trabajo"
          });
      }

      //personal alta
      let personal = await this.props.client
        .query({
          query: gql`
            {
              personalAltaDireccion(LIMIT: 1) {
                ID_PERSONAL_ALTA_DIRECCION
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.personalAltaDireccion;
        });

      if (personal.length === 0) {
        valores.push({
          color: "yellow",
          link: "personal-alta-direccion",
          tipo:"otros avisos",
          texto:
            "No se ha introducido información del personal de alta dirección"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto:
            "Se ha introducido información del personal de alta dirección"
        });
      }

      //apoderados
      let apoderados = await this.props.client
        .query({
          query: gql`
            {
              apoderados(LIMIT: 1) {
                ID_APODERADO
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.apoderados;
        });

      if (apoderados.length === 0) {
        valores.push({
          color: "yellow",
          link: "apoderados",
          tipo:"otros avisos",
          texto: "No se ha introducido información de apoderados"
        });
      } else {
        valores.push({
            color: "green",
            tipo:"otros avisos",
            texto: "Se ha introducido información de apoderados"
        });
      }

      //consejeros delegados
      let consejeros = await this.props.client
        .query({
          query: gql`
            {
              consejerosDelegados(LIMIT: 1) {
                ID_CONSEJERO_DELEGADO
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.consejerosDelegados;
        });

      if (consejeros.length === 0) {
        valores.push({
          color: "yellow",
          link: "consejeros-delegados",
          tipo:"otros avisos",
          texto: "No se ha introducido información de consejeros delegados"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de consejeros delegados"
        }); 
      }

      //proveedores
      let proveedores = await this.props.client
        .query({
          query: gql`
            {
              proveedores(LIMIT: 1) {
                ID_PROVEEDOR
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.proveedores;
        });

      if (proveedores.length === 0) {
        valores.push({
          color: "yellow",
          link: "proveedores",
          tipo:"otros avisos",
          texto: "No se ha introducido información de proveedores"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de proveedores"
        });
      }

      //acreedores
      let acreedores = await this.props.client
        .query({
          query: gql`
            {
              acreedores(LIMIT: 1) {
                ID_ACREEDOR
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.acreedores;
        });

      if (acreedores.length === 0) {
        valores.push({
          color: "yellow",
          link: "acreedores-diversos",
          tipo:"otros avisos",
          texto: "No se ha introducido información de acreedores"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de acreedores"
        });
      }

      //clientes
      let clientes = await this.props.client
        .query({
          query: gql`
            {
              clientes(LIMIT: 1) {
                ID_CLIENTE
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.clientes;
        });

      if (clientes.length === 0) {
        valores.push({
          color: "yellow",
          link: "clientes",
          tipo:"otros avisos",
          texto: "No se ha introducido información de clientes"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de clientes"
        });
      }

      //servicios independientes empresariales
      let serviciosIndependientes = await this.props.client
        .query({
          query: gql`
            {
              serviciosIndependientes(LIMIT: 1, FILTROS: { TIPO: "E" }) {
                ID_SERVICIO_INDEPENDIENTE
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.serviciosIndependientes;
        });

      if (serviciosIndependientes.length === 0) {
        valores.push({
          color: "yellow",
          link: "servicios-independientes",
          tipo:"otros avisos",
          texto:
            "No se ha introducido información de servicios independientes empresariales"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto:
            "Se ha introducido información de servicios independientes empresariales"
        }); 
      }

      //servicios independientes profesionales
      let serviciosIndependientesP = await this.props.client
        .query({
          query: gql`
            {
              serviciosIndependientes(LIMIT: 1, FILTROS: { TIPO: "P" }) {
                ID_SERVICIO_INDEPENDIENTE
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.serviciosIndependientes;
        });

      if (serviciosIndependientesP.length === 0) {
        valores.push({
          color: "yellow",
          link: "servicios-independientes",
          tipo:"otros avisos",
          texto:
            "No se ha introducido información de servicios independientes profesionales"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto:
            "Se ha introducido información de servicios independientes profesionales"
        });
      }

      //asesorias externas
      let asesoriasExternas = await this.props.client
        .query({
          query: gql`
            {
              asesoriasExternas(LIMIT: 1) {
                ID_ASESORIA_EXTERNA
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.asesoriasExternas;
        });

      if (asesoriasExternas.length === 0) {
        valores.push({
          color: "yellow",
          link: "asesorias-externas",
          tipo:"otros avisos",
          texto: "No se ha introducido información de asesorias externas"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de asesorias externas"
        });
      }

      //auditores
      let auditores = await this.props.client
        .query({
          query: gql`
            {
              auditores(LIMIT: 1) {
                ID_AUDITOR
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.auditores;
        });

      if (auditores.length === 0) {
        valores.push({
          color: "yellow",
          link: "auditores",
          tipo:"otros avisos",
          texto: "No se ha introducido información de auditores"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de auditores"
        });
      }

      //fondos inversion
      let fondosInversiones = await this.props.client
        .query({
          query: gql`
            {
              fondosInversiones(LIMIT: 1) {
                ID_FONDO_INVERSION
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.fondosInversiones;
        });

      if (fondosInversiones.length === 0) {
        valores.push({
          color: "yellow",
          link: "fondos-inversion",
          tipo:"otros avisos",
          texto: "No se ha introducido información de fondos inversion"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de fondos inversion"
        });
      }

      //socios gestores
      let sociosGestores = await this.props.client
        .query({
          query: gql`
            {
              sociosGestores(LIMIT: 1) {
                ID_SOCIO_GESTOR
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.sociosGestores;
        });

      if (sociosGestores.length === 0) {
        valores.push({
          color: "yellow",
          link: "socios-gestores",
          tipo:"otros avisos",
          texto: "No se ha introducido información de socios gestores"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de socios gestores"
        });
      }

      //joint ventures
      let jointVentures = await this.props.client
        .query({
          query: gql`
            {
              jointVentures(LIMIT: 1) {
                ID_JOINT_VENTURE
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.jointVentures;
        });

      if (jointVentures.length === 0) {
        valores.push({
          color: "yellow",
          link: "joint-ventures",
          tipo:"otros avisos",
          texto: "No se ha introducido información de joint ventures"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de joint ventures"
        });
      
      }

      //private equity
      let privatesEquity = await this.props.client
        .query({
          query: gql`
            {
              privatesEquity(LIMIT: 1) {
                ID_PRIVATE_EQUITY
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.privatesEquity;
        });

      if (privatesEquity.length === 0) {
        valores.push({
          color: "yellow",
          link: "private-equity",
          tipo:"otros avisos",
          texto: "No se ha introducido información de private equity"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de private equity"
        });
      }

      //comisiones
      let comisiones = await this.props.client
        .query({
          query: gql`
            {
              comisiones(LIMIT: 1) {
                ID_COMISION
              }
            }
          `,fetchPolicy:'network-only'
        })
        .then(result => {
          return result.data.comisiones;
        });

      if (comisiones.length === 0) {
        valores.push({
          color: "yellow",
          link: "comisiones",
          tipo:"otros avisos",
          texto: "No se ha introducido información de comisiones"
        });
      } else {
        valores.push({
          color: "green",
          tipo:"otros avisos",
          texto: "Se ha introducido información de comisiones"
        });
    }
    this.setState({ todosCuadros: valores });
    this.setState({ cuadros: valores });
    this.setState({primerCarga:false})

  }
  componentDidMount() {
    this.setState({cargandoCM:true}, async () =>{
      await this.cargarCuadros();
      this.setState({cargandoCM:false})

    })
  }

  comprobarFiltros(){

    let cuadros=this.state.todosCuadros
    if(!this.state.filtroActasyConvocatorias){
      cuadros=cuadros.filter(cuadro=>{return cuadro.tipo!=="actas y convocatorias"})
    }
    if(!this.state.filtroInfoEmpresa){
      cuadros=cuadros.filter(cuadro=>{return cuadro.tipo!=="empresa"})
    }
    if(!this.state.filtroCapitalSocial){
      cuadros=cuadros.filter(cuadro=>{return cuadro.tipo!=="capital social"})
    }
    if(!this.state.filtroPeriodicidadReuniones){
      cuadros=cuadros.filter(cuadro=>{return cuadro.tipo!=="reuniones"})
    }
    if(!this.state.filtroInfoEmpresa){
      cuadros=cuadros.filter(cuadro=>{return cuadro.tipo!=="empresa"})
    }
    if(!this.state.fitlroOtrosAvisos){
      cuadros=cuadros.filter(cuadro=>{return cuadro.tipo!=="otros avisos"})
    }
    if(this.state.filtroAccion){
      cuadros=cuadros.filter(cuadro=>{return cuadro.color!=="green"})
      console.log(cuadros)
    }

    this.setState({cuadros:cuadros})

  }
  componentDidUpdate(props,state){
    if(this.state!==state){
        //actas y convocatorias
        if(state.filtroActasyConvocatorias!==this.state.filtroActasyConvocatorias){
            this.comprobarFiltros()
        }
        //empresas
        if(state.filtroInfoEmpresa!==this.state.filtroInfoEmpresa){
            this.comprobarFiltros()
        }
        //capital social
        if(state.filtroCapitalSocial!==this.state.filtroCapitalSocial){
          this.comprobarFiltros()
        }
        //reuniones
        if(state.filtroPeriodicidadReuniones!==this.state.filtroPeriodicidadReuniones){
          this.comprobarFiltros()
        }
        //empresas
        if(state.filtroInfoEmpresa!==this.state.filtroInfoEmpresa){
          this.comprobarFiltros()
        }
        //otros avisos
        if(state.fitlroOtrosAvisos!==this.state.fitlroOtrosAvisos){
          this.comprobarFiltros()
        }
        //requieren accion
        if(state.filtroAccion!==this.state.filtroAccion){
          this.comprobarFiltros()
        }

      }
  }

  render() {

    const {steps} = this.state;

    document.title ="LexControl | Cuadro mando Gobierno Corporativo"

    const handleActasConvocatorias = () => {
      this.setState({
        filtroActasyConvocatorias: !this.state.filtroActasyConvocatorias
      });
    };

    const handleCapitalSocial = () => {
      this.setState({
        filtroCapitalSocial: !this.state.filtroCapitalSocial
      });
    };

    const handleInfoEmpresa = () => {
      this.setState({
        filtroInfoEmpresa: !this.state.filtroInfoEmpresa
      });
    };

    const handlePeriodicidadReuniones = () => {
      this.setState({
        filtroPeriodicidadReuniones: !this.state.filtroPeriodicidadReuniones
      });
    };

    const handleOtrosAvisos = () => {
      this.setState({
        fitlroOtrosAvisos: !this.state.fitlroOtrosAvisos
      });
    };

    const handleAccion = () => {
      this.setState({
        filtroAccion: !this.state.filtroAccion
      });
    };

    const accionTarjeta=(ev)=>{
      if(ev.currentTarget.className.includes("bp3-icon-confirm")){
        //Si es el icono de check eliminamos el elemento del array.
        let id=ReactDOM.findDOMNode(ev.currentTarget).parentNode.dataset.id
        let cuadros=this.state.cuadros
        let i=""
        cuadros.find((cuadro,index)=>{
          if(parseInt(cuadro.id)===parseInt(id)){
            i=index
          }
          return parseInt(cuadro.id)===parseInt(id)

        })
        cuadros.splice(i, 1)
        this.setState({cuadros:cuadros})
      }else{
        
        let id=ReactDOM.findDOMNode(ev.currentTarget).parentNode.dataset.id
        let cuadros=this.state.cuadros
        let cuadroSel=cuadros.find((cuadro)=>{
          return parseInt(cuadro.id)===parseInt(id)
        })

        window.location=cuadroSel.link

      }
    }

    const handleJoyrideCallback=async(data)=>{
        

      const { action, index, status, type } = data;

      if(data.action==="close"){

        localStorage.setItem("ayuda",false)
        this.setState({pasoGuia:0})

      }else{

        if ([EVENTS.STEP_AFTER].includes(type)) {

          let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
  
          //paso en el que nos encontremos.
          // eslint-disable-next-line default-case
          switch(paso){
            case 0:
                this.setState({siniestroSeleccionado:null})
            break;
            case 1:
                 this.setState({modalAbierto:false})
            break;
            case 2:
                 this.setState({modalAbierto:true,pestaña:"datos-siniestro"})
            break;
            case 3:
                 this.setState({modalAbierto:true,pestaña:"instrucciones-siniestro"})
            break;
            case 4:
                await this.tabInstruccionSiniestro.setState({comenzada:false})
            break;
            case 5:
                await this.tabInstruccionSiniestro.setState({comenzada:true})
                this.setState({pestaña:"instrucciones-siniestro"})
            break;
            case 6:
                this.setState({pestaña:"diligencias-siniestro"})
            break;
            case 7:
                await this.tabDiligenciasSiniestros.setState({modalAbierto:false})
            break;
            case 8:
                await this.tabDiligenciasSiniestros.setState({modalAbierto:true})
               this.setState({pestaña:"diligencias-siniestro"})
            break;
            case 9:
                await this.tabDiligenciasSiniestros.setState({modalAbierto:false})
                await this.tabResolucion.setState({resuelta:false})
               this.setState({pestaña:"resolucion"})
            break;
            case 10:
                await this.tabResolucion.setState({resuelta:true})
            break;
          }
  
          this.setState({ pasoGuia: paso });         
  
        }
  
        //si termina la guia.
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem("ayuda",false)
            await this.setState({modalAbierto:false})
  
        }

      }

    

    } 

    while (this.state.cuadros.length === 0 && this.state.primerCarga) return <Cargando />;
    return (
      <>
      {this.state.cargandoCM===true?
        <Cargando/>
      :
      <>
        <Joyride
            run={localStorage.getItem("ayuda")==="true"?true:false}
            callback={handleJoyrideCallback}
            showProgress={true}
            stepIndex={this.state.pasoGuia}
            steps={steps}
            disableScrollParentFix={true}
            continuous ={true}
            locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
            styles={{
                options: {
                    arrowColor: '#e3ffeb',
                    backgroundColor: '#e3ffeb',
                    primaryColor: '#E31E24',
                }
            }}
        />
        <div  id="contenedor-cuadro-mandos">
          <div className="pixelAlrededor">
            <Card  id="filtros-cuadro-mando" interactive={true} elevation={Elevation.TWO}>
              <div>
                <Switch
                  className="tablaMitadIzquierda"
                  label="Mostrar avisos relacionados con actas y convocatorias"
                  checked={this.state.filtroActasyConvocatorias}
                  onChange={handleActasConvocatorias}
                />
                <Switch
                  className="tablaMitadDerecha"
                  label="Mostrar avisos relacionados el capital social"
                  checked={this.state.filtroCapitalSocial}
                  onChange={handleCapitalSocial}
                />
              </div>
              <div>
                <Switch
                  className="tablaMitadIzquierda"
                  label="Mostrar avisos relacionados con información de la empresa"
                  checked={this.state.filtroInfoEmpresa}
                  onChange={handleInfoEmpresa}
                />
                <Switch
                  className="tablaMitadDerecha"
                  label="Mostrar avisos relacionados con periodicidad de reuniones"
                  checked={this.state.filtroPeriodicidadReuniones}
                  onChange={handlePeriodicidadReuniones}
                />
              </div>
              <div>
                <Switch
                  className="tablaMitadIzquierda"
                  label="Mostrar otros avisos"
                  checked={this.state.fitlroOtrosAvisos}
                  onChange={handleOtrosAvisos}
                />
                <Switch
                  className="tablaMitadDerecha"
                  label="Mostrar sólo avisos que requieran acciones"
                  checked={this.state.filtroAccion}
                  onChange={handleAccion}
                />
              </div>
              <br />
              <br />
              <br />
              <br />
            </Card>
          </div>
          <ul className="pixelAlrededor">
            {this.state.cuadros.map((cuadro,index) => {
              cuadro.id=index
              return (
                <li className="TarjetasCuadroMandoUnaPaCaLao" id={index} >
                    <Card
                      
                      interactive={true}
                      className={"CardCuadromando" + cuadro.color}
                      elevation={Elevation.TWO}
                      data-id={index}
                    >
                      <p>{cuadro.texto}</p>
                      <Icon
                        iconSize={this.state.tamanoIconos}
                        icon={cuadro.color === "green" ? "confirm" : "info-sign"}
                        onClick={accionTarjeta}
                        className="iconosCuadroMando"
                      />
                    </Card>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    }
    </>
    );
  }
}
export default withApollo(CuadroMando)