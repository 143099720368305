import React from "react"
import { Card,InputGroup, Elevation, Dialog, Checkbox,FormGroup,TextArea, Tab, Alignment, Button, Tooltip, Classes, AnchorButton, Intent, Navbar, ButtonGroup, NavbarDivider, Position, Callout, Slider, Tabs, Spinner } from "@blueprintjs/core"
import Select from "react-select"
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import gql from "graphql-tag";
import chroma from "chroma-js"
import Evaluacion from "../Pantallas/Evaluacion"
import EmpleadosFechas from "./Elementos/EmpleadosFechas"
import EmpleadosInformacionContractual from "./Elementos/EmpleadosInformacionContractual"
import EmpleadosRetribucion from "./Elementos/EmpleadosRetribucion"
import EmpleadosFormacion from "./Elementos/EmpleadosFormacion"
import EmpleadosExperiencia from "./Elementos/EmpleadosExperiencia"
import EmpleadosIdiomas from "./Elementos/EmpleadosIdiomas"
import EmpleadosIRPF from "./Elementos/EmpleadosIRPF"
import EmpleadosDatosMedicos from "./Elementos/EmpleadosDatosMedicos"
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import Epis from "./Elementos/Epis"
import { withApollo } from "react-apollo";
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import { CSVLink } from "react-csv";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import ReactDOM from 'react-dom'
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "../Pantallas/Cargando"
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from '@react-pdf/renderer';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import const_table from './BootstrapTable';
import i18next from 'i18next';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });
// Create styles
const styles = StyleSheet.create({
  dialog:{
    width: '65%'    
  },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHeadGral: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:5
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  labelA: {
    flexDirection: 'row',
    width: '40%',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  labelD: {
    flexDirection: 'row',
    width: '60%'
  },
  labelDataA: {
    flexDirection: 'column',
    width: '30%',
    fontSize:10,
    fontFamily: 'Open Sans Bold'
  },
  valueDataA: {
    flexDirection: 'column',
    width: '70%',
    fontSize:10,
    fontFamily: 'Open Sans'
  },
  labelDataD: {
    flexDirection: 'column',
    width: '35%',
    fontSize:10,
    fontFamily: 'Open Sans Bold'
  },
  valueDataD: {
    flexDirection: 'column',
    width: '65%',
    fontSize:10,
    fontFamily: 'Open Sans'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
});

const reuniones = [
    {
        nombre: "Rafael",
        puesto: "Soldador",
        fecha: "23/12/2016"
    },
    {
        nombre: "Gabriel",
        puesto: "Aguador",
        fecha: "23/12/2016"
    }
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : reuniones.filter(empl =>
    empl.nombre.toLowerCase().slice(0, inputLength) === inputValue
  );
};

class RecursosHumanos extends React.Component {

    state = {
        cargandoEmpleados:false,
        cargandoPuestos:false,
        cargandoCompetencia:false,
        modalImprimirT:false,
        modalImprimirPI:false,
        modalImprimirPG:false,
        modalImprimirC:false,
        cargandoImprimir:false,
        datosEmpresa:null,
        logotipo:null,
        value: '',
        suggestions: [],
        empleados: null,
        competencias: [
        ],
        puestosTablaGral: [
        
        ],
        modalPersonaAbierto:null,
        nombre:null,
        primerApellido:null,
        segundoApellido:null,
        idiomaSeleccionado:null,
        fechaNacimiento:null,
        idiomasSelect:[],
        bolFechaNacimiento:false,
        personas:[],
        empleadoSeleccionado:null,
        nombreSeleccionado:"",
        nuevaEmpleadoActivado: true,
        editarEmpleadoActivado: false,
        borrarEmpleadoActivado: false,
        modalPuestoDetalles:false,
        modalPuesto:false,
        puestoSeleccionado:null,
        nuevoPuestoActivado:true,
        editarPuestoActivado:false,
        borrarPuestoActivado:false,
        imprimirPIActivado:false,
        imprimirPGActivado:false,
        puestos: [
        ],
        puestosSelect:[
    
        ],
        gradoResponsabilidad: 1,
        riesgoOptions: [
        ],
        nombrePuesto:null,
        riesgoSeleccionado:null,
        codigoPuesto:null,
        descripcionBreve:null,
        descripcionDetallada:null,

        fechaAltaPuesto:new Date(),
        bolFechaBajaPuesto:false,
        fechaBajaPuesto:null,
        puestoSeleccionadoSel:null,
        modalPuestoBorrar:false,
        cargando:false,
        modalEvaluacionAbierto:false,
        evaluacionActivado:false,
        modalBorrarAbierto:false,
        modalAdvertenciaBaja:false,
        modalBajaFinal:false,
        indiceRegistros:0,
        totalRegistros:0,
        cargandoCsv:false,
        datosCsv:[],
        drawerAbierto: false,
        datosImpresion:[],
        observaciones:null,
        pasoGuia:0,
        areas: [],
        departamentos: [],
        steps: [
            {
              target: '#contenedor-recursos-humanos',
              content: 'En esta pantalla, añadimos los trabajadores de la empresa.',
              selector: '#contenedor-recursos-humanos',
              position: 'auto',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              stepIndex: 1,
            },
            {
              target: '#crear-empleado',
              content: 'Aqui introducimos los datos personales del trabajador.',
              selector: '#crear-empleado',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 2,
            },
            {
              target: '#seleccionar-crear-empleado',
              content: 'Desde aqui seleccionamos o creamos un empleado.',
              selector: '#seleccionar-crear-empleado',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 3,
            },
            {
              target: '#bp3-tab-title_pestañas_fechas',
              content: 'En esta pestaña, introducimos fechas de alta, baja, etc',
              selector: '#bp3-tab-title_pestañas_fechas',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 3,
            },
            {
              target: '#bp3-tab-title_pestañas_informacion-contractual',
              content: 'En esta pestaña, introducimos el tipo de contrato, parcialidad, retribución, etc. ',
              selector: '#bp3-tab-title_pestañas_informacion-contractual',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 4,
            },
            {
              target: '#bp3-tab-title_pestañas_formacion',
              content: 'Aqui podemos introducir todos los datos relacionados con cursos, habilidades o aptitudes del trabajador.',
              selector: '#bp3-tab-title_pestañas_formacion',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 5,
            },
            {
              target: '#bp3-tab-title_pestañas_epis',
              content: 'Si el trabajador ha recibido EPIs debido a su actividad en la empresa, aqui los podemos registrar.',
              selector: '#bp3-tab-title_pestañas_epis',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 6,
            },
            {
              target: '#bp3-tab-title_pestañas_experiencia',
              content: 'Descripción de la experiencia laboral del trabajador.',
              selector: '#bp3-tab-title_pestañas_experiencia',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 7,
            },
            {
              target: '#bp3-tab-title_pestañas_idiomas',
              content: 'En esta pestaña, introducimos los conocimientos de idiomas y su nivel',
              selector: '#bp3-tab-title_pestañas_idiomas',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 8,
            },
            {
              target: '#bp3-tab-title_pestañas_irpf',
              content: 'Aqui introducimos la situación familiar a efectos del IRPF del trabajador',
              selector: '#bp3-tab-title_pestañas_irpf',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 9,
            },
            {
              target: '#bp3-tab-title_pestañas_datos-medicos',
              content: 'Datos médicos o circunstancias del trabajador.',
              selector: '#bp3-tab-title_pestañas_datos-medicos',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 10,
            },
            {
              target: '#añadir-puesto',
              content: 'Con esta opción, asignamos su puesto a cada trabajador.',
              selector: '#añadir-puesto',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 11,
            },
            {
              target: '#elegir-puesto',
              content: 'Aqui elegimos los puestos de trabajo creados en Catálogo de puestos de trabajo.',
              selector: '#elegir-puesto',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 12,
            },
            {
              target: '#fecha-alta',
              content: 'La fecha en la que el trabajador empezó a desempeñar la tarea.',
              selector: '#fecha-alta',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 13,
            },
            {
              target: '#fecha-baja',
              content: 'Así como la de fin, si se diera el caso.',
              selector: '#fecha-baja',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 14,
            },
            {
              target: '#cuadro-competencias',
              content: 'Al seleccionar un trabajador, aparece aqui un botón para modificar sus competencias.',
              selector: '#cuadro-competencias',
              position: 'auto',
              placementBeacon :'center',
              type: 'hover',
              isFixed: true,
              stepIndex: 15,
            },
            {
                target: '#listado-competencias',
                content: 'En este listado, elegimos el nivel de competencia del trabajador en las diversas áreas de valoracion de las que disponemos.',
                selector: '#listado-competencias',
                position: 'left',
                placementBeacon :'left',
                type: 'hover',
                isFixed: true,
                stepIndex: 16,
            },
          ],
          competenciasFalsas: [
            {
              "id": 11,
              "nombre": "Adaptabilidad, flexibilidad",
              "value": 3
            },
            {
              "id": 30,
              "nombre": "Análisis numérico",
              "value": 2
            },
            {
              "id": 3,
              "nombre": "Aptitud de mando y coordinación de personas",
              "value": 4
            },
            {
              "id": 2,
              "nombre": "Aptitud para motivar a los demás",
              "value": 5
            },
            {
              "id": 1,
              "nombre": "Aptitud para tomar decisiones",
              "value": 1
            },
            {
              "id": 43,
              "nombre": "Asistencia y puntualidad",
              "value": 3
            },
            {
              "id": 42,
              "nombre": "Atención al cliente",
              "value": 2
            },
            {
              "id": 41,
              "nombre": "Auto-motivación",
              "value": 4
            },
            {
              "id": 9,
              "nombre": "Autodominio, equilibrio emocional",
              "value": 5
            },
            {
              "id": 29,
              "nombre": "Capacidad crítica",
              "value": 3
            },
            {
              "id": 18,
              "nombre": "Capacidad de actuar sin referencias anteriores",
              "value": 2
            },
            {
              "id": 6,
              "nombre": "Capacidad de delegación",
              "value": 1
            },
            {
              "id": 5,
              "nombre": "Capacidad de planificación",
              "value": 2
            },
            {
              "id": 10,
              "nombre": "Capacidad de razonamiento, lógica, deducción",
              "value": 3
            },
            {
              "id": 15,
              "nombre": "Capacidad para influir sobre los demás",
              "value": 4
            },
            {
              "id": 19,
              "nombre": "Capaz de asumir cambios constantes e interrupciones",
              "value": 5
            },
            {
              "id": 40,
              "nombre": "Compromiso",
              "value": 4
            },
            {
              "id": 34,
              "nombre": "Comunicación escrita",
              "value": 3
            },
            {
              "id": 33,
              "nombre": "Comunicación oral persuasiva",
              "value": 2
            },
            {
              "id": 16,
              "nombre": "Concentración en trabajos minuciosos",
              "value": 1
            },
            {
              "id": 28,
              "nombre": "Creatividad en soluciones imaginativas",
              "value": 2
            },
            {
              "id": 44,
              "nombre": "Discrección y tacto",
              "value": 3
            },
            {
              "id": 39,
              "nombre": "Energía",
              "value": 5
            },
            {
              "id": 32,
              "nombre": "Escucha",
              "value": 4
            },
            {
              "id": 31,
              "nombre": "Espíritu comercial",
              "value": 3
            },
            {
              "id": 27,
              "nombre": "Flexibilidad",
              "value": 2
            },
            {
              "id": 14,
              "nombre": "Fluidez verbal, capacidad expresiva",
              "value": 1
            },
            {
              "id": 26,
              "nombre": "Habilidad de control",
              "value": 2
            },
            {
              "id": 7,
              "nombre": "Habilidad de negociación",
              "value": 3
            },
            {
              "id": 25,
              "nombre": "Independencia en las propias convicciones",
              "value": 4
            },
            {
              "id": 37,
              "nombre": "Iniciativa",
              "value": 5
            },
            {
              "id": 24,
              "nombre": "Liderazgo",
              "value": 4
            },
            {
              "id": 23,
              "nombre": "Meticulosidad",
              "value": 3
            },
            {
              "id": 8,
              "nombre": "Organización, método precisión",
              "value": 2
            },
            {
              "id": 4,
              "nombre": "Ponderación, objetividad",
              "value": 1
            },
            {
              "id": 17,
              "nombre": "Rapidez y eficacia en trabajos repetitivos",
              "value": 2
            },
            {
              "id": 38,
              "nombre": "Resistencia",
              "value": 3
            },
            {
              "id": 13,
              "nombre": "Resistencia a la presión",
              "value": 4
            },
            {
              "id": 12,
              "nombre": "Seguridad en sí mismo, confianza",
              "value": 5
            },
            {
              "id": 22,
              "nombre": "Sensibilidad organizacional",
              "value": 4
            },
            {
              "id": 35,
              "nombre": "Sociabilidad",
              "value": 3
            },
            {
              "id": 21,
              "nombre": "Tenacidad",
              "value": 2
            },
            {
              "id": 20,
              "nombre": "Tolerancia al estrés",
              "value": 1
            },
            {
              "id": 36,
              "nombre": "Trabajo en equipo",
              "value": 2
            }
          ]
    };

    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }

    registrosMostrar=10

    setModalEvaluacion=(value)=>{
        this.setState({modalEvaluacionAbierto:value})
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
          suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    recargarPersonas=async ()=>{

        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,FECHA_NACIMIENTO,ID_LENGUA_MATERNA}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 

            let personas=[]
            result.data.personasFisicas.forEach(persona=>{
                personas.push({"value":persona.ID_PERSONA,"label":` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`,"nombre":persona.NOMBRE,"primerApellido":persona.APELLIDO1,"segundoApellido":persona.APELLIDO2,"fechaNacimiento":persona.FECHA_NACIMIENTO?new Date(persona.FECHA_NACIMIENTO):null,"lengua":persona.ID_LENGUA_MATERNA})
            })
            this.setState({personas:personas})

        })

    }

    cargarDatosEmpresa=async()=>{

        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //console.log(result.data.personasJuridicas[0]);
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

        
        
        return datosEmpresa
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            cargandoImprimir:false,
            datosEmpresa:datosEmpresa
        })
    }

    generarCsv =async () => {
      await this.cargarDatosCsv();
      this.Csv.link.click();
    }

    generarInformeC =async () => {
        this.setState({
            modalImprimirC:true,
            cargandoImprimir:true
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeC(), document.getElementById('informe-pdf-c'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeC(), document.getElementById('informe-pdf-c'))
                })
            }
        })
        
    }

    generarInformePI =async () => {
        this.setState({
            modalImprimirPI:true,
            cargandoImprimir:true,
            modalPuestoBorrar: false,
            nuevoPuestoActivado:false,
            editarPuestoActivado:false,
            borrarPuestoActivado:false,
            imprimirPIActivado:true,
            imprimirPGActivado:false
        }, async () => {
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformePI(), document.getElementById('informe-pdf-pi'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformePI(), document.getElementById('informe-pdf-pi'))
                })
            }
        })
        
    }

    cargarPuestosGral=async(empleado)=>{

        let puestosGral = this.state.puestosTablaGral
        let nombre=`${empleado.APELLIDO1} ${empleado.APELLIDO2?empleado.APELLIDO2:""},${empleado.NOMBRE}`              
            
        await this.props.client
        .query({
            query: gql` 
                {empleadosPuestos(FILTROS:{ID_EMPLEADO:${empleado.ID_EMPLEADO}}){ID_EMPLEO,FECHA_ALTA,FECHA_BAJA,PUESTO_TRABAJO{ID_PUESTO,DESCRIPCION,GRADO_RESPONSABILIDAD,ID_RIESGO_OBJETIVO,CODIGO_PUESTO,DESC_CORTA,DESC_LARGA}}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            let puestos=[]
            result.data.empleadosPuestos.forEach(puesto=>{
                puestos.push({
                    "id":puesto.PUESTO_TRABAJO.ID_PUESTO,
                    "idEmpleo":puesto.ID_EMPLEO,
                    "nombre":puesto.PUESTO_TRABAJO.DESCRIPCION,
                    "codigoPuesto":puesto.PUESTO_TRABAJO.CODIGO_PUESTO,
                    fechaInicio:puesto.FECHA_ALTA,
                    fechaFin:puesto.FECHA_BAJA?puesto.FECHA_BAJA:null})
            })
            puestosGral.push({
                "codigoEmpleado":empleado.CODIGO_EMPLEADO,
                "trabajador":nombre,
                "puestos":puestos})
            this.setState({puestosTablaGral:puestosGral})
        })
        
    }

    cargarDatosPuestosGral = async () => {
        if (this.state.datosImpresion.length===0) {
          await this.cargarDatosGral();
        }
        this.state.datosImpresion.map(async (empleado, index)=>{
          await this.cargarPuestosGral(empleado)
        }) 
    }

    generarInformePG =async () => {
        this.setState({
            modalImprimirPG:true,
            cargandoImprimir:true,
            modalPuestoBorrar: false,
            nuevoPuestoActivado:false,
            editarPuestoActivado:false,
            borrarPuestoActivado:false,
            imprimirPIActivado:false,
            imprimirPGActivado:true
        }, async () => {
            await this.cargarDatosPuestosGral();
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformePG(), document.getElementById('informe-pdf-pg'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformePG(), document.getElementById('informe-pdf-pg'))
                })
            }
        })
        
    }

    generarInformeT =async () => {
        this.setState({
            modalImprimirT: true,
            cargandoImprimir:true
        }, async () => {
            await this.cargarDatosGral();
            if (this.state.datosEmpresa==null) {
                await this.cargarDatos();
                ReactDOM.render(this.InformeT(), document.getElementById('informe-pdf-t'))
            } else {
                this.setState({
                    cargandoImprimir:false,
                },()=>{
                    ReactDOM.render(this.InformeT(), document.getElementById('informe-pdf-t'))
                })
            }
        })
        
    }

    InformeC = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>COMPETENCIAS POR EMPLEADO</Text>
                </View>
                {this.state.empleadoSeleccionado?
                            <View style={{marginBottom:10}}>
                                <View style={styles.rowHead}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>CÓDIGO:</Text>
                                        <Text style={styles.valueDataA}>{this.state.empleadoSeleccionado.CODIGO_EMPLEADO}</Text>
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>TRABAJADOR:</Text>
                                        <Text style={styles.valueDataD}>{this.state.empleadoSeleccionado.APELLIDO1} {this.state.empleadoSeleccionado.APELLIDO2?this.state.empleadoSeleccionado.APELLIDO2:""},{this.state.empleadoSeleccionado.NOMBRE}</Text>
                                    </View>
                                </View>
                                
                            </View>
                :<></>}
                {this.state.competencias?this.state.competencias.map((competencia, index)=>{
                    return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'80%'}}>COMPETENCIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans Bold',width:'20%'}}>PUNTOS</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'80%'}}>{competencia.nombre}</Text>
                                    <Text style={{textAlign:'right',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans',width:'20%'}}>{competencia.value}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    InformePI = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PUESTOS DE TRABAJO OCUPADOS POR TRABAJADOR</Text>
                </View>
                {this.state.empleadoSeleccionado?
                            <View>
                                <View style={styles.rowHead}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>CÓDIGO:</Text>
                                        <Text style={styles.valueDataA}>{this.state.empleadoSeleccionado.CODIGO_EMPLEADO}</Text>
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>TRABAJADOR:</Text>
                                        <Text style={styles.valueDataD}>{this.state.empleadoSeleccionado.APELLIDO1} {this.state.empleadoSeleccionado.APELLIDO2?this.state.empleadoSeleccionado.APELLIDO2:""},{this.state.empleadoSeleccionado.NOMBRE}</Text>
                                    </View>
                                </View>
                                
                            </View>
                :<></>}
                {this.state.puestos?this.state.puestos.map((puesto, index)=>{
                  return (
                            <View>
                              {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>CÓDIGO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>TRABAJADOR</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>FECHA DE ALTA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans Bold',width:'20%'}}>FECHA DE BAJA</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{puesto.codigoPuesto}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{puesto.nombre}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{moment(puesto.fechaInicio).format('DD/MM/YYYY')}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans',width:'20%'}}>{puesto.fechaFin!==null?moment(puesto.fechaFin).format('DD/MM/YYYY'):''}</Text>
                                </View>
                            </View>
                      )
                }):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    InformePG = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PUESTOS DE TRABAJO OCUPADOS POR TRABAJADOR</Text>
                </View>
                {this.state.puestosTablaGral?this.state.puestosTablaGral.map((puestos, indexG)=>{
                    return (
                        <View key={indexG}>
                                <View style={styles.rowHeadGral}>
                                    <View style={styles.labelA}>
                                        <Text style={styles.labelDataA}>CÓDIGO:</Text>
                                        <Text style={styles.valueDataA}>{puestos.codigoEmpleado}</Text>
                                    </View>
                                    <View style={styles.labelD}>
                                        <Text style={styles.labelDataD}>TRABAJADOR:</Text>
                                        <Text style={styles.valueDataD}>{puestos.trabajador}</Text>
                                    </View>
                                </View>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>CÓDIGO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>PUESTO DE TRABAJADO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>FECHA DE ALTA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans Bold',width:'20%'}}>FECHA DE BAJA</Text>
                                </View>
                                
                    {puestos.puestos.map((puesto, index)=>{
                        return (
                            <View key={index}>
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{puesto.codigoPuesto}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{puesto.nombre}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{moment(puesto.fechaInicio).format('DD/MM/YYYY')}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans',width:'20%'}}>{puesto.fechaFin!==null?moment(puesto.fechaFin).format('DD/MM/YYYY'):''}</Text>
                                </View>
                            </View>
                        )
                    })}

                    </View>
                    )
                }):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
      
    )

    InformeT = () => (
      <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>RELACIÓN DE TRABAJADORES</Text>
                </View>
                {this.state.datosImpresion?this.state.datosImpresion.map((empleado, index)=>{
                    let departamento='';
                    let area='';
                    if (empleado.ID_DEPARTAMENTO) {
                        let dptoSel=this.state.departamentos.find(dpto=>{return empleado.ID_DEPARTAMENTO===dpto.ID_DEPARTAMENTO})
                        let areaSel=this.state.areas.find(area=>{return dptoSel.ID_AREA===area.ID_AREA})
                        area=areaSel.DESCRIPCION;
                        departamento=dptoSel.DESCRIPCION;
                    }
                    return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>Nº</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'6%'}}>CÓD</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>NIF</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'23%'}}>TRABAJADOR</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>FEC. ALTA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>FEC. BAJA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans Bold',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%'}}>ÁREA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans Bold',width:'17%'}}>DEPARTAMENTO</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{index+1}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'6%'}}>{empleado.CODIGO_EMPLEADO?empleado.CODIGO_EMPLEADO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{empleado.NIF?empleado.NIF:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'23%',paddingLeft:2}}>{empleado.APELLIDO1} {empleado.APELLIDO2?empleado.APELLIDO2:""},{empleado.NOMBRE}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{moment(empleado.FECHA_ALTA).format("DD-MM-YYYY    ")}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{empleado.FECHA_BAJA!==null?moment(empleado.FECHA_BAJA).format("DD-MM-YYYY    "):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'17%',paddingLeft:2}}>{area}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'17%',paddingLeft:2}}>{departamento}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
    obtenerCodigo=async(indiceEmpleado,idDepto)=>{
        let codigo_empleado=""
        let indiceArea=""
        let indiceDpto=""
        let indicePuesto=""
        if (idDepto) {
        await this.props.client
            .query({
                query: gql` 
                    {departamentos(CAMPOS:"ID_AREA",FILTROS:{PREDEFINIDO:false,ID_DEPARTAMENTO:${idDepto}}){ID_AREA}}
                `,
                fetchPolicy:'network-only'
            }).then(async result=>{ 
                indiceArea=result.data.departamentos[0].ID_AREA
            })
            indiceDpto=idDepto;
        }
        await this.props.client
        .query({
            query: gql` 
                {empleadosPuestos(FILTROS:{ID_EMPLEADO:${indiceEmpleado},FECHA_BAJA:null},LIMIT:1,ORDEN:"ID_PUESTO,DESC"){PUESTO_TRABAJO{ID_PUESTO}}}
            `,
            fetchPolicy:'ne:twork-only'
        }).then(async result=>{ 
            if (result.data.empleadosPuestos.length>0) {
                for(let puesto of result.data.empleadosPuestos){
                    indicePuesto=puesto.PUESTO_TRABAJO.ID_PUESTO
                }
            }
        })
        codigo_empleado="A"+indiceArea+"|D"+indiceDpto+"|P"+indicePuesto+"|E"+indiceEmpleado;
        return codigo_empleado
        
    }

    recargarEmpleados=async (buscando)=>{
        
        //cargar el select de personas.
        this.setState({cargandoEmpleados:true});
        await this.props.client
        .query({
            query: gql` 
                {empleadosPaginacion(CAMPOS:"ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,ID_DEPARTAMENTO",INDICE_MOSTRAR:${this.state.indiceRegistros},NUMERO_MOSTRAR:${this.registrosMostrar}){total,EMPLEADOS{ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,ID_DEPARTAMENTO,NIF}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
            
            this.setState({ 
                empleados: result.data.empleadosPaginacion.EMPLEADOS, 
                totalRegistros: result.data.empleadosPaginacion.total,
                cargandoEmpleados:false 
            })

            if(buscando){
                ReactDOM.findDOMNode(this.buscador).children[0].focus()
            }
        })
   
    }

    cargarDatosGral=async ()=>{

        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {empleadosPaginacion(CAMPOS:"ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,ID_DEPARTAMENTO,NIF",INDICE_MOSTRAR:0,NUMERO_MOSTRAR:${this.state.totalRegistros}){total,EMPLEADOS{ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,ID_DEPARTAMENTO,NIF}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 

            this.setState({ datosImpresion: result.data.empleadosPaginacion.EMPLEADOS })

        })

    }
    obtenerArea=async(data)=>{
        if(data.ID_DEPARTAMENTO){
                let dptoSel=this.state.departamentos.find(dpto=>{return data.ID_DEPARTAMENTO===dpto.ID_DEPARTAMENTO})
                let areaSel=this.state.areas.find(area=>{return dptoSel.ID_AREA===area.ID_AREA})
                    return areaSel.DESCRIPCION
            }           
        
    }
    obtenerDpto=(data)=>{
        if(data.ID_DEPARTAMENTO){
            let dptoSel=this.state.departamentos.find(dpto=>{return data.ID_DEPARTAMENTO===dpto.ID_DEPARTAMENTO})
            return dptoSel.DESCRIPCION
        }
    }
    
    cargarDatosCsv=async ()=>{

        this.setState({ cargandoCsv: true })
        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {empleadosPaginacion(FILTROS:{NOMBRE:""},CAMPOS:"ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,ID_DEPARTAMENTO,NIF",INDICE_MOSTRAR:0,NUMERO_MOSTRAR:${this.state.totalRegistros}){total,EMPLEADOS{ID_EMPLEADO,FECHA_ALTA,FECHA_BAJA,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,ID_DEPARTAMENTO,NIF}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 

          let dpto='';
          let area='';
          let empleados=[]
          let fechaBaja=""
          //let datosImpresion=[]
          for(let empleado of result.data.empleadosPaginacion.EMPLEADOS){
              let fechaAlta= moment(empleado.FECHA_ALTA).format('DD-MM-YYYY')
              
              if (empleado.FECHA_BAJA)
                fechaBaja= moment(empleado.FECHA_BAJA).format('DD-MM-YYYY')
              else 
                fechaBaja=""
              area= await this.obtenerArea(empleado)
              dpto= await this.obtenerDpto(empleado)
              if (area===undefined) area=""
              if (dpto===undefined) dpto=""
            empleados.push({"Código":empleado.CODIGO_EMPLEADO,"NIF":empleado.NIF,"Trabajador":` ${empleado.APELLIDO1} ${empleado.APELLIDO2?empleado.APELLIDO2:""},${empleado.NOMBRE}`,"Fecha alta":`${fechaAlta}`,"Fecha baja":`${fechaBaja}`, "Área":`${area}`, "Departamento":`${dpto}`})
                      //datosImpresion.push({"nombre":` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`,"fechaInicio":empleado.FECHA_ALTA,"fechaFin":empleado.FECHA_BAJA,"codigoEmpleado":empleado.CODIGO_EMPLEADO})

          }


          empleados.sort(function (a, b) {
              return ('' + a.Nombre).localeCompare(b.Nombre);
          })
          this.setState({ datosCsv: empleados,
            cargandoCsv: false })
        })
        

    }

    recargaEmpleadosPuestos=async ()=>{

        //actualizamos el label de puestos de trabajo
        await this.props.client
        .query({
            query: gql` 
                {empleadosPuestos(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_EMPLEO,FECHA_ALTA,FECHA_BAJA,PUESTO_TRABAJO{ID_PUESTO,DESCRIPCION,GRADO_RESPONSABILIDAD,ID_RIESGO_OBJETIVO,CODIGO_PUESTO,DESC_CORTA,DESC_LARGA}}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 

            let puestos=[]
            result.data.empleadosPuestos.forEach(puesto=>{
                puestos.push({
                    "id":puesto.PUESTO_TRABAJO.ID_PUESTO,
                    "idEmpleo":puesto.ID_EMPLEO,
                    "nombre":puesto.PUESTO_TRABAJO.DESCRIPCION,
                    "codigoPuesto":puesto.PUESTO_TRABAJO.CODIGO_PUESTO,
                    fechaInicio:puesto.FECHA_ALTA,
                    fechaFin:puesto.FECHA_BAJA?puesto.FECHA_BAJA:null})
            })
            this.setState({puestos:puestos})
        })
    }

    recargaPuestos=async ()=>{

        //actualizamos el label de puestos de trabajo
        await this.props.client
        .query({
            query: gql` 
                {puestosTrabajo(FILTROS:{ID_DEPARTAMENTO:${this.state.empleadoSeleccionado.ID_DEPARTAMENTO}}){ID_PUESTO,DESCRIPCION,CODIGO_PUESTO}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 

            let puestos=[]
            result.data.puestosTrabajo.forEach(puesto=>{
                puestos.push({"value":puesto.ID_PUESTO,"label":puesto.DESCRIPCION,codigoPuesto:puesto.CODIGO_PUESTO})
            })
            this.setState({puestosSelect:puestos})
            console.log(puestos)
        })
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    cargarAD=async()=>{
        let departamentos=await this.props.client.query({
            query:gql`{departamentos{ID_DEPARTAMENTO,DESCRIPCION,ID_AREA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.departamentos;
        })
        let areas=await this.props.client.query({
            query:gql`{departamentosAreas{ID_AREA,DESCRIPCION,PREDEFINIDA}}`
            ,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return result.data.departamentosAreas
        })
        this.setState({departamentos:departamentos,areas:areas});
    }

    componentDidMount(props){

        this.setState({
            cargando: true,
            cargandoEmpleados:true
        },async () => {
            await this.recargarEmpleados()
            await this.obtenerLogoEmpresa()
            await this.cargarAD()
            this.setState({cargando: false,cargandoEmpleados:false})  
        });
        

    }


    insertarDatosPruebas=async()=>{

        for(let i=0;i<100;i++){


            let persona=await this.props.client.mutate({
                mutation:gql`mutation{crearPersona(VALORES:{ID_TIPO_PERSONA:1}){ID_PERSONA}}`
            }).then(result=>{
                return result.data.crearPersona
            }).catch(err=>{
              alert("Ha ocurrido un error al crear la perosna.Pongase en contacto con el soporte técnico")
            })
            
            const fullname=`Numero ${i}, Empleado`;

            let personaFisica=await this.props.client.mutate({
                mutation:gql`mutation{crearPersonaFisica(VALORES:{
                    ID_PERSONA:${persona.ID_PERSONA},
                    NOMBRE:"Empleado",
                    APELLIDO1:"Numero ${i}",
                    APELLIDO2:${null},
                    FECHA_NACIMIENTO:${null},
                    ID_LENGUA_MATERNA:${null},
                    FULLNAME:"${fullname}"
                }){ID_PERSONA}}`
            }).then(async result=>{
                return result.data.crearPersonaFisica
            }).catch(err=>{
              alert("Ha ocurrido un error al crear la perosna.Pongase en contacto con el soporte técnico")
            })
            
            await this.props.client.mutate({
                mutation:gql`mutation{crearEmpleado(VALORES:{
                    ID_EMPLEADO:${personaFisica.ID_PERSONA},
                    FECHA_ALTA:"${moment().format('YYYY-MM-DD')}",
                    FECHA_BAJA:"${moment().format('YYYY-MM-DD')}",
                    FECHA_INGRESO_INICIAL:"${moment().format('YYYY-MM-DD')}",
                    FECHA_ANTIGUEDAD:"${moment().format('YYYY-MM-DD')}",
                    ID_TIPO_CONTRATO:${null},
                    PARCIALIDAD:"Parcialidad del empleado numero ${i}",
                    CATEGORIA:"Categoria del empleado numero${i}",
                    RETRIBUCION:"Retribucion del empleado numero ${i}",
                    UBICACION:"Ubicacion del empleado número ${i}",
                    TEMPORALIDAD:"Temporalidad del empleado numero ${i}",
                    FORMACION_ACADEMICA:"Formacion academica del empleado numero ${i}",
                    OTRAS_TITULACIONES:"Otras titulaciones del empleado numero${i}",
                    AREA_CONOCIMIENTOS:"Area de conocmientos del emepleado numero ${i}",
                    CONOCIMIENTOS_ESPECIFICOS:"Conocmimientos especificos del empleado numero ${i}",
                    HABILIDADES:"Habilidades para el empleado numero ${i}",
                    APTITUDES:"Aptitutdes del empleado numero ${i}",
                    ACTITUDES:"Actitudes del empleado numero ${i}",
                    ESPECIALIDADES:"Especialidades del empleado numero ${i}",
                    EXPERIENCIA:"Experiencia del empleado numero ${i}",
                    EXPERIENCIA_COINCIDENTE:"Experiencia coincidente del empleado numero ${i}",
                    SITUACION_FAMILIAR_IRPF:${null},
                    NIF_CONYUGE:${null},
                    GRADO_MINUSVALIA:${null},
                    MOVILIDAD_REDUCIDA:${null},
                    OBSERVACIONES_RECONOCIMIENTO:"Observaciones de reconocimiento del empleado i${i}",
                    OTROS_DATOS_MEDICOS:"Otros datos médicos del empleado ${i}",
                    IDIOMAS:${null},
                    CURSOS:${null}
    
                }){ID_EMPLEADO}}`
            })
    
            await  this.props.client.mutate({
                mutation:gql`mutation{crearDepartamentoEmpleado(VALORES:{ID_DEPARTAMENTO:${25},ID_EMPLEADO:${personaFisica.ID_PERSONA}}){ID_DEPARTAMENTO,ID_EMPLEADO}}`
            })

            await this.props.client.mutate({
                mutation:gql`mutation{crearEmpleadoPuesto(VALORES:{ID_EMPLEADO:${personaFisica.ID_PERSONA},ID_PUESTO:${1},FECHA_ALTA:"${moment().format('YYYY-MM-DD')}",FECHA_BAJA:${null}}){ID_EMPLEADO,ID_PUESTO}}`
            })

        }

        
    }

    irPrimerPagina=async()=>{

        this.setState({ 
            indiceRegistros: 0, 
            cargando: true 
        }, async () => {
            await this.recargarEmpleados()

            this.setState({cargando: false})
        })
        
    }
    
    irUltimaPagina=async()=>{
        this.setState({ 
            indiceRegistros: Math.ceil(this.state.totalRegistros / this.registrosMostrar) - 1, 
            cargando: true 
        }, async ()=> {
            await this.recargarEmpleados()

            this.setState({cargando: false})
        })
        
    }
    
    cambiarIndiceMostrar=async(indice)=>{
        this.setState({ 
            indiceRegistros: indice,
            cargando: true 
        }, async () => {
            await this.recargarEmpleados()
            this.setState({cargando: false})
        })
        
    }
    
    botonesPaginacion=()=>{
        
		let botones=[]
		let totalPaginas=Math.ceil(this.state.totalRegistros/this.registrosMostrar)
		let restante=this.state.indiceRegistros+(totalPaginas-this.state.indiceRegistros)
		if(this.state.indiceRegistros>=3){
			botones.push(
				<Button>...</Button>
			)

		}
		for(let i=this.state.indiceRegistros>2?this.state.indiceRegistros-2:0;i<(this.state.indiceRegistros<2?(restante>=4?4:restante):(this.state.indiceRegistros+3)<totalPaginas?this.state.indiceRegistros+3:restante);i++){
			botones.push(
				<Button key={i} className={this.state.indiceRegistros===i?Classes.INTENT_PRIMARY:""} onClick={()=>{this.cambiarIndiceMostrar(i)}}>{i+1}</Button>
			)
		}

		if(this.state.indiceRegistros<10 && (this.state.indiceRegistros+3)<totalPaginas){
			botones.push(
				<Button>...</Button>
			)

		}
		return botones

	}

    
    render(){

        document.title ="LexControl | Recursos humanos"
        
        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const cerrarModal = () => {
            this.setState({
                modalEmpleadoAbierto: false,
                modalPersonaAbierto: false,
                modalPuesto:false,
                modalPuestoBorrar:false,
                modalEvaluacionAbierto:false,
                modalBorrarAbierto:false,
                modalAdvertenciaBaja:false,
                cargandoEmpleados:false,
                cargandoPuestos:false
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalPersonaAbierto: false,
                modalPuestoDetalles: false,
                modalAdvertenciaBaja:false,
                modalBajaFinal:false
            })
        }

        const cerrarModalImprimirT=()=>{
            this.setState({modalImprimirT: false})
        }

        const cerrarModalImprimirC=()=>{
            this.setState({modalImprimirC: false})
        }

        const cerrarModalImprimirPI=()=>{
            this.setState({modalImprimirPI: false})
        }

        const cerrarModalImprimirPG=()=>{
            this.setState({modalImprimirPG: false})
        }

        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }

        // const cargarPerfilesRiesgo= async () =>{
        //   //carga riesgos (select colores)
        //     this.props.client
        //     .query({
        //         query: gql` 
        //             {perfilesRiesgo{ID_PERFIL_RIESGO,DESCRIPCION}}
        //         `,                
        //         fetchPolicy:'network-only'

        //     }).then(result=>{ 

        //       let riesgos=[]
              
        //       result.data.perfilesRiesgo.forEach(riesgo=>{
        //           let color=""
        //           switch(riesgo.ID_PERFIL_RIESGO){
        //               case 1:
        //                   color="green"
        //               break;
        //               case 2:
        //                   color="yellow"
        //               break;
        //               case 3:
        //                   color="orange"
        //               break;
        //               case 4:
        //                   color="red"
        //               break;

        //               default:
        //               break;
        //           }
        //           riesgos.push({"value":riesgo.ID_PERFIL_RIESGO,"label":riesgo.DESCRIPCION,color})
        //       })
        //         this.setState({riesgoOptions:riesgos})

        //       })
        // }

        const abrirModalEmpleadoNuevo = async () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            

            if (this.state.personas.length===0) {
              await this.recargarPersonas()
            }

            this.setState({
                modalEmpleadoAbierto: true,
                nuevaEmpleadoActivado: true,
                editarEmpleadoActivado: false,
                borrarEmpleadoActivado: false,
                evaluacionActivado:false,
                nuevaPresonaActivado:false,
                editarPersonaActivado:false,
                personaSeleccionada:null,
                cargandoEmpleados:true
            });

            
        }
        
        const abrirModalEmpleadoEditar = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert("Debe seleccionar un empleado")
                return
            }

            

            if (this.state.personas.length===0) {
              await this.recargarPersonas()
            }

            //obtenemos los datos del empleado.
            let datosEmpleado=await this.props.client
            .query({
                query: gql` 
                {empleados(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){
                    ID_EMPLEADO,
                    FECHA_ALTA,
                    FECHA_BAJA,
                    FECHA_INGRESO_INICIAL,
                    FECHA_ANTIGUEDAD,
                    ID_TIPO_CONTRATO,
                    PARCIALIDAD,
                    CATEGORIA,
                    RETRIBUCION,
                    RETRIBUCION_VOLUNTARIA,
                    PORCENTAJE_VOLUNTARIA,
                    RETRIBUCION_VARIABLES,
                    PORCENTAJE_VARIABLES,
                    UBICACION,
                    TEMPORALIDAD,
                    CODIGO_EMPLEADO,
                    FORMACION_ACADEMICA,
                    OTRAS_TITULACIONES,
                    AREA_CONOCIMIENTOS,
                    CONOCIMIENTOS_ESPECIFICOS,
                    HABILIDADES,
                    APTITUDES,
                    ACTITUDES,
                    ESPECIALIDADES,
                    EXPERIENCIA,
                    EXPERIENCIA_COINCIDENTE,
                    SITUACION_FAMILIAR_IRPF,
                    NIF_CONYUGE,
                    GRADO_MINUSVALIA,
                    MOVILIDAD_REDUCIDA,
                    OBSERVACIONES_RECONOCIMIENTO,
                    OTROS_DATOS_MEDICOS,
                    IDIOMAS,
                    CURSOS,
                    CLAVE_CONTRATO,
                    GRUPO_COTIZACION
                }}`,
                fetchPolicy:'network-only'

                
            }).then(result=>{ 
                return result.data.empleados[0]
            })

            //abrimos el modal de empleados
            this.setState({
                modalEmpleadoAbierto: true,
                nuevaEmpleadoActivado: false,
                editarEmpleadoActivado: true,
                borrarEmpleadoActivado: false,
                nuevaPresonaActivado:false,
                editarPersonaActivado:false,
                evaluacionActivado:false,
                cargandoEmpleados:true
            })

            //rellenamos los datos de las pestañas.

            //select de personas
            let personaSel=this.state.personas.find(persona=>{
                return persona.value===datosEmpleado.ID_EMPLEADO
            })
            this.setState({
                personaSeleccionada:personaSel,            
            })


            //pestaña fechas.
            this.fechas.setState({
                fechaAlta:new Date(datosEmpleado.FECHA_ALTA),
                bolFechaBaja:datosEmpleado.FECHA_BAJA,
                fechaBaja:datosEmpleado.FECHA_BAJA?new Date(datosEmpleado.FECHA_BAJA):null,
                bolFechaIngreso:datosEmpleado.FECHA_INGRESO_INICIAL,
                fechaIngreso:datosEmpleado.FECHA_INGRESO_INICIAL?new Date(datosEmpleado.FECHA_INGRESO_INICIAL):null,
                bolFechaAntiguedad:datosEmpleado.FECHA_ANTIGUEDAD,
                fechaAntiguedad:datosEmpleado.FECHA_ANTIGUEDAD?new Date(datosEmpleado.FECHA_ANTIGUEDAD):null
            })


            //pestaña informacion
            this.informacion.setState({
                contratoSeleccionado:datosEmpleado.ID_TIPO_CONTRATO,
                clave_contrato:datosEmpleado.CLAVE_CONTRATO,
                grupo_cotizacion:datosEmpleado.GRUPO_COTIZACION,
                parcialidad:datosEmpleado.PARCIALIDAD,
                categoria:datosEmpleado.CATEGORIA,
                ubicacion:datosEmpleado.UBICACION,
                temporalidad:datosEmpleado.TEMPORALIDAD,
                codigoEmpleado:datosEmpleado.CODIGO_EMPLEADO
            })

            //pestaña retribucion
            this.retribucion.setState({
                retribucion:datosEmpleado.RETRIBUCION,
                retribucionVoluntaria:datosEmpleado.RETRIBUCION_VOLUNTARIA,
                porcentajeVoluntaria:datosEmpleado.PORCENTAJE_VOLUNTARIA,
                retribucionVariables:datosEmpleado.RETRIBUCION_VARIABLES,
                porcentajeVariables:datosEmpleado.PORCENTAJE_VARIABLES
            })


            //pestaña formacion academica
            this.formacion.setState({
                formacionAcademica:datosEmpleado.FORMACION_ACADEMICA,
                otrasTitulaciones:datosEmpleado.OTRAS_TITULACIONES,
                areaConocimientos:datosEmpleado.AREA_CONOCIMIENTOS,
                conocimientosEspecificos:datosEmpleado.CONOCIMIENTOS_ESPECIFICOS,
                habilidades:datosEmpleado.HABILIDADES,
                aptitudes:datosEmpleado.APTITUDES,
                actitudes:datosEmpleado.ACTITUDES,
                especialidades:datosEmpleado.ESPECIALIDADES,
                cursos:datosEmpleado.CURSOS?JSON.parse(datosEmpleado.CURSOS):[]
            })

            //epis
            let episEmp=await this.props.client
            .query({
                query: gql`
                {empleadosEpis(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_EPI,EPI{DESCRIPCION},CANTIDAD,FECHA_ENTREGA,ID_EMPLEADO,OBSERVACIONES}}
                `,fetchPolicy:'network-only'
            }).then(result=>{ 
                return JSON.parse(JSON.stringify(result.data.empleadosEpis))
            })

            this.epis.setState({epis:episEmp}) 

            //pestaña experiencia laboral
            this.experiencia.setState({
                laboral:datosEmpleado.EXPERIENCIA,
                coincidente:datosEmpleado.EXPERIENCIA_COINCIDENTE
            })

            //pestaña idiomas.
            if(datosEmpleado.IDIOMAS!==null && datosEmpleado.IDIOMAS!==""){
                console.log(datosEmpleado.IDIOMAS)
                let datosIdiomas=JSON.parse(datosEmpleado.IDIOMAS)
                this.idiomas.setState({idiomas:datosIdiomas})
            }

            //pestaña irpf
            this.irpf.setState({
                irpfValue:datosEmpleado.SITUACION_FAMILIAR_IRPF,
                nifConyuge:datosEmpleado.NIF_CONYUGE
            })

            //pestaña datos medicos.
            this.datosMedicos.setState({
                minusValue:datosEmpleado.GRADO_MINUSVALIA,
                necesitaAyuda:datosEmpleado.MOVILIDAD_REDUCIDA,
                otrosDatos:datosEmpleado.OBSERVACIONES_RECONOCIMIENTO,
                interes:datosEmpleado.OTROS_DATOS_MEDICOS
            })
    
        }
        
        const abrirModalPersona = () => {
            this.setState({
                modalPersonaAbierto: true,

                nuevaPresonaActivado: true,
                editarPersonaActivado: false,
                borrarPersonaActivado: false,

                nombre:null,
                primerApellido:null,
                segundoApellido:null,
                fechaNacimiento:null,
                bolFechaNacimiento:false,
                idiomaSeleccionado:null,
                personaSeleccionada:null

            })
        }

        const abrirModalEliminarEmpleados = () => {

            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert("Debe seleccionar un empleado")
                return
            }

            this.setState({
                nuevaEmpleadoActivado:false,
                editarEmpleadoActivado:false,
                actualizarEmpleado:false,
                borrarEmpleadoActivado:true,
                modalBorrarAbierto:true
            })
        }

        const abrirModalPersonaEditar = () => {

            if(!this.state.personaSeleccionada){
                alert("Debes seleccionar una persona")
                return
            }

            //buscamos el idioma de la persona seleccionada
            let idioma=this.state.personaSeleccionada.lengua?this.state.idiomasSelect.find(idioma=>{
                return idioma.value===this.state.personaSeleccionada.lengua
            }):null

            this.setState({
                modalPersonaAbierto: true,

                nuevaPresonaActivado: false,
                editarPersonaActivado: true,
                borrarPersonaActivado: false,

                nombre:this.state.personaSeleccionada.nombre,
                primerApellido:this.state.personaSeleccionada.primerApellido,
                segundoApellido:this.state.personaSeleccionada.segundoApellido,
                fechaNacimiento:this.state.personaSeleccionada.fechaNacimiento,
                bolFechaNacimiento:this.state.personaSeleccionada.fechaNacimiento,
                idiomaSeleccionado:idioma,
                
            })
        }

        

        const abrirModalNuevoPuesto = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert("Debes seleccionar un empleado.")
                return
            }
            if(!this.state.empleadoSeleccionado.ID_DEPARTAMENTO){
                alert("Debe asociar el empleado a un departamento.")
                return
            }
            if(this.state.empleadoSeleccionado.fechaFin){
                alert("No puede crear un puesto para el empleado seleccionado ya que está dado de baja.")
                return
            }


            this.setState({
                modalPuesto: true,
                nuevoPuestoActivado:true,
                editarPuestoActivado:false,    
                borrarPuestoActivado:false,
                imprimirPIActivado:false,
                imprimirPGActivado:false,
                bolFechaBajaPuesto:false,
                puestoSeleccionadoSel:false,
                fechaBajaPuesto:null,
                fechaAltaPuesto:new Date(),
                cargandoPuestos:true
            });

        }
        const abrirModalEditarPuesto = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert("Debes seleccionar un empleado.")
                return
            }

            if(!this.state.empleadoSeleccionado.ID_DEPARTAMENTO){
                alert("Debe asociar el empleado a un departamento.")
                return
            }

            if(!this.state.puestoSeleccionado){
                alert("Debe seleccionar un puesto de trabajo.")
                return
            }
          
            let puestoSel=this.state.puestosSelect.find(puesto=>{return puesto.value===this.state.puestoSeleccionado.id})

            this.setState({
                cargandoPuestos:true,
                modalPuesto: true,
                nuevoPuestoActivado:false,
                editarPuestoActivado:true,    
                borrarPuestoActivado:false,
                imprimirPIActivado:false,
                imprimirPGActivado:false,
                puestoSeleccionadoSel:puestoSel,
                bolFechaBajaPuesto:this.state.puestoSeleccionado.fechaFin?true:false,
                fechaBajaPuesto:this.state.puestoSeleccionado.fechaFin?new Date(this.state.puestoSeleccionado.fechaFin):null,
                fechaAltaPuesto:new Date(this.state.puestoSeleccionado.fechaInicio)
            })

        }
        const abrirModalNuevoPuestoDetalles = () => {

            let riesgoSel=this.state.riesgoOptions.find(riesgo=>{
                return riesgo.value===1
            })
            this.setState({
                modalPuestoDetalles: true,
                nombrePuesto:null,
                gradoResponsabilidad:1,
                riesgoSeleccionado:riesgoSel,
                codigoPuesto:null,
                descripcionBreve:null,
                descripcionDetallada:null
            })
        }

        const abrirModalBorrarPuesto = () => {

            if(!this.state.empleadoSeleccionado){
                alert("Debes seleccionar un empleado.")
                return
            }
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.puestoSeleccionado){
                alert("Debe seleccionar un puesto de trabajo.")
                return
            }
            this.setState({
                modalPuestoBorrar: true,
                nuevoPuestoActivado:false,
                editarPuestoActivado:false,
                borrarPuestoActivado:true,
                imprimirPIActivado:false,
                imprimirPGActivado:false
            })
        }

        const eliminarPuesto=async ()=>{
            
            //eliminamos el puesto de la tabla.
            let puestos=this.state.puestos.filter(puesto=>{
                return puesto.idEmpleo!==this.state.puestoSeleccionado.idEmpleo
            })
            
            this.setState({puestos:puestos})
            //eliminamos el puesto de la bdd.
            this.props.client.mutate({
                mutation:gql`mutation{eliminarEmpleadoPuesto(ID_EMPLEO:${this.state.puestoSeleccionado.idEmpleo}){ID_EMPLEADO,ID_PUESTO}}`
            }).then(async result=>{
                alert("Puesto de trabajo eliminado")
                this.setState({
                    puestoSeleccionado:null,
                })
            }).catch(error=>{
                alert("Ha ocurrido un error al eliminar el puesto.Contacte con el soporte técnico.")
            })
            
            await this.recargarEmpleados()
            
            cerrarModal()
        }
        
        
        const anadirEmpleado=async ()=>{

            if(!this.state.personaSeleccionada){
                return
            }

            let existe=await this.props.client
            .query({
                query: gql` 
                    {empleados(FILTROS:{ID_EMPLEADO:${this.state.personaSeleccionada.value}}){ID_EMPLEADO}}
                `,
                fetchPolicy:'network-only'
            }).then( result=>{ 
                if(result.data.empleados.length>0)return true;
                return false;
            })
            if(existe){
                alert("Esta persona ya esta insertada como empleado.")
                return
            }

            //empleados.push({"nombre":this.state.personaSeleccionada.label,"fechaInicio":this.fechas.state.fechaAlta,"fechaFin":this.fechas.state.bolFechaBaja?this.fechas.state.fechaBaja:null})

             //convertimos a json los idiomas
             let datosJson="["
             this.idiomas.state.idiomas.forEach((idioma,index)=>{
                 if(index===0){
                    datosJson+=`{"idCompetencia":${idioma.idCompetencia},"competencia":"${idioma.competencia}","idIdioma":${idioma.idIdioma},"nombre":"${idioma.nombre}","idNivel":${idioma.idNivel},"nivel":"${idioma.nivel}"}`
                 }else{
                    datosJson+=`,{"idCompetencia":${idioma.idCompetencia},"competencia":"${idioma.competencia}","idIdioma":${idioma.idIdioma},"nombre":"${idioma.nombre}","idNivel":${idioma.idNivel},"nivel":"${idioma.nivel}"}`
                }
             })
             datosJson+="]"

            let cursosJson="["
            this.formacion.state.cursos.forEach((curso,index)=>{
                if(index===0){
                    cursosJson+=`{"id":${curso.id},"curso":"${curso.curso}","duracion":"${curso.duracion}"}`
                }else{
                    cursosJson+=`,{"id":${curso.id},"curso":"${curso.curso}","duracion":"${curso.duracion}"}`
                }
            })
            cursosJson+="]"
            

            //mutation para crear el empleado.
            await this.props.client.mutate({
                mutation:gql`mutation{crearEmpleado(VALORES:{
                    ID_EMPLEADO:${this.state.personaSeleccionada.value},
                    FECHA_ALTA:"${moment(this.fechas.state.fechaAlta).format('YYYY-MM-DD')}",
                    FECHA_BAJA:${this.fechas.state.bolFechaBaja?'"'+moment(this.fechas.state.fechaBaja).format('YYYY-MM-DD')+'"':null},
                    FECHA_INGRESO_INICIAL:${this.fechas.state.bolFechaIngreso?'"'+moment(this.fechas.state.fechaIngreso).format('YYYY-MM-DD')+'"':null},
                    FECHA_ANTIGUEDAD:${this.fechas.state.bolFechaAntiguedad?'"'+moment(this.fechas.state.fechaAntiguedad).format('YYYY-MM-DD')+'"':null},
                    ID_TIPO_CONTRATO:${this.informacion.state.contratoSeleccionado?this.informacion.state.contratoSeleccionado.value:null},
                    PARCIALIDAD:${this.informacion.state.parcialidad?'"'+this.informacion.state.parcialidad+'"':null},
                    CATEGORIA:${this.informacion.state.categoria?'"'+this.informacion.state.categoria+'"':null},
                    RETRIBUCION:${this.retribucion.state.retribucion?'"'+this.retribucion.state.retribucion+'"':null},
                    RETRIBUCION_VOLUNTARIA:${this.retribucion.state.retribucionVoluntaria?'"'+this.retribucion.state.retribucionVoluntaria+'"':null},
                    PORCENTAJE_VOLUNTARIA:${this.retribucion.state.porcentajeVoluntaria?'"'+this.retribucion.state.porcentajeVoluntaria+'"':null},
                    RETRIBUCION_VARIABLES:${this.retribucion.state.retribucionVariables?'"'+this.retribucion.state.retribucionVariables+'"':null},
                    PORCENTAJE_VARIABLES:${this.retribucion.state.porcentajeVariables?'"'+this.retribucion.state.porcentajeVariables+'"':null},
                    UBICACION:${this.informacion.state.ubicacion?'"'+this.informacion.state.ubicacion+'"':null},
                    CLAVE_CONTRATO:${this.informacion.state.clave_contrato?'"'+this.informacion.state.clave_contrato+'"':null},
                    GRUPO_COTIZACION:${this.informacion.state.grupo_cotizacion?'"'+this.informacion.state.grupo_cotizacion+'"':null},
                    TEMPORALIDAD:${this.informacion.state.temporalidad?'"'+this.informacion.state.temporalidad+'"':null},
                    FORMACION_ACADEMICA:${this.formacion.state.formacionAcademica?'"'+this.formacion.state.formacionAcademica+'"':null},
                    OTRAS_TITULACIONES:${this.formacion.state.otrasTitulaciones?'"'+this.formacion.state.otrasTitulaciones+'"':null},
                    AREA_CONOCIMIENTOS:${this.formacion.state.areaConocimientos?'"'+this.formacion.state.areaConocimientos+'"':null},
                    CONOCIMIENTOS_ESPECIFICOS:${this.formacion.state.conocimientosEspecificos?'"'+this.formacion.state.conocimientosEspecificos+'"':null},
                    HABILIDADES:${this.formacion.state.habilidades?'"'+this.formacion.state.habilidades+'"':null},
                    APTITUDES:${this.formacion.state.aptitudes?'"'+this.formacion.state.aptitudes+'"':null},
                    ACTITUDES:${this.formacion.state.actitudes?'"'+this.formacion.state.actitudes+'"':null},
                    ESPECIALIDADES:${this.formacion.state.especialidades?'"""'+this.formacion.state.especialidades+'"""':null},
                    EXPERIENCIA:${this.experiencia.state.laboral?'"""'+this.experiencia.state.laboral+'"""':null},
                    EXPERIENCIA_COINCIDENTE:${this.experiencia.state.coincidente?'"""'+this.experiencia.state.coincidente+'"""':null},
                    SITUACION_FAMILIAR_IRPF:${this.irpf.state.irpfValue?'"'+this.irpf.state.irpfValue+'"':null},
                    NIF_CONYUGE:${this.irpf.state.nifConyuge?'"'+this.irpf.state.nifConyuge+'"':null},
                    GRADO_MINUSVALIA:${this.datosMedicos.state.minusValue?'"'+this.datosMedicos.state.minusValue+'"':null},
                    MOVILIDAD_REDUCIDA:${this.datosMedicos.state.necesitaAyuda?this.datosMedicos.state.necesitaAyuda:null},
                    OBSERVACIONES_RECONOCIMIENTO:${this.datosMedicos.state.otrosDatos?'"""'+this.datosMedicos.state.otrosDatos+'"""':null},
                    OTROS_DATOS_MEDICOS:${this.datosMedicos.state.interes?'"""'+this.datosMedicos.state.interes+'"""':null},
                    IDIOMAS:${this.idiomas.state.idiomas.length>0?JSON.stringify(datosJson):null},
                    CURSOS:${this.formacion.state.cursos.length>0?JSON.stringify(cursosJson):null}

                }){ID_EMPLEADO}}`
            }).then(async result=>{
                let idEmpleado=result.data.crearEmpleado.ID_EMPLEADO
                let codigo_empleado=idEmpleado

                await this.props.client.mutate({
                    mutation:gql`mutation{actualizarEmpleado(
                        ID_EMPLEADO:${idEmpleado},
                        VALORES:{
                        CODIGO_EMPLEADO:"${codigo_empleado}"})
                    {ID_EMPLEADO}}`
                }).then(async resultCod=>{
                    console.log("actualizado codigo empleado al crear");
                }).catch(err=>{
                    console.log(err)
                    alert("Ha ocurrido un error al actualizar codigo empleado.Contacte con el soporte técnico")
                })


                let num_row=await this.props.client
                .query({
                    query: gql` 
                        {obtenerIndice(ID_EMPLEADO:${idEmpleado})}
                    `,
                    fetchPolicy:'network-only'
                }).then(result=>{ 
                    return result.data.obtenerIndice
                })
                
                let n_pagina=Math.ceil(num_row/this.registrosMostrar)

                await this.recargarEmpleados().then(async()=>{

                    await this.cambiarIndiceMostrar(n_pagina-1)

                    
                    //Insercion de epis
                    if(this.epis.state.epis.length>0){

                        for(let epi of this.epis.state.epis){
        
                            this.props.client.mutate({
                                mutation:gql`mutation{crearEmpleadoEpi(VALORES:{ID_EMPLEADO:${idEmpleado},ID_EPI:${epi.ID_EPI},FECHA_ENTREGA:"${moment(epi.FECHA_ENTREGA).format("YYYY-MM-DD")}",CANTIDAD:${epi.CANTIDAD},OBSERVACIONES:${epi.OBSERVACIONES?'"""'+epi.OBSERVACIONES+'"""':null}}){ID_EMPLEADO,ID_EPI}}`
                            }).catch(error=>{
                                alert("Ha ocurrido un error al crear la epi.Contacte con el soporte técnico.")
                            })
        
                        }
        
                    }

                })

                this.cargarDatosCsv()

                alert("Empleado insertado correctamente")

               
            }).catch(error=>{
                console.log(error)
                alert("Ha ocurrido un error al crear el empleado.Contacte con el soporte técnico.")
            })

            
            cerrarModal()
        }

        const darBajaEmpleado=async()=>{

            //si damos de baja al empleado, lo daremos de baja automaticamente de su ulitmo puesto.
            await this.props.client
            .query({
                query: gql` 
                    {empleadosPuestos(CAMPOS:"ID_EMPLEO",FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},FECHA_BAJA:null}){ID_EMPLEO}}
                `,
                fetchPolicy:'network-only'
            }).then(async result=>{ 
                await this.recargarEmpleados()

                if(result.data.empleadosPuestos.length>0){
                    await this.props.client.mutate({
                        mutation:gql`mutation{actualizarEmpleadoPuesto(ID_EMPLEO:${result.data.empleadosPuestos[0].ID_EMPLEO},VALORES:{FECHA_BAJA:${'"'+moment().format('YYYY-MM-DD')+'"'}}){ID_EMPLEADO,ID_PUESTO}}`
                    })
                    await this.recargaEmpleadosPuestos()
                }
                alert("Empleado actualizado correctamente")

                cerrarModal2()
            
                
            }).catch(err=>{
                alert("Ha ocurrido un error al cambiar el empleado de departamento. Pongase en contacto con el soporte tecnico")
                return
            })
        }

        const actualizarEmpleado=async()=>{

            //convertimos a json los idiomas
            let datosJson="["
            this.idiomas.state.idiomas.forEach((idioma,index)=>{
                if(index===0){
                    datosJson+=`{"idCompetencia":${idioma.idCompetencia},"competencia":"${idioma.competencia}","idIdioma":${idioma.idIdioma},"nombre":"${idioma.nombre}","idNivel":${idioma.idNivel},"nivel":"${idioma.nivel}"}`
                }else{
                    datosJson+=`,{"idCompetencia":${idioma.idCompetencia},"competencia":"${idioma.competencia}","idIdioma":${idioma.idIdioma},"nombre":"${idioma.nombre}","idNivel":${idioma.idNivel},"nivel":"${idioma.nivel}"}`
                }
            })
            datosJson+="]"


            let cursosJson="["
            this.formacion.state.cursos.forEach((curso,index)=>{
                if(index===0){
                    cursosJson+=`{"id":${curso.id},"curso":"${curso.curso}","duracion":"${curso.duracion}"}`
                }else{
                    cursosJson+=`,{"id":${curso.id},"curso":"${curso.curso}","duracion":"${curso.duracion}"}`
                }
            })
            cursosJson+="]"

            let error=false

            //Insercion de epis

            //Eliminamos las antiguas
            await this.props.client.mutate({
                mutation:gql`mutation{eliminarEmpleadoEpi(ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}){ID_EMPLEADO,ID_EPI}}`
            }).catch(error=>{
                alert("Ha ocurrido un error al actualizar las epi.Contacte con el soporte técnico.")
                error=true
            })

            if(error)return

            //creamos las nuevas
            if(this.epis.state.epis.length>0){

                for(let epi of this.epis.state.epis){

                    await this.props.client.mutate({
                        mutation:gql`mutation{crearEmpleadoEpi(VALORES:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},ID_EPI:${epi.ID_EPI},FECHA_ENTREGA:"${moment(epi.FECHA_ENTREGA).format("YYYY-MM-DD")}",CANTIDAD:${epi.CANTIDAD},OBSERVACIONES:${epi.OBSERVACIONES?'"""'+epi.OBSERVACIONES+'"""':null}}){ID_EMPLEADO,ID_EPI}}`
                    }).catch(error=>{
                        alert("Ha ocurrido un error al crear la epi.Contacte con el soporte técnico.")
                        error=true
                    })

                }

            }

            if(error)return
            //console.log(this.state.empleadoSeleccionado);
            //console.log(codigo_empleado)
            //mutation para crear el empleado.
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarEmpleado(ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},VALORES:{
                    FECHA_ALTA:"${moment(this.fechas.state.fechaAlta).format('YYYY-MM-DD')}",
                    FECHA_BAJA:${this.fechas.state.bolFechaBaja?'"'+moment(this.fechas.state.fechaBaja).format('YYYY-MM-DD')+'"':null},
                    FECHA_INGRESO_INICIAL:${this.fechas.state.bolFechaIngreso?'"'+moment(this.fechas.state.fechaIngreso).format('YYYY-MM-DD')+'"':null},
                    FECHA_ANTIGUEDAD:${this.fechas.state.bolFechaAntiguedad?'"'+moment(this.fechas.state.fechaAntiguedad).format('YYYY-MM-DD')+'"':null},
                    ID_TIPO_CONTRATO:${this.informacion.state.contratoSeleccionado?this.informacion.state.contratoSeleccionado.value?this.informacion.state.contratoSeleccionado.value:this.informacion.state.contratoSeleccionado:null},
                    PARCIALIDAD:${this.informacion.state.parcialidad?'"'+this.informacion.state.parcialidad+'"':null},
                    CATEGORIA:${this.informacion.state.categoria?'"'+this.informacion.state.categoria+'"':null},
                    RETRIBUCION:${this.retribucion.state.retribucion?'"'+this.retribucion.state.retribucion+'"':null},
                    RETRIBUCION_VOLUNTARIA:${this.retribucion.state.retribucionVoluntaria?'"'+this.retribucion.state.retribucionVoluntaria+'"':null},
                    PORCENTAJE_VOLUNTARIA:${this.retribucion.state.porcentajeVoluntaria?'"'+this.retribucion.state.porcentajeVoluntaria+'"':null},
                    RETRIBUCION_VARIABLES:${this.retribucion.state.retribucionVariables?'"'+this.retribucion.state.retribucionVariables+'"':null},
                    PORCENTAJE_VARIABLES:${this.retribucion.state.porcentajeVariables?'"'+this.retribucion.state.porcentajeVariables+'"':null},
                    UBICACION:${this.informacion.state.ubicacion?'"'+this.informacion.state.ubicacion+'"':null},
                    CLAVE_CONTRATO:${this.informacion.state.clave_contrato?'"'+this.informacion.state.clave_contrato+'"':null},
                    GRUPO_COTIZACION:${this.informacion.state.grupo_cotizacion?'"'+this.informacion.state.grupo_cotizacion+'"':null},
                    TEMPORALIDAD:${this.informacion.state.temporalidad?'"'+this.informacion.state.temporalidad+'"':null},
                    FORMACION_ACADEMICA:${this.formacion.state.formacionAcademica?'"'+this.formacion.state.formacionAcademica+'"':null},
                    OTRAS_TITULACIONES:${this.formacion.state.otrasTitulaciones?'"'+this.formacion.state.otrasTitulaciones+'"':null},
                    AREA_CONOCIMIENTOS:${this.formacion.state.areaConocimientos?'"'+this.formacion.state.areaConocimientos+'"':null},
                    CONOCIMIENTOS_ESPECIFICOS:${this.formacion.state.conocimientosEspecificos?'"'+this.formacion.state.conocimientosEspecificos+'"':null},
                    HABILIDADES:${this.formacion.state.habilidades?'"'+this.formacion.state.habilidades+'"':null},
                    APTITUDES:${this.formacion.state.aptitudes?'"'+this.formacion.state.aptitudes+'"':null},
                    ACTITUDES:${this.formacion.state.actitudes?'"'+this.formacion.state.actitudes+'"':null},
                    ESPECIALIDADES:${this.formacion.state.especialidades?'"""'+this.formacion.state.especialidades+'"""':null},
                    EXPERIENCIA:${this.experiencia.state.laboral?'"""'+this.experiencia.state.laboral+'"""':null},
                    EXPERIENCIA_COINCIDENTE:${this.experiencia.state.coincidente?'"""'+this.experiencia.state.coincidente+'"""':null},
                    SITUACION_FAMILIAR_IRPF:${this.irpf.state.irpfValue?'"'+this.irpf.state.irpfValue+'"':null},
                    NIF_CONYUGE:${this.irpf.state.nifConyuge?'"'+this.irpf.state.nifConyuge+'"':null},
                    GRADO_MINUSVALIA:${this.datosMedicos.state.minusValue?'"'+this.datosMedicos.state.minusValue+'"':null},
                    MOVILIDAD_REDUCIDA:${this.datosMedicos.state.necesitaAyuda?this.datosMedicos.state.necesitaAyuda:null},
                    OBSERVACIONES_RECONOCIMIENTO:${this.datosMedicos.state.otrosDatos?'"""'+this.datosMedicos.state.otrosDatos+'"""':null},
                    OTROS_DATOS_MEDICOS:${this.datosMedicos.state.interes?'"""'+this.datosMedicos.state.interes+'"""':null},
                    IDIOMAS:${this.idiomas.state.idiomas.length>0?JSON.stringify(datosJson):null},
                    CURSOS:${this.formacion.state.cursos.length>0?JSON.stringify(cursosJson):null}
                }){ID_EMPLEADO}}`
            }).then(async result=>{


                if(this.fechas.state.bolFechaBaja){
                    this.setState({modalBajaFinal:true})
                    return
                }
        
                alert("Empleado actualizado correctamente")
                await this.recargarEmpleados()
                this.cargarDatosCsv()
                
                cerrarModal()
            
            }).catch(err=>{
                alert("Ha ocurrido un errar al actualizar el empleado.Contacte con el soporte técnico")
            })

        }

        const escogeFechaAltaPuesto = (nuevaFecha) => {
            this.setState({fechaAltaPuesto: nuevaFecha})
        }

        const escogeFechaBajaPuesto = (nuevaFecha) => {
            this.setState({fechaBajaPuesto: nuevaFecha})
        }
        
        const handleCambiarNombrePuesto = (ev) => {
            this.setState({nombrePuesto: ev.currentTarget.value})
        }

        const handleCambiarDescripcionBreve = (ev) => {
            this.setState({descripcionBreve: ev.currentTarget.value})
        }

        const handleCambiarDescripcionDetallada = (ev) => {
            this.setState({descripcionDetallada: ev.currentTarget.value})
        }

        const handleEnabledChangeBolFechaBajaPuesto = () => {
            let nuevoValor=!this.state.bolFechaBajaPuesto
            this.setState({ bolFechaBajaPuesto: nuevoValor })
            //si hacemos click en el boton y no hay fecha seleccionada, cogeremos le facha por defecto.
            if(nuevoValor){
                this.setState({fechaBajaPuesto:new Date()})
            }else{
                this.setState({fechaBajaPuesto:null})
            }
        }

        
        const handleSlider = async (value,idCompetencia) => {

          
            let competencias=this.state.competencias
            competencias.find((competencia,index)=>{
                if(competencia.id===idCompetencia)competencias[index].value=value
                return competencia.id===idCompetencia
            })
           
            this.setState({comeptencias:competencias})

        
        }

        const handleSliderResponsabilidad = (value) => {
            this.setState({ gradoResponsabilidad: value })
        }

        const guardarCompetencias=async()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            for(let c of this.state.comeptencias){
                //comprobacion de si existe en la tabla empleados_competencias.
                let existe =await this.props.client
                .query({
                    query: gql` 
                        {empleadosCompetencias(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},ID_COMPETENCIA:${c.id}}){VALOR}}
                    `,
                    fetchPolicy:'network-only'
                }).then(result=>{ 
                    if(result.data.empleadosCompetencias.length>0)return true
                    return false
                })

                if(existe){

                    await this.props.client.mutate({
                        mutation:gql`mutation{actualizarEmpleadoCompetencia(ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},ID_COMPETENCIA:${c.id},VALORES:{VALOR:${c.value}}){ID_EMPLEADO,ID_COMPETENCIA}}`
                    }).catch(error=>{
                        alert("Ha ocurrido un error al actualizar la competencia.Contacte con el soporte técnico.")
                    })

                }else{
                    
                    await this.props.client.mutate({
                        mutation:gql`mutation{crearEmpleadoCompetencia(VALORES:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},ID_COMPETENCIA:${c.id},VALOR:${c.value}}){ID_EMPLEADO,ID_COMPETENCIA}}`
                    }).catch(error=>{
                        alert("Ha ocurrido un error al crear la competencia.Contacte con el soporte técnico.")
                    })
                }
            }

            this.setState({drawerAbierto: false})

            alert("Las competencias se han guardado correctamente")

        }

        const cancelarCompetencias=async()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            //carga de las competencias del empleado.
            let competencias=await this.props.client
            .query({
                query: gql` 
                    {competencias(ORDEN:"DESCRIPCION,ASC"){ID_COMPETENCIA,DESCRIPCION}}
                `,
                fetchPolicy:'network-only'
            }).then( result=>{ 
                return result.data.competencias
            })

           // competencias
            //this.setState({competencias:competencias})
            let compClientes=await this.props.client
            .query({
                query: gql` 
                    {empleadosCompetencias(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_EMPLEADO,ID_COMPETENCIA,VALOR}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.empleadosCompetencias
              
            })

            let c=[]
            competencias.forEach(competencia=>{
                let valor=0
                compClientes.find(cc=>{
                    if(cc.ID_COMPETENCIA===competencia.ID_COMPETENCIA){
                        valor=cc.VALOR
                    }
                    return cc.ID_COMPETENCIA===competencia.ID_COMPETENCIA
                })
                c.push({id:competencia.ID_COMPETENCIA,nombre:competencia.DESCRIPCION,value:valor})
            })     
            this.setState({competencias:c, drawerAbierto: false})

        }

        const Competencias = () => (
            /* HAY QUE PONER QUE  si no hay empleado seleccionado, coja las competencias mock declaradas al principio del archivo */
            this.state.empleadoSeleccionado != null?
                <>
                {this.state.competencias.map((competencia,index) =>(
                    <div className="station"  key={competencia.id}>
                        {competencia.nombre}
                        <Slider 
                            onChange={(value) => handleSlider(value, competencia.id)}
                            value={competencia.value}
                            min={0}
                            max={5}
                            disabled={!this.props.editable}
                        />
                    </div>
                ))}
                {this.state.competencias.length>0?
                <>
                    <Button
                     alignText="left"
                     icon="floppy-disk"
                     text="Guardar"
                     intent="danger"
                     onClick={guardarCompetencias}
                     className="iconoIzquierda bp3-minimal"
                    />
                    <Button
                    alignText="left"
                    icon="cross"
                    text="Cancelar"
                    intent="danger"
                    onClick={cancelarCompetencias}
                    className="iconoIzquierda bp3-minimal"
                   />
                   <Button
                    alignText="left"
                    icon="print"
                    text="Imprimir"
                    intent="danger"
                    onClick={()=>this.generarInformeC()}
                    className="iconoIzquierda bp3-minimal"
                   />
                </>
                :<></>}
            </>
            :
            <>
                {this.state.competenciasFalsas.map((competencia,index) =>(
                    <div className="station" key={competencia.id}>
                        {competencia.nombre}
                        <Slider 
                            onChange={(value) => handleSlider(value, competencia.id)}
                            value={competencia.value}
                            min={0}
                            max={5}
                            disabled={!this.props.editable}
                        />
                    </div>
                ))}
            </>
        );

        const handleOnSelectPuesto = (row,isSelect) => {
            if (isSelect) {
                this.setState({
                    puestoSeleccionado: row
                });
            }
        }

        const handleOnSelectEmpleado=(row, isSelect) => {
            if (isSelect) {
                let nombre=`${row.APELLIDO1} ${row.APELLIDO2?row.APELLIDO2:""},${row.NOMBRE}`              
                this.setState({
                    empleadoSeleccionado: row,
                    nombreSeleccionado:nombre,
                    cargandoCompetencia:true,
                    cargandoPuestos:true
                },async ()=>{
                    this.recargaEmpleadosPuestos();
                    this.recargaPuestos();

                    let competencias=await this.props.client
                    .query({
                        query: gql` 
                            {competencias(ORDEN:"DESCRIPCION,ASC"){ID_COMPETENCIA,DESCRIPCION}}
                        `,
                        fetchPolicy:'network-only'
                    }).then( result=>{ 
                        return result.data.competencias
                    });

                    let compClientes=await this.props.client
                    .query({
                        query: gql` 
                            {empleadosCompetencias(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_EMPLEADO,ID_COMPETENCIA,VALOR}}
                        `,
                        fetchPolicy:'network-only'
                    }).then(result=>{ 
                        return result.data.empleadosCompetencias
                    
                    });

                    let c=[]
                    competencias.forEach(competencia=>{
                        let valor=0
                        compClientes.find(cc=>{
                            if(cc.ID_COMPETENCIA===competencia.ID_COMPETENCIA){
                                valor=cc.VALOR
                            }
                            return cc.ID_COMPETENCIA===competencia.ID_COMPETENCIA
                        })
                        c.push({id:competencia.ID_COMPETENCIA,nombre:competencia.DESCRIPCION,value:valor})
                    })     
                    this.setState({competencias:c,cargandoCompetencia:false,cargandoPuestos:false})
                });
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }
        
        //si no han cargado los empleados, devolvemos null.
        if(!this.state.empleados)return <div/>

        const seleccionRiesgos  = (value) => { 
            this.setState({riesgoSeleccionado: value})
        }

        const selPuestoEmp  = (value) => { 
            this.setState({puestoSeleccionadoSel: value})
        }


        const dot = (color = '#ccc') => ({
            alignItems: 'center',
            display: 'flex',
          
            ':before': {
              backgroundColor: color,
              borderRadius: 10,
              content: '" "',
              display: 'block',
              marginRight: 8,
              height: 10,
              width: 10,
            },
          });

        const estilosColorSelect = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = chroma(data.color);
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? '#ccc'
                  : isSelected
                    ? chroma.contrast(color, 'white') > 2 ? 'black' : 'black'
                    : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
              };
            },
            input: styles => ({ ...styles, ...dot() }),
            placeholder: styles => ({ ...styles, ...dot() }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
        }
/* 
        const obtenerNumPuesto=async(idDep)=>{
            return await this.props.client
            .query({
                query: gql` 
                    {puestosTrabajo(FILTROS:{ID_DEPARTAMENTO:${idDep}}){ID_PUESTO}}
                `
            }).then(result=>{ 
                return result.data.puestosTrabajo.length+1
            })
        } */

        const guardarPuesto = async() => {

            if(!this.state.nombrePuesto)return
            //if(!this.state.codigoPuesto)return

            //comprobar que no exista un puesto con ese codigo
            /*
            let existe=  await this.props.client
            .query({
                query: gql` 
                    {puestosTrabajo(FILTROS:{CODIGO_PUESTO:"${this.state.codigoPuesto}"}){CODIGO_PUESTO}}
                `
            }).then(result=>{ 
                if(result.data.puestosTrabajo.length>0)return true
                return false
            })

            if(existe){
                alert("Ya existe un puesto con ese codigo.")
                return
            }
            */

            
            //comprobar que no exista un puesto con ese nombre
            let existe2= await this.props.client
            .query({
                query: gql` 
                    {puestosTrabajo(FILTROS:{DESCRIPCION:"${this.state.nombrePuesto}"}){DESCRIPCION}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.puestosTrabajo.length>0)return true
                return false
            })

            if(existe2){
                alert("Ya existe un puesto con ese nombre.")
                return
            }

            
            //creamos el puesto de trabajo.
            await this.props.client.mutate({
                mutation:gql`mutation{crearPuestoTrabajo(VALORES:{ID_DEPARTAMENTO:${this.state.empleadoSeleccionado.ID_DEPARTAMENTO},DESCRIPCION:"${this.state.nombrePuesto}",GRADO_RESPONSABILIDAD:${this.state.gradoResponsabilidad},ID_RIESGO_OBJETIVO:${this.state.riesgoSeleccionado.value},DESC_CORTA:${this.state.descripcionBreve?'"'+this.state.descripcionBreve+'"':null},DESC_LARGA:${this.state.descripcionDetallada?'"""'+this.state.descripcionDetallada+'"""':null}}){ID_PUESTO}}`
            }).then(async result=>{
               
            
                await this.recargaPuestos()
                await this.recargarEmpleados()

                let puestoSel=this.state.puestosSelect.find(puesto=>{
                    return puesto.value===result.data.crearPuestoTrabajo.ID_PUESTO
                })
                
                this.setState({puestoSeleccionadoSel:puestoSel})
                alert("Nuevo puesto de trabajo insertado")
                cerrarModal2()
                
            }).catch(error=>{
                console.log(error)
                alert("Ha ocurrido un error al crear el puesto.Contacte con el soporte técnico.")
            })

        }


        const guardarPuestoEmpleado=async()=>{

            if(!this.state.puestoSeleccionadoSel){
                return
            }

            let existe=this.state.puestos.find(puesto=>{
                return puesto.id===this.state.puestoSeleccionadoSel.value
            })
            if(existe){
                alert("El empleado ya tiene asignado este puesto")
                return
            }

            
            //modal de advertencia
            //Si el empleado tiene algún puesto anterior y este no tiene fecha de baja
            if(this.state.puestos.length>0){
                let ultPuesto=this.state.puestos.find(puesto=>{return !puesto.fechaFin})
                if(ultPuesto){
                    this.setState({modalAdvertenciaBaja:true})
                    return
                }
            }
            
            //let codigoPuesto=this.state.puestoSeleccionadoSel.codigoPuesto.split("|")

            //obtencion del ultimo codigo empleado de ese area y de partamento
            /*
            let emps=await this.state.empleados.filter(emp=>{
               let patron=String(codigoPuesto[0])+"|"+String(codigoPuesto[1])+"|"
               return emp.codigoEmpleado && emp.codigoEmpleado.startsWith(patron)===true && emp.id!=this.state.empleadoSeleccionado.ID_EMPLEADO
            })
            */

            /*let mayor=Math.max.apply(Math,emps.map(emp=>{ 
                return parseInt(emp.codigoEmpleado.split("|")[3] 
            )}))*/

            //mutation para crear el empleado puesto
            this.props.client.mutate({
                mutation:gql`mutation{crearEmpleadoPuesto(VALORES:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},ID_PUESTO:${this.state.puestoSeleccionadoSel.value},FECHA_ALTA:"${moment(this.state.fechaAltaPuesto).format('YYYY-MM-DD')}",FECHA_BAJA:${this.state.bolFechaBajaPuesto?'"'+moment(this.state.fechaBajaPuesto).format('YYYY-MM-DD')+'"':null}}){ID_EMPLEADO,ID_PUESTO}}`
            }).then(async result=>{

                /*
                let codigoEmpleado=""

                if(this.state.empleadoSeleccionado.codigoEmpleado && this.state.empleadoSeleccionado.codigoEmpleado!==""){
                    codigoEmpleado=`${codigoPuesto[0]}|${codigoPuesto[1]}|${codigoPuesto[2]}|${this.state.empleadoSeleccionado.codigoEmpleado.split("|")[3]}`
                }else{
                    codigoEmpleado=`${codigoPuesto[0]}|${codigoPuesto[1]}|${codigoPuesto[2]}|${emps.length===0?1:mayor+1}`
                }*/
               
     
                //generamos el codigo del empleado
                /*
                await  this.props.client.mutate({
                     mutation:gql`mutation{actualizarEmpleado(
                     ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},
                     VALORES:{
                         CODIGO_EMPLEADO:"${codigoEmpleado}",
                     }){ID_EMPLEADO}}`
 
                })
                */
               
                await this.recargarEmpleados()
                await this.recargaEmpleadosPuestos()

                alert("El puesto se ha insertado correctamente.")

                cerrarModal()       
                     
            }).catch(err=>{

                alert("Ha ocurrido un error al asociar el empleado con el puesto de trabajo.Contacte con el soporte técnico")
            })

        }


        const actualizarPuestoEmpleado=()=>{

            let existe=this.state.puestos.find(puesto=>{
                return puesto.id===this.state.puestoSeleccionadoSel.value
            })

            if(existe && existe.id!==this.state.puestoSeleccionado.id){
                alert("El empleado ya tiene asignado este puesto")
                return
            }

            //mutation para crear el empleado puesto
            this.props.client.mutate({
                mutation:gql`mutation{actualizarEmpleadoPuesto(ID_EMPLEO:${this.state.puestoSeleccionado.idEmpleo},VALORES:{ID_PUESTO:${this.state.puestoSeleccionadoSel.value},FECHA_ALTA:"${moment(this.state.fechaAltaPuesto).format('YYYY-MM-DD')}",FECHA_BAJA:${this.state.bolFechaBajaPuesto?'"'+moment(this.state.fechaBajaPuesto).format('YYYY-MM-DD')+'"':null}}){ID_EMPLEADO,ID_PUESTO}}`
            }).then(async result=>{
                await this.recargaEmpleadosPuestos()
               
                alert("el empleado se ha actualizado correctamente.")

                cerrarModal()   

            }).catch(err=>{
                alert("Ha ocurrido un error al asociar el emplado con el puesto de trabajo.Contacte con el soporte técnico")
            })
        }
        
        const eliminarEmpleado=async()=>{


            //Eliminado de departamentos empleados.
            await this.props.client
            .query({
                query: gql` 
                    {departamentosEmpleados(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_DEPARTAMENTO,ID_EMPLEADO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.departamentosEmpleados.length>0){
                    this.props.client.mutate({
                        mutation:gql`mutation{eliminarDepartamentoEmpleado(ID_DEPARTAMENTO:${result.data.departamentosEmpleados[0].ID_DEPARTAMENTO},ID_EMPLEADO:${result.data.departamentosEmpleados[0].ID_EMPLEADO}){ID_DEPARTAMENTO,ID_EMPLEADO}}`
                    }).catch(err=>{
                        console.log(err)
                        alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
                    }) 
                }
            })

            //Eliminamos las competencias del empleado
            await this.props.client
            .query({
                query: gql` 
                    {empleadosCompetencias(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_COMPETENCIA,ID_EMPLEADO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.empleadosCompetencias.length>0){
                    result.data.empleadosCompetencias.forEach(competencia=>{
                        this.props.client.mutate({
                            mutation:gql`mutation{eliminarEmpleadoCompetencia(ID_COMPETENCIA:${competencia.ID_COMPETENCIA},ID_EMPLEADO:${competencia.ID_EMPLEADO}){ID_COMPETENCIA,ID_EMPLEADO}}`
                        }).catch(err=>{
                            console.log(err)
                            alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
                        }) 
                    })
                }
            })

            //Eliminamos los puestos del empleado
            await this.props.client
            .query({
                query: gql` 
                    {empleadosPuestos(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_EMPLEO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.empleadosPuestos.length>0){
                    result.data.empleadosPuestos.forEach(puesto=>{
                        this.props.client.mutate({
                            mutation:gql`mutation{eliminarEmpleadoPuesto(ID_EMPLEO:${puesto.ID_EMPLEO}){ID_EMPLEO}}`
                        }).catch(err=>{
                            console.log(err)
                            alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
                        }) 
                    })
                }
            })

            
            //Eliminamos las valoraciones del empleado
            await this.props.client
            .query({
                query: gql` 
                    {empleadosValoracion(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_VALORACION}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.empleadosValoracion.length>0){
                    result.data.empleadosValoracion.forEach(valoracion=>{
                        this.props.client.mutate({
                            mutation:gql`mutation{eliminarEmpleadoValoracion(ID_VALORACION:${valoracion.ID_VALORACION}){ID_VALORACION}}`
                        }).catch(err=>{
                            console.log(err)
                            alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
                        }) 
                    })
                }
            })

            //Eliminamos las valoraciones la cual el empleado es el valorador
            await this.props.client
            .query({
                query: gql` 
                    {empleadosValoracion(FILTROS:{ID_RESPONSABLE:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_VALORACION}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.empleadosValoracion.length>0){
                    result.data.empleadosValoracion.forEach(valoracion=>{
                        this.props.client.mutate({
                            mutation:gql`mutation{eliminarEmpleadoValoracion(ID_VALORACION:${valoracion.ID_VALORACION}){ID_VALORACION}}`
                        }).catch(err=>{
                            console.log(err)
                            alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
                            return
                        })
                    })
                }
            })

            //Eliminamos lo formacion informacion
            await this.props.client
            .query({
                query: gql` 
                    {formacionesInformacion(FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}}){ID_ACCION}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                if(result.data.formacionesInformacion.length>0){
                    result.data.formacionesInformacion.forEach(formacion=>{
                        this.props.client.mutate({
                            mutation:gql`mutation{eliminarFormacionInformacion(ID_ACCION:${formacion.ID_ACCION}){ID_ACCION}}`
                        }).catch(err=>{
                            console.log(err)
                            alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
                        }) 
                    })
                }
            })

                
            //Eliminamos el empleado
            this.props.client.mutate({
                mutation:gql`mutation{eliminarEmpleado(ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO}){ID_EMPLEADO}}`
            }).catch(err=>{
                console.log(err)
                alert("Ha ocurrido un error al eliminar el empleado.Contacto con el soporte técnico")
            })            

            cerrarModal()

            this.setState({
                puestos: [],
                competencias: [],
                empleadoSeleccionado: null,
                cargandoEmpleados:true
            });
            await this.recargarEmpleados()
            this.cargarDatosCsv()


            if(this.state.empleados.length===0)this.irUltimaPagina();

            alert("El empleado ha sido eliminado correctamente")

            
        }

        const crearPersona=async()=>{

            await this.datosPersona.crearPersona()
            if(this.datosPersona.state.idPersonaCreada){
                await this.recargarPersonas()
                //seleccionar empleado creado
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
                //cerrar el modal
                cerrarModal2()
            }

        }

        const actualizarPersona=async ()=>{

            await this.datosPersona.actualizarPersona()
            if(this.datosPersona.state.idPersonaActualizada){
                await this.recargarPersonas()
                this.recargarEmpleados()
                //seleccionar empleado creado
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                //cerrar el modal
                cerrarModal2()
            }

        }

        const abrirModalEvaluacion=async()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.empleadoSeleccionado){
                alert("Debe seleccionar un empleado")
                return
            }

            let puestoEmp=await this.props.client
            .query({
                query: gql` 
                    {empleadosPuestos(CAMPOS:"ID_EMPLEO",FILTROS:{ID_EMPLEADO:${this.state.empleadoSeleccionado.ID_EMPLEADO},FECHA_BAJA:null}){ID_EMPLEO}}
                `,
                fetchPolicy:'network-only'
            }).then(result=>{ 
                return result.data.empleadosPuestos
            })

            if(puestoEmp.length===0){
                alert("Actualmente el trabajador no se encuentra asignado a ningún puesto o está dado de baja su puesto de trabajo actual.Por favor asigne el trabajador a un nuevo puesto.")
                return
            }
            
            this.setState({
                modalEvaluacionAbierto:true,
                evaluacionActivado:true,
                nuevaEmpleadoActivado:false,
                actualizarEmpleado:false
            })
        }

        const finalizarPuesto=async()=>{

            //damos debajo la fecha de baja el ultimo puesto del empleado
            let ultPuesto=this.state.puestos.find(puesto=>{return !puesto.fechaFin})
            console.log(ultPuesto)
            //mutation para crear el empleado puesto
            this.props.client.mutate({
                mutation:gql`mutation{actualizarEmpleadoPuesto(ID_EMPLEO:${ultPuesto.idEmpleo},VALORES:{FECHA_BAJA:${'"'+moment().format('YYYY-MM-DD')+'"'}}){ID_EMPLEADO,ID_PUESTO}}`
            }).then(async result=>{
                await this.recargaEmpleadosPuestos()
                await guardarPuestoEmpleado()
            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar el puesto de trabajo. Por favor contacte con el soporte tecnico")
            })
        }
        
        const handleJoyrideCallback=async(data)=>{
            
            const { action, index, status, type } = data;

            if(data.action === "close"){
                localStorage.setItem("ayuda", false)
                this.setState({pasoGuia: 0, modalAbierto:false,modalEmpleadoAbierto:false,modalPuesto:false,drawerAbierto:false})
            } else {

            
                if ([EVENTS.STEP_AFTER].includes(type)) {
                    
                    let paso=index+ (action === ACTIONS.PREV ? -1 : 1)

                    //paso en el que nos encontremos.
                    switch(paso){
                        case 1:
                            this.setState({ modalEmpleadoAbierto: false })
                        break;
                        case 2:
                            this.setState({ modalEmpleadoAbierto: true })
                        break;
                        case 3:
                            this.setState({ pestaña: "fechas" })
                        break;
                        case 4:
                            this.setState({ pestaña: "informacion-contractual" })
                        break;
                        case 5:
                            this.setState({ pestaña: "formacion" })
                        break;
                        case 6:
                            this.setState({ pestaña: "epis" })
                        break;
                        case 7:
                            this.setState({ pestaña: "experiencia" })
                        break;
                        case 8:
                            this.setState({ pestaña: "idiomas" })
                        break;
                        case 9:
                            this.setState({ pestaña: "irpf" })
                        break;
                        case 10:
                            this.setState({ pestaña: "datos-medicos", modalEmpleadoAbierto: true })
                        break;
                        case 11:
                            this.setState({ modalEmpleadoAbierto: false, modalPuesto: false })
                        break;
                        case 12:
                            this.setState({ modalPuesto: true })
                        break;
                        case 13:
                            this.setState({ modalPuesto: true })
                        break;
                        case 14:
                            this.setState({ modalPuesto: true })
                        break;
                        case 15:
                            this.setState({ modalPuesto: false, drawerAbierto: false })
                        break;
                        case 16:
                            this.setState({ drawerAbierto: true })
                        break;
                        case 17:
                            this.setState({ drawerAbierto: false })
                        break;
                        default:
                    }
            
                    this.setState({ pasoGuia: paso });   


                }

                //si termina la guia.
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                    localStorage.setItem("ayuda",false)
                }

            }

        }

        const nameEmployee=(cell,row)=>{
            let name=cell;
            if (row.APELLIDO2) {
                name+=' '+row.APELLIDO2;
            }
            name+=', '+row.NOMBRE;
            return (
                name
            );
        }

        function dateAltaFormatter(cell) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }

        function dateBajaFormatter(cell) {
            let baja=null;
            if (cell) {
                baja=moment(cell).format("DD/MM/YYYY");
            }
            return (
                baja
            );
        }

        const areaFormatter=(cell)=>{
            let area=null;
            if (cell) {
                let dptoSel=this.state.departamentos.find(dpto=>{return cell===dpto.ID_DEPARTAMENTO})
                let areaSel=this.state.areas.find(area=>{return dptoSel.ID_AREA===area.ID_AREA})
                area=areaSel.DESCRIPCION;            
            }
            return (
                area
            );
        }

        const dptoFormatter=(cell,row)=>{
            let dpto=null;
            if (row.ID_DEPARTAMENTO) {
                let dptoSel=this.state.departamentos.find(dpto=>{return row.ID_DEPARTAMENTO===dpto.ID_DEPARTAMENTO})
                dpto=dptoSel.DESCRIPCION;            
            }
            return (
                dpto
            );
        }

        function dateFormatter(cell) {
            let fecha=null;
            if (cell) {
                fecha=moment(cell).format("DD/MM/YYYY");
            }
            return (
                fecha
            );
        }

        const columnsPuesto = [
            {
                dataField: 'codigoPuesto',
                text: 'Código'
            },
            {
                dataField: 'nombre',
                text: 'Puesto ocupado'
            },
            {
                dataField: 'fechaInicio',
                text: 'Fecha de alta',
                formatter: dateFormatter
            },
            {
                dataField: 'fechaFin',
                text: 'Fecha de baja',
                formatter: dateFormatter
            }
        ]

        const columns = [
            {
                dataField: 'CODIGO_EMPLEADO',
                text: 'Código'
            },
            {
                dataField: 'NIF',
                text: 'NIF',
                filter: textFilter({
                    placeholder: 'Filtrar por NIF...'
                })
            },
            {
                dataField: 'APELLIDO1',
                text: 'Trabajador',
                formatter: nameEmployee,
                filter: textFilter({
                    placeholder: 'Filtrar por trabajador...'
                })
            },
            {
                dataField: 'FECHA_ALTA',
                text: 'Alta',
                formatter: dateAltaFormatter
            },
            {
                dataField: 'FECHA_BAJA',
                text: 'Baja',
                formatter: dateBajaFormatter
            },
            {
                dataField: 'ID_DEPARTAMENTO',
                text: 'Área',
                formatter: areaFormatter
            },
            {
                dataField: 'ID_EMPLEADO',
                text: 'Departamento',
                formatter: dptoFormatter
            }
        ];

        return (
            <>

                
                {localStorage.getItem("ayuda")==="true"?
                    <>
                        <Joyride
                            run={localStorage.getItem("ayuda")==="true"?true:false}
                            callback={handleJoyrideCallback}
                            showProgress={true}
                            disableScrollParentFix ={true}
                            stepIndex={this.state.pasoGuia}
                            steps={this.state.steps}
                            continuous ={true}
                            locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
                            styles={{
                                options: {
                                    arrowColor: '#e3ffeb',
                                    backgroundColor: '#e3ffeb',
                                    primaryColor: '#E31E24',
                                }
                            }}
                        />
                    </>
                :null}

                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar una empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>Atención, ¿Desea eliminar el empleado?.Si acepta se eliminarán todos los datos relacionados con el empleado.</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar empleado." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarEmpleado}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Propiedades puesto de trabajo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")this.setState(this.state)}}
                    isOpen={this.state.modalPuesto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label="Puesto:"
                                labelFor="nombre-puesto"
                                intent="danger"
                                helperText={this.state.puestoSeleccionadoSel?"":"Debe seleccionar un puesto de trabajo."}

                            >
                                <div style={this.state.puestoSeleccionadoSel?{}:{border:"1px solid red"}}>
                                    <Select
                                        id="elegir-puesto"
                                        options={this.state.puestosSelect}
                                        className="pixelAlrededor"
                                        onChange={selPuestoEmp}
                                        isDisabled={this.state.editarPuestoActivado}
                                        backspaceRemovesValue={true}
                                        isClearable={true}
                                        value={this.state.puestosSelect.filter(puesto => puesto.value === (this.state.puestoSeleccionadoSel?this.state.puestoSeleccionadoSel.value:0))}

                                    />
                                </div>
                            </FormGroup>
                            {!this.state.editarPuestoActivado?
                                <Button 
                                    alignText="right"
                                    icon="add"
                                    className="iconoIzquierda"
                                    onClick={abrirModalNuevoPuestoDetalles}
                                />   
                            :<></>}
                                
                            <div id="fecha-alta">
                            <FormGroup
                                label="Fecha alta:"
                                labelFor="fecha-alta"
                            >
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    value={this.state.fechaAltaPuesto}
                                    onChange={escogeFechaAltaPuesto}
                                />
                            </FormGroup>
                            </div>
                            <div id="fecha-baja">
                            <FormGroup
                                label="Fecha baja:"
                                labelFor="fecha-baja"

                            >
                                <Checkbox checked={this.state.bolFechaBajaPuesto} onChange={handleEnabledChangeBolFechaBajaPuesto} />
                                <DateInput minDate={new Date(1900, 1 ,1)}
                                    {...jsDateFormatter} 
                                    value={this.state.fechaBajaPuesto}
                                    onChange={escogeFechaBajaPuesto} 
                                    disabled={!this.state.bolFechaBajaPuesto}
                                />
                            </FormGroup>
                            </div>
                            
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar modal" position="top">
                                <AnchorButton
                                    onClick={cerrarModal}
                                >
                                    Cerrar
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoPuestoActivado?"Crear puesto":"Actualizar puesto"} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoPuestoActivado?guardarPuestoEmpleado:actualizarPuestoEmpleado}
                                >
                                    {this.state.nuevoPuestoActivado?"Crear puesto":"Actualizar puesto"}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Nombre del puesto de trabajo"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPuestoDetalles}

                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="pixelAlrededor">
                            <FormGroup
                                label="Nombre del puesto:"
                                labelFor="nombre-puesto"
                                intent="danger"
                                helperText={this.state.nombrePuesto?"":"Debe rellenar el nombre del puesto"}
                            >
                                <InputGroup id="nombre-puesto"   intent={this.state.nombrePuesto?"primary":"danger"} onChange={handleCambiarNombrePuesto} className="pixelAlrededor" fill={true} />
                            </FormGroup>
                            <FormGroup
                                label="Grado de responsabilidad:"
                                labelFor="grado-resposabilidad"

                            >
                                <Slider 
                                    initialValue={this.state.riesgoSeleccionado}
                                    min={1}
                                    max={8}
                                    onChange={handleSliderResponsabilidad}
                                    onRelease={handleSliderResponsabilidad}
                                    value={this.state.gradoResponsabilidad}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Riesgo Objetivo:"
                                labelFor="riesgo-objetivo"

                            >
                                <Select
                                    options={this.state.riesgoOptions}
                                    className="pixelAlrededor"
                                    onChange={seleccionRiesgos}
                                    backspaceRemovesValue={true}
                                    isClearable={true}
                                    styles={estilosColorSelect}
                                    value={this.state.riesgoOptions.filter(riesgo => riesgo.value === (this.state.riesgoSeleccionado?this.state.riesgoSeleccionado.value:1))}
                                />
                            </FormGroup>
                            {/*<FormGroup
                                label="Código del puesto:"
                                labelFor="codigo-puesto"
                                intent="danger"
                                helperText={this.state.codigoPuesto?"":"Debe rellenar el nombre del puesto"}

                            >
                                <InputGroup intent={this.state.codigoPuesto?"primary":"danger"} onChange={handleCambiarCodigoPuesto} fill={true} />
                            </FormGroup>*/}
                            <FormGroup
                                label="Descripción breve:"
                                labelFor="descripcion-breve"

                            >
                                <InputGroup onChange={handleCambiarDescripcionBreve} fill={true} />
                            </FormGroup>
                            <FormGroup
                                label="Descripción detallada:"
                                labelFor="descripcion-detallada"

                            >
                                <TextArea onChange={handleCambiarDescripcionDetallada} fill={true} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar modal" position="top">
                                <AnchorButton
                                    onClick={cerrarModal2}
                                >
                                    Cerrar
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Crear Puesto." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={guardarPuesto}
                                >
                                    Crear Puesto
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Propiedades de empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="pixelAlrededor"
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")this.setState(this.state)}}
                    isOpen={this.state.modalEmpleadoAbierto}
                    style={{width: '80%' }}
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label="Empleado:"
                            labelFor="nombre-proyecto"
                            intent="danger"
                            className="pixelAlrededor"
                            helperText={!this.state.personaSeleccionada?"Debe seleccionar una persona.":""}
                        >
                            <div style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                <Select
                                    id="seleccionar-crear-empleado"
                                    options={this.state.personas}
                                    className="pixelAlrededor"
                                    onChange={seleccionPersona}
                                    value={this.state.personas.filter(persona => persona.value === (this.state.personaSeleccionada?this.state.personaSeleccionada.value:0))}
                                    isDisabled={this.state.editarEmpleadoActivado?true:false}
                                />
                            </div>
                            <ButtonGroup>
                                <Button icon="add" onClick={abrirModalPersona} active={this.state.nuevaPresonaActivado} disabled={this.state.editarEmpleadoActivado?true:false}/>
                                <Button icon="edit" onClick={abrirModalPersonaEditar} active={this.state.editarPersonaActivado}  />
                            </ButtonGroup>
                        </FormGroup>
                    </Card>
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Tabs
                            id="pestañas"
                            animate={this.animacion} 
                            onChange={this.cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                            renderActiveTabPanelOnly={false}
                            className="pixelAlrededor"
                        >
                            <Tab id="fechas" title="Fechas" panel={<EmpleadosFechas ref={(input) => { this.fechas= input; }} />} />
                            <Tab id="informacion-contractual" title="Información contractual" panel={<EmpleadosInformacionContractual ref={(input) => { this.informacion= input; }} />} />
                            <Tab id="retribucion" title="Retribución" panel={<EmpleadosRetribucion ref={(input) => { this.retribucion= input; }} />} />
                            <Tab id="formacion" title="Formación y conocimientos" panel={<EmpleadosFormacion ref={(input) => { this.formacion= input; }} />} />
                            <Tab id="epis" title="Epis" panel={<Epis ref={(input) => { this.epis= input; }} />} />
                            <Tab id="experiencia" title="Experiencia Laboral" panel={<EmpleadosExperiencia ref={(input) => { this.experiencia= input; }}  />} />
                            <Tab id="idiomas" title="Idiomas" panel={<EmpleadosIdiomas ref={(input) => { this.idiomas= input; }}/>} />
                            <Tab id="irpf" title="IRPF" panel={<EmpleadosIRPF ref={(input) => { this.irpf= input; }}/>} />
                            <Tab id="datos-medicos" title="Datos Medicos" panel={<EmpleadosDatosMedicos ref={(input) => { this.datosMedicos= input; }} />} />
                        </Tabs>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip content="Listado de trabajadores" position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    disabled={this.state.empleadoSeleccionado?this.state.editarEmpleadoActivado && this.state.empleadoSeleccionado.fechaFin?true:false:false}
                                    onClick={this.state.nuevaEmpleadoActivado?anadirEmpleado:actualizarEmpleado} 
                                >
                                {this.state.nuevaEmpleadoActivado?"Añadir Trabajador":"Actualizar trabajador"} 
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas  i18next={i18next} datosPersona={this.state.personaSeleccionada?this.state.personaSeleccionada.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal2}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPresonaActivado?"Añadir persona.":"Actualizar persona."} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                            >
                            {this.state.nuevaPresonaActivado?"Añadir persona":"actualizar Persona"} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar una puesto"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalPuestoBorrar}
                >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea eliminar el puesto seleccionado?</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar puesto." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarPuesto}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal2}
                    title="Dar de baja empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAdvertenciaBaja}
                >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea de asignar el trabajador a un nuevo puesto de trabajo? Si realiza esta acción el empleado será dado de baja automáticamente de su anterior puesto de trabajo</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Dar de baja empleado." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={finalizarPuesto}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal2}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal2}
                    title="Dar de baja empleado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBajaFinal}
                >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea dar de baja al empleado? Si realiza esta acción no podrá modificar el los datos del empleado ni asignarlo a ningún puesto de trabajo</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Dar de baja empleado." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={darBajaEmpleado}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal2}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirT}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title="Imprimir Trabajadores"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirT}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-t">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirC}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title="Imprimir Competencias por trabajador"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirC}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-c">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirPI}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title="Imprimir Puestos ocupados por trabajador"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPI}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-pi">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimirPG}
                    /* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
                    title="Imprimir Todos los puestos ocupados por trabajadores"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPG}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="informe-pdf-pg">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                {this.state.empleadoSeleccionado && (
                  <Evaluacion empleado={this.state.empleadoSeleccionado} setModalEvaluacion={this.setModalEvaluacion} abierto={this.state.modalEvaluacionAbierto} ref={(input) => { this.modalEvaluacion= input; }} modal={true} />
                )}
                
                {this.state.cargando === true?
                  <Cargando />
                :

                <div id="contenedor-recursos-humanos">
                    <div className="pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Trabajadores</Callout>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                
                                        <React.Fragment key="fragment">
                                            <Tooltip content="Añadir un nuevo empleado" position={Position.RIGHT}>
                                                <Button className="bp3-minimal" id="crear-empleado" icon="add" active={this.state.nuevaEmpleadoActivado} onClick={abrirModalEmpleadoNuevo} />
                                            </Tooltip>
                                            <Tooltip content="Modificar el empleado selecionado" position={Position.RIGHT}>
                                                <Button className="bp3-minimal" icon="edit" active={this.state.editarEmpleadoActivado} onClick={abrirModalEmpleadoEditar}/>
                                            </Tooltip>
                                            <Tooltip content="Eliminar el empleado selecionado" position={Position.RIGHT}>
                                                <Button className="bp3-minimal" icon="delete" active={this.state.borrarEmpleadoActivado} onClick={abrirModalEliminarEmpleados}/>
                                            </Tooltip>
                                            <NavbarDivider/>
                                            <Tooltip content="Crear evaluación del empleado selecionado" position={Position.RIGHT}>
                                                <Button className="bp3-minimal" icon="add-to-artifact" active={this.state.evaluacionActivado} onClick={abrirModalEvaluacion} />
                                            </Tooltip>
                                            <NavbarDivider/>
                                            {this.state.cargandoCsv===false?
                                                <Tooltip content="Imprimir el listado actual" position={Position.RIGHT}>
                                                    <Button key="botonImprimir" className="bp3-minimal" icon="print" text="" id="boton-imprimir" onClick={() => this.generarInformeT()} />
                                                </Tooltip>
                                            :
                                                <Button
                                                    className="bp3-minimal"
                                                    icon="print"
                                                    text="Cargando..."
                                                />
                                            }
                                        <NavbarDivider/>
                                            <CSVLink ref={(r) => { this.Csv = r; }} data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"Empleados.csv"}></CSVLink>
                                            {this.state.cargandoCsv===false?
                                                <Tooltip content="Exportar el listado de trabajadores" position={Position.RIGHT}>
                                                <Button
                                                    className="bp3-minimal"
                                                    icon="document"
                                                    onClick={() => this.generarCsv()}
                                                >
                                                </Button>
                                                </Tooltip>
                                            :
                                                <Button
                                                    className="bp3-minimal"
                                                    icon="document"
                                                    text="Cargando..."
                                                />
                                            }

                                        </React.Fragment>
                                
                                    <NavbarDivider/>
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <div className="pixelAlrededor">
                                {this.state.cargandoEmpleados?
                                <Cargando />
                                :
                                    <>
                                        <BootstrapTable
                                            keyField='ID_EMPLEADO'
                                            data={ this.state.empleados }
                                            columns={ columns }
                                            selectRow={ const_table.selectRow(handleOnSelectEmpleado) }
                                            filter={ filterFactory() }
                                            headerClasses="header-class"
                                            rowClasses="row-class"
                                            hover
                                            condensed
                                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                        />
                                        <div className="centrar2">
                                            {this.state.totalRegistros>10?
                                                <ButtonGroup className="pixelAlrededorGrande">
                                                <Button icon="chevron-left" onClick={this.irPrimerPagina} disabled={this.state.indiceRegistros===0?true:false}></Button>
                                                    {this.botonesPaginacion()}
                                                <Button icon="chevron-right" onClick={this.irUltimaPagina} disabled={this.state.indiceRegistros===Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1?true:false}></Button>
                                            </ButtonGroup> 
                                            :<></>}
                                        </div>
                                    </>
                                }    
                            </div>
                        </Card>
                    </div>
                    <div className="tablaMitadIzquierda pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="bp3-intent-danger">
                            {this.state.empleadoSeleccionado?`Puestos ocupados por ${this.state.nombreSeleccionado}`:"Seleccione un trabajador para ver los puestos ocupados."}
                        </Callout>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <Tooltip content="Añadir un nuevo puesto" position={Position.RIGHT}>
                                        <Button className="bp3-minimal" icon="add" id="añadir-puesto" active={this.state.nuevoPuestoActivado} onClick={abrirModalNuevoPuesto} />
                                    </Tooltip>
                                    <Tooltip content="Modificar el puesto selecionado" position={Position.RIGHT}>
                                        <Button className="bp3-minimal" icon="edit" active={this.state.editarPuestoActivado} onClick={abrirModalEditarPuesto}/>
                                    </Tooltip>
                                    <Tooltip content="Desea eliminar el puesto seleccionado" position={Position.RIGHT} >
                                        <Button className="bp3-minimal" icon="delete" active={this.state.borrarPuestoActivado} onClick={abrirModalBorrarPuesto}/>
                                    </Tooltip>
                                    <Tooltip content="Desea imprimir el puesto del empleado seleccionado" position={Position.RIGHT} >
                                        <Button className="bp3-minimal" icon="print" text="Imprimir" active={this.state.imprimirPIActivado} onClick={() => this.generarInformePI()}/>
                                    </Tooltip>
                                    <Tooltip content="Desea imprimir todos los puestos" position={Position.RIGHT} >
                                        <Button className="bp3-minimal" icon="print"  text="Imprimir General" active={this.state.imprimirPGActivado} onClick={() => this.generarInformePG()}/>
                                    </Tooltip>
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <div className=" pixelAlrededor">
                            {this.state.cargandoPuestos?
                                <Cargando />
                            :
                                <BootstrapTable
                                    keyField='id'
                                    data={ this.state.puestos }
                                    columns={ columnsPuesto }
                                    selectRow={ const_table.selectRow(handleOnSelectPuesto) }
                                    pagination={ paginationFactory(const_table.options) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    condensed
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            }
                        </div>
                    </Card>
                    
                  </div>
                  <div className="tablaMitadDerecha pixelAlrededor">
                      <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="bp3-intent-danger">
                            {this.state.empleadoSeleccionado?`Ver competencias de ${this.state.nombreSeleccionado}`:"Seleccione un trabajador para ver sus competencias."}
                        </Callout>
                        {this.state.empleadoSeleccionado?
                        <div id="listado-competencias"> 
                                {!this.state.cargandoCompetencia?
                                <div  className={Classes.DRAWER_BODY + " pixelAlrededorGrande"}>
                                    {this.state.competencias.length>0?
                                        <>
                                            <Button
                                                alignText="left"
                                                icon="floppy-disk"
                                                text="Guardar"
                                                intent="danger"
                                                onClick={guardarCompetencias}
                                                className="iconoIzquierda bp3-minimal"
                                            />
                                            <Button
                                                alignText="left"
                                                icon="cross"
                                                text="Cancelar"
                                                intent="danger"
                                                onClick={cancelarCompetencias}
                                                className="iconoIzquierda bp3-minimal"
                                            />
                                            <Button
                                                alignText="left"
                                                icon="print"
                                                text="Imprimir"
                                                intent="danger"
                                                onClick={()=>this.generarInformeC()}
                                                className="iconoIzquierda bp3-minimal"
                                            />
                                        </>
                                    :<></>}
                                    <Competencias className="pixelAlrededor" />
                                </div>
                                :
                                <Spinner />
                              }
                            
                        </div>
                        :
                                <></>
                              }
                      </Card>
                    </div>
                </div>
              }
            </>
        )
    }
}

export default withApollo(RecursosHumanos)