import React from "react"
import { RadioGroup, Radio, NumericInput,Tooltip,AnchorButton,Intent,Button,Classes,Dialog } from "@blueprintjs/core"
import Select from "react-select"
class Periodicidad extends React.Component {

    state={
        radio: "1",
        radio2:"1",
        numeroDia: 1,
        numeroMes:1,
        periodicidad:null,
        selectOrdinales: [
            {value: this.props.i18next.t("identificacion.primero",{ns:"page"}), label: "primero"},
            {value: this.props.i18next.t("identificacion.segundo",{ns:"page"}), label: "segundo"},
            {value: this.props.i18next.t("identificacion.tercero",{ns:"page"}), label: "tercero"},
            {value: this.props.i18next.t("identificacion.cuarto",{ns:"page"}), label: "cuarto"},
            {value: this.props.i18next.t("identificacion.ultimo",{ns:"page"}), label: "último"},
        ],
        ordinalSeleccionado: null,
        mesesSeleccionados: [],
        meses:[
            {"label":this.props.i18next.t("identificacion.enero",{ns:"page"}),"value":"enero"},
            {"label":this.props.i18next.t("identificacion.febrero",{ns:"page"}),"value":"febrero"},
            {"label":this.props.i18next.t("identificacion.marzo",{ns:"page"}),"value":"marzo"},
            {"label":this.props.i18next.t("identificacion.abril",{ns:"page"}),"value":"abril"},
            {"label":this.props.i18next.t("identificacion.mayo",{ns:"page"}),"value":"mayo"},
            {"label":this.props.i18next.t("identificacion.junio",{ns:"page"}),"value":"junio"},
            {"label":this.props.i18next.t("identificacion.julio",{ns:"page"}),"value":"julio"},
            {"label":this.props.i18next.t("identificacion.agosto",{ns:"page"}),"value":"agosto"},
            {"label":this.props.i18next.t("identificacion.septiembre",{ns:"page"}),"value":"septiembre"},
            {"label":this.props.i18next.t("identificacion.octubre",{ns:"page"}),"value":"octubre"},
            {"label":this.props.i18next.t("identificacion.noviembre",{ns:"page"}),"value":"noviembre"},
            {"label":this.props.i18next.t("identificacion.diciembre",{ns:"page"}),"value":"diciembre"},
        ],
        selectDiasSemana:[
            {"label":this.props.i18next.t("identificacion.lunes",{ns:"page"}),"value":"lunes"},
            {"label":this.props.i18next.t("identificacion.martes",{ns:"page"}),"value":"martes"},
            {"label":this.props.i18next.t("identificacion.miercoles",{ns:"page"}),"value":"miércoles"},
            {"label":this.props.i18next.t("identificacion.jueves",{ns:"page"}),"value":"jueves"},
            {"label":this.props.i18next.t("identificacion.viernes",{ns:"page"}),"value":"viernes"},
            {"label":this.props.i18next.t("identificacion.sabado",{ns:"page"}),"value":"sábado"},
            {"label":this.props.i18next.t("identificacion.domingo",{ns:"page"}),"value":"domingo"},
        ],
        relacionEmpresaDenunciado:null,
        diaSemana:null,
        valido1:true,
        valido2:true,
        cadena:"",
        modalAbierto:false,
        fecha:""
    }

    componentDidUpdate(props,state){


        if(this.props!==props){
            if(this.props.abierto){
                this.setState({modalAbierto:true})
            }
        }

        if(state!==this.state){
         

            //validacion grupo 1
            if(this.state.radio==="1" && this.state.numeroDia!==state.numeroDia){
                if(this.state.numeroDia>0 && this.state.numeroDia<=31){
                    this.setState({valido1:true})

                }else{
                    this.setState({valido1:false})

                }
            }
            else if((this.state.radio==="2" && this.state.ordinalSeleccionado!==state.ordinalSeleccionado) || (this.state.diaSemana!==state.diaSemana)){
                if(this.state.ordinalSeleccionado && this.state.diaSemana){
                    this.setState({valido1:true})
                }else{
                    this.setState({valido1:false})
                }
               
            }
            else if(this.state.radio!==state.radio){
               if(this.state.radio==="1"){
                    this.setState({valido1:true})
               }else{
                    this.setState({valido1:false})
               }
                
            } 
            


            //validacion grupo 2
            if(this.state.radio2==="2" && state.mesesSeleccionados!==this.state.mesesSeleccionados){
               
                if(this.state.mesesSeleccionados && this.state.mesesSeleccionados.length>0){
                    this.setState({valido2:true})
                }else{
                    this.setState({valido2:false})
                }
               
            }else if(this.state.radio2==="1" && this.state.numeroMes!==state.numeroMes){
                if(this.state.numeroMes>0 && this.state.numeroMes<=12){
                    this.setState({valido2:true})
                }else{
                    this.setState({valido2:false})
                }
            }else if(this.state.radio2!==state.radio2){
                if(this.state.radio2==="1"){
                     this.setState({valido2:true})
                }else{
                     this.setState({valido2:false})
                }
                 
             } 

        }

    }

    componentWillReceiveProps(props){

        //si tenemos periodicidad, la mostramos en el panel.
        if(this.props.periodicidad){
            
            let periodicidad=JSON.parse(this.props.periodicidad)

            if(periodicidad.dia){
                this.setState({radio:"1"})
                this.setState({numeroDia:periodicidad.dia})
                this.setState({diaSemana:null})
                this.setState({ordinalSeleccionado:null})

            }
            if(periodicidad.diaSemana){
                this.setState({radio:"2"})

                let diaSel=this.state.selectDiasSemana.find(dia=>{return dia.value===periodicidad.diaSemana})
                this.setState({diaSemana:diaSel})

                let ordinalSel=this.state.selectOrdinales.find(ordinal=>{return ordinal.value===periodicidad.ordinal})
                this.setState({ordinalSeleccionado:ordinalSel})

                this.setState({numeroDia:null})
            }
            if(periodicidad.numeroMes){
                this.setState({radio2:"1"})
                this.setState({numeroMes:periodicidad.numeroMes})
                this.setState({mesesSeleccionados:[]})
            }
            if(periodicidad.meseseSeleccionados && periodicidad.meseseSeleccionados.length>0){
                this.setState({radio2:"2"})
                this.setState({mesesSeleccionados:periodicidad.meseseSeleccionados})
                this.setState({numeroMes:null})
            }
        }   
    }

    render(){

        const handleRadioChange = (e) => {
            this.setState({radio: e.target.value})

            if(parseInt(e.target.value)===2){
                this.setState({numeroDia:null})
                this.setState({ordinalSeleccionado:null})
                this.setState({diaSemana:null})
            }else if(parseInt(e.target.value)===1){
                this.setState({ordinalSeleccionado:null})
                this.setState({diaSemana:null})
                this.setState({numeroDia:1})
            }

        }

        const handleRadioChange2 = (e) => {
            this.setState({radio2: e.target.value})
            if(parseInt(e.target.value===2)){
                this.setState({numeroMes:null})
                this.setState({mesesSeleccionados:[]})

            }else if(parseInt(e.target.value)===1){
                this.setState({mesesSeleccionados:[]})
                this.setState({numeroMes:1})

            }
        }

        const handleElDia = (e) => {
            this.setState({numeroDia: e})
        }

        const onSeleccionarOrdinal=(value)=>{
            this.setState({ordinalSeleccionado: value})
        }
        
        const onSeleccionarDiaSemenas=(value)=>{
            this.setState({diaSemana: value})
        }

        const handleFrecuenciaMes = (e) => {
            this.setState({numeroMes: e})
        }

        const onSeleccionarMes=(value)=>{
            this.setState({mesesSeleccionados: value})
        }

        const cerrarModal=()=>{
            this.setState({modalAbierto:false})
            this.props.cerrarModal()
        }

        const obtenerFecha=()=>{
            let fechaSel=`{"dia":"${this.state.numeroDia}","ordinal":"${this.state.ordinalSeleccionado?this.state.ordinalSeleccionado.value:""}","diaSemana":"${this.state.diaSemana?this.state.diaSemana.value:""}","numeroMes":${this.state.numeroMes},"meseseSeleccionados":${JSON.stringify(this.state.mesesSeleccionados)}}`
            this.setState({fecha:fechaSel})
            this.setState({modalAbierto:false})
            this.props.setPeriodicidad(fechaSel)
            this.props.cerrarModal()

        }
        return (
            <>
                   <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="time"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("identificacion.titleperiodicidad",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                    className="pixelAlrededor"
                >
                    <div className={Classes.DIALOG_BODY}></div>
                <table cellSpacing="0" cellPadding="0" className="bp3-html-table bp3-html-table-striped bp3-interactive">
                    <tbody>
                <tr>
                    <th rowSpan="4">
                        <RadioGroup
                            onChange={handleRadioChange}
                            selectedValue={this.state.radio}
                            
                        >
                            <br />
                            <Radio
                                label={this.props.i18next.t("identificacion.eldia",{ns:"page"})}
                                value="1"
                            />
                            <br />
                            <Radio
                                label={this.props.i18next.t("identificacion.el",{ns:"page"})}
                                value="2"
                            />
                            <br />
                        </RadioGroup>
                        
                        <RadioGroup
                            onChange={handleRadioChange2}
                            selectedValue={this.state.radio2}
                        >
                            <br />
                            <Radio
                                label={this.props.i18next.t("identificacion.decada",{ns:"page"})}
                                value="1"
                            />
                            <br />
                            <Radio
                                label={this.props.i18next.t("identificacion.delosmeses",{ns:"page"})}
                                value="2"
                            />
                        </RadioGroup>
                    </th>
                    <th colSpan="2">
                        {this.state.radio === "1"? 
                            <NumericInput min={1} max={31} onValueChange={handleElDia} value={this.state.numeroDia} /> 
                            :
                            <NumericInput disabled />
                        }
                    </th>
                </tr>
                <tr>
                    <td>
                        {this.state.radio === "2"? 
                            <Select
                                options={this.state.selectOrdinales}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={onSeleccionarOrdinal}
                                value={this.state.ordinalSeleccionado}
                            />
                            :
                            <Select 
                                isDisabled={true}
                            />
                        }
                    </td>
                    <td>
                        {this.state.radio === "2"? 
                            <Select 
                                options={this.state.selectDiasSemana}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                onChange={onSeleccionarDiaSemenas}
                                value={this.state.diaSemana}

                            />
                            :
                            <Select 
                                isDisabled={true}
                            />
                        }
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {this.state.radio2 === "1"? 
                            <NumericInput min={1} max={12} onValueChange={handleFrecuenciaMes} value={this.state.numeroMes} /> 
                            :
                            <NumericInput disabled />
                        }
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {this.state.radio2 === "2"?
                            <Select 
                                isMulti
                                isClearable
                                isSearchable
                                options={this.state.meses}
                                onChange={onSeleccionarMes}
                                backspaceRemovesValue={true}
                                value={this.state.mesesSeleccionados}
                            />
                            :
                            <Select 
                                isDisabled={true}
                            />
                        }
                    </td>
                </tr>
                </tbody>
                </table>    
                        
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("aceptar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                disabled={(this.state.valido1 && this.state.valido2)?false:true}
                                onClick={obtenerFecha}
                            >
                                {this.props.i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            </>
        )
    }
}

export default Periodicidad;