import React, {Component} from 'react';
import {
    Tab,
    Tabs
} from "@blueprintjs/core";
import {withApollo} from 'react-apollo';
import gql from "graphql-tag";
import { Cargando } from "../../Pantallas/Cargando";
import ContenidoProcedimientos from "./ContenidoProcedimientos";
import i18next from 'i18next';


const OPT_CONTEXTO = 1;
const OPT_OBLIGACIONES = 2;
const OPT_CONTRATOS = 3;
const OPT_RIESGOS_PENALES = 4;
const OPT_DIGITAL = 5;
const OPT_BLANQUEOS = 6;
const OPT_SERVICIOS = 7;

class DatosProcedimientos extends Component {

    state={
        cargando:true,
        datoSeleccionado:null,
        datosEmpresa:null,
        logotipo:null,
        animacion: true,
        pestania: "tab-contexto",
    }

    async componentDidMount() {
        await this.asignarPestania();
        await this.obtenerLogoEmpresa();
        await this.cargarDatos();
        this.setState({
            cargando:false,
            datoSeleccionado:this.props.dato
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.bloqueSeleccionado!==this.props.bloqueSeleccionado) {
            this.setState({
                cargando:true,
                resizedContextos:false,
                resizedObligaciones:false,
                resizedContratos:false,
                resizedRiesgosPenales:false,
                resizedDigital:false,
                resizedBlanqueos:false,
                resizedServicios:false
            },async ()=>{
                await this.asignarPestania();
                this.setState({
                    cargando:false
                })
            })
        } else {
            this.setState({
                datoSeleccionado:nextProps.dato
            })
        }
    }

    asignarPestania=async()=>{
        let pestania="";
        let idBloque=this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.ID_BLOQUE:null;
        switch(idBloque){
            case OPT_CONTEXTO:
                pestania= "tab-contexto";
                break;
            case OPT_OBLIGACIONES:
                pestania= "tab-municipales";
                break;
            case OPT_CONTRATOS:
                pestania= "tab-privados";
                break;
            case OPT_RIESGOS_PENALES:
                pestania= "tab-economicos";
                break;
            case OPT_DIGITAL:
                pestania= "tab-ciberseguridad";
                break;
            case OPT_BLANQUEOS:
                pestania= "tab-ocultacion";
                break;
            case OPT_SERVICIOS:
                pestania= "tab-subcontratacion";
                break;
            default:
                pestania= "tab-contexto";
                break;
        }
        this.setState({
            pestania: pestania
        });
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    
    cambiarPestania = (navbarTabId) => this.setState({ pestania: navbarTabId });

    
    render() {

        const getContextos=()=>{
            return (
                <>
                    <Tabs id="tabs-contextos"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-contexto" title={i18next.t("procedimientosCompliance.context", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="contexto" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabContexto= input; }}  />} />
                        <Tab id="tab-licencias" title={i18next.t("procedimientosCompliance.licenses", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="licencias" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabLicencias= input; }}  />} />
                        <Tab id="tab-competividad" title={i18next.t("procedimientosCompliance.competitiveness", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="competividad" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabCompetividad= input; }} />} />
                        <Tab id="tab-procedimientos_operativos" title={i18next.t("procedimientosCompliance.operationalProcedures", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="procedimientos operativos" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOperativos= input; }} />} />
                        <Tab id="tab-otros_contextos" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otros contextos" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrosProcedimientos= input; }}  />} />
                    </Tabs>
                </>
            )

        }

        const getObligaciones=()=>{
            return (
                <>
                    <Tabs id="tabs-obligaciones"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-municipales" title={i18next.t("procedimientosCompliance.municipal", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="municipales" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabMunicipales= input; }}  />} />
                        <Tab id="tab-provinciales" title={i18next.t("procedimientosCompliance.provincial", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="provinciales" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabProvinciales= input; }}  />} />
                        <Tab id="tab-autonomicas" title={i18next.t("procedimientosCompliance.autonomous", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="autonomicas" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabAutonomicas= input; }} />} />
                        <Tab id="tab-nacionales" title={i18next.t("procedimientosCompliance.national", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="nacionales" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabNacionales= input; }} />} />
                        <Tab id="tab-comunitarias" title={i18next.t("procedimientosCompliance.community", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="comunitarias" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabComunitarias= input; }} />} />
                        <Tab id="tab-sectoriales" title={i18next.t("procedimientosCompliance.sectoral", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="sectoriales" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabSectoriales= input; }} />} />
                        <Tab id="tab-otras_obligaciones" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otras obligaciones" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrasObligaciones= input; }}  />} />
                    </Tabs>
                </>
            )

        }

        const getContratos=()=>{
            return (
                <>
                    <Tabs id="tabs-contratos"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-privados" title={i18next.t("procedimientosCompliance.private", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="privados" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabPrivados= input; }}  />} />
                        <Tab id="tab-publicos" title={i18next.t("procedimientosCompliance.public", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="publicos" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabPublicos= input; }}  />} />
                        <Tab id="tab-consocios" title={i18next.t("procedimientosCompliance.withPartners", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="con socios" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabConSocios= input; }} />} />
                        <Tab id="tab-conadministradores" title={i18next.t("procedimientosCompliance.withAdministrators", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="con administradores" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabConAdministradores= input; }} />} />
                        <Tab id="tab-dealtadireccion" title={i18next.t("procedimientosCompliance.seniorManagement", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="de alta direccion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabDeAltaDireccion= input; }} />} />
                        <Tab id="tab-internacionales" title={i18next.t("procedimientosCompliance.international", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="internacionales" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabInternacionales= input; }} />} />
                        <Tab id="tab-otros_contratos" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otros contratos" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrosContratos= input; }}  />} />
                    </Tabs>
                </>
            )

        }

        const getRiesgosPenales=()=>{
            return (
                <>
                    <Tabs id="tabs-riesgos_penales"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-economicos" title={i18next.t("procedimientosCompliance.economic", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="economicos" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabEconomicos= input; }}  />} />
                        <Tab id="tab-seguridad" title={i18next.t("procedimientosCompliance.workerSecurity", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="seguridad" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabSeguridad= input; }}  />} />
                        <Tab id="tab-urbanismo" title={i18next.t("procedimientosCompliance.urbanPlanning", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="urbanismo" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabUrbanismo= input; }} />} />
                        <Tab id="tab-medio_ambiente" title={i18next.t("procedimientosCompliance.environment", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="medio ambiente" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabMedioAmbiente= input; }} />} />
                        <Tab id="tab-saludpublica" title={i18next.t("procedimientosCompliance.publicHealth", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="salud publica" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabSaludPublica= input; }} />} />
                        <Tab id="tab-administracion" title={i18next.t("procedimientosCompliance.administration", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="administracion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabAdministracion= input; }}  />} />
                        <Tab id="tab-otrosriesgospenales" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otros riesgos penales" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrosRiesgosPenales= input; }}  />} />
                    </Tabs>
                </>
            )

        }

        const getDigital=()=>{
            return (
                <>
                    <Tabs id="tabs-digital"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-ciberseguridad" title={i18next.t("procedimientosCompliance.cybersecurity", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="ciberseguridad" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabCiberseguridad= input; }}  />} />
                        <Tab id="tab-lopd" title={i18next.t("procedimientosCompliance.lopd", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="lopd" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabLopd= input; }}  />} />
                        <Tab id="tab-lssiyce" title={i18next.t("procedimientosCompliance.electronicCommerce", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="lssi y ce" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabLSSIYCE= input; }} />} />
                        <Tab id="tab-marcas_dominios" title={i18next.t("procedimientosCompliance.trademarksAndDomains", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="marcas y dominios" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabMarcasDominios= input; }} />} />
                        <Tab id="tab-otrosdigital" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otros digital" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrosDigital= input; }}  />} />
                    </Tabs>
                </>
            )

        }

        const getBlanqueos=()=>{
            return (
                <>
                    <Tabs id="tabs-blanqueos"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-ocultacion" title={i18next.t("procedimientosCompliance.concealment", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="ocultacion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabOcultacion= input; }}  />} />
                        <Tab id="tab-adquisicion" title={i18next.t("procedimientosCompliance.acquisition", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="adquisicion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabAdquisicion= input; }}  />} />
                        <Tab id="tab-conversion" title={i18next.t("procedimientosCompliance.conversion", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="conversion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabConversion= input; }} />} />
                        <Tab id="tab-inversion" title={i18next.t("procedimientosCompliance.investment", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="inversion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabInversion= input; }} />} />
                        <Tab id="tab-suministro" title={i18next.t("procedimientosCompliance.supply", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="suministro" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabSuministro= input; }} />} />
                        <Tab id="tab-distribucion" title={i18next.t("procedimientosCompliance.distribution", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="distribucion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabDistribucion= input; }} />} />
                        <Tab id="tab-otrosblanqueos" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otros blanqueos" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrosBlanqueos= input; }}  />} />
                    </Tabs>
                </>
            )

        }

        const getServicios=()=>{
            return (
                <>
                    <Tabs id="tabs-servicios"
                        animate={this.animacion} 
                        onChange={this.cambiarPestania} 
                        selectedTabId={this.state.pestania}
                        renderActiveTabPanelOnly={true}
                    >
                        <Tab id="tab-subcontratacion" title={i18next.t("procedimientosCompliance.subcontracting", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="subcontratacion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa}  ref={(input) => { this.tabSubcontratacion= input; }}  />} />
                        <Tab id="tab-etts" title={i18next.t("procedimientosCompliance.tempAgencies", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="etts" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabEtts= input; }}  />} />
                        <Tab id="tab-cesion" title={i18next.t("procedimientosCompliance.workerAssignment", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="cesion" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabCesion= input; }} />} />
                        <Tab id="tab-subrogaciones" title={i18next.t("procedimientosCompliance.subrogations", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="subrogaciones" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabSubrogaciones= input; }} />} />
                        <Tab id="tab-otrosservicios" title={i18next.t("procedimientosCompliance.others", { ns: "page2" })} panel={<ContenidoProcedimientos editable={this.props.editable} tipoProcedimiento="otros servicios" bloqueSeleccionado={this.props.bloqueSeleccionado?this.props.bloqueSeleccionado:null} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.tabOtrosServicios= input; }}  />} />
                    </Tabs>
                </>
            )

        }
        
        

        if (this.state.cargando) {
            return <Cargando/>
        } else {
            let idBloque=this.props.bloqueSeleccionado?this.props.bloqueSeleccionado.ID_BLOQUE:null;
            switch(idBloque){
                case OPT_CONTEXTO:
                    return getContextos();
                case OPT_OBLIGACIONES:
                    return getObligaciones();
                case OPT_CONTRATOS:
                    return getContratos();
                case OPT_RIESGOS_PENALES:
                    return getRiesgosPenales();
                case OPT_DIGITAL:
                    return getDigital();
                case OPT_BLANQUEOS:
                    return getBlanqueos();
                case OPT_SERVICIOS:
                    return getServicios();
                default:
                    return null;
            }
        }
    }

}

export default withApollo(DatosProcedimientos);
