import React, { Component } from "react";
import { Callout, TextArea, Button, Card, Elevation, Tabs, Tab, Dialog, Spinner } from "@blueprintjs/core";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import axios from 'axios';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import moment from 'moment';
import iconoFooter from '../../Assets/lc.png';
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import open_sans_bold from '../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'
import i18next from "i18next";
import { withTranslation } from "react-i18next";
Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

// Create styles
const styles = StyleSheet.create({
      dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:135,
    paddingTop:30,
    paddingLeft: 25,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
  rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#000000',
    padding:2,
    
  },
  textData: {
    flexDirection: 'column',
    fontSize:10,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexWrap: 'wrap',
    width: '90%'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize: 11,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  labelTitleOne  : {
    flexDirection: 'column',
    fontSize:13,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  }
  
})

class ProcesosTomaDecisiones extends Component {
state = {
	modalImprimirE:false,
	cargandoImprimirE:false,
	modalImprimirO:false,
	cargandoImprimiO:false,
	modalImprimirT:false,
	cargandoImprimirT:false,
	logotipo:null,
	datosEmpresa:null,
	idPersona: null,
	valEstrategica: '',
	valOperativa: '',
	valTactica: '',
	editorState: {
	options: [
		"inline",
		"fontFamily",
		"list",
		"textAlign",
		"link",
		"embedded",
		"image",
		"remove",
		"history"
	]
	},
	toolbarState: {
	options: [
		"inline",
		"list",
		"textAlign",
		"colorPicker",
		"link",
		"embedded",
		"history"
	],
	inline: {
		options: ["bold", "italic", "underline"]
	}
	},
	adjuntoEstrategicas:null,
	adjuntoOperativas:null,
	adjuntoTacticas:null
};

 


componentDidMount=()=>{

	this.obtenerLogoEmpresa();
	
}

cargarDatosEmpresa=async()=>{

	//DATOS JURIDICOS
	let datosEmpresa= await this.props.client
	.query({
		query: gql`
		{
			personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
		}
		`,
		fetchPolicy: 'network-only'

	}).then(result=>{
		//console.log(result.data.personasJuridicas[0]);
		//si tenemos razon social.
		if(result.data.personasJuridicas.length>0){
			return {
				"razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
			}

		}
		
	})

	
	
	return datosEmpresa
}

obtenerLogoEmpresa=async()=>{

	//obtencion logo de la empresa
	await this.props.client 
	.query({
		query: gql`
			{adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
		`,fetchPolicy:'network-only'             
	}).then(result=>{ 
		if(result.data.adjuntos.length>0){

			var request = require('request').defaults({ encoding: null,withCredentials:true });

			request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
				if (!error && response.statusCode === 200) {
				let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
				this.setState({logotipo:data})
				}
			});
		
		}
	})

}

InformeE= () => (
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{i18next.t("tomadecisiones.title",{ns:"page"}).toUpperCase()}</Text>
            </View>
            <View fixed style={styles.rowTitleOne}>
                <Text style={styles.labelTitleOne}>{i18next.t("tomadecisiones.titleestrategicas",{ns:"page"})}</Text>
            </View>
            <View style={styles.row}>
			  <Text style={styles.textData}>{this.state.valEstrategica}</Text>    
            </View>
        </View>
        <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
			fixed
          />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
		<Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
	  </Page>
    </Document>
  </PDFViewer>
)

generarInformeE =async () => {
	this.setState({
		modalImprimirE: true,
		cargandoImprimirE:true
	}, async () => {
		if (this.state.datosEmpresa==null) {
			let datosEmpresa=await this.cargarDatosEmpresa();
			this.setState({
				cargandoImprimirE:false,
				datosEmpresa:datosEmpresa
			},()=>{
				ReactDOM.render(this.InformeE(), document.getElementById('informe-pdf-e'))
			})
		} else {
			this.setState({
				cargandoImprimirE:false,
			},()=>{
				ReactDOM.render(this.InformeE(), document.getElementById('informe-pdf-e'))
			})
		}
	})
	
}

InformeO= () => (
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{i18next.t("tomadecisiones.title",{ns:"page"}).toUpperCase()}</Text>
            </View>
            <View fixed style={styles.rowTitleOne}>
                <Text style={styles.labelTitleOne}>{i18next.t("tomadecisiones.titleoperativas",{ns:"page"})}</Text>
            </View>
            <View style={styles.row}>
			  <Text style={styles.textData}>{this.state.valOperativa}</Text>    
            </View>
        </View>
        <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
			fixed
          />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
		<Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
	  </Page>
    </Document>
  </PDFViewer>
)

generarInformeO =async () => {
	this.setState({
		modalImprimirO: true,
		cargandoImprimirO:true
	}, async () => {
		if (this.state.datosEmpresa==null) {
			let datosEmpresa=await this.cargarDatosEmpresa();
			this.setState({
				cargandoImprimirO:false,
				datosEmpresa:datosEmpresa
			},()=>{
				ReactDOM.render(this.InformeO(), document.getElementById('informe-pdf-o'))
			})
		} else {
			this.setState({
				cargandoImprimirO:false,
			},()=>{
				ReactDOM.render(this.InformeO(), document.getElementById('informe-pdf-o'))
			})
		}
	})
	
}

InformeT= () => (
    <PDFViewer style={{width: '100%', height: '100%'}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
            <View fixed style={styles.row}>
              <Image
                style={styles.imageHead}
                src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                />
            </View>
            <View fixed style={styles.rowHead}>
                <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
            </View>
            <View fixed style={styles.rowHeadTwo}>
                <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                <Text style={styles.text}>{i18next.t("tomadecisiones.title",{ns:"page"}).toUpperCase()}</Text>
            </View>
            <View fixed style={styles.rowTitleOne}>
                <Text style={styles.labelTitleOne}>{i18next.t("tomadecisiones.titletacticas",{ns:"page"})}</Text>
            </View>
            <View style={styles.row}>
			  <Text style={styles.textData}>{this.state.valTactica}</Text>    
            </View>
        </View>
        <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
			fixed
          />
        <Image
                style={styles.icono}
                src={iconoFooter}
                fixed
                />
		<Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
	  </Page>
    </Document>
  </PDFViewer>
)

generarInformeT =async () => {
	this.setState({
		modalImprimirT: true,
		cargandoImprimirT:true
	}, async () => {
		if (this.state.datosEmpresa==null) {
			let datosEmpresa=await this.cargarDatosEmpresa();
			this.setState({
				cargandoImprimirT:false,
				datosEmpresa:datosEmpresa
			},()=>{
				ReactDOM.render(this.InformeT(), document.getElementById('informe-pdf-t'))
			})
		} else {
			this.setState({
				cargandoImprimirT:false,
			},()=>{
				ReactDOM.render(this.InformeT(), document.getElementById('informe-pdf-t'))
			})
		}
	})
	
}


async componentWillMount(props) {
	//DATOS EMPRESA
	await this.props.client
	.query({
		query: gql`
		{
			personasJuridicas(CAMPOS:"ID_PERSONA,TOMA_DECISIONES_ESTRATEGICAS,TOMA_DECISIONES_OPERATIVAS,TOMA_DECISIONES_TACTICAS",FILTROS: { EMPRESA_PRINCIPAL: true }) {
			ID_PERSONA
			TOMA_DECISIONES_ESTRATEGICAS
			TOMA_DECISIONES_OPERATIVAS
			TOMA_DECISIONES_TACTICAS

			}
		}
		`,
		fetchPolicy: "network-only"
	})
	.then(result => {
		this.setState({
		idPersona: result.data.personasJuridicas[0].ID_PERSONA
		});
		if (result.data.personasJuridicas[0].TOMA_DECISIONES_ESTRATEGICAS) {
		this.setState({
			valEstrategica:result.data.personasJuridicas[0].TOMA_DECISIONES_ESTRATEGICAS.replace(
				/"/g,
				"'"
			)
		});
		}
		if (result.data.personasJuridicas[0].TOMA_DECISIONES_OPERATIVAS) {
		this.setState({
			valOperativa:result.data.personasJuridicas[0].TOMA_DECISIONES_OPERATIVAS.replace(
				/"/g,
				"'"
			)
		});
		}
		if (result.data.personasJuridicas[0].TOMA_DECISIONES_TACTICAS) {
			this.setState({
				valTactica:result.data.personasJuridicas[0].TOMA_DECISIONES_TACTICAS.replace(
					/"/g,
					"'"
				)
			});
			}
	});

	//obtencion de los adjuntos.

	//adjunto toma decisiones tacticas
	await this.props.client
	.query({
		query: gql`
			{adjuntos(FILTROS:{ID_TIPO_ADJUNTO:26}){ID_ADJUNTO,DATOS}}
		`,
		fetchPolicy: "network-only"
	})
	.then(result => {
		if(result.data.adjuntos.length>0){
		console.log(result.data.adjuntos[0])
		this.setState({adjuntoTacticas:result.data.adjuntos[0]})
		}
	})

	//adjunto toma decisiones operativas
	await this.props.client
	.query({
		query: gql`
			{adjuntos(FILTROS:{ID_TIPO_ADJUNTO:25}){ID_ADJUNTO,DATOS}}
		`,
		fetchPolicy: "network-only"
	})
	.then(result => {
		if(result.data.adjuntos.length>0){
		console.log(result.data.adjuntos[0])
		this.setState({adjuntoOperativas:result.data.adjuntos[0]})
		}
	})

	//adjunto toma decisiones estrategicas
	await this.props.client
	.query({
		query: gql`
			{adjuntos(FILTROS:{ID_TIPO_ADJUNTO:24}){ID_ADJUNTO,DATOS}}
		`,
		fetchPolicy: "network-only"
	})
	.then(result => {
		if(result.data.adjuntos.length>0){
		console.log(result.data.adjuntos[0])
		this.setState({adjuntoEstrategicas:result.data.adjuntos[0]})
		}
	})

}

handleCambiarE = (event) => {
	this.setState({valEstrategica: event.target.value})
}

handleCambiarO = (event) => {
	this.setState({valOperativa: event.target.value})
}

handleCambiarT = (event) => {
	this.setState({valTactica: event.target.value})
}

Tacticas = (props) => (
	<div >
			<img
				className="App-logo-pdf logo-solo-impresion"
				src={this.state.logotipo?this.state.logotipo:pantallaInicio}
				alt="logotipo"
			/> 
		<br />
		<Card interactive={true} elevation={Elevation.TWO}>
			<Callout className="bp3-intent-danger">
				<b>{i18next.t("tomadecisiones.titletacticas",{ns:"page"})}</b>
				<p className="callout-pdf">{i18next.t("tomadecisiones.dettacticas",{ns:"page"})}</p>
			</Callout>
		</Card>
		<br />
		<TextArea style={{height:150}} id="txtarea-tactica" fill={true} onChange={this.handleCambiarT} value={this.state.valTactica} />
        
		<Button className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf" icon="print" onClick={()=>this.generarInformeT()}>{i18next.t("print",{ns:"global"})}</Button>
        
		<Button
		alignText="right"
		icon="floppy-disk"
		text={i18next.t("guardar",{ns:"global"})}
		onClick={props.handleGuardarDatosTacticas}
		className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
		{this.state.adjuntoTacticas?
		<>
			<Button
			alignText="right"
			icon="cross"
			onClick={props.handleEliminarArchivoTacticas}
			text={i18next.t("deletefile",{ns:"global"})}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
			/>
			<Button
			alignText="right"
			icon="cloud-download"
			text={i18next.t("downloadfile",{ns:"global"})}
			onClick={props.handleDescargarArchivoTacticas}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
		</>
		:<></>}
		<Button
		alignText="right"
		icon="cloud-upload"
		text={i18next.t("uploadfile",{ns:"global"})}
		onClick={props.handleSubirArchivoTacticas}
		className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
	</div>
);

Operativas = props => (
	<div >
		<Card interactive={true} elevation={Elevation.TWO}>
			<Callout className="bp3-intent-danger">
			<b>{i18next.t("tomadecisiones.titleoperativas",{ns:"page"})}</b>
			<p className="callout-pdf">{i18next.t("tomadecisiones.detoperativas",{ns:"page"})}</p>
			</Callout>
		</Card>
		<br />
		<TextArea style={{height:150}} id="txtarea-operative" fill={true} onChange={this.handleCambiarO} value={this.state.valOperativa} />
        
		<Button className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf" icon="print" onClick={()=>this.generarInformeO()}>{i18next.t("print",{ns:"global"})}</Button>
        
		<Button
		alignText="right"
		icon="floppy-disk"
		text={i18next.t("guardar",{ns:"global"})}
		onClick={props.handleGuardarDatosOperativas}
		className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
		{this.state.adjuntoOperativas?
		<>
			<Button
			alignText="right"
			icon="cross"
			onClick={props.handleEliminarArchivoOperativas}
			text={i18next.t("deletefile",{ns:"global"})}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
			/>
			<Button
			alignText="right"
			icon="cloud-download"
			text={i18next.t("downloadfile",{ns:"global"})}
			onClick={props.handleDescargarArchivoOperativas}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
		</>
		:<></>}
		<Button
		alignText="right"
		icon="cloud-upload"
		text={i18next.t("uploadfile",{ns:"global"})}
		onClick={props.handleSubirArchivoOperativas}
		className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
	</div>
);

Estrategicas = props => (
	<div >
		<Card interactive={true} elevation={Elevation.TWO}>
			<Callout className="bp3-intent-danger">
				<b>{i18next.t("tomadecisiones.titleestrategicas",{ns:"page"})}</b>
				<p className="callout-pdf">{i18next.t("tomadecisiones.detestrategicas",{ns:"page"})}</p>
			</Callout>
		</Card>
		<br />
		<TextArea style={{height:150}} id="txtarea-estrategica" fill={true} onChange={this.handleCambiarE} value={this.state.valEstrategica} />
                    
		<Button className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf" icon="print" onClick={()=>this.generarInformeE()}>{i18next.t("print",{ns:"global"})}</Button>
                    
		<Button
			alignText="right"
			icon="floppy-disk"
			text={i18next.t("guardar",{ns:"global"})}
			onClick={props.handleGuardarDatosEstrategicas}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
		{this.state.adjuntoEstrategicas?
		<>
			<Button
			alignText="right"
			icon="cross"
			onClick={props.handleEliminarArchivoEstrategicas}
			text={i18next.t("deletefile",{ns:"global"})}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
			/>
			<Button
			alignText="right"
			icon="cloud-download"
			text={i18next.t("downloadfile",{ns:"global"})}
			onClick={props.handleDescargarArchivoEstrategicas}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
		</>
		:<></>}
		<Button
			alignText="right"
			icon="cloud-upload"
			text={i18next.t("uploadfile",{ns:"global"})}
			onClick={props.handleSubirArchivoEstrategicas}
			className="bp3-intent-danger bp3-minimal iconoDerecha botonera-pdf"
		/>
	</div>
);


render() {

	document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("tomadecisiones.title",{ns:"page"})
	
	const archivoSubidoTacticas=async(ev)=>{           
	
	let archivo=ev.target.files[0]
	//insertamos el archivo
	
	const api = axios.create({
		withCredentials: true
	});
	
	var formData = new FormData();
	formData.append("files",archivo);
	
	//si no tenemos adjunto
	if(!this.state.adjuntoTacticas){
	
		api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:26,NOMBRE:"TOMAS_TACTICAS",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"ADJUNTO TOMA DECISIONES TACTICAS"}){ID_ADJUNTO,DATOS}}`, formData, {
			headers: {
			'Content-Type': 'multipart/form-data'
			}
		}).then(async(response)=> {
	
			alert(i18next.t("uploaded",{ns:"global"}))    
			this.setState({adjuntoTacticas:response.data.data.crearAdjunto})    
	
		}).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		})
	
	}else{
	
		await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoTacticas.ID_ADJUNTO}){ID_ADJUNTO}}`
		).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		}) 
	
		api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:26,NOMBRE:"TOMAS_TACTICAS",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"ADJUNTO TOMA DECISIONES TACTICAS"}){ID_ADJUNTO,DATOS}}`, formData, {
			headers: {
			'Content-Type': 'multipart/form-data'
			}
		}).then(async(response)=> {
			console.log(response)
			alert(i18next.t("uploaded",{ns:"global"}))    
			this.setState({adjuntoTacticas:response.data.data.crearAdjunto})          
	
		}).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		})
	
	}
	
	}

	const archivoSubidoOperativas=async(ev)=>{           

	let archivo=ev.target.files[0]
	//insertamos el archivo
	
	const api = axios.create({
		withCredentials: true
	});
	
	var formData = new FormData();
	formData.append("files",archivo);
	
	//si no tenemos adjunto
	if(!this.state.adjuntoOperativas){
	
		api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:25,NOMBRE:"TOMAS_OPERATIVAS",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"ADJUNTO TOMA DECISIONES OPERATIVAS"}){ID_ADJUNTO,DATOS}}`, formData, {
			headers: {
			'Content-Type': 'multipart/form-data'
			}
		}).then(async(response)=> {
	
			alert(i18next.t("uploaded",{ns:"global"}))    
			this.setState({adjuntoOperativas:response.data.data.crearAdjunto})    
	
		}).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		})
	
	}else{
	
		await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoOperativas.ID_ADJUNTO}){ID_ADJUNTO}}`
		).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		}) 
	
		api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:25,NOMBRE:"TOMAS_OPERATIVAS",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"ADJUNTO TOMA DECISIONES OPERATIVAS"}){ID_ADJUNTO,DATOS}}`, formData, {
			headers: {
			'Content-Type': 'multipart/form-data'
			}
		}).then(async(response)=> {
			console.log(response)
			alert(i18next.t("uploaded",{ns:"global"}))    
			this.setState({adjuntoOperativas:response.data.data.crearAdjunto})          
	
		}).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		})
	
	}
	
	}

	const archivoSubidoEstrategicas=async(ev)=>{           
	
	let archivo=ev.target.files[0]
	//insertamos el archivo
	
	const api = axios.create({
		withCredentials: true
	});
	
	var formData = new FormData();
	formData.append("files",archivo);
	
	//si no tenemos adjunto
	if(!this.state.adjuntoEstrategicas){
	
		api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:24,NOMBRE:"TOMAS_ESTRATEGICAS",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"ADJUNTO TOMA DECISIONES ESTRATEGICAS"}){ID_ADJUNTO,DATOS}}`, formData, {
			headers: {
			'Content-Type': 'multipart/form-data'
			}
		}).then(async(response)=> {
	
			alert(i18next.t("uploaded",{ns:"global"}))    
			this.setState({adjuntoEstrategicas:response.data.data.crearAdjunto})    
	
		}).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		})
	
	}else{
	
		await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoEstrategicas.ID_ADJUNTO}){ID_ADJUNTO}}`
		).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		}) 
	
		api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:24,NOMBRE:"TOMAS_ESTRATEGICAS",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"ADJUNTO TOMA DECISIONES ESTRATEGICAS"}){ID_ADJUNTO,DATOS}}`, formData, {
			headers: {
			'Content-Type': 'multipart/form-data'
			}
		}).then(async(response)=> {
			console.log(response)
			alert(i18next.t("uploaded",{ns:"global"}))    
			this.setState({adjuntoEstrategicas:response.data.data.crearAdjunto})          
	
		}).catch(err=>{
			alert(i18next.t("error",{ns:"global"}))
		})
	
	}
	
	}


	const handleGuardarDatosEstrategicas = () => {
	//si no tiene permisos para editar la pantalla
	if (this.props.editable === false) {
		alert(i18next.t("sinpermiso",{ns:"global"}));
		return;
	}

	this.props.client
		.mutate({
		mutation: gql`mutation{actualizarPersonaJuridica(ID_PERSONA:${
			this.state.idPersona
		},VALORES:{TOMA_DECISIONES_ESTRATEGICAS:${
			this.state.valEstrategica
			? '"'+this.state.valEstrategica+'"'
			: null
		}}){ID_PERSONA}}`
		})
		.then(result => {
		alert(i18next.t("updatecorrecto",{ns:"global"}));
		})
		.catch(err => {
		alert(i18next.t("error",{ns:"global"}));
		});
	};

	const handleGuardarDatosOperativas = () => {


	//si no tiene permisos para editar la pantalla
	if (this.props.editable === false) {
		alert(i18next.t("sinpermiso",{ns:"global"}));
		return;
	}

	this.props.client
		.mutate({
		mutation: gql`mutation{actualizarPersonaJuridica(ID_PERSONA:${
			this.state.idPersona
		}VALORES:{TOMA_DECISIONES_OPERATIVAS:${
			this.state.valOperativa
			? '"'+this.state.valOperativa+'"'
			: null
		}}){ID_PERSONA}}`
		})
		.then(result => {
		alert(i18next.t("updatecorrecto",{ns:"global"}));
		})
		.catch(err => {
		alert(i18next.t("error",{ns:"global"}));
		});
	};

	const handleGuardarDatosTacticas = () => {

	//si no tiene permisos para editar la pantalla
	if (this.props.editable === false) {
		alert(i18next.t("sinpermiso",{ns:"global"}));
		return;
	}

	this.props.client
		.mutate({
		mutation: gql`mutation{actualizarPersonaJuridica(ID_PERSONA:${
			this.state.idPersona
		},VALORES:{TOMA_DECISIONES_TACTICAS:${
			this.state.valTactica
			? '"'+this.state.valTactica+'"'
			: null
		}}){ID_PERSONA}}`
		})
		.then(result => {
		alert(i18next.t("updatecorrecto",{ns:"global"}));
		})
		.catch(err => {
		alert(
			"Ha ocurrido un error al actualizar tomas de decisiones estrategicas.Pongase en contacto con el soporte técnico"
		);
	});
	
	};

	const handleSubirArchivoEstrategicas = (e) => {
	e.preventDefault()
	this.adjuntoEstrategicas.click()
	}

	const handleSubirArchivoOperativas = (e) => {
	e.preventDefault()
	this.adjuntoOperativas.click()
	}

	const handleSubirArchivoTacticas = (e) => {
	e.preventDefault()
	this.adjuntoTacticas.click()
}

const handleDescargarArchivoEstrategicas = (e) => {
	let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoEstrategicas.DATOS.replace(".",""), '_blank')
	win.focus()
}

const handleDescargarArchivoOperativas = (e) => {
	let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoOperativas.DATOS.replace(".",""), '_blank')
	win.focus()
}

const handleDescargarArchivoTacticas = (e) => {
	let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.adjuntoTacticas.DATOS.replace(".",""), '_blank')
	win.focus()
}

const handleEliminarArchivoEstrategicas = (e) => {
	this.props.client
	.mutate({
	mutation: gql`mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoEstrategicas.ID_ADJUNTO}){ID_ADJUNTO}}`
	})
	.then(result => {
	alert(i18next.t("deletecorrecto",{ns:"global"}));
	this.setState({adjuntoEstrategicas:null})
	}).catch(err=>{
	alert(i18next.t("error",{ns:"global"}))
	})
}

const handleEliminarArchivoOperativas= (e) => {
	this.props.client
	.mutate({
	mutation: gql`mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoOperativas.ID_ADJUNTO}){ID_ADJUNTO}}`
	})
	.then(result => {
	alert(i18next.t("deletecorrecto",{ns:"global"}));
	this.setState({adjuntoOperativas:null})
	}).catch(err=>{
	alert(i18next.t("error",{ns:"global"}))
	})
}

const handleEliminarArchivoTacticas = (e) => {
	this.props.client
	.mutate({
	mutation: gql`mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.adjuntoTacticas.ID_ADJUNTO}){ID_ADJUNTO}}`
	})
	.then(result => {
	alert(i18next.t("deletecorrecto",{ns:"global"}));
	this.setState({adjuntoTacticas:null})
	}).catch(err=>{
	alert(i18next.t("error",{ns:"global"}))
	})
}

const cerrarModalImprimirE=()=>{
	this.setState({modalImprimirE: false})
}

const cerrarModalImprimirO=()=>{
	this.setState({modalImprimirO: false})
}

const cerrarModalImprimirT=()=>{
	this.setState({modalImprimirT: false})
}

return (
	<>
		<Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="print"
			onClose={cerrarModalImprimirE}
			/* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
			title={i18next.t("print",{ns:"global"})}
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
			usePortal={true}
			isOpen={this.state.modalImprimirE}
			style={styles.dialog}
		>
			{!this.state.cargandoImprimirE?
					<div style={{ width: '100%', height: '600px'}} id="informe-pdf-e">
					</div>
					:
						<Spinner />
				}

		</Dialog>
		<Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="print"
			onClose={cerrarModalImprimirO}
			/* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
			title={i18next.t("print",{ns:"global"})}
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
			usePortal={true}
			isOpen={this.state.modalImprimirO}
			style={styles.dialog}
		>
			{!this.state.cargandoImprimirO?
					<div style={{ width: '100%', height: '600px'}} id="informe-pdf-o">
					</div>
					:
						<Spinner />
				}

		</Dialog>
		<Dialog 
			transitionDuration={400}
			autoFocus={true}
			enforceFocus={true}
			icon="print"
			onClose={cerrarModalImprimirT}
			/* DEJO LA LOGICA DEL CAMBIO DE NOMBRE AQUI, AUNQUE SE QUE ES INCORRECTO, LUEGO LO EDITAS CON TU ESTADO */
			title={i18next.t("print",{ns:"global"})}
			canEscapeKeyClose={true}
			canOutsideClickClose={true}
			isCloseButtonShown={true}
			onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
			usePortal={true}
			isOpen={this.state.modalImprimirT}
			style={styles.dialog}
		>
			{!this.state.cargandoImprimirE?
					<div style={{ width: '100%', height: '600px'}} id="informe-pdf-t">
					</div>
					:
						<Spinner />
				}

		</Dialog>
		<div className="pixelAlrededor">
		<Card interactive={true} elevation={Elevation.TWO}>
			<Callout className="bp3-intent-danger">
			<p>{i18next.t("tomadecisiones.info",{ns:"page"})}</p>
			</Callout>
		</Card>
		</div>
		<div className="pixelAlrededor">
		<Card interactive={true} elevation={Elevation.TWO}>
			<Tabs
			id="acuerdos-parasociales"
			animate={this.animacion}
			onChange={this.cambiarPestaña}
			selectedTabId={this.state.pestaña}
			renderActiveTabPanelOnly={true}
			>
			<Tab
				id="estrategicas"
				title={i18next.t("tomadecisiones.estrategicas",{ns:"page"}).toUpperCase()}
				panel={
				<this.Estrategicas
					toolbarState={this.state.toolbarState}
					handleGuardarDatosEstrategicas={handleGuardarDatosEstrategicas}
					handleSubirArchivoEstrategicas={handleSubirArchivoEstrategicas}
					handleEliminarArchivoEstrategicas={handleEliminarArchivoEstrategicas}
					handleDescargarArchivoEstrategicas={handleDescargarArchivoEstrategicas}
				/>
				}
			/>
			<Tab id="operativas" title={i18next.t("tomadecisiones.operativas",{ns:"page"}).toUpperCase()} panel={
				<this.Operativas
				editorOperativasHtml={this.state.editorOperativasHtml}
				onEditorStateChangeOperativas={
					this.onEditorStateChangeOperativas
				}
				toolbarState={this.state.toolbarState}
				onContentStateChangeOperativas={
					this.onContentStateChangeOperativas
				}
				contenidoOperativasHtml={this.contenidoOperativasHtml}
				editorOperativas={this.editorOperativas}
				handleGuardarDatosOperativas={handleGuardarDatosOperativas}
				handleSubirArchivoOperativas={handleSubirArchivoOperativas}
				handleEliminarArchivoOperativas={handleEliminarArchivoOperativas}
				handleDescargarArchivoOperativas={handleDescargarArchivoOperativas}
				/>} 
			/>
			<Tab id="tacticas" title={i18next.t("tomadecisiones.tacticas",{ns:"page"}).toUpperCase()} panel={
				<this.Tacticas
					editorTacticasHtml={this.state.editorTacticasHtml}
					onEditorStateChangeTacticas={this.onEditorStateChangeTacticas}
					toolbarState={this.state.toolbarState}
					onContentStateChangeTacticas={
						this.onContentStateChangeTacticas
					}
					contenidoTacticasHtml={this.contenidoTacticasHtml}
					editorTacticas={this.editorTacticas}
					handleGuardarDatosTacticas={handleGuardarDatosTacticas}
					handleSubirArchivoTacticas={handleSubirArchivoTacticas}
					handleEliminarArchivoTacticas={handleEliminarArchivoTacticas}
					handleDescargarArchivoTacticas={handleDescargarArchivoTacticas}
				/>} 
			/>
			<input 
				ref={fileInput => this.adjuntoTacticas = fileInput} 
				type="file"
				onChange={archivoSubidoTacticas}
				style={{display:"none"}}
			/>
			<input 
				ref={fileInput => this.adjuntoEstrategicas = fileInput} 
				type="file"
				onChange={archivoSubidoEstrategicas}
				style={{display:"none"}}
			/>
			<input 
				ref={fileInput => this.adjuntoOperativas = fileInput} 
				type="file"
				onChange={archivoSubidoOperativas}
				style={{display:"none"}}
			/>
			</Tabs>
		<br />
		<br />
		</Card>
		
		</div>
	</>
	);
}
}

export default withTranslation()(withApollo(ProcesosTomaDecisiones));
