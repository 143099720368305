import React, {Component} from 'react';
import {
    Navbar,
    ButtonGroup,
    Button,
    Alignment,
    Dialog,
    Card,
    FormGroup,
    TextArea,
    Elevation,
    FileInput,
    Classes,
    AnchorButton,
    Intent,
    Tooltip,
    InputGroup,
    Spinner,
    NavbarDivider
} from "@blueprintjs/core";
import gql from "graphql-tag";
import axios from 'axios';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png";
import iconoFooter from '../../../Assets/lc.png';
import moment from 'moment';
import {withApollo} from 'react-apollo';
import { PDFViewer, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import i18next from 'i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from '../BootstrapTable';


import styles from '../stylesPrint';

class ProcedimientosGral extends Component {
    state={
        procedimientosGralSeleccionada:null,
        modalProcedimientosGral:false,
        modalProcedimientosGralArchivos:false,
        modalBorrarAbierto:false,
        nuevoActivado:false,
        procedimiento: '',
        nombre:'',
        nombre2:'',
        nombre3:'',
        archivo:null,
        archivo2:null,
        archivo3:null,
        datosProcedimientosGral:[],
        columnWidthsContextos:[100],
        resized:false,
        logotipo:null,
        datosEmpresa:null,
        modalImprimir:false,
        cargandoImprimir:false,
        fileName: '',
        fileName2:'',
        fileName3:'',
        tieneDocumento:false
    }

    async componentDidMount() {
        await this.obtenerLogoEmpresa();
        await this.cargarDatos();
        await this.cargarDatosProcedimientosGral();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.bloqueSeleccionado!==this.props.bloqueSeleccionado) {
            this.setState({
                datosProcedimientosGral:[],
                procedimientosGralSeleccionada:null,
                tieneDocumento: false,
                nuevoActivado:true
            },async ()=>{
                await this.cargarDatosProcedimientosGral();
            })
        }
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
    }

    cargarDatosProcedimientosGral() {
        if (this.props.bloqueSeleccionado) {
        this.props.client
       .query({
         query: gql`
           {bloquesDatosProcedimientos(FILTROS:{ID_BLOQUE:${this.props.bloqueSeleccionado}}){
            ID,
            ID_BLOQUE,
            PROCEDIMIENTO,
            ID_ADJUNTO,
            ADJUNTO{DATOS,NOMBRE},
            ID_ADJUNTO_2,
            ADJUNTO_2{DATOS,NOMBRE},
            ID_ADJUNTO_3,
            ADJUNTO_3{DATOS,NOMBRE}}}
         `,
         fetchPolicy:'network-only'
       }).then(async (result) => {
           this.setState({datosProcedimientosGral:result.data.bloquesDatosProcedimientos});
       })
        }
    }

    generarImprimirProcedimientosGral= async() => {
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
            })
        })
    }

    Imprimir=()=>{
        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}</Text>
                    <Text style={styles.text}>{i18next.t("procedimientosCompliance.generalProceduresInThisOrganization", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                {this.state.datosProcedimientosGral?this.state.datosProcedimientosGral.map((dato, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>Procedmientos de general aplicación en esta organización</Text>                                                                
                                    </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{dato.PROCEDIMIENTO?dato.PROCEDIMIENTO:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
            />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )
    }

    verImprimirIndividual=()=>{
        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>{i18next.t("procedimientosCompliance.generalProceduresInThisOrganization", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View >
                        <View style={styles.rowTitleCompliance}>
                            <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>Procedmientos de general aplicación en esta organización</Text>                                                                
                        </View>
                    <View style={styles.textDataEmp}>
                        <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{this.state.procedimiento}</Text>
                    </View>                               
                
            </View>
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )
    }

    render() {
        const columns = [
            {
                dataField: 'PROCEDIMIENTO',
                text: i18next.t("procedimientosCompliance.procedures", { ns: "page2" })
            }
        ];

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                this.setState({
                    procedimientosGralSeleccionada: row,
                    tieneDocumento: true
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };


        const archivoSubido=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({
                archivo:ev.target.files[0],
                tipoArchivo:ev.target.files[0].type,
                nombre:ev.target.files[0].name.replace("."+extension,"")
            })
          
        }

        const archivoSubido2=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({
                archivo2:ev.target.files[0],
                tipoArchivo2:ev.target.files[0].type,
                nombre2:ev.target.files[0].name.replace("."+extension,"")
            })
          
        }

        const archivoSubido3=(ev)=>{           

            let extension=ev.target.files[0].name.split('.').pop();
            this.setState({
                archivo3:ev.target.files[0],
                tipoArchivo3:ev.target.files[0].type,
                nombre3:ev.target.files[0].name.replace("."+extension,"")
            })
          
        }

        const handleCambiarProcedimiento = (ev) => {
            this.setState({procedimiento:ev.currentTarget.value})
        };

        const handleCambiarNombre = (ev) => {
            this.setState({nombre:ev.currentTarget.value})
        };

        const handleCambiarNombre2 = (ev) => {
            this.setState({nombre2:ev.currentTarget.value})
        };

        const handleCambiarNombre3 = (ev) => {
            this.setState({nombre3:ev.currentTarget.value})
        };
        
        const cerrarModalImprimir = () => {
            this.setState({
                modalImprimir:false
            });
        }

        const cerrarModal=()=>{
            this.setState({
                modalProcedimientosGral:false,
                modalBorrarAbierto:false,
                modalProcedimientosGralArchivos:false
            });
        }

        const borrar = () => {
            if(!this.state.procedimientosGralSeleccionada){
                alert(i18next.t("procedimientosCompliance.selectPorcedure", { ns: "page2" }))
                return
            }

            this.props.client.mutate({
                mutation:gql`mutation{eliminarBloquesDatosProcedimientos(
                    ID:${this.state.procedimientosGralSeleccionada.ID},
                ){ID}}`
            }).then(async result=>{
            
                alert(i18next.t("deletecorrecto", { ns: "global" }))
                this.props.client.mutate({
                    mutation:gql`mutation{eliminarAdjunto(ID_ADJUNTO:${this.state.procedimientosGralSeleccionada.ID_ADJUNTO}){ID_ADJUNTO}}`
                })
                cerrarModal()
                await this.cargarDatosProcedimientosGral()

                this.setState({
                    procedimientosGralSeleccionada:null,
                    tieneDocumento:false
                })
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })
            
        }
        
        const abrirModalProcedimientosGral = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({
                procedimiento: '',
                nuevoActivado:true,
                archivo: null,
                nombre: '',
                fileName:'',
                archivo2: null,
                nombre2: '',
                fileName2:'',
                archivo3: null,
                nombre3: '',
                fileName3:'',
                modalProcedimientosGral:true
            });
        }

        const abrirModalEditarProcedimientosGral = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.procedimientosGralSeleccionada){
                alert(i18next.t("procedimientosCompliance.selectPorcedure", { ns: "page2" }))
            }

            this.setState({
                procedimiento: this.state.procedimientosGralSeleccionada.PROCEDIMIENTO,
                archivo: null,
                nombre: '',
                fileName: this.state.procedimientosGralSeleccionada.ADJUNTO?this.state.procedimientosGralSeleccionada.ADJUNTO.NOMBRE:'',
                archivo2: null,
                nombre2: '',
                fileName2: this.state.procedimientosGralSeleccionada.ADJUNTO_2?this.state.procedimientosGralSeleccionada.ADJUNTO_2.NOMBRE:'',
                archivo3: null,
                nombre3: '',
                fileName3: this.state.procedimientosGralSeleccionada.ADJUNTO_3?this.state.procedimientosGralSeleccionada.ADJUNTO_3.NOMBRE:'',
                nuevoActivado:false,
                modalProcedimientosGral: true,
            })

        }

        const verArchivo = () => {

            if(!this.state.procedimientosGralSeleccionada){
                alert(i18next.t("procedimientosCompliance.selectPorcedure", { ns: "page2" }))
            }

            this.setState({
                archivo: null,
                nombre: '',
                fileName: this.state.procedimientosGralSeleccionada.ADJUNTO?this.state.procedimientosGralSeleccionada.ADJUNTO.NOMBRE:'',
                archivo2: null,
                nombre2: '',
                fileName2: this.state.procedimientosGralSeleccionada.ADJUNTO_2?this.state.procedimientosGralSeleccionada.ADJUNTO_2.NOMBRE:'',
                archivo3: null,
                nombre3: '',
                fileName3: this.state.procedimientosGralSeleccionada.ADJUNTO_3?this.state.procedimientosGralSeleccionada.ADJUNTO_3.NOMBRE:'',
                modalProcedimientosGralArchivos: true,
            })

        }

        const abrirModalBorrar=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.procedimientosGralSeleccionada){
                alert(i18next.t("procedimientosCompliance.selectPorcedure", { ns: "page2" }))
            }
            
            this.setState({
                modalBorrarAbierto:true
            })
        }

        const imprimirIndividual = () => {
            this.setState({
                modalProcedimientosGral:false,
                cargandoImprimir:true,
                modalImprimir:true
            }, async () => {
                this.setState({cargandoImprimir:false}, () => {
                    ReactDOM.render(this.verImprimirIndividual(), document.getElementById('generar-pdf'))
                })
            })
        }

        const ActualizarAdjunto =async () => {
            if(!this.state.procedimiento)return

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if (this.state.archivo) {
                if(!this.state.nombre) {
                    alert(i18next.t("procedimientosCompliance.enterFileName", { ns: "page2" }));
                    return
                }
            }
            if (this.state.archivo2) {
                if(!this.state.nombre2) {
                    alert(`${i18next.t("procedimientosCompliance.enterFileName", { ns: "page2" })} 2`);
                    return
                }
            }
            if (this.state.archivo3) {
                if(!this.state.nombre3) {
                    alert(`${i18next.t("procedimientosCompliance.enterFileName", { ns: "page2" })} 3`);
                    return
                }
            }

            const api = axios.create({
                withCredentials: true
            });

            if (this.state.archivo) {

                var formData = new FormData();
                formData.append("files",this.state.archivo);

                let response=await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:28,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque procedimiento general"}){ID_ADJUNTO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                });
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${this.state.procedimientosGralSeleccionada.ID},
                        VALORES:{
                            ID_ADJUNTO:${response.data.data.crearAdjunto.ID_ADJUNTO}
                            } 
                    ){ID}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            }
            if (this.state.archivo2) {

                var formData2 = new FormData();
                formData2.append("files",this.state.archivo2);

                let response=await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:28,NOMBRE:"${this.state.nombre2}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque procedimiento general"}){ID_ADJUNTO}}`, formData2, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                });
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${this.state.procedimientosGralSeleccionada.ID},
                        VALORES:{
                            ID_ADJUNTO_2:${response.data.data.crearAdjunto.ID_ADJUNTO}
                            } 
                    ){ID}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            }
            if (this.state.archivo3) {

                var formData3 = new FormData();
                formData3.append("files",this.state.archivo3);

                let response=await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:28,NOMBRE:"${this.state.nombre3}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque procedimiento general"}){ID_ADJUNTO}}`, formData3, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                });
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${this.state.procedimientosGralSeleccionada.ID},
                        VALORES:{
                            ID_ADJUNTO_3:${response.data.data.crearAdjunto.ID_ADJUNTO}
                            } 
                    ){ID}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            }

            this.props.client.mutate({
                mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${this.state.procedimientosGralSeleccionada.ID},
                   VALORES:{
                        PROCEDIMIENTO:"""${this.state.procedimiento}"""
                    } 
                ){ID}}`
            }).then(async result=>{
                    
                alert(i18next.t("updatecorrecto", { ns: "global" }));
                cerrarModal();
                await this.cargarDatosProcedimientosGral();

                this.setState({
                    procedimientosGralSeleccionada:null,
                    tieneDocumento:false
                })
                        
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })      
                
            
        }

        const anadirNuevo =async () => {

            if(!this.state.procedimiento)return

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            const api = axios.create({
                withCredentials: true
            });

            if (this.state.archivo) {
                if(!this.state.nombre) {
                    alert(i18next.t("procedimientosCompliance.enterFileName", { ns: "page2" }));
                    return
                }
            }
            if (this.state.archivo2) {
                if(!this.state.nombre2) {
                    alert(`${i18next.t("procedimientosCompliance.enterFileName", { ns: "page2" })} 2`);
                    return
                }
            }
            if (this.state.archivo3) {
                if(!this.state.nombre3) {
                    alert(`${i18next.t("procedimientosCompliance.enterFileName", { ns: "page2" })} 3`);
                    return
                }
            }

            let result= await this.props.client.mutate({
                mutation:gql`mutation{crearBloquesDatosProcedimientos(
                   VALORES:{
                        PROCEDIMIENTO:"""${this.state.procedimiento}""",
                        ID_BLOQUE:${this.props.bloqueSeleccionado}
                     } 
                ){ID}}`
            })

            if (this.state.archivo) {
                var formData = new FormData();
                formData.append("files",this.state.archivo);
                
                let response=await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:28,NOMBRE:"${this.state.nombre}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque procedimiento general"}){ID_ADJUNTO}}`, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                });

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${result.data.crearBloquesDatosProcedimientos.ID},
                        VALORES:{
                            ID_ADJUNTO:${response.data.data.crearAdjunto.ID_ADJUNTO}
                            } 
                    ){ID}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            }
            if (this.state.archivo2) {
                var formData2 = new FormData();
                formData2.append("files",this.state.archivo2);
                
                let response=await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:28,NOMBRE:"${this.state.nombre2}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque procedimiento general"}){ID_ADJUNTO}}`, formData2, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                });

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${result.data.crearBloquesDatosProcedimientos.ID},
                        VALORES:{
                            ID_ADJUNTO_2:${response.data.data.crearAdjunto.ID_ADJUNTO}
                            } 
                    ){ID}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            }
            if (this.state.archivo3) {
                var formData3 = new FormData();
                formData3.append("files",this.state.archivo3);
                
                let response=await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:28,NOMBRE:"${this.state.nombre3}",ID_ENTIDAD_ASOCIADA:1,ID_ASOCIADO:1,DESCRIPCION:"Bloque procedimiento general"}){ID_ADJUNTO}}`, formData3, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                });

                this.props.client.mutate({
                    mutation:gql`mutation{actualizarBloquesDatosProcedimientos(ID:${result.data.crearBloquesDatosProcedimientos.ID},
                        VALORES:{
                            ID_ADJUNTO_3:${response.data.data.crearAdjunto.ID_ADJUNTO}
                            } 
                    ){ID}}`
                }).catch(err=>{
                    console.log(err)
                    alert(i18next.t("error", { ns: "global" }))
                })
            }
                
            alert(i18next.t("insertcorrecto", { ns: "global" }));
            cerrarModal();
            await this.cargarDatosProcedimientosGral();

            this.setState({
                procedimientosGralSeleccionada:null,
                tieneDocumento:false
            })
                    
            
        }

        const descargarArchivo = () => {
            if (this.state.procedimientosGralSeleccionada.ADJUNTO) {
                let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.procedimientosGralSeleccionada.ADJUNTO.DATOS.replace(".",""), '_blank')
                win.focus()
            } else {
                alert(i18next.t("procedimientosCompliance.noAttachment", { ns: "page2" }));
            }
        }

        const descargarArchivo2 = () => {
            if (this.state.procedimientosGralSeleccionada.ADJUNTO_2) {
                let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.procedimientosGralSeleccionada.ADJUNTO_2.DATOS.replace(".",""), '_blank')
                win.focus()
            } else {
                alert(`${i18next.t("procedimientosCompliance.noAttachment", { ns: "page2" })} 2`);
            }
        }

        const descargarArchivo3 = () => {
            if (this.state.procedimientosGralSeleccionada.ADJUNTO_3) {
                let win = window.open (process.env.REACT_APP_DOMINIO+""+this.state.procedimientosGralSeleccionada.ADJUNTO_3.DATOS.replace(".",""), '_blank')
                win.focus()
            } else {
                alert(`${i18next.t("procedimientosCompliance.noAttachment", { ns: "page2" })} 3`);
            }
        }

        const getProcedimientosGral=()=>{
            return (
                <>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} disabled={this.props.bloqueSeleccionado?false:true} onClick={abrirModalProcedimientosGral}/>
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} disabled={this.state.procedimientosGralSeleccionada?false:true} onClick={abrirModalEditarProcedimientosGral}/>
                                <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} disabled={this.state.procedimientosGralSeleccionada?false:true} onClick={abrirModalBorrar}/>
                                <NavbarDivider />
                                {this.state.tieneDocumento?
                                    <Button className="bp3-minimal" style={{fontWeight:'bold'}} icon="document-open" text={i18next.t("procedimientosCompliance.viewAttachments", { ns: "page2" })} disabled={this.state.procedimientosGralSeleccionada?false:true} onClick={verArchivo}/>
                                :
                                    <Button className="bp3-minimal" icon="document-open" text={i18next.t("procedimientosCompliance.viewAttachments", { ns: "page2" })} disabled={this.state.procedimientosGralSeleccionada?false:true} onClick={verArchivo}/>
                                }
                                <Button key="botonImprimir" className="bp3-minimal" icon="print" disabled={this.props.bloqueSeleccionado?false:true} text={i18next.t("print", { ns: "global" })}  onClick={()=>this.generarImprimirProcedimientosGral()}/>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <div style={{ width: '100%', minHeight: '100px', maxHeight: '500px', overflowY: 'scroll', padding: '10px 10px 10px 10px',backgroundColor:'lightgray'}} >
                    <div style={{ width: '100%', minHeight: '100px', maxHeight: '500px', overflowY: 'scroll', padding: '10px 10px 10px 10px',backgroundColor:'white'}} >
                        <BootstrapTable
                            keyField="ID"
                            data={this.state.datosProcedimientosGral}
                            columns={columns}
                            selectRow={selectRow}
                            pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                    </div>
                    </div>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={i18next.t("procedimientosCompliance.viewAttachments", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalProcedimientosGralArchivos}
                        className="dialog-ancho dialog-scroll "
                    >
                        <Card interactive={true} elevation={Elevation.TWO}>
                            {this.state.fileName !== '' &&    
                            <div className="pixelAlrededor">
                                <button onClick={descargarArchivo}>{this.state.fileName}</button>
                            </div>
                            }
                            {this.state.fileName2 !== '' &&    
                            <div className="pixelAlrededor">
                                <button onClick={descargarArchivo2}>{this.state.fileName2}</button>
                            </div>
                            }
                            {this.state.fileName3 !== '' &&    
                            <div className="pixelAlrededor">
                                <button onClick={descargarArchivo3}>{this.state.fileName3}</button>
                            </div>
                            }
                            <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Card>
                    </Dialog>
                    
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("procedimientosCompliance.addProcedure", { ns: "page2" }):i18next.t("procedimientosCompliance.editProcedure", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalProcedimientosGral}
                        className="dialog-ancho dialog-scroll "
                    > 
                        <Card interactive={true} elevation={Elevation.TWO}>
                        {this.state.fileName !== '' &&    
                        <div className="pixelAlrededor">
                            <p>{i18next.t("procedimientosCompliance.viewExistingDocument", { ns: "page2" })}<button onClick={descargarArchivo}>{this.state.fileName}</button>
                            </p>
                        </div>
                        }
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label={i18next.t("procedimientosCompliance.attachedFile", { ns: "page2" })}
                                    labelFor="informacion"
                                >
                                    <FileInput  text={this.state.archivo?this.state.archivo.name:""} onInputChange={archivoSubido} name="files"  />
                                </FormGroup>
                            </div>
                        
                        <div className="pixelAlrededor">
                        </div>
                        <div className="pixelAlrededor">
                            <FormGroup
                                    label={i18next.t("procedimientosCompliance.documentName", { ns: "page2" })}
                                    labelFor="informacion"
                            >
                                <InputGroup id="nombre-documento" onChange={handleCambiarNombre}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre} placeholder={i18next.t("placeholder", { ns: "global" })} />
                            </FormGroup>
                        </div>
                        {this.state.fileName2 !== '' &&    
                        <div className="pixelAlrededor">
                            <p>{i18next.t("procedimientosCompliance.viewExistingDocument", { ns: "page2" })}<button onClick={descargarArchivo2}>{this.state.fileName2}</button>
                            </p>
                        </div>
                        }
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label={`${i18next.t("procedimientosCompliance.attachedFile", { ns: "page2" })} 2`}
                                    labelFor="informacion"
                                >
                                    <FileInput  text={this.state.archivo2?this.state.archivo2.name:""} onInputChange={archivoSubido2} name="files"  />
                                </FormGroup>
                            </div>
                        
                        <div className="pixelAlrededor">
                        </div>
                        <div className="pixelAlrededor">
                            <FormGroup
                                    label={`${i18next.t("procedimientosCompliance.documentName", { ns: "page2" })} 2`}
                                    labelFor="informacion"
                            >
                                <InputGroup id="nombre-documento" onChange={handleCambiarNombre2}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre2} placeholder={i18next.t("placeholder", { ns: "global" })}  />
                            </FormGroup>
                        </div>
                        
                        {this.state.fileName3 !== '' &&    
                        <div className="pixelAlrededor">
                            <p>{ i18next.t("procedimientosCompliance.viewExistingDocument", { ns: "page2" })}<button onClick={descargarArchivo3}>{this.state.fileName3}</button>
                            </p>
                        </div>
                        }
                            <div className="pixelAlrededor">
                                <FormGroup
                                    label="Archivo adjunto 3"
                                    labelFor="informacion"
                                >
                                    <FileInput  text={this.state.archivo3?this.state.archivo3.name:""} onInputChange={archivoSubido3} name="files"  />
                                </FormGroup>
                            </div>
                        
                        <div className="pixelAlrededor">
                        </div>
                        <div className="pixelAlrededor">
                                <FormGroup
                                    label={`${i18next.t("procedimientosCompliance.documentName", { ns: "page2" })} 3`}
                                    labelFor="informacion"
                            >
                                <InputGroup id="nombre-documento" onChange={handleCambiarNombre3}  defaultValue={this.state.adjuntoSeleccionado?this.state.adjuntoSeleccionado.nombre:this.state.nombre2} placeholder={i18next.t("placeholder", { ns: "global" })}  />
                            </FormGroup>
                        </div>
                        <div className="pixelAlrededor">
                            <FormGroup
                                    label={i18next.t("procedimientosCompliance.procedure", { ns: "page2" })}
                                    labelFor="procedimiento"
                                    intent="danger"
                                    helperText={this.state.procedimiento?"":`${i18next.t("procedimientosCompliance.write", { ns: "page2" })} ${i18next.t("procedimientosCompliance.generalProceduresInThisOrganization", { ns: "page2" }).toUpperCase()}`}
                            >
                                <TextArea
                                    large={true}
                                    fill={true}
                                    className="textareaEdicion"
                                    intent={this.state.procedimiento?Intent.PRIMARY:Intent.DANGER}
                                    onChange={handleCambiarProcedimiento}
                                    defaultValue={this.state.procedimiento?this.state.procedimiento:""}
                                />                    
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                        <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" }) }</Button>
                            </Tooltip>
                            {!this.state.nuevoActivado &&
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={imprimirIndividual}
                                >
                                    Imprimir
                                </AnchorButton>
                            </Tooltip>
                        
                            }
                            <Tooltip content={this.state.nuevoActivado?i18next.t("agregar", { ns: "global" }):i18next.t("modify", { ns: "global" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirNuevo:ActualizarAdjunto}
                                >
                                    {this.state.nuevoActivado?i18next.t("agregar", { ns: "global" }):i18next.t("modify", { ns: "global" })}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Card>
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print", { ns: "global" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete", { ns: "global" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("procedimientosCompliance.confirmDeleteProcedure", { ns: "page2" })}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content={i18next.t("eliminar", { ns: "global" })} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={borrar}
                                    >
                                        {i18next.t("yes", { ns: "global" })}
                                    </AnchorButton>
                                </Tooltip>
                                <Tooltip content="Cerrar y volver." position="top">
                                    <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                </>
            );
        }

        if (this.props.bloqueSeleccionado) {
            return getProcedimientosGral();
        } else {
            return null
        }
        
        
    }
}

export default withApollo(ProcedimientosGral);