import React from "react"
import { Card, Elevation, Dialog,FormGroup, Alignment, Button, Tooltip, Classes, AnchorButton, Intent, Navbar, ButtonGroup, Position, Callout, Spinner } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality } from "@blueprintjs/table";
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import { CSVLink } from "react-csv";
import ReactDOM from 'react-dom';
import axios from 'axios';
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "../Pantallas/Cargando"

import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image
} from '@react-pdf/renderer';

import styles from './stylesPrint';

class RegistrosRetributivos extends React.Component{
    state={
        datosCsv:[],
        columnWidths:[100, 100, 100, 100, 100, 100, 100, 100],
        resized: false,
        regionSeleccionada:null,
        cargandoRR:false,
        cargandoImprimir:false,
        modalImprimir:false,
        cargandoHerramienta: false,
        modalHerramienta:false,
        fileName: '',
        DatosHerramienta:null,
        registros:[],
        datosImpresion:[],
        datosEmpresa:[],
        logotipo:null,
        regionRegistros:null,
        cargandoCsv:false,
        totalRegistros:0,
        indiceRegistros:0,
    }
    registrosMostrar=10
    resizeColumns() {
        
         let containerSize = document.getElementsByClassName("tablaRegistros")[0].clientWidth
         let totalMinSizeReq = 0;
         let cumulativeColumnWidths= [];
         for (let index = 0; index < this.tablaEmpleados.locator.grid.numCols; index++) {
             totalMinSizeReq = totalMinSizeReq + this.tablaEmpleados.locator.getWidestVisibleCellInColumn(index);
             cumulativeColumnWidths.push(this.tablaEmpleados.locator.getWidestVisibleCellInColumn(index));
         }
         if (totalMinSizeReq > containerSize) {
             this.setState({
                 columnWidths: cumulativeColumnWidths,
                 resized: true
             });
         } else {
             let diff = ((containerSize - totalMinSizeReq) / this.tablaEmpleados.locator.grid.numCols);
             this.setState({
                 columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                 resized: true
             });
         }
        
    }

    cargarDatosEmpresa=async()=>{         
            //DATOS JURIDICOS
            let datosEmpresa= await this.props.client
            .query({
                query: gql`
                {
                    personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
                }
                `,
                fetchPolicy: 'network-only'
    
            }).then(result=>{
                //si tenemos razon social.
                if(result.data.personasJuridicas.length>0){
                    return {
                        "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                    }
    
                }
                
            })
        
    
           return datosEmpresa
    
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.cargarDatosEmpresa();
        this.setState({
            datosEmpresa:datosEmpresa
        })
    }

    cargarEmpleados=async()=>{
        await this.props.client
        .query({
            query: gql` 
                {empleadosPaginacion(CAMPOS:"ID_EMPLEADO,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,NIF",INDICE_MOSTRAR:${this.state.indiceRegistros},NUMERO_MOSTRAR:${this.registrosMostrar}){total,EMPLEADOS{ID_EMPLEADO,RETRIBUCION,RETRIBUCION_VOLUNTARIA,APELLIDO1,APELLIDO2,NOMBRE,PORCENTAJE_VOLUNTARIA,RETRIBUCION_VARIABLES,PORCENTAJE_VARIABLES,CODIGO_EMPLEADO,NIF}}}
            `,
            fetchPolicy:'network-only'

            
        }).then(result=>{ 
            this.setState({ registros: result.data.empleadosPaginacion.EMPLEADOS, totalRegistros: result.data.empleadosPaginacion.total })
        })
    }
    obtenerHerramientaEmpresa=async()=>{
      //obtencion logo de la empresa
       await this.props.client
       .query({
           query: gql`
               {adjuntos(FILTROS:{NOMBRE:"HERRAMIENTA_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
           `,fetchPolicy:'network-only'             
       }).then(result=>{ 
           if(result.data.adjuntos.length>0){
  
              this.setState({DatosHerramienta:result.data.adjuntos[0]})
               
  
           }
       })
   
   }
    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

    componentDidMount(){
        this.setState({cargandoRR:true}, async()=>{
            await this.cargarDatos()
            await this.cargarEmpleados()
            await this.obtenerHerramientaEmpresa()
            await this.obtenerLogoEmpresa()
            this.setState({cargandoRR:false})
        })
    }

    cargarDatosGral=async ()=>{

        //cargar el select de personas.
        await this.props.client
        .query({
            query: gql` 
                {empleadosPaginacion(CAMPOS:"ID_EMPLEADO,APELLIDO1,APELLIDO2,NOMBRE,CODIGO_EMPLEADO,NIF",INDICE_MOSTRAR:0,NUMERO_MOSTRAR:${this.state.totalRegistros}){total,EMPLEADOS{ID_EMPLEADO,RETRIBUCION,RETRIBUCION_VOLUNTARIA,APELLIDO1,APELLIDO2,NOMBRE,PORCENTAJE_VOLUNTARIA,RETRIBUCION_VARIABLES,PORCENTAJE_VARIABLES,CODIGO_EMPLEADO,NIF}}}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 

            this.setState({ datosImpresion: result.data.empleadosPaginacion.EMPLEADOS })

        })

    }

    generarInformeR=async()=>{
        this.setState({
            modalImprimir:true,
            cargandoImprimir:true
        }, async () => {
            await this.cargarDatosGral()
            this.setState({cargandoImprimir:false}, () => {
                ReactDOM.render(this.InformeRetribuciones(), document.getElementById('generar-pdf'))
            })
        })
    }

    InformeRetribuciones=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>RECURSOS HUMANOS</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>REGISTROS RETRIBUTIVOS</Text>
                </View>
                {this.state.datosImpresion?this.state.datosImpresion.map((registro, index)=>{
                    return (
                            <View key={index}>
                                {index===0 && (
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>CÓDIGO EMPLEADO</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'9%'}}>NIF</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'21%'}}>TRABAJADOR</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>RET. LEGAL</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>RET. VOLUNTARIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>% VOLUNTARIA</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'12%'}}>RET. VARIABLES</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'12%'}}>% VARIABLES</Text>
                                </View>
                                )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{registro.CODIGO_EMPLEADO?registro.CODIGO_EMPLEADO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'9%'}}>{registro.NIF?registro.NIF:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'21%'}}>{registro.APELLIDO1} {registro.APELLIDO2?registro.APELLIDO2:""},{registro.NOMBRE}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.RETRIBUCION?registro.RETRIBUCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.RETRIBUCION_VOLUNTARIA?registro.RETRIBUCION_VOLUNTARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.PORCENTAJE_VOLUNTARIA?registro.PORCENTAJE_VOLUNTARIA:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'12%'}}>{registro.RETRIBUCION_VARIABLES?registro.RETRIBUCION_VARIABLES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'12%'}}>{registro.PORCENTAJE_VARIABLES?registro.PORCENTAJE_VARIABLES:''}</Text>
                                </View>
                                
                            </View>
                        )
                    }
                ):<></>}
            </View>
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
    generarCsv =async () => {
        await this.cargarDatosCsv();
        this.Csv.link.click();
      }
      cargarDatosCsv=async ()=>{

        this.setState({ cargandoCsv: true })
        await this.cargarDatosGral();
        //cargar el select de personas.
      
            let registrosCsv=[]
            //let datosImpresion=[]
            for(let registro of this.state.datosImpresion){
                
                registrosCsv.push({"Codigo":registro.CODIGO_EMPLEADO,"NIF":registro.NIF,"Trabajador":` ${registro.APELLIDO1} ${registro.APELLIDO2?registro.APELLIDO2:""},${registro.NOMBRE}`, "Retribucion Legal":registro.RETRIBUCION,"Retribucion Voluntaria": registro.RETRIBUCION_VOLUNTARIA, "Porcentaje Voluntaria":registro.PORCENTAJE_VOLUNTARIA, "Retribucion Variables":registro.RETRIBUCION_VARIABLES, "Porcentaje Variables":registro.PORCENTAJE_VARIABLES})

            }


            registrosCsv.sort(function (a, b) {
                return ('' + a.Nombre).localeCompare(b.Nombre);
            })
            /*await datosImpresion.sort(function (a, b) {
                return ('' + a.nombre).localeCompare(b.nombre);
            })*/
            this.setState({ datosCsv: registrosCsv })

        

        this.setState({ cargandoCsv: false })

    }  

    botonesPaginacion=()=>{
        
		let botones=[]
		let totalPaginas=Math.ceil(this.state.totalRegistros/this.registrosMostrar)
		let restante=this.state.indiceRegistros+(totalPaginas-this.state.indiceRegistros)
		if(this.state.indiceRegistros>=3){
			botones.push(
				<Button>...</Button>
			)

		}
		for(let i=this.state.indiceRegistros>2?this.state.indiceRegistros-2:0;i<(this.state.indiceRegistros<2?(restante>=4?4:restante):(this.state.indiceRegistros+3)<totalPaginas?this.state.indiceRegistros+3:restante);i++){
			botones.push(
				<Button key={i} className={this.state.indiceRegistros===i?Classes.INTENT_PRIMARY:""} onClick={()=>{this.cambiarIndiceMostrar(i)}}>{i+1}</Button>
			)
		}

		if(this.state.indiceRegistros<10 && (this.state.indiceRegistros+3)<totalPaginas){
			botones.push(
				<Button>...</Button>
			)

		}
		return botones

	}

    cambiarIndiceMostrar=async(indice)=>{
        this.setState({ 
            indiceRegistros: indice, 
            cargandoRR: true 
        },async () => {
            await this.cargarEmpleados()
            this.setState({cargandoRR: false})
        })
        
    }

    irPrimerPagina=async()=>{

        this.setState({ 
            indiceRegistros: 0, 
            cargandoRR: true 
        }, async () =>{
            await this.cargarEmpleados()

            this.setState({cargandoRR: false})
        })
        

    }
    
    irUltimaPagina=async()=>{
        this.setState({ 
            indiceRegistros: Math.ceil(this.state.totalRegistros / this.registrosMostrar) - 1, 
            cargandoRR: true 
        }, async ()=> {
            await this.cargarEmpleados()
            this.setState({cargandoRR: false})
        })
        
        
    }

 render(){
        document.title ="LexControl | Registros Retributivos"

    const  cellRendererCodigoEmpleado = (rowIndex) => {
            return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].CODIGO_EMPLEADO?this.state.registros[rowIndex].CODIGO_EMPLEADO:"":null}</Cell>
        }
    const cellRendererNombreEmpleado= (rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex].APELLIDO1} {this.state.registros[rowIndex].APELLIDO2?this.state.registros[rowIndex].APELLIDO2:""},{this.state.registros[rowIndex].NOMBRE}</Cell>
    }
    const cellRendererNifEmpleado=(rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].NIF?this.state.registros[rowIndex].NIF:"":null}</Cell>
    }
    const cellRendererRetribucionLegal=(rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].RETRIBUCION?this.state.registros[rowIndex].RETRIBUCION:"":null}</Cell>
    }
    const cellRendererRetribucionVoluntaria=(rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].RETRIBUCION_VARIABLES?this.state.registros[rowIndex].RETRIBUCION_VARIABLES:"":null}</Cell>
    }
    const cellRendererRetribucionVariable=(rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].RETRIBUCION_VARIABLES?this.state.registros[rowIndex].RETRIBUCION_VARIABLES:"":null}</Cell>
    }
    const cellRendererPorcentajeVariables=(rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].PORCENTAJE_VARIABLES?this.state.registros[rowIndex].PORCENTAJE_VARIABLES:"":null}</Cell>
    }

    const cellRendererPorcentajeVoluntaria=(rowIndex)=>{
        return <Cell>{this.state.registros[rowIndex]?this.state.registros[rowIndex].PORCENTAJE_VOLUNTARIA?this.state.registros[rowIndex].PORCENTAJE_VOLUNTARIA:"":null}</Cell>
    }

    const cerrarModalImprimir=()=>{
        this.setState({modalImprimir: false})
    }

    const abrirModalHerramienta = () => {

        this.setState({
            modalHerramienta: !this.state.modalHerramienta
        })
    }

    const cerrarModalHerramienta = () => {
      this.setState({
          modalHerramienta: false,
        })
    }

    const editarHerramienta =(value) => {
            
        if (this.state.fileName!=='') {
          if(!this.refs.fileUploader.files[0]){

            alert("Debe adjuntar el documento de la herramienta")
            return

          }
        }

        this.setState({
          cargandoHerramienta:true
        },async() => {
          //subida del archivo
          var formData = new FormData();
          formData.append("files",this.refs.fileUploader.files[0]);
          const api = axios.create({
              withCredentials: true
          });    
          
          //eliminamos el anterior
          await this.props.client 
          .query({
              query: gql`
                  {adjuntos(FILTROS:{NOMBRE:"HERRAMIENTA_EMPRESA",ID_TIPO_ADJUNTO:3,ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
              `,
              fetchPolicy:'network-only'
          }).then(async result=>{ 
              if(result.data.adjuntos.length>0){
                  await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{eliminarAdjunto(ID_ADJUNTO:${result.data.adjuntos[0].ID_ADJUNTO}){ID_ADJUNTO}}`)
              }
          })
          
          await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearAdjunto(VALORES:{ID_TIPO_ADJUNTO:3,NOMBRE:"HERRAMIENTA_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0,DESCRIPCION:"HERRAMIENTA_EMPRESA"}){ID_ADJUNTO}}`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
          }).then(async (response)=>{
            alert("Se subio archivo correctamente.")
            this.setState({
              fileName: '',
              cargandoHerramienta:false
            }, async ()=> {
              await this.obtenerHerramientaEmpresa()
            });
            cerrarModalHerramienta();
            
          }).catch(err=>{
            alert("Ha ocurrido un error al actualizar archivo de herramienta.Pongase en contacto con el soporte técnico.")
            this.setState({
              fileName: '',
              cargandoHerramienta:false
            });
            cerrarModalHerramienta();
          })
        });

        

        
    }

    const onFileChange = event => {
    
      // Update the state
      this.setState({ fileName: event.target.files[0].name });
    
    };
    const abrirSubida = () => {
        this.refs.fileUploader.click()
    }
        
     return(
        <>  {this.state.cargandoRR?
            <Cargando/>
        :
        <>
             <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Registros Retributivos"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generar-pdf">
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModalHerramienta}
                    title="Herramienta"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho pixelAlrededorGrande"
                    isOpen={this.state.modalHerramienta}
                    style={styles.dialog}
                >
                  <>
                  {this.state.cargandoHerramienta &&
                      <Spinner/>
                  }
                    <FormGroup
                    >
                      <input type="file" id="file" ref="fileUploader" onChange={onFileChange}  style={{display: "none"}}/>
                      {this.state.DatosHerramienta && Object.keys(this.state.DatosHerramienta).length>0 &&
                        <p>Para remplazar el documento adjunto de la herramienta, haga click <button onClick={abrirSubida}>aqui</button>
                          <span style={{marginLeft: 10 }}>{this.state.fileName}</span>
                        </p>
                      }
                      {this.state.DatosHerramienta && Object.keys(this.state.DatosHerramienta).length>0?
                        <p>Para consultar el documento adjunto de la herramienta, haga click <a href={process.env.REACT_APP_DOMINIO+""+this.state.DatosHerramienta.DATOS.replace(".","")} target="_blank" rel="noopener noreferrer">aqui</a></p>
                      :
                        <p>Para subir el documento adjunto de la herramienta, haga click <button onClick={abrirSubida}>aqui</button><span style={{marginLeft: 10 }}>{this.state.fileName}</span></p>
                      }
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER}>
                      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                          <Tooltip content="Cerrar y volver." position="top">
                              <Button onClick={cerrarModalHerramienta}>Cerrar</Button>
                          </Tooltip>
                          <Tooltip content="Listado de Pólizas" position="top">
                              <AnchorButton
                                  intent={Intent.PRIMARY}
                                  onClick={editarHerramienta}

                              >
                                  {"Editar Herramienta"}
                              </AnchorButton>
                          </Tooltip>
                      </div>
                    </div>
                  </>
            </Dialog>
                
            <div id="contenedor-registros-retributivos">
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Registros Retributivos</Callout>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup aling={Alignment.LEFT}>
                                        <Tooltip content="Imprimir el listado actual" position={Position.RIGHT}>
                                            <Button key="botonImprimir" className="bp3-minimal" icon="print" text="Imprimir" id="boton-imprimir" onClick={() => this.generarInformeR()} />
                                        </Tooltip>
                                        <CSVLink ref={(r) => { this.Csv = r; }} data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"RegistrosRetributivos.csv"}></CSVLink>
                                        <Tooltip content="Exportar el listado de retribuciones" position={Position.RIGHT}>
                                            <Button
                                                className="bp3-minimal"
                                                icon="document"
                                                text="Exportar"
                                                onClick={() => this.generarCsv()}
                                            >
                                            </Button>
                                        </Tooltip>

                                        <Button className="bp3-minimal" icon="document-open" text="Herramienta"  onClick={abrirModalHerramienta}></Button>
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <>
                                <Table className="tablaRegistros" enableColumnResizing={false}  columnWidths={this.state.columnWidths} enableRowHeader={false} ref={(input) => { this.tablaEmpleados= input; }} enableMultipleSelection={false} selectedRegions={this.state.regionRegistros}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}  numRows={this.state.registros.length} onCompleteRender={() => {
                                                if (!this.state.resized) {
                                                 this.resizeColumns();
                                                }
                                            }}>
                                    <Column name="Código" cellRenderer={cellRendererCodigoEmpleado} />   
                                    <Column name="NIF" cellRenderer={cellRendererNifEmpleado} />   
                                    <Column name="Trabajador" cellRenderer={cellRendererNombreEmpleado}/>   
                                    <Column name="Retribución Legal" cellRenderer={cellRendererRetribucionLegal} />
                                    <Column name="Retribución Voluntaria" cellRenderer={cellRendererRetribucionVoluntaria} />
                                    <Column name="Porcentaje Voluntaria" cellRenderer={cellRendererPorcentajeVoluntaria} />
                                    <Column name="Retribución Variables" cellRenderer={cellRendererRetribucionVariable} />
                                    <Column name="Porcentaje Variables" cellRenderer={cellRendererPorcentajeVariables} />
                                </Table>
                                <div className="centrar2">
                                    {this.state.totalRegistros>10?
                                        <ButtonGroup className="pixelAlrededorGrande">
                                        <Button icon="chevron-left" onClick={this.irPrimerPagina} disabled={this.state.indiceRegistros===0?true:false}></Button>
                                            {this.botonesPaginacion()}
                                        <Button icon="chevron-right" onClick={this.irUltimaPagina} disabled={this.state.indiceRegistros===Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1?true:false}></Button>
                                    </ButtonGroup> 
                                    :<></>}
                                </div>
                                </>
                    </Card>

                </div>

            </div>
        </>
        }
      </>     
     )
 }

}

export default withApollo(RegistrosRetributivos)