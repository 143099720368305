import React, { Component } from 'react';
import { Callout, Card, FormGroup, Navbar, ButtonGroup, Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, Elevation, TextArea, Spinner } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';

class PersonalAltaDireccion extends Component {

    state={
        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        personaSeleccionada:false,
        pendienteGuardar:false,
        pendienteActualizar:false,
        pendienteBorrar:false,
        atribucion:null,
        retribucion:null,
        indemnizaciones:null,
        personas:[],
        personal: [
        ],
        abierto: false,
        personalAltaSeleccionado:null,
        regionSeleccionada:null,
        cargandoForm:false,
        columnWidths: [100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirP:false,
        modalImprimirP: false,
        cargandoImprimirPG:false,
        modalImprimirPG: false,
        logotipo: null,
        reloadTable: false
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    obtenerNombrePersonal=async(personal)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${personal.ID_PERSONAL_ALTA_DIRECCION}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            personal.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        })
    }

    cargarPersonal=async ()=>{

        let personal=await this.props.client.query({
            query:gql`{personalAltaDireccion{ID_PERSONAL_ALTA_DIRECCION,ATRIBUCIONES,RETRIBUCION,INDEMNIZACIONES}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
           return JSON.parse(JSON.stringify(result.data.personalAltaDireccion))
        })

        //console.log(result)
        let index=0

        for(let persona of personal){
             let nombre='';   
            //await this.obtenerNombrePersonal(persona)
            // eslint-disable-next-line array-callback-return
            this.state.personas.find((pers)=>{
                if (pers.value===persona.ID_PERSONAL_ALTA_DIRECCION) {
                    nombre=pers.label;
                }
            })
            personal[index].nombre=nombre
            personal[index].id=persona.ID_PERSONAL_ALTA_DIRECCION
            personal[index].atribucion=persona.ATRIBUCIONES
            personal[index].retribucion=persona.RETRIBUCION
            personal[index].indemnizaciones=persona.INDEMNIZACIONES

            index++
        }

        this.setState({personal:personal})

    }

    cargarPersonas=async()=>{
        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let personas=[]
            result.data.personasFisicas.forEach(async(persona)=>{
                
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 

            })
            this.setState({personas:personas})
        })
    }
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.cargarPersonas()
            await this.cargarPersonal()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoForm: false})
        });
    }

    generarImprimirP= async() => {
        this.setState({
            cargandoImprimirP:true,
            modalImprimirP: true,
            reloadTable: true
        }, async () => {
             this.setState({cargandoImprimirP:false}, () => {
                 ReactDOM.render(this.ImprimirP(), document.getElementById('generarP-pdf'))
             })
        })
    }

    generarImprimirPG= async() => {
        this.setState({
            cargandoImprimirPG:true,
            modalImprimirPG:true
        }, async () => {
             this.setState({cargandoImprimirPG:false}, () => {
                 ReactDOM.render(this.ImprimirPG(), document.getElementById('generarPG-pdf'))
             })
        })
    }

    ImprimirP=()=>{
        let personalSel=this.state.personas.find(persona=>{return persona.value===this.state.personalAltaSeleccionado.id})
                        
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.seniorManagementStaff", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.staff", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.personalAltaSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.person", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{personalSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.remuneration", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.personalAltaSeleccionado.retribucion}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.attribution", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.personalAltaSeleccionado.atribucion}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("partesInteresadas.compensations", { ns: "page2" }).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.personalAltaSeleccionado.indemnizaciones}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirPG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                            <Text style={styles.text}>{i18next.t("partesInteresadas.seniorManagementStaff", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.staffList", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.personal?this.state.personal.map((pers, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("name", { ns: "global" }).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{pers.nombre?pers.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
            </Page>
        </Document>
      </PDFViewer>
    )

    render() {

            document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("partesInteresadas.seniorManagementStaff", { ns: "page2" })}`

            const abrirModalNuevo = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("partesInteresadas.sinpermiso", { ns: "page2" }))
                    return
                }

                this.setState({
                    modalAbierto: true,
                    personaSeleccionada:null,
                    personalAltaSeleccionado:null,
                    nuevoActivado:true,
                    editarActivado:false,
                    borrarActivado:false,
                    atribucion:null,
                    retribucion:null,
                    indemnizaciones:null,
                    regionSeleccionada: null,
                })
            }

            const abrirModalEditar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("partesInteresadas.sinpermiso", { ns: "page2" }))
                    return
                }

                if(!this.state.personalAltaSeleccionado){
                    alert(i18next.t("partesInteresadas.selectStaff", { ns: "page2" }))
                    return
                }

                let personalSel=this.state.personas.find(persona=>{return persona.value===this.state.personalAltaSeleccionado.id})
                
                this.setState({
                    modalAbierto: true,
                    personaSeleccionada:personalSel,
                    nuevoActivado:false,
                    editarActivado:true,
                    borrarActivado:false,
                    atribucion:this.state.personalAltaSeleccionado.atribucion,
                    retribucion:this.state.personalAltaSeleccionado.retribucion,
                    indemnizaciones: this.state.personalAltaSeleccionado.indemnizaciones,
                    reloadTable:true
                })
            }

            const abrirModalBorrar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("partesInteresadas.sinpermiso", { ns: "page2" }))
                    return
                }
                
                if(!this.state.personalAltaSeleccionado){
                    alert(i18next.t("partesInteresadas.selectStaff", { ns: "page2" }))
                    return
                }
                this.setState({modalBorrarAbierto: true})
            }

            const seleccionPersona  = (value) => { 
                this.setState({personaSeleccionada: value})
            }

            const cambiarAtribucion  = (ev) => { 
                this.setState({atribucion: ev.currentTarget.value})
            }

            const cambiarRetribucion  = (ev) => { 
                this.setState({retribucion: ev.currentTarget.value})
            }

            const cambiarIndemnizaciones  = (ev) => { 
                this.setState({indemnizaciones: ev.currentTarget.value})
            }

            const cerrarModal = () => {
                this.setState({
                    modalAbierto: false,
                    modalBorrarAbierto: false,
                    reloadTable: false
                })
            }

            
            const añadirNuevo = (value) => {
                
                if(!this.state.personaSeleccionada){
                    return
                }

                //comporbacion de que existe en la tabla.
                let existe=this.state.personal.find(personal=>{return personal.id===this.state.personaSeleccionada.value})
                if(existe){
                    alert(i18next.t("partesInteresadas.existingStaff", { ns: "page2" }))
                    return
                }

                this.setState({pendienteGuardar:true})
                this.setState({controlesDesactivados:true})
                this.setState({regionSeleccionada:null})
                
                this.state.personal.push({
                    "id":0,
                    "nombre": this.state.personaSeleccionada.label
                })
                
                cerrarModal()
            }

            const actualizarPersonal = () => {

                if(!this.state.personaSeleccionada){
                    return
                }

                let personal=this.state.personal
                this.state.personal.find((pAlta,index) =>{
                    if(pAlta.id===this.state.personalAltaSeleccionado.id){
                        personal[index]["nombre"]=this.state.personaSeleccionada?this.state.personaSeleccionada.label:this.state.personalAltaSeleccionado.nombre
                    }
                    return pAlta.id===this.state.personalAltaSeleccionado.id
                })
                this.setState({pendienteActualizar:true})
                this.setState({controlesDesactivados:true})
                this.setState({personal:personal})
                this.setState({regionSeleccionada:null})

                cerrarModal()
            }

            const elimiaranPersonalAlta = () => {
                let personal=this.state.personal.filter(personal => personal.id !== this.state.personalAltaSeleccionado.id)
                this.setState({personal:personal})
                this.setState({pendienteBorrar:true})     
                this.setState({controlesDesactivados:true})

                cerrarModal()
            };

            const guardarCambios=()=>{
                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("partesInteresadas.sinpermiso", { ns: "page2" }))
                    return
                }
                
                if(this.state.pendienteGuardar){

                    this.props.client.mutate({
                        mutation:gql`mutation{crearPersonalAltaDireccion(VALORES:{ID_PERSONAL_ALTA_DIRECCION:${this.state.personaSeleccionada.value},ATRIBUCIONES:${this.state.atribucion?'"""'+this.state.atribucion+'"""':null},RETRIBUCION:${this.state.retribucion?'"""'+this.state.retribucion+'"""':null},INDEMNIZACIONES:${this.state.indemnizaciones?'"""'+this.state.indemnizaciones+'"""':null}}){ID_PERSONAL_ALTA_DIRECCION}}`
                    }).then(async result=>{
                     
                        alert(i18next.t("insertcorrecto", { ns: "global" }))
                        await this.cargarPersonal()

                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })

                    this.setState({pendienteGuardar:false })

                }else if(this.state.pendienteActualizar){

                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarPersonalAltaDireccion(ID_PERSONAL_ALTA_DIRECCION:${this.state.personalAltaSeleccionado.id},VALORES:{ATRIBUCIONES:${this.state.atribucion?'"""'+this.state.atribucion+'"""':null},RETRIBUCION:${this.state.retribucion?'"""'+this.state.retribucion+'"""':null},INDEMNIZACIONES:${this.state.indemnizaciones?'"""'+this.state.indemnizaciones+'"""':null}}){ID_PERSONAL_ALTA_DIRECCION}}`
                    }).then(async result=>{

                        alert(i18next.t("updatecorrecto", { ns: "global" }))
                        await this.cargarPersonal()
                        this.setState({
                            personalAltaSeleccionado:null
                        })
                        

                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })


                    this.setState({pendienteActualizar:false})

                }else if(this.state.pendienteBorrar){

                    this.props.client.mutate({
                        mutation:gql`mutation{eliminarPersonalAltaDireccion(ID_PERSONAL_ALTA_DIRECCION:${this.state.personalAltaSeleccionado.id}){ID_PERSONAL_ALTA_DIRECCION}}`
                    }).then(result=>{
                        alert(i18next.t("deletecorrecto", { ns: "global" }))
                        this.cargarPersonal()
                        this.setState({
                            pendienteBorrar:false,
                            personalAltaSeleccionado:null
                        })

                    }).catch(err=>{
                        alert(i18next.t("error", { ns: "global" }))
                    })
                }

                this.setState({controlesDesactivados:false, reloadTable: false })

        
            }


            const cancelarCambios=()=>{
                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("partesInteresadas.sinpermiso", { ns: "page2" }))
                    return
                }
                
                this.setState({
                    personaSeleccionada:null,
                    personalAltaSeleccionado:null,
                    atribucion:null,
                    retribucion:null,
                    indemnizaciones:null,
                    pendienteGuardar:false,
                    pendienteActualizar:false,
                    pendienteBorrar:false,
                    controlesDesactivados:false,
                    regionSeleccionada: null,
                    reloadTable: false
                })

                this.cargarPersonal()
            }

            const abrirModalNuevaPersona=()=>{
    
                //abrir modal de persona física
                this.setState({modalPersonaAbierto:true})
                this.setState({popoverAbierto:false})
                this.setState({nuevaPresonaActivado:true})
                this.setState({editarPersonaActivado:false})
    
            }
    
            const cerrarModal2 = () => {
                this.setState({
                    modalPersonaAbierto: false,
                })
            }

            
            const abrirModalEditarPersona=()=>{

                if(!this.state.personaSeleccionada){
                    alert(i18next.t("partesInteresadas.selectPerson", { ns: "page2" }))
                    return
                }

                this.setState({nuevaPresonaActivado:false})
                this.setState({editarPersonaActivado:true})
                //si es personas física
                this.setState({modalPersonaAbierto:true})
            }

            const crearPersona=async()=>{

                await this.datosPersona.crearPersona()
                if(this.datosPersona.state.idPersonaCreada){
                    await this.cargarPersonas()
                    //seleccionar empleado creado

                    let personaSel=await this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})

                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }
    
            const actualizarPersona=async ()=>{
    
                await this.datosPersona.actualizarPersona()
                if(this.datosPersona.state.idPersonaActualizada){
                    await this.cargarPersonas()
                    await this.cargarPersonal()

                    //seleccionar empleado creado
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }

            const cerrarModalImprimir= () => {
                this.setState({
                    modalImprimirP:false,
                    modalImprimirPG: false,
                    reloadTable: false
                })
            }
            
            
            const columns = [
                {
                    dataField: 'nombre',
                    text: i18next.t("name", { ns: "global" }),
                }
            ];

            const handleOnSelect = (row, isSelect) => {
                if (isSelect) {
                    this.setState({
                        personalAltaSeleccionado: row
                    },async()=>{
                    })
                    
                }
                return true; // return true or dont return to approve current select action
            }

            const selectRow = {
                mode: 'radio',
                clickToSelect: true,
                onSelect: handleOnSelect,
                style: { backgroundColor: '#c8e6c9' }
            };

            return (
                <>
                {this.state.cargandoForm===true?
                    <Cargando/>
                :
                <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.seniorManagementStaff", { ns: "page2" })}`}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirPG}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirPG?
                                <div style={{ width: '100%', height: '500px'}} id='generarPG-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.selectedStaff", { ns: "page2" })}`}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirP}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirP?
                                <div style={{ width: '100%', height: '500px'}} id='generarP-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("partesInteresadas.newStaff", { ns: "page2" }):i18next.t("partesInteresadas.updateStaff", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div className="pixelAlrededor bp3-card separarBordes">
                            <FormGroup
                                label={`${i18next.t("partesInteresadas.person", { ns: "page2" })}:`}
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?i18next.t("partesInteresadas.selectStaff", { ns: "page2" }):""}
                            >
                                <div style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                        isDisabled={this.state.editarActivado}
                                        options={this.state.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersona}
                                        value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevaPresonaActivado} onClick={abrirModalNuevaPersona} />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                            </FormGroup>
                            <FormGroup
                                label={`${i18next.t("partesInteresadas.remuneration", { ns: "page2" })}:`}
                                labelFor="retribucion"
                            >
                                <TextArea id="retribucion" maxlength='250' value={this.state.retribucion} onChange={cambiarRetribucion} fill={true} />
                            </FormGroup>
                            <FormGroup
                                label={`${i18next.t("partesInteresadas.attribution", { ns: "page2" })}:`}
                                labelFor="atribuciones"
                            >
                                <TextArea id="atribuciones" maxlength='250' value={this.state.atribucion} onChange={cambiarAtribucion} fill={true} />
                            </FormGroup>
                            <FormGroup
                                label={`${i18next.t("partesInteresadas.compensations", { ns: "page2" })}:`}
                                labelFor="indemnizaciones"
                            >
                                <TextArea id="indemnizaciones" maxlength='250' value={this.state.indemnizaciones} onChange={cambiarIndemnizaciones} fill={true} />
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close", { ns: "global" })}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?i18next.t("partesInteresadas.addSeniorManagementStaff", { ns: "page2" }):i18next.t("partesInteresadas.editSeniorManagementStaff", { ns: "page2" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?añadirNuevo:actualizarPersonal}
                                >
                                {this.state.nuevoActivado?i18next.t("partesInteresadas.addSeniorManagementStaff", { ns: "page2" }):i18next.t("partesInteresadas.editSeniorManagementStaff", { ns: "page2" })}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas i18next={i18next} datosPersona={this.state.personaSeleccionada?this.state.personaSeleccionada.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("close", { ns: "global" })}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPresonaActivado?i18next.t("addPerson", { ns: "global" }):i18next.t("editPerson", { ns: "global" })} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                            >
                            {this.state.nuevaPresonaActivado?i18next.t("addPerson", { ns: "global" }):i18next.t("editPerson", { ns: "global" })} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                    <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("partesInteresadas.deleteAccount", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("partesInteresadas.confirmDeleteSeniorStaff", { ns: "page2" })}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("partesInteresadas.deleteCredit", { ns: "page2" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={elimiaranPersonalAlta}
                                >
                                    {i18next.t("yes", { ns: "global" })}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                    <div className="pixelAlrededor tablaSesenta">
                    <Card>
                        <h4>{i18next.t("partesInteresadas.seniorManagementStaff", { ns: "page2" })}</h4>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    {this.state.controlesDesactivados?[
                                        <React.Fragment>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} disabled />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} disabled/>
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} active={this.state.borrarActivado} disabled/>
                                            <NavbarDivider />
                                            <Button className="bp3-minimal" icon="print"  text={i18next.t("print", { ns: "global" })} disabled/>
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral", { ns: "global" })} disabled/>
                                        </React.Fragment>
                                    ]:[
                                        <React.Fragment>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new", { ns: "global" })} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit", { ns: "global" })} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete", { ns: "global" })} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                            <NavbarDivider />
                                            <Tooltip 
                                                content={i18next.t("partesInteresadas.confirmPrintSelectedStaff", { ns: "page2" })}
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    disabled={this.state.personalAltaSeleccionado?false:true}  
                                                    text={i18next.t("print", { ns: "global" })} onClick={()=>this.generarImprimirP()}
                                                />
                                            </Tooltip>  
                                            <Tooltip 
                                                content={i18next.t("partesInteresadas.confirmPrintAllStaff", { ns: "page2" })} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    text={i18next.t("printgral", { ns: "global" })} 
                                                    onClick={()=>this.generarImprimirPG()}
                                                />
                                            </Tooltip>
                                            
                                        </React.Fragment>
                                    ]}
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                            {this.state.reloadTable?
                            <Cargando/>
                            :
                            <BootstrapTable
                                keyField="id"
                                data={this.state.personal}
                                columns={columns}
                                selectRow={selectRow}
                                pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        }
                        <br />
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text={i18next.t("guardar", { ns: "global" })}
                            intent="danger"
                            disabled={!this.state.controlesDesactivados}
                            onClick={guardarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text={i18next.t("cancelar", { ns: "global" })}
                            disabled={!this.state.controlesDesactivados}
                            onClick={cancelarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <br />
                        <br />
                    </Card>
                    </div>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("partesInteresadas.decreeText", { ns: "page2" })}</Callout>
                            <br />
                                    <AnchorButton onClick={() => window.open("https://www.boe.es/eli/es/rd/1985/08/01/1382", "_blank")}>{i18next.t("partesInteresadas.decreeButton", { ns: "page2" })}</AnchorButton>
                            <br />
                        </Card>
                    </div>
                </>
                }
                </>
            )    
        
    } 

}

export default  withTranslation()(withApollo(PersonalAltaDireccion))