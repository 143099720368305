import React from "react"
import { Spinner,Dialog, Classes, Tooltip, Button, AnchorButton, FormGroup,Intent, Navbar, Alignment, ButtonGroup, Card, Elevation } from "@blueprintjs/core"
import { Table, Cell, Column,RegionCardinality } from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import moment from "moment"
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,StyleSheet,Image, Font} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import open_sans_bold from '../../../Assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import open_sans from '../../../Assets/fonts/Open_Sans/OpenSans-Regular.ttf'

Font.register({ family: 'Open Sans', src: open_sans });
Font.register({ family: 'Open Sans Bold', src: open_sans_bold });

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

const styles = StyleSheet.create({
    dialog:{
        width: '65%'    
    },
  page: {
    paddingBottom:95,
    paddingTop:30,
    paddingLeft: 20,
    paddingRight: 20
  },
  body: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    marginLeft:20,
  },
  imageHead: {
    width: '20%',
    maxHeight: 60,
    marginBottom: 20
  },
rowHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
  rowHeadTwo: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginBottom: 20,
    marginTop:-1
  },
  row: {
    flexDirection: 'row',
    width: '100%'
  },
  label: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
  },
  labelHead: {
    flexDirection: 'column',
    width: '20%',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#000000'
  },
  colHead: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop:-1
  },
rowTitleOne: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  rowTitle: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000'
  },
  labelTitle: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans Bold',
    textAlign: 'center'
  },
  text: {
    flexDirection: 'column',
    fontSize:12,
    fontFamily: 'Open Sans',
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
  }, 
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 10,
    left: 20,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Open Sans',
  },
  footerPage: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'black',
    fontFamily: 'Open Sans Bold',
  },
  icono: {
    position: 'absolute',
    float: 'right',
    width: 40,
    bottom: 10,
    right: 20,
  },
 
  
});

export default class ProfesionalesExternos extends React.Component {

    state={
        profesionalesExternos: [],
        organoProfesionalesExternos: [],
        modalNuevoAbierto: false,
        personaSeleccionada:null,
        modalBorrarAbierto:false,
        profesionalesExternoSeleccionado:null,
        eliminados:[],
        nuevoActivado:true,
        borrarActivado:false,
        regionSeleccionada:null,
        imprimirActivado:false,
        cargandoImprimirPE:false,
        cargandoPE:false,
        modalImprimir:false,
        datosEmpresa:null,
        columnWidths: [100],
        resized: false
    }

    clamp(val, min, max) {
        if (val == null) {
            return val;
        }

        return Math.min(Math.max(val, min), max);
    }

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaProfesionales.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaProfesionales.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaProfesionales.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaProfesionales.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }

    limpiarEliminados=()=>{

        this.setState({eliminados:[]})
    }

    reiniciarEstado=()=>{
        this.setState({
            personaSeleccionada:null,
            profesionalesExternoSeleccionado:null,
            eliminados:[]
        })
        this.props.setProfesionalesExternos([])
        this.props.setProfesionalesExternosEliminados([])
        this.cargarPersonas()
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
        await client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarDatosEmpresa= async() => {
        
        //DATOS JURIDICOS
        let datosEmpresa= await client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }

            }
            
        })

       return datosEmpresa
   
   }
   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarDatosEmpresa()
    this.setState({
        datosEmpresa:datosEmpresa
    })

}


    

    cargarPersonas=async()=>{
  
        let organos=[]
        let personas=await client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}
            `
        })
        .then(result =>{
            return result.data.personasJuridicas
        });
        let profesionales= await client
            .query({
              query: gql`
              {ProfesionalesExternos{ID_PERSONA}}`
            })
            .then(result =>{
                return  result.data.ProfesionalesExternos
            })    

       
        
        personas.forEach(async (persona,index)=>{

            personas[index].value=personas[index].ID_PERSONA
            personas[index].label=personas[index].RAZON_SOCIAL
            personas[index].tipoPersona=2

            
                profesionales.forEach((profesional)=>{
                    if(profesional.ID_PERSONA===persona.ID_PERSONA){
                        // persona.label=persona.RAZON_SOCIAL
                        // persona.value=persona.ID_PERSONA
                        organos.push({
                            "value":persona.value,
                            "label":persona.label
                        })
                    }
                })
                 
              
                this.setState({organoProfesionalesExternos:organos})
                
                 //console.log(this.state.organoProfesionalesExternos) 

       })
       this.setState({profesionalesExternos:personas})
       //console.log(this.state.profesionalesExternos)
    }

    // profesionalesInvisibles =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.tablaProfesionalesAImprimir = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <Card interactive={true} elevation={Elevation.TWO}>
    //                     <Table selectionModes="NONE" defaultColumnWidth={650}  ref={(input) => { this.tablaProfesionales= input; }} numRows={this.state.organoProfesionalesExternos.length}>
    //                         <Column name="Nombre"  cellRenderer={this.cellRendererNombre}/>   
    //                     </Table>
    //                 </Card>
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    // cellRendererNombre = (rowIndex) => {
    //     return <Cell>{this.state.organoProfesionalesExternos[rowIndex].label}</Cell>
    // }
    
    componentDidMount(props){
        this.setState({cargandoPE:true}, async () => {
            await this.cargarPersonas()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargandoPE:false})
        })
    }

    generarPE= async () => {
        this.setState({
            cargandoImprimirPE:true,
            modalImprimirPE:true
        }, async () => {
            this.setState({cargandoImprimirPE:false}, () => {
                ReactDOM.render(this.ImprimirPE(), document.getElementById('generarPE-pdf'))
            })
        })
    }
    ImprimirPE=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ÓRGANO COMPLIANCE</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PROFESIONALES EXTERNOS</Text>
                </View>
                    {this.state.profesionalesExternos?this.state.profesionalesExternos.map((profesional, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'100%'}}>NOMBRE</Text>
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'100%'}}>{profesional.label?profesional.label:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
       )

    render(){
        const cerrarModalImprimir= () =>{
            this.setState({modalImprimirPE:false})
        } 

        const abrirModalNuevo = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                modalNuevoAbierto: true,
                nuevoActivado:true,
                borrarActivado:false
            })
        }

        const abrirModalBorrado = () => {

            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.profesionalesExternoSeleccionado){
                alert("Debes seleccionar un profesional")
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                borrarActivado:true
            })
        }

        const cellRendererNombre = (rowIndex) => {
            return <Cell>{this.state.organoProfesionalesExternos[rowIndex].label}</Cell>
        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const onSeleccionarPersona=(value)=>{
            this.setState({personaSeleccionada:value})
        }
        
        const handleSeleccionarProfesional=(pro)=>{

            if(pro.length===0)return
            
            let seleccionada=this.state.organoProfesionalesExternos[pro[0].rows[0]]
            this.setState({
                profesionalesExternoSeleccionado:seleccionada,
                regionSeleccionada:pro
            })
        }


        const añadirNuevo = () => {
            
            if(!this.state.personaSeleccionada)return

            let existe=this.state.organoProfesionalesExternos.find(profesional=>{
                return profesional.value===this.state.personaSeleccionada.value
            })

            if(existe){
                alert("El profesional ya existe")
                return
            }

            let organos=this.state.organoProfesionalesExternos
            organos.push(this.state.personaSeleccionada)
            this.setState({organoProfesionalesExternos:organos})
            this.props.setProfesionalesExternos(this.state.organoProfesionalesExternos)
            
            cerrarModal();
        }

        const eliminarProfesional = () => {
            var profesionales=this.state.organoProfesionalesExternos.filter(profesional => profesional.value !== this.state.profesionalesExternoSeleccionado.value)
            this.setState({organoProfesionalesExternos:profesionales})
            let eliminados=this.state.eliminados
            eliminados.push(this.state.profesionalesExternoSeleccionado)
            cerrarModal()
            this.props.setProfesionalesExternosEliminados(eliminados)
        };

        return (
            <>
                {this.state.cargandoPE===true?
                    <Spinner/>
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de Profesionales Externos"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPE}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirPE?
                            <div style={{ width: '100%', height: '600px'}} id='generarPE-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Listado de personas."
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                >
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <FormGroup
                                label="Seleccionar persona:"
                                labelFor="seleccionar-persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?"Debe seleccionar la persona":""}
                        >  
                        <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                            <Select 
                                options={this.state.profesionalesExternos}
                                onChange={onSeleccionarPersona}
                                
                            />
                        </div>
                        </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content="Listado de personas." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={añadirNuevo}
                            >
                                Añadir Trabajador
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Borrar una cuenta"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>¿Desea eliminar el profesional seleccionado?</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Eliminar variacion." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarProfesional}
                            >
                                Si
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>No</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup align={Alignment.LEFT}>
                            <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" icon="trash" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrado} />
                            <Button className="bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={()=>this.generarPE()}>Imprimir</Button>
                            {/* <ReactToPrint
                                trigger={() => <Button className="bp3-minimal" icon="print">Imprimir</Button>}
                                content={() => this.tablaProfesionalesAImprimir}
                            /> */}
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <Table selectedRegions={this.state.regionSeleccionada} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} columnWidths={this.state.columnWidths} enableColumnResizing={false} ref={(input) => { this.tablaProfesionales= input; }} onSelection={handleSeleccionarProfesional} numRows={this.state.organoProfesionalesExternos.length} onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}>
                    <Column name="Nombre"  cellRenderer={cellRendererNombre}/>   
                </Table>
            </>
        }
        </>
        )

    }
}