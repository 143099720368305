import React, { Component } from "react";
import {Spinner, Tab, Tabs, Card, Elevation, Callout } from "@blueprintjs/core"

import MedioAbiente from "./MedioAmbiente"
import CuestionesSociales from "./CuestionesSociales"
import DerechosHumanos from "./DerechosHumanos"
import CorrupcionSoborno from "./CorrupcionSoborno"
import SobreSociedad from "./SobreSociedad"
import AdjuntosNoFinanciera from "../Pantallas/Elementos/AdjuntosNoFinanciera"
import { withApollo } from "react-apollo";
import gql  from "graphql-tag";
import { Button } from "@blueprintjs/core"
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class InformacionNoFinanciera extends Component {

    state = {
        datosFinanciera:[],
        animacion: true,
        existeFinanciera:false,
        pestaña:"subir-documentos",
        datosEmpresa: null,
        logotipo:null,
        cargando:false
    }

    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }

    CargarDatosEmpresa= async () =>{
        let datosEmpresa= await this.props.client
        .query({
            query:gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,FUSIONES,FILIALES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `, fetchPolicy:'network-only'
        }).then(result => {
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "nombreComercial":result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                    "sitioWeb":result.data.personasJuridicas[0].WEB_CORPORATIVA,
                    "tipoSociedad":result.data.personasJuridicas[0].TIPO_SOCIEDAD?result.data.personasJuridicas[0].TIPO_SOCIEDAD.DESCRIPCION:"",
                    "tipoTitulos":result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL?result.data.personasJuridicas[0].TIPO_CAPITAL_SOCIAL.DESCRIPCION:"",
                    "totalPartidas":result.data.personasJuridicas[0].TOTAL_PARTIDAS_ACTIVO,
                    "cifraAnual":result.data.personasJuridicas[0].CIFRA_ANUAL_NEGOCIO,
                    "numTrabajadores":result.data.personasJuridicas[0].NUMERO_MEDIO_TRABAJADORES,
                    "escisiones":result.data.personasJuridicas[0].ESCISIONES,
                    "fusiones":result.data.personasJuridicas[0].FUSIONES,
                    "filiales":result.data.personasJuridicas[0].FILIALES,
                    "inicioAct":result.data.personasJuridicas[0].INICIO_EJERCICIO,
                    "finAct":result.data.personasJuridicas[0].FIN_EJERCICIO,
                    "grupoEmpresarial":result.data.personasJuridicas[0].GRUPO_EMPRESARIAL?result.data.personasJuridicas[0].GRUPO_EMPRESARIAL.DESCRIPCION:"",
                    "conveniosColectivos":result.data.personasJuridicas[0].CONVENIOS_COLECTIVOS
                }
            }
        })
        return datosEmpresa
    }



    cargarDatosFinanciera=async()=>{
        //obtenemos los dato
        await this.props.client.query({
            query:gql`{informacionNoFinanciera{
                ID_FINANCIERA,
                CONTAMINACION,
                ECONOMIA,
                USO,
                CAMBIO_CLIMATICO,
                BIODIVERSIDAD,
                EMPLEO,
                TIEMPO_TRABAJO,
                SALUD,
                RELACIONES,
                FORMACION,
                ACCESIBILIDAD,
                IGUALDAD,
                DILIGENCIA_DEBIDA,
                CORRUPCION,
                SOBORNO,
                BLANQUEO,
                APORTACIONES,
                DESARROLLO,
                SUBCONTRATACION,
                PROVEEDORES,
                CONSUMIDORES,
                INFORMACION_FISCAL
            }}`,
                fetchPolicy:'network-only'
        }).then(async(result)=>{
            if(result.data.informacionNoFinanciera.length>0){
                this.setState({
                    datosFinanciera:result.data.informacionNoFinanciera[0],
             })
            }
        })
    }

    cargarDatos = async () => {
        let datosEmpresa=await this.CargarDatosEmpresa();
        this.setState({
            datosEmpresa:datosEmpresa
        })
    }

    componentDidMount=async()=>{
      this.setState({cargando:true}, async () =>{
          await this.cargarDatosFinanciera();
          await this.cargarDatos();
          await this.obtenerLogoEmpresa()

          this.setState({cargando:false})
      })
    }

    // medioAmbienteInvisible =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.medioAmbienteAImprimir = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <MedioAbiente datos={this.state.datosFinanciera} impresion={true} />
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    // cuestionesSocialesInvisible =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.cuestionesSocialesAImprimir = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <CuestionesSociales datos={this.state.datosFinanciera} impresion={true} />
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    // derechosHumanosInvisible =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.derechosHumanosAImprimir = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <DerechosHumanos datos={this.state.datosFinanciera} impresion={true} />
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    // corrupcionInvisible =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.corrupcionAImprimir = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <CorrupcionSoborno datos={this.state.datosFinanciera} impresion={true} />
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    // sociedadInvisible =()=> (
    //     <>
    //         <div className="html-pdf" ref={(input) => { this.AdjuntosNoFinanciera = input; }} >
    //             <div className="body-pdf">
    //                 <br />
    //                 <img
    //                     className="App-logo-pdf"
    //                     src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                     alt="logotipo"
    //                 />
    //                 <br />
    //                 <SobreSociedad datos={this.state.datosFinanciera} impresion={true} />
    //             </div>
    //             <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //         </div>
    //     </>    
    // )

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("informacionnofinanciera.title",{ns:"page"})

        // const guardarCambios=()=>{

        //     //si no tiene permisos para editar la pantalla
        //     if(this.props.editable===false){
        //         alert(i18next.t("sinpermiso",{ns:"global"}))
        //         return
        //     }

            
        //     //si hay datos
        //     if(this.state.existeFinanciera){
        //         //actualizamos los datos
        //         this.props.client.mutate({
        //             mutation:gql`mutation{actualizarInformacionNoFinanciera(
        //                 ID_FINANCIERA:${this.state.datosFinanciera.ID_FINANCIERA},
        //                 VALORES:{
        //                     CONTAMINACION:${this.medioAmbiente.state.contaminacion?'"""'+this.medioAmbiente.state.contaminacion+'"""':null},
        //                     ECONOMIA:${this.medioAmbiente.state.economiaCircular?'"""'+this.medioAmbiente.state.economiaCircular+'"""':null},
        //                     USO:${this.medioAmbiente.state.usoSostenible?'"""'+this.medioAmbiente.state.usoSostenible+'"""':null},
        //                     CAMBIO_CLIMATICO:${this.medioAmbiente.state.cambioClimatico?'"""'+this.medioAmbiente.state.cambioClimatico+'"""':null},
        //                     BIODIVERSIDAD:${this.medioAmbiente.state.bioDiversidad?'"""'+this.medioAmbiente.state.bioDiversidad+'"""':null},
        //                     EMPLEO:${this.cuestionesSociales.state.empleo?'"""'+this.cuestionesSociales.state.empleo+'"""':null},
        //                     TIEMPO_TRABAJO:${this.cuestionesSociales.state.tiempo?'"""'+this.cuestionesSociales.state.tiempo+'"""':null},
        //                     SALUD:${this.cuestionesSociales.state.salud?'"""'+this.cuestionesSociales.state.salud+'"""':null},
        //                     RELACIONES:${this.cuestionesSociales.state.relaciones?'"""'+this.cuestionesSociales.state.relaciones+'"""':null},
        //                     FORMACION:${this.cuestionesSociales.state.formacion?'"""'+this.cuestionesSociales.state.formacion+'"""':null},
        //                     ACCESIBILIDAD:${this.cuestionesSociales.state.accesibilidad?'"""'+this.cuestionesSociales.state.accesibilidad+'"""':null},
        //                     IGUALDAD:${this.cuestionesSociales.state.igualdad?'"""'+this.cuestionesSociales.state.igualdad+'"""':null},
        //                     DILIGENCIA_DEBIDA:${this.derechosHumanos.state.diligenciaDebida?'"""'+this.derechosHumanos.state.diligenciaDebida+'"""':null},
        //                     CORRUPCION:${this.corrupcion.state.corrupcion?'"""'+this.corrupcion.state.corrupcion+'"""':null},
        //                     SOBORNO:${this.corrupcion.state.soborno?'"""'+this.corrupcion.state.soborno+'"""':null},
        //                     BLANQUEO:${this.corrupcion.state.blanqueo?'"""'+this.corrupcion.state.blanqueo+'"""':null},
        //                     APORTACIONES:${this.corrupcion.state.aportaciones?'"""'+this.corrupcion.state.aportaciones+'"""':null},
        //                     DESARROLLO:${this.sociedad.state.desarrolloSostenible?'"""'+this.sociedad.state.desarrolloSostenible+'"""':null},
        //                     SUBCONTRATACION:${this.sociedad.state.subcontratacion?'"""'+this.sociedad.state.subcontratacion+'"""':null},
        //                     PROVEEDORES:${this.sociedad.state.proveedores?'"""'+this.sociedad.state.proveedores+'"""':null},
        //                     CONSUMIDORES:${this.sociedad.state.consumidores?'"""'+this.sociedad.state.consumidores+'"""':null},
        //                     INFORMACION_FISCAL:${this.sociedad.state.informacionFiscal?'"""'+this.sociedad.state.informacionFiscal+'"""':null}})
        //                 {ID_FINANCIERA}}`
        //         }).then(async result=>{
                  
        //             alert(i18next.t("updatecorrecto",{ns:"global"}))

        //         }).catch(err=>{
        //             alert(i18next.t("error",{ns:"global"}))
        //         })

        //     }else{

        //         //creamos un nuevo registro
        //         this.props.client.mutate({
        //             mutation:gql`mutation{crearInformacionNoFinanciera(
        //                 VALORES:{
        //                     ID_FINANCIERA:${1}
        //                     CONTAMINACION:${this.medioAmbiente.state.contaminacion?'"""'+this.medioAmbiente.state.contaminacion+'"""':null},
        //                     ECONOMIA:${this.medioAmbiente.state.economiaCircular?'"""'+this.medioAmbiente.state.economiaCircular+'"""':null},
        //                     USO:${this.medioAmbiente.state.usoSostenible?'"""'+this.medioAmbiente.state.usoSostenible+'"""':null},
        //                     CAMBIO_CLIMATICO:${this.medioAmbiente.state.cambioClimatico?'"""'+this.medioAmbiente.state.cambioClimatico+'"""':null},
        //                     BIODIVERSIDAD:${this.medioAmbiente.state.bioDiversidad?'"""'+this.medioAmbiente.state.bioDiversidad+'"""':null},
        //                     EMPLEO:${this.cuestionesSociales.state.empleo?'"""'+this.cuestionesSociales.state.empleo+'"""':null},
        //                     TIEMPO_TRABAJO:${this.cuestionesSociales.state.tiempo?'"""'+this.cuestionesSociales.state.tiempo+'"""':null},
        //                     SALUD:${this.cuestionesSociales.state.salud?'"""'+this.cuestionesSociales.state.salud+'"""':null},
        //                     RELACIONES:${this.cuestionesSociales.state.relaciones?'"""'+this.cuestionesSociales.state.relaciones+'"""':null},
        //                     FORMACION:${this.cuestionesSociales.state.formacion?'"""'+this.cuestionesSociales.state.formacion+'"""':null},
        //                     ACCESIBILIDAD:${this.cuestionesSociales.state.accesibilidad?'"""'+this.cuestionesSociales.state.accesibilidad+'"""':null},
        //                     IGUALDAD:${this.cuestionesSociales.state.igualdad?'"""'+this.cuestionesSociales.state.igualdad+'"""':null},
        //                     DILIGENCIA_DEBIDA:${this.derechosHumanos.state.diligenciaDebida?'"""'+this.derechosHumanos.state.diligenciaDebida+'"""':null},
        //                     CORRUPCION:${this.corrupcion.state.corrupcion?'"""'+this.corrupcion.state.corrupcion+'"""':null},
        //                     SOBORNO:${this.corrupcion.state.soborno?'"""'+this.corrupcion.state.soborno+'"""':null},
        //                     BLANQUEO:${this.corrupcion.state.blanqueo?'"""'+this.corrupcion.state.blanqueo+'"""':null},
        //                     APORTACIONES:${this.corrupcion.state.aportaciones?'"""'+this.corrupcion.state.aportaciones+'"""':null},
        //                     DESARROLLO:${this.sociedad.state.desarrolloSostenible?'"""'+this.sociedad.state.desarrolloSostenible+'"""':null},
        //                     SUBCONTRATACION:${this.sociedad.state.subcontratacion?'"""'+this.sociedad.state.subcontratacion+'"""':null},
        //                     PROVEEDORES:${this.sociedad.state.proveedores?'"""'+this.sociedad.state.proveedores+'"""':null},
        //                     CONSUMIDORES:${this.sociedad.state.consumidores?'"""'+this.sociedad.state.consumidores+'"""':null},
        //                     INFORMACION_FISCAL:${this.sociedad.state.informacionFiscal?'"""'+this.sociedad.state.informacionFiscal+'"""':null}})
        //                 {ID_FINANCIERA}}`
        //         }).then(async result=>{
                  
        //             alert(i18next.t("insertcorrecto",{ns:"global"}))
        //             await this.cargarDatosFinanciera()
        //             await this.setState({
        //                 existeFinanciera:true
        //             })


        //         }).catch(err=>{
        //             alert(i18next.t("error",{ns:"global"}))
        //         })


        //     }

        // }

    
        return (
            <>
                 {this.state.cargando === true?
                <Spinner />
                :
            <>

               
                <Tabs id="medio-ambiente"
                    animate={this.animacion} 
                    onChange={this.cambiarPestaña} 
                    selectedTabId={this.state.pestaña}
                    renderActiveTabPanelOnly={false}
                >
                    <Tab id="medio-ambiente" title={i18next.t("informacionnofinanciera.medioambiente",{ns:"page"})} panel={<MedioAbiente i18next={i18next} datos={this.state.datosFinanciera} editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.medioAmbiente= input; }}  />} />
                    <Tab id="cuestiones-sociales" title={i18next.t("informacionnofinanciera.cuestionessociales",{ns:"page"})} panel={<CuestionesSociales i18next={i18next} datos={this.state.datosFinanciera}editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.cuestionesSociales= input; }}  />} />
                    <Tab id="derechos-humanos" title={i18next.t("informacionnofinanciera.derechoshumanos",{ns:"page"})} panel={<DerechosHumanos i18next={i18next} datos={this.state.datosFinanciera}editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.derechosHumanos= input; }} />} />
                    <Tab id="Corrupcion-soborno" title={i18next.t("informacionnofinanciera.corrupcionysoborno",{ns:"page"})} panel={<CorrupcionSoborno i18next={i18next} datos={this.state.datosFinanciera}editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.corrupcion= input; }}  />} />
                    <Tab id="sobre-sociedad" title={i18next.t("informacionnofinanciera.sobresociedad",{ns:"page"})} panel={<SobreSociedad i18next={i18next} datos={this.state.datosFinanciera}editable={this.props.editable} logotipo={this.state.logotipo} datosEmpresa={this.state.datosEmpresa} ref={(input) => { this.sociedad= input; }} />} />
                    <Tab id="subir-documentos" title={i18next.t("informacionnofinanciera.subirdocumentos",{ns:"page"})} panel={<AdjuntosNoFinanciera i18next={i18next} editable={this.props.editable}  />} />
                </Tabs>
                {this.state.pestaña!=="subir-documentos"?
                <>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("informacionnofinanciera.titleley",{ns:"page"})}</Callout>
                            <br />
                            <Button icon="git-repo" onClick={()=> window.open("https://www.boe.es/buscar/doc.php?id=BOE-A-2018-17989", "_blank")}>{i18next.t("informacionnofinanciera.openlegislacion",{ns:"page"})}</Button>
                            <br />
                        </Card>
                    </div>
                   
                </>
                :<></>}
                
            </>
            }
        </> 
        )
    }
    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });


}
export default withTranslation()(withApollo(InformacionNoFinanciera))