import React from "react"
import Select from "react-select"
import {Spinner, FormGroup, Button,InputGroup } from "@blueprintjs/core";
import gql from "graphql-tag";
import Periodicidad from "../Elementos/Periodicidad"
import ApolloClient from "apollo-boost";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class PropiedadesOrganoAdmon extends React.Component {


    state = {
        opciones: [
        ],
        formaSeleccionada:null,
        frecuencia: null,
        modalPeriodicidad:false,
        existe:false,
        periodicidad:null, 
        cargandoPOA:false
    }

    handleChange = (opcionSeleccionada) => {
        this.setState({ formaSeleccionada:opcionSeleccionada });
        this.props.setFormaSeleccionada(opcionSeleccionada.value)
    }

    cerrarModal=()=>{
        this.setState({modalPeriodicidad:false})
    }

    formatearPeriodicidad=(periodicidad)=>{

        
        if(typeof periodicidad==="string")periodicidad=JSON.parse(periodicidad)

        let format=""
        if(periodicidad.dia && periodicidad.dia>0){
            format+="El día "+periodicidad.dia+" de"
        }else if(periodicidad.diaSemana){
            format+="El "+periodicidad.ordinal+" "+periodicidad.diaSemana+" de"
        }

        if(periodicidad.numeroMes && periodicidad.numeroMes>0){
            format+=" cada "+periodicidad.numeroMes+" meses"
        }else if(periodicidad.meseseSeleccionados.length>0){
            let meses=periodicidad.meseseSeleccionados.map(function(elem){
                return elem.label;
            }).join(",");
            format+=" los meses "+meses
        }
        return format
    }

    setPeriodicidad=(valor)=>{
        this.setState({periodicidad:valor})
    }

    cargarFormas= async () => {
        let existe= true;
        let opciones=[]
        let formas= await client
        .query({
          query: gql`
            {formasAdministracion{ID_FORMA_ADMINISTRACION,DESCRIPCION}}
          `
        })
        .then(result =>{
            return result.data.formasAdministracion
        })  
        let organos=  await client
        .query({
          query: gql`
            {organoAdministracion{ID_FORMA_ADMINISTRACION,FRECUENCIA_REUNIONES}}
          `,fetchPolicy:'network-only'
        })
        .then(result =>{
            return result.data.organoAdministracion
        })
        formas.forEach(forma=>{
            opciones.push({
                "value":forma.ID_FORMA_ADMINISTRACION,
                "label": forma.DESCRIPCION
            })
        })
        
        if(organos.length===0){
            existe=false
        }else{
            organos.forEach(organo=>{
               let formaSel= opciones.find(forma=>{
                   return forma.value===organo.ID_FORMA_ADMINISTRACION
               })
               this.setState({formaSeleccionada:formaSel})
               this.props.setPropiedadesOrgano(formaSel)
               this.props.setFormaGuardada(organo.ID_FORMA_ADMINISTRACION)
               this.props.setFormaSeleccionada(organo.ID_FORMA_ADMINISTRACION)
               this.setState({periodicidad:organo.FRECUENCIA_REUNIONES})
            })
        }
        this.setState({
            opciones:opciones,
            existe:existe 
        })
      
    }
    // cargarFormas=async()=>{    

    //     let formas= await client
    //     .query({
    //       query: gql`
    //         {formasAdministracion{ID_FORMA_ADMINISTRACION,DESCRIPCION}}
    //       `
    //     })
    //     .then(result =>{
    //         return result.data.formasAdministracion
    //     })   
        
    //         let opciones=[]
    //         formas.forEach(forma=>{
    //             opciones.push({"value":forma.ID_FORMA_ADMINISTRACION,"label":forma.DESCRIPCION})
    //         })
    //         this.setState({opciones:opciones})
        

    //     await client
    //     .query({
    //       query: gql`
    //         {organoAdministracion{ID_FORMA_ADMINISTRACION,FRECUENCIA_REUNIONES}}
    //       `,fetchPolicy:'network-only'
    //     })
    //     .then(result =>{
    //         console.log(result.data.organoAdministracion)
    //         if(result.data.organoAdministracion.length>0){

    //             this.setState({existe:true})

    //             //forma seleccionada
    //             let formaSel=this.state.opciones.find(forma=>{return forma.value===result.data.organoAdministracion[0].ID_FORMA_ADMINISTRACION})
    //             this.setState({formaSeleccionada:formaSel})
    //             this.props.setFormaGuardada(result.data.organoAdministracion[0].ID_FORMA_ADMINISTRACION)
    //             this.props.setFormaSeleccionada(result.data.organoAdministracion[0].ID_FORMA_ADMINISTRACION)
    //             this.setState({periodicidad:result.data.organoAdministracion[0].FRECUENCIA_REUNIONES})
    //         }
    //     });

    //     console.log(this.state.periodicidad)

    // }

    componentDidMount(props){
        this.setState({
            cargandoPOA:true
        }, async () => {
            await this.cargarFormas()
            this.setState({cargandoPOA:false})
        })
       

    }

    render() {
        

        const abririModalPeriodicidad=()=>{

            this.setState({modalPeriodicidad:true})

        }
        const guardarCambios = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.formaSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.periodicidad){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(this.props.numeroMiembros===0){
                alert(this.props.i18next.t("organoadministracion.valid",{ns:"page"}))
                return
            }
            if(this.state.formaSeleccionada.value===1){
                if(this.props.numeroMiembros>1){
                    alert(this.props.i18next.t("organoadministracion.valid1",{ns:"page"}))
                    return
                }
            }else if(this.state.formaSeleccionada.value===2 || this.state.formaSeleccionada.value===3){

                if(this.props.numeroMiembros<2){
                    alert(this.props.i18next.t("organoadministracion.valid2",{ns:"page"}))
                    return
                }

            }else if(this.state.formaSeleccionada.value===4){

                if(this.props.numeroMiembros<3){
                    alert(this.props.i18next.t("organoadministracion.valid3",{ns:"page"}))
                    return
                }
            }

            if(!this.state.existe){

                //creamos la mutacion
                client.mutate({
                    mutation:gql`mutation{crearOrganoAdministracion(VALORES:{ID_FORMA_ADMINISTRACION:${this.state.formaSeleccionada.value},FRECUENCIA_REUNIONES:${JSON.stringify(this.state.periodicidad)}}){ID_FORMA_ADMINISTRACION}}`
                }).then(result=>{
                
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                    this.cargarFormas()
                    
                }).catch(error=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })
            
            }else{

                client.mutate({
                    mutation:gql`mutation{actualizarOrganoAdministracion(VALORES:{ID_FORMA_ADMINISTRACION:${this.state.formaSeleccionada.value},FRECUENCIA_REUNIONES:${JSON.stringify(this.state.periodicidad)}}){ID_FORMA_ADMINISTRACION}}`
                }).then(result=>{
             
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                    this.cargarFormas()
                    
                }).catch(error=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

            }
        }

        return (
            
            <>
            {this.state.cargandoPOA===true?
                <Spinner/>
            :
            <>       
                <Periodicidad cerrarModal={this.cerrarModal} periodicidad={this.state.periodicidad} setPeriodicidad={this.setPeriodicidad} abierto={this.state.modalPeriodicidad} ref={(input) => { this.Periodicidad= input; }} i18next={this.props.i18next}/>
                <Select
                        value={this.state.formaSeleccionada}
                        onChange={this.handleChange}
                        options={this.state.opciones}
                        style={{width:"100%"}}
                    />
                <br />
                <FormGroup
                    label={this.props.i18next.t("organoadministracion.frecuencia",{ns:"page"})+":"}
                    labelFor="frecuencia"
                  
                >
                    <InputGroup
                            placeholder=""
                            type="text"
                            large="true"
                            disabled={true}
                            value={this.state.periodicidad?this.formatearPeriodicidad(this.state.periodicidad):''}
                            rightElement={<Button icon="time" onClick={abririModalPeriodicidad} />}
                    />
                </FormGroup>
                <Button
                    alignText="right"
                    icon="floppy-disk"
                    text={this.props.i18next.t("guardar",{ns:"global"})}
                    onClick={guardarCambios}
                    className="bp3-intent-danger bp3-minimal iconoDerecha"
                />
                <br />
                <br />
            </>
        }
        </>
        )
    }
}
