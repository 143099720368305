import React , { Component } from "react"
import { Button, Collapse, Card, Elevation } from "@blueprintjs/core"

export default class DatosActa extends Component{

    state={
        abierto1: false,
        abierto2: false,
        abierto3: false
    }
    
    render(){

        
        
        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false, abierto3: false });
        }

        const handleClick2 = () => {
            this.setState({ abierto1: false, abierto2: !this.state.abierto2, abierto3: false });
        }

        const handleClick3 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: !this.state.abierto3 });
        }

        return(
            <>
                <Card id="card-informacion" interactive={true} elevation={Elevation.TWO}>
                    <div className="tablaTercio">
                        <Button icon="git-repo" onClick={handleClick1}>
                            {this.props.i18next.t("actas.articulo97",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaTercio">
                        <Button icon="git-repo" onClick={handleClick2}>
                            {this.props.i18next.t("actas.articulo99",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaTercio">
                        <Button icon="git-repo" onClick={handleClick3}>
                            {this.props.i18next.t("actas.articulo100",{ns:"page"})}
                        </Button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Collapse isOpen={this.state.abierto1}>
                        <Card> 
                            <ol>
                                <li>
                                {this.props.i18next.t("actas.detart97_1",{ns:"page"})}
                                    <ol>
                                        <li>{this.props.i18next.t("actas.detart97_2",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_3",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_4",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_5",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_6",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_7",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_8",{ns:"page"})}</li>
                                        <li>{this.props.i18next.t("actas.detart97_9",{ns:"page"})}</li>
                                    </ol>
                                </li>
                            </ol>
                        </Card>
                    </Collapse>
                    <Collapse isOpen={this.state.abierto2}>
                        <Card>
                            <ol>
                                <li>{this.props.i18next.t("actas.detart99_1",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("actas.detart99_2",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("actas.detart99_3",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("actas.detart99_4",{ns:"page"})}</li>
                            </ol>
                        </Card>
                    </Collapse>
                    <Collapse isOpen={this.state.abierto3}>
                        <Card>
                            <ol>
                                <li>{this.props.i18next.t("actas.detart100_1",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("actas.detart100_2",{ns:"page"})}</li>
                                <li>{this.props.i18next.t("actas.detart100_3",{ns:"page"})}</li>
                            </ol>
                        </Card>
                    </Collapse>
                </Card>
            </>
        )
    }
}