/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from "react";
import {
  Card,
  Elevation,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  Dialog,
  FormGroup,
  Navbar,
  NavbarHeading,
  ButtonGroup,
  Button,
  InputGroup,
  Alignment,
  Callout,
  Spinner
} from "@blueprintjs/core";
import { Table, Column, Cell,Regions ,RegionCardinality} from "@blueprintjs/table";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import gql from "graphql-tag";
import { NuevoTituloSociosActual } from "./NuevoTituloSociosActual";
import { withApollo } from "react-apollo";
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { CSVLink } from "react-csv";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom'

import styles from '../stylesPrint';


class EstadoCapitalSocial extends React.Component {
  _isMounted = false;

  state = {
	fecha: "",
	fechaSeleccionada: new Date(),
	valorTitulo: 0,
	numTitulos: 0,
	capitalSocialInicial: "0.00",
	existeCapitaSocial: false,
	abrirModalGuardarDatos: false,
	abrirModalDistribucion: false,
	capitalSocial: [
	],
	distribucionSeleccionada:null,
	nuevoActivado:true,
	editarActivado:false,
	borrarActivado:false,
	tiposParticipacion:null,
	regionSeleccionada:null,
	tipoCapital:null,
	indiceRegistros:0,
	totalRegistros:0,
	cargandoCsv:true,
	datosCsv:[],
	datosImpresion:[],
	valorTituloValido:true,
	pasoGuia:0,
	cargando: false,
	cargandoGrid: false,
	imprimirActivado:false,
	modalImprimir:false,
	cargandoImprimir:false,
	columnWidths: [100,100,100,100,100,100,100,100,100],
	resized: false,
	personasFisicas:null,
	personasJuridicas:null,
	capitalSocActual:null,
	cargandoSaveCapAct: false,
	steps: [

		{
			target: '#vista-general-estado-social',
			content: "En esta pantalla gestionaremos el estado actual del capital social.",
			selector: '#vista-general-estado-social',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 0,
		},
		{
			target: '#bp3-tab-title_pestanas_capital-social',
			content: "La funcionalidad de esta pantalla es exactamente igual a la de capital social inicial, salvo 2 diferencias.",
			selector: '#bp3-tab-title_pestanas_capital-social',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 1,
		},
		{
			target: '#datos-capital-social-actual',
			content: "Está información hace referencia al capital social actual, sin embargo, si está información no es cumplimentada, se mostrará la información del capital social inicial.",
			selector: '#datos-capital-social-actual',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 2,
		},
		{
			target: '#tabla-distribucion-actual',
			content: "En está tabla se mostrarán las distribuciones tanto del capital social inicial, como del capital social actual. Podemos diferenciarlos gracias a la columna 'Tipo'. Cada vez que demos de alta un registro en el capital social inicial también se hará aquí, y si realizamos una modificación en una distribución del capital social inicial, tambien se actualizará aquí, en el registro correspondiente.",
			selector: '#tabla-distribucion-actual',
			position: 'auto',
			disableBeacon: true,
			type: 'hover',
			isFixed: true,
			stepIndex: 3,
		}
			
	]
  };

  registrosMostrar=20

  resizeColumns() {
	
	let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
	let totalMinSizeReq = 0;
	let cumulativeColumnWidths= [];
	for (let index = 0; index < this.tablaDistribuciones.locator.grid.numCols; index++) {
		totalMinSizeReq = totalMinSizeReq + this.tablaDistribuciones.locator.getWidestVisibleCellInColumn(index);
		cumulativeColumnWidths.push(this.tablaDistribuciones.locator.getWidestVisibleCellInColumn(index));
	}
	if (totalMinSizeReq > containerSize) {
		this.setState({
			columnWidths: cumulativeColumnWidths,
			resized: true
		});
	} else {
		let diff = ((containerSize - totalMinSizeReq) / this.tablaDistribuciones.locator.grid.numCols);
		this.setState({
			columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
			resized: true
		});
	}
  }

  obtenerPotencias=(n)=>{
	let array = [];
	let binaryRepresentation = parseInt(n, 10).toString(2);
	binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
	for(let i = binaryRepresentation.length - 1; i >= 0; i--){
		if(parseInt(binaryRepresentation[i]) === 1){
			array[Math.pow(2, i)]=true
		}

	}

	return array
  }
  cargarDatosCsv=async()=>{

    this.setState({cargandoCsv:true})
    let datos=[]


	// let capitalActual=await this.props.client
	// .query({
    //   query:gql`{capSocialActualDistribucion(CAMPOS:"ID_DISTRIBUCION,TIPO_CAPITAL,ID_PERSONA,TIPO_TITULO,NUMERO_TITULOS,NUMERACION_TITULOS"){
    //     ID_DISTRIBUCION,
    //     ID_PERSONA,
    //     PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
	// 	TIPO_TITULO,
	// 	TIPO_CAPITAL,
    //     NUMERO_TITULOS,
    //     NUMERACION_TITULOS
    //   }}`,fetchPolicy:'network-only'
    // }).then(async(result)=>{
    //     return result.data.capSocialActualDistribucion
	// })
	let capitalActual= this.state.capitalSocActual
    let index=1
    
    for(let capital of capitalActual){
      //buscamos el nombre de la persona
      
      if(capital.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
			this.state.personasFisicas.find(persona=>{
				if(persona.ID_PERSONA===capital.ID_PERSONA){
					capital.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
					capital.GRAVAMENES=persona.GRAVAMENES
            		capital.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
				}
			})
        // nombre=await this.props.client
        //   .query({
        //       query: gql` 
        //           {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${capital.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
        //       `,fetchPolicy:'network-only'
        //   }).then(result=>{ 
		// 	capital.NACIONALIDAD=result.data.personasFisicas[0].NACIONALIDAD?result.data.personasFisicas[0].NACIONALIDAD.DESCRIPCION:""
		// 	capital.GRAVAMENES=result.data.personasFisicas[0].GRAVAMENES
        //     return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        //   })

      //Si es persona juridica
      }else if(capital.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
		this.state.personasJuridicas.find(persona=>{
			if(persona.ID_PERSONA===capital.ID_PERSONA){
				capital.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
				capital.GRAVAMENES=persona.GRAVAMENES
				capital.nombre= persona.RAZON_SOCIAL
			}
		})		

        //   nombre=await this.props.client
        //   .query({
        //       query: gql`
        //           {personasJuridicas(FILTROS:{ID_PERSONA:${capital.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
        //       `,fetchPolicy:'network-only'
        //   }).then(result=>{ 
		// 		capital.NACIONALIDAD=result.data.personasJuridicas[0].NACIONALIDAD?result.data.personasJuridicas[0].NACIONALIDAD.DESCRIPCION:""
		// 		capital.GRAVAMENES=result.data.personasJuridicas[0].GRAVAMENES
		// 		return result.data.personasJuridicas[0].RAZON_SOCIAL
        //   })

        }
        
        let tiposKeys=Object.keys(this.obtenerPotencias(capital.TIPO_TITULO))
        let tipos=""

        tiposKeys.forEach(t=>{

            let tipoSel=null
            if(this.state.tipoCapital===1){
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
            }else{
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
            }
            if(tipos===""){
              tipos+=tipoSel.DESCRIPCION
            }else{

              tipos+=","+tipoSel.DESCRIPCION

            }

        })
        capital.tipo=tipos

        datos.push({
			"Nº":index,
			"Tipo":capital.TIPO_CAPITAL==="I"?"Inicial":"Actual",
			"Socio":capital.nombre,
			"Nacionalidad":capital.NACIONALIDAD,
			"Tipo de titulo":tipos,
			"Número de titulos":capital.NUMERO_TITULOS,
			"%":parseFloat((capital.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2),
			"Numeración":""+capital.NUMERACION_TITULOS.replace("-"," a "),
			"Gravamenes":capital.GRAVAMENES
        })

		// datosImpresion.push({
		// 	"Nº":index,
		// 	"Tipo":capital.TIPO_CAPITAL==="I"?"Inicial":"Actual",
		// 	"Socio":nombre,
		// 	"Nacionalidad":capital.NACIONALIDAD,
		// 	"Tipo de titulo":tipos,
		// 	"Número de titulos":capital.NUMERO_TITULOS,
		// 	"%":parseFloat((capital.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2),
		// 	"Numeración":""+capital.NUMERACION_TITULOS.replace("-"," a "),
		// 	"Gravamenes":capital.GRAVAMENES
		// })

        index++;
	}
	
	console.log(capitalActual)
    this.setState({datosCsv:datos,cargandoCsv:false,datosImpresion:capitalActual})

  }
  cargarCapital=async()=>{

    let capital=await this.props.client.query({
        query:gql`{capSocialActualDistribucionPaginacion(CAMPOS:"ID_DISTRIBUCION,ID_PERSONA,TIPO_TITULO,NUMERO_TITULOS,NUMERACION_TITULOS",INDICE_MOSTRAR:${this.state.indiceRegistros},NUMERO_MOSTRAR:${this.registrosMostrar}){
          CAP_SOCIAL_ACTUAL_DISTRIBUCION{ID_DISTRIBUCION,
          ID_PERSONA,
          ID_TIPO_PERSONA,
          TIPO_TITULO,
          TIPO_CAPITAL,
          NUMERO_TITULOS,
          NUMERACION_TITULOS},
          total
        }}`,fetchPolicy:'network-only'
    }).then(async(result)=>{		
       this.setState({totalRegistros:result.data.capSocialActualDistribucionPaginacion.total})
		return result.data.capSocialActualDistribucionPaginacion.CAP_SOCIAL_ACTUAL_DISTRIBUCION
		
	})
	
	
	
	
    for(let cap of capital){
      //buscamos el nombre de la persona
      
      if(cap.ID_TIPO_PERSONA===1){
		  this.state.personasFisicas.find(persona=>{
				if(persona.ID_PERSONA===cap.ID_PERSONA){				
					cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:''
					cap.GRAVAMENES=persona.GRAVAMENES
					cap.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
				}
			})
		}else if(cap.ID_TIPO_PERSONA===2){
			this.state.personasJuridicas.find(persona=>{
				if(persona.ID_PERSONA===cap.ID_PERSONA){
					cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
					cap.GRAVAMENES=persona.GRAVAMENES
				    cap.nombre= persona.RAZON_SOCIAL
				}
			})

		}				

    // for(let cap of capital){
    //   //buscamos el nombre de la persona
    //   let nombre=""
    //   if(cap.ID_TIPO_PERSONA===1){

    //     nombre=await this.props.client
    //       .query({
    //           query: gql` 
    //               {personasFisicas(FILTROS:{ID_PERSONA:${cap.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
    //           `,fetchPolicy:'network-only'
    //       }).then(result=>{ 
	// 		cap.NACIONALIDAD=result.data.personasFisicas[0].NACIONALIDAD?result.data.personasFisicas[0].NACIONALIDAD.DESCRIPCION:""
	// 		cap.GRAVAMENES=result.data.personasFisicas[0].GRAVAMENES
    //         return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //       })

      //Si es persona juridica
    //   }else if(cap.ID_TIPO_PERSONA===2){

    //       nombre=await this.props.client
    //       .query({
    //           query: gql`
    //               {personasJuridicas(FILTROS:{ID_PERSONA:${cap.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
    //           `,fetchPolicy:'network-only'
    //       }).then(result=>{ 
	// 			cap.NACIONALIDAD=result.data.personasJuridicas[0].NACIONALIDAD?result.data.personasJuridicas[0].NACIONALIDAD.DESCRIPCION:""
	// 			cap.GRAVAMENES=result.data.personasJuridicas[0].GRAVAMENES
	// 			return result.data.personasJuridicas[0].RAZON_SOCIAL
    //       })

    
       
        let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
        let tipos=""

        tiposKeys.forEach(t=>{

            let tipoSel=null
            if(this.state.tipoCapital===1){
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
            }else{
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
            }
            if(tipos===""){
              tipos+=tipoSel.DESCRIPCION
            }else{

              tipos+=","+tipoSel.DESCRIPCION

            }

        })
		cap.tipo=tipos
		
    }


	//console.log(capital)
    this.setState({capitalSocial:capital})
	this.cargarDatosCsv()

  }
  cargarCapitalActual=async()=>{
	let capitalActual=await this.props.client
	.query({
      query:gql`{capSocialActualDistribucion{
        ID_DISTRIBUCION,
        ID_PERSONA,
        PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		TIPO_TITULO,
		TIPO_CAPITAL,
        NUMERO_TITULOS,
        NUMERACION_TITULOS
      }}`,fetchPolicy:'network-only'
    }).then(async(result)=>{
        return result.data.capSocialActualDistribucion
	})
	return capitalActual
  }
  cargarPersonasFisicas=async()=>{
	let personasFisicas= await this.props.client
	.query({
		query: gql` 
			{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		`,fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.personasFisicas
	})
	return personasFisicas
  }
  cargarPersonasJuridicas=async()=>{
	let personasJuridicas=await this.props.client
	.query({
		query: gql`
			{personasJuridicas{ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		`,fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.personasJuridicas
	})
	return personasJuridicas
  }
  cargarDatos=async()=>{
	  let personasFisicas= await this.cargarPersonasFisicas()
	  let personasJuridicas= await this.cargarPersonasJuridicas()
	  let capitalActual= await this.cargarCapitalActual()
	  this.setState({
		  personasFisicas:personasFisicas,
		  personasJuridicas:personasJuridicas,
		  capitalSocActual:capitalActual
	  })
  }

  cargarNumTitulos=async()=>{
	let numTitulos = 0;
  
	//obtencion de los titulos del capital social.
	let capital= this.state.capitalSocActual
	// await this.props.client
	// .query({
	//   query: gql`
	// 	{
	// 	  capSocialActualDistribucion (CAMPOS:"ID_PERSONA,NUMERO_TITULOS"){
	// 		ID_PERSONA
	// 		NUMERO_TITULOS
	// 		PERSONA {TIPO_PERSONA{ID_TIPO_PERSONA}}}}
	//   `,fetchPolicy:'network-only'
	// })
	// .then(result => {
	// 	return result.data.capSocialActualDistribucion
	// })	
	  capital.forEach(socio => {
		numTitulos += socio.NUMERO_TITULOS;
	  });
	
	 this.setState({ numTitulos: numTitulos });

  }

//   capitalSocialInicialInvisible =()=> (
// 	<div className="outer">
// 		<div className="inner">
// 			<div className="html-pdf" ref={(input) => { this.tablaDistribucionesAImprimir = input; }} >
// 				<div className="body-pdf">
// 					<br />
// 					<img
// 						className="App-logo-pdf"
// 						src={this.state.logotipo?this.state.logotipo:pantallaInicio}
// 						alt="logotipo"
// 					/>
// 					<br />
// 					<Card interactive={true}  elevation={Elevation.TWO}>
// 					<Table
// 					columnWidths={[45,80,215,110,220,100,75,135,100]}
// 					numRows={this.state.capitalSocial.length}
// 					enableRowHeader={false}
// 					ref={(input) => { this.tablaDistribuciones= input; }}
// 					>
// 						<Column name="" cellRenderer={this.cellRendererNumSoc} />
// 						<Column name="Tipo" cellRenderer={this.cellRendererTipoCap} />
// 						<Column name="Socio" cellRenderer={this.cellRendererSocio} />
// 						<Column name="Nacionalidad" cellRenderer={this.cellRendererNacionalidad} />
// 						<Column name="Tipo de títulos" cellRenderer={this.cellRendererTipo} />
// 						<Column name="Num. Títulos" cellRenderer={this.cellRendererNumero} />
// 						<Column name="%" cellRenderer={this.cellRendererPorc} />
// 						<Column name="Númeracion de Títulos" cellRenderer={this.cellRendererNumeracion} />
// 						<Column name="Gravamenes" cellRenderer={this.cellRendererGravamenes} />
// 					</Table>
// 					<br />
// 				</Card>
// 				</div>
// 				<p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
// 			</div>
// 		</div>  
// 	  </div>
//   )

   cellRendererTipoCap = rowIndex => {
	return (
	  <Cell wrapText="false" truncated="false">{`${
		this.state.capitalSocial[rowIndex].TIPO_CAPITAL==="I"?"Inicial":"Actual"
	  }`}</Cell>
	);
  };

   cellRendererNumSoc = rowIndex => {
	return (
	  <Cell wrapText="false" truncated="false">{`${
		(rowIndex+1)+(this.state.indiceRegistros*this.registrosMostrar)
	  }`}</Cell>
	);
  };

   cellRendererSocio = rowIndex => {
	return (
	  <Cell wrapText="false" truncated="false">{`${
		this.state.capitalSocial[rowIndex].nombre
	  }`}</Cell>
	);
  };
  cellRendererNacionalidad = rowIndex => {
		return (
			<Cell wrapText="false" truncated="false">{`${
				this.state.capitalSocial[rowIndex].NACIONALIDAD
			}`}</Cell>
		);
   };	
   cellRendererTipo = rowIndex => {
	return (
	  <Cell wrapText="false" truncated="false">{`${
		this.state.capitalSocial[rowIndex].tipo
	  }`}</Cell>
	);
  };
   cellRendererNumero = rowIndex => {
	return (
	  <Cell wrapText="false" truncated="false">{`${
		this.state.capitalSocial[rowIndex].NUMERO_TITULOS
	  }`}</Cell>
	);
  };
  cellRendererPorc = rowIndex => {
	return (
	  <Cell columnWidths  wrapText="false" truncated="false">{`${
		parseFloat((this.state.capitalSocial[rowIndex].NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2)
	  }`}</Cell>
	);
  };
  cellRendererNumeracion = rowIndex => {
	return (
	  <Cell wrapText="false" truncated="false">{`${
		this.state.capitalSocial[rowIndex].NUMERACION_TITULOS
	  }`}</Cell>
	);
  };
  
	cellRendererGravamenes = rowIndex => {
		return (
			<Cell wrapText="false" truncated="false">{`${
				this.state.capitalSocial[rowIndex].GRAVAMENES?this.state.capitalSocial[rowIndex].GRAVAMENES:""
			}`}</Cell>
		);
	};

	tipoCapital=async()=>{
		let personas= this.props.empresaPrincipal
	// 	 await this.props.client
	//   .query({
	// 	  query: gql`
	// 	  {
	// 		  personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
	// 	  }
	// 	  `,
	// 	  fetchPolicy:'network-only'
	//   }).then(result=>{ 
	// 		return result.data.personasJuridicas  
	//   })
		//   let tiposAccion= await this.props.client
		//   .query({
		// 	query:gql`{tiposAccion{
		// 		ID_TIPO_ACCION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`
		//   }).then(async(result)=>{
		// 	  return result.data.tiposAccion
		//   })
		//  let tiposParticipacion= await this.props.client
		//   .query({
		// 	query:gql`{tiposParticipacion{
		// 		ID_TIPO_PARTICIPACION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`,fetchPolicy:'network-only'
		//   }).then(async(result)=>{
		// 	  return result.data.tiposParticipacion
		//   })
		 for(let persona of personas){
			if(persona.ID_TIPO_CAPITAL_SOCIAL===1){
				this.setState({
					tiposParticipacion:this.props.tiposAccion
				})
			}else{
				this.setState({
					tiposParticipacion:this.props.tiposParticipacion
				})
			}
			this.setState({tipoCapital: persona.ID_TIPO_CAPITAL_SOCIAL})
		 }
		  
	//   this.setState({tipoCapital:result.data.personasJuridicas[0].ID_TIPO_CAPITAL_SOCIAL})
	//   if(this.state.tipoCapital===1){

	// 	await this.props.client.query({
	// 	  query:gql`{tiposAccion{
	// 		  ID_TIPO_ACCION,
	// 		  DESCRIPCION,
	// 		  INCOMPATIBLE,
	// 	  }}`
	// 	}).then(async(result)=>{
	// 		this.setState({tiposParticipacion:result.data.tiposAccion})
	// 	})

	//   }else{

	// 	await this.props.client.query({
	// 	  query:gql`{tiposParticipacion{
	// 		  ID_TIPO_PARTICIPACION,
	// 		  DESCRIPCION,
	// 		  INCOMPATIBLE,
	// 	  }}`,fetchPolicy:'network-only'
	// 	}).then(async(result)=>{
	// 		this.setState({tiposParticipacion:result.data.tiposParticipacion})
	// 	})

	//   }
	}
	cargarFecha= async() =>{
		//CAP SOCIAL ACTUAL
		this.props.client
		.query({
			query: gql`
			{
				CapSocialActualRegs {
				FECHA_VALIDEZ
				VALOR_TITULO
				}
			}
			`,fetchPolicy:'network-only'
		})
		.then(result => {
			//si tenemos capita social inicial.
			if (result.data.CapSocialActualRegs.length > 0) {
			this.setState({
				existeCapitaSocial: true,
				valorTitulo: Intl.NumberFormat('es-ES').format(result.data.CapSocialActualRegs[0].VALOR_TITULO),
				fechaSeleccionada: result.data.CapSocialActualRegs[0].FECHA_VALIDEZ
			});
			}else{

				//CAP SOCIAL INICIAL
				// this.props.client
				// .query({
				// 	query: gql`
				// 	{
				// 		capSocialInicial {
				// 		FECHA_VALIDEZ
				// 		VALOR_TITULO
				// 		}
				// 	}
				// 	`,fetchPolicy:'network-only'
				// })
				// .then(result => {
					//si tenemos capita social inicial.
					if (this.props.capSocialInicial.length > 0) {
						this.props.capSocialInicial.forEach(capital=>{
							this.setState({
								valorTitulo: Intl.NumberFormat('es-ES').format(capital.VALOR_TITULO),
								fechaSeleccionada: capital.FECHA_VALIDEZ
							});
						})
					
					}
			
			}
		});   
	}
	generarImprimir=async()=>{
		this.setState({
			cargandoImprimir:true,
			modalImprimir:true}, async ()=>{
				this.setState({cargandoImprimir:false}, () =>{
					ReactDOM.render(this.ImprimirCap(), document.getElementById('generarImprimir-pdf'))
				})
			})
	}

	ImprimirCap=()=>(
	<PDFViewer style={{width: '100%', height: '100%'}}>
		<Document>
		<Page size="A4" orientation="landscape" style={styles.page}>
			<View style={styles.body}>
				<View fixed style={styles.row}>
				<Image
					style={styles.imageHeadL}
					src={this.props.logotipo?this.props.logotipo:pantallaInicio}
					/>
				</View>
				<View fixed style={styles.rowHead}>
					<Text style={styles.labelHead}>EMPRESA:</Text>
					<Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
				</View>
				<View fixed style={styles.rowHeadTwo}>
					<Text style={styles.labelHead}>INFORME:</Text>
					<Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
				</View>
				<View style={styles.rowTitleOne}>
					<Text style={styles.labelTitle}>ESTADO DE CAPITAL ACTUAL</Text>
				</View>
					{this.state.datosImpresion?this.state.datosImpresion.map((dato, index)=>{ 
						let inicial="INICIAL";
						let actual="ACTUAL"
						return(
							<View key={index}>
							{index ===0 && (
							<View style={styles.colHead}>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>Nº</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>TIPO</Text> 
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>SOCIO</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NACIONALIDAD</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>TIPO DE TÍTULOS</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>NUM. DE TÍTULOS</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>%</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>NUMERACIÓN DE TÍTULOS</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'10%'}}>GRAVÁMENES</Text>
                                                               
							</View>
							)}
								<View style={styles.colHead}>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{index+1}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{dato.TIPO_CAPITAL==="I"?`${inicial}`:`${actual}`}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.nombre?dato.nombre:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NACIONALIDAD?dato.NACIONALIDAD:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{dato.tipo?dato.tipo:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.NUMERO_TITULOS?dato.NUMERO_TITULOS:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{parseFloat((dato.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2)}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NUMERACION_TITULOS?dato.NUMERACION_TITULOS:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'10%'}}>{dato.GRAVAMENES?dato.GRAVAMENES:''}</Text>
								</View>                               
							
						</View>
						)
					}):<></>}      
			</View>

			<Text
				style={styles.footerPage}
				render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
				fixed
			/>
			<Image
					style={styles.icono}
					src={iconoFooter}
					fixed
					/>
			<Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
		</Page>
		</Document>
	 </PDFViewer>
	)
  componentDidMount=async()=>{

	this.setState({cargando: true}, async ()=> {
		this._isMounted = true;
		await this.cargarDatos()
		await this.tipoCapital()
		await this.cargarCapital()
		await this.cargarNumTitulos()

	//si el componente ha terminado de montarse.
	if (this._isMounted) {
		await this.cargarFecha()
		//cogemos la fecha y el valor del titulo del cap social actual y si no existen
		//lo cogemos del inicial

		
	}

	this.setState({cargando: false})
   })
  }


  irPrimerPagina=async()=>{
	   this.setState({
		   indiceRegistros:0,
		   cargandoGrid:true
		}, async()=>{
			await this.cargarCapital()
			this.setState({cargandoGrid:false})
		})
	  
  }

  irUltimaPagina=async()=>{
	  this.setState({
		  indiceRegistros:Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1,
		  cargandoGrid:true
		},async()=>{
			await this.cargarCapital()
			this.setState({cargandoGrid:false})
		})
	  
  }

  cambiarIndiceMostrar=async(indice)=>{
	   this.setState({
		   indiceRegistros:indice,
		   cargandoGrid:true
		},async()=>{
			await this.cargarCapital()
			this.setState({cargandoGrid:false})
		})
	  
   }

	botonesPaginacion=()=>{
			
		let botones=[]
		let totalPaginas=Math.ceil(this.state.totalRegistros/this.registrosMostrar)
		let restante=this.state.indiceRegistros+(totalPaginas-this.state.indiceRegistros)
		if(this.state.indiceRegistros>=3){
			botones.push(
				<Button>...</Button>
			)

		}
		for(let i=this.state.indiceRegistros>2?this.state.indiceRegistros-2:0;i<(this.state.indiceRegistros<2?(restante>=4?4:restante):(this.state.indiceRegistros+3)<totalPaginas?this.state.indiceRegistros+3:restante);i++){
			botones.push(
				<Button key={i} className={this.state.indiceRegistros===i?Classes.INTENT_PRIMARY:""} onClick={()=>{this.cambiarIndiceMostrar(i)}}>{i+1}</Button>
			)
		}

		if(this.state.indiceRegistros<10 && (this.state.indiceRegistros+3)<totalPaginas){
			botones.push(
				<Button>...</Button>
			)

		}
		return botones

	}



  render() {

	const { steps } = this.state;

	document.title ="LexControl | Capital social inicial"

	const formatearMiles=(numero)=>{
		return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
	}

	const cambiarValorTitulo = ev => {
	    //solo permitir en el input numeros,comas y puntos.
		let pattern=/^[0-9,.]*$/
		if(pattern.test(ev.currentTarget.value)===false)return

		//comprobacion que sea un número válido
		let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
		if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
			this.setState({valorTituloValido:true})
		}else{
			this.setState({valorTituloValido:false})
		}

		this.setState({valorTitulo:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
	};

	const cerrarModal = () => {
	  this.setState({
		abrirModalGuardarDatos: false,
		abrirModalDistribucion: false,
		modalBorrarAbierto:false
	  },async ()=>{
		this.setState({cargando: true}, async ()=> {
			this._isMounted = true;
			await this.cargarDatos()
			await this.tipoCapital()
			await this.cargarCapital()
			await this.cargarNumTitulos()
	
		//si el componente ha terminado de montarse.
		if (this._isMounted) {
			await this.cargarFecha()
			//cogemos la fecha y el valor del titulo del cap social actual y si no existen
			//lo cogemos del inicial
	
			
		}
	
		this.setState({cargando: false})
	   })
	  });
	};

	const escogeFecha = nuevaFecha => {
	  this.setState({
		fechaSeleccionada: moment(nuevaFecha).format("YYYY-MM-DD")
	  });
	};



	const abrirModalGuardar = rowIndex => {

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert("No tienes permisos para realizar esta acción")
			return
		}

	  this.setState({ abrirModalGuardarDatos: true });
	};

	const abrirModalDistribucion =async() => {
	  
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert("No tienes permisos para realizar esta acción")
		  return
	  }

	   //CAP SOCIAL INICIAL
	   let capInicial= this.props.capSocialInicial
	//    await this.props.client
	//    .query({
	// 	   query: gql`
	// 	   {
	// 		   capSocialInicial {
	// 		   FECHA_VALIDEZ
	// 		   VALOR_TITULO
	// 		   }
	// 	   }
	// 	   `,fetchPolicy:'network-only'
	//    })
	//    .then(result => {
	// 	   //si tenemos capita social inicial.
	// 	   if (result.data.capSocialInicial.length > 0) {
	// 		 return true;
	// 	   }
	// 	   return false;
	//    });

	   if(!capInicial){
		  alert("Primero debe guardar la información del capital social inicial")
		  return
	   }

	  if(this.state.capitalSocial.length===0){
		alert("Primero debe insertar la distribución del capital social inicial.")
		return
	  }

	  this.setState({ 
		distribucionSeleccionada:null,
		nuevoActivado:true,
		editarActivado:false,
		borrarActivado:false,
		abrirModalDistribucion: true
	  });
	};

	const abrirModalDistribucionEditar=()=>{

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert("No tienes permisos para realizar esta acción")
			return
		}

	  if(!this.state.distribucionSeleccionada){
		alert("Debe seleccionar una distribucion")
		return
	  }

	  this.setState({ 
		abrirModalDistribucion: true,
		nuevoActivado:false,
		editarActivado:true,
		borrarActivado:false
	  });

	}

	const abrirModalDistribucionBorrar=()=>{

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert("No tienes permisos para realizar esta acción")
		  return
	  }

	  if(!this.state.distribucionSeleccionada){
		alert("Debe seleccionar una distribucion")
		return
	  }

	  this.setState({ 
		modalBorrarAbierto: true,
		nuevoActivado:false,
		editarActivado:false,
		borrarActivado:true
	  });

	}

	const getMomentFormatter = (format) => {
	  // note that locale argument comes from locale prop and may be undefined
	  return {
		formatDate: (date, locale) =>
		  moment(date)
			.locale(locale)
			.format(format),
		parseDate: (str, locale) =>
		  moment(str, format)
			.locale(locale)
			.toDate(),
		placeholder: format
	  };
	};

	const guardarCambiosCapitalSocial = () => {
	  let query = "";
	  if(!this.state.valorTituloValido)return
	  //si no existe el capital social lo creamos
	  if (!this.state.existeCapitaSocial) {
		query = gql`mutation{crearCapSocialActual(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  } else {
		// de lo contrario, lo actualizariamos
		query = gql`mutation{actualizarCapSocialActual(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  }
	  //guardar el capital social.
	  this.props.client.mutate({
		mutation: query
	  });

	  alert("Capital social actual actualizado")
	  
	  this.setState({existeCapitaSocial:true})

	  cerrarModal();
	};

	const aceptar = async() => {
		this.setState({cargandoSaveCapAct:true});
	  		if(!this.nuevoTitulo.state.socioSeleccionado) {
				this.setState({cargandoSaveCapAct:false});
				return
			}
			let tipoTitulo=0
			for (let key in this.nuevoTitulo.state.activadoCheckbox) {
				//damos de alta los tipos de participaciones
				tipoTitulo+=parseInt(key.replace("\"",""))
			}
			
			let existe=false
			if(this.state.nuevoActivado){
			
				this.state.capitalSocActual.find(capital=>{
					if(capital.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && capital.TIPO_TITULO===tipoTitulo){
						existe=true
						console.log(existe)
					}
				})
				// //comprobar que no exista un socio con ese tipo de titulo.
				// let existe=await this.props.client
				// .query({
				//   query: gql`
				// 	{
				// 	  capSocialActualDistribucion (CAMPOS:"ID_PERSONA,NUMERO_TITULOS",FILTROS:{ID_PERSONA:${this.nuevoTitulo.state.socioSeleccionado.value},TIPO_TITULO:${tipoTitulo}}){
				// 		ID_PERSONA
				// 		NUMERO_TITULOS
				// 		PERSONA {
				// 		  TIPO_PERSONA {
				// 			ID_TIPO_PERSONA
				// 		  }
				// 		}
				// 	  }
				// 	}
				//   `,fetchPolicy:'network-only'
				// })
				// .then(result => {
				//   if(result.data.capSocialActualDistribucion.length>0)return true;
				//   return false;
				// });
				if(existe===true){
					this.setState({cargandoSaveCapAct:false});
					alert("Ya existe este socio con este tipo de título.")
					return
				}
		
				await this.nuevoTitulo.aceptarDistribución()
				//si se ha creado el titulo
				if(this.nuevoTitulo.state.tituloCreado){
					await this.cargarCapital()
					await this.cargarNumTitulos()
		
					//vamos a la utlima paginación.
					await this.irUltimaPagina()
		
					//seleccionamos el creado
					let creado=await this.state.capitalSocial.find((capital,index)=>{
						if(capital.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloCreado){
							this.setState({regionSeleccionada:[Regions.row(index)]})
						}
						return capital.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloCreado
		
					})
					
					this.setState({
						cargandoSaveCapAct:false,
						distribucionSeleccionada:creado
					})
		
					cerrarModal()
		
				}
				this.setState({cargandoSaveCapAct:false}); 
			} else {
	
				//comprobar que no exista un socio con ese tipo de titulo.
				let existe=this.state.capitalSocial.find(cap=>{ return cap.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && cap.TIPO_TITULO===tipoTitulo && cap.ID_DISTRIBUCION!==this.state.distribucionSeleccionada.ID_DISTRIBUCION})
				if(existe){
					this.setState({cargandoSaveCapAct:false});
					alert("Ya existe este socio con este tipo de título.")
					return
				}
		
				await this.nuevoTitulo.aceptarDistribución()
				//si se ha creado el titulo
				if(this.nuevoTitulo.state.tituloActualizado){
					await this.cargarCapital()
					await this.cargarNumTitulos()
		
					//seleccionamos el creado
					let creado=this.state.capitalSocial.find((capital,index)=>{
					if(capital.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloActualizado){
						this.setState({regionSeleccionada:[Regions.row(index)]})
					} 
					return capital.ID_DISTRIBUCION===this.nuevoTitulo.state.tituloActualizado
		
					})
		
					this.setState({
						cargandoSaveCapAct:false,
						distribucionSeleccionada:creado
					})
		
					cerrarModal()
				} 
				this.setState({cargandoSaveCapAct:false});
			}

	  
   
	};

	const seleccionarDistribucion=(value)=>{
	  if(value.length===0)return
	  let seleccionada=this.state.capitalSocial[value[0].rows[0]]                
	  this.setState({
		distribucionSeleccionada:seleccionada,
		regionSeleccionada:value
	  })
	}

	const eliminarDistribucion=async()=>{
	  
	  //eliminamos la distribucion
	  await this.props.client.mutate({
		mutation:gql`mutation{eliminarcapSocialActualDistribucion(ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}){ID_DISTRIBUCION}}`
	  })
	  .then(async result=>{

		this.setState({
		  distribucionSeleccionada:null,
		  regionSeleccionada:null
		},()=>{this.tablaDistribuciones.clearSelection()})
		await this.cargarCapital()
		await this.cargarNumTitulos()
		if(this.state.capitalSocial.length===0){
		  await this.irUltimaPagina()
		}

		alert("El registro se ha eliminado correctamente.")

		cerrarModal()
		
	  }).catch(err=>{
		alert("Ha ocurrido un errar al eliminar la distribucion.Pongase en contacto con el soporte técnico.")
	  })

	  
	}
	const cerrarModalImprimir= () => {
			this.setState({
				modalImprimir:false
			})
	}

	const handleJoyrideCallback=async(data)=>{
            
		const { action, index, status, type } = data;
	 

		if ([EVENTS.STEP_AFTER].includes(type)) {

			let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
	  
			this.setState({ pasoGuia: paso });   

		}

		//si termina la guia.
		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			localStorage.setItem("ayuda",false)
		}

	}
	
	return (
	  <>
		  {
			this.state.cargando === true?
			<Cargando />
			:
			<>
				<Joyride
					run={localStorage.getItem("ayuda")==="true"?true:false}
					callback={handleJoyrideCallback}
					showProgress={true}
					stepIndex={this.state.pasoGuia}
					steps={steps}
					continuous ={true}
					locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
					styles={{
						options: {
							arrowColor: '#e3ffeb',
							backgroundColor: '#e3ffeb',
							primaryColor: '#E31E24',
							}
					}}
				/>
				<Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir informe de estado de capital social"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarImprimir-pdf"></div>
                            :
                                <Spinner />
                        }
				</Dialog>		
				<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Datos capital inicial"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.abrirModalGuardarDatos}
				>
				<div className={Classes.DIALOG_BODY}>
					<p>Datos del capital social actual:</p>
					<br />
					<p>
					Fecha de validez{" "}
					{moment(this.state.fechaSeleccionada).format("DD-MM-YYYY")}
					</p>
					<p>Valor individual del titulo: {this.state.valorTitulo}</p>
					<br />
					<p>¿Guardar estos datos?</p>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content="Guardar cambios." position="top">
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={guardarCambiosCapitalSocial}
						>
						Si
						</AnchorButton>
					</Tooltip>
					<Tooltip content="Cerrar y volver." position="top">
						<Button onClick={cerrarModal}>No</Button>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={cerrarModal}
				title="Guardando distribución del capital social."
				canEscapeKeyClose={false}
				canOutsideClickClose={false}
				isCloseButtonShown={false}
				usePortal={true}
				isOpen={this.state.cargandoSaveCapAct}
				>
					<Cargando />
				</Dialog>
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={cerrarModal}
				title="Distribución del capital social."
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.abrirModalDistribucion}
				>
				<NuevoTituloSociosActual datosDistribucion={this.state.distribucionSeleccionada} ref={(input) => { this.nuevoTitulo= input; }} />
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip
						content="Aceptar distribución del capital social."
						position="top"
					>
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={aceptar}
						>
						Aceptar
						</AnchorButton>
					</Tooltip>
					<Tooltip content="Cerrar y volver." position="top">
						<Button onClick={cerrarModal}>Cancelar</Button>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Borrar una cuenta"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.modalBorrarAbierto}
				>
						<div className={Classes.DIALOG_BODY}>
							<h2>¿Desea eliminar la distribucion?</h2>
						</div>
						<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Tooltip content="Eliminar credito." position="top">
								<AnchorButton
									intent={Intent.PRIMARY}
									onClick={eliminarDistribucion}
								>
									Si
								</AnchorButton>
							</Tooltip>
							<Tooltip content="Cerrar y volver." position="top">
								<Button onClick={cerrarModal}>No</Button>
							</Tooltip>
						</div>
					</div>
				</Dialog>
				<div className="tablaTreinta">
					<Card  id="datos-capital-social-actual"  interactive={true} elevation={Elevation.TWO}>
						<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Información del capital social</Callout>
					<br />
					<FormGroup label="Fecha de validez" labelFor="informacion">
					<DateInput minDate={new Date(1900, 1 ,1)}
						{...getMomentFormatter("DD-MM-YYYY")}
						locale="es"
						onChange={escogeFecha}
						value={new Date(this.state.fechaSeleccionada)}
					/>
					</FormGroup>
					<br />
					<FormGroup label="Valor del titulo" labelFor="informacion">
					<InputGroup
						name="Valor del titulo"
						value={this.state.valorTitulo}
						onChange={cambiarValorTitulo}
						type="text"
					/>
					</FormGroup>
					<br />
					<FormGroup label="Número de títulos" labelFor="informacion">
					<InputGroup
						name="Número de títulos"
						value={this.state.numTitulos}
						disabled
					/>
					</FormGroup>
					<br />
					<FormGroup
					label="Capital social actual (total)"
					labelFor="informacion"
					>
					<InputGroup
						name="Capital social actual (total)"
						value={
							Intl.NumberFormat('es-ES').format(parseFloat(this.state.valorTitulo?this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.'):this.state.valorTitulo) * this.state.numTitulos) + " €"
						}
						disabled
					/>
					</FormGroup>
					<Button
					alignText="right"
					icon="floppy-disk"
					text="Guardar"
					onClick={abrirModalGuardar}
					className="bp3-intent-danger iconoDerecha bp3-minimal"
					/>
					<br />
					<br />
				</Card>
				</div>
				<div className="tablaSetenta">
				<Card  id="vista-general-estado-social"  interactive={true} elevation={Elevation.TWO}>
				<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Distribución del capital social actual</Callout>
					<Navbar>
					<Navbar.Group>
						<NavbarHeading>
							Introduzca la distribución por socios y tipo de títulos del capital social actual para obtener el número total de títulos y el valor total del capital social actual.
						</NavbarHeading>
					</Navbar.Group>
					</Navbar>
					<Navbar>
					<Navbar.Group>
						<ButtonGroup align={Alignment.LEFT}>
						<Button
							className="bp3-minimal"
							icon="add"
							text="Nuevo"
							active={this.state.nuevoActivado}
							onClick={abrirModalDistribucion}
						/>
						<Button
							className="bp3-minimal"
							icon="edit"
							text="Editar"
							onClick={abrirModalDistribucionEditar}
							active={this.state.editarActivado}
						/>
						<Button
							className="bp3-minimal"
							icon="delete"
							text="Borrar"
							onClick={abrirModalDistribucionBorrar}
							active={this.state.borrarActivado}
						/>
						<Button
								className="bp3-minimal"
								icon="print"
								text="Imprimir"
								active={this.state.imprimirActivado}
								onClick={()=>this.generarImprimir()}
							/>
						{/* {this.state.cargandoCsv===false?
						<Tooltip content="Recomendamos usar la orientación horizontal para imprimir esta pantalla">
							<ReactToPrint
								trigger={() => <Button className="iconoDerecha bp3-minimal" icon="print">Libro de acciones nominativas</Button>}
								content={() => this.tablaDistribucionesAImprimir}
							/>
						</Tooltip>
						:
							<Button
								className="bp3-minimal"
								icon="print"
								text="Cargando..."
							/>
						
						} */}
						{this.state.cargandoCsv===false?
							<Button
							className="bp3-minimal"
							icon="document"
							>
							<CSVLink data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"Capital_Actual.csv"}>Exportar</CSVLink>
							</Button>
						:
							<Button
							className="bp3-minimal"
							icon="document"
							text="Cargando..."
							/>
						}
						</ButtonGroup>
					</Navbar.Group>
					</Navbar>
					{
					this.state.cargandoGrid === true?
					<Cargando />
					:
					<div  id="tabla-distribucion-actual">
						<Table
						columnWidths={this.state.columnWidths}
						numRows={this.state.capitalSocial.length}
						onSelection={seleccionarDistribucion}
						selectedRegions={this.state.regionSeleccionada}
						enableRowHeader={false}
						selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}
						enableMultipleSelection={false} 
						ref={(input) => { this.tablaDistribuciones= input; }}
						onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}
						>
							<Column name="" cellRenderer={this.cellRendererNumSoc} />
							<Column name="Tipo" cellRenderer={this.cellRendererTipoCap} />
							<Column name="Socio" cellRenderer={this.cellRendererSocio} />
							<Column name="Nacionalidad" cellRenderer={this.cellRendererNacionalidad} />
							<Column name="Tipo de títulos" cellRenderer={this.cellRendererTipo} />
							<Column name="Num. Títulos" cellRenderer={this.cellRendererNumero} />
							<Column name="%" cellRenderer={this.cellRendererPorc} />
							<Column name="Númeracion de Títulos" cellRenderer={this.cellRendererNumeracion} />
							<Column name="Gravamenes" cellRenderer={this.cellRendererGravamenes} />
						</Table>
					</div>
  					}
					<div className="centrar2">
					{this.state.totalRegistros>20?
							<ButtonGroup className="pixelAlrededorGrande">
								<Button icon="chevron-left" onClick={this.irPrimerPagina} disabled={this.state.indiceRegistros===0?true:false}></Button>
									{this.botonesPaginacion()}
								<Button icon="chevron-right" onClick={this.irUltimaPagina} disabled={this.state.indiceRegistros===Math.ceil(this.state.totalRegistros/this.registrosMostrar)-1?true:false}></Button>
							</ButtonGroup>
					:<></>}
		
					</div>
				</Card>
				</div>
			</>
		  }
	  </>
	);
  }
  componentWillUnmount(props) {
	this._isMounted = false;
  }
}


export default withApollo(EstadoCapitalSocial)