import React, { Component } from 'react';
import { Card, Elevation, InputGroup } from "@blueprintjs/core"

export default class Fichajes extends Component {
    
    state ={
        hora: new Date(),
        tipoInforme:[
            {value: 1, label: "Semanal"},
            {value: 2, label: "Mensual"},
            {value: 3, label: "Anual"},
        ],
        informeSeleccionado: null,
        fichajesRealizados: [
            { nombre: "Rafael García León", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 17:30", normales: 8, extras: 3},
            { nombre: "Gabriel Fernandez Lopez", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 15:30", normales: 8, extras: 0},
            { nombre: "Miguel Rodriguez Perez", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 17:30", normales: 8, extras: 2},
            { nombre: "Angel Sanchez Escobar", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 16:30", normales: 8, extras: 1},
            { nombre: "Gabriel Diaz Perez", fechaEntrada: "12/3/2019 07:00", fechaSalida: "12/3/2019 15:30", normales: 8, extras: 0},
        ],
        fechaMock: null,
    }

    render() {

        document.title ="LexControl | Fichajes"

        return (
            <>
                <div className="pixelAlrededor">
                    <Card interactive={true} elevation={Elevation.TWO}>
                    <div class="tg-wrap">
                        <table class="tg">
                            <tr>
                                <th class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="15">REGISTRO DE JORNADA</th>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2">TRABAJADOR:</td>
                                <td class="tg-1fcj" colspan="6"><InputGroup /></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">MES:</td>
                                <td class="tg-1fcj" colspan="4"><InputGroup /></td>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">AÑO:</td>
                                <td class="tg-1fcj"><InputGroup /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" colspan="2" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">CENTRO:</td>
                                <td class="tg-1fcj" colspan="6"><InputGroup /></td>
                                <td class="tg-lbol" colspan="2" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">DEPARTAMENTO:</td>
                                <td class="tg-1fcj" colspan="5"><InputGroup /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" rowspan="3" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >DIA</td>
                                <td class="tg-lbol" rowspan="3" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >TURNO</td>
                                <td class="tg-lbol" colspan="6" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >HORAS ORDINARIAS</td>
                                <td class="tg-lbol" colspan="3" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >HORAS EXTRAORDINARIAS</td>
                                <td class="tg-lbol" colspan="2" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="3">FIRMA DEL TRABAJADOR</td>
                                <td class="tg-lbol" colspan="2" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="3">FIRMA DE LA EMPRESA (HORAS EXTRAORDINARIAS)</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2" rowspan="2">EXCESO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">DEFECTO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2">COMPENSACIÓN</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >SALDO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">REALIZADAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">COMPENSADAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">SALDO</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >MES/AÑO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >HORAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >PENDIENTE</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">1</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">2</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">3</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">4</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">5</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">6</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">7</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">8</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">9</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">10</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">11</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">12</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">13</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">14</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">15</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">16</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">17</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">18</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">19</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">20</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">21</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">22</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">23</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">24</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="3">DIA</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="3">TURNO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="6">HORAS ORDINARIAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="3">HORAS EXTRAORDINARIAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2" rowspan="3">FIRMA DEL TRABAJADOR</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2" rowspan="3">FIRMA DE LA EMPRESA (HORAS EXTRAS)</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2" rowspan="2">EXCESO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">DEFECTO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2">COMPENSACIÓN</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >SALDO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2" >REALIZADAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2" >COMPENSADAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2" >SALDO</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >MES/AÑO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >HORAS</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" >PENDIENTE</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">25</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">26</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">27</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">28</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">29</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">30</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol">31</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-lbol"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2">TOTALES</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"></td>
                                <td class="tg-1fcj" colspan="2"></td>
                                <td class="tg-1fcj" colspan="2"></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="4"></td>
                                <td class="tg-1fcj" colspan="3">HORAS EXTRAS A PAGAR</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2">H. EXTRAS PAGADAS AÑO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"></td>
                                <td class="tg-1fcj" colspan="2"></td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="8">TURNOS DE TRABAJO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">TOTAL HORAS / TURNO</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" rowspan="2">HORARIO FLEXIBLE</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="5" rowspan="2">TIEMPO DE TRABAJO NO EFECTIVO</td>
                            </tr>
                            <tr>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" ></td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="3">MAÑANA</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2">TARDE</td>
                                <td class="tg-lbol" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes" colspan="2">NOCHE</td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">1</td>
                                <td class="tg-1fcj"><InputGroup disabled value="09:00"/></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled value="14:00" /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="16:00" /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="20:00" /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="9" /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="SI" /></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled value="30' DESAYUNO;  30' PAUSAS Y AUSENCIAS" /></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">2</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="22:00"/></td>
                                <td class="tg-1fcj"><InputGroup disabled value="06:00" /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="8"/></td>
                                <td class="tg-1fcj"><InputGroup disabled value="NO" /></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled value="15' PAUSA RETRIBUIDA; 15' PAUSAS TRABAJO NO EFECTIVO"/></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">1</td>
                                <td class="tg-1fcj"><InputGroup disabled value="07:00"/></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled value="15:30" /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="8 H. 30'"/></td>
                                <td class="tg-1fcj"><InputGroup disabled value="NO"/></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled value="30' DESAYUNO; 15' PAUSAS TRABAJO NO EFECTIVO" /></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">1</td>
                                <td class="tg-1fcj"><InputGroup disabled value="07:00"/></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled value="17:00" /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled value="10"/></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled value="30' DESAYUNO; 90' COMIDA; 15' PAUSAS TRABAJO NO EFECTIVO" /></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">5</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">6</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" className="celdaFondoRojoTextoCentradoBlancoGestionFichajes">7</td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="2"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj"><InputGroup disabled /></td>
                                <td class="tg-1fcj" colspan="5"><InputGroup disabled /></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15">El trabajador está obligado a cumplimentar el registro diario de la jornada, anotando a la entrada y salida, así como las horas de exceso y compensación cuando se produzca.</td>
                            </tr>
                            <tr>
                                <td class="tg-xdwg" className="celdaFondoNegro" colspan="15"></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15"><span style={{fontWeight: "bold",fontStyle: "normal"}}>Por principio, está prohibida la realización de horas extraordinarias y solamente se producirán por acuerdo entre empresa y trabajador.</span></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15">Si se realizaran horas extras, se anotarán en el día que sucedan, así su posterior compensaciuón o abono en nómina SIN SUPERAR el máximo de 80 al año.</td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15"><span >En cualquier caso, se procurará que al final de mes no exista saldo pendiente, ni de horas ordinarias ni extraordinarias, aunque el plazo máximo para su compensación es de 4 meses.</span></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15"><span >El límite no afecta a las realizadas para prevenir o reparar daños extraordinarios o urgentes. Tampoco afectan a este límite  las que se compensen en los 4 meses siguientes.</span></td>
                            </tr>
                            <tr>
                                <td class="tg-xdwg" className="celdaFondoNegro" colspan="15"></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15"><span style={{fontWeight: "bold",fontStyle: "normal"}}>HORARIO FLEXIBLE:</span> <span style={{fontWeight: "bold",fontStyle: "normal"}}>Si el turno lo autoriza</span> <span >el trabajador podrá retrasar o adelantar un máximo de una hora, tanto la que tiene fijada para comenzar a trabajar, como la establecida como final de jornada. El horario flexible tendrá que ser autorizado por el superior jerárquico y no podrá el trabajador acogerse a él, cuando la naturaleza de su trabajo no lo permita.</span></td>
                            </tr>
                            <tr>
                                <td class="tg-xdwg" className="celdaFondoNegro" colspan="15"></td>
                            </tr>
                            <tr>
                                <td class="tg-1fcj" colspan="15"><span style={{fontWeight: "bold",fontStyle: "normal"}}>EL TIEMPO NO EFECTIVO DE TRABAJO ESTABLECIDO EN CADA TURNO, HA SIDO PREVIAMENTE ACORDADO CON LOS TRABAJADORES, CON EL OBJETO DE PERMITIR LAS PAUSAS DURANTE LA JORNADA, PARA DESAYUNAR, ALMORZAR, FUMAR, HIDRATARSE, TOMAR UN CAFÉ, O PARA DESCANSAR, ASÍ COMO PARA COMPENSAR AUSENCIAS IMPREVISTAS QUE NO SIENDO LEGALMENTE RETRIBUIBLES, PERMITEN UNA MAYOR COMODIDAD EN LA PRESTACIÓN DE CADA ÁREA DE TRABAJO.</span></td>
                            </tr>
                            </table>
                        </div>
                    </Card>
                </div>
            </>
        )
    }
}